import React, { useState } from 'react';
import './PricingBulk.scss'; // Import your CSS file here
import Button from '@mui/material/Button';
import { StarIcon } from '../../../Asset/Icons/Icons';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import { useAuthUser } from 'react-auth-kit';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_API } from '../../../API/ApiConstants';
import { useIsAuthenticated } from 'react-auth-kit';
import { useNavigate } from 'react-router';
import InfoDialog from '../InfoDialog/InfoDialog';

const PricingCardBulk = ({ pricing_data }) => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const auth = useAuthUser();
  const [loader, setLoader] = useState(false);

  const handleStripePayment = async (stripeCost, plan) => {
    if (!isAuthenticated()) {
      navigate('/login');
      return;
    }
    const data = { plan: plan, amount: stripeCost, userId: auth().userId };
    const stripe = await loadStripe(`${process.env.REACT_APP_Publish_Stripe_KEY}`);

    const headers = {
      'Content-Type': 'application/json',
    };

    const fetchData = async () => {
      setLoader(true);

      try {
        const response = await axios.post(STRIPE_API, data, {
          headers: headers,
        });

        const session = response.data;

        const result = await stripe.redirectToCheckout({
          sessionId: session,
        });

        if (result.error) {
          console.log('Error during Checkout redirect:', result.error);
        } else if (result.complete) {
          console.log('Payment was successful!');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoader(false);
    };

    fetchData();
  };
  return (
    <>
      {pricing_data.map((cardData, index) => {
        return (
          <div key={index} className="price_card_bulk">
            <div className="card">
              <div className="card__side card__side--front">
                <div className="card__picture card__picture--1">&nbsp;</div>
                <h4 className="card__heading">
                  <span className="card__heading-span card__heading-span--2">
                    {cardData.planTitle} {cardData.bestSeller ? StarIcon : <></>}
                  </span>
                </h4>
                <div className="card__details">
                  <ul>
                    {cardData?.info?.map((data, index) => {
                      return <li key={index}>{data}</li>;
                    })}
                  </ul>
                </div>
                {/* <div className='imp_details'>
                  {cardData?.imp_info.map((data, index) => {
                    return <h5 key={index}>{data}</h5>;
                  })}
                </div> */}
              </div>
              <div className="card__side card__side--back card__side--back-2">
                <div className="card__cta">
                  <div className="card__price-box">
                    {cardData.bestSeller ? <h1>Best Seller {StarIcon}</h1> : <></>}
                    <h2>{cardData.title01}</h2>
                    <h2>{cardData.title02}</h2>
                    <p className="card__price-only">Total Cost</p>
                    <p className="card__price-value">${cardData.cost}</p>
                    {/* <Button
                      disabled={loader}
                      onClick={() =>
                        handleStripePayment(cardData.cost, cardData.planTitle)
                      }
                      className='btn btn--white'
                      variant='outlined'
                    >
                      {loader ? (
                        <CircularProgress color='warning' size='2rem' />
                      ) : (
                        <>
                          {isAuthenticated() ? (
                            <>{cardData.ButtonTitle}</>
                          ) : (
                            <>Try it free!</>
                          )}
                        </>
                      )}
                    </Button> */}
                    <InfoDialog
                      desc0={cardData.desc0}
                      desc1={cardData.desc1}
                      planDetails={cardData.planDetails}
                      plan={cardData.planTitle}
                      Butttontitle={cardData.ButtonTitle}
                      totalCost={cardData.totalCost}
                      paymentDetails={cardData.paymentInfo}
                      desc2={cardData.desc2}
                      desc3={cardData.desc3}
                      price={cardData.price}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default PricingCardBulk;
