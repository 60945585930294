import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  LoggedIn: false,
  snackBarVal: {
    severity: "",
    message: "",
    open: false,
  },
  userPlanDetails: {
    amount: 23,
    plan: "Free",
    perRecord: 0,
    freeTrials: 100,
    userDownloads: [],
  },
  perRecord: 0,
};

export const UserAuthenticationReducer = createSlice({
  name: "userAuthentication",
  initialState: initialState,
  reducers: {
    userLoggedIn: (state, action) => {
      state.LoggedIn = action.payload;
    },
    userLoggedOut: (state, action) => {
      state.LoggedIn = action.payload;
    },
    setSnackBarValues: (state, action) => {
      state.snackBarVal = action.payload;
    },
    closeSnackBar: (state) => {
      state.snackBarVal = {
        severity: "",
        message: "",
        open: false,
      };
    },
    updateUserPlan: (state, action) => {
      state.UserPlan = action.payload;
    },
    perRecordValue: (state, action) => {
      state.userDetailsLoader = action.payload;
    },
  },
});
