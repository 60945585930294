import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './ReportDetails.scss';
import {
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Divider,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';

import DescriptionCard from '../../Molecule/DescriptionCard/DescriptionCard';

import FaqComp from '../../Molecule/FaqComp/FaqComp';
import SearchComponent from '../../Molecule/SearchComponent/SearchComponent';
import { InfoIcon, RightArrow } from '../../../Asset/Icons/Icons';
import { reportsData, latest_reports_items } from '../../../Data/Data';
export const handleNavigation = (navigate, typeId, reportId) => {
  navigate(`/report/${typeId}/${reportId}`);
  window.scrollTo({ top: 0, behavior: 'smooth' });
};
function ReportDetails() {
  const navigate = useNavigate();
  const { id, result_id } = useParams();
  const [description, SetDescription] = useState([]);
  const [reportTitle, setReportTitle] = useState('');
  const [price, setPrice] = useState('Single User ($ 3000)');
  const handleBuyType = (type) => {
    navigate(`/buy_report/${type}/${id}/${result_id}`);
  };

  useEffect(() => {
    const foundReport = reportsData.find((data) => data.id === id);

    if (foundReport.industry) {
      const foundIdn = foundReport.industry.find((idn) => idn.id === result_id);

      if (foundIdn) {
        SetDescription(foundIdn.short_discription);
        setReportTitle(foundIdn.report_title);
      }
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [reportsData, id, result_id]);
  return (
    <div className="report_details_container">
      <SearchComponent />
      <h2 className="report_title_heading">{reportTitle}</h2>
      <div className="details_main_container">
        <div className="description_container">
          <DescriptionCard description={description} />
          <div className="faq_container_01">
            <FaqComp />
          </div>
        </div>
        <div className="pricing_n_other">
          <Paper className="buying_options width_parameters">
            <h2>Buying Options</h2>
            <div className="options_list">
              <Button
                onClick={() => handleBuyType('Request sample')}
                endIcon={RightArrow}
                variant="contained">
                Request sample
              </Button>
              <Button
                onClick={() => handleBuyType('Buy A Report')}
                endIcon={RightArrow}
                variant="contained">
                Buy A Report
              </Button>
              <Button
                onClick={() => handleBuyType('Enquiry before buying')}
                endIcon={RightArrow}
                variant="contained">
                Enquiry before buying
              </Button>
            </div>
          </Paper>
          <Paper className="pricing_details width_parameters">
            <h2>Licence Type</h2>
            <div className="pricing_radio_form">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="Single User ($ 3000)"
                  name="radio-buttons-group">
                  <div className="radio_form_group">
                    <FormControlLabel
                      value="Single User ($ 3000)"
                      control={<Radio />}
                      label="Single User ($ 3000)"
                      className="form_lable"
                      onChange={(e) => setPrice(e.target.value)}
                    />{' '}
                    <Link to={'/pricing'} className="radio_form_group">
                      <Tooltip
                        style={{
                          fontSize: '2rem',
                        }}
                        title="Know More">
                        <IconButton>{InfoIcon}</IconButton>
                      </Tooltip>
                    </Link>
                  </div>
                  <div className="radio_form_group">
                    <FormControlLabel
                      value="Multi User ($ 4500)"
                      control={<Radio />}
                      label="Multi User ($ 4500)"
                      className="form_lable"
                      onChange={(e) => setPrice(e.target.value)}
                    />
                    <Link to={'/pricing'} className="radio_form_group">
                      <Tooltip
                        style={{
                          fontSize: '2rem',
                        }}
                        title="Know More">
                        <IconButton>{InfoIcon}</IconButton>
                      </Tooltip>
                    </Link>
                  </div>
                  <div className="radio_form_group">
                    <FormControlLabel
                      value="Enterprise User ($ 6500)"
                      control={<Radio />}
                      label="Enterprise User ($ 6500)"
                      className="form_lable"
                      onChange={(e) => setPrice(e.target.value)}
                    />
                    <Link to={'/pricing'} className="radio_form_group">
                      <Tooltip
                        style={{
                          fontSize: '2rem',
                        }}
                        title="Know More">
                        <IconButton>{InfoIcon}</IconButton>
                      </Tooltip>
                    </Link>
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
            <Divider />
            <div className="know_more">
              <h3 className="know_more_heading">Get more information on our licences.</h3>
            </div>
            <Divider />
            <Button
              onClick={() => handleBuyType(price)}
              endIcon={RightArrow}
              variant="contained"
              className="button_buy_now">
              Buy Now
            </Button>
          </Paper>
          <Paper className="latest_reports_details width_parameters">
            <h2>Latest Reports</h2>
            {latest_reports_items.map((data, index) => {
              return (
                <div key={index} className="reports_list">
                  <h3
                    onClick={() => handleNavigation(navigate, data.typeId, data.reportId)}
                    className="title_heading">
                    {data.title}
                  </h3>
                </div>
              );
            })}
          </Paper>
          <div className="faq_container_02">
            <FaqComp />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportDetails;
