import React from "react";
import "./DataPoint.scss";
import Background from "../../Molecule/Bakground/Background";
import { data_points } from "../../../Data/Data";
const data = {
  title: "Your Gateway to 500M+ Public Data Records",
  description:
    "We're proud to offer an extensive data repository, comprising over 500 million records meticulously sourced from 5,000+ reputable public data sources. Our mission is to empower you with invaluable insights and information, helping you make informed decisions. Please note that our data access is exclusively available for U.S. residents.",
};
function DataPoint() {
  return (
    <div className='data_point'>
      <div className='background_contaier'>
        <Background title={data.title} description={data.description} />
      </div>

      <div className='content_container'>
        <p>
          We believe in transparency, which is why all our data points are
          provided "as available" directly from the public record sources. This
          means that you get real, unfiltered information straight from the most
          authoritative sources. It's our commitment to accuracy and
          reliability.
        </p>
      </div>
      <div className='data_points_table'>
        <div className='card_container_01'>
          <h3>Column</h3> <h3>Type</h3>
        </div>
        {data_points.map((data, index) => {
          return (
            <div className='card_container_01'>
              <p>{data.column}</p>
              <p>{data.type}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default DataPoint;
