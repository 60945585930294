import React from "react";
import "./Footer.scss";
import FooterComp from "../../Molecule/Footer/Footer";
function Footer() {
  return (
    <div className='footer_container'>
      <FooterComp />
    </div>
  );
}

export default Footer;
