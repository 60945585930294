import React, { useEffect } from "react";
import { Alert, Snackbar } from "@mui/material";
import { useDispatch } from "react-redux";
import { AuthenticationAction } from "../../../Redux/Actions/Action";
import "./SnackBar.scss";
function SnackBar({
  severity = "success",
  message = "This is a success message!",
  open = true,
}) {
  const dispatch = useDispatch();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(AuthenticationAction.closeSnackBar());
    // setOpen(false);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(AuthenticationAction.closeSnackBar());
    }, 8000);

    // Clear the timer if the component unmounts or open changes
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <div className='snack_bar_container'>
      <Snackbar open={open} autoHideDuration={8000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default SnackBar;
