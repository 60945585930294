import axios from "axios";
import {
  FILTER_USERS_DATA,
  FORGOT_PASSWORD,
  GET_CONFIRM_EMAIL_ID,
  GET_EXCEL,
  GET_REQUESTED_DATA,
  GET_USER_PLAN,
  LOG_IN,
  LOG_OUT,
  RESET_PASSWORD,
  SIGN_UP_API,
} from "./ApiConstants";
import { convertToCSV } from "../Helper/Helper";

export const CALL_SIGN_UP_API = async (data) => {
  try {
    const response = await axios.post(SIGN_UP_API, data);

    return {
      status: response.status,
    };
  } catch (error) {
    if (error?.response?.status === 400) {
      return { status: true, message: error.response?.data[0].description };
    } else {
      return {
        status: true,
        message:
          "Oops, something went wrong! We apologize for the inconvenience. Please try again later or get in touch with our support team for assistance.",
      };
    }
  }
};

export const CALL_LOG_IN_API = async (data) => {
  let response = await axios.post(LOG_IN, data);
  return response;
};
export const CALL_LOG_OUT_API = async () => {
  await axios.post(LOG_OUT);
};

export const CALL_USER_DETAILS = async (id, token) => {
  try {
    const response = await axios.get(`${GET_USER_PLAN}${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    // Handle errors here
    return error;
  }
};

export const CALL_DATA_FILTER = async (data, token) => {
  try {
    const response = await axios.post(FILTER_USERS_DATA, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const CALL_GET_REQUESTED_DATA = async (data, token) => {
  try {
    const response = await axios.post(GET_REQUESTED_DATA, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    // Handle errors here
    return error.response.data;
  }
};

export const CALL_CONFIRM_EMAIL_ID = async (uid, token, email) => {
  const url = `${GET_CONFIRM_EMAIL_ID}?uid=${uid}&token=${token}`;

  try {
    const response = await axios.get(url);
    return response.status; // Return only the status code
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      return error.response.status; // Return the error status code
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      throw error;
    } else {
      // Something happened in setting up the request that triggered an error
      console.error("Error in request setup:", error.message);
      throw error;
    }
  }
};

export const CALL_FORGOT_PASSWORD = async (email) => {
  const data = {
    email,
  };

  try {
    const response = await axios.post(FORGOT_PASSWORD, data);

    return {
      status: response.status,
    };
  } catch (error) {
    if (error?.response?.status === 400) {
      return { status: true, message: error.response?.data[0].description };
    } else {
      return {
        status: true,
        message:
          "Oops, something went wrong! We apologize for the inconvenience. Please try again later or get in touch with our support team for assistance.",
      };
    }
  }
};

export const CALL_RESET_PASSWORD = async (value) => {
  const data = {
    userId: value.userId,
    token: value.token,
    newPassword: value.newPassword,
    confirmNewPassword: value.confirmNewPassword,
  };

  try {
    const response = await axios.post(RESET_PASSWORD, data);

    return {
      status: response.status,
    };
  } catch (error) {
    if (error?.response?.status === 400) {
      return { status: true, message: error.response?.data[0].description };
    } else {
      return {
        status: true,
        message:
          "Oops, something went wrong! We apologize for the inconvenience. Please try again later or get in touch with our support team for assistance.",
      };
    }
  }
};

export const CALL_RESEND_API = async (data) => {
  try {
    const response = await axios.post(SIGN_UP_API, data);

    return {
      status: response.status,
    };
  } catch (error) {
    if (error?.response?.status === 400) {
      return { status: true, message: error.response?.data[0].description };
    } else {
      return {
        status: true,
        message:
          "Oops, something went wrong! We apologize for the inconvenience. Please try again later or get in touch with our support team for assistance.",
      };
    }
  }
};

export const CALL_GET_EXCEL = async (timeStamp, userId, token) => {
  try {
    const response = await axios.get(
      `${GET_EXCEL}?timestamp=${timeStamp}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return {
      status: true,
      data: response.data,
    };
  } catch (error) {
    // Handle errors here
    return {
      status: false,
    };
  }
};
