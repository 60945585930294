import React, { useEffect, useState } from 'react';
import './PrimaryReport.scss';
import { reportsData } from '../../../Data/Data';
import ReportCard from '../ReportCard/ReportCard';
import { Pagination, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import SearchComponet from '../../Molecule/SearchComponent/SearchComponent';

function PrimaryReport({ id, search }) {
  const [sectorReport, setSectorReport] = useState([]);
  const [paginationCount, setPaginationCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [title, setTitle] = useState('');
  useEffect(() => {
    setCurrentPage(1);
  }, [id]);

  useEffect(() => {
    let matchingReport = [];
    if (id.length > 0) {
      matchingReport = reportsData.find((data) => data?.id === id);

      setTitle(matchingReport?.title);
    } else if (search.length > 0) {
      const industry = [];
      reportsData.forEach((data) => {
        if (Array.isArray(data.industry)) {
          data.industry.forEach((industryElement) => {
            industryElement.sectorId = data.id;
            industry.push(industryElement);
          });
        }
      });
      setTitle('Reports Details');
      matchingReport = {
        industry: industry.filter((report) =>
          report.report_title.toLowerCase().includes(search.toLowerCase())
        ),
      };
    }

    if (matchingReport && matchingReport.industry) {
      const recordsPerPage = 5;
      const startIndex = (currentPage - 1) * recordsPerPage;
      const endIndex = startIndex + recordsPerPage;
      const currentPageRecords = matchingReport.industry.slice(startIndex, endIndex);

      setSectorReport(currentPageRecords);
      setPaginationCount(Math.ceil(matchingReport.industry.length / recordsPerPage));
    } else {
      setSectorReport([]);
    }
    window.scrollTo(0, 500);
  }, [id, currentPage, search]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="primary_sector_report">
      <SearchComponet />
      {sectorReport.length <= 0 ? (
        <Paper className="not_found" elevation={3}>
          <h1>Report are not found for searched result</h1>
          <h3>Please try something else</h3>
        </Paper>
      ) : (
        <>
          <div className="header_div">
            <h1>{title}</h1>
          </div>
          <div className="sector_reports_table">
            {sectorReport.map((data, index) => (
              <Link key={index} to={`/report/${data.sectorId || id}/${data.id}`}>
                <ReportCard
                  title={data.report_title}
                  publishDate={data.publishDate}
                  reportUrl={data.reportUrl}
                />
              </Link>
            ))}

            <Pagination
              size="large"
              className="pagination"
              count={paginationCount}
              variant="outlined"
              color="primary"
              page={currentPage}
              onChange={handlePageChange}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default PrimaryReport;

// report_title:
// "Global Digital Health Market – Industry Trends and Forecast to 2030",
// short_discription:
// "Global Digital Health Market, By Technology (Digital Health Systems, Tele-healthcare, mHealth, Healthcare Analytics), Application (Cardiology, Diabetes, Neurology, Sleep Apnea, Oncology, Others), Delivery Mode (On-Premise, Cloud-Based), Components (Software, Services, Hardware), End-User (Healthcare Providers, Healthcare Payers, Pharmaceutical Companies, Others) - Industry Trends and Forecast to 2030.",
// publishDate: "2023-10-13T00:00:00",
// imageURl: "2020/255b6629-0506-406a-a9d6-9f25a8751883.png",
// reportUrl: "global-digital-health-market",
// tableOfContent: [],
