import React from "react";
import "./DataLake.scss";
import Background from "../../Molecule/Bakground/Background";
const data = {
  title: "Unleash the Power of Our Public Data Cloud",
  description:
    "We're proud to introduce our revolutionary Public Data Cloud — your gateway to a wealth of information sourced from trusted Federal, State, and Local Government Agencies, as well as a treasure trove of Non-Government Public Data, including Telephone Listings and WHOIS Records. With billions of records at your fingertips, our cloud is designed to be your go-to destination for accurate and up-to-the-minute data.",
};
const content_data = [
  "Our commitment to accuracy and trust begins with partnering withestablished Federal, State, and Local Government Agencies. Rest easy knowing that the data you access is sourced directly from authoritative and reliable sources, ensuring that you make informed decisions with confidence.",
  "We don't stop at government data. Our Public Data Cloud extends its reach to encompass Non-Government Public Data, such as Telephone Listings. Access the most comprehensive, up-to-date information available, so you can connect with individuals and businesses seamlessly.",
  "Navigating the digital landscape has never been easier. Our cloud includes trusted Non-Government Public Data like WHOIS Records, allowing you to unravel the intricate web of online domains and ownership information with unparalleled precision.",
  "Navigating the digital landscape has never been easier. Our cloud includes trusted Non-Government Public Data like WHOIS Records, allowing you to unravel the intricate web of online domains and ownership information with unparalleled precision.",
  "Whether you're a researcher, business professional, or data enthusiast, our Public Data Cloud is your passport to accurate, reliable, and lightning-fast data. Discover the possibilities, make data-driven decisions, and experience the future of data access today!",
  "Ready to embark on your data journey? Join us.",
];
function DataLake() {
  return (
    <div className='data_lake'>
      <div className='background_contaier'>
        <Background title={data.title} description={data.description} />
      </div>
      {content_data.map((data, index) => {
        return (
          <div key={index} className='content_container'>
            <p>{data}</p>
          </div>
        );
      })}
    </div>
  );
}

export default DataLake;
