import React from 'react';
import './ReportCard.scss';
import { useNavigate } from 'react-router-dom';
import { Button, Paper } from '@mui/material';
function ReportCard({ title, publishDate, reportUrl }) {
  const navigate = useNavigate();

  const handleReport = (e, val, title) => {
    e.preventDefault();
    navigate(`/buy_report/${val}/${title}`);
  };
  return (
    <Paper className="primary_report_card_container" elevation={1}>
      <div className="imgae_div"></div>
      <div className="content_div">
        <h2>{title}</h2>
        <div className="sub_content">
          <h4>Publish Data : {publishDate}</h4>
          <h4>Report Url :{reportUrl}</h4>
        </div>
      </div>
      <div className="action_div">
        <Button onClick={(e) => handleReport(e, 'Buy a report', title)} variant="contained">
          Get Report
        </Button>
        <Button onClick={(e) => handleReport(e, 'Request a sample', title)} variant="contained">
          Get Sample
        </Button>
      </div>
    </Paper>
  );
}

export default ReportCard;
