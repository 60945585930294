import React, { useState } from 'react';
import { Button, Paper, TextField } from '@mui/material';
import './SearchCard.scss';
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from '../../../Asset/Icons/Icons';

function SearchCard() {
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState('');
  const [inputError, setInputError] = useState(false);

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;
    setSearchInput(inputValue);
  };

  const handleSearchClick = () => {
    if (searchInput.length < 2 || searchInput.length > 60) {
      setInputError(true);
    } else {
      setInputError(false);
      navigate(`/search/${searchInput}`);
      setSearchInput('');
    }
  };

  return (
    <div className="search_card_container">
      <div className="textfield_input">
        <TextField
          className="input_field"
          id="outlined-basic"
          label="Search a report"
          variant="outlined"
          value={searchInput}
          onChange={handleSearchChange}
          error={inputError}
          helperText={inputError ? 'Input length should be between 2 and 30 characters' : ''}
        />
        <Button
          className="search_button"
          startIcon={SearchIcon}
          variant="contained"
          size="large"
          onClick={handleSearchClick}>
          Search
        </Button>
      </div>
    </div>
  );
}

export default SearchCard;
