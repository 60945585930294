import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  Paper,
  TextField,
  Typography,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import "./ResetPassword.scss"; // Import your CSS file if needed
import { CALL_RESET_PASSWORD } from "../../../API/ApiCall";
import { useLocation, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { AuthenticationAction } from "../../../Redux/Actions/Action";

function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [loader, setLoader] = useState(true);
  const uid = searchParams.get("uid");
  const token = searchParams.get("token");
  const [fieldValues, setFieldValues] = useState({
    password: "",
    newPassword: "",
  });
  const [valid, setValid] = useState({
    password: true,
    newPassword: true,
    passwordMatch: true,
  });
  const [errors, setErrors] = useState({
    password: "",
    newPassword: "",
    passwordMatch: "",
  });
  const [progress, setProgress] = useState(false);

  const handleResetPassword = async () => {
    // Reset error messages
    setErrors({ password: "", newPassword: "", passwordMatch: "" });
    let checkValidation = false;

    // Validate password and new password fields
    if (!fieldValues.password) {
      setValid((prevState) => ({ ...prevState, password: false }));
      setErrors((prevState) => ({
        ...prevState,
        password: "Password is required.",
      }));
      checkValidation = true;
    }

    if (!fieldValues.newPassword) {
      setValid((prevState) => ({ ...prevState, newPassword: false }));
      setErrors((prevState) => ({
        ...prevState,
        newPassword: "New password is required.",
      }));
      checkValidation = true;
    }

    if (fieldValues.password !== fieldValues.newPassword) {
      setValid((prevState) => ({ ...prevState, passwordMatch: false }));
      setErrors((prevState) => ({
        ...prevState,
        passwordMatch: "Passwords do not match.",
      }));
      checkValidation = true;
    }

    // Check if there are validation errors
    if (checkValidation) {
      return; // Don't proceed with resetting password if there are validation errors
    }
    const data = {
      userId: uid,
      token: token,
      newPassword: fieldValues.password,
      confirmNewPassword: fieldValues.password,
    };
    setProgress(true);
    const res = await CALL_RESET_PASSWORD(data);

    if (res?.status === 200) {
      let snackData = {
        severity: "success",
        message: "Password reset successfully.",
        open: true,
      };

      dispatch(AuthenticationAction.setSnackBarValues(snackData));

      navigate("/login");
    } else {
      let snackData = {
        severity: "success",
        message: "Signed in successfully.",
        open: true,
      };

      dispatch(AuthenticationAction.setSnackBarValues(snackData));
    }

    setProgress(false);
    setFieldValues({ password: "", newPassword: "" });
  };
  useEffect(() => {
    if (
      uid === undefined ||
      uid === null ||
      token === undefined ||
      token === null
    ) {
      navigate("/");
    }
    setLoader(false);
  }, []);
  return (
    <div className='reset_container_component'>
      {loader ? (
        <CircularProgress size='3rem' />
      ) : (
        <Paper className='paper' elevation={5}>
          <Card className='card_container'>
            <CardContent>
              <Typography gutterBottom variant='h5' component='div'>
                <h3>Reset Password</h3>
              </Typography>
            </CardContent>
          </Card>
          <FormControl required variant='standard' className='form_control'>
            <TextField
              id='password'
              name='password'
              label='Password'
              variant='standard'
              type='password'
              value={fieldValues.password}
              onChange={(e) =>
                setFieldValues({ ...fieldValues, password: e.target.value })
              }
              error={!valid.password}
              helperText={errors.password}
              required
            />
            <TextField
              id='newPassword'
              name='newPassword'
              label='Confirm Password'
              variant='standard'
              type='password'
              value={fieldValues.newPassword}
              onChange={(e) =>
                setFieldValues({ ...fieldValues, newPassword: e.target.value })
              }
              error={!valid.newPassword || !valid.passwordMatch}
              helperText={errors.newPassword || errors.passwordMatch}
              required
            />
            <Button
              className='reset_password_button'
              onClick={() => handleResetPassword()}
              variant='contained'
              disabled={progress}
            >
              {progress ? (
                <CircularProgress color='success' size='1rem' />
              ) : (
                <></>
              )}{" "}
              Reset Password
            </Button>
          </FormControl>
        </Paper>
      )}
    </div>
  );
}

export default ResetPassword;
