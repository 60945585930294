import React from 'react';
import './CategoriesSection.scss';
import CategoryCard from '../../Atom/CategoryCard/CategoryCard';
import { catergories_card_data } from '../../../Data/Data';
function CategoriesSection() {
  return (
    <>
      <div className="categories_card_main_container">
        {catergories_card_data.map((data) => {
          return (
            <CategoryCard
              key={data.id}
              title={data.title}
              icon={data.icon}
              bgColor={data.bgColor}
              id={data.id}
              link={data.link}
            />
          );
        })}
      </div>
    </>
  );
}

export default CategoriesSection;
