import React from 'react';
import './SearchComponent.scss';
import SearchCard from '../../Atom/SearchCard/SearchCard';
function SearchComponent() {
  return (
    <div className="search_comp_content_container_main">
      <div className="search_comp_content_container">
        <h1>Strong matches the strategic gap with revenue impacting solutions</h1>
        <p>
          An independent global market research company providing business prospects & strategy
          research services for the consumer markets. An independent global market research company
          providing business prospects & strategy research services for the consumer markets. An
          independent global market research company providing business prospects & strategy
          research services for the consumer markets.
        </p>
      </div>
      <div className="search_div">
        <SearchCard />
      </div>
    </div>
  );
}

export default SearchComponent;
