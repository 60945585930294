import React, { useEffect, useState } from 'react';
import './DashBoard.scss';
import { useAuthUser } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, CircularProgress, LinearProgress, Paper } from '@mui/material';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import {
  Cart,
  Card,
  Plus,
  BiSolidKeyIcon,
  BiDollarIcon,
  EmailIcon,
  UserIdIcon,
  ExcelIcon,
} from '../../../Asset/Icons/Icons';

import { CALL_GET_EXCEL, CALL_USER_DETAILS } from '../../../API/ApiCall';
import { Link } from 'react-router-dom';
import { AuthenticationAction } from '../../../Redux/Actions/Action';
import { useDispatch } from 'react-redux';
import { convertToCSV } from '../../../Helper/Helper';
function DashBoard() {
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [DataType, setDataType] = useState([]);
  const [selectedTab, setSelectedTab] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [planLoader, setPlanLoader] = useState(true);
  const [userPlan, setUserPlan] = useState({
    amount: 0,
    plan: 'Free',
    perRecord: 0,
    freeTrials: 100,
    userDownloads: [],
    userDownloadBulkData: [],
  });

  const auth = useAuthUser();

  const handleCopy = (message, val) => {
    let snackData = {
      severity: 'success',
      message: message,
      open: true,
    };
    dispatch(AuthenticationAction.setSnackBarValues(snackData));
    navigator.clipboard.writeText(val);
  };

  const handleCopyKey = (message) => {
    let snackData = {
      severity: 'success',
      message: message,
      open: true,
    };
    dispatch(AuthenticationAction.setSnackBarValues(snackData));
    navigator.clipboard.writeText(auth().api_key);
  };

  const getThePlanDetails = async () => {
    const res = await CALL_USER_DETAILS(auth().userId, auth().api_key);

    const userData = {
      amount: res.amount,
      userDownloads: res.downloadedStamps,
      userDownloadBulkData: res.downloadedStampsBulk,
      freeTrials: res.freeTrial,
      perRecord: res.perRecord,
      plan: res.plan,
    };

    if (res.downloadedStamps.length > res.downloadedStampsBulk.length) {
      setDataType(res.downloadedStamps);
      setSelectedTab(false);
    } else if (res.downloadedStampsBulk.length > res.downloadedStamps.length) {
      setDataType(res.downloadedStampsBulk);
      setSelectedTab(true);
    } else if (res.downloadedStamps.length === 0 && res.downloadedStampsBulk.length === 0) {
      setDataType([]);
      setSelectedTab(true);
    }

    setUserPlan(userData);
    // dispatch(AuthenticationAction.updateUserPlan(userData));
    setPlanLoader(false);
  };
  const handleDataType = (val, selected) => {
    setDataType(val);
    setSelectedTab(selected);
  };
  const convertToExcel = (csvData) => {
    const jsonData = Papa.parse(csvData, { header: true }).data;
    const ws = XLSX.utils.json_to_sheet(jsonData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelBlob = new Blob([excelBuffer], {
      type: 'application/octet-stream',
    });
    const fileName = 'Data_Api_Connect.xlsx';

    const link = document.createElement('a');
    link.href = URL.createObjectURL(excelBlob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleExcelDownload = async (data, dataType) => {
    let snackData = {
      severity: 'warning',
      message: 'Please wait a moment; the download of the Excel file may take some time.',
      open: true,
    };
    dispatch(AuthenticationAction.setSnackBarValues(snackData));
    setDownloadLoader(true);
    const res = await CALL_GET_EXCEL(data, auth().userId, auth().api_key);
    const excelData = convertToCSV(res.data, dataType);
    if (res.status === true) {
      let snackData = {
        severity: 'success',
        message: 'Excel sheet downloaded successfully.',
        open: true,
      };
      dispatch(AuthenticationAction.setSnackBarValues(snackData));
    } else if (res.status === false) {
      let snackData = {
        severity: 'error',
        message: 'Something went wrong. Please try again.',
        open: true,
      };
      dispatch(AuthenticationAction.setSnackBarValues(snackData));
    }
    convertToExcel(excelData);
    setDownloadLoader(false);
  };

  useEffect(() => {
    getThePlanDetails();
  }, []);

  const NavigateToApi = () => {
    navigate('/api');
  };

  return (
    <div className="dashboard_container">
      <div className="user_keys_credit">
        <Paper className="paper_container_api_key" elevation={3}>
          <div className="icon_div color_01">{BiSolidKeyIcon}</div>
          <div className="key_container">
            <h3>Api Key</h3>
            <p title={auth().api_key}>{auth().api_key}</p>
            <IconButton
              onClick={() => handleCopyKey('Api key copied successfully.')}
              aria-label="delete"
              size="large">
              <ContentCopyIcon />
            </IconButton>
          </div>
        </Paper>
        <Paper elevation={3} className="credit_container">
          <div className="icon_div color_02">{BiDollarIcon}</div>
          <div className="key_container">
            <h3>Your Credits</h3>
            {planLoader ? (
              <CircularProgress color="success" size="1rem" />
            ) : (
              <p>${userPlan.amount.toFixed(2)}</p>
            )}
          </div>
        </Paper>
      </div>

      <div className="user_download_container">
        <Paper elevation={3} className="user_profile">
          <Paper className="top_paper" elevation={2}>
            <h3>PROFILE</h3>
          </Paper>
          <div className="user_details_div">
            <div className="common_div">
              <section className="icon_section_01"> {EmailIcon}</section>
              <section className="content_section">
                <h5>Email</h5>
                <p>{auth().email}</p>
              </section>
            </div>
            <div className="common_div">
              <section className="icon_section_02"> {UserIdIcon}</section>
              <section className="content_section">
                <h5>User Id</h5>
                <p>{auth().userId}</p>
              </section>
            </div>
          </div>
        </Paper>
        <Paper elevation={3} className="user_plan">
          <Paper className="top_paper" elevation={2}>
            <h3>PLAN INFORMATION</h3>
          </Paper>
          {planLoader ? (
            <CircularProgress className="plan_loader_info" color="success" size="3rem" />
          ) : (
            <div className="user_details_div">
              <div className="common_div">
                <section className="icon_section_01"> {Cart}</section>
                <section className="content_section">
                  <h5>Your Plan</h5>
                  <p>{userPlan.plan}</p>
                </section>
              </div>
              <div className="common_div">
                <section className="icon_section_02"> {Card}</section>
                <section className="content_section">
                  <h5>Price Per Record</h5>
                  <p>{userPlan.perRecord}$/record</p>
                </section>
              </div>
              <div className="common_div">
                <section className="icon_section_03"> {Plus}</section>
                <section className="content_section">
                  <h5>Minimum records</h5>
                  <p>{userPlan.freeTrials}</p>
                </section>
              </div>
            </div>
          )}
        </Paper>
        <Paper elevation={3} className="user_data_downloads">
          <Paper className="top_paper" elevation={2}>
            <h3>MY DOWNLOADS</h3>
          </Paper>
          {DataType?.length <= 0 ? (
            <></>
          ) : (
            <>
              <div className="use_api">
                <Button onClick={() => NavigateToApi()} variant="contained">
                  How to use api.
                </Button>
                <div className="tab_buttons_container">
                  <Button
                    disabled={userPlan.userDownloads.length < 0}
                    onClick={() => handleDataType(userPlan.userDownloads, false)}
                    variant="outlined"
                    className={`${!selectedTab ? 'color_class' : ''}`}>
                    Premium Data
                  </Button>
                  <Button
                    disabled={userPlan.userDownloadBulkData.length < 0}
                    onClick={() => handleDataType(userPlan.userDownloadBulkData, true)}
                    variant="outlined"
                    className={`${selectedTab ? 'color_class' : ''}`}>
                    Bulk Data
                  </Button>
                </div>
              </div>
            </>
          )}
          {planLoader ? (
            <LinearProgress className="plan_loader_info" color="success" size="3rem" />
          ) : (
            <div className="downloades_data_apis">
              {DataType?.length <= 0 ? (
                <div className="download_instruction">
                  <h2>No Downloads Available</h2>
                  <p>
                    We're sorry, but it seems like you don't have any downloads available at the
                    moment.
                  </p>
                  <p>
                    If you believe this is a mistake or if you have any questions, please contact
                    our support team.
                  </p>
                  <Link to="/viewer">
                    <Button variant="contained" className="redirect-button">
                      DOWNLOAD NOW !
                    </Button>
                  </Link>
                </div>
              ) : (
                <>
                  {DataType.map((data) => {
                    return (
                      <>
                        <Paper key={data} elevation={2} className="apis_info">
                          <Button variant="contained" className="date_button" disabled={true}>
                            {data.substring(0, 4)}/{data.substring(4, 6)}/{data.substring(6, 8)}
                          </Button>
                          <p
                            title={`https://peoplesdata.databoxmarketresearch.com/api/controller?timestamp=${data}&userId=${
                              auth().userId
                            }`}>
                            https://peoplesdata.databoxmarketresearch.com/api/controller?timestamp=
                            {data}&userId={auth().userId}
                          </p>
                          <Button
                            onClick={() =>
                              handleCopy(
                                'Api copied successfully.',
                                `https://peoplesdata.databoxmarketresearch.com/api/controller?timestamp=${data}&userId=${
                                  auth().userId
                                }`
                              )
                            }
                            className="copy_button"
                            variant="contained">
                            Copy
                          </Button>
                          <Button
                            disabled={downloadLoader}
                            onClick={() => handleExcelDownload(data, selectedTab)}
                            variant="contained"
                            className="excel_button">
                            {downloadLoader ? (
                              <CircularProgress color="success" size="1.5rem" />
                            ) : (
                              <div className="icons_div">{ExcelIcon} Download</div>
                            )}
                          </Button>
                        </Paper>
                      </>
                    );
                  })}
                </>
              )}
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
}

export default DashBoard;
