import React, { useState, useEffect } from 'react';
import './BuyReport.scss';
import { Button, Paper, TextField, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import SnackBar from '../../Molecule/SnackBar/SnackBar';
import emailjs from '@emailjs/browser';
import { currencies, reportsData } from '../../../Data/Data';
const InitialCountryData = [
  { value: 'USA', label: 'United States of America' },
  { value: 'CAN', label: 'Canada' },
  { value: 'GBR', label: 'United Kingdom' },
  { value: 'AUS', label: 'Australia' },
  { value: 'DEU', label: 'Germany' },
  { value: 'FRA', label: 'France' },
  { value: 'JPN', label: 'Japan' },
  { value: 'CHN', label: 'China' },
  { value: 'IND', label: 'India' },
  { value: 'BRA', label: 'Brazil' },
  { value: 'RUS', label: 'Russia' },
  { value: 'ZAF', label: 'South Africa' },
  { value: 'NGA', label: 'Nigeria' },
  { value: 'MEX', label: 'Mexico' },
  { value: 'ARG', label: 'Argentina' },
  { value: 'ITA', label: 'Italy' },
  { value: 'ESP', label: 'Spain' },
  { value: 'KOR', label: 'South Korea' },
];
function BuyReport() {
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [reportTitle, setReportTitle] = useState('');
  const [message, setMessage] = useState('');
  const { type, id, result_id } = useParams();
  const [filteredCountries, setFilteredCountries] = useState(InitialCountryData);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    companyName: '',
    jobTitle: '',
    message: '',
    country: { label: '', value: '' },
    contactNumber: '',
  });

  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    // Clear the corresponding error when user starts typing
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [id]: '',
    }));

    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };
  const handleAutoChange = (event, newValue) => {
    const value = newValue;
    setFormData((val) => ({ ...val, country: { label: value } }));
  };
  const handleFilterCountries = (event) => {
    const inputValue = event.target.value.toLowerCase();

    if (inputValue.length < 2) {
      return;
    }

    const filteredData = currencies.filter((item) => item.label.toLowerCase().includes(inputValue));
    setFilteredCountries(filteredData);
  };
  const handleButtonClick = async () => {
    // Add your validation logic here
    const errors = {};
    console.log(formData.country, 'checl01');
    if (!formData.fullName.trim()) {
      errors.fullName = 'Full Name is required';
    }

    if (!formData.country.label) {
      errors.country = 'Country Name is required';
    }
    if (!formData.companyName) {
      errors.companyName = 'Company Name is required';
    }
    if (!formData.jobTitle) {
      errors.jobTitle = 'Job Title is required';
    }
    if (!formData.message) {
      errors.message = 'Message is required';
    } else if (formData.message.length < 10 || formData.message.length > 300) {
      errors.message = 'Message must be between 10 and 300 characters in length';
    }
    if (!formData.contactNumber.trim()) {
      errors.contactNumber = 'contact number Name is required';
    } else {
      // Define a regular expression for a valid phone number with or without the '+'
      const phoneRegex = /^(\+\d{1,2})?\d{10}$/;

      // Test the phone number against the regular expression
      if (!phoneRegex.test(formData.contactNumber.trim())) {
        errors.contactNumber = 'Invalid phone number';
      }
    }
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = 'Invalid email format';
    }
    //  else if (!formData.email.endsWith("@example.com")) {
    //   errors.email = "Only official emails from example.com are allowed";
    // }

    // Add more validations for other fields

    if (Object.keys(errors).length === 0) {
      setLoader(true);
      try {
        // Use emailjs to send the email
        const templateParams = {
          to_email: 'recipient@example.com', // Replace with your recipient email
          from_name: formData.fullName,
          email: formData.email,
          phone_number: formData.contactNumber,
          country: formData?.country?.label?.label,
          company: formData.companyName,
          job_title: formData.jobTitle,
          report_name: result_id,
          report_type: type,
          message: formData.message,
        };

        // Replace 'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', and 'YOUR_USER_ID' with your own values
        const response = await emailjs.send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          templateParams,
          process.env.REACT_APP_PUBLIC_KEY
        );

        console.log('Email sent successfully:', response);
        setOpen(true);
        setMessage('Message sent successfully.');
        setFormData({
          fullName: '',
          email: '',
          companyName: '',
          jobTitle: '',
          message: '',
          country: { label: '', value: '' },
          contactNumber: '',
        });
      } catch {
        setOpen(true);
        setMessage('Message sent successfully.');
        setFormData({
          fullName: '',
          email: '',
          companyName: '',
          jobTitle: '',
          message: '',
          country: { label: '', value: '' },
          contactNumber: '',
        });
      }

      setOpen(true);
      setMessage('Message sent successfully.');
    } else {
      // If there are errors, update the state to show them
      setFormErrors(errors);
    }
    setLoader(false);
    console.log(formData, 'form data');
  };
  useEffect(() => {
    const foundReport = reportsData.find((data) => data.id === id);

    if (foundReport.industry) {
      const foundIdn = foundReport.industry.find((idn) => idn.id === result_id);

      if (foundIdn) {
        setReportTitle(foundIdn.report_title);
      }
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [reportsData, id, result_id]);
  return (
    <div className="buy_a_report_container">
      <SnackBar open={open} setOpen={setOpen} message={message} type="success" />
      <div className="main_report_container">
        <div className="heading_div">
          <h2> {type}</h2>
          <h1>{reportTitle}</h1>
        </div>
        <div className="form_div">
          <Paper className="form_paper" elevation={3}>
            <div className="input_container">
              <TextField
                className="text_field_input"
                required={true}
                id="fullName"
                label="Full Name"
                variant="outlined"
                value={formData.fullName}
                onChange={handleInputChange}
                error={!!formErrors.fullName}
                helperText={formErrors.fullName}
              />
              <TextField
                className="text_field_input"
                required={true}
                id="email"
                label="Email"
                variant="outlined"
                value={formData.email}
                onChange={handleInputChange}
                error={!!formErrors.email}
                helperText={formErrors.email}
              />
              <TextField
                className="text_field_input"
                required={true}
                id="companyName"
                label="Company Name"
                variant="outlined"
                value={formData.companyName}
                onChange={handleInputChange}
                error={!!formErrors.companyName}
                helperText={formErrors.companyName}
              />
              <TextField
                className="text_field_input"
                required={true}
                id="jobTitle"
                label="Job Title"
                variant="outlined"
                value={formData.jobTitle}
                onChange={handleInputChange}
                error={!!formErrors.jobTitle}
                helperText={formErrors.jobTitle}
              />
              <TextField
                className="text_field_input"
                required={true}
                id="contactNumber"
                label="Contact Number"
                variant="outlined"
                value={formData.contactNumber}
                onChange={handleInputChange}
                error={!!formErrors.contactNumber}
                helperText={formErrors.contactNumber}
              />
              <Autocomplete
                className="MuiFormControl-root MuiTextField-root css-1u3bzj6-MuiFormControl-root-MuiTextField-root"
                id="countryId"
                limitTags={10}
                value={formData.country.label || null}
                options={filteredCountries}
                getOptionLabel={(option) => option.label}
                defaultValue={null}
                onChange={handleAutoChange}
                renderInput={(params) => (
                  <TextField
                    required={true}
                    placeholder="Search Country"
                    {...params}
                    label="Country"
                    variant="outlined"
                    error={!!formErrors.country}
                    helperText={formErrors.country}
                    onChange={(e) => handleFilterCountries(e)}
                  />
                )}
                renderTags={(value, getTagProps) => <p>{value}</p>}
              />
            </div>
            <div className="text_area">
              <TextField
                className="text_area_input"
                required={true}
                id="message"
                label="Message"
                variant="outlined"
                multiline
                rows={4}
                value={formData.message}
                onChange={handleInputChange}
                error={!!formErrors.message}
                helperText={formErrors.message}
              />
            </div>
            <div className="button_div">
              <Button disabled={loader} variant="contained" onClick={handleButtonClick}>
                {loader ? <CircularProgress color="success" /> : ' Send Message'}
              </Button>
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
}

export default BuyReport;
