import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AuthenticationAction } from "../../../Redux/Actions/Action";
import { CALL_CONFIRM_EMAIL_ID, CALL_RESEND_API } from "../../../API/ApiCall";
import { Button, CircularProgress } from "@mui/material";
import "./ConfirmEmailComponent.scss";
function ConfirmEmailComponent() {
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const uid = searchParams.get("uid");
  const token = searchParams.get("token");

  const handleResend = async () => {
    const userEmail = localStorage.getItem("userEmail");
    let response = await CALL_RESEND_API(userEmail);
    if (response?.status === 200) {
      let snackData = {
        severity: "success",
        message:
          "Email sent successfully! Please check your inbox and verify your email address to complete the registration process.",
        open: true,
      };

      dispatch(AuthenticationAction.setSnackBarValues(snackData));
      navigate("/confirm-email");
    } else if (response?.status) {
      let snackData = {
        severity: "error",
        message: response?.message,
        open: true,
      };
      dispatch(AuthenticationAction.setSnackBarValues(snackData));
    }
  };

  useEffect(() => {
    const confirm_email = async () => {
      const res = await CALL_CONFIRM_EMAIL_ID(uid, token);

      if (res === 200) {
        navigate("/login");
      } else {
      }
    };
    if (
      uid === undefined ||
      uid === null ||
      token === undefined ||
      token === null
    ) {
      setLoader(false);
    } else {
      confirm_email();
    }
  }, []);

  return (
    <div className='confirm_email_div'>
      {loader ? (
        <CircularProgress size='5rem' />
      ) : (
        <div className='resend_email'>
          <h2>
            An email has been sent to your registered email address. Kindly
            check your inbox to confirm it.
          </h2>
          <Button onClick={() => handleResend()} variant='contained'>
            Resend Email
          </Button>
        </div>
      )}
    </div>
  );
}

export default ConfirmEmailComponent;
