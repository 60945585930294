import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import "./ActionButton.scss";
function ActionButton({ title, link, icon }) {
  return (
    <div className='action_button_container'>
      <Link to={link}>
        <Button startIcon={icon}>{title}</Button>
      </Link>
    </div>
  );
}

export default ActionButton;
