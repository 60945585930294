import React, { useState, useEffect } from "react";
import "./App.css";
import { useSelector } from "react-redux";
import Template from "./View/Template/Template";
import SnackBar from "./View/Atom/SnackBar/SnackBar";
import CircularProgress from "@mui/material/CircularProgress";

function App() {
  const [loading, setLoading] = useState(true);
  const { message, open, severity } = useSelector(
    (state) => state.userLoggIn.snackBarVal
  );

  useEffect(() => {
    // Simulating an asynchronous operation (e.g., data fetching) here
    const fetchData = async () => {
      // Perform your async operations here
      // For example, fetch data from an API

      // After the data is fetched, set loading to false
      setLoading(false);
    };

    fetchData(); // Call the async function
  }, []); // Empty dependency array ensures that this effect runs once after the initial render

  return (
    <div className='App'>
      {loading ? ( // If loading is true, show CircularProgress, else show your content
        <CircularProgress style={{ margin: "auto" }} />
      ) : (
        <>
          <SnackBar message={message} open={open} severity={severity} />
          <Template />
        </>
      )}
    </div>
  );
}

export default App;
