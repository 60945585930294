import React, { useEffect } from 'react';
import './PrivacyPolicy.scss';
import SearchComponent from '../../Molecule/SearchComponent/SearchComponent';
import FaqComp from '../../Molecule/FaqComp/FaqComp';
const policises = [
  'Our market research firm, Insight Analytics, delivers comprehensive insights into global market trends, providing detailed analyses across various sectors. The meticulously crafted reports are intended for exclusive use by our global clients, including individual business professionals, enterprises, and institutions.',
  'Our reports, developed with expert analysis, are strictly for internal client use and are not to be shared with third parties. Insight Analytics disclaims responsibility for any inaccuracies or inappropriate data provided by global enterprises, industry participants, or stakeholders.',
  'Quantitative information is gathered through extensive interviews with C-level professionals, making it subject to potential fluctuations. The confidentiality of global market report data is paramount; hence, it will not be delivered, rented, resold, or disclosed to third parties without authentic written permissions.',
  'To ensure the integrity and authenticity of valuable information, no sections or parts of the reports will be retransmitted to third parties without explicit authorization.',
  'Remember to customize this template to align with the specific practices and policies of your market research company.',
];
function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="privacy_policy_container">
      <SearchComponent />
      <div className="content_div">
        <h1>Privacy Policy</h1>
        <ul className="policies_container">
          {policises.map((data, index) => {
            return <li key={index}>{data}</li>;
          })}
        </ul>{' '}
        <FaqComp />
      </div>
    </div>
  );
}

export default PrivacyPolicy;
