import React, { useState } from 'react';
import { Drawer, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Logo from '../../../Asset/img/App_Logo/LogoAPi.png';

import { HamBugeerMenu, LogOut } from '../../../Asset/Icons/Icons';
import './DrawerSide.scss';
import { useSignOut } from 'react-auth-kit';
import { useIsAuthenticated } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';
import { drop_down_data, drop_down_data_login } from '../../../Data/Data';

function DrawerSide() {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const isAuthenticated = useIsAuthenticated();
  const [anchor, setAnchor] = useState(false);
  const toggleDrawer = (open) => {
    setAnchor(open);
  };
  const handleLogOut = async () => {
    // await CALL_LOG_OUT_API();
    navigate('/login');
    signOut();
  };
  return (
    <div className="drawer_container">
      <Button className="drawer_button" onClick={() => toggleDrawer(true)}>
        {HamBugeerMenu}
      </Button>
      <Drawer anchor="left" open={anchor} onClose={() => toggleDrawer(false)}>
        <div className="drawer_main_container">
          <div className="upper">
            <div className="logo_container">
              <img alt="logo" src={Logo} />
              <h1 className="logo_text">Data Api Connect</h1>
            </div>
            <div onClick={() => toggleDrawer(false)} className="nav_container">
              {isAuthenticated() ? (
                <>
                  {drop_down_data_login.map((data, index) => {
                    return (
                      <Link key={index} className="link" to={data.link}>
                        <div className="icon"> {data.icon}</div>
                        <span> {data.title}</span>
                      </Link>
                    );
                  })}
                </>
              ) : (
                <>
                  {drop_down_data.map((data, index) => {
                    return (
                      <Link key={index} className="link" to={data.link}>
                        <div className="icon"> {data.icon}</div>
                        <span> {data.title}</span>
                      </Link>
                    );
                  })}
                </>
              )}
              {isAuthenticated() ? (
                <Button
                  onClick={() => handleLogOut()}
                  className="log_out_button"
                  variant="outlined"
                  startIcon={LogOut}>
                  Log Out
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="lower">
            <h6 className="title">&copy; Copyright 2023 databoxmarketresearch.com - 2017</h6>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default DrawerSide;
