import React from 'react';

import { useNavigate } from 'react-router-dom';

import Background from '../../Molecule/Bakground/Background';
import { latest_reports_items } from '../../../Data/Data';
import './DataCompliance.scss';

import { Paper } from '@mui/material';
import { handleNavigation } from '../ReportDetails/ReportDetails';
import { ReportIcon } from '../../../Asset/Icons/Icons';
const data = {
  title: 'Empower Your Real Estate Journey with Precision Data and Tailored Solutions',
  description:
    'Unlock the full potential of your real estate endeavors with our comprehensive platform, catering to Mortgage Professionals, Technology Services, Residential Home Services, Real Estate Investors, Residential Realtors, Title Companies, Vendors & Contractors. From accurate mortgage information to real estate investment insights, our user-friendly hub streamlines transactions, simplifies complexities, and maximizes your investments. Join a community of empowered professionals and elevate your real estate experience. Start transforming your goals today. ',
};
function DataCompliance() {
  const navigate = useNavigate();
  return (
    <div className="data_compliance_Container">
      <div className="background_contaier">
        <Background title={data.title} description={data.description} />
      </div>
      {/* <div className='content_container'>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          ></AccordionSummary>
          <AccordionDetails>
            <CategoriesSection />
          </AccordionDetails>
        </Accordion>
      </div> */}
      <div className="cards_container">
        {latest_reports_items.map((data) => {
          return (
            <Paper
              onClick={() => handleNavigation(navigate, data.typeId, data.reportId)}
              className="paper_div"
              key={data.id}
              elevation={3}>
              <Paper className={`svg_paper background_color_0${3}`}>{ReportIcon}</Paper>
              <div className="description_div">
                <h2>{data.title}</h2>
                <p>{data.description}</p>
              </div>
            </Paper>
          );
        })}
      </div>
    </div>
  );
}

export default DataCompliance;
