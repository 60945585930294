import React from 'react';
import './FaqComp.scss';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FaqData = [
  {
    id: 'panel1',
    question: 'HOW TO ORDER?',
    title:
      'In case you are placing an order on company behalf, we expect you to have complete authorization to make the purchase decision.',
    listItems: [
      {
        list: 'To Order by Email',
        desc: 'We will require you to write about your requirements and queries on sales@databoxmarketresearch.com Our executives will promptly get in touch with you for further guidance in less than 24 hours.',
      },
      {
        list: 'TO ORDER BY PHONE',
        desc: 'Talk to us on +91-Xxx  (24 hours) to place an order instantly or any queries related to the purchase.',
      },
    ],
    description: [
      'At Databox Market Research the data we provide is in the following format – PDF. In case of any other specific formats required, you can reach out to us and depending on the feasibility we can assist you further.',
      'Delivery Period – 5 to 7 Business days.',
      'In case of any changes in requirements or specific updates from the client side, the delivery time frame can exceed until the appropriate sign off.',
    ],
  },
  {
    id: 'panel2',
    question: 'How Can I Make A Payment?',
    title: 'We offer various payment modes for your convenience:',
    listItems: [
      {
        list: 'Bank wire transfer.',
        desc: '',
      },
      {
        list: 'Online payment with Visa, MasterCard, or American Express credit cards.',
        desc: '',
      },
      {
        list: 'PayPal.',
        desc: '',
      },
    ],
    description: [],
  },
  {
    id: 'panel3',
    question: 'Difference Between User Licenses?',
    title: 'We offer three user license as per your convenience:',
    listItems: [
      {
        list: 'Single User: A Single User License permits the circulation of the report copy to a single user only.',
        desc: '',
      },
      {
        list: 'Multi User: A Multi-User License allows circulation to up to five users.',
        desc: '',
      },
      {
        list: 'Enterprise/Corporate User: A Corporate License enables the circulation of the report copy across the entire organization, including any subsidiaries or sister concerns of the organization.',
        desc: '',
      },
    ],
    description: [],
  },
  {
    id: 'panel4',
    question: 'Format of Our Research Report?',
    title: 'Our reports are available in various formats to cater to diverse preferences:',
    listItems: [
      {
        list: 'Word document.',
        desc: '',
      },
      {
        list: ' PDF.',
        desc: '',
      },
      {
        list: 'PPT (Depending on the nature of the report).',
        desc: '',
      },
      {
        list: 'Market Estimation Sheet.',
        desc: '',
      },
    ],
    description: [],
  },
  {
    id: 'panel5',
    question: 'Can I purchase individual sections of report? How?',
    title:
      'Individual sections of a report are available for purchase. For assistance, please contact our sales/customer service team.',
    listItems: [],
    description: [],
  },
  {
    id: 'panel6',
    question: 'Will I get a receipt of my purchase?',
    title:
      'Upon successful purchase and receipt of payment, the transaction receipt will be promptly sent to you via email.',
    listItems: [],
    description: [],
  },
];

function FaqComp() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className="faq_comp_container">
      <div>
        {FaqData.map((data) => {
          return (
            <Accordion expanded={expanded === data.id} onChange={handleChange(data.id)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={data.id + '-content'}
                id={data.id + '-header'}>
                <Typography className="title" sx={{ flexShrink: 1 }}>
                  {data.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="subtitle">{data.title}</Typography>
                {data?.listItems.length > 0 ? (
                  <Typography className="subtitle">
                    <ul>
                      {data?.listItems.map((item, index) => {
                        return (
                          <div key={index}>
                            <li>{item.list}</li>
                            <p>{item.desc}</p>
                          </div>
                        );
                      })}
                    </ul>
                  </Typography>
                ) : (
                  <></>
                )}
                {data.description.map((item, index) => {
                  return (
                    <Typography key={index} className="subtitle">
                      {item}
                    </Typography>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
}

export default FaqComp;
