import React from 'react';
import './Methodology.scss';
import {
  AdvertiseIcon,
  BulbIcon,
  SearchIcon,
  ChartIcon,
  TargetIcons,
} from '../../../../Asset/Icons/Icons.js';
import SearchComponent from '../../../Molecule/SearchComponent/SearchComponent';
import { Paper } from '@mui/material';
const ContentData = [
  {
    icon: BulbIcon,
    title: 'Primary Research: Interviewing the Trade Sources',
    description:
      'After the completion of the first stage, an in-depth analysis of the congregated data is carried out by comparing primary research to secondary. The research team then conducts various interviews with the people in the industry and are witnessing the changing trends.',
  },
  {
    icon: SearchIcon,
    title: 'Secondary Research: An information procurement technique',
    description:
      'After the completion of the first stage, an in-depth analysis of the congregated data is carried out by comparing primary research to secondary. The research team then conducts various interviews with the people in the industry and are witnessing the changing trends.',
  },
  {
    icon: ChartIcon,
    title: 'Information Analysis',
    description:
      'Analyzing and planning of all the information acquired from the previous one is done in this step, it also recognizes the analysis of data discrepancy observed across various data sources.',
  },
  {
    icon: TargetIcons,
    title: 'Market Origination',
    description:
      'The placement of data points is required at an applicable market space in a shot to interpret feasible implications. Analyst outlook and subject matter expert based on pragmatic form of market sizing also plays a vital role.',
  },
  {
    icon: AdvertiseIcon,
    title: 'Endorsement and Publishing',
    description:
      'Each and every report goes under a comprehensive quality scrutiny in the editing procedure before getting published, which is conducted by highly skilled management team to ensure the relevancy and accuracy of the published data.“You can trust us for research is our core etiquette”.',
  },
];

function Methodology() {
  return (
    <div className="methodology_container">
      <SearchComponent />
      <h1>Research Methodology</h1>
      <div className="research_container">
        {ContentData.map((data, index) => (
          <Paper elevation={3} key={index} className="right_content">
            <div className="svg_div">{data.icon}</div>
            <div className="content">
              <h3>{data.title}</h3>
              <p>{data.description}</p>
            </div>
          </Paper>
        ))}
      </div>
    </div>
  );
}

export default Methodology;
