import React from 'react';
import './CategoryCard.scss';
import { Paper } from '@mui/material';

import { Link } from 'react-router-dom';

function CategoryCard({ key, title, icon, bgColor, id, link }) {
  return (
    <Link to={link}>
      <div className="ctargories_card_container">
        <Paper className="paper_card" elevation={3}>
          <Paper className={`svg_paper background_color_0${bgColor}`} elevation={3}>
            {icon}
          </Paper>
          <h4>{title}</h4>
        </Paper>
      </div>
    </Link>
  );
}

export default CategoryCard;
