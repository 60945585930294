import React from 'react';
import './Template.scss';
import AppNavBar from '../Molecule/NavBar/AppNavBar';
import Footer from '../Page/Footer/Footer';
import { Route, Routes } from 'react-router';

import LogInPage from '../Page/LogIn/LogInPage';
import SignUpComp from '../Page/SignUp/SignUpComp';
import HomePage from '../Page/HomePage/HomePage';
import Pricing from '../Page/Pricing/Pricing';
import DataPage from '../Page/DataComplePage/DataPage';
import DataTransparency from '../Page/DataTransparency/DataTransparency';
import DataLake from '../Page/DataLake/DataLake';
import DataPoint from '../Page/DataPoint/DataPoint';
import DataCompliance from '../Page/DataCompliance/DataCompliance';
import PrivateRoute from './PrivateRoute';
import DashBoard from '../Page/DashBoard/DashBoard';
import ConfirmEmailComponent from '../Page/ConfirmEmail/ConfirmEmailComponent';
import Terms from '../Page/Terms/Terms';
import PrivacyPolicy from '../Page/PrivacyPolicy/PrivacyPolicy';
import UseCases from '../Page/UseCases/UseCases';
import ContactUs from '../Page/ContactUs/ContactUs';

import ResetPassword from '../Page/ResetPassword/ResetPassword';
import NotFound from '../Page/NotFound/NotFound';
import AfterPaymnet from '../Page/AfterPaymnet/AfterPaymnet';
import Report from '../Page/Reports/Report';
import ReportDetails from '../Page/ReportDetails/ReportDetails';
import SearchReport from '../Page/SearchReport/SearchReport';
import BuyReport from '../Page/BuyReport/BuyReport';
import Methodology from '../Page/AboutUs/Methodology/Methodology';
import OverView from '../Page/AboutUs/Overview/OverView';

// const ReportDetails = () => {
//   return <div style={{ backgroundColor: 'blue' }}>Report Details Component</div>;
// };

// const SearchReport = () => {
//   return <div style={{ backgroundColor: 'green' }}>Search Report Component</div>;
// };

// const BuyReport = () => {
//   return <div style={{ backgroundColor: 'yellow' }}>Buy Report Component</div>;
// };

function Template() {
  return (
    <div className="template_container">
      <AppNavBar />
      <Routes>
        {/* <Route path="/login" element={<LogInPage />} />
        <Route path="/sign up" element={<SignUpComp />} /> */}
        <Route path="/" element={<HomePage />} />
        <Route path="/pricing" element={<Pricing />} />
        {/* <Route
          path='/viewer'
          element={
            <RequireAuth loginPath={"/login"}>
              <DataPage />
            </RequireAuth>
          }
        ></Route> */}
        <Route element={<PrivateRoute />}>
          <Route path="/viewer" element={<DataPage />}></Route>
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/success/:id" element={<AfterPaymnet />}></Route>
        </Route>
        <Route path="/datatransparency" element={<DataTransparency />} />
        <Route path="/datalake" element={<DataLake />} />
        <Route path="/datapoint" element={<DataPoint />} />
        <Route path="/latest_Reports" element={<DataCompliance />} />
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<DashBoard />}></Route>
        </Route>
        <Route path="/confirm-email" element={<ConfirmEmailComponent />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        {/* <Route path="/usecases" element={<UseCases />} /> */}
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/research_methodology" element={<Methodology />} />
        <Route path="/overview" element={<OverView />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        <Route path="/report/:id" element={<Report />} />
        <Route path="/report/:id/:result_id" element={<ReportDetails />} />
        <Route path="/search/:search" element={<SearchReport />} />
        <Route path="/buy_report/:type/:id/:result_id" element={<BuyReport />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default Template;
