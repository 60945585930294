import React, { useState } from 'react';
import './ContactForm.scss';
import { currencies } from '../../../Data/Data';
import { Button, Paper, TextField, FormControl, CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import SnackBar from '../../../View/Atom/SnackBar/SnackBar';
import emailjs from '@emailjs/browser';
const InitialCountryData = [
  { value: 'USA', label: 'United States of America' },
  { value: 'CAN', label: 'Canada' },
  { value: 'GBR', label: 'United Kingdom' },
  { value: 'AUS', label: 'Australia' },
  { value: 'DEU', label: 'Germany' },
  { value: 'FRA', label: 'France' },
  { value: 'JPN', label: 'Japan' },
  { value: 'CHN', label: 'China' },
  { value: 'IND', label: 'India' },
  { value: 'BRA', label: 'Brazil' },
  { value: 'RUS', label: 'Russia' },
  { value: 'ZAF', label: 'South Africa' },
  { value: 'NGA', label: 'Nigeria' },
  { value: 'MEX', label: 'Mexico' },
  { value: 'ARG', label: 'Argentina' },
  { value: 'ITA', label: 'Italy' },
  { value: 'ESP', label: 'Spain' },
  { value: 'KOR', label: 'South Korea' },
  { value: 'EGY', label: 'Egypt' },
  { value: 'SAU', label: 'Saudi Arabia' },
  { value: 'IDN', label: 'Indonesia' },
  { value: 'TUR', label: 'Turkey' },
  { value: 'THA', label: 'Thailand' },
  { value: 'POL', label: 'Poland' },
  { value: 'NLD', label: 'Netherlands' },
  { value: 'CHE', label: 'Switzerland' },
  { value: 'SWE', label: 'Sweden' },
  { value: 'NGA', label: 'Nigeria' },
  { value: 'AUT', label: 'Austria' },
  { value: 'BEL', label: 'Belgium' },
  { value: 'DNK', label: 'Denmark' },
  { value: 'FIN', label: 'Finland' },
  { value: 'GRC', label: 'Greece' },
  { value: 'HUN', label: 'Hungary' },
  { value: 'IRL', label: 'Ireland' },
  { value: 'ISR', label: 'Israel' },
  { value: 'MYS', label: 'Malaysia' },
  { value: 'NZL', label: 'New Zealand' },
  { value: 'PRT', label: 'Portugal' },
  { value: 'SGP', label: 'Singapore' },
  { value: 'CZE', label: 'Czech Republic' },
  { value: 'ARE', label: 'United Arab Emirates' },
  { value: 'BGR', label: 'Bulgaria' },
  { value: 'HRV', label: 'Croatia' },
  { value: 'ROU', label: 'Romania' },
  { value: 'SRB', label: 'Serbia' },
  { value: 'SVK', label: 'Slovakia' },
  { value: 'SVN', label: 'Slovenia' },
  { value: 'UKR', label: 'Ukraine' },
  // Add more countries as needed
];

function ContactForm() {
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [filteredCountries, setFilteredCountries] = useState(InitialCountryData);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    company: '',
    jobTitle: '',
    message: '',
    contactNumber: '',
    country: '',
  });

  const [errors, setErrors] = useState({});

  const handleFilterCountries = (event) => {
    const inputValue = event.target.value.toLowerCase();

    if (inputValue.length < 2) {
      return;
    }

    const filteredData = currencies.filter((item) => item.label.toLowerCase().includes(inputValue));
    setFilteredCountries(filteredData);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: '',
    }));
  };

  const handleAutoChange = (event, newValue) => {
    const value = newValue;
    setFormData((val) => ({ ...val, country: value }));
  };

  const handleButtonClick = async () => {
    const newErrors = {};

    if (!formData.fullName.trim()) {
      newErrors.fullName = 'Full Name is required';
    }

    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }

    if (!formData.company.trim()) {
      newErrors.company = 'Company is required';
    }

    if (!formData.jobTitle.trim()) {
      newErrors.jobTitle = 'Job Title is required';
    }

    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
    } else if (formData.message.length < 10 || formData.message.length > 300) {
      newErrors.message = 'Message must be between 10 and 300 characters in length';
    }

    if (Object.keys(newErrors).length === 0) {
      setLoader(true);
      try {
        // Use emailjs to send the email
        const templateParams = {
          to_email: 'recipient@example.com', // Replace with your recipient email
          from_name: formData.fullName,
          email: formData.email,
          phone_number: formData.contactNumber,
          country: formData.country.label,
          company: formData.company,
          job_title: formData.jobTitle,
          report_name: 'From Contact Us',
          report_type: 'From Contact Us',
          message: formData.message,
        };

        // Replace 'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', and 'YOUR_USER_ID' with your own values
        const response = await emailjs.send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          templateParams,
          process.env.REACT_APP_PUBLIC_KEY
        );

        console.log('Email sent successfully:', response);
        setOpen(true);
        setMessage('Message sent successfully.');
        setFormData({
          fullName: '',
          email: '',
          company: '',
          jobTitle: '',
          message: '',
          contactNumber: '',
          country: '',
        });
      } catch (error) {
        setOpen(true);
        setMessage('Message sent successfully.');
        setFormData({
          fullName: '',
          email: '',
          company: '',
          jobTitle: '',
          message: '',
          contactNumber: '',
          country: '',
        });
      }
    } else {
      console.log('Form validation failed. Please fill in all required fields.');
      setErrors(newErrors);
      setLoader(false);
    }
  };

  return (
    <>
      <SnackBar open={open} setOpen={setOpen} message={message} type="success" />{' '}
      <div className="contact_form_contaier">
        <Paper className="form_paper" elevation={3}>
          <div className="input_container">
            <TextField
              className="text_field_input"
              id="fullName"
              label="Full Name"
              variant="outlined"
              required
              value={formData.fullName}
              onChange={handleChange}
              error={!!errors.fullName}
              helperText={errors.fullName}
            />
            <TextField
              className="text_field_input"
              id="email"
              label="Email"
              variant="outlined"
              required
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              className="text_field_input"
              id="company"
              label="Company"
              variant="outlined"
              required
              value={formData.company}
              onChange={handleChange}
              error={!!errors.company}
              helperText={errors.company}
            />
            <TextField
              className="text_field_input"
              id="jobTitle"
              label="Job Title"
              variant="outlined"
              required
              value={formData.jobTitle}
              onChange={handleChange}
              error={!!errors.jobTitle}
              helperText={errors.jobTitle}
            />
            <TextField
              className="text_field_input"
              id="contactNumber"
              label="Contact Number"
              variant="outlined"
              value={formData.contactNumber}
              onChange={handleChange}
              error={!!errors.contactNumber}
              helperText={errors.contactNumber}
            />
            <Autocomplete
              className="MuiFormControl-root MuiTextField-root css-1u3bzj6-MuiFormControl-root-MuiTextField-root"
              id="countryId"
              limitTags={10}
              value={formData.country || null}
              options={filteredCountries}
              getOptionLabel={(option) => option.label}
              defaultValue={null}
              onChange={handleAutoChange}
              renderInput={(params) => (
                <TextField
                  placeholder="Search Country"
                  {...params}
                  label="Country"
                  variant="outlined"
                  error={!!errors.country}
                  helperText={errors.country}
                  onChange={(e) => handleFilterCountries(e)}
                />
              )}
              renderTags={(value, getTagProps) => <p>{value}</p>}
            />
          </div>
          <div className="text_area">
            <textarea
              id="message"
              placeholder="Message"
              required
              value={formData.message}
              onChange={handleChange}
            />
            {errors.message && <span className="error_message">{errors.message}</span>}
          </div>
          <div className="button_div">
            <Button disabled={loader} variant="contained" onClick={handleButtonClick}>
              {loader ? <CircularProgress color="success" /> : ' Send Message'}
            </Button>
          </div>
        </Paper>
      </div>
    </>
  );
}

export default ContactForm;
