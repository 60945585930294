import React, { useEffect, useState } from "react";

import {
  FormControl,
  Paper,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  OutlinedInput,
  ListItemText,
  Checkbox,
  Button,
  FormHelperText,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { useAuthUser } from "react-auth-kit";
import "./DataForm.scss";
import { useDispatch } from "react-redux";
import { AuthenticationAction } from "../../../Redux/Actions/Action";
import { useNavigate } from "react-router-dom";
import { CALL_DATA_FILTER } from "../../../API/ApiCall";
import AlertDialog from "../AlertDialog/AlertDialog";
import { statesData } from "./dataFile";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const statesData = [
//   {
//     abbrevation: "NY",
//     name: "New York",
//     Cities: ["New York City", "Albany", "Buffalo"],
//     ZipCodes: {
//       "New York City": ["10001", "10002", "10003"],
//       Albany: ["12201", "12202", "12203"],
//       Buffalo: ["14201", "14202", "14203"],
//     },
//   },
//   {
//     abbrevation: "CA",
//     name: "California",
//     Cities: ["Los Angeles", "San Fransisco", "San Diego"],
//     ZipCodes: {
//       "Los Angeles": ["90001", "910002", "90003"],
//       "San Fransisco": ["94101", "94102", "94103"],
//       "San Diego": ["92101", "92102", "92103"],
//     },
//   },
//   {
//     abbrevation: "TX",
//     name: "California",
//     Cities: ["Los Angeles", "San Fransisco", "San Diego"],
//     ZipCodes: {
//       "Los Angeles": ["90001", "910002", "90003"],
//       "San Fransisco": ["94101", "94102", "94103"],
//       "San Diego": ["92101", "92102", "92103"],
//     },
//   },
// ];

function DataForm({ minRecord, maxRecord, plan, api_key }) {
  const auth = useAuthUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [filteredCities, setFilteredCities] = useState([]);

  const [perRecord, setPerRecord] = useState(0);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [fieldValues, setFieldValues] = useState({
    min_age: "",
    max_age: "",
    states: [],
    cities: [],
    zipCode: [],
    records: "",
  });
  const [valid, setValid] = useState({
    min_age: true,
    max_age: true,
    states: true,
    cities: true,
    zipCode: true,
    records: true,
  });
  const [errors, setErrors] = useState({
    min_age: "",
    max_age: "",
    states: "",
    cities: "",
    zipCode: "",
    records: "",
  });
  const handleFieldClear = () => {
    setFieldValues({
      min_age: "",
      max_age: "",
      states: [],
      cities: [],
      zipCode: [],
      records: "",
    });
  };
  const [openPopUp, setOpenPopUp] = useState(false);
  const [filterResponse, setFilterResponse] = useState(0);
  const handleLogIn = () => {
    // Reset error messages
    setErrors({
      min_age: "",
      max_age: "",
      states: "",
      cities: "",
      zipCode: "",
      records: "",
    });
    let checkValidation = false;
    //  Minimum number validation
    if (!fieldValues.min_age) {
      setValid((prevState) => ({ ...prevState, min_age: false }));
      setErrors((prevState) => ({
        ...prevState,
        min_age: "Minimum age is required.",
      }));
      checkValidation = true;
    } else if (fieldValues.min_age <= 20) {
      setValid((prevState) => ({ ...prevState, min_age: false }));
      setErrors((prevState) => ({
        ...prevState,
        min_age: "Minimum age should be greater than 20.",
      }));
      checkValidation = true;
    } else if (fieldValues.min_age >= 99) {
      setValid((prevState) => ({ ...prevState, min_age: false }));
      setErrors((prevState) => ({
        ...prevState,
        min_age: "Minimum age should be less than 99.",
      }));
      checkValidation = true;
    }
    //Maximum number validation
    if (!fieldValues.max_age) {
      setValid((prevState) => ({ ...prevState, max_age: false }));
      setErrors((prevState) => ({
        ...prevState,
        max_age: "Maximum age is required.",
      }));
      checkValidation = true;
    } else if (fieldValues.max_age < fieldValues.min_age) {
      setValid((prevState) => ({ ...prevState, max_age: false }));
      setErrors((prevState) => ({
        ...prevState,
        max_age: "Maximum age should not be less than minimum age.",
      }));
      checkValidation = true;
    }
    //States selection validation

    if (fieldValues.states.length <= 0) {
      setValid((prevState) => ({ ...prevState, states: false }));
      setErrors((prevState) => ({
        ...prevState,
        states: "State is required.",
      }));
      checkValidation = true;
    }
    //Number of records selection validation

    if (!fieldValues.records) {
      setValid((prevState) => ({ ...prevState, records: false }));
      setErrors((prevState) => ({
        ...prevState,
        records: "Please select a records.",
      }));
      checkValidation = true;
    } else if (fieldValues.records < minRecord) {
      setValid((prevState) => ({ ...prevState, records: false }));
      setErrors((prevState) => ({
        ...prevState,
        records: `Minimum number of records should be ${minRecord}`,
      }));
      checkValidation = true;
    } else if (fieldValues.records > maxRecord) {
      setValid((prevState) => ({ ...prevState, records: false }));
      setErrors((prevState) => ({
        ...prevState,
        records: `Maximum number of records should be ${maxRecord}`,
      }));
      checkValidation = true;
    }

    // Check if there are validation errors minRecord, maxRecord
    if (checkValidation) {
      checkValidation = false;
      return;
    }
    // *********************************************************
    //Check plan and give instructions

    if (plan === "Free") {
      if (minRecord === 0 && maxRecord === 0) {
        let snackData = {
          severity: "error",
          message: `As per ${plan} plan number of trial exceeded please upgrade the plan.`,
          open: true,
        };

        dispatch(AuthenticationAction.setSnackBarValues(snackData));
        navigate("/pricing");
        return;
      } else if (
        fieldValues.records > maxRecord ||
        fieldValues.records < minRecord
      ) {
        let snackData = {
          severity: "error",
          message: `As per ${plan} plan records should be within ${minRecord} to ${maxRecord}.`,
          open: true,
        };

        dispatch(AuthenticationAction.setSnackBarValues(snackData));

        return;
      }
    }
    let snackData = {
      severity: "warning",
      message: `Please do not refresh or change window records is downloading.`,
      open: true,
    };

    dispatch(AuthenticationAction.setSnackBarValues(snackData));
    // *********************************************************

    callApi(fieldValues);
  };

  function capitalizeFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  const callApi = async (fieldValues) => {
    const capitalizedCities = fieldValues.cities.map((city) => {
      // Split each city name into words
      const words = city.split(" ");

      // Capitalize the first letter of each word
      const capitalizedWords = words.map((word) => capitalizeFirstLetter(word));

      // Join the words back together to form the capitalized city name
      return capitalizedWords.join(" ");
    });

    const dataValues = {
      states: fieldValues.states,
      cities: capitalizedCities,
      zipCodes: fieldValues.zipCode,
      min_age: parseInt(fieldValues.min_age),
      max_age: parseInt(fieldValues.max_age),
      userId: auth().userId,
      records_number: fieldValues.records,
    };

    setLoader(true);
    const response = await CALL_DATA_FILTER(dataValues, api_key);

    if (typeof response === "number") {
    }

    if (typeof response === "string") {
      let snackData = {
        severity: "error",
        message: response,
        open: true,
      };
      dispatch(AuthenticationAction.setSnackBarValues(snackData));
      setLoader(false);
      return;
    }

    setOpenPopUp(true);
    setFilterResponse(response);

    setLoader(false);
  };
  const handlMinAgeChange = (e) => {
    setFieldValues({ ...fieldValues, min_age: e.target.value });

    if (!valid.min_age) {
      setValid((prevState) => ({ ...prevState, min_age: true }));
      setErrors((prevState) => ({ ...prevState, min_age: "" }));
    }
  };
  const handlMaxAgeChange = (e) => {
    setFieldValues({ ...fieldValues, max_age: e.target.value });

    if (!valid.max_age) {
      setValid((prevState) => ({ ...prevState, max_age: true }));
      setErrors((prevState) => ({ ...prevState, max_age: "" }));
    }
  };
  const handlRecordChange = (e) => {
    setFieldValues({ ...fieldValues, records: parseInt(e.target.value) });

    if (!valid.records) {
      setValid((prevState) => ({ ...prevState, records: true }));
      setErrors((prevState) => ({ ...prevState, records: "" }));
    }
  };
  const handleStatesChange = (e) => {
    const {
      target: { value },
    } = e;

    const selectedStates = typeof value === "string" ? value.split(",") : value;
    setFieldValues({
      ...fieldValues,
      states: selectedStates,
      cities: [],
      zipCode: [],
    });

    if (!valid.states) {
      setValid((prevState) => ({ ...prevState, states: true }));
      setErrors((prevState) => ({ ...prevState, states: "" }));
    }
  };

  const handleCitiesChange = (e, newValue) => {
    const value = newValue;

    setLoadingFilter(true);
    const selectedCitis = typeof value === "string" ? value.split(",") : value;
    setFieldValues({ ...fieldValues, cities: selectedCitis, zipCode: [] });

    if (!valid.cities) {
      setValid((prevState) => ({ ...prevState, cities: true }));
    }
    setLoadingFilter(true);
  };
  const handleZipChange = (e) => {
    const {
      target: { value },
    } = e;

    const selectedZip = typeof value === "string" ? value.split(",") : value;
    setFieldValues({ ...fieldValues, zipCode: selectedZip });

    if (!valid.zipCode) {
      setValid((prevState) => ({ ...prevState, zipCode: true }));
    }
  };

  const availableCities = statesData
    .filter((states) => fieldValues.states.includes(states.abbrevation))
    .flatMap((states) => states.Cities);

  const handleFilterCities = (event) => {
    const inputValue = event.target.value.toLowerCase();

    if (inputValue.length < 2) {
      return;
    }

    const filteredData = availableCities.filter((item) =>
      item.toLowerCase().includes(inputValue)
    );
    setFilteredCities(filteredData);
  };

  const availableZipCodes = availableCities
    .filter((city) => fieldValues.cities.includes(city))
    .flatMap((city) => {
      return statesData.find((state) => state.Cities.includes(city)).ZipCodes[
        city
      ];
    });
  useEffect(() => {
    switch (plan) {
      case "Free":
      case "Pay as you go":
        setPerRecord(0.05);
        break;
      case "Standard":
        setPerRecord(0.04);
        break;
      case "Professional":
        setPerRecord(0.03);
        break;
      case "Enterprise":
        setPerRecord(0.02);
        break;
      case "Basic Starting":
        setPerRecord(0.0079);
        break;
      case "Basic Standard":
        setPerRecord(0.00596);
        break;
      case "Basic Business":
        setPerRecord(0.00498);
        break;
      case "Entrepreneur Basic":
        setPerRecord(0.00449);
        break;
      case "Entrepreneur Standard":
        setPerRecord(0.003396);
        break;
      case "Entrepreneur Business":
        setPerRecord(0.003098);
        break;
      default:
        setPerRecord(0.05);
    }
  }, [plan]);
  return (
    <div className='data_form_container'>
      <AlertDialog
        openPopUp={openPopUp}
        setOpenPopUp={setOpenPopUp}
        filterResponse={filterResponse}
        perRecord={perRecord}
      />
      <Paper className='paper' elevation={3}>
        <FormControl className='form_control' defaultValue='' required>
          <div className='age_container'>
            <TextField
              id='min_age'
              name='minimum_age'
              label='Minimum Age'
              variant='outlined'
              onChange={(e) => handlMinAgeChange(e)}
              value={fieldValues.min_age}
              error={!valid.min_age}
              helperText={errors.min_age}
              // InputProps={{ inputProps: { min: 20, max: 99 } }}
              required={true}
              type='number'
            />
            <TextField
              id='max_age'
              name='maximum_age'
              label='Maximum Age'
              variant='outlined'
              onChange={(e) => handlMaxAgeChange(e)}
              value={fieldValues.max_age}
              error={!valid.max_age}
              helperText={errors.max_age}
              // InputProps={{ inputProps: { min: 22, max: 100 } }}
              required={true}
              type='number'
            />
          </div>
          <FormControl className='sub_form_control' defaultValue='' required>
            <InputLabel id='demo-multiple-checkbox-label'>State</InputLabel>

            <Select
              labelId='demo-multiple-checkbox-label'
              id='demo-multiple-checkbox'
              multiple
              value={fieldValues.states}
              onChange={handleStatesChange}
              error={!valid.states}
              required={true}
              input={<OutlinedInput label='State' />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {statesData.map((state, index) => (
                <MenuItem key={index} value={state.abbrevation}>
                  <Checkbox
                    checked={
                      fieldValues?.states?.indexOf(state.abbrevation) > -1
                    }
                  />
                  <ListItemText primary={state.name} />
                </MenuItem>
              ))}
            </Select>
            {fieldValues.states.length <= 0 ? (
              <FormHelperText className='helper_text_form'>
                {errors.states}
              </FormHelperText>
            ) : (
              <></>
            )}
          </FormControl>
          {/* <FormControl
            className='sub_form_control'
            disabled={fieldValues.states.length <= 0}
          >
            <InputLabel id='demo-simple-select-disabled-label'>City</InputLabel>
            <Select
              labelId='demo-simple-select-disabled-label'
              id='demo-simple-select-disabled'
              multiple
              value={fieldValues.cities}
              onChange={handleCitiesChange}
              error={!valid.cities}
              input={<OutlinedInput label='City' />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {availableCities.map((city, index) => (
                <MenuItem key={index} value={city}>
                  <Checkbox checked={fieldValues?.cities?.indexOf(city) > -1} />
                  <ListItemText primary={city} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText className='helper_text_form'>
              {errors.cities}
            </FormHelperText>
          </FormControl> */}
          {/* Testing code for autocomplete */}
          <Autocomplete
            multiple
            id='autocomplete'
            className='sub_form_control'
            options={filteredCities}
            getOptionLabel={(option) => option}
            value={fieldValues.cities}
            onChange={handleCitiesChange}
            disableCloseOnSelect
            renderOption={(props, city, { selected }) => (
              <li {...props} value={city}>
                <Checkbox checked={selected} color='primary' />
                <ListItemText primary={city} />
              </li>
            )}
            renderInput={(params) => (
              <TextField
                placeholder='Search city'
                {...params}
                variant='outlined'
                label='City'
                onChange={(e) => handleFilterCities(e)}
              />
            )}
            renderTags={(value, getTagProps) => <p>{value.join(",")}</p>}
          />
          {/* Testing code for autocomplete */}
          {/* <FormControl
            className='sub_form_control'
            disabled={fieldValues.cities.length <= 0}
          >
            <InputLabel id='demo-multiple-checkbox-label'>Zip Code</InputLabel>
            <Select
              labelId='demo-multiple-checkbox-label'
              id='demo-multiple-checkbox'
              multiple
              value={fieldValues.zipCode}
              error={!valid.zipCode}
              onChange={handleZipChange}
              input={<OutlinedInput label='Zip Code' />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {availableZipCodes.map((zip, index) => (
                <MenuItem key={index} value={zip}>
                  <Checkbox checked={fieldValues.zipCode.indexOf(zip) > -1} />
                  <ListItemText primary={zip} />
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          <TextField
            className='number_of_records'
            id='records'
            name='records'
            label='Number of records'
            variant='outlined'
            onChange={(e) => handlRecordChange(e)}
            value={fieldValues.records}
            error={!valid.records}
            helperText={errors.records}
            InputProps={{ inputProps: { min: minRecord, max: maxRecord } }}
            required={true}
            type='number'
          />
          <div className='button_group'>
            <Button
              disabled={loader}
              onClick={() => handleFieldClear()}
              variant='contained'
              color='warning'
            >
              Clear Fields
            </Button>
            <Button
              disabled={loader}
              onClick={() => handleLogIn()}
              variant='contained'
            >
              {loader ? (
                <CircularProgress size='2rem' />
              ) : (
                <>
                  <span>Get The Records</span>
                </>
              )}
            </Button>
          </div>
        </FormControl>
      </Paper>
    </div>
  );
}

export default DataForm;
