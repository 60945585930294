export const SIGN_UP_API = 'https://accounts.databoxmarketresearch.com/api/Account/signup';
export const LOG_IN = 'https://accounts.databoxmarketresearch.com/api/Account/SignIn';
export const LOG_OUT = 'https://accounts.databoxmarketresearch.com/api/Account/SignOut';

export const GET_CONFIRM_EMAIL_ID =
  'https://accounts.databoxmarketresearch.com/api/Account/confirm-email';
// "https://accounts.databoxmarketresearch.com/api/Account/signup";
export const FORGOT_PASSWORD =
  'https://accounts.databoxmarketresearch.com/api/Account/forgot-password';
export const RESET_PASSWORD =
  'https://accounts.databoxmarketresearch.com/api/Account/reset-password';
export const RESEND_EMAIL = 'https://accounts.databoxmarketresearch.com/api/Account/resend-email';
//data sending apis
export const GET_USER_PLAN =
  'https://peoplesdata.databoxmarketresearch.com/api/controller/usersplans?userId=';
export const FILTER_USERS_DATA = 'https://peoplesdata.databoxmarketresearch.com/api/controller';
export const GET_REQUESTED_DATA =
  'https://peoplesdata.databoxmarketresearch.com/api/controller/create_user';
export const GET_EXCEL = 'https://peoplesdata.databoxmarketresearch.com/api/controller';

export const STRIPE_API = 'https://peoplesdata.databoxmarketresearch.com/api/Stripe';
export const STRIPE_AFTER_PAYMNET =
  'https://peoplesdata.databoxmarketresearch.com/api/Stripe/verify-payment?sessionId=';
