import React from "react";
import Card from "@mui/material/Card";

import "./HomeCard.scss";

function HomeCard({ icon, title, description, color }) {
  return (
    <div className='home_card_container'>
      <Card className='card_container'>
        <div className={`icon_div ${color}`}>{icon}</div>
        <h2>{title}</h2>
        <p>{description}</p>
      </Card>
    </div>
  );
}

export default HomeCard;
