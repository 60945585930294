const replaceCommasWithSpaces = (value) => {
  return value.replace(/,/g, "");
};

export const convertToCSV = (data, dataType) => {
  let csvContent = "";
  if (dataType === false) {
    csvContent =
      "firstName,lastName,age,state,fullName,street,city,zipCode,email1,email2,email3,email4,email5,phoneNumber1,lineType1,carrier1,phoneNumber2,lineType2,carrier2,phoneNumber3,lineType3,carrier3,phoneNumber4,lineType4,carrier4,phoneNumber5,lineType5,carrier5,phoneNumber6,lineType6,carrier6,bedRooms,bathrooms,squareFeet,yearBuild,estimateValue,estimateEquity,lastSaleAmount,lastSaleDate,occupancyType,ownershipType,landUse,propertyClass,subDivision,lotSquareFeet,apn\n";
  } else if (dataType === true) {
    csvContent =
      "firstName,lastName,age,state,fullName,street,city,zipCode,bedRooms,bathrooms,squareFeet,yearBuild,estimateValue,estimateEquity,lastSaleAmount,lastSaleDate,occupancyType,ownershipType,landUse,propertyClass,subDivision,lotSquareFeet,apn\n";
  }

  data.forEach((person) => {
    const firstName = replaceCommasWithSpaces(person.firstName);
    const lastName = replaceCommasWithSpaces(person.lastName);
    const age = replaceCommasWithSpaces(person.age);
    const state = replaceCommasWithSpaces(person.state);
    const fullName = replaceCommasWithSpaces(person.fullName);
    const street = replaceCommasWithSpaces(person.street);
    const city = replaceCommasWithSpaces(person.city);
    const zipCode = replaceCommasWithSpaces(person.zipCode);

    // Emails
    const emails = person.emails || [];
    const email1 = replaceCommasWithSpaces(emails[0] || "N/A");
    const email2 = replaceCommasWithSpaces(emails[1] || "N/A");
    const email3 = replaceCommasWithSpaces(emails[2] || "N/A");
    const email4 = replaceCommasWithSpaces(emails[3] || "N/A");
    const email5 = replaceCommasWithSpaces(emails[4] || "N/A");
    // Phone Details
    const phoneDetails = person.phone_Details || [];
    let wirelessIndex = 1;
    let landlineIndex = 1;

    let phoneNumber1 = "N/A",
      lineType1 = "N/A",
      carrier1 = "N/A";
    let phoneNumber2 = "N/A",
      lineType2 = "N/A",
      carrier2 = "N/A";
    let phoneNumber3 = "N/A",
      lineType3 = "N/A",
      carrier3 = "N/A";
    let phoneNumber4 = "N/A",
      lineType4 = "N/A",
      carrier4 = "N/A";
    let phoneNumber5 = "N/A",
      lineType5 = "N/A",
      carrier5 = "N/A";
    let phoneNumber6 = "N/A",
      lineType6 = "N/A",
      carrier6 = "N/A";

    phoneDetails.forEach((phone) => {
      const phoneNumber = replaceCommasWithSpaces(phone.phoneNumber || "NA");
      const lineType = replaceCommasWithSpaces(phone.line_Type || "NA");
      const carrier = replaceCommasWithSpaces(phone.carrier || "NA");

      if (lineType === "Wireless") {
        if (wirelessIndex === 1) {
          phoneNumber1 = phoneNumber || "NA";
          lineType1 = lineType || "NA";
          carrier1 = carrier || "NA";
        } else if (wirelessIndex === 2) {
          phoneNumber2 = phoneNumber || "NA";
          lineType2 = lineType || "NA";
          carrier2 = carrier || "NA";
        } else if (wirelessIndex === 3) {
          phoneNumber3 = phoneNumber || "NA";
          lineType3 = lineType || "NA";
          carrier3 = carrier || "NA";
        }
        wirelessIndex++;
      } else if (lineType === "Landline") {
        if (landlineIndex === 1) {
          phoneNumber4 = phoneNumber || "NA";
          lineType4 = lineType || "NA";
          carrier4 = carrier || "NA";
        } else if (landlineIndex === 2) {
          phoneNumber5 = phoneNumber || "NA";
          lineType5 = lineType || "NA";
          carrier5 = carrier || "NA";
        } else if (landlineIndex === 3) {
          phoneNumber6 = phoneNumber || "NA";
          lineType6 = lineType || "NA";
          carrier6 = carrier || "NA";
        }
        landlineIndex++;
      }
    });

    // Property Details
    const propertyDetails = person.propertyDetails || {};
    const bedRooms = replaceCommasWithSpaces(
      propertyDetails.beedRooms || "N/A"
    );
    const bathrooms = replaceCommasWithSpaces(
      propertyDetails.bathrooms || "N/A"
    );
    const squareFeet = replaceCommasWithSpaces(
      propertyDetails.square_Feet || "N/A"
    );
    const yearBuild = replaceCommasWithSpaces(
      propertyDetails.year_Build || "N/A"
    );
    const estimateValue = replaceCommasWithSpaces(
      propertyDetails.estimate_Value || "N/A"
    );
    const estimateEquity = replaceCommasWithSpaces(
      propertyDetails.estimate_Equity || "N/A"
    );
    const lastSaleAmount = replaceCommasWithSpaces(
      propertyDetails.last_Sale_Amount || "N/A"
    );
    const lastSaleDate = replaceCommasWithSpaces(
      propertyDetails.last_Sale_Date || "N/A"
    );
    const occupancyType = replaceCommasWithSpaces(
      propertyDetails.occupancy_Type || "N/A"
    );
    const ownershipType = replaceCommasWithSpaces(
      propertyDetails.ownership_Type || "N/A"
    );
    const landUse = replaceCommasWithSpaces(propertyDetails.land_Use || "N/A");
    const propertyClass = replaceCommasWithSpaces(
      propertyDetails.propery_Class || "N/A"
    );
    const subDivision = replaceCommasWithSpaces(
      propertyDetails.sub_division || "N/A"
    );
    const lotSquareFeet = replaceCommasWithSpaces(
      propertyDetails.lot_Square_Feet || "N/A"
    );
    const apn = replaceCommasWithSpaces(propertyDetails.apn || "N/A");

    if (dataType === false) {
      csvContent += `${firstName},${lastName},${age},${state},${fullName},${street},${city},${zipCode},${email1},${email2},${email3},${email4},${email5},${phoneNumber1},${lineType1},${carrier1},${phoneNumber2},${lineType2},${carrier2},${phoneNumber3},${lineType3},${carrier3},${phoneNumber4},${lineType4},${carrier4},${phoneNumber5},${lineType5},${carrier5},${phoneNumber6},${lineType6},${carrier6},${bedRooms},${bathrooms},${squareFeet},${yearBuild},${estimateValue},${estimateEquity},${lastSaleAmount},${lastSaleDate},${occupancyType},${ownershipType},${landUse},${propertyClass},${subDivision},${lotSquareFeet},${apn}\n`;
    } else if (dataType === true) {
      csvContent += `${firstName},${lastName},${age},${state},${fullName},${street},${city},${zipCode},${bedRooms},${bathrooms},${squareFeet},${yearBuild},${estimateValue},${estimateEquity},${lastSaleAmount},${lastSaleDate},${occupancyType},${ownershipType},${landUse},${propertyClass},${subDivision},${lotSquareFeet},${apn}\n`;
    }
  });

  return csvContent;
};
