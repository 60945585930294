import { Outlet, Navigate } from "react-router-dom";

import { useIsAuthenticated } from "react-auth-kit";
const PrivateRoute = () => {
  const isAuthenticated = useIsAuthenticated();

  const userAuth = isAuthenticated();
  
  return userAuth ? <Outlet /> : <Navigate to='/login' />;
};
export default PrivateRoute;
