import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PrimaryReport from '../../Molecule/PrimaryReport/PrimaryReport';
import './Report.scss';
function Report() {
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 500);
  }, []);
  return (
    <div className="report_page_container">
      <PrimaryReport id={id} search="" />
    </div>
  );
}

export default Report;
