import React from 'react';
import './DescriptionCard.scss';
import { Paper } from '@mui/material';
function DescriptionCard({ description }) {
  return (
    <Paper className="description_card" elevation={2}>
      <h2 className="heading">Summary</h2>
      {description.map((data, index) => {
        return <p key={index}>{data} </p>;
      })}
    </Paper>
  );
}

export default DescriptionCard;
