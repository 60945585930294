import React, { useEffect } from 'react';
import './ContactUs.scss';
import Background from '../../Molecule/Bakground/Background';
import ContactCard from '../../Molecule/ContactCard/ContactCard';
import ContactForm from './contactForm';

const data = {
  title: 'Contact Us',
  description: '',
};
function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="contact_us_containet">
      <div className="background_contaier">
        <Background title={data.title} description={data.description} />
      </div>

      <div className="content_container">
        <ContactCard />
        <ContactForm />
      </div>
    </div>
  );
}

export default ContactUs;
