import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAuthUser } from "react-auth-kit";

import "./AlertDialog.scss";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { AuthenticationAction } from "../../../Redux/Actions/Action";
import { CALL_GET_REQUESTED_DATA } from "../../../API/ApiCall";
import { CircularProgress } from "@mui/material";
export default function AlertDialog({
  openPopUp,
  setOpenPopUp,
  perRecord,
  filterResponse,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useAuthUser();
  const [loader, setLoader] = useState(false);
  const handleClose = async () => {
    const data = {
      userId: auth().userId,
      perRecord: perRecord,
      amount: (perRecord * filterResponse).toFixed(2),
    };
    const amount = perRecord * filterResponse;

    setLoader(true);
    const response = await CALL_GET_REQUESTED_DATA(data, auth().api_key);

    if (response === "inefficient funds to buy this records") {
      let snackData = {
        severity: "error",
        message: response,
        open: true,
      };
      dispatch(AuthenticationAction.setSnackBarValues(snackData));
      setOpenPopUp(false);
      return;
    }

    if (response?.error) {
      let snackData = {
        severity: "error",
        message: response?.message,
        open: true,
      };
      dispatch(AuthenticationAction.setSnackBarValues(snackData));
      setOpenPopUp(false);
      return;
    }
    setLoader(false);
    setOpenPopUp(false);
    navigate("/dashboard");
  };
  const handleDisagree = () => {
    setOpenPopUp(false);
  };

  useEffect(() => {
    return () => {
      setLoader(false);
    };
  }, []);

  return (
    <div>
      <Dialog
        open={openPopUp}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        className='dialog_alert_container'
      >
        <DialogTitle id='alert-dialog-title'>{"Buy your Api"}</DialogTitle>{" "}
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            You will get {filterResponse} records and amount of $
            {(perRecord * filterResponse).toFixed(2)} will be deducted from your
            data api connect wallet.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loader}
            color='warning'
            variant='contained'
            onClick={handleDisagree}
          >
            {loader ? <CircularProgress color='success' /> : <>Disagree</>}
          </Button>
          <Button
            disabled={loader}
            variant='contained'
            onClick={handleClose}
            autoFocus
          >
            {loader ? <CircularProgress color='success' /> : <>Agree</>}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
