import React from "react";
import "./DataTransparency.scss";
import Background from "../../Molecule/Bakground/Background";
import { why_data, works_data } from "../../../Data/Data";

const data = {
  title: "Ensuring Fresh Data Every Time",
  description:
    "We understand the importance of providing our valued users with accurate and up-to-date data every time they access our services. To achieve this, we have implemented a robust system for managing data duplication and indexing. This ensures that when you return to buy more data, you receive only the newest and most relevant information.",
};
function DataTransparency() {
  return (
    <div className='data_transparency_container'>
      <div className='background_contaier'>
        <Background title={data.title} description={data.description} />
      </div>
      <div className='content_container'>
        <h1>How It Works:</h1>
        <ol className='sub_content'>
          {works_data.map((data, index) => {
            return (
              <li key={index}>
                <h2>{data.title}</h2>
                <p>{data.description}</p>
              </li>
            );
          })}
        </ol>
      </div>
      <div className='content_container'>
        <h1>Why It Matters:</h1>
        <ul className='sub_content'>
          {why_data.map((data, index) => {
            return (
              <li key={index}>
                <h2>{data.title}</h2>
                <p>{data.description}</p>
              </li>
            );
          })}
        </ul>
      </div>
      <div className='content_container margin_class'>
        <p>
          We are committed to delivering a seamless and efficient data
          experience for our users. Our data duplication and indexing strategy
          is just one way we strive to exceed your expectations.
        </p>
      </div>
      <div className='content_container margin_class'>
        <p>
          If you have any questions or need further assistance, please don't
          hesitate to contact us. We're here to ensure your data needs are met
          with excellence.
        </p>
      </div>
    </div>
  );
}

export default DataTransparency;
