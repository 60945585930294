import React from "react";
import "./Background.scss";
function Background({ description, title }) {
  return (
    <div className='background_main_container'>
      <section className='hero'>
        <div className='hero-content'>
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
      </section>
    </div>
  );
}

export default Background;
