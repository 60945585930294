import React from "react";
import "./SocialAccount.scss";
import { IconButton } from "@mui/material";
import {
  SkypeIcon,
  TelegramIcon,
  WhatsAppIcon,
} from "../../../Asset/Icons/Icons";
function SocialAccounts() {
  const redirect = (Value) => {
    window.open(Value, "_blank");
  };

  return (
    <div className='social_accounts'>
      <IconButton
        onClick={() =>
          redirect(
            "https://api.whatsapp.com/message/HH7KVRVRLPJYB1?autoload=1&app_absent=0"
          )
        }
        className='whatsapp'
        aria-label='Whatsapp'
      >
        {WhatsAppIcon}
      </IconButton>
      <IconButton
        onClick={() => redirect("https://join.skype.com/invite/vWqwR9VYdSNA")}
        className='skype'
        aria-label='Skype'
      >
        {SkypeIcon}
      </IconButton>
      <IconButton
        onClick={() => redirect("https://t.me/<Mangesh@279>")}
        className='telegram'
        aria-label='Telegram'
      >
        {TelegramIcon}
      </IconButton>
    </div>
  );
}

export default SocialAccounts;
