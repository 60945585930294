import { BsFillDatabaseFill } from 'react-icons/bs';
import {
  BsListColumns,
  BsFacebook,
  BsInstagram,
  BsTwitter,
  BsLinkedin,
  BsCheck2Circle,
  BsFillPersonPlusFill,
  BsShieldFillCheck,
  BsFingerprint,
  BsFillRocketTakeoffFill,
  BsPeopleFill,
  BsCreditCard2FrontFill,
  BsPlusCircleFill,
  BsTelephoneInbound,
} from 'react-icons/bs';
import { BsFillStarFill } from 'react-icons/bs';
import { FaEnvelopeOpenText, FaPeopleCarry } from 'react-icons/fa';
import { FaSkype, FaCcStripe } from 'react-icons/fa';
import FeedIcon from '@mui/icons-material/Feed';
import {
  BiDollarCircle,
  BiSupport,
  BiSolidQuoteAltLeft,
  BiSolidQuoteAltRight,
  BiSignal4,
  BiDollar,
  BiSolidKey,
  BiSolidUserAccount,
} from 'react-icons/bi';
import { RiCustomerService2Line } from 'react-icons/ri';
import { LuNetwork } from 'react-icons/lu';
import { IoIosPeople, IoMdInformationCircleOutline } from 'react-icons/io';
import { GiHamburgerMenu } from 'react-icons/gi';
import { VscGraphLine } from 'react-icons/vsc';
import {
  AiFillHome,
  AiFillDollarCircle,
  AiFillStar,
  AiOutlineSync,
  AiOutlineDatabase,
  AiOutlineCloudServer,
} from 'react-icons/ai';
import { HiPresentationChartLine, HiOutlineDocumentText, HiOutlineMail } from 'react-icons/hi';

import { HiBuildingOffice2 } from 'react-icons/hi2';
import { HiHomeModern } from 'react-icons/hi2';
import { ImWhatsapp } from 'react-icons/im';
import { PiSignIn, PiSignOut, PiCertificateFill } from 'react-icons/pi';
import { IoIosArrowDown } from 'react-icons/io';
import { AiOutlineMenu } from 'react-icons/ai';
import { IoDesktop } from 'react-icons/io5';
import { FaShoppingCart, FaTelegram, FaTools, FaLongArrowAltRight } from 'react-icons/fa';
import { MdDashboard, MdEmail } from 'react-icons/md';
import { FiLogOut } from 'react-icons/fi';
import { CiLocationOn } from 'react-icons/ci';
import { RiTwitterXFill, RiFileExcel2Fill } from 'react-icons/ri';

//Data box imports //
import { RiAdvertisementLine, RiSearchEyeLine } from 'react-icons/ri';
import { GiForkKnifeSpoon, GiWheat } from 'react-icons/gi';
import { AiOutlineDown, AiOutlineArrowRight } from 'react-icons/ai';
import {
  FaPlaneDeparture,
  FaIndustry,
  FaMotorcycle,
  FaFacebookSquare,
  FaBoxOpen,
  FaShoppingBasket,
  FaRobot,
  FaRegLightbulb,
  FaRegChartBar,
  FaChartBar,
} from 'react-icons/fa';
import { HiTruck } from 'react-icons/hi';
import { PiFlaskFill } from 'react-icons/pi';
import { BsHeartPulseFill, BsFillBuildingsFill, BsTelephoneFill } from 'react-icons/bs';
import { IoDesktopOutline, IoLocationSharp, IoHardwareChipSharp } from 'react-icons/io5';
import { BiSearch } from 'react-icons/bi';
import { FiTarget } from 'react-icons/fi';
import { MdArrowForwardIos, MdContactPhone } from 'react-icons/md';

export const downArrow = <IoIosArrowDown />;
export const menuIcon = <AiOutlineMenu />;
export const FillData = <BsFillDatabaseFill />;
export const BS_Facebook = <BsFacebook />;
export const BS_Twitter = <BsTwitter />;
export const BS_Linkedin = <BsLinkedin />;
export const BS_Instagram = <BsInstagram />;
export const RightCircle = <BsCheck2Circle />;
export const QuoteRight = <BiSolidQuoteAltRight />;
export const QuoteLeft = <BiSolidQuoteAltLeft />;
export const LogIn = <BsFillPersonPlusFill />;
export const SignIn = <PiSignIn />;
export const SignOut = <PiSignOut />;
export const Home = <AiFillHome />;
export const Shield = <BsShieldFillCheck />;
export const Dollar = <AiFillDollarCircle />;
export const Signal = <BiSignal4 />;
export const Services = <RiCustomerService2Line />;
export const Industry = <LuNetwork />;
export const Pricing = <BiDollarCircle />;
export const Support = <BiSupport />;
export const AboutUs = <IoIosPeople />;
export const HamBugeerMenu = <GiHamburgerMenu />;
export const Star = <AiFillStar />;
export const Sync = <AiOutlineSync />;
export const Fingerprint = <BsFingerprint />;
export const Rocket = <BsFillRocketTakeoffFill />;
export const Presentation = <HiPresentationChartLine />;
export const Peoples = <BsPeopleFill />;
export const Cart = <FaShoppingCart />;
export const Card = <BsCreditCard2FrontFill />;
export const Plus = <BsPlusCircleFill />;
export const BiDollarIcon = <BiDollar />;
export const BiSolidKeyIcon = <BiSolidKey />;
export const DashBoardIcon = <MdDashboard />;
export const LogOut = <FiLogOut />;
export const Document = <HiOutlineDocumentText />;
export const EmailIcon = <MdEmail />;
export const UserIdIcon = <BiSolidUserAccount />;
export const PhoneIcon = <BsTelephoneInbound />;
export const LocationIcon = <CiLocationOn />;
export const EmailAddressIcon = <HiOutlineMail />;
export const SkypeIcon = <FaSkype />;
export const WhatsAppIcon = <ImWhatsapp />;
export const TelegramIcon = <FaTelegram />;
export const DataTransferIcon = <FaEnvelopeOpenText />;
export const DataPoint = <BsListColumns />;
export const DataLakeIcon = <AiOutlineDatabase />;
export const DataComplianceIcon = <AiOutlineCloudServer />;
export const XTwitter = <RiTwitterXFill />;
export const StarIcon = <BsFillStarFill />;
export const ExcelIcon = <RiFileExcel2Fill />;
export const StripeIcon = <FaCcStripe />;
export const HomeIcon = <HiHomeModern />;
export const ToolIcon = <FaTools />;
export const BuildingIcon = <HiBuildingOffice2 />;
export const CertificateIcon = <PiCertificateFill />;
export const WorkerIcon = <IoDesktop />;
export const GraphIcon = <VscGraphLine />;
export const ArrowRight = <FaLongArrowAltRight />;

//From data box logo//

export const menuIocn = <GiHamburgerMenu />;

export const PlaneIcon = <FaPlaneDeparture />;
export const TruckLogo = <HiTruck />;
export const ChemicalIcon = <PiFlaskFill />;
export const IndustryLogo = <FaIndustry />;
export const ForkIcon = <GiForkKnifeSpoon />;
export const VentilatorHeart = <BsHeartPulseFill />;
export const DesktopIcon = <IoDesktopOutline />;
export const MotorCycle = <FaMotorcycle />;

export const LinkedInIcon = <BsLinkedin />;
export const TwitterIcon = <RiTwitterXFill />;
export const FaceBookIcon = <FaFacebookSquare />;

export const SemiConductor = <IoHardwareChipSharp />;
export const BoxIcon = <FaBoxOpen />;
export const ShoppingBasket = <FaShoppingBasket />;
export const WheatIcon = <GiWheat />;
export const RobotIcon = <FaRobot />;
export const RightArrow = <MdArrowForwardIos />;
export const BulbIcon = <FaRegLightbulb />;
export const SearchIcon = <BiSearch />;
export const ChartIcon = <FaRegChartBar />;
export const TargetIcons = <FiTarget />;
export const AdvertiseIcon = <RiAdvertisementLine />;
export const OverViewIcon = <RiSearchEyeLine />;

export const ReasearchIcon = <FaChartBar />;
export const ContactIcon = <MdContactPhone />;
export const ServiceIcon = <RiCustomerService2Line />;
export const InfoIcon = <IoMdInformationCircleOutline />;
export const ReportIcon = <FeedIcon />;
