import React, { useState } from 'react';
import './SnackBar.scss';
import { Snackbar, Alert } from '@mui/material';
function SnackBar({ open, setOpen, message, type }) {
  const [state, setState] = useState({
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal } = state;

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="snack_bar_div">
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        key={vertical + horizontal}>
        <Alert className="alert" severity={type}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default SnackBar;
