import React from 'react';
import './Builder.scss';
import IconCircle from '../IconCircle/IconCircle';
function Builder({ title, description, icon }) {
  return (
    <div className="builder_container">
      <IconCircle icon={icon} />
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
}

export default Builder;
