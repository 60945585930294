import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import Logo from '../../../Asset/img/App_Logo/LogoAPi.png';
import { SignIn, LogIn, DashBoardIcon, LogOut } from '../../../Asset/Icons/Icons';

import './AppNavBar.scss';
import ActionButton from '../../Atom/Buttons/ActionButton';
import DropDownButton from '../DropDownButton/DropDownButton';
import { actionButtonData, app_bar_menu, app_bar_menu_login } from '../../../Data/Data';
import { Link } from 'react-router-dom';
import { useIsAuthenticated } from 'react-auth-kit';

import { useSignOut } from 'react-auth-kit';
import DrawerSide from '../SideNavBar/DrawerSide';

import { useNavigate } from 'react-router-dom';
import LogInButton from '../../Atom/Buttons/LogInButton';
export default function AppNavBar() {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const isAuthenticated = useIsAuthenticated();

  const handleLogOut = async () => {
    // await CALL_LOG_OUT_API();
    navigate('/login');
    signOut();
  };

  return (
    <div className="app_bar_container">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <div className="items_container">
            <div className="drawer_container">
              <DrawerSide />
            </div>

            <Link to="/">
              <div className="Logo_container">
                <img alt="logo" src={Logo} />
                <h1 className="logo_text">Data Box Market Research</h1>
              </div>
            </Link>

            <div className="button_div">
              {isAuthenticated() ? (
                <ActionButton title={'Dashboard'} link={'/dashboard'} icon={DashBoardIcon} />
              ) : (
                <DropDownButton title="Report Categories" subActions={actionButtonData} />
              )}
              {isAuthenticated() ? (
                <>
                  {app_bar_menu_login.map((data, index) => {
                    return (
                      <ActionButton
                        key={index}
                        title={data.title}
                        link={data.link}
                        icon={data.icon}
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  {app_bar_menu.map((data, index) => {
                    return (
                      <ActionButton
                        key={index}
                        title={data.title}
                        link={data.link}
                        icon={data.icon}
                      />
                    );
                  })}
                </>
              )}

              {/* {!isAuthenticated() ? (
                <>
                  <LogInButton title='Sign Up' link='/sign up' icon={LogIn} />
                  <LogInButton title='Login' link='/login' icon={SignIn} />
                </>
              ) : (
                <></>
              )} */}

              {isAuthenticated() ? (
                // <DropDownButton title='Profile' subActions={actionButtonData} />
                <IconButton title="Log Out" onClick={() => handleLogOut()} aria-label="log out">
                  {LogOut}
                </IconButton>
              ) : (
                <></>
              )}
            </div>
          </div>
        </AppBar>
      </Box>
    </div>
  );
}
