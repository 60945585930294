import { configureStore } from "@reduxjs/toolkit";
import { LanguageReducer } from "./Reducers/Lang_Reducer";
import { UserAuthenticationReducer } from "./Reducers/LogIn_Reducer";
const store = configureStore({
  reducer: {
    product: LanguageReducer.reducer,
    userLoggIn: UserAuthenticationReducer.reducer,
  },
});

export default store;
