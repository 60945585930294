import React, { useState, useEffect } from "react";
import "./DataPage.scss";

import { useAuthUser } from "react-auth-kit";
import DataForm from "../../Molecule/DataComplieForm/DataForm";
import { CALL_USER_DETAILS } from "../../../API/ApiCall";
import { Paper } from "@mui/material";

function DataPage() {
  const auth = useAuthUser();
  const [minRecord, setMinRecord] = useState(0);
  const [maxRecord, setMaxRecord] = useState(0);
  const [planLoader, setPlanLoader] = useState(true);
  const [userPlan, setUserPlan] = useState({
    amount: 0,
    plan: "Free",
    perRecord: 0,
    freeTrials: 100,
    userDownloads: [],
  });
  const getThePlanDetails = async () => {
    const res = await CALL_USER_DETAILS(auth().userId, auth().api_key);

    const userData = {
      amount: res.amount,
      userDownloads: res.downloadedStamps,
      freeTrials: res.freeTrial,
      perRecord: res.perRecord,
      plan: res.plan,
    };
    if (userData.plan === "Free" && userData.freeTrials > 0) {
      setMinRecord(10);
      setMaxRecord(25);
    }
    if (userData.plan !== "Free") {
      setMinRecord(10000);
      setMaxRecord(10000);
    }

    setUserPlan(userData);
    setPlanLoader(false);
  };

  useEffect(() => {
    getThePlanDetails();
  }, []);
  return (
    <div className='data_page_container'>
      <DataForm
        minRecord={minRecord}
        maxRecord={maxRecord}
        plan={userPlan.plan}
        api_key={auth().api_key}
      />

      <Paper className='instructions_container' elevation={3}>
        <h3>How it works</h3>
        <ul className='numbered-list'>
          <li>
            <span>
              Specify the desired age range for customer data by indicating both
              the minimum and maximum age preferences.
            </span>
          </li>
          <li>
            <span>
              Choose the specific states for which you would like to retrieve
              customer data.
            </span>
          </li>
          <li>
            <span>
              If you desire data from a particular city, please search cities
              within the selected state and apply your preferences to those
              specific cities.
            </span>
          </li>
          <li>
            <span>Specify the quantity of records you wish to purchase.</span>
          </li>
        </ul>
      </Paper>
    </div>
  );
}

export default DataPage;
