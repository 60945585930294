import React from 'react';
import './NotFound.scss';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <div className="not_found_page">
      <div className="back_ground">
        <Button onClick={() => handleGoHome()} variant="contained">
          Home
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
