import React from 'react';
import './Footer.scss';
import { BS_Facebook, BS_Instagram, BS_Linkedin, XTwitter } from '../../../Asset/Icons/Icons';

import { Link } from 'react-router-dom';
const footerData = [
  {
    title: 'About the company',
    subtitles: [
      {
        name: 'Our market research company offers a comprehensive platform for businesses to gather valuable insights without limitations. With our cross-platform solutions, clients can access data seamlessly across various channels, empowering them to make informed decisions. Just as WhatsApp Web extends the messaging experience to browsers, our tools expand market research accessibility, enabling users to derive insights effortlessly from any device',
        link: '',
      },
    ],
  },
  {
    title: 'USEFUL LINKS',
    subtitles: [
      { name: 'Terms of Service', link: '/terms' },
      { name: 'Privacy Policy', link: '/privacy' },
      // { name: 'Use case', link: '/usecases' },
      { name: 'Contact US', link: '/contact' },
    ],
  },
];

const Icons = [
  {
    link: 'https://www.facebook.com/dataapiconnect?mibextid=LQQJ4d',
    Icon: BS_Facebook,
  },
  {
    link: 'https://x.com/dataapiconnect?s=11',
    Icon: XTwitter,
  },
  {
    link: 'https://www.linkedin.com/company/dataapiconnect/',
    Icon: BS_Linkedin,
  },
];

function FooterComp() {
  const redirect = (Value) => {
    window.open(Value, '_blank');
  };

  return (
    <div className="footer_container">
      <div className="upper_container">
        {footerData.map((data, index) => {
          return (
            <div className="data_card" key={index}>
              <h4 className="title">{data.title}</h4>
              {data.subtitles.map((subtitle, index) => {
                return subtitle.link ? (
                  <Link key={index} to={subtitle.link}>
                    <div className="subtitles">
                      <p className="subtitle_text">{subtitle.name}</p>
                    </div>
                  </Link>
                ) : (
                  <div key={index * 854} className="subtitles">
                    <p className="subtitle_text_01">{subtitle.name}</p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="lower_container">
        <h5 className="title">&copy; Copyright 2023 databoxmarketresearch.com - 2017</h5>
        {/* <div className="ions_container">
          {Icons.map((data, index) => {
            return (
              <div className="icons" key={index}>
                <Link onClick={() => redirect(data.link)}>{data.Icon}</Link>
              </div>
            );
          })}
        </div> */}
      </div>
    </div>
  );
}

export default FooterComp;
