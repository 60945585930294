// export const parseCSV = (csv) => {
//   const lines = csv.split("\n").slice(1); // Skip the header line and split by newline
//   const result = [];

//   lines.forEach((line) => {
//     const [stateFips, state, stateAbbr, zipcode, county, city] =
//       line.split(",");
//     const existingState = result.find((item) => item.abbrevation === stateAbbr);

//     if (existingState) {
//       const existingCity = existingState.ZipCodes[city];
//       if (existingCity) {
//         existingCity.push(zipcode);
//       } else {
//         existingState.ZipCodes[city] = [zipcode];
//       }
//     } else {
//       result.push({
//         abbrevation: stateAbbr,
//         name: state,
//         Cities: [city],
//         ZipCodes: {
//           [city]: [zipcode],
//         },
//       });
//     }
//   });

//   return result;
// };

export const statesData = [
  {
    abbrevation: "AL",
    name: "Alabama",
    Cities: [
      "Acmar",
      "Adamsville",
      "Adger",
      "Keystone",
      "New site",
      "Alpine",
      "Arab",
      "Baileyton",
      "Bessemer",
      "Zcta 35022",
      "Hueytown",
      "Blountsville",
      "Bremen",
      "Brent",
      "Brierfield",
      "Brookside",
      "Calera",
      "Centreville",
      "Chelsea",
      "Coosa pines",
      "Clanton",
      "Zcta 35046",
      "Cleveland",
      "Columbiana",
      "Cook springs",
      "Crane hill",
      "Cropwell",
      "Cullman",
      "Zcta 35058",
      "Docena",
      "Dolomite",
      "Dora",
      "Empire",
      "Fairfield",
      "Coalburg",
      "Garden city",
      "Gardendale",
      "Goodwater",
      "Alden",
      "Green pond",
      "Hanceville",
      "Harpersville",
      "Hayden",
      "Helena",
      "Hollins",
      "Holly pond",
      "Jemison",
      "Joppa",
      "Kellyton",
      "Kimberly",
      "Leeds",
      "Lincoln",
      "Locust fork",
      "Logan",
      "Zcta 350hh",
      "Zcta 350xx",
      "Mc calla",
      "Margaret",
      "Maylene",
      "Montevallo",
      "Morris",
      "Mount olive",
      "Sylvan springs",
      "New castle",
      "Odenville",
      "Oneonta",
      "Indian springs",
      "Pell city",
      "Dixiana",
      "Pleasant grove",
      "Zcta 35128",
      "Quinton",
      "Ragland",
      "Remlap",
      "Riverside",
      "Rockford",
      "Sayre",
      "Shelby",
      "Springville",
      "Sterrett",
      "Sumiton",
      "Sycamore",
      "Sylacauga",
      "Zcta 35151",
      "Talladega",
      "Thorsby",
      "Trafford",
      "Trussville",
      "Union grove",
      "Vandiver",
      "Vincent",
      "Vinemont",
      "Warrior",
      "Weogufka",
      "West blocton",
      "Wilsonville",
      "Woodstock",
      "Zcta 351hh",
      "Zcta 351xx",
      "Birmingham",
      "Homewood",
      "Irondale",
      "Crestline height",
      "Center point",
      "Vestavia hills",
      "Mountain brook",
      "Bluff park",
      "Midfield",
      "Shoal creek",
      "Cahaba heights",
      "Hoover",
      "Zcta 352hh",
      "Tuscaloosa",
      "Holt",
      "Stewart",
      "Aliceville",
      "Boligee",
      "Brookwood",
      "Buhl",
      "Carrollton",
      "Coker",
      "Cottondale",
      "Duncanville",
      "Echola",
      "Elrod",
      "Emelle",
      "Epes",
      "Ethelsville",
      "Eutaw",
      "Fosters",
      "Gainesville",
      "Gordo",
      "Knoxville",
      "Coatopa",
      "Mc shan",
      "Zcta 35473",
      "Cypress",
      "Zcta 35475",
      "Northport",
      "Panola",
      "Ralph",
      "Reform",
      "Vance",
      "Zcta 354hh",
      "Zcta 354xx",
      "Jasper",
      "Zcta 35503",
      "Zcta 35504",
      "Addison",
      "Arley",
      "Bankston",
      "Bear creek",
      "Beaverton",
      "Belk",
      "Berry",
      "Brilliant",
      "Carbon hill",
      "Cordova",
      "Detroit",
      "Double springs",
      "Eldridge",
      "Fayette",
      "Glen allen",
      "Guin",
      "Hackleburg",
      "Haleyville",
      "Hamilton",
      "Hodges",
      "Houston",
      "Kennedy",
      "Lynn",
      "Millport",
      "Nauvoo",
      "Oakman",
      "Parrish",
      "Phil campbell",
      "Red bay",
      "Sipsey",
      "Spruce pine",
      "Sulligent",
      "Townley",
      "Vernon",
      "Vina",
      "Winfield",
      "Zcta 355hh",
      "Zcta 355xx",
      "Decatur",
      "Anderson",
      "Athens",
      "Zcta 35613",
      "Zcta 35614",
      "Cherokee",
      "Courtland",
      "Danville",
      "Elkmont",
      "Eva",
      "Falkville",
      "Florence",
      "Zcta 35634",
      "Hartselle",
      "Hillsboro",
      "Killen",
      "Leighton",
      "Lester",
      "Lexington",
      "Mooresville",
      "Moulton",
      "Mount hope",
      "Rogersville",
      "Russellville",
      "Zcta 35654",
      "Sheffield",
      "Muscle shoals",
      "Somerville",
      "Tanner",
      "Town creek",
      "Trinity",
      "Tuscumbia",
      "Waterloo",
      "Zcta 356hh",
      "Zcta 356xx",
      "Ardmore",
      "Bridgeport",
      "Brownsboro",
      "Capshaw",
      "Dutton",
      "Estillfork",
      "Fackler",
      "Grant",
      "Gurley",
      "Harvest",
      "Hazel green",
      "Hollytree",
      "Hollywood",
      "Laceys spring",
      "Langston",
      "Zcta 35756",
      "Zcta 35757",
      "Triana",
      "Meridianville",
      "New hope",
      "New market",
      "Big cove",
      "Paint rock",
      "Pisgah",
      "Princeton",
      "Hytop",
      "Zcta 35769",
      "Section",
      "Stevenson",
      "Toney",
      "Trenton",
      "Valhermoso sprin",
      "Woodville",
      "Zcta 357hh",
      "Zcta 357xx",
      "Huntsville",
      "Zcta 358hh",
      "Zcta 358xx",
      "Southside",
      "Hokes bluff",
      "Gadsden",
      "Glencoe",
      "Rainbow city",
      "Zcta 35907",
      "Albertville",
      "Zcta 35951",
      "Snead",
      "Ashville",
      "Attalla",
      "Zcta 35956",
      "Boaz",
      "Bryant",
      "Cedar bluff",
      "Centre",
      "Collinsville",
      "Crossville",
      "Dawson",
      "Flat rock",
      "Fort payne",
      "Zcta 35968",
      "Fyffe",
      "Gallant",
      "Gaylesville",
      "Geraldine",
      "Groveoak",
      "Guntersville",
      "Henagar",
      "Higdon",
      "Horton",
      "Ider",
      "Leesburg",
      "Mentone",
      "Rainsville",
      "Steele",
      "Sylvania",
      "Valley head",
      "Walnut grove",
      "Zcta 359hh",
      "Autaugaville",
      "Banks",
      "Billingsley",
      "Brantley",
      "Brundidge",
      "Cecil",
      "Clayton",
      "Clio",
      "Coosada",
      "Deatsville",
      "Eclectic",
      "Elmore",
      "Equality",
      "Eufaula",
      "Dozier",
      "Fitzpatrick",
      "Forest home",
      "Fort davis",
      "Fort deposit",
      "Georgiana",
      "Glenwood",
      "Goshen",
      "Grady",
      "Greenville",
      "Hardaway",
      "Hayneville",
      "Highland home",
      "Honoraville",
      "Hope hull",
      "Lapine",
      "Letohatchee",
      "Louisville",
      "Luverne",
      "Marbury",
      "Mathews",
      "Midway",
      "Millbrook",
      "Pike road",
      "Pine level",
      "Prattville",
      "Ramer",
      "Rutledge",
      "Shorter",
      "Tallassee",
      "Zcta 36079",
      "Titus",
      "Troy",
      "Tuskegee",
      "Tuskegee institu",
      "Union springs",
      "Verbena",
      "Wetumpka",
      "Zcta 36093",
      "Zcta 360hh",
      "Zcta 360xx",
      "Montgomery",
      "Maxwell a f b",
      "Gunter afs",
      "Zcta 361hh",
      "Anniston",
      "Oxford",
      "Fort mc clellan",
      "Zcta 36207",
      "Alexandria",
      "Ashland",
      "Cragford",
      "Daviston",
      "Delta",
      "Eastaboga",
      "Fruithurst",
      "Graham",
      "Heflin",
      "Jacksonville",
      "Lineville",
      "Millerville",
      "Munford",
      "Muscadine",
      "Newell",
      "Ohatchee",
      "Piedmont",
      "Ranburne",
      "Rock mills",
      "Wadley",
      "Weaver",
      "Wedowee",
      "Wellington",
      "Woodland",
      "Zcta 362hh",
      "Zcta 362xx",
      "Taylor",
      "Napier field",
      "Zcta 36305",
      "Abbeville",
      "Ariton",
      "Ashford",
      "Bellwood",
      "Black",
      "Chancellor",
      "Clopton",
      "Coffee springs",
      "Columbia",
      "Cottonwood",
      "Daleville",
      "Elba",
      "Enterprise",
      "Geneva",
      "Gordon",
      "Hartford",
      "Headland",
      "Jack",
      "Midland city",
      "New brockton",
      "Newton",
      "Newville",
      "Ozark",
      "Fort rucker",
      "Pansey",
      "Pinckard",
      "Shorterville",
      "Skipperville",
      "Slocomb",
      "Webb",
      "Zcta 363hh",
      "Evergreen",
      "Andalusia",
      "Beatrice",
      "East brewton",
      "Castleberry",
      "Coy",
      "Dickinson",
      "Flomaton",
      "Florala",
      "Franklin",
      "Frisco city",
      "Fulton",
      "Grove hill",
      "Kinston",
      "Lockhart",
      "Mc kenzie",
      "Monroeville",
      "Opp",
      "Perdue hill",
      "Peterman",
      "Range",
      "Red level",
      "Repton",
      "River falls",
      "Samson",
      "Uriah",
      "Vredenburgh",
      "Whatley",
      "Wing",
      "Zcta 364hh",
      "Zcta 364xx",
      "Alma",
      "Atmore",
      "Axis",
      "Bay minette",
      "Bayou la batre",
      "Bon secour",
      "Calvert",
      "Carlton",
      "Chatom",
      "Chunchula",
      "Citronelle",
      "Coden",
      "Coffeeville",
      "Creola",
      "Daphne",
      "Spanish fort",
      "Dauphin island",
      "Deer park",
      "Elberta",
      "Fairhope",
      "Foley",
      "Frankville",
      "Fruitdale",
      "Gainestown",
      "Grand bay",
      "Fort morgan",
      "Irvington",
      "Jackson",
      "Leroy",
      "Lillian",
      "Little river",
      "Loxley",
      "Mc intosh",
      "Malcolm",
      "Millry",
      "Montrose",
      "Mount vernon",
      "Orange beach",
      "Perdido",
      "Point clear",
      "Robertsdale",
      "Saint stephens",
      "Salitpa",
      "Saraland",
      "Satsuma",
      "Seminole",
      "Semmes",
      "Silverhill",
      "Zcta 36577",
      "Stapleton",
      "Stockton",
      "Summerdale",
      "Sunflower",
      "Theodore",
      "Tibbie",
      "Vinegar bend",
      "Wagarville",
      "Wilmer",
      "Zcta 365hh",
      "Zcta 365xx",
      "Mobile",
      "Prichard",
      "Chickasaw",
      "Eight mile",
      "Zcta 366hh",
      "Selma",
      "Alberta",
      "Arlington",
      "Boykin",
      "Camden",
      "Campbell",
      "Catherine",
      "Demopolis",
      "Dixons mills",
      "Faunsdale",
      "Forkland",
      "Gallion",
      "Greensboro",
      "Linden",
      "Jones",
      "Maplesville",
      "Lower peach tree",
      "Burkville",
      "Magnolia",
      "Marion",
      "Plantersville",
      "Marion junction",
      "Boys ranch",
      "Myrtlewood",
      "Newbern",
      "Orrville",
      "Pine apple",
      "Pine hill",
      "Safford",
      "Sardis",
      "Sawyerville",
      "Sprott",
      "Sweet water",
      "Thomaston",
      "Thomasville",
      "Benton",
      "Uniontown",
      "Stanton",
      "Randolph",
      "Lawley",
      "Zcta 367hh",
      "Zcta 367xx",
      "Opelika",
      "Zcta 36804",
      "Auburn",
      "Camp hill",
      "Cusseta",
      "Dadeville",
      "Valley",
      "Five points",
      "Fort mitchell",
      "Hatchechubbee",
      "Hurtsboro",
      "Jacksons gap",
      "Lafayette",
      "Lanett",
      "Notasulga",
      "Phenix city",
      "Zcta 36870",
      "Pittsview",
      "Salem",
      "Seale",
      "Smiths",
      "Waverly",
      "Zcta 368hh",
      "Bellamy",
      "Butler",
      "Cuba",
      "Gilbertown",
      "Jachin",
      "Lisman",
      "Needham",
      "Pennington",
      "Silas",
      "Toxey",
      "Ward",
      "York",
      "Zcta 369hh",
      "Zcta 369xx",
      "",
    ],
    ZipCodes: {
      Acmar: ["35004"],
      Adamsville: ["35005"],
      Adger: ["35006"],
      Keystone: ["35007"],
      "New site": ["35010"],
      Alpine: ["35014"],
      Arab: ["35016"],
      Baileyton: ["35019"],
      Bessemer: ["35020"],
      "Zcta 35022": ["35022"],
      Hueytown: ["35023"],
      Blountsville: ["35031"],
      Bremen: ["35033"],
      Brent: ["35034"],
      Brierfield: ["35035"],
      Brookside: ["35036"],
      Calera: ["35040"],
      Centreville: ["35042"],
      Chelsea: ["35043"],
      "Coosa pines": ["35044"],
      Clanton: ["35045"],
      "Zcta 35046": ["35046"],
      Cleveland: ["35049"],
      Columbiana: ["35051"],
      "Cook springs": ["35052"],
      "Crane hill": ["35053"],
      Cropwell: ["35054"],
      Cullman: ["35055", "35057"],
      "Zcta 35058": ["35058"],
      Docena: ["35060"],
      Dolomite: ["35061"],
      Dora: ["35062"],
      Empire: ["35063"],
      Fairfield: ["35064"],
      Coalburg: ["35068"],
      "Garden city": ["35070"],
      Gardendale: ["35071"],
      Goodwater: ["35072"],
      Alden: ["35073"],
      "Green pond": ["35074"],
      Hanceville: ["35077"],
      Harpersville: ["35078"],
      Hayden: ["35079"],
      Helena: ["35080"],
      Hollins: ["35082"],
      "Holly pond": ["35083"],
      Jemison: ["35085"],
      Joppa: ["35087"],
      Kellyton: ["35089"],
      Kimberly: ["35091"],
      Leeds: ["35094"],
      Lincoln: ["35096"],
      "Locust fork": ["35097"],
      Logan: ["35098"],
      "Zcta 350hh": ["350HH"],
      "Zcta 350xx": ["350XX"],
      "Mc calla": ["35111"],
      Margaret: ["35112"],
      Maylene: ["35114"],
      Montevallo: ["35115"],
      Morris: ["35116"],
      "Mount olive": ["35117"],
      "Sylvan springs": ["35118"],
      "New castle": ["35119"],
      Odenville: ["35120"],
      Oneonta: ["35121"],
      "Indian springs": ["35124"],
      "Pell city": ["35125"],
      Dixiana: ["35126"],
      "Pleasant grove": ["35127"],
      "Zcta 35128": ["35128"],
      Quinton: ["35130"],
      Ragland: ["35131"],
      Remlap: ["35133"],
      Riverside: ["35135"],
      Rockford: ["35136"],
      Sayre: ["35139"],
      Shelby: ["35143"],
      Springville: ["35146"],
      Sterrett: ["35147"],
      Sumiton: ["35148"],
      Sycamore: ["35149"],
      Sylacauga: ["35150"],
      "Zcta 35151": ["35151"],
      Talladega: ["35160"],
      Thorsby: ["35171"],
      Trafford: ["35172"],
      Trussville: ["35173"],
      "Union grove": ["35175"],
      Vandiver: ["35176"],
      Vincent: ["35178"],
      Vinemont: ["35179"],
      Warrior: ["35180"],
      Weogufka: ["35183"],
      "West blocton": ["35184"],
      Wilsonville: ["35186"],
      Woodstock: ["35188"],
      "Zcta 351hh": ["351HH"],
      "Zcta 351xx": ["351XX"],
      Birmingham: [
        "35203",
        "35204",
        "35205",
        "35206",
        "35207",
        "35208",
        "35211",
        "35212",
        "35214",
        "35217",
        "35218",
        "35221",
        "35222",
        "35224",
        "35233",
        "35234",
      ],
      Homewood: ["35209"],
      Irondale: ["35210"],
      "Crestline height": ["35213"],
      "Center point": ["35215", "35235"],
      "Vestavia hills": ["35216"],
      "Mountain brook": ["35223"],
      "Bluff park": ["35226"],
      Midfield: ["35228"],
      "Shoal creek": ["35242"],
      "Cahaba heights": ["35243"],
      Hoover: ["35244"],
      "Zcta 352hh": ["352HH"],
      Tuscaloosa: ["35401", "35403", "35405", "35406"],
      Holt: ["35404"],
      Stewart: ["35441"],
      Aliceville: ["35442"],
      Boligee: ["35443"],
      Brookwood: ["35444"],
      Buhl: ["35446"],
      Carrollton: ["35447"],
      Coker: ["35452"],
      Cottondale: ["35453"],
      Duncanville: ["35456"],
      Echola: ["35457"],
      Elrod: ["35458"],
      Emelle: ["35459"],
      Epes: ["35460"],
      Ethelsville: ["35461"],
      Eutaw: ["35462"],
      Fosters: ["35463"],
      Gainesville: ["35464"],
      Gordo: ["35466"],
      Knoxville: ["35469"],
      Coatopa: ["35470"],
      "Mc shan": ["35471"],
      "Zcta 35473": ["35473"],
      Cypress: ["35474"],
      "Zcta 35475": ["35475"],
      Northport: ["35476"],
      Panola: ["35477"],
      Ralph: ["35480"],
      Reform: ["35481"],
      Vance: ["35490"],
      "Zcta 354hh": ["354HH"],
      "Zcta 354xx": ["354XX"],
      Jasper: ["35501"],
      "Zcta 35503": ["35503"],
      "Zcta 35504": ["35504"],
      Addison: ["35540"],
      Arley: ["35541"],
      Bankston: ["35542"],
      "Bear creek": ["35543"],
      Beaverton: ["35544"],
      Belk: ["35545"],
      Berry: ["35546"],
      Brilliant: ["35548"],
      "Carbon hill": ["35549"],
      Cordova: ["35550"],
      Detroit: ["35552"],
      "Double springs": ["35553"],
      Eldridge: ["35554"],
      Fayette: ["35555"],
      "Glen allen": ["35559"],
      Guin: ["35563"],
      Hackleburg: ["35564"],
      Haleyville: ["35565"],
      Hamilton: ["35570"],
      Hodges: ["35571"],
      Houston: ["35572"],
      Kennedy: ["35574"],
      Lynn: ["35575"],
      Millport: ["35576"],
      Nauvoo: ["35578"],
      Oakman: ["35579"],
      Parrish: ["35580"],
      "Phil campbell": ["35581"],
      "Red bay": ["35582"],
      Sipsey: ["35584"],
      "Spruce pine": ["35585"],
      Sulligent: ["35586"],
      Townley: ["35587"],
      Vernon: ["35592"],
      Vina: ["35593"],
      Winfield: ["35594"],
      "Zcta 355hh": ["355HH"],
      "Zcta 355xx": ["355XX"],
      Decatur: ["35601", "35603"],
      Anderson: ["35610"],
      Athens: ["35611"],
      "Zcta 35613": ["35613"],
      "Zcta 35614": ["35614"],
      Cherokee: ["35616"],
      Courtland: ["35618"],
      Danville: ["35619"],
      Elkmont: ["35620"],
      Eva: ["35621"],
      Falkville: ["35622"],
      Florence: ["35630", "35633"],
      "Zcta 35634": ["35634"],
      Hartselle: ["35640"],
      Hillsboro: ["35643"],
      Killen: ["35645"],
      Leighton: ["35646"],
      Lester: ["35647"],
      Lexington: ["35648"],
      Mooresville: ["35649"],
      Moulton: ["35650"],
      "Mount hope": ["35651"],
      Rogersville: ["35652"],
      Russellville: ["35653"],
      "Zcta 35654": ["35654"],
      Sheffield: ["35660"],
      "Muscle shoals": ["35661"],
      Somerville: ["35670"],
      Tanner: ["35671"],
      "Town creek": ["35672"],
      Trinity: ["35673"],
      Tuscumbia: ["35674"],
      Waterloo: ["35677"],
      "Zcta 356hh": ["356HH"],
      "Zcta 356xx": ["356XX"],
      Ardmore: ["35739"],
      Bridgeport: ["35740"],
      Brownsboro: ["35741"],
      Capshaw: ["35742"],
      Dutton: ["35744"],
      Estillfork: ["35745"],
      Fackler: ["35746"],
      Grant: ["35747"],
      Gurley: ["35748"],
      Harvest: ["35749"],
      "Hazel green": ["35750"],
      Hollytree: ["35751"],
      Hollywood: ["35752"],
      "Laceys spring": ["35754"],
      Langston: ["35755"],
      "Zcta 35756": ["35756"],
      "Zcta 35757": ["35757"],
      Triana: ["35758"],
      Meridianville: ["35759"],
      "New hope": ["35760"],
      "New market": ["35761"],
      "Big cove": ["35763"],
      "Paint rock": ["35764"],
      Pisgah: ["35765"],
      Princeton: ["35766"],
      Hytop: ["35768"],
      "Zcta 35769": ["35769"],
      Section: ["35771"],
      Stevenson: ["35772"],
      Toney: ["35773"],
      Trenton: ["35774"],
      "Valhermoso sprin": ["35775"],
      Woodville: ["35776"],
      "Zcta 357hh": ["357HH"],
      "Zcta 357xx": ["357XX"],
      Huntsville: [
        "35801",
        "35802",
        "35803",
        "35805",
        "35806",
        "35808",
        "35810",
        "35811",
        "35816",
        "35824",
      ],
      "Zcta 358hh": ["358HH"],
      "Zcta 358xx": ["358XX"],
      Southside: ["35901"],
      "Hokes bluff": ["35903"],
      Gadsden: ["35904"],
      Glencoe: ["35905"],
      "Rainbow city": ["35906"],
      "Zcta 35907": ["35907"],
      Albertville: ["35950"],
      "Zcta 35951": ["35951"],
      Snead: ["35952"],
      Ashville: ["35953"],
      Attalla: ["35954"],
      "Zcta 35956": ["35956"],
      Boaz: ["35957"],
      Bryant: ["35958"],
      "Cedar bluff": ["35959"],
      Centre: ["35960"],
      Collinsville: ["35961"],
      Crossville: ["35962"],
      Dawson: ["35963"],
      "Flat rock": ["35966"],
      "Fort payne": ["35967"],
      "Zcta 35968": ["35968"],
      Fyffe: ["35971"],
      Gallant: ["35972"],
      Gaylesville: ["35973"],
      Geraldine: ["35974"],
      Groveoak: ["35975"],
      Guntersville: ["35976"],
      Henagar: ["35978"],
      Higdon: ["35979"],
      Horton: ["35980"],
      Ider: ["35981"],
      Leesburg: ["35983"],
      Mentone: ["35984"],
      Rainsville: ["35986"],
      Steele: ["35987"],
      Sylvania: ["35988"],
      "Valley head": ["35989"],
      "Walnut grove": ["35990"],
      "Zcta 359hh": ["359HH"],
      Autaugaville: ["36003"],
      Banks: ["36005"],
      Billingsley: ["36006"],
      Brantley: ["36009"],
      Brundidge: ["36010"],
      Cecil: ["36013"],
      Clayton: ["36016"],
      Clio: ["36017"],
      Coosada: ["36020"],
      Deatsville: ["36022"],
      Eclectic: ["36024"],
      Elmore: ["36025"],
      Equality: ["36026"],
      Eufaula: ["36027"],
      Dozier: ["36028"],
      Fitzpatrick: ["36029"],
      "Forest home": ["36030"],
      "Fort davis": ["36031"],
      "Fort deposit": ["36032"],
      Georgiana: ["36033"],
      Glenwood: ["36034"],
      Goshen: ["36035"],
      Grady: ["36036"],
      Greenville: ["36037"],
      Hardaway: ["36039"],
      Hayneville: ["36040"],
      "Highland home": ["36041"],
      Honoraville: ["36042"],
      "Hope hull": ["36043"],
      Lapine: ["36046"],
      Letohatchee: ["36047"],
      Louisville: ["36048"],
      Luverne: ["36049"],
      Marbury: ["36051"],
      Mathews: ["36052"],
      Midway: ["36053"],
      Millbrook: ["36054"],
      "Pike road": ["36064"],
      "Pine level": ["36065"],
      Prattville: ["36066", "36067"],
      Ramer: ["36069"],
      Rutledge: ["36071"],
      Shorter: ["36075"],
      Tallassee: ["36078"],
      "Zcta 36079": ["36079"],
      Titus: ["36080"],
      Troy: ["36081"],
      Tuskegee: ["36083"],
      "Tuskegee institu": ["36088"],
      "Union springs": ["36089"],
      Verbena: ["36091"],
      Wetumpka: ["36092"],
      "Zcta 36093": ["36093"],
      "Zcta 360hh": ["360HH"],
      "Zcta 360xx": ["360XX"],
      Montgomery: [
        "36104",
        "36105",
        "36106",
        "36107",
        "36108",
        "36109",
        "36110",
        "36111",
        "36116",
        "36117",
      ],
      "Maxwell a f b": ["36113"],
      "Gunter afs": ["36115"],
      "Zcta 361hh": ["361HH"],
      Anniston: ["36201", "36206"],
      Oxford: ["36203"],
      "Fort mc clellan": ["36205"],
      "Zcta 36207": ["36207"],
      Alexandria: ["36250"],
      Ashland: ["36251"],
      Cragford: ["36255"],
      Daviston: ["36256"],
      Delta: ["36258"],
      Eastaboga: ["36260"],
      Fruithurst: ["36262"],
      Graham: ["36263"],
      Heflin: ["36264"],
      Jacksonville: ["36265"],
      Lineville: ["36266"],
      Millerville: ["36267"],
      Munford: ["36268"],
      Muscadine: ["36269"],
      Newell: ["36270"],
      Ohatchee: ["36271"],
      Piedmont: ["36272"],
      Ranburne: ["36273"],
      "Rock mills": ["36274"],
      Wadley: ["36276"],
      Weaver: ["36277"],
      Wedowee: ["36278"],
      Wellington: ["36279"],
      Woodland: ["36280"],
      "Zcta 362hh": ["362HH"],
      "Zcta 362xx": ["362XX"],
      Taylor: ["36301"],
      "Napier field": ["36303"],
      "Zcta 36305": ["36305"],
      Abbeville: ["36310"],
      Ariton: ["36311"],
      Ashford: ["36312"],
      Bellwood: ["36313"],
      Black: ["36314"],
      Chancellor: ["36316"],
      Clopton: ["36317"],
      "Coffee springs": ["36318"],
      Columbia: ["36319"],
      Cottonwood: ["36320"],
      Daleville: ["36322"],
      Elba: ["36323"],
      Enterprise: ["36330"],
      Geneva: ["36340"],
      Gordon: ["36343"],
      Hartford: ["36344"],
      Headland: ["36345"],
      Jack: ["36346"],
      "Midland city": ["36350"],
      "New brockton": ["36351"],
      Newton: ["36352"],
      Newville: ["36353"],
      Ozark: ["36360"],
      "Fort rucker": ["36362"],
      Pansey: ["36370"],
      Pinckard: ["36371"],
      Shorterville: ["36373"],
      Skipperville: ["36374"],
      Slocomb: ["36375"],
      Webb: ["36376"],
      "Zcta 363hh": ["363HH"],
      Evergreen: ["36401"],
      Andalusia: ["36420"],
      Beatrice: ["36425"],
      "East brewton": ["36426"],
      Castleberry: ["36432"],
      Coy: ["36435"],
      Dickinson: ["36436"],
      Flomaton: ["36441"],
      Florala: ["36442"],
      Franklin: ["36444"],
      "Frisco city": ["36445"],
      Fulton: ["36446"],
      "Grove hill": ["36451"],
      Kinston: ["36453"],
      Lockhart: ["36455"],
      "Mc kenzie": ["36456"],
      Monroeville: ["36460"],
      Opp: ["36467"],
      "Perdue hill": ["36470"],
      Peterman: ["36471"],
      Range: ["36473"],
      "Red level": ["36474"],
      Repton: ["36475"],
      "River falls": ["36476"],
      Samson: ["36477"],
      Uriah: ["36480"],
      Vredenburgh: ["36481"],
      Whatley: ["36482"],
      Wing: ["36483"],
      "Zcta 364hh": ["364HH"],
      "Zcta 364xx": ["364XX"],
      Alma: ["36501"],
      Atmore: ["36502"],
      Axis: ["36505"],
      "Bay minette": ["36507"],
      "Bayou la batre": ["36509"],
      "Bon secour": ["36511"],
      Calvert: ["36513"],
      Carlton: ["36515"],
      Chatom: ["36518"],
      Chunchula: ["36521"],
      Citronelle: ["36522"],
      Coden: ["36523"],
      Coffeeville: ["36524"],
      Creola: ["36525"],
      Daphne: ["36526"],
      "Spanish fort": ["36527"],
      "Dauphin island": ["36528"],
      "Deer park": ["36529"],
      Elberta: ["36530"],
      Fairhope: ["36532"],
      Foley: ["36535"],
      Frankville: ["36538"],
      Fruitdale: ["36539"],
      Gainestown: ["36540"],
      "Grand bay": ["36541"],
      "Fort morgan": ["36542"],
      Irvington: ["36544"],
      Jackson: ["36545"],
      Leroy: ["36548"],
      Lillian: ["36549"],
      "Little river": ["36550"],
      Loxley: ["36551"],
      "Mc intosh": ["36553"],
      Malcolm: ["36556"],
      Millry: ["36558"],
      Montrose: ["36559"],
      "Mount vernon": ["36560"],
      "Orange beach": ["36561"],
      Perdido: ["36562"],
      "Point clear": ["36564"],
      Robertsdale: ["36567"],
      "Saint stephens": ["36569"],
      Salitpa: ["36570"],
      Saraland: ["36571"],
      Satsuma: ["36572"],
      Seminole: ["36574"],
      Semmes: ["36575"],
      Silverhill: ["36576"],
      "Zcta 36577": ["36577"],
      Stapleton: ["36578"],
      Stockton: ["36579"],
      Summerdale: ["36580"],
      Sunflower: ["36581"],
      Theodore: ["36582"],
      Tibbie: ["36583"],
      "Vinegar bend": ["36584"],
      Wagarville: ["36585"],
      Wilmer: ["36587"],
      "Zcta 365hh": ["365HH"],
      "Zcta 365xx": ["365XX"],
      Mobile: [
        "36602",
        "36603",
        "36604",
        "36605",
        "36606",
        "36607",
        "36608",
        "36609",
        "36612",
        "36617",
        "36618",
        "36619",
        "36693",
        "36695",
      ],
      Prichard: ["36610"],
      Chickasaw: ["36611"],
      "Eight mile": ["36613"],
      "Zcta 366hh": ["366HH"],
      Selma: ["36701", "36703"],
      Alberta: ["36720"],
      Arlington: ["36722"],
      Boykin: ["36723"],
      Camden: ["36726"],
      Campbell: ["36727"],
      Catherine: ["36728"],
      Demopolis: ["36732"],
      "Dixons mills": ["36736"],
      Faunsdale: ["36738"],
      Forkland: ["36740"],
      Gallion: ["36742"],
      Greensboro: ["36744"],
      Linden: ["36748"],
      Jones: ["36749"],
      Maplesville: ["36750"],
      "Lower peach tree": ["36751"],
      Burkville: ["36752"],
      Magnolia: ["36754"],
      Marion: ["36756"],
      Plantersville: ["36758"],
      "Marion junction": ["36759"],
      "Boys ranch": ["36761"],
      Myrtlewood: ["36763"],
      Newbern: ["36765"],
      Orrville: ["36767"],
      "Pine apple": ["36768"],
      "Pine hill": ["36769"],
      Safford: ["36773"],
      Sardis: ["36775"],
      Sawyerville: ["36776"],
      Sprott: ["36779"],
      "Sweet water": ["36782"],
      Thomaston: ["36783"],
      Thomasville: ["36784"],
      Benton: ["36785"],
      Uniontown: ["36786"],
      Stanton: ["36790"],
      Randolph: ["36792"],
      Lawley: ["36793"],
      "Zcta 367hh": ["367HH"],
      "Zcta 367xx": ["367XX"],
      Opelika: ["36801"],
      "Zcta 36804": ["36804"],
      Auburn: ["36830", "36832", "36849"],
      "Camp hill": ["36850"],
      Cusseta: ["36852"],
      Dadeville: ["36853"],
      Valley: ["36854"],
      "Five points": ["36855"],
      "Fort mitchell": ["36856"],
      Hatchechubbee: ["36858"],
      Hurtsboro: ["36860"],
      "Jacksons gap": ["36861"],
      Lafayette: ["36862"],
      Lanett: ["36863"],
      Notasulga: ["36866"],
      "Phenix city": ["36867", "36869"],
      "Zcta 36870": ["36870"],
      Pittsview: ["36871"],
      Salem: ["36874"],
      Seale: ["36875"],
      Smiths: ["36877"],
      Waverly: ["36879"],
      "Zcta 368hh": ["368HH"],
      Bellamy: ["36901"],
      Butler: ["36904"],
      Cuba: ["36907"],
      Gilbertown: ["36908"],
      Jachin: ["36910"],
      Lisman: ["36912"],
      Needham: ["36915"],
      Pennington: ["36916"],
      Silas: ["36919"],
      Toxey: ["36921"],
      Ward: ["36922"],
      York: ["36925"],
      "Zcta 369hh": ["369HH"],
      "Zcta 369xx": ["369XX"],
      "": ["38852"],
    },
  },
  {
    abbrevation: "AK",
    name: "Alaska",
    Cities: [
      "Anchorage",
      "Anchorage",
      "Fort richardson",
      "Elmendorf afb",
      "Indian",
      "Zcta 99546",
      "Atka",
      "Chignik lake",
      "Port heiden",
      "Port lions",
      "Akiachak",
      "Akiak",
      "Akutan",
      "Alakanuk",
      "Aleknagik",
      "Nikolaevsk",
      "Chuathbaluk",
      "Anvik",
      "Atmautluak",
      "Chefornak",
      "Chevak",
      "Chignik",
      "Chignik lagoon",
      "Chitina",
      "Chugiak",
      "Clam gulch",
      "Clarks point",
      "Nelson lagoon",
      "Cooper landing",
      "Copper center",
      "Chenega bay",
      "Crooked creek",
      "Koliganek",
      "Eagle river",
      "Eek",
      "Egegik",
      "Ekwok",
      "Emmonak",
      "False pass",
      "Marshall",
      "Slana",
      "Girdwood",
      "Glennallen",
      "Goodnews bay",
      "Grayling",
      "Saint george isl",
      "Zcta 995hh",
      "Zcta 995xx",
      "Holy cross",
      "Port graham",
      "Hooper bay",
      "Hope",
      "Kokhanok",
      "Kalskag",
      "Karluk",
      "Kasigluk",
      "Kasilof",
      "Kenai",
      "King cove",
      "Igiugig",
      "Kipnuk",
      "Akhiok",
      "Kotlik",
      "Kwethluk",
      "Kwigillingok",
      "Larsen bay",
      "Levelock",
      "Lower kalskag",
      "Mc grath",
      "Manokotak",
      "Mekoryuk",
      "Moose pass",
      "Mountain village",
      "Naknek",
      "Napakiak",
      "Nikiski",
      "New stuyahok",
      "Toksook bay",
      "Nikolski",
      "Ninilchik",
      "Nondalton",
      "Nunapitchuk",
      "Old harbor",
      "Ouzinkie",
      "Butte",
      "Pedro bay",
      "Perryville",
      "Pilot point",
      "Pilot station",
      "Platinum",
      "Big lake",
      "Port alsworth",
      "Wasilla",
      "Quinhagak",
      "Red devil",
      "Russian mission",
      "Saint marys",
      "Saint michael",
      "Saint paul islan",
      "Sand point",
      "Scammon bay",
      "Seldovia",
      "Seward",
      "Shageluk",
      "Sheldon point",
      "Skwentna",
      "Sleetmute",
      "Soldotna",
      "South naknek",
      "Stebbins",
      "Sterling",
      "Chickaloon",
      "Takotna",
      "Talkeetna",
      "Tatitlek",
      "Togiak",
      "Tuluksak",
      "Tuntutuliak",
      "Tununak",
      "Tyonek",
      "Trapper creek",
      "Unalakleet",
      "Unalaska",
      "Valdez",
      "Willow",
      "Yakutat",
      "Nightmute",
      "Nikolai",
      "Dutch harbor",
      "Whittier",
      "Houston",
      "Kodiak",
      "Zcta 996hh",
      "Zcta 996xx",
      "Coldfoot",
      "Eielson afb",
      "Fort wainwright",
      "Clear",
      "North pole",
      "Fairbanks",
      "Salcha",
      "Allakaket",
      "Anaktuvuk pass",
      "Arctic village",
      "Barrow",
      "Beaver",
      "Ester",
      "Bettles field",
      "Buckland",
      "Cantwell",
      "Central",
      "Chicken",
      "Circle",
      "Deering",
      "Dot lake",
      "Eagle",
      "Elim",
      "Fort yukon",
      "Galena",
      "Gambell",
      "Healy",
      "Anderson",
      "Hughes",
      "Huslia",
      "Kaktovik",
      "Kaltag",
      "Kiana",
      "Kivalina",
      "Kobuk",
      "Kotzebue",
      "Koyuk",
      "Koyukuk",
      "Denali national",
      "Manley hot sprin",
      "Lake minchumina",
      "Minto",
      "Point lay",
      "Nenana",
      "Noatak",
      "Golovin",
      "Noorvik",
      "Northway",
      "Nulato",
      "Point hope",
      "Rampart",
      "Ruby",
      "Savoonga",
      "Selawik",
      "Shaktoolik",
      "Shishmaref",
      "Shungnak",
      "Stevens village",
      "Tanacross",
      "Tanana",
      "Teller",
      "Tetlin",
      "Border",
      "Venetie",
      "Wainwright",
      "Wales",
      "White mountain",
      "Brevig mission",
      "Ambler",
      "Chalkyitsik",
      "Nuiqsut",
      "Atqasuk",
      "Zcta 997hh",
      "Zcta 997xx",
      "Juneau",
      "Angoon",
      "Douglas",
      "Elfin cove",
      "Gustavus",
      "Haines",
      "Hoonah",
      "Kake",
      "Pelican",
      "Petersburg",
      "Sitka",
      "Skagway",
      "Tenakee springs",
      "Zcta 998hh",
      "Zcta 998xx",
      "Ketchikan",
      "Meyers chuck",
      "Coffman cove",
      "Thorne bay",
      "Craig",
      "Hydaburg",
      "Hyder",
      "Klawock",
      "Metlakatla",
      "Point baker",
      "Wrangell",
      "Zcta 999hh",
      "Zcta 999xx",
    ],
    ZipCodes: {
      Anchorage: [
        "99501",
        "99502",
        "99503",
        "99504",
        "99507",
        "99508",
        "99513",
        "99515",
        "99516",
        "99517",
        "99518",
        "99695",
      ],
      "Fort richardson": ["99505"],
      "Elmendorf afb": ["99506"],
      Indian: ["99540"],
      "Zcta 99546": ["99546"],
      Atka: ["99547"],
      "Chignik lake": ["99548"],
      "Port heiden": ["99549"],
      "Port lions": ["99550"],
      Akiachak: ["99551"],
      Akiak: ["99552"],
      Akutan: ["99553"],
      Alakanuk: ["99554"],
      Aleknagik: ["99555"],
      Nikolaevsk: ["99556"],
      Chuathbaluk: ["99557"],
      Anvik: ["99558"],
      Atmautluak: ["99559"],
      Chefornak: ["99561"],
      Chevak: ["99563"],
      Chignik: ["99564"],
      "Chignik lagoon": ["99565"],
      Chitina: ["99566"],
      Chugiak: ["99567"],
      "Clam gulch": ["99568"],
      "Clarks point": ["99569"],
      "Nelson lagoon": ["99571"],
      "Cooper landing": ["99572"],
      "Copper center": ["99573"],
      "Chenega bay": ["99574"],
      "Crooked creek": ["99575"],
      Koliganek: ["99576"],
      "Eagle river": ["99577"],
      Eek: ["99578"],
      Egegik: ["99579"],
      Ekwok: ["99580"],
      Emmonak: ["99581"],
      "False pass": ["99583"],
      Marshall: ["99585"],
      Slana: ["99586"],
      Girdwood: ["99587"],
      Glennallen: ["99588"],
      "Goodnews bay": ["99589"],
      Grayling: ["99590"],
      "Saint george isl": ["99591"],
      "Zcta 995hh": ["995HH"],
      "Zcta 995xx": ["995XX"],
      "Holy cross": ["99602"],
      "Port graham": ["99603"],
      "Hooper bay": ["99604"],
      Hope: ["99605"],
      Kokhanok: ["99606"],
      Kalskag: ["99607"],
      Karluk: ["99608"],
      Kasigluk: ["99609"],
      Kasilof: ["99610"],
      Kenai: ["99611"],
      "King cove": ["99612"],
      Igiugig: ["99613"],
      Kipnuk: ["99614"],
      Akhiok: ["99615"],
      Kotlik: ["99620"],
      Kwethluk: ["99621"],
      Kwigillingok: ["99622"],
      "Larsen bay": ["99624"],
      Levelock: ["99625"],
      "Lower kalskag": ["99626"],
      "Mc grath": ["99627"],
      Manokotak: ["99628"],
      Mekoryuk: ["99630"],
      "Moose pass": ["99631"],
      "Mountain village": ["99632"],
      Naknek: ["99633"],
      Napakiak: ["99634"],
      Nikiski: ["99635"],
      "New stuyahok": ["99636"],
      "Toksook bay": ["99637"],
      Nikolski: ["99638"],
      Ninilchik: ["99639"],
      Nondalton: ["99640"],
      Nunapitchuk: ["99641"],
      "Old harbor": ["99643"],
      Ouzinkie: ["99644"],
      Butte: ["99645"],
      "Pedro bay": ["99647"],
      Perryville: ["99648"],
      "Pilot point": ["99649"],
      "Pilot station": ["99650"],
      Platinum: ["99651"],
      "Big lake": ["99652"],
      "Port alsworth": ["99653"],
      Wasilla: ["99654", "99687"],
      Quinhagak: ["99655"],
      "Red devil": ["99656"],
      "Russian mission": ["99657"],
      "Saint marys": ["99658"],
      "Saint michael": ["99659"],
      "Saint paul islan": ["99660"],
      "Sand point": ["99661"],
      "Scammon bay": ["99662"],
      Seldovia: ["99663"],
      Seward: ["99664"],
      Shageluk: ["99665"],
      "Sheldon point": ["99666"],
      Skwentna: ["99667"],
      Sleetmute: ["99668"],
      Soldotna: ["99669"],
      "South naknek": ["99670"],
      Stebbins: ["99671"],
      Sterling: ["99672"],
      Chickaloon: ["99674"],
      Takotna: ["99675"],
      Talkeetna: ["99676"],
      Tatitlek: ["99677"],
      Togiak: ["99678"],
      Tuluksak: ["99679"],
      Tuntutuliak: ["99680"],
      Tununak: ["99681"],
      Tyonek: ["99682"],
      "Trapper creek": ["99683"],
      Unalakleet: ["99684"],
      Unalaska: ["99685"],
      Valdez: ["99686"],
      Willow: ["99688"],
      Yakutat: ["99689"],
      Nightmute: ["99690"],
      Nikolai: ["99691"],
      "Dutch harbor": ["99692"],
      Whittier: ["99693"],
      Houston: ["99694"],
      Kodiak: ["99697"],
      "Zcta 996hh": ["996HH"],
      "Zcta 996xx": ["996XX"],
      Coldfoot: ["99701"],
      "Eielson afb": ["99702"],
      "Fort wainwright": ["99703"],
      Clear: ["99704"],
      "North pole": ["99705"],
      Fairbanks: ["99709", "99712", "99775"],
      Salcha: ["99714"],
      Allakaket: ["99720"],
      "Anaktuvuk pass": ["99721"],
      "Arctic village": ["99722"],
      Barrow: ["99723"],
      Beaver: ["99724"],
      Ester: ["99725"],
      "Bettles field": ["99726"],
      Buckland: ["99727"],
      Cantwell: ["99729"],
      Central: ["99730"],
      Chicken: ["99732"],
      Circle: ["99733"],
      Deering: ["99736"],
      "Dot lake": ["99737"],
      Eagle: ["99738"],
      Elim: ["99739"],
      "Fort yukon": ["99740"],
      Galena: ["99741"],
      Gambell: ["99742"],
      Healy: ["99743"],
      Anderson: ["99744"],
      Hughes: ["99745"],
      Huslia: ["99746"],
      Kaktovik: ["99747"],
      Kaltag: ["99748"],
      Kiana: ["99749"],
      Kivalina: ["99750"],
      Kobuk: ["99751"],
      Kotzebue: ["99752"],
      Koyuk: ["99753"],
      Koyukuk: ["99754"],
      "Denali national": ["99755"],
      "Manley hot sprin": ["99756"],
      "Lake minchumina": ["99757"],
      Minto: ["99758"],
      "Point lay": ["99759"],
      Nenana: ["99760"],
      Noatak: ["99761"],
      Golovin: ["99762"],
      Noorvik: ["99763"],
      Northway: ["99764"],
      Nulato: ["99765"],
      "Point hope": ["99766"],
      Rampart: ["99767"],
      Ruby: ["99768"],
      Savoonga: ["99769"],
      Selawik: ["99770"],
      Shaktoolik: ["99771"],
      Shishmaref: ["99772"],
      Shungnak: ["99773"],
      "Stevens village": ["99774"],
      Tanacross: ["99776"],
      Tanana: ["99777"],
      Teller: ["99778"],
      Tetlin: ["99779"],
      Border: ["99780"],
      Venetie: ["99781"],
      Wainwright: ["99782"],
      Wales: ["99783"],
      "White mountain": ["99784"],
      "Brevig mission": ["99785"],
      Ambler: ["99786"],
      Chalkyitsik: ["99788"],
      Nuiqsut: ["99789"],
      Atqasuk: ["99791"],
      "Zcta 997hh": ["997HH"],
      "Zcta 997xx": ["997XX"],
      Juneau: ["99801", "99850"],
      Angoon: ["99820"],
      Douglas: ["99824"],
      "Elfin cove": ["99825"],
      Gustavus: ["99826"],
      Haines: ["99827"],
      Hoonah: ["99829"],
      Kake: ["99830"],
      Pelican: ["99832"],
      Petersburg: ["99833"],
      Sitka: ["99835"],
      Skagway: ["99840"],
      "Tenakee springs": ["99841"],
      "Zcta 998hh": ["998HH"],
      "Zcta 998xx": ["998XX"],
      Ketchikan: ["99901", "99950"],
      "Meyers chuck": ["99903"],
      "Coffman cove": ["99918"],
      "Thorne bay": ["99919"],
      Craig: ["99921"],
      Hydaburg: ["99922"],
      Hyder: ["99923"],
      Klawock: ["99925"],
      Metlakatla: ["99926"],
      "Point baker": ["99927"],
      Wrangell: ["99929"],
      "Zcta 999hh": ["999HH"],
      "Zcta 999xx": ["999XX"],
    },
  },
  {
    abbrevation: "AZ",
    name: "Arizona",
    Cities: [
      "",
      "",
      "Phoenix",
      "New river stage",
      "Zcta 85087",
      "Zcta 850hh",
      "Mesa",
      "Gold canyon",
      "Apache junction",
      "Bapchule",
      "Eleven mile corn",
      "Arizona city",
      "Chandler",
      "Coolidge",
      "Casa grande",
      "Eloy",
      "Florence",
      "Gilbert",
      "Hayden",
      "Higley",
      "Kearny",
      "Mobile",
      "Picacho",
      "Arizona boys ran",
      "Red rock",
      "Sacaton",
      "Sun lakes",
      "Scottsdale",
      "Paradise valley",
      "Rio verde",
      "Fort mcdowell",
      "Fountain hills",
      "Stanfield",
      "Superior",
      "Tempe",
      "Winkelman",
      "Zcta 852hh",
      "Zcta 852xx",
      "Glendale",
      "Luke afb",
      "Aguila",
      "Why",
      "Arlington",
      "Avondale",
      "Rock springs",
      "Bouse",
      "Buckeye",
      "Cibola",
      "Cashion",
      "Cave creek",
      "Congress",
      "Dateland",
      "Ehrenberg",
      "El mirage",
      "Gadsden",
      "Gila bend",
      "Goodyear",
      "Laveen",
      "Litchfield park",
      "Morristown",
      "Empire landing",
      "Peoria",
      "Quartzsite",
      "Roll",
      "Salome",
      "San luis",
      "Somerton",
      "Sun city",
      "Tolleson",
      "Tonopah",
      "Waddell",
      "Wellton",
      "Wenden",
      "Wikieup",
      "Wittmann",
      "Yarnell",
      "Youngtown",
      "Yuma",
      "Yuma proving gro",
      "Surprise",
      "Sun city west",
      "Zcta 85379",
      "Wickenburg",
      "Zcta 853hh",
      "Zcta 853xx",
      "Globe",
      "Bylas",
      "Central",
      "Clifton",
      "Franklin",
      "Eden",
      "Fort thomas",
      "Miami",
      "Morenci",
      "Payson",
      "Peridot",
      "Pima",
      "Strawberry",
      "Roosevelt",
      "Safford",
      "San carlos",
      "Thatcher",
      "Tonto basin",
      "Young",
      "Zcta 855hh",
      "Zcta 855xx",
      "Arivaca",
      "Benson",
      "Bisbee",
      "Bowie",
      "Cochise",
      "Douglas",
      "Dragoon",
      "Elfrida",
      "Elgin",
      "Fort huachuca",
      "Green valley",
      "Hereford",
      "Huachuca city",
      "Mc neal",
      "Mammoth",
      "Mount lemmon",
      "Nogales",
      "Oracle",
      "Patagonia",
      "Pearce",
      "Pomerene",
      "Sahuarita",
      "Saint david",
      "San manuel",
      "Portal",
      "Sasabe",
      "Pisinemo",
      "Sierra vista",
      "Sonoita",
      "Tombstone",
      "Topawa",
      "Amado",
      "Vail",
      "Willcox",
      "Tubac",
      "Rio rico",
      "Zcta 85650",
      "Marana",
      "Rillito",
      "Zcta 856hh",
      "Tucson",
      "Casas adobes",
      "Oro valley",
      "Zcta 85739",
      "Show low",
      "Cibeque",
      "Alpine",
      "Blue",
      "Clay springs",
      "Concho",
      "Eagar",
      "Fort apache",
      "Greer",
      "Heber",
      "Lakeside",
      "Mc nary",
      "Forest lakes",
      "Nutrioso",
      "Overgaard",
      "Pinedale",
      "Pinetop",
      "Saint johns",
      "Snowflake",
      "Springerville",
      "Taylor",
      "Vernon",
      "Whiteriver",
      "Woodruff",
      "Flagstaff",
      "Bellemont",
      "Gray mountain",
      "Munds park",
      "Parks",
      "Cameron",
      "Colorado city",
      "Fredonia",
      "Grand canyon",
      "Happy jack",
      "Holbrook",
      "Hotevilla",
      "Indian wells",
      "Joseph city",
      "Kayenta",
      "Keams canyon",
      "Leupp",
      "Marble canyon",
      "Kykotsmovi villa",
      "Greenehaven",
      "Polacca",
      "Second mesa",
      "Tonalea",
      "Tuba city",
      "Williams",
      "Winslow",
      "North rim",
      "Kaibito",
      "Shonto",
      "Zcta 860hh",
      "Zcta 860xx",
      "Prescott",
      "Groom creek",
      "Zcta 86305",
      "Prescott valley",
      "Ash fork",
      "Bagdad",
      "Camp verde",
      "Chino valley",
      "Clarkdale",
      "Cornville",
      "Cottonwood",
      "Dewey",
      "Jerome",
      "Kirkland",
      "Mayer",
      "Paulden",
      "Rimrock",
      "Sedona",
      "Seligman",
      "Skull valley",
      "Crown king",
      "Kingman",
      "Desert hills",
      "Lake havasu city",
      "Golden valley",
      "Bullhead city",
      "Chloride",
      "Littlefield",
      "Oatman",
      "Peach springs",
      "Supai",
      "Topock",
      "Valentine",
      "Yucca",
      "Mohave valley",
      "Dolan springs",
      "Meadview",
      "Zcta 864hh",
      "Zcta 864xx",
      "Chambers",
      "Chinle",
      "Fort defiance",
      "Ganado",
      "Houck",
      "Lukachukai",
      "Lupton",
      "Pinon",
      "Saint michaels",
      "Sanders",
      "Teec nos pos",
      "Window rock",
      "Blue gap",
      "Dennehotso",
      "Many farms",
      "Nazlini",
      "Red valley",
      "Rock point",
      "Round rock",
      "Sawmill",
      "Tsaile",
    ],
    ZipCodes: {
      "": ["84536", "87328", "89024"],
      Phoenix: [
        "85003",
        "85004",
        "85006",
        "85007",
        "85008",
        "85009",
        "85012",
        "85013",
        "85014",
        "85015",
        "85016",
        "85017",
        "85018",
        "85019",
        "85020",
        "85021",
        "85022",
        "85023",
        "85024",
        "85028",
        "85029",
        "85031",
        "85032",
        "85033",
        "85034",
        "85035",
        "85037",
        "85040",
        "85041",
        "85043",
        "85044",
        "85045",
        "85048",
        "85050",
        "85051",
        "85053",
        "85054",
        "85085",
        "85086",
      ],
      "New river stage": ["85027"],
      "Zcta 85087": ["85087"],
      "Zcta 850hh": ["850HH"],
      Mesa: [
        "85201",
        "85202",
        "85203",
        "85204",
        "85205",
        "85206",
        "85207",
        "85208",
        "85210",
        "85212",
        "85213",
        "85215",
      ],
      "Gold canyon": ["85219"],
      "Apache junction": ["85220"],
      Bapchule: ["85221"],
      "Eleven mile corn": ["85222"],
      "Arizona city": ["85223"],
      Chandler: ["85224", "85225", "85226", "85249"],
      Coolidge: ["85228"],
      "Casa grande": ["85230"],
      Eloy: ["85231"],
      Florence: ["85232"],
      Gilbert: ["85233", "85234", "85296"],
      Hayden: ["85235"],
      Higley: ["85236"],
      Kearny: ["85237"],
      Mobile: ["85239"],
      Picacho: ["85241"],
      "Arizona boys ran": ["85242"],
      "Red rock": ["85245"],
      Sacaton: ["85247"],
      "Sun lakes": ["85248"],
      Scottsdale: [
        "85250",
        "85251",
        "85254",
        "85255",
        "85256",
        "85257",
        "85258",
        "85259",
        "85260",
        "85262",
      ],
      "Paradise valley": ["85253"],
      "Rio verde": ["85263"],
      "Fort mcdowell": ["85264"],
      "Fountain hills": ["85268"],
      Stanfield: ["85272"],
      Superior: ["85273"],
      Tempe: ["85281", "85282", "85283", "85284"],
      Winkelman: ["85292"],
      "Zcta 852hh": ["852HH"],
      "Zcta 852xx": ["852XX"],
      Glendale: [
        "85301",
        "85302",
        "85303",
        "85304",
        "85305",
        "85306",
        "85308",
        "85310",
      ],
      "Luke afb": ["85307", "85309"],
      Aguila: ["85320"],
      Why: ["85321"],
      Arlington: ["85322"],
      Avondale: ["85323"],
      "Rock springs": ["85324"],
      Bouse: ["85325"],
      Buckeye: ["85326"],
      Cibola: ["85328"],
      Cashion: ["85329"],
      "Cave creek": ["85331"],
      Congress: ["85332"],
      Dateland: ["85333"],
      Ehrenberg: ["85334"],
      "El mirage": ["85335"],
      Gadsden: ["85336"],
      "Gila bend": ["85337"],
      Goodyear: ["85338"],
      Laveen: ["85339"],
      "Litchfield park": ["85340"],
      Morristown: ["85342"],
      "Empire landing": ["85344"],
      Peoria: ["85345", "85381", "85382"],
      Quartzsite: ["85346"],
      Roll: ["85347"],
      Salome: ["85348"],
      "San luis": ["85349"],
      Somerton: ["85350"],
      "Sun city": ["85351", "85373"],
      Tolleson: ["85353"],
      Tonopah: ["85354"],
      Waddell: ["85355"],
      Wellton: ["85356"],
      Wenden: ["85357"],
      Wikieup: ["85360"],
      Wittmann: ["85361"],
      Yarnell: ["85362"],
      Youngtown: ["85363"],
      Yuma: ["85364", "85367"],
      "Yuma proving gro": ["85365"],
      Surprise: ["85374"],
      "Sun city west": ["85375"],
      "Zcta 85379": ["85379"],
      Wickenburg: ["85390"],
      "Zcta 853hh": ["853HH"],
      "Zcta 853xx": ["853XX"],
      Globe: ["85501"],
      Bylas: ["85530"],
      Central: ["85531"],
      Clifton: ["85533"],
      Franklin: ["85534"],
      Eden: ["85535"],
      "Fort thomas": ["85536"],
      Miami: ["85539"],
      Morenci: ["85540"],
      Payson: ["85541"],
      Peridot: ["85542"],
      Pima: ["85543"],
      Strawberry: ["85544"],
      Roosevelt: ["85545"],
      Safford: ["85546"],
      "San carlos": ["85550"],
      Thatcher: ["85552"],
      "Tonto basin": ["85553"],
      Young: ["85554"],
      "Zcta 855hh": ["855HH"],
      "Zcta 855xx": ["855XX"],
      Arivaca: ["85601"],
      Benson: ["85602"],
      Bisbee: ["85603"],
      Bowie: ["85605"],
      Cochise: ["85606"],
      Douglas: ["85607", "85608"],
      Dragoon: ["85609"],
      Elfrida: ["85610"],
      Elgin: ["85611"],
      "Fort huachuca": ["85613"],
      "Green valley": ["85614"],
      Hereford: ["85615"],
      "Huachuca city": ["85616"],
      "Mc neal": ["85617"],
      Mammoth: ["85618"],
      "Mount lemmon": ["85619"],
      Nogales: ["85621"],
      Oracle: ["85623"],
      Patagonia: ["85624"],
      Pearce: ["85625"],
      Pomerene: ["85627"],
      Sahuarita: ["85629"],
      "Saint david": ["85630"],
      "San manuel": ["85631"],
      Portal: ["85632"],
      Sasabe: ["85633"],
      Pisinemo: ["85634"],
      "Sierra vista": ["85635"],
      Sonoita: ["85637"],
      Tombstone: ["85638"],
      Topawa: ["85639"],
      Amado: ["85640", "85645"],
      Vail: ["85641"],
      Willcox: ["85643"],
      Tubac: ["85646"],
      "Rio rico": ["85648"],
      "Zcta 85650": ["85650"],
      Marana: ["85653"],
      Rillito: ["85654"],
      "Zcta 856hh": ["856HH"],
      Tucson: [
        "85701",
        "85705",
        "85706",
        "85708",
        "85710",
        "85711",
        "85712",
        "85713",
        "85714",
        "85715",
        "85716",
        "85718",
        "85719",
        "85730",
        "85735",
        "85736",
        "85741",
        "85742",
        "85743",
        "85745",
        "85746",
        "85747",
        "85748",
        "85749",
        "85750",
      ],
      "Casas adobes": ["85704"],
      "Oro valley": ["85737"],
      "Zcta 85739": ["85739"],
      "Show low": ["85901"],
      Cibeque: ["85911"],
      Alpine: ["85920"],
      Blue: ["85922"],
      "Clay springs": ["85923"],
      Concho: ["85924"],
      Eagar: ["85925"],
      "Fort apache": ["85926"],
      Greer: ["85927"],
      Heber: ["85928"],
      Lakeside: ["85929"],
      "Mc nary": ["85930"],
      "Forest lakes": ["85931"],
      Nutrioso: ["85932"],
      Overgaard: ["85933"],
      Pinedale: ["85934"],
      Pinetop: ["85935"],
      "Saint johns": ["85936"],
      Snowflake: ["85937"],
      Springerville: ["85938"],
      Taylor: ["85939"],
      Vernon: ["85940"],
      Whiteriver: ["85941"],
      Woodruff: ["85942"],
      Flagstaff: ["86001", "86004"],
      Bellemont: ["86015"],
      "Gray mountain": ["86016"],
      "Munds park": ["86017"],
      Parks: ["86018"],
      Cameron: ["86020"],
      "Colorado city": ["86021"],
      Fredonia: ["86022"],
      "Grand canyon": ["86023"],
      "Happy jack": ["86024"],
      Holbrook: ["86025"],
      Hotevilla: ["86030"],
      "Indian wells": ["86031"],
      "Joseph city": ["86032"],
      Kayenta: ["86033"],
      "Keams canyon": ["86034"],
      Leupp: ["86035"],
      "Marble canyon": ["86036"],
      "Kykotsmovi villa": ["86039"],
      Greenehaven: ["86040"],
      Polacca: ["86042"],
      "Second mesa": ["86043"],
      Tonalea: ["86044"],
      "Tuba city": ["86045"],
      Williams: ["86046"],
      Winslow: ["86047"],
      "North rim": ["86052"],
      Kaibito: ["86053"],
      Shonto: ["86054"],
      "Zcta 860hh": ["860HH"],
      "Zcta 860xx": ["860XX"],
      Prescott: ["86301"],
      "Groom creek": ["86303"],
      "Zcta 86305": ["86305"],
      "Prescott valley": ["86314"],
      "Ash fork": ["86320"],
      Bagdad: ["86321"],
      "Camp verde": ["86322"],
      "Chino valley": ["86323"],
      Clarkdale: ["86324"],
      Cornville: ["86325"],
      Cottonwood: ["86326"],
      Dewey: ["86327"],
      Jerome: ["86331"],
      Kirkland: ["86332"],
      Mayer: ["86333"],
      Paulden: ["86334"],
      Rimrock: ["86335"],
      Sedona: ["86336", "86351"],
      Seligman: ["86337"],
      "Skull valley": ["86338"],
      "Crown king": ["86343"],
      Kingman: ["86401"],
      "Desert hills": ["86403"],
      "Lake havasu city": ["86404", "86406"],
      "Golden valley": ["86413"],
      "Bullhead city": ["86426", "86429", "86430", "86442"],
      Chloride: ["86431"],
      Littlefield: ["86432"],
      Oatman: ["86433"],
      "Peach springs": ["86434"],
      Supai: ["86435"],
      Topock: ["86436"],
      Valentine: ["86437"],
      Yucca: ["86438"],
      "Mohave valley": ["86440"],
      "Dolan springs": ["86441"],
      Meadview: ["86444"],
      "Zcta 864hh": ["864HH"],
      "Zcta 864xx": ["864XX"],
      Chambers: ["86502"],
      Chinle: ["86503"],
      "Fort defiance": ["86504"],
      Ganado: ["86505"],
      Houck: ["86506"],
      Lukachukai: ["86507"],
      Lupton: ["86508"],
      Pinon: ["86510"],
      "Saint michaels": ["86511"],
      Sanders: ["86512"],
      "Teec nos pos": ["86514"],
      "Window rock": ["86515"],
      "Blue gap": ["86520"],
      Dennehotso: ["86535"],
      "Many farms": ["86538"],
      Nazlini: ["86540"],
      "Red valley": ["86544"],
      "Rock point": ["86545"],
      "Round rock": ["86547"],
      Sawmill: ["86549"],
      Tsaile: ["86556"],
    },
  },
  {
    abbrevation: "AR",
    name: "Arkansas",
    Cities: [
      "",
      "",
      "North cedar",
      "Dollarway",
      "Pine bluff",
      "Arkansas city",
      "Banks",
      "North",
      "Dermott",
      "Dumas",
      "Eudora",
      "Fountain hill",
      "Gould",
      "Tamo",
      "Hamburg",
      "Ingalls",
      "Jersey",
      "Kingsland",
      "Lake village",
      "Mc gehee",
      "Monticello",
      "University of ar",
      "Montrose",
      "Moscow",
      "New edinburg",
      "Parkdale",
      "Portland",
      "Rison",
      "Rohwer",
      "Star city",
      "Reed",
      "Warren",
      "Watson",
      "Wilmar",
      "Wilmot",
      "Winchester",
      "Zcta 716hh",
      "Zcta 716xx",
      "East camden",
      "Bearden",
      "Bluff city",
      "Calion",
      "Carthage",
      "Reader",
      "El dorado",
      "Emerson",
      "Fordyce",
      "Gurdon",
      "Hampton",
      "Harrell",
      "Huttig",
      "Junction city",
      "Louann",
      "Mc neil",
      "Magnolia",
      "Mount holly",
      "Norphlet",
      "Smackover",
      "Manning",
      "Stephens",
      "Strong",
      "Thornton",
      "Waldo",
      "Zcta 717hh",
      "Zcta 717xx",
      "Perrytown",
      "Alleene",
      "Ashdown",
      "Ben lomond",
      "Blevins",
      "Bradley",
      "Buckner",
      "De queen",
      "Dierks",
      "Doddridge",
      "Emmet",
      "Foreman",
      "Fouke",
      "Fulton",
      "Garland city",
      "Gillham",
      "Horatio",
      "Lewisville",
      "Lockesburg",
      "Mc caskill",
      "Mineral springs",
      "Nashville",
      "Ogden",
      "Zcta 71854",
      "Ozan",
      "Prescott",
      "Rosston",
      "Saratoga",
      "Stamps",
      "Taylor",
      "Washington",
      "Wilton",
      "Winthrop",
      "Zcta 718hh",
      "Lake catherine",
      "Hot springs vill",
      "Lake hamilton",
      "Amity",
      "Antoine",
      "Arkadelphia",
      "Bismarck",
      "Bonnerdale",
      "Caddo gap",
      "Cove",
      "Delight",
      "Donaldson",
      "Glenwood",
      "Grannis",
      "Hatfield",
      "Jessieville",
      "Kirby",
      "Langley",
      "Mena",
      "Buckville",
      "Mount ida",
      "Murfreesboro",
      "Newhope",
      "Norman",
      "Oden",
      "Okolona",
      "Pearcy",
      "Pencil bluff",
      "Royal",
      "Sims",
      "Story",
      "Umpire",
      "Vandervoort",
      "Wickes",
      "Zcta 719hh",
      "Adona",
      "Alexander",
      "Almyra",
      "Altheimer",
      "Amagon",
      "Augusta",
      "Austin",
      "Bald knob",
      "Bauxite",
      "Beebe",
      "Bee branch",
      "Beedeville",
      "Benton",
      "Bigelow",
      "Biscoe",
      "Bradford",
      "Brinkley",
      "Bryant",
      "Cabot",
      "Carlisle",
      "Casa",
      "Casscoe",
      "Center ridge",
      "Clarendon",
      "Cleveland",
      "Clinton",
      "Conway",
      "Cotton plant",
      "Crocketts bluff",
      "Twin groves",
      "Des arc",
      "De valls bluff",
      "De witt",
      "Diaz",
      "Edgemont",
      "El paso",
      "England",
      "Enola",
      "Ethel",
      "Fox",
      "College station",
      "Gillett",
      "Grapevine",
      "Greenbrier",
      "Griffithville",
      "Guy",
      "Hattieville",
      "Hazen",
      "Hensley",
      "Greers ferry",
      "Higginson",
      "Holly grove",
      "Houston",
      "Humnoke",
      "Humphrey",
      "Hunter",
      "Jacksonport",
      "Gravel ridge",
      "Jefferson",
      "Jerusalem",
      "Judsonia",
      "Kensett",
      "Keo",
      "Leola",
      "Letona",
      "Lonoke",
      "Lonsdale",
      "Fairfield bay",
      "Jacksonville",
      "Zcta 720hh",
      "Zcta 720xx",
      "Mc crory",
      "Mc rae",
      "Shannon hills",
      "Malvern",
      "Jones mills",
      "Mayflower",
      "Menifee",
      "Monroe",
      "Morrilton",
      "Mount vernon",
      "Newport",
      "Maumelle",
      "North little roc",
      "Sherwood",
      "Camp joseph t ro",
      "Pangburn",
      "Paron",
      "Patterson",
      "Perry",
      "Perryville",
      "Plumerville",
      "Poyen",
      "Prattsville",
      "Prim",
      "Quitman",
      "Redfield",
      "Reydell",
      "Roe",
      "Roland",
      "Romance",
      "Rose bud",
      "Russell",
      "Saint charles",
      "Scotland",
      "Scott",
      "Georgetown",
      "Sheridan",
      "Sherrill",
      "Shirley",
      "Solgohachia",
      "Springfield",
      "Stuttgart",
      "Thida",
      "Tichnor",
      "Traskwood",
      "Tucker",
      "Tupelo",
      "Ulm",
      "Vilonia",
      "Wabbaseka",
      "Ward",
      "Wilburn",
      "Woodson",
      "Wooster",
      "Wrightsville",
      "Zcta 721hh",
      "Zcta 721xx",
      "Little rock",
      "Zcta 722hh",
      "West memphis",
      "Aubrey",
      "Blytheville a f",
      "Brickeys",
      "Burdette",
      "Caldwell",
      "Cherry valley",
      "Colt",
      "Crawfordsville",
      "Crumrod",
      "Driver",
      "Dyess",
      "Earle",
      "Edmondson",
      "Elaine",
      "Forrest city",
      "Frenchmans bayou",
      "Gilmore",
      "Goodwin",
      "Haynes",
      "Helena",
      "Heth",
      "Hickory ridge",
      "Hughes",
      "Joiner",
      "Keiser",
      "Lambrook",
      "Lepanto",
      "Lexa",
      "Luxora",
      "Madison",
      "Marianna",
      "Marion",
      "Marked tree",
      "Marvell",
      "Mellwood",
      "Moro",
      "Oneida",
      "Osceola",
      "Palestine",
      "Parkin",
      "Poplar grove",
      "Proctor",
      "Rivervale",
      "Snow lake",
      "Turner",
      "Turrell",
      "Tyronza",
      "Vanndale",
      "Wabash",
      "West helena",
      "Wheatley",
      "Widener",
      "Wilson",
      "Wynne",
      "Zcta 723hh",
      "Zcta 723xx",
      "Jonesboro",
      "Zcta 72404",
      "Alicia",
      "Bay",
      "Beech grove",
      "Biggers",
      "Black oak",
      "Black rock",
      "Bono",
      "Brookland",
      "Caraway",
      "Cash",
      "Corning",
      "Datto",
      "Delaplaine",
      "Dell",
      "Egypt",
      "Etowah",
      "Fisher",
      "Greenway",
      "Grubbs",
      "Harrisburg",
      "Hoxie",
      "Imboden",
      "Knobel",
      "Lafe",
      "Lake city",
      "Leachville",
      "Lynn",
      "Mc dougal",
      "Roseland",
      "Marmaduke",
      "Maynard",
      "Minturn",
      "Monette",
      "O kean",
      "Paragould",
      "Peach orchard",
      "Piggott",
      "Pocahontas",
      "Pollard",
      "Portia",
      "Powhatan",
      "Ravenden",
      "Ravenden springs",
      "Rector",
      "Reyno",
      "Saint francis",
      "Smithville",
      "State university",
      "Calamine",
      "Success",
      "Swifton",
      "Payneway",
      "Tuckerman",
      "Waldenburg",
      "College city",
      "Warm springs",
      "Weiner",
      "Williford",
      "Zcta 724hh",
      "Batesville",
      "Horseshoe bend",
      "Agnos",
      "Bexar",
      "Brockwell",
      "Jordan",
      "Camp",
      "Cave city",
      "Charlotte",
      "Concord",
      "Cord",
      "Cherokee village",
      "Cushman",
      "Desha",
      "Dolph",
      "Drasco",
      "Elizabeth",
      "Evening shade",
      "Fifty six",
      "Floral",
      "Franklin",
      "Gamaliel",
      "Gepp",
      "Glencoe",
      "Guion",
      "Hardy",
      "Heber springs",
      "Henderson",
      "Locust grove",
      "Magness",
      "Mammoth spring",
      "Marcella",
      "Zion",
      "Hanover",
      "Mount pleasant",
      "Newark",
      "Oil trough",
      "Oxford",
      "Pineville",
      "Pleasant grove",
      "Pleasant plains",
      "Poughkeepsie",
      "Rosie",
      "Saffell",
      "Sage",
      "Byron",
      "Sidney",
      "Sturkie",
      "Sulphur rock",
      "Tumbling shoals",
      "Viola",
      "Violet hill",
      "Wideman",
      "Wiseman",
      "Zcta 725hh",
      "Harrison",
      "Alco",
      "Alpena",
      "Berryville",
      "Big flat",
      "Bull shoals",
      "Clarkridge",
      "Compton",
      "Cotter",
      "Deer",
      "Dennard",
      "Diamond city",
      "Zcta 72631",
      "Eureka springs",
      "Everton",
      "Flippin",
      "Gassville",
      "Green forest",
      "Harriet",
      "Hasty",
      "Jasper",
      "Lakeview",
      "Lead hill",
      "Leslie",
      "Dogpatch",
      "Marshall",
      "Midway",
      "Mountain home",
      "Mount judea",
      "Norfork",
      "Oak grove",
      "Oakland",
      "Omaha",
      "Onia",
      "Parthenon",
      "Peel",
      "Pindall",
      "Pyatt",
      "Saint joe",
      "Summit",
      "Tilly",
      "Newnata",
      "Valley springs",
      "Vendor",
      "Western grove",
      "Witts springs",
      "Yellville",
      "Zcta 726hh",
      "Fayetteville",
      "Zcta 72704",
      "Avoca",
      "Bentonville",
      "Bella vista",
      "Zcta 72715",
      "Canehill",
      "Cave springs",
      "Centerton",
      "Combs",
      "Decatur",
      "Elkins",
      "Evansville",
      "Farmington",
      "Garfield",
      "Gentry",
      "Gravette",
      "Hindsville",
      "Hiwasse",
      "Huntsville",
      "Kingston",
      "Lincoln",
      "Lowell",
      "Maysville",
      "Morrow",
      "Pea ridge",
      "Pettigrew",
      "Prairie grove",
      "Rogers",
      "Zcta 72758",
      "Saint paul",
      "Siloam springs",
      "Springdale",
      "Bethel heights",
      "Sulphur springs",
      "Summers",
      "Wesley",
      "West fork",
      "Witter",
      "Zcta 727hh",
      "Russellville",
      "Alix",
      "Altus",
      "Atkins",
      "Belleville",
      "Blue mountain",
      "Bluffton",
      "Briggsville",
      "Centerville",
      "Clarksville",
      "Coal hill",
      "Danville",
      "Dardanelle",
      "Delaware",
      "Dover",
      "Gravelly",
      "Hagarville",
      "Hartman",
      "Harvey",
      "Waveland",
      "Hector",
      "Knoxville",
      "Lamar",
      "London",
      "New blaine",
      "Oark",
      "Ola",
      "Ozone",
      "Paris",
      "Pelsor",
      "Plainview",
      "Pottsville",
      "Rover",
      "Scranton",
      "Subiaco",
      "Zcta 728hh",
      "Zcta 728xx",
      "Fort smith",
      "Alma",
      "Barling",
      "Boles",
      "Booneville",
      "Branch",
      "Cecil",
      "Cedarville",
      "Charleston",
      "Chester",
      "Dyer",
      "Greenwood",
      "Hackett",
      "Hartford",
      "Huntington",
      "Central city",
      "Magazine",
      "Mansfield",
      "Midland",
      "Mountainburg",
      "Mulberry",
      "Natural dam",
      "Ozark",
      "Parks",
      "Ratcliff",
      "Rudy",
      "Uniontown",
      "Van buren",
      "Waldron",
      "Winslow",
      "Zcta 729hh",
      "Zcta 729xx",
    ],
    ZipCodes: {
      "": ["38041", "38063", "380HH", "65733", "65761", "657HH"],
      "North cedar": ["71601"],
      Dollarway: ["71602"],
      "Pine bluff": ["71603"],
      "Arkansas city": ["71630"],
      Banks: ["71631"],
      North: ["71635"],
      Dermott: ["71638"],
      Dumas: ["71639"],
      Eudora: ["71640"],
      "Fountain hill": ["71642"],
      Gould: ["71643"],
      Tamo: ["71644"],
      Hamburg: ["71646"],
      Ingalls: ["71647"],
      Jersey: ["71651"],
      Kingsland: ["71652"],
      "Lake village": ["71653"],
      "Mc gehee": ["71654"],
      Monticello: ["71655"],
      "University of ar": ["71656"],
      Montrose: ["71658"],
      Moscow: ["71659"],
      "New edinburg": ["71660"],
      Parkdale: ["71661"],
      Portland: ["71663"],
      Rison: ["71665"],
      Rohwer: ["71666"],
      "Star city": ["71667"],
      Reed: ["71670"],
      Warren: ["71671"],
      Watson: ["71674"],
      Wilmar: ["71675"],
      Wilmot: ["71676"],
      Winchester: ["71677"],
      "Zcta 716hh": ["716HH"],
      "Zcta 716xx": ["716XX"],
      "East camden": ["71701"],
      Bearden: ["71720"],
      "Bluff city": ["71722"],
      Calion: ["71724"],
      Carthage: ["71725"],
      Reader: ["71726"],
      "El dorado": ["71730"],
      Emerson: ["71740"],
      Fordyce: ["71742"],
      Gurdon: ["71743"],
      Hampton: ["71744"],
      Harrell: ["71745"],
      Huttig: ["71747"],
      "Junction city": ["71749"],
      Louann: ["71751"],
      "Mc neil": ["71752"],
      Magnolia: ["71753"],
      "Mount holly": ["71758"],
      Norphlet: ["71759"],
      Smackover: ["71762"],
      Manning: ["71763"],
      Stephens: ["71764"],
      Strong: ["71765"],
      Thornton: ["71766"],
      Waldo: ["71770"],
      "Zcta 717hh": ["717HH"],
      "Zcta 717xx": ["717XX"],
      Perrytown: ["71801"],
      Alleene: ["71820"],
      Ashdown: ["71822"],
      "Ben lomond": ["71823"],
      Blevins: ["71825"],
      Bradley: ["71826"],
      Buckner: ["71827"],
      "De queen": ["71832"],
      Dierks: ["71833"],
      Doddridge: ["71834"],
      Emmet: ["71835"],
      Foreman: ["71836"],
      Fouke: ["71837"],
      Fulton: ["71838"],
      "Garland city": ["71839"],
      Gillham: ["71841"],
      Horatio: ["71842"],
      Lewisville: ["71845"],
      Lockesburg: ["71846"],
      "Mc caskill": ["71847"],
      "Mineral springs": ["71851"],
      Nashville: ["71852"],
      Ogden: ["71853"],
      "Zcta 71854": ["71854"],
      Ozan: ["71855"],
      Prescott: ["71857"],
      Rosston: ["71858"],
      Saratoga: ["71859"],
      Stamps: ["71860"],
      Taylor: ["71861"],
      Washington: ["71862"],
      Wilton: ["71865"],
      Winthrop: ["71866"],
      "Zcta 718hh": ["718HH"],
      "Lake catherine": ["71901"],
      "Hot springs vill": ["71909"],
      "Lake hamilton": ["71913"],
      Amity: ["71921"],
      Antoine: ["71922"],
      Arkadelphia: ["71923"],
      Bismarck: ["71929"],
      Bonnerdale: ["71933"],
      "Caddo gap": ["71935"],
      Cove: ["71937"],
      Delight: ["71940"],
      Donaldson: ["71941"],
      Glenwood: ["71943"],
      Grannis: ["71944"],
      Hatfield: ["71945"],
      Jessieville: ["71949"],
      Kirby: ["71950"],
      Langley: ["71952"],
      Mena: ["71953"],
      Buckville: ["71956"],
      "Mount ida": ["71957"],
      Murfreesboro: ["71958"],
      Newhope: ["71959"],
      Norman: ["71960"],
      Oden: ["71961"],
      Okolona: ["71962"],
      Pearcy: ["71964"],
      "Pencil bluff": ["71965"],
      Royal: ["71968"],
      Sims: ["71969"],
      Story: ["71970"],
      Umpire: ["71971"],
      Vandervoort: ["71972"],
      Wickes: ["71973"],
      "Zcta 719hh": ["719HH"],
      Adona: ["72001"],
      Alexander: ["72002"],
      Almyra: ["72003"],
      Altheimer: ["72004"],
      Amagon: ["72005"],
      Augusta: ["72006"],
      Austin: ["72007"],
      "Bald knob": ["72010"],
      Bauxite: ["72011"],
      Beebe: ["72012"],
      "Bee branch": ["72013"],
      Beedeville: ["72014"],
      Benton: ["72015"],
      Bigelow: ["72016"],
      Biscoe: ["72017"],
      Bradford: ["72020"],
      Brinkley: ["72021"],
      Bryant: ["72022"],
      Cabot: ["72023"],
      Carlisle: ["72024"],
      Casa: ["72025"],
      Casscoe: ["72026"],
      "Center ridge": ["72027"],
      Clarendon: ["72029"],
      Cleveland: ["72030"],
      Clinton: ["72031"],
      Conway: ["72032"],
      "Cotton plant": ["72036"],
      "Crocketts bluff": ["72038"],
      "Twin groves": ["72039"],
      "Des arc": ["72040"],
      "De valls bluff": ["72041"],
      "De witt": ["72042"],
      Diaz: ["72043"],
      Edgemont: ["72044"],
      "El paso": ["72045"],
      England: ["72046"],
      Enola: ["72047"],
      Ethel: ["72048"],
      Fox: ["72051"],
      "College station": ["72053"],
      Gillett: ["72055"],
      Grapevine: ["72057"],
      Greenbrier: ["72058"],
      Griffithville: ["72060"],
      Guy: ["72061"],
      Hattieville: ["72063"],
      Hazen: ["72064"],
      Hensley: ["72065"],
      "Greers ferry": ["72067"],
      Higginson: ["72068"],
      "Holly grove": ["72069"],
      Houston: ["72070"],
      Humnoke: ["72072"],
      Humphrey: ["72073"],
      Hunter: ["72074"],
      Jacksonport: ["72075"],
      "Gravel ridge": ["72076"],
      Jefferson: ["72079"],
      Jerusalem: ["72080"],
      Judsonia: ["72081"],
      Kensett: ["72082"],
      Keo: ["72083"],
      Leola: ["72084"],
      Letona: ["72085"],
      Lonoke: ["72086"],
      Lonsdale: ["72087"],
      "Fairfield bay": ["72088"],
      Jacksonville: ["72099"],
      "Zcta 720hh": ["720HH"],
      "Zcta 720xx": ["720XX"],
      "Mc crory": ["72101"],
      "Mc rae": ["72102"],
      "Shannon hills": ["72103"],
      Malvern: ["72104"],
      "Jones mills": ["72105"],
      Mayflower: ["72106"],
      Menifee: ["72107"],
      Monroe: ["72108"],
      Morrilton: ["72110"],
      "Mount vernon": ["72111"],
      Newport: ["72112"],
      Maumelle: ["72113"],
      "North little roc": ["72114", "72117", "72120"],
      Sherwood: ["72116"],
      "Camp joseph t ro": ["72118"],
      Pangburn: ["72121"],
      Paron: ["72122"],
      Patterson: ["72123"],
      Perry: ["72125"],
      Perryville: ["72126"],
      Plumerville: ["72127"],
      Poyen: ["72128"],
      Prattsville: ["72129"],
      Prim: ["72130"],
      Quitman: ["72131"],
      Redfield: ["72132"],
      Reydell: ["72133"],
      Roe: ["72134"],
      Roland: ["72135"],
      Romance: ["72136"],
      "Rose bud": ["72137"],
      Russell: ["72139"],
      "Saint charles": ["72140"],
      Scotland: ["72141"],
      Scott: ["72142"],
      Georgetown: ["72143"],
      Sheridan: ["72150"],
      Sherrill: ["72152"],
      Shirley: ["72153"],
      Solgohachia: ["72156"],
      Springfield: ["72157"],
      Stuttgart: ["72160"],
      Thida: ["72165"],
      Tichnor: ["72166"],
      Traskwood: ["72167"],
      Tucker: ["72168"],
      Tupelo: ["72169"],
      Ulm: ["72170"],
      Vilonia: ["72173"],
      Wabbaseka: ["72175"],
      Ward: ["72176"],
      Wilburn: ["72179"],
      Woodson: ["72180"],
      Wooster: ["72181"],
      Wrightsville: ["72183"],
      "Zcta 721hh": ["721HH"],
      "Zcta 721xx": ["721XX"],
      "Little rock": [
        "72201",
        "72202",
        "72204",
        "72205",
        "72206",
        "72207",
        "72209",
        "72210",
        "72211",
        "72212",
        "72223",
        "72227",
      ],
      "Zcta 722hh": ["722HH"],
      "West memphis": ["72301"],
      Aubrey: ["72311"],
      "Blytheville a f": ["72315"],
      Brickeys: ["72320"],
      Burdette: ["72321"],
      Caldwell: ["72322"],
      "Cherry valley": ["72324"],
      Colt: ["72326"],
      Crawfordsville: ["72327"],
      Crumrod: ["72328"],
      Driver: ["72329"],
      Dyess: ["72330"],
      Earle: ["72331"],
      Edmondson: ["72332"],
      Elaine: ["72333"],
      "Forrest city": ["72335"],
      "Frenchmans bayou": ["72338"],
      Gilmore: ["72339"],
      Goodwin: ["72340"],
      Haynes: ["72341"],
      Helena: ["72342"],
      Heth: ["72346"],
      "Hickory ridge": ["72347"],
      Hughes: ["72348"],
      Joiner: ["72350"],
      Keiser: ["72351"],
      Lambrook: ["72353"],
      Lepanto: ["72354"],
      Lexa: ["72355"],
      Luxora: ["72358"],
      Madison: ["72359"],
      Marianna: ["72360"],
      Marion: ["72364"],
      "Marked tree": ["72365"],
      Marvell: ["72366"],
      Mellwood: ["72367"],
      Moro: ["72368"],
      Oneida: ["72369"],
      Osceola: ["72370"],
      Palestine: ["72372"],
      Parkin: ["72373"],
      "Poplar grove": ["72374"],
      Proctor: ["72376"],
      Rivervale: ["72377"],
      "Snow lake": ["72379"],
      Turner: ["72383"],
      Turrell: ["72384"],
      Tyronza: ["72386"],
      Vanndale: ["72387"],
      Wabash: ["72389"],
      "West helena": ["72390"],
      Wheatley: ["72392"],
      Widener: ["72394"],
      Wilson: ["72395"],
      Wynne: ["72396"],
      "Zcta 723hh": ["723HH"],
      "Zcta 723xx": ["723XX"],
      Jonesboro: ["72401"],
      "Zcta 72404": ["72404"],
      Alicia: ["72410"],
      Bay: ["72411"],
      "Beech grove": ["72412"],
      Biggers: ["72413"],
      "Black oak": ["72414"],
      "Black rock": ["72415"],
      Bono: ["72416"],
      Brookland: ["72417"],
      Caraway: ["72419"],
      Cash: ["72421"],
      Corning: ["72422"],
      Datto: ["72424"],
      Delaplaine: ["72425"],
      Dell: ["72426"],
      Egypt: ["72427"],
      Etowah: ["72428"],
      Fisher: ["72429"],
      Greenway: ["72430"],
      Grubbs: ["72431"],
      Harrisburg: ["72432"],
      Hoxie: ["72433"],
      Imboden: ["72434"],
      Knobel: ["72435"],
      Lafe: ["72436"],
      "Lake city": ["72437"],
      Leachville: ["72438"],
      Lynn: ["72440"],
      "Mc dougal": ["72441"],
      Roseland: ["72442"],
      Marmaduke: ["72443"],
      Maynard: ["72444"],
      Minturn: ["72445"],
      Monette: ["72447"],
      "O kean": ["72449"],
      Paragould: ["72450"],
      "Peach orchard": ["72453"],
      Piggott: ["72454"],
      Pocahontas: ["72455"],
      Pollard: ["72456"],
      Portia: ["72457"],
      Powhatan: ["72458"],
      Ravenden: ["72459"],
      "Ravenden springs": ["72460"],
      Rector: ["72461"],
      Reyno: ["72462"],
      "Saint francis": ["72464"],
      Smithville: ["72466"],
      "State university": ["72467"],
      Calamine: ["72469"],
      Success: ["72470"],
      Swifton: ["72471"],
      Payneway: ["72472"],
      Tuckerman: ["72473"],
      Waldenburg: ["72475"],
      "College city": ["72476"],
      "Warm springs": ["72478"],
      Weiner: ["72479"],
      Williford: ["72482"],
      "Zcta 724hh": ["724HH"],
      Batesville: ["72501"],
      "Horseshoe bend": ["72512"],
      Agnos: ["72513"],
      Bexar: ["72515"],
      Brockwell: ["72517"],
      Jordan: ["72519"],
      Camp: ["72520"],
      "Cave city": ["72521"],
      Charlotte: ["72522"],
      Concord: ["72523"],
      Cord: ["72524"],
      "Cherokee village": ["72525", "72529"],
      Cushman: ["72526"],
      Desha: ["72527"],
      Dolph: ["72528"],
      Drasco: ["72530"],
      Elizabeth: ["72531"],
      "Evening shade": ["72532"],
      "Fifty six": ["72533"],
      Floral: ["72534"],
      Franklin: ["72536"],
      Gamaliel: ["72537"],
      Gepp: ["72538"],
      Glencoe: ["72539"],
      Guion: ["72540"],
      Hardy: ["72542"],
      "Heber springs": ["72543"],
      Henderson: ["72544"],
      "Locust grove": ["72550"],
      Magness: ["72553"],
      "Mammoth spring": ["72554"],
      Marcella: ["72555"],
      Zion: ["72556"],
      Hanover: ["72560"],
      "Mount pleasant": ["72561"],
      Newark: ["72562"],
      "Oil trough": ["72564"],
      Oxford: ["72565"],
      Pineville: ["72566"],
      "Pleasant grove": ["72567"],
      "Pleasant plains": ["72568"],
      Poughkeepsie: ["72569"],
      Rosie: ["72571"],
      Saffell: ["72572"],
      Sage: ["72573"],
      Byron: ["72576"],
      Sidney: ["72577"],
      Sturkie: ["72578"],
      "Sulphur rock": ["72579"],
      "Tumbling shoals": ["72581"],
      Viola: ["72583"],
      "Violet hill": ["72584"],
      Wideman: ["72585"],
      Wiseman: ["72587"],
      "Zcta 725hh": ["725HH"],
      Harrison: ["72601"],
      Alco: ["72610"],
      Alpena: ["72611"],
      Berryville: ["72616"],
      "Big flat": ["72617"],
      "Bull shoals": ["72619"],
      Clarkridge: ["72623"],
      Compton: ["72624"],
      Cotter: ["72626"],
      Deer: ["72628"],
      Dennard: ["72629"],
      "Diamond city": ["72630"],
      "Zcta 72631": ["72631"],
      "Eureka springs": ["72632"],
      Everton: ["72633"],
      Flippin: ["72634"],
      Gassville: ["72635"],
      "Green forest": ["72638"],
      Harriet: ["72639"],
      Hasty: ["72640"],
      Jasper: ["72641"],
      Lakeview: ["72642"],
      "Lead hill": ["72644"],
      Leslie: ["72645"],
      Dogpatch: ["72648"],
      Marshall: ["72650"],
      Midway: ["72651"],
      "Mountain home": ["72653"],
      "Mount judea": ["72655"],
      Norfork: ["72658"],
      "Oak grove": ["72660"],
      Oakland: ["72661"],
      Omaha: ["72662"],
      Onia: ["72663"],
      Parthenon: ["72666"],
      Peel: ["72668"],
      Pindall: ["72669"],
      Pyatt: ["72672"],
      "Saint joe": ["72675"],
      Summit: ["72677"],
      Tilly: ["72679"],
      Newnata: ["72680"],
      "Valley springs": ["72682"],
      Vendor: ["72683"],
      "Western grove": ["72685"],
      "Witts springs": ["72686"],
      Yellville: ["72687"],
      "Zcta 726hh": ["726HH"],
      Fayetteville: ["72701", "72703"],
      "Zcta 72704": ["72704"],
      Avoca: ["72711"],
      Bentonville: ["72712"],
      "Bella vista": ["72714"],
      "Zcta 72715": ["72715"],
      Canehill: ["72717"],
      "Cave springs": ["72718"],
      Centerton: ["72719"],
      Combs: ["72721"],
      Decatur: ["72722"],
      Elkins: ["72727"],
      Evansville: ["72729"],
      Farmington: ["72730"],
      Garfield: ["72732"],
      Gentry: ["72734"],
      Gravette: ["72736"],
      Hindsville: ["72738"],
      Hiwasse: ["72739"],
      Huntsville: ["72740"],
      Kingston: ["72742"],
      Lincoln: ["72744"],
      Lowell: ["72745"],
      Maysville: ["72747"],
      Morrow: ["72749"],
      "Pea ridge": ["72751"],
      Pettigrew: ["72752"],
      "Prairie grove": ["72753"],
      Rogers: ["72756"],
      "Zcta 72758": ["72758"],
      "Saint paul": ["72760"],
      "Siloam springs": ["72761"],
      Springdale: ["72762"],
      "Bethel heights": ["72764"],
      "Sulphur springs": ["72768"],
      Summers: ["72769"],
      Wesley: ["72773"],
      "West fork": ["72774"],
      Witter: ["72776"],
      "Zcta 727hh": ["727HH"],
      Russellville: ["72801", "72802"],
      Alix: ["72820"],
      Altus: ["72821"],
      Atkins: ["72823"],
      Belleville: ["72824"],
      "Blue mountain": ["72826"],
      Bluffton: ["72827"],
      Briggsville: ["72828"],
      Centerville: ["72829"],
      Clarksville: ["72830"],
      "Coal hill": ["72832"],
      Danville: ["72833"],
      Dardanelle: ["72834"],
      Delaware: ["72835"],
      Dover: ["72837"],
      Gravelly: ["72838"],
      Hagarville: ["72839"],
      Hartman: ["72840"],
      Harvey: ["72841"],
      Waveland: ["72842"],
      Hector: ["72843"],
      Knoxville: ["72845"],
      Lamar: ["72846"],
      London: ["72847"],
      "New blaine": ["72851"],
      Oark: ["72852"],
      Ola: ["72853"],
      Ozone: ["72854"],
      Paris: ["72855"],
      Pelsor: ["72856"],
      Plainview: ["72857"],
      Pottsville: ["72858"],
      Rover: ["72860"],
      Scranton: ["72863"],
      Subiaco: ["72865"],
      "Zcta 728hh": ["728HH"],
      "Zcta 728xx": ["728XX"],
      "Fort smith": ["72901", "72903", "72904", "72908", "72916"],
      Alma: ["72921"],
      Barling: ["72923"],
      Boles: ["72926"],
      Booneville: ["72927"],
      Branch: ["72928"],
      Cecil: ["72930"],
      Cedarville: ["72932"],
      Charleston: ["72933"],
      Chester: ["72934"],
      Dyer: ["72935"],
      Greenwood: ["72936"],
      Hackett: ["72937"],
      Hartford: ["72938"],
      Huntington: ["72940"],
      "Central city": ["72941"],
      Magazine: ["72943"],
      Mansfield: ["72944"],
      Midland: ["72945"],
      Mountainburg: ["72946"],
      Mulberry: ["72947"],
      "Natural dam": ["72948"],
      Ozark: ["72949"],
      Parks: ["72950"],
      Ratcliff: ["72951"],
      Rudy: ["72952"],
      Uniontown: ["72955"],
      "Van buren": ["72956"],
      Waldron: ["72958"],
      Winslow: ["72959"],
      "Zcta 729hh": ["729HH"],
      "Zcta 729xx": ["729XX"],
    },
  },
  {
    abbrevation: "CA",
    name: "California",
    Cities: [
      "",
      "",
      "Los angeles",
      "East los angeles",
      "City of commerce",
      "Cole",
      "Vernon",
      "Hazard",
      "West hollywood",
      "Bell gardens",
      "Beverly hills",
      "Rancho dominguez",
      "East rancho domi",
      "Rosewood",
      "Culver city",
      "Downey",
      "El segundo",
      "Gardena",
      "Holly park",
      "Hermosa beach",
      "Huntington park",
      "Lawndale",
      "Lynwood",
      "Malibu",
      "Manhattan beach",
      "Maywood",
      "Pacific palisade",
      "Palos verdes est",
      "Zcta 90275",
      "Redondo beach",
      "South gate",
      "Topanga",
      "Venice",
      "Marina del rey",
      "Playa del rey",
      "Zcta 902hh",
      "Inglewood",
      "Lennox",
      "Santa monica",
      "Zcta 904hh",
      "Torrance",
      "Whittier",
      "Los nietos",
      "Buena park",
      "Cerritos",
      "Cypress",
      "La habra heights",
      "La mirada",
      "Montebello",
      "Norwalk",
      "Pico rivera",
      "Santa fe springs",
      "Stanton",
      "Zcta 906hh",
      "Avalon",
      "Bellflower",
      "Harbor city",
      "Lakewood",
      "Hawaiian gardens",
      "Rancho palos ver",
      "Rossmoor",
      "Paramount",
      "San pedro",
      "Seal beach",
      "Sunset beach",
      "Surfside",
      "Wilmington",
      "Carson",
      "Zcta 907hh",
      "Zcta 907xx",
      "Long beach",
      "Signal hill",
      "Zcta 908hh",
      "Altadena",
      "Arcadia",
      "Bradbury",
      "Flintridge",
      "Monrovia",
      "Montrose",
      "Sierra madre",
      "South pasadena",
      "Shadow hills",
      "Tujunga",
      "Zcta 910hh",
      "Zcta 910xx",
      "Pasadena",
      "San marino",
      "Glendale",
      "La crescenta",
      "Oak park",
      "Calabasas",
      "Canoga park",
      "Winnetka",
      "West hills",
      "Chatsworth",
      "Encino",
      "Newbury park",
      "Newhall",
      "Northridge",
      "Porter ranch",
      "Arleta",
      "Reseda",
      "San fernando",
      "Sylmar",
      "North hills",
      "Granada hills",
      "Mission hills",
      "Agua dulce",
      "Canyon country",
      "Sun valley",
      "Valencia",
      "Tarzana",
      "Thousand oaks",
      "Westlake village",
      "Woodland hills",
      "Zcta 91377",
      "Castaic",
      "Zcta 913hh",
      "Zcta 913xx",
      "Van nuys",
      "Panorama city",
      "Sherman oaks",
      "Burbank",
      "North hollywood",
      "Toluca lake",
      "Studio city",
      "Valley village",
      "Universal city",
      "Alta loma",
      "Azusa",
      "Irwindale",
      "Chino hills",
      "Chino",
      "Claremont",
      "Corona",
      "Covina",
      "Rancho cucamonga",
      "El monte",
      "South el monte",
      "Etiwanda",
      "Glendora",
      "Guasti",
      "Industry",
      "Hacienda heights",
      "Bassett",
      "Rowland heights",
      "La verne",
      "Mira loma",
      "Monterey park",
      "Mt baldy",
      "Ontario",
      "Montclair",
      "Diamond bar",
      "Phillips ranch",
      "Pomona",
      "Rosemead",
      "San dimas",
      "San gabriel",
      "Temple city",
      "Upland",
      "West covina",
      "Zcta 917hh",
      "Zcta 917xx",
      "Alhambra",
      "Alpine",
      "Bonita",
      "Boulevard",
      "Campo",
      "Chula vista",
      "Descanso",
      "Dulzura",
      "Guatay",
      "Imperial beach",
      "Jacumba",
      "Jamul",
      "La mesa",
      "Lemon grove",
      "Mount laguna",
      "National city",
      "Pine valley",
      "Potrero",
      "Spring valley",
      "Tecate",
      "Zcta 919hh",
      "Zcta 919xx",
      "Bonsall",
      "Borrego springs",
      "Cardiff by the s",
      "Carlsbad",
      "Del mar",
      "El cajon",
      "Encinitas",
      "Escondido",
      "Fallbrook",
      "Julian",
      "La jolla",
      "Lakeside",
      "Oceanside",
      "Pala",
      "Palomar mountain",
      "Pauma valley",
      "Poway",
      "Ramona",
      "Ranchita",
      "Rancho santa fe",
      "San marcos",
      "Santa ysabel",
      "Santee",
      "Solana beach",
      "Zcta 92078",
      "Valley center",
      "Vista",
      "Warner springs",
      "Zcta 92091",
      "Zcta 920hh",
      "Zcta 920xx",
      "San diego",
      "Coronado",
      "San ysidro",
      "Zcta 921hh",
      "Chiriaco summit",
      "Indio",
      "Indian wells",
      "Palm desert",
      "Banning",
      "Beaumont",
      "Lost lake",
      "Brawley",
      "Cabazon",
      "Calexico",
      "Calipatria",
      "Cathedral city",
      "Coachella",
      "Eagle mountain",
      "Desert hot sprin",
      "Big river",
      "El centro",
      "Heber",
      "Holtville",
      "Imperial",
      "Joshua tree",
      "La quinta",
      "Mecca",
      "Morongo valley",
      "Niland",
      "North palm sprin",
      "Ocotillo",
      "Palm city",
      "Palm springs",
      "Palo verde",
      "Parker dam",
      "Pioneertown",
      "Rancho mirage",
      "Seeley",
      "Salton city",
      "Thousand palms",
      "Twentynine palms",
      "Vidal",
      "Westmorland",
      "White water",
      "Felicity",
      "Yucca valley",
      "Landers",
      "Zcta 922hh",
      "Zcta 922xx",
      "Adelanto",
      "Amboy",
      "Angelus oaks",
      "Apple valley",
      "Baker",
      "Fort irwin",
      "Barstow",
      "Zcta 92313",
      "Big bear city",
      "Big bear lake",
      "Bloomington",
      "Blue jay",
      "Bryn mawr",
      "Calimesa",
      "Cedar glen",
      "Cima",
      "Grand terrace",
      "Crestline",
      "Daggett",
      "Death valley",
      "Essex",
      "Fawnskin",
      "Fontana",
      "Ludlow",
      "Forest falls",
      "Green valley lak",
      "Helendale",
      "Hesperia",
      "East highland",
      "Hinkley",
      "Lake arrowhead",
      "Loma linda",
      "Lucerne valley",
      "Lytle creek",
      "Mentone",
      "Needles",
      "Nipton",
      "Newberry springs",
      "Oro grande",
      "Phelan",
      "Pinon hills",
      "Redlands",
      "Rialto",
      "Arrowbear lake",
      "Shoshone",
      "Sugarloaf",
      "Tecopa",
      "Spring valley la",
      "George afb",
      "Wrightwood",
      "Yermo",
      "Yucaipa",
      "Zcta 923hh",
      "Zcta 923xx",
      "San bernardino",
      "Muscoy",
      "Riverside",
      "Rubidoux",
      "March air force",
      "Lake elsinore",
      "Aguanga",
      "Anza",
      "Hemet",
      "Homeland",
      "Idyllwild",
      "Zcta 92551",
      "Moreno valley",
      "Mountain center",
      "Murrieta",
      "Lakeview",
      "Mead valley",
      "Perris",
      "San jacinto",
      "Gilman hot sprin",
      "Menifee",
      "Romoland",
      "Sun city",
      "Canyon lake",
      "Temecula",
      "Wildomar",
      "Winchester",
      "Zcta 925hh",
      "Zcta 925xx",
      "Zcta 92602",
      "Zcta 92604",
      "Zcta 92606",
      "Foothill ranch",
      "Zcta 92612",
      "Zcta 92614",
      "Zcta 92618",
      "Zcta 92620",
      "Capistrano beach",
      "Corona del mar",
      "Costa mesa",
      "Monarch bay",
      "Lake forest",
      "Huntington beach",
      "Laguna niguel",
      "Laguna hills",
      "Midway city",
      "Aliso viejo",
      "Newport beach",
      "San clemente",
      "Mission viejo",
      "Silverado",
      "Coto de caza",
      "Westminster",
      "Rancho santa mar",
      "Zcta 92694",
      "Zcta 926hh",
      "Zcta 926xx",
      "Santa ana",
      "Cowan heights",
      "Santa ana height",
      "Fountain valley",
      "Zcta 92780",
      "Zcta 92782",
      "Zcta 927hh",
      "Anaheim",
      "Zcta 92831",
      "Zcta 92832",
      "Zcta 92833",
      "Zcta 92835",
      "Zcta 92840",
      "Zcta 92841",
      "Zcta 92843",
      "Zcta 92844",
      "Zcta 92845",
      "Zcta 92860",
      "Zcta 92861",
      "Zcta 92865",
      "Zcta 92866",
      "Zcta 92867",
      "Zcta 92868",
      "Zcta 92869",
      "Zcta 92870",
      "Zcta 92879",
      "Zcta 92880",
      "Zcta 92881",
      "Zcta 92882",
      "Zcta 92883",
      "Zcta 92886",
      "Zcta 92887",
      "Zcta 928hh",
      "Zcta 928xx",
      "San buenaventura",
      "Camarillo",
      "Carpinteria",
      "Bardsdale",
      "Moorpark",
      "Oak view",
      "Ojai",
      "Oxnard",
      "Piru",
      "Port hueneme",
      "Santa paula",
      "Santa susana",
      "Simi valley",
      "Somis",
      "Summerland",
      "Zcta 930hh",
      "Zcta 930xx",
      "Santa barbara",
      "Montecito",
      "Goleta",
      "Zcta 931hh",
      "Zcta 931xx",
      "Alpaugh",
      "Armona",
      "Arvin",
      "Avenal",
      "Bodfish",
      "Buttonwillow",
      "California hot s",
      "Camp nelson",
      "Coalinga",
      "Corcoran",
      "Delano",
      "Ducor",
      "Earlimart",
      "Exeter",
      "Frazier park",
      "Farmersville",
      "Fellows",
      "Glennville",
      "Hanford",
      "Huron",
      "Ivanhoe",
      "Kernville",
      "Kettleman city",
      "Mountain mesa",
      "Lamont",
      "Laton",
      "Gorman",
      "Lemoncove",
      "Lemoore naval ai",
      "Lindsay",
      "Lost hills",
      "Mc farland",
      "Mc kittrick",
      "Maricopa",
      "New cuyama",
      "Onyx",
      "Pixley",
      "Porterville",
      "Posey",
      "Richgrove",
      "Giant forest",
      "Shafter",
      "Springville",
      "Stratford",
      "Strathmore",
      "Taft",
      "Terra bella",
      "Three rivers",
      "Tipton",
      "Tulare",
      "Tupman",
      "Visalia",
      "Pond",
      "Waukena",
      "Weldon",
      "Wofford heights",
      "Woodlake",
      "Woody",
      "Zcta 932hh",
      "Zcta 932xx",
      "Bakersfield",
      "College heights",
      "Greenacres",
      "Zcta 933hh",
      "Zcta 933xx",
      "San luis obispo",
      "Los osos",
      "Halcyon",
      "Atascadero",
      "Avila beach",
      "Bradley",
      "Buellton",
      "Cambria",
      "Casmalia",
      "Cayucos",
      "Creston",
      "Grover beach",
      "Guadalupe",
      "Harmony",
      "Lompoc",
      "Los alamos",
      "Los olivos",
      "Morro bay",
      "Nipomo",
      "Oceano",
      "Adelaide",
      "Paso robles",
      "Shell beach",
      "San ardo",
      "Parkfield",
      "San simeon",
      "California valle",
      "Santa maria",
      "Orcutt",
      "Zcta 93458",
      "Santa ynez",
      "Shandon",
      "Ballard",
      "Templeton",
      "Zcta 934hh",
      "Zcta 934xx",
      "Mojave",
      "California city",
      "Acton",
      "Benton",
      "Big pine",
      "Toms place",
      "Boron",
      "Bridgeport",
      "Havilah",
      "Darwin",
      "North edwards",
      "Independence",
      "Pearsonville",
      "Johannesburg",
      "June lake",
      "Keeler",
      "Keene",
      "Elizabeth lake",
      "Lancaster",
      "Hi vista",
      "Quartz hill",
      "Lee vining",
      "Juniper hills",
      "Crystalaire",
      "Lone pine",
      "Crowley lake",
      "Cartago",
      "Lake los angeles",
      "Leona valley",
      "Palmdale",
      "Randsburg",
      "China lake nwc",
      "Willow springs",
      "Bear valley spri",
      "Argus",
      "Valyermo",
      "Zcta 935hh",
      "Zcta 935xx",
      "Ahwahnee",
      "Auberry",
      "Badger",
      "Bass lake",
      "Big creek",
      "Biola",
      "Cantua creek",
      "Caruthers",
      "Chowchilla",
      "Clovis",
      "Coarsegold",
      "Cutler",
      "Del rey",
      "Dinuba",
      "Dos palos",
      "Dunlap",
      "Firebaugh",
      "Fish camp",
      "Five points",
      "Fowler",
      "Friant",
      "Helm",
      "Hume",
      "Kerman",
      "Kingsburg",
      "Lakeshore",
      "Los banos",
      "Madera",
      "Mendota",
      "Miramonte",
      "North fork",
      "Oakhurst",
      "O neals",
      "Orange cove",
      "Orosi",
      "Parlier",
      "Pinedale",
      "Prather",
      "Raisin",
      "Raymond",
      "Reedley",
      "Riverdale",
      "Sanger",
      "San joaquin",
      "Selma",
      "Shaver lake",
      "South dos palos",
      "Sultana",
      "Tollhouse",
      "Tranquillity",
      "Wishon",
      "Traver",
      "Squaw valley",
      "Zcta 936hh",
      "Zcta 936xx",
      "Fresno",
      "Fig garden villa",
      "Easton",
      "Calwa",
      "Salinas",
      "Prunedale",
      "Big sur",
      "Carmel",
      "Carmel valley",
      "Chualar",
      "Gonzales",
      "Greenfield",
      "Jolon",
      "King city",
      "Lockwood",
      "Marina",
      "Del rey oaks",
      "Pacific grove",
      "Pebble beach",
      "San lucas",
      "Sand city",
      "Soledad",
      "Spreckels",
      "Zcta 939hh",
      "Zcta 939xx",
      "Belmont",
      "Brisbane",
      "Hillsborough",
      "Colma",
      "Daly city",
      "Half moon bay",
      "La honda",
      "Loma mar",
      "Los altos",
      "West menlo park",
      "Atherton",
      "Ladera",
      "Millbrae",
      "Montara",
      "Moss beach",
      "Mountain view",
      "Pacifica",
      "Pescadero",
      "Redwood city",
      "Woodside",
      "San bruno",
      "San carlos",
      "San gregorio",
      "South san franci",
      "Sunnyvale",
      "Zcta 940hh",
      "San francisco",
      "Zcta 941hh",
      "Zcta 941xx",
      "Palo alto",
      "East palo alto",
      "Stanford",
      "Zcta 943hh",
      "Russian river",
      "San mateo",
      "Foster city",
      "Zcta 944hh",
      "Coast guard isla",
      "Alameda",
      "Black hawk",
      "Alamo",
      "Angwin",
      "Antioch",
      "Benicia",
      "Bethel island",
      "Birds landing",
      "Brentwood",
      "Byron",
      "Calistoga",
      "Clayton",
      "Concord",
      "Pleasant hill",
      "Crockett",
      "Danville",
      "El cerrito",
      "Fairfield",
      "Travis afb",
      "Fremont",
      "Hayward",
      "Castro valley",
      "Hercules",
      "Lafayette",
      "Livermore",
      "Pacheco",
      "Moraga",
      "Spanish flat",
      "Napa",
      "Newark",
      "Oakley",
      "Orinda",
      "Pinole",
      "Shore acres",
      "Pleasanton",
      "Pope valley",
      "Dublin",
      "Port costa",
      "Rio vista",
      "Rodeo",
      "Saint helena",
      "Deer park",
      "San leandro",
      "San lorenzo",
      "San ramon",
      "Suisun city",
      "Sunol",
      "Union city",
      "American canyon",
      "Vallejo",
      "Mare island",
      "Walnut creek",
      "Yountville",
      "Zcta 945hh",
      "Zcta 945xx",
      "Oakland",
      "Emeryville",
      "Piedmont",
      "Zcta 946hh",
      "Berkeley",
      "Albany",
      "Kensington",
      "Zcta 947hh",
      "Richmond",
      "El sobrante",
      "San pablo",
      "Zcta 948hh",
      "San rafael",
      "Civic center",
      "Kentfield",
      "Belvedere",
      "Bodega",
      "Bodega bay",
      "Bolinas",
      "Corte madera",
      "Rohnert park",
      "Dillon beach",
      "Fairfax",
      "Cotati",
      "Forest knolls",
      "Inverness",
      "Lagunitas",
      "Larkspur",
      "Marshall",
      "Mill valley",
      "Novato",
      "Nicasio",
      "Olema",
      "Penngrove",
      "Petaluma",
      "Point reyes stat",
      "San anselmo",
      "San geronimo",
      "San quentin",
      "Sausalito",
      "Stinson beach",
      "Tomales",
      "Valley ford",
      "Woodacre",
      "Zcta 949hh",
      "Zcta 949xx",
      "Alviso",
      "Aptos",
      "Aromas",
      "Ben lomond",
      "Boulder creek",
      "Brookdale",
      "Campbell",
      "Capitola",
      "Castroville",
      "Monte vista",
      "Davenport",
      "Felton",
      "Freedom",
      "Gilroy",
      "Hollister",
      "Monte sereno",
      "Los gatos",
      "Zcta 95033",
      "Milpitas",
      "Morgan hill",
      "Moss landing",
      "Mount hermon",
      "Paicines",
      "San juan bautist",
      "San martin",
      "Santa clara",
      "Scotts valley",
      "Santa cruz",
      "Saratoga",
      "Soquel",
      "La selva beach",
      "Zcta 950hh",
      "San jose",
      "Mount hamilton",
      "Zcta 951hh",
      "Stockton",
      "Acampo",
      "Altaville",
      "Angels camp",
      "Bear valley",
      "Avery",
      "Burson",
      "Campo seco",
      "Clements",
      "Copperopolis",
      "Farmington",
      "French camp",
      "Glencoe",
      "Hathaway pines",
      "Linden",
      "Lockeford",
      "Lodi",
      "Mokelumne hill",
      "Mountain ranch",
      "Murphys",
      "San andreas",
      "Sheepranch",
      "Vallecito",
      "Valley springs",
      "Wallace",
      "West point",
      "Wilseyville",
      "Woodbridge",
      "Zcta 952hh",
      "Zcta 952xx",
      "Atwater",
      "Ballico",
      "Big oak flat",
      "Catheys valley",
      "Ceres",
      "Columbia",
      "Coulterville",
      "Cressey",
      "Crows landing",
      "Delhi",
      "Denair",
      "El nido",
      "El portal",
      "Escalon",
      "Groveland",
      "Gustine",
      "Hickman",
      "Hilmar",
      "Hornitos",
      "Hughson",
      "Jamestown",
      "Keyes",
      "La grange",
      "Lathrop",
      "Le grand",
      "Livingston",
      "Cold springs",
      "Manteca",
      "Mariposa",
      "Red top",
      "Midpines",
      "Mi wuk village",
      "Merced",
      "Modesto",
      "Newman",
      "Knights ferry",
      "Patterson",
      "Pinecrest",
      "Planada",
      "Ripon",
      "Riverbank",
      "Salida",
      "Snelling",
      "Sonora",
      "Soulsbyville",
      "Stevinson",
      "Tracy",
      "Tuolumne",
      "Turlock",
      "Twain harte",
      "Vernalis",
      "Waterford",
      "Westley",
      "Winton",
      "Yosemite lodge",
      "Zcta 953hh",
      "Zcta 953xx",
      "Santa rosa",
      "Albion",
      "Annapolis",
      "Boonville",
      "Branscomb",
      "Camp meeker",
      "Caspar",
      "Cazadero",
      "Clearlake",
      "Clearlake oaks",
      "Clearlake park",
      "Cloverdale",
      "Loch lomond",
      "Comptche",
      "Covelo",
      "Dos rios",
      "Duncans mills",
      "Eldridge",
      "Elk",
      "Forestville",
      "Fort bragg",
      "Fulton",
      "Geyserville",
      "Glen ellen",
      "Glenhaven",
      "Graton",
      "Gualala",
      "Guerneville",
      "Healdsburg",
      "Hopland",
      "Jenner",
      "Kelseyville",
      "Kenwood",
      "Lakeport",
      "Laytonville",
      "Littleriver",
      "Lower lake",
      "Lucerne",
      "Manchester",
      "Mendocino",
      "Middletown",
      "Russian river md",
      "Navarro",
      "Nice",
      "Occidental",
      "Philo",
      "Point arena",
      "Potter valley",
      "Redwood valley",
      "Rio nido",
      "Freestone",
      "Sonoma",
      "Stewarts point",
      "Talmage",
      "Ukiah",
      "Upper lake",
      "Westport",
      "Willits",
      "Windsor",
      "Witter springs",
      "Yorkville",
      "The sea ranch",
      "Zcta 954hh",
      "Zcta 954xx",
      "Eureka",
      "Alderpoint",
      "Blocksburg",
      "Zcta 95519",
      "Mc kinleyville",
      "Bayside",
      "Blue lake",
      "Ruth",
      "Burnt ranch",
      "Carlotta",
      "Crescent city",
      "Ferndale",
      "Fields landing",
      "Fortuna",
      "Garberville",
      "Gasquet",
      "Honeydew",
      "Hoopa",
      "Hydesville",
      "Klamath",
      "Kneeland",
      "Korbel",
      "Loleta",
      "Mad river",
      "Miranda",
      "Myers flat",
      "Orick",
      "Orleans",
      "Petrolia",
      "Phillipsville",
      "Redway",
      "Rio dell",
      "Salyer",
      "Samoa",
      "Scotia",
      "Smith river",
      "Somes bar",
      "Redcrest",
      "Westhaven",
      "Weott",
      "Willow creek",
      "Leggett",
      "Piercy",
      "Whitethorn",
      "Zenia",
      "Zcta 955hh",
      "Zcta 955xx",
      "Amador city",
      "Auburn",
      "Bryte",
      "Brooks",
      "Capay",
      "Carmichael",
      "Citrus heights",
      "Clarksburg",
      "Coloma",
      "Cool",
      "Courtland",
      "Davis",
      "El macero",
      "Diamond springs",
      "Liberty farms",
      "El dorado",
      "Elk grove",
      "Elmira",
      "Elverta",
      "Esparto",
      "Fair oaks",
      "Fiddletown",
      "El dorado hills",
      "Foresthill",
      "Galt",
      "Garden valley",
      "Georgetown",
      "Greenwood",
      "Grizzly flats",
      "Guinda",
      "Herald",
      "Hood",
      "Ione",
      "Isleton",
      "Jackson",
      "Knights landing",
      "Kirkwood",
      "Lincoln",
      "Loomis",
      "Lotus",
      "Madison",
      "Mather afb",
      "Newcastle",
      "Trowbridge",
      "North highlands",
      "Roseville",
      "Orangevale",
      "Penryn",
      "Pilot hill",
      "Pine grove",
      "Pioneer",
      "Placerville",
      "Pleasant grove",
      "Plymouth",
      "Gold river",
      "Rescue",
      "Rio linda",
      "Rio oso",
      "River pines",
      "Robbins",
      "Rocklin",
      "Sheridan",
      "Cameron park",
      "Rancho murieta",
      "Somerset",
      "Sutter creek",
      "Thornton",
      "Vacaville",
      "Volcano",
      "Walnut grove",
      "West sacramento",
      "Wheatland",
      "Wilton",
      "Winters",
      "Woodland",
      "Zcta 956hh",
      "Zcta 956xx",
      "Alta",
      "Applegate",
      "Camino",
      "Iowa hill",
      "Dutch flat",
      "Emigrant gap",
      "Gold run",
      "Kyburz",
      "Echo lake",
      "Meadow vista",
      "Pacific house",
      "Soda springs",
      "Twin bridges",
      "Weimar",
      "Rancho cordova",
      "Granite bay",
      "Zcta 957hh",
      "Zcta 957xx",
      "Sacramento",
      "Zcta 958hh",
      "Marysville",
      "Beale afb",
      "Alleghany",
      "Arbuckle",
      "Artois",
      "Bangor",
      "Berry creek",
      "Biggs",
      "Browns valley",
      "Brownsville",
      "Butte city",
      "Camptonville",
      "Canyondam",
      "Challenge",
      "Cohasset",
      "Chico",
      "Clipper mills",
      "Colusa",
      "Crescent mills",
      "Dobbins",
      "Downieville",
      "Dunnigan",
      "Durham",
      "Elk creek",
      "Forbestown",
      "Butte meadows",
      "Glenn",
      "Goodyears bar",
      "Grass valley",
      "Penn valley",
      "Greenville",
      "Gridley",
      "Grimes",
      "Hamilton city",
      "Live oak",
      "Magalia",
      "Maxwell",
      "Meadow valley",
      "Meridian",
      "Nevada city",
      "North san juan",
      "Olivehurst",
      "Oregon house",
      "Orland",
      "Pulga",
      "Oroville",
      "Palermo",
      "Paradise",
      "Princeton",
      "Quincy",
      "Rackerby",
      "Richardson sprin",
      "Richvale",
      "Rough and ready",
      "Smartville",
      "Stirling city",
      "Stonyford",
      "La porte",
      "Sutter",
      "Taylorsville",
      "Twain",
      "Washington",
      "Williams",
      "Willows",
      "Yuba city",
      "Zcta 959hh",
      "Zcta 959xx",
      "Redding",
      "Adin",
      "Anderson",
      "Bella vista",
      "Bieber",
      "Big bar",
      "Big bend",
      "Burney",
      "Callahan",
      "Canby",
      "Cassel",
      "Castella",
      "Shasta lake",
      "Chester",
      "Corning",
      "Cottonwood",
      "Dorris",
      "Douglas city",
      "Dunsmuir",
      "Sawyers bar",
      "Fall river mills",
      "Flournoy",
      "Forks of salmon",
      "Fort jones",
      "French gulch",
      "Gazelle",
      "Gerber",
      "Greenview",
      "Grenada",
      "Happy camp",
      "Hat creek",
      "Hayfork",
      "Hornbrook",
      "Hyampom",
      "Igo",
      "Helena",
      "Klamath river",
      "Lakehead",
      "Lewiston",
      "Lookout",
      "Los molinos",
      "Mcarthur",
      "Mccloud",
      "Macdoel",
      "Manton",
      "Mill creek",
      "Millville",
      "Mineral",
      "Montague",
      "Montgomery creek",
      "Mount shasta",
      "Nubieber",
      "Oak run",
      "Old station",
      "Palo cedro",
      "Paskenta",
      "Paynes creek",
      "Wildwood",
      "Red bluff",
      "Round mountain",
      "Scott bar",
      "Seiad valley",
      "Shasta",
      "Shingletown",
      "Tehama",
      "Trinity center",
      "Vina",
      "Weaverville",
      "Edgewood",
      "Whiskeytown",
      "Whitmore",
      "Yreka",
      "Zcta 960hh",
      "Zcta 960xx",
      "Alturas",
      "Cromberg",
      "Cedarville",
      "Chilcoot",
      "Clio",
      "Coleville",
      "Davis creek",
      "Doyle",
      "Eagleville",
      "Fort bidwell",
      "Herlong",
      "Janesville",
      "Lake city",
      "Likely",
      "Litchfield",
      "Loyalton",
      "Madeline",
      "Hope valley",
      "Milford",
      "Portola",
      "Ravendale",
      "Calpine",
      "Sierra city",
      "Sierraville",
      "Standish",
      "Susanville",
      "Termo",
      "Topaz",
      "Tulelake",
      "Wendel",
      "Peninsula villag",
      "Carnelian bay",
      "Homewood",
      "Tahoma",
      "Kings beach",
      "Tahoe city",
      "Olympic valley",
      "Tahoe vista",
      "South lake tahoe",
      "Truckee",
      "Zcta 961hh",
      "Zcta 961xx",
    ],
    ZipCodes: {
      "": ["89439", "97635", "976HH"],
      "Los angeles": [
        "90001",
        "90002",
        "90003",
        "90004",
        "90005",
        "90006",
        "90007",
        "90008",
        "90010",
        "90011",
        "90012",
        "90013",
        "90014",
        "90015",
        "90016",
        "90017",
        "90018",
        "90019",
        "90020",
        "90021",
        "90023",
        "90024",
        "90025",
        "90026",
        "90027",
        "90028",
        "90029",
        "90031",
        "90032",
        "90033",
        "90034",
        "90035",
        "90036",
        "90037",
        "90038",
        "90039",
        "90041",
        "90042",
        "90043",
        "90044",
        "90045",
        "90047",
        "90048",
        "90049",
        "90056",
        "90057",
        "90059",
        "90061",
        "90062",
        "90064",
        "90065",
        "90066",
        "90067",
        "90068",
        "90071",
        "90077",
        "900HH",
      ],
      "East los angeles": ["90022"],
      "City of commerce": ["90040"],
      Cole: ["90046"],
      Vernon: ["90058"],
      Hazard: ["90063"],
      "West hollywood": ["90069"],
      "Bell gardens": ["90201"],
      "Beverly hills": ["90210", "90211", "90212"],
      "Rancho dominguez": ["90220"],
      "East rancho domi": ["90221"],
      Rosewood: ["90222"],
      "Culver city": ["90230", "90232"],
      Downey: ["90240", "90241", "90242"],
      "El segundo": ["90245"],
      Gardena: ["90247", "90248", "90249"],
      "Holly park": ["90250"],
      "Hermosa beach": ["90254"],
      "Huntington park": ["90255"],
      Lawndale: ["90260", "90261"],
      Lynwood: ["90262"],
      Malibu: ["90263", "90265"],
      "Manhattan beach": ["90266"],
      Maywood: ["90270"],
      "Pacific palisade": ["90272"],
      "Palos verdes est": ["90274"],
      "Zcta 90275": ["90275"],
      "Redondo beach": ["90277", "90278"],
      "South gate": ["90280"],
      Topanga: ["90290"],
      Venice: ["90291"],
      "Marina del rey": ["90292"],
      "Playa del rey": ["90293"],
      "Zcta 902hh": ["902HH"],
      Inglewood: ["90301", "90302", "90303", "90305"],
      Lennox: ["90304"],
      "Santa monica": ["90401", "90402", "90403", "90404", "90405"],
      "Zcta 904hh": ["904HH"],
      Torrance: ["90501", "90502", "90503", "90504", "90505"],
      Whittier: ["90601", "90602", "90603", "90604", "90605"],
      "Los nietos": ["90606"],
      "Buena park": ["90620", "90621"],
      Cerritos: ["90623", "90701", "90703"],
      Cypress: ["90630"],
      "La habra heights": ["90631"],
      "La mirada": ["90638"],
      Montebello: ["90640"],
      Norwalk: ["90650"],
      "Pico rivera": ["90660"],
      "Santa fe springs": ["90670"],
      Stanton: ["90680"],
      "Zcta 906hh": ["906HH"],
      Avalon: ["90704"],
      Bellflower: ["90706"],
      "Harbor city": ["90710"],
      Lakewood: ["90712", "90713", "90715"],
      "Hawaiian gardens": ["90716"],
      "Rancho palos ver": ["90717", "90732"],
      Rossmoor: ["90720"],
      Paramount: ["90723"],
      "San pedro": ["90731"],
      "Seal beach": ["90740"],
      "Sunset beach": ["90742"],
      Surfside: ["90743"],
      Wilmington: ["90744"],
      Carson: ["90745", "90746", "90810"],
      "Zcta 907hh": ["907HH"],
      "Zcta 907xx": ["907XX"],
      "Long beach": [
        "90802",
        "90803",
        "90805",
        "90808",
        "90813",
        "90814",
        "90815",
        "90822",
      ],
      "Signal hill": ["90804", "90806", "90807"],
      "Zcta 908hh": ["908HH"],
      Altadena: ["91001"],
      Arcadia: ["91006", "91007"],
      Bradbury: ["91010"],
      Flintridge: ["91011"],
      Monrovia: ["91016"],
      Montrose: ["91020"],
      "Sierra madre": ["91024"],
      "South pasadena": ["91030"],
      "Shadow hills": ["91040"],
      Tujunga: ["91042"],
      "Zcta 910hh": ["910HH"],
      "Zcta 910xx": ["910XX"],
      Pasadena: ["91101", "91103", "91104", "91105", "91106", "91107"],
      "San marino": ["91108"],
      Glendale: [
        "91201",
        "91202",
        "91203",
        "91204",
        "91205",
        "91206",
        "91207",
        "91208",
      ],
      "La crescenta": ["91214"],
      "Oak park": ["91301"],
      Calabasas: ["91302"],
      "Canoga park": ["91303", "91304"],
      Winnetka: ["91306"],
      "West hills": ["91307"],
      Chatsworth: ["91311"],
      Encino: ["91316", "91436"],
      "Newbury park": ["91320"],
      Newhall: ["91321", "91381"],
      Northridge: ["91324", "91325"],
      "Porter ranch": ["91326"],
      Arleta: ["91331"],
      Reseda: ["91335"],
      "San fernando": ["91340"],
      Sylmar: ["91342"],
      "North hills": ["91343"],
      "Granada hills": ["91344"],
      "Mission hills": ["91345"],
      "Agua dulce": ["91350"],
      "Canyon country": ["91351"],
      "Sun valley": ["91352"],
      Valencia: ["91354", "91355"],
      Tarzana: ["91356"],
      "Thousand oaks": ["91360"],
      "Westlake village": ["91361", "91362"],
      "Woodland hills": ["91364", "91367"],
      "Zcta 91377": ["91377"],
      Castaic: ["91384"],
      "Zcta 913hh": ["913HH"],
      "Zcta 913xx": ["913XX"],
      "Van nuys": ["91401", "91405", "91406", "91411"],
      "Panorama city": ["91402"],
      "Sherman oaks": ["91403", "91423"],
      Burbank: ["91501", "91502", "91504", "91505", "91506"],
      "North hollywood": ["91601", "91605", "91606"],
      "Toluca lake": ["91602"],
      "Studio city": ["91604"],
      "Valley village": ["91607"],
      "Universal city": ["91608"],
      "Alta loma": ["91701", "91737"],
      Azusa: ["91702"],
      Irwindale: ["91706"],
      "Chino hills": ["91709"],
      Chino: ["91710"],
      Claremont: ["91711"],
      Corona: ["91719"],
      Covina: ["91722", "91723", "91724"],
      "Rancho cucamonga": ["91730"],
      "El monte": ["91731", "91732"],
      "South el monte": ["91733"],
      Etiwanda: ["91739"],
      Glendora: ["91740", "91741"],
      Guasti: ["91743"],
      Industry: ["91744"],
      "Hacienda heights": ["91745"],
      Bassett: ["91746"],
      "Rowland heights": ["91748"],
      "La verne": ["91750"],
      "Mira loma": ["91752"],
      "Monterey park": ["91754", "91755"],
      "Mt baldy": ["91759"],
      Ontario: ["91761", "91762", "91764"],
      Montclair: ["91763"],
      "Diamond bar": ["91765", "91789"],
      "Phillips ranch": ["91766"],
      Pomona: ["91767", "91768"],
      Rosemead: ["91770"],
      "San dimas": ["91773"],
      "San gabriel": ["91775", "91776"],
      "Temple city": ["91780"],
      Upland: ["91784", "91786"],
      "West covina": ["91790", "91791", "91792"],
      "Zcta 917hh": ["917HH"],
      "Zcta 917xx": ["917XX"],
      Alhambra: ["91801", "91803"],
      Alpine: ["91901"],
      Bonita: ["91902"],
      Boulevard: ["91905"],
      Campo: ["91906"],
      "Chula vista": ["91910", "91911", "91913", "91914", "91915"],
      Descanso: ["91916"],
      Dulzura: ["91917"],
      Guatay: ["91931"],
      "Imperial beach": ["91932"],
      Jacumba: ["91934"],
      Jamul: ["91935"],
      "La mesa": ["91941", "91942"],
      "Lemon grove": ["91945"],
      "Mount laguna": ["91948"],
      "National city": ["91950"],
      "Pine valley": ["91962"],
      Potrero: ["91963"],
      "Spring valley": ["91977", "91978"],
      Tecate: ["91980"],
      "Zcta 919hh": ["919HH"],
      "Zcta 919xx": ["919XX"],
      Bonsall: ["92003"],
      "Borrego springs": ["92004"],
      "Cardiff by the s": ["92007"],
      Carlsbad: ["92008", "92009"],
      "Del mar": ["92014"],
      "El cajon": ["92019", "92020", "92021"],
      Encinitas: ["92024"],
      Escondido: ["92025", "92026", "92027", "92029"],
      Fallbrook: ["92028"],
      Julian: ["92036"],
      "La jolla": ["92037"],
      Lakeside: ["92040"],
      Oceanside: ["92054", "92056", "92057"],
      Pala: ["92059"],
      "Palomar mountain": ["92060"],
      "Pauma valley": ["92061"],
      Poway: ["92064"],
      Ramona: ["92065"],
      Ranchita: ["92066"],
      "Rancho santa fe": ["92067"],
      "San marcos": ["92069"],
      "Santa ysabel": ["92070"],
      Santee: ["92071"],
      "Solana beach": ["92075"],
      "Zcta 92078": ["92078"],
      "Valley center": ["92082"],
      Vista: ["92083", "92084"],
      "Warner springs": ["92086"],
      "Zcta 92091": ["92091"],
      "Zcta 920hh": ["920HH"],
      "Zcta 920xx": ["920XX"],
      "San diego": [
        "92101",
        "92102",
        "92103",
        "92104",
        "92105",
        "92106",
        "92107",
        "92108",
        "92109",
        "92110",
        "92111",
        "92113",
        "92114",
        "92115",
        "92116",
        "92117",
        "92119",
        "92120",
        "92121",
        "92122",
        "92123",
        "92124",
        "92126",
        "92127",
        "92128",
        "92129",
        "92130",
        "92131",
        "92139",
        "92154",
      ],
      Coronado: ["92118"],
      "San ysidro": ["92173"],
      "Zcta 921hh": ["921HH"],
      "Chiriaco summit": ["92201"],
      Indio: ["92203"],
      "Indian wells": ["92210"],
      "Palm desert": ["92211"],
      Banning: ["92220"],
      Beaumont: ["92223"],
      "Lost lake": ["92225"],
      Brawley: ["92227"],
      Cabazon: ["92230"],
      Calexico: ["92231"],
      Calipatria: ["92233"],
      "Cathedral city": ["92234"],
      Coachella: ["92236"],
      "Eagle mountain": ["92239"],
      "Desert hot sprin": ["92240", "92241"],
      "Big river": ["92242"],
      "El centro": ["92243"],
      Heber: ["92249"],
      Holtville: ["92250"],
      Imperial: ["92251"],
      "Joshua tree": ["92252"],
      "La quinta": ["92253"],
      Mecca: ["92254"],
      "Morongo valley": ["92256"],
      Niland: ["92257"],
      "North palm sprin": ["92258"],
      Ocotillo: ["92259"],
      "Palm city": ["92260"],
      "Palm springs": ["92262", "92264"],
      "Palo verde": ["92266"],
      "Parker dam": ["92267"],
      Pioneertown: ["92268"],
      "Rancho mirage": ["92270"],
      Seeley: ["92273"],
      "Salton city": ["92274", "92275"],
      "Thousand palms": ["92276"],
      "Twentynine palms": ["92277", "92278"],
      Vidal: ["92280"],
      Westmorland: ["92281"],
      "White water": ["92282"],
      Felicity: ["92283"],
      "Yucca valley": ["92284"],
      Landers: ["92285"],
      "Zcta 922hh": ["922HH"],
      "Zcta 922xx": ["922XX"],
      Adelanto: ["92301"],
      Amboy: ["92304"],
      "Angelus oaks": ["92305"],
      "Apple valley": ["92307", "92308"],
      Baker: ["92309"],
      "Fort irwin": ["92310"],
      Barstow: ["92311"],
      "Zcta 92313": ["92313"],
      "Big bear city": ["92314"],
      "Big bear lake": ["92315"],
      Bloomington: ["92316"],
      "Blue jay": ["92317"],
      "Bryn mawr": ["92318"],
      Calimesa: ["92320"],
      "Cedar glen": ["92321"],
      Cima: ["92323"],
      "Grand terrace": ["92324"],
      Crestline: ["92325"],
      Daggett: ["92327"],
      "Death valley": ["92328"],
      Essex: ["92332"],
      Fawnskin: ["92333"],
      Fontana: ["92335", "92336", "92337"],
      Ludlow: ["92338"],
      "Forest falls": ["92339"],
      "Green valley lak": ["92341"],
      Helendale: ["92342"],
      Hesperia: ["92345"],
      "East highland": ["92346"],
      Hinkley: ["92347"],
      "Lake arrowhead": ["92352"],
      "Loma linda": ["92354"],
      "Lucerne valley": ["92356"],
      "Lytle creek": ["92358"],
      Mentone: ["92359"],
      Needles: ["92363"],
      Nipton: ["92364"],
      "Newberry springs": ["92365"],
      "Oro grande": ["92368"],
      Phelan: ["92371"],
      "Pinon hills": ["92372"],
      Redlands: ["92373", "92374"],
      Rialto: ["92376", "92377"],
      "Arrowbear lake": ["92382"],
      Shoshone: ["92384"],
      Sugarloaf: ["92386"],
      Tecopa: ["92389"],
      "Spring valley la": ["92392"],
      "George afb": ["92394"],
      Wrightwood: ["92397"],
      Yermo: ["92398"],
      Yucaipa: ["92399"],
      "Zcta 923hh": ["923HH"],
      "Zcta 923xx": ["923XX"],
      "San bernardino": ["92401", "92404", "92407", "92408", "92410", "92411"],
      Muscoy: ["92405"],
      Riverside: [
        "92501",
        "92503",
        "92504",
        "92505",
        "92506",
        "92507",
        "92508",
      ],
      Rubidoux: ["92509"],
      "March air force": ["92518"],
      "Lake elsinore": ["92530", "92532"],
      Aguanga: ["92536"],
      Anza: ["92539"],
      Hemet: ["92543", "92544", "92545"],
      Homeland: ["92548"],
      Idyllwild: ["92549"],
      "Zcta 92551": ["92551"],
      "Moreno valley": ["92553", "92555", "92557"],
      "Mountain center": ["92561"],
      Murrieta: ["92562", "92563"],
      Lakeview: ["92567"],
      "Mead valley": ["92570"],
      Perris: ["92571"],
      "San jacinto": ["92582"],
      "Gilman hot sprin": ["92583"],
      Menifee: ["92584"],
      Romoland: ["92585"],
      "Sun city": ["92586"],
      "Canyon lake": ["92587"],
      Temecula: ["92590", "92591", "92592"],
      Wildomar: ["92595"],
      Winchester: ["92596"],
      "Zcta 925hh": ["925HH"],
      "Zcta 925xx": ["925XX"],
      "Zcta 92602": ["92602"],
      "Zcta 92604": ["92604"],
      "Zcta 92606": ["92606"],
      "Foothill ranch": ["92610"],
      "Zcta 92612": ["92612"],
      "Zcta 92614": ["92614"],
      "Zcta 92618": ["92618"],
      "Zcta 92620": ["92620"],
      "Capistrano beach": ["92624"],
      "Corona del mar": ["92625"],
      "Costa mesa": ["92626", "92627"],
      "Monarch bay": ["92629"],
      "Lake forest": ["92630"],
      "Huntington beach": ["92646", "92647", "92648", "92649"],
      "Laguna niguel": ["92651", "92677"],
      "Laguna hills": ["92653"],
      "Midway city": ["92655"],
      "Aliso viejo": ["92656"],
      "Newport beach": ["92657", "92660", "92661", "92662", "92663"],
      "San clemente": ["92672", "92673"],
      "Mission viejo": ["92675", "92691", "92692"],
      Silverado: ["92676"],
      "Coto de caza": ["92679"],
      Westminster: ["92683"],
      "Rancho santa mar": ["92688"],
      "Zcta 92694": ["92694"],
      "Zcta 926hh": ["926HH"],
      "Zcta 926xx": ["926XX"],
      "Santa ana": ["92701", "92703", "92704", "92706"],
      "Cowan heights": ["92705"],
      "Santa ana height": ["92707"],
      "Fountain valley": ["92708"],
      "Zcta 92780": ["92780"],
      "Zcta 92782": ["92782"],
      "Zcta 927hh": ["927HH"],
      Anaheim: [
        "92801",
        "92802",
        "92804",
        "92805",
        "92806",
        "92807",
        "92808",
        "92821",
        "92823",
      ],
      "Zcta 92831": ["92831"],
      "Zcta 92832": ["92832"],
      "Zcta 92833": ["92833"],
      "Zcta 92835": ["92835"],
      "Zcta 92840": ["92840"],
      "Zcta 92841": ["92841"],
      "Zcta 92843": ["92843"],
      "Zcta 92844": ["92844"],
      "Zcta 92845": ["92845"],
      "Zcta 92860": ["92860"],
      "Zcta 92861": ["92861"],
      "Zcta 92865": ["92865"],
      "Zcta 92866": ["92866"],
      "Zcta 92867": ["92867"],
      "Zcta 92868": ["92868"],
      "Zcta 92869": ["92869"],
      "Zcta 92870": ["92870"],
      "Zcta 92879": ["92879"],
      "Zcta 92880": ["92880"],
      "Zcta 92881": ["92881"],
      "Zcta 92882": ["92882"],
      "Zcta 92883": ["92883"],
      "Zcta 92886": ["92886"],
      "Zcta 92887": ["92887"],
      "Zcta 928hh": ["928HH"],
      "Zcta 928xx": ["928XX"],
      "San buenaventura": ["93001", "93003", "93004"],
      Camarillo: ["93010", "93012"],
      Carpinteria: ["93013"],
      Bardsdale: ["93015"],
      Moorpark: ["93021"],
      "Oak view": ["93022"],
      Ojai: ["93023"],
      Oxnard: ["93030", "93033", "93035"],
      Piru: ["93040"],
      "Port hueneme": ["93041"],
      "Santa paula": ["93060"],
      "Santa susana": ["93063"],
      "Simi valley": ["93065"],
      Somis: ["93066"],
      Summerland: ["93067"],
      "Zcta 930hh": ["930HH"],
      "Zcta 930xx": ["930XX"],
      "Santa barbara": ["93101", "93103", "93105", "93109", "93110", "93111"],
      Montecito: ["93108"],
      Goleta: ["93117"],
      "Zcta 931hh": ["931HH"],
      "Zcta 931xx": ["931XX"],
      Alpaugh: ["93201"],
      Armona: ["93202"],
      Arvin: ["93203"],
      Avenal: ["93204"],
      Bodfish: ["93205"],
      Buttonwillow: ["93206"],
      "California hot s": ["93207"],
      "Camp nelson": ["93208"],
      Coalinga: ["93210"],
      Corcoran: ["93212"],
      Delano: ["93215", "93216"],
      Ducor: ["93218"],
      Earlimart: ["93219"],
      Exeter: ["93221"],
      "Frazier park": ["93222", "93225"],
      Farmersville: ["93223"],
      Fellows: ["93224"],
      Glennville: ["93226"],
      Hanford: ["93230"],
      Huron: ["93234"],
      Ivanhoe: ["93235"],
      Kernville: ["93238"],
      "Kettleman city": ["93239"],
      "Mountain mesa": ["93240"],
      Lamont: ["93241"],
      Laton: ["93242"],
      Gorman: ["93243"],
      Lemoncove: ["93244"],
      "Lemoore naval ai": ["93245"],
      Lindsay: ["93247"],
      "Lost hills": ["93249"],
      "Mc farland": ["93250"],
      "Mc kittrick": ["93251"],
      Maricopa: ["93252"],
      "New cuyama": ["93254"],
      Onyx: ["93255"],
      Pixley: ["93256"],
      Porterville: ["93257"],
      Posey: ["93260"],
      Richgrove: ["93261"],
      "Giant forest": ["93262"],
      Shafter: ["93263"],
      Springville: ["93265"],
      Stratford: ["93266"],
      Strathmore: ["93267"],
      Taft: ["93268"],
      "Terra bella": ["93270"],
      "Three rivers": ["93271"],
      Tipton: ["93272"],
      Tulare: ["93274"],
      Tupman: ["93276"],
      Visalia: ["93277", "93291", "93292"],
      Pond: ["93280"],
      Waukena: ["93282"],
      Weldon: ["93283"],
      "Wofford heights": ["93285"],
      Woodlake: ["93286"],
      Woody: ["93287"],
      "Zcta 932hh": ["932HH"],
      "Zcta 932xx": ["932XX"],
      Bakersfield: [
        "93301",
        "93304",
        "93306",
        "93307",
        "93308",
        "93309",
        "93311",
        "93313",
      ],
      "College heights": ["93305"],
      Greenacres: ["93312"],
      "Zcta 933hh": ["933HH"],
      "Zcta 933xx": ["933XX"],
      "San luis obispo": ["93401", "93405"],
      "Los osos": ["93402"],
      Halcyon: ["93420"],
      Atascadero: ["93422"],
      "Avila beach": ["93424"],
      Bradley: ["93426"],
      Buellton: ["93427"],
      Cambria: ["93428"],
      Casmalia: ["93429"],
      Cayucos: ["93430"],
      Creston: ["93432"],
      "Grover beach": ["93433"],
      Guadalupe: ["93434"],
      Harmony: ["93435"],
      Lompoc: ["93436", "93437"],
      "Los alamos": ["93440"],
      "Los olivos": ["93441"],
      "Morro bay": ["93442"],
      Nipomo: ["93444"],
      Oceano: ["93445"],
      Adelaide: ["93446"],
      "Paso robles": ["93447"],
      "Shell beach": ["93449"],
      "San ardo": ["93450"],
      Parkfield: ["93451"],
      "San simeon": ["93452"],
      "California valle": ["93453"],
      "Santa maria": ["93454"],
      Orcutt: ["93455"],
      "Zcta 93458": ["93458"],
      "Santa ynez": ["93460"],
      Shandon: ["93461"],
      Ballard: ["93463"],
      Templeton: ["93465"],
      "Zcta 934hh": ["934HH"],
      "Zcta 934xx": ["934XX"],
      Mojave: ["93501"],
      "California city": ["93505"],
      Acton: ["93510"],
      Benton: ["93512"],
      "Big pine": ["93513"],
      "Toms place": ["93514"],
      Boron: ["93516"],
      Bridgeport: ["93517"],
      Havilah: ["93518"],
      Darwin: ["93522"],
      "North edwards": ["93523"],
      Independence: ["93526"],
      Pearsonville: ["93527"],
      Johannesburg: ["93528"],
      "June lake": ["93529"],
      Keeler: ["93530"],
      Keene: ["93531"],
      "Elizabeth lake": ["93532"],
      Lancaster: ["93534"],
      "Hi vista": ["93535"],
      "Quartz hill": ["93536"],
      "Lee vining": ["93541"],
      "Juniper hills": ["93543", "93553"],
      Crystalaire: ["93544"],
      "Lone pine": ["93545"],
      "Crowley lake": ["93546"],
      Cartago: ["93549"],
      "Lake los angeles": ["93550", "93591"],
      "Leona valley": ["93551"],
      Palmdale: ["93552"],
      Randsburg: ["93554"],
      "China lake nwc": ["93555"],
      "Willow springs": ["93560"],
      "Bear valley spri": ["93561"],
      Argus: ["93562"],
      Valyermo: ["93563"],
      "Zcta 935hh": ["935HH"],
      "Zcta 935xx": ["935XX"],
      Ahwahnee: ["93601"],
      Auberry: ["93602"],
      Badger: ["93603"],
      "Bass lake": ["93604"],
      "Big creek": ["93605"],
      Biola: ["93606"],
      "Cantua creek": ["93608"],
      Caruthers: ["93609"],
      Chowchilla: ["93610"],
      Clovis: ["93611", "93612"],
      Coarsegold: ["93614"],
      Cutler: ["93615"],
      "Del rey": ["93616"],
      Dinuba: ["93618"],
      "Dos palos": ["93620"],
      Dunlap: ["93621"],
      Firebaugh: ["93622"],
      "Fish camp": ["93623"],
      "Five points": ["93624"],
      Fowler: ["93625"],
      Friant: ["93626"],
      Helm: ["93627"],
      Hume: ["93628"],
      Kerman: ["93630"],
      Kingsburg: ["93631"],
      Lakeshore: ["93634"],
      "Los banos": ["93635"],
      Madera: ["93637", "93638"],
      Mendota: ["93640"],
      Miramonte: ["93641"],
      "North fork": ["93643"],
      Oakhurst: ["93644"],
      "O neals": ["93645"],
      "Orange cove": ["93646"],
      Orosi: ["93647"],
      Parlier: ["93648"],
      Pinedale: ["93650"],
      Prather: ["93651"],
      Raisin: ["93652"],
      Raymond: ["93653"],
      Reedley: ["93654"],
      Riverdale: ["93656"],
      Sanger: ["93657"],
      "San joaquin": ["93660"],
      Selma: ["93662"],
      "Shaver lake": ["93664"],
      "South dos palos": ["93665"],
      Sultana: ["93666"],
      Tollhouse: ["93667"],
      Tranquillity: ["93668"],
      Wishon: ["93669"],
      Traver: ["93673"],
      "Squaw valley": ["93675"],
      "Zcta 936hh": ["936HH"],
      "Zcta 936xx": ["936XX"],
      Fresno: [
        "93701",
        "93702",
        "93703",
        "93705",
        "93710",
        "93711",
        "93720",
        "93721",
        "93722",
        "93726",
        "93727",
        "93728",
      ],
      "Fig garden villa": ["93704"],
      Easton: ["93706"],
      Calwa: ["93725"],
      Salinas: ["93901", "93905", "93906", "93908"],
      Prunedale: ["93907"],
      "Big sur": ["93920"],
      Carmel: ["93921", "93923"],
      "Carmel valley": ["93924"],
      Chualar: ["93925"],
      Gonzales: ["93926"],
      Greenfield: ["93927"],
      Jolon: ["93928"],
      "King city": ["93930"],
      Lockwood: ["93932"],
      Marina: ["93933"],
      "Del rey oaks": ["93940"],
      "Pacific grove": ["93950"],
      "Pebble beach": ["93953"],
      "San lucas": ["93954"],
      "Sand city": ["93955"],
      Soledad: ["93960"],
      Spreckels: ["93962"],
      "Zcta 939hh": ["939HH"],
      "Zcta 939xx": ["939XX"],
      Belmont: ["94002"],
      Brisbane: ["94005"],
      Hillsborough: ["94010"],
      Colma: ["94014"],
      "Daly city": ["94015"],
      "Half moon bay": ["94019"],
      "La honda": ["94020"],
      "Loma mar": ["94021"],
      "Los altos": ["94022", "94024"],
      "West menlo park": ["94025"],
      Atherton: ["94027"],
      Ladera: ["94028"],
      Millbrae: ["94030"],
      Montara: ["94037"],
      "Moss beach": ["94038"],
      "Mountain view": ["94040", "94041", "94043"],
      Pacifica: ["94044"],
      Pescadero: ["94060"],
      "Redwood city": ["94061", "94063", "94065"],
      Woodside: ["94062"],
      "San bruno": ["94066"],
      "San carlos": ["94070"],
      "San gregorio": ["94074"],
      "South san franci": ["94080"],
      Sunnyvale: ["94086", "94087", "94089"],
      "Zcta 940hh": ["940HH"],
      "San francisco": [
        "94102",
        "94103",
        "94104",
        "94105",
        "94107",
        "94108",
        "94109",
        "94110",
        "94111",
        "94112",
        "94114",
        "94115",
        "94116",
        "94117",
        "94118",
        "94121",
        "94122",
        "94123",
        "94124",
        "94127",
        "94128",
        "94129",
        "94130",
        "94131",
        "94132",
        "94133",
        "94134",
      ],
      "Zcta 941hh": ["941HH"],
      "Zcta 941xx": ["941XX"],
      "Palo alto": ["94301", "94304", "94306"],
      "East palo alto": ["94303"],
      Stanford: ["94305"],
      "Zcta 943hh": ["943HH"],
      "Russian river": ["94401"],
      "San mateo": ["94402", "94403"],
      "Foster city": ["94404"],
      "Zcta 944hh": ["944HH"],
      "Coast guard isla": ["94501"],
      Alameda: ["94502"],
      "Black hawk": ["94506"],
      Alamo: ["94507"],
      Angwin: ["94508"],
      Antioch: ["94509"],
      Benicia: ["94510"],
      "Bethel island": ["94511"],
      "Birds landing": ["94512"],
      Brentwood: ["94513"],
      Byron: ["94514"],
      Calistoga: ["94515"],
      Clayton: ["94517"],
      Concord: ["94518", "94519", "94520", "94521"],
      "Pleasant hill": ["94523"],
      Crockett: ["94525"],
      Danville: ["94526"],
      "El cerrito": ["94530"],
      Fairfield: ["94533"],
      "Travis afb": ["94535"],
      Fremont: ["94536", "94538", "94539", "94555"],
      Hayward: ["94541", "94542", "94544", "94545"],
      "Castro valley": ["94546", "94552"],
      Hercules: ["94547"],
      Lafayette: ["94549"],
      Livermore: ["94550"],
      Pacheco: ["94553"],
      Moraga: ["94556"],
      "Spanish flat": ["94558"],
      Napa: ["94559"],
      Newark: ["94560"],
      Oakley: ["94561"],
      Orinda: ["94563"],
      Pinole: ["94564"],
      "Shore acres": ["94565"],
      Pleasanton: ["94566", "94588"],
      "Pope valley": ["94567"],
      Dublin: ["94568"],
      "Port costa": ["94569"],
      "Rio vista": ["94571"],
      Rodeo: ["94572"],
      "Saint helena": ["94574"],
      "Deer park": ["94576"],
      "San leandro": ["94577", "94578", "94579"],
      "San lorenzo": ["94580"],
      "San ramon": ["94583"],
      "Suisun city": ["94585"],
      Sunol: ["94586"],
      "Union city": ["94587"],
      "American canyon": ["94589"],
      Vallejo: ["94590", "94591"],
      "Mare island": ["94592"],
      "Walnut creek": ["94595", "94596", "94598"],
      Yountville: ["94599"],
      "Zcta 945hh": ["945HH"],
      "Zcta 945xx": ["945XX"],
      Oakland: [
        "94601",
        "94602",
        "94603",
        "94605",
        "94606",
        "94607",
        "94609",
        "94610",
        "94612",
        "94619",
        "94621",
      ],
      Emeryville: ["94608"],
      Piedmont: ["94611", "94618"],
      "Zcta 946hh": ["946HH"],
      Berkeley: ["94702", "94703", "94704", "94705", "94709", "94710"],
      Albany: ["94706"],
      Kensington: ["94707", "94708"],
      "Zcta 947hh": ["947HH"],
      Richmond: ["94801", "94804", "94805"],
      "El sobrante": ["94803"],
      "San pablo": ["94806"],
      "Zcta 948hh": ["948HH"],
      "San rafael": ["94901"],
      "Civic center": ["94903"],
      Kentfield: ["94904"],
      Belvedere: ["94920"],
      Bodega: ["94922"],
      "Bodega bay": ["94923"],
      Bolinas: ["94924"],
      "Corte madera": ["94925"],
      "Rohnert park": ["94928"],
      "Dillon beach": ["94929"],
      Fairfax: ["94930"],
      Cotati: ["94931"],
      "Forest knolls": ["94933"],
      Inverness: ["94937"],
      Lagunitas: ["94938"],
      Larkspur: ["94939"],
      Marshall: ["94940"],
      "Mill valley": ["94941"],
      Novato: ["94945", "94947", "94949"],
      Nicasio: ["94946"],
      Olema: ["94950"],
      Penngrove: ["94951"],
      Petaluma: ["94952", "94954"],
      "Point reyes stat": ["94956"],
      "San anselmo": ["94960"],
      "San geronimo": ["94963"],
      "San quentin": ["94964"],
      Sausalito: ["94965"],
      "Stinson beach": ["94970"],
      Tomales: ["94971"],
      "Valley ford": ["94972"],
      Woodacre: ["94973"],
      "Zcta 949hh": ["949HH"],
      "Zcta 949xx": ["949XX"],
      Alviso: ["95002"],
      Aptos: ["95003"],
      Aromas: ["95004"],
      "Ben lomond": ["95005"],
      "Boulder creek": ["95006"],
      Brookdale: ["95007"],
      Campbell: ["95008"],
      Capitola: ["95010"],
      Castroville: ["95012"],
      "Monte vista": ["95014"],
      Davenport: ["95017"],
      Felton: ["95018"],
      Freedom: ["95019"],
      Gilroy: ["95020"],
      Hollister: ["95023"],
      "Monte sereno": ["95030"],
      "Los gatos": ["95032"],
      "Zcta 95033": ["95033"],
      Milpitas: ["95035"],
      "Morgan hill": ["95037"],
      "Moss landing": ["95039"],
      "Mount hermon": ["95041"],
      Paicines: ["95043"],
      "San juan bautist": ["95045"],
      "San martin": ["95046"],
      "Santa clara": ["95050", "95051", "95054"],
      "Scotts valley": ["95060", "95066"],
      "Santa cruz": ["95062", "95064", "95065"],
      Saratoga: ["95070"],
      Soquel: ["95073"],
      "La selva beach": ["95076"],
      "Zcta 950hh": ["950HH"],
      "San jose": [
        "95110",
        "95111",
        "95112",
        "95113",
        "95116",
        "95117",
        "95118",
        "95119",
        "95120",
        "95121",
        "95122",
        "95123",
        "95124",
        "95125",
        "95126",
        "95127",
        "95128",
        "95129",
        "95130",
        "95131",
        "95132",
        "95133",
        "95134",
        "95135",
        "95136",
        "95138",
        "95139",
        "95148",
      ],
      "Mount hamilton": ["95140"],
      "Zcta 951hh": ["951HH"],
      Stockton: [
        "95202",
        "95203",
        "95204",
        "95205",
        "95206",
        "95207",
        "95209",
        "95210",
        "95212",
        "95215",
        "95219",
      ],
      Acampo: ["95220"],
      Altaville: ["95221"],
      "Angels camp": ["95222"],
      "Bear valley": ["95223"],
      Avery: ["95224"],
      Burson: ["95225"],
      "Campo seco": ["95226"],
      Clements: ["95227"],
      Copperopolis: ["95228"],
      Farmington: ["95230"],
      "French camp": ["95231"],
      Glencoe: ["95232"],
      "Hathaway pines": ["95233"],
      Linden: ["95236"],
      Lockeford: ["95237"],
      Lodi: ["95240", "95242"],
      "Mokelumne hill": ["95245"],
      "Mountain ranch": ["95246"],
      Murphys: ["95247"],
      "San andreas": ["95249"],
      Sheepranch: ["95250"],
      Vallecito: ["95251"],
      "Valley springs": ["95252"],
      Wallace: ["95254"],
      "West point": ["95255"],
      Wilseyville: ["95257"],
      Woodbridge: ["95258"],
      "Zcta 952hh": ["952HH"],
      "Zcta 952xx": ["952XX"],
      Atwater: ["95301"],
      Ballico: ["95303"],
      "Big oak flat": ["95305"],
      "Catheys valley": ["95306"],
      Ceres: ["95307"],
      Columbia: ["95310"],
      Coulterville: ["95311"],
      Cressey: ["95312"],
      "Crows landing": ["95313"],
      Delhi: ["95315"],
      Denair: ["95316"],
      "El nido": ["95317"],
      "El portal": ["95318"],
      Escalon: ["95320"],
      Groveland: ["95321"],
      Gustine: ["95322"],
      Hickman: ["95323"],
      Hilmar: ["95324"],
      Hornitos: ["95325"],
      Hughson: ["95326"],
      Jamestown: ["95327"],
      Keyes: ["95328"],
      "La grange": ["95329"],
      Lathrop: ["95330"],
      "Le grand": ["95333"],
      Livingston: ["95334"],
      "Cold springs": ["95335"],
      Manteca: ["95336", "95337"],
      Mariposa: ["95338"],
      "Red top": ["95340"],
      Midpines: ["95345"],
      "Mi wuk village": ["95346"],
      Merced: ["95348"],
      Modesto: ["95350", "95351", "95354", "95355", "95356", "95357", "95358"],
      Newman: ["95360"],
      "Knights ferry": ["95361"],
      Patterson: ["95363"],
      Pinecrest: ["95364"],
      Planada: ["95365"],
      Ripon: ["95366"],
      Riverbank: ["95367"],
      Salida: ["95368"],
      Snelling: ["95369"],
      Sonora: ["95370"],
      Soulsbyville: ["95372"],
      Stevinson: ["95374"],
      Tracy: ["95376"],
      Tuolumne: ["95379"],
      Turlock: ["95380", "95382"],
      "Twain harte": ["95383"],
      Vernalis: ["95385"],
      Waterford: ["95386"],
      Westley: ["95387"],
      Winton: ["95388"],
      "Yosemite lodge": ["95389"],
      "Zcta 953hh": ["953HH"],
      "Zcta 953xx": ["953XX"],
      "Santa rosa": ["95401", "95403", "95404", "95405", "95407", "95409"],
      Albion: ["95410"],
      Annapolis: ["95412"],
      Boonville: ["95415"],
      Branscomb: ["95417"],
      "Camp meeker": ["95419"],
      Caspar: ["95420"],
      Cazadero: ["95421"],
      Clearlake: ["95422"],
      "Clearlake oaks": ["95423"],
      "Clearlake park": ["95424"],
      Cloverdale: ["95425"],
      "Loch lomond": ["95426"],
      Comptche: ["95427"],
      Covelo: ["95428"],
      "Dos rios": ["95429"],
      "Duncans mills": ["95430"],
      Eldridge: ["95431"],
      Elk: ["95432"],
      Forestville: ["95436"],
      "Fort bragg": ["95437"],
      Fulton: ["95439"],
      Geyserville: ["95441"],
      "Glen ellen": ["95442"],
      Glenhaven: ["95443"],
      Graton: ["95444"],
      Gualala: ["95445"],
      Guerneville: ["95446"],
      Healdsburg: ["95448"],
      Hopland: ["95449"],
      Jenner: ["95450"],
      Kelseyville: ["95451"],
      Kenwood: ["95452"],
      Lakeport: ["95453"],
      Laytonville: ["95454"],
      Littleriver: ["95456"],
      "Lower lake": ["95457"],
      Lucerne: ["95458"],
      Manchester: ["95459"],
      Mendocino: ["95460"],
      Middletown: ["95461"],
      "Russian river md": ["95462"],
      Navarro: ["95463"],
      Nice: ["95464"],
      Occidental: ["95465"],
      Philo: ["95466"],
      "Point arena": ["95468"],
      "Potter valley": ["95469"],
      "Redwood valley": ["95470"],
      "Rio nido": ["95471"],
      Freestone: ["95472"],
      Sonoma: ["95476"],
      "Stewarts point": ["95480"],
      Talmage: ["95481"],
      Ukiah: ["95482"],
      "Upper lake": ["95485"],
      Westport: ["95488"],
      Willits: ["95490"],
      Windsor: ["95492"],
      "Witter springs": ["95493"],
      Yorkville: ["95494"],
      "The sea ranch": ["95497"],
      "Zcta 954hh": ["954HH"],
      "Zcta 954xx": ["954XX"],
      Eureka: ["95501", "95502", "95503"],
      Alderpoint: ["95511"],
      Blocksburg: ["95514"],
      "Zcta 95519": ["95519"],
      "Mc kinleyville": ["95521"],
      Bayside: ["95524"],
      "Blue lake": ["95525"],
      Ruth: ["95526"],
      "Burnt ranch": ["95527"],
      Carlotta: ["95528"],
      "Crescent city": ["95531"],
      Ferndale: ["95536"],
      "Fields landing": ["95537"],
      Fortuna: ["95540"],
      Garberville: ["95542"],
      Gasquet: ["95543"],
      Honeydew: ["95545"],
      Hoopa: ["95546"],
      Hydesville: ["95547"],
      Klamath: ["95548"],
      Kneeland: ["95549"],
      Korbel: ["95550"],
      Loleta: ["95551"],
      "Mad river": ["95552"],
      Miranda: ["95553"],
      "Myers flat": ["95554"],
      Orick: ["95555"],
      Orleans: ["95556"],
      Petrolia: ["95558"],
      Phillipsville: ["95559"],
      Redway: ["95560"],
      "Rio dell": ["95562"],
      Salyer: ["95563"],
      Samoa: ["95564"],
      Scotia: ["95565"],
      "Smith river": ["95567"],
      "Somes bar": ["95568"],
      Redcrest: ["95569"],
      Westhaven: ["95570"],
      Weott: ["95571"],
      "Willow creek": ["95573"],
      Leggett: ["95585"],
      Piercy: ["95587"],
      Whitethorn: ["95589"],
      Zenia: ["95595"],
      "Zcta 955hh": ["955HH"],
      "Zcta 955xx": ["955XX"],
      "Amador city": ["95601"],
      Auburn: ["95602", "95603"],
      Bryte: ["95605"],
      Brooks: ["95606"],
      Capay: ["95607"],
      Carmichael: ["95608"],
      "Citrus heights": ["95610", "95621"],
      Clarksburg: ["95612"],
      Coloma: ["95613"],
      Cool: ["95614"],
      Courtland: ["95615"],
      Davis: ["95616"],
      "El macero": ["95618"],
      "Diamond springs": ["95619"],
      "Liberty farms": ["95620"],
      "El dorado": ["95623"],
      "Elk grove": ["95624", "95758"],
      Elmira: ["95625"],
      Elverta: ["95626"],
      Esparto: ["95627"],
      "Fair oaks": ["95628"],
      Fiddletown: ["95629"],
      "El dorado hills": ["95630", "95762"],
      Foresthill: ["95631"],
      Galt: ["95632"],
      "Garden valley": ["95633"],
      Georgetown: ["95634"],
      Greenwood: ["95635"],
      "Grizzly flats": ["95636"],
      Guinda: ["95637"],
      Herald: ["95638"],
      Hood: ["95639"],
      Ione: ["95640"],
      Isleton: ["95641"],
      Jackson: ["95642"],
      "Knights landing": ["95645"],
      Kirkwood: ["95646"],
      Lincoln: ["95648"],
      Loomis: ["95650"],
      Lotus: ["95651"],
      Madison: ["95653"],
      "Mather afb": ["95655"],
      Newcastle: ["95658"],
      Trowbridge: ["95659"],
      "North highlands": ["95660"],
      Roseville: ["95661", "95678", "95747"],
      Orangevale: ["95662"],
      Penryn: ["95663"],
      "Pilot hill": ["95664"],
      "Pine grove": ["95665"],
      Pioneer: ["95666"],
      Placerville: ["95667"],
      "Pleasant grove": ["95668"],
      Plymouth: ["95669"],
      "Gold river": ["95670"],
      Rescue: ["95672"],
      "Rio linda": ["95673"],
      "Rio oso": ["95674"],
      "River pines": ["95675"],
      Robbins: ["95676"],
      Rocklin: ["95677", "95765"],
      Sheridan: ["95681"],
      "Cameron park": ["95682"],
      "Rancho murieta": ["95683"],
      Somerset: ["95684"],
      "Sutter creek": ["95685"],
      Thornton: ["95686"],
      Vacaville: ["95687", "95688"],
      Volcano: ["95689"],
      "Walnut grove": ["95690"],
      "West sacramento": ["95691"],
      Wheatland: ["95692"],
      Wilton: ["95693"],
      Winters: ["95694"],
      Woodland: ["95695", "95776"],
      "Zcta 956hh": ["956HH"],
      "Zcta 956xx": ["956XX"],
      Alta: ["95701"],
      Applegate: ["95703"],
      Camino: ["95709"],
      "Iowa hill": ["95713"],
      "Dutch flat": ["95714"],
      "Emigrant gap": ["95715"],
      "Gold run": ["95717"],
      Kyburz: ["95720"],
      "Echo lake": ["95721"],
      "Meadow vista": ["95722"],
      "Pacific house": ["95726"],
      "Soda springs": ["95728"],
      "Twin bridges": ["95735"],
      Weimar: ["95736"],
      "Rancho cordova": ["95742"],
      "Granite bay": ["95746"],
      "Zcta 957hh": ["957HH"],
      "Zcta 957xx": ["957XX"],
      Sacramento: [
        "95814",
        "95815",
        "95816",
        "95817",
        "95818",
        "95819",
        "95820",
        "95821",
        "95822",
        "95823",
        "95824",
        "95825",
        "95826",
        "95827",
        "95828",
        "95829",
        "95830",
        "95831",
        "95832",
        "95833",
        "95834",
        "95835",
        "95836",
        "95837",
        "95838",
        "95841",
        "95842",
        "95843",
        "95864",
      ],
      "Zcta 958hh": ["958HH"],
      Marysville: ["95901"],
      "Beale afb": ["95903"],
      Alleghany: ["95910"],
      Arbuckle: ["95912"],
      Artois: ["95913"],
      Bangor: ["95914"],
      "Berry creek": ["95916"],
      Biggs: ["95917"],
      "Browns valley": ["95918"],
      Brownsville: ["95919"],
      "Butte city": ["95920"],
      Camptonville: ["95922"],
      Canyondam: ["95923"],
      Challenge: ["95925"],
      Cohasset: ["95926"],
      Chico: ["95928"],
      "Clipper mills": ["95930"],
      Colusa: ["95932"],
      "Crescent mills": ["95934"],
      Dobbins: ["95935"],
      Downieville: ["95936"],
      Dunnigan: ["95937"],
      Durham: ["95938"],
      "Elk creek": ["95939"],
      Forbestown: ["95941"],
      "Butte meadows": ["95942"],
      Glenn: ["95943"],
      "Goodyears bar": ["95944"],
      "Grass valley": ["95945", "95949"],
      "Penn valley": ["95946"],
      Greenville: ["95947"],
      Gridley: ["95948"],
      Grimes: ["95950"],
      "Hamilton city": ["95951"],
      "Live oak": ["95953"],
      Magalia: ["95954"],
      Maxwell: ["95955"],
      "Meadow valley": ["95956"],
      Meridian: ["95957"],
      "Nevada city": ["95959"],
      "North san juan": ["95960"],
      Olivehurst: ["95961"],
      "Oregon house": ["95962"],
      Orland: ["95963"],
      Pulga: ["95965"],
      Oroville: ["95966"],
      Palermo: ["95968"],
      Paradise: ["95969"],
      Princeton: ["95970"],
      Quincy: ["95971"],
      Rackerby: ["95972"],
      "Richardson sprin": ["95973"],
      Richvale: ["95974"],
      "Rough and ready": ["95975"],
      Smartville: ["95977"],
      "Stirling city": ["95978"],
      Stonyford: ["95979"],
      "La porte": ["95981"],
      Sutter: ["95982"],
      Taylorsville: ["95983"],
      Twain: ["95984"],
      Washington: ["95986"],
      Williams: ["95987"],
      Willows: ["95988"],
      "Yuba city": ["95991", "95993"],
      "Zcta 959hh": ["959HH"],
      "Zcta 959xx": ["959XX"],
      Redding: ["96001", "96002", "96003"],
      Adin: ["96006"],
      Anderson: ["96007"],
      "Bella vista": ["96008"],
      Bieber: ["96009"],
      "Big bar": ["96010"],
      "Big bend": ["96011"],
      Burney: ["96013"],
      Callahan: ["96014"],
      Canby: ["96015"],
      Cassel: ["96016"],
      Castella: ["96017"],
      "Shasta lake": ["96019"],
      Chester: ["96020"],
      Corning: ["96021"],
      Cottonwood: ["96022"],
      Dorris: ["96023"],
      "Douglas city": ["96024"],
      Dunsmuir: ["96025"],
      "Sawyers bar": ["96027"],
      "Fall river mills": ["96028"],
      Flournoy: ["96029"],
      "Forks of salmon": ["96031"],
      "Fort jones": ["96032"],
      "French gulch": ["96033"],
      Gazelle: ["96034"],
      Gerber: ["96035"],
      Greenview: ["96037"],
      Grenada: ["96038"],
      "Happy camp": ["96039"],
      "Hat creek": ["96040"],
      Hayfork: ["96041"],
      Hornbrook: ["96044"],
      Hyampom: ["96046"],
      Igo: ["96047"],
      Helena: ["96048"],
      "Klamath river": ["96050"],
      Lakehead: ["96051"],
      Lewiston: ["96052"],
      Lookout: ["96054"],
      "Los molinos": ["96055"],
      Mcarthur: ["96056"],
      Mccloud: ["96057"],
      Macdoel: ["96058"],
      Manton: ["96059"],
      "Mill creek": ["96061"],
      Millville: ["96062"],
      Mineral: ["96063"],
      Montague: ["96064"],
      "Montgomery creek": ["96065"],
      "Mount shasta": ["96067"],
      Nubieber: ["96068"],
      "Oak run": ["96069"],
      "Old station": ["96071"],
      "Palo cedro": ["96073"],
      Paskenta: ["96074"],
      "Paynes creek": ["96075"],
      Wildwood: ["96076"],
      "Red bluff": ["96080"],
      "Round mountain": ["96084"],
      "Scott bar": ["96085"],
      "Seiad valley": ["96086"],
      Shasta: ["96087"],
      Shingletown: ["96088"],
      Tehama: ["96090"],
      "Trinity center": ["96091"],
      Vina: ["96092"],
      Weaverville: ["96093"],
      Edgewood: ["96094"],
      Whiskeytown: ["96095"],
      Whitmore: ["96096"],
      Yreka: ["96097"],
      "Zcta 960hh": ["960HH"],
      "Zcta 960xx": ["960XX"],
      Alturas: ["96101"],
      Cromberg: ["96103"],
      Cedarville: ["96104"],
      Chilcoot: ["96105"],
      Clio: ["96106"],
      Coleville: ["96107"],
      "Davis creek": ["96108"],
      Doyle: ["96109"],
      Eagleville: ["96110"],
      "Fort bidwell": ["96112"],
      Herlong: ["96113"],
      Janesville: ["96114"],
      "Lake city": ["96115"],
      Likely: ["96116"],
      Litchfield: ["96117"],
      Loyalton: ["96118"],
      Madeline: ["96119"],
      "Hope valley": ["96120"],
      Milford: ["96121"],
      Portola: ["96122"],
      Ravendale: ["96123"],
      Calpine: ["96124"],
      "Sierra city": ["96125"],
      Sierraville: ["96126"],
      Standish: ["96128"],
      Susanville: ["96130"],
      Termo: ["96132"],
      Topaz: ["96133"],
      Tulelake: ["96134"],
      Wendel: ["96136"],
      "Peninsula villag": ["96137"],
      "Carnelian bay": ["96140"],
      Homewood: ["96141"],
      Tahoma: ["96142"],
      "Kings beach": ["96143"],
      "Tahoe city": ["96145"],
      "Olympic valley": ["96146"],
      "Tahoe vista": ["96148"],
      "South lake tahoe": ["96150"],
      Truckee: ["96161"],
      "Zcta 961hh": ["961HH"],
      "Zcta 961xx": ["961XX"],
    },
  },
  {
    abbrevation: "CO",
    name: "Colorado",
    Cities: [
      "Arvada",
      "Arvada",
      "Zcta 80007",
      "Aurora",
      "Broomfield",
      "Westminster",
      "Commerce city",
      "Dupont",
      "Eldorado springs",
      "Lafayette",
      "Louisville",
      "Wheat ridge",
      "Zcta 800hh",
      "Zcta 800xx",
      "Agate",
      "Bennett",
      "Byers",
      "Castle rock",
      "Deer trail",
      "Elbert",
      "Elizabeth",
      "Cherry hills vil",
      "Englewood",
      "Franktown",
      "Kiowa",
      "Larkspur",
      "Littleton",
      "Greenwood villag",
      "Bow mar",
      "Highlands ranch",
      "Zcta 80128",
      "Monument",
      "Palmer lake",
      "Parker",
      "Deckers",
      "Strasburg",
      "Watkins",
      "Zcta 80138",
      "Zcta 801xx",
      "Denver",
      "Mountain view",
      "Edgewater",
      "Lakewood",
      "Federal heights",
      "Glendale",
      "Thornton",
      "Lowry afb",
      "Northglenn",
      "Zcta 80246",
      "Zcta 80260",
      "Lincoln center b",
      "Two united bank",
      "First interstate",
      "Boulder",
      "Golden",
      "Alma",
      "Bailey",
      "Black hawk",
      "Bond",
      "Breckenridge",
      "Buffalo creek",
      "Burns",
      "Central city",
      "Clark",
      "Coalmont",
      "Como",
      "Conifer",
      "Cowdrey",
      "Keystone",
      "Dumont",
      "Empire",
      "Evergreen",
      "Fairplay",
      "Fraser",
      "Copper mountain",
      "Georgetown",
      "Granby",
      "Grand lake",
      "Grant",
      "Hartsel",
      "Hot sulphur spri",
      "Idaho springs",
      "Indian hills",
      "Jamestown",
      "Jefferson",
      "Kittredge",
      "Kremmling",
      "Leadville",
      "Mc coy",
      "Morrison",
      "Nederland",
      "Oak creek",
      "Parshall",
      "Phippsburg",
      "Pine",
      "Rand",
      "Rollinsville",
      "Silver plume",
      "Tabernash",
      "Toponas",
      "Walden",
      "Ward",
      "Winter park",
      "Yampa",
      "Steamboat spring",
      "Silverthorne",
      "Zcta 804hh",
      "Zcta 804xx",
      "Longmont",
      "Allenspark",
      "Bellvue",
      "Berthoud",
      "Dacono",
      "Drake",
      "Erie",
      "Estes park",
      "Firestone",
      "Fort collins",
      "Zcta 80528",
      "Frederick",
      "Glen haven",
      "Johnstown",
      "Laporte",
      "Virginia dale",
      "Loveland",
      "Lyons",
      "Mead",
      "Milliken",
      "Red feather lake",
      "Timnath",
      "Wellington",
      "Windsor",
      "Lochbui",
      "Ault",
      "Briggsdale",
      "Carr",
      "Eaton",
      "Evans",
      "Wattenburg",
      "Gilcrest",
      "Gill",
      "Garden city",
      "Greeley",
      "Henderson",
      "Hudson",
      "Keenesburg",
      "Kersey",
      "La salle",
      "Nunn",
      "Orchard",
      "Pierce",
      "Platteville",
      "Roggen",
      "Weldona",
      "Hoyt",
      "Zcta 806hh",
      "Fort morgan",
      "Log lane village",
      "Akron",
      "Amherst",
      "Atwood",
      "Brush",
      "Crook",
      "Eckley",
      "Fleming",
      "Grover",
      "Haxtun",
      "Hillrose",
      "Holyoke",
      "Hale",
      "Iliff",
      "Julesburg",
      "Lindon",
      "Willard",
      "New raymer",
      "Otis",
      "Ovid",
      "Padroni",
      "Peetz",
      "Sedgwick",
      "Snyder",
      "Sterling",
      "Stoneham",
      "Vernon",
      "Last chance",
      "Laird",
      "Yuma",
      "Zcta 807hh",
      "Anton",
      "Arapahoe",
      "Arriba",
      "Bethune",
      "Burlington",
      "Calhan",
      "North pole",
      "Cheyenne wells",
      "Cope",
      "Cripple creek",
      "Divide",
      "Flagler",
      "Florissant",
      "Fountain",
      "Genoa",
      "Green mountain f",
      "Guffey",
      "Hugo",
      "Joes",
      "Karval",
      "Kirk",
      "Kit carson",
      "Lake george",
      "Limon",
      "Manitou springs",
      "Matheson",
      "Peyton",
      "Ramah",
      "Rush",
      "Seibert",
      "Simla",
      "Stratton",
      "United states ai",
      "Victor",
      "Vona",
      "Wild horse",
      "Woodland park",
      "Yoder",
      "Colorado springs",
      "Fort carson",
      "Pueblo",
      "Pueblo west",
      "Aguilar",
      "Arlington",
      "North avondale",
      "Beulah",
      "Boncarbo",
      "Boone",
      "Branson",
      "Campo",
      "Cheraw",
      "Crowley",
      "Chivington",
      "Fowler",
      "Farisita",
      "Granada",
      "Hartman",
      "Caddoa",
      "Haswell",
      "Holly",
      "Villegreen",
      "Timpas",
      "Lamar",
      "Deora",
      "Cuchara",
      "Mc clave",
      "Manzanola",
      "Delhi",
      "Olney springs",
      "Ordway",
      "Utleyville",
      "Red wing",
      "Rocky ford",
      "Rye",
      "Towner",
      "Springfield",
      "Sugar city",
      "Swink",
      "Trinchera",
      "Jansen",
      "Lycan",
      "Vilas",
      "Farista",
      "Walsh",
      "Weston",
      "Wiley",
      "Zcta 810hh",
      "Zcta 810xx",
      "Alamosa",
      "Antonito",
      "Arboles",
      "Bayfield",
      "Blanca",
      "Capulin",
      "Center",
      "Chama",
      "Chimney rock",
      "Chromo",
      "Creede",
      "Crestone",
      "La garita",
      "Fort garland",
      "Hooper",
      "Ignacio",
      "La jara",
      "Manassa",
      "Moffat",
      "Monte vista",
      "Mosca",
      "Pagosa springs",
      "Romeo",
      "Saguache",
      "Sanford",
      "Mesita",
      "San pablo",
      "South fork",
      "Villa grove",
      "Zcta 811xx",
      "Salida",
      "Almont",
      "Buena vista",
      "Canon city",
      "Cimarron",
      "Coal creek",
      "Coaldale",
      "Cotopaxi",
      "Crested butte",
      "Florence",
      "Gunnison",
      "Howard",
      "Lake city",
      "Nathrop",
      "Parlin",
      "Penrose",
      "Pitkin",
      "Powderhorn",
      "Rockvale",
      "Sargents",
      "Twin lakes",
      "Westcliffe",
      "Wetmore",
      "Zcta 812hh",
      "Zcta 812xx",
      "Durango",
      "Cahone",
      "Cortez",
      "Dolores",
      "Dove creek",
      "Egnar",
      "Hesperus",
      "Lewis",
      "Mancos",
      "Mesa verde natio",
      "Pleasant view",
      "Rico",
      "Towaoc",
      "Yellow jacket",
      "Zcta 813xx",
      "Montrose",
      "Austin",
      "Bedrock",
      "Cedaredge",
      "Crawford",
      "Delta",
      "Eckert",
      "Hotchkiss",
      "Naturita",
      "Norwood",
      "Nucla",
      "Olathe",
      "Ophir",
      "Ouray",
      "Paonia",
      "Placerville",
      "Redvale",
      "Ridgway",
      "Silverton",
      "Somerset",
      "Telluride",
      "Zcta 814xx",
      "Grand junction",
      "Fruitvale",
      "Clifton",
      "Fruita",
      "Gateway",
      "Glade park",
      "Loma",
      "Mack",
      "Palisade",
      "Whitewater",
      "Zcta 815hh",
      "Zcta 815xx",
      "Glenwood springs",
      "Dinosaur",
      "Aspen",
      "Snowmass village",
      "Avon",
      "Basalt",
      "Marble",
      "Collbran",
      "Craig",
      "De beque",
      "Eagle",
      "Edwards",
      "Battlement mesa",
      "Gypsum",
      "Hamilton",
      "Hayden",
      "Maybell",
      "Meeker",
      "Meredith",
      "Mesa",
      "Gilman",
      "Molina",
      "New castle",
      "Rangely",
      "Red cliff",
      "Rifle",
      "Silt",
      "Slater",
      "Snowmass",
      "Wolcott",
      "Woody creek",
      "Vail",
      "Zcta 816hh",
      "Zcta 816xx",
      "",
    ],
    ZipCodes: {
      Arvada: ["80002", "80003", "80004", "80005"],
      "Zcta 80007": ["80007"],
      Aurora: [
        "80010",
        "80011",
        "80012",
        "80013",
        "80014",
        "80015",
        "80016",
        "80017",
        "80018",
        "80019",
      ],
      Broomfield: ["80020"],
      Westminster: ["80021", "80030", "80031"],
      "Commerce city": ["80022"],
      Dupont: ["80024"],
      "Eldorado springs": ["80025"],
      Lafayette: ["80026"],
      Louisville: ["80027"],
      "Wheat ridge": ["80033"],
      "Zcta 800hh": ["800HH"],
      "Zcta 800xx": ["800XX"],
      Agate: ["80101"],
      Bennett: ["80102"],
      Byers: ["80103"],
      "Castle rock": ["80104"],
      "Deer trail": ["80105"],
      Elbert: ["80106"],
      Elizabeth: ["80107"],
      "Cherry hills vil": ["80110", "80111"],
      Englewood: ["80112"],
      Franktown: ["80116"],
      Kiowa: ["80117"],
      Larkspur: ["80118"],
      Littleton: ["80120", "80122", "80124", "80125", "80127"],
      "Greenwood villag": ["80121"],
      "Bow mar": ["80123"],
      "Highlands ranch": ["80126"],
      "Zcta 80128": ["80128"],
      Monument: ["80132"],
      "Palmer lake": ["80133"],
      Parker: ["80134"],
      Deckers: ["80135"],
      Strasburg: ["80136"],
      Watkins: ["80137"],
      "Zcta 80138": ["80138"],
      "Zcta 801xx": ["801XX"],
      Denver: [
        "80202",
        "80203",
        "80204",
        "80205",
        "80206",
        "80207",
        "80209",
        "80210",
        "80211",
        "80216",
        "80218",
        "80219",
        "80220",
        "80223",
        "80224",
        "80227",
        "80231",
        "80235",
        "80236",
        "80237",
        "80239",
        "80249",
        "80294",
      ],
      "Mountain view": ["80212"],
      Edgewater: ["80214"],
      Lakewood: ["80215", "80226", "80228", "80232"],
      "Federal heights": ["80221"],
      Glendale: ["80222"],
      Thornton: ["80229"],
      "Lowry afb": ["80230"],
      Northglenn: ["80233", "80234", "80241"],
      "Zcta 80246": ["80246"],
      "Zcta 80260": ["80260"],
      "Lincoln center b": ["80264"],
      "Two united bank": ["80290"],
      "First interstate": ["80293"],
      Boulder: ["80301", "80302", "80303", "80304"],
      Golden: ["80401", "80403"],
      Alma: ["80420"],
      Bailey: ["80421"],
      "Black hawk": ["80422"],
      Bond: ["80423"],
      Breckenridge: ["80424"],
      "Buffalo creek": ["80425"],
      Burns: ["80426"],
      "Central city": ["80427"],
      Clark: ["80428"],
      Coalmont: ["80430"],
      Como: ["80432"],
      Conifer: ["80433"],
      Cowdrey: ["80434"],
      Keystone: ["80435"],
      Dumont: ["80436"],
      Empire: ["80438"],
      Evergreen: ["80439"],
      Fairplay: ["80440"],
      Fraser: ["80442"],
      "Copper mountain": ["80443"],
      Georgetown: ["80444"],
      Granby: ["80446"],
      "Grand lake": ["80447"],
      Grant: ["80448"],
      Hartsel: ["80449"],
      "Hot sulphur spri": ["80451"],
      "Idaho springs": ["80452"],
      "Indian hills": ["80454"],
      Jamestown: ["80455"],
      Jefferson: ["80456"],
      Kittredge: ["80457"],
      Kremmling: ["80459"],
      Leadville: ["80461"],
      "Mc coy": ["80463"],
      Morrison: ["80465"],
      Nederland: ["80466"],
      "Oak creek": ["80467"],
      Parshall: ["80468"],
      Phippsburg: ["80469"],
      Pine: ["80470"],
      Rand: ["80473"],
      Rollinsville: ["80474"],
      "Silver plume": ["80476"],
      Tabernash: ["80478"],
      Toponas: ["80479"],
      Walden: ["80480"],
      Ward: ["80481"],
      "Winter park": ["80482"],
      Yampa: ["80483"],
      "Steamboat spring": ["80487"],
      Silverthorne: ["80498"],
      "Zcta 804hh": ["804HH"],
      "Zcta 804xx": ["804XX"],
      Longmont: ["80501", "80503", "80504"],
      Allenspark: ["80510"],
      Bellvue: ["80512"],
      Berthoud: ["80513"],
      Dacono: ["80514"],
      Drake: ["80515"],
      Erie: ["80516"],
      "Estes park": ["80517"],
      Firestone: ["80520"],
      "Fort collins": ["80521", "80524", "80525", "80526"],
      "Zcta 80528": ["80528"],
      Frederick: ["80530"],
      "Glen haven": ["80532"],
      Johnstown: ["80534"],
      Laporte: ["80535"],
      "Virginia dale": ["80536"],
      Loveland: ["80537", "80538"],
      Lyons: ["80540"],
      Mead: ["80542"],
      Milliken: ["80543"],
      "Red feather lake": ["80545"],
      Timnath: ["80547"],
      Wellington: ["80549"],
      Windsor: ["80550"],
      Lochbui: ["80601"],
      Ault: ["80610"],
      Briggsdale: ["80611"],
      Carr: ["80612"],
      Eaton: ["80615"],
      Evans: ["80620"],
      Wattenburg: ["80621"],
      Gilcrest: ["80623"],
      Gill: ["80624"],
      "Garden city": ["80631"],
      Greeley: ["80634"],
      Henderson: ["80640"],
      Hudson: ["80642"],
      Keenesburg: ["80643"],
      Kersey: ["80644"],
      "La salle": ["80645"],
      Nunn: ["80648"],
      Orchard: ["80649"],
      Pierce: ["80650"],
      Platteville: ["80651"],
      Roggen: ["80652"],
      Weldona: ["80653"],
      Hoyt: ["80654"],
      "Zcta 806hh": ["806HH"],
      "Fort morgan": ["80701"],
      "Log lane village": ["80705"],
      Akron: ["80720"],
      Amherst: ["80721"],
      Atwood: ["80722"],
      Brush: ["80723"],
      Crook: ["80726"],
      Eckley: ["80727"],
      Fleming: ["80728"],
      Grover: ["80729"],
      Haxtun: ["80731"],
      Hillrose: ["80733"],
      Holyoke: ["80734"],
      Hale: ["80735"],
      Iliff: ["80736"],
      Julesburg: ["80737"],
      Lindon: ["80740"],
      Willard: ["80741"],
      "New raymer": ["80742"],
      Otis: ["80743"],
      Ovid: ["80744"],
      Padroni: ["80745"],
      Peetz: ["80747"],
      Sedgwick: ["80749"],
      Snyder: ["80750"],
      Sterling: ["80751"],
      Stoneham: ["80754"],
      Vernon: ["80755"],
      "Last chance": ["80757"],
      Laird: ["80758"],
      Yuma: ["80759"],
      "Zcta 807hh": ["807HH"],
      Anton: ["80801"],
      Arapahoe: ["80802"],
      Arriba: ["80804"],
      Bethune: ["80805"],
      Burlington: ["80807"],
      Calhan: ["80808"],
      "North pole": ["80809"],
      "Cheyenne wells": ["80810"],
      Cope: ["80812"],
      "Cripple creek": ["80813"],
      Divide: ["80814"],
      Flagler: ["80815"],
      Florissant: ["80816"],
      Fountain: ["80817"],
      Genoa: ["80818"],
      "Green mountain f": ["80819"],
      Guffey: ["80820"],
      Hugo: ["80821"],
      Joes: ["80822"],
      Karval: ["80823"],
      Kirk: ["80824"],
      "Kit carson": ["80825"],
      "Lake george": ["80827"],
      Limon: ["80828"],
      "Manitou springs": ["80829"],
      Matheson: ["80830"],
      Peyton: ["80831"],
      Ramah: ["80832"],
      Rush: ["80833"],
      Seibert: ["80834"],
      Simla: ["80835"],
      Stratton: ["80836"],
      "United states ai": ["80840"],
      Victor: ["80860"],
      Vona: ["80861"],
      "Wild horse": ["80862"],
      "Woodland park": ["80863", "80866"],
      Yoder: ["80864"],
      "Colorado springs": [
        "80903",
        "80904",
        "80905",
        "80906",
        "80907",
        "80908",
        "80909",
        "80910",
        "80911",
        "80915",
        "80916",
        "80917",
        "80918",
        "80919",
        "80920",
        "80921",
        "80922",
        "80925",
        "80926",
        "80928",
        "80929",
        "80930",
      ],
      "Fort carson": ["80913"],
      Pueblo: ["81001", "81003", "81004", "81005", "81006", "81008"],
      "Pueblo west": ["81007"],
      Aguilar: ["81020"],
      Arlington: ["81021"],
      "North avondale": ["81022"],
      Beulah: ["81023"],
      Boncarbo: ["81024"],
      Boone: ["81025"],
      Branson: ["81027"],
      Campo: ["81029"],
      Cheraw: ["81030"],
      Crowley: ["81033"],
      Chivington: ["81036"],
      Fowler: ["81039"],
      Farisita: ["81040"],
      Granada: ["81041"],
      Hartman: ["81043"],
      Caddoa: ["81044"],
      Haswell: ["81045"],
      Holly: ["81047"],
      Villegreen: ["81049"],
      Timpas: ["81050"],
      Lamar: ["81052"],
      Deora: ["81054"],
      Cuchara: ["81055"],
      "Mc clave": ["81057"],
      Manzanola: ["81058"],
      Delhi: ["81059"],
      "Olney springs": ["81062"],
      Ordway: ["81063"],
      Utleyville: ["81064"],
      "Red wing": ["81066"],
      "Rocky ford": ["81067"],
      Rye: ["81069"],
      Towner: ["81071"],
      Springfield: ["81073"],
      "Sugar city": ["81076"],
      Swink: ["81077"],
      Trinchera: ["81081"],
      Jansen: ["81082"],
      Lycan: ["81084"],
      Vilas: ["81087"],
      Farista: ["81089"],
      Walsh: ["81090"],
      Weston: ["81091"],
      Wiley: ["81092"],
      "Zcta 810hh": ["810HH"],
      "Zcta 810xx": ["810XX"],
      Alamosa: ["81101"],
      Antonito: ["81120"],
      Arboles: ["81121"],
      Bayfield: ["81122"],
      Blanca: ["81123"],
      Capulin: ["81124"],
      Center: ["81125"],
      Chama: ["81126"],
      "Chimney rock": ["81127"],
      Chromo: ["81128"],
      Creede: ["81130"],
      Crestone: ["81131"],
      "La garita": ["81132"],
      "Fort garland": ["81133"],
      Hooper: ["81136"],
      Ignacio: ["81137"],
      "La jara": ["81140"],
      Manassa: ["81141"],
      Moffat: ["81143"],
      "Monte vista": ["81144"],
      Mosca: ["81146"],
      "Pagosa springs": ["81147"],
      Romeo: ["81148"],
      Saguache: ["81149"],
      Sanford: ["81151"],
      Mesita: ["81152"],
      "San pablo": ["81153"],
      "South fork": ["81154"],
      "Villa grove": ["81155"],
      "Zcta 811xx": ["811XX"],
      Salida: ["81201"],
      Almont: ["81210"],
      "Buena vista": ["81211"],
      "Canon city": ["81212"],
      Cimarron: ["81220"],
      "Coal creek": ["81221"],
      Coaldale: ["81222"],
      Cotopaxi: ["81223"],
      "Crested butte": ["81224", "81225"],
      Florence: ["81226"],
      Gunnison: ["81230"],
      Howard: ["81233"],
      "Lake city": ["81235"],
      Nathrop: ["81236"],
      Parlin: ["81239"],
      Penrose: ["81240"],
      Pitkin: ["81241"],
      Powderhorn: ["81243"],
      Rockvale: ["81244"],
      Sargents: ["81248"],
      "Twin lakes": ["81251"],
      Westcliffe: ["81252"],
      Wetmore: ["81253"],
      "Zcta 812hh": ["812HH"],
      "Zcta 812xx": ["812XX"],
      Durango: ["81301"],
      Cahone: ["81320"],
      Cortez: ["81321"],
      Dolores: ["81323"],
      "Dove creek": ["81324"],
      Egnar: ["81325"],
      Hesperus: ["81326"],
      Lewis: ["81327"],
      Mancos: ["81328"],
      "Mesa verde natio": ["81330"],
      "Pleasant view": ["81331"],
      Rico: ["81332"],
      Towaoc: ["81334"],
      "Yellow jacket": ["81335"],
      "Zcta 813xx": ["813XX"],
      Montrose: ["81401"],
      Austin: ["81410"],
      Bedrock: ["81411"],
      Cedaredge: ["81413"],
      Crawford: ["81415"],
      Delta: ["81416"],
      Eckert: ["81418"],
      Hotchkiss: ["81419"],
      Naturita: ["81422"],
      Norwood: ["81423"],
      Nucla: ["81424"],
      Olathe: ["81425"],
      Ophir: ["81426"],
      Ouray: ["81427"],
      Paonia: ["81428"],
      Placerville: ["81430"],
      Redvale: ["81431"],
      Ridgway: ["81432"],
      Silverton: ["81433"],
      Somerset: ["81434"],
      Telluride: ["81435"],
      "Zcta 814xx": ["814XX"],
      "Grand junction": ["81501", "81503", "81505", "81506"],
      Fruitvale: ["81504"],
      Clifton: ["81520"],
      Fruita: ["81521"],
      Gateway: ["81522"],
      "Glade park": ["81523"],
      Loma: ["81524"],
      Mack: ["81525"],
      Palisade: ["81526"],
      Whitewater: ["81527"],
      "Zcta 815hh": ["815HH"],
      "Zcta 815xx": ["815XX"],
      "Glenwood springs": ["81601"],
      Dinosaur: ["81610"],
      Aspen: ["81611"],
      "Snowmass village": ["81615"],
      Avon: ["81620"],
      Basalt: ["81621"],
      Marble: ["81623"],
      Collbran: ["81624"],
      Craig: ["81625"],
      "De beque": ["81630"],
      Eagle: ["81631"],
      Edwards: ["81632"],
      "Battlement mesa": ["81635"],
      Gypsum: ["81637"],
      Hamilton: ["81638"],
      Hayden: ["81639"],
      Maybell: ["81640"],
      Meeker: ["81641"],
      Meredith: ["81642"],
      Mesa: ["81643"],
      Gilman: ["81645"],
      Molina: ["81646"],
      "New castle": ["81647"],
      Rangely: ["81648"],
      "Red cliff": ["81649"],
      Rifle: ["81650"],
      Silt: ["81652"],
      Slater: ["81653"],
      Snowmass: ["81654"],
      Wolcott: ["81655"],
      "Woody creek": ["81656"],
      Vail: ["81657"],
      "Zcta 816hh": ["816HH"],
      "Zcta 816xx": ["816XX"],
      "": ["82063"],
    },
  },
  {
    abbrevation: "CT",
    name: "Connecticut",
    Cities: [
      "Avon",
      "Avon",
      "Bloomfield",
      "Bristol",
      "Burlington",
      "Windsorville",
      "Canaan",
      "Canton",
      "Canton center",
      "Colebrook",
      "Collinsville",
      "East berlin",
      "East canaan",
      "East granby",
      "East hartland",
      "Ellington",
      "Falls village",
      "Farmington",
      "Glastonbury",
      "Granby",
      "Berlin",
      "Lakeville",
      "Manchester",
      "Bolton",
      "New britain",
      "New hartford",
      "Norfolk",
      "North canton",
      "North granby",
      "Plainville",
      "Pleasant valley",
      "Riverton",
      "Vernon rockville",
      "Rocky hill",
      "Salisbury",
      "Sharon",
      "Simsbury",
      "Somers",
      "South glastonbur",
      "South windsor",
      "Stafford springs",
      "Suffield",
      "Tariffville",
      "Enfield",
      "Tolland",
      "Unionville",
      "East windsor",
      "Weatogue",
      "West granby",
      "West hartland",
      "West simsbury",
      "West suffield",
      "Windsor",
      "Windsor locks",
      "Winsted",
      "Zcta 060hh",
      "Hartford",
      "W hartford",
      "East hartford",
      "Wethersfield",
      "Maple hill",
      "Zcta 061hh",
      "Willimantic",
      "Amston",
      "Andover",
      "Brooklyn",
      "Chaplin",
      "Columbia",
      "Coventry",
      "Danielson",
      "Dayville",
      "Eastford",
      "Hampton",
      "Hebron",
      "Lebanon",
      "Mansfield center",
      "North franklin",
      "North grosvenord",
      "North windham",
      "Pomfret center",
      "Putnam",
      "Quinebaug",
      "Scotland",
      "South windham",
      "Storrs mansfield",
      "University of ct",
      "Thompson",
      "Warrenville",
      "West willington",
      "Windham",
      "Woodstock",
      "Woodstock valley",
      "Zcta 062hh",
      "New london",
      "Baltic",
      "Canterbury",
      "East lyme",
      "Bozrah",
      "Gales ferry",
      "Gilman",
      "Ledyard",
      "Groton",
      "Jewett city",
      "Moosup",
      "Mystic",
      "Niantic",
      "North stonington",
      "Norwich",
      "Preston",
      "Oakdale",
      "Old lyme",
      "Oneco",
      "Plainfield",
      "Quaker hill",
      "South lyme",
      "Sterling",
      "Stonington",
      "Pawcatuck",
      "Taftville",
      "Uncasville",
      "Voluntown",
      "Waterford",
      "Zcta 063hh",
      "Ansonia",
      "Beacon falls",
      "Branford",
      "Centerbrook",
      "Cheshire",
      "Chester",
      "Clinton",
      "Colchester",
      "Cromwell",
      "Deep river",
      "Derby",
      "Killingworth",
      "Salem",
      "Durham",
      "East haddam",
      "East hampton",
      "Essex",
      "Fairfield",
      "Guilford",
      "Haddam",
      "Higganum",
      "Ivoryton",
      "Madison",
      "Marlborough",
      "Meriden",
      "Middlefield",
      "Middletown",
      "Milford",
      "Monroe",
      "Moodus",
      "Newtown",
      "North branford",
      "Northford",
      "North haven",
      "Old saybrook",
      "Orange",
      "Oxford",
      "Plantsville",
      "Portland",
      "Rockfall",
      "Sandy hook",
      "Seymour",
      "Shelton",
      "Southbury",
      "Southington",
      "Southport",
      "Wallingford",
      "Westbrook",
      "Zcta 064hh",
      "New haven",
      "East haven",
      "Hamden",
      "West haven",
      "Bethany",
      "Woodbridge",
      "Zcta 065hh",
      "Bridgeport",
      "Trumbull",
      "Easton",
      "Zcta 06614",
      "Zcta 06615",
      "Zcta 066hh",
      "Waterbury",
      "Prospect",
      "Wolcott",
      "Bantam",
      "Bethlehem",
      "Bridgewater",
      "Warren",
      "Gaylordsville",
      "Goshen",
      "Kent",
      "Litchfield",
      "Middlebury",
      "Morris",
      "Naugatuck",
      "New milford",
      "New preston marb",
      "Northfield",
      "Oakville",
      "Plymouth",
      "Roxbury",
      "Sherman",
      "South kent",
      "Terryville",
      "Thomaston",
      "Torrington",
      "Harwinton",
      "Washington depot",
      "Watertown",
      "West cornwall",
      "Woodbury",
      "Zcta 067hh",
      "Bethel",
      "Brookfield",
      "Cos cob",
      "Danbury",
      "New fairfield",
      "Darien",
      "Byram",
      "Greenwich",
      "New canaan",
      "Norwalk",
      "Old greenwich",
      "Ridgefield",
      "Riverside",
      "Westport",
      "Weston",
      "West redding",
      "Wilton",
      "Zcta 068hh",
      "Stamford",
      "Ridgeway",
      "Zcta 069hh",
    ],
    ZipCodes: {
      Avon: ["06001"],
      Bloomfield: ["06002"],
      Bristol: ["06010"],
      Burlington: ["06013"],
      Windsorville: ["06016"],
      Canaan: ["06018"],
      Canton: ["06019"],
      "Canton center": ["06020"],
      Colebrook: ["06021"],
      Collinsville: ["06022"],
      "East berlin": ["06023"],
      "East canaan": ["06024"],
      "East granby": ["06026"],
      "East hartland": ["06027"],
      Ellington: ["06029"],
      "Falls village": ["06031"],
      Farmington: ["06032"],
      Glastonbury: ["06033"],
      Granby: ["06035"],
      Berlin: ["06037"],
      Lakeville: ["06039"],
      Manchester: ["06040"],
      Bolton: ["06043"],
      "New britain": ["06051", "06052", "06053"],
      "New hartford": ["06057"],
      Norfolk: ["06058"],
      "North canton": ["06059"],
      "North granby": ["06060"],
      Plainville: ["06062"],
      "Pleasant valley": ["06063"],
      Riverton: ["06065"],
      "Vernon rockville": ["06066"],
      "Rocky hill": ["06067"],
      Salisbury: ["06068"],
      Sharon: ["06069"],
      Simsbury: ["06070"],
      Somers: ["06071"],
      "South glastonbur": ["06073"],
      "South windsor": ["06074"],
      "Stafford springs": ["06076"],
      Suffield: ["06078"],
      Tariffville: ["06081"],
      Enfield: ["06082"],
      Tolland: ["06084"],
      Unionville: ["06085"],
      "East windsor": ["06088"],
      Weatogue: ["06089"],
      "West granby": ["06090"],
      "West hartland": ["06091"],
      "West simsbury": ["06092"],
      "West suffield": ["06093"],
      Windsor: ["06095"],
      "Windsor locks": ["06096"],
      Winsted: ["06098"],
      "Zcta 060hh": ["060HH"],
      Hartford: ["06103", "06105", "06106", "06112", "06114", "06120"],
      "W hartford": ["06107", "06110", "06117", "06119"],
      "East hartford": ["06108", "06118"],
      Wethersfield: ["06109"],
      "Maple hill": ["06111"],
      "Zcta 061hh": ["061HH"],
      Willimantic: ["06226"],
      Amston: ["06231"],
      Andover: ["06232"],
      Brooklyn: ["06234"],
      Chaplin: ["06235"],
      Columbia: ["06237"],
      Coventry: ["06238"],
      Danielson: ["06239"],
      Dayville: ["06241"],
      Eastford: ["06242"],
      Hampton: ["06247"],
      Hebron: ["06248"],
      Lebanon: ["06249"],
      "Mansfield center": ["06250"],
      "North franklin": ["06254"],
      "North grosvenord": ["06255"],
      "North windham": ["06256"],
      "Pomfret center": ["06259"],
      Putnam: ["06260"],
      Quinebaug: ["06262"],
      Scotland: ["06264"],
      "South windham": ["06266"],
      "Storrs mansfield": ["06268"],
      "University of ct": ["06269"],
      Thompson: ["06277"],
      Warrenville: ["06278"],
      "West willington": ["06279"],
      Windham: ["06280"],
      Woodstock: ["06281"],
      "Woodstock valley": ["06282"],
      "Zcta 062hh": ["062HH"],
      "New london": ["06320"],
      Baltic: ["06330"],
      Canterbury: ["06331"],
      "East lyme": ["06333"],
      Bozrah: ["06334"],
      "Gales ferry": ["06335"],
      Gilman: ["06336"],
      Ledyard: ["06339"],
      Groton: ["06340"],
      "Jewett city": ["06351"],
      Moosup: ["06354"],
      Mystic: ["06355"],
      Niantic: ["06357"],
      "North stonington": ["06359"],
      Norwich: ["06360"],
      Preston: ["06365"],
      Oakdale: ["06370"],
      "Old lyme": ["06371"],
      Oneco: ["06373"],
      Plainfield: ["06374"],
      "Quaker hill": ["06375"],
      "South lyme": ["06376"],
      Sterling: ["06377"],
      Stonington: ["06378"],
      Pawcatuck: ["06379"],
      Taftville: ["06380"],
      Uncasville: ["06382"],
      Voluntown: ["06384"],
      Waterford: ["06385"],
      "Zcta 063hh": ["063HH"],
      Ansonia: ["06401"],
      "Beacon falls": ["06403"],
      Branford: ["06405"],
      Centerbrook: ["06409"],
      Cheshire: ["06410"],
      Chester: ["06412"],
      Clinton: ["06413"],
      Colchester: ["06415"],
      Cromwell: ["06416"],
      "Deep river": ["06417"],
      Derby: ["06418"],
      Killingworth: ["06419"],
      Salem: ["06420"],
      Durham: ["06422"],
      "East haddam": ["06423"],
      "East hampton": ["06424"],
      Essex: ["06426"],
      Fairfield: ["06430", "06432"],
      Guilford: ["06437"],
      Haddam: ["06438"],
      Higganum: ["06441"],
      Ivoryton: ["06442"],
      Madison: ["06443"],
      Marlborough: ["06447"],
      Meriden: ["06450", "06451"],
      Middlefield: ["06455"],
      Middletown: ["06457"],
      Milford: ["06460"],
      Monroe: ["06468"],
      Moodus: ["06469"],
      Newtown: ["06470"],
      "North branford": ["06471"],
      Northford: ["06472"],
      "North haven": ["06473"],
      "Old saybrook": ["06475"],
      Orange: ["06477"],
      Oxford: ["06478"],
      Plantsville: ["06479"],
      Portland: ["06480"],
      Rockfall: ["06481"],
      "Sandy hook": ["06482"],
      Seymour: ["06483"],
      Shelton: ["06484"],
      Southbury: ["06488"],
      Southington: ["06489"],
      Southport: ["06490"],
      Wallingford: ["06492"],
      Westbrook: ["06498"],
      "Zcta 064hh": ["064HH"],
      "New haven": ["06504", "06510", "06511", "06515", "06519"],
      "East haven": ["06512", "06513"],
      Hamden: ["06514", "06517", "06518"],
      "West haven": ["06516"],
      Bethany: ["06524"],
      Woodbridge: ["06525"],
      "Zcta 065hh": ["065HH"],
      Bridgeport: ["06604", "06605", "06606", "06607", "06608", "06610"],
      Trumbull: ["06611"],
      Easton: ["06612"],
      "Zcta 06614": ["06614"],
      "Zcta 06615": ["06615"],
      "Zcta 066hh": ["066HH"],
      Waterbury: ["06702", "06704", "06705", "06706", "06708", "06710"],
      Prospect: ["06712"],
      Wolcott: ["06716"],
      Bantam: ["06750"],
      Bethlehem: ["06751"],
      Bridgewater: ["06752"],
      Warren: ["06754"],
      Gaylordsville: ["06755"],
      Goshen: ["06756"],
      Kent: ["06757"],
      Litchfield: ["06759"],
      Middlebury: ["06762"],
      Morris: ["06763"],
      Naugatuck: ["06770"],
      "New milford": ["06776"],
      "New preston marb": ["06777"],
      Northfield: ["06778"],
      Oakville: ["06779"],
      Plymouth: ["06782"],
      Roxbury: ["06783"],
      Sherman: ["06784"],
      "South kent": ["06785"],
      Terryville: ["06786"],
      Thomaston: ["06787"],
      Torrington: ["06790"],
      Harwinton: ["06791"],
      "Washington depot": ["06793", "06794"],
      Watertown: ["06795"],
      "West cornwall": ["06796"],
      Woodbury: ["06798"],
      "Zcta 067hh": ["067HH"],
      Bethel: ["06801"],
      Brookfield: ["06804"],
      "Cos cob": ["06807"],
      Danbury: ["06810", "06811"],
      "New fairfield": ["06812"],
      Darien: ["06820"],
      Byram: ["06830"],
      Greenwich: ["06831"],
      "New canaan": ["06840"],
      Norwalk: ["06850", "06851", "06853", "06854", "06855"],
      "Old greenwich": ["06870"],
      Ridgefield: ["06877"],
      Riverside: ["06878"],
      Westport: ["06880"],
      Weston: ["06883"],
      "West redding": ["06896"],
      Wilton: ["06897"],
      "Zcta 068hh": ["068HH"],
      Stamford: ["06901", "06902", "06903", "06906", "06907"],
      Ridgeway: ["06905"],
      "Zcta 069hh": ["069HH"],
    },
  },
  {
    abbrevation: "DE",
    name: "Delaware",
    Cities: [
      "Bear",
      "Bear",
      "Newark",
      "Claymont",
      "Delaware city",
      "Hockessin",
      "Middletown",
      "Montchanin",
      "Manor",
      "Odessa",
      "Port penn",
      "Rockland",
      "Saint georges",
      "Townsend",
      "Yorklyn",
      "Zcta 197hh",
      "Wilmington",
      "Talleyville",
      "Newport",
      "Greenville",
      "Marshallton",
      "Edgemoor",
      "Zcta 198hh",
      "Dover",
      "Dover afb",
      "Zcta 19904",
      "Bethany beach",
      "Bethel",
      "Bridgeville",
      "Camden wyoming",
      "Clayton",
      "Dagsboro",
      "Delmar",
      "Ellendale",
      "Farmington",
      "Felton",
      "Fenwick island",
      "Frankford",
      "Frederica",
      "Georgetown",
      "Greenwood",
      "Harbeson",
      "Harrington",
      "Hartly",
      "Houston",
      "Kenton",
      "Laurel",
      "Lewes",
      "Lincoln",
      "Magnolia",
      "Milford",
      "Marydel",
      "Long neck",
      "Millville",
      "Milton",
      "Dewey beach",
      "Seaford",
      "Selbyville",
      "Smyrna",
      "Viola",
      "Woodside",
      "Zcta 199hh",
    ],
    ZipCodes: {
      Bear: ["19701"],
      Newark: ["19702", "19711", "19713"],
      Claymont: ["19703"],
      "Delaware city": ["19706"],
      Hockessin: ["19707"],
      Middletown: ["19709"],
      Montchanin: ["19710"],
      Manor: ["19720"],
      Odessa: ["19730"],
      "Port penn": ["19731"],
      Rockland: ["19732"],
      "Saint georges": ["19733"],
      Townsend: ["19734"],
      Yorklyn: ["19736"],
      "Zcta 197hh": ["197HH"],
      Wilmington: ["19801", "19802", "19805", "19806"],
      Talleyville: ["19803"],
      Newport: ["19804"],
      Greenville: ["19807"],
      Marshallton: ["19808"],
      Edgemoor: ["19809", "19810"],
      "Zcta 198hh": ["198HH"],
      Dover: ["19901"],
      "Dover afb": ["19902"],
      "Zcta 19904": ["19904"],
      "Bethany beach": ["19930"],
      Bethel: ["19931"],
      Bridgeville: ["19933"],
      "Camden wyoming": ["19934"],
      Clayton: ["19938"],
      Dagsboro: ["19939"],
      Delmar: ["19940"],
      Ellendale: ["19941"],
      Farmington: ["19942"],
      Felton: ["19943"],
      "Fenwick island": ["19944"],
      Frankford: ["19945"],
      Frederica: ["19946"],
      Georgetown: ["19947"],
      Greenwood: ["19950"],
      Harbeson: ["19951"],
      Harrington: ["19952"],
      Hartly: ["19953"],
      Houston: ["19954"],
      Kenton: ["19955"],
      Laurel: ["19956"],
      Lewes: ["19958"],
      Lincoln: ["19960"],
      Magnolia: ["19962"],
      Milford: ["19963"],
      Marydel: ["19964"],
      "Long neck": ["19966"],
      Millville: ["19967", "19970"],
      Milton: ["19968"],
      "Dewey beach": ["19971"],
      Seaford: ["19973"],
      Selbyville: ["19975"],
      Smyrna: ["19977"],
      Viola: ["19979"],
      Woodside: ["19980"],
      "Zcta 199hh": ["199HH"],
    },
  },
  {
    abbrevation: "DC",
    name: "District of columbia",
    Cities: [
      "Washington",
      "Washington",
      "Zcta 200hh",
      "Fort mcnair",
      "Bolling afb",
      "Naval research l",
      "Zcta 203xx",
    ],
    ZipCodes: {
      Washington: [
        "20001",
        "20002",
        "20003",
        "20004",
        "20005",
        "20006",
        "20007",
        "20008",
        "20009",
        "20010",
        "20011",
        "20012",
        "20015",
        "20016",
        "20017",
        "20018",
        "20019",
        "20020",
        "20024",
        "20032",
        "20036",
        "20037",
        "20336",
      ],
      "Zcta 200hh": ["200HH"],
      "Fort mcnair": ["20319"],
      "Bolling afb": ["20332"],
      "Naval research l": ["20375"],
      "Zcta 203xx": ["203XX"],
    },
  },
  {
    abbrevation: "FL",
    name: "Florida",
    Cities: [
      "Branford",
      "Branford",
      "Bryceville",
      "Callahan",
      "Day",
      "Zcta 32024",
      "Zcta 32025",
      "Elkton",
      "Amelia island",
      "Fort white",
      "Glen saint mary",
      "Green cove sprin",
      "Hampton",
      "Hilliard",
      "Jasper",
      "Jennings",
      "Lake butler",
      "Lake city",
      "Lawtey",
      "Lee",
      "Boys ranch",
      "Lulu",
      "Mc alpin",
      "Macclenny",
      "Orange park",
      "Mayo",
      "Middleburg",
      "O brien",
      "Olustee",
      "Penney farms",
      "Ponte vedra beac",
      "Raiford",
      "Saint augustine",
      "Sanderson",
      "Starke",
      "Wellborn",
      "White springs",
      "Yulee",
      "Zcta 320hh",
      "Zcta 320xx",
      "Astor",
      "Bunnell",
      "Crescent city",
      "Citra",
      "Daytona beach",
      "Holly hill",
      "Dunlawton",
      "Port orange",
      "De leon springs",
      "East palatka",
      "Edgewater",
      "Salt springs",
      "Flagler beach",
      "Palm coast",
      "Grandin",
      "Georgetown",
      "Florahome",
      "Hastings",
      "Hollister",
      "Interlachen",
      "Lake como",
      "Lady lake",
      "Zcta 32164",
      "New smyrna beach",
      "Ormond beach",
      "Palatka",
      "Ocklawaha",
      "Pierson",
      "Pomona park",
      "Orange springs",
      "Zcta 32183",
      "Putnam hall",
      "San mateo",
      "Satsuma",
      "Seville",
      "Welaka",
      "Weirsdale",
      "Zcta 321hh",
      "Zcta 321xx",
      "Jacksonville",
      "Jacksonville n a",
      "Cecil field nas",
      "Jacksonville bea",
      "Atlantic beach",
      "Baldwin",
      "Neptune beach",
      "Zcta 32277",
      "Zcta 322hh",
      "Tallahassee",
      "Apalachicola",
      "Bristol",
      "Carrabelle",
      "Lanark village",
      "Chattahoochee",
      "Crawfordville",
      "Saint george isl",
      "Greensboro",
      "Greenville",
      "Gretna",
      "Havana",
      "Hosford",
      "Sumatra",
      "Lamont",
      "Lloyd",
      "Madison",
      "Midway",
      "Monticello",
      "Panacea",
      "Perry",
      "Pinetta",
      "Quincy",
      "Saint marks",
      "Salem",
      "Shady grove",
      "Sopchoppy",
      "Steinhatchee",
      "Telogia",
      "Wacissa",
      "Zcta 323hh",
      "Zcta 323xx",
      "Panama city",
      "Panama city beac",
      "Southport",
      "Mexico beach",
      "Alford",
      "Altha",
      "Argyle",
      "Bascom",
      "Blountstown",
      "Bonifay",
      "Campbellton",
      "Caryville",
      "Chipley",
      "Clarksville",
      "Cottondale",
      "Cypress",
      "De funiak spring",
      "Ebro",
      "Fountain",
      "Freeport",
      "Graceville",
      "Grand ridge",
      "Greenwood",
      "Lynn haven",
      "Malone",
      "Marianna",
      "Zcta 32448",
      "Kinard",
      "Ponce de leon",
      "Port saint joe",
      "Santa rosa beach",
      "Sneads",
      "Vernon",
      "Wausau",
      "Westville",
      "Wewahitchka",
      "Youngstown",
      "Zcta 324hh",
      "Zcta 324xx",
      "Pensacola",
      "Baker",
      "Cantonment",
      "Century",
      "Crestview",
      "Zcta 32539",
      "Sandestin",
      "Eglin a f b",
      "Hurlburt field",
      "Fort walton beac",
      "Gulf breeze",
      "Holt",
      "Jay",
      "Navarre",
      "Laurel hill",
      "Walnut hill",
      "Mary esther",
      "Milton",
      "Pace",
      "Molino",
      "Niceville",
      "Shalimar",
      "Valparaiso",
      "Zcta 325hh",
      "Zcta 325xx",
      "Gainesville",
      "Santa fe",
      "Zcta 32616",
      "Anthony",
      "Archer",
      "Bell",
      "Bronson",
      "Brooker",
      "Cedar key",
      "Chiefland",
      "Cross city",
      "Gulf hammock",
      "Hawthorne",
      "Zcta 32641",
      "High springs",
      "Horseshoe beach",
      "Zcta 32653",
      "Island grove",
      "Keystone heights",
      "La crosse",
      "Lochloosa",
      "Mc intosh",
      "Melrose",
      "Micanopy",
      "Morriston",
      "Newberry",
      "Old town",
      "Orange lake",
      "Otter creek",
      "Reddick",
      "Suwannee",
      "Trenton",
      "Waldo",
      "Williston",
      "Worthington spri",
      "Zcta 326hh",
      "Zcta 326xx",
      "Altamonte spring",
      "Altoona",
      "Hunt club",
      "Cassadaga",
      "Casselberry",
      "Winter springs",
      "Christmas",
      "Apopka",
      "Debary",
      "Forest city",
      "Deland",
      "Deltona",
      "Eustis",
      "Fern park",
      "Geneva",
      "Grand island",
      "Zcta 32736",
      "Lake helen",
      "Heathrow",
      "Longwood",
      "Eatonville",
      "Mims",
      "Mount dora",
      "Oak hill",
      "Orange city",
      "Osteen",
      "Oviedo",
      "Chuluota",
      "Paisley",
      "Sanford",
      "Scottsmoor",
      "Sorrento",
      "Tavares",
      "Springs plaza",
      "Titusville",
      "Dona vista",
      "Winter park",
      "Aloma",
      "Zellwood",
      "Zcta 327hh",
      "Zcta 327xx",
      "Orlando",
      "Fairvilla",
      "Azalea park",
      "Pine hills",
      "Pine castle",
      "Lockhart",
      "Orlo vista",
      "Union park",
      "Sand lake",
      "Ventura",
      "Zcta 328hh",
      "Melbourne",
      "Indialantic",
      "Melbourne villag",
      "Palm bay",
      "Cape canaveral",
      "Cocoa",
      "Patrick a f b",
      "Port saint john",
      "Cocoa beach",
      "Eau gallie",
      "Indian harbor be",
      "Fellsmere",
      "Grant",
      "Malabar",
      "Melbourne beach",
      "Merritt island",
      "Rockledge",
      "Sebastian",
      "Vero beach",
      "Indian river sho",
      "Wabasso",
      "Barefoot bay",
      "Zcta 329hh",
      "Zcta 329xx",
      "Long key",
      "Dania",
      "Hallandale",
      "Hialeah",
      "Zcta 33018",
      "Hollywood",
      "Miramar",
      "Pembroke pines",
      "Homestead",
      "Princeton",
      "Florida city",
      "Islamorada",
      "Ocean reef",
      "Naval air statio",
      "Summerland key",
      "Big pine key",
      "Marathon",
      "Opa locka",
      "Carol city",
      "Pompano beach",
      "Margate",
      "Lighthouse point",
      "Coral springs",
      "North coral spri",
      "Tavernier",
      "Zcta 330hh",
      "Zcta 330xx",
      "Miami beach",
      "Miami",
      "Coral gables",
      "Miami shores",
      "Carl fisher",
      "North bay villag",
      "South miami",
      "Key biscayne",
      "Bal harbour",
      "Kendall",
      "Perrine",
      "North miami beac",
      "North miami",
      "Olympia heights",
      "Miami springs",
      "Quail heights",
      "Ojus",
      "Zcta 331hh",
      "Fort lauderdale",
      "Oakland park",
      "City of sunrise",
      "Davie",
      "Plantation",
      "Tamarac",
      "Sunrise",
      "Zcta 333hh",
      "Zcta 333xx",
      "West palm beach",
      "Lake park",
      "Riviera beach",
      "Glen ridge",
      "North palm beach",
      "Haverhill",
      "Palm beach garde",
      "Royal palm beach",
      "Boynton beach",
      "Boca raton",
      "Belle glade",
      "Briny breezes",
      "Village of golf",
      "Canal point",
      "Clewiston",
      "Deerfield beach",
      "Delray beach",
      "Hobe sound",
      "Jupiter",
      "Lake worth",
      "Lantana",
      "Greenacres",
      "Tequesta",
      "Loxahatchee",
      "Moore haven",
      "Pahokee",
      "Palm beach",
      "Highland beach",
      "South bay",
      "Zcta 334hh",
      "Zcta 334xx",
      "Brandon",
      "Bushnell",
      "Center hill",
      "Coleman",
      "Zcta 33523",
      "Ridge manor",
      "Dover",
      "Gibsonton",
      "Lake panasoffkee",
      "Zephyrhills",
      "Wesley chapel",
      "Lithia",
      "Lutz",
      "Odessa",
      "Plant city",
      "Riverview",
      "Ruskin",
      "Apollo beach",
      "Sun city center",
      "San antonio",
      "Seffner",
      "Sumterville",
      "Thonotosassa",
      "Valrico",
      "Ridge manor esta",
      "Wimauma",
      "Zcta 335hh",
      "Zcta 335xx",
      "Tampa",
      "Carrollwood",
      "Zcta 336hh",
      "Saint petersburg",
      "Madeira beach",
      "Kenneth city",
      "Tierra verde",
      "Zcta 33785",
      "Zcta 33786",
      "Zcta 337hh",
      "Lakeland",
      "Zcta 33810",
      "Southside",
      "Zcta 33815",
      "Auburndale",
      "Avon park",
      "Babson park",
      "Bartow",
      "Duette",
      "Bradley",
      "Davenport",
      "Dundee",
      "Eagle lake",
      "Fort meade",
      "Frostproof",
      "Grenelefe",
      "Homeland",
      "Intercession cit",
      "Kathleen",
      "Lake alfred",
      "Lake hamilton",
      "Lake placid",
      "Lake wales",
      "Lorida",
      "Mulberry",
      "Ona",
      "Polk city",
      "Sebring",
      "Wauchula",
      "Waverly",
      "Eloise",
      "Winter haven",
      "Cypress gardens",
      "Zolfo springs",
      "Zcta 338hh",
      "Zcta 338xx",
      "Fort myers",
      "Cape coral centr",
      "Tice",
      "College parkway",
      "Alva",
      "Boca grande",
      "Bokeelia",
      "Captiva",
      "Estero",
      "Felda",
      "Fort myers beach",
      "Labelle",
      "Lehigh acres",
      "Palmdale",
      "Pineland",
      "Placida",
      "Port charlotte",
      "Punta gorda",
      "Saint james city",
      "Sanibel",
      "Venus",
      "Zcta 33972",
      "Zcta 33975",
      "Zcta 33993",
      "Zcta 339hh",
      "Zcta 339xx",
      "Zcta 34102",
      "Zcta 34103",
      "Zcta 34104",
      "Zcta 34105",
      "Zcta 34108",
      "Zcta 34109",
      "Zcta 34110",
      "Zcta 34112",
      "Zcta 34113",
      "Zcta 34114",
      "Zcta 34116",
      "Zcta 34117",
      "Zcta 34119",
      "Zcta 34120",
      "Zcta 34134",
      "Zcta 34135",
      "Zcta 34137",
      "Zcta 34138",
      "Zcta 34139",
      "Zcta 34141",
      "Zcta 34142",
      "Zcta 34145",
      "Zcta 341hh",
      "Zcta 341xx",
      "Braden river",
      "Westgate",
      "College plaza",
      "Palma sola",
      "Bradenton",
      "Cortez",
      "Anna maria",
      "Bradenton beach",
      "Parrish",
      "Palmetto",
      "Ellenton",
      "Englewood",
      "Grove city",
      "Whitney beach",
      "Osprey",
      "South trail",
      "Forest lakes",
      "Sarasota",
      "Meadows village",
      "Sarasota square",
      "Crescent beach",
      "Terra ceia",
      "Myakka city",
      "Zcta 34266",
      "Zcta 34268",
      "Nokomis",
      "Venice",
      "Zcta 34286",
      "North port",
      "Mid venice",
      "South venice",
      "Zcta 342hh",
      "Zcta 342xx",
      "Belleview",
      "Crystal river",
      "Dunnellon",
      "Citrus springs",
      "Floral city",
      "Hernando",
      "Homosassa",
      "Inglis",
      "Inverness",
      "Lecanto",
      "Beverly hills",
      "Ocala",
      "Oxford",
      "Silver springs",
      "Summerfield",
      "Yankeetown",
      "Zcta 344hh",
      "Zcta 344xx",
      "Brooksville",
      "Ridge manor west",
      "Spring hill",
      "Shady hills",
      "Land o lakes",
      "New port richey",
      "Nobleton",
      "Hudson",
      "Port richey",
      "Oldsmar",
      "Aripeka",
      "Crystal beach",
      "Palm harbor",
      "Lake tarpon",
      "Tarpon springs",
      "Holiday",
      "Safety harbor",
      "Dunedin",
      "Zcta 346hh",
      "Zcta 346xx",
      "Astatula",
      "Clermont",
      "Fruitland park",
      "Gotha",
      "Groveland",
      "Howey in the hil",
      "Kenansville",
      "Kissimmee",
      "Buena ventura la",
      "Leesburg",
      "Mascotte",
      "Montverde",
      "Poinciana",
      "Oakland",
      "Ocoee",
      "Okahumpka",
      "Saint cloud",
      "Wildwood",
      "Windermere",
      "Winter garden",
      "Haines creek",
      "Yalaha",
      "Zcta 347hh",
      "Zcta 347xx",
      "Fort pierce",
      "Port saint lucie",
      "Indiantown",
      "Jensen beach",
      "Basinger",
      "Okeechobee",
      "Palm city",
      "Stuart",
      "Zcta 349hh",
      "Zcta 349xx",
    ],
    ZipCodes: {
      Branford: ["32008"],
      Bryceville: ["32009"],
      Callahan: ["32011"],
      Day: ["32013"],
      "Zcta 32024": ["32024"],
      "Zcta 32025": ["32025"],
      Elkton: ["32033"],
      "Amelia island": ["32034"],
      "Fort white": ["32038"],
      "Glen saint mary": ["32040"],
      "Green cove sprin": ["32043"],
      Hampton: ["32044"],
      Hilliard: ["32046"],
      Jasper: ["32052"],
      Jennings: ["32053"],
      "Lake butler": ["32054"],
      "Lake city": ["32055"],
      Lawtey: ["32058"],
      Lee: ["32059"],
      "Boys ranch": ["32060"],
      Lulu: ["32061"],
      "Mc alpin": ["32062"],
      Macclenny: ["32063"],
      "Orange park": ["32065", "32073"],
      Mayo: ["32066"],
      Middleburg: ["32068"],
      "O brien": ["32071"],
      Olustee: ["32072"],
      "Penney farms": ["32079"],
      "Ponte vedra beac": ["32082"],
      Raiford: ["32083"],
      "Saint augustine": ["32084", "32086", "32092", "32095"],
      Sanderson: ["32087"],
      Starke: ["32091"],
      Wellborn: ["32094"],
      "White springs": ["32096"],
      Yulee: ["32097"],
      "Zcta 320hh": ["320HH"],
      "Zcta 320xx": ["320XX"],
      Astor: ["32102"],
      Bunnell: ["32110"],
      "Crescent city": ["32112"],
      Citra: ["32113"],
      "Daytona beach": ["32114", "32118"],
      "Holly hill": ["32117"],
      Dunlawton: ["32119"],
      "Port orange": ["32124", "32127"],
      "De leon springs": ["32130"],
      "East palatka": ["32131"],
      Edgewater: ["32132", "32141"],
      "Salt springs": ["32134"],
      "Flagler beach": ["32136"],
      "Palm coast": ["32137"],
      Grandin: ["32138"],
      Georgetown: ["32139"],
      Florahome: ["32140"],
      Hastings: ["32145"],
      Hollister: ["32147"],
      Interlachen: ["32148"],
      "Lake como": ["32157"],
      "Lady lake": ["32159"],
      "Zcta 32164": ["32164"],
      "New smyrna beach": ["32168", "32169"],
      "Ormond beach": ["32174", "32176"],
      Palatka: ["32177"],
      Ocklawaha: ["32179"],
      Pierson: ["32180"],
      "Pomona park": ["32181"],
      "Orange springs": ["32182"],
      "Zcta 32183": ["32183"],
      "Putnam hall": ["32185"],
      "San mateo": ["32187"],
      Satsuma: ["32189"],
      Seville: ["32190"],
      Welaka: ["32193"],
      Weirsdale: ["32195"],
      "Zcta 321hh": ["321HH"],
      "Zcta 321xx": ["321XX"],
      Jacksonville: [
        "32202",
        "32204",
        "32205",
        "32206",
        "32207",
        "32208",
        "32209",
        "32210",
        "32211",
        "32216",
        "32217",
        "32218",
        "32219",
        "32220",
        "32221",
        "32222",
        "32223",
        "32224",
        "32225",
        "32226",
        "32244",
        "32246",
        "32254",
        "32256",
        "32257",
        "32258",
        "32259",
      ],
      "Jacksonville n a": ["32212"],
      "Cecil field nas": ["32215"],
      "Jacksonville bea": ["32227", "32250"],
      "Atlantic beach": ["32233"],
      Baldwin: ["32234"],
      "Neptune beach": ["32266"],
      "Zcta 32277": ["32277"],
      "Zcta 322hh": ["322HH"],
      Tallahassee: [
        "32301",
        "32303",
        "32304",
        "32308",
        "32310",
        "32311",
        "32312",
        "32399",
      ],
      Apalachicola: ["32320"],
      Bristol: ["32321"],
      Carrabelle: ["32322"],
      "Lanark village": ["32323"],
      Chattahoochee: ["32324"],
      Crawfordville: ["32327"],
      "Saint george isl": ["32328"],
      Greensboro: ["32330"],
      Greenville: ["32331"],
      Gretna: ["32332"],
      Havana: ["32333"],
      Hosford: ["32334"],
      Sumatra: ["32335"],
      Lamont: ["32336"],
      Lloyd: ["32337"],
      Madison: ["32340"],
      Midway: ["32343"],
      Monticello: ["32344"],
      Panacea: ["32346"],
      Perry: ["32347"],
      Pinetta: ["32350"],
      Quincy: ["32351"],
      "Saint marks": ["32355"],
      Salem: ["32356"],
      "Shady grove": ["32357"],
      Sopchoppy: ["32358"],
      Steinhatchee: ["32359"],
      Telogia: ["32360"],
      Wacissa: ["32361"],
      "Zcta 323hh": ["323HH"],
      "Zcta 323xx": ["323XX"],
      "Panama city": ["32401", "32403", "32404", "32405"],
      "Panama city beac": ["32407", "32408", "32413"],
      Southport: ["32409"],
      "Mexico beach": ["32410"],
      Alford: ["32420"],
      Altha: ["32421"],
      Argyle: ["32422"],
      Bascom: ["32423"],
      Blountstown: ["32424"],
      Bonifay: ["32425"],
      Campbellton: ["32426"],
      Caryville: ["32427"],
      Chipley: ["32428"],
      Clarksville: ["32430"],
      Cottondale: ["32431"],
      Cypress: ["32432"],
      "De funiak spring": ["32433"],
      Ebro: ["32437"],
      Fountain: ["32438"],
      Freeport: ["32439"],
      Graceville: ["32440"],
      "Grand ridge": ["32442"],
      Greenwood: ["32443"],
      "Lynn haven": ["32444"],
      Malone: ["32445"],
      Marianna: ["32446", "32447"],
      "Zcta 32448": ["32448"],
      Kinard: ["32449"],
      "Ponce de leon": ["32455"],
      "Port saint joe": ["32456"],
      "Santa rosa beach": ["32459"],
      Sneads: ["32460"],
      Vernon: ["32462"],
      Wausau: ["32463"],
      Westville: ["32464"],
      Wewahitchka: ["32465"],
      Youngstown: ["32466"],
      "Zcta 324hh": ["324HH"],
      "Zcta 324xx": ["324XX"],
      Pensacola: [
        "32501",
        "32503",
        "32504",
        "32505",
        "32506",
        "32507",
        "32508",
        "32514",
        "32526",
        "32534",
      ],
      Baker: ["32531"],
      Cantonment: ["32533"],
      Century: ["32535"],
      Crestview: ["32536"],
      "Zcta 32539": ["32539"],
      Sandestin: ["32541"],
      "Eglin a f b": ["32542"],
      "Hurlburt field": ["32544"],
      "Fort walton beac": ["32547", "32548"],
      "Gulf breeze": ["32561"],
      Holt: ["32564"],
      Jay: ["32565"],
      Navarre: ["32566"],
      "Laurel hill": ["32567"],
      "Walnut hill": ["32568"],
      "Mary esther": ["32569"],
      Milton: ["32570", "32583"],
      Pace: ["32571"],
      Molino: ["32577"],
      Niceville: ["32578"],
      Shalimar: ["32579"],
      Valparaiso: ["32580"],
      "Zcta 325hh": ["325HH"],
      "Zcta 325xx": ["325XX"],
      Gainesville: [
        "32601",
        "32603",
        "32605",
        "32606",
        "32607",
        "32608",
        "32609",
      ],
      "Santa fe": ["32615"],
      "Zcta 32616": ["32616"],
      Anthony: ["32617"],
      Archer: ["32618"],
      Bell: ["32619"],
      Bronson: ["32621"],
      Brooker: ["32622"],
      "Cedar key": ["32625"],
      Chiefland: ["32626"],
      "Cross city": ["32628"],
      "Gulf hammock": ["32639"],
      Hawthorne: ["32640"],
      "Zcta 32641": ["32641"],
      "High springs": ["32643"],
      "Horseshoe beach": ["32648"],
      "Zcta 32653": ["32653"],
      "Island grove": ["32654"],
      "Keystone heights": ["32656"],
      "La crosse": ["32658"],
      Lochloosa: ["32662"],
      "Mc intosh": ["32664"],
      Melrose: ["32666"],
      Micanopy: ["32667"],
      Morriston: ["32668"],
      Newberry: ["32669"],
      "Old town": ["32680"],
      "Orange lake": ["32681"],
      "Otter creek": ["32683"],
      Reddick: ["32686"],
      Suwannee: ["32692"],
      Trenton: ["32693"],
      Waldo: ["32694"],
      Williston: ["32696"],
      "Worthington spri": ["32697"],
      "Zcta 326hh": ["326HH"],
      "Zcta 326xx": ["326XX"],
      "Altamonte spring": ["32701"],
      Altoona: ["32702"],
      "Hunt club": ["32703"],
      Cassadaga: ["32706"],
      Casselberry: ["32707"],
      "Winter springs": ["32708"],
      Christmas: ["32709"],
      Apopka: ["32712"],
      Debary: ["32713"],
      "Forest city": ["32714"],
      Deland: ["32720", "32724"],
      Deltona: ["32725", "32738"],
      Eustis: ["32726"],
      "Fern park": ["32730"],
      Geneva: ["32732"],
      "Grand island": ["32735"],
      "Zcta 32736": ["32736"],
      "Lake helen": ["32744"],
      Heathrow: ["32746"],
      Longwood: ["32750"],
      Eatonville: ["32751"],
      Mims: ["32754"],
      "Mount dora": ["32757"],
      "Oak hill": ["32759"],
      "Orange city": ["32763"],
      Osteen: ["32764"],
      Oviedo: ["32765"],
      Chuluota: ["32766"],
      Paisley: ["32767"],
      Sanford: ["32771", "32773"],
      Scottsmoor: ["32775"],
      Sorrento: ["32776"],
      Tavares: ["32778"],
      "Springs plaza": ["32779"],
      Titusville: ["32780", "32796"],
      "Dona vista": ["32784"],
      "Winter park": ["32789"],
      Aloma: ["32792"],
      Zellwood: ["32798"],
      "Zcta 327hh": ["327HH"],
      "Zcta 327xx": ["327XX"],
      Orlando: [
        "32801",
        "32803",
        "32805",
        "32806",
        "32812",
        "32818",
        "32821",
        "32824",
        "32825",
        "32826",
        "32827",
        "32828",
        "32829",
        "32831",
        "32832",
        "32835",
        "32836",
        "32837",
        "32839",
      ],
      Fairvilla: ["32804"],
      "Azalea park": ["32807"],
      "Pine hills": ["32808"],
      "Pine castle": ["32809"],
      Lockhart: ["32810"],
      "Orlo vista": ["32811"],
      "Union park": ["32817", "32820", "32833"],
      "Sand lake": ["32819"],
      Ventura: ["32822"],
      "Zcta 328hh": ["328HH"],
      Melbourne: ["32901", "32935", "32940"],
      Indialantic: ["32903"],
      "Melbourne villag": ["32904"],
      "Palm bay": ["32905", "32907", "32908", "32909"],
      "Cape canaveral": ["32920"],
      Cocoa: ["32922", "32926"],
      "Patrick a f b": ["32925"],
      "Port saint john": ["32927"],
      "Cocoa beach": ["32931"],
      "Eau gallie": ["32934"],
      "Indian harbor be": ["32937"],
      Fellsmere: ["32948"],
      Grant: ["32949"],
      Malabar: ["32950"],
      "Melbourne beach": ["32951"],
      "Merritt island": ["32952", "32953"],
      Rockledge: ["32955"],
      Sebastian: ["32958"],
      "Vero beach": ["32960", "32962", "32966", "32967", "32968"],
      "Indian river sho": ["32963"],
      Wabasso: ["32970"],
      "Barefoot bay": ["32976"],
      "Zcta 329hh": ["329HH"],
      "Zcta 329xx": ["329XX"],
      "Long key": ["33001"],
      Dania: ["33004"],
      Hallandale: ["33009"],
      Hialeah: ["33010", "33012", "33013", "33014", "33015", "33016"],
      "Zcta 33018": ["33018"],
      Hollywood: [
        "33019",
        "33020",
        "33021",
        "33025",
        "33026",
        "33027",
        "33028",
      ],
      Miramar: ["33023"],
      "Pembroke pines": ["33024", "33029"],
      Homestead: ["33030", "33031", "33033", "33035"],
      Princeton: ["33032"],
      "Florida city": ["33034"],
      Islamorada: ["33036"],
      "Ocean reef": ["33037"],
      "Naval air statio": ["33040"],
      "Summerland key": ["33042"],
      "Big pine key": ["33043"],
      Marathon: ["33050"],
      "Opa locka": ["33054"],
      "Carol city": ["33055", "33056"],
      "Pompano beach": [
        "33060",
        "33062",
        "33068",
        "33069",
        "33071",
        "33073",
        "33076",
      ],
      Margate: ["33063", "33066"],
      "Lighthouse point": ["33064"],
      "Coral springs": ["33065"],
      "North coral spri": ["33067"],
      Tavernier: ["33070"],
      "Zcta 330hh": ["330HH"],
      "Zcta 330xx": ["330XX"],
      "Miami beach": ["33109"],
      Miami: [
        "33122",
        "33125",
        "33126",
        "33127",
        "33128",
        "33129",
        "33130",
        "33131",
        "33132",
        "33135",
        "33136",
        "33137",
        "33140",
        "33142",
        "33144",
        "33147",
        "33150",
        "33155",
        "33158",
        "33167",
        "33168",
        "33169",
        "33172",
        "33173",
        "33174",
        "33176",
        "33178",
        "33179",
        "33182",
        "33183",
        "33184",
        "33186",
        "33193",
        "33196",
      ],
      "Coral gables": ["33133", "33134", "33145", "33146"],
      "Miami shores": ["33138"],
      "Carl fisher": ["33139"],
      "North bay villag": ["33141"],
      "South miami": ["33143"],
      "Key biscayne": ["33149"],
      "Bal harbour": ["33154"],
      Kendall: ["33156"],
      Perrine: ["33157"],
      "North miami beac": ["33160", "33162", "33181"],
      "North miami": ["33161"],
      "Olympia heights": ["33165", "33175", "33185", "33194"],
      "Miami springs": ["33166"],
      "Quail heights": ["33170", "33177", "33187", "33189", "33190"],
      Ojus: ["33180"],
      "Zcta 331hh": ["331HH"],
      "Fort lauderdale": [
        "33301",
        "33309",
        "33311",
        "33312",
        "33315",
        "33316",
        "33327",
        "33388",
        "33394",
      ],
      "Oakland park": ["33304", "33305", "33306", "33308", "33334"],
      "City of sunrise": ["33313"],
      Davie: ["33314", "33325", "33326", "33328", "33330", "33331", "33332"],
      Plantation: ["33317", "33324"],
      Tamarac: ["33319", "33321", "33351"],
      Sunrise: ["33322", "33323"],
      "Zcta 333hh": ["333HH"],
      "Zcta 333xx": ["333XX"],
      "West palm beach": ["33401", "33405", "33407", "33412", "33413", "33414"],
      "Lake park": ["33403"],
      "Riviera beach": ["33404"],
      "Glen ridge": ["33406"],
      "North palm beach": ["33408"],
      Haverhill: ["33409", "33415", "33417"],
      "Palm beach garde": ["33410", "33418"],
      "Royal palm beach": ["33411"],
      "Boynton beach": ["33426", "33437"],
      "Boca raton": [
        "33428",
        "33431",
        "33432",
        "33433",
        "33434",
        "33486",
        "33496",
        "33498",
      ],
      "Belle glade": ["33430"],
      "Briny breezes": ["33435"],
      "Village of golf": ["33436"],
      "Canal point": ["33438"],
      Clewiston: ["33440"],
      "Deerfield beach": ["33441", "33442"],
      "Delray beach": ["33444", "33445", "33446", "33483", "33484"],
      "Hobe sound": ["33455"],
      Jupiter: ["33458", "33477", "33478"],
      "Lake worth": ["33460", "33461", "33467"],
      Lantana: ["33462"],
      Greenacres: ["33463"],
      Tequesta: ["33469"],
      Loxahatchee: ["33470"],
      "Moore haven": ["33471"],
      Pahokee: ["33476"],
      "Palm beach": ["33480"],
      "Highland beach": ["33487"],
      "South bay": ["33493"],
      "Zcta 334hh": ["334HH"],
      "Zcta 334xx": ["334XX"],
      Brandon: ["33510", "33511"],
      Bushnell: ["33513"],
      "Center hill": ["33514"],
      Coleman: ["33521"],
      "Zcta 33523": ["33523"],
      "Ridge manor": ["33525"],
      Dover: ["33527"],
      Gibsonton: ["33534"],
      "Lake panasoffkee": ["33538"],
      Zephyrhills: ["33540", "33541", "33544"],
      "Wesley chapel": ["33543"],
      Lithia: ["33547"],
      Lutz: ["33549"],
      Odessa: ["33556"],
      "Plant city": ["33565", "33566", "33567"],
      Riverview: ["33569"],
      Ruskin: ["33570"],
      "Apollo beach": ["33572"],
      "Sun city center": ["33573"],
      "San antonio": ["33576"],
      Seffner: ["33584"],
      Sumterville: ["33585"],
      Thonotosassa: ["33592"],
      Valrico: ["33594"],
      "Ridge manor esta": ["33597"],
      Wimauma: ["33598"],
      "Zcta 335hh": ["335HH"],
      "Zcta 335xx": ["335XX"],
      Tampa: [
        "33602",
        "33603",
        "33604",
        "33605",
        "33606",
        "33607",
        "33609",
        "33610",
        "33611",
        "33612",
        "33613",
        "33614",
        "33615",
        "33616",
        "33617",
        "33619",
        "33620",
        "33621",
        "33625",
        "33626",
        "33629",
        "33634",
        "33635",
        "33637",
        "33647",
      ],
      Carrollwood: ["33618", "33624"],
      "Zcta 336hh": ["336HH"],
      "Saint petersburg": [
        "33701",
        "33702",
        "33703",
        "33704",
        "33705",
        "33706",
        "33707",
        "33710",
        "33711",
        "33712",
        "33713",
        "33714",
        "33716",
        "33755",
        "33756",
        "33759",
        "33760",
        "33761",
        "33762",
        "33763",
        "33764",
        "33765",
        "33767",
        "33770",
        "33771",
        "33772",
        "33773",
        "33774",
        "33776",
        "33777",
        "33778",
        "33781",
        "33782",
      ],
      "Madeira beach": ["33708"],
      "Kenneth city": ["33709"],
      "Tierra verde": ["33715"],
      "Zcta 33785": ["33785"],
      "Zcta 33786": ["33786"],
      "Zcta 337hh": ["337HH"],
      Lakeland: ["33801", "33803", "33805", "33809"],
      "Zcta 33810": ["33810"],
      Southside: ["33811", "33813"],
      "Zcta 33815": ["33815"],
      Auburndale: ["33823"],
      "Avon park": ["33825"],
      "Babson park": ["33827"],
      Bartow: ["33830"],
      Duette: ["33834"],
      Bradley: ["33835"],
      Davenport: ["33837"],
      Dundee: ["33838"],
      "Eagle lake": ["33839"],
      "Fort meade": ["33841"],
      Frostproof: ["33843"],
      Grenelefe: ["33844"],
      Homeland: ["33847"],
      "Intercession cit": ["33848"],
      Kathleen: ["33849"],
      "Lake alfred": ["33850"],
      "Lake hamilton": ["33851"],
      "Lake placid": ["33852"],
      "Lake wales": ["33853"],
      Lorida: ["33857"],
      Mulberry: ["33860"],
      Ona: ["33865"],
      "Polk city": ["33868"],
      Sebring: ["33870", "33872"],
      Wauchula: ["33873"],
      Waverly: ["33877"],
      Eloise: ["33880"],
      "Winter haven": ["33881"],
      "Cypress gardens": ["33884"],
      "Zolfo springs": ["33890"],
      "Zcta 338hh": ["338HH"],
      "Zcta 338xx": ["338XX"],
      "Fort myers": [
        "33901",
        "33903",
        "33907",
        "33908",
        "33912",
        "33913",
        "33916",
        "33917",
      ],
      "Cape coral centr": ["33904", "33909", "33914", "33990", "33991"],
      Tice: ["33905"],
      "College parkway": ["33919"],
      Alva: ["33920"],
      "Boca grande": ["33921"],
      Bokeelia: ["33922"],
      Captiva: ["33924"],
      Estero: ["33928"],
      Felda: ["33930"],
      "Fort myers beach": ["33931"],
      Labelle: ["33935"],
      "Lehigh acres": ["33936", "33971"],
      Palmdale: ["33944"],
      Pineland: ["33945"],
      Placida: ["33946", "33947"],
      "Port charlotte": ["33948", "33952", "33953", "33954", "33980", "33981"],
      "Punta gorda": ["33950", "33955", "33982", "33983"],
      "Saint james city": ["33956"],
      Sanibel: ["33957"],
      Venus: ["33960"],
      "Zcta 33972": ["33972"],
      "Zcta 33975": ["33975"],
      "Zcta 33993": ["33993"],
      "Zcta 339hh": ["339HH"],
      "Zcta 339xx": ["339XX"],
      "Zcta 34102": ["34102"],
      "Zcta 34103": ["34103"],
      "Zcta 34104": ["34104"],
      "Zcta 34105": ["34105"],
      "Zcta 34108": ["34108"],
      "Zcta 34109": ["34109"],
      "Zcta 34110": ["34110"],
      "Zcta 34112": ["34112"],
      "Zcta 34113": ["34113"],
      "Zcta 34114": ["34114"],
      "Zcta 34116": ["34116"],
      "Zcta 34117": ["34117"],
      "Zcta 34119": ["34119"],
      "Zcta 34120": ["34120"],
      "Zcta 34134": ["34134"],
      "Zcta 34135": ["34135"],
      "Zcta 34137": ["34137"],
      "Zcta 34138": ["34138"],
      "Zcta 34139": ["34139"],
      "Zcta 34141": ["34141"],
      "Zcta 34142": ["34142"],
      "Zcta 34145": ["34145"],
      "Zcta 341hh": ["341HH"],
      "Zcta 341xx": ["341XX"],
      "Braden river": ["34201", "34202", "34203", "34208"],
      Westgate: ["34205"],
      "College plaza": ["34207"],
      "Palma sola": ["34209"],
      Bradenton: ["34210"],
      Cortez: ["34215"],
      "Anna maria": ["34216"],
      "Bradenton beach": ["34217"],
      Parrish: ["34219"],
      Palmetto: ["34221"],
      Ellenton: ["34222"],
      Englewood: ["34223"],
      "Grove city": ["34224"],
      "Whitney beach": ["34228"],
      Osprey: ["34229"],
      "South trail": ["34231"],
      "Forest lakes": ["34232"],
      Sarasota: [
        "34233",
        "34235",
        "34236",
        "34237",
        "34239",
        "34240",
        "34241",
        "34243",
      ],
      "Meadows village": ["34234"],
      "Sarasota square": ["34238"],
      "Crescent beach": ["34242"],
      "Terra ceia": ["34250"],
      "Myakka city": ["34251"],
      "Zcta 34266": ["34266"],
      "Zcta 34268": ["34268"],
      Nokomis: ["34275"],
      Venice: ["34285"],
      "Zcta 34286": ["34286"],
      "North port": ["34287"],
      "Mid venice": ["34292"],
      "South venice": ["34293"],
      "Zcta 342hh": ["342HH"],
      "Zcta 342xx": ["342XX"],
      Belleview: ["34420"],
      "Crystal river": ["34428", "34429"],
      Dunnellon: ["34431", "34432"],
      "Citrus springs": ["34433", "34434"],
      "Floral city": ["34436"],
      Hernando: ["34442"],
      Homosassa: ["34446", "34448"],
      Inglis: ["34449"],
      Inverness: ["34450", "34452", "34453"],
      Lecanto: ["34461"],
      "Beverly hills": ["34465"],
      Ocala: [
        "34470",
        "34471",
        "34472",
        "34473",
        "34474",
        "34475",
        "34476",
        "34479",
        "34480",
        "34481",
        "34482",
      ],
      Oxford: ["34484"],
      "Silver springs": ["34488"],
      Summerfield: ["34491"],
      Yankeetown: ["34498"],
      "Zcta 344hh": ["344HH"],
      "Zcta 344xx": ["344XX"],
      Brooksville: ["34601", "34613", "34614"],
      "Ridge manor west": ["34602"],
      "Spring hill": ["34606", "34607", "34608", "34609"],
      "Shady hills": ["34610"],
      "Land o lakes": ["34639"],
      "New port richey": ["34652", "34653", "34654", "34655"],
      Nobleton: ["34661"],
      Hudson: ["34667", "34669"],
      "Port richey": ["34668"],
      Oldsmar: ["34677"],
      Aripeka: ["34679"],
      "Crystal beach": ["34681"],
      "Palm harbor": ["34683", "34685"],
      "Lake tarpon": ["34684"],
      "Tarpon springs": ["34689"],
      Holiday: ["34690", "34691"],
      "Safety harbor": ["34695"],
      Dunedin: ["34698"],
      "Zcta 346hh": ["346HH"],
      "Zcta 346xx": ["346XX"],
      Astatula: ["34705"],
      Clermont: ["34711"],
      "Fruitland park": ["34731"],
      Gotha: ["34734"],
      Groveland: ["34736"],
      "Howey in the hil": ["34737"],
      Kenansville: ["34739"],
      Kissimmee: ["34741", "34744", "34746", "34747", "34758"],
      "Buena ventura la": ["34743"],
      Leesburg: ["34748"],
      Mascotte: ["34753"],
      Montverde: ["34756"],
      Poinciana: ["34759"],
      Oakland: ["34760"],
      Ocoee: ["34761"],
      Okahumpka: ["34762"],
      "Saint cloud": ["34769", "34771", "34772", "34773"],
      Wildwood: ["34785"],
      Windermere: ["34786"],
      "Winter garden": ["34787"],
      "Haines creek": ["34788"],
      Yalaha: ["34797"],
      "Zcta 347hh": ["347HH"],
      "Zcta 347xx": ["347XX"],
      "Fort pierce": [
        "34945",
        "34946",
        "34947",
        "34949",
        "34950",
        "34951",
        "34981",
        "34982",
      ],
      "Port saint lucie": [
        "34952",
        "34953",
        "34983",
        "34984",
        "34986",
        "34987",
      ],
      Indiantown: ["34956"],
      "Jensen beach": ["34957"],
      Basinger: ["34972"],
      Okeechobee: ["34974"],
      "Palm city": ["34990"],
      Stuart: ["34994", "34996", "34997"],
      "Zcta 349hh": ["349HH"],
      "Zcta 349xx": ["349XX"],
    },
  },
  {
    abbrevation: "GA",
    name: "Georgia",
    Cities: [
      "Avondale estates",
      "Avondale estates",
      "Zcta 30004",
      "Zcta 30005",
      "Zcta 30008",
      "Zcta 30011",
      "Zcta 30012",
      "Zcta 30013",
      "Zcta 30014",
      "Zcta 30016",
      "Zcta 30017",
      "Zcta 30019",
      "Clarkston",
      "Zcta 30022",
      "Zcta 30024",
      "Zcta 30025",
      "Decatur",
      "Lithonia",
      "Zcta 30039",
      "Zcta 30040",
      "Zcta 30041",
      "Zcta 30043",
      "Zcta 30044",
      "Zcta 30045",
      "Zcta 30047",
      "Zcta 30052",
      "Zcta 30054",
      "Zcta 30055",
      "Zcta 30056",
      "Centerville gwin",
      "Marietta",
      "Zcta 30070",
      "Norcross",
      "Pine lake",
      "Roswell",
      "Zcta 30078",
      "Scottdale",
      "Smyrna",
      "Stone mountain",
      "Tucker",
      "Zcta 30094",
      "Zcta 30096",
      "Zcta 30097",
      "Zcta 300hh",
      "Acworth",
      "Adairsville",
      "Aragon",
      "Armuchee",
      "Zcta 30106",
      "Ball ground",
      "Bowdon",
      "Bremen",
      "Buchanan",
      "Canton",
      "Carrollton",
      "Cartersville",
      "Zcta 30121",
      "Zcta 30122",
      "Cave spring",
      "Cedartown",
      "Zcta 30126",
      "Zcta 30127",
      "Dallas",
      "Douglasville",
      "Emerson",
      "Fairmount",
      "Hiram",
      "Jasper",
      "Kennesaw",
      "Kingston",
      "Lindale",
      "Marble hill",
      "Zcta 30152",
      "Rockmart",
      "Zcta 30157",
      "Rome",
      "Zcta 30168",
      "Roopville",
      "Pine log",
      "Silver creek",
      "Talking rock",
      "Tallapoosa",
      "Tate",
      "Taylorsville",
      "Temple",
      "Villa rica",
      "Waco",
      "Waleska",
      "White",
      "Whitesburg",
      "Winston",
      "Woodstock",
      "Zcta 30189",
      "Zcta 301hh",
      "Barnesville",
      "Brooks",
      "Concord",
      "Fairburn",
      "Woolsey",
      "Zcta 30215",
      "Flovilla",
      "Glenn",
      "Alvaton",
      "Grantville",
      "Stovall",
      "Griffin",
      "Hampton",
      "Hogansville",
      "Jackson",
      "Jenkinsburg",
      "Jonesboro",
      "Zcta 30238",
      "La grange",
      "Locust grove",
      "Lovejoy",
      "Luthersville",
      "Zcta 30252",
      "Mc donough",
      "Meansville",
      "Milner",
      "Molena",
      "Moreland",
      "Morrow",
      "Raymond",
      "Newnan",
      "Palmetto",
      "Peachtree city",
      "Rex",
      "Riverdale",
      "Sargent",
      "Senoia",
      "Sharpsburg",
      "Stockbridge",
      "The rock",
      "Thomaston",
      "Zcta 30288",
      "Tyrone",
      "Union city",
      "Williamson",
      "Woodbury",
      "Zcta 30294",
      "Zebulon",
      "Zcta 30297",
      "Zcta 302hh",
      "Atlanta",
      "Sandy springs",
      "College park",
      "Dunwoody",
      "Doraville",
      "Chamblee",
      "East point",
      "Hapeville",
      "Zcta 303hh",
      "Oak park",
      "Ailey",
      "Alamo",
      "Alston",
      "Bartow",
      "Bellville",
      "Brooklet",
      "Claxton",
      "Cobbtown",
      "Collins",
      "Daisy",
      "Garfield",
      "Girard",
      "Glennville",
      "Glenwood",
      "Hagan",
      "Louisville",
      "Lyons",
      "Manassas",
      "Metter",
      "Midville",
      "Millen",
      "Mount vernon",
      "Newington",
      "Norristown",
      "Nunez",
      "Portal",
      "Register",
      "Reidsville",
      "Rockledge",
      "Rocky ford",
      "Sardis",
      "Soperton",
      "Statesboro",
      "Zcta 30461",
      "Stillmore",
      "Hiltonia",
      "Tarrytown",
      "Twin city",
      "Uvalda",
      "Vidalia",
      "Wadley",
      "Zcta 304hh",
      "Zcta 304xx",
      "Gainesville",
      "Alto",
      "Baldwin",
      "Blairsville",
      "Blue ridge",
      "Bowersville",
      "Braselton",
      "Buford",
      "Zcta 30519",
      "Canon",
      "Carnesville",
      "Cherrylog",
      "Clarkesville",
      "Clayton",
      "Clermont",
      "Cleveland",
      "Commerce",
      "Zcta 30530",
      "Cornelia",
      "Dahlonega",
      "Juno",
      "Demorest",
      "Sky valley",
      "Eastanollee",
      "East ellijay",
      "Ellijay",
      "Epworth",
      "Flowery branch",
      "Gillsville",
      "Helen",
      "Hiawassee",
      "Homer",
      "Hoschton",
      "Jefferson",
      "Lakemont",
      "Lavonia",
      "Lula",
      "Mc caysville",
      "Martin",
      "Maysville",
      "Mineral bluff",
      "Morganton",
      "Mountain city",
      "Mount airy",
      "Murrayville",
      "Nicholson",
      "Oakwood",
      "Pendergrass",
      "Rabun gap",
      "Sautee nacoochee",
      "Suches",
      "Talmo",
      "Tiger",
      "Toccoa",
      "Wiley",
      "Young harris",
      "Toccoa falls",
      "Zcta 305hh",
      "Athens",
      "Arnoldsville",
      "Bethlehem",
      "Bishop",
      "Bogart",
      "Bowman",
      "Buckhead",
      "Carlton",
      "Colbert",
      "Comer",
      "Crawford",
      "Crawfordville",
      "Danielsville",
      "Dewy rose",
      "Elberton",
      "Farmington",
      "Franklin springs",
      "Good hope",
      "Greensboro",
      "Hartwell",
      "Hull",
      "Lexington",
      "Madison",
      "Monroe",
      "Zcta 30656",
      "Philomath",
      "Royston",
      "Rutledge",
      "Sharon",
      "Siloam",
      "Statham",
      "Stephens",
      "Danburg",
      "Union point",
      "Maxeys",
      "Washington",
      "Watkinsville",
      "White plains",
      "Winder",
      "Winterville",
      "Zcta 306hh",
      "Calhoun",
      "Chatsworth",
      "Chickamauga",
      "Cisco",
      "Cohutta",
      "Crandall",
      "Dalton",
      "Eton",
      "Flintstone",
      "Graysville",
      "La fayette",
      "Lyerly",
      "Cloudland",
      "Plainville",
      "Ranger",
      "Hill city",
      "Ringgold",
      "Rising fawn",
      "Rock spring",
      "Rocky face",
      "Rossville",
      "Fort oglethorpe",
      "Sugar valley",
      "Summerville",
      "Lookout mountain",
      "Trenton",
      "Trion",
      "Tunnel hill",
      "Wildwood",
      "Zcta 307hh",
      "Zcta 307xx",
      "Appling",
      "Avera",
      "Blythe",
      "Camak",
      "Dearing",
      "Evans",
      "Gibson",
      "Grovetown",
      "Harlem",
      "Hephzibah",
      "Keysville",
      "Lincolnton",
      "Matthews",
      "Mitchell",
      "Norwood",
      "Perkins",
      "Stapleton",
      "Thomson",
      "Warrenton",
      "Waynesboro",
      "Wrens",
      "Zcta 308hh",
      "Zcta 308xx",
      "Augusta",
      "Fort gordon",
      "Peach orchard",
      "Martinez",
      "Forest hills",
      "Zcta 309hh",
      "Abbeville",
      "Adrian",
      "Allentown",
      "Bonaire",
      "Butler",
      "Byromville",
      "Powersville",
      "Cadwell",
      "Chauncey",
      "Chester",
      "Cochran",
      "Cordele",
      "Culloden",
      "Danville",
      "Davisboro",
      "Dexter",
      "Dry branch",
      "East dublin",
      "Dudley",
      "Eastman",
      "Eatonton",
      "Elko",
      "Zcta 31027",
      "Centerville",
      "Forsyth",
      "Fort valley",
      "Stevens pottery",
      "Gray",
      "Haddock",
      "Hardwick",
      "Harrison",
      "Hawkinsville",
      "Helena",
      "Round oak",
      "Howard",
      "Ideal",
      "Irwinton",
      "Jeffersonville",
      "Jewell",
      "Juliette",
      "Kathleen",
      "Kite",
      "Knoxville",
      "Lilly",
      "Lizella",
      "Mc intyre",
      "Mc rae",
      "Marshallville",
      "Mauk",
      "Milan",
      "Milledgeville",
      "Montezuma",
      "Monticello",
      "Montrose",
      "Musella",
      "Oconee",
      "Oglethorpe",
      "Perry",
      "Pinehurst",
      "Pineview",
      "Pitts",
      "Rentz",
      "Reynolds",
      "Rhine",
      "Roberta",
      "Rochelle",
      "Rupert",
      "Deepstep",
      "Scotland",
      "Shady dale",
      "Devereux",
      "Warner robins",
      "Tennille",
      "Toomsboro",
      "Unadilla",
      "Vienna",
      "Warthen",
      "Wrightsville",
      "Yatesville",
      "Robins a f b",
      "Zcta 310hh",
      "Zcta 310xx",
      "Huber",
      "Macon",
      "Wilson airport",
      "Zcta 31216",
      "Zcta 31217",
      "Zcta 31220",
      "Zcta 312hh",
      "Allenhurst",
      "Bloomingdale",
      "Clyo",
      "Darien",
      "Eden",
      "Ellabell",
      "Fleming",
      "Guyton",
      "Hinesville",
      "Fort stewart",
      "Zcta 31315",
      "Ludowici",
      "Meldrim",
      "Meridian",
      "Midway",
      "Pembroke",
      "Pooler",
      "Riceboro",
      "Richmond hill",
      "Rincon",
      "Sapelo island",
      "Tybee island",
      "Stillwell",
      "Townsend",
      "Walthourville",
      "Zcta 313hh",
      "Zcta 313xx",
      "Savannah",
      "State college",
      "Port wentworth",
      "Garden city",
      "M m",
      "Zcta 314hh",
      "Okefenokee",
      "Waycross",
      "Alma",
      "Ambrose",
      "Baxley",
      "Blackshear",
      "Bristol",
      "Broxton",
      "Glynco",
      "Saint simons isl",
      "Brunswick",
      "Jekyll island",
      "Denton",
      "Douglas",
      "Zcta 31535",
      "Folkston",
      "Hazlehurst",
      "Hoboken",
      "Hortense",
      "Jacksonville",
      "Jesup",
      "Zcta 31546",
      "Kingsland",
      "Lumber city",
      "Manor",
      "Mershon",
      "Millwood",
      "Nahunta",
      "Nicholls",
      "Odum",
      "Patterson",
      "Saint marys",
      "Screven",
      "Surrency",
      "Waverly",
      "Waynesville",
      "West green",
      "White oak",
      "Woodbine",
      "Zcta 315hh",
      "Zcta 315xx",
      "Clyattville",
      "Bemiss",
      "Zcta 31605",
      "Zcta 31606",
      "Adel",
      "Alapaha",
      "Argyle",
      "Axson",
      "Barney",
      "Boston",
      "Cecil",
      "Dixie",
      "Du pont",
      "Fargo",
      "Hahira",
      "Cogdell",
      "Lakeland",
      "Lake park",
      "Lenox",
      "Morven",
      "Nashville",
      "Naylor",
      "Pearson",
      "Quitman",
      "Ray city",
      "Saint george",
      "Sparks",
      "Statenville",
      "Stockton",
      "Willacoochee",
      "Moody air force",
      "Zcta 316hh",
      "Zcta 316xx",
      "Albany",
      "Bridgeboro",
      "Georgia southwes",
      "Andersonville",
      "Arabi",
      "Arlington",
      "Ashburn",
      "Attapulgus",
      "Baconton",
      "Bainbridge",
      "Barwick",
      "Berlin",
      "Blakely",
      "Bluffton",
      "Brinson",
      "Bronwood",
      "Cairo",
      "Calvary",
      "Camilla",
      "Cedar springs",
      "Chula",
      "Climax",
      "Cobb",
      "Coleman",
      "Colquitt",
      "Coolidge",
      "Cuthbert",
      "Damascus",
      "Graves",
      "De soto",
      "Doerun",
      "Donalsonville",
      "Edison",
      "Ellenton",
      "Enigma",
      "Fitzgerald",
      "Fort gaines",
      "Fowlstown",
      "Georgetown",
      "Hartsfield",
      "Zcta 31757",
      "Iron city",
      "Jakin",
      "Leary",
      "Leesburg",
      "Leslie",
      "Meigs",
      "Morgan",
      "Springvale",
      "Moultrie",
      "Newton",
      "Norman park",
      "Oakfield",
      "Ochlocknee",
      "Ocilla",
      "Omega",
      "Parrott",
      "Pavo",
      "Pelham",
      "Plains",
      "Poulan",
      "Rebecca",
      "Sale city",
      "Shellman",
      "Smithville",
      "Sumner",
      "Sycamore",
      "Sylvester",
      "Thomasville",
      "Abac",
      "Ty ty",
      "Warwick",
      "Whigham",
      "Wray",
      "Zcta 317hh",
      "Zcta 317xx",
      "Juniper",
      "Tazewell",
      "Cataula",
      "Cusseta",
      "Ellaville",
      "Ellerslie",
      "Fortson",
      "Geneva",
      "Hamilton",
      "Junction city",
      "Louvale",
      "Lumpkin",
      "Manchester",
      "Midland",
      "Omaha",
      "Pine mountain",
      "Pine mountain va",
      "Preston",
      "Richland",
      "Shiloh",
      "Talbotton",
      "Upatoi",
      "Warm springs",
      "Waverly hall",
      "Weston",
      "West point",
      "Woodland",
      "Zcta 318hh",
      "Zcta 318xx",
      "Columbus",
      "Custer terrace",
      "Zcta 319hh",
      "Zcta 319xx",
    ],
    ZipCodes: {
      "Avondale estates": ["30002"],
      "Zcta 30004": ["30004"],
      "Zcta 30005": ["30005"],
      "Zcta 30008": ["30008"],
      "Zcta 30011": ["30011"],
      "Zcta 30012": ["30012"],
      "Zcta 30013": ["30013"],
      "Zcta 30014": ["30014"],
      "Zcta 30016": ["30016"],
      "Zcta 30017": ["30017"],
      "Zcta 30019": ["30019"],
      Clarkston: ["30021"],
      "Zcta 30022": ["30022"],
      "Zcta 30024": ["30024"],
      "Zcta 30025": ["30025"],
      Decatur: ["30030", "30032", "30033", "30034", "30035"],
      Lithonia: ["30038"],
      "Zcta 30039": ["30039"],
      "Zcta 30040": ["30040"],
      "Zcta 30041": ["30041"],
      "Zcta 30043": ["30043"],
      "Zcta 30044": ["30044"],
      "Zcta 30045": ["30045"],
      "Zcta 30047": ["30047"],
      "Zcta 30052": ["30052"],
      "Zcta 30054": ["30054"],
      "Zcta 30055": ["30055"],
      "Zcta 30056": ["30056"],
      "Centerville gwin": ["30058"],
      Marietta: ["30060", "30062", "30064", "30066", "30067", "30068"],
      "Zcta 30070": ["30070"],
      Norcross: ["30071", "30092", "30093"],
      "Pine lake": ["30072"],
      Roswell: ["30075", "30076"],
      "Zcta 30078": ["30078"],
      Scottdale: ["30079"],
      Smyrna: ["30080", "30082"],
      "Stone mountain": ["30083", "30087", "30088"],
      Tucker: ["30084"],
      "Zcta 30094": ["30094"],
      "Zcta 30096": ["30096"],
      "Zcta 30097": ["30097"],
      "Zcta 300hh": ["300HH"],
      Acworth: ["30101", "30102"],
      Adairsville: ["30103"],
      Aragon: ["30104"],
      Armuchee: ["30105"],
      "Zcta 30106": ["30106"],
      "Ball ground": ["30107"],
      Bowdon: ["30108"],
      Bremen: ["30110"],
      Buchanan: ["30113"],
      Canton: ["30114", "30115"],
      Carrollton: ["30116", "30117"],
      Cartersville: ["30120"],
      "Zcta 30121": ["30121"],
      "Zcta 30122": ["30122"],
      "Cave spring": ["30124"],
      Cedartown: ["30125"],
      "Zcta 30126": ["30126"],
      "Zcta 30127": ["30127"],
      Dallas: ["30132"],
      Douglasville: ["30134", "30135"],
      Emerson: ["30137"],
      Fairmount: ["30139"],
      Hiram: ["30141"],
      Jasper: ["30143"],
      Kennesaw: ["30144"],
      Kingston: ["30145"],
      Lindale: ["30147"],
      "Marble hill": ["30148"],
      "Zcta 30152": ["30152"],
      Rockmart: ["30153"],
      "Zcta 30157": ["30157"],
      Rome: ["30161", "30165"],
      "Zcta 30168": ["30168"],
      Roopville: ["30170"],
      "Pine log": ["30171"],
      "Silver creek": ["30173"],
      "Talking rock": ["30175"],
      Tallapoosa: ["30176"],
      Tate: ["30177"],
      Taylorsville: ["30178"],
      Temple: ["30179"],
      "Villa rica": ["30180"],
      Waco: ["30182"],
      Waleska: ["30183"],
      White: ["30184"],
      Whitesburg: ["30185"],
      Winston: ["30187"],
      Woodstock: ["30188"],
      "Zcta 30189": ["30189"],
      "Zcta 301hh": ["301HH"],
      Barnesville: ["30204"],
      Brooks: ["30205"],
      Concord: ["30206"],
      Fairburn: ["30213"],
      Woolsey: ["30214"],
      "Zcta 30215": ["30215"],
      Flovilla: ["30216"],
      Glenn: ["30217"],
      Alvaton: ["30218"],
      Grantville: ["30220"],
      Stovall: ["30222"],
      Griffin: ["30223", "30224"],
      Hampton: ["30228"],
      Hogansville: ["30230"],
      Jackson: ["30233"],
      Jenkinsburg: ["30234"],
      Jonesboro: ["30236"],
      "Zcta 30238": ["30238"],
      "La grange": ["30240", "30241"],
      "Locust grove": ["30248"],
      Lovejoy: ["30250"],
      Luthersville: ["30251"],
      "Zcta 30252": ["30252"],
      "Mc donough": ["30253"],
      Meansville: ["30256"],
      Milner: ["30257"],
      Molena: ["30258"],
      Moreland: ["30259"],
      Morrow: ["30260"],
      Raymond: ["30263"],
      Newnan: ["30265"],
      Palmetto: ["30268"],
      "Peachtree city": ["30269"],
      Rex: ["30273"],
      Riverdale: ["30274", "30296"],
      Sargent: ["30275"],
      Senoia: ["30276"],
      Sharpsburg: ["30277"],
      Stockbridge: ["30281"],
      "The rock": ["30285"],
      Thomaston: ["30286"],
      "Zcta 30288": ["30288"],
      Tyrone: ["30290"],
      "Union city": ["30291"],
      Williamson: ["30292"],
      Woodbury: ["30293"],
      "Zcta 30294": ["30294"],
      Zebulon: ["30295"],
      "Zcta 30297": ["30297"],
      "Zcta 302hh": ["302HH"],
      Atlanta: [
        "30303",
        "30305",
        "30306",
        "30307",
        "30308",
        "30309",
        "30310",
        "30311",
        "30312",
        "30313",
        "30314",
        "30315",
        "30316",
        "30317",
        "30318",
        "30319",
        "30322",
        "30324",
        "30326",
        "30327",
        "30329",
        "30331",
        "30336",
        "30339",
        "30342",
        "30345",
        "30346",
        "30349",
        "30350",
        "30360",
      ],
      "Sandy springs": ["30328"],
      "College park": ["30337"],
      Dunwoody: ["30338"],
      Doraville: ["30340"],
      Chamblee: ["30341"],
      "East point": ["30344"],
      Hapeville: ["30354"],
      "Zcta 303hh": ["303HH"],
      "Oak park": ["30401"],
      Ailey: ["30410"],
      Alamo: ["30411"],
      Alston: ["30412"],
      Bartow: ["30413"],
      Bellville: ["30414"],
      Brooklet: ["30415"],
      Claxton: ["30417"],
      Cobbtown: ["30420"],
      Collins: ["30421"],
      Daisy: ["30423"],
      Garfield: ["30425"],
      Girard: ["30426"],
      Glennville: ["30427"],
      Glenwood: ["30428"],
      Hagan: ["30429"],
      Louisville: ["30434"],
      Lyons: ["30436"],
      Manassas: ["30438"],
      Metter: ["30439"],
      Midville: ["30441"],
      Millen: ["30442"],
      "Mount vernon": ["30445"],
      Newington: ["30446"],
      Norristown: ["30447"],
      Nunez: ["30448"],
      Portal: ["30450"],
      Register: ["30452"],
      Reidsville: ["30453"],
      Rockledge: ["30454"],
      "Rocky ford": ["30455"],
      Sardis: ["30456"],
      Soperton: ["30457"],
      Statesboro: ["30458"],
      "Zcta 30461": ["30461"],
      Stillmore: ["30464"],
      Hiltonia: ["30467"],
      Tarrytown: ["30470"],
      "Twin city": ["30471"],
      Uvalda: ["30473"],
      Vidalia: ["30474"],
      Wadley: ["30477"],
      "Zcta 304hh": ["304HH"],
      "Zcta 304xx": ["304XX"],
      Gainesville: ["30501", "30504", "30506", "30507"],
      Alto: ["30510"],
      Baldwin: ["30511"],
      Blairsville: ["30512"],
      "Blue ridge": ["30513"],
      Bowersville: ["30516"],
      Braselton: ["30517"],
      Buford: ["30518"],
      "Zcta 30519": ["30519"],
      Canon: ["30520"],
      Carnesville: ["30521"],
      Cherrylog: ["30522"],
      Clarkesville: ["30523"],
      Clayton: ["30525"],
      Clermont: ["30527"],
      Cleveland: ["30528"],
      Commerce: ["30529"],
      "Zcta 30530": ["30530"],
      Cornelia: ["30531"],
      Dahlonega: ["30533"],
      Juno: ["30534"],
      Demorest: ["30535"],
      "Sky valley": ["30537"],
      Eastanollee: ["30538"],
      "East ellijay": ["30539"],
      Ellijay: ["30540"],
      Epworth: ["30541"],
      "Flowery branch": ["30542"],
      Gillsville: ["30543"],
      Helen: ["30545"],
      Hiawassee: ["30546"],
      Homer: ["30547"],
      Hoschton: ["30548"],
      Jefferson: ["30549"],
      Lakemont: ["30552"],
      Lavonia: ["30553"],
      Lula: ["30554"],
      "Mc caysville": ["30555"],
      Martin: ["30557"],
      Maysville: ["30558"],
      "Mineral bluff": ["30559"],
      Morganton: ["30560"],
      "Mountain city": ["30562"],
      "Mount airy": ["30563"],
      Murrayville: ["30564"],
      Nicholson: ["30565"],
      Oakwood: ["30566"],
      Pendergrass: ["30567"],
      "Rabun gap": ["30568"],
      "Sautee nacoochee": ["30571"],
      Suches: ["30572"],
      Talmo: ["30575"],
      Tiger: ["30576"],
      Toccoa: ["30577"],
      Wiley: ["30581"],
      "Young harris": ["30582"],
      "Toccoa falls": ["30598"],
      "Zcta 305hh": ["305HH"],
      Athens: ["30601", "30602", "30605", "30606", "30607"],
      Arnoldsville: ["30619"],
      Bethlehem: ["30620"],
      Bishop: ["30621"],
      Bogart: ["30622"],
      Bowman: ["30624"],
      Buckhead: ["30625"],
      Carlton: ["30627"],
      Colbert: ["30628"],
      Comer: ["30629"],
      Crawford: ["30630"],
      Crawfordville: ["30631"],
      Danielsville: ["30633"],
      "Dewy rose": ["30634"],
      Elberton: ["30635"],
      Farmington: ["30638"],
      "Franklin springs": ["30639"],
      "Good hope": ["30641"],
      Greensboro: ["30642"],
      Hartwell: ["30643"],
      Hull: ["30646"],
      Lexington: ["30648"],
      Madison: ["30650"],
      Monroe: ["30655"],
      "Zcta 30656": ["30656"],
      Philomath: ["30660"],
      Royston: ["30662"],
      Rutledge: ["30663"],
      Sharon: ["30664"],
      Siloam: ["30665"],
      Statham: ["30666"],
      Stephens: ["30667"],
      Danburg: ["30668"],
      "Union point": ["30669"],
      Maxeys: ["30671"],
      Washington: ["30673"],
      Watkinsville: ["30677"],
      "White plains": ["30678"],
      Winder: ["30680"],
      Winterville: ["30683"],
      "Zcta 306hh": ["306HH"],
      Calhoun: ["30701"],
      Chatsworth: ["30705"],
      Chickamauga: ["30707"],
      Cisco: ["30708"],
      Cohutta: ["30710"],
      Crandall: ["30711"],
      Dalton: ["30720", "30721"],
      Eton: ["30724"],
      Flintstone: ["30725"],
      Graysville: ["30726"],
      "La fayette": ["30728"],
      Lyerly: ["30730"],
      Cloudland: ["30731"],
      Plainville: ["30733"],
      Ranger: ["30734"],
      "Hill city": ["30735"],
      Ringgold: ["30736"],
      "Rising fawn": ["30738"],
      "Rock spring": ["30739"],
      "Rocky face": ["30740"],
      Rossville: ["30741"],
      "Fort oglethorpe": ["30742"],
      "Sugar valley": ["30746"],
      Summerville: ["30747"],
      "Lookout mountain": ["30750"],
      Trenton: ["30752"],
      Trion: ["30753"],
      "Tunnel hill": ["30755"],
      Wildwood: ["30757"],
      "Zcta 307hh": ["307HH"],
      "Zcta 307xx": ["307XX"],
      Appling: ["30802"],
      Avera: ["30803"],
      Blythe: ["30805"],
      Camak: ["30807"],
      Dearing: ["30808"],
      Evans: ["30809"],
      Gibson: ["30810"],
      Grovetown: ["30813"],
      Harlem: ["30814"],
      Hephzibah: ["30815"],
      Keysville: ["30816"],
      Lincolnton: ["30817"],
      Matthews: ["30818"],
      Mitchell: ["30820"],
      Norwood: ["30821"],
      Perkins: ["30822"],
      Stapleton: ["30823"],
      Thomson: ["30824"],
      Warrenton: ["30828"],
      Waynesboro: ["30830"],
      Wrens: ["30833"],
      "Zcta 308hh": ["308HH"],
      "Zcta 308xx": ["308XX"],
      Augusta: ["30901", "30904"],
      "Fort gordon": ["30905"],
      "Peach orchard": ["30906"],
      Martinez: ["30907"],
      "Forest hills": ["30909"],
      "Zcta 309hh": ["309HH"],
      Abbeville: ["31001"],
      Adrian: ["31002"],
      Allentown: ["31003"],
      Bonaire: ["31005"],
      Butler: ["31006"],
      Byromville: ["31007"],
      Powersville: ["31008"],
      Cadwell: ["31009"],
      Chauncey: ["31011"],
      Chester: ["31012"],
      Cochran: ["31014"],
      Cordele: ["31015"],
      Culloden: ["31016"],
      Danville: ["31017"],
      Davisboro: ["31018"],
      Dexter: ["31019"],
      "Dry branch": ["31020"],
      "East dublin": ["31021"],
      Dudley: ["31022"],
      Eastman: ["31023"],
      Eatonton: ["31024"],
      Elko: ["31025"],
      "Zcta 31027": ["31027"],
      Centerville: ["31028"],
      Forsyth: ["31029"],
      "Fort valley": ["31030"],
      "Stevens pottery": ["31031"],
      Gray: ["31032"],
      Haddock: ["31033"],
      Hardwick: ["31034"],
      Harrison: ["31035"],
      Hawkinsville: ["31036"],
      Helena: ["31037"],
      "Round oak": ["31038"],
      Howard: ["31039"],
      Ideal: ["31041"],
      Irwinton: ["31042"],
      Jeffersonville: ["31044"],
      Jewell: ["31045"],
      Juliette: ["31046"],
      Kathleen: ["31047"],
      Kite: ["31049"],
      Knoxville: ["31050"],
      Lilly: ["31051"],
      Lizella: ["31052"],
      "Mc intyre": ["31054"],
      "Mc rae": ["31055"],
      Marshallville: ["31057"],
      Mauk: ["31058"],
      Milan: ["31060"],
      Milledgeville: ["31061"],
      Montezuma: ["31063"],
      Monticello: ["31064"],
      Montrose: ["31065"],
      Musella: ["31066"],
      Oconee: ["31067"],
      Oglethorpe: ["31068"],
      Perry: ["31069"],
      Pinehurst: ["31070"],
      Pineview: ["31071"],
      Pitts: ["31072"],
      Rentz: ["31075"],
      Reynolds: ["31076"],
      Rhine: ["31077"],
      Roberta: ["31078"],
      Rochelle: ["31079"],
      Rupert: ["31081"],
      Deepstep: ["31082"],
      Scotland: ["31083"],
      "Shady dale": ["31085"],
      Devereux: ["31087"],
      "Warner robins": ["31088", "31093"],
      Tennille: ["31089"],
      Toomsboro: ["31090"],
      Unadilla: ["31091"],
      Vienna: ["31092"],
      Warthen: ["31094"],
      Wrightsville: ["31096"],
      Yatesville: ["31097"],
      "Robins a f b": ["31098"],
      "Zcta 310hh": ["310HH"],
      "Zcta 310xx": ["310XX"],
      Huber: ["31201"],
      Macon: ["31204", "31210", "31211"],
      "Wilson airport": ["31206"],
      "Zcta 31216": ["31216"],
      "Zcta 31217": ["31217"],
      "Zcta 31220": ["31220"],
      "Zcta 312hh": ["312HH"],
      Allenhurst: ["31301"],
      Bloomingdale: ["31302"],
      Clyo: ["31303"],
      Darien: ["31305"],
      Eden: ["31307"],
      Ellabell: ["31308"],
      Fleming: ["31309"],
      Guyton: ["31312"],
      Hinesville: ["31313"],
      "Fort stewart": ["31314"],
      "Zcta 31315": ["31315"],
      Ludowici: ["31316"],
      Meldrim: ["31318"],
      Meridian: ["31319"],
      Midway: ["31320"],
      Pembroke: ["31321"],
      Pooler: ["31322"],
      Riceboro: ["31323"],
      "Richmond hill": ["31324"],
      Rincon: ["31326"],
      "Sapelo island": ["31327"],
      "Tybee island": ["31328"],
      Stillwell: ["31329"],
      Townsend: ["31331"],
      Walthourville: ["31333"],
      "Zcta 313hh": ["313HH"],
      "Zcta 313xx": ["313XX"],
      Savannah: ["31401", "31405", "31406", "31410", "31411", "31415"],
      "State college": ["31404"],
      "Port wentworth": ["31407"],
      "Garden city": ["31408"],
      "M m": ["31419"],
      "Zcta 314hh": ["314HH"],
      Okefenokee: ["31501"],
      Waycross: ["31503"],
      Alma: ["31510"],
      Ambrose: ["31512"],
      Baxley: ["31513"],
      Blackshear: ["31516"],
      Bristol: ["31518"],
      Broxton: ["31519"],
      Glynco: ["31520"],
      "Saint simons isl": ["31522"],
      Brunswick: ["31523", "31525"],
      "Jekyll island": ["31527"],
      Denton: ["31532"],
      Douglas: ["31533"],
      "Zcta 31535": ["31535"],
      Folkston: ["31537"],
      Hazlehurst: ["31539"],
      Hoboken: ["31542"],
      Hortense: ["31543"],
      Jacksonville: ["31544"],
      Jesup: ["31545"],
      "Zcta 31546": ["31546"],
      Kingsland: ["31548"],
      "Lumber city": ["31549"],
      Manor: ["31550"],
      Mershon: ["31551"],
      Millwood: ["31552"],
      Nahunta: ["31553"],
      Nicholls: ["31554"],
      Odum: ["31555"],
      Patterson: ["31557"],
      "Saint marys": ["31558"],
      Screven: ["31560"],
      Surrency: ["31563"],
      Waverly: ["31565"],
      Waynesville: ["31566"],
      "West green": ["31567"],
      "White oak": ["31568"],
      Woodbine: ["31569"],
      "Zcta 315hh": ["315HH"],
      "Zcta 315xx": ["315XX"],
      Clyattville: ["31601"],
      Bemiss: ["31602"],
      "Zcta 31605": ["31605"],
      "Zcta 31606": ["31606"],
      Adel: ["31620"],
      Alapaha: ["31622"],
      Argyle: ["31623"],
      Axson: ["31624"],
      Barney: ["31625"],
      Boston: ["31626"],
      Cecil: ["31627"],
      Dixie: ["31629"],
      "Du pont": ["31630"],
      Fargo: ["31631"],
      Hahira: ["31632"],
      Cogdell: ["31634"],
      Lakeland: ["31635"],
      "Lake park": ["31636"],
      Lenox: ["31637"],
      Morven: ["31638"],
      Nashville: ["31639"],
      Naylor: ["31641"],
      Pearson: ["31642"],
      Quitman: ["31643"],
      "Ray city": ["31645"],
      "Saint george": ["31646"],
      Sparks: ["31647"],
      Statenville: ["31648"],
      Stockton: ["31649"],
      Willacoochee: ["31650"],
      "Moody air force": ["31699"],
      "Zcta 316hh": ["316HH"],
      "Zcta 316xx": ["316XX"],
      Albany: ["31701", "31707"],
      Bridgeboro: ["31705"],
      "Georgia southwes": ["31709"],
      Andersonville: ["31711"],
      Arabi: ["31712"],
      Arlington: ["31713"],
      Ashburn: ["31714"],
      Attapulgus: ["31715"],
      Baconton: ["31716"],
      Bainbridge: ["31717"],
      Barwick: ["31720"],
      Berlin: ["31722"],
      Blakely: ["31723"],
      Bluffton: ["31724"],
      Brinson: ["31725"],
      Bronwood: ["31726"],
      Cairo: ["31728"],
      Calvary: ["31729"],
      Camilla: ["31730"],
      "Cedar springs": ["31732"],
      Chula: ["31733"],
      Climax: ["31734"],
      Cobb: ["31735"],
      Coleman: ["31736"],
      Colquitt: ["31737"],
      Coolidge: ["31738"],
      Cuthbert: ["31740"],
      Damascus: ["31741"],
      Graves: ["31742"],
      "De soto": ["31743"],
      Doerun: ["31744"],
      Donalsonville: ["31745"],
      Edison: ["31746"],
      Ellenton: ["31747"],
      Enigma: ["31749"],
      Fitzgerald: ["31750"],
      "Fort gaines": ["31751"],
      Fowlstown: ["31752"],
      Georgetown: ["31754"],
      Hartsfield: ["31756"],
      "Zcta 31757": ["31757"],
      "Iron city": ["31759"],
      Jakin: ["31761"],
      Leary: ["31762"],
      Leesburg: ["31763"],
      Leslie: ["31764"],
      Meigs: ["31765"],
      Morgan: ["31766"],
      Springvale: ["31767"],
      Moultrie: ["31768"],
      Newton: ["31770"],
      "Norman park": ["31771"],
      Oakfield: ["31772"],
      Ochlocknee: ["31773"],
      Ocilla: ["31774"],
      Omega: ["31775"],
      Parrott: ["31777"],
      Pavo: ["31778"],
      Pelham: ["31779"],
      Plains: ["31780"],
      Poulan: ["31781"],
      Rebecca: ["31783"],
      "Sale city": ["31784"],
      Shellman: ["31786"],
      Smithville: ["31787"],
      Sumner: ["31789"],
      Sycamore: ["31790"],
      Sylvester: ["31791"],
      Thomasville: ["31792"],
      Abac: ["31794"],
      "Ty ty": ["31795"],
      Warwick: ["31796"],
      Whigham: ["31797"],
      Wray: ["31798"],
      "Zcta 317hh": ["317HH"],
      "Zcta 317xx": ["317XX"],
      Juniper: ["31801"],
      Tazewell: ["31803"],
      Cataula: ["31804"],
      Cusseta: ["31805"],
      Ellaville: ["31806"],
      Ellerslie: ["31807"],
      Fortson: ["31808"],
      Geneva: ["31810"],
      Hamilton: ["31811"],
      "Junction city": ["31812"],
      Louvale: ["31814"],
      Lumpkin: ["31815"],
      Manchester: ["31816"],
      Midland: ["31820"],
      Omaha: ["31821"],
      "Pine mountain": ["31822"],
      "Pine mountain va": ["31823"],
      Preston: ["31824"],
      Richland: ["31825"],
      Shiloh: ["31826"],
      Talbotton: ["31827"],
      Upatoi: ["31829"],
      "Warm springs": ["31830"],
      "Waverly hall": ["31831"],
      Weston: ["31832"],
      "West point": ["31833"],
      Woodland: ["31836"],
      "Zcta 318hh": ["318HH"],
      "Zcta 318xx": ["318XX"],
      Columbus: ["31901", "31903", "31904", "31906", "31907", "31909"],
      "Custer terrace": ["31905"],
      "Zcta 319hh": ["319HH"],
      "Zcta 319xx": ["319XX"],
    },
  },
  {
    abbrevation: "HI",
    name: "Hawaii",
    Cities: [
      "Aiea",
      "Aiea",
      "Anahola",
      "Captain cook",
      "Eleele",
      "Ewa beach",
      "Kapolei",
      "Haiku",
      "Hakalau",
      "Haleiwa",
      "Hana",
      "Hanalei",
      "Hanapepe",
      "Hauula",
      "Hawaii national",
      "Hawi",
      "Hilo",
      "Princeville",
      "Holualoa",
      "Honokaa",
      "Honomu",
      "Hoolehua",
      "Kaaawa",
      "Kahuku",
      "Kahului",
      "Kailua",
      "Zcta 96737",
      "Waikoloa",
      "Kailua kona",
      "Kalaheo",
      "Kalaupapa",
      "Kamuela",
      "Kaneohe",
      "Kapaa",
      "Kaumakani",
      "Kaunakakai",
      "Keaau",
      "Kealakekua",
      "Kealia",
      "Kekaha",
      "Kihei",
      "Kilauea",
      "Kapaau",
      "Koloa",
      "Kualapuu",
      "Kunia",
      "Kurtistown",
      "Lahaina",
      "Laie",
      "Lanai city",
      "Laupahoehoe",
      "Lihue",
      "Makawao",
      "Makaweli",
      "Maunaloa",
      "Mountain view",
      "Naalehu",
      "Ninole",
      "Ookala",
      "Paauilo",
      "Pahala",
      "Pahoa",
      "Paia",
      "Papaaloa",
      "Papaikou",
      "Pearl city",
      "Pepeekeo",
      "Volcano",
      "Wahiawa",
      "Mililani",
      "Kula",
      "Waialua",
      "Waianae",
      "Wailuku",
      "Waimanalo",
      "Waimea",
      "Waipahu",
      "Zcta 967hh",
      "Zcta 967xx",
      "Honolulu",
      "Barbers point na",
      "Kaneohe mcas",
      "Zcta 968hh",
    ],
    ZipCodes: {
      Aiea: ["96701"],
      Anahola: ["96703"],
      "Captain cook": ["96704"],
      Eleele: ["96705"],
      "Ewa beach": ["96706"],
      Kapolei: ["96707"],
      Haiku: ["96708"],
      Hakalau: ["96710"],
      Haleiwa: ["96712"],
      Hana: ["96713"],
      Hanalei: ["96714"],
      Hanapepe: ["96716"],
      Hauula: ["96717"],
      "Hawaii national": ["96718"],
      Hawi: ["96719"],
      Hilo: ["96720"],
      Princeville: ["96722"],
      Holualoa: ["96725"],
      Honokaa: ["96727"],
      Honomu: ["96728"],
      Hoolehua: ["96729"],
      Kaaawa: ["96730"],
      Kahuku: ["96731"],
      Kahului: ["96732"],
      Kailua: ["96734"],
      "Zcta 96737": ["96737"],
      Waikoloa: ["96738"],
      "Kailua kona": ["96740"],
      Kalaheo: ["96741"],
      Kalaupapa: ["96742"],
      Kamuela: ["96743"],
      Kaneohe: ["96744"],
      Kapaa: ["96746"],
      Kaumakani: ["96747"],
      Kaunakakai: ["96748"],
      Keaau: ["96749"],
      Kealakekua: ["96750"],
      Kealia: ["96751"],
      Kekaha: ["96752"],
      Kihei: ["96753"],
      Kilauea: ["96754"],
      Kapaau: ["96755"],
      Koloa: ["96756"],
      Kualapuu: ["96757"],
      Kunia: ["96759"],
      Kurtistown: ["96760"],
      Lahaina: ["96761"],
      Laie: ["96762"],
      "Lanai city": ["96763"],
      Laupahoehoe: ["96764"],
      Lihue: ["96766"],
      Makawao: ["96768"],
      Makaweli: ["96769"],
      Maunaloa: ["96770"],
      "Mountain view": ["96771"],
      Naalehu: ["96772"],
      Ninole: ["96773"],
      Ookala: ["96774"],
      Paauilo: ["96776"],
      Pahala: ["96777"],
      Pahoa: ["96778"],
      Paia: ["96779"],
      Papaaloa: ["96780"],
      Papaikou: ["96781"],
      "Pearl city": ["96782"],
      Pepeekeo: ["96783"],
      Volcano: ["96785"],
      Wahiawa: ["96786"],
      Mililani: ["96789"],
      Kula: ["96790"],
      Waialua: ["96791"],
      Waianae: ["96792"],
      Wailuku: ["96793"],
      Waimanalo: ["96795"],
      Waimea: ["96796"],
      Waipahu: ["96797"],
      "Zcta 967hh": ["967HH"],
      "Zcta 967xx": ["967XX"],
      Honolulu: [
        "96813",
        "96814",
        "96815",
        "96816",
        "96817",
        "96818",
        "96819",
        "96821",
        "96822",
        "96825",
        "96826",
      ],
      "Barbers point na": ["96862"],
      "Kaneohe mcas": ["96863"],
      "Zcta 968hh": ["968HH"],
    },
  },
  {
    abbrevation: "ID",
    name: "Idaho",
    Cities: [
      "",
      "",
      "Pocatello",
      "Chubbuck",
      "Fort hall",
      "Sterling",
      "American falls",
      "Arbon",
      "Arco",
      "Arimo",
      "Atomic city",
      "Bancroft",
      "Basalt",
      "Bern",
      "Blackfoot",
      "Bloomington",
      "Challis",
      "Clayton",
      "Clifton",
      "Dayton",
      "Downey",
      "Ellis",
      "Firth",
      "Franklin",
      "Geneva",
      "Georgetown",
      "Grace",
      "Holbrook",
      "Howe",
      "Inkom",
      "Lava hot springs",
      "Mc cammon",
      "Mackay",
      "Malad city",
      "Patterson",
      "Montpelier",
      "Moore",
      "Paris",
      "Pingree",
      "Preston",
      "Rockland",
      "Saint charles",
      "Shelley",
      "Soda springs",
      "Springfield",
      "Stanley",
      "Swanlake",
      "Thatcher",
      "Wayan",
      "Weston",
      "Fish haven",
      "Zcta 832hh",
      "Zcta 832xx",
      "Twin falls",
      "Rogerson",
      "Albion",
      "Almo",
      "Bellevue",
      "Bliss",
      "Buhl",
      "Burley",
      "Carey",
      "Castleford",
      "Corral",
      "Declo",
      "Dietrich",
      "Eden",
      "Fairfield",
      "Filer",
      "Gooding",
      "Hagerman",
      "Hailey",
      "Hansen",
      "Hazelton",
      "Heyburn",
      "Hill city",
      "Jerome",
      "Obsidian",
      "Kimberly",
      "Naf",
      "Murtaugh",
      "Oakley",
      "Paul",
      "Picabo",
      "Richfield",
      "Acequia",
      "Shoshone",
      "Sun valley",
      "Elk horn",
      "Wendell",
      "Zcta 833hh",
      "Zcta 833xx",
      "Ammon",
      "Idaho falls",
      "Ashton",
      "Chester",
      "Driggs",
      "Dubois",
      "Felt",
      "Hamer",
      "Iona",
      "Irwin",
      "Island park",
      "Lewisville",
      "Macks inn",
      "Menan",
      "Monteview",
      "Newdale",
      "Rexburg",
      "Rigby",
      "Ririe",
      "Roberts",
      "Saint anthony",
      "Spencer",
      "Sugar city",
      "Swan valley",
      "Terreton",
      "Teton",
      "Tetonia",
      "Victor",
      "Carmen",
      "Gibbonsville",
      "Leadore",
      "Lemhi",
      "North fork",
      "Salmon",
      "Tendoy",
      "Shoup",
      "Zcta 834hh",
      "Zcta 834xx",
      "South gate plaza",
      "Ahsahka",
      "Cottonwood",
      "Craigmont",
      "Culdesac",
      "Dixie",
      "Ferdinand",
      "Grangeville",
      "Greencreek",
      "Juliaetta",
      "Kamiah",
      "Kendrick",
      "Clearwater",
      "Lapwai",
      "Lenore",
      "Lucile",
      "Nezperce",
      "Orofino",
      "Peck",
      "Pierce",
      "Pollock",
      "Reubens",
      "Riggins",
      "Stites",
      "Weippe",
      "White bird",
      "Winchester",
      "Zcta 835hh",
      "Zcta 835xx",
      "Atlanta",
      "Banks",
      "Grasmere",
      "Caldwell",
      "Zcta 83607",
      "Cambridge",
      "West mountain",
      "Council",
      "Donnelly",
      "Eagle",
      "Montour",
      "Fruitland",
      "Fruitvale",
      "Garden valley",
      "Glenns ferry",
      "Grand view",
      "Greenleaf",
      "Hammett",
      "Homedale",
      "Horseshoe bend",
      "Idaho city",
      "Indian valley",
      "King hill",
      "Kuna",
      "Lowman",
      "Mc call",
      "Marsing",
      "Melba",
      "Meridian",
      "Mesa",
      "Middleton",
      "Midvale",
      "Mountain home",
      "Mountain home a",
      "Oreana",
      "Nampa",
      "New meadows",
      "New plymouth",
      "Notus",
      "Ola",
      "Parma",
      "Payette",
      "Placerville",
      "Star",
      "Sweet",
      "Warren",
      "Weiser",
      "Wilder",
      "Yellow pine",
      "Zcta 836hh",
      "Zcta 836xx",
      "Boise",
      "Zcta 83713",
      "Garden city",
      "Zcta 837hh",
      "Zcta 837xx",
      "Athol",
      "Avery",
      "Bayview",
      "Blanchard",
      "Bonners ferry",
      "Bovill",
      "Calder",
      "Careywood",
      "Cataldo",
      "Clark fork",
      "Clarkia",
      "Cocolalla",
      "Coeur d alene",
      "Coolin",
      "Old town",
      "Deary",
      "Desmet",
      "Eastport",
      "Elk river",
      "Fernwood",
      "Genesee",
      "Harrison",
      "Harvard",
      "Hayden lake",
      "Hope",
      "Kellogg",
      "Kingston",
      "Laclede",
      "Medimont",
      "Moscow",
      "Moyie springs",
      "Mullan",
      "Naples",
      "Nordman",
      "Osburn",
      "Pinehurst",
      "Plummer",
      "Ponderay",
      "Porthill",
      "Post falls",
      "Potlatch",
      "Priest river",
      "Princeton",
      "Rathdrum",
      "Sagle",
      "Saint maries",
      "Sandpoint",
      "Santa",
      "Silverton",
      "Smelterville",
      "Spirit lake",
      "Tensed",
      "Troy",
      "Viola",
      "Wallace",
      "Murray",
      "Worley",
      "Zcta 838hh",
      "Zcta 838xx",
    ],
    ZipCodes: {
      "": ["83120", "831XX", "97910", "97913", "979HH", "99128"],
      Pocatello: ["83201", "83204"],
      Chubbuck: ["83202"],
      "Fort hall": ["83203"],
      Sterling: ["83210"],
      "American falls": ["83211"],
      Arbon: ["83212"],
      Arco: ["83213"],
      Arimo: ["83214"],
      "Atomic city": ["83215"],
      Bancroft: ["83217"],
      Basalt: ["83218"],
      Bern: ["83220"],
      Blackfoot: ["83221"],
      Bloomington: ["83223"],
      Challis: ["83226"],
      Clayton: ["83227"],
      Clifton: ["83228"],
      Dayton: ["83232"],
      Downey: ["83234"],
      Ellis: ["83235"],
      Firth: ["83236"],
      Franklin: ["83237"],
      Geneva: ["83238"],
      Georgetown: ["83239"],
      Grace: ["83241"],
      Holbrook: ["83243"],
      Howe: ["83244"],
      Inkom: ["83245"],
      "Lava hot springs": ["83246"],
      "Mc cammon": ["83250"],
      Mackay: ["83251"],
      "Malad city": ["83252"],
      Patterson: ["83253"],
      Montpelier: ["83254"],
      Moore: ["83255"],
      Paris: ["83261"],
      Pingree: ["83262"],
      Preston: ["83263"],
      Rockland: ["83271"],
      "Saint charles": ["83272"],
      Shelley: ["83274"],
      "Soda springs": ["83276"],
      Springfield: ["83277"],
      Stanley: ["83278"],
      Swanlake: ["83281"],
      Thatcher: ["83283"],
      Wayan: ["83285"],
      Weston: ["83286"],
      "Fish haven": ["83287"],
      "Zcta 832hh": ["832HH"],
      "Zcta 832xx": ["832XX"],
      "Twin falls": ["83301"],
      Rogerson: ["83302"],
      Albion: ["83311"],
      Almo: ["83312"],
      Bellevue: ["83313"],
      Bliss: ["83314"],
      Buhl: ["83316"],
      Burley: ["83318"],
      Carey: ["83320"],
      Castleford: ["83321"],
      Corral: ["83322"],
      Declo: ["83323"],
      Dietrich: ["83324"],
      Eden: ["83325"],
      Fairfield: ["83327"],
      Filer: ["83328"],
      Gooding: ["83330"],
      Hagerman: ["83332"],
      Hailey: ["83333"],
      Hansen: ["83334"],
      Hazelton: ["83335"],
      Heyburn: ["83336"],
      "Hill city": ["83337"],
      Jerome: ["83338"],
      Obsidian: ["83340"],
      Kimberly: ["83341"],
      Naf: ["83342"],
      Murtaugh: ["83344"],
      Oakley: ["83346"],
      Paul: ["83347"],
      Picabo: ["83348"],
      Richfield: ["83349"],
      Acequia: ["83350"],
      Shoshone: ["83352"],
      "Sun valley": ["83353"],
      "Elk horn": ["83354"],
      Wendell: ["83355"],
      "Zcta 833hh": ["833HH"],
      "Zcta 833xx": ["833XX"],
      Ammon: ["83401"],
      "Idaho falls": ["83402", "83404", "83406"],
      Ashton: ["83420"],
      Chester: ["83421"],
      Driggs: ["83422"],
      Dubois: ["83423"],
      Felt: ["83424"],
      Hamer: ["83425"],
      Iona: ["83427"],
      Irwin: ["83428"],
      "Island park": ["83429"],
      Lewisville: ["83431"],
      "Macks inn": ["83433"],
      Menan: ["83434"],
      Monteview: ["83435"],
      Newdale: ["83436"],
      Rexburg: ["83440"],
      Rigby: ["83442"],
      Ririe: ["83443"],
      Roberts: ["83444"],
      "Saint anthony": ["83445"],
      Spencer: ["83446"],
      "Sugar city": ["83448"],
      "Swan valley": ["83449"],
      Terreton: ["83450"],
      Teton: ["83451"],
      Tetonia: ["83452"],
      Victor: ["83455"],
      Carmen: ["83462"],
      Gibbonsville: ["83463"],
      Leadore: ["83464"],
      Lemhi: ["83465"],
      "North fork": ["83466"],
      Salmon: ["83467"],
      Tendoy: ["83468"],
      Shoup: ["83469"],
      "Zcta 834hh": ["834HH"],
      "Zcta 834xx": ["834XX"],
      "South gate plaza": ["83501"],
      Ahsahka: ["83520"],
      Cottonwood: ["83522"],
      Craigmont: ["83523"],
      Culdesac: ["83524"],
      Dixie: ["83525"],
      Ferdinand: ["83526"],
      Grangeville: ["83530"],
      Greencreek: ["83533"],
      Juliaetta: ["83535"],
      Kamiah: ["83536"],
      Kendrick: ["83537"],
      Clearwater: ["83539"],
      Lapwai: ["83540"],
      Lenore: ["83541"],
      Lucile: ["83542"],
      Nezperce: ["83543"],
      Orofino: ["83544"],
      Peck: ["83545"],
      Pierce: ["83546"],
      Pollock: ["83547"],
      Reubens: ["83548"],
      Riggins: ["83549"],
      Stites: ["83552"],
      Weippe: ["83553"],
      "White bird": ["83554"],
      Winchester: ["83555"],
      "Zcta 835hh": ["835HH"],
      "Zcta 835xx": ["835XX"],
      Atlanta: ["83601"],
      Banks: ["83602"],
      Grasmere: ["83604"],
      Caldwell: ["83605"],
      "Zcta 83607": ["83607"],
      Cambridge: ["83610"],
      "West mountain": ["83611"],
      Council: ["83612"],
      Donnelly: ["83615"],
      Eagle: ["83616"],
      Montour: ["83617"],
      Fruitland: ["83619"],
      Fruitvale: ["83620"],
      "Garden valley": ["83622"],
      "Glenns ferry": ["83623"],
      "Grand view": ["83624"],
      Greenleaf: ["83626"],
      Hammett: ["83627"],
      Homedale: ["83628"],
      "Horseshoe bend": ["83629"],
      "Idaho city": ["83631"],
      "Indian valley": ["83632"],
      "King hill": ["83633"],
      Kuna: ["83634"],
      Lowman: ["83637"],
      "Mc call": ["83638"],
      Marsing: ["83639"],
      Melba: ["83641"],
      Meridian: ["83642"],
      Mesa: ["83643"],
      Middleton: ["83644"],
      Midvale: ["83645"],
      "Mountain home": ["83647"],
      "Mountain home a": ["83648"],
      Oreana: ["83650"],
      Nampa: ["83651", "83686", "83687"],
      "New meadows": ["83654"],
      "New plymouth": ["83655"],
      Notus: ["83656"],
      Ola: ["83657"],
      Parma: ["83660"],
      Payette: ["83661"],
      Placerville: ["83666"],
      Star: ["83669"],
      Sweet: ["83670"],
      Warren: ["83671"],
      Weiser: ["83672"],
      Wilder: ["83676"],
      "Yellow pine": ["83677"],
      "Zcta 836hh": ["836HH"],
      "Zcta 836xx": ["836XX"],
      Boise: [
        "83702",
        "83703",
        "83704",
        "83705",
        "83706",
        "83709",
        "83712",
        "83716",
        "83788",
      ],
      "Zcta 83713": ["83713"],
      "Garden city": ["83714"],
      "Zcta 837hh": ["837HH"],
      "Zcta 837xx": ["837XX"],
      Athol: ["83801"],
      Avery: ["83802"],
      Bayview: ["83803"],
      Blanchard: ["83804"],
      "Bonners ferry": ["83805"],
      Bovill: ["83806"],
      Calder: ["83808"],
      Careywood: ["83809"],
      Cataldo: ["83810"],
      "Clark fork": ["83811"],
      Clarkia: ["83812"],
      Cocolalla: ["83813"],
      "Coeur d alene": ["83814", "83815"],
      Coolin: ["83821"],
      "Old town": ["83822"],
      Deary: ["83823"],
      Desmet: ["83824"],
      Eastport: ["83826"],
      "Elk river": ["83827"],
      Fernwood: ["83830"],
      Genesee: ["83832"],
      Harrison: ["83833"],
      Harvard: ["83834"],
      "Hayden lake": ["83835"],
      Hope: ["83836"],
      Kellogg: ["83837"],
      Kingston: ["83839"],
      Laclede: ["83841"],
      Medimont: ["83842"],
      Moscow: ["83843"],
      "Moyie springs": ["83845"],
      Mullan: ["83846"],
      Naples: ["83847"],
      Nordman: ["83848"],
      Osburn: ["83849"],
      Pinehurst: ["83850"],
      Plummer: ["83851"],
      Ponderay: ["83852"],
      Porthill: ["83853"],
      "Post falls": ["83854"],
      Potlatch: ["83855"],
      "Priest river": ["83856"],
      Princeton: ["83857"],
      Rathdrum: ["83858"],
      Sagle: ["83860"],
      "Saint maries": ["83861"],
      Sandpoint: ["83864"],
      Santa: ["83866"],
      Silverton: ["83867"],
      Smelterville: ["83868"],
      "Spirit lake": ["83869"],
      Tensed: ["83870"],
      Troy: ["83871"],
      Viola: ["83872"],
      Wallace: ["83873"],
      Murray: ["83874"],
      Worley: ["83876"],
      "Zcta 838hh": ["838HH"],
      "Zcta 838xx": ["838XX"],
    },
  },
  {
    abbrevation: "IL",
    name: "Illinois",
    Cities: [
      "",
      "",
      "Antioch",
      "Arlington height",
      "Elk grove villag",
      "Rolling meadows",
      "Barrington",
      "Crystal lake",
      "Cary",
      "Deerfield",
      "Des plaines",
      "Rosemont",
      "Fox lake",
      "Fox river grove",
      "Glencoe",
      "Glenview",
      "Golf",
      "Gages lake",
      "Gurnee",
      "Harvard",
      "Hebron",
      "Highland park",
      "Fort sheridan",
      "Highwood",
      "Ingleside",
      "Island lake",
      "Kenilworth",
      "Lake bluff",
      "Lake forest",
      "Lindenhurst",
      "Long grove",
      "Libertyville",
      "Mc henry",
      "Morton grove",
      "Mount prospect",
      "Mundelein",
      "Vernon hills",
      "Northbrook",
      "Abbott park",
      "Palatine",
      "Park ridge",
      "Prairie view",
      "Prospect heights",
      "Richmond",
      "Ringwood",
      "Round lake",
      "Skokie",
      "Spring grove",
      "Wadsworth",
      "Wauconda",
      "Mc gaw park",
      "Waukegan",
      "Great lakes",
      "Buffalo grove",
      "Wheeling",
      "Wilmette",
      "Northfield",
      "Winthrop harbor",
      "Wonder lake",
      "Woodstock",
      "Zion",
      "Zcta 600hh",
      "Addison",
      "Lake in the hill",
      "Hanover park",
      "Bellwood",
      "Bensenville",
      "Streamwood",
      "Bloomingdale",
      "Burlington",
      "Carpentersville",
      "Clare",
      "Cortland",
      "Creston",
      "De kalb",
      "Dundee",
      "Elburn",
      "Elgin",
      "Elmhurst",
      "Esmond",
      "Forest park",
      "Franklin park",
      "Geneva",
      "Genoa",
      "Gilberts",
      "Glen ellyn",
      "Glendale heights",
      "Hampshire",
      "Hines",
      "Huntley",
      "Itasca",
      "Kingston",
      "Kirkland",
      "Lombard",
      "Malta",
      "Maple park",
      "Marengo",
      "Broadview",
      "Westchester",
      "Mellon financial",
      "Medinah",
      "Melrose park",
      "Hillside",
      "Northlake",
      "Stone park",
      "River grove",
      "Roselle",
      "Schaumburg",
      "Saint charles",
      "Schiller park",
      "South elgin",
      "Sycamore",
      "Union",
      "Villa park",
      "Wayne",
      "West chicago",
      "Wheaton",
      "Carol stream",
      "Winfield",
      "Wood dale",
      "Hoffman estates",
      "Zcta 601hh",
      "Evanston",
      "Oak park",
      "River forest",
      "Beecher",
      "Stickney",
      "Blue island",
      "Braceville",
      "Braidwood",
      "Calumet city",
      "Channahon",
      "Sauk village",
      "Chicago ridge",
      "Coal city",
      "Crete",
      "Dolton",
      "Dwight",
      "Elwood",
      "Flossmoor",
      "Frankfort",
      "Gardner",
      "Glenwood",
      "Markham",
      "Hazel crest",
      "Homewood",
      "Joliet",
      "Shorewood",
      "Rockdale",
      "Kinsman",
      "Lansing",
      "Argonne",
      "Bolingbrook",
      "Romeoville",
      "Manhattan",
      "Matteson",
      "Mazon",
      "Crestwood",
      "Minooka",
      "Mokena",
      "Monee",
      "Morris",
      "New lenox",
      "Oak forest",
      "Oak lawn",
      "Bridgeview",
      "Hometown",
      "Hickory hills",
      "Justice",
      "Burbank",
      "Odell",
      "Olympia fields",
      "Orland park",
      "Palos heights",
      "Palos park",
      "Palos hills",
      "University park",
      "Zcta 60467",
      "Peotone",
      "Posen",
      "Ransom",
      "Richton park",
      "Robbins",
      "South holland",
      "South wilmington",
      "Steger",
      "Thornton",
      "Tinley park",
      "Country club hil",
      "Verona",
      "Willow springs",
      "Custer park",
      "Worth",
      "Zcta 60490",
      "Zcta 604hh",
      "Argo",
      "Aurora",
      "Batavia",
      "Big rock",
      "Bristol",
      "Brookfield",
      "Clarendon hills",
      "Downers grove",
      "Woodridge",
      "Earlville",
      "Eola",
      "Hinckley",
      "Oak brook",
      "Zcta 60523",
      "Hodgkins",
      "World of beauty",
      "Lee",
      "Leland",
      "Lisle",
      "Lyons",
      "Millbrook",
      "Millington",
      "Montgomery",
      "Mooseheart",
      "Naperville",
      "Newark",
      "North aurora",
      "Oswego",
      "Plainfield",
      "Plano",
      "North riverside",
      "Sandwich",
      "Serena",
      "Shabbona",
      "Sheridan",
      "Somonauk",
      "Steward",
      "Sugar grove",
      "Warrenville",
      "Waterman",
      "Wedron",
      "Western springs",
      "Westmont",
      "Yorkville",
      "Darien",
      "Zcta 605hh",
      "Chicago",
      "Burnham",
      "Norridge",
      "Bedford park",
      "Calumet park",
      "Lincolnwood",
      "Merrionette park",
      "Harwood heights",
      "Zcta 606hh",
      "Zcta 60706",
      "Zcta 60707",
      "Zcta 60712",
      "Niles",
      "Zcta 60803",
      "Zcta 60804",
      "Zcta 60805",
      "Zcta 60827",
      "Zcta 608hh",
      "Kankakee",
      "Aroma park",
      "Ashkum",
      "Beaverville",
      "Bonfield",
      "Bourbonnais",
      "Bradley",
      "Buckingham",
      "Buckley",
      "Cabery",
      "Campus",
      "Chatsworth",
      "Chebanse",
      "Cissna park",
      "Claytonville",
      "Clifton",
      "Crescent city",
      "Cullom",
      "Danforth",
      "Donovan",
      "East lynn",
      "Elliott",
      "Emington",
      "Essex",
      "Gibson city",
      "Gilman",
      "Grant park",
      "Herscher",
      "Hoopeston",
      "Iroquois",
      "Kempton",
      "Loda",
      "Ludlow",
      "Manteno",
      "Martinton",
      "Melvin",
      "Milford",
      "Momence",
      "Onarga",
      "Paxton",
      "Piper city",
      "Rankin",
      "Reddick",
      "Roberts",
      "Rossville",
      "Saint anne",
      "Sheldon",
      "Stockland",
      "Thawville",
      "Union hill",
      "Watseka",
      "Wellington",
      "Woodland",
      "Zcta 609hh",
      "Apple river",
      "Ashton",
      "Baileyville",
      "Belvidere",
      "Byron",
      "Caledonia",
      "Capron",
      "Cedarville",
      "Chadwick",
      "Chana",
      "Cherry valley",
      "Dakota",
      "Davis",
      "Davis junction",
      "Dixon",
      "Durand",
      "East dubuque",
      "Elizabeth",
      "Forreston",
      "Franklin grove",
      "Freeport",
      "Galena",
      "Galt",
      "Garden prairie",
      "German valley",
      "Hanover",
      "Harmon",
      "Kent",
      "Lanark",
      "Egan",
      "Lena",
      "Lindenwood",
      "Mc connell",
      "Milledgeville",
      "Monroe center",
      "Mount carroll",
      "Mount morris",
      "Nachusa",
      "Nora",
      "Orangeville",
      "Oregon",
      "Pearl city",
      "Pecatonica",
      "Polo",
      "Poplar grove",
      "Ridott",
      "Rochelle",
      "Rock city",
      "Rock falls",
      "Rockton",
      "Roscoe",
      "Savanna",
      "Scales mound",
      "Seward",
      "Shannon",
      "South beloit",
      "Sterling",
      "Stillman valley",
      "Stockton",
      "Warren",
      "Winnebago",
      "Winslow",
      "Woosung",
      "Zcta 610hh",
      "Rockford",
      "Loves park",
      "Machesney park",
      "Zcta 611hh",
      "Rock island",
      "Albany",
      "Aledo",
      "Andalusia",
      "Andover",
      "Annawan",
      "Atkinson",
      "Barstow",
      "Cambridge",
      "Carbon cliff",
      "Coal valley",
      "Green rock",
      "Cordova",
      "Deer grove",
      "East moline",
      "Erie",
      "Fenton",
      "Fulton",
      "Geneseo",
      "Hampton",
      "Hillsdale",
      "Illinois city",
      "Joy",
      "Lyndon",
      "Lynn center",
      "Matherville",
      "Milan",
      "Moline",
      "Morrison",
      "New boston",
      "Orion",
      "Osco",
      "Port byron",
      "Preemption",
      "Prophetstown",
      "Reynolds",
      "Sherrard",
      "Silvis",
      "Tampico",
      "Taylor ridge",
      "Thomson",
      "Zcta 612hh",
      "La salle",
      "Amboy",
      "Ancona",
      "Arlington",
      "Blackstone",
      "Buda",
      "Bureau",
      "Cedar point",
      "Cherry",
      "Compton",
      "Manville",
      "Dalzell",
      "Dana",
      "Depue",
      "Dover",
      "Eldena",
      "Grand ridge",
      "Granville",
      "Hennepin",
      "Kasbeer",
      "Ladd",
      "La moille",
      "Lee center",
      "Leonore",
      "Long point",
      "Lostant",
      "Mc nabb",
      "Magnolia",
      "Malden",
      "Manlius",
      "Mark",
      "Marseilles",
      "Mendota",
      "Mineral",
      "Neponset",
      "New bedford",
      "Oglesby",
      "Ohio",
      "Ottawa",
      "Paw paw",
      "Peru",
      "Princeton",
      "Rutland",
      "Seatonville",
      "Seneca",
      "Sheffield",
      "Spring valley",
      "Standard",
      "Streator",
      "Sublette",
      "Tiskilwa",
      "Toluca",
      "Tonica",
      "Troy grove",
      "Utica",
      "Van orin",
      "Varna",
      "Normandy",
      "Wenona",
      "West brooklyn",
      "Wyanet",
      "Zcta 613hh",
      "Galesburg",
      "Abingdon",
      "Adair",
      "Alexis",
      "Alpha",
      "Altona",
      "Avon",
      "Bardolph",
      "Berwick",
      "Biggsville",
      "Bishop hill",
      "Blandinsville",
      "Bradford",
      "Bushnell",
      "Cameron",
      "Camp grove",
      "Carman",
      "Castleton",
      "Cuba",
      "Dahinda",
      "East galesburg",
      "Ellisville",
      "Fairview",
      "Galva",
      "Gerlaw",
      "Gilson",
      "Gladstone",
      "Good hope",
      "Henderson",
      "Industry",
      "Ipava",
      "Keithsburg",
      "Kewanee",
      "Kirkwood",
      "Knoxville",
      "La fayette",
      "La harpe",
      "Laura",
      "Littleton",
      "Little york",
      "Lomax",
      "Macomb",
      "Maquon",
      "Marietta",
      "Media",
      "Monmouth",
      "New windsor",
      "North henderson",
      "Oneida",
      "Opheim",
      "Oquawka",
      "Prairie city",
      "Raritan",
      "Rio",
      "Roseville",
      "Saint augustine",
      "Sciota",
      "Seaton",
      "Smithfield",
      "Smithshire",
      "Speer",
      "Stronghurst",
      "Table grove",
      "Toulon",
      "Vermont",
      "Victoria",
      "Viola",
      "Wataga",
      "Williamsfield",
      "Woodhull",
      "Wyoming",
      "Zcta 614hh",
      "Astoria",
      "Benson",
      "Brimfield",
      "Bryant",
      "Canton",
      "Chillicothe",
      "Dunfermline",
      "Dunlap",
      "Edelstein",
      "Edwards",
      "Elmwood",
      "Eureka",
      "Middlegrove",
      "Forest city",
      "Glasford",
      "Green valley",
      "Groveland",
      "Hanna city",
      "Henry",
      "Kingston mines",
      "Lacon",
      "La rose",
      "Lewistown",
      "Liverpool",
      "London mills",
      "Cazenovia",
      "Manito",
      "Mapleton",
      "Metamora",
      "Morton",
      "Mossville",
      "Pekin",
      "Princeville",
      "Putnam",
      "Roanoke",
      "Saint david",
      "South pekin",
      "Sparland",
      "Topeka",
      "Tremont",
      "Trivoli",
      "Washburn",
      "Sunnyland",
      "Yates city",
      "Zcta 615hh",
      "Peoria",
      "Peoria heights",
      "Bartonville",
      "Zcta 61610",
      "East peoria",
      "Zcta 616hh",
      "Bloomington",
      "Anchor",
      "Armington",
      "Arrowsmith",
      "Atlanta",
      "Bellflower",
      "Carlock",
      "Chenoa",
      "Clinton",
      "Colfax",
      "Congerville",
      "Cooksville",
      "Cropsey",
      "Danvers",
      "Deer creek",
      "Delavan",
      "Dewitt",
      "Holder",
      "Ellsworth",
      "El paso",
      "Fairbury",
      "Flanagan",
      "Forrest",
      "Goodfield",
      "Graymont",
      "Gridley",
      "Heyworth",
      "Hopedale",
      "Hudson",
      "Kenney",
      "Lane",
      "Le roy",
      "Lexington",
      "Mc lean",
      "Mackinaw",
      "Maroa",
      "Merna",
      "Minier",
      "Minonk",
      "Normal",
      "Pontiac",
      "Saunemin",
      "Saybrook",
      "Secor",
      "Shirley",
      "Sibley",
      "Stanford",
      "Strawn",
      "Towanda",
      "Wapella",
      "Waynesville",
      "Zcta 617hh",
      "Urbana",
      "Zcta 61802",
      "Allerton",
      "Alvin",
      "Armstrong",
      "Bement",
      "Bismarck",
      "Bondville",
      "Broadlands",
      "Catlin",
      "Cerro gordo",
      "Champaign",
      "Cisco",
      "Collison",
      "Danville",
      "Tilton",
      "De land",
      "Dewey",
      "Fairmount",
      "Farmer city",
      "Fisher",
      "Fithian",
      "Foosland",
      "Georgetown",
      "Gifford",
      "Henning",
      "Homer",
      "Indianola",
      "Ivesdale",
      "Longview",
      "Mahomet",
      "Mansfield",
      "Milmine",
      "Monticello",
      "Muncie",
      "Oakwood",
      "Ogden",
      "Penfield",
      "Pesotum",
      "Philo",
      "Potomac",
      "Rantoul",
      "Ridge farm",
      "Royal",
      "Sadorus",
      "Saint joseph",
      "Savoy",
      "Seymour",
      "Sidell",
      "Sidney",
      "Thomasboro",
      "Tolono",
      "Weldon",
      "Westville",
      "White heath",
      "Zcta 618hh",
      "Arcola",
      "Arthur",
      "Ashmore",
      "Atwood",
      "Bethany",
      "Brocton",
      "Camargo",
      "Charleston",
      "Chrisman",
      "Dalton city",
      "Gays",
      "Hammond",
      "Hindsboro",
      "Humboldt",
      "Hume",
      "Kansas",
      "La place",
      "Lovington",
      "Mattoon",
      "Metcalf",
      "Murdock",
      "Newman",
      "Oakland",
      "Paris",
      "Redmon",
      "Sullivan",
      "Tuscola",
      "Villa grove",
      "Windsor",
      "Zcta 619hh",
      "Alhambra",
      "Alton",
      "Batchtown",
      "Benld",
      "Bethalto",
      "Bingham",
      "Brighton",
      "Meppen",
      "Bunker hill",
      "Butler",
      "Carrollton",
      "Coffeen",
      "Cottage hills",
      "Donnellson",
      "Dorsey",
      "Dow",
      "Eagarville",
      "East alton",
      "Edwardsville",
      "Eldred",
      "Elsah",
      "Fidelity",
      "Fieldon",
      "Fillmore",
      "Dorchester",
      "Glen carbon",
      "Godfrey",
      "Golden eagle",
      "Grafton",
      "Mitchell",
      "Greenfield",
      "Hamburg",
      "Hamel",
      "Hardin",
      "Hartford",
      "Hillsboro",
      "Hillview",
      "Irving",
      "Jerseyville",
      "Kampsville",
      "Kane",
      "Litchfield",
      "Livingston",
      "Lovejoy",
      "Madison",
      "Marine",
      "Maryville",
      "Medora",
      "Michael",
      "Moro",
      "Mount olive",
      "Mozier",
      "New douglas",
      "Nokomis",
      "Panama",
      "Patterson",
      "Piasa",
      "Ramsey",
      "Rockbridge",
      "Roodhouse",
      "Rosamond",
      "Roxana",
      "Sawyerville",
      "Sorento",
      "South roxana",
      "Staunton",
      "Taylor springs",
      "Venice",
      "Walshville",
      "White hall",
      "Wilsonville",
      "Witt",
      "Wood river",
      "Worden",
      "Zcta 620hh",
      "Sauget",
      "East saint louis",
      "Washington park",
      "Cahokia",
      "Alorton",
      "Fairview heights",
      "Venedy",
      "Albers",
      "Aviston",
      "Baldwin",
      "Bartelso",
      "Beckemeyer",
      "Belleville",
      "Scott a f b",
      "Zcta 62226",
      "Breese",
      "Carlyle",
      "Caseyville",
      "Chester",
      "Collinsville",
      "Columbia",
      "Swanwick",
      "Cutler",
      "Dupo",
      "East carondelet",
      "Ellis grove",
      "Evansville",
      "Freeburg",
      "Fults",
      "Germantown",
      "Greenville",
      "Highland",
      "Hoffman",
      "Keyesport",
      "Lebanon",
      "Lenzburg",
      "Marissa",
      "Mascoutah",
      "Millstadt",
      "Modoc",
      "Mulberry grove",
      "Nashville",
      "New athens",
      "New baden",
      "New memphis",
      "Oakdale",
      "Shiloh",
      "Okawville",
      "Percy",
      "Pierron",
      "Pinckneyville",
      "Pocahontas",
      "Prairie du roche",
      "Red bud",
      "Renault",
      "Rockwood",
      "Saint jacob",
      "Shattuc",
      "Smithboro",
      "Smithton",
      "Sparta",
      "Steeleville",
      "Summerfield",
      "Tilden",
      "Trenton",
      "Troy",
      "Valmeyer",
      "Walsh",
      "Waterloo",
      "Zcta 622hh",
      "Quincy",
      "Augusta",
      "Barry",
      "Basco",
      "Baylis",
      "Bowen",
      "Burnside",
      "Camden",
      "Camp point",
      "Carthage",
      "Chambersburg",
      "Clayton",
      "Coatsburg",
      "Colchester",
      "Pontoosuc",
      "Elvaston",
      "Ferris",
      "Fowler",
      "Golden",
      "Griggsville",
      "Hamilton",
      "Hull",
      "Huntsville",
      "Kinderhook",
      "La prairie",
      "Liberty",
      "Lima",
      "Loraine",
      "Mendon",
      "Milton",
      "Mount sterling",
      "Nauvoo",
      "Nebo",
      "New canton",
      "New salem",
      "Niota",
      "Paloma",
      "Payson",
      "Pearl",
      "Perry",
      "Pittsfield",
      "Plainville",
      "Pleasant hill",
      "Colmar",
      "Rockport",
      "Sutter",
      "Tennessee",
      "Timewell",
      "Ursa",
      "Versailles",
      "Warsaw",
      "West point",
      "Zcta 623hh",
      "Effingham",
      "Allendale",
      "Altamont",
      "Annapolis",
      "Beecher city",
      "Bridgeport",
      "Brownstown",
      "Calhoun",
      "Casey",
      "Claremont",
      "Cowden",
      "Dennison",
      "Dieterich",
      "Dundas",
      "Laclede",
      "Flat rock",
      "Hazel dell",
      "Herrick",
      "Hidalgo",
      "Hutsonville",
      "Ingraham",
      "Jewett",
      "Lakewood",
      "Lawrenceville",
      "Lerna",
      "Marshall",
      "Martinsville",
      "Mason",
      "Mode",
      "Montrose",
      "Mount erie",
      "Neoga",
      "Newton",
      "Oblong",
      "Olney",
      "Palestine",
      "Parkersburg",
      "Robinson",
      "Saint elmo",
      "Sainte marie",
      "Saint francisvil",
      "Shumway",
      "Sigel",
      "Stewardson",
      "Strasburg",
      "Sumner",
      "Teutopolis",
      "Toledo",
      "Trilla",
      "Vandalia",
      "Watson",
      "Westfield",
      "West liberty",
      "West salem",
      "West union",
      "West york",
      "Wheeler",
      "Willow hill",
      "Yale",
      "Zcta 624hh",
      "Newburg",
      "Assumption",
      "Atwater",
      "Beason",
      "Blue mound",
      "Boody",
      "Buffalo hart",
      "Bulpitt",
      "Chestnut",
      "Cornland",
      "Dawson",
      "Decatur",
      "Bearsdale",
      "Cimic",
      "Edinburg",
      "Elwin",
      "Thomasville",
      "Brunswick",
      "Forsyth",
      "Glenarm",
      "Harristown",
      "Harvel",
      "Illiopolis",
      "Kincaid",
      "Lake fork",
      "Latham",
      "Macon",
      "Bolivia",
      "Morrisonville",
      "Mount auburn",
      "Mount pulaski",
      "Hervey city",
      "Radford",
      "Niantic",
      "Casner",
      "Oconee",
      "Oreana",
      "Owaneco",
      "Clarksdale",
      "Dunkel",
      "Sicily",
      "Raymond",
      "Spaulding",
      "Berry",
      "Clarksburg",
      "Stonington",
      "Hewittsville",
      "Tovey",
      "Dollville",
      "Waggoner",
      "Heman",
      "Zcta 625hh",
      "Orleans",
      "Alsey",
      "Arenzville",
      "Newmansville",
      "Fancy prairie",
      "Auburn",
      "Lynchburg",
      "Beardstown",
      "Exeter",
      "Bader",
      "Cantrall",
      "Comer",
      "Panther creek",
      "Chapin",
      "Chatham",
      "Hagaman",
      "Concord",
      "Biggs",
      "Broadwell",
      "Emden",
      "Clements",
      "Frederick",
      "Mcvey",
      "Hubly",
      "Hartsburg",
      "Eckard",
      "Hettick",
      "Arcadia",
      "Kilbourne",
      "Lincoln",
      "Loami",
      "Manchester",
      "Luther",
      "Naples",
      "Middletown",
      "Modesto",
      "Nortonville",
      "Bates",
      "New holland",
      "Nilwood",
      "Oakford",
      "Barr",
      "Atterbury",
      "Farmingdale",
      "Layton",
      "Allen",
      "Barclay",
      "Royal lakes",
      "South standard",
      "Tallula",
      "Thayer",
      "Virden",
      "Little indian",
      "Waverly",
      "Williamsville",
      "Glasgow",
      "Woodson",
      "Zcta 626hh",
      "Springfield",
      "Grandview",
      "Southern view",
      "Jerome",
      "Andrew",
      "Zcta 627hh",
      "Centralia",
      "Hoyleton",
      "Albion",
      "Alma",
      "Ashley",
      "Barnhill",
      "Belle rive",
      "Bellmont",
      "Benton",
      "Bluford",
      "Bone gap",
      "Bonnie",
      "Broughton",
      "Browns",
      "Buckner",
      "Burnt prairie",
      "Carmi",
      "Christopher",
      "Cisne",
      "Clay city",
      "Coello",
      "Crossville",
      "Dahlgren",
      "Dale",
      "Dix",
      "Du bois",
      "Du quoin",
      "Ellery",
      "Enfield",
      "Ewing",
      "Fairfield",
      "Farina",
      "Flora",
      "Geff",
      "Golden gate",
      "Grayville",
      "Ina",
      "Irvington",
      "Iuka",
      "Johnsonville",
      "Keenes",
      "Keensburg",
      "Kell",
      "Kinmundy",
      "Loogootee",
      "Bible grove",
      "Mc leansboro",
      "Macedonia",
      "Maunie",
      "Mill shoals",
      "Mount carmel",
      "Mount vernon",
      "Mulkeytown",
      "New haven",
      "Noble",
      "Norris city",
      "Odin",
      "Omaha",
      "Opdyke",
      "Orient",
      "Patoka",
      "Radom",
      "Richview",
      "Rinard",
      "Sailor springs",
      "Saint peter",
      "Salem",
      "Sandoval",
      "Scheller",
      "Sesser",
      "Shobonier",
      "Sims",
      "Springerton",
      "Tamaroa",
      "Texico",
      "Thompsonville",
      "Valier",
      "Vernon",
      "Walnut hill",
      "Waltonville",
      "Wayne city",
      "West frankfort",
      "Whittington",
      "Woodlawn",
      "Xenia",
      "Zcta 628hh",
      "Zcta 628xx",
      "Carbondale",
      "Alto pass",
      "Anna",
      "Ava",
      "Belknap",
      "New liberty",
      "Buncombe",
      "Cairo",
      "Cambria",
      "Campbell hill",
      "Carrier mills",
      "Carterville",
      "Cave in rock",
      "Cobden",
      "Colp",
      "Creal springs",
      "Cypress",
      "De soto",
      "Dongola",
      "Dowell",
      "Eddyville",
      "Eldorado",
      "Elizabethtown",
      "Elkville",
      "Energy",
      "Equality",
      "Galatia",
      "Brownfield",
      "Goreville",
      "Gorham",
      "Grand chain",
      "Grand tower",
      "Grantsburg",
      "Harrisburg",
      "Herod",
      "Herrin",
      "Hurst",
      "Jacob",
      "Johnston city",
      "Jonesboro",
      "Joppa",
      "Junction",
      "Karnak",
      "Mc clure",
      "Makanda",
      "Marion",
      "Metropolis",
      "Miller city",
      "Mound city",
      "Mounds",
      "Murphysboro",
      "New burnside",
      "Olive branch",
      "Olmsted",
      "Ozark",
      "Pittsburg",
      "Pomona",
      "Pulaski",
      "Raleigh",
      "Ridgway",
      "Rosiclare",
      "Royalton",
      "Shawneetown",
      "Robbs",
      "Stonefort",
      "Tamms",
      "Gale",
      "Tunnel hill",
      "Ullin",
      "Unity",
      "Vergennes",
      "Vienna",
      "Villa ridge",
      "Willisville",
      "Wolf lake",
      "Zeigler",
      "Zcta 629hh",
    ],
    ZipCodes: {
      "": ["52761"],
      Antioch: ["60002"],
      "Arlington height": ["60004", "60005"],
      "Elk grove villag": ["60007"],
      "Rolling meadows": ["60008"],
      Barrington: ["60010"],
      "Crystal lake": ["60012", "60014"],
      Cary: ["60013"],
      Deerfield: ["60015"],
      "Des plaines": ["60016"],
      Rosemont: ["60018"],
      "Fox lake": ["60020"],
      "Fox river grove": ["60021"],
      Glencoe: ["60022"],
      Glenview: ["60025"],
      Golf: ["60029"],
      "Gages lake": ["60030"],
      Gurnee: ["60031"],
      Harvard: ["60033"],
      Hebron: ["60034"],
      "Highland park": ["60035"],
      "Fort sheridan": ["60037"],
      Highwood: ["60040"],
      Ingleside: ["60041"],
      "Island lake": ["60042"],
      Kenilworth: ["60043"],
      "Lake bluff": ["60044"],
      "Lake forest": ["60045"],
      Lindenhurst: ["60046"],
      "Long grove": ["60047"],
      Libertyville: ["60048"],
      "Mc henry": ["60050"],
      "Morton grove": ["60053"],
      "Mount prospect": ["60056"],
      Mundelein: ["60060"],
      "Vernon hills": ["60061"],
      Northbrook: ["60062"],
      "Abbott park": ["60064"],
      Palatine: ["60067", "60074"],
      "Park ridge": ["60068"],
      "Prairie view": ["60069"],
      "Prospect heights": ["60070"],
      Richmond: ["60071"],
      Ringwood: ["60072"],
      "Round lake": ["60073"],
      Skokie: ["60076", "60077"],
      "Spring grove": ["60081"],
      Wadsworth: ["60083"],
      Wauconda: ["60084"],
      "Mc gaw park": ["60085"],
      Waukegan: ["60087"],
      "Great lakes": ["60088"],
      "Buffalo grove": ["60089"],
      Wheeling: ["60090"],
      Wilmette: ["60091"],
      Northfield: ["60093"],
      "Winthrop harbor": ["60096"],
      "Wonder lake": ["60097"],
      Woodstock: ["60098"],
      Zion: ["60099"],
      "Zcta 600hh": ["600HH"],
      Addison: ["60101"],
      "Lake in the hill": ["60102"],
      "Hanover park": ["60103"],
      Bellwood: ["60104"],
      Bensenville: ["60106"],
      Streamwood: ["60107"],
      Bloomingdale: ["60108"],
      Burlington: ["60109"],
      Carpentersville: ["60110"],
      Clare: ["60111"],
      Cortland: ["60112"],
      Creston: ["60113"],
      "De kalb": ["60115"],
      Dundee: ["60118"],
      Elburn: ["60119"],
      Elgin: ["60120", "60123"],
      Elmhurst: ["60126"],
      Esmond: ["60129"],
      "Forest park": ["60130"],
      "Franklin park": ["60131"],
      Geneva: ["60134"],
      Genoa: ["60135"],
      Gilberts: ["60136"],
      "Glen ellyn": ["60137"],
      "Glendale heights": ["60139"],
      Hampshire: ["60140"],
      Hines: ["60141"],
      Huntley: ["60142"],
      Itasca: ["60143"],
      Kingston: ["60145"],
      Kirkland: ["60146"],
      Lombard: ["60148"],
      Malta: ["60150"],
      "Maple park": ["60151"],
      Marengo: ["60152"],
      Broadview: ["60153"],
      Westchester: ["60154"],
      "Mellon financial": ["60155"],
      Medinah: ["60157"],
      "Melrose park": ["60160"],
      Hillside: ["60162", "60163"],
      Northlake: ["60164"],
      "Stone park": ["60165"],
      "River grove": ["60171"],
      Roselle: ["60172"],
      Schaumburg: ["60173", "60193"],
      "Saint charles": ["60174", "60175"],
      "Schiller park": ["60176"],
      "South elgin": ["60177"],
      Sycamore: ["60178"],
      Union: ["60180"],
      "Villa park": ["60181"],
      Wayne: ["60184"],
      "West chicago": ["60185"],
      Wheaton: ["60187"],
      "Carol stream": ["60188"],
      Winfield: ["60190"],
      "Wood dale": ["60191"],
      "Hoffman estates": ["60192", "60194", "60195"],
      "Zcta 601hh": ["601HH"],
      Evanston: ["60201", "60202", "60203"],
      "Oak park": ["60301", "60302", "60304"],
      "River forest": ["60305"],
      Beecher: ["60401"],
      Stickney: ["60402"],
      "Blue island": ["60406"],
      Braceville: ["60407"],
      Braidwood: ["60408"],
      "Calumet city": ["60409"],
      Channahon: ["60410"],
      "Sauk village": ["60411"],
      "Chicago ridge": ["60415"],
      "Coal city": ["60416"],
      Crete: ["60417"],
      Dolton: ["60419"],
      Dwight: ["60420"],
      Elwood: ["60421"],
      Flossmoor: ["60422"],
      Frankfort: ["60423"],
      Gardner: ["60424"],
      Glenwood: ["60425"],
      Markham: ["60426"],
      "Hazel crest": ["60429"],
      Homewood: ["60430"],
      Joliet: ["60431", "60432", "60433"],
      Shorewood: ["60435"],
      Rockdale: ["60436"],
      Kinsman: ["60437"],
      Lansing: ["60438"],
      Argonne: ["60439"],
      Bolingbrook: ["60440"],
      Romeoville: ["60441", "60446"],
      Manhattan: ["60442"],
      Matteson: ["60443"],
      Mazon: ["60444"],
      Crestwood: ["60445"],
      Minooka: ["60447"],
      Mokena: ["60448"],
      Monee: ["60449"],
      Morris: ["60450"],
      "New lenox": ["60451"],
      "Oak forest": ["60452"],
      "Oak lawn": ["60453"],
      Bridgeview: ["60455"],
      Hometown: ["60456"],
      "Hickory hills": ["60457"],
      Justice: ["60458"],
      Burbank: ["60459"],
      Odell: ["60460"],
      "Olympia fields": ["60461"],
      "Orland park": ["60462"],
      "Palos heights": ["60463"],
      "Palos park": ["60464"],
      "Palos hills": ["60465"],
      "University park": ["60466"],
      "Zcta 60467": ["60467"],
      Peotone: ["60468"],
      Posen: ["60469"],
      Ransom: ["60470"],
      "Richton park": ["60471"],
      Robbins: ["60472"],
      "South holland": ["60473"],
      "South wilmington": ["60474"],
      Steger: ["60475"],
      Thornton: ["60476"],
      "Tinley park": ["60477"],
      "Country club hil": ["60478"],
      Verona: ["60479"],
      "Willow springs": ["60480"],
      "Custer park": ["60481"],
      Worth: ["60482"],
      "Zcta 60490": ["60490"],
      "Zcta 604hh": ["604HH"],
      Argo: ["60501"],
      Aurora: ["60504", "60505", "60506"],
      Batavia: ["60510"],
      "Big rock": ["60511"],
      Bristol: ["60512"],
      Brookfield: ["60513"],
      "Clarendon hills": ["60514"],
      "Downers grove": ["60515", "60516"],
      Woodridge: ["60517"],
      Earlville: ["60518"],
      Eola: ["60519"],
      Hinckley: ["60520"],
      "Oak brook": ["60521"],
      "Zcta 60523": ["60523"],
      Hodgkins: ["60525"],
      "World of beauty": ["60526"],
      Lee: ["60530"],
      Leland: ["60531"],
      Lisle: ["60532"],
      Lyons: ["60534"],
      Millbrook: ["60536"],
      Millington: ["60537"],
      Montgomery: ["60538"],
      Mooseheart: ["60539"],
      Naperville: ["60540", "60563", "60564", "60565"],
      Newark: ["60541"],
      "North aurora": ["60542"],
      Oswego: ["60543"],
      Plainfield: ["60544"],
      Plano: ["60545"],
      "North riverside": ["60546"],
      Sandwich: ["60548"],
      Serena: ["60549"],
      Shabbona: ["60550"],
      Sheridan: ["60551"],
      Somonauk: ["60552"],
      Steward: ["60553"],
      "Sugar grove": ["60554"],
      Warrenville: ["60555"],
      Waterman: ["60556"],
      Wedron: ["60557"],
      "Western springs": ["60558"],
      Westmont: ["60559"],
      Yorkville: ["60560"],
      Darien: ["60561"],
      "Zcta 605hh": ["605HH"],
      Chicago: [
        "60601",
        "60602",
        "60603",
        "60604",
        "60605",
        "60606",
        "60607",
        "60608",
        "60609",
        "60610",
        "60611",
        "60612",
        "60613",
        "60614",
        "60615",
        "60616",
        "60617",
        "60618",
        "60619",
        "60620",
        "60621",
        "60622",
        "60623",
        "60624",
        "60625",
        "60626",
        "60628",
        "60629",
        "60630",
        "60631",
        "60632",
        "60636",
        "60637",
        "60639",
        "60640",
        "60641",
        "60644",
        "60647",
        "60649",
        "60651",
        "60652",
        "60653",
        "60654",
        "60657",
        "60660",
        "60661",
      ],
      Burnham: ["60633"],
      Norridge: ["60634"],
      "Bedford park": ["60638"],
      "Calumet park": ["60643"],
      Lincolnwood: ["60645", "60646", "60659"],
      "Merrionette park": ["60655"],
      "Harwood heights": ["60656"],
      "Zcta 606hh": ["606HH"],
      "Zcta 60706": ["60706"],
      "Zcta 60707": ["60707"],
      "Zcta 60712": ["60712"],
      Niles: ["60714"],
      "Zcta 60803": ["60803"],
      "Zcta 60804": ["60804"],
      "Zcta 60805": ["60805"],
      "Zcta 60827": ["60827"],
      "Zcta 608hh": ["608HH"],
      Kankakee: ["60901"],
      "Aroma park": ["60910"],
      Ashkum: ["60911"],
      Beaverville: ["60912"],
      Bonfield: ["60913"],
      Bourbonnais: ["60914"],
      Bradley: ["60915"],
      Buckingham: ["60917"],
      Buckley: ["60918"],
      Cabery: ["60919"],
      Campus: ["60920"],
      Chatsworth: ["60921"],
      Chebanse: ["60922"],
      "Cissna park": ["60924"],
      Claytonville: ["60926"],
      Clifton: ["60927"],
      "Crescent city": ["60928"],
      Cullom: ["60929"],
      Danforth: ["60930"],
      Donovan: ["60931"],
      "East lynn": ["60932"],
      Elliott: ["60933"],
      Emington: ["60934"],
      Essex: ["60935"],
      "Gibson city": ["60936"],
      Gilman: ["60938"],
      "Grant park": ["60940"],
      Herscher: ["60941"],
      Hoopeston: ["60942"],
      Iroquois: ["60945"],
      Kempton: ["60946"],
      Loda: ["60948"],
      Ludlow: ["60949"],
      Manteno: ["60950"],
      Martinton: ["60951"],
      Melvin: ["60952"],
      Milford: ["60953"],
      Momence: ["60954"],
      Onarga: ["60955"],
      Paxton: ["60957"],
      "Piper city": ["60959"],
      Rankin: ["60960"],
      Reddick: ["60961"],
      Roberts: ["60962"],
      Rossville: ["60963"],
      "Saint anne": ["60964"],
      Sheldon: ["60966"],
      Stockland: ["60967"],
      Thawville: ["60968"],
      "Union hill": ["60969"],
      Watseka: ["60970"],
      Wellington: ["60973"],
      Woodland: ["60974"],
      "Zcta 609hh": ["609HH"],
      "Apple river": ["61001"],
      Ashton: ["61006"],
      Baileyville: ["61007"],
      Belvidere: ["61008"],
      Byron: ["61010"],
      Caledonia: ["61011"],
      Capron: ["61012"],
      Cedarville: ["61013"],
      Chadwick: ["61014"],
      Chana: ["61015"],
      "Cherry valley": ["61016"],
      Dakota: ["61018"],
      Davis: ["61019"],
      "Davis junction": ["61020"],
      Dixon: ["61021"],
      Durand: ["61024"],
      "East dubuque": ["61025"],
      Elizabeth: ["61028"],
      Forreston: ["61030"],
      "Franklin grove": ["61031"],
      Freeport: ["61032"],
      Galena: ["61036"],
      Galt: ["61037"],
      "Garden prairie": ["61038"],
      "German valley": ["61039"],
      Hanover: ["61041"],
      Harmon: ["61042"],
      Kent: ["61044"],
      Lanark: ["61046"],
      Egan: ["61047"],
      Lena: ["61048"],
      Lindenwood: ["61049"],
      "Mc connell": ["61050"],
      Milledgeville: ["61051"],
      "Monroe center": ["61052"],
      "Mount carroll": ["61053"],
      "Mount morris": ["61054"],
      Nachusa: ["61057"],
      Nora: ["61059"],
      Orangeville: ["61060"],
      Oregon: ["61061"],
      "Pearl city": ["61062"],
      Pecatonica: ["61063"],
      Polo: ["61064"],
      "Poplar grove": ["61065"],
      Ridott: ["61067"],
      Rochelle: ["61068"],
      "Rock city": ["61070"],
      "Rock falls": ["61071"],
      Rockton: ["61072"],
      Roscoe: ["61073"],
      Savanna: ["61074"],
      "Scales mound": ["61075"],
      Seward: ["61077"],
      Shannon: ["61078"],
      "South beloit": ["61080"],
      Sterling: ["61081"],
      "Stillman valley": ["61084"],
      Stockton: ["61085"],
      Warren: ["61087"],
      Winnebago: ["61088"],
      Winslow: ["61089"],
      Woosung: ["61091"],
      "Zcta 610hh": ["610HH"],
      Rockford: [
        "61101",
        "61102",
        "61103",
        "61104",
        "61107",
        "61108",
        "61109",
        "61112",
        "61114",
      ],
      "Loves park": ["61111"],
      "Machesney park": ["61115"],
      "Zcta 611hh": ["611HH"],
      "Rock island": ["61201"],
      Albany: ["61230"],
      Aledo: ["61231"],
      Andalusia: ["61232"],
      Andover: ["61233"],
      Annawan: ["61234"],
      Atkinson: ["61235"],
      Barstow: ["61236"],
      Cambridge: ["61238"],
      "Carbon cliff": ["61239"],
      "Coal valley": ["61240"],
      "Green rock": ["61241"],
      Cordova: ["61242"],
      "Deer grove": ["61243"],
      "East moline": ["61244"],
      Erie: ["61250"],
      Fenton: ["61251"],
      Fulton: ["61252"],
      Geneseo: ["61254"],
      Hampton: ["61256"],
      Hillsdale: ["61257"],
      "Illinois city": ["61259"],
      Joy: ["61260"],
      Lyndon: ["61261"],
      "Lynn center": ["61262"],
      Matherville: ["61263"],
      Milan: ["61264"],
      Moline: ["61265"],
      Morrison: ["61270"],
      "New boston": ["61272"],
      Orion: ["61273"],
      Osco: ["61274"],
      "Port byron": ["61275"],
      Preemption: ["61276"],
      Prophetstown: ["61277"],
      Reynolds: ["61279"],
      Sherrard: ["61281"],
      Silvis: ["61282"],
      Tampico: ["61283"],
      "Taylor ridge": ["61284"],
      Thomson: ["61285"],
      "Zcta 612hh": ["612HH"],
      "La salle": ["61301"],
      Amboy: ["61310"],
      Ancona: ["61311"],
      Arlington: ["61312"],
      Blackstone: ["61313"],
      Buda: ["61314"],
      Bureau: ["61315"],
      "Cedar point": ["61316"],
      Cherry: ["61317"],
      Compton: ["61318"],
      Manville: ["61319"],
      Dalzell: ["61320"],
      Dana: ["61321"],
      Depue: ["61322"],
      Dover: ["61323"],
      Eldena: ["61324"],
      "Grand ridge": ["61325"],
      Granville: ["61326"],
      Hennepin: ["61327"],
      Kasbeer: ["61328"],
      Ladd: ["61329"],
      "La moille": ["61330"],
      "Lee center": ["61331"],
      Leonore: ["61332"],
      "Long point": ["61333"],
      Lostant: ["61334"],
      "Mc nabb": ["61335"],
      Magnolia: ["61336"],
      Malden: ["61337"],
      Manlius: ["61338"],
      Mark: ["61340"],
      Marseilles: ["61341"],
      Mendota: ["61342"],
      Mineral: ["61344"],
      Neponset: ["61345"],
      "New bedford": ["61346"],
      Oglesby: ["61348"],
      Ohio: ["61349"],
      Ottawa: ["61350"],
      "Paw paw": ["61353"],
      Peru: ["61354"],
      Princeton: ["61356"],
      Rutland: ["61358"],
      Seatonville: ["61359"],
      Seneca: ["61360"],
      Sheffield: ["61361"],
      "Spring valley": ["61362"],
      Standard: ["61363"],
      Streator: ["61364"],
      Sublette: ["61367"],
      Tiskilwa: ["61368"],
      Toluca: ["61369"],
      Tonica: ["61370"],
      "Troy grove": ["61372"],
      Utica: ["61373"],
      "Van orin": ["61374"],
      Varna: ["61375"],
      Normandy: ["61376"],
      Wenona: ["61377"],
      "West brooklyn": ["61378"],
      Wyanet: ["61379"],
      "Zcta 613hh": ["613HH"],
      Galesburg: ["61401"],
      Abingdon: ["61410"],
      Adair: ["61411"],
      Alexis: ["61412"],
      Alpha: ["61413"],
      Altona: ["61414"],
      Avon: ["61415"],
      Bardolph: ["61416"],
      Berwick: ["61417"],
      Biggsville: ["61418"],
      "Bishop hill": ["61419"],
      Blandinsville: ["61420"],
      Bradford: ["61421"],
      Bushnell: ["61422"],
      Cameron: ["61423"],
      "Camp grove": ["61424"],
      Carman: ["61425"],
      Castleton: ["61426"],
      Cuba: ["61427"],
      Dahinda: ["61428"],
      "East galesburg": ["61430"],
      Ellisville: ["61431"],
      Fairview: ["61432"],
      Galva: ["61434"],
      Gerlaw: ["61435"],
      Gilson: ["61436"],
      Gladstone: ["61437"],
      "Good hope": ["61438"],
      Henderson: ["61439"],
      Industry: ["61440"],
      Ipava: ["61441"],
      Keithsburg: ["61442"],
      Kewanee: ["61443"],
      Kirkwood: ["61447"],
      Knoxville: ["61448"],
      "La fayette": ["61449"],
      "La harpe": ["61450"],
      Laura: ["61451"],
      Littleton: ["61452"],
      "Little york": ["61453"],
      Lomax: ["61454"],
      Macomb: ["61455"],
      Maquon: ["61458"],
      Marietta: ["61459"],
      Media: ["61460"],
      Monmouth: ["61462"],
      "New windsor": ["61465"],
      "North henderson": ["61466"],
      Oneida: ["61467"],
      Opheim: ["61468"],
      Oquawka: ["61469"],
      "Prairie city": ["61470"],
      Raritan: ["61471"],
      Rio: ["61472"],
      Roseville: ["61473"],
      "Saint augustine": ["61474"],
      Sciota: ["61475"],
      Seaton: ["61476"],
      Smithfield: ["61477"],
      Smithshire: ["61478"],
      Speer: ["61479"],
      Stronghurst: ["61480"],
      "Table grove": ["61482"],
      Toulon: ["61483"],
      Vermont: ["61484"],
      Victoria: ["61485"],
      Viola: ["61486"],
      Wataga: ["61488"],
      Williamsfield: ["61489"],
      Woodhull: ["61490"],
      Wyoming: ["61491"],
      "Zcta 614hh": ["614HH"],
      Astoria: ["61501"],
      Benson: ["61516"],
      Brimfield: ["61517"],
      Bryant: ["61519"],
      Canton: ["61520"],
      Chillicothe: ["61523"],
      Dunfermline: ["61524"],
      Dunlap: ["61525"],
      Edelstein: ["61526"],
      Edwards: ["61528"],
      Elmwood: ["61529"],
      Eureka: ["61530"],
      Middlegrove: ["61531"],
      "Forest city": ["61532"],
      Glasford: ["61533"],
      "Green valley": ["61534"],
      Groveland: ["61535"],
      "Hanna city": ["61536"],
      Henry: ["61537"],
      "Kingston mines": ["61539"],
      Lacon: ["61540"],
      "La rose": ["61541"],
      Lewistown: ["61542"],
      Liverpool: ["61543"],
      "London mills": ["61544"],
      Cazenovia: ["61545"],
      Manito: ["61546"],
      Mapleton: ["61547"],
      Metamora: ["61548"],
      Morton: ["61550"],
      Mossville: ["61552"],
      Pekin: ["61554"],
      Princeville: ["61559"],
      Putnam: ["61560"],
      Roanoke: ["61561"],
      "Saint david": ["61563"],
      "South pekin": ["61564"],
      Sparland: ["61565"],
      Topeka: ["61567"],
      Tremont: ["61568"],
      Trivoli: ["61569"],
      Washburn: ["61570"],
      Sunnyland: ["61571"],
      "Yates city": ["61572"],
      "Zcta 615hh": ["615HH"],
      Peoria: ["61602", "61604", "61605", "61606", "61615"],
      "Peoria heights": ["61603", "61614"],
      Bartonville: ["61607"],
      "Zcta 61610": ["61610"],
      "East peoria": ["61611"],
      "Zcta 616hh": ["616HH"],
      Bloomington: ["61701", "61704"],
      Anchor: ["61720"],
      Armington: ["61721"],
      Arrowsmith: ["61722"],
      Atlanta: ["61723"],
      Bellflower: ["61724"],
      Carlock: ["61725"],
      Chenoa: ["61726"],
      Clinton: ["61727"],
      Colfax: ["61728"],
      Congerville: ["61729"],
      Cooksville: ["61730"],
      Cropsey: ["61731"],
      Danvers: ["61732"],
      "Deer creek": ["61733"],
      Delavan: ["61734"],
      Dewitt: ["61735"],
      Holder: ["61736"],
      Ellsworth: ["61737"],
      "El paso": ["61738"],
      Fairbury: ["61739"],
      Flanagan: ["61740"],
      Forrest: ["61741"],
      Goodfield: ["61742"],
      Graymont: ["61743"],
      Gridley: ["61744"],
      Heyworth: ["61745"],
      Hopedale: ["61747"],
      Hudson: ["61748"],
      Kenney: ["61749"],
      Lane: ["61750"],
      "Le roy": ["61752"],
      Lexington: ["61753"],
      "Mc lean": ["61754"],
      Mackinaw: ["61755"],
      Maroa: ["61756"],
      Merna: ["61758"],
      Minier: ["61759"],
      Minonk: ["61760"],
      Normal: ["61761"],
      Pontiac: ["61764"],
      Saunemin: ["61769"],
      Saybrook: ["61770"],
      Secor: ["61771"],
      Shirley: ["61772"],
      Sibley: ["61773"],
      Stanford: ["61774"],
      Strawn: ["61775"],
      Towanda: ["61776"],
      Wapella: ["61777"],
      Waynesville: ["61778"],
      "Zcta 617hh": ["617HH"],
      Urbana: ["61801"],
      "Zcta 61802": ["61802"],
      Allerton: ["61810"],
      Alvin: ["61811"],
      Armstrong: ["61812"],
      Bement: ["61813"],
      Bismarck: ["61814"],
      Bondville: ["61815"],
      Broadlands: ["61816"],
      Catlin: ["61817"],
      "Cerro gordo": ["61818"],
      Champaign: ["61820", "61821", "61822"],
      Cisco: ["61830"],
      Collison: ["61831"],
      Danville: ["61832", "61834"],
      Tilton: ["61833"],
      "De land": ["61839"],
      Dewey: ["61840"],
      Fairmount: ["61841"],
      "Farmer city": ["61842"],
      Fisher: ["61843"],
      Fithian: ["61844"],
      Foosland: ["61845"],
      Georgetown: ["61846"],
      Gifford: ["61847"],
      Henning: ["61848"],
      Homer: ["61849"],
      Indianola: ["61850"],
      Ivesdale: ["61851"],
      Longview: ["61852"],
      Mahomet: ["61853"],
      Mansfield: ["61854"],
      Milmine: ["61855"],
      Monticello: ["61856"],
      Muncie: ["61857"],
      Oakwood: ["61858"],
      Ogden: ["61859"],
      Penfield: ["61862"],
      Pesotum: ["61863"],
      Philo: ["61864"],
      Potomac: ["61865"],
      Rantoul: ["61866"],
      "Ridge farm": ["61870"],
      Royal: ["61871"],
      Sadorus: ["61872"],
      "Saint joseph": ["61873"],
      Savoy: ["61874"],
      Seymour: ["61875"],
      Sidell: ["61876"],
      Sidney: ["61877"],
      Thomasboro: ["61878"],
      Tolono: ["61880"],
      Weldon: ["61882"],
      Westville: ["61883"],
      "White heath": ["61884"],
      "Zcta 618hh": ["618HH"],
      Arcola: ["61910"],
      Arthur: ["61911"],
      Ashmore: ["61912"],
      Atwood: ["61913"],
      Bethany: ["61914"],
      Brocton: ["61917"],
      Camargo: ["61919"],
      Charleston: ["61920"],
      Chrisman: ["61924"],
      "Dalton city": ["61925"],
      Gays: ["61928"],
      Hammond: ["61929"],
      Hindsboro: ["61930"],
      Humboldt: ["61931"],
      Hume: ["61932"],
      Kansas: ["61933"],
      "La place": ["61936"],
      Lovington: ["61937"],
      Mattoon: ["61938"],
      Metcalf: ["61940"],
      Murdock: ["61941"],
      Newman: ["61942"],
      Oakland: ["61943"],
      Paris: ["61944"],
      Redmon: ["61949"],
      Sullivan: ["61951"],
      Tuscola: ["61953"],
      "Villa grove": ["61956"],
      Windsor: ["61957"],
      "Zcta 619hh": ["619HH"],
      Alhambra: ["62001"],
      Alton: ["62002"],
      Batchtown: ["62006"],
      Benld: ["62009"],
      Bethalto: ["62010"],
      Bingham: ["62011"],
      Brighton: ["62012"],
      Meppen: ["62013"],
      "Bunker hill": ["62014"],
      Butler: ["62015"],
      Carrollton: ["62016"],
      Coffeen: ["62017"],
      "Cottage hills": ["62018"],
      Donnellson: ["62019"],
      Dorsey: ["62021"],
      Dow: ["62022"],
      Eagarville: ["62023"],
      "East alton": ["62024"],
      Edwardsville: ["62025"],
      Eldred: ["62027"],
      Elsah: ["62028"],
      Fidelity: ["62030"],
      Fieldon: ["62031"],
      Fillmore: ["62032"],
      Dorchester: ["62033"],
      "Glen carbon": ["62034"],
      Godfrey: ["62035"],
      "Golden eagle": ["62036"],
      Grafton: ["62037"],
      Mitchell: ["62040"],
      Greenfield: ["62044"],
      Hamburg: ["62045"],
      Hamel: ["62046"],
      Hardin: ["62047"],
      Hartford: ["62048"],
      Hillsboro: ["62049"],
      Hillview: ["62050"],
      Irving: ["62051"],
      Jerseyville: ["62052"],
      Kampsville: ["62053"],
      Kane: ["62054"],
      Litchfield: ["62056"],
      Livingston: ["62058"],
      Lovejoy: ["62059"],
      Madison: ["62060"],
      Marine: ["62061"],
      Maryville: ["62062"],
      Medora: ["62063"],
      Michael: ["62065"],
      Moro: ["62067"],
      "Mount olive": ["62069"],
      Mozier: ["62070"],
      "New douglas": ["62074"],
      Nokomis: ["62075"],
      Panama: ["62077"],
      Patterson: ["62078"],
      Piasa: ["62079"],
      Ramsey: ["62080"],
      Rockbridge: ["62081"],
      Roodhouse: ["62082"],
      Rosamond: ["62083"],
      Roxana: ["62084"],
      Sawyerville: ["62085"],
      Sorento: ["62086"],
      "South roxana": ["62087"],
      Staunton: ["62088"],
      "Taylor springs": ["62089"],
      Venice: ["62090"],
      Walshville: ["62091"],
      "White hall": ["62092"],
      Wilsonville: ["62093"],
      Witt: ["62094"],
      "Wood river": ["62095"],
      Worden: ["62097"],
      "Zcta 620hh": ["620HH"],
      Sauget: ["62201"],
      "East saint louis": ["62203", "62205"],
      "Washington park": ["62204"],
      Cahokia: ["62206"],
      Alorton: ["62207"],
      "Fairview heights": ["62208"],
      Venedy: ["62214"],
      Albers: ["62215"],
      Aviston: ["62216"],
      Baldwin: ["62217"],
      Bartelso: ["62218"],
      Beckemeyer: ["62219"],
      Belleville: ["62220", "62221", "62223"],
      "Scott a f b": ["62225"],
      "Zcta 62226": ["62226"],
      Breese: ["62230"],
      Carlyle: ["62231"],
      Caseyville: ["62232"],
      Chester: ["62233"],
      Collinsville: ["62234"],
      Columbia: ["62236"],
      Swanwick: ["62237"],
      Cutler: ["62238"],
      Dupo: ["62239"],
      "East carondelet": ["62240"],
      "Ellis grove": ["62241"],
      Evansville: ["62242"],
      Freeburg: ["62243"],
      Fults: ["62244"],
      Germantown: ["62245"],
      Greenville: ["62246"],
      Highland: ["62249"],
      Hoffman: ["62250"],
      Keyesport: ["62253"],
      Lebanon: ["62254"],
      Lenzburg: ["62255"],
      Marissa: ["62257"],
      Mascoutah: ["62258"],
      Millstadt: ["62260"],
      Modoc: ["62261"],
      "Mulberry grove": ["62262"],
      Nashville: ["62263"],
      "New athens": ["62264"],
      "New baden": ["62265"],
      "New memphis": ["62266"],
      Oakdale: ["62268"],
      Shiloh: ["62269"],
      Okawville: ["62271"],
      Percy: ["62272"],
      Pierron: ["62273"],
      Pinckneyville: ["62274"],
      Pocahontas: ["62275"],
      "Prairie du roche": ["62277"],
      "Red bud": ["62278"],
      Renault: ["62279"],
      Rockwood: ["62280"],
      "Saint jacob": ["62281"],
      Shattuc: ["62283"],
      Smithboro: ["62284"],
      Smithton: ["62285"],
      Sparta: ["62286"],
      Steeleville: ["62288"],
      Summerfield: ["62289"],
      Tilden: ["62292"],
      Trenton: ["62293"],
      Troy: ["62294"],
      Valmeyer: ["62295"],
      Walsh: ["62297"],
      Waterloo: ["62298"],
      "Zcta 622hh": ["622HH"],
      Quincy: ["62301"],
      Augusta: ["62311"],
      Barry: ["62312"],
      Basco: ["62313"],
      Baylis: ["62314"],
      Bowen: ["62316"],
      Burnside: ["62318"],
      Camden: ["62319"],
      "Camp point": ["62320"],
      Carthage: ["62321"],
      Chambersburg: ["62323"],
      Clayton: ["62324"],
      Coatsburg: ["62325"],
      Colchester: ["62326"],
      Pontoosuc: ["62330"],
      Elvaston: ["62334"],
      Ferris: ["62336"],
      Fowler: ["62338"],
      Golden: ["62339"],
      Griggsville: ["62340"],
      Hamilton: ["62341"],
      Hull: ["62343"],
      Huntsville: ["62344"],
      Kinderhook: ["62345"],
      "La prairie": ["62346"],
      Liberty: ["62347"],
      Lima: ["62348"],
      Loraine: ["62349"],
      Mendon: ["62351"],
      Milton: ["62352"],
      "Mount sterling": ["62353"],
      Nauvoo: ["62354"],
      Nebo: ["62355"],
      "New canton": ["62356"],
      "New salem": ["62357"],
      Niota: ["62358"],
      Paloma: ["62359"],
      Payson: ["62360"],
      Pearl: ["62361"],
      Perry: ["62362"],
      Pittsfield: ["62363"],
      Plainville: ["62365"],
      "Pleasant hill": ["62366"],
      Colmar: ["62367"],
      Rockport: ["62370"],
      Sutter: ["62373"],
      Tennessee: ["62374"],
      Timewell: ["62375"],
      Ursa: ["62376"],
      Versailles: ["62378"],
      Warsaw: ["62379"],
      "West point": ["62380"],
      "Zcta 623hh": ["623HH"],
      Effingham: ["62401"],
      Allendale: ["62410"],
      Altamont: ["62411"],
      Annapolis: ["62413"],
      "Beecher city": ["62414"],
      Bridgeport: ["62417"],
      Brownstown: ["62418"],
      Calhoun: ["62419"],
      Casey: ["62420"],
      Claremont: ["62421"],
      Cowden: ["62422"],
      Dennison: ["62423"],
      Dieterich: ["62424"],
      Dundas: ["62425"],
      Laclede: ["62426"],
      "Flat rock": ["62427"],
      "Hazel dell": ["62428"],
      Herrick: ["62431"],
      Hidalgo: ["62432"],
      Hutsonville: ["62433"],
      Ingraham: ["62434"],
      Jewett: ["62436"],
      Lakewood: ["62438"],
      Lawrenceville: ["62439"],
      Lerna: ["62440"],
      Marshall: ["62441"],
      Martinsville: ["62442"],
      Mason: ["62443"],
      Mode: ["62444"],
      Montrose: ["62445"],
      "Mount erie": ["62446"],
      Neoga: ["62447"],
      Newton: ["62448"],
      Oblong: ["62449"],
      Olney: ["62450"],
      Palestine: ["62451"],
      Parkersburg: ["62452"],
      Robinson: ["62454"],
      "Saint elmo": ["62458"],
      "Sainte marie": ["62459"],
      "Saint francisvil": ["62460"],
      Shumway: ["62461"],
      Sigel: ["62462"],
      Stewardson: ["62463"],
      Strasburg: ["62465"],
      Sumner: ["62466"],
      Teutopolis: ["62467"],
      Toledo: ["62468"],
      Trilla: ["62469"],
      Vandalia: ["62471"],
      Watson: ["62473"],
      Westfield: ["62474"],
      "West liberty": ["62475"],
      "West salem": ["62476"],
      "West union": ["62477"],
      "West york": ["62478"],
      Wheeler: ["62479"],
      "Willow hill": ["62480"],
      Yale: ["62481"],
      "Zcta 624hh": ["624HH"],
      Newburg: ["62501"],
      Assumption: ["62510"],
      Atwater: ["62511"],
      Beason: ["62512"],
      "Blue mound": ["62513"],
      Boody: ["62514"],
      "Buffalo hart": ["62515"],
      Bulpitt: ["62517"],
      Chestnut: ["62518"],
      Cornland: ["62519"],
      Dawson: ["62520"],
      Decatur: ["62521", "62522", "62523"],
      Bearsdale: ["62526"],
      Cimic: ["62530"],
      Edinburg: ["62531"],
      Elwin: ["62532"],
      Thomasville: ["62533"],
      Brunswick: ["62534"],
      Forsyth: ["62535"],
      Glenarm: ["62536"],
      Harristown: ["62537"],
      Harvel: ["62538"],
      Illiopolis: ["62539"],
      Kincaid: ["62540"],
      "Lake fork": ["62541"],
      Latham: ["62543"],
      Macon: ["62544"],
      Bolivia: ["62545"],
      Morrisonville: ["62546"],
      "Mount auburn": ["62547"],
      "Mount pulaski": ["62548"],
      "Hervey city": ["62549"],
      Radford: ["62550"],
      Niantic: ["62551"],
      Casner: ["62552"],
      Oconee: ["62553"],
      Oreana: ["62554"],
      Owaneco: ["62555"],
      Clarksdale: ["62556"],
      Dunkel: ["62557"],
      Sicily: ["62558"],
      Raymond: ["62560"],
      Spaulding: ["62561"],
      Berry: ["62563"],
      Clarksburg: ["62565"],
      Stonington: ["62567"],
      Hewittsville: ["62568"],
      Tovey: ["62570"],
      Dollville: ["62571"],
      Waggoner: ["62572"],
      Heman: ["62573"],
      "Zcta 625hh": ["625HH"],
      Orleans: ["62601"],
      Alsey: ["62610"],
      Arenzville: ["62611"],
      Newmansville: ["62612"],
      "Fancy prairie": ["62613"],
      Auburn: ["62615"],
      Lynchburg: ["62617"],
      Beardstown: ["62618"],
      Exeter: ["62621"],
      Bader: ["62624"],
      Cantrall: ["62625"],
      Comer: ["62626"],
      "Panther creek": ["62627"],
      Chapin: ["62628"],
      Chatham: ["62629"],
      Hagaman: ["62630"],
      Concord: ["62631"],
      Biggs: ["62633"],
      Broadwell: ["62634"],
      Emden: ["62635"],
      Clements: ["62638"],
      Frederick: ["62639"],
      Mcvey: ["62640"],
      Hubly: ["62642"],
      Hartsburg: ["62643"],
      Eckard: ["62644"],
      Hettick: ["62649"],
      Arcadia: ["62650"],
      Kilbourne: ["62655"],
      Lincoln: ["62656"],
      Loami: ["62661"],
      Manchester: ["62663"],
      Luther: ["62664"],
      Naples: ["62665"],
      Middletown: ["62666"],
      Modesto: ["62667"],
      Nortonville: ["62668"],
      Bates: ["62670"],
      "New holland": ["62671"],
      Nilwood: ["62672"],
      Oakford: ["62673"],
      Barr: ["62674"],
      Atterbury: ["62675"],
      Farmingdale: ["62677"],
      Layton: ["62681"],
      Allen: ["62682"],
      Barclay: ["62684"],
      "Royal lakes": ["62685"],
      "South standard": ["62686"],
      Tallula: ["62688"],
      Thayer: ["62689"],
      Virden: ["62690"],
      "Little indian": ["62691"],
      Waverly: ["62692"],
      Williamsville: ["62693"],
      Glasgow: ["62694"],
      Woodson: ["62695"],
      "Zcta 626hh": ["626HH"],
      Springfield: ["62701"],
      Grandview: ["62702"],
      "Southern view": ["62703"],
      Jerome: ["62704"],
      Andrew: ["62707"],
      "Zcta 627hh": ["627HH"],
      Centralia: ["62801"],
      Hoyleton: ["62803"],
      Albion: ["62806"],
      Alma: ["62807"],
      Ashley: ["62808"],
      Barnhill: ["62809"],
      "Belle rive": ["62810"],
      Bellmont: ["62811"],
      Benton: ["62812"],
      Bluford: ["62814"],
      "Bone gap": ["62815"],
      Bonnie: ["62816"],
      Broughton: ["62817"],
      Browns: ["62818"],
      Buckner: ["62819"],
      "Burnt prairie": ["62820"],
      Carmi: ["62821"],
      Christopher: ["62822"],
      Cisne: ["62823"],
      "Clay city": ["62824"],
      Coello: ["62825"],
      Crossville: ["62827"],
      Dahlgren: ["62828"],
      Dale: ["62829"],
      Dix: ["62830"],
      "Du bois": ["62831"],
      "Du quoin": ["62832"],
      Ellery: ["62833"],
      Enfield: ["62835"],
      Ewing: ["62836"],
      Fairfield: ["62837"],
      Farina: ["62838"],
      Flora: ["62839"],
      Geff: ["62842"],
      "Golden gate": ["62843"],
      Grayville: ["62844"],
      Ina: ["62846"],
      Irvington: ["62848"],
      Iuka: ["62849"],
      Johnsonville: ["62850"],
      Keenes: ["62851"],
      Keensburg: ["62852"],
      Kell: ["62853"],
      Kinmundy: ["62854"],
      Loogootee: ["62857"],
      "Bible grove": ["62858"],
      "Mc leansboro": ["62859"],
      Macedonia: ["62860"],
      Maunie: ["62861"],
      "Mill shoals": ["62862"],
      "Mount carmel": ["62863"],
      "Mount vernon": ["62864"],
      Mulkeytown: ["62865"],
      "New haven": ["62867"],
      Noble: ["62868"],
      "Norris city": ["62869"],
      Odin: ["62870"],
      Omaha: ["62871"],
      Opdyke: ["62872"],
      Orient: ["62874"],
      Patoka: ["62875"],
      Radom: ["62876"],
      Richview: ["62877"],
      Rinard: ["62878"],
      "Sailor springs": ["62879"],
      "Saint peter": ["62880"],
      Salem: ["62881"],
      Sandoval: ["62882"],
      Scheller: ["62883"],
      Sesser: ["62884"],
      Shobonier: ["62885"],
      Sims: ["62886"],
      Springerton: ["62887"],
      Tamaroa: ["62888"],
      Texico: ["62889"],
      Thompsonville: ["62890"],
      Valier: ["62891"],
      Vernon: ["62892"],
      "Walnut hill": ["62893"],
      Waltonville: ["62894"],
      "Wayne city": ["62895"],
      "West frankfort": ["62896"],
      Whittington: ["62897"],
      Woodlawn: ["62898"],
      Xenia: ["62899"],
      "Zcta 628hh": ["628HH"],
      "Zcta 628xx": ["628XX"],
      Carbondale: ["62901"],
      "Alto pass": ["62905"],
      Anna: ["62906"],
      Ava: ["62907"],
      Belknap: ["62908"],
      "New liberty": ["62910"],
      Buncombe: ["62912"],
      Cairo: ["62914"],
      Cambria: ["62915"],
      "Campbell hill": ["62916"],
      "Carrier mills": ["62917"],
      Carterville: ["62918"],
      "Cave in rock": ["62919"],
      Cobden: ["62920"],
      Colp: ["62921"],
      "Creal springs": ["62922"],
      Cypress: ["62923"],
      "De soto": ["62924"],
      Dongola: ["62926"],
      Dowell: ["62927"],
      Eddyville: ["62928"],
      Eldorado: ["62930"],
      Elizabethtown: ["62931"],
      Elkville: ["62932"],
      Energy: ["62933"],
      Equality: ["62934"],
      Galatia: ["62935"],
      Brownfield: ["62938"],
      Goreville: ["62939"],
      Gorham: ["62940"],
      "Grand chain": ["62941"],
      "Grand tower": ["62942"],
      Grantsburg: ["62943"],
      Harrisburg: ["62946"],
      Herod: ["62947"],
      Herrin: ["62948"],
      Hurst: ["62949"],
      Jacob: ["62950"],
      "Johnston city": ["62951"],
      Jonesboro: ["62952"],
      Joppa: ["62953"],
      Junction: ["62954"],
      Karnak: ["62956"],
      "Mc clure": ["62957"],
      Makanda: ["62958"],
      Marion: ["62959"],
      Metropolis: ["62960"],
      "Miller city": ["62962"],
      "Mound city": ["62963"],
      Mounds: ["62964"],
      Murphysboro: ["62966"],
      "New burnside": ["62967"],
      "Olive branch": ["62969"],
      Olmsted: ["62970"],
      Ozark: ["62972"],
      Pittsburg: ["62974"],
      Pomona: ["62975"],
      Pulaski: ["62976"],
      Raleigh: ["62977"],
      Ridgway: ["62979"],
      Rosiclare: ["62982"],
      Royalton: ["62983"],
      Shawneetown: ["62984"],
      Robbs: ["62985"],
      Stonefort: ["62987"],
      Tamms: ["62988"],
      Gale: ["62990"],
      "Tunnel hill": ["62991"],
      Ullin: ["62992"],
      Unity: ["62993"],
      Vergennes: ["62994"],
      Vienna: ["62995"],
      "Villa ridge": ["62996"],
      Willisville: ["62997"],
      "Wolf lake": ["62998"],
      Zeigler: ["62999"],
      "Zcta 629hh": ["629HH"],
    },
  },
  {
    abbrevation: "IN",
    name: "Indiana",
    Cities: [
      "Alexandria",
      "Alexandria",
      "Anderson",
      "Chesterfield",
      "Arcadia",
      "Atlanta",
      "Carmel",
      "Cicero",
      "Colfax",
      "Elwood",
      "Fishers",
      "Forest",
      "Fortville",
      "Hillisburg",
      "Frankton",
      "Goldsmith",
      "Hobbs",
      "Ingalls",
      "Kempton",
      "Kirklin",
      "Lapel",
      "Lebanon",
      "Mc cordsville",
      "Markleville",
      "Michigantown",
      "Mulberry",
      "Noblesville",
      "Orestes",
      "Pendleton",
      "Rossville",
      "Sharpsville",
      "Sheridan",
      "Summitville",
      "Thorntown",
      "Tipton",
      "Westfield",
      "Whitestown",
      "Windfall",
      "Zionsville",
      "Zcta 460hh",
      "Advance",
      "Amo",
      "Arlington",
      "Bainbridge",
      "Bargersville",
      "Beech grove",
      "Boggstown",
      "Brooklyn",
      "Brownsburg",
      "Camby",
      "Carthage",
      "Charlottesville",
      "Clayton",
      "Cloverdale",
      "Coatesville",
      "Danville",
      "Zcta 46123",
      "Edinburgh",
      "Eminence",
      "Fairland",
      "Falmouth",
      "Fillmore",
      "Fountaintown",
      "Franklin",
      "Glenwood",
      "Greencastle",
      "Greenfield",
      "Greenwood",
      "Gwynneville",
      "Jamestown",
      "Knightstown",
      "Lizton",
      "Manilla",
      "Centerton",
      "Mays",
      "Milroy",
      "Monrovia",
      "Mooresville",
      "Morgantown",
      "Morristown",
      "Needham",
      "New palestine",
      "Nineveh",
      "North salem",
      "Paragon",
      "Pittsboro",
      "Avon",
      "Reelsville",
      "Roachdale",
      "Rushville",
      "Russellville",
      "Shelbyville",
      "Stilesville",
      "Trafalgar",
      "Waldron",
      "New whiteland",
      "Wilkinson",
      "Zcta 461hh",
      "Indianapolis",
      "Eagle creek",
      "Fort benjamin ha",
      "Southport",
      "Speedway",
      "Lawrence",
      "Zcta 46228",
      "Cumberland",
      "Bridgeport",
      "Clermont",
      "Zcta 46235",
      "Oaklandon",
      "Wanamaker",
      "Nora",
      "Park fletcher",
      "Castleton",
      "Acton",
      "New augusta",
      "Zcta 462hh",
      "Beverly shores",
      "East cedar lake",
      "Porter",
      "Crown point",
      "Demotte",
      "Dyer",
      "East chicago",
      "Griffith",
      "Hammond",
      "Munster",
      "Highland",
      "Hanna",
      "Hebron",
      "Hobart",
      "Kingsbury",
      "Kingsford height",
      "Kouts",
      "La crosse",
      "Lake village",
      "La porte",
      "Lowell",
      "Michigan city",
      "Mill creek",
      "North judson",
      "Portage",
      "Rolling prairie",
      "Saint john",
      "San pierre",
      "Schererville",
      "Schneider",
      "Shelby",
      "Sumava resorts",
      "Thayer",
      "Union mills",
      "Valparaiso",
      "Zcta 46385",
      "Wanatah",
      "Westville",
      "Wheatfield",
      "Wheeler",
      "Whiting",
      "Zcta 463hh",
      "Gary",
      "Lake station",
      "Merrillville",
      "Zcta 464hh",
      "Argos",
      "Atwood",
      "Bourbon",
      "Bremen",
      "Bristol",
      "Burket",
      "Claypool",
      "Culver military",
      "Donaldson",
      "Elkhart",
      "Etna green",
      "Foraker",
      "Zcta 46528",
      "Granger",
      "Grovertown",
      "Hamlet",
      "Ober",
      "Lakeville",
      "Lapaz",
      "Leesburg",
      "Mentone",
      "Middlebury",
      "Milford",
      "Millersburg",
      "Mishawaka",
      "Nappanee",
      "New carlisle",
      "New paris",
      "North liberty",
      "North webster",
      "Saint marys",
      "Osceola",
      "Pierceton",
      "Inwood",
      "Shipshewana",
      "Sidney",
      "Syracuse",
      "Tippecanoe",
      "Topeka",
      "Wakarusa",
      "Walkerton",
      "Warsaw",
      "Zcta 46582",
      "Winona lake",
      "Wyatt",
      "Zcta 465hh",
      "South bend",
      "Zcta 466hh",
      "Albion",
      "Andrews",
      "Angola",
      "Ashley",
      "Auburn",
      "Avilla",
      "Linn grove",
      "Bluffton",
      "Butler",
      "Churubusco",
      "Columbia city",
      "Corunna",
      "Craigville",
      "Cromwell",
      "Decatur",
      "Fremont",
      "Garrett",
      "Geneva",
      "Grabill",
      "Hamilton",
      "Harlan",
      "Hoagland",
      "Howe",
      "Helmer",
      "Huntertown",
      "Huntington",
      "Kendallville",
      "Keystone",
      "Kimmell",
      "Lagrange",
      "Laotto",
      "Larwill",
      "Leo",
      "Liberty center",
      "Ligonier",
      "Markle",
      "Monroe",
      "Monroeville",
      "New haven",
      "Orland",
      "Ossian",
      "Pleasant lake",
      "Poneto",
      "Roanoke",
      "Rome city",
      "Saint joe",
      "South whitley",
      "Spencerville",
      "Uniondale",
      "Warren",
      "Waterloo",
      "Wawaka",
      "Wolcottville",
      "Woodburn",
      "Yoder",
      "Zanesville",
      "Zcta 467hh",
      "Fort wayne",
      "Kokomo",
      "Akron",
      "Amboy",
      "Bringhurst",
      "Bunker hill",
      "Camden",
      "Converse",
      "Cutler",
      "Deedsville",
      "Delphi",
      "Chili",
      "Fairmount",
      "Flora",
      "Fowlerton",
      "Fulton",
      "Galveston",
      "Gas city",
      "Greentown",
      "Jonesboro",
      "Kewanna",
      "La fontaine",
      "Lagro",
      "Laketon",
      "Liberty mills",
      "Logansport",
      "Lucerne",
      "Macy",
      "Marion",
      "Matthews",
      "Mexico",
      "Miami",
      "Monterey",
      "New waverly",
      "North manchester",
      "Ora",
      "Peru",
      "Roann",
      "Rochester",
      "Royal center",
      "Russiaville",
      "Silver lake",
      "Star city",
      "Swayzee",
      "Sweetser",
      "Twelve mile",
      "Upland",
      "Urbana",
      "Landess",
      "Wabash",
      "Walton",
      "Winamac",
      "Young america",
      "Zcta 469hh",
      "Aurora",
      "Zcta 47003",
      "Batesville",
      "Bath",
      "Bennington",
      "Brookville",
      "Cedar grove",
      "Cross plains",
      "Dillsboro",
      "East enterprise",
      "Florence",
      "Friendship",
      "Guilford",
      "Holton",
      "Laurel",
      "Lawrenceburg",
      "Metamora",
      "Milan",
      "Moores hill",
      "Napoleon",
      "New trenton",
      "Oldenburg",
      "Osgood",
      "Patriot",
      "Rising sun",
      "Sunman",
      "Versailles",
      "Vevay",
      "W harrison",
      "Zcta 470hh",
      "Austin",
      "Borden",
      "Campbellsburg",
      "Charlestown",
      "Corydon",
      "Crandall",
      "Depauw",
      "Eckerty",
      "Elizabeth",
      "English",
      "Floyds knobs",
      "Fredericksburg",
      "Georgetown",
      "Grantsburg",
      "Greenville",
      "Hardinsburg",
      "Henryville",
      "Clarksville",
      "Jeffersonville",
      "Laconia",
      "Lanesville",
      "Leavenworth",
      "Lexington",
      "Little york",
      "Marengo",
      "Marysville",
      "Mauckport",
      "Memphis",
      "Milltown",
      "Nabb",
      "New albany",
      "New middletown",
      "New salisbury",
      "New washington",
      "Otisco",
      "Palmyra",
      "Pekin",
      "Ramsey",
      "Salem",
      "Scottsburg",
      "Speed",
      "Sulphur",
      "Taswell",
      "Underwood",
      "Zcta 471hh",
      "Columbus",
      "Brownstown",
      "Butlerville",
      "Canaan",
      "Clifford",
      "Commiskey",
      "Crothersville",
      "Deputy",
      "Dupont",
      "Elizabethtown",
      "Flat rock",
      "Freetown",
      "Adams",
      "Hanover",
      "Hartsville",
      "Hope",
      "Jonesville",
      "Madison",
      "Medora",
      "New point",
      "Norman",
      "North vernon",
      "Paris crossing",
      "Saint paul",
      "Scipio",
      "Seymour",
      "Taylorsville",
      "Vallonia",
      "Vernon",
      "Westport",
      "Zcta 472hh",
      "Muncie",
      "Albany",
      "Brownsville",
      "Bryant",
      "Cambridge city",
      "Centerville",
      "Connersville",
      "Daleville",
      "Dunkirk",
      "Dunreith",
      "Eaton",
      "Economy",
      "Farmland",
      "Fountain city",
      "Gaston",
      "Greensboro",
      "Greens fork",
      "Hagerstown",
      "Hartford city",
      "Kennard",
      "Lewisville",
      "Liberty",
      "Losantville",
      "Lynn",
      "Middletown",
      "Milton",
      "Modoc",
      "Montpelier",
      "Mooreland",
      "Mount summit",
      "New castle",
      "Parker city",
      "Pennville",
      "Portland",
      "Redkey",
      "Richmond",
      "Ridgeville",
      "Salamonia",
      "Saratoga",
      "Selma",
      "Shirley",
      "Spiceland",
      "Springport",
      "Straughn",
      "Union city",
      "Webster",
      "Williamsburg",
      "Winchester",
      "Yorktown",
      "Zcta 473hh",
      "Bloomington",
      "Woodbridge",
      "Avoca",
      "Bedford",
      "Bloomfield",
      "Coal city",
      "Ellettsville",
      "Fort ritner",
      "Freedom",
      "French lick",
      "Gosport",
      "Heltonville",
      "Jasonville",
      "Linton",
      "Lyons",
      "Midland",
      "Mitchell",
      "Nashville",
      "Newberry",
      "Oolitic",
      "Orleans",
      "Owensburg",
      "Paoli",
      "Patricksburg",
      "Quincy",
      "Solsberry",
      "Spencer",
      "Springville",
      "Stinesville",
      "Switz city",
      "Tunnelton",
      "Unionville",
      "West baden sprin",
      "Williams",
      "Worthington",
      "Zcta 474hh",
      "Washington",
      "Bicknell",
      "Birdseye",
      "Branchville",
      "Siberia",
      "Bruceville",
      "Cannelburg",
      "Mount pleasant",
      "Celestine",
      "Crane naval depo",
      "Dale",
      "Decker",
      "Derby",
      "Dubois",
      "Edwardsport",
      "Elnora",
      "Evanston",
      "Ferdinand",
      "Freelandville",
      "Fulda",
      "Gentryville",
      "Holland",
      "Huntingburg",
      "Ireland",
      "Haysville",
      "Buffaloville",
      "Leopold",
      "Lincoln city",
      "Loogootee",
      "Monroe city",
      "Montgomery",
      "Oaktown",
      "Odon",
      "Otwell",
      "Petersburg",
      "Plainville",
      "Rome",
      "Kyana",
      "Saint croix",
      "Saint meinrad",
      "Sandborn",
      "Santa claus",
      "Schnellville",
      "Shoals",
      "Spurgeon",
      "Stendal",
      "Tell city",
      "Troy",
      "Velpen",
      "Vincennes",
      "Westphalia",
      "Wheatland",
      "Winslow",
      "Zcta 475hh",
      "Boonville",
      "Chandler",
      "Chrisney",
      "Cynthiana",
      "Elberfeld",
      "Grandview",
      "Griffin",
      "Lynnville",
      "Mount vernon",
      "Newburgh",
      "New harmony",
      "Poseyville",
      "Richland",
      "Rockport",
      "Tennyson",
      "Wadesville",
      "Haubstadt",
      "Hazleton",
      "Buckskin",
      "Fort branch",
      "Francisco",
      "Mackey",
      "Oakland city",
      "Owensville",
      "Patoka",
      "Princeton",
      "Somerville",
      "Zcta 476hh",
      "Evansville",
      "Zcta 47725",
      "Terre haute",
      "North terre haut",
      "Bloomingdale",
      "Bowling green",
      "Brazil",
      "Bridgeton",
      "Carbon",
      "Carlisle",
      "Centerpoint",
      "Clay city",
      "Clinton",
      "Cory",
      "Dana",
      "Dugger",
      "Fairbanks",
      "Farmersburg",
      "Harmony",
      "Hillsdale",
      "Hymera",
      "Judson",
      "Lewis",
      "Marshall",
      "Mecca",
      "Merom",
      "Montezuma",
      "New goshen",
      "Paxton",
      "Pimento",
      "Poland",
      "Rockville",
      "Rosedale",
      "Saint mary of th",
      "Shelburn",
      "Staunton",
      "Sullivan",
      "Universal",
      "Sandford",
      "Zcta 478hh",
      "Lafayette",
      "West lafayette",
      "Zcta 47909",
      "Alamo",
      "Ambia",
      "Attica",
      "Battle ground",
      "Boswell",
      "Brook",
      "Brookston",
      "Buck creek",
      "Buffalo",
      "Burnettsville",
      "Cayuga",
      "Chalmers",
      "Clarks hill",
      "Covington",
      "Crawfordsville",
      "Darlington",
      "Dayton",
      "Earl park",
      "Fair oaks",
      "Fowler",
      "Francesville",
      "Goodland",
      "Hillsboro",
      "Idaville",
      "Kentland",
      "Cates",
      "Ladoga",
      "Linden",
      "Medaryville",
      "Mellott",
      "Monon",
      "Monticello",
      "Morocco",
      "Mount ayr",
      "New market",
      "Newport",
      "New richmond",
      "New ross",
      "Newtown",
      "Otterbein",
      "Oxford",
      "Perrysville",
      "Pine village",
      "Remington",
      "Collegeville",
      "Reynolds",
      "Romney",
      "State line",
      "Stockwell",
      "Templeton",
      "Veedersburg",
      "Waveland",
      "Waynetown",
      "West lebanon",
      "Westpoint",
      "Marshfield",
      "Wingate",
      "Wolcott",
      "Yeoman",
      "Zcta 479hh",
    ],
    ZipCodes: {
      Alexandria: ["46001"],
      Anderson: ["46011", "46012", "46013", "46016"],
      Chesterfield: ["46017"],
      Arcadia: ["46030"],
      Atlanta: ["46031"],
      Carmel: ["46032", "46033"],
      Cicero: ["46034"],
      Colfax: ["46035"],
      Elwood: ["46036"],
      Fishers: ["46038"],
      Forest: ["46039"],
      Fortville: ["46040"],
      Hillisburg: ["46041"],
      Frankton: ["46044"],
      Goldsmith: ["46045"],
      Hobbs: ["46047"],
      Ingalls: ["46048"],
      Kempton: ["46049"],
      Kirklin: ["46050"],
      Lapel: ["46051"],
      Lebanon: ["46052"],
      "Mc cordsville": ["46055"],
      Markleville: ["46056"],
      Michigantown: ["46057"],
      Mulberry: ["46058"],
      Noblesville: ["46060"],
      Orestes: ["46063"],
      Pendleton: ["46064"],
      Rossville: ["46065"],
      Sharpsville: ["46068"],
      Sheridan: ["46069"],
      Summitville: ["46070"],
      Thorntown: ["46071"],
      Tipton: ["46072"],
      Westfield: ["46074"],
      Whitestown: ["46075"],
      Windfall: ["46076"],
      Zionsville: ["46077"],
      "Zcta 460hh": ["460HH"],
      Advance: ["46102"],
      Amo: ["46103"],
      Arlington: ["46104"],
      Bainbridge: ["46105"],
      Bargersville: ["46106"],
      "Beech grove": ["46107"],
      Boggstown: ["46110"],
      Brooklyn: ["46111"],
      Brownsburg: ["46112"],
      Camby: ["46113"],
      Carthage: ["46115"],
      Charlottesville: ["46117"],
      Clayton: ["46118"],
      Cloverdale: ["46120"],
      Coatesville: ["46121"],
      Danville: ["46122"],
      "Zcta 46123": ["46123"],
      Edinburgh: ["46124"],
      Eminence: ["46125"],
      Fairland: ["46126"],
      Falmouth: ["46127"],
      Fillmore: ["46128"],
      Fountaintown: ["46130"],
      Franklin: ["46131"],
      Glenwood: ["46133"],
      Greencastle: ["46135"],
      Greenfield: ["46140"],
      Greenwood: ["46142", "46143"],
      Gwynneville: ["46144"],
      Jamestown: ["46147"],
      Knightstown: ["46148"],
      Lizton: ["46149"],
      Manilla: ["46150"],
      Centerton: ["46151"],
      Mays: ["46155"],
      Milroy: ["46156"],
      Monrovia: ["46157"],
      Mooresville: ["46158"],
      Morgantown: ["46160"],
      Morristown: ["46161"],
      Needham: ["46162"],
      "New palestine": ["46163"],
      Nineveh: ["46164"],
      "North salem": ["46165"],
      Paragon: ["46166"],
      Pittsboro: ["46167"],
      Avon: ["46168"],
      Reelsville: ["46171"],
      Roachdale: ["46172"],
      Rushville: ["46173"],
      Russellville: ["46175"],
      Shelbyville: ["46176"],
      Stilesville: ["46180"],
      Trafalgar: ["46181"],
      Waldron: ["46182"],
      "New whiteland": ["46184"],
      Wilkinson: ["46186"],
      "Zcta 461hh": ["461HH"],
      Indianapolis: [
        "46201",
        "46202",
        "46203",
        "46204",
        "46205",
        "46208",
        "46218",
        "46219",
        "46220",
        "46221",
        "46222",
        "46225",
      ],
      "Eagle creek": ["46214", "46254"],
      "Fort benjamin ha": ["46216"],
      Southport: ["46217", "46227", "46237"],
      Speedway: ["46224"],
      Lawrence: ["46226"],
      "Zcta 46228": ["46228"],
      Cumberland: ["46229"],
      Bridgeport: ["46231"],
      Clermont: ["46234"],
      "Zcta 46235": ["46235"],
      Oaklandon: ["46236"],
      Wanamaker: ["46239"],
      Nora: ["46240", "46260", "46280", "46290"],
      "Park fletcher": ["46241"],
      Castleton: ["46250", "46256"],
      Acton: ["46259"],
      "New augusta": ["46268", "46278"],
      "Zcta 462hh": ["462HH"],
      "Beverly shores": ["46301"],
      "East cedar lake": ["46303"],
      Porter: ["46304"],
      "Crown point": ["46307"],
      Demotte: ["46310"],
      Dyer: ["46311"],
      "East chicago": ["46312"],
      Griffith: ["46319"],
      Hammond: ["46320", "46323", "46324", "46327"],
      Munster: ["46321"],
      Highland: ["46322"],
      Hanna: ["46340"],
      Hebron: ["46341"],
      Hobart: ["46342"],
      Kingsbury: ["46345"],
      "Kingsford height": ["46346"],
      Kouts: ["46347"],
      "La crosse": ["46348"],
      "Lake village": ["46349"],
      "La porte": ["46350"],
      Lowell: ["46356"],
      "Michigan city": ["46360"],
      "Mill creek": ["46365"],
      "North judson": ["46366"],
      Portage: ["46368"],
      "Rolling prairie": ["46371"],
      "Saint john": ["46373"],
      "San pierre": ["46374"],
      Schererville: ["46375"],
      Schneider: ["46376"],
      Shelby: ["46377"],
      "Sumava resorts": ["46379"],
      Thayer: ["46381"],
      "Union mills": ["46382"],
      Valparaiso: ["46383"],
      "Zcta 46385": ["46385"],
      Wanatah: ["46390"],
      Westville: ["46391"],
      Wheatfield: ["46392"],
      Wheeler: ["46393"],
      Whiting: ["46394"],
      "Zcta 463hh": ["463HH"],
      Gary: ["46402", "46403", "46404", "46406", "46407", "46408", "46409"],
      "Lake station": ["46405"],
      Merrillville: ["46410"],
      "Zcta 464hh": ["464HH"],
      Argos: ["46501"],
      Atwood: ["46502"],
      Bourbon: ["46504"],
      Bremen: ["46506"],
      Bristol: ["46507"],
      Burket: ["46508"],
      Claypool: ["46510"],
      "Culver military": ["46511"],
      Donaldson: ["46513"],
      Elkhart: ["46514", "46516", "46517"],
      "Etna green": ["46524"],
      Foraker: ["46526"],
      "Zcta 46528": ["46528"],
      Granger: ["46530"],
      Grovertown: ["46531"],
      Hamlet: ["46532"],
      Ober: ["46534"],
      Lakeville: ["46536"],
      Lapaz: ["46537"],
      Leesburg: ["46538"],
      Mentone: ["46539"],
      Middlebury: ["46540"],
      Milford: ["46542"],
      Millersburg: ["46543"],
      Mishawaka: ["46544", "46545"],
      Nappanee: ["46550"],
      "New carlisle": ["46552"],
      "New paris": ["46553"],
      "North liberty": ["46554"],
      "North webster": ["46555"],
      "Saint marys": ["46556"],
      Osceola: ["46561"],
      Pierceton: ["46562"],
      Inwood: ["46563"],
      Shipshewana: ["46565"],
      Sidney: ["46566"],
      Syracuse: ["46567"],
      Tippecanoe: ["46570"],
      Topeka: ["46571"],
      Wakarusa: ["46573"],
      Walkerton: ["46574"],
      Warsaw: ["46580"],
      "Zcta 46582": ["46582"],
      "Winona lake": ["46590"],
      Wyatt: ["46595"],
      "Zcta 465hh": ["465HH"],
      "South bend": [
        "46601",
        "46613",
        "46614",
        "46615",
        "46616",
        "46617",
        "46619",
        "46628",
        "46629",
        "46635",
        "46637",
      ],
      "Zcta 466hh": ["466HH"],
      Albion: ["46701"],
      Andrews: ["46702"],
      Angola: ["46703"],
      Ashley: ["46705"],
      Auburn: ["46706"],
      Avilla: ["46710"],
      "Linn grove": ["46711"],
      Bluffton: ["46714"],
      Butler: ["46721"],
      Churubusco: ["46723"],
      "Columbia city": ["46725"],
      Corunna: ["46730"],
      Craigville: ["46731"],
      Cromwell: ["46732"],
      Decatur: ["46733"],
      Fremont: ["46737"],
      Garrett: ["46738"],
      Geneva: ["46740"],
      Grabill: ["46741"],
      Hamilton: ["46742"],
      Harlan: ["46743"],
      Hoagland: ["46745"],
      Howe: ["46746"],
      Helmer: ["46747"],
      Huntertown: ["46748"],
      Huntington: ["46750"],
      Kendallville: ["46755"],
      Keystone: ["46759"],
      Kimmell: ["46760"],
      Lagrange: ["46761"],
      Laotto: ["46763"],
      Larwill: ["46764"],
      Leo: ["46765"],
      "Liberty center": ["46766"],
      Ligonier: ["46767"],
      Markle: ["46770"],
      Monroe: ["46772"],
      Monroeville: ["46773"],
      "New haven": ["46774"],
      Orland: ["46776"],
      Ossian: ["46777"],
      "Pleasant lake": ["46779"],
      Poneto: ["46781"],
      Roanoke: ["46783"],
      "Rome city": ["46784"],
      "Saint joe": ["46785"],
      "South whitley": ["46787"],
      Spencerville: ["46788"],
      Uniondale: ["46791"],
      Warren: ["46792"],
      Waterloo: ["46793"],
      Wawaka: ["46794"],
      Wolcottville: ["46795"],
      Woodburn: ["46797"],
      Yoder: ["46798"],
      Zanesville: ["46799"],
      "Zcta 467hh": ["467HH"],
      "Fort wayne": [
        "46802",
        "46803",
        "46804",
        "46805",
        "46806",
        "46807",
        "46808",
        "46809",
        "46814",
        "46815",
        "46816",
        "46818",
        "46819",
        "46825",
        "46835",
        "46845",
      ],
      Kokomo: ["46901", "46902"],
      Akron: ["46910"],
      Amboy: ["46911"],
      Bringhurst: ["46913"],
      "Bunker hill": ["46914"],
      Camden: ["46917"],
      Converse: ["46919"],
      Cutler: ["46920"],
      Deedsville: ["46921"],
      Delphi: ["46923"],
      Chili: ["46926"],
      Fairmount: ["46928"],
      Flora: ["46929"],
      Fowlerton: ["46930"],
      Fulton: ["46931"],
      Galveston: ["46932"],
      "Gas city": ["46933"],
      Greentown: ["46936"],
      Jonesboro: ["46938"],
      Kewanna: ["46939"],
      "La fontaine": ["46940"],
      Lagro: ["46941"],
      Laketon: ["46943"],
      "Liberty mills": ["46946"],
      Logansport: ["46947"],
      Lucerne: ["46950"],
      Macy: ["46951"],
      Marion: ["46952", "46953"],
      Matthews: ["46957"],
      Mexico: ["46958"],
      Miami: ["46959"],
      Monterey: ["46960"],
      "New waverly": ["46961"],
      "North manchester": ["46962"],
      Ora: ["46968"],
      Peru: ["46970"],
      Roann: ["46974"],
      Rochester: ["46975"],
      "Royal center": ["46978"],
      Russiaville: ["46979"],
      "Silver lake": ["46982"],
      "Star city": ["46985"],
      Swayzee: ["46986"],
      Sweetser: ["46987"],
      "Twelve mile": ["46988"],
      Upland: ["46989"],
      Urbana: ["46990"],
      Landess: ["46991"],
      Wabash: ["46992"],
      Walton: ["46994"],
      Winamac: ["46996"],
      "Young america": ["46998"],
      "Zcta 469hh": ["469HH"],
      Aurora: ["47001"],
      "Zcta 47003": ["47003"],
      Batesville: ["47006"],
      Bath: ["47010"],
      Bennington: ["47011"],
      Brookville: ["47012"],
      "Cedar grove": ["47016"],
      "Cross plains": ["47017"],
      Dillsboro: ["47018"],
      "East enterprise": ["47019"],
      Florence: ["47020"],
      Friendship: ["47021"],
      Guilford: ["47022"],
      Holton: ["47023"],
      Laurel: ["47024"],
      Lawrenceburg: ["47025"],
      Metamora: ["47030"],
      Milan: ["47031"],
      "Moores hill": ["47032"],
      Napoleon: ["47034"],
      "New trenton": ["47035"],
      Oldenburg: ["47036"],
      Osgood: ["47037"],
      Patriot: ["47038"],
      "Rising sun": ["47040"],
      Sunman: ["47041"],
      Versailles: ["47042"],
      Vevay: ["47043"],
      "W harrison": ["47060"],
      "Zcta 470hh": ["470HH"],
      Austin: ["47102"],
      Borden: ["47106"],
      Campbellsburg: ["47108"],
      Charlestown: ["47111"],
      Corydon: ["47112"],
      Crandall: ["47114"],
      Depauw: ["47115"],
      Eckerty: ["47116"],
      Elizabeth: ["47117"],
      English: ["47118"],
      "Floyds knobs": ["47119"],
      Fredericksburg: ["47120"],
      Georgetown: ["47122"],
      Grantsburg: ["47123"],
      Greenville: ["47124"],
      Hardinsburg: ["47125"],
      Henryville: ["47126"],
      Clarksville: ["47129"],
      Jeffersonville: ["47130"],
      Laconia: ["47135"],
      Lanesville: ["47136"],
      Leavenworth: ["47137"],
      Lexington: ["47138"],
      "Little york": ["47139"],
      Marengo: ["47140"],
      Marysville: ["47141"],
      Mauckport: ["47142"],
      Memphis: ["47143"],
      Milltown: ["47145"],
      Nabb: ["47147"],
      "New albany": ["47150"],
      "New middletown": ["47160"],
      "New salisbury": ["47161"],
      "New washington": ["47162"],
      Otisco: ["47163"],
      Palmyra: ["47164"],
      Pekin: ["47165"],
      Ramsey: ["47166"],
      Salem: ["47167"],
      Scottsburg: ["47170"],
      Speed: ["47172"],
      Sulphur: ["47174"],
      Taswell: ["47175"],
      Underwood: ["47177"],
      "Zcta 471hh": ["471HH"],
      Columbus: ["47201", "47203"],
      Brownstown: ["47220"],
      Butlerville: ["47223"],
      Canaan: ["47224"],
      Clifford: ["47226"],
      Commiskey: ["47227"],
      Crothersville: ["47229"],
      Deputy: ["47230"],
      Dupont: ["47231"],
      Elizabethtown: ["47232"],
      "Flat rock": ["47234"],
      Freetown: ["47235"],
      Adams: ["47240"],
      Hanover: ["47243"],
      Hartsville: ["47244"],
      Hope: ["47246"],
      Jonesville: ["47247"],
      Madison: ["47250"],
      Medora: ["47260"],
      "New point": ["47263"],
      Norman: ["47264"],
      "North vernon": ["47265"],
      "Paris crossing": ["47270"],
      "Saint paul": ["47272"],
      Scipio: ["47273"],
      Seymour: ["47274"],
      Taylorsville: ["47280"],
      Vallonia: ["47281"],
      Vernon: ["47282"],
      Westport: ["47283"],
      "Zcta 472hh": ["472HH"],
      Muncie: ["47302", "47303", "47304", "47305"],
      Albany: ["47320"],
      Brownsville: ["47325"],
      Bryant: ["47326"],
      "Cambridge city": ["47327"],
      Centerville: ["47330"],
      Connersville: ["47331"],
      Daleville: ["47334"],
      Dunkirk: ["47336"],
      Dunreith: ["47337"],
      Eaton: ["47338"],
      Economy: ["47339"],
      Farmland: ["47340"],
      "Fountain city": ["47341"],
      Gaston: ["47342"],
      Greensboro: ["47344"],
      "Greens fork": ["47345"],
      Hagerstown: ["47346"],
      "Hartford city": ["47348"],
      Kennard: ["47351"],
      Lewisville: ["47352"],
      Liberty: ["47353"],
      Losantville: ["47354"],
      Lynn: ["47355"],
      Middletown: ["47356"],
      Milton: ["47357"],
      Modoc: ["47358"],
      Montpelier: ["47359"],
      Mooreland: ["47360"],
      "Mount summit": ["47361"],
      "New castle": ["47362"],
      "Parker city": ["47368"],
      Pennville: ["47369"],
      Portland: ["47371"],
      Redkey: ["47373"],
      Richmond: ["47374"],
      Ridgeville: ["47380"],
      Salamonia: ["47381"],
      Saratoga: ["47382"],
      Selma: ["47383"],
      Shirley: ["47384"],
      Spiceland: ["47385"],
      Springport: ["47386"],
      Straughn: ["47387"],
      "Union city": ["47390"],
      Webster: ["47392"],
      Williamsburg: ["47393"],
      Winchester: ["47394"],
      Yorktown: ["47396"],
      "Zcta 473hh": ["473HH"],
      Bloomington: ["47401", "47403", "47404", "47406"],
      Woodbridge: ["47408"],
      Avoca: ["47420"],
      Bedford: ["47421"],
      Bloomfield: ["47424"],
      "Coal city": ["47427"],
      Ellettsville: ["47429"],
      "Fort ritner": ["47430"],
      Freedom: ["47431"],
      "French lick": ["47432"],
      Gosport: ["47433"],
      Heltonville: ["47436"],
      Jasonville: ["47438"],
      Linton: ["47441"],
      Lyons: ["47443"],
      Midland: ["47445"],
      Mitchell: ["47446"],
      Nashville: ["47448"],
      Newberry: ["47449"],
      Oolitic: ["47451"],
      Orleans: ["47452"],
      Owensburg: ["47453"],
      Paoli: ["47454"],
      Patricksburg: ["47455"],
      Quincy: ["47456"],
      Solsberry: ["47459"],
      Spencer: ["47460"],
      Springville: ["47462"],
      Stinesville: ["47464"],
      "Switz city": ["47465"],
      Tunnelton: ["47467"],
      Unionville: ["47468"],
      "West baden sprin": ["47469"],
      Williams: ["47470"],
      Worthington: ["47471"],
      "Zcta 474hh": ["474HH"],
      Washington: ["47501"],
      Bicknell: ["47512"],
      Birdseye: ["47513"],
      Branchville: ["47514"],
      Siberia: ["47515"],
      Bruceville: ["47516"],
      Cannelburg: ["47519"],
      "Mount pleasant": ["47520"],
      Celestine: ["47521"],
      "Crane naval depo": ["47522"],
      Dale: ["47523"],
      Decker: ["47524"],
      Derby: ["47525"],
      Dubois: ["47527"],
      Edwardsport: ["47528"],
      Elnora: ["47529"],
      Evanston: ["47531"],
      Ferdinand: ["47532"],
      Freelandville: ["47535"],
      Fulda: ["47536"],
      Gentryville: ["47537"],
      Holland: ["47541"],
      Huntingburg: ["47542"],
      Ireland: ["47545"],
      Haysville: ["47546"],
      Buffaloville: ["47550"],
      Leopold: ["47551"],
      "Lincoln city": ["47552"],
      Loogootee: ["47553"],
      "Monroe city": ["47557"],
      Montgomery: ["47558"],
      Oaktown: ["47561"],
      Odon: ["47562"],
      Otwell: ["47564"],
      Petersburg: ["47567"],
      Plainville: ["47568"],
      Rome: ["47574"],
      Kyana: ["47575"],
      "Saint croix": ["47576"],
      "Saint meinrad": ["47577"],
      Sandborn: ["47578"],
      "Santa claus": ["47579"],
      Schnellville: ["47580"],
      Shoals: ["47581"],
      Spurgeon: ["47584"],
      Stendal: ["47585"],
      "Tell city": ["47586"],
      Troy: ["47588"],
      Velpen: ["47590"],
      Vincennes: ["47591"],
      Westphalia: ["47596"],
      Wheatland: ["47597"],
      Winslow: ["47598"],
      "Zcta 475hh": ["475HH"],
      Boonville: ["47601"],
      Chandler: ["47610"],
      Chrisney: ["47611"],
      Cynthiana: ["47612"],
      Elberfeld: ["47613"],
      Grandview: ["47615"],
      Griffin: ["47616"],
      Lynnville: ["47619"],
      "Mount vernon": ["47620"],
      Newburgh: ["47630"],
      "New harmony": ["47631"],
      Poseyville: ["47633"],
      Richland: ["47634"],
      Rockport: ["47635"],
      Tennyson: ["47637"],
      Wadesville: ["47638"],
      Haubstadt: ["47639"],
      Hazleton: ["47640"],
      Buckskin: ["47647"],
      "Fort branch": ["47648"],
      Francisco: ["47649"],
      Mackey: ["47654"],
      "Oakland city": ["47660"],
      Owensville: ["47665"],
      Patoka: ["47666"],
      Princeton: ["47670"],
      Somerville: ["47683"],
      "Zcta 476hh": ["476HH"],
      Evansville: [
        "47708",
        "47710",
        "47711",
        "47712",
        "47713",
        "47714",
        "47715",
        "47720",
      ],
      "Zcta 47725": ["47725"],
      "Terre haute": ["47802", "47803", "47804", "47807"],
      "North terre haut": ["47805"],
      Bloomingdale: ["47832"],
      "Bowling green": ["47833"],
      Brazil: ["47834"],
      Bridgeton: ["47836"],
      Carbon: ["47837"],
      Carlisle: ["47838"],
      Centerpoint: ["47840"],
      "Clay city": ["47841"],
      Clinton: ["47842"],
      Cory: ["47846"],
      Dana: ["47847"],
      Dugger: ["47848"],
      Fairbanks: ["47849"],
      Farmersburg: ["47850"],
      Harmony: ["47853"],
      Hillsdale: ["47854"],
      Hymera: ["47855"],
      Judson: ["47856"],
      Lewis: ["47858"],
      Marshall: ["47859"],
      Mecca: ["47860"],
      Merom: ["47861"],
      Montezuma: ["47862"],
      "New goshen": ["47863"],
      Paxton: ["47865"],
      Pimento: ["47866"],
      Poland: ["47868"],
      Rockville: ["47872"],
      Rosedale: ["47874"],
      "Saint mary of th": ["47876"],
      Shelburn: ["47879"],
      Staunton: ["47881"],
      Sullivan: ["47882"],
      Universal: ["47884"],
      Sandford: ["47885"],
      "Zcta 478hh": ["478HH"],
      Lafayette: ["47901", "47904", "47905"],
      "West lafayette": ["47906"],
      "Zcta 47909": ["47909"],
      Alamo: ["47916"],
      Ambia: ["47917"],
      Attica: ["47918"],
      "Battle ground": ["47920"],
      Boswell: ["47921"],
      Brook: ["47922"],
      Brookston: ["47923"],
      "Buck creek": ["47924"],
      Buffalo: ["47925"],
      Burnettsville: ["47926"],
      Cayuga: ["47928"],
      Chalmers: ["47929"],
      "Clarks hill": ["47930"],
      Covington: ["47932"],
      Crawfordsville: ["47933"],
      Darlington: ["47940"],
      Dayton: ["47941"],
      "Earl park": ["47942"],
      "Fair oaks": ["47943"],
      Fowler: ["47944"],
      Francesville: ["47946"],
      Goodland: ["47948"],
      Hillsboro: ["47949"],
      Idaville: ["47950"],
      Kentland: ["47951"],
      Cates: ["47952"],
      Ladoga: ["47954"],
      Linden: ["47955"],
      Medaryville: ["47957"],
      Mellott: ["47958"],
      Monon: ["47959"],
      Monticello: ["47960"],
      Morocco: ["47963"],
      "Mount ayr": ["47964"],
      "New market": ["47965"],
      Newport: ["47966"],
      "New richmond": ["47967"],
      "New ross": ["47968"],
      Newtown: ["47969"],
      Otterbein: ["47970"],
      Oxford: ["47971"],
      Perrysville: ["47974"],
      "Pine village": ["47975"],
      Remington: ["47977"],
      Collegeville: ["47978"],
      Reynolds: ["47980"],
      Romney: ["47981"],
      "State line": ["47982"],
      Stockwell: ["47983"],
      Templeton: ["47986"],
      Veedersburg: ["47987"],
      Waveland: ["47989"],
      Waynetown: ["47990"],
      "West lebanon": ["47991"],
      Westpoint: ["47992"],
      Marshfield: ["47993"],
      Wingate: ["47994"],
      Wolcott: ["47995"],
      Yeoman: ["47997"],
      "Zcta 479hh": ["479HH"],
    },
  },
  {
    abbrevation: "IA",
    name: "Iowa",
    Cities: [
      "Ackworth",
      "Ackworth",
      "Adair",
      "Adel",
      "Albion",
      "Alden",
      "Alleman",
      "Allerton",
      "Altoona",
      "Ames",
      "Anita",
      "Ankeny",
      "Atlantic",
      "Audubon",
      "Bagley",
      "Barnes city",
      "Baxter",
      "Bayard",
      "Berwick",
      "Bevington",
      "Blairsburg",
      "Bondurant",
      "Boone",
      "Booneville",
      "Bouton",
      "Boxholm",
      "Bradford",
      "Brayton",
      "Bussey",
      "Cambridge",
      "Carlisle",
      "Casey",
      "Chariton",
      "Churdan",
      "Clemons",
      "Clio",
      "Colfax",
      "Collins",
      "Colo",
      "Columbia",
      "Coon rapids",
      "Cooper",
      "Sewal",
      "Cumming",
      "Dallas",
      "Dallas center",
      "Dana",
      "Pleasanton",
      "Dawson",
      "Decatur",
      "Derby",
      "De soto",
      "Dexter",
      "Dows",
      "Earlham",
      "Elkhart",
      "Ellston",
      "Ellsworth",
      "Exira",
      "Ferguson",
      "Zcta 500hh",
      "Galt",
      "Garden city",
      "Garden grove",
      "Gibson",
      "Gilbert",
      "Gilman",
      "Grand junction",
      "Grand river",
      "Granger",
      "Gray",
      "Grimes",
      "Grinnell",
      "Guthrie center",
      "Hamilton",
      "Hamlin",
      "Hartford",
      "Harvey",
      "Haverhill",
      "Hubbard",
      "Humeston",
      "Huxley",
      "Spring hill",
      "Iowa falls",
      "Jamaica",
      "Jefferson",
      "Jewell",
      "Johnston",
      "Kamrar",
      "Kellerton",
      "Kelley",
      "Kellogg",
      "Keswick",
      "Killduff",
      "Knoxville",
      "Lacona",
      "Lamoni",
      "Laurel",
      "Le grand",
      "Leighton",
      "Leon",
      "Liberty center",
      "Linden",
      "Lineville",
      "Liscomb",
      "Lorimor",
      "Lovilia",
      "Lucas",
      "Luther",
      "Lynnville",
      "Mc callsburg",
      "Macksburg",
      "Madrid",
      "Malcom",
      "Marshalltown",
      "Martensdale",
      "Maxwell",
      "Melbourne",
      "Menlo",
      "Millerton",
      "Milo",
      "Minburn",
      "Mingo",
      "Mitchellville",
      "Monroe",
      "Montezuma",
      "Montour",
      "Murray",
      "Zcta 501hh",
      "Nevada",
      "New providence",
      "New sharon",
      "Newton",
      "New virginia",
      "Norwalk",
      "Ogden",
      "Osceola",
      "Otley",
      "Panora",
      "Paton",
      "Patterson",
      "Pella",
      "Perry",
      "Peru",
      "Pilot mound",
      "Pleasantville",
      "Polk city",
      "Popejoy",
      "Prairie city",
      "Prole",
      "Radcliffe",
      "Randall",
      "Reasnor",
      "Redfield",
      "Rhodes",
      "Rippey",
      "Roland",
      "Runnells",
      "Russell",
      "Saint anthony",
      "Saint charles",
      "Saint marys",
      "Searsboro",
      "Sheldahl",
      "Slater",
      "Stanhope",
      "State center",
      "Story city",
      "Stratford",
      "Stuart",
      "Sully",
      "Swan",
      "Thayer",
      "Tracy",
      "Truro",
      "Union",
      "Van meter",
      "Van wert",
      "Waukee",
      "Weldon",
      "West des moines",
      "What cheer",
      "Whitten",
      "Williams",
      "Williamson",
      "Winterset",
      "Wiota",
      "Woodburn",
      "Woodward",
      "Yale",
      "Zearing",
      "Zcta 502hh",
      "Des moines",
      "Windsor heights",
      "Pleasant hill",
      "Urbandale",
      "Zcta 50323",
      "Clive",
      "Zcta 503hh",
      "Mason city",
      "Alexander",
      "Belmond",
      "Britt",
      "Buffalo center",
      "Carpenter",
      "Clear lake",
      "Corwith",
      "Coulter",
      "Crystal lake",
      "Dougherty",
      "Fertile",
      "Floyd",
      "Forest city",
      "Garner",
      "Goodell",
      "Grafton",
      "Hampton",
      "Hanlontown",
      "Joice",
      "Kanawha",
      "Kensett",
      "Klemme",
      "Lake mills",
      "Lakota",
      "Latimer",
      "Leland",
      "Little cedar",
      "Mc intire",
      "Manly",
      "Meservey",
      "Nora springs",
      "Northwood",
      "Orchard",
      "Osage",
      "Plymouth",
      "Rake",
      "Riceville",
      "Rockford",
      "Rockwell",
      "Rowan",
      "Rudd",
      "Saint ansgar",
      "Scarville",
      "Sheffield",
      "Stacyville",
      "Swaledale",
      "Thompson",
      "Thornton",
      "Titonka",
      "Ventura",
      "Wesley",
      "Woden",
      "Zcta 504hh",
      "Fort dodge",
      "Albert city",
      "Algona",
      "Armstrong",
      "Ayrshire",
      "Badger",
      "Bancroft",
      "Barnum",
      "Bode",
      "Bradgate",
      "Burnside",
      "Burt",
      "Callender",
      "Clare",
      "Clarion",
      "Curlew",
      "Cylinder",
      "Dakota city",
      "Dayton",
      "Dolliver",
      "Duncombe",
      "Eagle grove",
      "Early",
      "Emmetsburg",
      "Farnhamville",
      "Fenton",
      "Fonda",
      "Gilmore city",
      "Goldfield",
      "Gowrie",
      "Harcourt",
      "Hardy",
      "Havelock",
      "Humboldt",
      "Jolley",
      "Knierim",
      "Laurens",
      "Ledyard",
      "Lehigh",
      "Livermore",
      "Lone rock",
      "Lu verne",
      "Lytton",
      "Mallard",
      "Manson",
      "Marathon",
      "Moorland",
      "Nemaha",
      "Newell",
      "Otho",
      "Ottosen",
      "Palmer",
      "Plover",
      "Pocahontas",
      "Pomeroy",
      "Rembrandt",
      "Renwick",
      "Ringsted",
      "Rockwell city",
      "Rolfe",
      "Rutland",
      "Sac city",
      "Sioux rapids",
      "Somers",
      "Storm lake",
      "Swea city",
      "Thor",
      "Truesdale",
      "Varina",
      "Vincent",
      "Webster city",
      "West bend",
      "Whittemore",
      "Woolstock",
      "Zcta 505hh",
      "Ackley",
      "Allison",
      "Alta vista",
      "Aplington",
      "Aredale",
      "Arlington",
      "Aurora",
      "Beaman",
      "Bristow",
      "Buckingham",
      "Cedar falls",
      "Charles city",
      "Clarksville",
      "Conrad",
      "Denver",
      "Dewar",
      "Dike",
      "Dumont",
      "Dunkerton",
      "Eldora",
      "Elma",
      "Fairbank",
      "Fredericksburg",
      "Frederika",
      "Garwin",
      "Geneva",
      "Gilbertville",
      "Gladbrook",
      "Greene",
      "Grundy center",
      "Hazleton",
      "Holland",
      "Hudson",
      "Independence",
      "Ionia",
      "Janesville",
      "Jesup",
      "Kesley",
      "Lamont",
      "La porte city",
      "Lincoln",
      "Marble rock",
      "Masonville",
      "Maynard",
      "Nashua",
      "New hampton",
      "New hartford",
      "Oelwein",
      "Parkersburg",
      "Plainfield",
      "Raymond",
      "Readlyn",
      "Reinbeck",
      "Shell rock",
      "Stanley",
      "Steamboat rock",
      "Stout",
      "Sumner",
      "Traer",
      "Tripoli",
      "Bremer",
      "Wellsburg",
      "Westgate",
      "Winthrop",
      "Zcta 506hh",
      "Waterloo",
      "Washburn",
      "Evansdale",
      "Zcta 507hh",
      "Nevinville",
      "Afton",
      "Bedford",
      "Benton",
      "Blockton",
      "Bridgewater",
      "Clearfield",
      "Corning",
      "Cumberland",
      "Diagonal",
      "Fontanelle",
      "Grant",
      "Gravity",
      "Greenfield",
      "Lenox",
      "Massena",
      "Mount ayr",
      "Nodaway",
      "Orient",
      "Prescott",
      "Redding",
      "Shannon city",
      "Sharpsburg",
      "Tingley",
      "Villisca",
      "Akron",
      "Alta",
      "Alton",
      "Anthon",
      "Aurelia",
      "Battle creek",
      "Bronson",
      "Brunsville",
      "Castana",
      "Chatsworth",
      "Cherokee",
      "Cleghorn",
      "Correctionville",
      "Cushing",
      "Danbury",
      "Galva",
      "Granville",
      "Hawarden",
      "Hinton",
      "Holstein",
      "Hornick",
      "Ireton",
      "Kingsley",
      "Larrabee",
      "Lawton",
      "Le mars",
      "Linn grove",
      "Mapleton",
      "Marcus",
      "Maurice",
      "Meriden",
      "Merrill",
      "Moville",
      "Onawa",
      "Orange city",
      "Oto",
      "Oyens",
      "Paullina",
      "Peterson",
      "Pierson",
      "Quimby",
      "Remsen",
      "Rodney",
      "Salix",
      "Schaller",
      "Sergeant bluff",
      "Sloan",
      "Smithland",
      "Sutherland",
      "Turin",
      "Ute",
      "Washta",
      "Westfield",
      "Whiting",
      "Zcta 510hh",
      "Sioux city",
      "Zcta 511hh",
      "Sheldon",
      "Alvord",
      "Archer",
      "Ashton",
      "Boyden",
      "Doon",
      "George",
      "Hospers",
      "Hull",
      "Inwood",
      "Larchwood",
      "Lester",
      "Little rock",
      "Matlock",
      "Primghar",
      "Rock rapids",
      "Rock valley",
      "Sanborn",
      "Sibley",
      "Sioux center",
      "Spencer",
      "Arnolds park",
      "Dickens",
      "Estherville",
      "Everly",
      "Gillett grove",
      "Graettinger",
      "Greenville",
      "Gruver",
      "Harris",
      "Hartley",
      "Lake park",
      "May city",
      "Melvin",
      "Milford",
      "Ocheyedan",
      "Okoboji",
      "Royal",
      "Ruthven",
      "Spirit lake",
      "Superior",
      "Terril",
      "Wallingford",
      "Webb",
      "Zcta 513hh",
      "Carroll",
      "Arcadia",
      "Arthur",
      "Yetter",
      "Breda",
      "Charter oak",
      "Dedham",
      "Deloit",
      "Denison",
      "Glidden",
      "Halbur",
      "Ida grove",
      "Irwin",
      "Kirkman",
      "Kiron",
      "Lake city",
      "Lake view",
      "Lanesboro",
      "Lidderdale",
      "Lohrville",
      "Manilla",
      "Manning",
      "Odebolt",
      "Ralston",
      "Schleswig",
      "Scranton",
      "Templeton",
      "Vail",
      "Wall lake",
      "Westside",
      "Zcta 514hh",
      "Manawa",
      "Council bluffs",
      "Carter lake",
      "Arion",
      "Avoca",
      "Blencoe",
      "Carson",
      "Crescent",
      "Earling",
      "Dow city",
      "Elk horn",
      "Elliott",
      "Emerson",
      "Glenwood",
      "Griswold",
      "Hancock",
      "Harlan",
      "Hastings",
      "Henderson",
      "Honey creek",
      "Kimballton",
      "Lewis",
      "Little sioux",
      "Logan",
      "Mc clelland",
      "Macedonia",
      "Magnolia",
      "Malvern",
      "Marne",
      "Minden",
      "Mineola",
      "Missouri valley",
      "Modale",
      "Mondamin",
      "Moorhead",
      "Neola",
      "Oakland",
      "Pacific junction",
      "Panama",
      "Persia",
      "Pisgah",
      "Portsmouth",
      "Red oak",
      "Shelby",
      "Silver city",
      "Soldier",
      "Stanton",
      "Tennant",
      "Treynor",
      "Underwood",
      "Walnut",
      "Westphalia",
      "Woodbine",
      "Zcta 515hh",
      "Shenandoah",
      "Blanchard",
      "Braddyville",
      "Clarinda",
      "Coin",
      "College springs",
      "Essex",
      "Farragut",
      "Hamburg",
      "Imogene",
      "New market",
      "Northboro",
      "Percival",
      "Randolph",
      "Riverton",
      "Sidney",
      "Tabor",
      "Thurman",
      "Zcta 516hh",
      "Dubuque",
      "Andrew",
      "Bellevue",
      "Bernard",
      "Cascade",
      "Colesburg",
      "Delaware",
      "Delmar",
      "Dundee",
      "Durango",
      "Dyersville",
      "Earlville",
      "Edgewood",
      "Elkader",
      "Elkport",
      "Epworth",
      "Farley",
      "Farmersburg",
      "Garber",
      "Garnavillo",
      "Greeley",
      "Guttenberg",
      "Holy cross",
      "La motte",
      "Luxemburg",
      "Manchester",
      "Maquoketa",
      "Miles",
      "New vienna",
      "North buena vist",
      "Peosta",
      "Preston",
      "Sabula",
      "Saint donatus",
      "Saint olaf",
      "Sherrill",
      "Spragueville",
      "Springbrook",
      "Strawberry point",
      "Volga",
      "Worthington",
      "Zwingle",
      "Zcta 520hh",
      "Decorah",
      "Calmar",
      "Castalia",
      "Chester",
      "Clermont",
      "Cresco",
      "Dorchester",
      "Elgin",
      "Fayette",
      "Fort atkinson",
      "Harpers ferry",
      "Hawkeye",
      "Lansing",
      "Lawler",
      "Lime springs",
      "Luana",
      "Mc gregor",
      "Marquette",
      "Monona",
      "New albin",
      "Ossian",
      "Postville",
      "Protivin",
      "Randalia",
      "Ridgeway",
      "Saint lucas",
      "Spillville",
      "Wadena",
      "Waterville",
      "Waucoma",
      "Waukon",
      "Eldorado",
      "Zcta 521hh",
      "Ainsworth",
      "Alburnett",
      "Amana",
      "Anamosa",
      "Atkins",
      "Baldwin",
      "Belle plaine",
      "Blairstown",
      "Brandon",
      "Brooklyn",
      "Center junction",
      "Center point",
      "Central city",
      "Chelsea",
      "Clarence",
      "Clutier",
      "Coggon",
      "Prairieburg",
      "Conroy",
      "Zcta 52221",
      "Deep river",
      "Delhi",
      "Dysart",
      "Elberon",
      "Elwood",
      "Ely",
      "Fairfax",
      "Garrison",
      "Harper",
      "Hartwick",
      "Hiawatha",
      "Hills",
      "Homestead",
      "Hopkinton",
      "Iowa city",
      "Coralville",
      "Kalona",
      "Keota",
      "Keystone",
      "Ladora",
      "Lisbon",
      "Lost nation",
      "Lowden",
      "Luzerne",
      "Zcta 522hh",
      "Marengo",
      "Marion",
      "Martelle",
      "Mechanicsville",
      "Middle amana",
      "Millersburg",
      "Monmouth",
      "Monticello",
      "Morley",
      "Mount auburn",
      "Mount vernon",
      "Newhall",
      "North english",
      "North liberty",
      "Norway",
      "Olin",
      "Onslow",
      "Oxford",
      "Oxford junction",
      "Palo",
      "Parnell",
      "Quasqueton",
      "Riverside",
      "Robins",
      "Rowley",
      "Ryan",
      "Shellsburg",
      "Solon",
      "South amana",
      "South english",
      "Springville",
      "Stanwood",
      "Swisher",
      "Tama",
      "Tiffin",
      "Toddville",
      "Toledo",
      "Urbana",
      "Van horne",
      "Victor",
      "Vining",
      "Vinton",
      "Walford",
      "Walker",
      "Washington",
      "Watkins",
      "Webster",
      "Wellman",
      "West branch",
      "West chester",
      "Williamsburg",
      "Wyoming",
      "Zcta 523hh",
      "Cedar rapids",
      "Zcta 524hh",
      "Highland center",
      "Agency",
      "Albia",
      "Batavia",
      "Beacon",
      "Birmingham",
      "Blakesburg",
      "Bloomfield",
      "Brighton",
      "Cantril",
      "Cedar",
      "Centerville",
      "Chillicothe",
      "Cincinnati",
      "Delta",
      "Douds",
      "Drakesville",
      "Eddyville",
      "Eldon",
      "Exline",
      "Fairfield",
      "Floris",
      "Fremont",
      "Hayesville",
      "Hedrick",
      "Keosauqua",
      "Kirkville",
      "Libertyville",
      "Martinsburg",
      "Melrose",
      "Milton",
      "Moravia",
      "Moulton",
      "Mount sterling",
      "Mystic",
      "Ollie",
      "Oskaloosa",
      "Packwood",
      "Plano",
      "Promise city",
      "Pulaski",
      "Richland",
      "Rose hill",
      "Selma",
      "Seymour",
      "Sigourney",
      "Udell",
      "Unionville",
      "University park",
      "Zcta 525hh",
      "Burlington",
      "Argyle",
      "Bonaparte",
      "Crawfordsville",
      "Danville",
      "Denmark",
      "Donnellson",
      "Farmington",
      "Fort madison",
      "Hillsboro",
      "Houghton",
      "Keokuk",
      "Lockridge",
      "Mediapolis",
      "Middletown",
      "Montrose",
      "Morning sun",
      "Mount pleasant",
      "Mount union",
      "New london",
      "Oakville",
      "Olds",
      "Salem",
      "Sperry",
      "Stockport",
      "Swedesburg",
      "Wapello",
      "Wayland",
      "West burlington",
      "West point",
      "Wever",
      "Winfield",
      "Yarmouth",
      "Zcta 526hh",
      "Andover",
      "Atalissa",
      "Bennett",
      "Bettendorf",
      "Blue grass",
      "Bryant",
      "Buffalo",
      "Calamus",
      "Camanche",
      "Charlotte",
      "Clinton",
      "Columbus city",
      "Columbus junctio",
      "Conesville",
      "De witt",
      "Big rock",
      "Donahue",
      "Durant",
      "Eldridge",
      "Fruitland",
      "Goose lake",
      "Grand mound",
      "Grandview",
      "Le claire",
      "Letts",
      "Lone tree",
      "Long grove",
      "Low moor",
      "Mc causland",
      "Moscow",
      "Muscatine",
      "New liberty",
      "Nichols",
      "Pleasant valley",
      "Princeton",
      "Stockton",
      "Tipton",
      "Walcott",
      "Welton",
      "West liberty",
      "Wheatland",
      "Wilton",
      "Zcta 527hh",
      "Davenport",
      "Zcta 528hh",
      "",
    ],
    ZipCodes: {
      Ackworth: ["50001"],
      Adair: ["50002"],
      Adel: ["50003"],
      Albion: ["50005"],
      Alden: ["50006"],
      Alleman: ["50007"],
      Allerton: ["50008"],
      Altoona: ["50009"],
      Ames: ["50010", "50014"],
      Anita: ["50020"],
      Ankeny: ["50021"],
      Atlantic: ["50022"],
      Audubon: ["50025"],
      Bagley: ["50026"],
      "Barnes city": ["50027"],
      Baxter: ["50028"],
      Bayard: ["50029"],
      Berwick: ["50032"],
      Bevington: ["50033"],
      Blairsburg: ["50034"],
      Bondurant: ["50035"],
      Boone: ["50036"],
      Booneville: ["50038"],
      Bouton: ["50039"],
      Boxholm: ["50040"],
      Bradford: ["50041"],
      Brayton: ["50042"],
      Bussey: ["50044"],
      Cambridge: ["50046"],
      Carlisle: ["50047"],
      Casey: ["50048"],
      Chariton: ["50049"],
      Churdan: ["50050"],
      Clemons: ["50051"],
      Clio: ["50052"],
      Colfax: ["50054"],
      Collins: ["50055"],
      Colo: ["50056"],
      Columbia: ["50057"],
      "Coon rapids": ["50058"],
      Cooper: ["50059"],
      Sewal: ["50060"],
      Cumming: ["50061"],
      Dallas: ["50062"],
      "Dallas center": ["50063"],
      Dana: ["50064"],
      Pleasanton: ["50065"],
      Dawson: ["50066"],
      Decatur: ["50067"],
      Derby: ["50068"],
      "De soto": ["50069"],
      Dexter: ["50070"],
      Dows: ["50071"],
      Earlham: ["50072"],
      Elkhart: ["50073"],
      Ellston: ["50074"],
      Ellsworth: ["50075"],
      Exira: ["50076"],
      Ferguson: ["50078"],
      "Zcta 500hh": ["500HH"],
      Galt: ["50101"],
      "Garden city": ["50102"],
      "Garden grove": ["50103"],
      Gibson: ["50104"],
      Gilbert: ["50105"],
      Gilman: ["50106"],
      "Grand junction": ["50107"],
      "Grand river": ["50108"],
      Granger: ["50109"],
      Gray: ["50110"],
      Grimes: ["50111"],
      Grinnell: ["50112"],
      "Guthrie center": ["50115"],
      Hamilton: ["50116"],
      Hamlin: ["50117"],
      Hartford: ["50118"],
      Harvey: ["50119"],
      Haverhill: ["50120"],
      Hubbard: ["50122"],
      Humeston: ["50123"],
      Huxley: ["50124"],
      "Spring hill": ["50125"],
      "Iowa falls": ["50126"],
      Jamaica: ["50128"],
      Jefferson: ["50129"],
      Jewell: ["50130"],
      Johnston: ["50131"],
      Kamrar: ["50132"],
      Kellerton: ["50133"],
      Kelley: ["50134"],
      Kellogg: ["50135"],
      Keswick: ["50136"],
      Killduff: ["50137"],
      Knoxville: ["50138"],
      Lacona: ["50139"],
      Lamoni: ["50140"],
      Laurel: ["50141"],
      "Le grand": ["50142"],
      Leighton: ["50143"],
      Leon: ["50144"],
      "Liberty center": ["50145"],
      Linden: ["50146"],
      Lineville: ["50147"],
      Liscomb: ["50148"],
      Lorimor: ["50149"],
      Lovilia: ["50150"],
      Lucas: ["50151"],
      Luther: ["50152"],
      Lynnville: ["50153"],
      "Mc callsburg": ["50154"],
      Macksburg: ["50155"],
      Madrid: ["50156"],
      Malcom: ["50157"],
      Marshalltown: ["50158"],
      Martensdale: ["50160"],
      Maxwell: ["50161"],
      Melbourne: ["50162"],
      Menlo: ["50164"],
      Millerton: ["50165"],
      Milo: ["50166"],
      Minburn: ["50167"],
      Mingo: ["50168"],
      Mitchellville: ["50169"],
      Monroe: ["50170"],
      Montezuma: ["50171"],
      Montour: ["50173"],
      Murray: ["50174"],
      "Zcta 501hh": ["501HH"],
      Nevada: ["50201"],
      "New providence": ["50206"],
      "New sharon": ["50207"],
      Newton: ["50208"],
      "New virginia": ["50210"],
      Norwalk: ["50211"],
      Ogden: ["50212"],
      Osceola: ["50213"],
      Otley: ["50214"],
      Panora: ["50216"],
      Paton: ["50217"],
      Patterson: ["50218"],
      Pella: ["50219"],
      Perry: ["50220"],
      Peru: ["50222"],
      "Pilot mound": ["50223"],
      Pleasantville: ["50225"],
      "Polk city": ["50226"],
      Popejoy: ["50227"],
      "Prairie city": ["50228"],
      Prole: ["50229"],
      Radcliffe: ["50230"],
      Randall: ["50231"],
      Reasnor: ["50232"],
      Redfield: ["50233"],
      Rhodes: ["50234"],
      Rippey: ["50235"],
      Roland: ["50236"],
      Runnells: ["50237"],
      Russell: ["50238"],
      "Saint anthony": ["50239"],
      "Saint charles": ["50240"],
      "Saint marys": ["50241"],
      Searsboro: ["50242"],
      Sheldahl: ["50243"],
      Slater: ["50244"],
      Stanhope: ["50246"],
      "State center": ["50247"],
      "Story city": ["50248"],
      Stratford: ["50249"],
      Stuart: ["50250"],
      Sully: ["50251"],
      Swan: ["50252"],
      Thayer: ["50254"],
      Tracy: ["50256"],
      Truro: ["50257"],
      Union: ["50258"],
      "Van meter": ["50261"],
      "Van wert": ["50262"],
      Waukee: ["50263"],
      Weldon: ["50264"],
      "West des moines": ["50265", "50266"],
      "What cheer": ["50268"],
      Whitten: ["50269"],
      Williams: ["50271"],
      Williamson: ["50272"],
      Winterset: ["50273"],
      Wiota: ["50274"],
      Woodburn: ["50275"],
      Woodward: ["50276"],
      Yale: ["50277"],
      Zearing: ["50278"],
      "Zcta 502hh": ["502HH"],
      "Des moines": [
        "50309",
        "50310",
        "50312",
        "50313",
        "50314",
        "50315",
        "50316",
        "50320",
        "50321",
      ],
      "Windsor heights": ["50311"],
      "Pleasant hill": ["50317"],
      Urbandale: ["50322"],
      "Zcta 50323": ["50323"],
      Clive: ["50325"],
      "Zcta 503hh": ["503HH"],
      "Mason city": ["50401"],
      Alexander: ["50420"],
      Belmond: ["50421"],
      Britt: ["50423"],
      "Buffalo center": ["50424"],
      Carpenter: ["50426"],
      "Clear lake": ["50428"],
      Corwith: ["50430"],
      Coulter: ["50431"],
      "Crystal lake": ["50432"],
      Dougherty: ["50433"],
      Fertile: ["50434"],
      Floyd: ["50435"],
      "Forest city": ["50436"],
      Garner: ["50438"],
      Goodell: ["50439"],
      Grafton: ["50440"],
      Hampton: ["50441"],
      Hanlontown: ["50444"],
      Joice: ["50446"],
      Kanawha: ["50447"],
      Kensett: ["50448"],
      Klemme: ["50449"],
      "Lake mills": ["50450"],
      Lakota: ["50451"],
      Latimer: ["50452"],
      Leland: ["50453"],
      "Little cedar": ["50454"],
      "Mc intire": ["50455"],
      Manly: ["50456"],
      Meservey: ["50457"],
      "Nora springs": ["50458"],
      Northwood: ["50459"],
      Orchard: ["50460"],
      Osage: ["50461"],
      Plymouth: ["50464"],
      Rake: ["50465"],
      Riceville: ["50466"],
      Rockford: ["50468"],
      Rockwell: ["50469"],
      Rowan: ["50470"],
      Rudd: ["50471"],
      "Saint ansgar": ["50472"],
      Scarville: ["50473"],
      Sheffield: ["50475"],
      Stacyville: ["50476"],
      Swaledale: ["50477"],
      Thompson: ["50478"],
      Thornton: ["50479"],
      Titonka: ["50480"],
      Ventura: ["50482"],
      Wesley: ["50483"],
      Woden: ["50484"],
      "Zcta 504hh": ["504HH"],
      "Fort dodge": ["50501"],
      "Albert city": ["50510"],
      Algona: ["50511"],
      Armstrong: ["50514"],
      Ayrshire: ["50515"],
      Badger: ["50516"],
      Bancroft: ["50517"],
      Barnum: ["50518"],
      Bode: ["50519"],
      Bradgate: ["50520"],
      Burnside: ["50521"],
      Burt: ["50522"],
      Callender: ["50523"],
      Clare: ["50524"],
      Clarion: ["50525"],
      Curlew: ["50527"],
      Cylinder: ["50528"],
      "Dakota city": ["50529"],
      Dayton: ["50530"],
      Dolliver: ["50531"],
      Duncombe: ["50532"],
      "Eagle grove": ["50533"],
      Early: ["50535"],
      Emmetsburg: ["50536"],
      Farnhamville: ["50538"],
      Fenton: ["50539"],
      Fonda: ["50540"],
      "Gilmore city": ["50541"],
      Goldfield: ["50542"],
      Gowrie: ["50543"],
      Harcourt: ["50544"],
      Hardy: ["50545"],
      Havelock: ["50546"],
      Humboldt: ["50548"],
      Jolley: ["50551"],
      Knierim: ["50552"],
      Laurens: ["50554"],
      Ledyard: ["50556"],
      Lehigh: ["50557"],
      Livermore: ["50558"],
      "Lone rock": ["50559"],
      "Lu verne": ["50560"],
      Lytton: ["50561"],
      Mallard: ["50562"],
      Manson: ["50563"],
      Marathon: ["50565"],
      Moorland: ["50566"],
      Nemaha: ["50567"],
      Newell: ["50568"],
      Otho: ["50569"],
      Ottosen: ["50570"],
      Palmer: ["50571"],
      Plover: ["50573"],
      Pocahontas: ["50574"],
      Pomeroy: ["50575"],
      Rembrandt: ["50576"],
      Renwick: ["50577"],
      Ringsted: ["50578"],
      "Rockwell city": ["50579"],
      Rolfe: ["50581"],
      Rutland: ["50582"],
      "Sac city": ["50583"],
      "Sioux rapids": ["50585"],
      Somers: ["50586"],
      "Storm lake": ["50588"],
      "Swea city": ["50590"],
      Thor: ["50591"],
      Truesdale: ["50592"],
      Varina: ["50593"],
      Vincent: ["50594"],
      "Webster city": ["50595"],
      "West bend": ["50597"],
      Whittemore: ["50598"],
      Woolstock: ["50599"],
      "Zcta 505hh": ["505HH"],
      Ackley: ["50601"],
      Allison: ["50602"],
      "Alta vista": ["50603"],
      Aplington: ["50604"],
      Aredale: ["50605"],
      Arlington: ["50606"],
      Aurora: ["50607"],
      Beaman: ["50609"],
      Bristow: ["50611"],
      Buckingham: ["50612"],
      "Cedar falls": ["50613"],
      "Charles city": ["50616"],
      Clarksville: ["50619"],
      Conrad: ["50621"],
      Denver: ["50622"],
      Dewar: ["50623"],
      Dike: ["50624"],
      Dumont: ["50625"],
      Dunkerton: ["50626"],
      Eldora: ["50627"],
      Elma: ["50628"],
      Fairbank: ["50629"],
      Fredericksburg: ["50630"],
      Frederika: ["50631"],
      Garwin: ["50632"],
      Geneva: ["50633"],
      Gilbertville: ["50634"],
      Gladbrook: ["50635"],
      Greene: ["50636"],
      "Grundy center": ["50638"],
      Hazleton: ["50641"],
      Holland: ["50642"],
      Hudson: ["50643"],
      Independence: ["50644"],
      Ionia: ["50645"],
      Janesville: ["50647"],
      Jesup: ["50648"],
      Kesley: ["50649"],
      Lamont: ["50650"],
      "La porte city": ["50651"],
      Lincoln: ["50652"],
      "Marble rock": ["50653"],
      Masonville: ["50654"],
      Maynard: ["50655"],
      Nashua: ["50658"],
      "New hampton": ["50659"],
      "New hartford": ["50660"],
      Oelwein: ["50662"],
      Parkersburg: ["50665"],
      Plainfield: ["50666"],
      Raymond: ["50667"],
      Readlyn: ["50668"],
      Reinbeck: ["50669"],
      "Shell rock": ["50670"],
      Stanley: ["50671"],
      "Steamboat rock": ["50672"],
      Stout: ["50673"],
      Sumner: ["50674"],
      Traer: ["50675"],
      Tripoli: ["50676"],
      Bremer: ["50677"],
      Wellsburg: ["50680"],
      Westgate: ["50681"],
      Winthrop: ["50682"],
      "Zcta 506hh": ["506HH"],
      Waterloo: ["50701", "50702", "50703"],
      Washburn: ["50706"],
      Evansdale: ["50707"],
      "Zcta 507hh": ["507HH"],
      Nevinville: ["50801"],
      Afton: ["50830"],
      Bedford: ["50833"],
      Benton: ["50835"],
      Blockton: ["50836"],
      Bridgewater: ["50837"],
      Clearfield: ["50840"],
      Corning: ["50841"],
      Cumberland: ["50843"],
      Diagonal: ["50845"],
      Fontanelle: ["50846"],
      Grant: ["50847"],
      Gravity: ["50848"],
      Greenfield: ["50849"],
      Lenox: ["50851"],
      Massena: ["50853"],
      "Mount ayr": ["50854"],
      Nodaway: ["50857"],
      Orient: ["50858"],
      Prescott: ["50859"],
      Redding: ["50860"],
      "Shannon city": ["50861"],
      Sharpsburg: ["50862"],
      Tingley: ["50863"],
      Villisca: ["50864"],
      Akron: ["51001"],
      Alta: ["51002"],
      Alton: ["51003"],
      Anthon: ["51004"],
      Aurelia: ["51005"],
      "Battle creek": ["51006"],
      Bronson: ["51007"],
      Brunsville: ["51008"],
      Castana: ["51010"],
      Chatsworth: ["51011"],
      Cherokee: ["51012"],
      Cleghorn: ["51014"],
      Correctionville: ["51016"],
      Cushing: ["51018"],
      Danbury: ["51019"],
      Galva: ["51020"],
      Granville: ["51022"],
      Hawarden: ["51023"],
      Hinton: ["51024"],
      Holstein: ["51025"],
      Hornick: ["51026"],
      Ireton: ["51027"],
      Kingsley: ["51028"],
      Larrabee: ["51029"],
      Lawton: ["51030"],
      "Le mars": ["51031"],
      "Linn grove": ["51033"],
      Mapleton: ["51034"],
      Marcus: ["51035"],
      Maurice: ["51036"],
      Meriden: ["51037"],
      Merrill: ["51038"],
      Moville: ["51039"],
      Onawa: ["51040"],
      "Orange city": ["51041"],
      Oto: ["51044"],
      Oyens: ["51045"],
      Paullina: ["51046"],
      Peterson: ["51047"],
      Pierson: ["51048"],
      Quimby: ["51049"],
      Remsen: ["51050"],
      Rodney: ["51051"],
      Salix: ["51052"],
      Schaller: ["51053"],
      "Sergeant bluff": ["51054"],
      Sloan: ["51055"],
      Smithland: ["51056"],
      Sutherland: ["51058"],
      Turin: ["51059"],
      Ute: ["51060"],
      Washta: ["51061"],
      Westfield: ["51062"],
      Whiting: ["51063"],
      "Zcta 510hh": ["510HH"],
      "Sioux city": [
        "51101",
        "51103",
        "51104",
        "51105",
        "51106",
        "51108",
        "51109",
        "51111",
      ],
      "Zcta 511hh": ["511HH"],
      Sheldon: ["51201"],
      Alvord: ["51230"],
      Archer: ["51231"],
      Ashton: ["51232"],
      Boyden: ["51234"],
      Doon: ["51235"],
      George: ["51237"],
      Hospers: ["51238"],
      Hull: ["51239"],
      Inwood: ["51240"],
      Larchwood: ["51241"],
      Lester: ["51242"],
      "Little rock": ["51243"],
      Matlock: ["51244"],
      Primghar: ["51245"],
      "Rock rapids": ["51246"],
      "Rock valley": ["51247"],
      Sanborn: ["51248"],
      Sibley: ["51249"],
      "Sioux center": ["51250"],
      Spencer: ["51301"],
      "Arnolds park": ["51331"],
      Dickens: ["51333"],
      Estherville: ["51334"],
      Everly: ["51338"],
      "Gillett grove": ["51341"],
      Graettinger: ["51342"],
      Greenville: ["51343"],
      Gruver: ["51344"],
      Harris: ["51345"],
      Hartley: ["51346"],
      "Lake park": ["51347"],
      "May city": ["51349"],
      Melvin: ["51350"],
      Milford: ["51351"],
      Ocheyedan: ["51354"],
      Okoboji: ["51355"],
      Royal: ["51357"],
      Ruthven: ["51358"],
      "Spirit lake": ["51360"],
      Superior: ["51363"],
      Terril: ["51364"],
      Wallingford: ["51365"],
      Webb: ["51366"],
      "Zcta 513hh": ["513HH"],
      Carroll: ["51401"],
      Arcadia: ["51430"],
      Arthur: ["51431"],
      Yetter: ["51433"],
      Breda: ["51436"],
      "Charter oak": ["51439"],
      Dedham: ["51440"],
      Deloit: ["51441"],
      Denison: ["51442"],
      Glidden: ["51443"],
      Halbur: ["51444"],
      "Ida grove": ["51445"],
      Irwin: ["51446"],
      Kirkman: ["51447"],
      Kiron: ["51448"],
      "Lake city": ["51449"],
      "Lake view": ["51450"],
      Lanesboro: ["51451"],
      Lidderdale: ["51452"],
      Lohrville: ["51453"],
      Manilla: ["51454"],
      Manning: ["51455"],
      Odebolt: ["51458"],
      Ralston: ["51459"],
      Schleswig: ["51461"],
      Scranton: ["51462"],
      Templeton: ["51463"],
      Vail: ["51465"],
      "Wall lake": ["51466"],
      Westside: ["51467"],
      "Zcta 514hh": ["514HH"],
      Manawa: ["51501"],
      "Council bluffs": ["51502", "51503"],
      "Carter lake": ["51510"],
      Arion: ["51520"],
      Avoca: ["51521"],
      Blencoe: ["51523"],
      Carson: ["51525"],
      Crescent: ["51526"],
      Earling: ["51527", "51529", "51530"],
      "Dow city": ["51528"],
      "Elk horn": ["51531"],
      Elliott: ["51532"],
      Emerson: ["51533"],
      Glenwood: ["51534"],
      Griswold: ["51535"],
      Hancock: ["51536"],
      Harlan: ["51537"],
      Hastings: ["51540"],
      Henderson: ["51541"],
      "Honey creek": ["51542"],
      Kimballton: ["51543"],
      Lewis: ["51544"],
      "Little sioux": ["51545"],
      Logan: ["51546"],
      "Mc clelland": ["51548"],
      Macedonia: ["51549"],
      Magnolia: ["51550"],
      Malvern: ["51551"],
      Marne: ["51552"],
      Minden: ["51553"],
      Mineola: ["51554"],
      "Missouri valley": ["51555"],
      Modale: ["51556"],
      Mondamin: ["51557"],
      Moorhead: ["51558"],
      Neola: ["51559"],
      Oakland: ["51560"],
      "Pacific junction": ["51561"],
      Panama: ["51562"],
      Persia: ["51563"],
      Pisgah: ["51564"],
      Portsmouth: ["51565"],
      "Red oak": ["51566"],
      Shelby: ["51570"],
      "Silver city": ["51571"],
      Soldier: ["51572"],
      Stanton: ["51573"],
      Tennant: ["51574"],
      Treynor: ["51575"],
      Underwood: ["51576"],
      Walnut: ["51577"],
      Westphalia: ["51578"],
      Woodbine: ["51579"],
      "Zcta 515hh": ["515HH"],
      Shenandoah: ["51601"],
      Blanchard: ["51630"],
      Braddyville: ["51631"],
      Clarinda: ["51632"],
      Coin: ["51636"],
      "College springs": ["51637"],
      Essex: ["51638"],
      Farragut: ["51639"],
      Hamburg: ["51640"],
      Imogene: ["51645"],
      "New market": ["51646"],
      Northboro: ["51647"],
      Percival: ["51648"],
      Randolph: ["51649"],
      Riverton: ["51650"],
      Sidney: ["51652"],
      Tabor: ["51653"],
      Thurman: ["51654"],
      "Zcta 516hh": ["516HH"],
      Dubuque: ["52001", "52002", "52003"],
      Andrew: ["52030"],
      Bellevue: ["52031"],
      Bernard: ["52032"],
      Cascade: ["52033"],
      Colesburg: ["52035"],
      Delaware: ["52036"],
      Delmar: ["52037"],
      Dundee: ["52038"],
      Durango: ["52039"],
      Dyersville: ["52040"],
      Earlville: ["52041"],
      Edgewood: ["52042"],
      Elkader: ["52043"],
      Elkport: ["52044"],
      Epworth: ["52045"],
      Farley: ["52046"],
      Farmersburg: ["52047"],
      Garber: ["52048"],
      Garnavillo: ["52049"],
      Greeley: ["52050"],
      Guttenberg: ["52052"],
      "Holy cross": ["52053"],
      "La motte": ["52054"],
      Luxemburg: ["52056"],
      Manchester: ["52057"],
      Maquoketa: ["52060"],
      Miles: ["52064"],
      "New vienna": ["52065"],
      "North buena vist": ["52066"],
      Peosta: ["52068"],
      Preston: ["52069"],
      Sabula: ["52070"],
      "Saint donatus": ["52071"],
      "Saint olaf": ["52072"],
      Sherrill: ["52073"],
      Spragueville: ["52074"],
      Springbrook: ["52075"],
      "Strawberry point": ["52076"],
      Volga: ["52077"],
      Worthington: ["52078"],
      Zwingle: ["52079"],
      "Zcta 520hh": ["520HH"],
      Decorah: ["52101"],
      Calmar: ["52132"],
      Castalia: ["52133"],
      Chester: ["52134"],
      Clermont: ["52135"],
      Cresco: ["52136"],
      Dorchester: ["52140"],
      Elgin: ["52141"],
      Fayette: ["52142"],
      "Fort atkinson": ["52144"],
      "Harpers ferry": ["52146"],
      Hawkeye: ["52147"],
      Lansing: ["52151"],
      Lawler: ["52154"],
      "Lime springs": ["52155"],
      Luana: ["52156"],
      "Mc gregor": ["52157"],
      Marquette: ["52158"],
      Monona: ["52159"],
      "New albin": ["52160"],
      Ossian: ["52161"],
      Postville: ["52162"],
      Protivin: ["52163"],
      Randalia: ["52164"],
      Ridgeway: ["52165"],
      "Saint lucas": ["52166"],
      Spillville: ["52168"],
      Wadena: ["52169"],
      Waterville: ["52170"],
      Waucoma: ["52171"],
      Waukon: ["52172"],
      Eldorado: ["52175"],
      "Zcta 521hh": ["521HH"],
      Ainsworth: ["52201"],
      Alburnett: ["52202"],
      Amana: ["52203"],
      Anamosa: ["52205"],
      Atkins: ["52206"],
      Baldwin: ["52207"],
      "Belle plaine": ["52208"],
      Blairstown: ["52209"],
      Brandon: ["52210"],
      Brooklyn: ["52211"],
      "Center junction": ["52212"],
      "Center point": ["52213"],
      "Central city": ["52214"],
      Chelsea: ["52215"],
      Clarence: ["52216"],
      Clutier: ["52217"],
      Coggon: ["52218"],
      Prairieburg: ["52219"],
      Conroy: ["52220"],
      "Zcta 52221": ["52221"],
      "Deep river": ["52222"],
      Delhi: ["52223"],
      Dysart: ["52224"],
      Elberon: ["52225"],
      Elwood: ["52226"],
      Ely: ["52227"],
      Fairfax: ["52228"],
      Garrison: ["52229"],
      Harper: ["52231"],
      Hartwick: ["52232"],
      Hiawatha: ["52233"],
      Hills: ["52235"],
      Homestead: ["52236"],
      Hopkinton: ["52237"],
      "Iowa city": ["52240", "52242", "52245", "52246"],
      Coralville: ["52241"],
      Kalona: ["52247"],
      Keota: ["52248"],
      Keystone: ["52249"],
      Ladora: ["52251"],
      Lisbon: ["52253"],
      "Lost nation": ["52254"],
      Lowden: ["52255"],
      Luzerne: ["52257"],
      "Zcta 522hh": ["522HH"],
      Marengo: ["52301"],
      Marion: ["52302"],
      Martelle: ["52305"],
      Mechanicsville: ["52306"],
      "Middle amana": ["52307"],
      Millersburg: ["52308"],
      Monmouth: ["52309"],
      Monticello: ["52310"],
      Morley: ["52312"],
      "Mount auburn": ["52313"],
      "Mount vernon": ["52314"],
      Newhall: ["52315"],
      "North english": ["52316"],
      "North liberty": ["52317"],
      Norway: ["52318"],
      Olin: ["52320"],
      Onslow: ["52321"],
      Oxford: ["52322"],
      "Oxford junction": ["52323"],
      Palo: ["52324"],
      Parnell: ["52325"],
      Quasqueton: ["52326"],
      Riverside: ["52327"],
      Robins: ["52328"],
      Rowley: ["52329"],
      Ryan: ["52330"],
      Shellsburg: ["52332"],
      Solon: ["52333"],
      "South amana": ["52334"],
      "South english": ["52335"],
      Springville: ["52336"],
      Stanwood: ["52337"],
      Swisher: ["52338"],
      Tama: ["52339"],
      Tiffin: ["52340"],
      Toddville: ["52341"],
      Toledo: ["52342"],
      Urbana: ["52345"],
      "Van horne": ["52346"],
      Victor: ["52347"],
      Vining: ["52348"],
      Vinton: ["52349"],
      Walford: ["52351"],
      Walker: ["52352"],
      Washington: ["52353"],
      Watkins: ["52354"],
      Webster: ["52355"],
      Wellman: ["52356"],
      "West branch": ["52358"],
      "West chester": ["52359"],
      Williamsburg: ["52361"],
      Wyoming: ["52362"],
      "Zcta 523hh": ["523HH"],
      "Cedar rapids": ["52401", "52402", "52403", "52404", "52405", "52411"],
      "Zcta 524hh": ["524HH"],
      "Highland center": ["52501"],
      Agency: ["52530"],
      Albia: ["52531"],
      Batavia: ["52533"],
      Beacon: ["52534"],
      Birmingham: ["52535"],
      Blakesburg: ["52536"],
      Bloomfield: ["52537"],
      Brighton: ["52540"],
      Cantril: ["52542"],
      Cedar: ["52543"],
      Centerville: ["52544"],
      Chillicothe: ["52548"],
      Cincinnati: ["52549"],
      Delta: ["52550"],
      Douds: ["52551"],
      Drakesville: ["52552"],
      Eddyville: ["52553"],
      Eldon: ["52554"],
      Exline: ["52555"],
      Fairfield: ["52556"],
      Floris: ["52560"],
      Fremont: ["52561"],
      Hayesville: ["52562"],
      Hedrick: ["52563"],
      Keosauqua: ["52565"],
      Kirkville: ["52566"],
      Libertyville: ["52567"],
      Martinsburg: ["52568"],
      Melrose: ["52569"],
      Milton: ["52570"],
      Moravia: ["52571"],
      Moulton: ["52572"],
      "Mount sterling": ["52573"],
      Mystic: ["52574"],
      Ollie: ["52576"],
      Oskaloosa: ["52577"],
      Packwood: ["52580"],
      Plano: ["52581"],
      "Promise city": ["52583"],
      Pulaski: ["52584"],
      Richland: ["52585"],
      "Rose hill": ["52586"],
      Selma: ["52588"],
      Seymour: ["52590"],
      Sigourney: ["52591"],
      Udell: ["52593"],
      Unionville: ["52594"],
      "University park": ["52595"],
      "Zcta 525hh": ["525HH"],
      Burlington: ["52601"],
      Argyle: ["52619"],
      Bonaparte: ["52620"],
      Crawfordsville: ["52621"],
      Danville: ["52623"],
      Denmark: ["52624"],
      Donnellson: ["52625"],
      Farmington: ["52626"],
      "Fort madison": ["52627"],
      Hillsboro: ["52630"],
      Houghton: ["52631"],
      Keokuk: ["52632"],
      Lockridge: ["52635"],
      Mediapolis: ["52637"],
      Middletown: ["52638"],
      Montrose: ["52639"],
      "Morning sun": ["52640"],
      "Mount pleasant": ["52641"],
      "Mount union": ["52644"],
      "New london": ["52645"],
      Oakville: ["52646"],
      Olds: ["52647"],
      Salem: ["52649"],
      Sperry: ["52650"],
      Stockport: ["52651"],
      Swedesburg: ["52652"],
      Wapello: ["52653"],
      Wayland: ["52654"],
      "West burlington": ["52655"],
      "West point": ["52656"],
      Wever: ["52658"],
      Winfield: ["52659"],
      Yarmouth: ["52660"],
      "Zcta 526hh": ["526HH"],
      Andover: ["52701"],
      Atalissa: ["52720"],
      Bennett: ["52721"],
      Bettendorf: ["52722"],
      "Blue grass": ["52726"],
      Bryant: ["52727"],
      Buffalo: ["52728"],
      Calamus: ["52729"],
      Camanche: ["52730"],
      Charlotte: ["52731"],
      Clinton: ["52732"],
      "Columbus city": ["52737"],
      "Columbus junctio": ["52738"],
      Conesville: ["52739"],
      "De witt": ["52742"],
      "Big rock": ["52745"],
      Donahue: ["52746"],
      Durant: ["52747"],
      Eldridge: ["52748"],
      Fruitland: ["52749"],
      "Goose lake": ["52750"],
      "Grand mound": ["52751"],
      Grandview: ["52752"],
      "Le claire": ["52753"],
      Letts: ["52754"],
      "Lone tree": ["52755"],
      "Long grove": ["52756"],
      "Low moor": ["52757"],
      "Mc causland": ["52758"],
      Moscow: ["52760"],
      Muscatine: ["52761"],
      "New liberty": ["52765"],
      Nichols: ["52766"],
      "Pleasant valley": ["52767"],
      Princeton: ["52768"],
      Stockton: ["52769"],
      Tipton: ["52772"],
      Walcott: ["52773"],
      Welton: ["52774"],
      "West liberty": ["52776"],
      Wheatland: ["52777"],
      Wilton: ["52778"],
      "Zcta 527hh": ["527HH"],
      Davenport: ["52801", "52802", "52803", "52804", "52806", "52807"],
      "Zcta 528hh": ["528HH"],
      "": ["55954", "56027", "56129"],
    },
  },
  {
    abbrevation: "KS",
    name: "Kansas",
    Cities: [
      "Atchison",
      "Atchison",
      "Baldwin city",
      "Basehor",
      "Bendena",
      "Blue mound",
      "Lake of the fore",
      "Bucyrus",
      "Centerville",
      "Colony",
      "Cummings",
      "Denton",
      "De soto",
      "Clearview city",
      "Easton",
      "Edgerton",
      "Effingham",
      "Elwood",
      "Eudora",
      "Fontana",
      "Fort leavenworth",
      "Gardner",
      "Garnett",
      "Greeley",
      "Highland",
      "Mildred",
      "La cygne",
      "Huron",
      "Lane",
      "Lansing",
      "Lawrence",
      "Leavenworth",
      "Lecompton",
      "Linwood",
      "Louisburg",
      "Mc louth",
      "Mound city",
      "Muscotah",
      "Nortonville",
      "Olathe",
      "Osawatomie",
      "Oskaloosa",
      "Ottawa",
      "Ozawkie",
      "Paola",
      "Parker",
      "Perry",
      "Pleasanton",
      "Pomona",
      "Potter",
      "Princeton",
      "Rantoul",
      "Richmond",
      "Spring hill",
      "Stilwell",
      "Tonganoxie",
      "Severance",
      "Valley falls",
      "Wathena",
      "Welda",
      "Wellsville",
      "Westphalia",
      "White cloud",
      "Williamsburg",
      "Winchester",
      "Zcta 660hh",
      "Kansas city",
      "Rosedale",
      "Lake quivira",
      "Zcta 661hh",
      "Countryside",
      "Shawnee",
      "Overland park",
      "Mission",
      "Leawood",
      "Shawnee mission",
      "Prairie village",
      "Lenexa",
      "Stanley",
      "Zcta 662hh",
      "Alma",
      "Auburn",
      "Axtell",
      "Baileyville",
      "Beattie",
      "Belvue",
      "Bern",
      "Berryton",
      "Blue rapids",
      "Bremen",
      "Burlingame",
      "Carbondale",
      "Centralia",
      "Circleville",
      "Corning",
      "Delia",
      "Denison",
      "Emmett",
      "Eskridge",
      "Everest",
      "Fairview",
      "Winifred",
      "Goff",
      "Grantville",
      "Harveyville",
      "Havensville",
      "Reserve",
      "Holton",
      "Home",
      "Horton",
      "Hoyt",
      "Junction city",
      "Fort riley",
      "Leonardville",
      "Louisville",
      "Lyndon",
      "Zcta 664hh",
      "Zcta 664xx",
      "Mc farland",
      "Manhattan",
      "Maple hill",
      "Marysville",
      "Mayetta",
      "Melvern",
      "Meriden",
      "Milford",
      "Morrill",
      "Netawaka",
      "Ogden",
      "Oketo",
      "Olsburg",
      "Duluth",
      "Oneida",
      "Osage city",
      "Overbrook",
      "Paxico",
      "Powhattan",
      "Quenemo",
      "Riley",
      "Leona",
      "Rossville",
      "Sabetha",
      "Saint george",
      "Saint marys",
      "Scranton",
      "Kelly",
      "Silver lake",
      "Soldier",
      "Summerfield",
      "Tecumseh",
      "Vassar",
      "Vliets",
      "Wakarusa",
      "Wamego",
      "Waterville",
      "Blaine",
      "Wetmore",
      "Whiting",
      "Randolph",
      "Zcta 665hh",
      "Zcta 665xx",
      "Topeka",
      "Pauline",
      "Zcta 666hh",
      "Hiattville",
      "Altoona",
      "Arcadia",
      "Arma",
      "Baxter springs",
      "Benedict",
      "Bronson",
      "Buffalo",
      "Chanute",
      "Cherokee",
      "Hallowell",
      "Elsmore",
      "Erie",
      "Farlington",
      "Franklin",
      "Lafontaine",
      "Fulton",
      "Galena",
      "Galesburg",
      "Garland",
      "Girard",
      "Hepler",
      "Humboldt",
      "Carlyle",
      "La harpe",
      "Mc cune",
      "Mapleton",
      "Moran",
      "Mulberry",
      "Neodesha",
      "Neosho falls",
      "New albany",
      "Opolis",
      "Piqua",
      "Radley",
      "Frontenac",
      "Prescott",
      "Redfield",
      "Riverton",
      "Saint paul",
      "Savonburg",
      "Carona",
      "Stark",
      "Thayer",
      "Toronto",
      "Treece",
      "Uniontown",
      "Walnut",
      "Lawton",
      "West mineral",
      "Yates center",
      "Zcta 667hh",
      "Emporia",
      "Admire",
      "Bushong",
      "Alta vista",
      "Americus",
      "Burdick",
      "Strawn",
      "Burns",
      "Cassoday",
      "Clements",
      "Cottonwood falls",
      "Dunlap",
      "Dwight",
      "Elmdale",
      "Florence",
      "Gridley",
      "Hamilton",
      "Hartford",
      "Lebo",
      "Le roy",
      "Antelope",
      "Lost springs",
      "Madison",
      "Marion",
      "Matfield green",
      "Neal",
      "Neosho rapids",
      "Olpe",
      "Peabody",
      "Reading",
      "Strong city",
      "Virgil",
      "Waverly",
      "White city",
      "Zcta 668hh",
      "Rice",
      "Agenda",
      "Athol",
      "Barnes",
      "Belleville",
      "Burr oak",
      "Clifton",
      "Clyde",
      "Courtland",
      "Cuba",
      "Esbon",
      "Formoso",
      "Greenleaf",
      "Haddam",
      "Hanover",
      "Hollenberg",
      "Jamestown",
      "Ionia",
      "Kensington",
      "Bellaire",
      "Linn",
      "Mahaska",
      "Mankato",
      "Morrowville",
      "Munden",
      "Narka",
      "Palmer",
      "Randall",
      "Republic",
      "Scandia",
      "Smith center",
      "Washington",
      "Webber",
      "Zcta 669hh",
      "Zcta 669xx",
      "Andale",
      "Andover",
      "Anthony",
      "Argonia",
      "Arkansas city",
      "Atlanta",
      "Attica",
      "Augusta",
      "Beaumont",
      "Belle plaine",
      "Bentley",
      "Benton",
      "Bluff city",
      "Burden",
      "Burrton",
      "Byers",
      "Caldwell",
      "Cambridge",
      "Cedar vale",
      "Cheney",
      "Clearwater",
      "Coats",
      "Coldwater",
      "Colwich",
      "Conway springs",
      "Penalosa",
      "Danville",
      "Derby",
      "Dexter",
      "Douglass",
      "Elbing",
      "El dorado",
      "Eureka",
      "Fall river",
      "Freeport",
      "Garden plain",
      "Geuda springs",
      "Goddard",
      "Goessel",
      "Greensburg",
      "Halstead",
      "Hardtner",
      "Harper",
      "Haviland",
      "Haysville",
      "Hazelton",
      "Hesston",
      "Hillsboro",
      "Isabel",
      "Iuka",
      "Kechi",
      "Belmont",
      "Kiowa",
      "Lake city",
      "Latham",
      "Lehigh",
      "Leon",
      "Zcta 670hh",
      "Zcta 670xx",
      "Maize",
      "Maple city",
      "Mayfield",
      "Medicine lodge",
      "Milan",
      "Milton",
      "Moundridge",
      "Mount hope",
      "Mullinville",
      "Mulvane",
      "Murdock",
      "Nashville",
      "Newton",
      "North newton",
      "Norwich",
      "Oxford",
      "Peck",
      "Piedmont",
      "Potwin",
      "Pratt",
      "Protection",
      "Rago",
      "Rock",
      "Rosalia",
      "Rose hill",
      "Sawyer",
      "Sedgwick",
      "Climax",
      "Sharon",
      "South haven",
      "Spivey",
      "Sun city",
      "Towanda",
      "Udall",
      "Valley center",
      "Viola",
      "Waldron",
      "Walton",
      "Wellington",
      "Whitewater",
      "Wilmore",
      "Winfield",
      "Zenda",
      "Zcta 671hh",
      "Wichita",
      "Eastborough",
      "Park city",
      "Bel aire",
      "Zcta 672hh",
      "Independence",
      "Altamont",
      "Bartlett",
      "Caney",
      "Chautauqua",
      "Cherryvale",
      "Chetopa",
      "Coffeyville",
      "Dearing",
      "Dennis",
      "Edna",
      "Elk city",
      "Elk falls",
      "Grenola",
      "Havana",
      "Howard",
      "Liberty",
      "Longton",
      "Moline",
      "Mound valley",
      "Niotaze",
      "Oswego",
      "Parsons",
      "Peru",
      "Sedan",
      "Sycamore",
      "Tyro",
      "Zcta 673hh",
      "Zcta 673xx",
      "Bavaria",
      "Abilene",
      "Assaria",
      "Aurora",
      "Barnard",
      "Scottsville",
      "Bennington",
      "Beverly",
      "Brookville",
      "Bushton",
      "Canton",
      "Cawker city",
      "Chapman",
      "Clay center",
      "Delphos",
      "Downs",
      "Durham",
      "Ellsworth",
      "Enterprise",
      "Falun",
      "Galva",
      "Geneseo",
      "Glasco",
      "Glen elder",
      "Green",
      "Gypsum",
      "Delavan",
      "Holyrood",
      "Hope",
      "Hunter",
      "Kanopolis",
      "Westfall",
      "Lindsborg",
      "Little river",
      "Longford",
      "Lorraine",
      "Conway",
      "Marquette",
      "Miltonvale",
      "Minneapolis",
      "Morganville",
      "New cambria",
      "Osborne",
      "Portis",
      "Ramona",
      "Simpson",
      "Solomon",
      "Sylvan grove",
      "Talmage",
      "Tampa",
      "Culver",
      "Tipton",
      "Wakefield",
      "Wilson",
      "Windom",
      "Woodbine",
      "Zcta 674hh",
      "Zcta 674xx",
      "Hutchinson",
      "Medora",
      "South hutchinson",
      "Abbyville",
      "Albert",
      "Alden",
      "Alexander",
      "Arlington",
      "Arnold",
      "Bazine",
      "Beeler",
      "Belpre",
      "Bison",
      "Brownell",
      "Buhler",
      "Burdett",
      "Chase",
      "Claflin",
      "Ellinwood",
      "Garfield",
      "Heizer",
      "Haven",
      "Susank",
      "Hudson",
      "Inman",
      "Kinsley",
      "La crosse",
      "Radium",
      "Lewis",
      "Liebenthal",
      "Lyons",
      "Mc cracken",
      "Macksville",
      "Nekoma",
      "Ness city",
      "Nickerson",
      "Offerle",
      "Galatia",
      "Partridge",
      "Pawnee rock",
      "Plevna",
      "Pretty prairie",
      "Ransom",
      "Raymond",
      "Rozel",
      "Rush center",
      "Saint john",
      "Stafford",
      "Sterling",
      "Sylvia",
      "Langdon",
      "Utica",
      "Zcta 675hh",
      "Zcta 675xx",
      "Antonino",
      "Agra",
      "Almena",
      "Alton",
      "Bogue",
      "Bunker hill",
      "Cedar",
      "Clayton",
      "Collyer",
      "Damar",
      "Dorrance",
      "Dresden",
      "Ellis",
      "Gaylord",
      "Glade",
      "Gorham",
      "Hill city",
      "Jennings",
      "Kirwin",
      "Densmore",
      "Logan",
      "Long island",
      "Lucas",
      "Luray",
      "Morland",
      "Natoma",
      "Norcatur",
      "Norton",
      "Ogallah",
      "Palco",
      "Paradise",
      "Penokee",
      "Pfeifer",
      "Phillipsburg",
      "Plainville",
      "Prairie view",
      "Russell",
      "Schoenchen",
      "Stockton",
      "Stuttgart",
      "Victoria",
      "Wa keeney",
      "Waldo",
      "Walker",
      "Woodston",
      "Zcta 676hh",
      "Zcta 676xx",
      "Colby",
      "Atwood",
      "Bird city",
      "Brewster",
      "Edson",
      "Gem",
      "Goodland",
      "Gove",
      "Grainfield",
      "Grinnell",
      "Herndon",
      "Hoxie",
      "Kanorado",
      "Levant",
      "Ludell",
      "Mc donald",
      "Monument",
      "Oakley",
      "Oberlin",
      "Park",
      "Quinter",
      "Menlo",
      "Wheeler",
      "Selden",
      "Sharon springs",
      "Wallace",
      "Weskan",
      "Winona",
      "Zcta 677hh",
      "Zcta 677xx",
      "Dodge city",
      "Ashland",
      "Bucklin",
      "Cimarron",
      "Coolidge",
      "Copeland",
      "Deerfield",
      "Alamota",
      "Englewood",
      "Ensign",
      "Ford",
      "Fowler",
      "Garden city",
      "Hanston",
      "Healy",
      "Holcomb",
      "Ingalls",
      "Jetmore",
      "Johnson",
      "Kendall",
      "Kismet",
      "Lakin",
      "Leoti",
      "Manter",
      "Modoc",
      "Meade",
      "Bloom",
      "Montezuma",
      "Pierceville",
      "Plains",
      "Satanta",
      "Friend",
      "Spearville",
      "Sublette",
      "Syracuse",
      "Tribune",
      "Ulysses",
      "Wright",
      "Zcta 678hh",
      "Zcta 678xx",
      "Liberal",
      "Elkhart",
      "Hugoton",
      "Moscow",
      "Richfield",
      "Rolla",
      "Zcta 679xx",
    ],
    ZipCodes: {
      Atchison: ["66002"],
      "Baldwin city": ["66006"],
      Basehor: ["66007"],
      Bendena: ["66008"],
      "Blue mound": ["66010"],
      "Lake of the fore": ["66012"],
      Bucyrus: ["66013"],
      Centerville: ["66014"],
      Colony: ["66015"],
      Cummings: ["66016"],
      Denton: ["66017"],
      "De soto": ["66018"],
      "Clearview city": ["66019"],
      Easton: ["66020"],
      Edgerton: ["66021"],
      Effingham: ["66023"],
      Elwood: ["66024"],
      Eudora: ["66025"],
      Fontana: ["66026"],
      "Fort leavenworth": ["66027"],
      Gardner: ["66030"],
      Garnett: ["66032"],
      Greeley: ["66033"],
      Highland: ["66035"],
      Mildred: ["66039"],
      "La cygne": ["66040"],
      Huron: ["66041"],
      Lane: ["66042"],
      Lansing: ["66043"],
      Lawrence: ["66044", "66046", "66047", "66049"],
      Leavenworth: ["66048"],
      Lecompton: ["66050"],
      Linwood: ["66052"],
      Louisburg: ["66053"],
      "Mc louth": ["66054"],
      "Mound city": ["66056"],
      Muscotah: ["66058"],
      Nortonville: ["66060"],
      Olathe: ["66061", "66062"],
      Osawatomie: ["66064"],
      Oskaloosa: ["66066"],
      Ottawa: ["66067"],
      Ozawkie: ["66070"],
      Paola: ["66071"],
      Parker: ["66072"],
      Perry: ["66073"],
      Pleasanton: ["66075"],
      Pomona: ["66076"],
      Potter: ["66077"],
      Princeton: ["66078"],
      Rantoul: ["66079"],
      Richmond: ["66080"],
      "Spring hill": ["66083"],
      Stilwell: ["66085"],
      Tonganoxie: ["66086"],
      Severance: ["66087"],
      "Valley falls": ["66088"],
      Wathena: ["66090"],
      Welda: ["66091"],
      Wellsville: ["66092"],
      Westphalia: ["66093"],
      "White cloud": ["66094"],
      Williamsburg: ["66095"],
      Winchester: ["66097"],
      "Zcta 660hh": ["660HH"],
      "Kansas city": [
        "66101",
        "66102",
        "66104",
        "66105",
        "66109",
        "66111",
        "66112",
        "66115",
        "66118",
      ],
      Rosedale: ["66103"],
      "Lake quivira": ["66106"],
      "Zcta 661hh": ["661HH"],
      Countryside: ["66202"],
      Shawnee: ["66203", "66216", "66217", "66218", "66226"],
      "Overland park": ["66204", "66212", "66213"],
      Mission: ["66205"],
      Leawood: ["66206", "66209", "66211"],
      "Shawnee mission": ["66207"],
      "Prairie village": ["66208"],
      Lenexa: ["66210", "66214", "66215", "66219", "66220", "66227"],
      Stanley: ["66221", "66223", "66224"],
      "Zcta 662hh": ["662HH"],
      Alma: ["66401"],
      Auburn: ["66402"],
      Axtell: ["66403"],
      Baileyville: ["66404"],
      Beattie: ["66406"],
      Belvue: ["66407"],
      Bern: ["66408"],
      Berryton: ["66409"],
      "Blue rapids": ["66411"],
      Bremen: ["66412"],
      Burlingame: ["66413"],
      Carbondale: ["66414"],
      Centralia: ["66415"],
      Circleville: ["66416"],
      Corning: ["66417"],
      Delia: ["66418"],
      Denison: ["66419"],
      Emmett: ["66422"],
      Eskridge: ["66423"],
      Everest: ["66424"],
      Fairview: ["66425"],
      Winifred: ["66427"],
      Goff: ["66428"],
      Grantville: ["66429"],
      Harveyville: ["66431"],
      Havensville: ["66432"],
      Reserve: ["66434"],
      Holton: ["66436"],
      Home: ["66438"],
      Horton: ["66439"],
      Hoyt: ["66440"],
      "Junction city": ["66441"],
      "Fort riley": ["66442"],
      Leonardville: ["66449"],
      Louisville: ["66450"],
      Lyndon: ["66451"],
      "Zcta 664hh": ["664HH"],
      "Zcta 664xx": ["664XX"],
      "Mc farland": ["66501"],
      Manhattan: ["66502", "66503", "66506"],
      "Maple hill": ["66507"],
      Marysville: ["66508"],
      Mayetta: ["66509"],
      Melvern: ["66510"],
      Meriden: ["66512"],
      Milford: ["66514"],
      Morrill: ["66515"],
      Netawaka: ["66516"],
      Ogden: ["66517"],
      Oketo: ["66518"],
      Olsburg: ["66520"],
      Duluth: ["66521"],
      Oneida: ["66522"],
      "Osage city": ["66523"],
      Overbrook: ["66524"],
      Paxico: ["66526"],
      Powhattan: ["66527"],
      Quenemo: ["66528"],
      Riley: ["66531"],
      Leona: ["66532"],
      Rossville: ["66533"],
      Sabetha: ["66534"],
      "Saint george": ["66535"],
      "Saint marys": ["66536"],
      Scranton: ["66537"],
      Kelly: ["66538"],
      "Silver lake": ["66539"],
      Soldier: ["66540"],
      Summerfield: ["66541"],
      Tecumseh: ["66542"],
      Vassar: ["66543"],
      Vliets: ["66544"],
      Wakarusa: ["66546"],
      Wamego: ["66547"],
      Waterville: ["66548"],
      Blaine: ["66549"],
      Wetmore: ["66550"],
      Whiting: ["66552"],
      Randolph: ["66554"],
      "Zcta 665hh": ["665HH"],
      "Zcta 665xx": ["665XX"],
      Topeka: [
        "66603",
        "66604",
        "66605",
        "66606",
        "66607",
        "66608",
        "66609",
        "66610",
        "66611",
        "66612",
        "66614",
        "66615",
        "66616",
        "66617",
        "66618",
      ],
      Pauline: ["66619"],
      "Zcta 666hh": ["666HH"],
      Hiattville: ["66701"],
      Altoona: ["66710"],
      Arcadia: ["66711"],
      Arma: ["66712"],
      "Baxter springs": ["66713"],
      Benedict: ["66714"],
      Bronson: ["66716"],
      Buffalo: ["66717"],
      Chanute: ["66720"],
      Cherokee: ["66724"],
      Hallowell: ["66725"],
      Elsmore: ["66732"],
      Erie: ["66733"],
      Farlington: ["66734"],
      Franklin: ["66735"],
      Lafontaine: ["66736"],
      Fulton: ["66738"],
      Galena: ["66739"],
      Galesburg: ["66740"],
      Garland: ["66741"],
      Girard: ["66743"],
      Hepler: ["66746"],
      Humboldt: ["66748"],
      Carlyle: ["66749"],
      "La harpe": ["66751"],
      "Mc cune": ["66753"],
      Mapleton: ["66754"],
      Moran: ["66755"],
      Mulberry: ["66756"],
      Neodesha: ["66757"],
      "Neosho falls": ["66758"],
      "New albany": ["66759"],
      Opolis: ["66760"],
      Piqua: ["66761"],
      Radley: ["66762"],
      Frontenac: ["66763"],
      Prescott: ["66767"],
      Redfield: ["66769"],
      Riverton: ["66770"],
      "Saint paul": ["66771"],
      Savonburg: ["66772"],
      Carona: ["66773"],
      Stark: ["66775"],
      Thayer: ["66776"],
      Toronto: ["66777"],
      Treece: ["66778"],
      Uniontown: ["66779"],
      Walnut: ["66780"],
      Lawton: ["66781"],
      "West mineral": ["66782"],
      "Yates center": ["66783"],
      "Zcta 667hh": ["667HH"],
      Emporia: ["66801"],
      Admire: ["66830"],
      Bushong: ["66833"],
      "Alta vista": ["66834"],
      Americus: ["66835"],
      Burdick: ["66838"],
      Strawn: ["66839"],
      Burns: ["66840"],
      Cassoday: ["66842"],
      Clements: ["66843"],
      "Cottonwood falls": ["66845"],
      Dunlap: ["66846"],
      Dwight: ["66849"],
      Elmdale: ["66850"],
      Florence: ["66851"],
      Gridley: ["66852"],
      Hamilton: ["66853"],
      Hartford: ["66854"],
      Lebo: ["66856"],
      "Le roy": ["66857"],
      Antelope: ["66858"],
      "Lost springs": ["66859"],
      Madison: ["66860"],
      Marion: ["66861"],
      "Matfield green": ["66862"],
      Neal: ["66863"],
      "Neosho rapids": ["66864"],
      Olpe: ["66865"],
      Peabody: ["66866"],
      Reading: ["66868"],
      "Strong city": ["66869"],
      Virgil: ["66870"],
      Waverly: ["66871"],
      "White city": ["66872"],
      "Zcta 668hh": ["668HH"],
      Rice: ["66901"],
      Agenda: ["66930"],
      Athol: ["66932"],
      Barnes: ["66933"],
      Belleville: ["66935"],
      "Burr oak": ["66936"],
      Clifton: ["66937"],
      Clyde: ["66938"],
      Courtland: ["66939"],
      Cuba: ["66940"],
      Esbon: ["66941"],
      Formoso: ["66942"],
      Greenleaf: ["66943"],
      Haddam: ["66944"],
      Hanover: ["66945"],
      Hollenberg: ["66946"],
      Jamestown: ["66948"],
      Ionia: ["66949"],
      Kensington: ["66951"],
      Bellaire: ["66952"],
      Linn: ["66953"],
      Mahaska: ["66955"],
      Mankato: ["66956"],
      Morrowville: ["66958"],
      Munden: ["66959"],
      Narka: ["66960"],
      Palmer: ["66962"],
      Randall: ["66963"],
      Republic: ["66964"],
      Scandia: ["66966"],
      "Smith center": ["66967"],
      Washington: ["66968"],
      Webber: ["66970"],
      "Zcta 669hh": ["669HH"],
      "Zcta 669xx": ["669XX"],
      Andale: ["67001"],
      Andover: ["67002"],
      Anthony: ["67003"],
      Argonia: ["67004"],
      "Arkansas city": ["67005"],
      Atlanta: ["67008"],
      Attica: ["67009"],
      Augusta: ["67010"],
      Beaumont: ["67012"],
      "Belle plaine": ["67013"],
      Bentley: ["67016"],
      Benton: ["67017"],
      "Bluff city": ["67018"],
      Burden: ["67019"],
      Burrton: ["67020"],
      Byers: ["67021"],
      Caldwell: ["67022"],
      Cambridge: ["67023"],
      "Cedar vale": ["67024"],
      Cheney: ["67025"],
      Clearwater: ["67026"],
      Coats: ["67028"],
      Coldwater: ["67029"],
      Colwich: ["67030"],
      "Conway springs": ["67031"],
      Penalosa: ["67035"],
      Danville: ["67036"],
      Derby: ["67037"],
      Dexter: ["67038"],
      Douglass: ["67039"],
      Elbing: ["67041"],
      "El dorado": ["67042"],
      Eureka: ["67045"],
      "Fall river": ["67047"],
      Freeport: ["67049"],
      "Garden plain": ["67050"],
      "Geuda springs": ["67051"],
      Goddard: ["67052"],
      Goessel: ["67053"],
      Greensburg: ["67054"],
      Halstead: ["67056"],
      Hardtner: ["67057"],
      Harper: ["67058"],
      Haviland: ["67059"],
      Haysville: ["67060"],
      Hazelton: ["67061"],
      Hesston: ["67062"],
      Hillsboro: ["67063"],
      Isabel: ["67065"],
      Iuka: ["67066"],
      Kechi: ["67067"],
      Belmont: ["67068"],
      Kiowa: ["67070"],
      "Lake city": ["67071"],
      Latham: ["67072"],
      Lehigh: ["67073"],
      Leon: ["67074"],
      "Zcta 670hh": ["670HH"],
      "Zcta 670xx": ["670XX"],
      Maize: ["67101"],
      "Maple city": ["67102"],
      Mayfield: ["67103"],
      "Medicine lodge": ["67104"],
      Milan: ["67105"],
      Milton: ["67106"],
      Moundridge: ["67107"],
      "Mount hope": ["67108"],
      Mullinville: ["67109"],
      Mulvane: ["67110"],
      Murdock: ["67111"],
      Nashville: ["67112"],
      Newton: ["67114"],
      "North newton": ["67117"],
      Norwich: ["67118"],
      Oxford: ["67119"],
      Peck: ["67120"],
      Piedmont: ["67122"],
      Potwin: ["67123"],
      Pratt: ["67124"],
      Protection: ["67127"],
      Rago: ["67128"],
      Rock: ["67131"],
      Rosalia: ["67132"],
      "Rose hill": ["67133"],
      Sawyer: ["67134"],
      Sedgwick: ["67135"],
      Climax: ["67137"],
      Sharon: ["67138"],
      "South haven": ["67140"],
      Spivey: ["67142"],
      "Sun city": ["67143"],
      Towanda: ["67144"],
      Udall: ["67146"],
      "Valley center": ["67147"],
      Viola: ["67149"],
      Waldron: ["67150"],
      Walton: ["67151"],
      Wellington: ["67152"],
      Whitewater: ["67154"],
      Wilmore: ["67155"],
      Winfield: ["67156"],
      Zenda: ["67159"],
      "Zcta 671hh": ["671HH"],
      Wichita: [
        "67202",
        "67203",
        "67204",
        "67205",
        "67208",
        "67209",
        "67210",
        "67211",
        "67212",
        "67213",
        "67214",
        "67215",
        "67216",
        "67217",
        "67218",
        "67226",
        "67230",
        "67233",
        "67235",
      ],
      Eastborough: ["67206", "67207"],
      "Park city": ["67219"],
      "Bel aire": ["67220"],
      "Zcta 672hh": ["672HH"],
      Independence: ["67301"],
      Altamont: ["67330"],
      Bartlett: ["67332"],
      Caney: ["67333"],
      Chautauqua: ["67334"],
      Cherryvale: ["67335"],
      Chetopa: ["67336"],
      Coffeyville: ["67337"],
      Dearing: ["67340"],
      Dennis: ["67341"],
      Edna: ["67342"],
      "Elk city": ["67344"],
      "Elk falls": ["67345"],
      Grenola: ["67346"],
      Havana: ["67347"],
      Howard: ["67349"],
      Liberty: ["67351"],
      Longton: ["67352"],
      Moline: ["67353"],
      "Mound valley": ["67354"],
      Niotaze: ["67355"],
      Oswego: ["67356"],
      Parsons: ["67357"],
      Peru: ["67360"],
      Sedan: ["67361"],
      Sycamore: ["67363"],
      Tyro: ["67364"],
      "Zcta 673hh": ["673HH"],
      "Zcta 673xx": ["673XX"],
      Bavaria: ["67401"],
      Abilene: ["67410"],
      Assaria: ["67416"],
      Aurora: ["67417"],
      Barnard: ["67418"],
      Scottsville: ["67420"],
      Bennington: ["67422"],
      Beverly: ["67423"],
      Brookville: ["67425"],
      Bushton: ["67427"],
      Canton: ["67428"],
      "Cawker city": ["67430"],
      Chapman: ["67431"],
      "Clay center": ["67432"],
      Delphos: ["67436"],
      Downs: ["67437"],
      Durham: ["67438"],
      Ellsworth: ["67439"],
      Enterprise: ["67441"],
      Falun: ["67442"],
      Galva: ["67443"],
      Geneseo: ["67444"],
      Glasco: ["67445"],
      "Glen elder": ["67446"],
      Green: ["67447"],
      Gypsum: ["67448"],
      Delavan: ["67449"],
      Holyrood: ["67450"],
      Hope: ["67451"],
      Hunter: ["67452"],
      Kanopolis: ["67454"],
      Westfall: ["67455"],
      Lindsborg: ["67456"],
      "Little river": ["67457"],
      Longford: ["67458"],
      Lorraine: ["67459"],
      Conway: ["67460"],
      Marquette: ["67464"],
      Miltonvale: ["67466"],
      Minneapolis: ["67467"],
      Morganville: ["67468"],
      "New cambria": ["67470"],
      Osborne: ["67473"],
      Portis: ["67474"],
      Ramona: ["67475"],
      Simpson: ["67478"],
      Solomon: ["67480"],
      "Sylvan grove": ["67481"],
      Talmage: ["67482"],
      Tampa: ["67483"],
      Culver: ["67484"],
      Tipton: ["67485"],
      Wakefield: ["67487"],
      Wilson: ["67490"],
      Windom: ["67491"],
      Woodbine: ["67492"],
      "Zcta 674hh": ["674HH"],
      "Zcta 674xx": ["674XX"],
      Hutchinson: ["67501"],
      Medora: ["67502"],
      "South hutchinson": ["67505"],
      Abbyville: ["67510"],
      Albert: ["67511"],
      Alden: ["67512"],
      Alexander: ["67513"],
      Arlington: ["67514"],
      Arnold: ["67515"],
      Bazine: ["67516"],
      Beeler: ["67518"],
      Belpre: ["67519"],
      Bison: ["67520"],
      Brownell: ["67521"],
      Buhler: ["67522"],
      Burdett: ["67523"],
      Chase: ["67524"],
      Claflin: ["67525"],
      Ellinwood: ["67526"],
      Garfield: ["67529"],
      Heizer: ["67530"],
      Haven: ["67543"],
      Susank: ["67544"],
      Hudson: ["67545"],
      Inman: ["67546"],
      Kinsley: ["67547"],
      "La crosse": ["67548"],
      Radium: ["67550"],
      Lewis: ["67552"],
      Liebenthal: ["67553"],
      Lyons: ["67554"],
      "Mc cracken": ["67556"],
      Macksville: ["67557"],
      Nekoma: ["67559"],
      "Ness city": ["67560"],
      Nickerson: ["67561"],
      Offerle: ["67563"],
      Galatia: ["67564", "67565"],
      Partridge: ["67566"],
      "Pawnee rock": ["67567"],
      Plevna: ["67568"],
      "Pretty prairie": ["67570"],
      Ransom: ["67572"],
      Raymond: ["67573"],
      Rozel: ["67574"],
      "Rush center": ["67575"],
      "Saint john": ["67576"],
      Stafford: ["67578"],
      Sterling: ["67579"],
      Sylvia: ["67581"],
      Langdon: ["67583"],
      Utica: ["67584"],
      "Zcta 675hh": ["675HH"],
      "Zcta 675xx": ["675XX"],
      Antonino: ["67601"],
      Agra: ["67621"],
      Almena: ["67622"],
      Alton: ["67623"],
      Bogue: ["67625"],
      "Bunker hill": ["67626"],
      Cedar: ["67628"],
      Clayton: ["67629"],
      Collyer: ["67631"],
      Damar: ["67632"],
      Dorrance: ["67634"],
      Dresden: ["67635"],
      Ellis: ["67637"],
      Gaylord: ["67638"],
      Glade: ["67639"],
      Gorham: ["67640"],
      "Hill city": ["67642"],
      Jennings: ["67643"],
      Kirwin: ["67644"],
      Densmore: ["67645"],
      Logan: ["67646"],
      "Long island": ["67647"],
      Lucas: ["67648"],
      Luray: ["67649"],
      Morland: ["67650"],
      Natoma: ["67651"],
      Norcatur: ["67653"],
      Norton: ["67654"],
      Ogallah: ["67656"],
      Palco: ["67657"],
      Paradise: ["67658"],
      Penokee: ["67659"],
      Pfeifer: ["67660"],
      Phillipsburg: ["67661"],
      Plainville: ["67663"],
      "Prairie view": ["67664"],
      Russell: ["67665"],
      Schoenchen: ["67667"],
      Stockton: ["67669"],
      Stuttgart: ["67670"],
      Victoria: ["67671"],
      "Wa keeney": ["67672"],
      Waldo: ["67673"],
      Walker: ["67674"],
      Woodston: ["67675"],
      "Zcta 676hh": ["676HH"],
      "Zcta 676xx": ["676XX"],
      Colby: ["67701"],
      Atwood: ["67730"],
      "Bird city": ["67731"],
      Brewster: ["67732"],
      Edson: ["67733"],
      Gem: ["67734"],
      Goodland: ["67735"],
      Gove: ["67736"],
      Grainfield: ["67737"],
      Grinnell: ["67738"],
      Herndon: ["67739"],
      Hoxie: ["67740"],
      Kanorado: ["67741"],
      Levant: ["67743"],
      Ludell: ["67744"],
      "Mc donald": ["67745"],
      Monument: ["67747"],
      Oakley: ["67748"],
      Oberlin: ["67749"],
      Park: ["67751"],
      Quinter: ["67752"],
      Menlo: ["67753"],
      Wheeler: ["67756"],
      Selden: ["67757"],
      "Sharon springs": ["67758"],
      Wallace: ["67761"],
      Weskan: ["67762"],
      Winona: ["67764"],
      "Zcta 677hh": ["677HH"],
      "Zcta 677xx": ["677XX"],
      "Dodge city": ["67801"],
      Ashland: ["67831"],
      Bucklin: ["67834"],
      Cimarron: ["67835"],
      Coolidge: ["67836"],
      Copeland: ["67837"],
      Deerfield: ["67838"],
      Alamota: ["67839"],
      Englewood: ["67840"],
      Ensign: ["67841"],
      Ford: ["67842"],
      Fowler: ["67844"],
      "Garden city": ["67846"],
      Hanston: ["67849"],
      Healy: ["67850"],
      Holcomb: ["67851"],
      Ingalls: ["67853"],
      Jetmore: ["67854"],
      Johnson: ["67855"],
      Kendall: ["67857"],
      Kismet: ["67859"],
      Lakin: ["67860"],
      Leoti: ["67861"],
      Manter: ["67862"],
      Modoc: ["67863"],
      Meade: ["67864"],
      Bloom: ["67865"],
      Montezuma: ["67867"],
      Pierceville: ["67868"],
      Plains: ["67869"],
      Satanta: ["67870"],
      Friend: ["67871"],
      Spearville: ["67876"],
      Sublette: ["67877"],
      Syracuse: ["67878"],
      Tribune: ["67879"],
      Ulysses: ["67880"],
      Wright: ["67882"],
      "Zcta 678hh": ["678HH"],
      "Zcta 678xx": ["678XX"],
      Liberal: ["67901"],
      Elkhart: ["67950"],
      Hugoton: ["67951"],
      Moscow: ["67952"],
      Richfield: ["67953"],
      Rolla: ["67954"],
      "Zcta 679xx": ["679XX"],
    },
  },
  {
    abbrevation: "KY",
    name: "Kentucky",
    Cities: [
      "",
      "",
      "Bagdad",
      "Bardstown",
      "Bedford",
      "Bethlehem",
      "Bloomfield",
      "Bradfordsville",
      "Buckner",
      "Campbellsburg",
      "Chaplin",
      "Deatsville",
      "Crestwood",
      "Eminence",
      "Fairfield",
      "Finchville",
      "Fisherville",
      "Goshen",
      "La grange",
      "Lebanon",
      "Lockport",
      "Loretto",
      "Mackville",
      "Milton",
      "Mount eden",
      "Mount washington",
      "New castle",
      "Trappist",
      "New hope",
      "Pendleton",
      "Pewee valley",
      "Cropper",
      "Port royal",
      "Prospect",
      "Raywick",
      "Saint francis",
      "Shelbyville",
      "Simpsonville",
      "Smithfield",
      "Maud",
      "Sulphur",
      "Taylorsville",
      "Waddy",
      "Westport",
      "Willisburg",
      "Zcta 400hh",
      "Battletown",
      "Boston",
      "Brandenburg",
      "Brooks",
      "Cloverport",
      "Custer",
      "Ekron",
      "Fairdale",
      "Glen dean",
      "Fort knox",
      "Garfield",
      "Guston",
      "Mooleyville",
      "Locust hill",
      "Hudson",
      "Irvington",
      "Lebanon junction",
      "Mc daniels",
      "Muldraugh",
      "Payneville",
      "Radcliff",
      "Rineyville",
      "Se ree",
      "Shepherdsville",
      "Stephensport",
      "Union star",
      "Vine grove",
      "Webster",
      "West point",
      "Westview",
      "Zcta 401hh",
      "Zcta 401xx",
      "Louisville",
      "Saint matthews",
      "Shively",
      "Buechel",
      "Okolona",
      "Lyndon",
      "Anchorage",
      "Middletown",
      "Pleasure ridge p",
      "Valley station",
      "Fern creek",
      "Jeffersontown",
      "Zcta 402hh",
      "Burgin",
      "Carlisle",
      "Westbend",
      "Clearfield",
      "Denniston",
      "Scranton",
      "Georgetown",
      "Gravel switch",
      "Cornishville",
      "Hope",
      "Jinks",
      "Jeffersonville",
      "Keene",
      "Lawrenceburg",
      "Means",
      "Midway",
      "Millersburg",
      "Moorefield",
      "Morehead",
      "Mount sterling",
      "New liberty",
      "Nicholasville",
      "Olympia",
      "Owenton",
      "Owingsville",
      "Paris",
      "Perry park",
      "Sadieville",
      "Salt lick",
      "Bondville",
      "Sharpsburg",
      "Slade",
      "Stamping ground",
      "Patsey",
      "Versailles",
      "Bybee",
      "Korea",
      "High bridge",
      "Winchester",
      "Zcta 403hh",
      "Moores creek",
      "Berea",
      "Brodhead",
      "Crab orchard",
      "Danville",
      "Hustonville",
      "Junction city",
      "Kings mountain",
      "Lancaster",
      "Livingston",
      "Clover bottom",
      "Mc kinney",
      "Climax",
      "Orlando",
      "Paint lick",
      "Parksville",
      "Perryville",
      "Ravenna",
      "Richmond",
      "Sandgap",
      "Stanford",
      "Elias",
      "Waneta",
      "Waynesburg",
      "Zcta 404hh",
      "Lexington",
      "Zcta 405hh",
      "Hatton",
      "Corbin",
      "Symbol",
      "Emlyn",
      "Gray",
      "Keavy",
      "Lily",
      "Sasser",
      "London",
      "Rockholds",
      "Siler",
      "Pleasant view",
      "Woodbine",
      "Zcta 407hh",
      "Ages brookside",
      "Asher",
      "Baxter",
      "Benham",
      "Big laurel",
      "Lewis creek",
      "Calvin",
      "Crummies",
      "Chappell",
      "Coalgood",
      "Coldiron",
      "Cranks",
      "Cumberland",
      "Dayhoit",
      "Eolia",
      "Essie",
      "Louellen",
      "Gulston",
      "Chevrolet",
      "Helton",
      "Holmes mill",
      "Hoskinston",
      "Hulen",
      "Kenvir",
      "Lejunior",
      "Loyall",
      "Lynch",
      "Cubage",
      "Mozelle",
      "Partridge",
      "Pathfork",
      "Putney",
      "Smith",
      "Stinnett",
      "Totz",
      "Wallins creek",
      "Warbranch",
      "Zcta 408hh",
      "Zcta 408xx",
      "Arjay",
      "Artemus",
      "Bailey switch",
      "Beverly",
      "Big creek",
      "Bimble",
      "Bryants store",
      "Cannon",
      "Closplint",
      "Dewitt",
      "Salt gum",
      "Fonde",
      "Garrard",
      "Girdler",
      "Green road",
      "Heidrick",
      "Hinkle",
      "Kettle island",
      "Bright shade",
      "Middlesboro",
      "Oneida",
      "Callaway",
      "Roark",
      "Scalf",
      "Sextons creek",
      "Stoney fork",
      "Trosper",
      "Walker",
      "Woollum",
      "Zcta 409hh",
      "Alexandria",
      "Augusta",
      "Berry",
      "Brooksville",
      "Rabbit hash",
      "Butler",
      "California",
      "Carrollton",
      "Corinth",
      "Covington",
      "Rouse",
      "Latonia",
      "Ludlow",
      "Dixie",
      "Erlanger",
      "Crittenden",
      "Cynthiana",
      "Demossville",
      "Dover",
      "Dry ridge",
      "Ewing",
      "Falmouth",
      "Flemingsburg",
      "Florence",
      "Foster",
      "Germantown",
      "Ghent",
      "Glencoe",
      "Hebron",
      "Hillsboro",
      "Independence",
      "Jonesville",
      "Mays lick",
      "Limestone sq",
      "Melbourne",
      "Morning view",
      "Mount olivet",
      "Southgate",
      "Bellevue",
      "Dayton",
      "Fort thomas",
      "Newport",
      "Petersburg",
      "Sanders",
      "Silver grove",
      "Sparta",
      "Union",
      "Verona",
      "Wallingford",
      "Walton",
      "Warsaw",
      "Williamstown",
      "Worthville",
      "Zcta 410hh",
      "Westwood",
      "Ashland",
      "Argillite",
      "Blaine",
      "Catlettsburg",
      "Denton",
      "Head of grassy",
      "Firebrick",
      "Flatwoods",
      "Garrison",
      "Grahn",
      "Fultz",
      "Lynn",
      "Hitchins",
      "Isonville",
      "Martha",
      "Lawton",
      "Quincy",
      "Rush",
      "Raceland",
      "Saint paul",
      "Burke",
      "South portsmouth",
      "Maloneton",
      "Trinity",
      "Webbville",
      "Worthington",
      "Tollesboro",
      "Zcta 411hh",
      "Adams",
      "Beauty",
      "Boons camp",
      "Davella",
      "East point",
      "Elna",
      "Hagerhill",
      "Job",
      "Keaton",
      "Leander",
      "Clifford",
      "Lovely",
      "Lowmansville",
      "Meally",
      "Manila",
      "Nippa",
      "Laura",
      "River",
      "Sitka",
      "Barnetts creek",
      "Stambaugh",
      "Thelma",
      "Davisport",
      "Tutor key",
      "Ulysses",
      "Van lear",
      "Hode",
      "West van lear",
      "Wittensville",
      "Zcta 412hh",
      "Flat",
      "Bays",
      "Vada",
      "Morris fork",
      "Clayhole",
      "Grassy creek",
      "Canoe",
      "Lerose",
      "Hardshell",
      "Pine ridge",
      "Ricetown",
      "Rogers",
      "Rowdy",
      "Talbert",
      "Vancleve",
      "Vincent",
      "Whick",
      "Zoe",
      "Cannel city",
      "Elkfork",
      "Ezel",
      "Falcon",
      "Royalton",
      "Bethanna",
      "Blairs mill",
      "Zcta 414hh",
      "Broad bottom",
      "South williamson",
      "Ashcamp",
      "Belcher",
      "Belfry",
      "Canada",
      "Senterville",
      "Biggs",
      "Forest hills",
      "Freeburn",
      "Hardy",
      "Hellier",
      "Huddy",
      "Payne gap",
      "Jonancy",
      "Kimper",
      "Lick creek",
      "Mc andrews",
      "Mc carr",
      "Mc veigh",
      "Majestic",
      "Mouthcard",
      "Myra",
      "Phelps",
      "Phyllis",
      "Pinsonfork",
      "Fishtrap",
      "Ransom",
      "Regina",
      "Robinson creek",
      "Shelbiana",
      "Shelby gap",
      "Sidney",
      "Steele",
      "Stone",
      "Argo",
      "Toler",
      "Varney",
      "Etty",
      "Zcta 415hh",
      "Allen",
      "Auxier",
      "Banner",
      "Ligon",
      "Betsy layne",
      "Bevinsville",
      "Blue river",
      "Bypro",
      "Dana",
      "David",
      "Drift",
      "Dwale",
      "Eastern",
      "Garrett",
      "Grethel",
      "Waldo",
      "Harold",
      "Buckingham",
      "Elmrock",
      "Ivel",
      "Langley",
      "East mc dowell",
      "Hite",
      "Melvin",
      "Minnie",
      "Emma",
      "Printer",
      "Stanville",
      "Teaberry",
      "Tram",
      "Wayland",
      "Weeksbury",
      "Wheelwright",
      "Zcta 416hh",
      "Darfork",
      "Ary",
      "Bear branch",
      "Blue diamond",
      "Buckhorn",
      "Tribbey",
      "Busy",
      "Carrie",
      "Chavies",
      "Combs",
      "Confluence",
      "Ulvah",
      "Delphia",
      "Dice",
      "Dwarf",
      "Bearville",
      "Gays creek",
      "Happy",
      "Dryhill",
      "Jeff",
      "Napfor",
      "Anco",
      "Scuddy",
      "Sizerock",
      "Slemp",
      "Smilax",
      "Thousandsticks",
      "Vest",
      "Vicco",
      "Farler",
      "Wendover",
      "Frew",
      "Big rock",
      "Yerkes",
      "Zcta 417hh",
      "Carcassonne",
      "Cromona",
      "Deane",
      "Ermine",
      "Larkslane",
      "Gilly",
      "Skyline",
      "Hindman",
      "Isom",
      "Jackhorn",
      "Jeremiah",
      "Puncheon",
      "Soft shell",
      "Letcher",
      "Linefork",
      "Littcarr",
      "Mc roberts",
      "Mallie",
      "Mayking",
      "Millstone",
      "Mousie",
      "Fleming neon",
      "Omaha",
      "Raven",
      "Premium",
      "Redfox",
      "Seco",
      "Thornton",
      "Day rural",
      "Dema",
      "Dry creek",
      "Zcta 418hh",
      "Paducah",
      "Almo",
      "Arlington",
      "Bandana",
      "Bardwell",
      "Barlow",
      "Benton",
      "Boaz",
      "Burna",
      "Calvert city",
      "Clinton",
      "Columbus",
      "Cunningham",
      "Dexter",
      "Eddyville",
      "Fancy farm",
      "Farmington",
      "Crutchfield",
      "Gilbertsville",
      "Iuka",
      "Hampton",
      "Hardin",
      "Hazel",
      "Hickman",
      "Hickory",
      "Kevil",
      "Kirksey",
      "Kuttawa",
      "La center",
      "Ledbetter",
      "Lovelaceville",
      "Lowes",
      "Marion",
      "Mayfield",
      "Melber",
      "Murray",
      "New concord",
      "Salem",
      "Sedalia",
      "Carrsville",
      "Symsonia",
      "Tiline",
      "Tolu",
      "Water valley",
      "West paducah",
      "Wickliffe",
      "Wingo",
      "Zcta 420hh",
      "Zcta 420xx",
      "Plum springs",
      "Bowling green",
      "Adolphus",
      "Alvaton",
      "Austin",
      "Cave city",
      "Subtle",
      "Etoile",
      "Fountain run",
      "Franklin",
      "Gamaliel",
      "Glasgow",
      "Hestand",
      "Holland",
      "Knob lick",
      "Mount herman",
      "Oakland",
      "Park city",
      "Scottsville",
      "Summer shade",
      "T ville",
      "Woodburn",
      "Smiths grove",
      "Zcta 421hh",
      "Adairville",
      "Allensville",
      "Auburn",
      "Bee spring",
      "Reedyville",
      "Golden pond",
      "Center",
      "Cerulean",
      "Crofton",
      "Elkton",
      "Fort campbell",
      "Gracey",
      "Tiny town",
      "Herndon",
      "Hopkinsville",
      "La fayette",
      "Lewisburg",
      "Mammoth cave nat",
      "Logansport",
      "Oak grove",
      "Olmstead",
      "Pembroke",
      "Rochester",
      "Browning",
      "Roundhill",
      "Daysville",
      "Sharon grove",
      "Kyrock",
      "Trenton",
      "Welchs creek",
      "Zcta 422hh",
      "Zcta 422xx",
      "Owensboro",
      "Beaver dam",
      "Beech creek",
      "Beechmont",
      "Belton",
      "Bremen",
      "Browder",
      "Calhoun",
      "Centertown",
      "Central city",
      "Cleaton",
      "Cromwell",
      "Drakesboro",
      "Dundee",
      "Dunmor",
      "Fordsville",
      "Graham",
      "Greenville",
      "Hartford",
      "Hawesville",
      "Horse branch",
      "Island",
      "Lewisport",
      "Livermore",
      "Mc henry",
      "Maceo",
      "Olaton",
      "Penrod",
      "Philpot",
      "Reynolds station",
      "Rockport",
      "Rosine",
      "Rumsey",
      "Sacramento",
      "South carrollton",
      "Utica",
      "Whitesville",
      "Zcta 423hh",
      "Zcta 423xx",
      "Blackford",
      "Clay",
      "Corydon",
      "Dawson springs",
      "Dixon",
      "Earlington",
      "Fredonia",
      "Hanson",
      "Henderson",
      "Madisonville",
      "Manitou",
      "Henshaw",
      "Mortons gap",
      "Nebo",
      "Nortonville",
      "Poole",
      "Princeton",
      "Providence",
      "Reed",
      "Robards",
      "Saint charles",
      "Sebree",
      "Slaughters",
      "Smith mills",
      "Spottsville",
      "Sturgis",
      "Uniontown",
      "Waverly",
      "Wheatcroft",
      "White plains",
      "Zcta 424hh",
      "Zcta 424xx",
      "Alcalde",
      "Zcta 42503",
      "Bethelridge",
      "Bronston",
      "Sloans valley",
      "Dunnville",
      "Ferguson",
      "Liberty",
      "Middleburg",
      "Pointer",
      "Science hill",
      "Windsor",
      "Yosemite",
      "Pulaski",
      "Zcta 425hh",
      "Albany",
      "Alpha",
      "Jamestown",
      "Marshes siding",
      "Mill springs",
      "Pueblo",
      "Parkers lake",
      "Hollyhill",
      "Revelo",
      "Webbs cross road",
      "Stearns",
      "Strunk",
      "Wiborg",
      "Zcta 426hh",
      "Zcta 426xx",
      "E town",
      "Bakerton",
      "Big clifty",
      "Bonnieville",
      "Breeding",
      "Buffalo",
      "Burkesville",
      "Campbellsville",
      "Caneyville",
      "Canmer",
      "Stephensburg",
      "Wax",
      "Montpelier",
      "Cub run",
      "Dubre",
      "E view",
      "Elk horn",
      "Glendale",
      "Glens fork",
      "Gradyville",
      "Greensburg",
      "Hardyville",
      "Hodgenville",
      "Horse cave",
      "Knifley",
      "Sadler",
      "Magnolia",
      "Marrowbone",
      "Milltown",
      "Millwood",
      "Munfordville",
      "Sonora",
      "Summersville",
      "Upton",
      "White mills",
      "Zcta 427hh",
    ],
    ZipCodes: {
      "": ["38079", "380HH"],
      Bagdad: ["40003"],
      Bardstown: ["40004"],
      Bedford: ["40006"],
      Bethlehem: ["40007"],
      Bloomfield: ["40008"],
      Bradfordsville: ["40009"],
      Buckner: ["40010"],
      Campbellsburg: ["40011"],
      Chaplin: ["40012"],
      Deatsville: ["40013"],
      Crestwood: ["40014"],
      Eminence: ["40019"],
      Fairfield: ["40020"],
      Finchville: ["40022"],
      Fisherville: ["40023"],
      Goshen: ["40026"],
      "La grange": ["40031"],
      Lebanon: ["40033"],
      Lockport: ["40036"],
      Loretto: ["40037"],
      Mackville: ["40040"],
      Milton: ["40045"],
      "Mount eden": ["40046"],
      "Mount washington": ["40047"],
      "New castle": ["40050"],
      Trappist: ["40051"],
      "New hope": ["40052"],
      Pendleton: ["40055"],
      "Pewee valley": ["40056"],
      Cropper: ["40057"],
      "Port royal": ["40058"],
      Prospect: ["40059"],
      Raywick: ["40060"],
      "Saint francis": ["40062"],
      Shelbyville: ["40065"],
      Simpsonville: ["40067"],
      Smithfield: ["40068"],
      Maud: ["40069"],
      Sulphur: ["40070"],
      Taylorsville: ["40071"],
      Waddy: ["40076"],
      Westport: ["40077"],
      Willisburg: ["40078"],
      "Zcta 400hh": ["400HH"],
      Battletown: ["40104"],
      Boston: ["40107"],
      Brandenburg: ["40108"],
      Brooks: ["40109"],
      Cloverport: ["40111"],
      Custer: ["40115"],
      Ekron: ["40117"],
      Fairdale: ["40118"],
      "Glen dean": ["40119"],
      "Fort knox": ["40121"],
      Garfield: ["40140"],
      Guston: ["40142"],
      Mooleyville: ["40143"],
      "Locust hill": ["40144"],
      Hudson: ["40145"],
      Irvington: ["40146"],
      "Lebanon junction": ["40150"],
      "Mc daniels": ["40152"],
      Muldraugh: ["40155"],
      Payneville: ["40157"],
      Radcliff: ["40160"],
      Rineyville: ["40162"],
      "Se ree": ["40164"],
      Shepherdsville: ["40165"],
      Stephensport: ["40170"],
      "Union star": ["40171"],
      "Vine grove": ["40175"],
      Webster: ["40176"],
      "West point": ["40177"],
      Westview: ["40178"],
      "Zcta 401hh": ["401HH"],
      "Zcta 401xx": ["401XX"],
      Louisville: [
        "40202",
        "40203",
        "40204",
        "40205",
        "40208",
        "40209",
        "40210",
        "40211",
        "40212",
        "40213",
        "40214",
        "40215",
        "40217",
        "40220",
        "40245",
      ],
      "Saint matthews": ["40206", "40207"],
      Shively: ["40216"],
      Buechel: ["40218", "40228"],
      Okolona: ["40219", "40229"],
      Lyndon: ["40222", "40241", "40242"],
      Anchorage: ["40223"],
      Middletown: ["40243"],
      "Pleasure ridge p": ["40258"],
      "Valley station": ["40272"],
      "Fern creek": ["40291"],
      Jeffersontown: ["40299"],
      "Zcta 402hh": ["402HH"],
      Burgin: ["40310"],
      Carlisle: ["40311"],
      Westbend: ["40312"],
      Clearfield: ["40313"],
      Denniston: ["40316"],
      Scranton: ["40322"],
      Georgetown: ["40324"],
      "Gravel switch": ["40328"],
      Cornishville: ["40330"],
      Hope: ["40334"],
      Jinks: ["40336"],
      Jeffersonville: ["40337"],
      Keene: ["40339"],
      Lawrenceburg: ["40342"],
      Means: ["40346"],
      Midway: ["40347"],
      Millersburg: ["40348"],
      Moorefield: ["40350"],
      Morehead: ["40351"],
      "Mount sterling": ["40353"],
      "New liberty": ["40355"],
      Nicholasville: ["40356"],
      Olympia: ["40358"],
      Owenton: ["40359"],
      Owingsville: ["40360"],
      Paris: ["40361"],
      "Perry park": ["40363"],
      Sadieville: ["40370"],
      "Salt lick": ["40371"],
      Bondville: ["40372"],
      Sharpsburg: ["40374"],
      Slade: ["40376"],
      "Stamping ground": ["40379"],
      Patsey: ["40380"],
      Versailles: ["40383"],
      Bybee: ["40385"],
      Korea: ["40387"],
      "High bridge": ["40390"],
      Winchester: ["40391"],
      "Zcta 403hh": ["403HH"],
      "Moores creek": ["40402"],
      Berea: ["40403"],
      Brodhead: ["40409"],
      "Crab orchard": ["40419"],
      Danville: ["40422"],
      Hustonville: ["40437"],
      "Junction city": ["40440"],
      "Kings mountain": ["40442"],
      Lancaster: ["40444"],
      Livingston: ["40445"],
      "Clover bottom": ["40447"],
      "Mc kinney": ["40448"],
      Climax: ["40456"],
      Orlando: ["40460"],
      "Paint lick": ["40461"],
      Parksville: ["40464"],
      Perryville: ["40468"],
      Ravenna: ["40472"],
      Richmond: ["40475"],
      Sandgap: ["40481"],
      Stanford: ["40484"],
      Elias: ["40486"],
      Waneta: ["40488"],
      Waynesburg: ["40489"],
      "Zcta 404hh": ["404HH"],
      Lexington: [
        "40502",
        "40503",
        "40504",
        "40505",
        "40507",
        "40508",
        "40509",
        "40510",
        "40511",
        "40513",
        "40514",
        "40515",
        "40516",
        "40517",
      ],
      "Zcta 405hh": ["405HH"],
      Hatton: ["40601"],
      Corbin: ["40701"],
      Symbol: ["40729"],
      Emlyn: ["40730"],
      Gray: ["40734"],
      Keavy: ["40737"],
      Lily: ["40740"],
      Sasser: ["40741"],
      London: ["40744"],
      Rockholds: ["40759"],
      Siler: ["40763"],
      "Pleasant view": ["40769"],
      Woodbine: ["40771"],
      "Zcta 407hh": ["407HH"],
      "Ages brookside": ["40801"],
      Asher: ["40803"],
      Baxter: ["40806"],
      Benham: ["40807"],
      "Big laurel": ["40808"],
      "Lewis creek": ["40810"],
      Calvin: ["40813"],
      Crummies: ["40815"],
      Chappell: ["40816"],
      Coalgood: ["40818"],
      Coldiron: ["40819"],
      Cranks: ["40820"],
      Cumberland: ["40823"],
      Dayhoit: ["40824"],
      Eolia: ["40826"],
      Essie: ["40827"],
      Louellen: ["40828"],
      Gulston: ["40830"],
      Chevrolet: ["40831"],
      Helton: ["40840"],
      "Holmes mill": ["40843"],
      Hoskinston: ["40844"],
      Hulen: ["40845"],
      Kenvir: ["40847"],
      Lejunior: ["40849"],
      Loyall: ["40854"],
      Lynch: ["40855"],
      Cubage: ["40856"],
      Mozelle: ["40858"],
      Partridge: ["40862"],
      Pathfork: ["40863"],
      Putney: ["40865"],
      Smith: ["40867"],
      Stinnett: ["40868"],
      Totz: ["40870"],
      "Wallins creek": ["40873"],
      Warbranch: ["40874"],
      "Zcta 408hh": ["408HH"],
      "Zcta 408xx": ["408XX"],
      Arjay: ["40902"],
      Artemus: ["40903"],
      "Bailey switch": ["40906"],
      Beverly: ["40913"],
      "Big creek": ["40914"],
      Bimble: ["40915"],
      "Bryants store": ["40921"],
      Cannon: ["40923"],
      Closplint: ["40927"],
      Dewitt: ["40930"],
      "Salt gum": ["40935"],
      Fonde: ["40940"],
      Garrard: ["40941"],
      Girdler: ["40943"],
      "Green road": ["40946"],
      Heidrick: ["40949"],
      Hinkle: ["40953"],
      "Kettle island": ["40958"],
      "Bright shade": ["40962"],
      Middlesboro: ["40965"],
      Oneida: ["40972"],
      Callaway: ["40977"],
      Roark: ["40979"],
      Scalf: ["40982"],
      "Sextons creek": ["40983"],
      "Stoney fork": ["40988"],
      Trosper: ["40995"],
      Walker: ["40997"],
      Woollum: ["40999"],
      "Zcta 409hh": ["409HH"],
      Alexandria: ["41001"],
      Augusta: ["41002"],
      Berry: ["41003"],
      Brooksville: ["41004"],
      "Rabbit hash": ["41005"],
      Butler: ["41006"],
      California: ["41007"],
      Carrollton: ["41008"],
      Corinth: ["41010"],
      Covington: ["41011"],
      Rouse: ["41014"],
      Latonia: ["41015"],
      Ludlow: ["41016"],
      Dixie: ["41017"],
      Erlanger: ["41018"],
      Crittenden: ["41030"],
      Cynthiana: ["41031"],
      Demossville: ["41033"],
      Dover: ["41034"],
      "Dry ridge": ["41035"],
      Ewing: ["41039"],
      Falmouth: ["41040"],
      Flemingsburg: ["41041"],
      Florence: ["41042"],
      Foster: ["41043"],
      Germantown: ["41044"],
      Ghent: ["41045"],
      Glencoe: ["41046"],
      Hebron: ["41048"],
      Hillsboro: ["41049"],
      Independence: ["41051"],
      Jonesville: ["41052"],
      "Mays lick": ["41055"],
      "Limestone sq": ["41056"],
      Melbourne: ["41059"],
      "Morning view": ["41063"],
      "Mount olivet": ["41064"],
      Southgate: ["41071"],
      Bellevue: ["41073"],
      Dayton: ["41074"],
      "Fort thomas": ["41075"],
      Newport: ["41076"],
      Petersburg: ["41080"],
      Sanders: ["41083"],
      "Silver grove": ["41085"],
      Sparta: ["41086"],
      Union: ["41091"],
      Verona: ["41092"],
      Wallingford: ["41093"],
      Walton: ["41094"],
      Warsaw: ["41095"],
      Williamstown: ["41097"],
      Worthville: ["41098"],
      "Zcta 410hh": ["410HH"],
      Westwood: ["41101"],
      Ashland: ["41102"],
      Argillite: ["41121"],
      Blaine: ["41124"],
      Catlettsburg: ["41129"],
      Denton: ["41132"],
      "Head of grassy": ["41135"],
      Firebrick: ["41137"],
      Flatwoods: ["41139"],
      Garrison: ["41141"],
      Grahn: ["41142"],
      Fultz: ["41143"],
      Lynn: ["41144"],
      Hitchins: ["41146"],
      Isonville: ["41149"],
      Martha: ["41159"],
      Lawton: ["41164"],
      Quincy: ["41166"],
      Rush: ["41168"],
      Raceland: ["41169"],
      "Saint paul": ["41170"],
      Burke: ["41171"],
      "South portsmouth": ["41174"],
      Maloneton: ["41175"],
      Trinity: ["41179"],
      Webbville: ["41180"],
      Worthington: ["41183"],
      Tollesboro: ["41189"],
      "Zcta 411hh": ["411HH"],
      Adams: ["41201"],
      Beauty: ["41203"],
      "Boons camp": ["41204"],
      Davella: ["41214"],
      "East point": ["41216"],
      Elna: ["41219"],
      Hagerhill: ["41222"],
      Job: ["41224"],
      Keaton: ["41226"],
      Leander: ["41228"],
      Clifford: ["41230"],
      Lovely: ["41231"],
      Lowmansville: ["41232"],
      Meally: ["41234"],
      Manila: ["41238"],
      Nippa: ["41240"],
      Laura: ["41250"],
      River: ["41254"],
      Sitka: ["41255"],
      "Barnetts creek": ["41256"],
      Stambaugh: ["41257"],
      Thelma: ["41260"],
      Davisport: ["41262"],
      "Tutor key": ["41263"],
      Ulysses: ["41264"],
      "Van lear": ["41265"],
      Hode: ["41267"],
      "West van lear": ["41268"],
      Wittensville: ["41274"],
      "Zcta 412hh": ["412HH"],
      Flat: ["41301"],
      Bays: ["41310"],
      Vada: ["41311"],
      "Morris fork": ["41314"],
      Clayhole: ["41317"],
      "Grassy creek": ["41332"],
      Canoe: ["41339"],
      Lerose: ["41344"],
      Hardshell: ["41348"],
      "Pine ridge": ["41360"],
      Ricetown: ["41364"],
      Rogers: ["41365"],
      Rowdy: ["41367"],
      Talbert: ["41377"],
      Vancleve: ["41385"],
      Vincent: ["41386"],
      Whick: ["41390"],
      Zoe: ["41397"],
      "Cannel city": ["41408"],
      Elkfork: ["41421"],
      Ezel: ["41425"],
      Falcon: ["41426"],
      Royalton: ["41464"],
      Bethanna: ["41465"],
      "Blairs mill": ["41472"],
      "Zcta 414hh": ["414HH"],
      "Broad bottom": ["41501"],
      "South williamson": ["41503"],
      Ashcamp: ["41512"],
      Belcher: ["41513"],
      Belfry: ["41514"],
      Canada: ["41519"],
      Senterville: ["41522"],
      Biggs: ["41524"],
      "Forest hills": ["41527"],
      Freeburn: ["41528"],
      Hardy: ["41531"],
      Hellier: ["41534"],
      Huddy: ["41535"],
      "Payne gap": ["41537"],
      Jonancy: ["41538"],
      Kimper: ["41539"],
      "Lick creek": ["41540"],
      "Mc andrews": ["41543"],
      "Mc carr": ["41544"],
      "Mc veigh": ["41546"],
      Majestic: ["41547"],
      Mouthcard: ["41548"],
      Myra: ["41549"],
      Phelps: ["41553"],
      Phyllis: ["41554"],
      Pinsonfork: ["41555"],
      Fishtrap: ["41557"],
      Ransom: ["41558"],
      Regina: ["41559"],
      "Robinson creek": ["41560"],
      Shelbiana: ["41562"],
      "Shelby gap": ["41563"],
      Sidney: ["41564"],
      Steele: ["41566"],
      Stone: ["41567"],
      Argo: ["41568"],
      Toler: ["41569"],
      Varney: ["41571"],
      Etty: ["41572"],
      "Zcta 415hh": ["415HH"],
      Allen: ["41601"],
      Auxier: ["41602"],
      Banner: ["41603"],
      Ligon: ["41604"],
      "Betsy layne": ["41605"],
      Bevinsville: ["41606"],
      "Blue river": ["41607"],
      Bypro: ["41612"],
      Dana: ["41615"],
      David: ["41616"],
      Drift: ["41619"],
      Dwale: ["41621"],
      Eastern: ["41622"],
      Garrett: ["41630"],
      Grethel: ["41631"],
      Waldo: ["41632"],
      Harold: ["41635"],
      Buckingham: ["41636"],
      Elmrock: ["41640"],
      Ivel: ["41642"],
      Langley: ["41645"],
      "East mc dowell": ["41647"],
      Hite: ["41649"],
      Melvin: ["41650"],
      Minnie: ["41651"],
      Emma: ["41653"],
      Printer: ["41655"],
      Stanville: ["41659"],
      Teaberry: ["41660"],
      Tram: ["41663"],
      Wayland: ["41666"],
      Weeksbury: ["41667"],
      Wheelwright: ["41669"],
      "Zcta 416hh": ["416HH"],
      Darfork: ["41701"],
      Ary: ["41712"],
      "Bear branch": ["41714"],
      "Blue diamond": ["41719"],
      Buckhorn: ["41721"],
      Tribbey: ["41722"],
      Busy: ["41723"],
      Carrie: ["41725"],
      Chavies: ["41727"],
      Combs: ["41729"],
      Confluence: ["41730"],
      Ulvah: ["41731"],
      Delphia: ["41735"],
      Dice: ["41736"],
      Dwarf: ["41739"],
      Bearville: ["41740"],
      "Gays creek": ["41745"],
      Happy: ["41746"],
      Dryhill: ["41749"],
      Jeff: ["41751"],
      Napfor: ["41754"],
      Anco: ["41759"],
      Scuddy: ["41760"],
      Sizerock: ["41762"],
      Slemp: ["41763"],
      Smilax: ["41764"],
      Thousandsticks: ["41766"],
      Vest: ["41772"],
      Vicco: ["41773"],
      Farler: ["41774"],
      Wendover: ["41775"],
      Frew: ["41776"],
      "Big rock": ["41777"],
      Yerkes: ["41778"],
      "Zcta 417hh": ["417HH"],
      Carcassonne: ["41804"],
      Cromona: ["41810"],
      Deane: ["41812"],
      Ermine: ["41815"],
      Larkslane: ["41817"],
      Gilly: ["41819"],
      Skyline: ["41821"],
      Hindman: ["41822"],
      Isom: ["41824"],
      Jackhorn: ["41825"],
      Jeremiah: ["41826"],
      Puncheon: ["41828"],
      "Soft shell": ["41831"],
      Letcher: ["41832"],
      Linefork: ["41833"],
      Littcarr: ["41834"],
      "Mc roberts": ["41835"],
      Mallie: ["41836"],
      Mayking: ["41837"],
      Millstone: ["41838"],
      Mousie: ["41839"],
      "Fleming neon": ["41840"],
      Omaha: ["41843"],
      Raven: ["41844", "41861"],
      Premium: ["41845"],
      Redfox: ["41847"],
      Seco: ["41849"],
      Thornton: ["41855"],
      "Day rural": ["41858"],
      Dema: ["41859"],
      "Dry creek": ["41862"],
      "Zcta 418hh": ["418HH"],
      Paducah: ["42001", "42003"],
      Almo: ["42020"],
      Arlington: ["42021"],
      Bandana: ["42022"],
      Bardwell: ["42023"],
      Barlow: ["42024"],
      Benton: ["42025"],
      Boaz: ["42027"],
      Burna: ["42028"],
      "Calvert city": ["42029"],
      Clinton: ["42031"],
      Columbus: ["42032"],
      Cunningham: ["42035"],
      Dexter: ["42036"],
      Eddyville: ["42038"],
      "Fancy farm": ["42039"],
      Farmington: ["42040"],
      Crutchfield: ["42041"],
      Gilbertsville: ["42044"],
      Iuka: ["42045"],
      Hampton: ["42047"],
      Hardin: ["42048"],
      Hazel: ["42049"],
      Hickman: ["42050"],
      Hickory: ["42051"],
      Kevil: ["42053"],
      Kirksey: ["42054"],
      Kuttawa: ["42055"],
      "La center": ["42056"],
      Ledbetter: ["42058"],
      Lovelaceville: ["42060"],
      Lowes: ["42061"],
      Marion: ["42064"],
      Mayfield: ["42066"],
      Melber: ["42069"],
      Murray: ["42071"],
      "New concord": ["42076"],
      Salem: ["42078"],
      Sedalia: ["42079"],
      Carrsville: ["42081"],
      Symsonia: ["42082"],
      Tiline: ["42083"],
      Tolu: ["42084"],
      "Water valley": ["42085"],
      "West paducah": ["42086"],
      Wickliffe: ["42087"],
      Wingo: ["42088"],
      "Zcta 420hh": ["420HH"],
      "Zcta 420xx": ["420XX"],
      "Plum springs": ["42101"],
      "Bowling green": ["42103", "42104"],
      Adolphus: ["42120"],
      Alvaton: ["42122"],
      Austin: ["42123"],
      "Cave city": ["42127"],
      Subtle: ["42129"],
      Etoile: ["42131"],
      "Fountain run": ["42133"],
      Franklin: ["42134"],
      Gamaliel: ["42140"],
      Glasgow: ["42141"],
      Hestand: ["42151"],
      Holland: ["42153"],
      "Knob lick": ["42154"],
      "Mount herman": ["42157"],
      Oakland: ["42159"],
      "Park city": ["42160"],
      Scottsville: ["42164"],
      "Summer shade": ["42166"],
      "T ville": ["42167"],
      Woodburn: ["42170"],
      "Smiths grove": ["42171"],
      "Zcta 421hh": ["421HH"],
      Adairville: ["42202"],
      Allensville: ["42204"],
      Auburn: ["42206"],
      "Bee spring": ["42207"],
      Reedyville: ["42210"],
      "Golden pond": ["42211"],
      Center: ["42214"],
      Cerulean: ["42215"],
      Crofton: ["42217"],
      Elkton: ["42220"],
      "Fort campbell": ["42223"],
      Gracey: ["42232"],
      "Tiny town": ["42234"],
      Herndon: ["42236"],
      Hopkinsville: ["42240"],
      "La fayette": ["42254"],
      Lewisburg: ["42256"],
      "Mammoth cave nat": ["42259"],
      Logansport: ["42261"],
      "Oak grove": ["42262"],
      Olmstead: ["42265"],
      Pembroke: ["42266"],
      Rochester: ["42273"],
      Browning: ["42274"],
      Roundhill: ["42275"],
      Daysville: ["42276"],
      "Sharon grove": ["42280"],
      Kyrock: ["42285"],
      Trenton: ["42286"],
      "Welchs creek": ["42287"],
      "Zcta 422hh": ["422HH"],
      "Zcta 422xx": ["422XX"],
      Owensboro: ["42301", "42303"],
      "Beaver dam": ["42320"],
      "Beech creek": ["42321"],
      Beechmont: ["42323"],
      Belton: ["42324"],
      Bremen: ["42325"],
      Browder: ["42326"],
      Calhoun: ["42327"],
      Centertown: ["42328"],
      "Central city": ["42330"],
      Cleaton: ["42332"],
      Cromwell: ["42333"],
      Drakesboro: ["42337"],
      Dundee: ["42338"],
      Dunmor: ["42339"],
      Fordsville: ["42343"],
      Graham: ["42344"],
      Greenville: ["42345"],
      Hartford: ["42347"],
      Hawesville: ["42348"],
      "Horse branch": ["42349"],
      Island: ["42350"],
      Lewisport: ["42351"],
      Livermore: ["42352"],
      "Mc henry": ["42354"],
      Maceo: ["42355"],
      Olaton: ["42361"],
      Penrod: ["42365"],
      Philpot: ["42366"],
      "Reynolds station": ["42368"],
      Rockport: ["42369"],
      Rosine: ["42370"],
      Rumsey: ["42371"],
      Sacramento: ["42372"],
      "South carrollton": ["42374"],
      Utica: ["42376"],
      Whitesville: ["42378"],
      "Zcta 423hh": ["423HH"],
      "Zcta 423xx": ["423XX"],
      Blackford: ["42403"],
      Clay: ["42404"],
      Corydon: ["42406"],
      "Dawson springs": ["42408"],
      Dixon: ["42409"],
      Earlington: ["42410"],
      Fredonia: ["42411"],
      Hanson: ["42413"],
      Henderson: ["42420"],
      Madisonville: ["42431"],
      Manitou: ["42436"],
      Henshaw: ["42437"],
      "Mortons gap": ["42440"],
      Nebo: ["42441"],
      Nortonville: ["42442"],
      Poole: ["42444"],
      Princeton: ["42445"],
      Providence: ["42450"],
      Reed: ["42451"],
      Robards: ["42452"],
      "Saint charles": ["42453"],
      Sebree: ["42455"],
      Slaughters: ["42456"],
      "Smith mills": ["42457"],
      Spottsville: ["42458"],
      Sturgis: ["42459"],
      Uniontown: ["42461"],
      Waverly: ["42462"],
      Wheatcroft: ["42463"],
      "White plains": ["42464"],
      "Zcta 424hh": ["424HH"],
      "Zcta 424xx": ["424XX"],
      Alcalde: ["42501"],
      "Zcta 42503": ["42503"],
      Bethelridge: ["42516"],
      Bronston: ["42518"],
      "Sloans valley": ["42519"],
      Dunnville: ["42528"],
      Ferguson: ["42533"],
      Liberty: ["42539"],
      Middleburg: ["42541"],
      Pointer: ["42544"],
      "Science hill": ["42553"],
      Windsor: ["42565"],
      Yosemite: ["42566"],
      Pulaski: ["42567"],
      "Zcta 425hh": ["425HH"],
      Albany: ["42602"],
      Alpha: ["42603"],
      Jamestown: ["42629"],
      "Marshes siding": ["42631"],
      "Mill springs": ["42632"],
      Pueblo: ["42633"],
      "Parkers lake": ["42634"],
      Hollyhill: ["42635"],
      Revelo: ["42638"],
      "Webbs cross road": ["42642"],
      Stearns: ["42647"],
      Strunk: ["42649"],
      Wiborg: ["42653"],
      "Zcta 426hh": ["426HH"],
      "Zcta 426xx": ["426XX"],
      "E town": ["42701"],
      Bakerton: ["42711"],
      "Big clifty": ["42712"],
      Bonnieville: ["42713"],
      Breeding: ["42715"],
      Buffalo: ["42716"],
      Burkesville: ["42717"],
      Campbellsville: ["42718"],
      Caneyville: ["42721"],
      Canmer: ["42722"],
      Stephensburg: ["42724"],
      Wax: ["42726"],
      Montpelier: ["42728"],
      "Cub run": ["42729"],
      Dubre: ["42731"],
      "E view": ["42732"],
      "Elk horn": ["42733"],
      Glendale: ["42740"],
      "Glens fork": ["42741"],
      Gradyville: ["42742"],
      Greensburg: ["42743"],
      Hardyville: ["42746"],
      Hodgenville: ["42748"],
      "Horse cave": ["42749"],
      Knifley: ["42753"],
      Sadler: ["42754"],
      Magnolia: ["42757"],
      Marrowbone: ["42759"],
      Milltown: ["42761"],
      Millwood: ["42762"],
      Munfordville: ["42765"],
      Sonora: ["42776"],
      Summersville: ["42782"],
      Upton: ["42784"],
      "White mills": ["42788"],
      "Zcta 427hh": ["427HH"],
    },
  },
  {
    abbrevation: "LA",
    name: "Louisiana",
    Cities: [
      "Metairie",
      "Metairie",
      "Des allemands",
      "Ama",
      "Arabi",
      "Barataria",
      "Belle chasse",
      "Boothville",
      "Boutte",
      "Braithwaite",
      "Buras",
      "Chalmette",
      "New sarpy",
      "Edgard",
      "Empire",
      "Garyville",
      "Gramercy",
      "Gretna",
      "Terrytown",
      "Hahnville",
      "Harvey",
      "Kenner",
      "Killona",
      "Lafitte",
      "La place",
      "Luling",
      "Lutcher",
      "Marrero",
      "Meraux",
      "Mount airy",
      "Norco",
      "Paradis",
      "Pointe a la hach",
      "Port sulphur",
      "Reserve",
      "Saint bernard",
      "Saint james",
      "Saint rose",
      "Vacherie",
      "Venice",
      "Violet",
      "Bridge city",
      "Zcta 700hh",
      "Zcta 700xx",
      "New orleans",
      "Jefferson",
      "Harahan",
      "Zcta 701hh",
      "Zcta 701xx",
      "Thibodaux",
      "Pierre part",
      "Belle rose",
      "Berwick",
      "Bourg",
      "Chauvin",
      "Cut off",
      "Donaldsonville",
      "Dulac",
      "Galliano",
      "Gheens",
      "Gibson",
      "Golden meadow",
      "Grand isle",
      "Gray",
      "Houma",
      "Labadieville",
      "Larose",
      "Lockport",
      "Mathews",
      "Montegut",
      "Morgan city",
      "Napoleonville",
      "Paincourtville",
      "Patterson",
      "Plattenville",
      "Raceland",
      "Schriever",
      "Theriot",
      "Zcta 703hh",
      "Zcta 703xx",
      "Hammond",
      "Abita springs",
      "Amite",
      "Angie",
      "Bogalusa",
      "Bush",
      "Covington",
      "Zcta 70435",
      "Fluker",
      "Folsom",
      "Franklinton",
      "Greensburg",
      "Husser",
      "Independence",
      "Kentwood",
      "Lacombe",
      "Loranger",
      "Madisonville",
      "Mandeville",
      "Maurepas",
      "Mount hermon",
      "Natalbany",
      "Pearl river",
      "Pine grove",
      "Ponchatoula",
      "Robert",
      "Roseland",
      "Slidell",
      "Springfield",
      "Sun",
      "Talisheek",
      "Tangipahoa",
      "Tickfaw",
      "Zcta 70471",
      "Zcta 704hh",
      "Zcta 704xx",
      "Lafayette",
      "Forked island",
      "Arnaudville",
      "Avery island",
      "Baldwin",
      "Basile",
      "Branch",
      "Henderson",
      "Broussard",
      "Carencro",
      "Cecilia",
      "Charenton",
      "Chataignier",
      "Church point",
      "Crowley",
      "Delcambre",
      "Duson",
      "Egan",
      "Elton",
      "Erath",
      "Estherwood",
      "Eunice",
      "Evangeline",
      "Franklin",
      "Garden city",
      "Grand coteau",
      "Gueydan",
      "Iota",
      "Jeanerette",
      "Jennings",
      "Kaplan",
      "Lake arthur",
      "Lawtell",
      "Loreauville",
      "Mamou",
      "Maurice",
      "Mermentau",
      "Milton",
      "Midland",
      "New iberia",
      "Zcta 70563",
      "Opelousas",
      "Pine prairie",
      "Port barre",
      "Rayne",
      "Reddell",
      "Roanoke",
      "Saint martinvill",
      "Scott",
      "Cankton",
      "Turkey creek",
      "Ville platte",
      "Washington",
      "Welsh",
      "Youngsville",
      "Zcta 705hh",
      "Zcta 705xx",
      "Lake charles",
      "Bell city",
      "Cameron",
      "Creole",
      "Dequincy",
      "Deridder",
      "Dry creek",
      "Elizabeth",
      "Evans",
      "Fenton",
      "Grand chenier",
      "Grant",
      "Hackberry",
      "Hayes",
      "Iowa",
      "Kinder",
      "Lacassine",
      "Leblanc",
      "Longville",
      "Fields",
      "Mittie",
      "Oberlin",
      "Pitkin",
      "Ragley",
      "Reeves",
      "Rosepine",
      "Singer",
      "Starks",
      "Sugartown",
      "Sulphur",
      "Zcta 70665",
      "Vinton",
      "Westlake",
      "Zcta 706hh",
      "Zcta 706xx",
      "Zcta 70706",
      "Addis",
      "Albany",
      "Angola",
      "Baker",
      "Batchelor",
      "Blanks",
      "Brusly",
      "Bueche",
      "Point clair",
      "Clinton",
      "Convent",
      "Darrow",
      "Port vincent",
      "Erwinville",
      "Ethel",
      "Fordoche",
      "French settlemen",
      "Geismar",
      "Glynn",
      "Gonzales",
      "Greenwell spring",
      "Grosse tete",
      "Hester",
      "Holden",
      "Innis",
      "The bluffs",
      "Jarreau",
      "Krotz springs",
      "Lakeland",
      "Lettsworth",
      "Livingston",
      "Livonia",
      "Lottie",
      "Ramah",
      "Morganza",
      "New roads",
      "Norwood",
      "Oscar",
      "Paulina",
      "Plaquemine",
      "Port allen",
      "Galvez",
      "Pride",
      "Rosedale",
      "Rougon",
      "Saint amant",
      "Bains",
      "Iberville",
      "Slaughter",
      "Sorrento",
      "Sunshine",
      "Torbert",
      "Tunica",
      "Ventress",
      "Walker",
      "Weyanoke",
      "White castle",
      "Wilson",
      "Zachary",
      "Zcta 707hh",
      "Zcta 707xx",
      "Baton rouge",
      "Scotlandville",
      "Greenwood",
      "Zcta 708hh",
      "Arcadia",
      "Ashland",
      "Athens",
      "Belcher",
      "Benton",
      "Bethany",
      "Bienville",
      "Blanchard",
      "Castor",
      "Cotton valley",
      "Hanna",
      "Cullen",
      "Doyline",
      "Dubberly",
      "Frierson",
      "Gibsland",
      "Gilliam",
      "Gloster",
      "Goldonna",
      "Grand cane",
      "Hall summit",
      "Haughton",
      "Haynesville",
      "Heflin",
      "Homer",
      "Hosston",
      "Ida",
      "Jamestown",
      "Keatchie",
      "Keithville",
      "Lisbon",
      "Logansport",
      "Elm grove",
      "Mansfield",
      "Minden",
      "Mooringsport",
      "Oil city",
      "Pelican",
      "Plain dealing",
      "Pleasant hill",
      "Powhatan",
      "Princeton",
      "Ringgold",
      "Rodessa",
      "Chestnut",
      "Sarepta",
      "Shongaloo",
      "Sibley",
      "Springhill",
      "Stonewall",
      "Summerfield",
      "Trees",
      "Zcta 710hh",
      "Zcta 710xx",
      "Shreveport",
      "Forbing",
      "Dixie",
      "Barksdale a f b",
      "Bossier city",
      "Caspiana",
      "Zcta 711hh",
      "Monroe",
      "Richwood",
      "Archibald",
      "Baskin",
      "Bastrop",
      "Bernice",
      "Bonita",
      "Calhoun",
      "Chatham",
      "Choudrant",
      "Collinston",
      "Warden",
      "Downsville",
      "Dubach",
      "Epps",
      "Eros",
      "Farmerville",
      "Fort necessity",
      "Grambling",
      "Hodge",
      "Jones",
      "Jonesboro",
      "Kilbourne",
      "Lake providence",
      "Lillie",
      "Mangham",
      "Linville",
      "Mer rouge",
      "Terry",
      "Oak ridge",
      "Pioneer",
      "Quitman",
      "Alto",
      "Ruston",
      "Simsboro",
      "Sondheimer",
      "Spearsville",
      "Spencer",
      "Mound",
      "Transylvania",
      "West monroe",
      "Winnsboro",
      "Zcta 712hh",
      "Zcta 712xx",
      "Alexandria",
      "Acme",
      "Bordelonville",
      "Eola",
      "Center point",
      "Cheneyville",
      "Clayton",
      "Cottonport",
      "Buckeye",
      "Echo",
      "Vick",
      "Goudeau",
      "Frogmore",
      "Gilbert",
      "Hamburg",
      "Harrisonburg",
      "Hessmer",
      "Jena",
      "Larto",
      "Rosa",
      "Lecompte",
      "Mansura",
      "Marksville",
      "Melville",
      "Monterey",
      "Moreauville",
      "Le moyen",
      "Newellton",
      "Palmetto",
      "Kolin",
      "Plaucheville",
      "Saint joseph",
      "Saint landry",
      "Sicily island",
      "Simmesport",
      "Trout",
      "Vidalia",
      "Waterproof",
      "Wildsville",
      "Wisner",
      "Zcta 713hh",
      "Zcta 713xx",
      "Aimwell",
      "Anacoco",
      "Atlanta",
      "Belmont",
      "Bentley",
      "Boyce",
      "Calvin",
      "Campti",
      "Clarence",
      "Clarks",
      "Derry",
      "Colfax",
      "Hebert",
      "Mitchell",
      "Dodson",
      "Dry prong",
      "Elmer",
      "Enterprise",
      "Fisher",
      "Flatwoods",
      "Florien",
      "Forest hill",
      "Georgetown",
      "Calcasieu",
      "Gorum",
      "Grayson",
      "Leander",
      "Hornbeck",
      "Kelly",
      "Hicks",
      "Chopin",
      "Many",
      "Marthaville",
      "Melrose",
      "Montgomery",
      "Clifton",
      "Natchez",
      "Natchitoches",
      "Fort polk",
      "Newllano",
      "Noble",
      "Oakdale",
      "Olla",
      "Otis",
      "Pollock",
      "Provencal",
      "Robeline",
      "Sieper",
      "Sikes",
      "Temple",
      "Tullos",
      "Urania",
      "Winnfield",
      "Woodworth",
      "Zwolle",
      "Zcta 714hh",
      "Zcta 714xx",
      "",
    ],
    ZipCodes: {
      Metairie: ["70001", "70002", "70003", "70005", "70006"],
      "Des allemands": ["70030"],
      Ama: ["70031"],
      Arabi: ["70032"],
      Barataria: ["70036"],
      "Belle chasse": ["70037"],
      Boothville: ["70038"],
      Boutte: ["70039"],
      Braithwaite: ["70040"],
      Buras: ["70041"],
      Chalmette: ["70043"],
      "New sarpy": ["70047"],
      Edgard: ["70049"],
      Empire: ["70050"],
      Garyville: ["70051"],
      Gramercy: ["70052"],
      Gretna: ["70053"],
      Terrytown: ["70056"],
      Hahnville: ["70057"],
      Harvey: ["70058"],
      Kenner: ["70062", "70065"],
      Killona: ["70066"],
      Lafitte: ["70067"],
      "La place": ["70068"],
      Luling: ["70070"],
      Lutcher: ["70071"],
      Marrero: ["70072"],
      Meraux: ["70075"],
      "Mount airy": ["70076"],
      Norco: ["70079"],
      Paradis: ["70080"],
      "Pointe a la hach": ["70082"],
      "Port sulphur": ["70083"],
      Reserve: ["70084"],
      "Saint bernard": ["70085"],
      "Saint james": ["70086"],
      "Saint rose": ["70087"],
      Vacherie: ["70090"],
      Venice: ["70091"],
      Violet: ["70092"],
      "Bridge city": ["70094"],
      "Zcta 700hh": ["700HH"],
      "Zcta 700xx": ["700XX"],
      "New orleans": [
        "70112",
        "70113",
        "70114",
        "70115",
        "70116",
        "70117",
        "70118",
        "70119",
        "70122",
        "70124",
        "70125",
        "70126",
        "70127",
        "70128",
        "70129",
        "70130",
        "70131",
        "70163",
      ],
      Jefferson: ["70121"],
      Harahan: ["70123"],
      "Zcta 701hh": ["701HH"],
      "Zcta 701xx": ["701XX"],
      Thibodaux: ["70301"],
      "Pierre part": ["70339"],
      "Belle rose": ["70341"],
      Berwick: ["70342"],
      Bourg: ["70343"],
      Chauvin: ["70344"],
      "Cut off": ["70345"],
      Donaldsonville: ["70346"],
      Dulac: ["70353"],
      Galliano: ["70354"],
      Gheens: ["70355"],
      Gibson: ["70356"],
      "Golden meadow": ["70357"],
      "Grand isle": ["70358"],
      Gray: ["70359"],
      Houma: ["70360", "70363", "70364"],
      Labadieville: ["70372"],
      Larose: ["70373"],
      Lockport: ["70374"],
      Mathews: ["70375"],
      Montegut: ["70377"],
      "Morgan city": ["70380"],
      Napoleonville: ["70390"],
      Paincourtville: ["70391"],
      Patterson: ["70392"],
      Plattenville: ["70393"],
      Raceland: ["70394"],
      Schriever: ["70395"],
      Theriot: ["70397"],
      "Zcta 703hh": ["703HH"],
      "Zcta 703xx": ["703XX"],
      Hammond: ["70401", "70403"],
      "Abita springs": ["70420"],
      Amite: ["70422"],
      Angie: ["70426"],
      Bogalusa: ["70427"],
      Bush: ["70431"],
      Covington: ["70433"],
      "Zcta 70435": ["70435"],
      Fluker: ["70436"],
      Folsom: ["70437"],
      Franklinton: ["70438"],
      Greensburg: ["70441"],
      Husser: ["70442"],
      Independence: ["70443"],
      Kentwood: ["70444"],
      Lacombe: ["70445"],
      Loranger: ["70446"],
      Madisonville: ["70447"],
      Mandeville: ["70448"],
      Maurepas: ["70449"],
      "Mount hermon": ["70450"],
      Natalbany: ["70451"],
      "Pearl river": ["70452"],
      "Pine grove": ["70453"],
      Ponchatoula: ["70454"],
      Robert: ["70455"],
      Roseland: ["70456"],
      Slidell: ["70458", "70460", "70461"],
      Springfield: ["70462"],
      Sun: ["70463"],
      Talisheek: ["70464"],
      Tangipahoa: ["70465"],
      Tickfaw: ["70466"],
      "Zcta 70471": ["70471"],
      "Zcta 704hh": ["704HH"],
      "Zcta 704xx": ["704XX"],
      Lafayette: ["70501", "70503", "70506", "70507", "70508"],
      "Forked island": ["70510"],
      Arnaudville: ["70512"],
      "Avery island": ["70513"],
      Baldwin: ["70514"],
      Basile: ["70515"],
      Branch: ["70516"],
      Henderson: ["70517"],
      Broussard: ["70518"],
      Carencro: ["70520"],
      Cecilia: ["70521"],
      Charenton: ["70523"],
      Chataignier: ["70524"],
      "Church point": ["70525"],
      Crowley: ["70526"],
      Delcambre: ["70528"],
      Duson: ["70529"],
      Egan: ["70531"],
      Elton: ["70532"],
      Erath: ["70533"],
      Estherwood: ["70534"],
      Eunice: ["70535"],
      Evangeline: ["70537"],
      Franklin: ["70538"],
      "Garden city": ["70540"],
      "Grand coteau": ["70541"],
      Gueydan: ["70542"],
      Iota: ["70543"],
      Jeanerette: ["70544"],
      Jennings: ["70546"],
      Kaplan: ["70548"],
      "Lake arthur": ["70549"],
      Lawtell: ["70550"],
      Loreauville: ["70552"],
      Mamou: ["70554"],
      Maurice: ["70555"],
      Mermentau: ["70556"],
      Milton: ["70558"],
      Midland: ["70559"],
      "New iberia": ["70560"],
      "Zcta 70563": ["70563"],
      Opelousas: ["70570"],
      "Pine prairie": ["70576"],
      "Port barre": ["70577"],
      Rayne: ["70578"],
      Reddell: ["70580"],
      Roanoke: ["70581"],
      "Saint martinvill": ["70582"],
      Scott: ["70583"],
      Cankton: ["70584"],
      "Turkey creek": ["70585"],
      "Ville platte": ["70586"],
      Washington: ["70589"],
      Welsh: ["70591"],
      Youngsville: ["70592"],
      "Zcta 705hh": ["705HH"],
      "Zcta 705xx": ["705XX"],
      "Lake charles": ["70601", "70605", "70607", "70611", "70615"],
      "Bell city": ["70630"],
      Cameron: ["70631"],
      Creole: ["70632"],
      Dequincy: ["70633"],
      Deridder: ["70634"],
      "Dry creek": ["70637"],
      Elizabeth: ["70638"],
      Evans: ["70639"],
      Fenton: ["70640"],
      "Grand chenier": ["70643"],
      Grant: ["70644"],
      Hackberry: ["70645"],
      Hayes: ["70646"],
      Iowa: ["70647"],
      Kinder: ["70648"],
      Lacassine: ["70650"],
      Leblanc: ["70651"],
      Longville: ["70652"],
      Fields: ["70653"],
      Mittie: ["70654"],
      Oberlin: ["70655"],
      Pitkin: ["70656"],
      Ragley: ["70657"],
      Reeves: ["70658"],
      Rosepine: ["70659"],
      Singer: ["70660"],
      Starks: ["70661"],
      Sugartown: ["70662"],
      Sulphur: ["70663"],
      "Zcta 70665": ["70665"],
      Vinton: ["70668"],
      Westlake: ["70669"],
      "Zcta 706hh": ["706HH"],
      "Zcta 706xx": ["706XX"],
      "Zcta 70706": ["70706"],
      Addis: ["70710"],
      Albany: ["70711"],
      Angola: ["70712"],
      Baker: ["70714"],
      Batchelor: ["70715"],
      Blanks: ["70717"],
      Brusly: ["70719"],
      Bueche: ["70720"],
      "Point clair": ["70721"],
      Clinton: ["70722"],
      Convent: ["70723"],
      Darrow: ["70725"],
      "Port vincent": ["70726"],
      Erwinville: ["70729"],
      Ethel: ["70730"],
      Fordoche: ["70732"],
      "French settlemen": ["70733"],
      Geismar: ["70734"],
      Glynn: ["70736"],
      Gonzales: ["70737"],
      "Greenwell spring": ["70739"],
      "Grosse tete": ["70740"],
      Hester: ["70743"],
      Holden: ["70744"],
      Innis: ["70747"],
      "The bluffs": ["70748"],
      Jarreau: ["70749"],
      "Krotz springs": ["70750"],
      Lakeland: ["70752"],
      Lettsworth: ["70753"],
      Livingston: ["70754"],
      Livonia: ["70755"],
      Lottie: ["70756"],
      Ramah: ["70757"],
      Morganza: ["70759"],
      "New roads": ["70760"],
      Norwood: ["70761"],
      Oscar: ["70762"],
      Paulina: ["70763"],
      Plaquemine: ["70764"],
      "Port allen": ["70767"],
      Galvez: ["70769"],
      Pride: ["70770"],
      Rosedale: ["70772"],
      Rougon: ["70773"],
      "Saint amant": ["70774"],
      Bains: ["70775"],
      Iberville: ["70776"],
      Slaughter: ["70777"],
      Sorrento: ["70778"],
      Sunshine: ["70780"],
      Torbert: ["70781"],
      Tunica: ["70782"],
      Ventress: ["70783"],
      Walker: ["70785"],
      Weyanoke: ["70787"],
      "White castle": ["70788"],
      Wilson: ["70789"],
      Zachary: ["70791"],
      "Zcta 707hh": ["707HH"],
      "Zcta 707xx": ["707XX"],
      "Baton rouge": [
        "70801",
        "70802",
        "70805",
        "70806",
        "70808",
        "70809",
        "70810",
        "70812",
        "70814",
        "70815",
        "70816",
        "70817",
        "70818",
        "70819",
        "70820",
        "70836",
      ],
      Scotlandville: ["70807"],
      Greenwood: ["70811", "71033"],
      "Zcta 708hh": ["708HH"],
      Arcadia: ["71001"],
      Ashland: ["71002"],
      Athens: ["71003"],
      Belcher: ["71004"],
      Benton: ["71006"],
      Bethany: ["71007"],
      Bienville: ["71008"],
      Blanchard: ["71009"],
      Castor: ["71016"],
      "Cotton valley": ["71018"],
      Hanna: ["71019"],
      Cullen: ["71021"],
      Doyline: ["71023"],
      Dubberly: ["71024"],
      Frierson: ["71027"],
      Gibsland: ["71028"],
      Gilliam: ["71029"],
      Gloster: ["71030"],
      Goldonna: ["71031"],
      "Grand cane": ["71032"],
      "Hall summit": ["71034"],
      Haughton: ["71037"],
      Haynesville: ["71038"],
      Heflin: ["71039"],
      Homer: ["71040"],
      Hosston: ["71043"],
      Ida: ["71044"],
      Jamestown: ["71045"],
      Keatchie: ["71046"],
      Keithville: ["71047"],
      Lisbon: ["71048"],
      Logansport: ["71049"],
      "Elm grove": ["71051"],
      Mansfield: ["71052"],
      Minden: ["71055"],
      Mooringsport: ["71060"],
      "Oil city": ["71061"],
      Pelican: ["71063"],
      "Plain dealing": ["71064"],
      "Pleasant hill": ["71065"],
      Powhatan: ["71066"],
      Princeton: ["71067"],
      Ringgold: ["71068"],
      Rodessa: ["71069"],
      Chestnut: ["71070"],
      Sarepta: ["71071"],
      Shongaloo: ["71072"],
      Sibley: ["71073"],
      Springhill: ["71075"],
      Stonewall: ["71078"],
      Summerfield: ["71079"],
      Trees: ["71082"],
      "Zcta 710hh": ["710HH"],
      "Zcta 710xx": ["710XX"],
      Shreveport: [
        "71101",
        "71103",
        "71104",
        "71105",
        "71108",
        "71109",
        "71118",
        "71119",
        "71129",
      ],
      Forbing: ["71106"],
      Dixie: ["71107"],
      "Barksdale a f b": ["71110"],
      "Bossier city": ["71111", "71112"],
      Caspiana: ["71115"],
      "Zcta 711hh": ["711HH"],
      Monroe: ["71201", "71203"],
      Richwood: ["71202"],
      Archibald: ["71218"],
      Baskin: ["71219"],
      Bastrop: ["71220"],
      Bernice: ["71222"],
      Bonita: ["71223"],
      Calhoun: ["71225"],
      Chatham: ["71226"],
      Choudrant: ["71227"],
      Collinston: ["71229"],
      Warden: ["71232"],
      Downsville: ["71234"],
      Dubach: ["71235"],
      Epps: ["71237"],
      Eros: ["71238"],
      Farmerville: ["71241"],
      "Fort necessity": ["71243"],
      Grambling: ["71245"],
      Hodge: ["71247"],
      Jones: ["71250"],
      Jonesboro: ["71251"],
      Kilbourne: ["71253"],
      "Lake providence": ["71254"],
      Lillie: ["71256"],
      Mangham: ["71259"],
      Linville: ["71260"],
      "Mer rouge": ["71261"],
      Terry: ["71263"],
      "Oak ridge": ["71264"],
      Pioneer: ["71266"],
      Quitman: ["71268"],
      Alto: ["71269"],
      Ruston: ["71270"],
      Simsboro: ["71275"],
      Sondheimer: ["71276"],
      Spearsville: ["71277"],
      Spencer: ["71280"],
      Mound: ["71282"],
      Transylvania: ["71286"],
      "West monroe": ["71291", "71292"],
      Winnsboro: ["71295"],
      "Zcta 712hh": ["712HH"],
      "Zcta 712xx": ["712XX"],
      Alexandria: ["71301", "71302", "71303"],
      Acme: ["71316"],
      Bordelonville: ["71320"],
      Eola: ["71322"],
      "Center point": ["71323"],
      Cheneyville: ["71325"],
      Clayton: ["71326"],
      Cottonport: ["71327"],
      Buckeye: ["71328"],
      Echo: ["71330"],
      Vick: ["71331"],
      Goudeau: ["71333"],
      Frogmore: ["71334"],
      Gilbert: ["71336"],
      Hamburg: ["71339"],
      Harrisonburg: ["71340"],
      Hessmer: ["71341"],
      Jena: ["71342"],
      Larto: ["71343"],
      Rosa: ["71345"],
      Lecompte: ["71346"],
      Mansura: ["71350"],
      Marksville: ["71351"],
      Melville: ["71353"],
      Monterey: ["71354"],
      Moreauville: ["71355"],
      "Le moyen": ["71356"],
      Newellton: ["71357"],
      Palmetto: ["71358"],
      Kolin: ["71360"],
      Plaucheville: ["71362"],
      "Saint joseph": ["71366"],
      "Saint landry": ["71367"],
      "Sicily island": ["71368"],
      Simmesport: ["71369"],
      Trout: ["71371"],
      Vidalia: ["71373"],
      Waterproof: ["71375"],
      Wildsville: ["71377"],
      Wisner: ["71378"],
      "Zcta 713hh": ["713HH"],
      "Zcta 713xx": ["713XX"],
      Aimwell: ["71401"],
      Anacoco: ["71403"],
      Atlanta: ["71404"],
      Belmont: ["71406"],
      Bentley: ["71407"],
      Boyce: ["71409"],
      Calvin: ["71410"],
      Campti: ["71411"],
      Clarence: ["71414"],
      Clarks: ["71415"],
      Derry: ["71416"],
      Colfax: ["71417"],
      Hebert: ["71418"],
      Mitchell: ["71419"],
      Dodson: ["71422"],
      "Dry prong": ["71423"],
      Elmer: ["71424"],
      Enterprise: ["71425"],
      Fisher: ["71426"],
      Flatwoods: ["71427"],
      Florien: ["71429"],
      "Forest hill": ["71430"],
      Georgetown: ["71432"],
      Calcasieu: ["71433"],
      Gorum: ["71434"],
      Grayson: ["71435"],
      Leander: ["71438"],
      Hornbeck: ["71439"],
      Kelly: ["71441"],
      Hicks: ["71446"],
      Chopin: ["71447"],
      Many: ["71449"],
      Marthaville: ["71450"],
      Melrose: ["71452"],
      Montgomery: ["71454"],
      Clifton: ["71455"],
      Natchez: ["71456"],
      Natchitoches: ["71457"],
      "Fort polk": ["71459"],
      Newllano: ["71461"],
      Noble: ["71462"],
      Oakdale: ["71463"],
      Olla: ["71465"],
      Otis: ["71466"],
      Pollock: ["71467"],
      Provencal: ["71468"],
      Robeline: ["71469"],
      Sieper: ["71472"],
      Sikes: ["71473"],
      Temple: ["71474"],
      Tullos: ["71479"],
      Urania: ["71480"],
      Winnfield: ["71483"],
      Woodworth: ["71485"],
      Zwolle: ["71486"],
      "Zcta 714hh": ["714HH"],
      "Zcta 714xx": ["714XX"],
      "": ["71749"],
    },
  },
  {
    abbrevation: "ME",
    name: "Maine",
    Cities: [
      "Berwick",
      "Berwick",
      "Cape neddick",
      "Eliot",
      "Kittery",
      "Kittery point",
      "North berwick",
      "Ogunquit",
      "South berwick",
      "York",
      "Zcta 039hh",
      "Acton",
      "Alfred",
      "Bailey island",
      "Arundel",
      "Bowdoinham",
      "Bridgton",
      "Brownfield",
      "Birch island",
      "Casco",
      "Chebeague island",
      "Cliff island",
      "Cornish",
      "Cumberland cente",
      "Denmark",
      "East baldwin",
      "West lebanon",
      "North sebago",
      "East waterboro",
      "Freeport",
      "Fryeburg",
      "Gorham",
      "Gray",
      "Harrison",
      "Hiram",
      "Hollis center",
      "Kennebunk",
      "Kennebunkport",
      "Kezar falls",
      "Limerick",
      "Limington",
      "Long island",
      "Lovell",
      "Naples",
      "North waterboro",
      "Windham",
      "Old orchard beac",
      "Orrs island",
      "Porter",
      "Pownal",
      "Raymond",
      "Saco",
      "Sanford",
      "Scarborough",
      "Shapleigh",
      "South harpswell",
      "Springvale",
      "Standish",
      "Steep falls",
      "Pejepscot",
      "Waterboro",
      "Waterford",
      "Wells",
      "West baldwin",
      "Westbrook",
      "West buxton",
      "Maplewood",
      "Yarmouth",
      "North yarmouth",
      "Zcta 040hh",
      "Portland",
      "Falmouth",
      "South portland",
      "Cape elizabeth",
      "Peaks island",
      "Cumberland fores",
      "Zcta 041hh",
      "Auburn",
      "Andover",
      "Bethel",
      "Bryant pond",
      "Buckfield",
      "Canton",
      "Durham",
      "Dixfield",
      "East andover",
      "East livermore",
      "East stoneham",
      "Greene",
      "Hanover",
      "Hebron",
      "Jay",
      "Lewiston",
      "Lisbon",
      "Lisbon falls",
      "Livermore",
      "Livermore falls",
      "Locke mills",
      "Mechanic falls",
      "Mexico",
      "Minot",
      "Monmouth",
      "New gloucester",
      "Newry",
      "Leeds",
      "North monmouth",
      "Norway",
      "Oxford",
      "Poland spring",
      "Roxbury",
      "Rumford",
      "Sabattus",
      "South paris",
      "Turner",
      "Wayne",
      "Weld",
      "West bowdoin",
      "West paris",
      "Peru",
      "West sumner",
      "Wilton",
      "Zcta 042hh",
      "Zcta 042xx",
      "Augusta",
      "Dresden",
      "Farmingdale",
      "Gardiner",
      "Randolph",
      "Hallowell",
      "Jefferson",
      "Kents hill",
      "Litchfield",
      "Manchester",
      "Mount vernon",
      "North whitefield",
      "Palermo",
      "Readfield",
      "Richmond",
      "South china",
      "Vienna",
      "Windsor",
      "Winthrop",
      "Zcta 043hh",
      "Bangor",
      "Abbot village",
      "Aurora",
      "Bradford",
      "Bradley",
      "Brewer",
      "Brookton",
      "Brownville",
      "Bucksport",
      "Burlington",
      "Cardville",
      "Carmel",
      "Castine",
      "Charleston",
      "Costigan",
      "Danforth",
      "Dover foxcroft",
      "East corinth",
      "East eddington",
      "East holden",
      "East millinocket",
      "East orland",
      "Etna",
      "Exeter",
      "Frankfort",
      "Greenville",
      "Greenville junct",
      "Guilford",
      "Hampden",
      "Seboeis",
      "Hudson",
      "Kenduskeag",
      "Kingman",
      "Lagrange",
      "Lee",
      "Levant",
      "Lincoln",
      "Mattawamkeag",
      "Medway",
      "Milford",
      "Millinocket",
      "Derby",
      "Monson",
      "Old town",
      "North amity",
      "Orland",
      "Orono",
      "Orrington",
      "Passadumkeag",
      "Penobscot",
      "Rockwood",
      "Sangerville",
      "Springfield",
      "Stetson",
      "Topsfield",
      "West enfield",
      "Winn",
      "Winterport",
      "Wytopitlock",
      "Zcta 044hh",
      "Zcta 044xx",
      "Bath",
      "Alna",
      "Boothbay",
      "Capitol island",
      "Bristol",
      "Chamberlain",
      "Damariscotta",
      "East boothbay",
      "Friendship",
      "Mac mahan",
      "Medomak",
      "Newcastle",
      "New harbor",
      "Nobleboro",
      "Edgecomb",
      "Pemaquid",
      "Phippsburg",
      "Cushing",
      "Round pond",
      "South bristol",
      "Waldoboro",
      "Walpole",
      "Washington",
      "West southport",
      "Wiscasset",
      "Woolwich",
      "Zcta 045hh",
      "Ellsworth",
      "Addison",
      "Gouldsboro",
      "Bar harbor",
      "Beals",
      "Bernard",
      "Birch harbor",
      "Blue hill",
      "Blue hill falls",
      "Brooklin",
      "Brooksville",
      "Calais",
      "Cherryfield",
      "Columbia falls",
      "Corea",
      "Cranberry isles",
      "Cutler",
      "Deer isle",
      "Dennysville",
      "East blue hill",
      "East machias",
      "Eastport",
      "Franklin",
      "Frenchboro",
      "Hancock",
      "Harborside",
      "Harrington",
      "Isle au haut",
      "Islesford",
      "Jonesboro",
      "Jonesport",
      "Little deer isle",
      "Lubec",
      "Bass harbor",
      "Machias",
      "Machiasport",
      "Meddybemps",
      "Milbridge",
      "Mount desert",
      "Northeast harbor",
      "North sullivan",
      "Pembroke",
      "Perry",
      "Princeton",
      "Prospect harbor",
      "Robbinston",
      "Salsbury cove",
      "Sargentville",
      "Seal cove",
      "Seal harbor",
      "Sedgwick",
      "Sorrento",
      "Southwest harbor",
      "Steuben",
      "Stonington",
      "Sunset",
      "Surry",
      "Swans island",
      "Whiting",
      "Winter harbor",
      "Woodland",
      "Zcta 046hh",
      "Zcta 046xx",
      "Houlton",
      "Ashland",
      "Benedicta",
      "Bridgewater",
      "Caribou",
      "Eagle lake",
      "Easton",
      "Fort fairfield",
      "Fort kent",
      "Frenchville",
      "Grand isle",
      "Island falls",
      "Limestone",
      "Madawaska",
      "Mapleton",
      "Mars hill",
      "Masardis",
      "Monticello",
      "New sweden",
      "Oakfield",
      "Oxbow",
      "Patten",
      "Perham",
      "Portage",
      "Presque isle",
      "Saint agatha",
      "Saint david",
      "Saint francis",
      "Sherman mills",
      "Sherman station",
      "Sinclair",
      "Smyrna mills",
      "Soldier pond",
      "Stockholm",
      "Van buren",
      "Washburn",
      "Westfield",
      "Zcta 047hh",
      "Zcta 047xx",
      "Rockland",
      "Camden",
      "Hope",
      "Islesboro",
      "Lincolnville",
      "Matinicus",
      "Monhegan",
      "North haven",
      "Owls head",
      "Rockport",
      "Saint george",
      "South thomaston",
      "Spruce head",
      "Tenants harbor",
      "Thomaston",
      "Union",
      "Vinalhaven",
      "Warren",
      "Zcta 048hh",
      "Winslow",
      "Albion",
      "Anson",
      "Athens",
      "Belfast",
      "Belgrade",
      "Bingham",
      "Brooks",
      "Burnham",
      "Cambridge",
      "Canaan",
      "Caratunk",
      "Clinton",
      "Corinna",
      "Detroit",
      "Dexter",
      "Dixmont",
      "Eustis",
      "Benton station",
      "Farmington",
      "Garland",
      "Freedom",
      "Wellington",
      "Hartland",
      "Jackman",
      "Kingfield",
      "Liberty",
      "Madison",
      "Monroe",
      "Morrill",
      "Newport",
      "New portland",
      "New sharon",
      "New vineyard",
      "Norridgewock",
      "North anson",
      "North new portla",
      "North vassalboro",
      "Oakland",
      "Oquossoc",
      "Palmyra",
      "Phillips",
      "Pittsfield",
      "Plymouth",
      "Rangeley",
      "Saint albans",
      "Searsmont",
      "Searsport",
      "Skowhegan",
      "Smithfield",
      "Solon",
      "Stockton springs",
      "Stratton",
      "Strong",
      "Temple",
      "West forks",
      "Thorndike",
      "Troy",
      "Unity",
      "Vassalboro",
      "Zcta 049hh",
      "Zcta 049xx",
    ],
    ZipCodes: {
      Berwick: ["03901"],
      "Cape neddick": ["03902"],
      Eliot: ["03903"],
      Kittery: ["03904"],
      "Kittery point": ["03905"],
      "North berwick": ["03906"],
      Ogunquit: ["03907"],
      "South berwick": ["03908"],
      York: ["03909"],
      "Zcta 039hh": ["039HH"],
      Acton: ["04001"],
      Alfred: ["04002"],
      "Bailey island": ["04003"],
      Arundel: ["04005"],
      Bowdoinham: ["04008"],
      Bridgton: ["04009"],
      Brownfield: ["04010"],
      "Birch island": ["04011"],
      Casco: ["04015"],
      "Chebeague island": ["04017"],
      "Cliff island": ["04019"],
      Cornish: ["04020"],
      "Cumberland cente": ["04021"],
      Denmark: ["04022"],
      "East baldwin": ["04024"],
      "West lebanon": ["04027"],
      "North sebago": ["04029"],
      "East waterboro": ["04030"],
      Freeport: ["04032"],
      Fryeburg: ["04037"],
      Gorham: ["04038"],
      Gray: ["04039"],
      Harrison: ["04040"],
      Hiram: ["04041"],
      "Hollis center": ["04042"],
      Kennebunk: ["04043"],
      Kennebunkport: ["04046"],
      "Kezar falls": ["04047"],
      Limerick: ["04048"],
      Limington: ["04049"],
      "Long island": ["04050"],
      Lovell: ["04051"],
      Naples: ["04055"],
      "North waterboro": ["04061"],
      Windham: ["04062"],
      "Old orchard beac": ["04064"],
      "Orrs island": ["04066"],
      Porter: ["04068"],
      Pownal: ["04069"],
      Raymond: ["04071"],
      Saco: ["04072"],
      Sanford: ["04073"],
      Scarborough: ["04074"],
      Shapleigh: ["04076"],
      "South harpswell": ["04079"],
      Springvale: ["04083"],
      Standish: ["04084"],
      "Steep falls": ["04085"],
      Pejepscot: ["04086"],
      Waterboro: ["04087"],
      Waterford: ["04088"],
      Wells: ["04090"],
      "West baldwin": ["04091"],
      Westbrook: ["04092"],
      "West buxton": ["04093"],
      Maplewood: ["04095"],
      Yarmouth: ["04096"],
      "North yarmouth": ["04097"],
      "Zcta 040hh": ["040HH"],
      Portland: ["04101", "04102", "04103"],
      Falmouth: ["04105"],
      "South portland": ["04106"],
      "Cape elizabeth": ["04107"],
      "Peaks island": ["04108"],
      "Cumberland fores": ["04110"],
      "Zcta 041hh": ["041HH"],
      Auburn: ["04210"],
      Andover: ["04216"],
      Bethel: ["04217"],
      "Bryant pond": ["04219"],
      Buckfield: ["04220"],
      Canton: ["04221"],
      Durham: ["04222"],
      Dixfield: ["04224"],
      "East andover": ["04226"],
      "East livermore": ["04228"],
      "East stoneham": ["04231"],
      Greene: ["04236"],
      Hanover: ["04237"],
      Hebron: ["04238"],
      Jay: ["04239"],
      Lewiston: ["04240"],
      Lisbon: ["04250"],
      "Lisbon falls": ["04252"],
      Livermore: ["04253"],
      "Livermore falls": ["04254"],
      "Locke mills": ["04255"],
      "Mechanic falls": ["04256"],
      Mexico: ["04257"],
      Minot: ["04258"],
      Monmouth: ["04259"],
      "New gloucester": ["04260"],
      Newry: ["04261"],
      Leeds: ["04263"],
      "North monmouth": ["04265"],
      Norway: ["04268"],
      Oxford: ["04270"],
      "Poland spring": ["04274"],
      Roxbury: ["04275"],
      Rumford: ["04276"],
      Sabattus: ["04280"],
      "South paris": ["04281"],
      Turner: ["04282"],
      Wayne: ["04284"],
      Weld: ["04285"],
      "West bowdoin": ["04287"],
      "West paris": ["04289"],
      Peru: ["04290"],
      "West sumner": ["04292"],
      Wilton: ["04294"],
      "Zcta 042hh": ["042HH"],
      "Zcta 042xx": ["042XX"],
      Augusta: ["04330"],
      Dresden: ["04342"],
      Farmingdale: ["04344"],
      Gardiner: ["04345"],
      Randolph: ["04346"],
      Hallowell: ["04347"],
      Jefferson: ["04348"],
      "Kents hill": ["04349"],
      Litchfield: ["04350"],
      Manchester: ["04351"],
      "Mount vernon": ["04352"],
      "North whitefield": ["04353"],
      Palermo: ["04354"],
      Readfield: ["04355"],
      Richmond: ["04357"],
      "South china": ["04358"],
      Vienna: ["04360"],
      Windsor: ["04363"],
      Winthrop: ["04364"],
      "Zcta 043hh": ["043HH"],
      Bangor: ["04401"],
      "Abbot village": ["04406"],
      Aurora: ["04408"],
      Bradford: ["04410"],
      Bradley: ["04411"],
      Brewer: ["04412"],
      Brookton: ["04413"],
      Brownville: ["04414"],
      Bucksport: ["04416"],
      Burlington: ["04417"],
      Cardville: ["04418"],
      Carmel: ["04419"],
      Castine: ["04421"],
      Charleston: ["04422"],
      Costigan: ["04423"],
      Danforth: ["04424"],
      "Dover foxcroft": ["04426"],
      "East corinth": ["04427"],
      "East eddington": ["04428"],
      "East holden": ["04429"],
      "East millinocket": ["04430"],
      "East orland": ["04431"],
      Etna: ["04434"],
      Exeter: ["04435"],
      Frankfort: ["04438"],
      Greenville: ["04441"],
      "Greenville junct": ["04442"],
      Guilford: ["04443"],
      Hampden: ["04444"],
      Seboeis: ["04448"],
      Hudson: ["04449"],
      Kenduskeag: ["04450"],
      Kingman: ["04451"],
      Lagrange: ["04453"],
      Lee: ["04455"],
      Levant: ["04456"],
      Lincoln: ["04457"],
      Mattawamkeag: ["04459"],
      Medway: ["04460"],
      Milford: ["04461"],
      Millinocket: ["04462"],
      Derby: ["04463"],
      Monson: ["04464"],
      "Old town": ["04468"],
      "North amity": ["04471"],
      Orland: ["04472"],
      Orono: ["04473"],
      Orrington: ["04474"],
      Passadumkeag: ["04475"],
      Penobscot: ["04476"],
      Rockwood: ["04478"],
      Sangerville: ["04479"],
      Springfield: ["04487"],
      Stetson: ["04488"],
      Topsfield: ["04490"],
      "West enfield": ["04493"],
      Winn: ["04495"],
      Winterport: ["04496"],
      Wytopitlock: ["04497"],
      "Zcta 044hh": ["044HH"],
      "Zcta 044xx": ["044XX"],
      Bath: ["04530"],
      Alna: ["04535"],
      Boothbay: ["04537"],
      "Capitol island": ["04538"],
      Bristol: ["04539"],
      Chamberlain: ["04541"],
      Damariscotta: ["04543"],
      "East boothbay": ["04544"],
      Friendship: ["04547"],
      "Mac mahan": ["04548"],
      Medomak: ["04551"],
      Newcastle: ["04553"],
      "New harbor": ["04554"],
      Nobleboro: ["04555"],
      Edgecomb: ["04556"],
      Pemaquid: ["04558"],
      Phippsburg: ["04562"],
      Cushing: ["04563"],
      "Round pond": ["04564"],
      "South bristol": ["04568"],
      Waldoboro: ["04572"],
      Walpole: ["04573"],
      Washington: ["04574"],
      "West southport": ["04576"],
      Wiscasset: ["04578"],
      Woolwich: ["04579"],
      "Zcta 045hh": ["045HH"],
      Ellsworth: ["04605"],
      Addison: ["04606"],
      Gouldsboro: ["04607"],
      "Bar harbor": ["04609"],
      Beals: ["04611"],
      Bernard: ["04612"],
      "Birch harbor": ["04613"],
      "Blue hill": ["04614"],
      "Blue hill falls": ["04615"],
      Brooklin: ["04616"],
      Brooksville: ["04617"],
      Calais: ["04619"],
      Cherryfield: ["04622"],
      "Columbia falls": ["04623"],
      Corea: ["04624"],
      "Cranberry isles": ["04625"],
      Cutler: ["04626"],
      "Deer isle": ["04627"],
      Dennysville: ["04628"],
      "East blue hill": ["04629"],
      "East machias": ["04630"],
      Eastport: ["04631"],
      Franklin: ["04634"],
      Frenchboro: ["04635"],
      Hancock: ["04640"],
      Harborside: ["04642"],
      Harrington: ["04643"],
      "Isle au haut": ["04645"],
      Islesford: ["04646"],
      Jonesboro: ["04648"],
      Jonesport: ["04649"],
      "Little deer isle": ["04650"],
      Lubec: ["04652"],
      "Bass harbor": ["04653"],
      Machias: ["04654"],
      Machiasport: ["04655"],
      Meddybemps: ["04657"],
      Milbridge: ["04658"],
      "Mount desert": ["04660"],
      "Northeast harbor": ["04662"],
      "North sullivan": ["04664"],
      Pembroke: ["04666"],
      Perry: ["04667"],
      Princeton: ["04668"],
      "Prospect harbor": ["04669"],
      Robbinston: ["04671"],
      "Salsbury cove": ["04672"],
      Sargentville: ["04673"],
      "Seal cove": ["04674"],
      "Seal harbor": ["04675"],
      Sedgwick: ["04676"],
      Sorrento: ["04677"],
      "Southwest harbor": ["04679"],
      Steuben: ["04680"],
      Stonington: ["04681"],
      Sunset: ["04683"],
      Surry: ["04684"],
      "Swans island": ["04685"],
      Whiting: ["04691"],
      "Winter harbor": ["04693"],
      Woodland: ["04694"],
      "Zcta 046hh": ["046HH"],
      "Zcta 046xx": ["046XX"],
      Houlton: ["04730"],
      Ashland: ["04732"],
      Benedicta: ["04733"],
      Bridgewater: ["04735"],
      Caribou: ["04736"],
      "Eagle lake": ["04739"],
      Easton: ["04740"],
      "Fort fairfield": ["04742"],
      "Fort kent": ["04743"],
      Frenchville: ["04745"],
      "Grand isle": ["04746"],
      "Island falls": ["04747"],
      Limestone: ["04750"],
      Madawaska: ["04756"],
      Mapleton: ["04757"],
      "Mars hill": ["04758"],
      Masardis: ["04759"],
      Monticello: ["04760"],
      "New sweden": ["04762"],
      Oakfield: ["04763"],
      Oxbow: ["04764"],
      Patten: ["04765"],
      Perham: ["04766"],
      Portage: ["04768"],
      "Presque isle": ["04769"],
      "Saint agatha": ["04772"],
      "Saint david": ["04773"],
      "Saint francis": ["04774"],
      "Sherman mills": ["04776"],
      "Sherman station": ["04777"],
      Sinclair: ["04779"],
      "Smyrna mills": ["04780"],
      "Soldier pond": ["04781"],
      Stockholm: ["04783"],
      "Van buren": ["04785"],
      Washburn: ["04786"],
      Westfield: ["04787"],
      "Zcta 047hh": ["047HH"],
      "Zcta 047xx": ["047XX"],
      Rockland: ["04841"],
      Camden: ["04843"],
      Hope: ["04847"],
      Islesboro: ["04848"],
      Lincolnville: ["04849"],
      Matinicus: ["04851"],
      Monhegan: ["04852"],
      "North haven": ["04853"],
      "Owls head": ["04854"],
      Rockport: ["04856"],
      "Saint george": ["04857"],
      "South thomaston": ["04858"],
      "Spruce head": ["04859"],
      "Tenants harbor": ["04860"],
      Thomaston: ["04861"],
      Union: ["04862"],
      Vinalhaven: ["04863"],
      Warren: ["04864"],
      "Zcta 048hh": ["048HH"],
      Winslow: ["04901"],
      Albion: ["04910"],
      Anson: ["04911"],
      Athens: ["04912"],
      Belfast: ["04915"],
      Belgrade: ["04917"],
      Bingham: ["04920"],
      Brooks: ["04921"],
      Burnham: ["04922"],
      Cambridge: ["04923"],
      Canaan: ["04924"],
      Caratunk: ["04925"],
      Clinton: ["04927"],
      Corinna: ["04928"],
      Detroit: ["04929"],
      Dexter: ["04930"],
      Dixmont: ["04932"],
      Eustis: ["04936"],
      "Benton station": ["04937"],
      Farmington: ["04938"],
      Garland: ["04939"],
      Freedom: ["04941"],
      Wellington: ["04942"],
      Hartland: ["04943"],
      Jackman: ["04945"],
      Kingfield: ["04947"],
      Liberty: ["04949"],
      Madison: ["04950"],
      Monroe: ["04951"],
      Morrill: ["04952"],
      Newport: ["04953"],
      "New portland": ["04954"],
      "New sharon": ["04955"],
      "New vineyard": ["04956"],
      Norridgewock: ["04957"],
      "North anson": ["04958"],
      "North new portla": ["04961"],
      "North vassalboro": ["04962"],
      Oakland: ["04963"],
      Oquossoc: ["04964"],
      Palmyra: ["04965"],
      Phillips: ["04966"],
      Pittsfield: ["04967"],
      Plymouth: ["04969"],
      Rangeley: ["04970"],
      "Saint albans": ["04971"],
      Searsmont: ["04973"],
      Searsport: ["04974"],
      Skowhegan: ["04976"],
      Smithfield: ["04978"],
      Solon: ["04979"],
      "Stockton springs": ["04981"],
      Stratton: ["04982"],
      Strong: ["04983"],
      Temple: ["04984"],
      "West forks": ["04985"],
      Thorndike: ["04986"],
      Troy: ["04987"],
      Unity: ["04988"],
      Vassalboro: ["04989"],
      "Zcta 049hh": ["049HH"],
      "Zcta 049xx": ["049XX"],
    },
  },
  {
    abbrevation: "MD",
    name: "Maryland",
    Cities: [
      "Waldorf",
      "Waldorf",
      "Saint charles",
      "Abell",
      "Accokeek",
      "Aquasco",
      "Avenue",
      "Bel alton",
      "Benedict",
      "Brandywine",
      "Broomes island",
      "Bryans road",
      "Bryantown",
      "Bushwood",
      "California",
      "Callaway",
      "Maddox",
      "Charlotte hall",
      "Cheltenham",
      "Clements",
      "Cobb island",
      "Coltons point",
      "Dameron",
      "Dowell",
      "Drayden",
      "Faulkner",
      "Great mills",
      "Hollywood",
      "Hughesville",
      "Huntingtown",
      "Pisgah",
      "Issue",
      "La plata",
      "Leonardtown",
      "Lexington park",
      "Lusby",
      "Rison",
      "Mechanicsville",
      "Nanjemoy",
      "Newburg",
      "Park hall",
      "Patuxent river",
      "Piney point",
      "Pomfret",
      "Port republic",
      "Port tobacco",
      "Prince frederick",
      "Ridge",
      "Saint inigoes",
      "Saint leonard",
      "Scotland",
      "Solomons",
      "Sunderland",
      "Tall timbers",
      "Valley lee",
      "Welcome",
      "White plains",
      "Zcta 206hh",
      "Annapolis juncti",
      "Beltsville",
      "Lanham",
      "Laurel",
      "Montpelier",
      "Bladensburg",
      "Lothian",
      "Mount rainier",
      "North beach",
      "Bowie",
      "Mitchellville",
      "Brentwood",
      "Chesapeake beach",
      "Churchton",
      "Clinton",
      "Owings",
      "Riverdale",
      "College park",
      "Capital heights",
      "Fort washington",
      "Oxon hill",
      "Suitland",
      "District heights",
      "Temple hills",
      "Deale",
      "Dunkirk",
      "Fort george g me",
      "Friendship",
      "Fulton",
      "Zcta 20762",
      "Savage",
      "Shady side",
      "Galesville",
      "Glenn dale",
      "Greenbelt",
      "Upper marlboro",
      "Harwood",
      "Highland",
      "West river",
      "Tracys landing",
      "Hyattsville",
      "West hyattsville",
      "Adelphi",
      "Landover hills",
      "Landover",
      "Jessup",
      "Zcta 207hh",
      "Glen echo",
      "Bethesda",
      "Chevy chase",
      "West bethesda",
      "Cabin john",
      "Olney",
      "Brookeville",
      "Poolesville",
      "Barnesville",
      "Beallsville",
      "Boyds",
      "Dickerson",
      "Rockville",
      "Potomac",
      "Derwood",
      "Sandy spring",
      "Ashton",
      "Brinklow",
      "Burtonsville",
      "Spencerville",
      "Clarksburg",
      "Damascus",
      "Darnestown",
      "Germantown",
      "Gaithersburg",
      "Laytonsville",
      "Washington grove",
      "Kensington",
      "Garrett park",
      "Zcta 208hh",
      "Silver spring",
      "Wheaton",
      "Colesville",
      "Aspen hill",
      "Takoma park",
      "Zcta 209hh",
      "Aberdeen",
      "Aberdeen proving",
      "Abingdon",
      "Gunpowder",
      "Arnold",
      "Baldwin",
      "Bel air",
      "Belcamp",
      "Churchville",
      "Clarksville",
      "Cockeysville hun",
      "Crownsville",
      "Darlington",
      "Davidsonville",
      "Dayton",
      "Edgewater beach",
      "Edgewood",
      "Ellicott city",
      "Daniels",
      "Columbia",
      "Fallston",
      "Patapsco",
      "Forest hill",
      "Fork",
      "Fort howard",
      "Freeland",
      "Gambrills",
      "Gibson island",
      "Glen arm",
      "Glen burnie",
      "Glyndon",
      "Greenmount",
      "Zcta 21075",
      "Hanover",
      "Harmans",
      "Havre de grace",
      "Hydes",
      "Jarrettsville",
      "Joppa",
      "Kingsville",
      "Linthicum height",
      "Lutherville",
      "Zcta 210hh",
      "Manchester",
      "Marriottsville",
      "Maryland line",
      "Millersville",
      "Hereford",
      "Odenton",
      "Crofton",
      "Owings mills",
      "Bentley springs",
      "Riviera beach",
      "Perry hall",
      "Perryman",
      "Jacksonville",
      "Pylesville",
      "Randallstown",
      "Reisterstown",
      "Riva",
      "Severn",
      "Severna park",
      "Glencoe",
      "Rocks",
      "Fowbelsburg",
      "Upper falls",
      "Carrollton",
      "Uniontown",
      "Whiteford",
      "White hall",
      "White marsh",
      "Granite",
      "Zcta 211hh",
      "Baltimore",
      "Eudowood",
      "Gwynn oak",
      "Pikesville",
      "Dundalk sparrows",
      "Middle river",
      "Essex",
      "Brooklyn curtis",
      "Halethorpe",
      "Catonsville",
      "Parkville",
      "Nottingham",
      "Rosedale",
      "Zcta 212hh",
      "Cape saint clair",
      "Naval academy",
      "Annapolis",
      "Sherwood forest",
      "Zcta 214hh",
      "Cresaptown",
      "Accident",
      "Barton",
      "Bittinger",
      "Bloomington",
      "Corriganville",
      "Eckhart mines",
      "Ellerslie",
      "Flintstone",
      "Friendsville",
      "Frostburg",
      "Jennings",
      "Shallmar",
      "Lonaconing",
      "Luke",
      "Sang run",
      "Midland",
      "Midlothian",
      "Mount savage",
      "Deer park",
      "Oldtown",
      "Rawlings",
      "Spring gap",
      "Swanton",
      "Mccoole",
      "Zcta 215hh",
      "Easton",
      "Barclay",
      "Betterton",
      "Bozman",
      "Cambridge",
      "Centreville",
      "Chester",
      "Chestertown",
      "Church creek",
      "Church hill",
      "Claiborne",
      "Cordova",
      "Crapo",
      "Crocheron",
      "Crumpton",
      "Denton",
      "East new market",
      "Federalsburg",
      "Fishing creek",
      "Galena",
      "Goldsboro",
      "Grasonville",
      "Greensboro",
      "Henderson",
      "Hillsboro",
      "Williamsburg",
      "Ingleside",
      "Kennedyville",
      "Mcdaniel",
      "Madison",
      "Marydel",
      "Massey",
      "Millington",
      "Newcomb",
      "Oxford",
      "Preston",
      "Queen anne",
      "Queenstown",
      "Rhodesdale",
      "Ridgely",
      "Rock hall",
      "Royal oak",
      "Saint michaels",
      "Secretary",
      "Sherwood",
      "Stevensville",
      "Still pond",
      "Sudlersville",
      "Taylors island",
      "Tilghman",
      "Toddville",
      "Trappe",
      "Wingate",
      "Wittman",
      "Woolford",
      "Worton",
      "Wye mills",
      "Zcta 216hh",
      "Lewistown",
      "Fort detrick",
      "Zcta 21703",
      "Zcta 21704",
      "Doubs",
      "Big pool",
      "Fahrney keedy me",
      "Brunswick",
      "Buckeystown",
      "Burkittsville",
      "Fort ritchie",
      "Big spring",
      "Cooksville",
      "Emmitsburg",
      "Fair play",
      "Funkstown",
      "Glenelg",
      "Glenwood",
      "Hagerstown",
      "Hancock",
      "Ijamsville",
      "Jefferson",
      "Keedysville",
      "Keymar",
      "Knoxville",
      "Libertytown",
      "Little orleans",
      "Maugansville",
      "Middletown",
      "Monrovia",
      "Mount airy",
      "Myersville",
      "New market",
      "New windsor",
      "Point of rocks",
      "Rocky ridge",
      "Rohrersville",
      "Sabillasville",
      "Sharpsburg",
      "Smithsburg",
      "Carrolltowne",
      "Taneytown",
      "Graceham",
      "Tuscarora",
      "Unionville",
      "Walkersville",
      "West friendship",
      "Williamsport",
      "Woodbine",
      "Woodsboro",
      "Zcta 217hh",
      "Salisbury",
      "Zcta 21804",
      "Allen",
      "Berlin",
      "Bishopville",
      "Bivalve",
      "Crisfield",
      "Deal island",
      "Eden",
      "Ewell",
      "Fruitland",
      "Girdletree",
      "Hebron",
      "Linkwood",
      "Mardela springs",
      "Marion station",
      "Nanticoke",
      "Newark",
      "Ocean city",
      "Parsonsburg",
      "Pittsville",
      "Pocomoke city",
      "Princess anne",
      "Quantico",
      "Sharptown",
      "Showell",
      "Snow hill",
      "Stockton",
      "Tyaskin",
      "Tylerton",
      "Upper fairmount",
      "Vienna",
      "Westover",
      "Whaleysville",
      "Willards",
      "Delmar",
      "Zcta 218hh",
      "Zcta 218xx",
      "North east",
      "Perry point",
      "Perryville",
      "Bainbridge",
      "Rising sun",
      "Warwick",
      "Cecilton",
      "Charlestown",
      "Chesapeake city",
      "Colora",
      "Conowingo",
      "Earleville",
      "Elk mills",
      "Elkton",
      "Georgetown",
      "Zcta 219hh",
    ],
    ZipCodes: {
      Waldorf: ["20601"],
      "Saint charles": ["20602", "20603"],
      Abell: ["20606"],
      Accokeek: ["20607"],
      Aquasco: ["20608"],
      Avenue: ["20609"],
      "Bel alton": ["20611"],
      Benedict: ["20612"],
      Brandywine: ["20613"],
      "Broomes island": ["20615"],
      "Bryans road": ["20616"],
      Bryantown: ["20617"],
      Bushwood: ["20618"],
      California: ["20619"],
      Callaway: ["20620"],
      Maddox: ["20621"],
      "Charlotte hall": ["20622"],
      Cheltenham: ["20623"],
      Clements: ["20624"],
      "Cobb island": ["20625"],
      "Coltons point": ["20626"],
      Dameron: ["20628"],
      Dowell: ["20629"],
      Drayden: ["20630"],
      Faulkner: ["20632"],
      "Great mills": ["20634"],
      Hollywood: ["20636"],
      Hughesville: ["20637"],
      Huntingtown: ["20639"],
      Pisgah: ["20640"],
      Issue: ["20645"],
      "La plata": ["20646"],
      Leonardtown: ["20650"],
      "Lexington park": ["20653"],
      Lusby: ["20657"],
      Rison: ["20658"],
      Mechanicsville: ["20659"],
      Nanjemoy: ["20662"],
      Newburg: ["20664"],
      "Park hall": ["20667"],
      "Patuxent river": ["20670"],
      "Piney point": ["20674"],
      Pomfret: ["20675"],
      "Port republic": ["20676"],
      "Port tobacco": ["20677"],
      "Prince frederick": ["20678"],
      Ridge: ["20680"],
      "Saint inigoes": ["20684"],
      "Saint leonard": ["20685"],
      Scotland: ["20687"],
      Solomons: ["20688"],
      Sunderland: ["20689"],
      "Tall timbers": ["20690"],
      "Valley lee": ["20692"],
      Welcome: ["20693"],
      "White plains": ["20695"],
      "Zcta 206hh": ["206HH"],
      "Annapolis juncti": ["20701"],
      Beltsville: ["20705"],
      Lanham: ["20706"],
      Laurel: ["20707", "20723", "20724"],
      Montpelier: ["20708"],
      Bladensburg: ["20710"],
      Lothian: ["20711"],
      "Mount rainier": ["20712"],
      "North beach": ["20714"],
      Bowie: ["20715", "20720"],
      Mitchellville: ["20716", "20721"],
      Brentwood: ["20722"],
      "Chesapeake beach": ["20732"],
      Churchton: ["20733"],
      Clinton: ["20735"],
      Owings: ["20736"],
      Riverdale: ["20737"],
      "College park": ["20740"],
      "Capital heights": ["20743"],
      "Fort washington": ["20744"],
      "Oxon hill": ["20745"],
      Suitland: ["20746"],
      "District heights": ["20747"],
      "Temple hills": ["20748"],
      Deale: ["20751"],
      Dunkirk: ["20754"],
      "Fort george g me": ["20755"],
      Friendship: ["20758"],
      Fulton: ["20759"],
      "Zcta 20762": ["20762"],
      Savage: ["20763"],
      "Shady side": ["20764"],
      Galesville: ["20765"],
      "Glenn dale": ["20769"],
      Greenbelt: ["20770"],
      "Upper marlboro": ["20772", "20774"],
      Harwood: ["20776"],
      Highland: ["20777"],
      "West river": ["20778"],
      "Tracys landing": ["20779"],
      Hyattsville: ["20781"],
      "West hyattsville": ["20782"],
      Adelphi: ["20783"],
      "Landover hills": ["20784"],
      Landover: ["20785"],
      Jessup: ["20794"],
      "Zcta 207hh": ["207HH"],
      "Glen echo": ["20812"],
      Bethesda: ["20814", "20816"],
      "Chevy chase": ["20815"],
      "West bethesda": ["20817"],
      "Cabin john": ["20818"],
      Olney: ["20832"],
      Brookeville: ["20833"],
      Poolesville: ["20837"],
      Barnesville: ["20838"],
      Beallsville: ["20839"],
      Boyds: ["20841"],
      Dickerson: ["20842"],
      Rockville: ["20850", "20851", "20852", "20853"],
      Potomac: ["20854"],
      Derwood: ["20855"],
      "Sandy spring": ["20860"],
      Ashton: ["20861"],
      Brinklow: ["20862"],
      Burtonsville: ["20866"],
      Spencerville: ["20868"],
      Clarksburg: ["20871"],
      Damascus: ["20872"],
      Darnestown: ["20874", "20878"],
      Germantown: ["20876"],
      Gaithersburg: ["20877", "20886"],
      Laytonsville: ["20879", "20882"],
      "Washington grove": ["20880"],
      Kensington: ["20895"],
      "Garrett park": ["20896"],
      "Zcta 208hh": ["208HH"],
      "Silver spring": ["20901", "20903", "20910"],
      Wheaton: ["20902"],
      Colesville: ["20904", "20905"],
      "Aspen hill": ["20906"],
      "Takoma park": ["20912"],
      "Zcta 209hh": ["209HH"],
      Aberdeen: ["21001"],
      "Aberdeen proving": ["21005"],
      Abingdon: ["21009"],
      Gunpowder: ["21010"],
      Arnold: ["21012"],
      Baldwin: ["21013"],
      "Bel air": ["21014", "21015"],
      Belcamp: ["21017"],
      Churchville: ["21028"],
      Clarksville: ["21029"],
      "Cockeysville hun": ["21030", "21031"],
      Crownsville: ["21032"],
      Darlington: ["21034"],
      Davidsonville: ["21035"],
      Dayton: ["21036"],
      "Edgewater beach": ["21037"],
      Edgewood: ["21040"],
      "Ellicott city": ["21042"],
      Daniels: ["21043"],
      Columbia: ["21044", "21045", "21046"],
      Fallston: ["21047"],
      Patapsco: ["21048"],
      "Forest hill": ["21050"],
      Fork: ["21051"],
      "Fort howard": ["21052"],
      Freeland: ["21053"],
      Gambrills: ["21054"],
      "Gibson island": ["21056"],
      "Glen arm": ["21057"],
      "Glen burnie": ["21060", "21061"],
      Glyndon: ["21071"],
      Greenmount: ["21074"],
      "Zcta 21075": ["21075"],
      Hanover: ["21076"],
      Harmans: ["21077"],
      "Havre de grace": ["21078"],
      Hydes: ["21082"],
      Jarrettsville: ["21084"],
      Joppa: ["21085"],
      Kingsville: ["21087"],
      "Linthicum height": ["21090"],
      Lutherville: ["21093"],
      "Zcta 210hh": ["210HH"],
      Manchester: ["21102"],
      Marriottsville: ["21104"],
      "Maryland line": ["21105"],
      Millersville: ["21108"],
      Hereford: ["21111"],
      Odenton: ["21113"],
      Crofton: ["21114"],
      "Owings mills": ["21117"],
      "Bentley springs": ["21120"],
      "Riviera beach": ["21122"],
      "Perry hall": ["21128"],
      Perryman: ["21130"],
      Jacksonville: ["21131"],
      Pylesville: ["21132"],
      Randallstown: ["21133"],
      Reisterstown: ["21136"],
      Riva: ["21140"],
      Severn: ["21144"],
      "Severna park": ["21146"],
      Glencoe: ["21152"],
      Rocks: ["21154"],
      Fowbelsburg: ["21155"],
      "Upper falls": ["21156"],
      Carrollton: ["21157"],
      Uniontown: ["21158"],
      Whiteford: ["21160"],
      "White hall": ["21161"],
      "White marsh": ["21162"],
      Granite: ["21163"],
      "Zcta 211hh": ["211HH"],
      Baltimore: [
        "21201",
        "21202",
        "21205",
        "21206",
        "21209",
        "21210",
        "21211",
        "21212",
        "21213",
        "21214",
        "21215",
        "21216",
        "21217",
        "21218",
        "21223",
        "21224",
        "21229",
        "21230",
        "21231",
        "21239",
        "21286",
      ],
      Eudowood: ["21204"],
      "Gwynn oak": ["21207", "21244"],
      Pikesville: ["21208"],
      "Dundalk sparrows": ["21219", "21222"],
      "Middle river": ["21220"],
      Essex: ["21221"],
      "Brooklyn curtis": ["21225", "21226"],
      Halethorpe: ["21227"],
      Catonsville: ["21228"],
      Parkville: ["21234"],
      Nottingham: ["21236"],
      Rosedale: ["21237"],
      "Zcta 212hh": ["212HH"],
      "Cape saint clair": ["21401"],
      "Naval academy": ["21402"],
      Annapolis: ["21403"],
      "Sherwood forest": ["21405"],
      "Zcta 214hh": ["214HH"],
      Cresaptown: ["21502"],
      Accident: ["21520"],
      Barton: ["21521"],
      Bittinger: ["21522"],
      Bloomington: ["21523"],
      Corriganville: ["21524"],
      "Eckhart mines": ["21528"],
      Ellerslie: ["21529"],
      Flintstone: ["21530"],
      Friendsville: ["21531"],
      Frostburg: ["21532"],
      Jennings: ["21536"],
      Shallmar: ["21538"],
      Lonaconing: ["21539"],
      Luke: ["21540"],
      "Sang run": ["21541"],
      Midland: ["21542"],
      Midlothian: ["21543"],
      "Mount savage": ["21545"],
      "Deer park": ["21550"],
      Oldtown: ["21555"],
      Rawlings: ["21557"],
      "Spring gap": ["21560"],
      Swanton: ["21561"],
      Mccoole: ["21562"],
      "Zcta 215hh": ["215HH"],
      Easton: ["21601"],
      Barclay: ["21607"],
      Betterton: ["21610"],
      Bozman: ["21612"],
      Cambridge: ["21613"],
      Centreville: ["21617"],
      Chester: ["21619"],
      Chestertown: ["21620"],
      "Church creek": ["21622"],
      "Church hill": ["21623"],
      Claiborne: ["21624"],
      Cordova: ["21625"],
      Crapo: ["21626"],
      Crocheron: ["21627"],
      Crumpton: ["21628"],
      Denton: ["21629"],
      "East new market": ["21631"],
      Federalsburg: ["21632"],
      "Fishing creek": ["21634"],
      Galena: ["21635"],
      Goldsboro: ["21636"],
      Grasonville: ["21638"],
      Greensboro: ["21639"],
      Henderson: ["21640"],
      Hillsboro: ["21641"],
      Williamsburg: ["21643"],
      Ingleside: ["21644"],
      Kennedyville: ["21645"],
      Mcdaniel: ["21647"],
      Madison: ["21648"],
      Marydel: ["21649"],
      Massey: ["21650"],
      Millington: ["21651"],
      Newcomb: ["21653"],
      Oxford: ["21654"],
      Preston: ["21655"],
      "Queen anne": ["21657"],
      Queenstown: ["21658"],
      Rhodesdale: ["21659"],
      Ridgely: ["21660"],
      "Rock hall": ["21661"],
      "Royal oak": ["21662"],
      "Saint michaels": ["21663"],
      Secretary: ["21664"],
      Sherwood: ["21665"],
      Stevensville: ["21666"],
      "Still pond": ["21667"],
      Sudlersville: ["21668"],
      "Taylors island": ["21669"],
      Tilghman: ["21671"],
      Toddville: ["21672"],
      Trappe: ["21673"],
      Wingate: ["21675"],
      Wittman: ["21676"],
      Woolford: ["21677"],
      Worton: ["21678"],
      "Wye mills": ["21679"],
      "Zcta 216hh": ["216HH"],
      Lewistown: ["21701"],
      "Fort detrick": ["21702"],
      "Zcta 21703": ["21703"],
      "Zcta 21704": ["21704"],
      Doubs: ["21710"],
      "Big pool": ["21711"],
      "Fahrney keedy me": ["21713"],
      Brunswick: ["21716"],
      Buckeystown: ["21717"],
      Burkittsville: ["21718"],
      "Fort ritchie": ["21719"],
      "Big spring": ["21722"],
      Cooksville: ["21723"],
      Emmitsburg: ["21727"],
      "Fair play": ["21733"],
      Funkstown: ["21734"],
      Glenelg: ["21737"],
      Glenwood: ["21738"],
      Hagerstown: ["21740", "21742"],
      Hancock: ["21750"],
      Ijamsville: ["21754"],
      Jefferson: ["21755"],
      Keedysville: ["21756"],
      Keymar: ["21757"],
      Knoxville: ["21758"],
      Libertytown: ["21762"],
      "Little orleans": ["21766"],
      Maugansville: ["21767"],
      Middletown: ["21769"],
      Monrovia: ["21770"],
      "Mount airy": ["21771"],
      Myersville: ["21773"],
      "New market": ["21774"],
      "New windsor": ["21776"],
      "Point of rocks": ["21777"],
      "Rocky ridge": ["21778"],
      Rohrersville: ["21779"],
      Sabillasville: ["21780"],
      Sharpsburg: ["21782"],
      Smithsburg: ["21783"],
      Carrolltowne: ["21784"],
      Taneytown: ["21787"],
      Graceham: ["21788"],
      Tuscarora: ["21790"],
      Unionville: ["21791"],
      Walkersville: ["21793"],
      "West friendship": ["21794"],
      Williamsport: ["21795"],
      Woodbine: ["21797"],
      Woodsboro: ["21798"],
      "Zcta 217hh": ["217HH"],
      Salisbury: ["21801"],
      "Zcta 21804": ["21804"],
      Allen: ["21810"],
      Berlin: ["21811"],
      Bishopville: ["21813"],
      Bivalve: ["21814"],
      Crisfield: ["21817"],
      "Deal island": ["21821"],
      Eden: ["21822"],
      Ewell: ["21824"],
      Fruitland: ["21826"],
      Girdletree: ["21829"],
      Hebron: ["21830"],
      Linkwood: ["21835"],
      "Mardela springs": ["21837"],
      "Marion station": ["21838"],
      Nanticoke: ["21840"],
      Newark: ["21841"],
      "Ocean city": ["21842"],
      Parsonsburg: ["21849"],
      Pittsville: ["21850"],
      "Pocomoke city": ["21851"],
      "Princess anne": ["21853"],
      Quantico: ["21856"],
      Sharptown: ["21861"],
      Showell: ["21862"],
      "Snow hill": ["21863"],
      Stockton: ["21864"],
      Tyaskin: ["21865"],
      Tylerton: ["21866"],
      "Upper fairmount": ["21867"],
      Vienna: ["21869"],
      Westover: ["21871"],
      Whaleysville: ["21872"],
      Willards: ["21874"],
      Delmar: ["21875"],
      "Zcta 218hh": ["218HH"],
      "Zcta 218xx": ["218XX"],
      "North east": ["21901"],
      "Perry point": ["21902"],
      Perryville: ["21903"],
      Bainbridge: ["21904"],
      "Rising sun": ["21911"],
      Warwick: ["21912"],
      Cecilton: ["21913"],
      Charlestown: ["21914"],
      "Chesapeake city": ["21915"],
      Colora: ["21917"],
      Conowingo: ["21918"],
      Earleville: ["21919"],
      "Elk mills": ["21920"],
      Elkton: ["21921"],
      Georgetown: ["21930"],
      "Zcta 219hh": ["219HH"],
    },
  },
  {
    abbrevation: "MA",
    name: "Massachusetts",
    Cities: [
      "Agawam",
      "Agawam",
      "Cushman",
      "Barre",
      "Belchertown",
      "Blandford",
      "Brimfield",
      "Chester",
      "Chesterfield",
      "Chicopee",
      "Westover afb",
      "Cummington",
      "Mount tom",
      "East longmeadow",
      "East otis",
      "Feeding hills",
      "Gilbertville",
      "Goshen",
      "Granby",
      "Tolland",
      "Hadley",
      "Hampden",
      "Hardwick",
      "Hatfield",
      "Haydenville",
      "Holyoke",
      "Huntington",
      "Leeds",
      "Leverett",
      "Ludlow",
      "Monson",
      "Florence",
      "Zcta 01062",
      "Oakham",
      "Palmer",
      "Plainfield",
      "Russell",
      "Shutesbury",
      "Southampton",
      "South hadley",
      "Southwick",
      "Three rivers",
      "Wales",
      "Ware",
      "Warren",
      "West chesterfiel",
      "Montgomery",
      "W hatfield",
      "West springfield",
      "West warren",
      "Wilbraham",
      "Williamsburg",
      "Worthington",
      "Zcta 010hh",
      "Springfield",
      "Longmeadow",
      "Indian orchard",
      "Zcta 011hh",
      "Pittsfield",
      "Adams",
      "Ashley falls",
      "Becket",
      "Cheshire",
      "Dalton",
      "Great barrington",
      "Peru",
      "Housatonic",
      "Hancock",
      "Lee",
      "Lenox",
      "Lenox dale",
      "Mill river",
      "West otis",
      "Clarksburg",
      "Otis",
      "Richmond",
      "Sandisfield",
      "Savoy",
      "Sheffield",
      "South egremont",
      "Southfield",
      "Stockbridge",
      "Tyringham",
      "West stockbridge",
      "Williamstown",
      "Zcta 012hh",
      "Leyden",
      "Ashfield",
      "New salem",
      "Buckland",
      "Hawley",
      "Colrain",
      "Conway",
      "Deerfield",
      "Erving",
      "Heath",
      "Millers falls",
      "Monroe",
      "Montague",
      "Northfield",
      "Petersham",
      "Rowe",
      "Royalston",
      "Shelburne falls",
      "South deerfield",
      "Sunderland",
      "Turners falls",
      "Warwick",
      "Wendell",
      "Wendell depot",
      "Zcta 013hh",
      "Fitchburg",
      "Ashburnham",
      "Ashby",
      "Ayer",
      "Baldwinville",
      "East templeton",
      "Gardner",
      "Groton",
      "Harvard",
      "Hubbardston",
      "Leominster",
      "Littleton",
      "Lunenburg",
      "Pepperell",
      "Shirley center",
      "Still river",
      "Templeton",
      "Townsend",
      "Westminster",
      "W townsend",
      "Winchendon",
      "Zcta 014hh",
      "Auburn",
      "Berlin",
      "Blackstone",
      "Boylston",
      "Brookfield",
      "Charlton",
      "Clinton",
      "East brookfield",
      "East douglas",
      "Fiskdale",
      "Grafton",
      "Holden",
      "Holland",
      "Jefferson",
      "Lancaster",
      "Leicester",
      "Millbury",
      "Millville",
      "New braintree",
      "Northborough",
      "Northbridge",
      "North brookfield",
      "North grafton",
      "North oxford",
      "Oxford",
      "Princeton",
      "Rochdale",
      "Rutland",
      "Shrewsbury",
      "Southbridge",
      "South grafton",
      "Spencer",
      "Sterling",
      "Sturbridge",
      "West upton",
      "Uxbridge",
      "Dudley hill",
      "Dudley",
      "Westborough",
      "West boylston",
      "West brookfield",
      "Whitinsville",
      "Wilkinsonville",
      "Zcta 015hh",
      "Worcester",
      "Cherry valley",
      "Paxton",
      "Zcta 016hh",
      "Framingham",
      "Zcta 01702",
      "Village of nagog",
      "Boxboro",
      "Acton",
      "Ashland",
      "Bedford",
      "Hanscom afb",
      "Bolton",
      "Carlisle",
      "Concord",
      "Southborough",
      "Holliston",
      "Hopedale",
      "Hopkinton",
      "Hudson",
      "Marlborough",
      "Maynard",
      "Mendon",
      "Milford",
      "Natick",
      "Sherborn",
      "Lincoln",
      "Stow",
      "Sudbury",
      "Wayland",
      "Woburn",
      "Burlington",
      "Andover",
      "Billerica",
      "South chelmsford",
      "Dracut",
      "Dunstable",
      "Haverhill",
      "Georgetown",
      "Groveland",
      "Bradford",
      "Lawrence",
      "Methuen",
      "North andover",
      "Lowell",
      "Merrimac",
      "North billerica",
      "North chelmsford",
      "North reading",
      "Reading",
      "Tewksbury",
      "Tyngsboro",
      "Wakefield",
      "Graniteville",
      "Wilmington",
      "Winchester",
      "Zcta 018hh",
      "Lynn",
      "East lynn",
      "West lynn",
      "Saugus",
      "Swampscott",
      "Nahant",
      "Amesbury",
      "Beverly",
      "Boxford",
      "Byfield",
      "Danvers",
      "Essex",
      "Gloucester",
      "Ipswich",
      "Lynnfield",
      "Manchester",
      "Marblehead",
      "Middleton",
      "Newburyport",
      "Newbury",
      "Salisbury",
      "Peabody",
      "Rockport",
      "Rowley",
      "Salem",
      "South hamilton",
      "Topsfield",
      "Wenham",
      "West newbury",
      "Zcta 019hh",
      "Bellingham",
      "Canton",
      "Cohasset",
      "Dedham",
      "Dover",
      "East walpole",
      "Foxboro",
      "Franklin",
      "Hingham",
      "Hull",
      "Mansfield",
      "Marshfield",
      "Medfield",
      "Medway",
      "Millis",
      "Norfolk",
      "Norwell",
      "Norwood",
      "Scituate",
      "Sharon",
      "South walpole",
      "Stoughton",
      "Walpole",
      "Westwood",
      "Wrentham",
      "Zcta 020hh",
      "Boston",
      "Roxbury",
      "Dorchester",
      "Mattapan",
      "South boston",
      "East boston",
      "Charlestown",
      "Jamaica plain",
      "Roslindale",
      "West roxbury",
      "Allston",
      "Brighton",
      "Hyde park",
      "Cambridge",
      "North cambridge",
      "East cambridge",
      "Somerville",
      "Malden",
      "Everett",
      "Chelsea",
      "Revere",
      "Winthrop",
      "Medford",
      "Quincy",
      "Melrose",
      "Stoneham",
      "Braintree",
      "Milton",
      "Weymouth",
      "Zcta 021hh",
      "Zcta 022hh",
      "Zcta 02301",
      "Zcta 02302",
      "Avon",
      "Bridgewater",
      "Carver",
      "Duxbury",
      "East bridgewater",
      "Halifax",
      "Hanover",
      "Hanson",
      "Holbrook",
      "Middleboro",
      "Lakeville",
      "Abington",
      "North easton",
      "North pembroke",
      "Pembroke",
      "Plymouth",
      "Kingston",
      "South carver",
      "Plympton",
      "Randolph",
      "Rockland",
      "South easton",
      "West bridgewater",
      "Whitman",
      "Zcta 023hh",
      "Zcta 023xx",
      "Zcta 02420",
      "Zcta 02421",
      "Zcta 02445",
      "Zcta 02446",
      "Zcta 02451",
      "Zcta 02452",
      "Zcta 02453",
      "Zcta 02458",
      "Zcta 02459",
      "Zcta 02460",
      "Zcta 02461",
      "Zcta 02462",
      "Zcta 02464",
      "Zcta 02465",
      "Zcta 02466",
      "Zcta 02467",
      "Zcta 02468",
      "Zcta 02472",
      "Zcta 02474",
      "Zcta 02476",
      "Zcta 02478",
      "Zcta 02481",
      "Zcta 02482",
      "Zcta 02492",
      "Zcta 02493",
      "Zcta 02494",
      "Onset",
      "Cataumet",
      "Chilmark",
      "Teaticket",
      "East sandwich",
      "East wareham",
      "Edgartown",
      "Falmouth",
      "Otis a f b",
      "Woods hole",
      "Nantucket",
      "North falmouth",
      "Pocasset",
      "Sandwich",
      "Vineyard haven",
      "Wareham",
      "West tisbury",
      "West wareham",
      "Zcta 025hh",
      "Zcta 025xx",
      "West yarmouth",
      "Barnstable",
      "Brewster",
      "Centerville",
      "South chatham",
      "Cotuit",
      "Dennis",
      "Dennis port",
      "Eastham",
      "Forestdale",
      "Harwich",
      "Harwich port",
      "Hyannis port",
      "Marstons mills",
      "Mashpee",
      "North chatham",
      "North truro",
      "Orleans",
      "Osterville",
      "Provincetown",
      "South dennis",
      "South harwich",
      "Bass river",
      "Truro",
      "Wellfleet",
      "West barnstable",
      "West chatham",
      "West dennis",
      "West harwich",
      "West hyannisport",
      "Yarmouth port",
      "Zcta 026hh",
      "Assonet",
      "Attleboro",
      "Cuttyhunk",
      "Dighton",
      "East freetown",
      "East taunton",
      "Fairhaven",
      "Fall river",
      "Somerset",
      "Marion",
      "Mattapoisett",
      "New bedford",
      "Acushnet",
      "North dartmouth",
      "Padanaram villag",
      "North attleboro",
      "Plainville",
      "North dighton",
      "Norton",
      "Raynham",
      "Rehoboth",
      "Rochester",
      "Seekonk",
      "Swansea",
      "Berkley",
      "Taunton",
      "Westport",
      "Zcta 027hh",
    ],
    ZipCodes: {
      Agawam: ["01001"],
      Cushman: ["01002"],
      Barre: ["01005"],
      Belchertown: ["01007"],
      Blandford: ["01008"],
      Brimfield: ["01010"],
      Chester: ["01011"],
      Chesterfield: ["01012"],
      Chicopee: ["01013", "01020"],
      "Westover afb": ["01022"],
      Cummington: ["01026"],
      "Mount tom": ["01027"],
      "East longmeadow": ["01028"],
      "East otis": ["01029"],
      "Feeding hills": ["01030"],
      Gilbertville: ["01031"],
      Goshen: ["01032"],
      Granby: ["01033"],
      Tolland: ["01034"],
      Hadley: ["01035"],
      Hampden: ["01036"],
      Hardwick: ["01037"],
      Hatfield: ["01038"],
      Haydenville: ["01039"],
      Holyoke: ["01040"],
      Huntington: ["01050"],
      Leeds: ["01053"],
      Leverett: ["01054"],
      Ludlow: ["01056"],
      Monson: ["01057"],
      Florence: ["01060"],
      "Zcta 01062": ["01062"],
      Oakham: ["01068"],
      Palmer: ["01069"],
      Plainfield: ["01070"],
      Russell: ["01071"],
      Shutesbury: ["01072"],
      Southampton: ["01073"],
      "South hadley": ["01075"],
      Southwick: ["01077"],
      "Three rivers": ["01080"],
      Wales: ["01081"],
      Ware: ["01082"],
      Warren: ["01083"],
      "West chesterfiel": ["01084"],
      Montgomery: ["01085"],
      "W hatfield": ["01088"],
      "West springfield": ["01089"],
      "West warren": ["01092"],
      Wilbraham: ["01095"],
      Williamsburg: ["01096"],
      Worthington: ["01098"],
      "Zcta 010hh": ["010HH"],
      Springfield: [
        "01103",
        "01104",
        "01105",
        "01107",
        "01108",
        "01109",
        "01118",
        "01119",
        "01128",
        "01129",
      ],
      Longmeadow: ["01106"],
      "Indian orchard": ["01151"],
      "Zcta 011hh": ["011HH"],
      Pittsfield: ["01201"],
      Adams: ["01220"],
      "Ashley falls": ["01222"],
      Becket: ["01223"],
      Cheshire: ["01225"],
      Dalton: ["01226"],
      "Great barrington": ["01230"],
      Peru: ["01235"],
      Housatonic: ["01236"],
      Hancock: ["01237"],
      Lee: ["01238"],
      Lenox: ["01240"],
      "Lenox dale": ["01242"],
      "Mill river": ["01244"],
      "West otis": ["01245"],
      Clarksburg: ["01247"],
      Otis: ["01253"],
      Richmond: ["01254"],
      Sandisfield: ["01255"],
      Savoy: ["01256"],
      Sheffield: ["01257"],
      "South egremont": ["01258"],
      Southfield: ["01259"],
      Stockbridge: ["01262"],
      Tyringham: ["01264"],
      "West stockbridge": ["01266"],
      Williamstown: ["01267"],
      "Zcta 012hh": ["012HH"],
      Leyden: ["01301", "01337"],
      Ashfield: ["01330"],
      "New salem": ["01331", "01355", "01364"],
      Buckland: ["01338"],
      Hawley: ["01339"],
      Colrain: ["01340"],
      Conway: ["01341"],
      Deerfield: ["01342"],
      Erving: ["01344"],
      Heath: ["01346"],
      "Millers falls": ["01349"],
      Monroe: ["01350"],
      Montague: ["01351"],
      Northfield: ["01360"],
      Petersham: ["01366"],
      Rowe: ["01367"],
      Royalston: ["01368"],
      "Shelburne falls": ["01370"],
      "South deerfield": ["01373"],
      Sunderland: ["01375"],
      "Turners falls": ["01376"],
      Warwick: ["01378"],
      Wendell: ["01379"],
      "Wendell depot": ["01380"],
      "Zcta 013hh": ["013HH"],
      Fitchburg: ["01420"],
      Ashburnham: ["01430"],
      Ashby: ["01431"],
      Ayer: ["01432"],
      Baldwinville: ["01436"],
      "East templeton": ["01438"],
      Gardner: ["01440"],
      Groton: ["01450"],
      Harvard: ["01451"],
      Hubbardston: ["01452"],
      Leominster: ["01453"],
      Littleton: ["01460"],
      Lunenburg: ["01462"],
      Pepperell: ["01463"],
      "Shirley center": ["01464"],
      "Still river": ["01467"],
      Templeton: ["01468"],
      Townsend: ["01469"],
      Westminster: ["01473"],
      "W townsend": ["01474"],
      Winchendon: ["01475"],
      "Zcta 014hh": ["014HH"],
      Auburn: ["01501"],
      Berlin: ["01503"],
      Blackstone: ["01504"],
      Boylston: ["01505"],
      Brookfield: ["01506"],
      Charlton: ["01507"],
      Clinton: ["01510"],
      "East brookfield": ["01515"],
      "East douglas": ["01516"],
      Fiskdale: ["01518"],
      Grafton: ["01519"],
      Holden: ["01520"],
      Holland: ["01521"],
      Jefferson: ["01522"],
      Lancaster: ["01523"],
      Leicester: ["01524"],
      Millbury: ["01527"],
      Millville: ["01529"],
      "New braintree": ["01531"],
      Northborough: ["01532"],
      Northbridge: ["01534"],
      "North brookfield": ["01535"],
      "North grafton": ["01536"],
      "North oxford": ["01537"],
      Oxford: ["01540"],
      Princeton: ["01541"],
      Rochdale: ["01542"],
      Rutland: ["01543"],
      Shrewsbury: ["01545"],
      Southbridge: ["01550"],
      "South grafton": ["01560"],
      Spencer: ["01562"],
      Sterling: ["01564"],
      Sturbridge: ["01566"],
      "West upton": ["01568"],
      Uxbridge: ["01569"],
      "Dudley hill": ["01570"],
      Dudley: ["01571"],
      Westborough: ["01581"],
      "West boylston": ["01583"],
      "West brookfield": ["01585"],
      Whitinsville: ["01588"],
      Wilkinsonville: ["01590"],
      "Zcta 015hh": ["015HH"],
      Worcester: [
        "01602",
        "01603",
        "01604",
        "01605",
        "01606",
        "01607",
        "01608",
        "01609",
        "01610",
      ],
      "Cherry valley": ["01611"],
      Paxton: ["01612"],
      "Zcta 016hh": ["016HH"],
      Framingham: ["01701"],
      "Zcta 01702": ["01702"],
      "Village of nagog": ["01718"],
      Boxboro: ["01719"],
      Acton: ["01720"],
      Ashland: ["01721"],
      Bedford: ["01730"],
      "Hanscom afb": ["01731"],
      Bolton: ["01740"],
      Carlisle: ["01741"],
      Concord: ["01742"],
      Southborough: ["01745", "01772"],
      Holliston: ["01746"],
      Hopedale: ["01747"],
      Hopkinton: ["01748"],
      Hudson: ["01749"],
      Marlborough: ["01752"],
      Maynard: ["01754"],
      Mendon: ["01756"],
      Milford: ["01757"],
      Natick: ["01760"],
      Sherborn: ["01770"],
      Lincoln: ["01773"],
      Stow: ["01775"],
      Sudbury: ["01776"],
      Wayland: ["01778"],
      Woburn: ["01801"],
      Burlington: ["01803"],
      Andover: ["01810"],
      Billerica: ["01821"],
      "South chelmsford": ["01824"],
      Dracut: ["01826"],
      Dunstable: ["01827"],
      Haverhill: ["01830", "01832"],
      Georgetown: ["01833"],
      Groveland: ["01834"],
      Bradford: ["01835"],
      Lawrence: ["01840", "01841", "01843"],
      Methuen: ["01844"],
      "North andover": ["01845"],
      Lowell: ["01850", "01851", "01852", "01854"],
      Merrimac: ["01860"],
      "North billerica": ["01862"],
      "North chelmsford": ["01863"],
      "North reading": ["01864"],
      Reading: ["01867"],
      Tewksbury: ["01876"],
      Tyngsboro: ["01879"],
      Wakefield: ["01880"],
      Graniteville: ["01886"],
      Wilmington: ["01887"],
      Winchester: ["01890"],
      "Zcta 018hh": ["018HH"],
      Lynn: ["01902"],
      "East lynn": ["01904"],
      "West lynn": ["01905"],
      Saugus: ["01906"],
      Swampscott: ["01907"],
      Nahant: ["01908"],
      Amesbury: ["01913"],
      Beverly: ["01915"],
      Boxford: ["01921"],
      Byfield: ["01922"],
      Danvers: ["01923"],
      Essex: ["01929"],
      Gloucester: ["01930"],
      Ipswich: ["01938"],
      Lynnfield: ["01940"],
      Manchester: ["01944"],
      Marblehead: ["01945"],
      Middleton: ["01949"],
      Newburyport: ["01950"],
      Newbury: ["01951"],
      Salisbury: ["01952"],
      Peabody: ["01960"],
      Rockport: ["01966"],
      Rowley: ["01969"],
      Salem: ["01970"],
      "South hamilton": ["01982"],
      Topsfield: ["01983"],
      Wenham: ["01984"],
      "West newbury": ["01985"],
      "Zcta 019hh": ["019HH"],
      Bellingham: ["02019"],
      Canton: ["02021"],
      Cohasset: ["02025"],
      Dedham: ["02026"],
      Dover: ["02030"],
      "East walpole": ["02032"],
      Foxboro: ["02035"],
      Franklin: ["02038"],
      Hingham: ["02043"],
      Hull: ["02045"],
      Mansfield: ["02048"],
      Marshfield: ["02050"],
      Medfield: ["02052"],
      Medway: ["02053"],
      Millis: ["02054"],
      Norfolk: ["02056"],
      Norwell: ["02061"],
      Norwood: ["02062"],
      Scituate: ["02066"],
      Sharon: ["02067"],
      "South walpole": ["02071"],
      Stoughton: ["02072"],
      Walpole: ["02081"],
      Westwood: ["02090"],
      Wrentham: ["02093"],
      "Zcta 020hh": ["020HH"],
      Boston: [
        "02108",
        "02109",
        "02110",
        "02111",
        "02113",
        "02114",
        "02115",
        "02116",
        "02199",
        "02210",
        "02215",
        "02222",
      ],
      Roxbury: ["02118", "02119", "02120"],
      Dorchester: ["02121", "02122", "02124", "02125"],
      Mattapan: ["02126"],
      "South boston": ["02127"],
      "East boston": ["02128"],
      Charlestown: ["02129"],
      "Jamaica plain": ["02130"],
      Roslindale: ["02131"],
      "West roxbury": ["02132"],
      Allston: ["02134"],
      Brighton: ["02135"],
      "Hyde park": ["02136"],
      Cambridge: ["02138", "02139", "02142", "02163"],
      "North cambridge": ["02140"],
      "East cambridge": ["02141"],
      Somerville: ["02143", "02144", "02145"],
      Malden: ["02148"],
      Everett: ["02149"],
      Chelsea: ["02150"],
      Revere: ["02151"],
      Winthrop: ["02152"],
      Medford: ["02155"],
      Quincy: ["02169", "02170", "02171"],
      Melrose: ["02176"],
      Stoneham: ["02180"],
      Braintree: ["02184"],
      Milton: ["02186"],
      Weymouth: ["02188", "02189", "02190", "02191"],
      "Zcta 021hh": ["021HH"],
      "Zcta 022hh": ["022HH"],
      "Zcta 02301": ["02301"],
      "Zcta 02302": ["02302"],
      Avon: ["02322"],
      Bridgewater: ["02324"],
      Carver: ["02330"],
      Duxbury: ["02332"],
      "East bridgewater": ["02333"],
      Halifax: ["02338"],
      Hanover: ["02339"],
      Hanson: ["02341"],
      Holbrook: ["02343"],
      Middleboro: ["02346"],
      Lakeville: ["02347"],
      Abington: ["02351"],
      "North easton": ["02356"],
      "North pembroke": ["02358"],
      Pembroke: ["02359"],
      Plymouth: ["02360"],
      Kingston: ["02364"],
      "South carver": ["02366"],
      Plympton: ["02367"],
      Randolph: ["02368"],
      Rockland: ["02370"],
      "South easton": ["02375"],
      "West bridgewater": ["02379"],
      Whitman: ["02382"],
      "Zcta 023hh": ["023HH"],
      "Zcta 023xx": ["023XX"],
      "Zcta 02420": ["02420"],
      "Zcta 02421": ["02421"],
      "Zcta 02445": ["02445"],
      "Zcta 02446": ["02446"],
      "Zcta 02451": ["02451"],
      "Zcta 02452": ["02452"],
      "Zcta 02453": ["02453"],
      "Zcta 02458": ["02458"],
      "Zcta 02459": ["02459"],
      "Zcta 02460": ["02460"],
      "Zcta 02461": ["02461"],
      "Zcta 02462": ["02462"],
      "Zcta 02464": ["02464"],
      "Zcta 02465": ["02465"],
      "Zcta 02466": ["02466"],
      "Zcta 02467": ["02467"],
      "Zcta 02468": ["02468"],
      "Zcta 02472": ["02472"],
      "Zcta 02474": ["02474"],
      "Zcta 02476": ["02476"],
      "Zcta 02478": ["02478"],
      "Zcta 02481": ["02481"],
      "Zcta 02482": ["02482"],
      "Zcta 02492": ["02492"],
      "Zcta 02493": ["02493"],
      "Zcta 02494": ["02494"],
      Onset: ["02532", "02558"],
      Cataumet: ["02534"],
      Chilmark: ["02535"],
      Teaticket: ["02536"],
      "East sandwich": ["02537"],
      "East wareham": ["02538"],
      Edgartown: ["02539"],
      Falmouth: ["02540"],
      "Otis a f b": ["02542"],
      "Woods hole": ["02543"],
      Nantucket: ["02554"],
      "North falmouth": ["02556"],
      Pocasset: ["02559"],
      Sandwich: ["02563"],
      "Vineyard haven": ["02568"],
      Wareham: ["02571"],
      "West tisbury": ["02575"],
      "West wareham": ["02576"],
      "Zcta 025hh": ["025HH"],
      "Zcta 025xx": ["025XX"],
      "West yarmouth": ["02601", "02673"],
      Barnstable: ["02630"],
      Brewster: ["02631"],
      Centerville: ["02632"],
      "South chatham": ["02633", "02659"],
      Cotuit: ["02635"],
      Dennis: ["02638"],
      "Dennis port": ["02639"],
      Eastham: ["02642"],
      Forestdale: ["02644"],
      Harwich: ["02645"],
      "Harwich port": ["02646"],
      "Hyannis port": ["02647"],
      "Marstons mills": ["02648"],
      Mashpee: ["02649"],
      "North chatham": ["02650"],
      "North truro": ["02652"],
      Orleans: ["02653"],
      Osterville: ["02655"],
      Provincetown: ["02657"],
      "South dennis": ["02660"],
      "South harwich": ["02661"],
      "Bass river": ["02664"],
      Truro: ["02666"],
      Wellfleet: ["02667"],
      "West barnstable": ["02668"],
      "West chatham": ["02669"],
      "West dennis": ["02670"],
      "West harwich": ["02671"],
      "West hyannisport": ["02672"],
      "Yarmouth port": ["02675"],
      "Zcta 026hh": ["026HH"],
      Assonet: ["02702"],
      Attleboro: ["02703"],
      Cuttyhunk: ["02713"],
      Dighton: ["02715"],
      "East freetown": ["02717"],
      "East taunton": ["02718"],
      Fairhaven: ["02719"],
      "Fall river": ["02720", "02721", "02723", "02724"],
      Somerset: ["02725", "02726"],
      Marion: ["02738"],
      Mattapoisett: ["02739"],
      "New bedford": ["02740", "02744", "02745", "02746"],
      Acushnet: ["02743"],
      "North dartmouth": ["02747"],
      "Padanaram villag": ["02748"],
      "North attleboro": ["02760", "02763"],
      Plainville: ["02762"],
      "North dighton": ["02764"],
      Norton: ["02766"],
      Raynham: ["02767"],
      Rehoboth: ["02769"],
      Rochester: ["02770"],
      Seekonk: ["02771"],
      Swansea: ["02777"],
      Berkley: ["02779"],
      Taunton: ["02780"],
      Westport: ["02790"],
      "Zcta 027hh": ["027HH"],
    },
  },
  {
    abbrevation: "MI",
    name: "Michigan",
    Cities: [
      "Pearl beach",
      "Pearl beach",
      "Berlin",
      "Almont",
      "Armada",
      "Greenwood",
      "Birmingham",
      "Mussey",
      "Center line",
      "Clawson",
      "Eastpointe",
      "Emmett",
      "Ira",
      "Franklin",
      "Fraser",
      "Wales",
      "Harsens island",
      "Hazel park",
      "Grant township",
      "Southfield",
      "Clinton township",
      "Cottrellville",
      "Marysville",
      "Riley",
      "Macomb",
      "Mount clemens",
      "Selfridge a n g",
      "Chesterfield",
      "Lenox",
      "Ruby",
      "China",
      "Fort gratiot",
      "Port huron",
      "Richmond",
      "Columbus",
      "Casco",
      "Bruce",
      "Roseville",
      "Royal oak",
      "Pleasant ridge",
      "Huntington woods",
      "Madison heights",
      "Berkley",
      "Kimball",
      "Lathrup village",
      "Saint clair",
      "Saint clair shor",
      "Troy",
      "Warren",
      "Washington",
      "Ray",
      "Brockway",
      "Zcta 480hh",
      "Allen park",
      "Ann arbor",
      "Belleville",
      "Zcta 48114",
      "Brighton",
      "Carleton",
      "Chelsea",
      "Dearborn",
      "Melvindale",
      "Dearborn heights",
      "Dexter",
      "Dundee",
      "Erie",
      "Flat rock",
      "Garden city",
      "Gregory",
      "Grosse ile",
      "Hamburg",
      "Ida",
      "Inkster",
      "Lakeland",
      "Lambertville",
      "La salle",
      "Lincoln park",
      "Livonia",
      "Luna pier",
      "Manchester",
      "Maybee",
      "Milan",
      "Detroit beach",
      "Zcta 48162",
      "New boston",
      "New hudson",
      "Newport",
      "Northville",
      "Pinckney",
      "Plymouth",
      "Gibraltar",
      "Romulus",
      "Saline",
      "South lyon",
      "South rockwood",
      "Taylor",
      "Temperance",
      "Woodhaven",
      "Wayne",
      "Westland",
      "Zcta 48186",
      "Canton",
      "Whitmore lake",
      "Whittaker",
      "Willis",
      "Riverview",
      "Southgate",
      "Ypsilanti",
      "Zcta 481hh",
      "Detroit",
      "Highland park",
      "Hamtramck",
      "River rouge",
      "Ferndale",
      "Harper woods",
      "Ecorse",
      "Grosse pointe",
      "Oak park",
      "Redford",
      "Zcta 482hh",
      "Bloomfield towns",
      "Rochester hills",
      "Sterling heights",
      "Shelby township",
      "Sylvan lake",
      "West bloomfield",
      "Orchard lake",
      "Auburn hills",
      "Waterford",
      "Farmington hills",
      "Pontiac",
      "Independence",
      "Springfield",
      "Hartland",
      "Highland",
      "Orion",
      "Oakland",
      "Addison township",
      "Oxford",
      "Novi",
      "Milford",
      "Commerce townshi",
      "White lake",
      "Wolverine lake",
      "Wixom",
      "Zcta 483hh",
      "Applegate",
      "Attica",
      "Bad axe",
      "Bancroft",
      "Birch run",
      "Brown city",
      "Burt",
      "Byron",
      "Carsonville",
      "Clio",
      "Columbiaville",
      "Croswell",
      "Davison",
      "Decker",
      "Deckerville",
      "Dryden",
      "Durand",
      "Fenton",
      "Filion",
      "Flushing",
      "Forestville",
      "Fostoria",
      "Gaines",
      "Genesee",
      "Goodrich",
      "Grand blanc",
      "Hadley",
      "Harbor beach",
      "Holly",
      "Imlay city",
      "Kinde",
      "Lapeer",
      "Lennon",
      "Lexington",
      "Linden",
      "Marlette",
      "Melvin",
      "Metamora",
      "Minden city",
      "Montrose",
      "Mount morris",
      "New lothrop",
      "North branch",
      "Ortonville",
      "Otisville",
      "Otter lake",
      "Palms",
      "Peck",
      "Port austin",
      "Port hope",
      "Port sanilac",
      "Ruth",
      "Sandusky",
      "Snover",
      "Swartz creek",
      "Ubly",
      "Vernon",
      "Zcta 484hh",
      "Flint",
      "Northwest",
      "Northeast",
      "Southeast",
      "Zcta 485hh",
      "Saginaw",
      "Alger",
      "Auburn",
      "Beaverton",
      "Bentley",
      "Brant",
      "Breckenridge",
      "Chesaning",
      "Clare",
      "Coleman",
      "Comins",
      "Edenville",
      "Fairview",
      "Farwell",
      "Freeland",
      "Gladwin",
      "Harrison",
      "Hemlock",
      "Higgins lake",
      "Hope",
      "Houghton lake",
      "Houghton lake he",
      "Kawkawlin",
      "Lake",
      "Lake george",
      "Linwood",
      "Lupton",
      "Luzerne",
      "Merrill",
      "Midland",
      "Mio",
      "Oakley",
      "Pinconning",
      "Prudenville",
      "Rhodes",
      "Roscommon",
      "Rose city",
      "Saint charles",
      "Saint helen",
      "Sanford",
      "Standish",
      "Sterling",
      "West branch",
      "Wheeler",
      "Zcta 486hh",
      "Zcta 486xx",
      "Akron",
      "Au gres",
      "Barton city",
      "University cente",
      "Bay city",
      "Bay port",
      "Black river",
      "Bridgeport",
      "Caro",
      "Carrollton",
      "Caseville",
      "Cass city",
      "Clifford",
      "Curran",
      "Deford",
      "East tawas",
      "Elkton",
      "Essexville",
      "Fairgrove",
      "Frankenmuth",
      "Gagetown",
      "Glennie",
      "Greenbush",
      "Hale",
      "Harrisville",
      "Kingston",
      "Lincoln",
      "Long lake",
      "Mayville",
      "Mikado",
      "Millington",
      "Munger",
      "National city",
      "Omer",
      "Oscoda",
      "Owendale",
      "Pigeon",
      "Prescott",
      "Reese",
      "Sebewaing",
      "Silverwood",
      "South branch",
      "Spruce",
      "Tawas city",
      "Turner",
      "Twining",
      "Unionville",
      "Vassar",
      "Whittemore",
      "Zcta 487hh",
      "Alma",
      "Ashley",
      "Bannister",
      "Bath",
      "Belding",
      "Carson city",
      "Charlotte",
      "Clarksville",
      "Corunna",
      "Crystal",
      "Dansville",
      "Dewitt",
      "Dimondale",
      "Eagle",
      "East lansing",
      "Eaton rapids",
      "Edmore",
      "Elm hall",
      "Carland",
      "Elwell",
      "Fenwick",
      "Fowler",
      "Fowlerville",
      "Grand ledge",
      "Greenville",
      "Haslett",
      "Henderson",
      "Holt",
      "Howell",
      "Hubbardston",
      "Ionia",
      "Ithaca",
      "Laingsburg",
      "Lake odessa",
      "Lakeview",
      "Lyons",
      "Mcbrides",
      "Maple rapids",
      "Mason",
      "Middleton",
      "Morrice",
      "Mount pleasant",
      "Muir",
      "Mulliken",
      "Okemos",
      "Orleans",
      "Ovid",
      "Owosso",
      "Perrinton",
      "Perry",
      "Pewamo",
      "Pompeii",
      "Portland",
      "Potterville",
      "Riverdale",
      "Rosebush",
      "Saint johns",
      "Saint louis",
      "Saranac",
      "Shepherd",
      "Sheridan",
      "Sidney",
      "Six lakes",
      "Stanton",
      "Sumner",
      "Sunfield",
      "Vestaburg",
      "Webberville",
      "Weidman",
      "Westphalia",
      "Williamston",
      "Winn",
      "Woodland",
      "Zcta 488hh",
      "Lansing",
      "Zcta 489hh",
      "Kalamazoo",
      "Parchment",
      "Allegan",
      "Athens",
      "Augusta",
      "Bangor",
      "Zcta 49014",
      "Battle creek",
      "Bellevue",
      "Benton harbor",
      "Zcta 49024",
      "Bloomingdale",
      "Breedsville",
      "Bronson",
      "Burlington",
      "Burr oak",
      "Cassopolis",
      "Centreville",
      "Ceresco",
      "Climax",
      "Coldwater",
      "Coloma",
      "Colon",
      "Constantine",
      "Covert",
      "Decatur",
      "Delton",
      "Dowagiac",
      "Dowling",
      "East leroy",
      "Fulton",
      "Galesburg",
      "Gobles",
      "Grand junction",
      "Hartford",
      "Hastings",
      "Hickory corners",
      "Jones",
      "Lawrence",
      "Lawton",
      "Leonidas",
      "Marcellus",
      "Marshall",
      "Martin",
      "Mattawan",
      "Mendon",
      "Nashville",
      "Nottawa",
      "Olivet",
      "Otsego",
      "Paw paw",
      "Plainwell",
      "Quincy",
      "Richland",
      "Saint joseph",
      "Schoolcraft",
      "Scotts",
      "Sherwood",
      "South haven",
      "Sturgis",
      "Tekonsha",
      "Three rivers",
      "Union city",
      "Vandalia",
      "Vermontville",
      "Vicksburg",
      "Watervliet",
      "White pigeon",
      "Zcta 490hh",
      "Baroda",
      "Berrien center",
      "Berrien springs",
      "Bridgman",
      "Buchanan",
      "Eau claire",
      "Edwardsburg",
      "Galien",
      "Harbert",
      "Lakeside",
      "Grand beach",
      "New troy",
      "Niles",
      "Sawyer",
      "Sodus",
      "Stevensville",
      "Three oaks",
      "Union pier",
      "Union",
      "Zcta 491hh",
      "Jackson",
      "Addison",
      "Adrian",
      "Albion",
      "Allen",
      "Blissfield",
      "Britton",
      "Brooklyn",
      "Camden",
      "Cement city",
      "Clarklake",
      "Clayton",
      "Clinton",
      "Concord",
      "Deerfield",
      "Grass lake",
      "Hanover",
      "Hillsdale",
      "Homer",
      "Horton",
      "Hudson",
      "Jasper",
      "Jerome",
      "Jonesville",
      "Leslie",
      "Litchfield",
      "Manitou beach",
      "Michigan center",
      "Montgomery",
      "Morenci",
      "Munith",
      "North adams",
      "Norvell",
      "Onondaga",
      "Onsted",
      "Osseo",
      "Ottawa lake",
      "Palmyra",
      "Parma",
      "Petersburg",
      "Pittsford",
      "Pleasant lake",
      "Reading",
      "Riga",
      "Rives junction",
      "Sand creek",
      "Somerset",
      "Somerset center",
      "Spring arbor",
      "Springport",
      "Stockbridge",
      "Tecumseh",
      "Tipton",
      "Waldron",
      "Weston",
      "Zcta 492hh",
      "Ada",
      "Alto",
      "Bailey",
      "Baldwin",
      "Barryton",
      "Belmont",
      "Big rapids",
      "Bitely",
      "Blanchard",
      "Brohman",
      "Byron center",
      "Dutton",
      "Casnovia",
      "Cedar springs",
      "Comstock park",
      "Coral",
      "Dorr",
      "Freeport",
      "Gowen",
      "Grant",
      "Hopkins",
      "Howard city",
      "Kent city",
      "Lowell",
      "Mecosta",
      "Middleville",
      "Moline",
      "Morley",
      "Newaygo",
      "Paris",
      "Pierson",
      "Remus",
      "Rockford",
      "Rodney",
      "Sand lake",
      "Shelbyville",
      "Sparta",
      "Stanwood",
      "Trufant",
      "Wayland",
      "White cloud",
      "Zcta 493hh",
      "Allendale",
      "Branch",
      "Conklin",
      "Coopersville",
      "Custer",
      "Douglas",
      "Fennville",
      "Fountain",
      "Free soil",
      "Fremont",
      "Fruitport",
      "Grand haven",
      "Grandville",
      "Hamilton",
      "Hart",
      "Hesperia",
      "Holland",
      "Holton",
      "Hudsonville",
      "Jenison",
      "Ludington",
      "Macatawa",
      "Marne",
      "Mears",
      "Montague",
      "Muskegon",
      "Muskegon heights",
      "North muskegon",
      "New era",
      "Nunica",
      "Pentwater",
      "Pullman",
      "Ravenna",
      "Rothbury",
      "Saugatuck",
      "Scottville",
      "Shelby",
      "Spring lake",
      "Twin lake",
      "Walhalla",
      "Walkerville",
      "West olive",
      "Whitehall",
      "Zeeland",
      "Zcta 494hh",
      "Grand rapids",
      "Walker",
      "Kentwood",
      "Wyoming",
      "Zcta 49525",
      "Zcta 49544",
      "Zcta 495hh",
      "Cadillac",
      "Alba",
      "Alden",
      "Arcadia",
      "Bear lake",
      "Bellaire",
      "Benzonia",
      "Beulah",
      "Boon",
      "Brethren",
      "Buckley",
      "Cedar",
      "Central lake",
      "Chase",
      "Copemish",
      "Eastlake",
      "Eastport",
      "Elberta",
      "Elk rapids",
      "Empire",
      "Evart",
      "Falmouth",
      "Fife lake",
      "Filer city",
      "Frankfort",
      "Glen arbor",
      "Grawn",
      "Harrietta",
      "Hersey",
      "Honor",
      "Idlewild",
      "Interlochen",
      "Irons",
      "Kaleva",
      "Kalkaska",
      "Kewadin",
      "Kingsley",
      "Lake ann",
      "Moorestown",
      "Lake leelanau",
      "Leland",
      "Leroy",
      "Luther",
      "Mc bain",
      "Mancelona",
      "Stronach",
      "Manton",
      "Maple city",
      "Marion",
      "Mayfield",
      "Merritt",
      "Mesick",
      "Northport",
      "Onekama",
      "Rapid city",
      "Reed city",
      "Sears",
      "South boardman",
      "Suttons bay",
      "Thompsonville",
      "Traverse city",
      "Zcta 49686",
      "Tustin",
      "Wellston",
      "Williamsburg",
      "Zcta 496hh",
      "Mackinaw city",
      "Afton",
      "Alanson",
      "Alpena",
      "Atlanta",
      "Barbeau",
      "Boyne city",
      "Boyne falls",
      "Raco",
      "Brutus",
      "Carp lake",
      "Cedarville",
      "Charlevoix",
      "Cheboygan",
      "Conway",
      "Dafter",
      "De tour village",
      "Drummond island",
      "East jordan",
      "Eckerman",
      "Ellsworth",
      "Elmira",
      "Frederic",
      "Gaylord",
      "Goetzville",
      "Grayling",
      "Harbor point",
      "Hawks",
      "Herron",
      "Hessel",
      "Hillman",
      "Hubbard lake",
      "Hulbert",
      "Indian river",
      "Johannesburg",
      "Kinross",
      "Lachine",
      "Levering",
      "Lewiston",
      "Mackinac island",
      "Millersburg",
      "Moran",
      "Mullett lake",
      "Naubinway",
      "Oden",
      "Onaway",
      "Ossineke",
      "Paradise",
      "Pellston",
      "Bay view",
      "Pickford",
      "Pointe aux pins",
      "Posen",
      "Presque isle",
      "Rogers city",
      "Fibre",
      "Saint ignace",
      "Saint james",
      "Sault sainte mar",
      "Kincheloe",
      "Topinabee",
      "Tower",
      "Trout lake",
      "Vanderbilt",
      "Walloon lake",
      "Wolverine",
      "Zcta 497hh",
      "Zcta 497xx",
      "Iron mountain",
      "Allouez",
      "Au train",
      "Hardwood",
      "Big bay",
      "Carney",
      "Cedar river",
      "Champion",
      "Channing",
      "Limestone",
      "Cooks",
      "Cornell",
      "Curtis",
      "Daggett",
      "Deerton",
      "Eben junction",
      "Rumely",
      "Engadine",
      "Escanaba",
      "Felch",
      "Little lake",
      "Foster city",
      "Garden",
      "Germfask",
      "Brampton",
      "Gould city",
      "Grand marais",
      "Gulliver",
      "Princeton",
      "Hermansville",
      "Ingalls",
      "North lake",
      "Loretto",
      "Mc millan",
      "Thompson",
      "Beaver grove",
      "Menominee",
      "Michigamme",
      "Christmas",
      "Nadeau",
      "Nahma",
      "Negaunee",
      "Newberry",
      "Norway",
      "Palmer",
      "Perkins",
      "Perronville",
      "Powers",
      "Quinnesec",
      "Ralph",
      "Rapid river",
      "Republic",
      "Rock",
      "Sagola",
      "Seney",
      "Shingleton",
      "Skandia",
      "Spalding",
      "Stephenson",
      "Trenary",
      "Vulcan",
      "Wallace",
      "Wells",
      "Wetmore",
      "Wilson",
      "Zcta 498hh",
      "Zcta 498xx",
      "Ahmeek",
      "Alpha",
      "Amasa",
      "Atlantic mine",
      "Keweenaw bay",
      "Bergland",
      "Bessemer",
      "Bruce crossing",
      "Laurium",
      "Caspian",
      "Chassell",
      "Copper city",
      "Copper harbor",
      "Covington",
      "Crystal falls",
      "Dodgeville",
      "Dollar bay",
      "Ewen",
      "Gaastra",
      "Greenland",
      "Hancock",
      "Houghton",
      "Hubbell",
      "Iron river",
      "Ironwood",
      "Kearsarge",
      "Gay",
      "Lanse",
      "Marenisco",
      "Mass city",
      "Eagle harbor",
      "Nisula",
      "Ontonagon",
      "Painesdale",
      "Pelkie",
      "Ramsay",
      "Rockland",
      "Sidnaw",
      "Skanee",
      "South range",
      "Stambaugh",
      "Toivola",
      "Trout creek",
      "Wakefield",
      "Watersmeet",
      "Watton",
      "White pine",
      "Zcta 499hh",
      "Zcta 499xx",
    ],
    ZipCodes: {
      "Pearl beach": ["48001"],
      Berlin: ["48002"],
      Almont: ["48003"],
      Armada: ["48005"],
      Greenwood: ["48006"],
      Birmingham: ["48009"],
      Mussey: ["48014"],
      "Center line": ["48015"],
      Clawson: ["48017"],
      Eastpointe: ["48021"],
      Emmett: ["48022"],
      Ira: ["48023"],
      Franklin: ["48025"],
      Fraser: ["48026"],
      Wales: ["48027"],
      "Harsens island": ["48028"],
      "Hazel park": ["48030"],
      "Grant township": ["48032"],
      Southfield: ["48034", "48075"],
      "Clinton township": ["48035", "48036", "48038"],
      Cottrellville: ["48039"],
      Marysville: ["48040"],
      Riley: ["48041"],
      Macomb: ["48042", "48044"],
      "Mount clemens": ["48043"],
      "Selfridge a n g": ["48045"],
      Chesterfield: ["48047", "48051"],
      Lenox: ["48048", "48050"],
      Ruby: ["48049"],
      China: ["48054"],
      "Fort gratiot": ["48059"],
      "Port huron": ["48060"],
      Richmond: ["48062"],
      Columbus: ["48063"],
      Casco: ["48064"],
      Bruce: ["48065"],
      Roseville: ["48066"],
      "Royal oak": ["48067", "48073"],
      "Pleasant ridge": ["48069"],
      "Huntington woods": ["48070"],
      "Madison heights": ["48071"],
      Berkley: ["48072"],
      Kimball: ["48074"],
      "Lathrup village": ["48076"],
      "Saint clair": ["48079"],
      "Saint clair shor": ["48080", "48081", "48082"],
      Troy: ["48083", "48084", "48098"],
      Warren: ["48089", "48091", "48092", "48093"],
      Washington: ["48094", "48095"],
      Ray: ["48096"],
      Brockway: ["48097"],
      "Zcta 480hh": ["480HH"],
      "Allen park": ["48101"],
      "Ann arbor": ["48103", "48104", "48105", "48108", "48109"],
      Belleville: ["48111"],
      "Zcta 48114": ["48114"],
      Brighton: ["48116"],
      Carleton: ["48117"],
      Chelsea: ["48118"],
      Dearborn: ["48120", "48124", "48126", "48128"],
      Melvindale: ["48122"],
      "Dearborn heights": ["48125", "48127"],
      Dexter: ["48130"],
      Dundee: ["48131"],
      Erie: ["48133"],
      "Flat rock": ["48134"],
      "Garden city": ["48135"],
      Gregory: ["48137"],
      "Grosse ile": ["48138"],
      Hamburg: ["48139"],
      Ida: ["48140"],
      Inkster: ["48141"],
      Lakeland: ["48143"],
      Lambertville: ["48144"],
      "La salle": ["48145"],
      "Lincoln park": ["48146"],
      Livonia: ["48150", "48152", "48154"],
      "Luna pier": ["48157"],
      Manchester: ["48158"],
      Maybee: ["48159"],
      Milan: ["48160"],
      "Detroit beach": ["48161"],
      "Zcta 48162": ["48162"],
      "New boston": ["48164"],
      "New hudson": ["48165"],
      Newport: ["48166"],
      Northville: ["48167"],
      Pinckney: ["48169"],
      Plymouth: ["48170"],
      Gibraltar: ["48173"],
      Romulus: ["48174"],
      Saline: ["48176"],
      "South lyon": ["48178"],
      "South rockwood": ["48179"],
      Taylor: ["48180"],
      Temperance: ["48182"],
      Woodhaven: ["48183"],
      Wayne: ["48184"],
      Westland: ["48185"],
      "Zcta 48186": ["48186"],
      Canton: ["48187", "48188"],
      "Whitmore lake": ["48189"],
      Whittaker: ["48190"],
      Willis: ["48191"],
      Riverview: ["48192"],
      Southgate: ["48195"],
      Ypsilanti: ["48197", "48198"],
      "Zcta 481hh": ["481HH"],
      Detroit: [
        "48201",
        "48202",
        "48204",
        "48205",
        "48206",
        "48207",
        "48208",
        "48209",
        "48210",
        "48211",
        "48213",
        "48214",
        "48215",
        "48216",
        "48217",
        "48219",
        "48221",
        "48223",
        "48224",
        "48226",
        "48227",
        "48228",
        "48234",
        "48235",
        "48238",
        "48242",
      ],
      "Highland park": ["48203"],
      Hamtramck: ["48212"],
      "River rouge": ["48218"],
      Ferndale: ["48220"],
      "Harper woods": ["48225"],
      Ecorse: ["48229"],
      "Grosse pointe": ["48230", "48236"],
      "Oak park": ["48237"],
      Redford: ["48239", "48240"],
      "Zcta 482hh": ["482HH"],
      "Bloomfield towns": ["48301", "48302", "48304"],
      "Rochester hills": ["48306", "48307", "48309"],
      "Sterling heights": ["48310", "48312", "48313", "48314"],
      "Shelby township": ["48315", "48316", "48317"],
      "Sylvan lake": ["48320"],
      "West bloomfield": ["48322"],
      "Orchard lake": ["48323", "48324"],
      "Auburn hills": ["48326"],
      Waterford: ["48327", "48328", "48329"],
      "Farmington hills": ["48331", "48334", "48335", "48336"],
      Pontiac: ["48340", "48341", "48342"],
      Independence: ["48346", "48348"],
      Springfield: ["48350"],
      Hartland: ["48353"],
      Highland: ["48356", "48357"],
      Orion: ["48359", "48360", "48362"],
      Oakland: ["48363"],
      "Addison township": ["48367"],
      Oxford: ["48370", "48371"],
      Novi: ["48374", "48375", "48377"],
      Milford: ["48380", "48381"],
      "Commerce townshi": ["48382"],
      "White lake": ["48383", "48386"],
      "Wolverine lake": ["48390"],
      Wixom: ["48393"],
      "Zcta 483hh": ["483HH"],
      Applegate: ["48401"],
      Attica: ["48412"],
      "Bad axe": ["48413"],
      Bancroft: ["48414"],
      "Birch run": ["48415"],
      "Brown city": ["48416"],
      Burt: ["48417"],
      Byron: ["48418"],
      Carsonville: ["48419"],
      Clio: ["48420"],
      Columbiaville: ["48421"],
      Croswell: ["48422"],
      Davison: ["48423"],
      Decker: ["48426"],
      Deckerville: ["48427"],
      Dryden: ["48428"],
      Durand: ["48429"],
      Fenton: ["48430"],
      Filion: ["48432"],
      Flushing: ["48433"],
      Forestville: ["48434"],
      Fostoria: ["48435"],
      Gaines: ["48436"],
      Genesee: ["48437"],
      Goodrich: ["48438"],
      "Grand blanc": ["48439"],
      Hadley: ["48440"],
      "Harbor beach": ["48441"],
      Holly: ["48442"],
      "Imlay city": ["48444"],
      Kinde: ["48445"],
      Lapeer: ["48446"],
      Lennon: ["48449"],
      Lexington: ["48450"],
      Linden: ["48451"],
      Marlette: ["48453"],
      Melvin: ["48454"],
      Metamora: ["48455"],
      "Minden city": ["48456"],
      Montrose: ["48457"],
      "Mount morris": ["48458"],
      "New lothrop": ["48460"],
      "North branch": ["48461"],
      Ortonville: ["48462"],
      Otisville: ["48463"],
      "Otter lake": ["48464"],
      Palms: ["48465"],
      Peck: ["48466"],
      "Port austin": ["48467"],
      "Port hope": ["48468"],
      "Port sanilac": ["48469"],
      Ruth: ["48470"],
      Sandusky: ["48471"],
      Snover: ["48472"],
      "Swartz creek": ["48473"],
      Ubly: ["48475"],
      Vernon: ["48476"],
      "Zcta 484hh": ["484HH"],
      Flint: ["48502", "48503", "48505", "48507"],
      Northwest: ["48504", "48532"],
      Northeast: ["48506", "48509"],
      Southeast: ["48519", "48529"],
      "Zcta 485hh": ["485HH"],
      Saginaw: ["48601", "48602", "48603", "48604", "48607", "48609"],
      Alger: ["48610"],
      Auburn: ["48611"],
      Beaverton: ["48612"],
      Bentley: ["48613"],
      Brant: ["48614"],
      Breckenridge: ["48615"],
      Chesaning: ["48616"],
      Clare: ["48617"],
      Coleman: ["48618"],
      Comins: ["48619"],
      Edenville: ["48620"],
      Fairview: ["48621"],
      Farwell: ["48622"],
      Freeland: ["48623"],
      Gladwin: ["48624"],
      Harrison: ["48625"],
      Hemlock: ["48626"],
      "Higgins lake": ["48627"],
      Hope: ["48628"],
      "Houghton lake": ["48629"],
      "Houghton lake he": ["48630"],
      Kawkawlin: ["48631"],
      Lake: ["48632"],
      "Lake george": ["48633"],
      Linwood: ["48634"],
      Lupton: ["48635"],
      Luzerne: ["48636"],
      Merrill: ["48637"],
      Midland: ["48640", "48642"],
      Mio: ["48647"],
      Oakley: ["48649"],
      Pinconning: ["48650"],
      Prudenville: ["48651"],
      Rhodes: ["48652"],
      Roscommon: ["48653"],
      "Rose city": ["48654"],
      "Saint charles": ["48655"],
      "Saint helen": ["48656"],
      Sanford: ["48657"],
      Standish: ["48658"],
      Sterling: ["48659"],
      "West branch": ["48661"],
      Wheeler: ["48662"],
      "Zcta 486hh": ["486HH"],
      "Zcta 486xx": ["486XX"],
      Akron: ["48701"],
      "Au gres": ["48703"],
      "Barton city": ["48705"],
      "University cente": ["48706"],
      "Bay city": ["48708"],
      "Bay port": ["48720"],
      "Black river": ["48721"],
      Bridgeport: ["48722"],
      Caro: ["48723"],
      Carrollton: ["48724"],
      Caseville: ["48725"],
      "Cass city": ["48726"],
      Clifford: ["48727"],
      Curran: ["48728"],
      Deford: ["48729"],
      "East tawas": ["48730"],
      Elkton: ["48731"],
      Essexville: ["48732"],
      Fairgrove: ["48733"],
      Frankenmuth: ["48734"],
      Gagetown: ["48735"],
      Glennie: ["48737"],
      Greenbush: ["48738"],
      Hale: ["48739"],
      Harrisville: ["48740"],
      Kingston: ["48741"],
      Lincoln: ["48742"],
      "Long lake": ["48743"],
      Mayville: ["48744"],
      Mikado: ["48745"],
      Millington: ["48746"],
      Munger: ["48747"],
      "National city": ["48748"],
      Omer: ["48749"],
      Oscoda: ["48750"],
      Owendale: ["48754"],
      Pigeon: ["48755"],
      Prescott: ["48756"],
      Reese: ["48757"],
      Sebewaing: ["48759"],
      Silverwood: ["48760"],
      "South branch": ["48761"],
      Spruce: ["48762"],
      "Tawas city": ["48763"],
      Turner: ["48765"],
      Twining: ["48766"],
      Unionville: ["48767"],
      Vassar: ["48768"],
      Whittemore: ["48770"],
      "Zcta 487hh": ["487HH"],
      Alma: ["48801"],
      Ashley: ["48806"],
      Bannister: ["48807"],
      Bath: ["48808"],
      Belding: ["48809"],
      "Carson city": ["48811"],
      Charlotte: ["48813"],
      Clarksville: ["48815"],
      Corunna: ["48817"],
      Crystal: ["48818"],
      Dansville: ["48819"],
      Dewitt: ["48820"],
      Dimondale: ["48821"],
      Eagle: ["48822"],
      "East lansing": ["48823", "48824"],
      "Eaton rapids": ["48827"],
      Edmore: ["48829"],
      "Elm hall": ["48830"],
      Carland: ["48831"],
      Elwell: ["48832"],
      Fenwick: ["48834"],
      Fowler: ["48835"],
      Fowlerville: ["48836"],
      "Grand ledge": ["48837"],
      Greenville: ["48838"],
      Haslett: ["48840"],
      Henderson: ["48841"],
      Holt: ["48842"],
      Howell: ["48843"],
      Hubbardston: ["48845"],
      Ionia: ["48846"],
      Ithaca: ["48847"],
      Laingsburg: ["48848"],
      "Lake odessa": ["48849"],
      Lakeview: ["48850"],
      Lyons: ["48851"],
      Mcbrides: ["48852"],
      "Maple rapids": ["48853"],
      Mason: ["48854"],
      Middleton: ["48856"],
      Morrice: ["48857"],
      "Mount pleasant": ["48858"],
      Muir: ["48860"],
      Mulliken: ["48861"],
      Okemos: ["48864"],
      Orleans: ["48865"],
      Ovid: ["48866"],
      Owosso: ["48867"],
      Perrinton: ["48871"],
      Perry: ["48872"],
      Pewamo: ["48873"],
      Pompeii: ["48874"],
      Portland: ["48875"],
      Potterville: ["48876"],
      Riverdale: ["48877"],
      Rosebush: ["48878"],
      "Saint johns": ["48879"],
      "Saint louis": ["48880"],
      Saranac: ["48881"],
      Shepherd: ["48883"],
      Sheridan: ["48884"],
      Sidney: ["48885"],
      "Six lakes": ["48886"],
      Stanton: ["48888"],
      Sumner: ["48889"],
      Sunfield: ["48890"],
      Vestaburg: ["48891"],
      Webberville: ["48892"],
      Weidman: ["48893"],
      Westphalia: ["48894"],
      Williamston: ["48895"],
      Winn: ["48896"],
      Woodland: ["48897"],
      "Zcta 488hh": ["488HH"],
      Lansing: ["48906", "48910", "48911", "48912", "48915", "48917", "48933"],
      "Zcta 489hh": ["489HH"],
      Kalamazoo: ["49001", "49002", "49006", "49007", "49008", "49009"],
      Parchment: ["49004"],
      Allegan: ["49010"],
      Athens: ["49011"],
      Augusta: ["49012"],
      Bangor: ["49013"],
      "Zcta 49014": ["49014"],
      "Battle creek": ["49015", "49016", "49017"],
      Bellevue: ["49021"],
      "Benton harbor": ["49022"],
      "Zcta 49024": ["49024"],
      Bloomingdale: ["49026"],
      Breedsville: ["49027"],
      Bronson: ["49028"],
      Burlington: ["49029"],
      "Burr oak": ["49030"],
      Cassopolis: ["49031"],
      Centreville: ["49032"],
      Ceresco: ["49033"],
      Climax: ["49034"],
      Coldwater: ["49036"],
      Coloma: ["49038"],
      Colon: ["49040"],
      Constantine: ["49042"],
      Covert: ["49043"],
      Decatur: ["49045"],
      Delton: ["49046"],
      Dowagiac: ["49047"],
      Dowling: ["49050"],
      "East leroy": ["49051"],
      Fulton: ["49052"],
      Galesburg: ["49053"],
      Gobles: ["49055"],
      "Grand junction": ["49056"],
      Hartford: ["49057"],
      Hastings: ["49058"],
      "Hickory corners": ["49060"],
      Jones: ["49061"],
      Lawrence: ["49064"],
      Lawton: ["49065"],
      Leonidas: ["49066"],
      Marcellus: ["49067"],
      Marshall: ["49068"],
      Martin: ["49070"],
      Mattawan: ["49071"],
      Mendon: ["49072"],
      Nashville: ["49073"],
      Nottawa: ["49075"],
      Olivet: ["49076"],
      Otsego: ["49078"],
      "Paw paw": ["49079"],
      Plainwell: ["49080"],
      Quincy: ["49082"],
      Richland: ["49083"],
      "Saint joseph": ["49085"],
      Schoolcraft: ["49087"],
      Scotts: ["49088"],
      Sherwood: ["49089"],
      "South haven": ["49090"],
      Sturgis: ["49091"],
      Tekonsha: ["49092"],
      "Three rivers": ["49093"],
      "Union city": ["49094"],
      Vandalia: ["49095"],
      Vermontville: ["49096"],
      Vicksburg: ["49097"],
      Watervliet: ["49098"],
      "White pigeon": ["49099"],
      "Zcta 490hh": ["490HH"],
      Baroda: ["49101"],
      "Berrien center": ["49102"],
      "Berrien springs": ["49103"],
      Bridgman: ["49106"],
      Buchanan: ["49107"],
      "Eau claire": ["49111"],
      Edwardsburg: ["49112"],
      Galien: ["49113"],
      Harbert: ["49115"],
      Lakeside: ["49116"],
      "Grand beach": ["49117"],
      "New troy": ["49119"],
      Niles: ["49120"],
      Sawyer: ["49125"],
      Sodus: ["49126"],
      Stevensville: ["49127"],
      "Three oaks": ["49128"],
      "Union pier": ["49129"],
      Union: ["49130"],
      "Zcta 491hh": ["491HH"],
      Jackson: ["49201", "49202", "49203"],
      Addison: ["49220"],
      Adrian: ["49221"],
      Albion: ["49224"],
      Allen: ["49227"],
      Blissfield: ["49228"],
      Britton: ["49229"],
      Brooklyn: ["49230"],
      Camden: ["49232"],
      "Cement city": ["49233"],
      Clarklake: ["49234"],
      Clayton: ["49235"],
      Clinton: ["49236"],
      Concord: ["49237"],
      Deerfield: ["49238"],
      "Grass lake": ["49240"],
      Hanover: ["49241"],
      Hillsdale: ["49242"],
      Homer: ["49245"],
      Horton: ["49246"],
      Hudson: ["49247"],
      Jasper: ["49248"],
      Jerome: ["49249"],
      Jonesville: ["49250"],
      Leslie: ["49251"],
      Litchfield: ["49252"],
      "Manitou beach": ["49253"],
      "Michigan center": ["49254"],
      Montgomery: ["49255"],
      Morenci: ["49256"],
      Munith: ["49259"],
      "North adams": ["49262"],
      Norvell: ["49263"],
      Onondaga: ["49264"],
      Onsted: ["49265"],
      Osseo: ["49266"],
      "Ottawa lake": ["49267"],
      Palmyra: ["49268"],
      Parma: ["49269"],
      Petersburg: ["49270"],
      Pittsford: ["49271"],
      "Pleasant lake": ["49272"],
      Reading: ["49274"],
      Riga: ["49276"],
      "Rives junction": ["49277"],
      "Sand creek": ["49279"],
      Somerset: ["49281"],
      "Somerset center": ["49282"],
      "Spring arbor": ["49283"],
      Springport: ["49284"],
      Stockbridge: ["49285"],
      Tecumseh: ["49286"],
      Tipton: ["49287"],
      Waldron: ["49288"],
      Weston: ["49289"],
      "Zcta 492hh": ["492HH"],
      Ada: ["49301"],
      Alto: ["49302"],
      Bailey: ["49303"],
      Baldwin: ["49304"],
      Barryton: ["49305"],
      Belmont: ["49306"],
      "Big rapids": ["49307"],
      Bitely: ["49309"],
      Blanchard: ["49310"],
      Brohman: ["49312"],
      "Byron center": ["49315"],
      Dutton: ["49316"],
      Casnovia: ["49318"],
      "Cedar springs": ["49319"],
      "Comstock park": ["49321"],
      Coral: ["49322"],
      Dorr: ["49323"],
      Freeport: ["49325"],
      Gowen: ["49326"],
      Grant: ["49327"],
      Hopkins: ["49328"],
      "Howard city": ["49329"],
      "Kent city": ["49330"],
      Lowell: ["49331"],
      Mecosta: ["49332"],
      Middleville: ["49333"],
      Moline: ["49335"],
      Morley: ["49336"],
      Newaygo: ["49337"],
      Paris: ["49338"],
      Pierson: ["49339"],
      Remus: ["49340"],
      Rockford: ["49341"],
      Rodney: ["49342"],
      "Sand lake": ["49343"],
      Shelbyville: ["49344"],
      Sparta: ["49345"],
      Stanwood: ["49346"],
      Trufant: ["49347"],
      Wayland: ["49348"],
      "White cloud": ["49349"],
      "Zcta 493hh": ["493HH"],
      Allendale: ["49401"],
      Branch: ["49402"],
      Conklin: ["49403"],
      Coopersville: ["49404"],
      Custer: ["49405"],
      Douglas: ["49406"],
      Fennville: ["49408"],
      Fountain: ["49410"],
      "Free soil": ["49411"],
      Fremont: ["49412"],
      Fruitport: ["49415"],
      "Grand haven": ["49417"],
      Grandville: ["49418"],
      Hamilton: ["49419"],
      Hart: ["49420"],
      Hesperia: ["49421"],
      Holland: ["49423", "49424"],
      Holton: ["49425"],
      Hudsonville: ["49426"],
      Jenison: ["49428"],
      Ludington: ["49431"],
      Macatawa: ["49434"],
      Marne: ["49435"],
      Mears: ["49436"],
      Montague: ["49437"],
      Muskegon: ["49440", "49441", "49442"],
      "Muskegon heights": ["49444"],
      "North muskegon": ["49445"],
      "New era": ["49446"],
      Nunica: ["49448"],
      Pentwater: ["49449"],
      Pullman: ["49450"],
      Ravenna: ["49451"],
      Rothbury: ["49452"],
      Saugatuck: ["49453"],
      Scottville: ["49454"],
      Shelby: ["49455"],
      "Spring lake": ["49456"],
      "Twin lake": ["49457"],
      Walhalla: ["49458"],
      Walkerville: ["49459"],
      "West olive": ["49460"],
      Whitehall: ["49461"],
      Zeeland: ["49464"],
      "Zcta 494hh": ["494HH"],
      "Grand rapids": ["49503", "49505", "49506", "49507", "49546"],
      Walker: ["49504"],
      Kentwood: ["49508", "49512", "49548"],
      Wyoming: ["49509"],
      "Zcta 49525": ["49525"],
      "Zcta 49544": ["49544"],
      "Zcta 495hh": ["495HH"],
      Cadillac: ["49601"],
      Alba: ["49611"],
      Alden: ["49612"],
      Arcadia: ["49613"],
      "Bear lake": ["49614"],
      Bellaire: ["49615"],
      Benzonia: ["49616"],
      Beulah: ["49617"],
      Boon: ["49618"],
      Brethren: ["49619"],
      Buckley: ["49620"],
      Cedar: ["49621"],
      "Central lake": ["49622"],
      Chase: ["49623"],
      Copemish: ["49625"],
      Eastlake: ["49626"],
      Eastport: ["49627"],
      Elberta: ["49628"],
      "Elk rapids": ["49629"],
      Empire: ["49630"],
      Evart: ["49631"],
      Falmouth: ["49632"],
      "Fife lake": ["49633"],
      "Filer city": ["49634"],
      Frankfort: ["49635"],
      "Glen arbor": ["49636"],
      Grawn: ["49637"],
      Harrietta: ["49638"],
      Hersey: ["49639"],
      Honor: ["49640"],
      Idlewild: ["49642"],
      Interlochen: ["49643"],
      Irons: ["49644"],
      Kaleva: ["49645"],
      Kalkaska: ["49646"],
      Kewadin: ["49648"],
      Kingsley: ["49649"],
      "Lake ann": ["49650"],
      Moorestown: ["49651"],
      "Lake leelanau": ["49653"],
      Leland: ["49654"],
      Leroy: ["49655"],
      Luther: ["49656"],
      "Mc bain": ["49657"],
      Mancelona: ["49659"],
      Stronach: ["49660"],
      Manton: ["49663"],
      "Maple city": ["49664"],
      Marion: ["49665"],
      Mayfield: ["49666"],
      Merritt: ["49667"],
      Mesick: ["49668"],
      Northport: ["49670"],
      Onekama: ["49675"],
      "Rapid city": ["49676"],
      "Reed city": ["49677"],
      Sears: ["49679"],
      "South boardman": ["49680"],
      "Suttons bay": ["49682"],
      Thompsonville: ["49683"],
      "Traverse city": ["49684"],
      "Zcta 49686": ["49686"],
      Tustin: ["49688"],
      Wellston: ["49689"],
      Williamsburg: ["49690"],
      "Zcta 496hh": ["496HH"],
      "Mackinaw city": ["49701"],
      Afton: ["49705"],
      Alanson: ["49706"],
      Alpena: ["49707"],
      Atlanta: ["49709"],
      Barbeau: ["49710"],
      "Boyne city": ["49712"],
      "Boyne falls": ["49713"],
      Raco: ["49715"],
      Brutus: ["49716"],
      "Carp lake": ["49718"],
      Cedarville: ["49719"],
      Charlevoix: ["49720"],
      Cheboygan: ["49721"],
      Conway: ["49722"],
      Dafter: ["49724"],
      "De tour village": ["49725"],
      "Drummond island": ["49726"],
      "East jordan": ["49727"],
      Eckerman: ["49728"],
      Ellsworth: ["49729"],
      Elmira: ["49730"],
      Frederic: ["49733"],
      Gaylord: ["49735"],
      Goetzville: ["49736"],
      Grayling: ["49738"],
      "Harbor point": ["49740"],
      Hawks: ["49743"],
      Herron: ["49744"],
      Hessel: ["49745"],
      Hillman: ["49746"],
      "Hubbard lake": ["49747"],
      Hulbert: ["49748"],
      "Indian river": ["49749"],
      Johannesburg: ["49751"],
      Kinross: ["49752"],
      Lachine: ["49753"],
      Levering: ["49755"],
      Lewiston: ["49756"],
      "Mackinac island": ["49757"],
      Millersburg: ["49759"],
      Moran: ["49760"],
      "Mullett lake": ["49761"],
      Naubinway: ["49762"],
      Oden: ["49764"],
      Onaway: ["49765"],
      Ossineke: ["49766"],
      Paradise: ["49768"],
      Pellston: ["49769"],
      "Bay view": ["49770"],
      Pickford: ["49774"],
      "Pointe aux pins": ["49775"],
      Posen: ["49776"],
      "Presque isle": ["49777"],
      "Rogers city": ["49779"],
      Fibre: ["49780"],
      "Saint ignace": ["49781"],
      "Saint james": ["49782"],
      "Sault sainte mar": ["49783"],
      Kincheloe: ["49788"],
      Topinabee: ["49791"],
      Tower: ["49792"],
      "Trout lake": ["49793"],
      Vanderbilt: ["49795"],
      "Walloon lake": ["49796"],
      Wolverine: ["49799"],
      "Zcta 497hh": ["497HH"],
      "Zcta 497xx": ["497XX"],
      "Iron mountain": ["49801", "49802"],
      Allouez: ["49805"],
      "Au train": ["49806"],
      Hardwood: ["49807"],
      "Big bay": ["49808"],
      Carney: ["49812"],
      "Cedar river": ["49813"],
      Champion: ["49814"],
      Channing: ["49815"],
      Limestone: ["49816"],
      Cooks: ["49817"],
      Cornell: ["49818"],
      Curtis: ["49820"],
      Daggett: ["49821"],
      Deerton: ["49822"],
      "Eben junction": ["49825"],
      Rumely: ["49826"],
      Engadine: ["49827"],
      Escanaba: ["49829"],
      Felch: ["49831"],
      "Little lake": ["49833"],
      "Foster city": ["49834"],
      Garden: ["49835"],
      Germfask: ["49836"],
      Brampton: ["49837"],
      "Gould city": ["49838"],
      "Grand marais": ["49839"],
      Gulliver: ["49840"],
      Princeton: ["49841"],
      Hermansville: ["49847"],
      Ingalls: ["49848"],
      "North lake": ["49849"],
      Loretto: ["49852"],
      "Mc millan": ["49853"],
      Thompson: ["49854"],
      "Beaver grove": ["49855"],
      Menominee: ["49858"],
      Michigamme: ["49861"],
      Christmas: ["49862"],
      Nadeau: ["49863"],
      Nahma: ["49864"],
      Negaunee: ["49866"],
      Newberry: ["49868"],
      Norway: ["49870"],
      Palmer: ["49871"],
      Perkins: ["49872"],
      Perronville: ["49873"],
      Powers: ["49874"],
      Quinnesec: ["49876"],
      Ralph: ["49877"],
      "Rapid river": ["49878"],
      Republic: ["49879"],
      Rock: ["49880"],
      Sagola: ["49881"],
      Seney: ["49883"],
      Shingleton: ["49884"],
      Skandia: ["49885"],
      Spalding: ["49886"],
      Stephenson: ["49887"],
      Trenary: ["49891"],
      Vulcan: ["49892"],
      Wallace: ["49893"],
      Wells: ["49894"],
      Wetmore: ["49895"],
      Wilson: ["49896"],
      "Zcta 498hh": ["498HH"],
      "Zcta 498xx": ["498XX"],
      Ahmeek: ["49901"],
      Alpha: ["49902"],
      Amasa: ["49903"],
      "Atlantic mine": ["49905"],
      "Keweenaw bay": ["49908"],
      Bergland: ["49910"],
      Bessemer: ["49911"],
      "Bruce crossing": ["49912"],
      Laurium: ["49913"],
      Caspian: ["49915"],
      Chassell: ["49916"],
      "Copper city": ["49917"],
      "Copper harbor": ["49918"],
      Covington: ["49919"],
      "Crystal falls": ["49920"],
      Dodgeville: ["49921"],
      "Dollar bay": ["49922"],
      Ewen: ["49925"],
      Gaastra: ["49927"],
      Greenland: ["49929"],
      Hancock: ["49930"],
      Houghton: ["49931"],
      Hubbell: ["49934"],
      "Iron river": ["49935"],
      Ironwood: ["49938"],
      Kearsarge: ["49942"],
      Gay: ["49945"],
      Lanse: ["49946"],
      Marenisco: ["49947"],
      "Mass city": ["49948"],
      "Eagle harbor": ["49950"],
      Nisula: ["49952"],
      Ontonagon: ["49953"],
      Painesdale: ["49955"],
      Pelkie: ["49958"],
      Ramsay: ["49959"],
      Rockland: ["49960"],
      Sidnaw: ["49961"],
      Skanee: ["49962"],
      "South range": ["49963"],
      Stambaugh: ["49964"],
      Toivola: ["49965"],
      "Trout creek": ["49967"],
      Wakefield: ["49968"],
      Watersmeet: ["49969"],
      Watton: ["49970"],
      "White pine": ["49971"],
      "Zcta 499hh": ["499HH"],
      "Zcta 499xx": ["499XX"],
    },
  },
  {
    abbrevation: "MN",
    name: "Minnesota",
    Cities: [
      "Afton",
      "Afton",
      "Bayport",
      "East bethel",
      "Braham",
      "Quamba",
      "Cambridge",
      "Cannon falls",
      "Castle rock",
      "Cedar east bethe",
      "Center city",
      "Chisago city",
      "Circle pines",
      "Cottage grove",
      "Dalbo",
      "Stanton",
      "Dundas",
      "Elko",
      "Faribault",
      "Farmington",
      "Forest lake",
      "Frontenac",
      "Goodhue",
      "Grasston",
      "Hampton",
      "Harris",
      "Welch",
      "Henriette",
      "Hinckley",
      "Centerville",
      "Isanti",
      "Lake city",
      "Lake elmo",
      "Lakeland",
      "Lakeville",
      "Lindstrom",
      "Veseli",
      "Marine on saint",
      "Medford",
      "Mora",
      "Morristown",
      "Nerstrand",
      "Newport",
      "North branch",
      "Northfield",
      "Owatonna",
      "Beroun",
      "Randolph",
      "Red wing",
      "Rosemount",
      "Rush city",
      "Saint francis",
      "Saint paul park",
      "Markville",
      "Scandia",
      "Shafer",
      "South saint paul",
      "Inver grove heig",
      "Stacy",
      "Stanchfield",
      "Oak park heights",
      "Taylors falls",
      "Vermillion",
      "Warsaw",
      "Webster",
      "Willernie",
      "Zcta 550hh",
      "Saint paul",
      "West saint paul",
      "Lauderdale",
      "North saint paul",
      "White bear lake",
      "New brighton",
      "Roseville",
      "Little canada",
      "Maplewood",
      "Eagan",
      "Apple valley",
      "Woodbury",
      "Shoreview",
      "Vadnais heights",
      "Oakdale",
      "Zcta 55129",
      "Mendota",
      "Zcta 551hh",
      "Albertville",
      "Annandale",
      "Ramsey",
      "Ham lake",
      "Minnetonka",
      "Burnsville",
      "Arlington",
      "Becker",
      "Big lake",
      "Bird island",
      "Maple grove",
      "Brownton",
      "Buffalo",
      "Buffalo lake",
      "Carver",
      "Champlin",
      "Chanhassen",
      "Chaska",
      "Clear lake",
      "Clearwater",
      "Cokato",
      "Cologne",
      "Darwin",
      "Dassel",
      "Dayton",
      "Delano",
      "Eden valley",
      "Elk river",
      "Excelsior",
      "Fairfax",
      "Franklin",
      "Gaylord",
      "Gibbon",
      "Glencoe",
      "Green isle",
      "Hamburg",
      "Hamel",
      "Hanover",
      "Hector",
      "Eden prairie",
      "Howard lake",
      "Hutchinson",
      "Jordan",
      "Kimball",
      "Lester prairie",
      "Litchfield",
      "Long lake",
      "Loretto",
      "Maple lake",
      "Maple plain",
      "Mayer",
      "Monticello",
      "Montrose",
      "Mound",
      "New germany",
      "Norwood",
      "Plato",
      "Princeton",
      "Prior lake",
      "Rockford",
      "Rogers",
      "Bible college",
      "Saint michael",
      "Savage",
      "Shakopee",
      "Silver lake",
      "South haven",
      "Spring park",
      "Stewart",
      "Victoria",
      "Waconia",
      "Watertown",
      "Watkins",
      "Waverly",
      "Wayzata",
      "Winsted",
      "Winthrop",
      "Young america",
      "Zimmerman",
      "Zcta 553hh",
      "Minneapolis",
      "Edina",
      "Saint louis park",
      "Bloomington",
      "Columbia heights",
      "Robbinsdale",
      "Richfield",
      "Golden valley",
      "Crystal",
      "Brooklyn center",
      "Fridley",
      "Coon rapids",
      "Blaine",
      "Plymouth",
      "Brooklyn park",
      "Zcta 554hh",
      "Beaver bay",
      "Brimson",
      "Finland",
      "Grand marais",
      "Grand portage",
      "Hovland",
      "Isabella",
      "Knife river",
      "Lutsen",
      "Schroeder",
      "Little marais",
      "Tofte",
      "Two harbors",
      "Zcta 556hh",
      "Zcta 556xx",
      "Alborn",
      "Angora",
      "Askov",
      "Aurora",
      "Babbitt",
      "Barnum",
      "Biwabik",
      "Bovey",
      "Britt",
      "Brookston",
      "Bruno",
      "Buhl",
      "Calumet",
      "Canyon",
      "Carlton",
      "Chisholm",
      "Cloquet",
      "Cohasset",
      "Coleraine",
      "Cook",
      "Kelsey",
      "Crane lake",
      "Cromwell",
      "Ely",
      "Embarrass",
      "Esko",
      "Eveleth",
      "Finlayson",
      "Floodwood",
      "Forbes",
      "Gilbert",
      "Goodland",
      "La prairie",
      "Hibbing",
      "Hill city",
      "Holyoke",
      "Hoyt lakes",
      "Iron",
      "Jacobson",
      "Keewatin",
      "Kerrick",
      "Kettle river",
      "Kinney",
      "Mc gregor",
      "Makinen",
      "Marble",
      "Meadowlands",
      "Moose lake",
      "Mountain iron",
      "Nashwauk",
      "Buyck",
      "Pengilly",
      "Saginaw",
      "Sawyer",
      "Side lake",
      "Soudan",
      "Sturgeon lake",
      "Swan river",
      "Swatara",
      "Taconite",
      "Tamarack",
      "Tower",
      "Virginia",
      "Warba",
      "Willow river",
      "Winton",
      "Wrenshall",
      "Wright",
      "Zcta 557hh",
      "Zcta 557xx",
      "Duluth",
      "Proctor",
      "Hermantown",
      "Zcta 558hh",
      "Rochester",
      "Adams",
      "Altura",
      "Austin",
      "Blooming prairie",
      "Brownsdale",
      "Brownsville",
      "Byron",
      "Caledonia",
      "Canton",
      "Chatfield",
      "Claremont",
      "Dakota",
      "Dexter",
      "Dodge center",
      "Dover",
      "Eitzen",
      "Elgin",
      "Elkton",
      "Viola",
      "Fountain",
      "Grand meadow",
      "Harmony",
      "Hayfield",
      "Hokah",
      "Houston",
      "Kasson",
      "Theilman",
      "Kenyon",
      "La crescent",
      "Lanesboro",
      "Lansing",
      "Le roy",
      "Lewiston",
      "Lyle",
      "Mabel",
      "Mantorville",
      "Mazeppa",
      "Millville",
      "Minnesota city",
      "Oronoco",
      "Ostrander",
      "Peterson",
      "Pine island",
      "Plainview",
      "Preston",
      "Racine",
      "Rollingstone",
      "Rose creek",
      "Rushford",
      "Saint charles",
      "Sargeant",
      "Spring grove",
      "Spring valley",
      "Stewartville",
      "Taopi",
      "Utica",
      "Wabasha",
      "Waltham",
      "Wanamingo",
      "West concord",
      "Goodview",
      "Stockton",
      "Wykoff",
      "Hammond",
      "Zumbrota",
      "Zcta 559hh",
      "Mankato",
      "North mankato",
      "Albert lea",
      "Alden",
      "Amboy",
      "Belle plaine",
      "Blue earth",
      "Bricelyn",
      "Clarks grove",
      "Cleveland",
      "Comfrey",
      "Conger",
      "Courtland",
      "Darfur",
      "Delavan",
      "Eagle lake",
      "Easton",
      "Ellendale",
      "Elmore",
      "Elysian",
      "Emmons",
      "Fairmont",
      "Freeborn",
      "Frost",
      "Garden city",
      "Geneva",
      "Glenville",
      "Good thunder",
      "Granada",
      "Hanska",
      "Hartland",
      "Hayward",
      "Henderson",
      "Hollandale",
      "Janesville",
      "Kasota",
      "Kiester",
      "Kilkenny",
      "Lafayette",
      "Lake crystal",
      "La salle",
      "Le center",
      "Le sueur",
      "Lewisville",
      "Madelia",
      "Madison lake",
      "Manchester",
      "Mapleton",
      "Minnesota lake",
      "Montgomery",
      "New prague",
      "New richland",
      "New ulm",
      "Nicollet",
      "Northrop",
      "Oakland",
      "Pemberton",
      "Saint clair",
      "Saint james",
      "Saint peter",
      "Sanborn",
      "Sleepy eye",
      "Springfield",
      "Truman",
      "Twin lakes",
      "Vernon center",
      "Waldorf",
      "Waseca",
      "Waterville",
      "Wells",
      "Winnebago",
      "Zcta 560hh",
      "Wilder",
      "Adrian",
      "Alpha",
      "Arco",
      "Avoca",
      "Balaton",
      "Beaver creek",
      "Bigelow",
      "Bingham lake",
      "Brewster",
      "Butterfield",
      "Ceylon",
      "Chandler",
      "Currie",
      "Dunnell",
      "Edgerton",
      "Ellsworth",
      "Fulda",
      "Garvin",
      "Hardwick",
      "Hendricks",
      "Heron lake",
      "Hills",
      "Holland",
      "Ihlen",
      "Iona",
      "Ivanhoe",
      "Jackson",
      "Jasper",
      "Jeffers",
      "Kenneth",
      "Lake benton",
      "Lakefield",
      "Lake wilson",
      "Lamberton",
      "Leota",
      "Lismore",
      "Luverne",
      "Lynd",
      "Magnolia",
      "Mountain lake",
      "Odin",
      "Okabena",
      "Ormsby",
      "Hatfield",
      "Reading",
      "Revere",
      "Round lake",
      "Rushmore",
      "Russell",
      "Florence",
      "Sherburn",
      "Slayton",
      "Steen",
      "Storden",
      "Tracy",
      "Trimont",
      "Trosky",
      "Tyler",
      "Walnut grove",
      "Welcome",
      "Westbrook",
      "Wilmont",
      "Woodstock",
      "Worthington",
      "Zcta 561hh",
      "Willmar",
      "Alberta",
      "Appleton",
      "Atwater",
      "Beardsley",
      "Bellingham",
      "Belview",
      "Benson",
      "Svea",
      "Boyd",
      "Browns valley",
      "Canby",
      "Chokio",
      "Clara city",
      "Clarkfield",
      "Clements",
      "Clinton",
      "Clontarf",
      "Correll",
      "Cosmos",
      "Cottonwood",
      "Danube",
      "Danvers",
      "Dawson",
      "Donnelly",
      "Dumont",
      "Echo",
      "Ghent",
      "Graceville",
      "Granite falls",
      "Grove city",
      "Hancock",
      "Hanley falls",
      "Hawick",
      "Herman",
      "Holloway",
      "Kandiyohi",
      "Kerkhoven",
      "Lake lillian",
      "Lucan",
      "Madison",
      "Marietta",
      "Marshall",
      "Maynard",
      "Milan",
      "Milroy",
      "Minneota",
      "Montevideo",
      "Morgan",
      "Morris",
      "Morton",
      "Murdock",
      "New london",
      "Norcross",
      "Odessa",
      "Olivia",
      "Ortonville",
      "Pennock",
      "Porter",
      "Prinsburg",
      "Raymond",
      "Delhi",
      "Renville",
      "Sacred heart",
      "Seaforth",
      "Spicer",
      "Sunburg",
      "Taunton",
      "Vesta",
      "Wabasso",
      "Wanda",
      "Watson",
      "Wheaton",
      "Wood lake",
      "Zcta 562hh",
      "Zcta 562xx",
      "Saint cloud",
      "Albany",
      "Alexandria",
      "Ashby",
      "Avon",
      "Barrett",
      "Belgrade",
      "Bock",
      "Bowlus",
      "Brandon",
      "Brooten",
      "Burtrum",
      "Carlos",
      "Cold spring",
      "Cyrus",
      "Dalton",
      "Elrosa",
      "Evansville",
      "Farwell",
      "Flensburg",
      "Foley",
      "Foreston",
      "Freeport",
      "Garfield",
      "Glenwood",
      "Greenwald",
      "Grey eagle",
      "Hillman",
      "Hoffman",
      "Holdingford",
      "Isle",
      "Kensington",
      "Little falls",
      "Little sauk",
      "Lowry",
      "Mc grath",
      "Melrose",
      "Milaca",
      "Miltona",
      "Nelson",
      "New munich",
      "Oak park",
      "Ogilvie",
      "Onamia",
      "Osakis",
      "Parkers prairie",
      "Paynesville",
      "Pierz",
      "Rice",
      "Richmond",
      "Rockville",
      "Roscoe",
      "Royalton",
      "Saint joseph",
      "Saint stephen",
      "Saint martin",
      "Sartell",
      "Sauk centre",
      "Sauk rapids",
      "Starbuck",
      "Swanville",
      "Upsala",
      "Villard",
      "Wahkon",
      "Waite park",
      "Zcta 563hh",
      "East gull lake",
      "Baxter",
      "Aitkin",
      "Akeley",
      "Aldrich",
      "Backus",
      "Benedict",
      "Bertha",
      "Browerville",
      "Clarissa",
      "Crosby",
      "Crosslake",
      "Cushing",
      "Deerwood",
      "Eagle bend",
      "Emily",
      "Fifty lakes",
      "Fort ripley",
      "Garrison",
      "Hackensack",
      "Hewitt",
      "Ironton",
      "Jenkins",
      "Lake george",
      "Laporte",
      "Menahga",
      "Merrifield",
      "Leader",
      "Nevis",
      "Lake shore",
      "Palisade",
      "Park rapids",
      "Pequot lakes",
      "Pillager",
      "Pine river",
      "Randall",
      "Sebeka",
      "Staples",
      "Verndale",
      "Wadena",
      "Walker",
      "Zcta 564hh",
      "Zcta 564xx",
      "Detroit lakes",
      "Lockhart",
      "Audubon",
      "Baker",
      "Downer",
      "Battle lake",
      "Bejou",
      "Beltrami",
      "Bluffton",
      "Borup",
      "Breckenridge",
      "Callaway",
      "Doran",
      "Eldred",
      "Clitherall",
      "Comstock",
      "Deer creek",
      "Dent",
      "Dilworth",
      "Elbow lake",
      "Elizabeth",
      "Erhard",
      "Erskine",
      "Felton",
      "Carlisle",
      "Fertile",
      "Fosston",
      "Foxhome",
      "Frazee",
      "Gary",
      "Georgetown",
      "Glyndon",
      "Halstad",
      "Rollag",
      "Hendrum",
      "Henning",
      "Hitterdal",
      "Kent",
      "Lake park",
      "Mcintosh",
      "Mahnomen",
      "Moorhead",
      "Nashua",
      "Naytahwaush",
      "New york mills",
      "Nielsville",
      "Ogema",
      "Osage",
      "Ottertail",
      "Pelican rapids",
      "Perham",
      "Perley",
      "Ponsford",
      "Richville",
      "Rochert",
      "Rothsay",
      "Sabin",
      "Shelly",
      "Tenney",
      "Twin valley",
      "Ulen",
      "Underwood",
      "Vergas",
      "Vining",
      "Waubun",
      "Wendell",
      "Winger",
      "Wolverton",
      "Zcta 565hh",
      "Zcta 565xx",
      "Bemidji",
      "Bagley",
      "Baudette",
      "Bena",
      "Big falls",
      "Bigfork",
      "Birchdale",
      "Blackduck",
      "Cass lake",
      "Clearbrook",
      "Deer river",
      "Talmoon",
      "Effie",
      "Federal dam",
      "Gonvick",
      "Gully",
      "Hines",
      "International fa",
      "Kelliher",
      "Lengby",
      "Leonard",
      "Littlefork",
      "Loman",
      "Longville",
      "Marcell",
      "Max",
      "Mizpah",
      "Northome",
      "Outing",
      "Pennington",
      "Ponemah",
      "Puposky",
      "Ray",
      "Redby",
      "Redlake",
      "Remer",
      "Roosevelt",
      "Shevlin",
      "Solway",
      "Spring lake",
      "Squaw lake",
      "Tenstrike",
      "Trail",
      "Waskish",
      "Williams",
      "Wilton",
      "Wirt",
      "Zcta 566hh",
      "Zcta 566xx",
      "Thief river fall",
      "Alvarado",
      "Angle inlet",
      "Angus",
      "Argyle",
      "Badger",
      "Brooks",
      "Crookston",
      "Donaldson",
      "East grand forks",
      "Euclid",
      "Fisher",
      "Gatzke",
      "Goodridge",
      "Greenbush",
      "Grygla",
      "Hallock",
      "Halma",
      "Humboldt",
      "Karlstad",
      "Kennedy",
      "Lake bronson",
      "Orleans",
      "Mentor",
      "Middle river",
      "Newfolden",
      "Oak island",
      "Oklee",
      "Oslo",
      "Plummer",
      "Red lake falls",
      "Pencer",
      "Saint hilaire",
      "Saint vincent",
      "Salol",
      "Stephen",
      "Strandquist",
      "Strathcona",
      "Viking",
      "Wannaska",
      "Radium",
      "Warroad",
      "Zcta 567hh",
      "Zcta 567xx",
      "",
    ],
    ZipCodes: {
      Afton: ["55001"],
      Bayport: ["55003"],
      "East bethel": ["55005", "55092"],
      Braham: ["55006"],
      Quamba: ["55007"],
      Cambridge: ["55008"],
      "Cannon falls": ["55009"],
      "Castle rock": ["55010"],
      "Cedar east bethe": ["55011"],
      "Center city": ["55012"],
      "Chisago city": ["55013"],
      "Circle pines": ["55014"],
      "Cottage grove": ["55016"],
      Dalbo: ["55017"],
      Stanton: ["55018"],
      Dundas: ["55019"],
      Elko: ["55020"],
      Faribault: ["55021"],
      Farmington: ["55024"],
      "Forest lake": ["55025"],
      Frontenac: ["55026"],
      Goodhue: ["55027"],
      Grasston: ["55030"],
      Hampton: ["55031"],
      Harris: ["55032"],
      Welch: ["55033", "55089"],
      Henriette: ["55036"],
      Hinckley: ["55037"],
      Centerville: ["55038"],
      Isanti: ["55040"],
      "Lake city": ["55041"],
      "Lake elmo": ["55042"],
      Lakeland: ["55043"],
      Lakeville: ["55044"],
      Lindstrom: ["55045"],
      Veseli: ["55046"],
      "Marine on saint": ["55047"],
      Medford: ["55049"],
      Mora: ["55051"],
      Morristown: ["55052"],
      Nerstrand: ["55053"],
      Newport: ["55055"],
      "North branch": ["55056"],
      Northfield: ["55057"],
      Owatonna: ["55060"],
      Beroun: ["55063"],
      Randolph: ["55065"],
      "Red wing": ["55066"],
      Rosemount: ["55068"],
      "Rush city": ["55069"],
      "Saint francis": ["55070"],
      "Saint paul park": ["55071"],
      Markville: ["55072"],
      Scandia: ["55073"],
      Shafer: ["55074"],
      "South saint paul": ["55075"],
      "Inver grove heig": ["55076", "55077"],
      Stacy: ["55079"],
      Stanchfield: ["55080"],
      "Oak park heights": ["55082"],
      "Taylors falls": ["55084"],
      Vermillion: ["55085"],
      Warsaw: ["55087"],
      Webster: ["55088"],
      Willernie: ["55090"],
      "Zcta 550hh": ["550HH"],
      "Saint paul": [
        "55101",
        "55102",
        "55103",
        "55104",
        "55105",
        "55106",
        "55114",
        "55116",
      ],
      "West saint paul": ["55107", "55118"],
      Lauderdale: ["55108"],
      "North saint paul": ["55109"],
      "White bear lake": ["55110", "55115"],
      "New brighton": ["55112"],
      Roseville: ["55113"],
      "Little canada": ["55117"],
      Maplewood: ["55119"],
      Eagan: ["55120", "55121", "55122", "55123"],
      "Apple valley": ["55124"],
      Woodbury: ["55125"],
      Shoreview: ["55126"],
      "Vadnais heights": ["55127"],
      Oakdale: ["55128"],
      "Zcta 55129": ["55129"],
      Mendota: ["55150"],
      "Zcta 551hh": ["551HH"],
      Albertville: ["55301"],
      Annandale: ["55302"],
      Ramsey: ["55303"],
      "Ham lake": ["55304"],
      Minnetonka: ["55305", "55345"],
      Burnsville: ["55306", "55337"],
      Arlington: ["55307"],
      Becker: ["55308"],
      "Big lake": ["55309"],
      "Bird island": ["55310"],
      "Maple grove": ["55311", "55369"],
      Brownton: ["55312"],
      Buffalo: ["55313"],
      "Buffalo lake": ["55314"],
      Carver: ["55315"],
      Champlin: ["55316"],
      Chanhassen: ["55317"],
      Chaska: ["55318"],
      "Clear lake": ["55319"],
      Clearwater: ["55320"],
      Cokato: ["55321"],
      Cologne: ["55322"],
      Darwin: ["55324"],
      Dassel: ["55325"],
      Dayton: ["55327"],
      Delano: ["55328"],
      "Eden valley": ["55329"],
      "Elk river": ["55330"],
      Excelsior: ["55331"],
      Fairfax: ["55332"],
      Franklin: ["55333"],
      Gaylord: ["55334"],
      Gibbon: ["55335"],
      Glencoe: ["55336"],
      "Green isle": ["55338"],
      Hamburg: ["55339"],
      Hamel: ["55340"],
      Hanover: ["55341"],
      Hector: ["55342"],
      "Eden prairie": ["55343", "55344", "55346", "55347"],
      "Howard lake": ["55349"],
      Hutchinson: ["55350"],
      Jordan: ["55352"],
      Kimball: ["55353"],
      "Lester prairie": ["55354"],
      Litchfield: ["55355"],
      "Long lake": ["55356"],
      Loretto: ["55357"],
      "Maple lake": ["55358"],
      "Maple plain": ["55359"],
      Mayer: ["55360"],
      Monticello: ["55362"],
      Montrose: ["55363"],
      Mound: ["55364"],
      "New germany": ["55367"],
      Norwood: ["55368"],
      Plato: ["55370"],
      Princeton: ["55371"],
      "Prior lake": ["55372"],
      Rockford: ["55373"],
      Rogers: ["55374"],
      "Bible college": ["55375"],
      "Saint michael": ["55376"],
      Savage: ["55378"],
      Shakopee: ["55379"],
      "Silver lake": ["55381"],
      "South haven": ["55382"],
      "Spring park": ["55384"],
      Stewart: ["55385"],
      Victoria: ["55386"],
      Waconia: ["55387"],
      Watertown: ["55388"],
      Watkins: ["55389"],
      Waverly: ["55390"],
      Wayzata: ["55391"],
      Winsted: ["55395"],
      Winthrop: ["55396"],
      "Young america": ["55397"],
      Zimmerman: ["55398"],
      "Zcta 553hh": ["553HH"],
      Minneapolis: [
        "55401",
        "55402",
        "55403",
        "55404",
        "55405",
        "55406",
        "55407",
        "55408",
        "55409",
        "55411",
        "55412",
        "55413",
        "55414",
        "55415",
        "55417",
        "55418",
        "55419",
        "55450",
        "55454",
        "55455",
      ],
      Edina: ["55410", "55424", "55435", "55436", "55439"],
      "Saint louis park": ["55416", "55426"],
      Bloomington: ["55420", "55425", "55431", "55437", "55438"],
      "Columbia heights": ["55421"],
      Robbinsdale: ["55422"],
      Richfield: ["55423"],
      "Golden valley": ["55427"],
      Crystal: ["55428"],
      "Brooklyn center": ["55429", "55430", "55443", "55444"],
      Fridley: ["55432"],
      "Coon rapids": ["55433", "55448"],
      Blaine: ["55434", "55449"],
      Plymouth: ["55441", "55442", "55446", "55447"],
      "Brooklyn park": ["55445"],
      "Zcta 554hh": ["554HH"],
      "Beaver bay": ["55601"],
      Brimson: ["55602"],
      Finland: ["55603"],
      "Grand marais": ["55604"],
      "Grand portage": ["55605"],
      Hovland: ["55606"],
      Isabella: ["55607"],
      "Knife river": ["55609"],
      Lutsen: ["55612"],
      Schroeder: ["55613"],
      "Little marais": ["55614"],
      Tofte: ["55615"],
      "Two harbors": ["55616"],
      "Zcta 556hh": ["556HH"],
      "Zcta 556xx": ["556XX"],
      Alborn: ["55702"],
      Angora: ["55703"],
      Askov: ["55704"],
      Aurora: ["55705"],
      Babbitt: ["55706"],
      Barnum: ["55707"],
      Biwabik: ["55708"],
      Bovey: ["55709"],
      Britt: ["55710"],
      Brookston: ["55711"],
      Bruno: ["55712"],
      Buhl: ["55713"],
      Calumet: ["55716"],
      Canyon: ["55717"],
      Carlton: ["55718"],
      Chisholm: ["55719"],
      Cloquet: ["55720"],
      Cohasset: ["55721"],
      Coleraine: ["55722"],
      Cook: ["55723"],
      Kelsey: ["55724"],
      "Crane lake": ["55725"],
      Cromwell: ["55726"],
      Ely: ["55731"],
      Embarrass: ["55732"],
      Esko: ["55733"],
      Eveleth: ["55734"],
      Finlayson: ["55735"],
      Floodwood: ["55736"],
      Forbes: ["55738"],
      Gilbert: ["55741"],
      Goodland: ["55742"],
      "La prairie": ["55744"],
      Hibbing: ["55746"],
      "Hill city": ["55748"],
      Holyoke: ["55749"],
      "Hoyt lakes": ["55750"],
      Iron: ["55751"],
      Jacobson: ["55752"],
      Keewatin: ["55753"],
      Kerrick: ["55756"],
      "Kettle river": ["55757"],
      Kinney: ["55758"],
      "Mc gregor": ["55760"],
      Makinen: ["55763"],
      Marble: ["55764"],
      Meadowlands: ["55765"],
      "Moose lake": ["55767"],
      "Mountain iron": ["55768"],
      Nashwauk: ["55769"],
      Buyck: ["55771"],
      Pengilly: ["55775"],
      Saginaw: ["55779"],
      Sawyer: ["55780"],
      "Side lake": ["55781"],
      Soudan: ["55782"],
      "Sturgeon lake": ["55783"],
      "Swan river": ["55784"],
      Swatara: ["55785"],
      Taconite: ["55786"],
      Tamarack: ["55787"],
      Tower: ["55790"],
      Virginia: ["55792"],
      Warba: ["55793"],
      "Willow river": ["55795"],
      Winton: ["55796"],
      Wrenshall: ["55797"],
      Wright: ["55798"],
      "Zcta 557hh": ["557HH"],
      "Zcta 557xx": ["557XX"],
      Duluth: [
        "55802",
        "55803",
        "55804",
        "55805",
        "55806",
        "55807",
        "55808",
        "55812",
      ],
      Proctor: ["55810"],
      Hermantown: ["55811"],
      "Zcta 558hh": ["558HH"],
      Rochester: ["55901", "55902", "55904", "55906"],
      Adams: ["55909"],
      Altura: ["55910"],
      Austin: ["55912"],
      "Blooming prairie": ["55917"],
      Brownsdale: ["55918"],
      Brownsville: ["55919"],
      Byron: ["55920"],
      Caledonia: ["55921"],
      Canton: ["55922"],
      Chatfield: ["55923"],
      Claremont: ["55924"],
      Dakota: ["55925"],
      Dexter: ["55926"],
      "Dodge center": ["55927"],
      Dover: ["55929"],
      Eitzen: ["55931"],
      Elgin: ["55932"],
      Elkton: ["55933"],
      Viola: ["55934"],
      Fountain: ["55935"],
      "Grand meadow": ["55936"],
      Harmony: ["55939"],
      Hayfield: ["55940"],
      Hokah: ["55941"],
      Houston: ["55943"],
      Kasson: ["55944"],
      Theilman: ["55945"],
      Kenyon: ["55946"],
      "La crescent": ["55947"],
      Lanesboro: ["55949"],
      Lansing: ["55950"],
      "Le roy": ["55951"],
      Lewiston: ["55952"],
      Lyle: ["55953"],
      Mabel: ["55954"],
      Mantorville: ["55955"],
      Mazeppa: ["55956"],
      Millville: ["55957"],
      "Minnesota city": ["55959"],
      Oronoco: ["55960"],
      Ostrander: ["55961"],
      Peterson: ["55962"],
      "Pine island": ["55963"],
      Plainview: ["55964"],
      Preston: ["55965"],
      Racine: ["55967"],
      Rollingstone: ["55969"],
      "Rose creek": ["55970"],
      Rushford: ["55971"],
      "Saint charles": ["55972"],
      Sargeant: ["55973"],
      "Spring grove": ["55974"],
      "Spring valley": ["55975"],
      Stewartville: ["55976"],
      Taopi: ["55977"],
      Utica: ["55979"],
      Wabasha: ["55981"],
      Waltham: ["55982"],
      Wanamingo: ["55983"],
      "West concord": ["55985"],
      Goodview: ["55987"],
      Stockton: ["55988"],
      Wykoff: ["55990"],
      Hammond: ["55991"],
      Zumbrota: ["55992"],
      "Zcta 559hh": ["559HH"],
      Mankato: ["56001"],
      "North mankato": ["56003"],
      "Albert lea": ["56007"],
      Alden: ["56009"],
      Amboy: ["56010"],
      "Belle plaine": ["56011"],
      "Blue earth": ["56013"],
      Bricelyn: ["56014"],
      "Clarks grove": ["56016"],
      Cleveland: ["56017"],
      Comfrey: ["56019"],
      Conger: ["56020"],
      Courtland: ["56021"],
      Darfur: ["56022"],
      Delavan: ["56023"],
      "Eagle lake": ["56024"],
      Easton: ["56025"],
      Ellendale: ["56026"],
      Elmore: ["56027"],
      Elysian: ["56028"],
      Emmons: ["56029"],
      Fairmont: ["56031"],
      Freeborn: ["56032"],
      Frost: ["56033"],
      "Garden city": ["56034"],
      Geneva: ["56035"],
      Glenville: ["56036"],
      "Good thunder": ["56037"],
      Granada: ["56039"],
      Hanska: ["56041"],
      Hartland: ["56042"],
      Hayward: ["56043"],
      Henderson: ["56044"],
      Hollandale: ["56045"],
      Janesville: ["56048"],
      Kasota: ["56050"],
      Kiester: ["56051"],
      Kilkenny: ["56052"],
      Lafayette: ["56054"],
      "Lake crystal": ["56055"],
      "La salle": ["56056"],
      "Le center": ["56057"],
      "Le sueur": ["56058"],
      Lewisville: ["56060"],
      Madelia: ["56062"],
      "Madison lake": ["56063"],
      Manchester: ["56064"],
      Mapleton: ["56065"],
      "Minnesota lake": ["56068"],
      Montgomery: ["56069"],
      "New prague": ["56071"],
      "New richland": ["56072"],
      "New ulm": ["56073"],
      Nicollet: ["56074"],
      Northrop: ["56075"],
      Oakland: ["56076"],
      Pemberton: ["56078"],
      "Saint clair": ["56080"],
      "Saint james": ["56081"],
      "Saint peter": ["56082"],
      Sanborn: ["56083"],
      "Sleepy eye": ["56085"],
      Springfield: ["56087"],
      Truman: ["56088"],
      "Twin lakes": ["56089"],
      "Vernon center": ["56090"],
      Waldorf: ["56091"],
      Waseca: ["56093"],
      Waterville: ["56096"],
      Wells: ["56097"],
      Winnebago: ["56098"],
      "Zcta 560hh": ["560HH"],
      Wilder: ["56101"],
      Adrian: ["56110"],
      Alpha: ["56111"],
      Arco: ["56113"],
      Avoca: ["56114"],
      Balaton: ["56115"],
      "Beaver creek": ["56116"],
      Bigelow: ["56117"],
      "Bingham lake": ["56118"],
      Brewster: ["56119"],
      Butterfield: ["56120"],
      Ceylon: ["56121"],
      Chandler: ["56122"],
      Currie: ["56123"],
      Dunnell: ["56127"],
      Edgerton: ["56128"],
      Ellsworth: ["56129"],
      Fulda: ["56131"],
      Garvin: ["56132"],
      Hardwick: ["56134"],
      Hendricks: ["56136"],
      "Heron lake": ["56137"],
      Hills: ["56138"],
      Holland: ["56139"],
      Ihlen: ["56140"],
      Iona: ["56141"],
      Ivanhoe: ["56142"],
      Jackson: ["56143"],
      Jasper: ["56144"],
      Jeffers: ["56145"],
      Kenneth: ["56147"],
      "Lake benton": ["56149"],
      Lakefield: ["56150"],
      "Lake wilson": ["56151"],
      Lamberton: ["56152"],
      Leota: ["56153"],
      Lismore: ["56155"],
      Luverne: ["56156"],
      Lynd: ["56157"],
      Magnolia: ["56158"],
      "Mountain lake": ["56159"],
      Odin: ["56160"],
      Okabena: ["56161"],
      Ormsby: ["56162"],
      Hatfield: ["56164"],
      Reading: ["56165"],
      Revere: ["56166"],
      "Round lake": ["56167"],
      Rushmore: ["56168"],
      Russell: ["56169"],
      Florence: ["56170"],
      Sherburn: ["56171"],
      Slayton: ["56172"],
      Steen: ["56173"],
      Storden: ["56174"],
      Tracy: ["56175"],
      Trimont: ["56176"],
      Trosky: ["56177"],
      Tyler: ["56178"],
      "Walnut grove": ["56180"],
      Welcome: ["56181"],
      Westbrook: ["56183"],
      Wilmont: ["56185"],
      Woodstock: ["56186"],
      Worthington: ["56187"],
      "Zcta 561hh": ["561HH"],
      Willmar: ["56201"],
      Alberta: ["56207"],
      Appleton: ["56208"],
      Atwater: ["56209"],
      Beardsley: ["56211"],
      Bellingham: ["56212"],
      Belview: ["56214"],
      Benson: ["56215"],
      Svea: ["56216"],
      Boyd: ["56218"],
      "Browns valley": ["56219"],
      Canby: ["56220"],
      Chokio: ["56221"],
      "Clara city": ["56222"],
      Clarkfield: ["56223"],
      Clements: ["56224"],
      Clinton: ["56225"],
      Clontarf: ["56226"],
      Correll: ["56227"],
      Cosmos: ["56228"],
      Cottonwood: ["56229"],
      Danube: ["56230"],
      Danvers: ["56231"],
      Dawson: ["56232"],
      Donnelly: ["56235"],
      Dumont: ["56236"],
      Echo: ["56237"],
      Ghent: ["56239"],
      Graceville: ["56240"],
      "Granite falls": ["56241"],
      "Grove city": ["56243"],
      Hancock: ["56244"],
      "Hanley falls": ["56245"],
      Hawick: ["56246"],
      Herman: ["56248"],
      Holloway: ["56249"],
      Kandiyohi: ["56251"],
      Kerkhoven: ["56252"],
      "Lake lillian": ["56253"],
      Lucan: ["56255"],
      Madison: ["56256"],
      Marietta: ["56257"],
      Marshall: ["56258"],
      Maynard: ["56260"],
      Milan: ["56262"],
      Milroy: ["56263"],
      Minneota: ["56264"],
      Montevideo: ["56265"],
      Morgan: ["56266"],
      Morris: ["56267"],
      Morton: ["56270"],
      Murdock: ["56271"],
      "New london": ["56273"],
      Norcross: ["56274"],
      Odessa: ["56276"],
      Olivia: ["56277"],
      Ortonville: ["56278"],
      Pennock: ["56279"],
      Porter: ["56280"],
      Prinsburg: ["56281"],
      Raymond: ["56282"],
      Delhi: ["56283"],
      Renville: ["56284"],
      "Sacred heart": ["56285"],
      Seaforth: ["56287"],
      Spicer: ["56288"],
      Sunburg: ["56289"],
      Taunton: ["56291"],
      Vesta: ["56292"],
      Wabasso: ["56293"],
      Wanda: ["56294"],
      Watson: ["56295"],
      Wheaton: ["56296"],
      "Wood lake": ["56297"],
      "Zcta 562hh": ["562HH"],
      "Zcta 562xx": ["562XX"],
      "Saint cloud": ["56301", "56303", "56304"],
      Albany: ["56307"],
      Alexandria: ["56308"],
      Ashby: ["56309"],
      Avon: ["56310"],
      Barrett: ["56311"],
      Belgrade: ["56312"],
      Bock: ["56313"],
      Bowlus: ["56314"],
      Brandon: ["56315"],
      Brooten: ["56316"],
      Burtrum: ["56318"],
      Carlos: ["56319"],
      "Cold spring": ["56320"],
      Cyrus: ["56323"],
      Dalton: ["56324"],
      Elrosa: ["56325"],
      Evansville: ["56326"],
      Farwell: ["56327"],
      Flensburg: ["56328"],
      Foley: ["56329"],
      Foreston: ["56330"],
      Freeport: ["56331"],
      Garfield: ["56332"],
      Glenwood: ["56334"],
      Greenwald: ["56335"],
      "Grey eagle": ["56336"],
      Hillman: ["56338"],
      Hoffman: ["56339"],
      Holdingford: ["56340"],
      Isle: ["56342"],
      Kensington: ["56343"],
      "Little falls": ["56345"],
      "Little sauk": ["56347"],
      Lowry: ["56349"],
      "Mc grath": ["56350"],
      Melrose: ["56352"],
      Milaca: ["56353"],
      Miltona: ["56354"],
      Nelson: ["56355"],
      "New munich": ["56356"],
      "Oak park": ["56357"],
      Ogilvie: ["56358"],
      Onamia: ["56359"],
      Osakis: ["56360"],
      "Parkers prairie": ["56361"],
      Paynesville: ["56362"],
      Pierz: ["56364"],
      Rice: ["56367"],
      Richmond: ["56368"],
      Rockville: ["56369"],
      Roscoe: ["56371"],
      Royalton: ["56373"],
      "Saint joseph": ["56374"],
      "Saint stephen": ["56375"],
      "Saint martin": ["56376"],
      Sartell: ["56377"],
      "Sauk centre": ["56378"],
      "Sauk rapids": ["56379"],
      Starbuck: ["56381"],
      Swanville: ["56382"],
      Upsala: ["56384"],
      Villard: ["56385"],
      Wahkon: ["56386"],
      "Waite park": ["56387"],
      "Zcta 563hh": ["563HH"],
      "East gull lake": ["56401"],
      Baxter: ["56425"],
      Aitkin: ["56431"],
      Akeley: ["56433"],
      Aldrich: ["56434"],
      Backus: ["56435"],
      Benedict: ["56436"],
      Bertha: ["56437"],
      Browerville: ["56438"],
      Clarissa: ["56440"],
      Crosby: ["56441"],
      Crosslake: ["56442"],
      Cushing: ["56443"],
      Deerwood: ["56444"],
      "Eagle bend": ["56446"],
      Emily: ["56447"],
      "Fifty lakes": ["56448"],
      "Fort ripley": ["56449"],
      Garrison: ["56450"],
      Hackensack: ["56452"],
      Hewitt: ["56453"],
      Ironton: ["56455"],
      Jenkins: ["56456"],
      "Lake george": ["56458"],
      Laporte: ["56461"],
      Menahga: ["56464"],
      Merrifield: ["56465"],
      Leader: ["56466"],
      Nevis: ["56467"],
      "Lake shore": ["56468"],
      Palisade: ["56469"],
      "Park rapids": ["56470"],
      "Pequot lakes": ["56472"],
      Pillager: ["56473"],
      "Pine river": ["56474"],
      Randall: ["56475"],
      Sebeka: ["56477"],
      Staples: ["56479"],
      Verndale: ["56481"],
      Wadena: ["56482"],
      Walker: ["56484"],
      "Zcta 564hh": ["564HH"],
      "Zcta 564xx": ["564XX"],
      "Detroit lakes": ["56501"],
      Lockhart: ["56510"],
      Audubon: ["56511"],
      Baker: ["56513"],
      Downer: ["56514"],
      "Battle lake": ["56515"],
      Bejou: ["56516"],
      Beltrami: ["56517"],
      Bluffton: ["56518"],
      Borup: ["56519"],
      Breckenridge: ["56520"],
      Callaway: ["56521"],
      Doran: ["56522"],
      Eldred: ["56523"],
      Clitherall: ["56524"],
      Comstock: ["56525"],
      "Deer creek": ["56527"],
      Dent: ["56528"],
      Dilworth: ["56529"],
      "Elbow lake": ["56531"],
      Elizabeth: ["56533"],
      Erhard: ["56534"],
      Erskine: ["56535"],
      Felton: ["56536"],
      Carlisle: ["56537"],
      Fertile: ["56540"],
      Fosston: ["56542"],
      Foxhome: ["56543"],
      Frazee: ["56544"],
      Gary: ["56545"],
      Georgetown: ["56546"],
      Glyndon: ["56547"],
      Halstad: ["56548"],
      Rollag: ["56549"],
      Hendrum: ["56550"],
      Henning: ["56551"],
      Hitterdal: ["56552"],
      Kent: ["56553"],
      "Lake park": ["56554"],
      Mcintosh: ["56556"],
      Mahnomen: ["56557"],
      Moorhead: ["56560"],
      Nashua: ["56565"],
      Naytahwaush: ["56566"],
      "New york mills": ["56567"],
      Nielsville: ["56568"],
      Ogema: ["56569"],
      Osage: ["56570"],
      Ottertail: ["56571"],
      "Pelican rapids": ["56572"],
      Perham: ["56573"],
      Perley: ["56574"],
      Ponsford: ["56575"],
      Richville: ["56576"],
      Rochert: ["56578"],
      Rothsay: ["56579"],
      Sabin: ["56580"],
      Shelly: ["56581"],
      Tenney: ["56583"],
      "Twin valley": ["56584"],
      Ulen: ["56585"],
      Underwood: ["56586"],
      Vergas: ["56587"],
      Vining: ["56588"],
      Waubun: ["56589"],
      Wendell: ["56590"],
      Winger: ["56592"],
      Wolverton: ["56594"],
      "Zcta 565hh": ["565HH"],
      "Zcta 565xx": ["565XX"],
      Bemidji: ["56601"],
      Bagley: ["56621"],
      Baudette: ["56623"],
      Bena: ["56626"],
      "Big falls": ["56627"],
      Bigfork: ["56628"],
      Birchdale: ["56629"],
      Blackduck: ["56630"],
      "Cass lake": ["56633"],
      Clearbrook: ["56634"],
      "Deer river": ["56636"],
      Talmoon: ["56637"],
      Effie: ["56639"],
      "Federal dam": ["56641"],
      Gonvick: ["56644"],
      Gully: ["56646"],
      Hines: ["56647"],
      "International fa": ["56649"],
      Kelliher: ["56650"],
      Lengby: ["56651"],
      Leonard: ["56652"],
      Littlefork: ["56653"],
      Loman: ["56654"],
      Longville: ["56655"],
      Marcell: ["56657"],
      Max: ["56659"],
      Mizpah: ["56660"],
      Northome: ["56661"],
      Outing: ["56662"],
      Pennington: ["56663"],
      Ponemah: ["56666"],
      Puposky: ["56667"],
      Ray: ["56669"],
      Redby: ["56670"],
      Redlake: ["56671"],
      Remer: ["56672"],
      Roosevelt: ["56673"],
      Shevlin: ["56676"],
      Solway: ["56678"],
      "Spring lake": ["56680"],
      "Squaw lake": ["56681"],
      Tenstrike: ["56683"],
      Trail: ["56684"],
      Waskish: ["56685"],
      Williams: ["56686"],
      Wilton: ["56687"],
      Wirt: ["56688"],
      "Zcta 566hh": ["566HH"],
      "Zcta 566xx": ["566XX"],
      "Thief river fall": ["56701"],
      Alvarado: ["56710"],
      "Angle inlet": ["56711"],
      Angus: ["56712"],
      Argyle: ["56713"],
      Badger: ["56714"],
      Brooks: ["56715"],
      Crookston: ["56716"],
      Donaldson: ["56720"],
      "East grand forks": ["56721"],
      Euclid: ["56722"],
      Fisher: ["56723"],
      Gatzke: ["56724"],
      Goodridge: ["56725"],
      Greenbush: ["56726"],
      Grygla: ["56727"],
      Hallock: ["56728"],
      Halma: ["56729"],
      Humboldt: ["56731"],
      Karlstad: ["56732"],
      Kennedy: ["56733"],
      "Lake bronson": ["56734"],
      Orleans: ["56735"],
      Mentor: ["56736"],
      "Middle river": ["56737"],
      Newfolden: ["56738"],
      "Oak island": ["56741"],
      Oklee: ["56742"],
      Oslo: ["56744"],
      Plummer: ["56748"],
      "Red lake falls": ["56750"],
      Pencer: ["56751"],
      "Saint hilaire": ["56754"],
      "Saint vincent": ["56755"],
      Salol: ["56756"],
      Stephen: ["56757"],
      Strandquist: ["56758"],
      Strathcona: ["56759"],
      Viking: ["56760"],
      Wannaska: ["56761"],
      Radium: ["56762"],
      Warroad: ["56763"],
      "Zcta 567hh": ["567HH"],
      "Zcta 567xx": ["567XX"],
      "": ["582HH"],
    },
  },
  {
    abbrevation: "MS",
    name: "Mississippi",
    Cities: [
      "Abbeville",
      "Abbeville",
      "Cannon",
      "Batesville",
      "Belen",
      "Blue mountain",
      "Byhalia",
      "Stovall",
      "Coahoma",
      "Coldwater",
      "Como",
      "Courtland",
      "Askew",
      "Crowder",
      "Darling",
      "Dumas",
      "Dundee",
      "Etta",
      "Falkner",
      "Farrell",
      "Friars point",
      "Hernando",
      "Hickory flat",
      "Holly springs",
      "Horn lake",
      "Jonestown",
      "Lake cormorant",
      "Lamar",
      "Lambert",
      "Lula",
      "Lyon",
      "Marks",
      "Michigan city",
      "Mount pleasant",
      "Myrtle",
      "Nesbit",
      "New albany",
      "Olive branch",
      "Lafayette",
      "Pope",
      "Potts camp",
      "Red banks",
      "Ripley",
      "Robinsonville",
      "Savage",
      "Sardis",
      "Senatobia",
      "Clarksdale",
      "Sledge",
      "Southaven",
      "Zcta 38672",
      "Taylor",
      "Tiplersville",
      "Tunica",
      "University",
      "Walls",
      "Walnut",
      "Waterford",
      "Zcta 386hh",
      "Zcta 386xx",
      "Greenville",
      "Alligator",
      "Anguilla",
      "Arcola",
      "Avon",
      "Benoit",
      "Beulah",
      "Boyle",
      "Cleveland",
      "Doddsville",
      "Drew",
      "Parchman",
      "Dublin",
      "Duncan",
      "Glen allan",
      "Grace",
      "Gunnison",
      "Percy",
      "Baird",
      "Inverness",
      "Isola",
      "Elizabeth",
      "Mattson",
      "Merigold",
      "Metcalfe",
      "Moorhead",
      "Mound bayou",
      "Nitta yuma",
      "Pace",
      "Panther burn",
      "Rena lara",
      "Rome",
      "Rosedale",
      "Ruleville",
      "Scott",
      "Shaw",
      "Shelby",
      "Sunflower",
      "Zcta 387hh",
      "Zcta 387xx",
      "Tupelo",
      "Zcta 38804",
      "Amory",
      "Baldwyn",
      "Belden",
      "Belmont",
      "Blue springs",
      "Booneville",
      "Burnsville",
      "Kossuth",
      "Dennis",
      "Ecru",
      "Fulton",
      "Gattman",
      "Glen",
      "Golden",
      "Greenwood spring",
      "Guntown",
      "Houlka",
      "Houston",
      "Iuka",
      "Mantachie",
      "Marietta",
      "Mooreville",
      "Nettleton",
      "New site",
      "Egypt",
      "Plantersville",
      "Pontotoc",
      "Sarepta",
      "Rienzi",
      "Saltillo",
      "Shannon",
      "Sherman",
      "Smithville",
      "Thaxton",
      "Tishomingo",
      "Toccopola",
      "Tremont",
      "Vardaman",
      "Verona",
      "Wheeler",
      "Zcta 388hh",
      "Grenada",
      "Banner",
      "Big creek",
      "Bruce",
      "Calhoun city",
      "Carrollton",
      "Cascilla",
      "Charleston",
      "Coffeeville",
      "Coila",
      "Cruger",
      "Duck hill",
      "Enid",
      "Glendora",
      "Gore springs",
      "Greenwood",
      "Holcomb",
      "Itta bena",
      "Mc carley",
      "Minter city",
      "Morgan city",
      "North carrollton",
      "Oakland",
      "Water valley",
      "Philipp",
      "Pittsboro",
      "Schlater",
      "Scobey",
      "Sidon",
      "Sumner",
      "Swiftown",
      "Tillatoba",
      "Tippo",
      "Tutwiler",
      "Vance",
      "Webb",
      "Winona",
      "Zcta 389hh",
      "Zcta 389xx",
      "Belzoni",
      "Benton",
      "Bentonia",
      "Bolton",
      "Brandon",
      "Braxton",
      "Camden",
      "Canton",
      "Edinburg",
      "Cary",
      "Clinton",
      "Conehatta",
      "Crystal springs",
      "Delta city",
      "D lo",
      "Durant",
      "Edwards",
      "Ethel",
      "Fayette",
      "Flora",
      "Florence",
      "Forest",
      "Georgetown",
      "Goodman",
      "Harrisville",
      "Hazlehurst",
      "Hermanville",
      "Holly bluff",
      "Kosciusko",
      "Lake",
      "Lena",
      "Lexington",
      "Lorman",
      "Louise",
      "Ludlow",
      "Zcta 390hh",
      "Zcta 390xx",
      "Mc adams",
      "Mc cool",
      "Madden",
      "Madison",
      "Magee",
      "Sanatorium",
      "Mayersville",
      "Mendenhall",
      "Midnight",
      "Mize",
      "Morton",
      "Mount olive",
      "Natchez",
      "Newhebron",
      "Pattison",
      "Pelahatchie",
      "Pickens",
      "Piney woods",
      "Pinola",
      "Port gibson",
      "Puckett",
      "Pulaski",
      "Raleigh",
      "Learned",
      "Redwood",
      "Ridgeland",
      "Rolling fork",
      "Sallis",
      "Sandhill",
      "Satartia",
      "Silver city",
      "Taylorsville",
      "Tchula",
      "Terry",
      "Tougaloo",
      "Utica",
      "Vaiden",
      "Valley park",
      "Vicksburg",
      "Zcta 39183",
      "Walnut grove",
      "Wesson",
      "West",
      "Yazoo city",
      "Zcta 391hh",
      "Zcta 391xx",
      "Jackson",
      "Pearl",
      "Richland",
      "Zcta 392hh",
      "Meridian",
      "Bailey",
      "Buckatunna",
      "Chunky",
      "Collinsville",
      "Daleville",
      "Decatur",
      "De kalb",
      "Enterprise",
      "Hickory",
      "Lauderdale",
      "Lawrence",
      "Little rock",
      "Louin",
      "Louisville",
      "Macon",
      "Newton",
      "Noxapater",
      "Pachuta",
      "Paulding",
      "Philadelphia",
      "Porterville",
      "Preston",
      "Quitman",
      "Rose hill",
      "Scooba",
      "Sebastopol",
      "Matherville",
      "Shuqualak",
      "State line",
      "Stonewall",
      "Toomsuba",
      "Union",
      "Vossburg",
      "Waynesboro",
      "Zcta 393hh",
      "Zcta 393xx",
      "Hattiesburg",
      "Bassfield",
      "Bay springs",
      "Beaumont",
      "Brooklyn",
      "Carriere",
      "Carson",
      "Collins",
      "Columbia",
      "Eastabuchie",
      "Ellisville",
      "Heidelberg",
      "Laurel",
      "Zcta 39443",
      "Leakesville",
      "Agricola",
      "Lumberton",
      "Leaf",
      "Moselle",
      "Neely",
      "New augusta",
      "Ovett",
      "Petal",
      "Picayune",
      "Poplarville",
      "Prentiss",
      "Purvis",
      "Richton",
      "Sandersville",
      "Sandy hook",
      "Seminary",
      "Soso",
      "Stringer",
      "Sumrall",
      "Foxworth",
      "Zcta 394hh",
      "Zcta 394xx",
      "Gulfport",
      "Diamondhead",
      "Biloxi",
      "North bay",
      "Gautier",
      "Kiln",
      "Long beach",
      "Mc henry",
      "Moss point",
      "Kreole",
      "Ocean springs",
      "Pascagoula",
      "Pass christian",
      "Pearlington",
      "Perkinston",
      "Saucier",
      "Waveland",
      "Wiggins",
      "Zcta 395hh",
      "Zcta 395xx",
      "Brookhaven",
      "Bogue chitto",
      "Bude",
      "Centreville",
      "Chatawa",
      "Crosby",
      "Fernwood",
      "Gloster",
      "Jayess",
      "Kokomo",
      "Liberty",
      "Mc call creek",
      "Mc comb",
      "Magnolia",
      "Meadville",
      "Monticello",
      "Oak vale",
      "Osyka",
      "Roxie",
      "Ruth",
      "Silver creek",
      "Smithdale",
      "Sontag",
      "Summit",
      "Tylertown",
      "Union church",
      "Woodville",
      "Zcta 396hh",
      "Zcta 396xx",
      "Columbus",
      "Aberdeen",
      "Ackerman",
      "Artesia",
      "Brooksville",
      "Caledonia",
      "Cedarbluff",
      "Crawford",
      "Tomnolen",
      "French camp",
      "Hamilton",
      "Kilmichael",
      "Maben",
      "Mantee",
      "Mathiston",
      "Pheba",
      "Prairie",
      "Sessums",
      "Mississippi stat",
      "Steens",
      "Stewart",
      "Sturgis",
      "Walthall",
      "Weir",
      "West point",
      "Woodland",
      "Zcta 397hh",
      "Zcta 397xx",
    ],
    ZipCodes: {
      Abbeville: ["38601"],
      Cannon: ["38603"],
      Batesville: ["38606"],
      Belen: ["38609"],
      "Blue mountain": ["38610"],
      Byhalia: ["38611"],
      Stovall: ["38614"],
      Coahoma: ["38617"],
      Coldwater: ["38618"],
      Como: ["38619"],
      Courtland: ["38620"],
      Askew: ["38621"],
      Crowder: ["38622"],
      Darling: ["38623"],
      Dumas: ["38625"],
      Dundee: ["38626"],
      Etta: ["38627"],
      Falkner: ["38629"],
      Farrell: ["38630"],
      "Friars point": ["38631"],
      Hernando: ["38632"],
      "Hickory flat": ["38633"],
      "Holly springs": ["38635"],
      "Horn lake": ["38637"],
      Jonestown: ["38639"],
      "Lake cormorant": ["38641"],
      Lamar: ["38642"],
      Lambert: ["38643"],
      Lula: ["38644"],
      Lyon: ["38645"],
      Marks: ["38646"],
      "Michigan city": ["38647"],
      "Mount pleasant": ["38649"],
      Myrtle: ["38650"],
      Nesbit: ["38651"],
      "New albany": ["38652"],
      "Olive branch": ["38654"],
      Lafayette: ["38655"],
      Pope: ["38658"],
      "Potts camp": ["38659"],
      "Red banks": ["38661"],
      Ripley: ["38663"],
      Robinsonville: ["38664"],
      Savage: ["38665"],
      Sardis: ["38666"],
      Senatobia: ["38668"],
      Clarksdale: ["38669"],
      Sledge: ["38670"],
      Southaven: ["38671"],
      "Zcta 38672": ["38672"],
      Taylor: ["38673"],
      Tiplersville: ["38674"],
      Tunica: ["38676"],
      University: ["38677"],
      Walls: ["38680"],
      Walnut: ["38683"],
      Waterford: ["38685"],
      "Zcta 386hh": ["386HH"],
      "Zcta 386xx": ["386XX"],
      Greenville: ["38701", "38703"],
      Alligator: ["38720"],
      Anguilla: ["38721"],
      Arcola: ["38722"],
      Avon: ["38723"],
      Benoit: ["38725"],
      Beulah: ["38726"],
      Boyle: ["38730"],
      Cleveland: ["38732"],
      Doddsville: ["38736"],
      Drew: ["38737"],
      Parchman: ["38738"],
      Dublin: ["38739"],
      Duncan: ["38740"],
      "Glen allan": ["38744"],
      Grace: ["38745"],
      Gunnison: ["38746"],
      Percy: ["38748"],
      Baird: ["38751"],
      Inverness: ["38753"],
      Isola: ["38754"],
      Elizabeth: ["38756"],
      Mattson: ["38758"],
      Merigold: ["38759"],
      Metcalfe: ["38760"],
      Moorhead: ["38761"],
      "Mound bayou": ["38762"],
      "Nitta yuma": ["38763"],
      Pace: ["38764"],
      "Panther burn": ["38765"],
      "Rena lara": ["38767"],
      Rome: ["38768"],
      Rosedale: ["38769"],
      Ruleville: ["38771"],
      Scott: ["38772"],
      Shaw: ["38773"],
      Shelby: ["38774"],
      Sunflower: ["38778"],
      "Zcta 387hh": ["387HH"],
      "Zcta 387xx": ["387XX"],
      Tupelo: ["38801"],
      "Zcta 38804": ["38804"],
      Amory: ["38821"],
      Baldwyn: ["38824"],
      Belden: ["38826"],
      Belmont: ["38827"],
      "Blue springs": ["38828"],
      Booneville: ["38829"],
      Burnsville: ["38833"],
      Kossuth: ["38834"],
      Dennis: ["38838"],
      Ecru: ["38841"],
      Fulton: ["38843"],
      Gattman: ["38844"],
      Glen: ["38846"],
      Golden: ["38847"],
      "Greenwood spring": ["38848"],
      Guntown: ["38849"],
      Houlka: ["38850"],
      Houston: ["38851"],
      Iuka: ["38852"],
      Mantachie: ["38855"],
      Marietta: ["38856"],
      Mooreville: ["38857"],
      Nettleton: ["38858"],
      "New site": ["38859"],
      Egypt: ["38860"],
      Plantersville: ["38862"],
      Pontotoc: ["38863"],
      Sarepta: ["38864"],
      Rienzi: ["38865"],
      Saltillo: ["38866"],
      Shannon: ["38868"],
      Sherman: ["38869"],
      Smithville: ["38870"],
      Thaxton: ["38871"],
      Tishomingo: ["38873"],
      Toccopola: ["38874"],
      Tremont: ["38876"],
      Vardaman: ["38878"],
      Verona: ["38879"],
      Wheeler: ["38880"],
      "Zcta 388hh": ["388HH"],
      Grenada: ["38901"],
      Banner: ["38913"],
      "Big creek": ["38914"],
      Bruce: ["38915"],
      "Calhoun city": ["38916"],
      Carrollton: ["38917"],
      Cascilla: ["38920"],
      Charleston: ["38921"],
      Coffeeville: ["38922"],
      Coila: ["38923"],
      Cruger: ["38924"],
      "Duck hill": ["38925"],
      Enid: ["38927"],
      Glendora: ["38928"],
      "Gore springs": ["38929"],
      Greenwood: ["38930"],
      Holcomb: ["38940"],
      "Itta bena": ["38941"],
      "Mc carley": ["38943"],
      "Minter city": ["38944"],
      "Morgan city": ["38946"],
      "North carrollton": ["38947"],
      Oakland: ["38948"],
      "Water valley": ["38949", "38965"],
      Philipp: ["38950"],
      Pittsboro: ["38951"],
      Schlater: ["38952"],
      Scobey: ["38953"],
      Sidon: ["38954"],
      Sumner: ["38957"],
      Swiftown: ["38959"],
      Tillatoba: ["38961"],
      Tippo: ["38962"],
      Tutwiler: ["38963"],
      Vance: ["38964"],
      Webb: ["38966"],
      Winona: ["38967"],
      "Zcta 389hh": ["389HH"],
      "Zcta 389xx": ["389XX"],
      Belzoni: ["39038"],
      Benton: ["39039"],
      Bentonia: ["39040"],
      Bolton: ["39041"],
      Brandon: ["39042", "39047"],
      Braxton: ["39044"],
      Camden: ["39045"],
      Canton: ["39046"],
      Edinburg: ["39051"],
      Cary: ["39054"],
      Clinton: ["39056", "39060"],
      Conehatta: ["39057"],
      "Crystal springs": ["39059"],
      "Delta city": ["39061"],
      "D lo": ["39062"],
      Durant: ["39063"],
      Edwards: ["39066"],
      Ethel: ["39067"],
      Fayette: ["39069"],
      Flora: ["39071"],
      Florence: ["39073"],
      Forest: ["39074"],
      Georgetown: ["39078"],
      Goodman: ["39079"],
      Harrisville: ["39082"],
      Hazlehurst: ["39083"],
      Hermanville: ["39086"],
      "Holly bluff": ["39088"],
      Kosciusko: ["39090"],
      Lake: ["39092"],
      Lena: ["39094"],
      Lexington: ["39095"],
      Lorman: ["39096"],
      Louise: ["39097"],
      Ludlow: ["39098"],
      "Zcta 390hh": ["390HH"],
      "Zcta 390xx": ["390XX"],
      "Mc adams": ["39107"],
      "Mc cool": ["39108"],
      Madden: ["39109"],
      Madison: ["39110"],
      Magee: ["39111"],
      Sanatorium: ["39112"],
      Mayersville: ["39113"],
      Mendenhall: ["39114"],
      Midnight: ["39115"],
      Mize: ["39116"],
      Morton: ["39117"],
      "Mount olive": ["39119"],
      Natchez: ["39120"],
      Newhebron: ["39140"],
      Pattison: ["39144"],
      Pelahatchie: ["39145"],
      Pickens: ["39146", "39179"],
      "Piney woods": ["39148"],
      Pinola: ["39149"],
      "Port gibson": ["39150"],
      Puckett: ["39151"],
      Pulaski: ["39152"],
      Raleigh: ["39153"],
      Learned: ["39154"],
      Redwood: ["39156"],
      Ridgeland: ["39157"],
      "Rolling fork": ["39159"],
      Sallis: ["39160"],
      Sandhill: ["39161"],
      Satartia: ["39162"],
      "Silver city": ["39166"],
      Taylorsville: ["39168"],
      Tchula: ["39169"],
      Terry: ["39170"],
      Tougaloo: ["39174"],
      Utica: ["39175"],
      Vaiden: ["39176"],
      "Valley park": ["39177"],
      Vicksburg: ["39180"],
      "Zcta 39183": ["39183"],
      "Walnut grove": ["39189"],
      Wesson: ["39191"],
      West: ["39192"],
      "Yazoo city": ["39194"],
      "Zcta 391hh": ["391HH"],
      "Zcta 391xx": ["391XX"],
      Jackson: [
        "39201",
        "39202",
        "39203",
        "39204",
        "39206",
        "39209",
        "39210",
        "39211",
        "39212",
        "39213",
        "39216",
        "39269",
      ],
      Pearl: ["39208"],
      Richland: ["39218"],
      "Zcta 392hh": ["392HH"],
      Meridian: ["39301", "39305", "39307"],
      Bailey: ["39320"],
      Buckatunna: ["39322"],
      Chunky: ["39323"],
      Collinsville: ["39325"],
      Daleville: ["39326"],
      Decatur: ["39327"],
      "De kalb": ["39328"],
      Enterprise: ["39330"],
      Hickory: ["39332"],
      Lauderdale: ["39335"],
      Lawrence: ["39336"],
      "Little rock": ["39337"],
      Louin: ["39338"],
      Louisville: ["39339"],
      Macon: ["39341"],
      Newton: ["39345"],
      Noxapater: ["39346"],
      Pachuta: ["39347"],
      Paulding: ["39348"],
      Philadelphia: ["39350"],
      Porterville: ["39352"],
      Preston: ["39354"],
      Quitman: ["39355"],
      "Rose hill": ["39356"],
      Scooba: ["39358"],
      Sebastopol: ["39359"],
      Matherville: ["39360"],
      Shuqualak: ["39361"],
      "State line": ["39362"],
      Stonewall: ["39363"],
      Toomsuba: ["39364"],
      Union: ["39365"],
      Vossburg: ["39366"],
      Waynesboro: ["39367"],
      "Zcta 393hh": ["393HH"],
      "Zcta 393xx": ["393XX"],
      Hattiesburg: ["39401", "39402"],
      Bassfield: ["39421"],
      "Bay springs": ["39422"],
      Beaumont: ["39423"],
      Brooklyn: ["39425"],
      Carriere: ["39426"],
      Carson: ["39427"],
      Collins: ["39428"],
      Columbia: ["39429"],
      Eastabuchie: ["39436"],
      Ellisville: ["39437"],
      Heidelberg: ["39439"],
      Laurel: ["39440"],
      "Zcta 39443": ["39443"],
      Leakesville: ["39451"],
      Agricola: ["39452"],
      Lumberton: ["39455"],
      Leaf: ["39456"],
      Moselle: ["39459"],
      Neely: ["39461"],
      "New augusta": ["39462"],
      Ovett: ["39464"],
      Petal: ["39465"],
      Picayune: ["39466"],
      Poplarville: ["39470"],
      Prentiss: ["39474"],
      Purvis: ["39475"],
      Richton: ["39476"],
      Sandersville: ["39477"],
      "Sandy hook": ["39478"],
      Seminary: ["39479"],
      Soso: ["39480"],
      Stringer: ["39481"],
      Sumrall: ["39482"],
      Foxworth: ["39483"],
      "Zcta 394hh": ["394HH"],
      "Zcta 394xx": ["394XX"],
      Gulfport: ["39501", "39503", "39507"],
      Diamondhead: ["39520", "39525"],
      Biloxi: ["39530", "39531"],
      "North bay": ["39532"],
      Gautier: ["39553"],
      Kiln: ["39556"],
      "Long beach": ["39560"],
      "Mc henry": ["39561"],
      "Moss point": ["39562"],
      Kreole: ["39563"],
      "Ocean springs": ["39564", "39565"],
      Pascagoula: ["39567", "39581"],
      "Pass christian": ["39571"],
      Pearlington: ["39572"],
      Perkinston: ["39573"],
      Saucier: ["39574"],
      Waveland: ["39576"],
      Wiggins: ["39577"],
      "Zcta 395hh": ["395HH"],
      "Zcta 395xx": ["395XX"],
      Brookhaven: ["39601"],
      "Bogue chitto": ["39629"],
      Bude: ["39630"],
      Centreville: ["39631"],
      Chatawa: ["39632"],
      Crosby: ["39633"],
      Fernwood: ["39635"],
      Gloster: ["39638"],
      Jayess: ["39641"],
      Kokomo: ["39643"],
      Liberty: ["39645"],
      "Mc call creek": ["39647"],
      "Mc comb": ["39648"],
      Magnolia: ["39652"],
      Meadville: ["39653"],
      Monticello: ["39654"],
      "Oak vale": ["39656"],
      Osyka: ["39657"],
      Roxie: ["39661"],
      Ruth: ["39662"],
      "Silver creek": ["39663"],
      Smithdale: ["39664"],
      Sontag: ["39665"],
      Summit: ["39666"],
      Tylertown: ["39667"],
      "Union church": ["39668"],
      Woodville: ["39669"],
      "Zcta 396hh": ["396HH"],
      "Zcta 396xx": ["396XX"],
      Columbus: ["39701", "39702", "39704", "39705"],
      Aberdeen: ["39730"],
      Ackerman: ["39735"],
      Artesia: ["39736"],
      Brooksville: ["39739"],
      Caledonia: ["39740"],
      Cedarbluff: ["39741"],
      Crawford: ["39743"],
      Tomnolen: ["39744"],
      "French camp": ["39745"],
      Hamilton: ["39746"],
      Kilmichael: ["39747"],
      Maben: ["39750"],
      Mantee: ["39751"],
      Mathiston: ["39752"],
      Pheba: ["39755"],
      Prairie: ["39756"],
      Sessums: ["39759"],
      "Mississippi stat": ["39762"],
      Steens: ["39766"],
      Stewart: ["39767"],
      Sturgis: ["39769"],
      Walthall: ["39771"],
      Weir: ["39772"],
      "West point": ["39773"],
      Woodland: ["39776"],
      "Zcta 397hh": ["397HH"],
      "Zcta 397xx": ["397XX"],
    },
  },
  {
    abbrevation: "MO",
    name: "Missouri",
    Cities: [
      "",
      "",
      "Chesterfield",
      "Arnold",
      "Manchester",
      "Barnhart",
      "Beaufort",
      "Berger",
      "Catawissa",
      "Cedar hill",
      "Town and country",
      "Crystal city",
      "De soto",
      "Ballwin",
      "Dittmer",
      "Crescent",
      "Fenton",
      "Festus",
      "Fletcher",
      "Florissant",
      "French village",
      "Gerald",
      "Glencoe",
      "Gray summit",
      "Grover",
      "Grubville",
      "Hazelwood",
      "Maryland heights",
      "Bridgeton",
      "Herculaneum",
      "High ridge",
      "Hillsboro",
      "House springs",
      "Antonia",
      "Labadie",
      "Leslie",
      "Lonedell",
      "Luebbering",
      "New haven",
      "Pacific",
      "Pevely",
      "Richwoods",
      "Robertsville",
      "Saint ann",
      "Saint clair",
      "Sullivan",
      "Union",
      "Valles mines",
      "Valley park",
      "Villa ridge",
      "Washington",
      "Rosebud",
      "Zcta 630hh",
      "Saint louis",
      "Clayton",
      "Overland",
      "Richmond heights",
      "Webster groves",
      "Normandy",
      "Kirkwood",
      "Affton",
      "Ladue",
      "Lemay",
      "Sappington",
      "South county",
      "University city",
      "Des peres",
      "Olivette",
      "Berkeley",
      "Ferguson",
      "Jennings",
      "North county",
      "Creve coeur",
      "Maplewood",
      "Brentwood",
      "West county",
      "Zcta 631hh",
      "Saint charles",
      "Annada",
      "Augusta",
      "Bellflower",
      "Bowling green",
      "Clarksville",
      "Curryville",
      "Defiance",
      "Elsberry",
      "Eolia",
      "Farber",
      "Foley",
      "Foristell",
      "Hawk point",
      "High hill",
      "Jonesburg",
      "Laddonia",
      "Louisiana",
      "Lake sherwood",
      "Middletown",
      "Montgomery city",
      "Moscow mills",
      "New florence",
      "Saint paul",
      "Lake saint louis",
      "Old monroe",
      "Olney",
      "Portage des siou",
      "Saint peters",
      "Silex",
      "Troy",
      "Truxton",
      "Vandalia",
      "Warrenton",
      "Wellsville",
      "Wentzville",
      "West alton",
      "Whiteside",
      "Williamsburg",
      "Winfield",
      "Wright city",
      "Zcta 633hh",
      "Hannibal",
      "Alexandria",
      "Anabel",
      "Arbela",
      "Ashburn",
      "Bethel",
      "Canton",
      "Center",
      "Clarence",
      "Durham",
      "Emden",
      "Ewing",
      "Frankford",
      "Hunnewell",
      "Kahoka",
      "Knox city",
      "La belle",
      "La grange",
      "Lentner",
      "Leonard",
      "Lewistown",
      "Luray",
      "Maywood",
      "Monroe city",
      "Monticello",
      "Newark",
      "New london",
      "Novelty",
      "Palmyra",
      "Perry",
      "Philadelphia",
      "Plevna",
      "Revere",
      "Shelbina",
      "Shelbyville",
      "Taylor",
      "Wayland",
      "Williamstown",
      "Wyaconda",
      "Zcta 634hh",
      "Kirksville",
      "Atlanta",
      "Baring",
      "Bevier",
      "Brashear",
      "Callao",
      "Coatsville",
      "Downing",
      "Edina",
      "Elmer",
      "Ethel",
      "Gibbs",
      "Glenwood",
      "Gorin",
      "Green castle",
      "Green city",
      "Greentop",
      "Hurdland",
      "Lancaster",
      "La plata",
      "Livonia",
      "Macon",
      "Memphis",
      "Milan",
      "New boston",
      "New cambria",
      "Novinger",
      "Pollock",
      "Queen city",
      "Rutledge",
      "Unionville",
      "Winigan",
      "Worthington",
      "Zcta 635hh",
      "Desloge",
      "Annapolis",
      "Arcadia",
      "Belgrade",
      "Belleview",
      "Black",
      "Blackwell",
      "Bloomsdale",
      "Bonne terre",
      "Bunker",
      "Cadet",
      "Caledonia",
      "Centerville",
      "Des arc",
      "Doe run",
      "Ellington",
      "Farmington",
      "Millcreek",
      "Irondale",
      "Iron mountain",
      "Leadwood",
      "Lesterville",
      "Marquand",
      "Middle brook",
      "Mineral point",
      "Patton",
      "Pilot knob",
      "Potosi",
      "Redford",
      "Lake forest esta",
      "Saint mary",
      "Tiff",
      "Vulcan",
      "Zcta 636hh",
      "Cape girardeau",
      "Zcta 63703",
      "Advance",
      "New wells",
      "Bell city",
      "Benton",
      "Brownwood",
      "Burfordville",
      "Chaffee",
      "Commerce",
      "Daisy",
      "Friedheim",
      "Frohna",
      "Gipsy",
      "Glenallen",
      "Grassy",
      "Jackson",
      "Kelso",
      "Leopold",
      "Mc gee",
      "Scopus",
      "Millersville",
      "Morley",
      "Oak ridge",
      "Old appleton",
      "Oran",
      "Perkins",
      "Perryville",
      "Scott city",
      "Sedgewickville",
      "Sturdivant",
      "Uniontown",
      "Vanduser",
      "Whitewater",
      "Zalma",
      "Zcta 637hh",
      "Sikeston",
      "Anniston",
      "Arbyrd",
      "Bernie",
      "Bertrand",
      "Blodgett",
      "Bloomfield",
      "Braggadocio",
      "Bragg city",
      "Canalou",
      "Cardwell",
      "Caruthersville",
      "Catron",
      "Charleston",
      "Clarkton",
      "Cooter",
      "Dexter",
      "East prairie",
      "Essex",
      "Gibson",
      "Gideon",
      "Gobler",
      "Grayridge",
      "Hayti heights",
      "Holcomb",
      "Holland",
      "Hornersville",
      "Kennett",
      "Kewanee",
      "Lilbourn",
      "Malden",
      "Marston",
      "Matthews",
      "Morehouse",
      "New madrid",
      "Parma",
      "Portageville",
      "Risco",
      "Senath",
      "Steele",
      "Tallapoosa",
      "Homestown",
      "Whiteoak",
      "Wyatt",
      "Zcta 638hh",
      "Poplar bluff",
      "Broseley",
      "Campbell",
      "Clubb",
      "Poynor",
      "Dudley",
      "Ellsinore",
      "Fagus",
      "Fairdealing",
      "Fisk",
      "Fremont",
      "Gatewood",
      "Grandin",
      "Greenville",
      "Harviell",
      "Hiram",
      "Lowndes",
      "Mill spring",
      "Naylor",
      "Neelyville",
      "Oxly",
      "Patterson",
      "Piedmont",
      "Puxico",
      "Qulin",
      "Shook",
      "Silva",
      "Van buren",
      "Wappapello",
      "Williamsville",
      "Zcta 639hh",
      "Alma",
      "Bates city",
      "Belton",
      "Blue springs",
      "Lake tapawingo",
      "Buckner",
      "Camden",
      "Camden point",
      "Centerview",
      "Concordia",
      "Corder",
      "Dover",
      "Excelsior spring",
      "Grain valley",
      "Grandview",
      "Lake winnebago",
      "Hardin",
      "Henrietta",
      "Higginsville",
      "Holden",
      "Holt",
      "Independence",
      "Sugar creek",
      "Kearney",
      "Kingsville",
      "Lawson",
      "Lake lotawana",
      "Lees summit",
      "Levasy",
      "Lexington",
      "Pleasant valley",
      "Lone jack",
      "Mayview",
      "Missouri city",
      "Napoleon",
      "Oak grove",
      "Odessa",
      "Orrick",
      "Peculiar",
      "Platte city",
      "Pleasant hill",
      "Raymore",
      "Rayville",
      "Richmond",
      "Sibley",
      "Smithville",
      "Strasburg",
      "Warrensburg",
      "Waverly",
      "Wellington",
      "Weston",
      "Zcta 640hh",
      "Kansas city",
      "North kansas cit",
      "Randolph",
      "Gladstone",
      "Raytown",
      "Martin city",
      "Lake waukomis",
      "Parkville",
      "Ferrelview",
      "Zcta 641hh",
      "Agency",
      "Albany",
      "Amazonia",
      "Amity",
      "Barnard",
      "Bethany",
      "Blythedale",
      "Bolckow",
      "Burlington junct",
      "Cameron",
      "Clarksdale",
      "Clearmont",
      "Clyde",
      "Conception",
      "Conception junct",
      "Cosby",
      "Bigelow",
      "Darlington",
      "Dearborn",
      "De kalb",
      "Denver",
      "Eagleville",
      "Easton",
      "Edgerton",
      "Elmo",
      "Fairfax",
      "Faucett",
      "Fillmore",
      "Forest city",
      "Gentry",
      "Gower",
      "Graham",
      "Grant city",
      "Guilford",
      "Hatfield",
      "Helena",
      "Hopkins",
      "King city",
      "Lathrop",
      "Maitland",
      "Martinsville",
      "Maryville",
      "Maysville",
      "Mound city",
      "New hampton",
      "Oregon",
      "Osborn",
      "Parnell",
      "Pickering",
      "Plattsburg",
      "Ravenwood",
      "Rea",
      "Ridgeway",
      "Rock port",
      "Rosendale",
      "Rushville",
      "Savannah",
      "Sheridan",
      "Skidmore",
      "Stanberry",
      "Hemple",
      "Tarkio",
      "Trimble",
      "Turney",
      "Union star",
      "Watson",
      "Weatherby",
      "Westboro",
      "Worth",
      "Zcta 644hh",
      "Saint joseph",
      "Zcta 645hh",
      "Chillicothe",
      "Altamont",
      "Bogard",
      "Bosworth",
      "Braymer",
      "Breckenridge",
      "Brookfield",
      "Browning",
      "Bucklin",
      "Cainsville",
      "Carrollton",
      "Chula",
      "Coffey",
      "Cowgill",
      "Dawn",
      "De witt",
      "Gallatin",
      "Galt",
      "Gilman city",
      "Hale",
      "Hamilton",
      "Harris",
      "Humphreys",
      "Jameson",
      "Jamesport",
      "Kidder",
      "Kingston",
      "Laclede",
      "Laredo",
      "Linneus",
      "Lucerne",
      "Ludlow",
      "Mc fall",
      "Marceline",
      "Meadville",
      "Mendon",
      "Mercer",
      "Mooresville",
      "Newtown",
      "Norborne",
      "Pattonsburg",
      "Polo",
      "Powersville",
      "Princeton",
      "Purdin",
      "Rothville",
      "Spickard",
      "Sumner",
      "Tina",
      "Trenton",
      "Utica",
      "Wheeling",
      "Winston",
      "Zcta 646hh",
      "Harrisonville",
      "Adrian",
      "Amoret",
      "Amsterdam",
      "Appleton city",
      "Archie",
      "Blairstown",
      "Bronaugh",
      "Butler",
      "Chilhowee",
      "Cleveland",
      "Tightwad",
      "Collins",
      "Creighton",
      "Deepwater",
      "Deerfield",
      "Drexel",
      "East lynne",
      "El dorado spring",
      "Foster",
      "Freeman",
      "Garden city",
      "Golden city",
      "Harwood",
      "Horton",
      "Stotesbury",
      "Jasper",
      "Jerico springs",
      "Iantha",
      "Leeton",
      "Liberal",
      "Lowry city",
      "Milo",
      "Mindenmines",
      "Montrose",
      "Moundville",
      "Nevada",
      "Osceola",
      "Richards",
      "Rich hill",
      "Rockville",
      "Roscoe",
      "Schell city",
      "Sheldon",
      "Urich",
      "Walker",
      "Zcta 647hh",
      "Joplin",
      "Alba",
      "Anderson",
      "Asbury",
      "Avilla",
      "Carl junction",
      "Carterville",
      "Carthage",
      "Diamond",
      "Duenweg",
      "Fairview",
      "Goodman",
      "Granby",
      "Lanagan",
      "La russell",
      "Neck city",
      "Neosho",
      "Noel",
      "Oronogo",
      "Jane",
      "Purcell",
      "Racine",
      "Reeds",
      "Rocky comfort",
      "Sarcoxie",
      "South west city",
      "Seneca",
      "Stark city",
      "Stella",
      "Webb city",
      "Wentworth",
      "Wheaton",
      "Argyle",
      "Ashland",
      "Barnett",
      "Belle",
      "Bland",
      "Bonnots mill",
      "Brumley",
      "California",
      "Camdenton",
      "Centertown",
      "Chamois",
      "Clarksburg",
      "Eldon",
      "Eugene",
      "Fortuna",
      "Freeburg",
      "Gasconade",
      "Gravois mills",
      "Hartsburg",
      "Henley",
      "Bay",
      "Holts summit",
      "Jamestown",
      "Kaiser",
      "Koeltztown",
      "Four seasons",
      "Latham",
      "Linn",
      "Linn creek",
      "Lohman",
      "Loose creek",
      "Meta",
      "Mokane",
      "Morrison",
      "Mount sterling",
      "New bloomfield",
      "Olean",
      "Osage beach",
      "Owensville",
      "Portland",
      "Prairie home",
      "Rhineland",
      "Rocky mount",
      "Russellville",
      "Saint elizabeth",
      "Saint thomas",
      "Steedman",
      "Stover",
      "Sunrise beach",
      "Tebbetts",
      "Tipton",
      "Tuscumbia",
      "Ulman",
      "Versailles",
      "Westphalia",
      "Zcta 650hh",
      "Jefferson city",
      "Zcta 651hh",
      "Columbia",
      "Armstrong",
      "Auxvasse",
      "Benton city",
      "Boonville",
      "Brunswick",
      "Bunceton",
      "Cairo",
      "Centralia",
      "Clark",
      "Clifton hill",
      "Dalton",
      "Excello",
      "Fayette",
      "Franklin",
      "Fulton",
      "Glasgow",
      "Hallsville",
      "Harrisburg",
      "Higbee",
      "Holliday",
      "Huntsville",
      "Jacksonville",
      "Keytesville",
      "Kingdom city",
      "Madison",
      "Martinsburg",
      "Mexico",
      "Moberly",
      "New franklin",
      "Paris",
      "Pilot grove",
      "Renick",
      "Rocheport",
      "Rush hill",
      "Salisbury",
      "Santa fe",
      "Stoutsville",
      "Sturgeon",
      "Thompson",
      "Triplett",
      "Wooldridge",
      "Zcta 652hh",
      "Sedalia",
      "Whiteman afb",
      "Arrow rock",
      "Blackburn",
      "Blackwater",
      "Calhoun",
      "Climax springs",
      "Cole camp",
      "Edwards",
      "Emma",
      "Florence",
      "Gilliam",
      "Green ridge",
      "Houstonia",
      "Hughesville",
      "Ionia",
      "Knob noster",
      "La monte",
      "Lincoln",
      "Grand pass",
      "Napton",
      "Miami",
      "Mora",
      "Nelson",
      "Otterville",
      "Slater",
      "Smithton",
      "Sweet springs",
      "Syracuse",
      "Warsaw",
      "Windsor",
      "Zcta 653hh",
      "Rolla",
      "Beulah",
      "Birch tree",
      "Bixby",
      "Boss",
      "Bourbon",
      "Brinktown",
      "Bucyrus",
      "Cherryville",
      "Cook station",
      "Crocker",
      "Cuba",
      "Davisville",
      "Devils elbow",
      "Dixon",
      "Duke",
      "Edgar springs",
      "Eldridge",
      "Elk creek",
      "Eminence",
      "Eunice",
      "Falcon",
      "Fort leonard woo",
      "Hartshorn",
      "Houston",
      "Huggins",
      "Iberia",
      "Zcta 654hh",
      "Jadwin",
      "Jerome",
      "Lake spring",
      "Laquey",
      "Leasburg",
      "Lebanon",
      "Anutt",
      "Lenox",
      "Licking",
      "Lynchburg",
      "Mountain view",
      "Newburg",
      "Plato",
      "Raymondville",
      "Richland",
      "Roby",
      "Saint james",
      "Salem",
      "Solo",
      "Berryman",
      "Viburnum",
      "Stoutland",
      "Success",
      "Summersville",
      "Vichy",
      "Vienna",
      "Saint robert",
      "Winona",
      "Yukon",
      "Long lane",
      "Montreal",
      "Zcta 655hh",
      "Aldrich",
      "Arcola",
      "Ash grove",
      "Jenkins",
      "Riverton",
      "Ava",
      "Bakersfield",
      "Billings",
      "Blue eye",
      "Bois d arc",
      "Bolivar",
      "Bradleyville",
      "Marvel cave park",
      "Brighton",
      "Brixey",
      "Brookline statio",
      "Bruner",
      "Buffalo",
      "Butterfield",
      "Cape fair",
      "Cassville",
      "Caulfield",
      "Cedarcreek",
      "Chadwick",
      "Chestnutridge",
      "Clever",
      "Conway",
      "Crane",
      "Cross timbers",
      "Dadeville",
      "Dora",
      "Drury",
      "Dunnegan",
      "Eagle rock",
      "Elkland",
      "Everton",
      "Exeter",
      "Fair grove",
      "Fair play",
      "Flemington",
      "Fordland",
      "Forsyth",
      "Gainesville",
      "Galena",
      "Garrison",
      "Golden",
      "Goodson",
      "Graff",
      "Greenfield",
      "Grovespring",
      "Half way",
      "Halltown",
      "Hartville",
      "Hermitage",
      "Highlandville",
      "Hollister",
      "Humansville",
      "Hurley",
      "Isabella",
      "Kirbyville",
      "Kissee mills",
      "Lampe",
      "Lockwood",
      "Louisburg",
      "Kimberling city",
      "Cabool",
      "Couch",
      "Koshkonong",
      "Zcta 656hh",
      "Mc clurg",
      "Macomb",
      "Mansfield",
      "Marionville",
      "Marshfield",
      "Miller",
      "Monett",
      "Morrisville",
      "Mountain grove",
      "Mount vernon",
      "Niangua",
      "Nixa",
      "Noble",
      "Norwood",
      "Oldfield",
      "Ozark",
      "Phillipsburg",
      "Pierce city",
      "Pittsburg",
      "Pleasant hope",
      "Polk",
      "Ponce de leon",
      "Pontiac",
      "Powell",
      "Powersite",
      "Preston",
      "Protem",
      "Purdy",
      "Quincy",
      "Branson west",
      "Republic",
      "Ridgedale",
      "Rockaway beach",
      "Rogersville",
      "Rueter",
      "Seligman",
      "Seymour",
      "Shell knob",
      "South greenfield",
      "Sparta",
      "Spokane",
      "Squires",
      "Stotts city",
      "Strafford",
      "Taneyville",
      "Tecumseh",
      "Dugginsville",
      "Nottinghill",
      "Tunas",
      "Udall",
      "Urbana",
      "Vanzant",
      "Verona",
      "Walnut grove",
      "Walnut shade",
      "Washburn",
      "Souder",
      "Weaubleau",
      "West plains",
      "Moody",
      "Myrtle",
      "Wheatland",
      "Willard",
      "Windyville",
      "Zanoni",
      "Stockton",
      "Macks creek",
      "Roach",
      "Peace valley",
      "Pomona",
      "Pottersville",
      "Thayer",
      "Willow springs",
      "Zcta 657hh",
      "Springfield",
      "Zcta 658hh",
    ],
    ZipCodes: {
      "": ["51630", "51640", "52626", "72644"],
      Chesterfield: ["63005"],
      Arnold: ["63010"],
      Manchester: ["63011"],
      Barnhart: ["63012"],
      Beaufort: ["63013"],
      Berger: ["63014"],
      Catawissa: ["63015"],
      "Cedar hill": ["63016"],
      "Town and country": ["63017"],
      "Crystal city": ["63019"],
      "De soto": ["63020"],
      Ballwin: ["63021"],
      Dittmer: ["63023"],
      Crescent: ["63025"],
      Fenton: ["63026"],
      Festus: ["63028"],
      Fletcher: ["63030"],
      Florissant: ["63031", "63033", "63034"],
      "French village": ["63036"],
      Gerald: ["63037"],
      Glencoe: ["63038"],
      "Gray summit": ["63039"],
      Grover: ["63040"],
      Grubville: ["63041"],
      Hazelwood: ["63042"],
      "Maryland heights": ["63043"],
      Bridgeton: ["63044"],
      Herculaneum: ["63048"],
      "High ridge": ["63049"],
      Hillsboro: ["63050"],
      "House springs": ["63051"],
      Antonia: ["63052"],
      Labadie: ["63055"],
      Leslie: ["63056"],
      Lonedell: ["63060"],
      Luebbering: ["63061"],
      "New haven": ["63068"],
      Pacific: ["63069"],
      Pevely: ["63070"],
      Richwoods: ["63071"],
      Robertsville: ["63072"],
      "Saint ann": ["63074"],
      "Saint clair": ["63077"],
      Sullivan: ["63080"],
      Union: ["63084"],
      "Valles mines": ["63087"],
      "Valley park": ["63088"],
      "Villa ridge": ["63089"],
      Washington: ["63090"],
      Rosebud: ["63091"],
      "Zcta 630hh": ["630HH"],
      "Saint louis": [
        "63101",
        "63102",
        "63103",
        "63104",
        "63106",
        "63107",
        "63108",
        "63109",
        "63110",
        "63111",
        "63112",
        "63113",
        "63115",
        "63116",
        "63118",
        "63120",
        "63133",
        "63139",
        "63147",
      ],
      Clayton: ["63105"],
      Overland: ["63114"],
      "Richmond heights": ["63117"],
      "Webster groves": ["63119"],
      Normandy: ["63121"],
      Kirkwood: ["63122"],
      Affton: ["63123"],
      Ladue: ["63124"],
      Lemay: ["63125"],
      Sappington: ["63126", "63127", "63128"],
      "South county": ["63129"],
      "University city": ["63130"],
      "Des peres": ["63131"],
      Olivette: ["63132"],
      Berkeley: ["63134", "63140"],
      Ferguson: ["63135"],
      Jennings: ["63136"],
      "North county": ["63137", "63138"],
      "Creve coeur": ["63141"],
      Maplewood: ["63143"],
      Brentwood: ["63144"],
      "West county": ["63146"],
      "Zcta 631hh": ["631HH"],
      "Saint charles": ["63301", "63303", "63304"],
      Annada: ["63330"],
      Augusta: ["63332"],
      Bellflower: ["63333"],
      "Bowling green": ["63334"],
      Clarksville: ["63336"],
      Curryville: ["63339"],
      Defiance: ["63341"],
      Elsberry: ["63343"],
      Eolia: ["63344"],
      Farber: ["63345"],
      Foley: ["63347"],
      Foristell: ["63348"],
      "Hawk point": ["63349"],
      "High hill": ["63350"],
      Jonesburg: ["63351"],
      Laddonia: ["63352"],
      Louisiana: ["63353"],
      "Lake sherwood": ["63357"],
      Middletown: ["63359"],
      "Montgomery city": ["63361"],
      "Moscow mills": ["63362"],
      "New florence": ["63363"],
      "Saint paul": ["63366"],
      "Lake saint louis": ["63367"],
      "Old monroe": ["63369"],
      Olney: ["63370"],
      "Portage des siou": ["63373"],
      "Saint peters": ["63376"],
      Silex: ["63377"],
      Troy: ["63379"],
      Truxton: ["63381"],
      Vandalia: ["63382"],
      Warrenton: ["63383"],
      Wellsville: ["63384"],
      Wentzville: ["63385"],
      "West alton": ["63386"],
      Whiteside: ["63387"],
      Williamsburg: ["63388"],
      Winfield: ["63389"],
      "Wright city": ["63390"],
      "Zcta 633hh": ["633HH"],
      Hannibal: ["63401"],
      Alexandria: ["63430"],
      Anabel: ["63431"],
      Arbela: ["63432"],
      Ashburn: ["63433"],
      Bethel: ["63434"],
      Canton: ["63435"],
      Center: ["63436"],
      Clarence: ["63437"],
      Durham: ["63438"],
      Emden: ["63439"],
      Ewing: ["63440"],
      Frankford: ["63441"],
      Hunnewell: ["63443"],
      Kahoka: ["63445"],
      "Knox city": ["63446"],
      "La belle": ["63447"],
      "La grange": ["63448"],
      Lentner: ["63450"],
      Leonard: ["63451"],
      Lewistown: ["63452"],
      Luray: ["63453"],
      Maywood: ["63454"],
      "Monroe city": ["63456"],
      Monticello: ["63457"],
      Newark: ["63458"],
      "New london": ["63459"],
      Novelty: ["63460"],
      Palmyra: ["63461"],
      Perry: ["63462"],
      Philadelphia: ["63463"],
      Plevna: ["63464"],
      Revere: ["63465"],
      Shelbina: ["63468"],
      Shelbyville: ["63469"],
      Taylor: ["63471"],
      Wayland: ["63472"],
      Williamstown: ["63473"],
      Wyaconda: ["63474"],
      "Zcta 634hh": ["634HH"],
      Kirksville: ["63501"],
      Atlanta: ["63530"],
      Baring: ["63531"],
      Bevier: ["63532"],
      Brashear: ["63533"],
      Callao: ["63534"],
      Coatsville: ["63535"],
      Downing: ["63536"],
      Edina: ["63537"],
      Elmer: ["63538"],
      Ethel: ["63539"],
      Gibbs: ["63540"],
      Glenwood: ["63541"],
      Gorin: ["63543"],
      "Green castle": ["63544"],
      "Green city": ["63545"],
      Greentop: ["63546"],
      Hurdland: ["63547"],
      Lancaster: ["63548"],
      "La plata": ["63549"],
      Livonia: ["63551"],
      Macon: ["63552"],
      Memphis: ["63555"],
      Milan: ["63556"],
      "New boston": ["63557"],
      "New cambria": ["63558"],
      Novinger: ["63559"],
      Pollock: ["63560"],
      "Queen city": ["63561"],
      Rutledge: ["63563"],
      Unionville: ["63565"],
      Winigan: ["63566"],
      Worthington: ["63567"],
      "Zcta 635hh": ["635HH"],
      Desloge: ["63601", "63624"],
      Annapolis: ["63620"],
      Arcadia: ["63621"],
      Belgrade: ["63622"],
      Belleview: ["63623"],
      Black: ["63625"],
      Blackwell: ["63626"],
      Bloomsdale: ["63627"],
      "Bonne terre": ["63628"],
      Bunker: ["63629"],
      Cadet: ["63630"],
      Caledonia: ["63631"],
      Centerville: ["63633"],
      "Des arc": ["63636"],
      "Doe run": ["63637"],
      Ellington: ["63638"],
      Farmington: ["63640"],
      Millcreek: ["63645"],
      Irondale: ["63648"],
      "Iron mountain": ["63650"],
      Leadwood: ["63653"],
      Lesterville: ["63654"],
      Marquand: ["63655"],
      "Middle brook": ["63656"],
      "Mineral point": ["63660"],
      Patton: ["63662"],
      "Pilot knob": ["63663"],
      Potosi: ["63664"],
      Redford: ["63665"],
      "Lake forest esta": ["63670"],
      "Saint mary": ["63673"],
      Tiff: ["63674"],
      Vulcan: ["63675"],
      "Zcta 636hh": ["636HH"],
      "Cape girardeau": ["63701"],
      "Zcta 63703": ["63703"],
      Advance: ["63730"],
      "New wells": ["63732"],
      "Bell city": ["63735"],
      Benton: ["63736"],
      Brownwood: ["63738"],
      Burfordville: ["63739"],
      Chaffee: ["63740"],
      Commerce: ["63742"],
      Daisy: ["63743"],
      Friedheim: ["63747"],
      Frohna: ["63748"],
      Gipsy: ["63750"],
      Glenallen: ["63751"],
      Grassy: ["63753"],
      Jackson: ["63755"],
      Kelso: ["63758"],
      Leopold: ["63760"],
      "Mc gee": ["63763"],
      Scopus: ["63764"],
      Millersville: ["63766"],
      Morley: ["63767"],
      "Oak ridge": ["63769"],
      "Old appleton": ["63770"],
      Oran: ["63771"],
      Perkins: ["63774"],
      Perryville: ["63775"],
      "Scott city": ["63780"],
      Sedgewickville: ["63781"],
      Sturdivant: ["63782"],
      Uniontown: ["63783"],
      Vanduser: ["63784"],
      Whitewater: ["63785"],
      Zalma: ["63787"],
      "Zcta 637hh": ["637HH"],
      Sikeston: ["63801"],
      Anniston: ["63820"],
      Arbyrd: ["63821"],
      Bernie: ["63822"],
      Bertrand: ["63823"],
      Blodgett: ["63824"],
      Bloomfield: ["63825"],
      Braggadocio: ["63826"],
      "Bragg city": ["63827"],
      Canalou: ["63828"],
      Cardwell: ["63829"],
      Caruthersville: ["63830"],
      Catron: ["63833"],
      Charleston: ["63834"],
      Clarkton: ["63837"],
      Cooter: ["63839"],
      Dexter: ["63841"],
      "East prairie": ["63845"],
      Essex: ["63846"],
      Gibson: ["63847"],
      Gideon: ["63848"],
      Gobler: ["63849"],
      Grayridge: ["63850"],
      "Hayti heights": ["63851"],
      Holcomb: ["63852"],
      Holland: ["63853"],
      Hornersville: ["63855"],
      Kennett: ["63857"],
      Kewanee: ["63860"],
      Lilbourn: ["63862"],
      Malden: ["63863"],
      Marston: ["63866"],
      Matthews: ["63867"],
      Morehouse: ["63868"],
      "New madrid": ["63869"],
      Parma: ["63870"],
      Portageville: ["63873"],
      Risco: ["63874"],
      Senath: ["63876"],
      Steele: ["63877"],
      Tallapoosa: ["63878"],
      Homestown: ["63879"],
      Whiteoak: ["63880"],
      Wyatt: ["63882"],
      "Zcta 638hh": ["638HH"],
      "Poplar bluff": ["63901"],
      Broseley: ["63932"],
      Campbell: ["63933"],
      Clubb: ["63934"],
      Poynor: ["63935"],
      Dudley: ["63936"],
      Ellsinore: ["63937"],
      Fagus: ["63938"],
      Fairdealing: ["63939"],
      Fisk: ["63940"],
      Fremont: ["63941"],
      Gatewood: ["63942"],
      Grandin: ["63943"],
      Greenville: ["63944"],
      Harviell: ["63945"],
      Hiram: ["63947"],
      Lowndes: ["63951"],
      "Mill spring": ["63952"],
      Naylor: ["63953"],
      Neelyville: ["63954"],
      Oxly: ["63955"],
      Patterson: ["63956"],
      Piedmont: ["63957"],
      Puxico: ["63960"],
      Qulin: ["63961"],
      Shook: ["63963"],
      Silva: ["63964"],
      "Van buren": ["63965"],
      Wappapello: ["63966"],
      Williamsville: ["63967"],
      "Zcta 639hh": ["639HH"],
      Alma: ["64001"],
      "Bates city": ["64011"],
      Belton: ["64012"],
      "Blue springs": ["64014"],
      "Lake tapawingo": ["64015"],
      Buckner: ["64016"],
      Camden: ["64017"],
      "Camden point": ["64018"],
      Centerview: ["64019"],
      Concordia: ["64020"],
      Corder: ["64021"],
      Dover: ["64022"],
      "Excelsior spring": ["64024"],
      "Grain valley": ["64029"],
      Grandview: ["64030"],
      "Lake winnebago": ["64034"],
      Hardin: ["64035"],
      Henrietta: ["64036"],
      Higginsville: ["64037"],
      Holden: ["64040"],
      Holt: ["64048"],
      Independence: [
        "64050",
        "64052",
        "64053",
        "64055",
        "64056",
        "64057",
        "64058",
      ],
      "Sugar creek": ["64054"],
      Kearney: ["64060"],
      Kingsville: ["64061"],
      Lawson: ["64062"],
      "Lake lotawana": ["64063"],
      "Lees summit": ["64064", "64081", "64082", "64086"],
      Levasy: ["64066"],
      Lexington: ["64067"],
      "Pleasant valley": ["64068"],
      "Lone jack": ["64070"],
      Mayview: ["64071"],
      "Missouri city": ["64072"],
      Napoleon: ["64074"],
      "Oak grove": ["64075"],
      Odessa: ["64076"],
      Orrick: ["64077"],
      Peculiar: ["64078"],
      "Platte city": ["64079"],
      "Pleasant hill": ["64080"],
      Raymore: ["64083"],
      Rayville: ["64084"],
      Richmond: ["64085"],
      Sibley: ["64088"],
      Smithville: ["64089"],
      Strasburg: ["64090"],
      Warrensburg: ["64093"],
      Waverly: ["64096"],
      Wellington: ["64097"],
      Weston: ["64098"],
      "Zcta 640hh": ["640HH"],
      "Kansas city": [
        "64101",
        "64102",
        "64105",
        "64106",
        "64108",
        "64109",
        "64110",
        "64111",
        "64112",
        "64113",
        "64114",
        "64119",
        "64120",
        "64123",
        "64124",
        "64125",
        "64126",
        "64127",
        "64128",
        "64129",
        "64130",
        "64131",
        "64132",
        "64134",
        "64136",
        "64137",
        "64139",
        "64145",
        "64146",
        "64149",
        "64150",
        "64153",
        "64154",
        "64155",
        "64156",
        "64157",
        "64158",
        "64164",
        "64165",
        "64166",
        "64167",
        "64192",
      ],
      "North kansas cit": ["64116"],
      Randolph: ["64117", "64161"],
      Gladstone: ["64118"],
      Raytown: ["64133", "64138"],
      "Martin city": ["64147"],
      "Lake waukomis": ["64151"],
      Parkville: ["64152"],
      Ferrelview: ["64163"],
      "Zcta 641hh": ["641HH"],
      Agency: ["64401"],
      Albany: ["64402"],
      Amazonia: ["64421"],
      Amity: ["64422"],
      Barnard: ["64423"],
      Bethany: ["64424"],
      Blythedale: ["64426"],
      Bolckow: ["64427"],
      "Burlington junct": ["64428"],
      Cameron: ["64429"],
      Clarksdale: ["64430"],
      Clearmont: ["64431"],
      Clyde: ["64432"],
      Conception: ["64433"],
      "Conception junct": ["64434"],
      Cosby: ["64436"],
      Bigelow: ["64437"],
      Darlington: ["64438"],
      Dearborn: ["64439"],
      "De kalb": ["64440"],
      Denver: ["64441"],
      Eagleville: ["64442"],
      Easton: ["64443"],
      Edgerton: ["64444"],
      Elmo: ["64445"],
      Fairfax: ["64446"],
      Faucett: ["64448"],
      Fillmore: ["64449"],
      "Forest city": ["64451"],
      Gentry: ["64453"],
      Gower: ["64454"],
      Graham: ["64455"],
      "Grant city": ["64456"],
      Guilford: ["64457"],
      Hatfield: ["64458"],
      Helena: ["64459"],
      Hopkins: ["64461"],
      "King city": ["64463"],
      Lathrop: ["64465"],
      Maitland: ["64466"],
      Martinsville: ["64467"],
      Maryville: ["64468"],
      Maysville: ["64469"],
      "Mound city": ["64470"],
      "New hampton": ["64471"],
      Oregon: ["64473"],
      Osborn: ["64474"],
      Parnell: ["64475"],
      Pickering: ["64476"],
      Plattsburg: ["64477"],
      Ravenwood: ["64479"],
      Rea: ["64480"],
      Ridgeway: ["64481"],
      "Rock port": ["64482"],
      Rosendale: ["64483"],
      Rushville: ["64484"],
      Savannah: ["64485"],
      Sheridan: ["64486"],
      Skidmore: ["64487"],
      Stanberry: ["64489"],
      Hemple: ["64490"],
      Tarkio: ["64491"],
      Trimble: ["64492"],
      Turney: ["64493"],
      "Union star": ["64494"],
      Watson: ["64496"],
      Weatherby: ["64497"],
      Westboro: ["64498"],
      Worth: ["64499"],
      "Zcta 644hh": ["644HH"],
      "Saint joseph": ["64501", "64503", "64504", "64505", "64506", "64507"],
      "Zcta 645hh": ["645HH"],
      Chillicothe: ["64601"],
      Altamont: ["64620"],
      Bogard: ["64622"],
      Bosworth: ["64623"],
      Braymer: ["64624"],
      Breckenridge: ["64625"],
      Brookfield: ["64628"],
      Browning: ["64630"],
      Bucklin: ["64631"],
      Cainsville: ["64632"],
      Carrollton: ["64633"],
      Chula: ["64635"],
      Coffey: ["64636"],
      Cowgill: ["64637"],
      Dawn: ["64638"],
      "De witt": ["64639"],
      Gallatin: ["64640"],
      Galt: ["64641"],
      "Gilman city": ["64642"],
      Hale: ["64643"],
      Hamilton: ["64644"],
      Harris: ["64645"],
      Humphreys: ["64646"],
      Jameson: ["64647"],
      Jamesport: ["64648"],
      Kidder: ["64649"],
      Kingston: ["64650"],
      Laclede: ["64651"],
      Laredo: ["64652"],
      Linneus: ["64653"],
      Lucerne: ["64655"],
      Ludlow: ["64656"],
      "Mc fall": ["64657"],
      Marceline: ["64658"],
      Meadville: ["64659"],
      Mendon: ["64660"],
      Mercer: ["64661"],
      Mooresville: ["64664"],
      Newtown: ["64667"],
      Norborne: ["64668"],
      Pattonsburg: ["64670"],
      Polo: ["64671"],
      Powersville: ["64672"],
      Princeton: ["64673"],
      Purdin: ["64674"],
      Rothville: ["64676"],
      Spickard: ["64679"],
      Sumner: ["64681"],
      Tina: ["64682"],
      Trenton: ["64683"],
      Utica: ["64686"],
      Wheeling: ["64688"],
      Winston: ["64689"],
      "Zcta 646hh": ["646HH"],
      Harrisonville: ["64701"],
      Adrian: ["64720"],
      Amoret: ["64722"],
      Amsterdam: ["64723"],
      "Appleton city": ["64724"],
      Archie: ["64725"],
      Blairstown: ["64726"],
      Bronaugh: ["64728"],
      Butler: ["64730"],
      Chilhowee: ["64733"],
      Cleveland: ["64734"],
      Tightwad: ["64735"],
      Collins: ["64738"],
      Creighton: ["64739"],
      Deepwater: ["64740"],
      Deerfield: ["64741"],
      Drexel: ["64742"],
      "East lynne": ["64743"],
      "El dorado spring": ["64744"],
      Foster: ["64745"],
      Freeman: ["64746"],
      "Garden city": ["64747"],
      "Golden city": ["64748"],
      Harwood: ["64750"],
      Horton: ["64751"],
      Stotesbury: ["64752"],
      Jasper: ["64755"],
      "Jerico springs": ["64756"],
      Iantha: ["64759"],
      Leeton: ["64761"],
      Liberal: ["64762"],
      "Lowry city": ["64763"],
      Milo: ["64767"],
      Mindenmines: ["64769"],
      Montrose: ["64770"],
      Moundville: ["64771"],
      Nevada: ["64772"],
      Osceola: ["64776"],
      Richards: ["64778"],
      "Rich hill": ["64779"],
      Rockville: ["64780"],
      Roscoe: ["64781"],
      "Schell city": ["64783"],
      Sheldon: ["64784"],
      Urich: ["64788"],
      Walker: ["64790"],
      "Zcta 647hh": ["647HH"],
      Joplin: ["64801", "64804"],
      Alba: ["64830"],
      Anderson: ["64831"],
      Asbury: ["64832"],
      Avilla: ["64833"],
      "Carl junction": ["64834"],
      Carterville: ["64835"],
      Carthage: ["64836"],
      Diamond: ["64840"],
      Duenweg: ["64841"],
      Fairview: ["64842"],
      Goodman: ["64843"],
      Granby: ["64844"],
      Lanagan: ["64847"],
      "La russell": ["64848"],
      "Neck city": ["64849"],
      Neosho: ["64850"],
      Noel: ["64854"],
      Oronogo: ["64855"],
      Jane: ["64856"],
      Purcell: ["64857"],
      Racine: ["64858"],
      Reeds: ["64859"],
      "Rocky comfort": ["64861"],
      Sarcoxie: ["64862"],
      "South west city": ["64863"],
      Seneca: ["64865"],
      "Stark city": ["64866"],
      Stella: ["64867"],
      "Webb city": ["64870"],
      Wentworth: ["64873"],
      Wheaton: ["64874"],
      Argyle: ["65001"],
      Ashland: ["65010"],
      Barnett: ["65011"],
      Belle: ["65013"],
      Bland: ["65014"],
      "Bonnots mill": ["65016"],
      Brumley: ["65017"],
      California: ["65018"],
      Camdenton: ["65020"],
      Centertown: ["65023"],
      Chamois: ["65024"],
      Clarksburg: ["65025"],
      Eldon: ["65026"],
      Eugene: ["65032"],
      Fortuna: ["65034"],
      Freeburg: ["65035"],
      Gasconade: ["65036"],
      "Gravois mills": ["65037"],
      Hartsburg: ["65039"],
      Henley: ["65040"],
      Bay: ["65041"],
      "Holts summit": ["65043"],
      Jamestown: ["65046"],
      Kaiser: ["65047"],
      Koeltztown: ["65048"],
      "Four seasons": ["65049"],
      Latham: ["65050"],
      Linn: ["65051"],
      "Linn creek": ["65052"],
      Lohman: ["65053"],
      "Loose creek": ["65054"],
      Meta: ["65058"],
      Mokane: ["65059"],
      Morrison: ["65061"],
      "Mount sterling": ["65062"],
      "New bloomfield": ["65063"],
      Olean: ["65064"],
      "Osage beach": ["65065"],
      Owensville: ["65066"],
      Portland: ["65067"],
      "Prairie home": ["65068"],
      Rhineland: ["65069"],
      "Rocky mount": ["65072"],
      Russellville: ["65074"],
      "Saint elizabeth": ["65075"],
      "Saint thomas": ["65076"],
      Steedman: ["65077"],
      Stover: ["65078"],
      "Sunrise beach": ["65079"],
      Tebbetts: ["65080"],
      Tipton: ["65081"],
      Tuscumbia: ["65082"],
      Ulman: ["65083"],
      Versailles: ["65084"],
      Westphalia: ["65085"],
      "Zcta 650hh": ["650HH"],
      "Jefferson city": ["65101", "65109"],
      "Zcta 651hh": ["651HH"],
      Columbia: ["65201", "65202", "65203"],
      Armstrong: ["65230"],
      Auxvasse: ["65231"],
      "Benton city": ["65232"],
      Boonville: ["65233"],
      Brunswick: ["65236"],
      Bunceton: ["65237"],
      Cairo: ["65239"],
      Centralia: ["65240"],
      Clark: ["65243"],
      "Clifton hill": ["65244"],
      Dalton: ["65246"],
      Excello: ["65247"],
      Fayette: ["65248"],
      Franklin: ["65250"],
      Fulton: ["65251"],
      Glasgow: ["65254"],
      Hallsville: ["65255"],
      Harrisburg: ["65256"],
      Higbee: ["65257"],
      Holliday: ["65258"],
      Huntsville: ["65259"],
      Jacksonville: ["65260"],
      Keytesville: ["65261"],
      "Kingdom city": ["65262"],
      Madison: ["65263"],
      Martinsburg: ["65264"],
      Mexico: ["65265"],
      Moberly: ["65270"],
      "New franklin": ["65274"],
      Paris: ["65275"],
      "Pilot grove": ["65276"],
      Renick: ["65278"],
      Rocheport: ["65279"],
      "Rush hill": ["65280"],
      Salisbury: ["65281"],
      "Santa fe": ["65282"],
      Stoutsville: ["65283"],
      Sturgeon: ["65284"],
      Thompson: ["65285"],
      Triplett: ["65286"],
      Wooldridge: ["65287"],
      "Zcta 652hh": ["652HH"],
      Sedalia: ["65301"],
      "Whiteman afb": ["65305"],
      "Arrow rock": ["65320"],
      Blackburn: ["65321"],
      Blackwater: ["65322"],
      Calhoun: ["65323"],
      "Climax springs": ["65324"],
      "Cole camp": ["65325"],
      Edwards: ["65326"],
      Emma: ["65327"],
      Florence: ["65329"],
      Gilliam: ["65330"],
      "Green ridge": ["65332"],
      Houstonia: ["65333"],
      Hughesville: ["65334"],
      Ionia: ["65335"],
      "Knob noster": ["65336"],
      "La monte": ["65337"],
      Lincoln: ["65338"],
      "Grand pass": ["65339"],
      Napton: ["65340"],
      Miami: ["65344"],
      Mora: ["65345"],
      Nelson: ["65347"],
      Otterville: ["65348"],
      Slater: ["65349"],
      Smithton: ["65350"],
      "Sweet springs": ["65351"],
      Syracuse: ["65354"],
      Warsaw: ["65355"],
      Windsor: ["65360"],
      "Zcta 653hh": ["653HH"],
      Rolla: ["65401"],
      Beulah: ["65436"],
      "Birch tree": ["65438"],
      Bixby: ["65439"],
      Boss: ["65440"],
      Bourbon: ["65441"],
      Brinktown: ["65443"],
      Bucyrus: ["65444"],
      Cherryville: ["65446"],
      "Cook station": ["65449"],
      Crocker: ["65452"],
      Cuba: ["65453"],
      Davisville: ["65456"],
      "Devils elbow": ["65457"],
      Dixon: ["65459"],
      Duke: ["65461"],
      "Edgar springs": ["65462"],
      Eldridge: ["65463"],
      "Elk creek": ["65464"],
      Eminence: ["65466"],
      Eunice: ["65468"],
      Falcon: ["65470"],
      "Fort leonard woo": ["65473"],
      Hartshorn: ["65479"],
      Houston: ["65483"],
      Huggins: ["65484"],
      Iberia: ["65486"],
      "Zcta 654hh": ["654HH"],
      Jadwin: ["65501"],
      Jerome: ["65529"],
      "Lake spring": ["65532"],
      Laquey: ["65534"],
      Leasburg: ["65535"],
      Lebanon: ["65536"],
      Anutt: ["65540"],
      Lenox: ["65541"],
      Licking: ["65542"],
      Lynchburg: ["65543"],
      "Mountain view": ["65548"],
      Newburg: ["65550"],
      Plato: ["65552"],
      Raymondville: ["65555"],
      Richland: ["65556"],
      Roby: ["65557"],
      "Saint james": ["65559"],
      Salem: ["65560"],
      Solo: ["65564"],
      Berryman: ["65565"],
      Viburnum: ["65566"],
      Stoutland: ["65567"],
      Success: ["65570"],
      Summersville: ["65571"],
      Vichy: ["65580"],
      Vienna: ["65582"],
      "Saint robert": ["65583"],
      Winona: ["65588"],
      Yukon: ["65589"],
      "Long lane": ["65590"],
      Montreal: ["65591"],
      "Zcta 655hh": ["655HH"],
      Aldrich: ["65601"],
      Arcola: ["65603"],
      "Ash grove": ["65604"],
      Jenkins: ["65605"],
      Riverton: ["65606"],
      Ava: ["65608"],
      Bakersfield: ["65609"],
      Billings: ["65610"],
      "Blue eye": ["65611"],
      "Bois d arc": ["65612"],
      Bolivar: ["65613"],
      Bradleyville: ["65614"],
      "Marvel cave park": ["65616"],
      Brighton: ["65617"],
      Brixey: ["65618"],
      "Brookline statio": ["65619"],
      Bruner: ["65620"],
      Buffalo: ["65622"],
      Butterfield: ["65623"],
      "Cape fair": ["65624"],
      Cassville: ["65625"],
      Caulfield: ["65626"],
      Cedarcreek: ["65627"],
      Chadwick: ["65629"],
      Chestnutridge: ["65630"],
      Clever: ["65631"],
      Conway: ["65632"],
      Crane: ["65633"],
      "Cross timbers": ["65634"],
      Dadeville: ["65635"],
      Dora: ["65637"],
      Drury: ["65638"],
      Dunnegan: ["65640"],
      "Eagle rock": ["65641"],
      Elkland: ["65644"],
      Everton: ["65646"],
      Exeter: ["65647"],
      "Fair grove": ["65648"],
      "Fair play": ["65649"],
      Flemington: ["65650"],
      Fordland: ["65652"],
      Forsyth: ["65653"],
      Gainesville: ["65655"],
      Galena: ["65656"],
      Garrison: ["65657"],
      Golden: ["65658"],
      Goodson: ["65659"],
      Graff: ["65660"],
      Greenfield: ["65661"],
      Grovespring: ["65662"],
      "Half way": ["65663"],
      Halltown: ["65664"],
      Hartville: ["65667"],
      Hermitage: ["65668"],
      Highlandville: ["65669"],
      Hollister: ["65672"],
      Humansville: ["65674"],
      Hurley: ["65675"],
      Isabella: ["65676"],
      Kirbyville: ["65679"],
      "Kissee mills": ["65680"],
      Lampe: ["65681"],
      Lockwood: ["65682"],
      Louisburg: ["65685"],
      "Kimberling city": ["65686"],
      Cabool: ["65689"],
      Couch: ["65690"],
      Koshkonong: ["65692"],
      "Zcta 656hh": ["656HH"],
      "Mc clurg": ["65701"],
      Macomb: ["65702"],
      Mansfield: ["65704"],
      Marionville: ["65705"],
      Marshfield: ["65706"],
      Miller: ["65707"],
      Monett: ["65708"],
      Morrisville: ["65710"],
      "Mountain grove": ["65711"],
      "Mount vernon": ["65712"],
      Niangua: ["65713"],
      Nixa: ["65714"],
      Noble: ["65715"],
      Norwood: ["65717"],
      Oldfield: ["65720"],
      Ozark: ["65721"],
      Phillipsburg: ["65722"],
      "Pierce city": ["65723"],
      Pittsburg: ["65724"],
      "Pleasant hope": ["65725"],
      Polk: ["65727"],
      "Ponce de leon": ["65728"],
      Pontiac: ["65729"],
      Powell: ["65730"],
      Powersite: ["65731"],
      Preston: ["65732"],
      Protem: ["65733"],
      Purdy: ["65734"],
      Quincy: ["65735"],
      "Branson west": ["65737"],
      Republic: ["65738"],
      Ridgedale: ["65739"],
      "Rockaway beach": ["65740"],
      Rogersville: ["65742"],
      Rueter: ["65744"],
      Seligman: ["65745"],
      Seymour: ["65746"],
      "Shell knob": ["65747"],
      "South greenfield": ["65752"],
      Sparta: ["65753"],
      Spokane: ["65754"],
      Squires: ["65755"],
      "Stotts city": ["65756"],
      Strafford: ["65757"],
      Taneyville: ["65759"],
      Tecumseh: ["65760"],
      Dugginsville: ["65761"],
      Nottinghill: ["65762"],
      Tunas: ["65764"],
      Udall: ["65766"],
      Urbana: ["65767"],
      Vanzant: ["65768"],
      Verona: ["65769"],
      "Walnut grove": ["65770"],
      "Walnut shade": ["65771"],
      Washburn: ["65772"],
      Souder: ["65773"],
      Weaubleau: ["65774"],
      "West plains": ["65775"],
      Moody: ["65777"],
      Myrtle: ["65778"],
      Wheatland: ["65779"],
      Willard: ["65781"],
      Windyville: ["65783"],
      Zanoni: ["65784"],
      Stockton: ["65785"],
      "Macks creek": ["65786"],
      Roach: ["65787"],
      "Peace valley": ["65788"],
      Pomona: ["65789"],
      Pottersville: ["65790"],
      Thayer: ["65791"],
      "Willow springs": ["65793"],
      "Zcta 657hh": ["657HH"],
      Springfield: [
        "65802",
        "65803",
        "65804",
        "65806",
        "65807",
        "65809",
        "65810",
      ],
      "Zcta 658hh": ["658HH"],
    },
  },
  {
    abbrevation: "MT",
    name: "Montana",
    Cities: [
      "Absarokee",
      "Absarokee",
      "Acton",
      "Ashland",
      "Ballantine",
      "Bearcreek",
      "Belfry",
      "Bighorn",
      "Big timber",
      "Birney",
      "Boyd",
      "Bridger",
      "Broadview",
      "Busby",
      "Clyde park",
      "Columbus",
      "Cooke city",
      "Crow agency",
      "Custer",
      "Decker",
      "Edgar",
      "Emigrant",
      "Fishtail",
      "Fromberg",
      "Gardiner",
      "Garryowen",
      "Grass range",
      "Greycliff",
      "Hardin",
      "Yellowtail",
      "Harlowton",
      "Huntley",
      "Hysham",
      "Ingomar",
      "Silesia",
      "Lame deer",
      "Laurel",
      "Lavina",
      "Livingston",
      "Lodge grass",
      "Mc leod",
      "Martinsdale",
      "Melstone",
      "Melville",
      "Molt",
      "Mosby",
      "Musselshell",
      "Nye",
      "Otter",
      "Park city",
      "Pompeys pillar",
      "Pray",
      "Pryor",
      "Rapelje",
      "Red lodge",
      "Reedpoint",
      "Roberts",
      "Roscoe",
      "Roundup",
      "Ryegate",
      "Saint xavier",
      "Sanders",
      "Sand springs",
      "Shawmut",
      "Shepherd",
      "Silver gate",
      "Twodot",
      "Wilsall",
      "Winnett",
      "Worden",
      "Wyola",
      "Zcta 590hh",
      "Zcta 590xx",
      "Billings",
      "Billings heights",
      "Wolf point",
      "Antelope",
      "Bainville",
      "Brockton",
      "Brockway",
      "Circle",
      "Crane",
      "Culbertson",
      "Dagmar",
      "Fairview",
      "Flaxville",
      "Fort peck",
      "Lustre",
      "Froid",
      "Glasgow",
      "Saint marie",
      "Hinsdale",
      "Homestead",
      "Lambert",
      "Larslan",
      "Medicine lake",
      "Nashua",
      "Opheim",
      "Outlook",
      "Peerless",
      "Plentywood",
      "Poplar",
      "Redstone",
      "Reserve",
      "Richey",
      "Richland",
      "Saco",
      "Savage",
      "Scobey",
      "Sidney",
      "Vida",
      "Westby",
      "Whitetail",
      "Zcta 592hh",
      "Miles city",
      "Alzada",
      "Baker",
      "Biddle",
      "Bloomfield",
      "Belle creek",
      "Brusett",
      "Cohagen",
      "Colstrip",
      "Ekalaka",
      "Fallon",
      "Forsyth",
      "Glendive",
      "Hammond",
      "Hathaway",
      "Ismay",
      "Jordan",
      "Kinsey",
      "Lindsay",
      "Olive",
      "Plevna",
      "Rosebud",
      "Sonnette",
      "Terry",
      "Volborg",
      "Wibaux",
      "Willard",
      "Zcta 593hh",
      "Zcta 593xx",
      "Great falls",
      "Augusta",
      "Babb",
      "Belt",
      "Black eagle",
      "Brady",
      "Saint mary",
      "Bynum",
      "Carter",
      "Cascade",
      "Choteau",
      "Coffee creek",
      "Conrad",
      "Cut bank",
      "Denton",
      "Dupuyer",
      "Dutton",
      "East glacier par",
      "Fairfield",
      "Floweree",
      "Forestgrove",
      "Fort benton",
      "Fort shaw",
      "Galata",
      "Geraldine",
      "Geyser",
      "Heart butte",
      "Highwood",
      "Hilger",
      "Utica",
      "Judith gap",
      "Kevin",
      "Ledger",
      "Lewistown",
      "Loma",
      "Moccasin",
      "Monarch",
      "Moore",
      "Neihart",
      "Oilmont",
      "Pendroy",
      "Power",
      "Raynesford",
      "Roy",
      "Sand coulee",
      "Shelby",
      "Simms",
      "Stanford",
      "Stockett",
      "Sunburst",
      "Sun river",
      "Sweetgrass",
      "Ulm",
      "Valier",
      "Vaughn",
      "Zcta 594hh",
      "Zcta 594xx",
      "Havre",
      "Big sandy",
      "Box elder",
      "Chester",
      "Chinook",
      "Dodson",
      "Gildford",
      "Harlem",
      "Hays",
      "Hingham",
      "Hogeland",
      "Inverness",
      "Joplin",
      "Kremlin",
      "Lloyd",
      "Loring",
      "Malta",
      "Rudyard",
      "Turner",
      "Whitewater",
      "Whitlash",
      "Zortman",
      "Zurich",
      "Zcta 595hh",
      "Helena",
      "Basin",
      "Boulder",
      "Canyon creek",
      "Montana city",
      "East helena",
      "Fort harrison",
      "Jefferson city",
      "Lincoln",
      "Marysville",
      "Ringling",
      "Toston",
      "Townsend",
      "White sulphur sp",
      "Wolf creek",
      "Zcta 596hh",
      "Zcta 596xx",
      "Walkerville",
      "Alder",
      "Anaconda",
      "Avon",
      "Belgrade",
      "Bozeman",
      "Big sky",
      "Zcta 59718",
      "Cameron",
      "Cardwell",
      "Deer lodge",
      "Dillon",
      "Divide",
      "Elliston",
      "Ennis",
      "Gallatin gateway",
      "Garrison",
      "Gold creek",
      "Harrison",
      "Jackson",
      "Lima",
      "Mc allister",
      "Manhattan",
      "Melrose",
      "Norris",
      "Polaris",
      "Pony",
      "Ramsay",
      "Sheridan",
      "Butte",
      "Silver star",
      "Three forks",
      "Twin bridges",
      "Virginia city",
      "Warmsprings",
      "West yellowstone",
      "Whitehall",
      "Wisdom",
      "Wise river",
      "Zcta 597hh",
      "Zcta 597xx",
      "Missoula",
      "Zcta 59808",
      "Alberton",
      "Arlee",
      "Bonner",
      "Moiese",
      "Clinton",
      "Condon",
      "Conner",
      "Corvallis",
      "Darby",
      "Dixon",
      "Drummond",
      "Florence",
      "Frenchtown",
      "Greenough",
      "Hall",
      "Hamilton",
      "Pinesdale",
      "Haugan",
      "Helmville",
      "Heron",
      "Hot springs",
      "Huson",
      "Lolo",
      "Lonepine",
      "Milltown",
      "Noxon",
      "Ovando",
      "Pablo",
      "Philipsburg",
      "Plains",
      "Polson",
      "Ronan",
      "Saint ignatius",
      "Saint regis",
      "Saltese",
      "Seeley lake",
      "Stevensville",
      "Sula",
      "Superior",
      "Thompson falls",
      "Trout creek",
      "Victor",
      "Zcta 598hh",
      "Zcta 598xx",
      "Evergreen",
      "Big arm",
      "Swan lake",
      "Columbia falls",
      "Coram",
      "Dayton",
      "Elmo",
      "Essex",
      "Eureka",
      "Fortine",
      "Hungry horse",
      "Kila",
      "Lake mc donald",
      "Lakeside",
      "Libby",
      "Marion",
      "Martin city",
      "Olney",
      "Polebridge",
      "Proctor",
      "Rexford",
      "Rollins",
      "Somers",
      "Stryker",
      "Trego",
      "Troy",
      "West glacier",
      "Whitefish",
      "Zcta 599hh",
    ],
    ZipCodes: {
      Absarokee: ["59001"],
      Acton: ["59002"],
      Ashland: ["59003"],
      Ballantine: ["59006"],
      Bearcreek: ["59007"],
      Belfry: ["59008"],
      Bighorn: ["59010"],
      "Big timber": ["59011"],
      Birney: ["59012"],
      Boyd: ["59013"],
      Bridger: ["59014"],
      Broadview: ["59015"],
      Busby: ["59016"],
      "Clyde park": ["59018"],
      Columbus: ["59019"],
      "Cooke city": ["59020"],
      "Crow agency": ["59022"],
      Custer: ["59024"],
      Decker: ["59025"],
      Edgar: ["59026"],
      Emigrant: ["59027"],
      Fishtail: ["59028"],
      Fromberg: ["59029"],
      Gardiner: ["59030"],
      Garryowen: ["59031"],
      "Grass range": ["59032"],
      Greycliff: ["59033"],
      Hardin: ["59034"],
      Yellowtail: ["59035"],
      Harlowton: ["59036"],
      Huntley: ["59037"],
      Hysham: ["59038"],
      Ingomar: ["59039"],
      Silesia: ["59041"],
      "Lame deer": ["59043"],
      Laurel: ["59044"],
      Lavina: ["59046"],
      Livingston: ["59047"],
      "Lodge grass": ["59050"],
      "Mc leod": ["59052"],
      Martinsdale: ["59053"],
      Melstone: ["59054"],
      Melville: ["59055"],
      Molt: ["59057"],
      Mosby: ["59058"],
      Musselshell: ["59059"],
      Nye: ["59061"],
      Otter: ["59062"],
      "Park city": ["59063"],
      "Pompeys pillar": ["59064"],
      Pray: ["59065"],
      Pryor: ["59066"],
      Rapelje: ["59067"],
      "Red lodge": ["59068"],
      Reedpoint: ["59069"],
      Roberts: ["59070"],
      Roscoe: ["59071"],
      Roundup: ["59072"],
      Ryegate: ["59074"],
      "Saint xavier": ["59075"],
      Sanders: ["59076"],
      "Sand springs": ["59077"],
      Shawmut: ["59078"],
      Shepherd: ["59079"],
      "Silver gate": ["59081"],
      Twodot: ["59085"],
      Wilsall: ["59086"],
      Winnett: ["59087"],
      Worden: ["59088"],
      Wyola: ["59089"],
      "Zcta 590hh": ["590HH"],
      "Zcta 590xx": ["590XX"],
      Billings: ["59101", "59102", "59106"],
      "Billings heights": ["59105"],
      "Wolf point": ["59201"],
      Antelope: ["59211"],
      Bainville: ["59212"],
      Brockton: ["59213"],
      Brockway: ["59214"],
      Circle: ["59215"],
      Crane: ["59217"],
      Culbertson: ["59218"],
      Dagmar: ["59219"],
      Fairview: ["59221"],
      Flaxville: ["59222"],
      "Fort peck": ["59223"],
      Lustre: ["59225"],
      Froid: ["59226"],
      Glasgow: ["59230"],
      "Saint marie": ["59231"],
      Hinsdale: ["59241"],
      Homestead: ["59242"],
      Lambert: ["59243"],
      Larslan: ["59244"],
      "Medicine lake": ["59247"],
      Nashua: ["59248"],
      Opheim: ["59250"],
      Outlook: ["59252"],
      Peerless: ["59253"],
      Plentywood: ["59254"],
      Poplar: ["59255"],
      Redstone: ["59257"],
      Reserve: ["59258"],
      Richey: ["59259"],
      Richland: ["59260"],
      Saco: ["59261"],
      Savage: ["59262"],
      Scobey: ["59263"],
      Sidney: ["59270"],
      Vida: ["59274"],
      Westby: ["59275"],
      Whitetail: ["59276"],
      "Zcta 592hh": ["592HH"],
      "Miles city": ["59301"],
      Alzada: ["59311"],
      Baker: ["59313"],
      Biddle: ["59314"],
      Bloomfield: ["59315"],
      "Belle creek": ["59317"],
      Brusett: ["59318"],
      Cohagen: ["59322"],
      Colstrip: ["59323"],
      Ekalaka: ["59324"],
      Fallon: ["59326"],
      Forsyth: ["59327"],
      Glendive: ["59330"],
      Hammond: ["59332"],
      Hathaway: ["59333"],
      Ismay: ["59336"],
      Jordan: ["59337"],
      Kinsey: ["59338"],
      Lindsay: ["59339"],
      Olive: ["59343"],
      Plevna: ["59344"],
      Rosebud: ["59347"],
      Sonnette: ["59348"],
      Terry: ["59349"],
      Volborg: ["59351"],
      Wibaux: ["59353"],
      Willard: ["59354"],
      "Zcta 593hh": ["593HH"],
      "Zcta 593xx": ["593XX"],
      "Great falls": ["59401", "59404", "59405"],
      Augusta: ["59410"],
      Babb: ["59411"],
      Belt: ["59412"],
      "Black eagle": ["59414"],
      Brady: ["59416"],
      "Saint mary": ["59417"],
      Bynum: ["59419"],
      Carter: ["59420"],
      Cascade: ["59421"],
      Choteau: ["59422"],
      "Coffee creek": ["59424"],
      Conrad: ["59425"],
      "Cut bank": ["59427"],
      Denton: ["59430"],
      Dupuyer: ["59432"],
      Dutton: ["59433"],
      "East glacier par": ["59434"],
      Fairfield: ["59436"],
      Floweree: ["59440"],
      Forestgrove: ["59441"],
      "Fort benton": ["59442"],
      "Fort shaw": ["59443"],
      Galata: ["59444"],
      Geraldine: ["59446"],
      Geyser: ["59447"],
      "Heart butte": ["59448"],
      Highwood: ["59450"],
      Hilger: ["59451"],
      Utica: ["59452"],
      "Judith gap": ["59453"],
      Kevin: ["59454"],
      Ledger: ["59456"],
      Lewistown: ["59457"],
      Loma: ["59460"],
      Moccasin: ["59462"],
      Monarch: ["59463"],
      Moore: ["59464"],
      Neihart: ["59465"],
      Oilmont: ["59466"],
      Pendroy: ["59467"],
      Power: ["59468"],
      Raynesford: ["59469"],
      Roy: ["59471"],
      "Sand coulee": ["59472"],
      Shelby: ["59474"],
      Simms: ["59477"],
      Stanford: ["59479"],
      Stockett: ["59480"],
      Sunburst: ["59482"],
      "Sun river": ["59483"],
      Sweetgrass: ["59484"],
      Ulm: ["59485"],
      Valier: ["59486"],
      Vaughn: ["59487"],
      "Zcta 594hh": ["594HH"],
      "Zcta 594xx": ["594XX"],
      Havre: ["59501"],
      "Big sandy": ["59520"],
      "Box elder": ["59521"],
      Chester: ["59522"],
      Chinook: ["59523"],
      Dodson: ["59524"],
      Gildford: ["59525"],
      Harlem: ["59526"],
      Hays: ["59527"],
      Hingham: ["59528"],
      Hogeland: ["59529"],
      Inverness: ["59530"],
      Joplin: ["59531"],
      Kremlin: ["59532"],
      Lloyd: ["59535"],
      Loring: ["59537"],
      Malta: ["59538"],
      Rudyard: ["59540"],
      Turner: ["59542"],
      Whitewater: ["59544"],
      Whitlash: ["59545"],
      Zortman: ["59546"],
      Zurich: ["59547"],
      "Zcta 595hh": ["595HH"],
      Helena: ["59601", "59602"],
      Basin: ["59631"],
      Boulder: ["59632"],
      "Canyon creek": ["59633"],
      "Montana city": ["59634"],
      "East helena": ["59635"],
      "Fort harrison": ["59636"],
      "Jefferson city": ["59638"],
      Lincoln: ["59639"],
      Marysville: ["59640"],
      Ringling: ["59642"],
      Toston: ["59643"],
      Townsend: ["59644"],
      "White sulphur sp": ["59645"],
      "Wolf creek": ["59648"],
      "Zcta 596hh": ["596HH"],
      "Zcta 596xx": ["596XX"],
      Walkerville: ["59701"],
      Alder: ["59710"],
      Anaconda: ["59711"],
      Avon: ["59713"],
      Belgrade: ["59714"],
      Bozeman: ["59715"],
      "Big sky": ["59716"],
      "Zcta 59718": ["59718"],
      Cameron: ["59720"],
      Cardwell: ["59721"],
      "Deer lodge": ["59722"],
      Dillon: ["59725"],
      Divide: ["59727"],
      Elliston: ["59728"],
      Ennis: ["59729"],
      "Gallatin gateway": ["59730"],
      Garrison: ["59731"],
      "Gold creek": ["59733"],
      Harrison: ["59735"],
      Jackson: ["59736"],
      Lima: ["59739"],
      "Mc allister": ["59740"],
      Manhattan: ["59741"],
      Melrose: ["59743"],
      Norris: ["59745"],
      Polaris: ["59746"],
      Pony: ["59747"],
      Ramsay: ["59748"],
      Sheridan: ["59749"],
      Butte: ["59750"],
      "Silver star": ["59751"],
      "Three forks": ["59752"],
      "Twin bridges": ["59754"],
      "Virginia city": ["59755"],
      Warmsprings: ["59756"],
      "West yellowstone": ["59758"],
      Whitehall: ["59759"],
      Wisdom: ["59761"],
      "Wise river": ["59762"],
      "Zcta 597hh": ["597HH"],
      "Zcta 597xx": ["597XX"],
      Missoula: ["59801", "59802", "59803", "59804"],
      "Zcta 59808": ["59808"],
      Alberton: ["59820"],
      Arlee: ["59821"],
      Bonner: ["59823"],
      Moiese: ["59824"],
      Clinton: ["59825"],
      Condon: ["59826"],
      Conner: ["59827"],
      Corvallis: ["59828"],
      Darby: ["59829"],
      Dixon: ["59831"],
      Drummond: ["59832"],
      Florence: ["59833"],
      Frenchtown: ["59834"],
      Greenough: ["59836"],
      Hall: ["59837"],
      Hamilton: ["59840"],
      Pinesdale: ["59841"],
      Haugan: ["59842"],
      Helmville: ["59843"],
      Heron: ["59844"],
      "Hot springs": ["59845"],
      Huson: ["59846"],
      Lolo: ["59847"],
      Lonepine: ["59848"],
      Milltown: ["59851"],
      Noxon: ["59853"],
      Ovando: ["59854"],
      Pablo: ["59855"],
      Philipsburg: ["59858"],
      Plains: ["59859"],
      Polson: ["59860"],
      Ronan: ["59864"],
      "Saint ignatius": ["59865"],
      "Saint regis": ["59866"],
      Saltese: ["59867"],
      "Seeley lake": ["59868"],
      Stevensville: ["59870"],
      Sula: ["59871"],
      Superior: ["59872"],
      "Thompson falls": ["59873"],
      "Trout creek": ["59874"],
      Victor: ["59875"],
      "Zcta 598hh": ["598HH"],
      "Zcta 598xx": ["598XX"],
      Evergreen: ["59901"],
      "Big arm": ["59910"],
      "Swan lake": ["59911"],
      "Columbia falls": ["59912"],
      Coram: ["59913"],
      Dayton: ["59914"],
      Elmo: ["59915"],
      Essex: ["59916"],
      Eureka: ["59917"],
      Fortine: ["59918"],
      "Hungry horse": ["59919"],
      Kila: ["59920"],
      "Lake mc donald": ["59921"],
      Lakeside: ["59922"],
      Libby: ["59923"],
      Marion: ["59925"],
      "Martin city": ["59926"],
      Olney: ["59927"],
      Polebridge: ["59928"],
      Proctor: ["59929"],
      Rexford: ["59930"],
      Rollins: ["59931"],
      Somers: ["59932"],
      Stryker: ["59933"],
      Trego: ["59934"],
      Troy: ["59935"],
      "West glacier": ["59936"],
      Whitefish: ["59937"],
      "Zcta 599hh": ["599HH"],
    },
  },
  {
    abbrevation: "NE",
    name: "Nebraska",
    Cities: [
      "Abie",
      "Abie",
      "Arlington",
      "Ashland",
      "Bancroft",
      "Bellevue",
      "Bennington",
      "Blair",
      "Boys town",
      "Bruno",
      "Cedar bluffs",
      "Cedar creek",
      "Ceresco",
      "Colon",
      "Craig",
      "Decatur",
      "Elkhorn",
      "Fort calhoun",
      "Fremont",
      "Gretna",
      "Herman",
      "Homer",
      "Hooper",
      "Ithaca",
      "Kennard",
      "Leshara",
      "Linwood",
      "Louisville",
      "Lyons",
      "Macy",
      "Malmo",
      "Mead",
      "Memphis",
      "Nickerson",
      "Oakland",
      "Papillion",
      "Pender",
      "Plattsmouth",
      "Prague",
      "Richfield",
      "Rosalie",
      "Scribner",
      "South bend",
      "Springfield",
      "Tekamah",
      "Thurston",
      "Uehling",
      "Valley",
      "Valparaiso",
      "Wahoo",
      "Walthill",
      "Washington",
      "Waterloo",
      "Weston",
      "Winnebago",
      "Yutan",
      "Zcta 680hh",
      "Omaha",
      "Offutt a f b",
      "Ralston",
      "Millard",
      "Zcta 681hh",
      "Adams",
      "Alexandria",
      "Alvo",
      "Auburn",
      "Avoca",
      "Barneston",
      "Beatrice",
      "Beaver crossing",
      "Bee",
      "Belvidere",
      "Benedict",
      "Bennet",
      "Blue springs",
      "Bradshaw",
      "Brock",
      "Brownville",
      "Bruning",
      "Burchard",
      "Burr",
      "Byron",
      "Carleton",
      "Chester",
      "Clatonia",
      "Cook",
      "Cordova",
      "Cortland",
      "Crab orchard",
      "Crete",
      "Davenport",
      "Davey",
      "Dawson",
      "Daykin",
      "Denton",
      "Deshler",
      "De witt",
      "Diller",
      "Dorchester",
      "Douglas",
      "Du bois",
      "Dunbar",
      "Eagle",
      "Elk creek",
      "Elmwood",
      "Endicott",
      "Exeter",
      "Fairbury",
      "Fairmont",
      "Falls city",
      "Filley",
      "Firth",
      "Friend",
      "Garland",
      "Geneva",
      "Gilead",
      "Goehner",
      "Grafton",
      "Greenwood",
      "Gresham",
      "Hallam",
      "Hebron",
      "Henderson",
      "Holland",
      "Holmesville",
      "Hubbell",
      "Humboldt",
      "Jansen",
      "Johnson",
      "Lewiston",
      "Liberty",
      "Zcta 683hh",
      "Mc cool junction",
      "Malcolm",
      "Manley",
      "Martell",
      "Milford",
      "Milligan",
      "Murdock",
      "Murray",
      "Nebraska city",
      "Nehawka",
      "Nemaha",
      "Odell",
      "Ohiowa",
      "Otoe",
      "Palmyra",
      "Panama",
      "Pawnee city",
      "Peru",
      "Pickrell",
      "Pleasant dale",
      "Plymouth",
      "Agnew",
      "Roca",
      "Rulo",
      "Salem",
      "Seward",
      "Shickley",
      "Shubert",
      "Staplehurst",
      "Steele city",
      "Steinauer",
      "Stella",
      "Sterling",
      "Strang",
      "Swanton",
      "Syracuse",
      "Table rock",
      "Talmage",
      "Tecumseh",
      "Ong",
      "Tobias",
      "Unadilla",
      "Union",
      "Utica",
      "Verdon",
      "Virginia",
      "Waco",
      "Walton",
      "Waverly",
      "Weeping water",
      "Western",
      "Wilber",
      "Wymore",
      "York",
      "Zcta 684hh",
      "Lincoln",
      "Richland",
      "Albion",
      "Ames",
      "Bartlett",
      "Belgrade",
      "Bellwood",
      "Brainard",
      "Cedar rapids",
      "Clarks",
      "Clarkson",
      "Creston",
      "Garrison",
      "Dodge",
      "Duncan",
      "Dwight",
      "Elgin",
      "Ericson",
      "Fullerton",
      "Genoa",
      "Howells",
      "Humphrey",
      "Leigh",
      "Lindsay",
      "Monroe",
      "Morse bluff",
      "North bend",
      "Osceola",
      "Petersburg",
      "Platte center",
      "Polk",
      "Primrose",
      "Rising city",
      "Rogers",
      "Saint edward",
      "Schuyler",
      "Shelby",
      "Silver creek",
      "Snyder",
      "Spalding",
      "Stromsburg",
      "Ulysses",
      "Zcta 686hh",
      "Norfolk",
      "Allen",
      "Amelia",
      "Atkinson",
      "Bassett",
      "Battle creek",
      "Beemer",
      "Belden",
      "Bloomfield",
      "Bristow",
      "Brunswick",
      "Butte",
      "Carroll",
      "Center",
      "Chambers",
      "Clearwater",
      "Coleridge",
      "Concord",
      "Creighton",
      "Crofton",
      "Dakota city",
      "Dixon",
      "Emerson",
      "Emmet",
      "Ewing",
      "Fordyce",
      "Foster",
      "Hartington",
      "Hoskins",
      "Hubbard",
      "Inman",
      "Jackson",
      "Laurel",
      "Lynch",
      "Mclean",
      "Madison",
      "Meadow grove",
      "Mills",
      "Naper",
      "Neligh",
      "Newcastle",
      "Newman grove",
      "Newport",
      "Verdel",
      "Oakdale",
      "Oneill",
      "Orchard",
      "Osmond",
      "Page",
      "Pierce",
      "Pilger",
      "Plainview",
      "Ponca",
      "Randolph",
      "Rose",
      "Royal",
      "Saint helena",
      "South sioux city",
      "Spencer",
      "Springview",
      "Stanton",
      "Stuart",
      "Tilden",
      "Verdigre",
      "Wakefield",
      "Waterbury",
      "Wausa",
      "Wayne",
      "West point",
      "Winnetoon",
      "Winside",
      "Wisner",
      "Wynot",
      "Zcta 687hh",
      "Grand island",
      "Alda",
      "Amherst",
      "Milburn",
      "Ansley",
      "Arcadia",
      "Archer",
      "Ashton",
      "Aurora",
      "Berwyn",
      "Boelus",
      "Brewster",
      "Broken bow",
      "Burwell",
      "Cairo",
      "Callaway",
      "Central city",
      "Chapman",
      "Comstock",
      "Dannebrog",
      "Doniphan",
      "Dunning",
      "Eddyville",
      "Elba",
      "Elm creek",
      "Elyria",
      "Farwell",
      "Gibbon",
      "Giltner",
      "Greeley",
      "Hampton",
      "Hazard",
      "Zcta 68845",
      "Hordville",
      "Kearney",
      "Lexington",
      "Litchfield",
      "Loup city",
      "Marquette",
      "Mason city",
      "Merna",
      "Miller",
      "North loup",
      "Oconto",
      "Odessa",
      "Ord",
      "Overton",
      "Palmer",
      "Phillips",
      "Pleasanton",
      "Ravenna",
      "Riverdale",
      "Rockville",
      "Saint libory",
      "Saint paul",
      "Sargent",
      "Scotia",
      "Shelton",
      "Sumner",
      "Almeria",
      "Westerville",
      "Wolbach",
      "Wood river",
      "Zcta 688hh",
      "Hastings",
      "Alma",
      "Arapahoe",
      "Atlanta",
      "Axtell",
      "Ayr",
      "Beaver city",
      "Bertrand",
      "Bladen",
      "Bloomington",
      "Blue hill",
      "Campbell",
      "Clay center",
      "Deweese",
      "Edgar",
      "Edison",
      "Elwood",
      "Fairfield",
      "Franklin",
      "Funk",
      "Glenvil",
      "Guide rock",
      "Hardy",
      "Harvard",
      "Heartwell",
      "Hendley",
      "Hildreth",
      "Holbrook",
      "Holdrege",
      "Holstein",
      "Inavale",
      "Inland",
      "Juniata",
      "Kenesaw",
      "Lawrence",
      "Loomis",
      "Minden",
      "Naponee",
      "Nora",
      "Oak",
      "Orleans",
      "Oxford",
      "Red cloud",
      "Republican city",
      "Riverton",
      "Roseland",
      "Ruskin",
      "Saronville",
      "Smithfield",
      "Stamford",
      "Superior",
      "Sutton",
      "Trumbull",
      "Upland",
      "Wilcox",
      "Zcta 689hh",
      "Mc cook",
      "Bartley",
      "Benkelman",
      "Cambridge",
      "Champion",
      "Culbertson",
      "Curtis",
      "Danbury",
      "Enders",
      "Eustis",
      "Farnam",
      "Haigler",
      "Hamlet",
      "Hayes center",
      "Imperial",
      "Indianola",
      "Lebanon",
      "Max",
      "Maywood",
      "Moorefield",
      "Palisade",
      "Parks",
      "Stockville",
      "Stratton",
      "Trenton",
      "Wauneta",
      "Wilsonville",
      "Zcta 690hh",
      "Zcta 690xx",
      "North platte",
      "Arnold",
      "Arthur",
      "Big springs",
      "Brady",
      "Broadwater",
      "Brule",
      "Bushnell",
      "Chappell",
      "Cozad",
      "Dalton",
      "Dickens",
      "Dix",
      "Elsie",
      "Elsmere",
      "Gothenburg",
      "Grant",
      "Gurley",
      "Halsey",
      "Hershey",
      "Keystone",
      "Kimball",
      "Lemoyne",
      "Lewellen",
      "Lisco",
      "Lodgepole",
      "Madrid",
      "Maxwell",
      "Mullen",
      "Ogallala",
      "Oshkosh",
      "Paxton",
      "Potter",
      "Purdum",
      "Seneca",
      "Sidney",
      "Stapleton",
      "Sutherland",
      "Brownlee",
      "Tryon",
      "Venango",
      "Wallace",
      "Wellfleet",
      "Zcta 691hh",
      "Zcta 691xx",
      "Valentine",
      "Ainsworth",
      "Cody",
      "Crookston",
      "Johnstown",
      "Kilgore",
      "Long pine",
      "Merriman",
      "Nenzel",
      "Sparks",
      "Wood lake",
      "Alliance",
      "Angora",
      "Ashby",
      "Bayard",
      "Bingham",
      "Bridgeport",
      "Chadron",
      "Crawford",
      "Ellsworth",
      "Gering",
      "Gordon",
      "Harrisburg",
      "Harrison",
      "Hay springs",
      "Hemingford",
      "Henry",
      "Hyannis",
      "Lakeside",
      "Lyman",
      "Marsland",
      "Melbeta",
      "Minatare",
      "Mitchell",
      "Morrill",
      "Rushville",
      "Scottsbluff",
      "Whiteclay",
      "Whitman",
      "Whitney",
      "",
    ],
    ZipCodes: {
      Abie: ["68001"],
      Arlington: ["68002"],
      Ashland: ["68003"],
      Bancroft: ["68004"],
      Bellevue: ["68005"],
      Bennington: ["68007"],
      Blair: ["68008"],
      "Boys town": ["68010"],
      Bruno: ["68014"],
      "Cedar bluffs": ["68015"],
      "Cedar creek": ["68016"],
      Ceresco: ["68017"],
      Colon: ["68018"],
      Craig: ["68019"],
      Decatur: ["68020"],
      Elkhorn: ["68022"],
      "Fort calhoun": ["68023"],
      Fremont: ["68025"],
      Gretna: ["68028"],
      Herman: ["68029"],
      Homer: ["68030"],
      Hooper: ["68031"],
      Ithaca: ["68033"],
      Kennard: ["68034"],
      Leshara: ["68035"],
      Linwood: ["68036"],
      Louisville: ["68037"],
      Lyons: ["68038"],
      Macy: ["68039"],
      Malmo: ["68040"],
      Mead: ["68041"],
      Memphis: ["68042"],
      Nickerson: ["68044"],
      Oakland: ["68045"],
      Papillion: ["68046", "68128", "68133", "68138", "68157"],
      Pender: ["68047"],
      Plattsmouth: ["68048"],
      Prague: ["68050"],
      Richfield: ["68054"],
      Rosalie: ["68055"],
      Scribner: ["68057"],
      "South bend": ["68058"],
      Springfield: ["68059"],
      Tekamah: ["68061"],
      Thurston: ["68062"],
      Uehling: ["68063"],
      Valley: ["68064"],
      Valparaiso: ["68065"],
      Wahoo: ["68066"],
      Walthill: ["68067"],
      Washington: ["68068"],
      Waterloo: ["68069"],
      Weston: ["68070"],
      Winnebago: ["68071"],
      Yutan: ["68073"],
      "Zcta 680hh": ["680HH"],
      Omaha: [
        "68102",
        "68104",
        "68105",
        "68106",
        "68107",
        "68108",
        "68110",
        "68111",
        "68112",
        "68114",
        "68116",
        "68117",
        "68118",
        "68122",
        "68123",
        "68124",
        "68130",
        "68131",
        "68132",
        "68134",
        "68135",
        "68136",
        "68142",
        "68147",
        "68152",
        "68154",
        "68164",
      ],
      "Offutt a f b": ["68113"],
      Ralston: ["68127"],
      Millard: ["68137", "68144"],
      "Zcta 681hh": ["681HH"],
      Adams: ["68301"],
      Alexandria: ["68303"],
      Alvo: ["68304"],
      Auburn: ["68305"],
      Avoca: ["68307"],
      Barneston: ["68309"],
      Beatrice: ["68310"],
      "Beaver crossing": ["68313"],
      Bee: ["68314"],
      Belvidere: ["68315"],
      Benedict: ["68316"],
      Bennet: ["68317"],
      "Blue springs": ["68318"],
      Bradshaw: ["68319"],
      Brock: ["68320"],
      Brownville: ["68321"],
      Bruning: ["68322"],
      Burchard: ["68323"],
      Burr: ["68324"],
      Byron: ["68325"],
      Carleton: ["68326"],
      Chester: ["68327"],
      Clatonia: ["68328"],
      Cook: ["68329"],
      Cordova: ["68330"],
      Cortland: ["68331"],
      "Crab orchard": ["68332"],
      Crete: ["68333"],
      Davenport: ["68335"],
      Davey: ["68336"],
      Dawson: ["68337"],
      Daykin: ["68338"],
      Denton: ["68339"],
      Deshler: ["68340"],
      "De witt": ["68341"],
      Diller: ["68342"],
      Dorchester: ["68343"],
      Douglas: ["68344"],
      "Du bois": ["68345"],
      Dunbar: ["68346"],
      Eagle: ["68347"],
      "Elk creek": ["68348"],
      Elmwood: ["68349"],
      Endicott: ["68350"],
      Exeter: ["68351"],
      Fairbury: ["68352"],
      Fairmont: ["68354"],
      "Falls city": ["68355"],
      Filley: ["68357"],
      Firth: ["68358"],
      Friend: ["68359"],
      Garland: ["68360"],
      Geneva: ["68361"],
      Gilead: ["68362"],
      Goehner: ["68364"],
      Grafton: ["68365"],
      Greenwood: ["68366"],
      Gresham: ["68367"],
      Hallam: ["68368"],
      Hebron: ["68370"],
      Henderson: ["68371"],
      Holland: ["68372"],
      Holmesville: ["68374"],
      Hubbell: ["68375"],
      Humboldt: ["68376"],
      Jansen: ["68377"],
      Johnson: ["68378"],
      Lewiston: ["68380"],
      Liberty: ["68381"],
      "Zcta 683hh": ["683HH"],
      "Mc cool junction": ["68401"],
      Malcolm: ["68402"],
      Manley: ["68403"],
      Martell: ["68404"],
      Milford: ["68405"],
      Milligan: ["68406"],
      Murdock: ["68407"],
      Murray: ["68409"],
      "Nebraska city": ["68410"],
      Nehawka: ["68413"],
      Nemaha: ["68414"],
      Odell: ["68415"],
      Ohiowa: ["68416"],
      Otoe: ["68417"],
      Palmyra: ["68418"],
      Panama: ["68419"],
      "Pawnee city": ["68420"],
      Peru: ["68421"],
      Pickrell: ["68422"],
      "Pleasant dale": ["68423"],
      Plymouth: ["68424"],
      Agnew: ["68428"],
      Roca: ["68430"],
      Rulo: ["68431"],
      Salem: ["68433"],
      Seward: ["68434"],
      Shickley: ["68436"],
      Shubert: ["68437"],
      Staplehurst: ["68439"],
      "Steele city": ["68440"],
      Steinauer: ["68441"],
      Stella: ["68442"],
      Sterling: ["68443"],
      Strang: ["68444"],
      Swanton: ["68445"],
      Syracuse: ["68446"],
      "Table rock": ["68447"],
      Talmage: ["68448"],
      Tecumseh: ["68450"],
      Ong: ["68452"],
      Tobias: ["68453"],
      Unadilla: ["68454"],
      Union: ["68455"],
      Utica: ["68456"],
      Verdon: ["68457"],
      Virginia: ["68458"],
      Waco: ["68460"],
      Walton: ["68461"],
      Waverly: ["68462"],
      "Weeping water": ["68463"],
      Western: ["68464"],
      Wilber: ["68465"],
      Wymore: ["68466"],
      York: ["68467"],
      "Zcta 684hh": ["684HH"],
      Lincoln: [
        "68502",
        "68503",
        "68504",
        "68505",
        "68506",
        "68507",
        "68508",
        "68510",
        "68512",
        "68516",
        "68517",
        "68520",
        "68521",
        "68522",
        "68523",
        "68524",
        "68526",
        "68527",
        "68528",
        "68532",
      ],
      Richland: ["68601"],
      Albion: ["68620"],
      Ames: ["68621"],
      Bartlett: ["68622"],
      Belgrade: ["68623"],
      Bellwood: ["68624"],
      Brainard: ["68626"],
      "Cedar rapids": ["68627"],
      Clarks: ["68628"],
      Clarkson: ["68629"],
      Creston: ["68631"],
      Garrison: ["68632"],
      Dodge: ["68633"],
      Duncan: ["68634"],
      Dwight: ["68635"],
      Elgin: ["68636"],
      Ericson: ["68637"],
      Fullerton: ["68638"],
      Genoa: ["68640"],
      Howells: ["68641"],
      Humphrey: ["68642"],
      Leigh: ["68643"],
      Lindsay: ["68644"],
      Monroe: ["68647"],
      "Morse bluff": ["68648"],
      "North bend": ["68649"],
      Osceola: ["68651"],
      Petersburg: ["68652"],
      "Platte center": ["68653"],
      Polk: ["68654"],
      Primrose: ["68655"],
      "Rising city": ["68658"],
      Rogers: ["68659"],
      "Saint edward": ["68660"],
      Schuyler: ["68661"],
      Shelby: ["68662"],
      "Silver creek": ["68663"],
      Snyder: ["68664"],
      Spalding: ["68665"],
      Stromsburg: ["68666"],
      Ulysses: ["68667", "68669"],
      "Zcta 686hh": ["686HH"],
      Norfolk: ["68701"],
      Allen: ["68710"],
      Amelia: ["68711"],
      Atkinson: ["68713"],
      Bassett: ["68714"],
      "Battle creek": ["68715"],
      Beemer: ["68716"],
      Belden: ["68717"],
      Bloomfield: ["68718"],
      Bristow: ["68719"],
      Brunswick: ["68720"],
      Butte: ["68722"],
      Carroll: ["68723"],
      Center: ["68724"],
      Chambers: ["68725"],
      Clearwater: ["68726"],
      Coleridge: ["68727"],
      Concord: ["68728"],
      Creighton: ["68729"],
      Crofton: ["68730"],
      "Dakota city": ["68731"],
      Dixon: ["68732"],
      Emerson: ["68733"],
      Emmet: ["68734"],
      Ewing: ["68735"],
      Fordyce: ["68736"],
      Foster: ["68737"],
      Hartington: ["68739"],
      Hoskins: ["68740"],
      Hubbard: ["68741"],
      Inman: ["68742"],
      Jackson: ["68743"],
      Laurel: ["68745"],
      Lynch: ["68746"],
      Mclean: ["68747"],
      Madison: ["68748"],
      "Meadow grove": ["68752"],
      Mills: ["68753"],
      Naper: ["68755"],
      Neligh: ["68756"],
      Newcastle: ["68757"],
      "Newman grove": ["68758"],
      Newport: ["68759"],
      Verdel: ["68760"],
      Oakdale: ["68761"],
      Oneill: ["68763"],
      Orchard: ["68764"],
      Osmond: ["68765"],
      Page: ["68766"],
      Pierce: ["68767"],
      Pilger: ["68768"],
      Plainview: ["68769"],
      Ponca: ["68770"],
      Randolph: ["68771"],
      Rose: ["68772"],
      Royal: ["68773"],
      "Saint helena": ["68774"],
      "South sioux city": ["68776"],
      Spencer: ["68777"],
      Springview: ["68778"],
      Stanton: ["68779"],
      Stuart: ["68780"],
      Tilden: ["68781"],
      Verdigre: ["68783"],
      Wakefield: ["68784"],
      Waterbury: ["68785"],
      Wausa: ["68786"],
      Wayne: ["68787"],
      "West point": ["68788"],
      Winnetoon: ["68789"],
      Winside: ["68790"],
      Wisner: ["68791"],
      Wynot: ["68792"],
      "Zcta 687hh": ["687HH"],
      "Grand island": ["68801", "68803"],
      Alda: ["68810"],
      Amherst: ["68812"],
      Milburn: ["68813"],
      Ansley: ["68814"],
      Arcadia: ["68815"],
      Archer: ["68816"],
      Ashton: ["68817"],
      Aurora: ["68818"],
      Berwyn: ["68819"],
      Boelus: ["68820"],
      Brewster: ["68821"],
      "Broken bow": ["68822"],
      Burwell: ["68823"],
      Cairo: ["68824"],
      Callaway: ["68825"],
      "Central city": ["68826"],
      Chapman: ["68827"],
      Comstock: ["68828"],
      Dannebrog: ["68831"],
      Doniphan: ["68832"],
      Dunning: ["68833"],
      Eddyville: ["68834"],
      Elba: ["68835"],
      "Elm creek": ["68836"],
      Elyria: ["68837"],
      Farwell: ["68838"],
      Gibbon: ["68840"],
      Giltner: ["68841"],
      Greeley: ["68842"],
      Hampton: ["68843"],
      Hazard: ["68844"],
      "Zcta 68845": ["68845"],
      Hordville: ["68846"],
      Kearney: ["68847"],
      Lexington: ["68850"],
      Litchfield: ["68852"],
      "Loup city": ["68853"],
      Marquette: ["68854"],
      "Mason city": ["68855"],
      Merna: ["68856"],
      Miller: ["68858"],
      "North loup": ["68859"],
      Oconto: ["68860"],
      Odessa: ["68861"],
      Ord: ["68862"],
      Overton: ["68863"],
      Palmer: ["68864"],
      Phillips: ["68865"],
      Pleasanton: ["68866"],
      Ravenna: ["68869"],
      Riverdale: ["68870"],
      Rockville: ["68871"],
      "Saint libory": ["68872"],
      "Saint paul": ["68873"],
      Sargent: ["68874"],
      Scotia: ["68875"],
      Shelton: ["68876"],
      Sumner: ["68878"],
      Almeria: ["68879"],
      Westerville: ["68881"],
      Wolbach: ["68882"],
      "Wood river": ["68883"],
      "Zcta 688hh": ["688HH"],
      Hastings: ["68901"],
      Alma: ["68920"],
      Arapahoe: ["68922"],
      Atlanta: ["68923"],
      Axtell: ["68924"],
      Ayr: ["68925"],
      "Beaver city": ["68926"],
      Bertrand: ["68927"],
      Bladen: ["68928"],
      Bloomington: ["68929"],
      "Blue hill": ["68930"],
      Campbell: ["68932"],
      "Clay center": ["68933"],
      Deweese: ["68934"],
      Edgar: ["68935"],
      Edison: ["68936"],
      Elwood: ["68937"],
      Fairfield: ["68938"],
      Franklin: ["68939"],
      Funk: ["68940"],
      Glenvil: ["68941"],
      "Guide rock": ["68942"],
      Hardy: ["68943"],
      Harvard: ["68944"],
      Heartwell: ["68945"],
      Hendley: ["68946"],
      Hildreth: ["68947"],
      Holbrook: ["68948"],
      Holdrege: ["68949"],
      Holstein: ["68950"],
      Inavale: ["68952"],
      Inland: ["68954"],
      Juniata: ["68955"],
      Kenesaw: ["68956"],
      Lawrence: ["68957"],
      Loomis: ["68958"],
      Minden: ["68959"],
      Naponee: ["68960"],
      Nora: ["68961"],
      Oak: ["68964"],
      Orleans: ["68966"],
      Oxford: ["68967"],
      "Red cloud": ["68970"],
      "Republican city": ["68971"],
      Riverton: ["68972"],
      Roseland: ["68973"],
      Ruskin: ["68974"],
      Saronville: ["68975"],
      Smithfield: ["68976"],
      Stamford: ["68977"],
      Superior: ["68978"],
      Sutton: ["68979"],
      Trumbull: ["68980"],
      Upland: ["68981"],
      Wilcox: ["68982"],
      "Zcta 689hh": ["689HH"],
      "Mc cook": ["69001"],
      Bartley: ["69020"],
      Benkelman: ["69021"],
      Cambridge: ["69022"],
      Champion: ["69023"],
      Culbertson: ["69024"],
      Curtis: ["69025"],
      Danbury: ["69026"],
      Enders: ["69027"],
      Eustis: ["69028"],
      Farnam: ["69029"],
      Haigler: ["69030"],
      Hamlet: ["69031"],
      "Hayes center": ["69032"],
      Imperial: ["69033"],
      Indianola: ["69034"],
      Lebanon: ["69036"],
      Max: ["69037"],
      Maywood: ["69038"],
      Moorefield: ["69039"],
      Palisade: ["69040"],
      Parks: ["69041"],
      Stockville: ["69042"],
      Stratton: ["69043"],
      Trenton: ["69044"],
      Wauneta: ["69045"],
      Wilsonville: ["69046"],
      "Zcta 690hh": ["690HH"],
      "Zcta 690xx": ["690XX"],
      "North platte": ["69101"],
      Arnold: ["69120"],
      Arthur: ["69121"],
      "Big springs": ["69122"],
      Brady: ["69123"],
      Broadwater: ["69125"],
      Brule: ["69127"],
      Bushnell: ["69128"],
      Chappell: ["69129"],
      Cozad: ["69130"],
      Dalton: ["69131"],
      Dickens: ["69132"],
      Dix: ["69133"],
      Elsie: ["69134"],
      Elsmere: ["69135"],
      Gothenburg: ["69138"],
      Grant: ["69140"],
      Gurley: ["69141"],
      Halsey: ["69142"],
      Hershey: ["69143"],
      Keystone: ["69144"],
      Kimball: ["69145"],
      Lemoyne: ["69146"],
      Lewellen: ["69147"],
      Lisco: ["69148"],
      Lodgepole: ["69149"],
      Madrid: ["69150"],
      Maxwell: ["69151"],
      Mullen: ["69152"],
      Ogallala: ["69153"],
      Oshkosh: ["69154"],
      Paxton: ["69155"],
      Potter: ["69156"],
      Purdum: ["69157"],
      Seneca: ["69161"],
      Sidney: ["69162"],
      Stapleton: ["69163"],
      Sutherland: ["69165"],
      Brownlee: ["69166"],
      Tryon: ["69167"],
      Venango: ["69168"],
      Wallace: ["69169"],
      Wellfleet: ["69170"],
      "Zcta 691hh": ["691HH"],
      "Zcta 691xx": ["691XX"],
      Valentine: ["69201"],
      Ainsworth: ["69210"],
      Cody: ["69211"],
      Crookston: ["69212"],
      Johnstown: ["69214"],
      Kilgore: ["69216"],
      "Long pine": ["69217"],
      Merriman: ["69218"],
      Nenzel: ["69219"],
      Sparks: ["69220"],
      "Wood lake": ["69221"],
      Alliance: ["69301"],
      Angora: ["69331"],
      Ashby: ["69333"],
      Bayard: ["69334"],
      Bingham: ["69335"],
      Bridgeport: ["69336"],
      Chadron: ["69337"],
      Crawford: ["69339"],
      Ellsworth: ["69340"],
      Gering: ["69341"],
      Gordon: ["69343"],
      Harrisburg: ["69345"],
      Harrison: ["69346"],
      "Hay springs": ["69347"],
      Hemingford: ["69348"],
      Henry: ["69349"],
      Hyannis: ["69350"],
      Lakeside: ["69351"],
      Lyman: ["69352"],
      Marsland: ["69354"],
      Melbeta: ["69355"],
      Minatare: ["69356"],
      Mitchell: ["69357"],
      Morrill: ["69358"],
      Rushville: ["69360"],
      Scottsbluff: ["69361"],
      Whiteclay: ["69365"],
      Whitman: ["69366"],
      Whitney: ["69367"],
      "": ["82082"],
    },
  },
  {
    abbrevation: "NV",
    name: "Nevada",
    Cities: [
      "Alamo",
      "Alamo",
      "Beatty",
      "Blue diamond",
      "Boulder city",
      "Bunkerville",
      "Caliente",
      "Dyer",
      "Henderson",
      "Goldfield",
      "Hiko",
      "Indian springs",
      "Goodsprings",
      "Amargosa valley",
      "Logandale",
      "Manhattan",
      "Moapa",
      "Zcta 89027",
      "Laughlin",
      "North las vegas",
      "Cal nev ari",
      "Overton",
      "Panaca",
      "Pioche",
      "Cottonwood cove",
      "Silverpeak",
      "Zcta 89048",
      "Tonopah",
      "Zcta 89052",
      "Zcta 890hh",
      "Zcta 890xx",
      "Las vegas",
      "Zcta 89141",
      "Zcta 89142",
      "Zcta 89143",
      "Zcta 89144",
      "Zcta 89145",
      "Zcta 89146",
      "Zcta 89147",
      "Zcta 89148",
      "Zcta 89149",
      "Zcta 89156",
      "Nellis a f b",
      "Zcta 891xx",
      "Ely",
      "Austin",
      "Baker",
      "Duckwater",
      "Eureka",
      "Lund",
      "Mc gill",
      "Zcta 893hh",
      "Zcta 893xx",
      "Dayton",
      "Denio",
      "Empire",
      "Fallon",
      "Fernley",
      "Gabbs",
      "Gardnerville",
      "Genoa",
      "Glenbrook",
      "Golconda",
      "Hawthorne",
      "Unionville",
      "Lovelock",
      "Luning",
      "Mc dermitt",
      "Mina",
      "Minden",
      "Nixon",
      "Orovada",
      "Paradise valley",
      "Schurz",
      "Silver city",
      "Silver springs",
      "Smith",
      "Sparks",
      "Sun valley",
      "Verdi",
      "Virginia city",
      "Wadsworth",
      "Wellington",
      "Winnemucca",
      "Yerington",
      "Zephyr cove",
      "Stateline",
      "Incline village",
      "Zcta 894hh",
      "Zcta 894xx",
      "Reno",
      "Zcta 895xx",
      "Carson city",
      "Moundhouse",
      "Zcta 897hh",
      "Zcta 897xx",
      "Jiggs",
      "Elko",
      "Zcta 89815",
      "Battle mountain",
      "Beowawe",
      "Carlin",
      "Deeth",
      "Halleck",
      "Jackpot",
      "Jarbidge",
      "Lamoille",
      "Montello",
      "Mountain city",
      "Owyhee",
      "Ruby valley",
      "Tuscarora",
      "Oasis",
      "Wendover",
      "Zcta 898xx",
    ],
    ZipCodes: {
      Alamo: ["89001"],
      Beatty: ["89003"],
      "Blue diamond": ["89004"],
      "Boulder city": ["89005"],
      Bunkerville: ["89007"],
      Caliente: ["89008"],
      Dyer: ["89010"],
      Henderson: ["89011", "89012", "89014", "89015"],
      Goldfield: ["89013"],
      Hiko: ["89017"],
      "Indian springs": ["89018"],
      Goodsprings: ["89019"],
      "Amargosa valley": ["89020"],
      Logandale: ["89021"],
      Manhattan: ["89022"],
      Moapa: ["89025"],
      "Zcta 89027": ["89027"],
      Laughlin: ["89028", "89029"],
      "North las vegas": ["89030", "89031", "89032"],
      "Cal nev ari": ["89039"],
      Overton: ["89040"],
      Panaca: ["89042"],
      Pioche: ["89043"],
      "Cottonwood cove": ["89046"],
      Silverpeak: ["89047"],
      "Zcta 89048": ["89048"],
      Tonopah: ["89049"],
      "Zcta 89052": ["89052"],
      "Zcta 890hh": ["890HH"],
      "Zcta 890xx": ["890XX"],
      "Las vegas": [
        "89101",
        "89102",
        "89103",
        "89104",
        "89106",
        "89107",
        "89108",
        "89109",
        "89110",
        "89113",
        "89115",
        "89117",
        "89118",
        "89119",
        "89120",
        "89121",
        "89122",
        "89123",
        "89124",
        "89128",
        "89129",
        "89130",
        "89131",
        "89134",
        "89135",
        "89139",
      ],
      "Zcta 89141": ["89141"],
      "Zcta 89142": ["89142"],
      "Zcta 89143": ["89143"],
      "Zcta 89144": ["89144"],
      "Zcta 89145": ["89145"],
      "Zcta 89146": ["89146"],
      "Zcta 89147": ["89147"],
      "Zcta 89148": ["89148"],
      "Zcta 89149": ["89149"],
      "Zcta 89156": ["89156"],
      "Nellis a f b": ["89191"],
      "Zcta 891xx": ["891XX"],
      Ely: ["89301"],
      Austin: ["89310"],
      Baker: ["89311"],
      Duckwater: ["89314"],
      Eureka: ["89316"],
      Lund: ["89317"],
      "Mc gill": ["89318"],
      "Zcta 893hh": ["893HH"],
      "Zcta 893xx": ["893XX"],
      Dayton: ["89403"],
      Denio: ["89404"],
      Empire: ["89405"],
      Fallon: ["89406"],
      Fernley: ["89408"],
      Gabbs: ["89409"],
      Gardnerville: ["89410"],
      Genoa: ["89411"],
      Glenbrook: ["89413"],
      Golconda: ["89414"],
      Hawthorne: ["89415"],
      Unionville: ["89418"],
      Lovelock: ["89419"],
      Luning: ["89420"],
      "Mc dermitt": ["89421"],
      Mina: ["89422"],
      Minden: ["89423"],
      Nixon: ["89424"],
      Orovada: ["89425"],
      "Paradise valley": ["89426"],
      Schurz: ["89427"],
      "Silver city": ["89428"],
      "Silver springs": ["89429"],
      Smith: ["89430"],
      Sparks: ["89431", "89434", "89436"],
      "Sun valley": ["89433"],
      Verdi: ["89439"],
      "Virginia city": ["89440"],
      Wadsworth: ["89442"],
      Wellington: ["89444"],
      Winnemucca: ["89445"],
      Yerington: ["89447"],
      "Zephyr cove": ["89448"],
      Stateline: ["89449"],
      "Incline village": ["89451"],
      "Zcta 894hh": ["894HH"],
      "Zcta 894xx": ["894XX"],
      Reno: [
        "89501",
        "89502",
        "89503",
        "89506",
        "89509",
        "89510",
        "89511",
        "89512",
        "89523",
      ],
      "Zcta 895xx": ["895XX"],
      "Carson city": ["89701", "89703", "89704", "89705"],
      Moundhouse: ["89706"],
      "Zcta 897hh": ["897HH"],
      "Zcta 897xx": ["897XX"],
      Jiggs: ["89801"],
      Elko: ["89803"],
      "Zcta 89815": ["89815"],
      "Battle mountain": ["89820"],
      Beowawe: ["89821"],
      Carlin: ["89822"],
      Deeth: ["89823"],
      Halleck: ["89824"],
      Jackpot: ["89825"],
      Jarbidge: ["89826"],
      Lamoille: ["89828"],
      Montello: ["89830"],
      "Mountain city": ["89831"],
      Owyhee: ["89832"],
      "Ruby valley": ["89833"],
      Tuscarora: ["89834"],
      Oasis: ["89835"],
      Wendover: ["89883"],
      "Zcta 898xx": ["898XX"],
    },
  },
  {
    abbrevation: "NH",
    name: "New hampshire",
    Cities: [
      "Amherst",
      "Amherst",
      "Auburn",
      "Brookline",
      "Candia",
      "Chester",
      "Deerfield",
      "Derry",
      "Epping",
      "Francestown",
      "Fremont",
      "Dunbarton",
      "Greenfield",
      "Mason",
      "Hollis",
      "Hudson",
      "Zcta 03052",
      "Londonderry",
      "Merrimack",
      "Milford",
      "Mont vernon",
      "Nashua",
      "Zcta 03064",
      "New boston",
      "New ipswich",
      "Pelham",
      "Raymond",
      "Salem",
      "Lyndeborough",
      "Temple",
      "Wilton",
      "Windham",
      "Zcta 030hh",
      "Manchester",
      "Hooksett",
      "Bedford",
      "Zcta 031hh",
      "Waterville valle",
      "Andover",
      "Ashland",
      "Barnstead",
      "Belmont",
      "Bradford",
      "Bristol",
      "Beebe river",
      "Canterbury",
      "Center barnstead",
      "Center harbor",
      "Center sandwich",
      "Hopkinton",
      "Danbury",
      "Elkins",
      "Epsom",
      "Franklin",
      "Gilmanton",
      "Grafton",
      "Hebron",
      "Henniker",
      "Hill",
      "Hillsboro",
      "Gilford",
      "Lincoln",
      "Meredith",
      "Moultonborough",
      "Newbury",
      "New hampton",
      "New london",
      "North sandwich",
      "North sutton",
      "Northwood",
      "North woodstock",
      "Pittsfield",
      "Plymouth",
      "Rumney",
      "Salisbury",
      "Sanbornton",
      "South sutton",
      "Allenstown",
      "Tilton",
      "Warner",
      "Warren",
      "Washington",
      "Weare",
      "Wentworth",
      "West springfield",
      "Wilmot flat",
      "Nottingham",
      "West nottingham",
      "Zcta 032hh",
      "Zcta 032xx",
      "Concord",
      "Boscawen",
      "Bow",
      "Zcta 03307",
      "Zcta 033hh",
      "Surry",
      "Antrim",
      "Ashuelot",
      "Bennington",
      "Chesterfield",
      "Dublin",
      "East sullivan",
      "East swanzey",
      "Fitzwilliam",
      "Gilsum",
      "Hancock",
      "Harrisville",
      "Hinsdale",
      "Jaffrey",
      "Marlborough",
      "Marlow",
      "Munsonville",
      "Peterborough",
      "Rindge",
      "Spofford",
      "Stoddard",
      "Troy",
      "West chesterfiel",
      "Westmoreland",
      "Richmond",
      "Zcta 034hh",
      "Littleton",
      "Berlin",
      "Bethlehem",
      "Colebrook",
      "Errol",
      "Franconia",
      "Gorham",
      "Groveton",
      "Jefferson",
      "Lancaster",
      "Lisbon",
      "Milan",
      "North stratford",
      "Pittsburg",
      "Twin mountain",
      "Whitefield",
      "Zcta 035hh",
      "Zcta 035xx",
      "Acworth",
      "Alstead",
      "Charlestown",
      "East lempster",
      "South acworth",
      "Walpole",
      "North walpole",
      "Zcta 036hh",
      "Canaan",
      "Claremont",
      "Cornish",
      "Enfield",
      "Etna",
      "Georges mills",
      "Goshen",
      "Grantham",
      "Hanover",
      "Haverhill",
      "Lebanon",
      "Lyme",
      "Meriden",
      "Monroe",
      "Newport",
      "North haverhill",
      "Orford",
      "Piermont",
      "Pike",
      "Plainfield",
      "Sunapee",
      "West lebanon",
      "Woodsville",
      "Zcta 037hh",
      "Newington",
      "Alton",
      "Alton bay",
      "Atkinson",
      "Bartlett",
      "Center conway",
      "Center ossipee",
      "Center strafford",
      "Center tuftonbor",
      "Chocorua",
      "Conway",
      "Danville",
      "Madbury",
      "Lee",
      "Barrington",
      "East hampstead",
      "South hampton",
      "East wakefield",
      "Eaton center",
      "Brentwood",
      "Farmington",
      "Freedom",
      "Gilmanton iron w",
      "Glen",
      "Gonic",
      "Greenland",
      "Hampstead",
      "Hampton",
      "Hampton falls",
      "Intervale",
      "Jackson",
      "Kingston",
      "Madison",
      "Milton mills",
      "Mirror lake",
      "New castle",
      "New durham",
      "Newfields",
      "Newmarket",
      "Newton",
      "North conway",
      "North hampton",
      "Ossipee",
      "Plaistow",
      "Rochester",
      "East rochester",
      "Rollinsford",
      "Rye",
      "Sanbornville",
      "Sandown",
      "Seabrook",
      "Silver lake",
      "Somersworth",
      "South effingham",
      "South tamworth",
      "Strafford",
      "Stratham",
      "Tamworth",
      "Union",
      "Wolfeboro",
      "Wonalancet",
      "Zcta 038hh",
    ],
    ZipCodes: {
      Amherst: ["03031"],
      Auburn: ["03032"],
      Brookline: ["03033"],
      Candia: ["03034"],
      Chester: ["03036"],
      Deerfield: ["03037"],
      Derry: ["03038"],
      Epping: ["03042"],
      Francestown: ["03043"],
      Fremont: ["03044"],
      Dunbarton: ["03045"],
      Greenfield: ["03047"],
      Mason: ["03048"],
      Hollis: ["03049"],
      Hudson: ["03051"],
      "Zcta 03052": ["03052"],
      Londonderry: ["03053"],
      Merrimack: ["03054"],
      Milford: ["03055"],
      "Mont vernon": ["03057"],
      Nashua: ["03060", "03062", "03063"],
      "Zcta 03064": ["03064"],
      "New boston": ["03070"],
      "New ipswich": ["03071"],
      Pelham: ["03076"],
      Raymond: ["03077"],
      Salem: ["03079"],
      Lyndeborough: ["03082"],
      Temple: ["03084"],
      Wilton: ["03086"],
      Windham: ["03087"],
      "Zcta 030hh": ["030HH"],
      Manchester: ["03101", "03102", "03103", "03104", "03109"],
      Hooksett: ["03106"],
      Bedford: ["03110"],
      "Zcta 031hh": ["031HH"],
      "Waterville valle": ["03215"],
      Andover: ["03216"],
      Ashland: ["03217"],
      Barnstead: ["03218"],
      Belmont: ["03220"],
      Bradford: ["03221"],
      Bristol: ["03222"],
      "Beebe river": ["03223"],
      Canterbury: ["03224"],
      "Center barnstead": ["03225"],
      "Center harbor": ["03226"],
      "Center sandwich": ["03227"],
      Hopkinton: ["03229"],
      Danbury: ["03230"],
      Elkins: ["03233"],
      Epsom: ["03234"],
      Franklin: ["03235"],
      Gilmanton: ["03237"],
      Grafton: ["03240"],
      Hebron: ["03241"],
      Henniker: ["03242"],
      Hill: ["03243"],
      Hillsboro: ["03244"],
      Gilford: ["03246"],
      Lincoln: ["03251"],
      Meredith: ["03253"],
      Moultonborough: ["03254"],
      Newbury: ["03255"],
      "New hampton": ["03256"],
      "New london": ["03257"],
      "North sandwich": ["03259"],
      "North sutton": ["03260"],
      Northwood: ["03261"],
      "North woodstock": ["03262"],
      Pittsfield: ["03263"],
      Plymouth: ["03264"],
      Rumney: ["03266"],
      Salisbury: ["03268"],
      Sanbornton: ["03269"],
      "South sutton": ["03273"],
      Allenstown: ["03275"],
      Tilton: ["03276"],
      Warner: ["03278"],
      Warren: ["03279"],
      Washington: ["03280"],
      Weare: ["03281"],
      Wentworth: ["03282"],
      "West springfield": ["03284"],
      "Wilmot flat": ["03287"],
      Nottingham: ["03290"],
      "West nottingham": ["03291"],
      "Zcta 032hh": ["032HH"],
      "Zcta 032xx": ["032XX"],
      Concord: ["03301"],
      Boscawen: ["03303"],
      Bow: ["03304"],
      "Zcta 03307": ["03307"],
      "Zcta 033hh": ["033HH"],
      Surry: ["03431"],
      Antrim: ["03440"],
      Ashuelot: ["03441"],
      Bennington: ["03442"],
      Chesterfield: ["03443"],
      Dublin: ["03444"],
      "East sullivan": ["03445"],
      "East swanzey": ["03446"],
      Fitzwilliam: ["03447"],
      Gilsum: ["03448"],
      Hancock: ["03449"],
      Harrisville: ["03450"],
      Hinsdale: ["03451"],
      Jaffrey: ["03452"],
      Marlborough: ["03455"],
      Marlow: ["03456"],
      Munsonville: ["03457"],
      Peterborough: ["03458"],
      Rindge: ["03461"],
      Spofford: ["03462"],
      Stoddard: ["03464"],
      Troy: ["03465"],
      "West chesterfiel": ["03466"],
      Westmoreland: ["03467"],
      Richmond: ["03470"],
      "Zcta 034hh": ["034HH"],
      Littleton: ["03561"],
      Berlin: ["03570"],
      Bethlehem: ["03574"],
      Colebrook: ["03576"],
      Errol: ["03579"],
      Franconia: ["03580"],
      Gorham: ["03581"],
      Groveton: ["03582"],
      Jefferson: ["03583"],
      Lancaster: ["03584"],
      Lisbon: ["03585"],
      Milan: ["03588"],
      "North stratford": ["03590"],
      Pittsburg: ["03592"],
      "Twin mountain": ["03595"],
      Whitefield: ["03598"],
      "Zcta 035hh": ["035HH"],
      "Zcta 035xx": ["035XX"],
      Acworth: ["03601"],
      Alstead: ["03602"],
      Charlestown: ["03603"],
      "East lempster": ["03605"],
      "South acworth": ["03607"],
      Walpole: ["03608"],
      "North walpole": ["03609"],
      "Zcta 036hh": ["036HH"],
      Canaan: ["03741"],
      Claremont: ["03743"],
      Cornish: ["03745"],
      Enfield: ["03748"],
      Etna: ["03750"],
      "Georges mills": ["03751"],
      Goshen: ["03752"],
      Grantham: ["03753"],
      Hanover: ["03755"],
      Haverhill: ["03765"],
      Lebanon: ["03766"],
      Lyme: ["03768"],
      Meriden: ["03770"],
      Monroe: ["03771"],
      Newport: ["03773"],
      "North haverhill": ["03774"],
      Orford: ["03777"],
      Piermont: ["03779"],
      Pike: ["03780"],
      Plainfield: ["03781"],
      Sunapee: ["03782"],
      "West lebanon": ["03784"],
      Woodsville: ["03785"],
      "Zcta 037hh": ["037HH"],
      Newington: ["03801"],
      Alton: ["03809"],
      "Alton bay": ["03810"],
      Atkinson: ["03811"],
      Bartlett: ["03812"],
      "Center conway": ["03813"],
      "Center ossipee": ["03814"],
      "Center strafford": ["03815"],
      "Center tuftonbor": ["03816"],
      Chocorua: ["03817"],
      Conway: ["03818"],
      Danville: ["03819"],
      Madbury: ["03820"],
      Lee: ["03824"],
      Barrington: ["03825"],
      "East hampstead": ["03826"],
      "South hampton": ["03827"],
      "East wakefield": ["03830"],
      "Eaton center": ["03832"],
      Brentwood: ["03833"],
      Farmington: ["03835"],
      Freedom: ["03836"],
      "Gilmanton iron w": ["03837"],
      Glen: ["03838"],
      Gonic: ["03839"],
      Greenland: ["03840"],
      Hampstead: ["03841"],
      Hampton: ["03842"],
      "Hampton falls": ["03844"],
      Intervale: ["03845"],
      Jackson: ["03846"],
      Kingston: ["03848"],
      Madison: ["03849"],
      "Milton mills": ["03852"],
      "Mirror lake": ["03853"],
      "New castle": ["03854"],
      "New durham": ["03855"],
      Newfields: ["03856"],
      Newmarket: ["03857"],
      Newton: ["03858"],
      "North conway": ["03860"],
      "North hampton": ["03862"],
      Ossipee: ["03864"],
      Plaistow: ["03865"],
      Rochester: ["03867"],
      "East rochester": ["03868"],
      Rollinsford: ["03869"],
      Rye: ["03870"],
      Sanbornville: ["03872"],
      Sandown: ["03873"],
      Seabrook: ["03874"],
      "Silver lake": ["03875"],
      Somersworth: ["03878"],
      "South effingham": ["03882"],
      "South tamworth": ["03883"],
      Strafford: ["03884"],
      Stratham: ["03885"],
      Tamworth: ["03886"],
      Union: ["03887"],
      Wolfeboro: ["03894"],
      Wonalancet: ["03897"],
      "Zcta 038hh": ["038HH"],
    },
  },
  {
    abbrevation: "NJ",
    name: "New jersey",
    Cities: [
      "Avenel",
      "Avenel",
      "Bayonne",
      "Bloomfield",
      "Fairfield",
      "Boonton",
      "West caldwell",
      "Carteret",
      "Cedar grove",
      "Cliffside park",
      "Clifton",
      "Cranford",
      "East orange",
      "Edgewater",
      "Essex fells",
      "Fairview",
      "Fanwood",
      "Fort lee",
      "Garfield",
      "Garwood",
      "Glen ridge",
      "Kearny",
      "Hoboken",
      "North arlington",
      "Kenilworth",
      "Lake hiawatha",
      "Lincoln park",
      "Linden",
      "Livingston",
      "Maplewood",
      "Millburn",
      "Montclair",
      "Verona",
      "Montville",
      "Mountain lakes",
      "North bergen",
      "Orange",
      "West orange",
      "Parsippany",
      "Passaic",
      "Wallington",
      "Pine brook",
      "Warren",
      "North plainfield",
      "Port reading",
      "Rahway",
      "Clark",
      "Colonia",
      "Roseland",
      "Rutherford",
      "Lyndhurst",
      "Carlstadt",
      "East rutherford",
      "Moonachie",
      "Wood ridge",
      "Scotch plains",
      "Sewaren",
      "Short hills",
      "South orange",
      "South plainfield",
      "Springfield",
      "Towaco",
      "Union",
      "Weehawken",
      "Vauxhall",
      "Westfield",
      "Mountainside",
      "Guttenberg",
      "Secaucus",
      "Woodbridge",
      "Zcta 070hh",
      "Newark",
      "Belleville",
      "Nutley",
      "Irvington",
      "Zcta 071hh",
      "Elizabeth",
      "Roselle",
      "Roselle park",
      "Hillside",
      "Zcta 072hh",
      "Jersey city",
      "Zcta 073hh",
      "Allendale",
      "Bloomingdale",
      "Kinnelon",
      "Elmwood park",
      "Fair lawn",
      "Franklin",
      "Franklin lakes",
      "Glenwood",
      "Hamburg",
      "Haskell",
      "Hewitt",
      "Highland lakes",
      "Ho ho kus",
      "West paterson",
      "Mc afee",
      "Mahwah",
      "Midland park",
      "Newfoundland",
      "Oakland",
      "Milton",
      "Ogdensburg",
      "Pequannock",
      "Pompton lakes",
      "Pompton plains",
      "Ramsey",
      "Ridgewood",
      "Glen rock",
      "Ringwood",
      "Riverdale",
      "Upper saddle riv",
      "Stockholm",
      "Sussex",
      "Vernon",
      "Waldwick",
      "Wanaque",
      "Wayne",
      "West milford",
      "Wyckoff",
      "Zcta 074hh",
      "Paterson",
      "Hawthorne",
      "Haledon",
      "Totowa",
      "Zcta 075hh",
      "Hackensack",
      "Bogota",
      "Hasbrouck height",
      "Leonia",
      "South hackensack",
      "Maywood",
      "Teterboro",
      "Alpine",
      "Bergenfield",
      "Closter",
      "Cresskill",
      "Demarest",
      "Dumont",
      "Emerson",
      "Englewood",
      "Englewood cliffs",
      "Harrington park",
      "Haworth",
      "Hillsdale",
      "Little ferry",
      "Lodi",
      "Montvale",
      "New milford",
      "Rockleigh",
      "Norwood",
      "Oradell",
      "Palisades park",
      "Paramus",
      "Park ridge",
      "Ridgefield",
      "Ridgefield park",
      "River edge",
      "Saddle brook",
      "Zcta 07663",
      "Teaneck",
      "Tenafly",
      "Old tappan",
      "Zcta 076hh",
      "Suburban",
      "Shrewsbury",
      "Fort monmouth",
      "Fair haven",
      "Allenhurst",
      "Ocean",
      "Atlantic highlan",
      "Avon by the sea",
      "Belford",
      "Wall",
      "Bradley beach",
      "Cliffwood",
      "Colts neck",
      "Deal",
      "Eatontown",
      "Manalapan",
      "Farmingdale",
      "Freehold",
      "Hazlet",
      "Howell",
      "Fort hancock",
      "Holmdel",
      "Keansburg",
      "Keyport",
      "Leonardo",
      "Lincroft",
      "Little silver",
      "Long branch",
      "Marlboro",
      "Matawan",
      "New monmouth",
      "Monmouth beach",
      "Morganville",
      "Neptune city",
      "Oakhurst",
      "Ocean grove",
      "Oceanport",
      "Port monmouth",
      "Sea bright",
      "Spring lake",
      "West long branch",
      "Zcta 077hh",
      "Mine hill",
      "Zcta 07803",
      "Andover",
      "Augusta",
      "Belvidere",
      "Blairstown",
      "Branchville",
      "Montague",
      "Budd lake",
      "Califon",
      "Columbia",
      "Delaware",
      "Denville",
      "Flanders",
      "Great meadows",
      "Hackettstown",
      "Hibernia",
      "Hopatcong",
      "Johnsonburg",
      "Kenvil",
      "Lafayette",
      "Lake hopatcong",
      "Landing",
      "Layton",
      "Ledgewood",
      "Long valley",
      "Mount arlington",
      "Netcong",
      "Fredon township",
      "Oxford",
      "Port murray",
      "Rockaway",
      "Randolph",
      "Sparta",
      "Stanhope",
      "Succasunna",
      "Mount tabor",
      "Washington",
      "Wharton",
      "Zcta 078hh",
      "Summit",
      "Basking ridge",
      "Bedminster",
      "Berkeley heights",
      "Bernardsville",
      "Cedar knolls",
      "Chatham",
      "Chester",
      "Far hills",
      "Florham park",
      "Gillette",
      "Gladstone",
      "Green village",
      "East hanover",
      "Madison",
      "Mendham",
      "Millington",
      "Greystone park",
      "Morristown",
      "Mount freedom",
      "New providence",
      "New vernon",
      "Peapack",
      "Pottersville",
      "Stirling",
      "Whippany",
      "Alloway",
      "Cherry hill",
      "Winslow",
      "Barnegat",
      "Barnegat light",
      "Barrington",
      "Harvey cedars",
      "Berlin",
      "Beverly",
      "Birmingham",
      "Bridgeport",
      "Browns mills",
      "Burlington",
      "Chatsworth",
      "Clarksboro",
      "Laurel springs",
      "Columbus",
      "Deepwater",
      "Gibbsboro",
      "Gibbstown",
      "Glassboro",
      "Glendora",
      "Gloucester city",
      "Bellmawr",
      "Grenloch",
      "Haddonfield",
      "Haddon heights",
      "Hainesport",
      "Batsto",
      "Hancocks bridge",
      "Harrisonville",
      "Jobstown",
      "Juliustown",
      "Voorhees",
      "Lawnside",
      "Willingboro",
      "Lumberton",
      "Magnolia",
      "Manahawkin",
      "Mantua",
      "Maple shade",
      "Marlton",
      "Mount laurel",
      "Medford lakes",
      "Mickleton",
      "Moorestown",
      "Mount ephraim",
      "Eastampton twp",
      "Mount royal",
      "Mullica hill",
      "National park",
      "New lisbon",
      "Palmyra",
      "Paulsboro",
      "Pedricktown",
      "Pemberton",
      "Carneys point",
      "Pennsville",
      "Pitman",
      "Quinton",
      "Rancocas",
      "Richwood",
      "Delanco",
      "Cinnaminson",
      "Runnemede",
      "Salem",
      "Sewell",
      "Sicklerville",
      "Somerdale",
      "Stratford",
      "Swedesboro",
      "Thorofare",
      "Tuckerton",
      "Southampton",
      "Waterford works",
      "Wenonah",
      "West berlin",
      "West creek",
      "Westville",
      "Williamstown",
      "Deptford",
      "Woodbury heights",
      "Woodstown",
      "Zcta 080hh",
      "Zcta 080xx",
      "Camden",
      "Audubon",
      "Oaklyn",
      "Collingswood",
      "Merchantville",
      "Delair",
      "Zcta 081hh",
      "Smithville",
      "Avalon",
      "Brigantine",
      "North cape may",
      "Cape may court h",
      "Cape may point",
      "Egg harbor city",
      "Elwood",
      "Linwood",
      "Marmora",
      "New gretna",
      "Northfield",
      "Ocean city",
      "Ocean view",
      "Pleasantville",
      "Zcta 08234",
      "Port republic",
      "Rio grande",
      "Townsends inlet",
      "Somers point",
      "South dennis",
      "Stone harbor",
      "Strathmere",
      "Villas",
      "Whitesboro",
      "North wildwood",
      "Corbin city",
      "Zcta 082hh",
      "Seabrook",
      "Buena",
      "Cedarville",
      "Clayton",
      "Deerfield street",
      "Delmont",
      "Dorchester",
      "Dorothy",
      "Elmer",
      "Estell manor",
      "Fortescue",
      "Franklinville",
      "Greenwich",
      "Heislerville",
      "Landisville",
      "Leesburg",
      "Malaga",
      "Mauricetown",
      "Mays landing",
      "Millville",
      "Milmay",
      "Minotola",
      "Monroeville",
      "Newfield",
      "Newport",
      "Newtonville",
      "Port elizabeth",
      "Port norris",
      "Richland",
      "Rosenhayn",
      "Shiloh",
      "Vineland",
      "Zcta 08361",
      "Zcta 083hh",
      "Zcta 083xx",
      "Atlantic city",
      "Margate city",
      "Longport",
      "Ventnor city",
      "Zcta 084hh",
      "Allentown",
      "Belle mead",
      "Bordentown",
      "Clarksburg",
      "Cookstown",
      "Cranbury",
      "Creamridge",
      "Crosswicks",
      "Florence",
      "Hightstown",
      "Hopewell",
      "Jackson",
      "Kingston",
      "Lambertville",
      "New egypt",
      "Pennington",
      "Perrineville",
      "Plainsboro",
      "Princeton",
      "Princeton juncti",
      "Ringoes",
      "Rocky hill",
      "Roebling",
      "Roosevelt",
      "Skillman",
      "Stockton",
      "Titusville",
      "Windsor",
      "Wrightstown",
      "Zcta 085hh",
      "Trenton",
      "Hamilton",
      "Mercerville",
      "Yardville",
      "West trenton",
      "Fort dix",
      "Mc guire afb",
      "Lawrenceville",
      "Zcta 086hh",
      "Lakewood",
      "Allenwood",
      "Bayville",
      "Beachwood",
      "Osbornsville",
      "Brick",
      "Brielle",
      "Forked river",
      "Island heights",
      "Lakehurst naec",
      "Lanoka harbor",
      "Lavallette",
      "Manasquan",
      "Mantoloking",
      "Ocean gate",
      "Pine beach",
      "Bay head",
      "Sea girt",
      "Seaside heights",
      "Seaside park",
      "Toms river",
      "Waretown",
      "Whiting",
      "Zcta 087hh",
      "Zcta 087xx",
      "Annandale",
      "Pattenburg",
      "Bloomsbury",
      "Bound brook",
      "Bridgewater",
      "Clinton",
      "Dayton",
      "Green brook",
      "East brunswick",
      "Edison",
      "Flagtown",
      "Flemington",
      "Franklin park",
      "Kendall park",
      "Frenchtown",
      "Glen gardner",
      "Hampton",
      "Helmetta",
      "High bridge",
      "Iselin",
      "Jamesburg",
      "Keasbey",
      "Lebanon",
      "Manville",
      "Martinsville",
      "Metuchen",
      "Middlesex",
      "Milford",
      "Milltown",
      "Monmouth junctio",
      "Neshanic station",
      "Piscataway",
      "Old bridge",
      "Oldwick",
      "Parlin",
      "Perth amboy",
      "Fords",
      "Alpha",
      "Pittstown",
      "Raritan",
      "Sayreville",
      "Somerset",
      "North branch",
      "Laurence harbor",
      "South bound broo",
      "South river",
      "Spotswood",
      "Stewartsville",
      "Three bridges",
      "Whitehouse stati",
      "Zcta 088hh",
      "New brunswick",
      "North brunswick",
      "Highland park",
      "Zcta 089hh",
      "",
    ],
    ZipCodes: {
      Avenel: ["07001"],
      Bayonne: ["07002"],
      Bloomfield: ["07003"],
      Fairfield: ["07004"],
      Boonton: ["07005"],
      "West caldwell": ["07006"],
      Carteret: ["07008"],
      "Cedar grove": ["07009"],
      "Cliffside park": ["07010"],
      Clifton: ["07011", "07012", "07013", "07014"],
      Cranford: ["07016"],
      "East orange": ["07017", "07018"],
      Edgewater: ["07020"],
      "Essex fells": ["07021"],
      Fairview: ["07022"],
      Fanwood: ["07023"],
      "Fort lee": ["07024"],
      Garfield: ["07026"],
      Garwood: ["07027"],
      "Glen ridge": ["07028"],
      Kearny: ["07029", "07032"],
      Hoboken: ["07030"],
      "North arlington": ["07031"],
      Kenilworth: ["07033"],
      "Lake hiawatha": ["07034"],
      "Lincoln park": ["07035"],
      Linden: ["07036"],
      Livingston: ["07039"],
      Maplewood: ["07040"],
      Millburn: ["07041"],
      Montclair: ["07042", "07043"],
      Verona: ["07044"],
      Montville: ["07045"],
      "Mountain lakes": ["07046"],
      "North bergen": ["07047"],
      Orange: ["07050"],
      "West orange": ["07052"],
      Parsippany: ["07054"],
      Passaic: ["07055"],
      Wallington: ["07057"],
      "Pine brook": ["07058"],
      Warren: ["07059"],
      "North plainfield": ["07060", "07062", "07063"],
      "Port reading": ["07064"],
      Rahway: ["07065"],
      Clark: ["07066"],
      Colonia: ["07067"],
      Roseland: ["07068"],
      Rutherford: ["07070"],
      Lyndhurst: ["07071"],
      Carlstadt: ["07072"],
      "East rutherford": ["07073"],
      Moonachie: ["07074"],
      "Wood ridge": ["07075"],
      "Scotch plains": ["07076"],
      Sewaren: ["07077"],
      "Short hills": ["07078"],
      "South orange": ["07079"],
      "South plainfield": ["07080"],
      Springfield: ["07081"],
      Towaco: ["07082"],
      Union: ["07083"],
      Weehawken: ["07087"],
      Vauxhall: ["07088"],
      Westfield: ["07090"],
      Mountainside: ["07092"],
      Guttenberg: ["07093"],
      Secaucus: ["07094"],
      Woodbridge: ["07095"],
      "Zcta 070hh": ["070HH"],
      Newark: [
        "07102",
        "07103",
        "07104",
        "07105",
        "07106",
        "07107",
        "07108",
        "07112",
        "07114",
      ],
      Belleville: ["07109"],
      Nutley: ["07110"],
      Irvington: ["07111"],
      "Zcta 071hh": ["071HH"],
      Elizabeth: ["07201", "07202", "07206", "07208"],
      Roselle: ["07203"],
      "Roselle park": ["07204"],
      Hillside: ["07205"],
      "Zcta 072hh": ["072HH"],
      "Jersey city": ["07302", "07304", "07305", "07306", "07307", "07310"],
      "Zcta 073hh": ["073HH"],
      Allendale: ["07401"],
      Bloomingdale: ["07403"],
      Kinnelon: ["07405"],
      "Elmwood park": ["07407"],
      "Fair lawn": ["07410"],
      Franklin: ["07416"],
      "Franklin lakes": ["07417"],
      Glenwood: ["07418"],
      Hamburg: ["07419"],
      Haskell: ["07420"],
      Hewitt: ["07421"],
      "Highland lakes": ["07422"],
      "Ho ho kus": ["07423"],
      "West paterson": ["07424"],
      "Mc afee": ["07428"],
      Mahwah: ["07430"],
      "Midland park": ["07432"],
      Newfoundland: ["07435"],
      Oakland: ["07436"],
      Milton: ["07438"],
      Ogdensburg: ["07439"],
      Pequannock: ["07440"],
      "Pompton lakes": ["07442"],
      "Pompton plains": ["07444"],
      Ramsey: ["07446"],
      Ridgewood: ["07450"],
      "Glen rock": ["07452"],
      Ringwood: ["07456"],
      Riverdale: ["07457"],
      "Upper saddle riv": ["07458"],
      Stockholm: ["07460"],
      Sussex: ["07461"],
      Vernon: ["07462"],
      Waldwick: ["07463"],
      Wanaque: ["07465"],
      Wayne: ["07470"],
      "West milford": ["07480"],
      Wyckoff: ["07481"],
      "Zcta 074hh": ["074HH"],
      Paterson: [
        "07501",
        "07502",
        "07503",
        "07504",
        "07505",
        "07513",
        "07514",
        "07522",
        "07524",
      ],
      Hawthorne: ["07506"],
      Haledon: ["07508"],
      Totowa: ["07512"],
      "Zcta 075hh": ["075HH"],
      Hackensack: ["07601"],
      Bogota: ["07603"],
      "Hasbrouck height": ["07604"],
      Leonia: ["07605"],
      "South hackensack": ["07606"],
      Maywood: ["07607"],
      Teterboro: ["07608"],
      Alpine: ["07620"],
      Bergenfield: ["07621"],
      Closter: ["07624"],
      Cresskill: ["07626"],
      Demarest: ["07627"],
      Dumont: ["07628"],
      Emerson: ["07630"],
      Englewood: ["07631"],
      "Englewood cliffs": ["07632"],
      "Harrington park": ["07640"],
      Haworth: ["07641"],
      Hillsdale: ["07642"],
      "Little ferry": ["07643"],
      Lodi: ["07644"],
      Montvale: ["07645"],
      "New milford": ["07646"],
      Rockleigh: ["07647"],
      Norwood: ["07648"],
      Oradell: ["07649"],
      "Palisades park": ["07650"],
      Paramus: ["07652"],
      "Park ridge": ["07656"],
      Ridgefield: ["07657"],
      "Ridgefield park": ["07660"],
      "River edge": ["07661"],
      "Saddle brook": ["07662"],
      "Zcta 07663": ["07663"],
      Teaneck: ["07666"],
      Tenafly: ["07670"],
      "Old tappan": ["07675"],
      "Zcta 076hh": ["076HH"],
      Suburban: ["07701"],
      Shrewsbury: ["07702"],
      "Fort monmouth": ["07703"],
      "Fair haven": ["07704"],
      Allenhurst: ["07711"],
      Ocean: ["07712"],
      "Atlantic highlan": ["07716"],
      "Avon by the sea": ["07717"],
      Belford: ["07718"],
      Wall: ["07719"],
      "Bradley beach": ["07720"],
      Cliffwood: ["07721"],
      "Colts neck": ["07722"],
      Deal: ["07723"],
      Eatontown: ["07724"],
      Manalapan: ["07726"],
      Farmingdale: ["07727"],
      Freehold: ["07728"],
      Hazlet: ["07730"],
      Howell: ["07731"],
      "Fort hancock": ["07732"],
      Holmdel: ["07733"],
      Keansburg: ["07734"],
      Keyport: ["07735"],
      Leonardo: ["07737"],
      Lincroft: ["07738"],
      "Little silver": ["07739"],
      "Long branch": ["07740"],
      Marlboro: ["07746"],
      Matawan: ["07747"],
      "New monmouth": ["07748"],
      "Monmouth beach": ["07750"],
      Morganville: ["07751"],
      "Neptune city": ["07753"],
      Oakhurst: ["07755"],
      "Ocean grove": ["07756"],
      Oceanport: ["07757"],
      "Port monmouth": ["07758"],
      "Sea bright": ["07760"],
      "Spring lake": ["07762"],
      "West long branch": ["07764"],
      "Zcta 077hh": ["077HH"],
      "Mine hill": ["07801"],
      "Zcta 07803": ["07803"],
      Andover: ["07821"],
      Augusta: ["07822"],
      Belvidere: ["07823"],
      Blairstown: ["07825"],
      Branchville: ["07826"],
      Montague: ["07827"],
      "Budd lake": ["07828"],
      Califon: ["07830"],
      Columbia: ["07832"],
      Delaware: ["07833"],
      Denville: ["07834"],
      Flanders: ["07836"],
      "Great meadows": ["07838"],
      Hackettstown: ["07840"],
      Hibernia: ["07842"],
      Hopatcong: ["07843"],
      Johnsonburg: ["07846"],
      Kenvil: ["07847"],
      Lafayette: ["07848"],
      "Lake hopatcong": ["07849"],
      Landing: ["07850"],
      Layton: ["07851"],
      Ledgewood: ["07852"],
      "Long valley": ["07853"],
      "Mount arlington": ["07856"],
      Netcong: ["07857"],
      "Fredon township": ["07860"],
      Oxford: ["07863"],
      "Port murray": ["07865"],
      Rockaway: ["07866"],
      Randolph: ["07869"],
      Sparta: ["07871"],
      Stanhope: ["07874"],
      Succasunna: ["07876"],
      "Mount tabor": ["07878"],
      Washington: ["07882", "08012"],
      Wharton: ["07885"],
      "Zcta 078hh": ["078HH"],
      Summit: ["07901"],
      "Basking ridge": ["07920"],
      Bedminster: ["07921"],
      "Berkeley heights": ["07922"],
      Bernardsville: ["07924"],
      "Cedar knolls": ["07927"],
      Chatham: ["07928"],
      Chester: ["07930"],
      "Far hills": ["07931"],
      "Florham park": ["07932"],
      Gillette: ["07933"],
      Gladstone: ["07934"],
      "Green village": ["07935"],
      "East hanover": ["07936"],
      Madison: ["07940"],
      Mendham: ["07945"],
      Millington: ["07946"],
      "Greystone park": ["07950"],
      Morristown: ["07960"],
      "Mount freedom": ["07970"],
      "New providence": ["07974"],
      "New vernon": ["07976"],
      Peapack: ["07977"],
      Pottersville: ["07979"],
      Stirling: ["07980"],
      Whippany: ["07981"],
      Alloway: ["08001"],
      "Cherry hill": ["08002", "08003", "08034"],
      Winslow: ["08004", "08095"],
      Barnegat: ["08005"],
      "Barnegat light": ["08006"],
      Barrington: ["08007"],
      "Harvey cedars": ["08008"],
      Berlin: ["08009"],
      Beverly: ["08010"],
      Birmingham: ["08011"],
      Bridgeport: ["08014"],
      "Browns mills": ["08015"],
      Burlington: ["08016"],
      Chatsworth: ["08019"],
      Clarksboro: ["08020"],
      "Laurel springs": ["08021"],
      Columbus: ["08022"],
      Deepwater: ["08023"],
      Gibbsboro: ["08026"],
      Gibbstown: ["08027"],
      Glassboro: ["08028"],
      Glendora: ["08029"],
      "Gloucester city": ["08030"],
      Bellmawr: ["08031"],
      Grenloch: ["08032"],
      Haddonfield: ["08033"],
      "Haddon heights": ["08035"],
      Hainesport: ["08036"],
      Batsto: ["08037"],
      "Hancocks bridge": ["08038"],
      Harrisonville: ["08039"],
      Jobstown: ["08041"],
      Juliustown: ["08042"],
      Voorhees: ["08043"],
      Lawnside: ["08045"],
      Willingboro: ["08046"],
      Lumberton: ["08048"],
      Magnolia: ["08049"],
      Manahawkin: ["08050"],
      Mantua: ["08051"],
      "Maple shade": ["08052"],
      Marlton: ["08053"],
      "Mount laurel": ["08054"],
      "Medford lakes": ["08055"],
      Mickleton: ["08056"],
      Moorestown: ["08057"],
      "Mount ephraim": ["08059"],
      "Eastampton twp": ["08060"],
      "Mount royal": ["08061"],
      "Mullica hill": ["08062"],
      "National park": ["08063"],
      "New lisbon": ["08064"],
      Palmyra: ["08065"],
      Paulsboro: ["08066"],
      Pedricktown: ["08067"],
      Pemberton: ["08068"],
      "Carneys point": ["08069"],
      Pennsville: ["08070"],
      Pitman: ["08071"],
      Quinton: ["08072"],
      Rancocas: ["08073"],
      Richwood: ["08074"],
      Delanco: ["08075"],
      Cinnaminson: ["08077"],
      Runnemede: ["08078"],
      Salem: ["08079"],
      Sewell: ["08080"],
      Sicklerville: ["08081"],
      Somerdale: ["08083"],
      Stratford: ["08084"],
      Swedesboro: ["08085"],
      Thorofare: ["08086"],
      Tuckerton: ["08087"],
      Southampton: ["08088"],
      "Waterford works": ["08089"],
      Wenonah: ["08090"],
      "West berlin": ["08091"],
      "West creek": ["08092"],
      Westville: ["08093"],
      Williamstown: ["08094"],
      Deptford: ["08096"],
      "Woodbury heights": ["08097"],
      Woodstown: ["08098"],
      "Zcta 080hh": ["080HH"],
      "Zcta 080xx": ["080XX"],
      Camden: ["08102", "08103", "08104", "08105"],
      Audubon: ["08106"],
      Oaklyn: ["08107"],
      Collingswood: ["08108"],
      Merchantville: ["08109"],
      Delair: ["08110"],
      "Zcta 081hh": ["081HH"],
      Smithville: ["08201"],
      Avalon: ["08202"],
      Brigantine: ["08203"],
      "North cape may": ["08204"],
      "Cape may court h": ["08210"],
      "Cape may point": ["08212"],
      "Egg harbor city": ["08215"],
      Elwood: ["08217"],
      Linwood: ["08221"],
      Marmora: ["08223"],
      "New gretna": ["08224"],
      Northfield: ["08225"],
      "Ocean city": ["08226"],
      "Ocean view": ["08230"],
      Pleasantville: ["08232"],
      "Zcta 08234": ["08234"],
      "Port republic": ["08241"],
      "Rio grande": ["08242"],
      "Townsends inlet": ["08243"],
      "Somers point": ["08244"],
      "South dennis": ["08245"],
      "Stone harbor": ["08247"],
      Strathmere: ["08248"],
      Villas: ["08251"],
      Whitesboro: ["08252"],
      "North wildwood": ["08260"],
      "Corbin city": ["08270"],
      "Zcta 082hh": ["082HH"],
      Seabrook: ["08302"],
      Buena: ["08310"],
      Cedarville: ["08311"],
      Clayton: ["08312"],
      "Deerfield street": ["08313"],
      Delmont: ["08314"],
      Dorchester: ["08316"],
      Dorothy: ["08317"],
      Elmer: ["08318"],
      "Estell manor": ["08319"],
      Fortescue: ["08321"],
      Franklinville: ["08322"],
      Greenwich: ["08323"],
      Heislerville: ["08324"],
      Landisville: ["08326"],
      Leesburg: ["08327"],
      Malaga: ["08328"],
      Mauricetown: ["08329"],
      "Mays landing": ["08330"],
      Millville: ["08332"],
      Milmay: ["08340"],
      Minotola: ["08341"],
      Monroeville: ["08343"],
      Newfield: ["08344"],
      Newport: ["08345"],
      Newtonville: ["08346"],
      "Port elizabeth": ["08348"],
      "Port norris": ["08349"],
      Richland: ["08350"],
      Rosenhayn: ["08352"],
      Shiloh: ["08353"],
      Vineland: ["08360"],
      "Zcta 08361": ["08361"],
      "Zcta 083hh": ["083HH"],
      "Zcta 083xx": ["083XX"],
      "Atlantic city": ["08401"],
      "Margate city": ["08402"],
      Longport: ["08403"],
      "Ventnor city": ["08406"],
      "Zcta 084hh": ["084HH"],
      Allentown: ["08501"],
      "Belle mead": ["08502"],
      Bordentown: ["08505"],
      Clarksburg: ["08510"],
      Cookstown: ["08511"],
      Cranbury: ["08512"],
      Creamridge: ["08514"],
      Crosswicks: ["08515"],
      Florence: ["08518"],
      Hightstown: ["08520"],
      Hopewell: ["08525"],
      Jackson: ["08527"],
      Kingston: ["08528"],
      Lambertville: ["08530"],
      "New egypt": ["08533"],
      Pennington: ["08534"],
      Perrineville: ["08535"],
      Plainsboro: ["08536"],
      Princeton: ["08540", "08542"],
      "Princeton juncti": ["08550"],
      Ringoes: ["08551"],
      "Rocky hill": ["08553"],
      Roebling: ["08554"],
      Roosevelt: ["08555"],
      Skillman: ["08558"],
      Stockton: ["08559"],
      Titusville: ["08560"],
      Windsor: ["08561"],
      Wrightstown: ["08562"],
      "Zcta 085hh": ["085HH"],
      Trenton: ["08608", "08618", "08625", "08638"],
      Hamilton: ["08609", "08610", "08611", "08629", "08690", "08691"],
      Mercerville: ["08619"],
      Yardville: ["08620"],
      "West trenton": ["08628"],
      "Fort dix": ["08640"],
      "Mc guire afb": ["08641"],
      Lawrenceville: ["08648"],
      "Zcta 086hh": ["086HH"],
      Lakewood: ["08701"],
      Allenwood: ["08720"],
      Bayville: ["08721"],
      Beachwood: ["08722"],
      Osbornsville: ["08723"],
      Brick: ["08724"],
      Brielle: ["08730"],
      "Forked river": ["08731"],
      "Island heights": ["08732"],
      "Lakehurst naec": ["08733"],
      "Lanoka harbor": ["08734"],
      Lavallette: ["08735"],
      Manasquan: ["08736"],
      Mantoloking: ["08738"],
      "Ocean gate": ["08740"],
      "Pine beach": ["08741"],
      "Bay head": ["08742"],
      "Sea girt": ["08750"],
      "Seaside heights": ["08751"],
      "Seaside park": ["08752"],
      "Toms river": ["08753", "08755", "08757"],
      Waretown: ["08758"],
      Whiting: ["08759"],
      "Zcta 087hh": ["087HH"],
      "Zcta 087xx": ["087XX"],
      Annandale: ["08801"],
      Pattenburg: ["08802"],
      Bloomsbury: ["08804"],
      "Bound brook": ["08805"],
      Bridgewater: ["08807"],
      Clinton: ["08809"],
      Dayton: ["08810"],
      "Green brook": ["08812"],
      "East brunswick": ["08816"],
      Edison: ["08817", "08820", "08837"],
      Flagtown: ["08821"],
      Flemington: ["08822"],
      "Franklin park": ["08823"],
      "Kendall park": ["08824"],
      Frenchtown: ["08825"],
      "Glen gardner": ["08826"],
      Hampton: ["08827"],
      Helmetta: ["08828"],
      "High bridge": ["08829"],
      Iselin: ["08830"],
      Jamesburg: ["08831"],
      Keasbey: ["08832"],
      Lebanon: ["08833"],
      Manville: ["08835"],
      Martinsville: ["08836"],
      Metuchen: ["08840"],
      Middlesex: ["08846"],
      Milford: ["08848"],
      Milltown: ["08850"],
      "Monmouth junctio": ["08852"],
      "Neshanic station": ["08853"],
      Piscataway: ["08854"],
      "Old bridge": ["08857"],
      Oldwick: ["08858"],
      Parlin: ["08859"],
      "Perth amboy": ["08861"],
      Fords: ["08863"],
      Alpha: ["08865"],
      Pittstown: ["08867"],
      Raritan: ["08869"],
      Sayreville: ["08872"],
      Somerset: ["08873"],
      "North branch": ["08876"],
      "Laurence harbor": ["08879"],
      "South bound broo": ["08880"],
      "South river": ["08882"],
      Spotswood: ["08884"],
      Stewartsville: ["08886"],
      "Three bridges": ["08887"],
      "Whitehouse stati": ["08889"],
      "Zcta 088hh": ["088HH"],
      "New brunswick": ["08901"],
      "North brunswick": ["08902"],
      "Highland park": ["08904"],
      "Zcta 089hh": ["089HH"],
      "": ["10004"],
    },
  },
  {
    abbrevation: "NM",
    name: "New mexico",
    Cities: [
      "",
      "",
      "Algodones",
      "Boys ranch",
      "Bernalillo",
      "Bluewater",
      "Bosque",
      "Casa blanca",
      "Cedar crest",
      "Cerrillos",
      "Claunch",
      "Coyote",
      "Cuba",
      "Cubero",
      "Edgewood",
      "Estancia",
      "Gallina",
      "Counselor",
      "Grants",
      "Isleta",
      "Jarales",
      "Jemez pueblo",
      "Jemez springs",
      "Canoncito",
      "La jara",
      "Lajoya",
      "Lindrith",
      "Los lunas",
      "Mc intosh",
      "Pueblo of acoma",
      "Moriarty",
      "Mountainair",
      "Nageezi",
      "New laguna",
      "Paguate",
      "Cochiti pueblo",
      "Peralta",
      "Placitas",
      "Ponderosa",
      "Prewitt",
      "Sandia park",
      "Corrales",
      "San fidel",
      "Santo domingo pu",
      "Zia pueblo",
      "Stanley",
      "Tijeras",
      "Tome",
      "Torreon",
      "Veguita",
      "Willard",
      "Youngsville",
      "Bosque farms",
      "Clines corners",
      "Cochiti lake",
      "Zcta 870hh",
      "Zcta 870xx",
      "Albuquerque",
      "Alameda",
      "Rio rancho",
      "Zcta 871xx",
      "Gallup",
      "Brimhall",
      "Church rock",
      "Continental divi",
      "Crownpoint",
      "Fence lake",
      "Fort wingate",
      "Gamerco",
      "Mentmore",
      "Mexican springs",
      "Ramah",
      "Rehoboth",
      "Thoreau",
      "Tohatchi",
      "Vanderwagen",
      "Zuni",
      "Navajo",
      "Jamestown",
      "Pinehill",
      "Sheep springs",
      "Yatahey",
      "Zcta 873xx",
      "Farmington",
      "Aztec",
      "Blanco",
      "Bloomfield",
      "Flora vista",
      "Fruitland",
      "Kirtland",
      "La plata",
      "Navajo dam",
      "Shiprock",
      "Waterflow",
      "Newcomb",
      "Sanostee",
      "Zcta 874hh",
      "Zcta 874xx",
      "Pojoaque valley",
      "Santa fe",
      "Abiquiu",
      "Alcalde",
      "Amalia",
      "Arroyo hondo",
      "Arroyo seco",
      "Canjilon",
      "Canones",
      "Carson",
      "Cebolla",
      "Cerro",
      "Chama",
      "Chamisal",
      "Cundiyo",
      "Costilla",
      "Taos ski valley",
      "Dixon",
      "Dulce",
      "El prado",
      "El rito",
      "Embudo",
      "Espanola",
      "Fairview",
      "Glorieta",
      "Hernandez",
      "La madera",
      "Lamy",
      "Llano",
      "Los alamos",
      "Medanales",
      "Ojo caliente",
      "Los ojos",
      "Pecos",
      "Penasco",
      "Questa",
      "Ranchos de taos",
      "Red river",
      "Ribera",
      "Rowe",
      "San cristobal",
      "San jose",
      "San juan pueblo",
      "Santa cruz",
      "Serafina",
      "Taos",
      "Tererro",
      "Tierra amarilla",
      "Tres piedras",
      "Truchas",
      "Vadito",
      "Valdez",
      "Vallecitos",
      "Velarde",
      "Villanueva",
      "Zcta 875hh",
      "Zcta 875xx",
      "Las vegas",
      "Angel fire",
      "Anton chico",
      "Buena vista",
      "Chacon",
      "Cimarron",
      "Cleveland",
      "Eagle nest",
      "Guadalupita",
      "Holman",
      "La loma",
      "Maxwell",
      "Miami",
      "Mills",
      "Montezuma",
      "Mora",
      "Albert",
      "Ocate",
      "Ojo feliz",
      "Rainsville",
      "Raton",
      "Rociada",
      "Roy",
      "Sapello",
      "Springer",
      "Valmora",
      "Wagon mound",
      "Watrous",
      "Zcta 877xx",
      "Socorro",
      "Aragon",
      "Datil",
      "Lemitar",
      "Luna",
      "Alamo",
      "Pie town",
      "Polvadera",
      "Quemado",
      "Reserve",
      "San acacia",
      "San antonio",
      "Truth or consequ",
      "Arrey",
      "Caballo",
      "Derry",
      "Elephant butte",
      "Garfield",
      "Hatch",
      "Monticello",
      "Rincon",
      "Salem",
      "Williamsburg",
      "Winston",
      "Zcta 879hh",
      "Zcta 879xx",
      "Las cruces",
      "White sands miss",
      "Santa teresa",
      "Playas",
      "Animas",
      "Chaparral",
      "Arenas valley",
      "Vanadium",
      "Berino",
      "Buckhorn",
      "Central",
      "Chamberino",
      "Columbus",
      "Deming",
      "Fairacres",
      "Fort bayard",
      "Gila",
      "Glenwood",
      "Hachita",
      "San lorenzo",
      "Hillsboro",
      "Hurley",
      "La mesa",
      "Road forks",
      "Mesilla park",
      "Mesquite",
      "Mimbres",
      "Mule creek",
      "Organ",
      "Pinos altos",
      "Redrock",
      "Rodeo",
      "Silver city",
      "Sunland park",
      "Tyrone",
      "Vado",
      "Zcta 880xx",
      "Clovis",
      "Broadview",
      "Causey",
      "Crossroads",
      "Dora",
      "Elida",
      "Floyd",
      "Fort sumner",
      "Grady",
      "House",
      "Melrose",
      "Milnesand",
      "Pep",
      "Portales",
      "Rogers",
      "Taiban",
      "Texico",
      "Yeso",
      "Roswell",
      "Artesia",
      "Caprock",
      "Carlsbad",
      "Dexter",
      "Eunice",
      "Hagerman",
      "Hobbs",
      "Zcta 88242",
      "Hope",
      "Jal",
      "Lake arthur",
      "Lakewood",
      "Loco hills",
      "Loving",
      "Lovington",
      "Mc donald",
      "Malaga",
      "Maljamar",
      "Monument",
      "Tatum",
      "Whites city",
      "Carrizozo",
      "Alamogordo",
      "Alto",
      "Bent",
      "Capitan",
      "Cloudcroft",
      "Corona",
      "Encino",
      "Fort stanton",
      "Glencoe",
      "High rolls mount",
      "Holloman air for",
      "Hondo",
      "La luz",
      "Lincoln",
      "Mayhill",
      "Mescalero",
      "Nogal",
      "Picacho",
      "Pinon",
      "Ruidoso",
      "Ruidoso downs",
      "Sacramento",
      "San patricio",
      "Timberon",
      "Tinnie",
      "Tularosa",
      "Vaughn",
      "Weed",
      "Zcta 883xx",
      "Tucumcari",
      "Amistad",
      "Bard",
      "Capulin",
      "Clayton",
      "Conchas dam",
      "Cuervo",
      "Des moines",
      "Folsom",
      "Garita",
      "Gladstone",
      "Grenville",
      "Logan",
      "Mc alister",
      "Nara visa",
      "Newkirk",
      "San jon",
      "Pastura",
      "Sedan",
      "Seneca",
      "Zcta 884hh",
    ],
    ZipCodes: {
      "": ["79922", "85534", "86504", "86508", "86514", "86515"],
      Algodones: ["87001"],
      "Boys ranch": ["87002"],
      Bernalillo: ["87004"],
      Bluewater: ["87005"],
      Bosque: ["87006"],
      "Casa blanca": ["87007"],
      "Cedar crest": ["87008"],
      Cerrillos: ["87010"],
      Claunch: ["87011"],
      Coyote: ["87012"],
      Cuba: ["87013"],
      Cubero: ["87014"],
      Edgewood: ["87015"],
      Estancia: ["87016"],
      Gallina: ["87017"],
      Counselor: ["87018"],
      Grants: ["87020"],
      Isleta: ["87022"],
      Jarales: ["87023"],
      "Jemez pueblo": ["87024"],
      "Jemez springs": ["87025"],
      Canoncito: ["87026"],
      "La jara": ["87027"],
      Lajoya: ["87028"],
      Lindrith: ["87029"],
      "Los lunas": ["87031"],
      "Mc intosh": ["87032"],
      "Pueblo of acoma": ["87034"],
      Moriarty: ["87035"],
      Mountainair: ["87036"],
      Nageezi: ["87037"],
      "New laguna": ["87038"],
      Paguate: ["87040"],
      "Cochiti pueblo": ["87041", "87072"],
      Peralta: ["87042"],
      Placitas: ["87043"],
      Ponderosa: ["87044"],
      Prewitt: ["87045"],
      "Sandia park": ["87047"],
      Corrales: ["87048"],
      "San fidel": ["87049"],
      "Santo domingo pu": ["87052"],
      "Zia pueblo": ["87053"],
      Stanley: ["87056"],
      Tijeras: ["87059"],
      Tome: ["87060"],
      Torreon: ["87061"],
      Veguita: ["87062"],
      Willard: ["87063"],
      Youngsville: ["87064"],
      "Bosque farms": ["87068"],
      "Clines corners": ["87070"],
      "Cochiti lake": ["87083"],
      "Zcta 870hh": ["870HH"],
      "Zcta 870xx": ["870XX"],
      Albuquerque: [
        "87102",
        "87104",
        "87105",
        "87106",
        "87107",
        "87108",
        "87109",
        "87110",
        "87111",
        "87112",
        "87113",
        "87116",
        "87118",
        "87120",
        "87121",
        "87122",
        "87123",
      ],
      Alameda: ["87114"],
      "Rio rancho": ["87124"],
      "Zcta 871xx": ["871XX"],
      Gallup: ["87301", "87305"],
      Brimhall: ["87310"],
      "Church rock": ["87311"],
      "Continental divi": ["87312"],
      Crownpoint: ["87313"],
      "Fence lake": ["87315"],
      "Fort wingate": ["87316"],
      Gamerco: ["87317"],
      Mentmore: ["87319"],
      "Mexican springs": ["87320"],
      Ramah: ["87321"],
      Rehoboth: ["87322"],
      Thoreau: ["87323"],
      Tohatchi: ["87325"],
      Vanderwagen: ["87326"],
      Zuni: ["87327"],
      Navajo: ["87328"],
      Jamestown: ["87347"],
      Pinehill: ["87357"],
      "Sheep springs": ["87364"],
      Yatahey: ["87375"],
      "Zcta 873xx": ["873XX"],
      Farmington: ["87401", "87402"],
      Aztec: ["87410"],
      Blanco: ["87412"],
      Bloomfield: ["87413"],
      "Flora vista": ["87415"],
      Fruitland: ["87416"],
      Kirtland: ["87417"],
      "La plata": ["87418"],
      "Navajo dam": ["87419"],
      Shiprock: ["87420"],
      Waterflow: ["87421"],
      Newcomb: ["87455"],
      Sanostee: ["87461"],
      "Zcta 874hh": ["874HH"],
      "Zcta 874xx": ["874XX"],
      "Pojoaque valley": ["87501"],
      "Santa fe": ["87505"],
      Abiquiu: ["87510"],
      Alcalde: ["87511"],
      Amalia: ["87512"],
      "Arroyo hondo": ["87513"],
      "Arroyo seco": ["87514"],
      Canjilon: ["87515"],
      Canones: ["87516"],
      Carson: ["87517"],
      Cebolla: ["87518"],
      Cerro: ["87519"],
      Chama: ["87520"],
      Chamisal: ["87521"],
      Cundiyo: ["87522"],
      Costilla: ["87524"],
      "Taos ski valley": ["87525"],
      Dixon: ["87527"],
      Dulce: ["87528"],
      "El prado": ["87529"],
      "El rito": ["87530"],
      Embudo: ["87531"],
      Espanola: ["87532"],
      Fairview: ["87533"],
      Glorieta: ["87535"],
      Hernandez: ["87537"],
      "La madera": ["87539"],
      Lamy: ["87540"],
      Llano: ["87543"],
      "Los alamos": ["87544"],
      Medanales: ["87548"],
      "Ojo caliente": ["87549"],
      "Los ojos": ["87551"],
      Pecos: ["87552"],
      Penasco: ["87553"],
      Questa: ["87556"],
      "Ranchos de taos": ["87557"],
      "Red river": ["87558"],
      Ribera: ["87560"],
      Rowe: ["87562"],
      "San cristobal": ["87564"],
      "San jose": ["87565"],
      "San juan pueblo": ["87566"],
      "Santa cruz": ["87567"],
      Serafina: ["87569"],
      Taos: ["87571"],
      Tererro: ["87573"],
      "Tierra amarilla": ["87575"],
      "Tres piedras": ["87577"],
      Truchas: ["87578"],
      Vadito: ["87579"],
      Valdez: ["87580"],
      Vallecitos: ["87581"],
      Velarde: ["87582"],
      Villanueva: ["87583"],
      "Zcta 875hh": ["875HH"],
      "Zcta 875xx": ["875XX"],
      "Las vegas": ["87701"],
      "Angel fire": ["87710"],
      "Anton chico": ["87711"],
      "Buena vista": ["87712"],
      Chacon: ["87713"],
      Cimarron: ["87714"],
      Cleveland: ["87715"],
      "Eagle nest": ["87718"],
      Guadalupita: ["87722"],
      Holman: ["87723"],
      "La loma": ["87724"],
      Maxwell: ["87728"],
      Miami: ["87729"],
      Mills: ["87730"],
      Montezuma: ["87731"],
      Mora: ["87732"],
      Albert: ["87733"],
      Ocate: ["87734"],
      "Ojo feliz": ["87735"],
      Rainsville: ["87736"],
      Raton: ["87740"],
      Rociada: ["87742"],
      Roy: ["87743"],
      Sapello: ["87745"],
      Springer: ["87747"],
      Valmora: ["87750"],
      "Wagon mound": ["87752"],
      Watrous: ["87753"],
      "Zcta 877xx": ["877XX"],
      Socorro: ["87801"],
      Aragon: ["87820"],
      Datil: ["87821"],
      Lemitar: ["87823"],
      Luna: ["87824"],
      Alamo: ["87825"],
      "Pie town": ["87827"],
      Polvadera: ["87828"],
      Quemado: ["87829"],
      Reserve: ["87830"],
      "San acacia": ["87831"],
      "San antonio": ["87832"],
      "Truth or consequ": ["87901"],
      Arrey: ["87930"],
      Caballo: ["87931"],
      Derry: ["87933"],
      "Elephant butte": ["87935"],
      Garfield: ["87936"],
      Hatch: ["87937"],
      Monticello: ["87939"],
      Rincon: ["87940"],
      Salem: ["87941"],
      Williamsburg: ["87942"],
      Winston: ["87943"],
      "Zcta 879hh": ["879HH"],
      "Zcta 879xx": ["879XX"],
      "Las cruces": ["88001", "88003", "88004", "88005", "88011", "88012"],
      "White sands miss": ["88002"],
      "Santa teresa": ["88008"],
      Playas: ["88009"],
      Animas: ["88020"],
      Chaparral: ["88021"],
      "Arenas valley": ["88022"],
      Vanadium: ["88023"],
      Berino: ["88024"],
      Buckhorn: ["88025"],
      Central: ["88026"],
      Chamberino: ["88027"],
      Columbus: ["88029"],
      Deming: ["88030"],
      Fairacres: ["88033"],
      "Fort bayard": ["88036"],
      Gila: ["88038"],
      Glenwood: ["88039"],
      Hachita: ["88040"],
      "San lorenzo": ["88041"],
      Hillsboro: ["88042"],
      Hurley: ["88043"],
      "La mesa": ["88044"],
      "Road forks": ["88045"],
      "Mesilla park": ["88047"],
      Mesquite: ["88048"],
      Mimbres: ["88049"],
      "Mule creek": ["88051"],
      Organ: ["88052"],
      "Pinos altos": ["88053"],
      Redrock: ["88055"],
      Rodeo: ["88056"],
      "Silver city": ["88061"],
      "Sunland park": ["88063"],
      Tyrone: ["88065"],
      Vado: ["88072"],
      "Zcta 880xx": ["880XX"],
      Clovis: ["88101"],
      Broadview: ["88112"],
      Causey: ["88113"],
      Crossroads: ["88114"],
      Dora: ["88115"],
      Elida: ["88116"],
      Floyd: ["88118"],
      "Fort sumner": ["88119"],
      Grady: ["88120"],
      House: ["88121"],
      Melrose: ["88124"],
      Milnesand: ["88125"],
      Pep: ["88126"],
      Portales: ["88130"],
      Rogers: ["88132"],
      Taiban: ["88134"],
      Texico: ["88135"],
      Yeso: ["88136"],
      Roswell: ["88201"],
      Artesia: ["88210"],
      Caprock: ["88213"],
      Carlsbad: ["88220"],
      Dexter: ["88230"],
      Eunice: ["88231"],
      Hagerman: ["88232"],
      Hobbs: ["88240"],
      "Zcta 88242": ["88242"],
      Hope: ["88250"],
      Jal: ["88252"],
      "Lake arthur": ["88253"],
      Lakewood: ["88254"],
      "Loco hills": ["88255"],
      Loving: ["88256"],
      Lovington: ["88260"],
      "Mc donald": ["88262"],
      Malaga: ["88263"],
      Maljamar: ["88264"],
      Monument: ["88265"],
      Tatum: ["88267"],
      "Whites city": ["88268"],
      Carrizozo: ["88301"],
      Alamogordo: ["88310"],
      Alto: ["88312"],
      Bent: ["88314"],
      Capitan: ["88316"],
      Cloudcroft: ["88317"],
      Corona: ["88318"],
      Encino: ["88321"],
      "Fort stanton": ["88323"],
      Glencoe: ["88324"],
      "High rolls mount": ["88325"],
      "Holloman air for": ["88330"],
      Hondo: ["88336"],
      "La luz": ["88337"],
      Lincoln: ["88338"],
      Mayhill: ["88339"],
      Mescalero: ["88340"],
      Nogal: ["88341"],
      Picacho: ["88343"],
      Pinon: ["88344"],
      Ruidoso: ["88345"],
      "Ruidoso downs": ["88346"],
      Sacramento: ["88347"],
      "San patricio": ["88348"],
      Timberon: ["88350"],
      Tinnie: ["88351"],
      Tularosa: ["88352"],
      Vaughn: ["88353"],
      Weed: ["88354"],
      "Zcta 883xx": ["883XX"],
      Tucumcari: ["88401"],
      Amistad: ["88410"],
      Bard: ["88411"],
      Capulin: ["88414"],
      Clayton: ["88415"],
      "Conchas dam": ["88416"],
      Cuervo: ["88417"],
      "Des moines": ["88418"],
      Folsom: ["88419"],
      Garita: ["88421"],
      Gladstone: ["88422"],
      Grenville: ["88424"],
      Logan: ["88426"],
      "Mc alister": ["88427"],
      "Nara visa": ["88430"],
      Newkirk: ["88431"],
      "San jon": ["88434"],
      Pastura: ["88435"],
      Sedan: ["88436"],
      Seneca: ["88437"],
      "Zcta 884hh": ["884HH"],
    },
  },
  {
    abbrevation: "NY",
    name: "New york",
    Cities: [
      "",
      "",
      "New york",
      "Governors island",
      "Zcta 10069",
      "Zcta 102hh",
      "Staten island",
      "Zcta 103hh",
      "Bronx",
      "Zcta 104hh",
      "Amawalk",
      "Ardsley",
      "Ardsley on hudso",
      "Armonk",
      "Bedford",
      "Bedford hills",
      "Brewster",
      "Briarcliff manor",
      "Buchanan",
      "Carmel",
      "Chappaqua",
      "Cold spring",
      "Cross river",
      "Croton falls",
      "Croton on hudson",
      "Dobbs ferry",
      "Elmsford",
      "Garrison",
      "Goldens bridge",
      "Granite springs",
      "Harrison",
      "Hartsdale",
      "Hawthorne",
      "Irvington",
      "Jefferson valley",
      "Katonah",
      "Lake peekskill",
      "Larchmont",
      "Mahopac",
      "Mamaroneck",
      "Millwood",
      "Mohegan lake",
      "Montrose",
      "Mount kisco",
      "Mount vernon",
      "North salem",
      "Ossining",
      "Cortlandt manor",
      "Zcta 10567",
      "Pleasantville",
      "Rye brook",
      "Pound ridge",
      "Purchase",
      "Purdys",
      "Putnam valley",
      "Rye",
      "Heathcote",
      "Shrub oak",
      "Somers",
      "South salem",
      "North tarrytown",
      "Thornwood",
      "Valhalla",
      "Waccabuc",
      "Yorktown heights",
      "Zcta 105hh",
      "White plains",
      "East white plain",
      "Zcta 106hh",
      "Yonkers",
      "Hastings on huds",
      "Tuckahoe",
      "Bronxville",
      "Eastchester",
      "Zcta 107hh",
      "New rochelle",
      "Pelham",
      "Zcta 108hh",
      "Suffern",
      "Blauvelt",
      "Campbell hall",
      "Central valley",
      "Chester",
      "Circleville",
      "Congers",
      "Florida",
      "Garnerville",
      "Goshen",
      "Greenwood lake",
      "Harriman",
      "Haverstraw",
      "Highland falls",
      "Highland mills",
      "Hillburn",
      "Scotchtown",
      "Middletown",
      "Monroe",
      "Monsey",
      "Bardonia",
      "New city",
      "New hampton",
      "Nyack",
      "Orangeburg",
      "Otisville",
      "Palisades",
      "Pearl river",
      "Piermont",
      "Pine island",
      "Pomona",
      "Slate hill",
      "Sterlington",
      "Southfields",
      "Sparkill",
      "Chestnut ridge",
      "Stony point",
      "Tappan",
      "Thiells",
      "Thompson ridge",
      "Tomkins cove",
      "Tuxedo park",
      "Valley cottage",
      "Warwick",
      "Washingtonville",
      "West haverstraw",
      "West nyack",
      "West point",
      "Westtown",
      "Zcta 109hh",
      "Zcta 109xx",
      "Floral park",
      "Alden manor",
      "Glen oaks",
      "Franklin square",
      "Great neck",
      "Kings point cont",
      "Plandome",
      "Hillside manor",
      "New hyde park",
      "Port washington",
      "Zcta 11096",
      "Zcta 110hh",
      "Astoria",
      "Sunnyside",
      "Zcta 111hh",
      "Brooklyn",
      "Zcta 112hh",
      "Flushing",
      "College point",
      "Whitestone",
      "Bayside",
      "Little neck",
      "Fresh meadows",
      "Corona",
      "East elmhurst",
      "Jackson heights",
      "Rego park",
      "Forest hills",
      "Woodside",
      "Maspeth",
      "Middle village",
      "Ridgewood",
      "Zcta 113hh",
      "Cambria heights",
      "Kew gardens",
      "Springfield gard",
      "Ozone park",
      "Richmond hill",
      "S richmond hill",
      "S ozone park",
      "Woodhaven",
      "Rosedale",
      "Hollis",
      "Bellerose",
      "Queens village",
      "Jamaica",
      "Zcta 114hh",
      "Mineola",
      "Albertson",
      "Atlantic beach",
      "Baldwin",
      "Carle place",
      "Cedarhurst",
      "East rockaway",
      "Freeport",
      "Garden city",
      "Glen cove",
      "Glen head",
      "Glenwood landing",
      "Greenvale",
      "Hempstead",
      "West hempstead",
      "Uniondale",
      "East meadow",
      "Hewlett",
      "Island park",
      "Lawrence",
      "Locust valley",
      "Long beach",
      "Lynbrook",
      "Malverne",
      "North merrick",
      "Old westbury",
      "Point lookout",
      "Rockville centre",
      "Oceanside",
      "Roosevelt",
      "Roslyn",
      "Roslyn heights",
      "Sea cliff",
      "Valley stream",
      "North woodmere",
      "Westbury",
      "Williston park",
      "Woodmere",
      "Zcta 115hh",
      "Far rockaway",
      "Zcta 116hh",
      "Amityville",
      "Oak beach",
      "North babylon",
      "West babylon",
      "Bayport",
      "Kismet",
      "Bayville",
      "North bellmore",
      "Bellport",
      "Bethpage",
      "Blue point",
      "Bohemia",
      "West brentwood",
      "Brightwaters",
      "Brookhaven",
      "Centereach",
      "Centerport",
      "Central islip",
      "Cold spring harb",
      "Commack",
      "Copiague",
      "Coram",
      "Deer park",
      "East islip",
      "Elwood",
      "East norwich",
      "Setauket",
      "South farmingdal",
      "Farmingville",
      "Greenlawn",
      "Holbrook",
      "Holtsville",
      "Halesite",
      "Dix hills",
      "Melville",
      "Islip",
      "Islip terrace",
      "Jericho",
      "Kings park",
      "Lake grove",
      "Levittown",
      "Lindenhurst",
      "North massapequa",
      "Massapequa park",
      "Medford",
      "Miller place",
      "Mill neck",
      "Mount sinai",
      "Nesconset",
      "Northport",
      "Oakdale",
      "Ocean beach",
      "Oyster bay",
      "Davis park",
      "Port jefferson s",
      "Port jefferson",
      "Rocky point",
      "Lake ronkonkoma",
      "Saint james",
      "Cherry grove",
      "Seaford",
      "Selden",
      "Shoreham",
      "Smithtown",
      "Hauppauge",
      "Sound beach",
      "Stony brook",
      "Syosset",
      "Wading river",
      "Wantagh",
      "West islip",
      "West sayville",
      "Woodbury",
      "Wheatley heights",
      "Zcta 117hh",
      "Hicksville",
      "Plainview",
      "Old bethpage",
      "Riverhead",
      "Amagansett",
      "Bridgehampton",
      "Calverton",
      "Center moriches",
      "Cutchogue",
      "East hampton",
      "East marion",
      "East moriches",
      "Eastport",
      "East quogue",
      "Greenport",
      "Hampton bays",
      "Jamesport",
      "Laurel",
      "Manorville",
      "Mastic",
      "Mastic beach",
      "Mattituck",
      "Middle island",
      "Montauk",
      "Moriches",
      "New suffolk",
      "Orient",
      "Peconic",
      "Quogue",
      "Remsenburg",
      "Ridge",
      "Sagaponack",
      "Sag harbor",
      "Shelter island",
      "Shelter island h",
      "Shirley",
      "Southampton",
      "South jamesport",
      "Southold",
      "Speonk",
      "Wainscott",
      "Water mill",
      "Westhampton",
      "Westhampton beac",
      "Yaphank",
      "Zcta 119hh",
      "Alplaus",
      "Altamont",
      "West charlton",
      "Athens",
      "Austerlitz",
      "Averill park",
      "Ballston lake",
      "Ballston spa",
      "Berlin",
      "Berne",
      "Broadalbin",
      "Burnt hills",
      "Buskirk",
      "Canaan",
      "Carlisle",
      "Caroga lake",
      "Castleton on hud",
      "Central bridge",
      "Charlotteville",
      "Chatham",
      "Clarksville",
      "Climax",
      "Cobleskill",
      "Coeymans",
      "Coeymans hollow",
      "Cohoes",
      "Coxsackie",
      "Cropseyville",
      "Delanson",
      "Delmar",
      "Duanesburg",
      "White creek",
      "Earlton",
      "East berne",
      "East chatham",
      "East greenbush",
      "East nassau",
      "East schodack",
      "East worcester",
      "Clifton park",
      "Esperance",
      "Feura bush",
      "Fonda",
      "Fort johnson",
      "Fultonham",
      "Fultonville",
      "Galway",
      "Ghent",
      "Gilboa",
      "Glenmont",
      "Gloversville",
      "Greenville",
      "Guilderland",
      "Hagaman",
      "Hannacroix",
      "Hoosick falls",
      "Howes cave",
      "Jefferson",
      "Johnsonville",
      "Johnstown",
      "Zcta 120hh",
      "Kinderhook",
      "Lake pleasant",
      "Latham",
      "Malden bridge",
      "Maryland",
      "Mayfield",
      "Mechanicville",
      "Medusa",
      "Melrose",
      "Middleburgh",
      "Nassau",
      "New lebanon",
      "Niverville",
      "North blenheim",
      "Edinburg",
      "Old chatham",
      "Pattersonville",
      "Taconic lake",
      "Piseco",
      "Poestenkill",
      "Ravena",
      "Rensselaer",
      "Rensselaerville",
      "Rexford",
      "Richmondville",
      "Rotterdam juncti",
      "Round lake",
      "Sand lake",
      "Schaghticoke",
      "Schenevus",
      "Schodack landing",
      "Schoharie",
      "Selkirk",
      "Slingerlands",
      "Sloansville",
      "Speculator",
      "Spencertown",
      "Sprakers",
      "Stamford",
      "Stephentown",
      "Stillwater",
      "Stuyvesant",
      "Summit",
      "Surprise",
      "Troy",
      "Green island",
      "Valatie",
      "Valley falls",
      "Voorheesville",
      "Warnerville",
      "Waterford",
      "Watervliet",
      "Wells",
      "West coxsackie",
      "Westerlo",
      "West fulton",
      "West sand lake",
      "Worcester",
      "Wynantskill",
      "Zcta 121hh",
      "Zcta 121xx",
      "Albany",
      "Mc kownville",
      "Roessleville",
      "Loudonville",
      "Zcta 122hh",
      "Mayfair",
      "Rotterdam",
      "Schenectady",
      "Niskayuna",
      "Zcta 123hh",
      "Eddyville",
      "Accord",
      "Acra",
      "Arkville",
      "Ashland",
      "Shady",
      "Oliverea",
      "Bloomington",
      "Boiceville",
      "Cairo",
      "Catskill",
      "Chichester",
      "Cornwallville",
      "Cottekill",
      "Cragsmoor",
      "Denver",
      "Durham",
      "East durham",
      "East jewett",
      "Elka park",
      "Ellenville",
      "Esopus",
      "Halcott center",
      "Freehold",
      "Glenford",
      "Grand gorge",
      "Greenfield park",
      "East windham",
      "High falls",
      "Hunter",
      "Hurley",
      "Jewett",
      "Kerhonkson",
      "Lake hill",
      "Lake katrine",
      "Lanesville",
      "Leeds",
      "Maplecrest",
      "Kelly corners",
      "Mount marion",
      "Mount tremper",
      "Napanoch",
      "New kingston",
      "Oak hill",
      "Krumville",
      "Palenville",
      "Phoenicia",
      "Port ewen",
      "Prattsville",
      "Preston hollow",
      "Purling",
      "Rifton",
      "Rosendale",
      "Round top",
      "Roxbury",
      "Saugerties",
      "Shandaken",
      "Shokan",
      "South cairo",
      "Stone ridge",
      "Tannersville",
      "Tillson",
      "Ulster park",
      "Wawarsing",
      "West hurley",
      "West kill",
      "West park",
      "West shokan",
      "Willow",
      "Windham",
      "Woodstock",
      "Zcta 124hh",
      "Amenia",
      "Ancram",
      "Ancramdale",
      "Barrytown",
      "Beacon",
      "Claverack",
      "Clinton corners",
      "Clintondale",
      "Copake",
      "Copake falls",
      "Cornwall",
      "Cornwall on huds",
      "Craryville",
      "Dover plains",
      "Elizaville",
      "Fishkill",
      "Gardiner",
      "Germantown",
      "Highland",
      "Hillsdale",
      "Holmes",
      "Hopewell junctio",
      "Hudson",
      "Hyde park",
      "Lagrangeville",
      "Marlboro",
      "Maybrook",
      "Millbrook",
      "Millerton",
      "Milton",
      "Modena",
      "Montgomery",
      "Middle hope",
      "New windsor",
      "Mohonk lake",
      "Patterson",
      "Pawling",
      "Philmont",
      "Pine bush",
      "Pine plains",
      "Pleasant valley",
      "Poughquag",
      "Red hook",
      "Rhinebeck",
      "Rock tavern",
      "Salisbury mills",
      "Salt point",
      "Staatsburg",
      "Stanfordville",
      "Stormville",
      "Tivoli",
      "Verbank",
      "Walden",
      "Wallkill",
      "New hamburg",
      "Wassaic",
      "Wingdale",
      "Zcta 125hh",
      "Zcta 125xx",
      "South road",
      "Arlington",
      "Zcta 126hh",
      "Monticello",
      "Barryville",
      "Bethel",
      "Bloomingburg",
      "Callicoon",
      "Callicoon center",
      "Claryville",
      "Fosterdale",
      "Cochecton center",
      "Cuddebackville",
      "Eldred",
      "Fallsburg",
      "Grossinger",
      "Fremont center",
      "Glen spey",
      "Glen wild",
      "Godeffroy",
      "Grahamsville",
      "Mileses",
      "Harris",
      "Highland lake",
      "Huguenot",
      "Hurleyville",
      "Jeffersonville",
      "Kauneonga lake",
      "Kiamesha lake",
      "Lake huntington",
      "Liberty",
      "Livingston manor",
      "Loch sheldrake",
      "Long eddy",
      "Mongaup valley",
      "Mountain dale",
      "Narrowsburg",
      "Neversink",
      "North branch",
      "Parksville",
      "Pond eddy",
      "Port jervis",
      "Rock hill",
      "Cook falls",
      "Forestburgh",
      "Smallwood",
      "South fallsburg",
      "Sparrowbush",
      "Swan lake",
      "Thompsonville",
      "Westbrookville",
      "White lake",
      "White sulphur sp",
      "Woodbourne",
      "Woodridge",
      "Wurtsboro",
      "Youngsville",
      "Yulan",
      "Zcta 127hh",
      "Queensbury",
      "South glens fall",
      "Adirondack",
      "Argyle",
      "Athol",
      "Blue mountain la",
      "Bolton landing",
      "Brant lake",
      "Cambridge",
      "Chestertown",
      "Clemons",
      "Comstock",
      "Corinth",
      "Cossayuna",
      "Diamond point",
      "Fort ann",
      "Fort edward",
      "Gansevoort",
      "Granville",
      "Greenfield cente",
      "Thomson",
      "Hadley",
      "Hague",
      "Hampton",
      "Hartford",
      "Hudson falls",
      "Huletts landing",
      "Indian lake",
      "Johnsburg",
      "Pilot knob",
      "Lake george",
      "Lake luzerne",
      "Long lake",
      "Middle granville",
      "Middle grove",
      "Minerva",
      "Newcomb",
      "North creek",
      "North hudson",
      "North river",
      "Olmstedville",
      "Paradox",
      "Porter corners",
      "Pottersville",
      "Putnam station",
      "Rock city falls",
      "Salem",
      "Wilton",
      "Schroon lake",
      "Schuylerville",
      "Shushan",
      "Silver bay",
      "Stony creek",
      "Ticonderoga",
      "Warrensburg",
      "Wevertown",
      "Whitehall",
      "Zcta 128hh",
      "Zcta 128xx",
      "Plattsburgh",
      "Plattsburgh a f",
      "Altona",
      "Au sable chasm",
      "Au sable forks",
      "Bloomingdale",
      "Bombay",
      "Brushton",
      "Burke",
      "Cadyville",
      "Champlain",
      "Chateaugay",
      "Chazy",
      "Childwold",
      "Churubusco",
      "Constable",
      "Cranberry lake",
      "Crown point",
      "Dickinson center",
      "Elizabethtown",
      "Ellenburg center",
      "Ellenburg depot",
      "Essex",
      "Fort covington",
      "Jay",
      "Keene",
      "Saint huberts",
      "Keeseville",
      "Upper saint regi",
      "North pole",
      "Lewis",
      "Lyon mountain",
      "Malone",
      "Merrill",
      "Mineville",
      "Moira",
      "Mooers",
      "Mooers forks",
      "Moriah",
      "Moriah center",
      "Morrisonville",
      "New russia",
      "Nicholville",
      "Bangor",
      "North lawrence",
      "Owls head",
      "Paul smiths",
      "Peru",
      "Port henry",
      "Rouses point",
      "Saint regis fall",
      "Saranac",
      "Saranac lake",
      "Schuyler falls",
      "Sunmount",
      "Upper jay",
      "Vermontville",
      "West chazy",
      "Westport",
      "Willsboro",
      "Zcta 129hh",
      "Zcta 129xx",
      "Auburn",
      "Aurora",
      "Baldwinsville",
      "Bernhards bay",
      "Brewerton",
      "Bridgeport",
      "Camillus",
      "Canastota",
      "Cato",
      "Cayuga",
      "Cazenovia",
      "Central square",
      "Chittenango",
      "Cicero",
      "Cincinnatus",
      "Clay",
      "Cleveland",
      "Constantia",
      "Cortland",
      "De ruyter",
      "Dryden",
      "Durhamville",
      "East syracuse",
      "Elbridge",
      "Erieville",
      "Etna",
      "Fabius",
      "Fayetteville",
      "Freeville",
      "Fulton",
      "Genoa",
      "Georgetown",
      "Groton",
      "Hannibal",
      "Hastings",
      "Homer",
      "Jamesville",
      "Jordan",
      "King ferry",
      "Kirkville",
      "Lacona",
      "La fayette",
      "Liverpool",
      "Bayberry",
      "Locke",
      "Zcta 130hh",
      "Mc graw",
      "Manlius",
      "Marcellus",
      "Marietta",
      "Martville",
      "Memphis",
      "Mexico",
      "Minoa",
      "Moravia",
      "Nedrow",
      "New woodstock",
      "North pitcher",
      "Oswego",
      "Parish",
      "Pennellville",
      "Phoenix",
      "Pitcher",
      "Port byron",
      "Preble",
      "Pulaski",
      "Red creek",
      "Richland",
      "Sandy creek",
      "Savannah",
      "Venice center",
      "Seneca falls",
      "Skaneateles",
      "South otselic",
      "Sterling",
      "Truxton",
      "Tully",
      "Union springs",
      "Warners",
      "Waterloo",
      "Weedsport",
      "West monroe",
      "Zcta 131hh",
      "Syracuse",
      "Solvay",
      "Mattydale",
      "North syracuse",
      "De witt",
      "Onondaga",
      "Zcta 132hh",
      "Alder creek",
      "Altmar",
      "Ava",
      "Barneveld",
      "Blossvale",
      "Boonville",
      "Bouckville",
      "Glenfield",
      "Bridgewater",
      "Brookfield",
      "Burlington flats",
      "Camden",
      "Ames",
      "Cassville",
      "Chadwicks",
      "Cherry valley",
      "Clayville",
      "Clinton",
      "Cold brook",
      "Constableville",
      "Cooperstown",
      "Croghan",
      "Deansboro",
      "Dolgeville",
      "Eagle bay",
      "Earlville",
      "East springfield",
      "Eaton",
      "Edmeston",
      "Fly creek",
      "Forestport",
      "Fort plain",
      "Frankfort",
      "Franklin springs",
      "Garrattsville",
      "Hamilton",
      "Hartwick",
      "Herkimer",
      "Hoffmeister",
      "Holland patent",
      "Hubbardsville",
      "Ilion",
      "Inlet",
      "Jordanville",
      "Lee center",
      "Little falls",
      "Beaver river",
      "Lyons falls",
      "Zcta 133hh",
      "Zcta 133xx",
      "Madison",
      "Marcy",
      "Middleville",
      "Mohawk",
      "Morrisville",
      "Munnsville",
      "New berlin",
      "New hartford",
      "Newport",
      "New york mills",
      "North brookfield",
      "Old forge",
      "Oneida",
      "Oriskany",
      "Oriskany falls",
      "Palatine bridge",
      "Poland",
      "Port leyden",
      "Raquette lake",
      "Redfield",
      "Remsen",
      "Richfield spring",
      "Rome",
      "Roseboom",
      "Saint johnsville",
      "Salisbury center",
      "Sauquoit",
      "Sharon springs",
      "Sherburne",
      "Sherrill",
      "Smyrna",
      "Springfield cent",
      "Stittville",
      "Stratford",
      "Taberg",
      "Turin",
      "Van hornesville",
      "Vernon",
      "Vernon center",
      "Verona",
      "Waterville",
      "Westdale",
      "West edmeston",
      "Westernville",
      "West leyden",
      "Westmoreland",
      "West winfield",
      "Whitesboro",
      "Williamstown",
      "Yorkville",
      "Zcta 134hh",
      "Zcta 134xx",
      "Utica",
      "Zcta 135hh",
      "Watertown",
      "Fort drum",
      "Smithville",
      "Adams center",
      "Point vivian",
      "Antwerp",
      "Black river",
      "Brasher falls",
      "Brier hill",
      "Brownville",
      "Calcium",
      "Canton",
      "Cape vincent",
      "Carthage",
      "Castorland",
      "Chase mills",
      "Chaumont",
      "Frontenac",
      "Colton",
      "Copenhagen",
      "De kalb junction",
      "De peyster",
      "Dexter",
      "Edwards",
      "Evans mills",
      "Felts mills",
      "Fine",
      "Wellesley island",
      "Gouverneur",
      "Hammond",
      "Harrisville",
      "Henderson",
      "Hermon",
      "Heuvelton",
      "Hogansburg",
      "La fargeville",
      "Lisbon",
      "Lorraine",
      "Madrid",
      "Mannsville",
      "Massena",
      "Morristown",
      "Natural bridge",
      "Norfolk",
      "Norwood",
      "Ogdensburg",
      "Oswegatchie",
      "Parishville",
      "Philadelphia",
      "Plessis",
      "Potsdam",
      "Redwood",
      "Rensselaer falls",
      "Richville",
      "Rodman",
      "Degrasse",
      "Sackets harbor",
      "South colton",
      "Star lake",
      "Theresa",
      "Three mile bay",
      "Waddington",
      "Winthrop",
      "Zcta 136hh",
      "Zcta 136xx",
      "Afton",
      "Andes",
      "Apalachin",
      "Bainbridge",
      "Barton",
      "Berkshire",
      "Bloomville",
      "Bovina center",
      "Candor",
      "Castle creek",
      "Chenango forks",
      "Conklin",
      "Davenport",
      "De lancey",
      "Meredith",
      "Deposit",
      "Downsville",
      "East branch",
      "East meredith",
      "Endwell",
      "Fishs eddy",
      "Franklin",
      "Gilbertsville",
      "Glen aubrey",
      "Greene",
      "Guilford",
      "Hamden",
      "Cadosia",
      "Harpersfield",
      "Harpursville",
      "Hobart",
      "Johnson city",
      "Kirkwood",
      "Laurens",
      "Lisle",
      "Zcta 137hh",
      "Mc donough",
      "Maine",
      "Marathon",
      "Masonville",
      "Milford",
      "Morris",
      "Mount upton",
      "Mount vision",
      "Newark valley",
      "Nichols",
      "Nineveh",
      "Norwich",
      "Oneonta",
      "Otego",
      "Owego",
      "Brisben",
      "Plymouth",
      "Sanitaria spring",
      "Portlandville",
      "Richford",
      "Sidney",
      "Sidney center",
      "Smithville flats",
      "South kortright",
      "South new berlin",
      "South plymouth",
      "Treadwell",
      "Unadilla",
      "Vestal",
      "Walton",
      "Wells bridge",
      "West oneonta",
      "Whitney point",
      "Willet",
      "Willseyville",
      "Windsor",
      "Zcta 138hh",
      "Binghamton",
      "Zcta 139hh",
      "Akron",
      "Alden",
      "Alexander",
      "Angola",
      "Appleton",
      "Arcade",
      "Attica",
      "Barker",
      "Basom",
      "Batavia",
      "Bliss",
      "Boston",
      "Bowmansville",
      "Burt",
      "Chaffee",
      "Clarence",
      "Clarence center",
      "Colden",
      "Collins",
      "Corfu",
      "Cowlesville",
      "Dale",
      "Darien center",
      "Delevan",
      "Depew",
      "Derby",
      "Van buren bay",
      "Swormville",
      "East aurora",
      "East bethany",
      "East concord",
      "Eden",
      "Elba",
      "Elma",
      "Farmersville sta",
      "Forestville",
      "Fredonia",
      "Freedom",
      "Gainesville",
      "Gasport",
      "Getzville",
      "Glenwood",
      "Gowanda",
      "Grand island",
      "Hamburg",
      "Holland",
      "Irving",
      "Java center",
      "Java village",
      "Lake view",
      "Lancaster",
      "Lawtons",
      "Lewiston",
      "Lockport",
      "Lyndonville",
      "Zcta 140hh",
      "Machias",
      "Marilla",
      "Medina",
      "Middleport",
      "Newfane",
      "North collins",
      "North java",
      "North tonawanda",
      "Oakfield",
      "Orchard park",
      "Perrysburg",
      "Ransomville",
      "Sanborn",
      "Sardinia",
      "Silver creek",
      "South dayton",
      "South wales",
      "Springville",
      "Stafford",
      "Strykersville",
      "Tonawanda",
      "Varysburg",
      "West falls",
      "West valley",
      "Wilson",
      "Youngstown",
      "Zcta 141hh",
      "Buffalo",
      "Kenmore",
      "Lackawanna",
      "Blasdell",
      "Williamsville",
      "West seneca",
      "Cheektowaga",
      "Amherst",
      "Zcta 142hh",
      "Niagara falls",
      "Zcta 143hh",
      "Albion",
      "Avon",
      "Bellona",
      "Bergen",
      "Branchport",
      "Brockport",
      "Byron",
      "Caledonia",
      "Canandaigua",
      "Castile",
      "Clifton",
      "Clifton springs",
      "Clyde",
      "Conesus",
      "Dansville",
      "Dresden",
      "East rochester",
      "Fairport",
      "Geneseo",
      "Geneva",
      "Groveland",
      "Hamlin",
      "Hemlock",
      "Henrietta",
      "Hilton",
      "Bloomfield",
      "Hulberton",
      "Honeoye",
      "Honeoye falls",
      "Ionia",
      "Kendall",
      "Kent",
      "Bluff point",
      "Lakeville",
      "Leicester",
      "Le roy",
      "Lima",
      "Livonia",
      "Lyons",
      "Zcta 144hh",
      "Macedon",
      "Manchester",
      "Marion",
      "Mendon",
      "Middlesex",
      "Tuscarora",
      "Naples",
      "Newark",
      "North chili",
      "North rose",
      "Nunda",
      "Ontario",
      "Hayt corners",
      "Palmyra",
      "Linwood",
      "Penfield",
      "Penn yan",
      "Perry",
      "Phelps",
      "Wadsworth",
      "Pittsford",
      "Portageville",
      "Mac dougall",
      "West rush",
      "Rushville",
      "Scottsburg",
      "Scottsville",
      "Shortsville",
      "Rock glen",
      "Sodus",
      "Sodus point",
      "Spencerport",
      "Springwater",
      "Stanley",
      "Victor",
      "Walworth",
      "Warsaw",
      "Waterport",
      "Wayland",
      "Webster",
      "West bloomfield",
      "West henrietta",
      "Williamson",
      "Wolcott",
      "Wyoming",
      "Zcta 145hh",
      "Rochester",
      "Greece",
      "Twelve corners",
      "Westgate",
      "Panorama",
      "Zcta 146hh",
      "Jamestown",
      "Allegany",
      "Alma",
      "Angelica",
      "Ashville",
      "Belfast",
      "Bemus point",
      "Black creek",
      "Bolivar",
      "Brocton",
      "Caneadea",
      "Cassadaga",
      "Cattaraugus",
      "Ceres",
      "Cherry creek",
      "Clymer",
      "Conewango valley",
      "Cuba",
      "Dewittville",
      "East otto",
      "Ellicottville",
      "Falconer",
      "Fillmore",
      "Findley lake",
      "Franklinville",
      "Frewsburg",
      "Friendship",
      "Gerry",
      "Great valley",
      "Ischua",
      "Houghton",
      "Kennedy",
      "Kill buck",
      "Lakewood",
      "Limestone",
      "Little genesee",
      "Little valley",
      "Mayville",
      "Olean",
      "Panama",
      "Portland",
      "Portville",
      "Randolph",
      "Ripley",
      "Rushford",
      "Salamanca",
      "Sherman",
      "Sinclairville",
      "Steamburg",
      "Stockton",
      "Westfield",
      "Zcta 147hh",
      "Zcta 147xx",
      "Addison",
      "Alfred",
      "Alfred station",
      "Almond",
      "Alpine",
      "Andover",
      "Arkport",
      "Atlanta",
      "Wallace",
      "Veterans adminis",
      "Beaver dams",
      "Belmont",
      "Big flats",
      "Bradford",
      "Breesport",
      "Brooktondale",
      "Burdett",
      "Cameron",
      "Cameron mills",
      "Campbell",
      "Canaseraga",
      "Canisteo",
      "Cayuta",
      "Chemung",
      "Cohocton",
      "Corning",
      "Dalton",
      "Dundee",
      "Erin",
      "Greenwood",
      "Hammondsport",
      "Hector",
      "Himrod",
      "Hornell",
      "Horseheads",
      "Hunt",
      "Interlaken",
      "Ithaca college",
      "Jasper",
      "Lakemont",
      "Lindley",
      "Lockwood",
      "Lodi",
      "Lowman",
      "Millport",
      "Montour falls",
      "Newfield",
      "Odessa",
      "Painted post",
      "Pine city",
      "Pine valley",
      "Prattsburg",
      "Pulteney",
      "Rexville",
      "Rock stream",
      "Savona",
      "Scio",
      "Slaterville spri",
      "Lansing",
      "Spencer",
      "Swain",
      "Troupsburg",
      "Trumansburg",
      "Van etten",
      "Watkins glen",
      "Waverly",
      "Wellsburg",
      "Wellsville",
      "Whitesville",
      "Woodhull",
      "Zcta 148hh",
      "Elmira",
      "Elmira heights",
      "Zcta 149hh",
    ],
    ZipCodes: {
      "": ["06390", "063HH"],
      "New york": [
        "10001",
        "10002",
        "10003",
        "10005",
        "10006",
        "10007",
        "10009",
        "10010",
        "10011",
        "10012",
        "10013",
        "10014",
        "10016",
        "10017",
        "10018",
        "10019",
        "10020",
        "10021",
        "10022",
        "10023",
        "10024",
        "10025",
        "10026",
        "10027",
        "10028",
        "10029",
        "10030",
        "10031",
        "10032",
        "10033",
        "10034",
        "10035",
        "10036",
        "10037",
        "10038",
        "10039",
        "10040",
        "10041",
        "10044",
        "10048",
        "100HH",
        "10103",
        "10111",
        "10112",
        "10115",
        "10119",
        "10128",
        "10152",
        "10153",
        "10154",
        "10162",
        "10165",
        "10167",
        "10169",
        "10170",
        "10171",
        "10172",
        "10173",
        "10177",
        "10271",
        "10278",
        "10279",
        "10280",
        "10282",
      ],
      "Governors island": ["10004"],
      "Zcta 10069": ["10069"],
      "Zcta 102hh": ["102HH"],
      "Staten island": [
        "10301",
        "10302",
        "10303",
        "10304",
        "10305",
        "10306",
        "10307",
        "10308",
        "10309",
        "10310",
        "10312",
        "10314",
      ],
      "Zcta 103hh": ["103HH"],
      Bronx: [
        "10451",
        "10452",
        "10453",
        "10454",
        "10455",
        "10456",
        "10457",
        "10458",
        "10459",
        "10460",
        "10461",
        "10462",
        "10463",
        "10464",
        "10465",
        "10466",
        "10467",
        "10468",
        "10469",
        "10470",
        "10471",
        "10472",
        "10473",
        "10474",
        "10475",
      ],
      "Zcta 104hh": ["104HH"],
      Amawalk: ["10501"],
      Ardsley: ["10502"],
      "Ardsley on hudso": ["10503"],
      Armonk: ["10504"],
      Bedford: ["10506"],
      "Bedford hills": ["10507"],
      Brewster: ["10509"],
      "Briarcliff manor": ["10510"],
      Buchanan: ["10511"],
      Carmel: ["10512"],
      Chappaqua: ["10514"],
      "Cold spring": ["10516"],
      "Cross river": ["10518"],
      "Croton falls": ["10519"],
      "Croton on hudson": ["10520"],
      "Dobbs ferry": ["10522"],
      Elmsford: ["10523"],
      Garrison: ["10524"],
      "Goldens bridge": ["10526"],
      "Granite springs": ["10527"],
      Harrison: ["10528"],
      Hartsdale: ["10530"],
      Hawthorne: ["10532"],
      Irvington: ["10533"],
      "Jefferson valley": ["10535"],
      Katonah: ["10536"],
      "Lake peekskill": ["10537"],
      Larchmont: ["10538"],
      Mahopac: ["10541"],
      Mamaroneck: ["10543"],
      Millwood: ["10546"],
      "Mohegan lake": ["10547"],
      Montrose: ["10548"],
      "Mount kisco": ["10549"],
      "Mount vernon": ["10550", "10552", "10553"],
      "North salem": ["10560"],
      Ossining: ["10562"],
      "Cortlandt manor": ["10566"],
      "Zcta 10567": ["10567"],
      Pleasantville: ["10570"],
      "Rye brook": ["10573"],
      "Pound ridge": ["10576"],
      Purchase: ["10577"],
      Purdys: ["10578"],
      "Putnam valley": ["10579"],
      Rye: ["10580"],
      Heathcote: ["10583"],
      "Shrub oak": ["10588"],
      Somers: ["10589"],
      "South salem": ["10590"],
      "North tarrytown": ["10591"],
      Thornwood: ["10594"],
      Valhalla: ["10595"],
      Waccabuc: ["10597"],
      "Yorktown heights": ["10598"],
      "Zcta 105hh": ["105HH"],
      "White plains": ["10601", "10603", "10605", "10606", "10607"],
      "East white plain": ["10604"],
      "Zcta 106hh": ["106HH"],
      Yonkers: ["10701", "10703", "10704", "10705", "10710"],
      "Hastings on huds": ["10706"],
      Tuckahoe: ["10707"],
      Bronxville: ["10708"],
      Eastchester: ["10709"],
      "Zcta 107hh": ["107HH"],
      "New rochelle": ["10801", "10804", "10805"],
      Pelham: ["10803"],
      "Zcta 108hh": ["108HH"],
      Suffern: ["10901"],
      Blauvelt: ["10913"],
      "Campbell hall": ["10916"],
      "Central valley": ["10917"],
      Chester: ["10918"],
      Circleville: ["10919"],
      Congers: ["10920"],
      Florida: ["10921"],
      Garnerville: ["10923"],
      Goshen: ["10924"],
      "Greenwood lake": ["10925"],
      Harriman: ["10926"],
      Haverstraw: ["10927"],
      "Highland falls": ["10928"],
      "Highland mills": ["10930"],
      Hillburn: ["10931"],
      Scotchtown: ["10940"],
      Middletown: ["10941"],
      Monroe: ["10950"],
      Monsey: ["10952"],
      Bardonia: ["10954"],
      "New city": ["10956"],
      "New hampton": ["10958"],
      Nyack: ["10960"],
      Orangeburg: ["10962"],
      Otisville: ["10963"],
      Palisades: ["10964"],
      "Pearl river": ["10965"],
      Piermont: ["10968"],
      "Pine island": ["10969"],
      Pomona: ["10970"],
      "Slate hill": ["10973"],
      Sterlington: ["10974"],
      Southfields: ["10975"],
      Sparkill: ["10976"],
      "Chestnut ridge": ["10977"],
      "Stony point": ["10980"],
      Tappan: ["10983"],
      Thiells: ["10984"],
      "Thompson ridge": ["10985"],
      "Tomkins cove": ["10986"],
      "Tuxedo park": ["10987"],
      "Valley cottage": ["10989"],
      Warwick: ["10990"],
      Washingtonville: ["10992"],
      "West haverstraw": ["10993"],
      "West nyack": ["10994"],
      "West point": ["10996"],
      Westtown: ["10998"],
      "Zcta 109hh": ["109HH"],
      "Zcta 109xx": ["109XX"],
      "Floral park": ["11001", "11005"],
      "Alden manor": ["11003"],
      "Glen oaks": ["11004"],
      "Franklin square": ["11010"],
      "Great neck": ["11020", "11021", "11023"],
      "Kings point cont": ["11024"],
      Plandome: ["11030"],
      "Hillside manor": ["11040"],
      "New hyde park": ["11042"],
      "Port washington": ["11050"],
      "Zcta 11096": ["11096"],
      "Zcta 110hh": ["110HH"],
      Astoria: ["11101", "11102", "11103", "11105", "11106"],
      Sunnyside: ["11104"],
      "Zcta 111hh": ["111HH"],
      Brooklyn: [
        "11201",
        "11203",
        "11204",
        "11205",
        "11206",
        "11207",
        "11208",
        "11209",
        "11210",
        "11211",
        "11212",
        "11213",
        "11214",
        "11215",
        "11216",
        "11217",
        "11218",
        "11219",
        "11220",
        "11221",
        "11222",
        "11223",
        "11224",
        "11225",
        "11226",
        "11228",
        "11229",
        "11230",
        "11231",
        "11232",
        "11233",
        "11234",
        "11235",
        "11236",
        "11237",
        "11238",
        "11239",
      ],
      "Zcta 112hh": ["112HH"],
      Flushing: ["11354", "11355", "11358", "11364", "11367", "11371"],
      "College point": ["11356"],
      Whitestone: ["11357"],
      Bayside: ["11360", "11361"],
      "Little neck": ["11362", "11363"],
      "Fresh meadows": ["11365", "11366"],
      Corona: ["11368"],
      "East elmhurst": ["11369", "11370"],
      "Jackson heights": ["11372", "11373"],
      "Rego park": ["11374"],
      "Forest hills": ["11375"],
      Woodside: ["11377"],
      Maspeth: ["11378"],
      "Middle village": ["11379"],
      Ridgewood: ["11385"],
      "Zcta 113hh": ["113HH"],
      "Cambria heights": ["11411"],
      "Kew gardens": ["11412", "11414", "11415"],
      "Springfield gard": ["11413"],
      "Ozone park": ["11416", "11417"],
      "Richmond hill": ["11418"],
      "S richmond hill": ["11419"],
      "S ozone park": ["11420"],
      Woodhaven: ["11421"],
      Rosedale: ["11422"],
      Hollis: ["11423"],
      Bellerose: ["11426"],
      "Queens village": ["11427", "11428", "11429"],
      Jamaica: ["11430", "11432", "11433", "11434", "11435", "11436"],
      "Zcta 114hh": ["114HH"],
      Mineola: ["11501"],
      Albertson: ["11507"],
      "Atlantic beach": ["11509"],
      Baldwin: ["11510"],
      "Carle place": ["11514"],
      Cedarhurst: ["11516"],
      "East rockaway": ["11518"],
      Freeport: ["11520"],
      "Garden city": ["11530"],
      "Glen cove": ["11542"],
      "Glen head": ["11545"],
      "Glenwood landing": ["11547"],
      Greenvale: ["11548"],
      Hempstead: ["11550"],
      "West hempstead": ["11552"],
      Uniondale: ["11553"],
      "East meadow": ["11554"],
      Hewlett: ["11557"],
      "Island park": ["11558"],
      Lawrence: ["11559"],
      "Locust valley": ["11560"],
      "Long beach": ["11561"],
      Lynbrook: ["11563"],
      Malverne: ["11565"],
      "North merrick": ["11566"],
      "Old westbury": ["11568"],
      "Point lookout": ["11569"],
      "Rockville centre": ["11570"],
      Oceanside: ["11572"],
      Roosevelt: ["11575"],
      Roslyn: ["11576"],
      "Roslyn heights": ["11577"],
      "Sea cliff": ["11579"],
      "Valley stream": ["11580"],
      "North woodmere": ["11581"],
      Westbury: ["11590"],
      "Williston park": ["11596"],
      Woodmere: ["11598"],
      "Zcta 115hh": ["115HH"],
      "Far rockaway": ["11691", "11692", "11693", "11694", "11697"],
      "Zcta 116hh": ["116HH"],
      Amityville: ["11701"],
      "Oak beach": ["11702"],
      "North babylon": ["11703"],
      "West babylon": ["11704"],
      Bayport: ["11705"],
      Kismet: ["11706"],
      Bayville: ["11709"],
      "North bellmore": ["11710"],
      Bellport: ["11713"],
      Bethpage: ["11714"],
      "Blue point": ["11715"],
      Bohemia: ["11716"],
      "West brentwood": ["11717"],
      Brightwaters: ["11718"],
      Brookhaven: ["11719"],
      Centereach: ["11720"],
      Centerport: ["11721"],
      "Central islip": ["11722"],
      "Cold spring harb": ["11724"],
      Commack: ["11725"],
      Copiague: ["11726"],
      Coram: ["11727"],
      "Deer park": ["11729"],
      "East islip": ["11730"],
      Elwood: ["11731"],
      "East norwich": ["11732"],
      Setauket: ["11733"],
      "South farmingdal": ["11735"],
      Farmingville: ["11738"],
      Greenlawn: ["11740"],
      Holbrook: ["11741"],
      Holtsville: ["11742"],
      Halesite: ["11743"],
      "Dix hills": ["11746"],
      Melville: ["11747"],
      Islip: ["11751"],
      "Islip terrace": ["11752"],
      Jericho: ["11753"],
      "Kings park": ["11754"],
      "Lake grove": ["11755"],
      Levittown: ["11756"],
      Lindenhurst: ["11757"],
      "North massapequa": ["11758"],
      "Massapequa park": ["11762"],
      Medford: ["11763"],
      "Miller place": ["11764"],
      "Mill neck": ["11765"],
      "Mount sinai": ["11766"],
      Nesconset: ["11767"],
      Northport: ["11768"],
      Oakdale: ["11769"],
      "Ocean beach": ["11770"],
      "Oyster bay": ["11771"],
      "Davis park": ["11772"],
      "Port jefferson s": ["11776"],
      "Port jefferson": ["11777"],
      "Rocky point": ["11778"],
      "Lake ronkonkoma": ["11779"],
      "Saint james": ["11780"],
      "Cherry grove": ["11782"],
      Seaford: ["11783"],
      Selden: ["11784"],
      Shoreham: ["11786"],
      Smithtown: ["11787"],
      Hauppauge: ["11788"],
      "Sound beach": ["11789"],
      "Stony brook": ["11790"],
      Syosset: ["11791"],
      "Wading river": ["11792"],
      Wantagh: ["11793"],
      "West islip": ["11795"],
      "West sayville": ["11796"],
      Woodbury: ["11797"],
      "Wheatley heights": ["11798"],
      "Zcta 117hh": ["117HH"],
      Hicksville: ["11801"],
      Plainview: ["11803"],
      "Old bethpage": ["11804"],
      Riverhead: ["11901"],
      Amagansett: ["11930"],
      Bridgehampton: ["11932"],
      Calverton: ["11933"],
      "Center moriches": ["11934"],
      Cutchogue: ["11935"],
      "East hampton": ["11937"],
      "East marion": ["11939"],
      "East moriches": ["11940"],
      Eastport: ["11941"],
      "East quogue": ["11942"],
      Greenport: ["11944"],
      "Hampton bays": ["11946"],
      Jamesport: ["11947"],
      Laurel: ["11948"],
      Manorville: ["11949"],
      Mastic: ["11950"],
      "Mastic beach": ["11951"],
      Mattituck: ["11952"],
      "Middle island": ["11953"],
      Montauk: ["11954"],
      Moriches: ["11955"],
      "New suffolk": ["11956"],
      Orient: ["11957"],
      Peconic: ["11958"],
      Quogue: ["11959"],
      Remsenburg: ["11960"],
      Ridge: ["11961"],
      Sagaponack: ["11962"],
      "Sag harbor": ["11963"],
      "Shelter island": ["11964"],
      "Shelter island h": ["11965"],
      Shirley: ["11967"],
      Southampton: ["11968"],
      "South jamesport": ["11970"],
      Southold: ["11971"],
      Speonk: ["11972"],
      Wainscott: ["11975"],
      "Water mill": ["11976"],
      Westhampton: ["11977"],
      "Westhampton beac": ["11978"],
      Yaphank: ["11980"],
      "Zcta 119hh": ["119HH"],
      Alplaus: ["12008"],
      Altamont: ["12009"],
      "West charlton": ["12010"],
      Athens: ["12015"],
      Austerlitz: ["12017"],
      "Averill park": ["12018"],
      "Ballston lake": ["12019"],
      "Ballston spa": ["12020"],
      Berlin: ["12022"],
      Berne: ["12023"],
      Broadalbin: ["12025"],
      "Burnt hills": ["12027"],
      Buskirk: ["12028"],
      Canaan: ["12029"],
      Carlisle: ["12031"],
      "Caroga lake": ["12032"],
      "Castleton on hud": ["12033"],
      "Central bridge": ["12035"],
      Charlotteville: ["12036"],
      Chatham: ["12037"],
      Clarksville: ["12041"],
      Climax: ["12042"],
      Cobleskill: ["12043"],
      Coeymans: ["12045"],
      "Coeymans hollow": ["12046"],
      Cohoes: ["12047"],
      Coxsackie: ["12051"],
      Cropseyville: ["12052"],
      Delanson: ["12053"],
      Delmar: ["12054"],
      Duanesburg: ["12056"],
      "White creek": ["12057"],
      Earlton: ["12058"],
      "East berne": ["12059"],
      "East chatham": ["12060"],
      "East greenbush": ["12061"],
      "East nassau": ["12062"],
      "East schodack": ["12063"],
      "East worcester": ["12064"],
      "Clifton park": ["12065"],
      Esperance: ["12066"],
      "Feura bush": ["12067"],
      Fonda: ["12068"],
      "Fort johnson": ["12070"],
      Fultonham: ["12071"],
      Fultonville: ["12072"],
      Galway: ["12074"],
      Ghent: ["12075"],
      Gilboa: ["12076"],
      Glenmont: ["12077"],
      Gloversville: ["12078"],
      Greenville: ["12083"],
      Guilderland: ["12084"],
      Hagaman: ["12086"],
      Hannacroix: ["12087"],
      "Hoosick falls": ["12090"],
      "Howes cave": ["12092"],
      Jefferson: ["12093"],
      Johnsonville: ["12094"],
      Johnstown: ["12095"],
      "Zcta 120hh": ["120HH"],
      Kinderhook: ["12106"],
      "Lake pleasant": ["12108"],
      Latham: ["12110"],
      "Malden bridge": ["12115"],
      Maryland: ["12116"],
      Mayfield: ["12117"],
      Mechanicville: ["12118"],
      Medusa: ["12120"],
      Melrose: ["12121"],
      Middleburgh: ["12122"],
      Nassau: ["12123"],
      "New lebanon": ["12125"],
      Niverville: ["12130"],
      "North blenheim": ["12131"],
      Edinburg: ["12134"],
      "Old chatham": ["12136"],
      Pattersonville: ["12137"],
      "Taconic lake": ["12138"],
      Piseco: ["12139"],
      Poestenkill: ["12140"],
      Ravena: ["12143"],
      Rensselaer: ["12144"],
      Rensselaerville: ["12147"],
      Rexford: ["12148"],
      Richmondville: ["12149"],
      "Rotterdam juncti": ["12150"],
      "Round lake": ["12151"],
      "Sand lake": ["12153"],
      Schaghticoke: ["12154"],
      Schenevus: ["12155"],
      "Schodack landing": ["12156"],
      Schoharie: ["12157"],
      Selkirk: ["12158"],
      Slingerlands: ["12159"],
      Sloansville: ["12160"],
      Speculator: ["12164"],
      Spencertown: ["12165"],
      Sprakers: ["12166"],
      Stamford: ["12167"],
      Stephentown: ["12168", "12169"],
      Stillwater: ["12170"],
      Stuyvesant: ["12173"],
      Summit: ["12175"],
      Surprise: ["12176"],
      Troy: ["12180", "12182"],
      "Green island": ["12183"],
      Valatie: ["12184"],
      "Valley falls": ["12185"],
      Voorheesville: ["12186"],
      Warnerville: ["12187"],
      Waterford: ["12188"],
      Watervliet: ["12189"],
      Wells: ["12190"],
      "West coxsackie": ["12192"],
      Westerlo: ["12193"],
      "West fulton": ["12194"],
      "West sand lake": ["12196"],
      Worcester: ["12197"],
      Wynantskill: ["12198"],
      "Zcta 121hh": ["121HH"],
      "Zcta 121xx": ["121XX"],
      Albany: ["12202", "12204", "12206", "12207", "12208", "12209", "12210"],
      "Mc kownville": ["12203"],
      Roessleville: ["12205"],
      Loudonville: ["12211"],
      "Zcta 122hh": ["122HH"],
      Mayfair: ["12302"],
      Rotterdam: ["12303"],
      Schenectady: ["12304", "12305", "12306", "12307", "12308"],
      Niskayuna: ["12309"],
      "Zcta 123hh": ["123HH"],
      Eddyville: ["12401"],
      Accord: ["12404"],
      Acra: ["12405"],
      Arkville: ["12406"],
      Ashland: ["12407"],
      Shady: ["12409"],
      Oliverea: ["12410"],
      Bloomington: ["12411"],
      Boiceville: ["12412"],
      Cairo: ["12413"],
      Catskill: ["12414"],
      Chichester: ["12416"],
      Cornwallville: ["12418"],
      Cottekill: ["12419"],
      Cragsmoor: ["12420"],
      Denver: ["12421"],
      Durham: ["12422"],
      "East durham": ["12423"],
      "East jewett": ["12424"],
      "Elka park": ["12427"],
      Ellenville: ["12428"],
      Esopus: ["12429"],
      "Halcott center": ["12430"],
      Freehold: ["12431"],
      Glenford: ["12433"],
      "Grand gorge": ["12434"],
      "Greenfield park": ["12435"],
      "East windham": ["12439"],
      "High falls": ["12440"],
      Hunter: ["12442"],
      Hurley: ["12443"],
      Jewett: ["12444"],
      Kerhonkson: ["12446"],
      "Lake hill": ["12448"],
      "Lake katrine": ["12449"],
      Lanesville: ["12450"],
      Leeds: ["12451"],
      Maplecrest: ["12454"],
      "Kelly corners": ["12455"],
      "Mount marion": ["12456"],
      "Mount tremper": ["12457"],
      Napanoch: ["12458"],
      "New kingston": ["12459"],
      "Oak hill": ["12460"],
      Krumville: ["12461"],
      Palenville: ["12463"],
      Phoenicia: ["12464"],
      "Port ewen": ["12466"],
      Prattsville: ["12468"],
      "Preston hollow": ["12469"],
      Purling: ["12470"],
      Rifton: ["12471"],
      Rosendale: ["12472"],
      "Round top": ["12473"],
      Roxbury: ["12474"],
      Saugerties: ["12477"],
      Shandaken: ["12480"],
      Shokan: ["12481"],
      "South cairo": ["12482"],
      "Stone ridge": ["12484"],
      Tannersville: ["12485"],
      Tillson: ["12486"],
      "Ulster park": ["12487"],
      Wawarsing: ["12489"],
      "West hurley": ["12491"],
      "West kill": ["12492"],
      "West park": ["12493"],
      "West shokan": ["12494"],
      Willow: ["12495"],
      Windham: ["12496"],
      Woodstock: ["12498"],
      "Zcta 124hh": ["124HH"],
      Amenia: ["12501"],
      Ancram: ["12502"],
      Ancramdale: ["12503"],
      Barrytown: ["12507"],
      Beacon: ["12508"],
      Claverack: ["12513"],
      "Clinton corners": ["12514"],
      Clintondale: ["12515"],
      Copake: ["12516"],
      "Copake falls": ["12517"],
      Cornwall: ["12518"],
      "Cornwall on huds": ["12520"],
      Craryville: ["12521"],
      "Dover plains": ["12522"],
      Elizaville: ["12523"],
      Fishkill: ["12524"],
      Gardiner: ["12525"],
      Germantown: ["12526"],
      Highland: ["12528"],
      Hillsdale: ["12529"],
      Holmes: ["12531"],
      "Hopewell junctio": ["12533"],
      Hudson: ["12534"],
      "Hyde park": ["12538"],
      Lagrangeville: ["12540"],
      Marlboro: ["12542"],
      Maybrook: ["12543"],
      Millbrook: ["12545"],
      Millerton: ["12546"],
      Milton: ["12547"],
      Modena: ["12548"],
      Montgomery: ["12549"],
      "Middle hope": ["12550"],
      "New windsor": ["12553"],
      "Mohonk lake": ["12561"],
      Patterson: ["12563"],
      Pawling: ["12564"],
      Philmont: ["12565"],
      "Pine bush": ["12566"],
      "Pine plains": ["12567"],
      "Pleasant valley": ["12569"],
      Poughquag: ["12570"],
      "Red hook": ["12571"],
      Rhinebeck: ["12572"],
      "Rock tavern": ["12575"],
      "Salisbury mills": ["12577"],
      "Salt point": ["12578"],
      Staatsburg: ["12580"],
      Stanfordville: ["12581"],
      Stormville: ["12582"],
      Tivoli: ["12583"],
      Verbank: ["12585"],
      Walden: ["12586"],
      Wallkill: ["12589"],
      "New hamburg": ["12590"],
      Wassaic: ["12592"],
      Wingdale: ["12594"],
      "Zcta 125hh": ["125HH"],
      "Zcta 125xx": ["125XX"],
      "South road": ["12601"],
      Arlington: ["12603"],
      "Zcta 126hh": ["126HH"],
      Monticello: ["12701"],
      Barryville: ["12719"],
      Bethel: ["12720"],
      Bloomingburg: ["12721"],
      Callicoon: ["12723"],
      "Callicoon center": ["12724"],
      Claryville: ["12725"],
      Fosterdale: ["12726"],
      "Cochecton center": ["12727"],
      Cuddebackville: ["12729"],
      Eldred: ["12732"],
      Fallsburg: ["12733"],
      Grossinger: ["12734"],
      "Fremont center": ["12736"],
      "Glen spey": ["12737"],
      "Glen wild": ["12738"],
      Godeffroy: ["12739"],
      Grahamsville: ["12740"],
      Mileses: ["12741"],
      Harris: ["12742"],
      "Highland lake": ["12743"],
      Huguenot: ["12746"],
      Hurleyville: ["12747"],
      Jeffersonville: ["12748"],
      "Kauneonga lake": ["12749"],
      "Kiamesha lake": ["12751"],
      "Lake huntington": ["12752"],
      Liberty: ["12754"],
      "Livingston manor": ["12758"],
      "Loch sheldrake": ["12759"],
      "Long eddy": ["12760"],
      "Mongaup valley": ["12762"],
      "Mountain dale": ["12763"],
      Narrowsburg: ["12764"],
      Neversink: ["12765"],
      "North branch": ["12766"],
      Parksville: ["12768"],
      "Pond eddy": ["12770"],
      "Port jervis": ["12771"],
      "Rock hill": ["12775"],
      "Cook falls": ["12776"],
      Forestburgh: ["12777"],
      Smallwood: ["12778"],
      "South fallsburg": ["12779"],
      Sparrowbush: ["12780"],
      "Swan lake": ["12783"],
      Thompsonville: ["12784"],
      Westbrookville: ["12785"],
      "White lake": ["12786"],
      "White sulphur sp": ["12787"],
      Woodbourne: ["12788"],
      Woodridge: ["12789"],
      Wurtsboro: ["12790"],
      Youngsville: ["12791"],
      Yulan: ["12792"],
      "Zcta 127hh": ["127HH"],
      Queensbury: ["12801", "12804"],
      "South glens fall": ["12803"],
      Adirondack: ["12808"],
      Argyle: ["12809"],
      Athol: ["12810"],
      "Blue mountain la": ["12812"],
      "Bolton landing": ["12814"],
      "Brant lake": ["12815"],
      Cambridge: ["12816"],
      Chestertown: ["12817"],
      Clemons: ["12819"],
      Comstock: ["12821"],
      Corinth: ["12822"],
      Cossayuna: ["12823"],
      "Diamond point": ["12824"],
      "Fort ann": ["12827"],
      "Fort edward": ["12828"],
      Gansevoort: ["12831"],
      Granville: ["12832"],
      "Greenfield cente": ["12833"],
      Thomson: ["12834"],
      Hadley: ["12835"],
      Hague: ["12836"],
      Hampton: ["12837"],
      Hartford: ["12838"],
      "Hudson falls": ["12839"],
      "Huletts landing": ["12841"],
      "Indian lake": ["12842"],
      Johnsburg: ["12843"],
      "Pilot knob": ["12844"],
      "Lake george": ["12845"],
      "Lake luzerne": ["12846"],
      "Long lake": ["12847"],
      "Middle granville": ["12849"],
      "Middle grove": ["12850"],
      Minerva: ["12851"],
      Newcomb: ["12852"],
      "North creek": ["12853"],
      "North hudson": ["12855"],
      "North river": ["12856"],
      Olmstedville: ["12857"],
      Paradox: ["12858"],
      "Porter corners": ["12859"],
      Pottersville: ["12860"],
      "Putnam station": ["12861"],
      "Rock city falls": ["12863"],
      Salem: ["12865"],
      Wilton: ["12866"],
      "Schroon lake": ["12870"],
      Schuylerville: ["12871"],
      Shushan: ["12873"],
      "Silver bay": ["12874"],
      "Stony creek": ["12878"],
      Ticonderoga: ["12883"],
      Warrensburg: ["12885"],
      Wevertown: ["12886"],
      Whitehall: ["12887"],
      "Zcta 128hh": ["128HH"],
      "Zcta 128xx": ["128XX"],
      Plattsburgh: ["12901"],
      "Plattsburgh a f": ["12903"],
      Altona: ["12910"],
      "Au sable chasm": ["12911"],
      "Au sable forks": ["12912"],
      Bloomingdale: ["12913"],
      Bombay: ["12914"],
      Brushton: ["12916"],
      Burke: ["12917"],
      Cadyville: ["12918"],
      Champlain: ["12919"],
      Chateaugay: ["12920"],
      Chazy: ["12921"],
      Childwold: ["12922"],
      Churubusco: ["12923"],
      Constable: ["12926"],
      "Cranberry lake": ["12927"],
      "Crown point": ["12928"],
      "Dickinson center": ["12930"],
      Elizabethtown: ["12932"],
      "Ellenburg center": ["12934"],
      "Ellenburg depot": ["12935"],
      Essex: ["12936"],
      "Fort covington": ["12937"],
      Jay: ["12941"],
      Keene: ["12942"],
      "Saint huberts": ["12943"],
      Keeseville: ["12944"],
      "Upper saint regi": ["12945"],
      "North pole": ["12946"],
      Lewis: ["12950"],
      "Lyon mountain": ["12952"],
      Malone: ["12953"],
      Merrill: ["12955"],
      Mineville: ["12956"],
      Moira: ["12957"],
      Mooers: ["12958"],
      "Mooers forks": ["12959"],
      Moriah: ["12960"],
      "Moriah center": ["12961"],
      Morrisonville: ["12962"],
      "New russia": ["12964"],
      Nicholville: ["12965"],
      Bangor: ["12966"],
      "North lawrence": ["12967"],
      "Owls head": ["12969"],
      "Paul smiths": ["12970"],
      Peru: ["12972"],
      "Port henry": ["12974"],
      "Rouses point": ["12979"],
      "Saint regis fall": ["12980"],
      Saranac: ["12981"],
      "Saranac lake": ["12983"],
      "Schuyler falls": ["12985"],
      Sunmount: ["12986"],
      "Upper jay": ["12987"],
      Vermontville: ["12989"],
      "West chazy": ["12992"],
      Westport: ["12993"],
      Willsboro: ["12996"],
      "Zcta 129hh": ["129HH"],
      "Zcta 129xx": ["129XX"],
      Auburn: ["13021"],
      Aurora: ["13026"],
      Baldwinsville: ["13027"],
      "Bernhards bay": ["13028"],
      Brewerton: ["13029"],
      Bridgeport: ["13030"],
      Camillus: ["13031"],
      Canastota: ["13032"],
      Cato: ["13033"],
      Cayuga: ["13034"],
      Cazenovia: ["13035"],
      "Central square": ["13036"],
      Chittenango: ["13037"],
      Cicero: ["13039"],
      Cincinnatus: ["13040"],
      Clay: ["13041"],
      Cleveland: ["13042"],
      Constantia: ["13044"],
      Cortland: ["13045"],
      "De ruyter": ["13052"],
      Dryden: ["13053"],
      Durhamville: ["13054"],
      "East syracuse": ["13057"],
      Elbridge: ["13060"],
      Erieville: ["13061"],
      Etna: ["13062"],
      Fabius: ["13063"],
      Fayetteville: ["13066"],
      Freeville: ["13068"],
      Fulton: ["13069"],
      Genoa: ["13071"],
      Georgetown: ["13072"],
      Groton: ["13073"],
      Hannibal: ["13074"],
      Hastings: ["13076"],
      Homer: ["13077"],
      Jamesville: ["13078"],
      Jordan: ["13080"],
      "King ferry": ["13081"],
      Kirkville: ["13082"],
      Lacona: ["13083"],
      "La fayette": ["13084"],
      Liverpool: ["13088"],
      Bayberry: ["13090"],
      Locke: ["13092"],
      "Zcta 130hh": ["130HH"],
      "Mc graw": ["13101"],
      Manlius: ["13104"],
      Marcellus: ["13108"],
      Marietta: ["13110"],
      Martville: ["13111"],
      Memphis: ["13112"],
      Mexico: ["13114"],
      Minoa: ["13116"],
      Moravia: ["13118"],
      Nedrow: ["13120"],
      "New woodstock": ["13122"],
      "North pitcher": ["13124"],
      Oswego: ["13126"],
      Parish: ["13131"],
      Pennellville: ["13132"],
      Phoenix: ["13135"],
      Pitcher: ["13136"],
      "Port byron": ["13140"],
      Preble: ["13141"],
      Pulaski: ["13142"],
      "Red creek": ["13143"],
      Richland: ["13144"],
      "Sandy creek": ["13145"],
      Savannah: ["13146"],
      "Venice center": ["13147"],
      "Seneca falls": ["13148"],
      Skaneateles: ["13152"],
      "South otselic": ["13155"],
      Sterling: ["13156"],
      Truxton: ["13158"],
      Tully: ["13159"],
      "Union springs": ["13160"],
      Warners: ["13164"],
      Waterloo: ["13165"],
      Weedsport: ["13166"],
      "West monroe": ["13167"],
      "Zcta 131hh": ["131HH"],
      Syracuse: [
        "13202",
        "13203",
        "13204",
        "13205",
        "13206",
        "13207",
        "13208",
        "13210",
        "13219",
        "13224",
        "13290",
      ],
      Solvay: ["13209"],
      Mattydale: ["13211"],
      "North syracuse": ["13212"],
      "De witt": ["13214"],
      Onondaga: ["13215"],
      "Zcta 132hh": ["132HH"],
      "Alder creek": ["13301"],
      Altmar: ["13302"],
      Ava: ["13303"],
      Barneveld: ["13304"],
      Blossvale: ["13308"],
      Boonville: ["13309"],
      Bouckville: ["13310"],
      Glenfield: ["13312", "13343"],
      Bridgewater: ["13313"],
      Brookfield: ["13314"],
      "Burlington flats": ["13315"],
      Camden: ["13316"],
      Ames: ["13317"],
      Cassville: ["13318"],
      Chadwicks: ["13319"],
      "Cherry valley": ["13320"],
      Clayville: ["13322"],
      Clinton: ["13323"],
      "Cold brook": ["13324"],
      Constableville: ["13325"],
      Cooperstown: ["13326"],
      Croghan: ["13327"],
      Deansboro: ["13328"],
      Dolgeville: ["13329"],
      "Eagle bay": ["13331"],
      Earlville: ["13332"],
      "East springfield": ["13333"],
      Eaton: ["13334"],
      Edmeston: ["13335"],
      "Fly creek": ["13337"],
      Forestport: ["13338"],
      "Fort plain": ["13339"],
      Frankfort: ["13340"],
      "Franklin springs": ["13341"],
      Garrattsville: ["13342"],
      Hamilton: ["13346"],
      Hartwick: ["13348"],
      Herkimer: ["13350"],
      Hoffmeister: ["13353"],
      "Holland patent": ["13354"],
      Hubbardsville: ["13355"],
      Ilion: ["13357"],
      Inlet: ["13360"],
      Jordanville: ["13361"],
      "Lee center": ["13363"],
      "Little falls": ["13365"],
      "Beaver river": ["13367"],
      "Lyons falls": ["13368"],
      "Zcta 133hh": ["133HH"],
      "Zcta 133xx": ["133XX"],
      Madison: ["13402"],
      Marcy: ["13403"],
      Middleville: ["13406"],
      Mohawk: ["13407"],
      Morrisville: ["13408"],
      Munnsville: ["13409"],
      "New berlin": ["13411"],
      "New hartford": ["13413"],
      Newport: ["13416"],
      "New york mills": ["13417"],
      "North brookfield": ["13418"],
      "Old forge": ["13420"],
      Oneida: ["13421"],
      Oriskany: ["13424"],
      "Oriskany falls": ["13425"],
      "Palatine bridge": ["13428"],
      Poland: ["13431"],
      "Port leyden": ["13433"],
      "Raquette lake": ["13436"],
      Redfield: ["13437"],
      Remsen: ["13438"],
      "Richfield spring": ["13439"],
      Rome: ["13440"],
      Roseboom: ["13450"],
      "Saint johnsville": ["13452"],
      "Salisbury center": ["13454"],
      Sauquoit: ["13456"],
      "Sharon springs": ["13459"],
      Sherburne: ["13460"],
      Sherrill: ["13461"],
      Smyrna: ["13464"],
      "Springfield cent": ["13468"],
      Stittville: ["13469"],
      Stratford: ["13470"],
      Taberg: ["13471"],
      Turin: ["13473"],
      "Van hornesville": ["13475"],
      Vernon: ["13476"],
      "Vernon center": ["13477"],
      Verona: ["13478"],
      Waterville: ["13480"],
      Westdale: ["13483"],
      "West edmeston": ["13485"],
      Westernville: ["13486"],
      "West leyden": ["13489"],
      Westmoreland: ["13490"],
      "West winfield": ["13491"],
      Whitesboro: ["13492"],
      Williamstown: ["13493"],
      Yorkville: ["13495"],
      "Zcta 134hh": ["134HH"],
      "Zcta 134xx": ["134XX"],
      Utica: ["13501", "13502"],
      "Zcta 135hh": ["135HH"],
      Watertown: ["13601"],
      "Fort drum": ["13602", "13603"],
      Smithville: ["13605"],
      "Adams center": ["13606"],
      "Point vivian": ["13607"],
      Antwerp: ["13608"],
      "Black river": ["13612"],
      "Brasher falls": ["13613"],
      "Brier hill": ["13614"],
      Brownville: ["13615"],
      Calcium: ["13616"],
      Canton: ["13617"],
      "Cape vincent": ["13618"],
      Carthage: ["13619"],
      Castorland: ["13620"],
      "Chase mills": ["13621"],
      Chaumont: ["13622"],
      Frontenac: ["13624"],
      Colton: ["13625"],
      Copenhagen: ["13626"],
      "De kalb junction": ["13630"],
      "De peyster": ["13633"],
      Dexter: ["13634"],
      Edwards: ["13635"],
      "Evans mills": ["13637"],
      "Felts mills": ["13638"],
      Fine: ["13639"],
      "Wellesley island": ["13640"],
      Gouverneur: ["13642"],
      Hammond: ["13646"],
      Harrisville: ["13648"],
      Henderson: ["13650"],
      Hermon: ["13652"],
      Heuvelton: ["13654"],
      Hogansburg: ["13655"],
      "La fargeville": ["13656"],
      Lisbon: ["13658"],
      Lorraine: ["13659"],
      Madrid: ["13660"],
      Mannsville: ["13661"],
      Massena: ["13662"],
      Morristown: ["13664"],
      "Natural bridge": ["13665"],
      Norfolk: ["13667"],
      Norwood: ["13668"],
      Ogdensburg: ["13669"],
      Oswegatchie: ["13670"],
      Parishville: ["13672"],
      Philadelphia: ["13673"],
      Plessis: ["13675"],
      Potsdam: ["13676"],
      Redwood: ["13679"],
      "Rensselaer falls": ["13680"],
      Richville: ["13681"],
      Rodman: ["13682"],
      Degrasse: ["13684"],
      "Sackets harbor": ["13685"],
      "South colton": ["13687"],
      "Star lake": ["13690"],
      Theresa: ["13691"],
      "Three mile bay": ["13693"],
      Waddington: ["13694"],
      Winthrop: ["13697"],
      "Zcta 136hh": ["136HH"],
      "Zcta 136xx": ["136XX"],
      Afton: ["13730"],
      Andes: ["13731"],
      Apalachin: ["13732"],
      Bainbridge: ["13733"],
      Barton: ["13734"],
      Berkshire: ["13736"],
      Bloomville: ["13739"],
      "Bovina center": ["13740"],
      Candor: ["13743"],
      "Castle creek": ["13744"],
      "Chenango forks": ["13746"],
      Conklin: ["13748"],
      Davenport: ["13750"],
      "De lancey": ["13752"],
      Meredith: ["13753"],
      Deposit: ["13754"],
      Downsville: ["13755"],
      "East branch": ["13756"],
      "East meredith": ["13757"],
      Endwell: ["13760"],
      "Fishs eddy": ["13774"],
      Franklin: ["13775"],
      Gilbertsville: ["13776"],
      "Glen aubrey": ["13777"],
      Greene: ["13778"],
      Guilford: ["13780"],
      Hamden: ["13782"],
      Cadosia: ["13783"],
      Harpersfield: ["13786"],
      Harpursville: ["13787"],
      Hobart: ["13788"],
      "Johnson city": ["13790"],
      Kirkwood: ["13795"],
      Laurens: ["13796"],
      Lisle: ["13797"],
      "Zcta 137hh": ["137HH"],
      "Mc donough": ["13801"],
      Maine: ["13802"],
      Marathon: ["13803"],
      Masonville: ["13804"],
      Milford: ["13807"],
      Morris: ["13808"],
      "Mount upton": ["13809"],
      "Mount vision": ["13810"],
      "Newark valley": ["13811"],
      Nichols: ["13812"],
      Nineveh: ["13813"],
      Norwich: ["13815"],
      Oneonta: ["13820"],
      Otego: ["13825"],
      Owego: ["13827"],
      Brisben: ["13830"],
      Plymouth: ["13832"],
      "Sanitaria spring": ["13833"],
      Portlandville: ["13834"],
      Richford: ["13835"],
      Sidney: ["13838"],
      "Sidney center": ["13839"],
      "Smithville flats": ["13841"],
      "South kortright": ["13842"],
      "South new berlin": ["13843"],
      "South plymouth": ["13844"],
      Treadwell: ["13846"],
      Unadilla: ["13849"],
      Vestal: ["13850"],
      Walton: ["13856"],
      "Wells bridge": ["13859"],
      "West oneonta": ["13861"],
      "Whitney point": ["13862"],
      Willet: ["13863"],
      Willseyville: ["13864"],
      Windsor: ["13865"],
      "Zcta 138hh": ["138HH"],
      Binghamton: ["13901", "13903", "13904", "13905"],
      "Zcta 139hh": ["139HH"],
      Akron: ["14001"],
      Alden: ["14004"],
      Alexander: ["14005"],
      Angola: ["14006"],
      Appleton: ["14008"],
      Arcade: ["14009"],
      Attica: ["14011"],
      Barker: ["14012"],
      Basom: ["14013"],
      Batavia: ["14020"],
      Bliss: ["14024"],
      Boston: ["14025"],
      Bowmansville: ["14026"],
      Burt: ["14028"],
      Chaffee: ["14030"],
      Clarence: ["14031"],
      "Clarence center": ["14032"],
      Colden: ["14033"],
      Collins: ["14034"],
      Corfu: ["14036"],
      Cowlesville: ["14037"],
      Dale: ["14039"],
      "Darien center": ["14040"],
      Delevan: ["14042"],
      Depew: ["14043"],
      Derby: ["14047"],
      "Van buren bay": ["14048"],
      Swormville: ["14051"],
      "East aurora": ["14052"],
      "East bethany": ["14054"],
      "East concord": ["14055"],
      Eden: ["14057"],
      Elba: ["14058"],
      Elma: ["14059"],
      "Farmersville sta": ["14060"],
      Forestville: ["14062"],
      Fredonia: ["14063"],
      Freedom: ["14065"],
      Gainesville: ["14066"],
      Gasport: ["14067"],
      Getzville: ["14068"],
      Glenwood: ["14069"],
      Gowanda: ["14070"],
      "Grand island": ["14072"],
      Hamburg: ["14075"],
      Holland: ["14080"],
      Irving: ["14081"],
      "Java center": ["14082"],
      "Java village": ["14083"],
      "Lake view": ["14085"],
      Lancaster: ["14086"],
      Lawtons: ["14091"],
      Lewiston: ["14092"],
      Lockport: ["14094"],
      Lyndonville: ["14098"],
      "Zcta 140hh": ["140HH"],
      Machias: ["14101"],
      Marilla: ["14102"],
      Medina: ["14103"],
      Middleport: ["14105"],
      Newfane: ["14108"],
      "North collins": ["14111"],
      "North java": ["14113"],
      "North tonawanda": ["14120"],
      Oakfield: ["14125"],
      "Orchard park": ["14127"],
      Perrysburg: ["14129"],
      Ransomville: ["14131"],
      Sanborn: ["14132"],
      Sardinia: ["14134"],
      "Silver creek": ["14136"],
      "South dayton": ["14138"],
      "South wales": ["14139"],
      Springville: ["14141"],
      Stafford: ["14143"],
      Strykersville: ["14145"],
      Tonawanda: ["14150"],
      Varysburg: ["14167"],
      "West falls": ["14170"],
      "West valley": ["14171"],
      Wilson: ["14172"],
      Youngstown: ["14174"],
      "Zcta 141hh": ["141HH"],
      Buffalo: [
        "14201",
        "14202",
        "14203",
        "14204",
        "14206",
        "14207",
        "14208",
        "14209",
        "14210",
        "14211",
        "14212",
        "14213",
        "14214",
        "14215",
        "14216",
        "14220",
        "14222",
        "14223",
      ],
      Kenmore: ["14217"],
      Lackawanna: ["14218"],
      Blasdell: ["14219"],
      Williamsville: ["14221"],
      "West seneca": ["14224"],
      Cheektowaga: ["14225", "14227"],
      Amherst: ["14226", "14228"],
      "Zcta 142hh": ["142HH"],
      "Niagara falls": ["14301", "14303", "14304", "14305"],
      "Zcta 143hh": ["143HH"],
      Albion: ["14411"],
      Avon: ["14414"],
      Bellona: ["14415"],
      Bergen: ["14416"],
      Branchport: ["14418"],
      Brockport: ["14420"],
      Byron: ["14422"],
      Caledonia: ["14423"],
      Canandaigua: ["14424", "14425"],
      Castile: ["14427"],
      Clifton: ["14428"],
      "Clifton springs": ["14432"],
      Clyde: ["14433"],
      Conesus: ["14435"],
      Dansville: ["14437"],
      Dresden: ["14441"],
      "East rochester": ["14445"],
      Fairport: ["14450"],
      Geneseo: ["14454"],
      Geneva: ["14456"],
      Groveland: ["14462"],
      Hamlin: ["14464"],
      Hemlock: ["14466"],
      Henrietta: ["14467"],
      Hilton: ["14468"],
      Bloomfield: ["14469"],
      Hulberton: ["14470"],
      Honeoye: ["14471"],
      "Honeoye falls": ["14472"],
      Ionia: ["14475"],
      Kendall: ["14476"],
      Kent: ["14477"],
      "Bluff point": ["14478"],
      Lakeville: ["14480"],
      Leicester: ["14481"],
      "Le roy": ["14482"],
      Lima: ["14485"],
      Livonia: ["14487"],
      Lyons: ["14489"],
      "Zcta 144hh": ["144HH"],
      Macedon: ["14502"],
      Manchester: ["14504"],
      Marion: ["14505"],
      Mendon: ["14506"],
      Middlesex: ["14507"],
      Tuscarora: ["14510"],
      Naples: ["14512"],
      Newark: ["14513"],
      "North chili": ["14514"],
      "North rose": ["14516"],
      Nunda: ["14517"],
      Ontario: ["14519"],
      "Hayt corners": ["14521"],
      Palmyra: ["14522"],
      Linwood: ["14525"],
      Penfield: ["14526"],
      "Penn yan": ["14527"],
      Perry: ["14530"],
      Phelps: ["14532"],
      Wadsworth: ["14533"],
      Pittsford: ["14534"],
      Portageville: ["14536"],
      "Mac dougall": ["14541"],
      "West rush": ["14543"],
      Rushville: ["14544"],
      Scottsburg: ["14545"],
      Scottsville: ["14546"],
      Shortsville: ["14548"],
      "Rock glen": ["14550"],
      Sodus: ["14551"],
      "Sodus point": ["14555"],
      Spencerport: ["14559"],
      Springwater: ["14560"],
      Stanley: ["14561"],
      Victor: ["14564"],
      Walworth: ["14568"],
      Warsaw: ["14569"],
      Waterport: ["14571"],
      Wayland: ["14572"],
      Webster: ["14580"],
      "West bloomfield": ["14585"],
      "West henrietta": ["14586"],
      Williamson: ["14589"],
      Wolcott: ["14590"],
      Wyoming: ["14591"],
      "Zcta 145hh": ["145HH"],
      Rochester: [
        "14604",
        "14605",
        "14606",
        "14607",
        "14608",
        "14609",
        "14610",
        "14611",
        "14612",
        "14613",
        "14614",
        "14615",
        "14617",
        "14619",
        "14620",
        "14621",
        "14622",
        "14623",
        "14626",
      ],
      Greece: ["14616"],
      "Twelve corners": ["14618"],
      Westgate: ["14624"],
      Panorama: ["14625"],
      "Zcta 146hh": ["146HH"],
      Jamestown: ["14701"],
      Allegany: ["14706"],
      Alma: ["14708"],
      Angelica: ["14709"],
      Ashville: ["14710"],
      Belfast: ["14711"],
      "Bemus point": ["14712"],
      "Black creek": ["14714"],
      Bolivar: ["14715"],
      Brocton: ["14716"],
      Caneadea: ["14717"],
      Cassadaga: ["14718"],
      Cattaraugus: ["14719"],
      Ceres: ["14721"],
      "Cherry creek": ["14723"],
      Clymer: ["14724"],
      "Conewango valley": ["14726"],
      Cuba: ["14727"],
      Dewittville: ["14728"],
      "East otto": ["14729"],
      Ellicottville: ["14731"],
      Falconer: ["14733"],
      Fillmore: ["14735"],
      "Findley lake": ["14736"],
      Franklinville: ["14737"],
      Frewsburg: ["14738"],
      Friendship: ["14739"],
      Gerry: ["14740"],
      "Great valley": ["14741"],
      Ischua: ["14743"],
      Houghton: ["14744"],
      Kennedy: ["14747"],
      "Kill buck": ["14748"],
      Lakewood: ["14750"],
      Limestone: ["14753"],
      "Little genesee": ["14754"],
      "Little valley": ["14755"],
      Mayville: ["14757"],
      Olean: ["14760"],
      Panama: ["14767"],
      Portland: ["14769"],
      Portville: ["14770"],
      Randolph: ["14772"],
      Ripley: ["14775"],
      Rushford: ["14777"],
      Salamanca: ["14779"],
      Sherman: ["14781"],
      Sinclairville: ["14782"],
      Steamburg: ["14783"],
      Stockton: ["14784"],
      Westfield: ["14787"],
      "Zcta 147hh": ["147HH"],
      "Zcta 147xx": ["147XX"],
      Addison: ["14801"],
      Alfred: ["14802"],
      "Alfred station": ["14803"],
      Almond: ["14804"],
      Alpine: ["14805"],
      Andover: ["14806"],
      Arkport: ["14807"],
      Atlanta: ["14808"],
      Wallace: ["14809"],
      "Veterans adminis": ["14810"],
      "Beaver dams": ["14812"],
      Belmont: ["14813"],
      "Big flats": ["14814"],
      Bradford: ["14815"],
      Breesport: ["14816"],
      Brooktondale: ["14817"],
      Burdett: ["14818"],
      Cameron: ["14819"],
      "Cameron mills": ["14820"],
      Campbell: ["14821"],
      Canaseraga: ["14822"],
      Canisteo: ["14823"],
      Cayuta: ["14824"],
      Chemung: ["14825"],
      Cohocton: ["14826"],
      Corning: ["14830"],
      Dalton: ["14836"],
      Dundee: ["14837"],
      Erin: ["14838"],
      Greenwood: ["14839"],
      Hammondsport: ["14840"],
      Hector: ["14841"],
      Himrod: ["14842"],
      Hornell: ["14843"],
      Horseheads: ["14845"],
      Hunt: ["14846"],
      Interlaken: ["14847"],
      "Ithaca college": ["14850"],
      Jasper: ["14855"],
      Lakemont: ["14857"],
      Lindley: ["14858"],
      Lockwood: ["14859"],
      Lodi: ["14860"],
      Lowman: ["14861"],
      Millport: ["14864"],
      "Montour falls": ["14865"],
      Newfield: ["14867"],
      Odessa: ["14869"],
      "Painted post": ["14870"],
      "Pine city": ["14871"],
      "Pine valley": ["14872"],
      Prattsburg: ["14873"],
      Pulteney: ["14874"],
      Rexville: ["14877"],
      "Rock stream": ["14878"],
      Savona: ["14879"],
      Scio: ["14880"],
      "Slaterville spri": ["14881"],
      Lansing: ["14882"],
      Spencer: ["14883"],
      Swain: ["14884"],
      Troupsburg: ["14885"],
      Trumansburg: ["14886"],
      "Van etten": ["14889"],
      "Watkins glen": ["14891"],
      Waverly: ["14892"],
      Wellsburg: ["14894"],
      Wellsville: ["14895"],
      Whitesville: ["14897"],
      Woodhull: ["14898"],
      "Zcta 148hh": ["148HH"],
      Elmira: ["14901", "14904", "14905"],
      "Elmira heights": ["14903"],
      "Zcta 149hh": ["149HH"],
    },
  },
  {
    abbrevation: "NC",
    name: "North carolina",
    Cities: [
      "Advance",
      "Advance",
      "Ararat",
      "Belews creek",
      "Boonville",
      "Clemmons",
      "Cleveland",
      "Cooleemee",
      "Danbury",
      "Dobson",
      "East bend",
      "Germanton",
      "Hamptonville",
      "King",
      "Lawsonville",
      "Lewisville",
      "Lowgap",
      "Madison",
      "Mayodan",
      "Mocksville",
      "Mount airy",
      "Pfafftown",
      "Pilot mountain",
      "Pine hall",
      "Pinnacle",
      "Rural hall",
      "Sandy ridge",
      "Siloam",
      "Stoneville",
      "Tobaccoville",
      "Walkertown",
      "Walnut cove",
      "Westfield",
      "Woodleaf",
      "Yadkinville",
      "Zcta 270hh",
      "Winston salem",
      "Zcta 271hh",
      "Altamahaw",
      "Farmer",
      "Bear creek",
      "Bennett",
      "Biscoe",
      "Blanch",
      "Browns summit",
      "Glen raven",
      "Burlington",
      "Candor",
      "Cedar grove",
      "Climax",
      "Colfax",
      "Denton",
      "Eagle springs",
      "Efland",
      "Elon college",
      "Franklinville",
      "Gibsonville",
      "Goldston",
      "Graham",
      "Gulf",
      "Haw river",
      "High point",
      "Archdale",
      "Hillsborough",
      "Jackson springs",
      "Jamestown",
      "Julian",
      "Kernersville",
      "Eden",
      "Leasburg",
      "Lexington",
      "Zcta 27295",
      "Liberty",
      "Linwood",
      "Zcta 272hh",
      "Mc leansville",
      "Mebane",
      "Milton",
      "Mount gilead",
      "Oak ridge",
      "Pelham",
      "Pittsboro",
      "Pleasant garden",
      "Prospect hill",
      "Providence",
      "Coleridge",
      "Randleman",
      "Reidsville",
      "Robbins",
      "Ruffin",
      "Colon",
      "Seagrove",
      "Sedalia",
      "Semora",
      "Siler city",
      "Snow camp",
      "Sophia",
      "Staley",
      "Star",
      "Stokesdale",
      "Summerfield",
      "Thomasville",
      "Trinity",
      "Troy",
      "West end",
      "Whitsett",
      "Yanceyville",
      "Zcta 273hh",
      "Greensboro",
      "Zcta 274hh",
      "Angier",
      "Apex",
      "Bahama",
      "Benson",
      "Broadway",
      "Buies creek",
      "Bullock",
      "Bunn",
      "Butner",
      "Carrboro",
      "Cary",
      "Chapel hill",
      "Clayton",
      "Coats",
      "Creedmoor",
      "Four oaks",
      "Franklinton",
      "Fuquay varina",
      "Garner",
      "Grantham",
      "Goldsboro",
      "Henderson",
      "Holly springs",
      "Hurdle mills",
      "Kenly",
      "Kittrell",
      "Knightdale",
      "Lillington",
      "Louisburg",
      "Macon",
      "Manson",
      "Micro",
      "Middlesex",
      "Moncure",
      "Morrisville",
      "New hill",
      "Norlina",
      "Oxford",
      "Pine level",
      "Princeton",
      "Ridgeway",
      "Rolesville",
      "Rougemont",
      "Roxboro",
      "Selma",
      "Smithfield",
      "Stem",
      "Stovall",
      "Timberlake",
      "Townsville",
      "Wake forest",
      "Warrenton",
      "Wendell",
      "Willow spring",
      "Youngsville",
      "Zebulon",
      "Zcta 275hh",
      "Raleigh",
      "Zcta 276hh",
      "Durham",
      "Research triangl",
      "Zcta 277hh",
      "Rocky mount",
      "Wesleyan college",
      "Aulander",
      "Aurora",
      "Bailey",
      "Bath",
      "Battleboro",
      "Belhaven",
      "Bethel",
      "Black creek",
      "Blounts creek",
      "Castalia",
      "Chocowinity",
      "Como",
      "Conetoe",
      "Conway",
      "Edward",
      "Elm city",
      "Enfield",
      "Middletown",
      "Fairfield",
      "Falkland",
      "Farmville",
      "Fountain",
      "Eureka",
      "Garysburg",
      "Gaston",
      "Greenville",
      "Grimesland",
      "Halifax",
      "Hamilton",
      "Hassell",
      "Henrico",
      "Hobgood",
      "Hollister",
      "Jackson",
      "Jamesville",
      "Kelford",
      "Lewiston woodvil",
      "Littleton",
      "Lucama",
      "Crisp",
      "Margarettsville",
      "Murfreesboro",
      "Nashville",
      "Oak city",
      "Pantego",
      "Pendleton",
      "Pikeville",
      "Pinetops",
      "Pinetown",
      "Pleasant hill",
      "Rich square",
      "Roanoke rapids",
      "Robersonville",
      "Roxobel",
      "Saratoga",
      "Scotland neck",
      "Scranton",
      "Seaboard",
      "Severn",
      "Sharpsburg",
      "Sims",
      "Spring hope",
      "Stantonsburg",
      "Stokes",
      "Swanquarter",
      "Tarboro",
      "Walstonburg",
      "Washington",
      "Weldon",
      "Whitakers",
      "Williamston",
      "Wilson",
      "George",
      "Zcta 278hh",
      "Zcta 278xx",
      "Elizabeth city",
      "Ahoskie",
      "Avon",
      "Aydlett",
      "Barco",
      "Belvidere",
      "Buxton",
      "Camden",
      "Cofield",
      "Coinjock",
      "Colerain",
      "Columbia",
      "Corapeake",
      "Corolla",
      "Creswell",
      "Currituck",
      "Edenton",
      "Eure",
      "Frisco",
      "Gates",
      "Gatesville",
      "Grandy",
      "Harbinger",
      "Harrellsville",
      "Hatteras",
      "Durants neck",
      "Hobbsville",
      "Jarvisburg",
      "Kill devil hills",
      "Southern shores",
      "Knotts island",
      "East lake",
      "Manteo",
      "Maple",
      "Merry hill",
      "Moyock",
      "Nags head",
      "Ocracoke",
      "Plymouth",
      "Point harbor",
      "Poplar branch",
      "Powells point",
      "Powellsville",
      "Rodanthe",
      "Roper",
      "Salvo",
      "Shawboro",
      "Shiloh",
      "South mills",
      "Sunbury",
      "Tyner",
      "Wanchese",
      "Waves",
      "Windsor",
      "Winfall",
      "Winton",
      "Zcta 279hh",
      "Zcta 279xx",
      "Albemarle",
      "Alexis",
      "Ansonville",
      "Badin",
      "Belmont",
      "Bessemer city",
      "Gardner webb col",
      "Bostic",
      "Caroleen",
      "Casar",
      "Cherryville",
      "China grove",
      "Concord",
      "Cornelius",
      "Cramerton",
      "Crouse",
      "Dallas",
      "Denver",
      "Earl",
      "Ellenboro",
      "Alexander mills",
      "Gastonia",
      "Gold hill",
      "Granite quarry",
      "Grover",
      "Harrisburg",
      "Henrietta",
      "High shoals",
      "Indian trail",
      "Iron station",
      "Kannapolis",
      "Kings mountain",
      "Landis",
      "Lattimore",
      "Lawndale",
      "Lilesville",
      "Boger city",
      "Locust",
      "Lowell",
      "Zcta 280hh",
      "Mc adenville",
      "Mc farlan",
      "Marshville",
      "Zcta 28104",
      "Stallings",
      "Midland",
      "Misenheimer",
      "Monroe",
      "Mooresboro",
      "Mooresville",
      "Zcta 28117",
      "Morven",
      "Mount holly",
      "Mount pleasant",
      "Mount ulla",
      "New london",
      "Norwood",
      "Oakboro",
      "Peachland",
      "Pineville",
      "Polkton",
      "Richfield",
      "Rockwell",
      "Rutherfordton",
      "Salisbury",
      "Kingstown",
      "Shelby",
      "Spencer",
      "Spindale",
      "Stanfield",
      "Stanley",
      "Troutman",
      "Union mills",
      "Vale",
      "Waco",
      "Wadesboro",
      "Weddington",
      "Wingate",
      "Zcta 281hh",
      "Charlotte",
      "Zcta 282hh",
      "East fayettevill",
      "Bonnie doone",
      "Fayetteville",
      "Fort bragg",
      "Aberdeen",
      "Autryville",
      "Bladenboro",
      "Bunnlevel",
      "Calypso",
      "Johnsonville",
      "Carthage",
      "Clinton",
      "Cordova",
      "Dublin",
      "Dudley",
      "Dunn",
      "Elizabethtown",
      "Ellerbe",
      "Erwin",
      "Mcdonald",
      "Faison",
      "Falcon",
      "Gibson",
      "Godwin",
      "Hamlet",
      "Hoffman",
      "Hope mills",
      "Kenansville",
      "Lakeview",
      "Laurel hill",
      "Laurinburg",
      "Linden",
      "Lumber bridge",
      "Lumberton",
      "Zcta 28360",
      "Marietta",
      "Marston",
      "Maxton",
      "Mount olive",
      "Newton grove",
      "Norman",
      "Olivia",
      "Orrum",
      "Parkton",
      "Pembroke",
      "Pinebluff",
      "Pinehurst",
      "Raeford",
      "Red springs",
      "Rex",
      "Rockingham",
      "Roseboro",
      "Rowland",
      "Saint pauls",
      "Salemburg",
      "Shannon",
      "Southern pines",
      "Spring lake",
      "Stedman",
      "Tar heel",
      "Turkey",
      "Vass",
      "Wade",
      "Wagram",
      "Bowdens",
      "White oak",
      "Zcta 283hh",
      "Zcta 283xx",
      "Cape fear",
      "Wilmington",
      "Ogden",
      "Zcta 28411",
      "Ash",
      "Atkinson",
      "Bolivia",
      "Bolton",
      "Burgaw",
      "Carolina beach",
      "Castle hayne",
      "Cerro gordo",
      "Chadbourn",
      "Clarendon",
      "Clarkton",
      "Council",
      "Currie",
      "Delco",
      "Evergreen",
      "Fair bluff",
      "Garland",
      "Hallsboro",
      "Hampstead",
      "Harrells",
      "Surf city",
      "Ivanhoe",
      "Kelly",
      "Kure beach",
      "Lake waccamaw",
      "Leland",
      "Longwood",
      "Magnolia",
      "Maple hill",
      "Nakina",
      "Riegelwood",
      "Rocky point",
      "Rose hill",
      "Sneads ferry",
      "Boiling spring l",
      "Holden beach",
      "Tabor city",
      "Teachey",
      "Oak island",
      "Wallace",
      "Calabash",
      "Sunset beach",
      "Ocean isle beach",
      "South brunswick",
      "Watha",
      "Whiteville",
      "Willard",
      "Winnabow",
      "Wrightsville bea",
      "Zcta 284hh",
      "Zcta 284xx",
      "Kinston",
      "Zcta 28504",
      "Albertson",
      "Arapahoe",
      "Atlantic",
      "Pine knoll shore",
      "Ayden",
      "Bayboro",
      "Beaufort",
      "Beulaville",
      "Bridgeton",
      "Cedar island",
      "Chinquapin",
      "Cove city",
      "Davis",
      "Deep run",
      "Dover",
      "Ernul",
      "Gloucester",
      "Grantsboro",
      "Grifton",
      "Harkers island",
      "Havelock",
      "Hobucken",
      "Hookerton",
      "Hubert",
      "Jacksonville",
      "Tarawa terrace",
      "Midway park",
      "Camp lejeune",
      "La grange",
      "Lowland",
      "Marshallberg",
      "Maury",
      "Maysville",
      "Merritt",
      "Morehead city",
      "New bern",
      "Newport",
      "Oriental",
      "Pink hill",
      "Pollocksville",
      "Richlands",
      "Salter path",
      "Sealevel",
      "Seven springs",
      "Smyrna",
      "Snow hill",
      "Stacy",
      "Stella",
      "Swansboro",
      "Trenton",
      "Vanceboro",
      "Williston",
      "Winterville",
      "Emerald isle",
      "Zcta 285hh",
      "Zcta 285xx",
      "Hickory",
      "Banner elk",
      "Blowing rock",
      "Boomer",
      "Boone",
      "Catawba",
      "Claremont",
      "Collettsville",
      "Connellys spring",
      "Conover",
      "Creston",
      "Crossnore",
      "Crumpler",
      "Deep gap",
      "Drexel",
      "Elkin",
      "Elk park",
      "Ennice",
      "Ferguson",
      "Zcta 28625",
      "Fleetwood",
      "Glade valley",
      "Glendale springs",
      "Granite falls",
      "Grassy creek",
      "Harmony",
      "Hays",
      "Hiddenite",
      "Hildebran",
      "Hudson",
      "Jefferson",
      "Jonesville",
      "Lansing",
      "Laurel springs",
      "Lenoir",
      "Linville",
      "Mc grady",
      "Maiden",
      "Millers creek",
      "Moravian falls",
      "Morganton",
      "Frank",
      "Newton",
      "North wilkesboro",
      "Olin",
      "Pineola",
      "Piney creek",
      "Purlear",
      "Icard",
      "Roaring gap",
      "Roaring river",
      "Ronda",
      "Rutherford colle",
      "Sherrills ford",
      "Sparta",
      "State road",
      "Statesville",
      "Stony point",
      "Sugar grove",
      "Taylorsville",
      "Terrell",
      "Thurmond",
      "Todd",
      "Traphill",
      "Union grove",
      "Valdese",
      "Vilas",
      "Warrensville",
      "West jefferson",
      "Wilkesboro",
      "Zionville",
      "Zcta 286hh",
      "Alexander",
      "Almond",
      "Arden",
      "Bakersville",
      "Balsam",
      "Balsam grove",
      "Barnardsville",
      "Bat cave",
      "Black mountain s",
      "Brevard",
      "Bryson city",
      "Burnsville",
      "Candler",
      "Canton",
      "Cashiers",
      "Cedar mountain",
      "Cherokee",
      "Clyde",
      "Columbus",
      "Cullowhee",
      "Dillsboro",
      "East flat rock",
      "Etowah",
      "Fairview",
      "Flat rock",
      "Fletcher",
      "Fontana dam",
      "Franklin",
      "Gerton",
      "Glenville",
      "Hendersonville",
      "Greenmountain",
      "Highlands",
      "Horse shoe",
      "Hot springs",
      "Lake junaluska",
      "Lake lure",
      "Lake toxaway",
      "Leicester",
      "Little switzerla",
      "Maggie valley",
      "Marion",
      "Walnut",
      "Mars hill",
      "Micaville",
      "Mill spring",
      "Montreat",
      "Mountain home",
      "Nebo",
      "Old fort",
      "Otto",
      "Penland",
      "Penrose",
      "Pisgah forest",
      "Robbinsville",
      "Rosman",
      "Saluda",
      "Sapphire",
      "Spruce pine",
      "Warren wilson co",
      "Sylva",
      "Topton",
      "Tryon",
      "Tuckasegee",
      "Tuxedo",
      "Waynesville",
      "Weaverville",
      "Whittier",
      "Zirconia",
      "Zcta 28791",
      "Zcta 287hh",
      "Zcta 287xx",
      "Asheville",
      "Zcta 288hh",
      "Andrews",
      "Brasstown",
      "Hayesville",
      "Marble",
      "Unaka",
      "Warne",
      "Zcta 289hh",
    ],
    ZipCodes: {
      Advance: ["27006"],
      Ararat: ["27007"],
      "Belews creek": ["27009"],
      Boonville: ["27011"],
      Clemmons: ["27012"],
      Cleveland: ["27013"],
      Cooleemee: ["27014"],
      Danbury: ["27016"],
      Dobson: ["27017"],
      "East bend": ["27018"],
      Germanton: ["27019"],
      Hamptonville: ["27020"],
      King: ["27021"],
      Lawsonville: ["27022"],
      Lewisville: ["27023"],
      Lowgap: ["27024"],
      Madison: ["27025"],
      Mayodan: ["27027"],
      Mocksville: ["27028"],
      "Mount airy": ["27030"],
      Pfafftown: ["27040"],
      "Pilot mountain": ["27041"],
      "Pine hall": ["27042"],
      Pinnacle: ["27043"],
      "Rural hall": ["27045"],
      "Sandy ridge": ["27046"],
      Siloam: ["27047"],
      Stoneville: ["27048"],
      Tobaccoville: ["27050"],
      Walkertown: ["27051"],
      "Walnut cove": ["27052"],
      Westfield: ["27053"],
      Woodleaf: ["27054"],
      Yadkinville: ["27055"],
      "Zcta 270hh": ["270HH"],
      "Winston salem": [
        "27101",
        "27103",
        "27104",
        "27105",
        "27106",
        "27107",
        "27109",
        "27127",
      ],
      "Zcta 271hh": ["271HH"],
      Altamahaw: ["27202"],
      Farmer: ["27203"],
      "Bear creek": ["27207"],
      Bennett: ["27208"],
      Biscoe: ["27209"],
      Blanch: ["27212"],
      "Browns summit": ["27214"],
      "Glen raven": ["27215"],
      Burlington: ["27217"],
      Candor: ["27229"],
      "Cedar grove": ["27231"],
      Climax: ["27233"],
      Colfax: ["27235"],
      Denton: ["27239"],
      "Eagle springs": ["27242"],
      Efland: ["27243"],
      "Elon college": ["27244"],
      Franklinville: ["27248"],
      Gibsonville: ["27249"],
      Goldston: ["27252"],
      Graham: ["27253"],
      Gulf: ["27256"],
      "Haw river": ["27258"],
      "High point": ["27260", "27262", "27265"],
      Archdale: ["27263"],
      Hillsborough: ["27278"],
      "Jackson springs": ["27281"],
      Jamestown: ["27282"],
      Julian: ["27283"],
      Kernersville: ["27284"],
      Eden: ["27288"],
      Leasburg: ["27291"],
      Lexington: ["27292"],
      "Zcta 27295": ["27295"],
      Liberty: ["27298"],
      Linwood: ["27299"],
      "Zcta 272hh": ["272HH"],
      "Mc leansville": ["27301"],
      Mebane: ["27302"],
      Milton: ["27305"],
      "Mount gilead": ["27306"],
      "Oak ridge": ["27310"],
      Pelham: ["27311"],
      Pittsboro: ["27312"],
      "Pleasant garden": ["27313"],
      "Prospect hill": ["27314"],
      Providence: ["27315"],
      Coleridge: ["27316"],
      Randleman: ["27317"],
      Reidsville: ["27320"],
      Robbins: ["27325"],
      Ruffin: ["27326"],
      Colon: ["27330"],
      Seagrove: ["27341"],
      Sedalia: ["27342"],
      Semora: ["27343"],
      "Siler city": ["27344"],
      "Snow camp": ["27349"],
      Sophia: ["27350"],
      Staley: ["27355"],
      Star: ["27356"],
      Stokesdale: ["27357"],
      Summerfield: ["27358"],
      Thomasville: ["27360"],
      Trinity: ["27370"],
      Troy: ["27371"],
      "West end": ["27376"],
      Whitsett: ["27377"],
      Yanceyville: ["27379"],
      "Zcta 273hh": ["273HH"],
      Greensboro: [
        "27401",
        "27403",
        "27405",
        "27406",
        "27407",
        "27408",
        "27409",
        "27410",
        "27455",
      ],
      "Zcta 274hh": ["274HH"],
      Angier: ["27501"],
      Apex: ["27502"],
      Bahama: ["27503"],
      Benson: ["27504"],
      Broadway: ["27505"],
      "Buies creek": ["27506"],
      Bullock: ["27507"],
      Bunn: ["27508"],
      Butner: ["27509"],
      Carrboro: ["27510"],
      Cary: ["27511", "27513"],
      "Chapel hill": ["27514", "27516"],
      Clayton: ["27520"],
      Coats: ["27521"],
      Creedmoor: ["27522"],
      "Four oaks": ["27524"],
      Franklinton: ["27525"],
      "Fuquay varina": ["27526"],
      Garner: ["27529"],
      Grantham: ["27530"],
      Goldsboro: ["27534"],
      Henderson: ["27536"],
      "Holly springs": ["27540"],
      "Hurdle mills": ["27541"],
      Kenly: ["27542"],
      Kittrell: ["27544"],
      Knightdale: ["27545"],
      Lillington: ["27546"],
      Louisburg: ["27549"],
      Macon: ["27551"],
      Manson: ["27553"],
      Micro: ["27555"],
      Middlesex: ["27557"],
      Moncure: ["27559"],
      Morrisville: ["27560"],
      "New hill": ["27562"],
      Norlina: ["27563"],
      Oxford: ["27565"],
      "Pine level": ["27568"],
      Princeton: ["27569"],
      Ridgeway: ["27570"],
      Rolesville: ["27571"],
      Rougemont: ["27572"],
      Roxboro: ["27573"],
      Selma: ["27576"],
      Smithfield: ["27577"],
      Stem: ["27581"],
      Stovall: ["27582"],
      Timberlake: ["27583"],
      Townsville: ["27584"],
      "Wake forest": ["27587"],
      Warrenton: ["27589"],
      Wendell: ["27591"],
      "Willow spring": ["27592"],
      Youngsville: ["27596"],
      Zebulon: ["27597"],
      "Zcta 275hh": ["275HH"],
      Raleigh: [
        "27601",
        "27603",
        "27604",
        "27605",
        "27606",
        "27607",
        "27608",
        "27609",
        "27610",
        "27612",
        "27613",
        "27614",
        "27615",
        "27616",
      ],
      "Zcta 276hh": ["276HH"],
      Durham: ["27701", "27703", "27704", "27705", "27706", "27707", "27712"],
      "Research triangl": ["27713"],
      "Zcta 277hh": ["277HH"],
      "Rocky mount": ["27801", "27803"],
      "Wesleyan college": ["27804"],
      Aulander: ["27805"],
      Aurora: ["27806"],
      Bailey: ["27807"],
      Bath: ["27808"],
      Battleboro: ["27809"],
      Belhaven: ["27810"],
      Bethel: ["27812"],
      "Black creek": ["27813"],
      "Blounts creek": ["27814"],
      Castalia: ["27816"],
      Chocowinity: ["27817"],
      Como: ["27818"],
      Conetoe: ["27819"],
      Conway: ["27820"],
      Edward: ["27821"],
      "Elm city": ["27822"],
      Enfield: ["27823"],
      Middletown: ["27824"],
      Fairfield: ["27826"],
      Falkland: ["27827"],
      Farmville: ["27828"],
      Fountain: ["27829"],
      Eureka: ["27830"],
      Garysburg: ["27831"],
      Gaston: ["27832"],
      Greenville: ["27834", "27858"],
      Grimesland: ["27837"],
      Halifax: ["27839"],
      Hamilton: ["27840"],
      Hassell: ["27841"],
      Henrico: ["27842"],
      Hobgood: ["27843"],
      Hollister: ["27844"],
      Jackson: ["27845"],
      Jamesville: ["27846"],
      Kelford: ["27847"],
      "Lewiston woodvil": ["27849"],
      Littleton: ["27850"],
      Lucama: ["27851"],
      Crisp: ["27852"],
      Margarettsville: ["27853"],
      Murfreesboro: ["27855"],
      Nashville: ["27856"],
      "Oak city": ["27857"],
      Pantego: ["27860"],
      Pendleton: ["27862"],
      Pikeville: ["27863"],
      Pinetops: ["27864"],
      Pinetown: ["27865"],
      "Pleasant hill": ["27866"],
      "Rich square": ["27869"],
      "Roanoke rapids": ["27870"],
      Robersonville: ["27871"],
      Roxobel: ["27872"],
      Saratoga: ["27873"],
      "Scotland neck": ["27874"],
      Scranton: ["27875"],
      Seaboard: ["27876"],
      Severn: ["27877"],
      Sharpsburg: ["27878"],
      Sims: ["27880"],
      "Spring hope": ["27882"],
      Stantonsburg: ["27883"],
      Stokes: ["27884"],
      Swanquarter: ["27885"],
      Tarboro: ["27886"],
      Walstonburg: ["27888"],
      Washington: ["27889"],
      Weldon: ["27890"],
      Whitakers: ["27891"],
      Williamston: ["27892"],
      Wilson: ["27893", "27896"],
      George: ["27897"],
      "Zcta 278hh": ["278HH"],
      "Zcta 278xx": ["278XX"],
      "Elizabeth city": ["27909"],
      Ahoskie: ["27910"],
      Avon: ["27915"],
      Aydlett: ["27916"],
      Barco: ["27917"],
      Belvidere: ["27919"],
      Buxton: ["27920"],
      Camden: ["27921"],
      Cofield: ["27922"],
      Coinjock: ["27923"],
      Colerain: ["27924"],
      Columbia: ["27925"],
      Corapeake: ["27926"],
      Corolla: ["27927"],
      Creswell: ["27928"],
      Currituck: ["27929"],
      Edenton: ["27932"],
      Eure: ["27935"],
      Frisco: ["27936"],
      Gates: ["27937"],
      Gatesville: ["27938"],
      Grandy: ["27939"],
      Harbinger: ["27941"],
      Harrellsville: ["27942"],
      Hatteras: ["27943"],
      "Durants neck": ["27944"],
      Hobbsville: ["27946"],
      Jarvisburg: ["27947"],
      "Kill devil hills": ["27948"],
      "Southern shores": ["27949"],
      "Knotts island": ["27950"],
      "East lake": ["27953"],
      Manteo: ["27954"],
      Maple: ["27956"],
      "Merry hill": ["27957"],
      Moyock: ["27958"],
      "Nags head": ["27959"],
      Ocracoke: ["27960"],
      Plymouth: ["27962"],
      "Point harbor": ["27964"],
      "Poplar branch": ["27965"],
      "Powells point": ["27966"],
      Powellsville: ["27967"],
      Rodanthe: ["27968"],
      Roper: ["27970"],
      Salvo: ["27972"],
      Shawboro: ["27973"],
      Shiloh: ["27974"],
      "South mills": ["27976"],
      Sunbury: ["27979"],
      Tyner: ["27980"],
      Wanchese: ["27981"],
      Waves: ["27982"],
      Windsor: ["27983"],
      Winfall: ["27985"],
      Winton: ["27986"],
      "Zcta 279hh": ["279HH"],
      "Zcta 279xx": ["279XX"],
      Albemarle: ["28001"],
      Alexis: ["28006"],
      Ansonville: ["28007"],
      Badin: ["28009"],
      Belmont: ["28012"],
      "Bessemer city": ["28016"],
      "Gardner webb col": ["28017"],
      Bostic: ["28018"],
      Caroleen: ["28019"],
      Casar: ["28020"],
      Cherryville: ["28021"],
      "China grove": ["28023"],
      Concord: ["28025", "28027"],
      Cornelius: ["28031", "28036", "28078"],
      Cramerton: ["28032"],
      Crouse: ["28033"],
      Dallas: ["28034"],
      Denver: ["28037"],
      Earl: ["28038"],
      Ellenboro: ["28040"],
      "Alexander mills": ["28043"],
      Gastonia: ["28052", "28054", "28056"],
      "Gold hill": ["28071"],
      "Granite quarry": ["28072"],
      Grover: ["28073"],
      Harrisburg: ["28075"],
      Henrietta: ["28076"],
      "High shoals": ["28077"],
      "Indian trail": ["28079"],
      "Iron station": ["28080"],
      Kannapolis: ["28081", "28083"],
      "Kings mountain": ["28086"],
      Landis: ["28088"],
      Lattimore: ["28089"],
      Lawndale: ["28090"],
      Lilesville: ["28091"],
      "Boger city": ["28092"],
      Locust: ["28097"],
      Lowell: ["28098"],
      "Zcta 280hh": ["280HH"],
      "Mc adenville": ["28101"],
      "Mc farlan": ["28102"],
      Marshville: ["28103"],
      "Zcta 28104": ["28104"],
      Stallings: ["28105"],
      Midland: ["28107"],
      Misenheimer: ["28109"],
      Monroe: ["28110", "28112"],
      Mooresboro: ["28114"],
      Mooresville: ["28115"],
      "Zcta 28117": ["28117"],
      Morven: ["28119"],
      "Mount holly": ["28120"],
      "Mount pleasant": ["28124"],
      "Mount ulla": ["28125"],
      "New london": ["28127"],
      Norwood: ["28128"],
      Oakboro: ["28129"],
      Peachland: ["28133"],
      Pineville: ["28134"],
      Polkton: ["28135"],
      Richfield: ["28137"],
      Rockwell: ["28138"],
      Rutherfordton: ["28139"],
      Salisbury: ["28144", "28146", "28147"],
      Kingstown: ["28150"],
      Shelby: ["28152"],
      Spencer: ["28159"],
      Spindale: ["28160"],
      Stanfield: ["28163"],
      Stanley: ["28164"],
      Troutman: ["28166"],
      "Union mills": ["28167"],
      Vale: ["28168"],
      Waco: ["28169"],
      Wadesboro: ["28170"],
      Weddington: ["28173"],
      Wingate: ["28174"],
      "Zcta 281hh": ["281HH"],
      Charlotte: [
        "28202",
        "28203",
        "28204",
        "28205",
        "28206",
        "28207",
        "28208",
        "28209",
        "28210",
        "28211",
        "28212",
        "28213",
        "28214",
        "28215",
        "28216",
        "28217",
        "28223",
        "28226",
        "28227",
        "28262",
        "28269",
        "28270",
        "28273",
        "28277",
        "28278",
      ],
      "Zcta 282hh": ["282HH"],
      "East fayettevill": ["28301"],
      "Bonnie doone": ["28303"],
      Fayetteville: ["28304", "28305", "28306", "28311", "28314"],
      "Fort bragg": ["28307"],
      Aberdeen: ["28315"],
      Autryville: ["28318"],
      Bladenboro: ["28320"],
      Bunnlevel: ["28323"],
      Calypso: ["28325"],
      Johnsonville: ["28326"],
      Carthage: ["28327"],
      Clinton: ["28328"],
      Cordova: ["28330"],
      Dublin: ["28332"],
      Dudley: ["28333"],
      Dunn: ["28334"],
      Elizabethtown: ["28337"],
      Ellerbe: ["28338"],
      Erwin: ["28339"],
      Mcdonald: ["28340"],
      Faison: ["28341"],
      Falcon: ["28342"],
      Gibson: ["28343"],
      Godwin: ["28344"],
      Hamlet: ["28345"],
      Hoffman: ["28347"],
      "Hope mills": ["28348"],
      Kenansville: ["28349"],
      Lakeview: ["28350"],
      "Laurel hill": ["28351"],
      Laurinburg: ["28352"],
      Linden: ["28356"],
      "Lumber bridge": ["28357"],
      Lumberton: ["28358"],
      "Zcta 28360": ["28360"],
      Marietta: ["28362"],
      Marston: ["28363"],
      Maxton: ["28364"],
      "Mount olive": ["28365"],
      "Newton grove": ["28366"],
      Norman: ["28367"],
      Olivia: ["28368"],
      Orrum: ["28369"],
      Parkton: ["28371"],
      Pembroke: ["28372"],
      Pinebluff: ["28373"],
      Pinehurst: ["28374"],
      Raeford: ["28376"],
      "Red springs": ["28377"],
      Rex: ["28378"],
      Rockingham: ["28379"],
      Roseboro: ["28382"],
      Rowland: ["28383"],
      "Saint pauls": ["28384"],
      Salemburg: ["28385"],
      Shannon: ["28386"],
      "Southern pines": ["28387"],
      "Spring lake": ["28390"],
      Stedman: ["28391"],
      "Tar heel": ["28392"],
      Turkey: ["28393"],
      Vass: ["28394"],
      Wade: ["28395"],
      Wagram: ["28396"],
      Bowdens: ["28398"],
      "White oak": ["28399"],
      "Zcta 283hh": ["283HH"],
      "Zcta 283xx": ["283XX"],
      "Cape fear": ["28401"],
      Wilmington: ["28403", "28409", "28412"],
      Ogden: ["28405"],
      "Zcta 28411": ["28411"],
      Ash: ["28420"],
      Atkinson: ["28421"],
      Bolivia: ["28422"],
      Bolton: ["28423"],
      Burgaw: ["28425"],
      "Carolina beach": ["28428"],
      "Castle hayne": ["28429"],
      "Cerro gordo": ["28430"],
      Chadbourn: ["28431"],
      Clarendon: ["28432"],
      Clarkton: ["28433"],
      Council: ["28434"],
      Currie: ["28435"],
      Delco: ["28436"],
      Evergreen: ["28438"],
      "Fair bluff": ["28439"],
      Garland: ["28441"],
      Hallsboro: ["28442"],
      Hampstead: ["28443"],
      Harrells: ["28444"],
      "Surf city": ["28445"],
      Ivanhoe: ["28447"],
      Kelly: ["28448"],
      "Kure beach": ["28449"],
      "Lake waccamaw": ["28450"],
      Leland: ["28451"],
      Longwood: ["28452"],
      Magnolia: ["28453"],
      "Maple hill": ["28454"],
      Nakina: ["28455"],
      Riegelwood: ["28456"],
      "Rocky point": ["28457"],
      "Rose hill": ["28458"],
      "Sneads ferry": ["28460"],
      "Boiling spring l": ["28461"],
      "Holden beach": ["28462"],
      "Tabor city": ["28463"],
      Teachey: ["28464"],
      "Oak island": ["28465"],
      Wallace: ["28466"],
      Calabash: ["28467"],
      "Sunset beach": ["28468"],
      "Ocean isle beach": ["28469"],
      "South brunswick": ["28470"],
      Watha: ["28471"],
      Whiteville: ["28472"],
      Willard: ["28478"],
      Winnabow: ["28479"],
      "Wrightsville bea": ["28480"],
      "Zcta 284hh": ["284HH"],
      "Zcta 284xx": ["284XX"],
      Kinston: ["28501"],
      "Zcta 28504": ["28504"],
      Albertson: ["28508"],
      Arapahoe: ["28510"],
      Atlantic: ["28511"],
      "Pine knoll shore": ["28512"],
      Ayden: ["28513"],
      Bayboro: ["28515"],
      Beaufort: ["28516"],
      Beulaville: ["28518"],
      Bridgeton: ["28519"],
      "Cedar island": ["28520"],
      Chinquapin: ["28521"],
      "Cove city": ["28523"],
      Davis: ["28524"],
      "Deep run": ["28525"],
      Dover: ["28526"],
      Ernul: ["28527"],
      Gloucester: ["28528"],
      Grantsboro: ["28529"],
      Grifton: ["28530"],
      "Harkers island": ["28531"],
      Havelock: ["28532"],
      Hobucken: ["28537"],
      Hookerton: ["28538"],
      Hubert: ["28539"],
      Jacksonville: ["28540", "28546"],
      "Tarawa terrace": ["28543"],
      "Midway park": ["28544"],
      "Camp lejeune": ["28547"],
      "La grange": ["28551"],
      Lowland: ["28552"],
      Marshallberg: ["28553"],
      Maury: ["28554"],
      Maysville: ["28555"],
      Merritt: ["28556"],
      "Morehead city": ["28557"],
      "New bern": ["28560", "28562"],
      Newport: ["28570"],
      Oriental: ["28571"],
      "Pink hill": ["28572"],
      Pollocksville: ["28573"],
      Richlands: ["28574"],
      "Salter path": ["28575"],
      Sealevel: ["28577"],
      "Seven springs": ["28578"],
      Smyrna: ["28579"],
      "Snow hill": ["28580"],
      Stacy: ["28581"],
      Stella: ["28582"],
      Swansboro: ["28584"],
      Trenton: ["28585"],
      Vanceboro: ["28586"],
      Williston: ["28589"],
      Winterville: ["28590"],
      "Emerald isle": ["28594"],
      "Zcta 285hh": ["285HH"],
      "Zcta 285xx": ["285XX"],
      Hickory: ["28601", "28602"],
      "Banner elk": ["28604"],
      "Blowing rock": ["28605"],
      Boomer: ["28606"],
      Boone: ["28607"],
      Catawba: ["28609"],
      Claremont: ["28610"],
      Collettsville: ["28611"],
      "Connellys spring": ["28612"],
      Conover: ["28613"],
      Creston: ["28615"],
      Crossnore: ["28616"],
      Crumpler: ["28617"],
      "Deep gap": ["28618"],
      Drexel: ["28619"],
      Elkin: ["28621"],
      "Elk park": ["28622"],
      Ennice: ["28623"],
      Ferguson: ["28624"],
      "Zcta 28625": ["28625"],
      Fleetwood: ["28626"],
      "Glade valley": ["28627"],
      "Glendale springs": ["28629"],
      "Granite falls": ["28630"],
      "Grassy creek": ["28631"],
      Harmony: ["28634"],
      Hays: ["28635"],
      Hiddenite: ["28636"],
      Hildebran: ["28637"],
      Hudson: ["28638"],
      Jefferson: ["28640"],
      Jonesville: ["28642"],
      Lansing: ["28643"],
      "Laurel springs": ["28644"],
      Lenoir: ["28645"],
      Linville: ["28646"],
      "Mc grady": ["28649"],
      Maiden: ["28650"],
      "Millers creek": ["28651"],
      "Moravian falls": ["28654"],
      Morganton: ["28655"],
      Frank: ["28657"],
      Newton: ["28658"],
      "North wilkesboro": ["28659"],
      Olin: ["28660"],
      Pineola: ["28662"],
      "Piney creek": ["28663"],
      Purlear: ["28665"],
      Icard: ["28666"],
      "Roaring gap": ["28668"],
      "Roaring river": ["28669"],
      Ronda: ["28670"],
      "Rutherford colle": ["28671"],
      "Sherrills ford": ["28673"],
      Sparta: ["28675"],
      "State road": ["28676"],
      Statesville: ["28677"],
      "Stony point": ["28678"],
      "Sugar grove": ["28679"],
      Taylorsville: ["28681"],
      Terrell: ["28682"],
      Thurmond: ["28683"],
      Todd: ["28684"],
      Traphill: ["28685"],
      "Union grove": ["28689"],
      Valdese: ["28690"],
      Vilas: ["28692"],
      Warrensville: ["28693"],
      "West jefferson": ["28694"],
      Wilkesboro: ["28697"],
      Zionville: ["28698"],
      "Zcta 286hh": ["286HH"],
      Alexander: ["28701"],
      Almond: ["28702"],
      Arden: ["28704"],
      Bakersville: ["28705"],
      Balsam: ["28707"],
      "Balsam grove": ["28708"],
      Barnardsville: ["28709"],
      "Bat cave": ["28710"],
      "Black mountain s": ["28711"],
      Brevard: ["28712"],
      "Bryson city": ["28713"],
      Burnsville: ["28714"],
      Candler: ["28715"],
      Canton: ["28716"],
      Cashiers: ["28717"],
      "Cedar mountain": ["28718"],
      Cherokee: ["28719"],
      Clyde: ["28721"],
      Columbus: ["28722"],
      Cullowhee: ["28723"],
      Dillsboro: ["28725"],
      "East flat rock": ["28726"],
      Etowah: ["28729"],
      Fairview: ["28730"],
      "Flat rock": ["28731"],
      Fletcher: ["28732"],
      "Fontana dam": ["28733"],
      Franklin: ["28734"],
      Gerton: ["28735"],
      Glenville: ["28736"],
      Hendersonville: ["28739", "28792"],
      Greenmountain: ["28740"],
      Highlands: ["28741"],
      "Horse shoe": ["28742"],
      "Hot springs": ["28743"],
      "Lake junaluska": ["28745"],
      "Lake lure": ["28746"],
      "Lake toxaway": ["28747"],
      Leicester: ["28748"],
      "Little switzerla": ["28749"],
      "Maggie valley": ["28751"],
      Marion: ["28752"],
      Walnut: ["28753"],
      "Mars hill": ["28754"],
      Micaville: ["28755"],
      "Mill spring": ["28756"],
      Montreat: ["28757"],
      "Mountain home": ["28758"],
      Nebo: ["28761"],
      "Old fort": ["28762"],
      Otto: ["28763"],
      Penland: ["28765"],
      Penrose: ["28766"],
      "Pisgah forest": ["28768"],
      Robbinsville: ["28771"],
      Rosman: ["28772"],
      Saluda: ["28773"],
      Sapphire: ["28774"],
      "Spruce pine": ["28777"],
      "Warren wilson co": ["28778"],
      Sylva: ["28779"],
      Topton: ["28781"],
      Tryon: ["28782"],
      Tuckasegee: ["28783"],
      Tuxedo: ["28784"],
      Waynesville: ["28786"],
      Weaverville: ["28787"],
      Whittier: ["28789"],
      Zirconia: ["28790"],
      "Zcta 28791": ["28791"],
      "Zcta 287hh": ["287HH"],
      "Zcta 287xx": ["287XX"],
      Asheville: ["28801", "28803", "28804", "28805", "28806"],
      "Zcta 288hh": ["288HH"],
      Andrews: ["28901"],
      Brasstown: ["28902"],
      Hayesville: ["28904"],
      Marble: ["28905"],
      Unaka: ["28906"],
      Warne: ["28909"],
      "Zcta 289hh": ["289HH"],
    },
  },
  {
    abbrevation: "ND",
    name: "North dakota",
    Cities: [
      "",
      "",
      "Abercrombie",
      "Amenia",
      "Argusville",
      "Arthur",
      "Ayr",
      "Barney",
      "Blanchard",
      "Buffalo",
      "Casselton",
      "Cayuga",
      "Christine",
      "Clifford",
      "Brampton",
      "Colfax",
      "Davenport",
      "Enderlin",
      "Erie",
      "Fairmount",
      "Fingal",
      "Forman",
      "Englevale",
      "Galesburg",
      "Gardner",
      "Grandin",
      "Great bend",
      "Crete",
      "Hankinson",
      "Prosper",
      "Havana",
      "Kelso",
      "Colgate",
      "Hickson",
      "Hunter",
      "Hastings",
      "Kindred",
      "Leonard",
      "Geneseo",
      "Elliott",
      "Luverne",
      "Mcleod",
      "Mantador",
      "Durbin",
      "Delamere",
      "Mooreton",
      "Nome",
      "Oriska",
      "Page",
      "Rutland",
      "Sheldon",
      "Stirum",
      "Tower city",
      "Valley city",
      "Dwight",
      "Walcott",
      "Riverside",
      "Embden",
      "Wyndmere",
      "Zcta 580hh",
      "North river",
      "Fargo",
      "Briarwood",
      "Grand forks",
      "Grand forks afb",
      "Adams",
      "Aneta",
      "Ardoch",
      "Arvilla",
      "Bathgate",
      "Buxton",
      "Caledonia",
      "Concrete",
      "Crystal",
      "Cummings",
      "Dahlen",
      "Bowesmont",
      "Gardar",
      "Emerado",
      "Fairdale",
      "Finley",
      "Fordville",
      "Forest river",
      "Honeyford",
      "Glasston",
      "Nash",
      "Hamilton",
      "Hannah",
      "Hatton",
      "Hensel",
      "Hoople",
      "Orr",
      "Langdon",
      "Lankin",
      "Mccanna",
      "Kloten",
      "Maida",
      "Manvel",
      "Mayville",
      "Mekinock",
      "Whitman",
      "Milton",
      "Voss",
      "Mountain",
      "Neche",
      "Niagara",
      "Kempton",
      "Osnabrock",
      "Park river",
      "Joliette",
      "Petersburg",
      "Pisek",
      "Portland",
      "Reynolds",
      "Saint thomas",
      "Sharon",
      "Thompson",
      "Wales",
      "Backoo",
      "Zcta 582hh",
      "Devils lake",
      "Agate",
      "Loma",
      "Balta",
      "Belcourt",
      "Bisbee",
      "Bottineau",
      "Brocket",
      "Calvin",
      "Maza",
      "Churchs ferry",
      "Southam",
      "San haven",
      "Edmore",
      "Egeland",
      "Fillmore",
      "Fort totten",
      "Hampden",
      "Manfred",
      "Knox",
      "Mapes",
      "Lawton",
      "Harlow",
      "Flora",
      "Minnewaukan",
      "Calio",
      "Mylo",
      "Nekoma",
      "Brantford",
      "Oberon",
      "Orrin",
      "Pekin",
      "Penn",
      "Perth",
      "Rocklake",
      "Nanson",
      "Rolla",
      "Pleasant lake",
      "Saint john",
      "Saint michael",
      "Sarles",
      "Sheyenne",
      "Starkweather",
      "Tokio",
      "Hamar",
      "Warwick",
      "Webster",
      "Willow city",
      "Wolford",
      "Baker",
      "Zcta 583hh",
      "Eldridge",
      "Ashley",
      "Berlin",
      "Binford",
      "Bowdon",
      "Buchanan",
      "Bordulac",
      "Emrick",
      "Chaseley",
      "Windsor",
      "Cooperstown",
      "Courtenay",
      "Dawson",
      "Sibley",
      "Denhoff",
      "Dickey",
      "Merricourt",
      "Ellendale",
      "Fessenden",
      "Forbes",
      "Fredonia",
      "Fullerton",
      "Gackle",
      "Juanita",
      "Goodrich",
      "Grace city",
      "Walum",
      "Hurdsfield",
      "Nortonville",
      "Kensal",
      "Kulm",
      "Grand rapids",
      "Lehr",
      "Litchville",
      "Mcclusky",
      "Mchenry",
      "Marion",
      "Medina",
      "Adrian",
      "Guelph",
      "Pettibone",
      "Edmunds",
      "Regan",
      "Lake williams",
      "Leal",
      "Sanborn",
      "Spiritwood",
      "Steele",
      "Streeter",
      "Sutton",
      "Sykeston",
      "Tappen",
      "Tuttle",
      "Venturia",
      "Verona",
      "Wimbledon",
      "Wing",
      "Burnstad",
      "Woodworth",
      "Ypsilanti",
      "Zcta 584hh",
      "Bismarck",
      "Lincoln",
      "Almont",
      "Baldwin",
      "Beulah",
      "Braddock",
      "Cannon ball",
      "Carson",
      "Fort clark",
      "Coleharbor",
      "Driscoll",
      "Heil",
      "Lark",
      "Fort yates",
      "Emmet",
      "Golden valley",
      "Hague",
      "Hazelton",
      "Hazen",
      "Kintyre",
      "Temvik",
      "Mckenzie",
      "Mandan",
      "Menoken",
      "Mercer",
      "Moffit",
      "Napoleon",
      "Bentley",
      "Hannover",
      "Raleigh",
      "Riverdale",
      "Saint anthony",
      "Selfridge",
      "Shields",
      "Breien",
      "Stanton",
      "Sterling",
      "Strasburg",
      "Turtle lake",
      "Underwood",
      "Washburn",
      "Wilton",
      "Zap",
      "Zeeland",
      "Zcta 585hh",
      "New hradec",
      "Amidon",
      "Beach",
      "Fryburg",
      "Bowman",
      "Dodge",
      "Dunn center",
      "Gorham",
      "Gladstone",
      "Glen ullin",
      "Golva",
      "Grassy butte",
      "Werner",
      "Hebron",
      "Bucyrus",
      "Killdeer",
      "Lefor",
      "Manning",
      "Marmarth",
      "Medora",
      "Burt",
      "New england",
      "Reeder",
      "Regent",
      "Rhame",
      "Richardton",
      "Gascoyne",
      "Sentinel butte",
      "South heart",
      "Taylor",
      "Zcta 586xx",
      "Minot",
      "Zcta 58703",
      "Minot afb",
      "Anamoose",
      "Antler",
      "Balfour",
      "Bantry",
      "Benedict",
      "Blaisdell",
      "Coteau",
      "Burlington",
      "Butte",
      "Carpio",
      "Larson",
      "Crosby",
      "Deering",
      "Des lacs",
      "Donnybrook",
      "Douglas",
      "Drake",
      "Northgate",
      "Wolseth",
      "Granville",
      "Karlsruhe",
      "Coulee",
      "Kief",
      "Kramer",
      "Lansford",
      "Lignite",
      "Mcgregor",
      "Makoti",
      "Mandaree",
      "Martin",
      "Max",
      "Maxbass",
      "Loraine",
      "Newburg",
      "Charlson",
      "Noonan",
      "Norwich",
      "Palermo",
      "Parshall",
      "Plaza",
      "Portal",
      "Battleview",
      "Roseglen",
      "Ross",
      "Ruso",
      "Raub",
      "Sawyer",
      "Sherwood",
      "Carbury",
      "Belden",
      "Surrey",
      "Tolley",
      "Berwick",
      "Upham",
      "Velva",
      "Bergen",
      "Westhope",
      "White earth",
      "Hamlet",
      "Zcta 587hh",
      "Bonetraill",
      "Appam",
      "Rawson",
      "Ambrose",
      "Arnegard",
      "Cartwright",
      "Springbrook",
      "Colgan",
      "Alkabo",
      "Keene",
      "Wheelock",
      "Temple",
      "Trenton",
      "Watford city",
      "Zahl",
      "Zcta 588hh",
      "Zcta 588xx",
    ],
    ZipCodes: {
      "": ["57638", "59221", "592HH"],
      Abercrombie: ["58001"],
      Amenia: ["58004"],
      Argusville: ["58005"],
      Arthur: ["58006"],
      Ayr: ["58007"],
      Barney: ["58008"],
      Blanchard: ["58009"],
      Buffalo: ["58011"],
      Casselton: ["58012"],
      Cayuga: ["58013"],
      Christine: ["58015"],
      Clifford: ["58016"],
      Brampton: ["58017"],
      Colfax: ["58018"],
      Davenport: ["58021"],
      Enderlin: ["58027"],
      Erie: ["58029"],
      Fairmount: ["58030"],
      Fingal: ["58031"],
      Forman: ["58032"],
      Englevale: ["58033"],
      Galesburg: ["58035"],
      Gardner: ["58036"],
      Grandin: ["58038"],
      "Great bend": ["58039"],
      Crete: ["58040"],
      Hankinson: ["58041"],
      Prosper: ["58042"],
      Havana: ["58043"],
      Kelso: ["58045"],
      Colgate: ["58046"],
      Hickson: ["58047"],
      Hunter: ["58048"],
      Hastings: ["58049"],
      Kindred: ["58051"],
      Leonard: ["58052"],
      Geneseo: ["58053"],
      Elliott: ["58054"],
      Luverne: ["58056"],
      Mcleod: ["58057"],
      Mantador: ["58058"],
      Durbin: ["58059"],
      Delamere: ["58060"],
      Mooreton: ["58061"],
      Nome: ["58062"],
      Oriska: ["58063"],
      Page: ["58064"],
      Rutland: ["58067"],
      Sheldon: ["58068"],
      Stirum: ["58069"],
      "Tower city": ["58071"],
      "Valley city": ["58072"],
      Dwight: ["58075"],
      Walcott: ["58077"],
      Riverside: ["58078"],
      Embden: ["58079"],
      Wyndmere: ["58081"],
      "Zcta 580hh": ["580HH"],
      "North river": ["58102"],
      Fargo: ["58103"],
      Briarwood: ["58104"],
      "Grand forks": ["58201", "58202", "58203"],
      "Grand forks afb": ["58204"],
      Adams: ["58210"],
      Aneta: ["58212"],
      Ardoch: ["58213"],
      Arvilla: ["58214"],
      Bathgate: ["58216"],
      Buxton: ["58218"],
      Caledonia: ["58219"],
      Concrete: ["58220"],
      Crystal: ["58222"],
      Cummings: ["58223"],
      Dahlen: ["58224"],
      Bowesmont: ["58225"],
      Gardar: ["58227"],
      Emerado: ["58228"],
      Fairdale: ["58229"],
      Finley: ["58230"],
      Fordville: ["58231"],
      "Forest river": ["58233"],
      Honeyford: ["58235"],
      Glasston: ["58236"],
      Nash: ["58237"],
      Hamilton: ["58238"],
      Hannah: ["58239"],
      Hatton: ["58240"],
      Hensel: ["58241"],
      Hoople: ["58243"],
      Orr: ["58244"],
      Langdon: ["58249"],
      Lankin: ["58250"],
      Mccanna: ["58251"],
      Kloten: ["58254"],
      Maida: ["58255"],
      Manvel: ["58256"],
      Mayville: ["58257"],
      Mekinock: ["58258"],
      Whitman: ["58259"],
      Milton: ["58260"],
      Voss: ["58261"],
      Mountain: ["58262"],
      Neche: ["58265"],
      Niagara: ["58266"],
      Kempton: ["58267"],
      Osnabrock: ["58269"],
      "Park river": ["58270"],
      Joliette: ["58271"],
      Petersburg: ["58272"],
      Pisek: ["58273"],
      Portland: ["58274"],
      Reynolds: ["58275"],
      "Saint thomas": ["58276"],
      Sharon: ["58277"],
      Thompson: ["58278"],
      Wales: ["58281"],
      Backoo: ["58282"],
      "Zcta 582hh": ["582HH"],
      "Devils lake": ["58301"],
      Agate: ["58310"],
      Loma: ["58311"],
      Balta: ["58313"],
      Belcourt: ["58316"],
      Bisbee: ["58317"],
      Bottineau: ["58318"],
      Brocket: ["58321"],
      Calvin: ["58323"],
      Maza: ["58324"],
      "Churchs ferry": ["58325"],
      Southam: ["58327"],
      "San haven": ["58329"],
      Edmore: ["58330"],
      Egeland: ["58331"],
      Fillmore: ["58332"],
      "Fort totten": ["58335"],
      Hampden: ["58338"],
      Manfred: ["58341"],
      Knox: ["58343"],
      Mapes: ["58344"],
      Lawton: ["58345"],
      Harlow: ["58346"],
      Flora: ["58348"],
      Minnewaukan: ["58351"],
      Calio: ["58352"],
      Mylo: ["58353"],
      Nekoma: ["58355"],
      Brantford: ["58356"],
      Oberon: ["58357"],
      Orrin: ["58359"],
      Pekin: ["58361"],
      Penn: ["58362"],
      Perth: ["58363"],
      Rocklake: ["58365"],
      Nanson: ["58366"],
      Rolla: ["58367"],
      "Pleasant lake": ["58368"],
      "Saint john": ["58369"],
      "Saint michael": ["58370"],
      Sarles: ["58372"],
      Sheyenne: ["58374"],
      Starkweather: ["58377"],
      Tokio: ["58379"],
      Hamar: ["58380"],
      Warwick: ["58381"],
      Webster: ["58382"],
      "Willow city": ["58384"],
      Wolford: ["58385"],
      Baker: ["58386"],
      "Zcta 583hh": ["583HH"],
      Eldridge: ["58401"],
      Ashley: ["58413"],
      Berlin: ["58415"],
      Binford: ["58416"],
      Bowdon: ["58418"],
      Buchanan: ["58420"],
      Bordulac: ["58421"],
      Emrick: ["58422"],
      Chaseley: ["58423"],
      Windsor: ["58424"],
      Cooperstown: ["58425"],
      Courtenay: ["58426"],
      Dawson: ["58428"],
      Sibley: ["58429"],
      Denhoff: ["58430"],
      Dickey: ["58431"],
      Merricourt: ["58433"],
      Ellendale: ["58436"],
      Fessenden: ["58438"],
      Forbes: ["58439"],
      Fredonia: ["58440"],
      Fullerton: ["58441"],
      Gackle: ["58442"],
      Juanita: ["58443"],
      Goodrich: ["58444"],
      "Grace city": ["58445"],
      Walum: ["58448"],
      Hurdsfield: ["58451"],
      Nortonville: ["58454"],
      Kensal: ["58455"],
      Kulm: ["58456"],
      "Grand rapids": ["58458"],
      Lehr: ["58460"],
      Litchville: ["58461"],
      Mcclusky: ["58463"],
      Mchenry: ["58464"],
      Marion: ["58466"],
      Medina: ["58467"],
      Adrian: ["58472"],
      Guelph: ["58474"],
      Pettibone: ["58475"],
      Edmunds: ["58476"],
      Regan: ["58477"],
      "Lake williams": ["58478"],
      Leal: ["58479"],
      Sanborn: ["58480"],
      Spiritwood: ["58481"],
      Steele: ["58482"],
      Streeter: ["58483"],
      Sutton: ["58484"],
      Sykeston: ["58486"],
      Tappen: ["58487"],
      Tuttle: ["58488"],
      Venturia: ["58489"],
      Verona: ["58490"],
      Wimbledon: ["58492"],
      Wing: ["58494"],
      Burnstad: ["58495"],
      Woodworth: ["58496"],
      Ypsilanti: ["58497"],
      "Zcta 584hh": ["584HH"],
      Bismarck: ["58501", "58505"],
      Lincoln: ["58504"],
      Almont: ["58520"],
      Baldwin: ["58521"],
      Beulah: ["58523"],
      Braddock: ["58524"],
      "Cannon ball": ["58528"],
      Carson: ["58529"],
      "Fort clark": ["58530"],
      Coleharbor: ["58531"],
      Driscoll: ["58532"],
      Heil: ["58533"],
      Lark: ["58535"],
      "Fort yates": ["58538"],
      Emmet: ["58540"],
      "Golden valley": ["58541"],
      Hague: ["58542"],
      Hazelton: ["58544"],
      Hazen: ["58545"],
      Kintyre: ["58549"],
      Temvik: ["58552"],
      Mckenzie: ["58553"],
      Mandan: ["58554"],
      Menoken: ["58558"],
      Mercer: ["58559"],
      Moffit: ["58560"],
      Napoleon: ["58561"],
      Bentley: ["58562"],
      Hannover: ["58563"],
      Raleigh: ["58564"],
      Riverdale: ["58565"],
      "Saint anthony": ["58566"],
      Selfridge: ["58568"],
      Shields: ["58569"],
      Breien: ["58570"],
      Stanton: ["58571"],
      Sterling: ["58572"],
      Strasburg: ["58573"],
      "Turtle lake": ["58575"],
      Underwood: ["58576"],
      Washburn: ["58577"],
      Wilton: ["58579"],
      Zap: ["58580"],
      Zeeland: ["58581"],
      "Zcta 585hh": ["585HH"],
      "New hradec": ["58601"],
      Amidon: ["58620"],
      Beach: ["58621"],
      Fryburg: ["58622"],
      Bowman: ["58623"],
      Dodge: ["58625"],
      "Dunn center": ["58626"],
      Gorham: ["58627"],
      Gladstone: ["58630"],
      "Glen ullin": ["58631"],
      Golva: ["58632"],
      "Grassy butte": ["58634"],
      Werner: ["58636"],
      Hebron: ["58638"],
      Bucyrus: ["58639"],
      Killdeer: ["58640"],
      Lefor: ["58641"],
      Manning: ["58642"],
      Marmarth: ["58643"],
      Medora: ["58645"],
      Burt: ["58646"],
      "New england": ["58647"],
      Reeder: ["58649"],
      Regent: ["58650"],
      Rhame: ["58651"],
      Richardton: ["58652"],
      Gascoyne: ["58653"],
      "Sentinel butte": ["58654"],
      "South heart": ["58655"],
      Taylor: ["58656"],
      "Zcta 586xx": ["586XX"],
      Minot: ["58701"],
      "Zcta 58703": ["58703"],
      "Minot afb": ["58704"],
      Anamoose: ["58710"],
      Antler: ["58711"],
      Balfour: ["58712"],
      Bantry: ["58713"],
      Benedict: ["58716"],
      Blaisdell: ["58718"],
      Coteau: ["58721"],
      Burlington: ["58722"],
      Butte: ["58723"],
      Carpio: ["58725"],
      Larson: ["58727"],
      Crosby: ["58730"],
      Deering: ["58731"],
      "Des lacs": ["58733"],
      Donnybrook: ["58734"],
      Douglas: ["58735"],
      Drake: ["58736"],
      Northgate: ["58737"],
      Wolseth: ["58740"],
      Granville: ["58741"],
      Karlsruhe: ["58744"],
      Coulee: ["58746"],
      Kief: ["58747"],
      Kramer: ["58748"],
      Lansford: ["58750"],
      Lignite: ["58752"],
      Mcgregor: ["58755"],
      Makoti: ["58756"],
      Mandaree: ["58757"],
      Martin: ["58758"],
      Max: ["58759"],
      Maxbass: ["58760"],
      Loraine: ["58761"],
      Newburg: ["58762"],
      Charlson: ["58763"],
      Noonan: ["58765"],
      Norwich: ["58768"],
      Palermo: ["58769"],
      Parshall: ["58770"],
      Plaza: ["58771"],
      Portal: ["58772"],
      Battleview: ["58773"],
      Roseglen: ["58775"],
      Ross: ["58776"],
      Ruso: ["58778"],
      Raub: ["58779"],
      Sawyer: ["58781"],
      Sherwood: ["58782"],
      Carbury: ["58783"],
      Belden: ["58784"],
      Surrey: ["58785"],
      Tolley: ["58787"],
      Berwick: ["58788"],
      Upham: ["58789"],
      Velva: ["58790"],
      Bergen: ["58792"],
      Westhope: ["58793"],
      "White earth": ["58794"],
      Hamlet: ["58795"],
      "Zcta 587hh": ["587HH"],
      Bonetraill: ["58801"],
      Appam: ["58830"],
      Rawson: ["58831"],
      Ambrose: ["58833"],
      Arnegard: ["58835"],
      Cartwright: ["58838"],
      Springbrook: ["58843"],
      Colgan: ["58844"],
      Alkabo: ["58845"],
      Keene: ["58847"],
      Wheelock: ["58849"],
      Temple: ["58852"],
      Trenton: ["58853"],
      "Watford city": ["58854"],
      Zahl: ["58856"],
      "Zcta 588hh": ["588HH"],
      "Zcta 588xx": ["588XX"],
    },
  },
  {
    abbrevation: "OH",
    name: "Ohio",
    Cities: [
      "Alexandria",
      "Alexandria",
      "Amlin",
      "Ashley",
      "Blacklick",
      "Bladensburg",
      "Brinkhaven",
      "Buckeye lake",
      "Cable",
      "Catawba",
      "Centerburg",
      "Croton",
      "Danville",
      "Delaware",
      "Dublin",
      "Etna",
      "Fredericktown",
      "Galena",
      "Gambier",
      "Granville",
      "Hebron",
      "Hilliard",
      "Howard",
      "Irwin",
      "Johnstown",
      "Kilbourne",
      "Kirkersville",
      "Lewis center",
      "Magnetic springs",
      "Martinsburg",
      "Marysville",
      "Mechanicsburg",
      "Milford center",
      "Millersport",
      "Mount vernon",
      "New albany",
      "Newark",
      "Heath",
      "North lewisburg",
      "Ostrander",
      "Pataskala",
      "Plain city",
      "Shawnee hills",
      "Radnor",
      "Raymond",
      "Reynoldsburg",
      "Rosewood",
      "Saint louisville",
      "Saint paris",
      "Sunbury",
      "Thornville",
      "Unionville cente",
      "Urbana",
      "Utica",
      "Westerville",
      "Woodstock",
      "Worthington",
      "Zcta 430hh",
      "Adelphi",
      "Amanda",
      "Ashville",
      "Baltimore",
      "Bloomingburg",
      "Hide a way hills",
      "Brice",
      "Canal winchester",
      "Carbon hill",
      "Carroll",
      "Circleville",
      "Clarksburg",
      "Commercial point",
      "Galloway",
      "Grove city",
      "Groveport",
      "Harrisburg",
      "Haydenville",
      "Jeffersonville",
      "Lancaster",
      "Laurelville",
      "Lithopolis",
      "Lockbourne",
      "Logan",
      "London",
      "Milledgeville",
      "Mount sterling",
      "Murray city",
      "New holland",
      "Orient",
      "Pickerington",
      "Pleasantville",
      "Rockbridge",
      "Rushville",
      "Sedalia",
      "South bloomingvi",
      "South solon",
      "Stoutsville",
      "Sugar grove",
      "Tarlton",
      "Thurston",
      "Union furnace",
      "Washington court",
      "West jefferson",
      "Williamsport",
      "Zcta 431hh",
      "Columbus",
      "Bexley",
      "Whitehall",
      "Shepard",
      "Upper arlington",
      "Lincoln village",
      "Gahanna",
      "West worthington",
      "Zcta 432hh",
      "Marion",
      "Belle center",
      "Bellefontaine",
      "Caledonia",
      "Cardington",
      "Carey",
      "Chesterville",
      "De graff",
      "East liberty",
      "Edison",
      "Fulton",
      "Green camp",
      "Harpster",
      "Huntsville",
      "Kenton",
      "Lakeview",
      "La rue",
      "Lewistown",
      "Marengo",
      "Middleburg",
      "Morral",
      "Mount gilead",
      "Mount victory",
      "New bloomington",
      "Prospect",
      "Quincy",
      "Richwood",
      "Ridgeway",
      "Rushsylvania",
      "Russells point",
      "Sparta",
      "Upper sandusky",
      "Waldo",
      "West liberty",
      "West mansfield",
      "Wharton",
      "Zanesfield",
      "Zcta 433hh",
      "Bowling green",
      "Bradner",
      "Burgoon",
      "Clay center",
      "Clyde",
      "Curtice",
      "Cygnet",
      "Elmore",
      "Fremont",
      "Genoa",
      "Gibsonburg",
      "Elliston",
      "Millersville",
      "Jerry city",
      "Kelleys island",
      "Lacarne",
      "Lakeside",
      "Lindsey",
      "Luckey",
      "Bono",
      "Millbury",
      "Oak harbor",
      "Pemberville",
      "Portage",
      "Port clinton",
      "Put in bay",
      "Risingsun",
      "Rocky ridge",
      "Rossford",
      "Rudolph",
      "Vickery",
      "Walbridge",
      "Wayne",
      "West millgrove",
      "Williston",
      "Woodville",
      "Zcta 434hh",
      "Alvordton",
      "Archbold",
      "Berkey",
      "Bryan",
      "Custar",
      "Defiance",
      "Delta",
      "Deshler",
      "Edgerton",
      "Edon",
      "Fayette",
      "Grand rapids",
      "Hamler",
      "Haskins",
      "Hicksville",
      "Holgate",
      "Holland",
      "Hoytville",
      "Kunkle",
      "Liberty center",
      "Lyons",
      "Mc clure",
      "Malinta",
      "Mark center",
      "Maumee",
      "Metamora",
      "Milton center",
      "Monclova",
      "Montpelier",
      "Napoleon",
      "Neapolis",
      "New bavaria",
      "Ney",
      "Perrysburg",
      "Pettisville",
      "Pioneer",
      "Ridgeville corne",
      "Sherwood",
      "Stryker",
      "Swanton",
      "Sylvania",
      "Tontogany",
      "Waterville",
      "Wauseon",
      "Weston",
      "West unity",
      "Whitehouse",
      "Zcta 435hh",
      "Toledo",
      "Oregon",
      "Northwood",
      "Zcta 436hh",
      "Sonora",
      "Somerton",
      "Beallsville",
      "Belle valley",
      "Belmont",
      "Bethesda",
      "Blue rock",
      "Brownsville",
      "Buffalo",
      "Byesville",
      "Caldwell",
      "Claysville",
      "Chandlersville",
      "Chesterhill",
      "Hemlock",
      "Crooksville",
      "Cumberland",
      "Duncan falls",
      "East fultonham",
      "Fultonham",
      "Glenford",
      "Gratiot",
      "Hopewell",
      "Jerusalem",
      "Junction city",
      "Guernsey",
      "Lewisville",
      "Lore city",
      "Mc connelsville",
      "Malta",
      "Mount perry",
      "New concord",
      "New lexington",
      "New straitsville",
      "Norwich",
      "Old washington",
      "Philo",
      "Pleasant city",
      "Quaker city",
      "Roseville",
      "Salesville",
      "Sarahsville",
      "Senecaville",
      "Shawnee",
      "Somerset",
      "Pennsville",
      "Summerfield",
      "White cottage",
      "Antioch",
      "Zcta 437hh",
      "Adamsville",
      "Baltic",
      "Conesville",
      "Coshocton",
      "Adams mills",
      "Frazeysburg",
      "Fresno",
      "Nashport",
      "Newcomerstown",
      "Port washington",
      "Stone creek",
      "Trinway",
      "Walhonding",
      "Warsaw",
      "West lafayette",
      "Zcta 438hh",
      "Adena",
      "Alledonia",
      "Amsterdam",
      "Barton",
      "Bellaire",
      "Moorefield",
      "Bergholz",
      "Blaine",
      "Bloomingdale",
      "Bridgeport",
      "Brilliant",
      "Cameron",
      "Clarington",
      "Dillonvale",
      "Calcutta",
      "East springfield",
      "Empire",
      "Fairpoint",
      "Glencoe",
      "Hammondsville",
      "Irondale",
      "Armstrong mills",
      "Lansing",
      "Martins ferry",
      "Mingo junction",
      "Mount pleasant",
      "Neffs",
      "Powhatan point",
      "Rayland",
      "Richmond",
      "Salineville",
      "Sardis",
      "Shadyside",
      "Smithfield",
      "Saint clairsvill",
      "Lafferty",
      "Wintersville",
      "Zcta 43953",
      "Stratton",
      "Summitville",
      "Tiltonsville",
      "Toronto",
      "Warnock",
      "Wellsville",
      "Yorkville",
      "Bannock",
      "Freeport",
      "Harrisville",
      "Hopedale",
      "Flushing",
      "New athens",
      "Piedmont",
      "Jewett",
      "Scio",
      "Zcta 439hh",
      "South amherst",
      "Andover",
      "Ashtabula",
      "Austinburg",
      "Avon",
      "Avon lake",
      "Berea",
      "Burton",
      "Chagrin falls",
      "Chardon",
      "Chesterland",
      "Columbia station",
      "Conneaut",
      "Dorset",
      "Elyria",
      "North ridgeville",
      "Gates mills",
      "Geneva",
      "Grafton",
      "Huntsburg",
      "Jefferson",
      "Kingsville",
      "Kipton",
      "Lagrange",
      "Lorain",
      "Sheffield lake",
      "Macedonia",
      "Madison",
      "Mentor",
      "Middlefield",
      "Montville",
      "Newbury",
      "Northfield",
      "North olmsted",
      "Novelty",
      "Oberlin",
      "East orwell",
      "Fairport harbor",
      "Parkman",
      "Perry",
      "Pierpont",
      "Roaming shores",
      "Thompson",
      "Twinsburg",
      "Vermilion",
      "Wellington",
      "Wickliffe",
      "Williamsfield",
      "Willoughby",
      "Willowick",
      "Windsor",
      "Zcta 440hh",
      "Cleveland",
      "Edgewater",
      "East cleveland",
      "Rocky river",
      "Euclid",
      "Cleveland height",
      "South euclid",
      "Beachwood",
      "Shore",
      "Lyndhurst mayfie",
      "Garfield heights",
      "Fairview park",
      "Parma",
      "Midpark",
      "Independence",
      "Noble",
      "North royalton",
      "Strongsville",
      "Maple heights",
      "Olmsted falls",
      "Solon",
      "Bay village",
      "Brecksville",
      "Brookpark",
      "Richmond heights",
      "Brooklyn",
      "Westlake",
      "Bedford",
      "Broadview height",
      "Zcta 441hh",
      "Atwater",
      "Reminderville",
      "Norton",
      "Brunswick",
      "Burbank",
      "Chippewa lake",
      "Clinton",
      "Creston",
      "Cuyahoga falls",
      "Stow",
      "Doylestown",
      "Garrettsville",
      "Hinckley",
      "Hiram",
      "Homerville",
      "Hudson",
      "Kent",
      "Streetsboro",
      "Lakemore",
      "Westfield center",
      "Litchfield",
      "Lodi",
      "Mantua",
      "Medina",
      "Mogadore",
      "Munroe falls",
      "Peninsula",
      "Ravenna",
      "Rittman",
      "Rootstown",
      "Seville",
      "Spencer",
      "Sterling",
      "Tallmadge",
      "Valley city",
      "Wadsworth",
      "Richfield",
      "West salem",
      "Windham",
      "Zcta 442hh",
      "Akron",
      "Copley",
      "Fairlawn",
      "Zcta 443hh",
      "Berlin center",
      "Bristolville",
      "Brookfield",
      "Burghill",
      "Campbell",
      "Canfield",
      "Columbiana",
      "Cortland",
      "Deerfield",
      "Diamond",
      "East palestine",
      "Farmdale",
      "Fowler",
      "Girard",
      "Hanoverton",
      "Hubbard",
      "Kensington",
      "Kinsman",
      "Lake milton",
      "Leavittsburg",
      "Leetonia",
      "Lisbon",
      "Lowellville",
      "Mc donald",
      "Masury",
      "Mineral ridge",
      "Negley",
      "New middletown",
      "New springfield",
      "Newton falls",
      "New waterford",
      "Niles",
      "North benton",
      "North bloomfield",
      "North jackson",
      "North lima",
      "Petersburg",
      "Rogers",
      "Salem",
      "Southington",
      "Struthers",
      "Vienna",
      "Warren",
      "Washingtonville",
      "West farmington",
      "Zcta 444hh",
      "Youngstown",
      "Boardman",
      "Poland",
      "Austintown",
      "Zcta 445hh",
      "Alliance",
      "Apple creek",
      "Beach city",
      "Beloit",
      "Berlin",
      "Big prairie",
      "Bolivar",
      "Brewster",
      "Canal fulton",
      "Carrollton",
      "Dalton",
      "Damascus",
      "Dellroy",
      "Dennison",
      "Dover",
      "Dundee",
      "East rochester",
      "East sparta",
      "Fredericksburg",
      "Glenmont",
      "Gnadenhutten",
      "Hartville",
      "Holmesville",
      "Homeworth",
      "Killbuck",
      "Lakeville",
      "Limaville",
      "Louisville",
      "Magnolia",
      "Malvern",
      "Marshallville",
      "Massillon",
      "Mechanicstown",
      "Midvale",
      "Millersburg",
      "Zoarville",
      "Minerva",
      "Mount eaton",
      "Nashville",
      "Navarre",
      "New philadelphia",
      "North lawrence",
      "Orrville",
      "Paris",
      "Robertsville",
      "Sandyville",
      "Sebring",
      "Sherrodsville",
      "Shreve",
      "Smithville",
      "Somerdale",
      "Strasburg",
      "Sugarcreek",
      "Tuscarawas",
      "Uhrichsville",
      "Uniontown",
      "Waynesburg",
      "Wilmot",
      "Winesburg",
      "Wooster",
      "Deersville",
      "Bowerston",
      "Zoar",
      "Tippecanoe",
      "Zcta 446hh",
      "Canton",
      "North industry",
      "North canton",
      "Jackson belden",
      "East canton",
      "Alvada",
      "Arcadia",
      "Ashland",
      "Carrothers",
      "Bascom",
      "Bellevue",
      "Bellville",
      "Berlin heights",
      "Bettsville",
      "Birmingham",
      "Bloomdale",
      "Bloomville",
      "Bucyrus",
      "Butler",
      "Castalia",
      "Chatfield",
      "Collins",
      "Crestline",
      "Flat rock",
      "Fostoria",
      "Galion",
      "Green springs",
      "Greenwich",
      "Hayesville",
      "Shinrock",
      "Jeromesville",
      "Kansas",
      "Loudonville",
      "Lucas",
      "Mc cutchenville",
      "Milan",
      "Monroeville",
      "Nevada",
      "New haven",
      "New london",
      "New riegel",
      "New washington",
      "North fairfield",
      "North robinson",
      "Norwalk",
      "Nova",
      "Oceola",
      "Old fort",
      "Perrysville",
      "Plymouth",
      "Polk",
      "Republic",
      "Sandusky",
      "Savannah",
      "Shelby",
      "Shiloh",
      "Sullivan",
      "Sycamore",
      "Tiffin",
      "Tiro",
      "Wakeman",
      "Willard",
      "Zcta 448hh",
      "Mansfield",
      "Lexington",
      "Lincoln",
      "Zcta 449hh",
      "Addyston",
      "Cleves",
      "College corner",
      "Carlisle",
      "Hamilton",
      "Rossville",
      "Fairfield",
      "Lindenwald",
      "Harrison",
      "Harveysburg",
      "Hooven",
      "Kings island",
      "Otterbien home",
      "Maineville",
      "Mason",
      "Miamitown",
      "Middletown",
      "Excello",
      "Monroe",
      "North bend",
      "Okeana",
      "Oregonia",
      "Miami university",
      "Seven mile",
      "Somerville",
      "South lebanon",
      "Springboro",
      "Trenton",
      "Waynesville",
      "West chester",
      "West elkton",
      "Zcta 450hh",
      "Aberdeen",
      "Amelia",
      "Batavia",
      "Bethel",
      "Blanchester",
      "Camp dennison",
      "Chilo",
      "Clarksville",
      "Cuba",
      "Decatur",
      "Fayetteville",
      "Felicity",
      "Georgetown",
      "Goshen",
      "Greenfield",
      "Hamersville",
      "Higginsport",
      "Highland",
      "Hillsboro",
      "Leesburg",
      "Loveland",
      "Lynchburg",
      "Manchester",
      "Martinsville",
      "Midland",
      "Day heights",
      "Morrow",
      "Moscow",
      "Mount orab",
      "Mowrystown",
      "Neville",
      "New richmond",
      "New vienna",
      "Owensville",
      "Pleasant plain",
      "Port william",
      "Ripley",
      "Russellville",
      "Sabina",
      "Sardinia",
      "Sinking spring",
      "Terrace park",
      "Williamsburg",
      "Wilmington",
      "Zcta 451hh",
      "Cincinnati",
      "Norwood",
      "Taft",
      "Lockland",
      "Elmwood place",
      "Saint bernard",
      "Greenhills",
      "College hill",
      "Madisonville",
      "Anderson",
      "Saylor park",
      "Western hills",
      "Groesbeck",
      "Parkdale",
      "Sharonville",
      "Madeira",
      "Newtown",
      "Glendale",
      "Westwood",
      "Zcta 452hh",
      "Alpha",
      "Anna",
      "Ansonia",
      "Castine",
      "Bellbrook",
      "Botkins",
      "Bowersville",
      "Bradford",
      "Brookville",
      "Burkettsville",
      "Camden",
      "Casstown",
      "Cedarville",
      "Clayton",
      "Clifton",
      "Conover",
      "Covington",
      "Eaton",
      "Eldorado",
      "Union",
      "Enon",
      "Fairborn",
      "Farmersville",
      "Fletcher",
      "Germantown",
      "Gettysburg",
      "Gratis",
      "Greenville",
      "Hollansburg",
      "Houston",
      "Jackson center",
      "Jamestown",
      "Kettlersville",
      "Laura",
      "Lewisburg",
      "Ludlow falls",
      "Maplewood",
      "Medway",
      "Miamisburg",
      "New carlisle",
      "New lebanon",
      "New madison",
      "New paris",
      "New weston",
      "North hampton",
      "Osgood",
      "Palestine",
      "Pemberton",
      "Phillipsburg",
      "Piqua",
      "Pitsburg",
      "Pleasant hill",
      "Port jefferson",
      "Rossburg",
      "Russia",
      "Sidney",
      "Selma",
      "South vienna",
      "Spring valley",
      "Phoneton",
      "Troy",
      "Vandalia",
      "Verona",
      "Versailles",
      "West alexandria",
      "West manchester",
      "West milton",
      "Wilberforce",
      "Xenia",
      "Yellow springs",
      "Yorkshire",
      "Christiansburg",
      "Union city",
      "Zcta 453hh",
      "Dayton",
      "Trotwood",
      "Kettering",
      "Huber heights",
      "Beavercreek",
      "West carrollton",
      "Centerville",
      "Zcta 454hh",
      "Springfield",
      "Zcta 455hh",
      "Chillicothe",
      "Bainbridge",
      "Beaver",
      "Bidwell",
      "Blue creek",
      "Chesapeake",
      "Cheshire",
      "Coalton",
      "Creola",
      "Crown city",
      "Cynthiana",
      "Frankfort",
      "Franklin furnace",
      "Gallipolis",
      "Hamden",
      "Ironton",
      "Jackson",
      "Jasper",
      "Kingston",
      "Kitts hill",
      "Latham",
      "Londonderry",
      "Lucasville",
      "Lynx",
      "Allensville",
      "Mc dermott",
      "Minford",
      "New plymouth",
      "Oak hill",
      "Otway",
      "Patriot",
      "Pedro",
      "Peebles",
      "Idaho",
      "New boston",
      "Portsmouth",
      "Proctorville",
      "Rarden",
      "Ray",
      "Richmond dale",
      "Rio grande",
      "Scottown",
      "Seaman",
      "South point",
      "South salem",
      "South webster",
      "Stout",
      "Thurman",
      "Vinton",
      "Waterloo",
      "Waverly",
      "Wellston",
      "West union",
      "Wheelersburg",
      "Wilkesville",
      "Willow wood",
      "Winchester",
      "Zaleski",
      "Zcta 456hh",
      "Zcta 456xx",
      "Athens",
      "Albany",
      "Amesville",
      "Barlow",
      "Bartlett",
      "Belpre",
      "Beverly",
      "Buchtel",
      "Chauncey",
      "Coolville",
      "Cutler",
      "Dexter city",
      "Fleming",
      "Glouster",
      "Rinard mills",
      "Guysville",
      "Jacksonville",
      "Dexter",
      "Little hocking",
      "Long bottom",
      "Lowell",
      "Warner",
      "Macksburg",
      "Marietta",
      "Middleport",
      "Millfield",
      "Nelsonville",
      "New marshfield",
      "New matamoras",
      "Newport",
      "Pomeroy",
      "Portland",
      "Racine",
      "Reedsville",
      "Reno",
      "Rutland",
      "Shade",
      "Stewart",
      "Syracuse",
      "The plains",
      "Trimble",
      "Vincent",
      "Waterford",
      "Whipple",
      "Wingett run",
      "Zcta 457hh",
      "Lima",
      "Cridersville",
      "Elida",
      "Beaverdam",
      "Gomer",
      "Ada",
      "Alger",
      "Antwerp",
      "Arlington",
      "Benton ridge",
      "Bluffton",
      "Buckland",
      "Cairo",
      "Cecil",
      "Carthagena",
      "Cloverdale",
      "Coldwater",
      "Columbus grove",
      "Continental",
      "Convoy",
      "Delphos",
      "Dola",
      "Dunkirk",
      "Dupont",
      "Elgin",
      "Findlay",
      "Jenera",
      "Patterson",
      "Fort jennings",
      "Fort loramie",
      "Fort recovery",
      "Grover hill",
      "Harrod",
      "Haviland",
      "Kalida",
      "Latty",
      "Leipsic",
      "Mc comb",
      "Mc guffey",
      "Maria stein",
      "Melrose",
      "Mendon",
      "Middle point",
      "Minster",
      "Montezuma",
      "Mount blanchard",
      "Mount cory",
      "New bremen",
      "New hampshire",
      "New knoxville",
      "North baltimore",
      "Oakwood",
      "Ohio city",
      "Gilboa",
      "Ottoville",
      "Pandora",
      "Paulding",
      "Payne",
      "Rawson",
      "Rockford",
      "Saint henry",
      "Saint marys",
      "Scott",
      "Spencerville",
      "Uniopolis",
      "Van buren",
      "Vanlue",
      "Van wert",
      "Venedocia",
      "Wapakoneta",
      "Waynesfield",
      "Williamstown",
      "Willshire",
      "Wren",
      "Zcta 458hh",
    ],
    ZipCodes: {
      Alexandria: ["43001"],
      Amlin: ["43002"],
      Ashley: ["43003"],
      Blacklick: ["43004"],
      Bladensburg: ["43005"],
      Brinkhaven: ["43006"],
      "Buckeye lake": ["43008"],
      Cable: ["43009"],
      Catawba: ["43010"],
      Centerburg: ["43011"],
      Croton: ["43013"],
      Danville: ["43014"],
      Delaware: ["43015"],
      Dublin: ["43016", "43017"],
      Etna: ["43018"],
      Fredericktown: ["43019"],
      Galena: ["43021"],
      Gambier: ["43022"],
      Granville: ["43023"],
      Hebron: ["43025"],
      Hilliard: ["43026"],
      Howard: ["43028"],
      Irwin: ["43029"],
      Johnstown: ["43031"],
      Kilbourne: ["43032"],
      Kirkersville: ["43033"],
      "Lewis center": ["43035"],
      "Magnetic springs": ["43036"],
      Martinsburg: ["43037"],
      Marysville: ["43040"],
      Mechanicsburg: ["43044"],
      "Milford center": ["43045"],
      Millersport: ["43046"],
      "Mount vernon": ["43050"],
      "New albany": ["43054"],
      Newark: ["43055"],
      Heath: ["43056"],
      "North lewisburg": ["43060"],
      Ostrander: ["43061"],
      Pataskala: ["43062"],
      "Plain city": ["43064"],
      "Shawnee hills": ["43065"],
      Radnor: ["43066"],
      Raymond: ["43067"],
      Reynoldsburg: ["43068"],
      Rosewood: ["43070"],
      "Saint louisville": ["43071"],
      "Saint paris": ["43072"],
      Sunbury: ["43074"],
      Thornville: ["43076"],
      "Unionville cente": ["43077"],
      Urbana: ["43078"],
      Utica: ["43080"],
      Westerville: ["43081", "43082"],
      Woodstock: ["43084"],
      Worthington: ["43085"],
      "Zcta 430hh": ["430HH"],
      Adelphi: ["43101"],
      Amanda: ["43102"],
      Ashville: ["43103"],
      Baltimore: ["43105"],
      Bloomingburg: ["43106"],
      "Hide a way hills": ["43107"],
      Brice: ["43109"],
      "Canal winchester": ["43110"],
      "Carbon hill": ["43111"],
      Carroll: ["43112"],
      Circleville: ["43113"],
      Clarksburg: ["43115"],
      "Commercial point": ["43116"],
      Galloway: ["43119"],
      "Grove city": ["43123"],
      Groveport: ["43125"],
      Harrisburg: ["43126"],
      Haydenville: ["43127"],
      Jeffersonville: ["43128"],
      Lancaster: ["43130"],
      Laurelville: ["43135"],
      Lithopolis: ["43136"],
      Lockbourne: ["43137"],
      Logan: ["43138"],
      London: ["43140"],
      Milledgeville: ["43142"],
      "Mount sterling": ["43143"],
      "Murray city": ["43144"],
      "New holland": ["43145"],
      Orient: ["43146"],
      Pickerington: ["43147"],
      Pleasantville: ["43148"],
      Rockbridge: ["43149"],
      Rushville: ["43150"],
      Sedalia: ["43151"],
      "South bloomingvi": ["43152"],
      "South solon": ["43153"],
      Stoutsville: ["43154"],
      "Sugar grove": ["43155"],
      Tarlton: ["43156"],
      Thurston: ["43157"],
      "Union furnace": ["43158"],
      "Washington court": ["43160"],
      "West jefferson": ["43162"],
      Williamsport: ["43164"],
      "Zcta 431hh": ["431HH"],
      Columbus: [
        "43201",
        "43202",
        "43203",
        "43204",
        "43205",
        "43206",
        "43207",
        "43210",
        "43211",
        "43212",
        "43214",
        "43215",
        "43217",
        "43220",
        "43222",
        "43223",
        "43224",
        "43227",
        "43229",
        "43231",
        "43232",
        "43240",
      ],
      Bexley: ["43209"],
      Whitehall: ["43213"],
      Shepard: ["43219"],
      "Upper arlington": ["43221"],
      "Lincoln village": ["43228"],
      Gahanna: ["43230"],
      "West worthington": ["43235"],
      "Zcta 432hh": ["432HH"],
      Marion: ["43302"],
      "Belle center": ["43310"],
      Bellefontaine: ["43311"],
      Caledonia: ["43314"],
      Cardington: ["43315"],
      Carey: ["43316"],
      Chesterville: ["43317"],
      "De graff": ["43318"],
      "East liberty": ["43319"],
      Edison: ["43320"],
      Fulton: ["43321"],
      "Green camp": ["43322"],
      Harpster: ["43323"],
      Huntsville: ["43324"],
      Kenton: ["43326"],
      Lakeview: ["43331"],
      "La rue": ["43332"],
      Lewistown: ["43333"],
      Marengo: ["43334"],
      Middleburg: ["43336"],
      Morral: ["43337"],
      "Mount gilead": ["43338"],
      "Mount victory": ["43340"],
      "New bloomington": ["43341"],
      Prospect: ["43342"],
      Quincy: ["43343"],
      Richwood: ["43344"],
      Ridgeway: ["43345"],
      Rushsylvania: ["43347"],
      "Russells point": ["43348"],
      Sparta: ["43350"],
      "Upper sandusky": ["43351"],
      Waldo: ["43356"],
      "West liberty": ["43357"],
      "West mansfield": ["43358"],
      Wharton: ["43359"],
      Zanesfield: ["43360"],
      "Zcta 433hh": ["433HH"],
      "Bowling green": ["43402"],
      Bradner: ["43406"],
      Burgoon: ["43407"],
      "Clay center": ["43408"],
      Clyde: ["43410"],
      Curtice: ["43412"],
      Cygnet: ["43413"],
      Elmore: ["43416"],
      Fremont: ["43420"],
      Genoa: ["43430"],
      Gibsonburg: ["43431"],
      Elliston: ["43432"],
      Millersville: ["43435"],
      "Jerry city": ["43437"],
      "Kelleys island": ["43438"],
      Lacarne: ["43439"],
      Lakeside: ["43440"],
      Lindsey: ["43442"],
      Luckey: ["43443"],
      Bono: ["43445"],
      Millbury: ["43447"],
      "Oak harbor": ["43449"],
      Pemberville: ["43450"],
      Portage: ["43451"],
      "Port clinton": ["43452"],
      "Put in bay": ["43456"],
      Risingsun: ["43457"],
      "Rocky ridge": ["43458"],
      Rossford: ["43460"],
      Rudolph: ["43462"],
      Vickery: ["43464"],
      Walbridge: ["43465"],
      Wayne: ["43466"],
      "West millgrove": ["43467"],
      Williston: ["43468"],
      Woodville: ["43469"],
      "Zcta 434hh": ["434HH"],
      Alvordton: ["43501"],
      Archbold: ["43502"],
      Berkey: ["43504"],
      Bryan: ["43506"],
      Custar: ["43511"],
      Defiance: ["43512"],
      Delta: ["43515"],
      Deshler: ["43516"],
      Edgerton: ["43517"],
      Edon: ["43518"],
      Fayette: ["43521"],
      "Grand rapids": ["43522"],
      Hamler: ["43524"],
      Haskins: ["43525"],
      Hicksville: ["43526"],
      Holgate: ["43527"],
      Holland: ["43528"],
      Hoytville: ["43529"],
      Kunkle: ["43531"],
      "Liberty center": ["43532"],
      Lyons: ["43533"],
      "Mc clure": ["43534"],
      Malinta: ["43535"],
      "Mark center": ["43536"],
      Maumee: ["43537"],
      Metamora: ["43540"],
      "Milton center": ["43541"],
      Monclova: ["43542"],
      Montpelier: ["43543"],
      Napoleon: ["43545"],
      Neapolis: ["43547"],
      "New bavaria": ["43548"],
      Ney: ["43549"],
      Perrysburg: ["43551"],
      Pettisville: ["43553"],
      Pioneer: ["43554"],
      "Ridgeville corne": ["43555"],
      Sherwood: ["43556"],
      Stryker: ["43557"],
      Swanton: ["43558"],
      Sylvania: ["43560"],
      Tontogany: ["43565"],
      Waterville: ["43566"],
      Wauseon: ["43567"],
      Weston: ["43569"],
      "West unity": ["43570"],
      Whitehouse: ["43571"],
      "Zcta 435hh": ["435HH"],
      Toledo: [
        "43602",
        "43604",
        "43606",
        "43607",
        "43608",
        "43609",
        "43610",
        "43611",
        "43612",
        "43613",
        "43614",
        "43615",
        "43617",
        "43620",
        "43623",
        "43624",
      ],
      Oregon: ["43605", "43616", "43618"],
      Northwood: ["43619"],
      "Zcta 436hh": ["436HH"],
      Sonora: ["43701"],
      Somerton: ["43713"],
      Beallsville: ["43716"],
      "Belle valley": ["43717"],
      Belmont: ["43718"],
      Bethesda: ["43719"],
      "Blue rock": ["43720"],
      Brownsville: ["43721"],
      Buffalo: ["43722"],
      Byesville: ["43723"],
      Caldwell: ["43724"],
      Claysville: ["43725"],
      Chandlersville: ["43727"],
      Chesterhill: ["43728"],
      Hemlock: ["43730"],
      Crooksville: ["43731"],
      Cumberland: ["43732"],
      "Duncan falls": ["43734"],
      "East fultonham": ["43735"],
      Fultonham: ["43738"],
      Glenford: ["43739"],
      Gratiot: ["43740"],
      Hopewell: ["43746"],
      Jerusalem: ["43747"],
      "Junction city": ["43748"],
      Guernsey: ["43749"],
      Lewisville: ["43754"],
      "Lore city": ["43755"],
      "Mc connelsville": ["43756"],
      Malta: ["43758"],
      "Mount perry": ["43760"],
      "New concord": ["43762"],
      "New lexington": ["43764"],
      "New straitsville": ["43766"],
      Norwich: ["43767"],
      "Old washington": ["43768"],
      Philo: ["43771"],
      "Pleasant city": ["43772"],
      "Quaker city": ["43773"],
      Roseville: ["43777"],
      Salesville: ["43778"],
      Sarahsville: ["43779"],
      Senecaville: ["43780"],
      Shawnee: ["43782"],
      Somerset: ["43783"],
      Pennsville: ["43787"],
      Summerfield: ["43788"],
      "White cottage": ["43791"],
      Antioch: ["43793"],
      "Zcta 437hh": ["437HH"],
      Adamsville: ["43802"],
      Baltic: ["43804"],
      Conesville: ["43811"],
      Coshocton: ["43812"],
      "Adams mills": ["43821"],
      Frazeysburg: ["43822"],
      Fresno: ["43824"],
      Nashport: ["43830"],
      Newcomerstown: ["43832"],
      "Port washington": ["43837"],
      "Stone creek": ["43840"],
      Trinway: ["43842"],
      Walhonding: ["43843"],
      Warsaw: ["43844"],
      "West lafayette": ["43845"],
      "Zcta 438hh": ["438HH"],
      Adena: ["43901"],
      Alledonia: ["43902"],
      Amsterdam: ["43903"],
      Barton: ["43905"],
      Bellaire: ["43906"],
      Moorefield: ["43907"],
      Bergholz: ["43908"],
      Blaine: ["43909"],
      Bloomingdale: ["43910"],
      Bridgeport: ["43912"],
      Brilliant: ["43913"],
      Cameron: ["43914"],
      Clarington: ["43915"],
      Dillonvale: ["43917"],
      Calcutta: ["43920"],
      "East springfield": ["43925"],
      Empire: ["43926"],
      Fairpoint: ["43927"],
      Glencoe: ["43928"],
      Hammondsville: ["43930"],
      Irondale: ["43932"],
      "Armstrong mills": ["43933"],
      Lansing: ["43934"],
      "Martins ferry": ["43935"],
      "Mingo junction": ["43938"],
      "Mount pleasant": ["43939"],
      Neffs: ["43940"],
      "Powhatan point": ["43942"],
      Rayland: ["43943"],
      Richmond: ["43944"],
      Salineville: ["43945"],
      Sardis: ["43946"],
      Shadyside: ["43947"],
      Smithfield: ["43948"],
      "Saint clairsvill": ["43950"],
      Lafferty: ["43951"],
      Wintersville: ["43952"],
      "Zcta 43953": ["43953"],
      Stratton: ["43961"],
      Summitville: ["43962"],
      Tiltonsville: ["43963"],
      Toronto: ["43964"],
      Warnock: ["43967"],
      Wellsville: ["43968"],
      Yorkville: ["43971"],
      Bannock: ["43972"],
      Freeport: ["43973"],
      Harrisville: ["43974"],
      Hopedale: ["43976"],
      Flushing: ["43977"],
      "New athens": ["43981"],
      Piedmont: ["43983"],
      Jewett: ["43986"],
      Scio: ["43988"],
      "Zcta 439hh": ["439HH"],
      "South amherst": ["44001"],
      Andover: ["44003"],
      Ashtabula: ["44004"],
      Austinburg: ["44010"],
      Avon: ["44011"],
      "Avon lake": ["44012"],
      Berea: ["44017"],
      Burton: ["44021"],
      "Chagrin falls": ["44022", "44023"],
      Chardon: ["44024"],
      Chesterland: ["44026"],
      "Columbia station": ["44028"],
      Conneaut: ["44030"],
      Dorset: ["44032"],
      Elyria: ["44035"],
      "North ridgeville": ["44039"],
      "Gates mills": ["44040"],
      Geneva: ["44041"],
      Grafton: ["44044"],
      Huntsburg: ["44046"],
      Jefferson: ["44047"],
      Kingsville: ["44048"],
      Kipton: ["44049"],
      Lagrange: ["44050"],
      Lorain: ["44052", "44053", "44055"],
      "Sheffield lake": ["44054"],
      Macedonia: ["44056"],
      Madison: ["44057"],
      Mentor: ["44060"],
      Middlefield: ["44062"],
      Montville: ["44064"],
      Newbury: ["44065"],
      Northfield: ["44067"],
      "North olmsted": ["44070"],
      Novelty: ["44072"],
      Oberlin: ["44074"],
      "East orwell": ["44076"],
      "Fairport harbor": ["44077"],
      Parkman: ["44080"],
      Perry: ["44081"],
      Pierpont: ["44082"],
      "Roaming shores": ["44084", "44085"],
      Thompson: ["44086"],
      Twinsburg: ["44087"],
      Vermilion: ["44089"],
      Wellington: ["44090"],
      Wickliffe: ["44092"],
      Williamsfield: ["44093"],
      Willoughby: ["44094"],
      Willowick: ["44095"],
      Windsor: ["44099"],
      "Zcta 440hh": ["440HH"],
      Cleveland: [
        "44102",
        "44103",
        "44104",
        "44105",
        "44106",
        "44108",
        "44109",
        "44110",
        "44111",
        "44113",
        "44114",
        "44115",
        "44119",
        "44120",
        "44127",
        "44128",
        "44135",
      ],
      Edgewater: ["44107"],
      "East cleveland": ["44112"],
      "Rocky river": ["44116"],
      Euclid: ["44117"],
      "Cleveland height": ["44118"],
      "South euclid": ["44121"],
      Beachwood: ["44122"],
      Shore: ["44123"],
      "Lyndhurst mayfie": ["44124"],
      "Garfield heights": ["44125"],
      "Fairview park": ["44126"],
      Parma: ["44129", "44134"],
      Midpark: ["44130"],
      Independence: ["44131"],
      Noble: ["44132"],
      "North royalton": ["44133"],
      Strongsville: ["44136"],
      "Maple heights": ["44137"],
      "Olmsted falls": ["44138"],
      Solon: ["44139"],
      "Bay village": ["44140"],
      Brecksville: ["44141"],
      Brookpark: ["44142"],
      "Richmond heights": ["44143"],
      Brooklyn: ["44144"],
      Westlake: ["44145"],
      Bedford: ["44146"],
      "Broadview height": ["44147"],
      "Zcta 441hh": ["441HH"],
      Atwater: ["44201"],
      Reminderville: ["44202"],
      Norton: ["44203"],
      Brunswick: ["44212"],
      Burbank: ["44214"],
      "Chippewa lake": ["44215"],
      Clinton: ["44216"],
      Creston: ["44217"],
      "Cuyahoga falls": ["44221", "44223"],
      Stow: ["44224"],
      Doylestown: ["44230"],
      Garrettsville: ["44231"],
      Hinckley: ["44233"],
      Hiram: ["44234"],
      Homerville: ["44235"],
      Hudson: ["44236"],
      Kent: ["44240", "44243"],
      Streetsboro: ["44241"],
      Lakemore: ["44250"],
      "Westfield center": ["44251"],
      Litchfield: ["44253"],
      Lodi: ["44254"],
      Mantua: ["44255"],
      Medina: ["44256"],
      Mogadore: ["44260"],
      "Munroe falls": ["44262"],
      Peninsula: ["44264"],
      Ravenna: ["44266"],
      Rittman: ["44270"],
      Rootstown: ["44272"],
      Seville: ["44273"],
      Spencer: ["44275"],
      Sterling: ["44276"],
      Tallmadge: ["44278"],
      "Valley city": ["44280"],
      Wadsworth: ["44281"],
      Richfield: ["44286"],
      "West salem": ["44287"],
      Windham: ["44288"],
      "Zcta 442hh": ["442HH"],
      Akron: [
        "44301",
        "44302",
        "44303",
        "44304",
        "44305",
        "44306",
        "44307",
        "44308",
        "44310",
        "44311",
        "44312",
        "44313",
        "44314",
        "44319",
        "44320",
        "44322",
      ],
      Copley: ["44321"],
      Fairlawn: ["44333"],
      "Zcta 443hh": ["443HH"],
      "Berlin center": ["44401"],
      Bristolville: ["44402"],
      Brookfield: ["44403"],
      Burghill: ["44404"],
      Campbell: ["44405"],
      Canfield: ["44406"],
      Columbiana: ["44408"],
      Cortland: ["44410"],
      Deerfield: ["44411"],
      Diamond: ["44412"],
      "East palestine": ["44413"],
      Farmdale: ["44417"],
      Fowler: ["44418"],
      Girard: ["44420"],
      Hanoverton: ["44423"],
      Hubbard: ["44425"],
      Kensington: ["44427"],
      Kinsman: ["44428"],
      "Lake milton": ["44429"],
      Leavittsburg: ["44430"],
      Leetonia: ["44431"],
      Lisbon: ["44432"],
      Lowellville: ["44436"],
      "Mc donald": ["44437"],
      Masury: ["44438"],
      "Mineral ridge": ["44440"],
      Negley: ["44441"],
      "New middletown": ["44442"],
      "New springfield": ["44443"],
      "Newton falls": ["44444"],
      "New waterford": ["44445"],
      Niles: ["44446"],
      "North benton": ["44449"],
      "North bloomfield": ["44450"],
      "North jackson": ["44451"],
      "North lima": ["44452"],
      Petersburg: ["44454"],
      Rogers: ["44455"],
      Salem: ["44460"],
      Southington: ["44470"],
      Struthers: ["44471"],
      Vienna: ["44473"],
      Warren: ["44481", "44483", "44484", "44485"],
      Washingtonville: ["44490"],
      "West farmington": ["44491"],
      "Zcta 444hh": ["444HH"],
      Youngstown: [
        "44502",
        "44503",
        "44504",
        "44505",
        "44506",
        "44507",
        "44509",
        "44510",
        "44511",
      ],
      Boardman: ["44512"],
      Poland: ["44514"],
      Austintown: ["44515"],
      "Zcta 445hh": ["445HH"],
      Alliance: ["44601"],
      "Apple creek": ["44606"],
      "Beach city": ["44608"],
      Beloit: ["44609"],
      Berlin: ["44610"],
      "Big prairie": ["44611"],
      Bolivar: ["44612"],
      Brewster: ["44613"],
      "Canal fulton": ["44614"],
      Carrollton: ["44615"],
      Dalton: ["44618"],
      Damascus: ["44619"],
      Dellroy: ["44620"],
      Dennison: ["44621"],
      Dover: ["44622"],
      Dundee: ["44624"],
      "East rochester": ["44625"],
      "East sparta": ["44626"],
      Fredericksburg: ["44627"],
      Glenmont: ["44628"],
      Gnadenhutten: ["44629"],
      Hartville: ["44632"],
      Holmesville: ["44633"],
      Homeworth: ["44634"],
      Killbuck: ["44637"],
      Lakeville: ["44638"],
      Limaville: ["44640"],
      Louisville: ["44641"],
      Magnolia: ["44643"],
      Malvern: ["44644"],
      Marshallville: ["44645"],
      Massillon: ["44646", "44647"],
      Mechanicstown: ["44651"],
      Midvale: ["44653"],
      Millersburg: ["44654"],
      Zoarville: ["44656"],
      Minerva: ["44657"],
      "Mount eaton": ["44659"],
      Nashville: ["44661"],
      Navarre: ["44662"],
      "New philadelphia": ["44663"],
      "North lawrence": ["44666"],
      Orrville: ["44667"],
      Paris: ["44669"],
      Robertsville: ["44670"],
      Sandyville: ["44671"],
      Sebring: ["44672"],
      Sherrodsville: ["44675"],
      Shreve: ["44676"],
      Smithville: ["44677"],
      Somerdale: ["44678"],
      Strasburg: ["44680"],
      Sugarcreek: ["44681"],
      Tuscarawas: ["44682"],
      Uhrichsville: ["44683"],
      Uniontown: ["44685"],
      Waynesburg: ["44688"],
      Wilmot: ["44689"],
      Winesburg: ["44690"],
      Wooster: ["44691"],
      Deersville: ["44693"],
      Bowerston: ["44695"],
      Zoar: ["44697"],
      Tippecanoe: ["44699"],
      "Zcta 446hh": ["446HH"],
      Canton: [
        "44702",
        "44703",
        "44704",
        "44705",
        "44706",
        "44708",
        "44710",
        "44714",
        "44721",
      ],
      "North industry": ["44707"],
      "North canton": ["44709", "44720"],
      "Jackson belden": ["44718"],
      "East canton": ["44730"],
      Alvada: ["44802"],
      Arcadia: ["44804"],
      Ashland: ["44805"],
      Carrothers: ["44807"],
      Bascom: ["44809"],
      Bellevue: ["44811"],
      Bellville: ["44813"],
      "Berlin heights": ["44814"],
      Bettsville: ["44815"],
      Birmingham: ["44816"],
      Bloomdale: ["44817"],
      Bloomville: ["44818"],
      Bucyrus: ["44820"],
      Butler: ["44822"],
      Castalia: ["44824"],
      Chatfield: ["44825"],
      Collins: ["44826"],
      Crestline: ["44827"],
      "Flat rock": ["44828"],
      Fostoria: ["44830"],
      Galion: ["44833"],
      "Green springs": ["44836"],
      Greenwich: ["44837"],
      Hayesville: ["44838"],
      Shinrock: ["44839"],
      Jeromesville: ["44840"],
      Kansas: ["44841"],
      Loudonville: ["44842"],
      Lucas: ["44843"],
      "Mc cutchenville": ["44844"],
      Milan: ["44846"],
      Monroeville: ["44847"],
      Nevada: ["44849"],
      "New haven": ["44850"],
      "New london": ["44851"],
      "New riegel": ["44853"],
      "New washington": ["44854"],
      "North fairfield": ["44855"],
      "North robinson": ["44856"],
      Norwalk: ["44857"],
      Nova: ["44859"],
      Oceola: ["44860"],
      "Old fort": ["44861"],
      Perrysville: ["44864"],
      Plymouth: ["44865"],
      Polk: ["44866"],
      Republic: ["44867"],
      Sandusky: ["44870"],
      Savannah: ["44874"],
      Shelby: ["44875"],
      Shiloh: ["44878"],
      Sullivan: ["44880"],
      Sycamore: ["44882", "45242", "45249"],
      Tiffin: ["44883"],
      Tiro: ["44887"],
      Wakeman: ["44889"],
      Willard: ["44890"],
      "Zcta 448hh": ["448HH"],
      Mansfield: ["44902", "44903", "44906", "44907"],
      Lexington: ["44904"],
      Lincoln: ["44905"],
      "Zcta 449hh": ["449HH"],
      Addyston: ["45001"],
      Cleves: ["45002"],
      "College corner": ["45003"],
      Carlisle: ["45005"],
      Hamilton: ["45011"],
      Rossville: ["45013"],
      Fairfield: ["45014"],
      Lindenwald: ["45015"],
      Harrison: ["45030"],
      Harveysburg: ["45032"],
      Hooven: ["45033"],
      "Kings island": ["45034"],
      "Otterbien home": ["45036"],
      Maineville: ["45039"],
      Mason: ["45040"],
      Miamitown: ["45041"],
      Middletown: ["45042"],
      Excello: ["45044"],
      Monroe: ["45050"],
      "North bend": ["45052"],
      Okeana: ["45053"],
      Oregonia: ["45054"],
      "Miami university": ["45056"],
      "Seven mile": ["45062"],
      Somerville: ["45064"],
      "South lebanon": ["45065"],
      Springboro: ["45066"],
      Trenton: ["45067"],
      Waynesville: ["45068"],
      "West chester": ["45069"],
      "West elkton": ["45070"],
      "Zcta 450hh": ["450HH"],
      Aberdeen: ["45101"],
      Amelia: ["45102"],
      Batavia: ["45103"],
      Bethel: ["45106"],
      Blanchester: ["45107"],
      "Camp dennison": ["45111"],
      Chilo: ["45112"],
      Clarksville: ["45113"],
      Cuba: ["45114"],
      Decatur: ["45115"],
      Fayetteville: ["45118"],
      Felicity: ["45120"],
      Georgetown: ["45121"],
      Goshen: ["45122"],
      Greenfield: ["45123"],
      Hamersville: ["45130"],
      Higginsport: ["45131"],
      Highland: ["45132"],
      Hillsboro: ["45133"],
      Leesburg: ["45135"],
      Loveland: ["45140"],
      Lynchburg: ["45142"],
      Manchester: ["45144"],
      Martinsville: ["45146"],
      Midland: ["45148"],
      "Day heights": ["45150"],
      Morrow: ["45152"],
      Moscow: ["45153"],
      "Mount orab": ["45154"],
      Mowrystown: ["45155"],
      Neville: ["45156"],
      "New richmond": ["45157"],
      "New vienna": ["45159"],
      Owensville: ["45160"],
      "Pleasant plain": ["45162"],
      "Port william": ["45164"],
      Ripley: ["45167"],
      Russellville: ["45168"],
      Sabina: ["45169"],
      Sardinia: ["45171"],
      "Sinking spring": ["45172"],
      "Terrace park": ["45174"],
      Williamsburg: ["45176"],
      Wilmington: ["45177"],
      "Zcta 451hh": ["451HH"],
      Cincinnati: [
        "45202",
        "45203",
        "45204",
        "45205",
        "45206",
        "45207",
        "45208",
        "45209",
        "45210",
        "45211",
        "45214",
        "45219",
        "45220",
        "45223",
        "45225",
        "45226",
        "45229",
        "45231",
        "45232",
        "45237",
        "45252",
      ],
      Norwood: ["45212"],
      Taft: ["45213", "45236"],
      Lockland: ["45215"],
      "Elmwood place": ["45216"],
      "Saint bernard": ["45217"],
      Greenhills: ["45218"],
      "College hill": ["45224"],
      Madisonville: ["45227"],
      Anderson: ["45230", "45255"],
      "Saylor park": ["45233"],
      "Western hills": ["45238"],
      Groesbeck: ["45239", "45247", "45251"],
      Parkdale: ["45240"],
      Sharonville: ["45241"],
      Madeira: ["45243"],
      Newtown: ["45244", "45245"],
      Glendale: ["45246"],
      Westwood: ["45248"],
      "Zcta 452hh": ["452HH"],
      Alpha: ["45301"],
      Anna: ["45302"],
      Ansonia: ["45303"],
      Castine: ["45304"],
      Bellbrook: ["45305"],
      Botkins: ["45306"],
      Bowersville: ["45307"],
      Bradford: ["45308"],
      Brookville: ["45309"],
      Burkettsville: ["45310"],
      Camden: ["45311"],
      Casstown: ["45312"],
      Cedarville: ["45314"],
      Clayton: ["45315"],
      Clifton: ["45316"],
      Conover: ["45317"],
      Covington: ["45318"],
      Eaton: ["45320"],
      Eldorado: ["45321"],
      Union: ["45322"],
      Enon: ["45323"],
      Fairborn: ["45324"],
      Farmersville: ["45325"],
      Fletcher: ["45326"],
      Germantown: ["45327"],
      Gettysburg: ["45328"],
      Gratis: ["45330"],
      Greenville: ["45331"],
      Hollansburg: ["45332"],
      Houston: ["45333"],
      "Jackson center": ["45334"],
      Jamestown: ["45335"],
      Kettlersville: ["45336"],
      Laura: ["45337"],
      Lewisburg: ["45338"],
      "Ludlow falls": ["45339"],
      Maplewood: ["45340"],
      Medway: ["45341"],
      Miamisburg: ["45342"],
      "New carlisle": ["45344"],
      "New lebanon": ["45345"],
      "New madison": ["45346"],
      "New paris": ["45347"],
      "New weston": ["45348"],
      "North hampton": ["45349"],
      Osgood: ["45351"],
      Palestine: ["45352"],
      Pemberton: ["45353"],
      Phillipsburg: ["45354"],
      Piqua: ["45356"],
      Pitsburg: ["45358"],
      "Pleasant hill": ["45359"],
      "Port jefferson": ["45360"],
      Rossburg: ["45362"],
      Russia: ["45363"],
      Sidney: ["45365"],
      Selma: ["45368"],
      "South vienna": ["45369"],
      "Spring valley": ["45370"],
      Phoneton: ["45371"],
      Troy: ["45373"],
      Vandalia: ["45377"],
      Verona: ["45378"],
      Versailles: ["45380"],
      "West alexandria": ["45381"],
      "West manchester": ["45382"],
      "West milton": ["45383"],
      Wilberforce: ["45384"],
      Xenia: ["45385"],
      "Yellow springs": ["45387"],
      Yorkshire: ["45388"],
      Christiansburg: ["45389"],
      "Union city": ["45390"],
      "Zcta 453hh": ["453HH"],
      Dayton: [
        "45402",
        "45403",
        "45404",
        "45405",
        "45406",
        "45407",
        "45408",
        "45409",
        "45410",
        "45414",
        "45415",
        "45417",
        "45418",
        "45419",
        "45427",
        "45433",
        "45440",
      ],
      Trotwood: ["45416", "45426"],
      Kettering: ["45420", "45429"],
      "Huber heights": ["45424"],
      Beavercreek: ["45430", "45431", "45432", "45434"],
      "West carrollton": ["45439", "45449"],
      Centerville: ["45458", "45459"],
      "Zcta 454hh": ["454HH"],
      Springfield: ["45502", "45503", "45504", "45505", "45506"],
      "Zcta 455hh": ["455HH"],
      Chillicothe: ["45601"],
      Bainbridge: ["45612"],
      Beaver: ["45613"],
      Bidwell: ["45614"],
      "Blue creek": ["45616"],
      Chesapeake: ["45619"],
      Cheshire: ["45620"],
      Coalton: ["45621"],
      Creola: ["45622"],
      "Crown city": ["45623"],
      Cynthiana: ["45624"],
      Frankfort: ["45628"],
      "Franklin furnace": ["45629"],
      Gallipolis: ["45631"],
      Hamden: ["45634"],
      Ironton: ["45638"],
      Jackson: ["45640"],
      Jasper: ["45642"],
      Kingston: ["45644"],
      "Kitts hill": ["45645"],
      Latham: ["45646"],
      Londonderry: ["45647"],
      Lucasville: ["45648"],
      Lynx: ["45650"],
      Allensville: ["45651"],
      "Mc dermott": ["45652"],
      Minford: ["45653"],
      "New plymouth": ["45654"],
      "Oak hill": ["45656"],
      Otway: ["45657"],
      Patriot: ["45658"],
      Pedro: ["45659"],
      Peebles: ["45660"],
      Idaho: ["45661"],
      "New boston": ["45662"],
      Portsmouth: ["45663"],
      Proctorville: ["45669"],
      Rarden: ["45671"],
      Ray: ["45672"],
      "Richmond dale": ["45673"],
      "Rio grande": ["45674"],
      Scottown: ["45678"],
      Seaman: ["45679"],
      "South point": ["45680"],
      "South salem": ["45681"],
      "South webster": ["45682"],
      Stout: ["45684"],
      Thurman: ["45685"],
      Vinton: ["45686"],
      Waterloo: ["45688"],
      Waverly: ["45690"],
      Wellston: ["45692"],
      "West union": ["45693"],
      Wheelersburg: ["45694"],
      Wilkesville: ["45695"],
      "Willow wood": ["45696"],
      Winchester: ["45697"],
      Zaleski: ["45698"],
      "Zcta 456hh": ["456HH"],
      "Zcta 456xx": ["456XX"],
      Athens: ["45701"],
      Albany: ["45710"],
      Amesville: ["45711"],
      Barlow: ["45712"],
      Bartlett: ["45713"],
      Belpre: ["45714"],
      Beverly: ["45715"],
      Buchtel: ["45716"],
      Chauncey: ["45719"],
      Coolville: ["45723"],
      Cutler: ["45724"],
      "Dexter city": ["45727"],
      Fleming: ["45729"],
      Glouster: ["45732"],
      "Rinard mills": ["45734"],
      Guysville: ["45735"],
      Jacksonville: ["45740"],
      Dexter: ["45741"],
      "Little hocking": ["45742"],
      "Long bottom": ["45743"],
      Lowell: ["45744"],
      Warner: ["45745"],
      Macksburg: ["45746"],
      Marietta: ["45750"],
      Middleport: ["45760"],
      Millfield: ["45761"],
      Nelsonville: ["45764"],
      "New marshfield": ["45766"],
      "New matamoras": ["45767"],
      Newport: ["45768"],
      Pomeroy: ["45769"],
      Portland: ["45770"],
      Racine: ["45771"],
      Reedsville: ["45772"],
      Reno: ["45773"],
      Rutland: ["45775"],
      Shade: ["45776"],
      Stewart: ["45778"],
      Syracuse: ["45779"],
      "The plains": ["45780"],
      Trimble: ["45782"],
      Vincent: ["45784"],
      Waterford: ["45786"],
      Whipple: ["45788"],
      "Wingett run": ["45789"],
      "Zcta 457hh": ["457HH"],
      Lima: ["45801", "45804", "45805"],
      Cridersville: ["45806"],
      Elida: ["45807"],
      Beaverdam: ["45808"],
      Gomer: ["45809"],
      Ada: ["45810"],
      Alger: ["45812"],
      Antwerp: ["45813"],
      Arlington: ["45814"],
      "Benton ridge": ["45816"],
      Bluffton: ["45817"],
      Buckland: ["45819"],
      Cairo: ["45820"],
      Cecil: ["45821"],
      Carthagena: ["45822"],
      Cloverdale: ["45827"],
      Coldwater: ["45828"],
      "Columbus grove": ["45830"],
      Continental: ["45831"],
      Convoy: ["45832"],
      Delphos: ["45833"],
      Dola: ["45835"],
      Dunkirk: ["45836"],
      Dupont: ["45837"],
      Elgin: ["45838"],
      Findlay: ["45840"],
      Jenera: ["45841"],
      Patterson: ["45843"],
      "Fort jennings": ["45844"],
      "Fort loramie": ["45845"],
      "Fort recovery": ["45846"],
      "Grover hill": ["45849"],
      Harrod: ["45850"],
      Haviland: ["45851"],
      Kalida: ["45853"],
      Latty: ["45855"],
      Leipsic: ["45856"],
      "Mc comb": ["45858"],
      "Mc guffey": ["45859"],
      "Maria stein": ["45860"],
      Melrose: ["45861"],
      Mendon: ["45862"],
      "Middle point": ["45863"],
      Minster: ["45865"],
      Montezuma: ["45866"],
      "Mount blanchard": ["45867"],
      "Mount cory": ["45868"],
      "New bremen": ["45869"],
      "New hampshire": ["45870"],
      "New knoxville": ["45871"],
      "North baltimore": ["45872"],
      Oakwood: ["45873"],
      "Ohio city": ["45874"],
      Gilboa: ["45875"],
      Ottoville: ["45876"],
      Pandora: ["45877"],
      Paulding: ["45879"],
      Payne: ["45880"],
      Rawson: ["45881"],
      Rockford: ["45882"],
      "Saint henry": ["45883"],
      "Saint marys": ["45885"],
      Scott: ["45886"],
      Spencerville: ["45887"],
      Uniopolis: ["45888"],
      "Van buren": ["45889"],
      Vanlue: ["45890"],
      "Van wert": ["45891"],
      Venedocia: ["45894"],
      Wapakoneta: ["45895"],
      Waynesfield: ["45896"],
      Williamstown: ["45897"],
      Willshire: ["45898"],
      Wren: ["45899"],
      "Zcta 458hh": ["458HH"],
    },
  },
  {
    abbrevation: "OK",
    name: "Oklahoma",
    Cities: [
      "",
      "",
      "Alex",
      "Edmond",
      "Amber",
      "Anadarko",
      "Apache",
      "Arcadia",
      "Bethany",
      "Binger",
      "Blanchard",
      "Bradley",
      "Calumet",
      "Carnegie",
      "Cashion",
      "Cement",
      "Chickasha",
      "Choctaw",
      "Colony",
      "Concho",
      "Corn",
      "Okla univ studen",
      "Coyle",
      "Crescent",
      "Cyril",
      "Davis",
      "Dibble",
      "Dougherty",
      "Eakly",
      "El reno",
      "Fort cobb",
      "Geary",
      "Gotebo",
      "Gracemont",
      "Greenfield",
      "Guthrie",
      "Harrah",
      "Hinton",
      "Hydro",
      "Jones",
      "Langston",
      "Lexington",
      "Lindsay",
      "Lookeba",
      "Luther",
      "Marlow",
      "Marshall",
      "Maysville",
      "Meridian",
      "Minco",
      "Morrison",
      "Mountain view",
      "Mulhall",
      "Mustang",
      "Newcastle",
      "Nicoma park",
      "Ninnekah",
      "Noble",
      "Norman",
      "Orlando",
      "Paoli",
      "Pauls valley",
      "Perry",
      "Piedmont",
      "Pocasset",
      "Purcell",
      "Rush springs",
      "Spencer",
      "Sulphur",
      "Tuttle",
      "Union city",
      "Verden",
      "Washington",
      "Wayne",
      "Weatherford",
      "Wheatland",
      "Wynnewood",
      "Yukon",
      "Zcta 730hh",
      "Zcta 730xx",
      "Oklahoma city",
      "Midwest city",
      "Del city",
      "Nichols hills",
      "Warr acres",
      "Tinker afb",
      "Moore",
      "Zcta 731hh",
      "Milo",
      "Zcta 73425",
      "Burneyville",
      "Coleman",
      "Zcta 73433",
      "Zcta 73434",
      "Fox",
      "Graham",
      "Healdton",
      "Kingston",
      "Lebanon",
      "Leon",
      "Loco",
      "Lone grove",
      "Zcta 73444",
      "Mc millan",
      "Mannsville",
      "Marietta",
      "Mead",
      "Milburn",
      "Overbrook",
      "Ravia",
      "Ringling",
      "Springer",
      "Thackerville",
      "Tishomingo",
      "Wapanucka",
      "Rubottom",
      "Zcta 73481",
      "Zcta 73487",
      "Zcta 73488",
      "Zcta 73491",
      "Zcta 734hh",
      "Lawton",
      "Fort sill",
      "Addington",
      "Altus",
      "Blair",
      "Cache",
      "Chattanooga",
      "Comanche",
      "Davidson",
      "Devol",
      "Duke",
      "Duncan",
      "Eldorado",
      "Elgin",
      "Elmer",
      "Faxon",
      "Fletcher",
      "Frederick",
      "Geronimo",
      "Gould",
      "Grandfield",
      "Granite",
      "Hastings",
      "Headrick",
      "Hollis",
      "Hollister",
      "Indiahoma",
      "Loveland",
      "Reed",
      "Manitou",
      "Martha",
      "Medicine park",
      "Mountain park",
      "Olustee",
      "Oscar",
      "Randlett",
      "Roosevelt",
      "Ryan",
      "Snyder",
      "Sterling",
      "Temple",
      "Grady",
      "Tipton",
      "Vinson",
      "Walters",
      "Waurika",
      "Zcta 735hh",
      "Zcta 735xx",
      "Clinton",
      "Arapaho",
      "Bessie",
      "Burns flat",
      "Butler",
      "Canute",
      "Carter",
      "Strong city",
      "Cordell",
      "Crawford",
      "Custer city",
      "Dill city",
      "Durham",
      "Elk city",
      "Erick",
      "Fay",
      "Foss",
      "Hammon",
      "Hobart",
      "Leedey",
      "Lone wolf",
      "Eagle city",
      "Putnam",
      "Reydon",
      "Rocky",
      "Sayre",
      "Seiling",
      "Sentinel",
      "Sweetwater",
      "Taloga",
      "Texola",
      "Thomas",
      "Willow",
      "Zcta 736hh",
      "Zcta 736xx",
      "Enid",
      "Aline",
      "Alva",
      "Ames",
      "Amorita",
      "Bison",
      "Burlington",
      "Canton",
      "Carmen",
      "Carrier",
      "Cherokee",
      "Cleo springs",
      "Covington",
      "Dacoma",
      "Douglas",
      "Dover",
      "Drummond",
      "Fairmont",
      "Orienta",
      "Garber",
      "Goltry",
      "Helena",
      "Hennessey",
      "Hillsdale",
      "Hitchcock",
      "Hopeton",
      "Isabella",
      "Jet",
      "Kingfisher",
      "Kremlin",
      "Lahoma",
      "Longdale",
      "Loyal",
      "Lucien",
      "Manchester",
      "Medford",
      "Meno",
      "Nash",
      "Okarche",
      "Okeene",
      "Omega",
      "Pond creek",
      "Ringwood",
      "Wakita",
      "Watonga",
      "Waukomis",
      "Zcta 737hh",
      "Woodward",
      "Harmon",
      "Selman",
      "Camargo",
      "Chester",
      "Fargo",
      "Fort supply",
      "Freedom",
      "Gage",
      "Gate",
      "Knowles",
      "Laverne",
      "May",
      "Mooreland",
      "Mutual",
      "Rosston",
      "Sharon",
      "Shattuck",
      "Vici",
      "Waynoka",
      "Zcta 738hh",
      "Zcta 738xx",
      "Adams",
      "Balko",
      "Elmwood",
      "Boise city",
      "Felt",
      "Forgan",
      "Goodwell",
      "Guymon",
      "Hardesty",
      "Optima",
      "Kenton",
      "Keyes",
      "Texhoma",
      "Baker",
      "Tyrone",
      "Zcta 739hh",
      "Zcta 739xx",
      "Avant",
      "Barnsdall",
      "Bartlesville",
      "Bixby",
      "Bristow",
      "Broken arrow",
      "Catoosa",
      "Chelsea",
      "Claremore",
      "Cleveland",
      "Collinsville",
      "Copan",
      "Cushing",
      "Davenport",
      "Delaware",
      "Depew",
      "Dewey",
      "Drumright",
      "Glencoe",
      "Glenpool",
      "Hallett",
      "Hominy",
      "Inola",
      "Jenks",
      "Jennings",
      "Kellyville",
      "Kiefer",
      "Lenapah",
      "Mannford",
      "Maramec",
      "Mounds",
      "Nowata",
      "Oakhurst",
      "Ochelata",
      "Oilton",
      "Oologah",
      "Osage",
      "Owasso",
      "Pawhuska",
      "Pawnee",
      "Perkins",
      "Prue",
      "Ramona",
      "Ripley",
      "Sand springs",
      "Sapulpa",
      "Shamrock",
      "Skiatook",
      "Slick",
      "S coffeyville",
      "Sperry",
      "Stillwater",
      "Kendrick",
      "Talala",
      "Terlton",
      "Wann",
      "Wynona",
      "Yale",
      "Zcta 740hh",
      "Tulsa",
      "Vinita",
      "Adair",
      "Bernice",
      "Big cabin",
      "Bluejacket",
      "Cardin",
      "Chouteau",
      "Colcord",
      "Commerce",
      "Disney",
      "Eucha",
      "Fairland",
      "Grove",
      "Jay",
      "Kansas",
      "Ketchum",
      "Langley",
      "Locust grove",
      "Miami",
      "North miami",
      "Picher",
      "Pryor",
      "Quapaw",
      "Leach",
      "Salina",
      "Spavinaw",
      "Strang",
      "Twin oaks",
      "Welch",
      "Wyandotte",
      "Zcta 743hh",
      "Muskogee",
      "Beggs",
      "Boynton",
      "Braggs",
      "Canadian",
      "Checotah",
      "Cookson",
      "Council hill",
      "Coweta",
      "Crowder",
      "Dewar",
      "Eufaula",
      "Fort gibson",
      "Gore",
      "Haskell",
      "Hoffman",
      "Hitchita",
      "Hoyt",
      "Hulbert",
      "Indianola",
      "Moodys",
      "Morris",
      "Okay",
      "Okmulgee",
      "Oktaha",
      "Park hill",
      "Peggs",
      "Porter",
      "Porum",
      "Preston",
      "Proctor",
      "Rentiesville",
      "Schulter",
      "Stidham",
      "Stigler",
      "Taft",
      "Tahlequah",
      "Wagoner",
      "Wainwright",
      "Warner",
      "Webbers falls",
      "Welling",
      "Whitefield",
      "Zcta 744hh",
      "Zcta 744xx",
      "Mcalester",
      "Albion",
      "Alderson",
      "Antlers",
      "Atoka",
      "Blanco",
      "Bromide",
      "Calvin",
      "Caney",
      "Centrahoma",
      "Clarita",
      "Clayton",
      "Coalgate",
      "Daisy",
      "Finley",
      "Gowen",
      "Haileyville",
      "Hartshorne",
      "Honobia",
      "Kinta",
      "Kiowa",
      "Krebs",
      "Lane",
      "Lehigh",
      "Moyers",
      "Nashoba",
      "Pittsburg",
      "Quinton",
      "Rattan",
      "Red oak",
      "Savanna",
      "Snow",
      "Stringtown",
      "Stuart",
      "Talihina",
      "Tupelo",
      "Tuskahoma",
      "Wardville",
      "Whitesboro",
      "Wilburton",
      "Zcta 745hh",
      "Zcta 745xx",
      "Ponca city",
      "Billings",
      "Blackwell",
      "Braman",
      "Burbank",
      "Deer creek",
      "Fairfax",
      "Hunter",
      "Kaw city",
      "Lamont",
      "Marland",
      "Nardin",
      "Peckham",
      "Ralston",
      "Red rock",
      "Foraker",
      "Tonkawa",
      "Zcta 746hh",
      "Durant",
      "Achille",
      "Albany",
      "Battiest",
      "Bennington",
      "Bethel",
      "Bokchito",
      "Boswell",
      "Broken bow",
      "Caddo",
      "Calera",
      "Cartwright",
      "Colbert",
      "Eagletown",
      "Fort towson",
      "Garvin",
      "Golden",
      "Grant",
      "Tom",
      "Hendrix",
      "Hugo",
      "Idabel",
      "Kemp",
      "Kenefic",
      "Millerton",
      "Platter",
      "Ringold",
      "Rufe",
      "Sawyer",
      "Soper",
      "Spencerville",
      "Swink",
      "Valliant",
      "Wright city",
      "Zcta 747hh",
      "Zcta 747xx",
      "Shawnee",
      "Zcta 74804",
      "Ada",
      "Agra",
      "Allen",
      "Asher",
      "Atwood",
      "Boley",
      "Bowlegs",
      "Byars",
      "Carney",
      "Castle",
      "Chandler",
      "Connerville",
      "Cromwell",
      "Dustin",
      "Earlsboro",
      "Fittstown",
      "Fitzhugh",
      "Francis",
      "Vernon",
      "Holdenville",
      "Konawa",
      "Lamar",
      "Mc loud",
      "Macomb",
      "Maud",
      "Meeker",
      "Mill creek",
      "Newalla",
      "Bearden",
      "Paden",
      "Prague",
      "Roff",
      "Saint louis",
      "Sasakwa",
      "Seminole",
      "Sparks",
      "Harden city",
      "Stratford",
      "Tecumseh",
      "Tryon",
      "Wanette",
      "Weleetka",
      "Wellston",
      "Wetumka",
      "New lima",
      "Zcta 748hh",
      "Arkoma",
      "Pocola",
      "Bokoshe",
      "Bunch",
      "Cameron",
      "Fanshawe",
      "Gans",
      "Heavener",
      "Hodgen",
      "Howe",
      "Keota",
      "Leflore",
      "Lequire",
      "Mccurtain",
      "Marble city",
      "Moffett",
      "Monroe",
      "Muldrow",
      "Muse",
      "Panama",
      "Poteau",
      "Roland",
      "Sallisaw",
      "Shady point",
      "Octavia",
      "Spiro",
      "Stilwell",
      "Vian",
      "Watson",
      "Watts",
      "Westville",
      "Wister",
      "Zcta 749hh",
    ],
    ZipCodes: {
      "": ["67950", "79051"],
      Alex: ["73002"],
      Edmond: ["73003", "73013", "73034"],
      Amber: ["73004"],
      Anadarko: ["73005"],
      Apache: ["73006"],
      Arcadia: ["73007"],
      Bethany: ["73008"],
      Binger: ["73009"],
      Blanchard: ["73010"],
      Bradley: ["73011"],
      Calumet: ["73014"],
      Carnegie: ["73015"],
      Cashion: ["73016"],
      Cement: ["73017"],
      Chickasha: ["73018"],
      Choctaw: ["73020"],
      Colony: ["73021"],
      Concho: ["73022"],
      Corn: ["73024"],
      "Okla univ studen": ["73026"],
      Coyle: ["73027"],
      Crescent: ["73028"],
      Cyril: ["73029"],
      Davis: ["73030"],
      Dibble: ["73031"],
      Dougherty: ["73032"],
      Eakly: ["73033"],
      "El reno": ["73036"],
      "Fort cobb": ["73038"],
      Geary: ["73040"],
      Gotebo: ["73041"],
      Gracemont: ["73042"],
      Greenfield: ["73043"],
      Guthrie: ["73044"],
      Harrah: ["73045"],
      Hinton: ["73047"],
      Hydro: ["73048"],
      Jones: ["73049"],
      Langston: ["73050"],
      Lexington: ["73051"],
      Lindsay: ["73052"],
      Lookeba: ["73053"],
      Luther: ["73054"],
      Marlow: ["73055"],
      Marshall: ["73056"],
      Maysville: ["73057"],
      Meridian: ["73058"],
      Minco: ["73059"],
      Morrison: ["73061"],
      "Mountain view": ["73062"],
      Mulhall: ["73063"],
      Mustang: ["73064"],
      Newcastle: ["73065"],
      "Nicoma park": ["73066"],
      Ninnekah: ["73067"],
      Noble: ["73068"],
      Norman: ["73069", "73071", "73072"],
      Orlando: ["73073"],
      Paoli: ["73074"],
      "Pauls valley": ["73075"],
      Perry: ["73077"],
      Piedmont: ["73078"],
      Pocasset: ["73079"],
      Purcell: ["73080"],
      "Rush springs": ["73082"],
      Spencer: ["73084"],
      Sulphur: ["73086"],
      Tuttle: ["73089"],
      "Union city": ["73090"],
      Verden: ["73092"],
      Washington: ["73093"],
      Wayne: ["73095"],
      Weatherford: ["73096"],
      Wheatland: ["73097"],
      Wynnewood: ["73098"],
      Yukon: ["73099"],
      "Zcta 730hh": ["730HH"],
      "Zcta 730xx": ["730XX"],
      "Oklahoma city": [
        "73102",
        "73103",
        "73104",
        "73105",
        "73106",
        "73107",
        "73108",
        "73109",
        "73111",
        "73112",
        "73114",
        "73117",
        "73118",
        "73119",
        "73120",
        "73121",
        "73127",
        "73128",
        "73129",
        "73131",
        "73134",
        "73135",
        "73139",
        "73141",
        "73142",
        "73149",
        "73150",
        "73151",
        "73159",
        "73162",
        "73169",
        "73173",
        "73179",
      ],
      "Midwest city": ["73110", "73130"],
      "Del city": ["73115"],
      "Nichols hills": ["73116"],
      "Warr acres": ["73122", "73132"],
      "Tinker afb": ["73145"],
      Moore: ["73160", "73165", "73170"],
      "Zcta 731hh": ["731HH"],
      Milo: ["73401"],
      "Zcta 73425": ["73425"],
      Burneyville: ["73430"],
      Coleman: ["73432"],
      "Zcta 73433": ["73433"],
      "Zcta 73434": ["73434"],
      Fox: ["73435"],
      Graham: ["73437"],
      Healdton: ["73438"],
      Kingston: ["73439"],
      Lebanon: ["73440"],
      Leon: ["73441"],
      Loco: ["73442"],
      "Lone grove": ["73443"],
      "Zcta 73444": ["73444"],
      "Mc millan": ["73446"],
      Mannsville: ["73447"],
      Marietta: ["73448"],
      Mead: ["73449"],
      Milburn: ["73450"],
      Overbrook: ["73453"],
      Ravia: ["73455"],
      Ringling: ["73456"],
      Springer: ["73458"],
      Thackerville: ["73459"],
      Tishomingo: ["73460"],
      Wapanucka: ["73461"],
      Rubottom: ["73463"],
      "Zcta 73481": ["73481"],
      "Zcta 73487": ["73487"],
      "Zcta 73488": ["73488"],
      "Zcta 73491": ["73491"],
      "Zcta 734hh": ["734HH"],
      Lawton: ["73501", "73505", "73507"],
      "Fort sill": ["73503"],
      Addington: ["73520"],
      Altus: ["73521"],
      Blair: ["73526"],
      Cache: ["73527"],
      Chattanooga: ["73528"],
      Comanche: ["73529"],
      Davidson: ["73530"],
      Devol: ["73531"],
      Duke: ["73532"],
      Duncan: ["73533"],
      Eldorado: ["73537"],
      Elgin: ["73538"],
      Elmer: ["73539"],
      Faxon: ["73540"],
      Fletcher: ["73541"],
      Frederick: ["73542"],
      Geronimo: ["73543"],
      Gould: ["73544"],
      Grandfield: ["73546"],
      Granite: ["73547"],
      Hastings: ["73548"],
      Headrick: ["73549"],
      Hollis: ["73550"],
      Hollister: ["73551"],
      Indiahoma: ["73552"],
      Loveland: ["73553"],
      Reed: ["73554"],
      Manitou: ["73555"],
      Martha: ["73556"],
      "Medicine park": ["73557"],
      "Mountain park": ["73559"],
      Olustee: ["73560"],
      Oscar: ["73561"],
      Randlett: ["73562"],
      Roosevelt: ["73564"],
      Ryan: ["73565"],
      Snyder: ["73566"],
      Sterling: ["73567"],
      Temple: ["73568"],
      Grady: ["73569"],
      Tipton: ["73570"],
      Vinson: ["73571"],
      Walters: ["73572"],
      Waurika: ["73573"],
      "Zcta 735hh": ["735HH"],
      "Zcta 735xx": ["735XX"],
      Clinton: ["73601"],
      Arapaho: ["73620"],
      Bessie: ["73622"],
      "Burns flat": ["73624"],
      Butler: ["73625"],
      Canute: ["73626"],
      Carter: ["73627"],
      "Strong city": ["73628"],
      Cordell: ["73632"],
      Crawford: ["73638"],
      "Custer city": ["73639"],
      "Dill city": ["73641"],
      Durham: ["73642"],
      "Elk city": ["73644"],
      Erick: ["73645"],
      Fay: ["73646"],
      Foss: ["73647"],
      Hammon: ["73650"],
      Hobart: ["73651"],
      Leedey: ["73654"],
      "Lone wolf": ["73655"],
      "Eagle city": ["73658"],
      Putnam: ["73659"],
      Reydon: ["73660"],
      Rocky: ["73661"],
      Sayre: ["73662"],
      Seiling: ["73663"],
      Sentinel: ["73664"],
      Sweetwater: ["73666"],
      Taloga: ["73667"],
      Texola: ["73668"],
      Thomas: ["73669"],
      Willow: ["73673"],
      "Zcta 736hh": ["736HH"],
      "Zcta 736xx": ["736XX"],
      Enid: ["73701", "73703"],
      Aline: ["73716"],
      Alva: ["73717"],
      Ames: ["73718"],
      Amorita: ["73719"],
      Bison: ["73720"],
      Burlington: ["73722"],
      Canton: ["73724"],
      Carmen: ["73726"],
      Carrier: ["73727"],
      Cherokee: ["73728"],
      "Cleo springs": ["73729"],
      Covington: ["73730"],
      Dacoma: ["73731"],
      Douglas: ["73733"],
      Dover: ["73734"],
      Drummond: ["73735"],
      Fairmont: ["73736"],
      Orienta: ["73737"],
      Garber: ["73738"],
      Goltry: ["73739"],
      Helena: ["73741"],
      Hennessey: ["73742"],
      Hillsdale: ["73743"],
      Hitchcock: ["73744"],
      Hopeton: ["73746"],
      Isabella: ["73747"],
      Jet: ["73749"],
      Kingfisher: ["73750"],
      Kremlin: ["73753"],
      Lahoma: ["73754"],
      Longdale: ["73755"],
      Loyal: ["73756"],
      Lucien: ["73757"],
      Manchester: ["73758"],
      Medford: ["73759"],
      Meno: ["73760"],
      Nash: ["73761"],
      Okarche: ["73762"],
      Okeene: ["73763"],
      Omega: ["73764"],
      "Pond creek": ["73766"],
      Ringwood: ["73768"],
      Wakita: ["73771"],
      Watonga: ["73772"],
      Waukomis: ["73773"],
      "Zcta 737hh": ["737HH"],
      Woodward: ["73801", "73802"],
      Harmon: ["73832"],
      Selman: ["73834"],
      Camargo: ["73835"],
      Chester: ["73838"],
      Fargo: ["73840"],
      "Fort supply": ["73841"],
      Freedom: ["73842"],
      Gage: ["73843"],
      Gate: ["73844"],
      Knowles: ["73847"],
      Laverne: ["73848"],
      May: ["73851"],
      Mooreland: ["73852"],
      Mutual: ["73853"],
      Rosston: ["73855"],
      Sharon: ["73857"],
      Shattuck: ["73858"],
      Vici: ["73859"],
      Waynoka: ["73860"],
      "Zcta 738hh": ["738HH"],
      "Zcta 738xx": ["738XX"],
      Adams: ["73901"],
      Balko: ["73931"],
      Elmwood: ["73932"],
      "Boise city": ["73933"],
      Felt: ["73937"],
      Forgan: ["73938"],
      Goodwell: ["73939"],
      Guymon: ["73942"],
      Hardesty: ["73944"],
      Optima: ["73945"],
      Kenton: ["73946"],
      Keyes: ["73947"],
      Texhoma: ["73949"],
      Baker: ["73950"],
      Tyrone: ["73951"],
      "Zcta 739hh": ["739HH"],
      "Zcta 739xx": ["739XX"],
      Avant: ["74001"],
      Barnsdall: ["74002"],
      Bartlesville: ["74003", "74006"],
      Bixby: ["74008"],
      Bristow: ["74010"],
      "Broken arrow": ["74011", "74012", "74014"],
      Catoosa: ["74015"],
      Chelsea: ["74016"],
      Claremore: ["74017"],
      Cleveland: ["74020"],
      Collinsville: ["74021"],
      Copan: ["74022"],
      Cushing: ["74023"],
      Davenport: ["74026"],
      Delaware: ["74027"],
      Depew: ["74028"],
      Dewey: ["74029"],
      Drumright: ["74030"],
      Glencoe: ["74032"],
      Glenpool: ["74033"],
      Hallett: ["74034"],
      Hominy: ["74035"],
      Inola: ["74036"],
      Jenks: ["74037"],
      Jennings: ["74038"],
      Kellyville: ["74039"],
      Kiefer: ["74041"],
      Lenapah: ["74042"],
      Mannford: ["74044"],
      Maramec: ["74045"],
      Mounds: ["74047"],
      Nowata: ["74048"],
      Oakhurst: ["74050"],
      Ochelata: ["74051"],
      Oilton: ["74052"],
      Oologah: ["74053"],
      Osage: ["74054"],
      Owasso: ["74055"],
      Pawhuska: ["74056"],
      Pawnee: ["74058"],
      Perkins: ["74059"],
      Prue: ["74060"],
      Ramona: ["74061"],
      Ripley: ["74062"],
      "Sand springs": ["74063"],
      Sapulpa: ["74066"],
      Shamrock: ["74068"],
      Skiatook: ["74070"],
      Slick: ["74071"],
      "S coffeyville": ["74072"],
      Sperry: ["74073"],
      Stillwater: ["74074", "74075"],
      Kendrick: ["74079"],
      Talala: ["74080"],
      Terlton: ["74081"],
      Wann: ["74083"],
      Wynona: ["74084"],
      Yale: ["74085"],
      "Zcta 740hh": ["740HH"],
      Tulsa: [
        "74103",
        "74104",
        "74105",
        "74106",
        "74107",
        "74108",
        "74110",
        "74112",
        "74114",
        "74115",
        "74116",
        "74117",
        "74119",
        "74120",
        "74126",
        "74127",
        "74128",
        "74129",
        "74130",
        "74131",
        "74132",
        "74133",
        "74134",
        "74135",
        "74136",
        "74137",
        "74145",
        "74146",
      ],
      Vinita: ["74301"],
      Adair: ["74330"],
      Bernice: ["74331"],
      "Big cabin": ["74332"],
      Bluejacket: ["74333"],
      Cardin: ["74335"],
      Chouteau: ["74337"],
      Colcord: ["74338"],
      Commerce: ["74339"],
      Disney: ["74340"],
      Eucha: ["74342"],
      Fairland: ["74343"],
      Grove: ["74344"],
      Jay: ["74346"],
      Kansas: ["74347"],
      Ketchum: ["74349"],
      Langley: ["74350"],
      "Locust grove": ["74352"],
      Miami: ["74354"],
      "North miami": ["74358"],
      Picher: ["74360"],
      Pryor: ["74361"],
      Quapaw: ["74363"],
      Leach: ["74364"],
      Salina: ["74365"],
      Spavinaw: ["74366"],
      Strang: ["74367"],
      "Twin oaks": ["74368"],
      Welch: ["74369"],
      Wyandotte: ["74370"],
      "Zcta 743hh": ["743HH"],
      Muskogee: ["74401", "74403"],
      Beggs: ["74421"],
      Boynton: ["74422"],
      Braggs: ["74423"],
      Canadian: ["74425"],
      Checotah: ["74426"],
      Cookson: ["74427"],
      "Council hill": ["74428"],
      Coweta: ["74429"],
      Crowder: ["74430"],
      Dewar: ["74431"],
      Eufaula: ["74432"],
      "Fort gibson": ["74434"],
      Gore: ["74435"],
      Haskell: ["74436"],
      Hoffman: ["74437"],
      Hitchita: ["74438"],
      Hoyt: ["74440"],
      Hulbert: ["74441"],
      Indianola: ["74442"],
      Moodys: ["74444"],
      Morris: ["74445"],
      Okay: ["74446"],
      Okmulgee: ["74447"],
      Oktaha: ["74450"],
      "Park hill": ["74451"],
      Peggs: ["74452"],
      Porter: ["74454"],
      Porum: ["74455"],
      Preston: ["74456"],
      Proctor: ["74457"],
      Rentiesville: ["74459"],
      Schulter: ["74460"],
      Stidham: ["74461"],
      Stigler: ["74462"],
      Taft: ["74463"],
      Tahlequah: ["74464"],
      Wagoner: ["74467"],
      Wainwright: ["74468"],
      Warner: ["74469"],
      "Webbers falls": ["74470"],
      Welling: ["74471"],
      Whitefield: ["74472"],
      "Zcta 744hh": ["744HH"],
      "Zcta 744xx": ["744XX"],
      Mcalester: ["74501"],
      Albion: ["74521"],
      Alderson: ["74522"],
      Antlers: ["74523"],
      Atoka: ["74525"],
      Blanco: ["74528"],
      Bromide: ["74530"],
      Calvin: ["74531"],
      Caney: ["74533"],
      Centrahoma: ["74534"],
      Clarita: ["74535"],
      Clayton: ["74536"],
      Coalgate: ["74538"],
      Daisy: ["74540"],
      Finley: ["74543"],
      Gowen: ["74545"],
      Haileyville: ["74546"],
      Hartshorne: ["74547"],
      Honobia: ["74549"],
      Kinta: ["74552"],
      Kiowa: ["74553"],
      Krebs: ["74554"],
      Lane: ["74555"],
      Lehigh: ["74556"],
      Moyers: ["74557"],
      Nashoba: ["74558"],
      Pittsburg: ["74560"],
      Quinton: ["74561"],
      Rattan: ["74562"],
      "Red oak": ["74563"],
      Savanna: ["74565"],
      Snow: ["74567"],
      Stringtown: ["74569"],
      Stuart: ["74570"],
      Talihina: ["74571"],
      Tupelo: ["74572"],
      Tuskahoma: ["74574"],
      Wardville: ["74576"],
      Whitesboro: ["74577"],
      Wilburton: ["74578"],
      "Zcta 745hh": ["745HH"],
      "Zcta 745xx": ["745XX"],
      "Ponca city": ["74601", "74604"],
      Billings: ["74630"],
      Blackwell: ["74631"],
      Braman: ["74632"],
      Burbank: ["74633"],
      "Deer creek": ["74636"],
      Fairfax: ["74637"],
      Hunter: ["74640"],
      "Kaw city": ["74641"],
      Lamont: ["74643"],
      Marland: ["74644"],
      Nardin: ["74646"],
      Peckham: ["74647"],
      Ralston: ["74650"],
      "Red rock": ["74651"],
      Foraker: ["74652"],
      Tonkawa: ["74653"],
      "Zcta 746hh": ["746HH"],
      Durant: ["74701"],
      Achille: ["74720"],
      Albany: ["74721"],
      Battiest: ["74722"],
      Bennington: ["74723"],
      Bethel: ["74724"],
      Bokchito: ["74726"],
      Boswell: ["74727"],
      "Broken bow": ["74728"],
      Caddo: ["74729"],
      Calera: ["74730"],
      Cartwright: ["74731"],
      Colbert: ["74733"],
      Eagletown: ["74734"],
      "Fort towson": ["74735"],
      Garvin: ["74736"],
      Golden: ["74737"],
      Grant: ["74738"],
      Tom: ["74740"],
      Hendrix: ["74741"],
      Hugo: ["74743"],
      Idabel: ["74745"],
      Kemp: ["74747"],
      Kenefic: ["74748"],
      Millerton: ["74750"],
      Platter: ["74753"],
      Ringold: ["74754"],
      Rufe: ["74755"],
      Sawyer: ["74756"],
      Soper: ["74759"],
      Spencerville: ["74760"],
      Swink: ["74761"],
      Valliant: ["74764"],
      "Wright city": ["74766"],
      "Zcta 747hh": ["747HH"],
      "Zcta 747xx": ["747XX"],
      Shawnee: ["74801"],
      "Zcta 74804": ["74804"],
      Ada: ["74820"],
      Agra: ["74824"],
      Allen: ["74825"],
      Asher: ["74826"],
      Atwood: ["74827"],
      Boley: ["74829"],
      Bowlegs: ["74830"],
      Byars: ["74831"],
      Carney: ["74832"],
      Castle: ["74833"],
      Chandler: ["74834"],
      Connerville: ["74836"],
      Cromwell: ["74837"],
      Dustin: ["74839"],
      Earlsboro: ["74840"],
      Fittstown: ["74842"],
      Fitzhugh: ["74843"],
      Francis: ["74844"],
      Vernon: ["74845"],
      Holdenville: ["74848"],
      Konawa: ["74849"],
      Lamar: ["74850"],
      "Mc loud": ["74851"],
      Macomb: ["74852"],
      Maud: ["74854"],
      Meeker: ["74855"],
      "Mill creek": ["74856"],
      Newalla: ["74857"],
      Bearden: ["74859"],
      Paden: ["74860"],
      Prague: ["74864"],
      Roff: ["74865"],
      "Saint louis": ["74866"],
      Sasakwa: ["74867"],
      Seminole: ["74868"],
      Sparks: ["74869"],
      "Harden city": ["74871"],
      Stratford: ["74872"],
      Tecumseh: ["74873"],
      Tryon: ["74875"],
      Wanette: ["74878"],
      Weleetka: ["74880"],
      Wellston: ["74881"],
      Wetumka: ["74883"],
      "New lima": ["74884"],
      "Zcta 748hh": ["748HH"],
      Arkoma: ["74901"],
      Pocola: ["74902"],
      Bokoshe: ["74930"],
      Bunch: ["74931"],
      Cameron: ["74932"],
      Fanshawe: ["74935"],
      Gans: ["74936"],
      Heavener: ["74937"],
      Hodgen: ["74939"],
      Howe: ["74940"],
      Keota: ["74941"],
      Leflore: ["74942"],
      Lequire: ["74943"],
      Mccurtain: ["74944"],
      "Marble city": ["74945"],
      Moffett: ["74946"],
      Monroe: ["74947"],
      Muldrow: ["74948"],
      Muse: ["74949"],
      Panama: ["74951"],
      Poteau: ["74953"],
      Roland: ["74954"],
      Sallisaw: ["74955"],
      "Shady point": ["74956"],
      Octavia: ["74957"],
      Spiro: ["74959"],
      Stilwell: ["74960"],
      Vian: ["74962"],
      Watson: ["74963"],
      Watts: ["74964"],
      Westville: ["74965"],
      Wister: ["74966"],
      "Zcta 749hh": ["749HH"],
    },
  },
  {
    abbrevation: "OR",
    name: "Oregon",
    Cities: [
      "Antelope",
      "Antelope",
      "Aurora",
      "Beavercreek",
      "Beaverton",
      "Aloha",
      "Zcta 97008",
      "Boring",
      "Brightwood",
      "Canby",
      "Bonneville",
      "Clackamas",
      "Westport",
      "Colton",
      "Columbia city",
      "Corbett",
      "Donald",
      "Friend",
      "Eagle creek",
      "Estacada",
      "Fairview",
      "Gervais",
      "Gladstone",
      "Timberline lodge",
      "Grass valley",
      "Gresham",
      "Hood river",
      "Hubbard",
      "Kent",
      "Lake oswego",
      "Maupin",
      "Molalla",
      "Moro",
      "Mosier",
      "Mount hood parkd",
      "Mulino",
      "Oregon city",
      "Rainier",
      "Zigzag",
      "Rufus",
      "Saint helens",
      "Warren",
      "Deer island",
      "Sandy",
      "Scappoose",
      "The dalles",
      "Troutdale",
      "Tualatin",
      "Wamic",
      "Vernonia",
      "Wasco",
      "Welches",
      "West linn",
      "Wilsonville",
      "Woodburn",
      "Zcta 970hh",
      "Zcta 970xx",
      "Amity",
      "Arch cape",
      "Astoria",
      "Banks",
      "Bay city",
      "Beaver",
      "Buxton",
      "Cannon beach",
      "Carlton",
      "Cloverdale",
      "Cornelius",
      "Dayton",
      "Dundee",
      "Glenwood",
      "Gales creek",
      "Garibaldi",
      "Gaston",
      "Hammond",
      "Hebo",
      "Hillsboro",
      "Manning",
      "Lafayette",
      "Mcminnville",
      "Manzanita",
      "Nehalem",
      "Newberg",
      "North plains",
      "Oceanside",
      "Pacific city",
      "Rockaway",
      "Saint paul",
      "Gearhart",
      "Sherwood",
      "Tillamook",
      "Netarts",
      "Timber",
      "Tolovana park",
      "Warrenton",
      "Wheeler",
      "Yamhill",
      "Neskowin",
      "Zcta 971hh",
      "Zcta 971xx",
      "Portland",
      "Milwaukie",
      "Garden home",
      "Tigard",
      "Cedar hills",
      "Rockwood corners",
      "Oak grove",
      "Zcta 972hh",
      "Salem",
      "Keizer",
      "Brooks",
      "Albany",
      "Alsea",
      "West stayton",
      "Blodgett",
      "Brownsville",
      "Cascadia",
      "Corvallis",
      "Dallas",
      "Depoe bay",
      "Detroit",
      "Eddyville",
      "Falls city",
      "Foster",
      "Gates",
      "Grand ronde",
      "Halsey",
      "Idanha",
      "Independence",
      "Jefferson",
      "Lebanon",
      "Logsden",
      "Lyons",
      "Mill city",
      "Monmouth",
      "Mount angel",
      "Neotsu",
      "Newport",
      "South beach",
      "Lincoln city",
      "Otis",
      "Philomath",
      "Rickreall",
      "Scio",
      "Scotts mills",
      "Seal rock",
      "Shedd",
      "Sheridan",
      "Siletz",
      "Silverton",
      "Stayton",
      "Sublimity",
      "Sweet home",
      "Tangent",
      "Tidewater",
      "Toledo",
      "Turner",
      "Waldport",
      "Willamina",
      "Zcta 973hh",
      "Zcta 973xx",
      "Coburg",
      "Eugene",
      "Agness",
      "Zcta 97408",
      "Azalea",
      "Bandon",
      "Blachly",
      "Mc kenzie bridge",
      "Broadbent",
      "Harbor",
      "Camas valley",
      "Canyonville",
      "Cheshire",
      "Charleston",
      "Coquille",
      "Cottage grove",
      "Cascade summit",
      "Creswell",
      "Culp creek",
      "Curtin",
      "Days creek",
      "Greenleaf",
      "Dexter",
      "Dorena",
      "Drain",
      "Elkton",
      "Elmira",
      "Fall creek",
      "Florence",
      "Gardiner",
      "Glendale",
      "Glide",
      "Pistol river",
      "Harrisburg",
      "Idleyld park",
      "Junction city",
      "Lakeside",
      "Langlois",
      "Lorane",
      "Lowell",
      "Mapleton",
      "Marcola",
      "Pleasant hill",
      "Monroe",
      "Myrtle creek",
      "Myrtle point",
      "North bend",
      "Noti",
      "Oakland",
      "Oakridge",
      "Port orford",
      "Powers",
      "Winchester bay",
      "Riddle",
      "Roseburg",
      "Scottsburg",
      "Sixes",
      "Springfield",
      "Sutherlin",
      "Swisshome",
      "Tenmile",
      "Tiller",
      "Umpqua",
      "Veneta",
      "Vida",
      "Leaburg",
      "Walton",
      "Westfir",
      "Westlake",
      "Winston",
      "Sunny valley",
      "Yachats",
      "Yoncalla",
      "Zcta 974hh",
      "Zcta 974xx",
      "West main",
      "Central point",
      "White city",
      "Medford",
      "Ashland",
      "Butte falls",
      "Cave junction",
      "Eagle point",
      "Gold hill",
      "Grants pass",
      "Applegate",
      "Kerby",
      "Merlin",
      "O brien",
      "Phoenix",
      "Prospect",
      "Rogue river",
      "Selma",
      "Shady cove",
      "Talent",
      "Trail",
      "Wilderville",
      "Williams",
      "Zcta 975hh",
      "Zcta 975xx",
      "Oretech",
      "Klamath falls",
      "Crater lake",
      "Adel",
      "Beatty",
      "Bly",
      "Bonanza",
      "Chiloquin",
      "Dairy",
      "Fort klamath",
      "Keno",
      "Lakeview",
      "Malin",
      "Merrill",
      "Midland",
      "New pine creek",
      "Paisley",
      "Plush",
      "Silver lake",
      "Sprague river",
      "Summer lake",
      "Christmas valley",
      "Zcta 976hh",
      "Zcta 976xx",
      "Bend",
      "Sunriver",
      "Fields",
      "Ashwood",
      "Brothers",
      "Burns",
      "Princeton",
      "Diamond",
      "Camp sherman",
      "Diamond lake",
      "Crane",
      "Crescent",
      "Culver",
      "Fort rock",
      "Frenchglen",
      "Gilchrist",
      "Hines",
      "La pine",
      "Madras",
      "Mitchell",
      "Paulina",
      "Post",
      "Powell butte",
      "Prineville",
      "Redmond",
      "Riley",
      "Black butte ranc",
      "Crooked river ra",
      "Warm springs",
      "Zcta 977hh",
      "Zcta 977xx",
      "Pendleton",
      "Adams",
      "Arlington",
      "Athena",
      "Medical springs",
      "Bates",
      "Boardman",
      "Bridgeport",
      "Canyon city",
      "Condon",
      "Cove",
      "Dayville",
      "Echo",
      "Elgin",
      "Enterprise",
      "Kinzua",
      "Haines",
      "Halfway",
      "Helix",
      "Heppner",
      "Hereford",
      "Hermiston",
      "Lexington",
      "Oxbow",
      "Imbler",
      "Imnaha",
      "Ione",
      "Irrigon",
      "John day",
      "Joseph",
      "Kimberly",
      "La grande",
      "Long creek",
      "Lostine",
      "Meacham",
      "Milton freewater",
      "Monument",
      "Mount vernon",
      "North powder",
      "Pilot rock",
      "Prairie city",
      "Richland",
      "Ritter",
      "Seneca",
      "Spray",
      "Stanfield",
      "Summerville",
      "Sumpter",
      "Dale",
      "Mcnary",
      "Union",
      "Unity",
      "Wallowa",
      "Weston",
      "Zcta 978hh",
      "Zcta 978xx",
      "Adrian",
      "Brogan",
      "Drewsey",
      "Durkee",
      "Harper",
      "Huntington",
      "Ironside",
      "Jamieson",
      "Jordan valley",
      "Juntura",
      "Nyssa",
      "Ontario",
      "Vale",
      "Westfall",
      "Zcta 979hh",
      "Zcta 979xx",
      "",
    ],
    ZipCodes: {
      Antelope: ["97001"],
      Aurora: ["97002"],
      Beavercreek: ["97004"],
      Beaverton: ["97005"],
      Aloha: ["97006", "97007"],
      "Zcta 97008": ["97008"],
      Boring: ["97009"],
      Brightwood: ["97011"],
      Canby: ["97013"],
      Bonneville: ["97014"],
      Clackamas: ["97015"],
      Westport: ["97016"],
      Colton: ["97017"],
      "Columbia city": ["97018"],
      Corbett: ["97019"],
      Donald: ["97020"],
      Friend: ["97021"],
      "Eagle creek": ["97022"],
      Estacada: ["97023"],
      Fairview: ["97024"],
      Gervais: ["97026"],
      Gladstone: ["97027"],
      "Timberline lodge": ["97028"],
      "Grass valley": ["97029"],
      Gresham: ["97030", "97080"],
      "Hood river": ["97031"],
      Hubbard: ["97032"],
      Kent: ["97033"],
      "Lake oswego": ["97034", "97035"],
      Maupin: ["97037"],
      Molalla: ["97038"],
      Moro: ["97039"],
      Mosier: ["97040"],
      "Mount hood parkd": ["97041"],
      Mulino: ["97042"],
      "Oregon city": ["97045"],
      Rainier: ["97048"],
      Zigzag: ["97049"],
      Rufus: ["97050"],
      "Saint helens": ["97051"],
      Warren: ["97053"],
      "Deer island": ["97054"],
      Sandy: ["97055"],
      Scappoose: ["97056"],
      "The dalles": ["97058"],
      Troutdale: ["97060"],
      Tualatin: ["97062"],
      Wamic: ["97063"],
      Vernonia: ["97064"],
      Wasco: ["97065"],
      Welches: ["97067"],
      "West linn": ["97068"],
      Wilsonville: ["97070"],
      Woodburn: ["97071"],
      "Zcta 970hh": ["970HH"],
      "Zcta 970xx": ["970XX"],
      Amity: ["97101"],
      "Arch cape": ["97102"],
      Astoria: ["97103"],
      Banks: ["97106"],
      "Bay city": ["97107"],
      Beaver: ["97108"],
      Buxton: ["97109"],
      "Cannon beach": ["97110"],
      Carlton: ["97111"],
      Cloverdale: ["97112"],
      Cornelius: ["97113"],
      Dayton: ["97114"],
      Dundee: ["97115"],
      Glenwood: ["97116"],
      "Gales creek": ["97117"],
      Garibaldi: ["97118"],
      Gaston: ["97119"],
      Hammond: ["97121"],
      Hebo: ["97122"],
      Hillsboro: ["97123", "97124"],
      Manning: ["97125"],
      Lafayette: ["97127"],
      Mcminnville: ["97128"],
      Manzanita: ["97130"],
      Nehalem: ["97131"],
      Newberg: ["97132"],
      "North plains": ["97133"],
      Oceanside: ["97134"],
      "Pacific city": ["97135"],
      Rockaway: ["97136"],
      "Saint paul": ["97137"],
      Gearhart: ["97138"],
      Sherwood: ["97140"],
      Tillamook: ["97141"],
      Netarts: ["97143"],
      Timber: ["97144"],
      "Tolovana park": ["97145"],
      Warrenton: ["97146"],
      Wheeler: ["97147"],
      Yamhill: ["97148"],
      Neskowin: ["97149"],
      "Zcta 971hh": ["971HH"],
      "Zcta 971xx": ["971XX"],
      Portland: [
        "97201",
        "97202",
        "97203",
        "97204",
        "97205",
        "97206",
        "97209",
        "97210",
        "97211",
        "97212",
        "97213",
        "97214",
        "97215",
        "97216",
        "97217",
        "97218",
        "97219",
        "97220",
        "97221",
        "97227",
        "97229",
        "97231",
        "97232",
        "97233",
        "97236",
        "97266",
      ],
      Milwaukie: ["97222"],
      "Garden home": ["97223"],
      Tigard: ["97224"],
      "Cedar hills": ["97225"],
      "Rockwood corners": ["97230"],
      "Oak grove": ["97267"],
      "Zcta 972hh": ["972HH"],
      Salem: ["97301", "97302", "97304", "97306"],
      Keizer: ["97303"],
      Brooks: ["97305"],
      Albany: ["97321"],
      Alsea: ["97324"],
      "West stayton": ["97325"],
      Blodgett: ["97326"],
      Brownsville: ["97327"],
      Cascadia: ["97329"],
      Corvallis: ["97330", "97331", "97333"],
      Dallas: ["97338"],
      "Depoe bay": ["97341"],
      Detroit: ["97342"],
      Eddyville: ["97343"],
      "Falls city": ["97344"],
      Foster: ["97345"],
      Gates: ["97346"],
      "Grand ronde": ["97347"],
      Halsey: ["97348"],
      Idanha: ["97350"],
      Independence: ["97351"],
      Jefferson: ["97352"],
      Lebanon: ["97355"],
      Logsden: ["97357"],
      Lyons: ["97358"],
      "Mill city": ["97360"],
      Monmouth: ["97361"],
      "Mount angel": ["97362"],
      Neotsu: ["97364"],
      Newport: ["97365"],
      "South beach": ["97366"],
      "Lincoln city": ["97367"],
      Otis: ["97368"],
      Philomath: ["97370"],
      Rickreall: ["97371"],
      Scio: ["97374"],
      "Scotts mills": ["97375"],
      "Seal rock": ["97376"],
      Shedd: ["97377"],
      Sheridan: ["97378"],
      Siletz: ["97380"],
      Silverton: ["97381"],
      Stayton: ["97383"],
      Sublimity: ["97385"],
      "Sweet home": ["97386"],
      Tangent: ["97389"],
      Tidewater: ["97390"],
      Toledo: ["97391"],
      Turner: ["97392"],
      Waldport: ["97394"],
      Willamina: ["97396"],
      "Zcta 973hh": ["973HH"],
      "Zcta 973xx": ["973XX"],
      Coburg: ["97401"],
      Eugene: ["97402", "97403", "97404", "97405"],
      Agness: ["97406"],
      "Zcta 97408": ["97408"],
      Azalea: ["97410"],
      Bandon: ["97411"],
      Blachly: ["97412"],
      "Mc kenzie bridge": ["97413"],
      Broadbent: ["97414"],
      Harbor: ["97415"],
      "Camas valley": ["97416"],
      Canyonville: ["97417"],
      Cheshire: ["97419"],
      Charleston: ["97420"],
      Coquille: ["97423"],
      "Cottage grove": ["97424"],
      "Cascade summit": ["97425"],
      Creswell: ["97426"],
      "Culp creek": ["97427"],
      Curtin: ["97428"],
      "Days creek": ["97429"],
      Greenleaf: ["97430"],
      Dexter: ["97431"],
      Dorena: ["97434"],
      Drain: ["97435"],
      Elkton: ["97436"],
      Elmira: ["97437"],
      "Fall creek": ["97438"],
      Florence: ["97439"],
      Gardiner: ["97441"],
      Glendale: ["97442"],
      Glide: ["97443"],
      "Pistol river": ["97444"],
      Harrisburg: ["97446"],
      "Idleyld park": ["97447"],
      "Junction city": ["97448"],
      Lakeside: ["97449"],
      Langlois: ["97450"],
      Lorane: ["97451"],
      Lowell: ["97452"],
      Mapleton: ["97453"],
      Marcola: ["97454"],
      "Pleasant hill": ["97455"],
      Monroe: ["97456"],
      "Myrtle creek": ["97457"],
      "Myrtle point": ["97458"],
      "North bend": ["97459"],
      Noti: ["97461"],
      Oakland: ["97462"],
      Oakridge: ["97463"],
      "Port orford": ["97465"],
      Powers: ["97466"],
      "Winchester bay": ["97467"],
      Riddle: ["97469"],
      Roseburg: ["97470"],
      Scottsburg: ["97473"],
      Sixes: ["97476"],
      Springfield: ["97477", "97478"],
      Sutherlin: ["97479"],
      Swisshome: ["97480"],
      Tenmile: ["97481"],
      Tiller: ["97484"],
      Umpqua: ["97486"],
      Veneta: ["97487"],
      Vida: ["97488"],
      Leaburg: ["97489"],
      Walton: ["97490"],
      Westfir: ["97492"],
      Westlake: ["97493"],
      Winston: ["97496"],
      "Sunny valley": ["97497"],
      Yachats: ["97498"],
      Yoncalla: ["97499"],
      "Zcta 974hh": ["974HH"],
      "Zcta 974xx": ["974XX"],
      "West main": ["97501"],
      "Central point": ["97502"],
      "White city": ["97503"],
      Medford: ["97504"],
      Ashland: ["97520"],
      "Butte falls": ["97522"],
      "Cave junction": ["97523"],
      "Eagle point": ["97524"],
      "Gold hill": ["97525"],
      "Grants pass": ["97526", "97527"],
      Applegate: ["97530"],
      Kerby: ["97531"],
      Merlin: ["97532"],
      "O brien": ["97534"],
      Phoenix: ["97535"],
      Prospect: ["97536"],
      "Rogue river": ["97537"],
      Selma: ["97538"],
      "Shady cove": ["97539"],
      Talent: ["97540"],
      Trail: ["97541"],
      Wilderville: ["97543"],
      Williams: ["97544"],
      "Zcta 975hh": ["975HH"],
      "Zcta 975xx": ["975XX"],
      Oretech: ["97601"],
      "Klamath falls": ["97603"],
      "Crater lake": ["97604"],
      Adel: ["97620"],
      Beatty: ["97621"],
      Bly: ["97622"],
      Bonanza: ["97623"],
      Chiloquin: ["97624"],
      Dairy: ["97625"],
      "Fort klamath": ["97626"],
      Keno: ["97627"],
      Lakeview: ["97630"],
      Malin: ["97632"],
      Merrill: ["97633"],
      Midland: ["97634"],
      "New pine creek": ["97635"],
      Paisley: ["97636"],
      Plush: ["97637"],
      "Silver lake": ["97638"],
      "Sprague river": ["97639"],
      "Summer lake": ["97640"],
      "Christmas valley": ["97641"],
      "Zcta 976hh": ["976HH"],
      "Zcta 976xx": ["976XX"],
      Bend: ["97701", "97702"],
      Sunriver: ["97707"],
      Fields: ["97710"],
      Ashwood: ["97711"],
      Brothers: ["97712"],
      Burns: ["97720"],
      Princeton: ["97721"],
      Diamond: ["97722"],
      "Camp sherman": ["97730"],
      "Diamond lake": ["97731"],
      Crane: ["97732"],
      Crescent: ["97733"],
      Culver: ["97734"],
      "Fort rock": ["97735"],
      Frenchglen: ["97736"],
      Gilchrist: ["97737"],
      Hines: ["97738"],
      "La pine": ["97739"],
      Madras: ["97741"],
      Mitchell: ["97750"],
      Paulina: ["97751"],
      Post: ["97752"],
      "Powell butte": ["97753"],
      Prineville: ["97754"],
      Redmond: ["97756"],
      Riley: ["97758"],
      "Black butte ranc": ["97759"],
      "Crooked river ra": ["97760"],
      "Warm springs": ["97761"],
      "Zcta 977hh": ["977HH"],
      "Zcta 977xx": ["977XX"],
      Pendleton: ["97801"],
      Adams: ["97810"],
      Arlington: ["97812"],
      Athena: ["97813"],
      "Medical springs": ["97814"],
      Bates: ["97817"],
      Boardman: ["97818"],
      Bridgeport: ["97819"],
      "Canyon city": ["97820"],
      Condon: ["97823"],
      Cove: ["97824"],
      Dayville: ["97825"],
      Echo: ["97826"],
      Elgin: ["97827"],
      Enterprise: ["97828"],
      Kinzua: ["97830"],
      Haines: ["97833"],
      Halfway: ["97834"],
      Helix: ["97835"],
      Heppner: ["97836"],
      Hereford: ["97837"],
      Hermiston: ["97838"],
      Lexington: ["97839"],
      Oxbow: ["97840"],
      Imbler: ["97841"],
      Imnaha: ["97842"],
      Ione: ["97843"],
      Irrigon: ["97844"],
      "John day": ["97845"],
      Joseph: ["97846"],
      Kimberly: ["97848"],
      "La grande": ["97850"],
      "Long creek": ["97856"],
      Lostine: ["97857"],
      Meacham: ["97859"],
      "Milton freewater": ["97862"],
      Monument: ["97864"],
      "Mount vernon": ["97865"],
      "North powder": ["97867"],
      "Pilot rock": ["97868"],
      "Prairie city": ["97869"],
      Richland: ["97870"],
      Ritter: ["97872"],
      Seneca: ["97873"],
      Spray: ["97874"],
      Stanfield: ["97875"],
      Summerville: ["97876"],
      Sumpter: ["97877"],
      Dale: ["97880"],
      Mcnary: ["97882"],
      Union: ["97883"],
      Unity: ["97884"],
      Wallowa: ["97885"],
      Weston: ["97886"],
      "Zcta 978hh": ["978HH"],
      "Zcta 978xx": ["978XX"],
      Adrian: ["97901"],
      Brogan: ["97903"],
      Drewsey: ["97904"],
      Durkee: ["97905"],
      Harper: ["97906"],
      Huntington: ["97907"],
      Ironside: ["97908"],
      Jamieson: ["97909"],
      "Jordan valley": ["97910"],
      Juntura: ["97911"],
      Nyssa: ["97913"],
      Ontario: ["97914"],
      Vale: ["97918"],
      Westfall: ["97920"],
      "Zcta 979hh": ["979HH"],
      "Zcta 979xx": ["979XX"],
      "": ["99362"],
    },
  },
  {
    abbrevation: "PA",
    name: "Pennsylvania",
    Cities: [
      "Macarthur",
      "Macarthur",
      "Fairoaks",
      "Atlasburg",
      "Baden",
      "Bairdford",
      "Bakerstown",
      "Beaver",
      "Racine",
      "Rostraver",
      "Brackenridge",
      "Bradfordwoods",
      "Bridgeville",
      "Buena vista",
      "Bulger",
      "Bunola",
      "Paris",
      "Charleroi",
      "Cheswick",
      "Large",
      "Clinton",
      "Conway",
      "Coulters",
      "Creighton",
      "Cuddy",
      "Donora",
      "Dravosburg",
      "East mc keesport",
      "Elizabeth",
      "Elrama",
      "Freedom",
      "Georgetown",
      "Gibsonia",
      "Glassport",
      "Glenwillard",
      "Harwick",
      "Hookstown",
      "Indianola",
      "Industry",
      "Joffre",
      "Langeloth",
      "Lawrence",
      "Leetsdale",
      "Mc donald",
      "Midland",
      "Midway",
      "Monaca",
      "Monessen",
      "Monongahela",
      "Morgan",
      "Natrona",
      "New brighton",
      "New eagle",
      "Arnold",
      "Noblestown",
      "Pricedale",
      "Rochester",
      "Rural ridge",
      "Russellton",
      "Slovan",
      "South heights",
      "Sturgeon",
      "Sutersville",
      "Tarentum",
      "Level green",
      "Warrendale",
      "Webster",
      "West elizabeth",
      "West newton",
      "Wexford",
      "Zcta 150hh",
      "Allison park",
      "Bethel park",
      "Rankin",
      "Carnegie",
      "Moon twp",
      "Duquesne",
      "East pittsburgh",
      "Glenshaw",
      "Munhall",
      "W mifflin fin",
      "Imperial",
      "Library",
      "White oak",
      "Mc keesport",
      "Boston",
      "Mc kees rocks",
      "North versailles",
      "Oakmont",
      "Pitcairn",
      "Presto",
      "Sewickley",
      "Springdale",
      "Turtle creek",
      "Monroeville",
      "Verona",
      "Wall",
      "Zcta 151hh",
      "Arsenal",
      "Bellevue",
      "Carson",
      "Corliss",
      "Crafton",
      "East liberty",
      "Hazelwood",
      "Homewood",
      "Millvale",
      "Mount oliver",
      "Mount washington",
      "Allegheny",
      "Oakland",
      "Observatory",
      "Aspinwall",
      "South hills",
      "Squirrel hill",
      "Swissvale",
      "Uptown",
      "Parkway center",
      "Wilkinsburg",
      "Downtown",
      "Etna",
      "Bloomfield",
      "Neville island",
      "Brookline",
      "Brentwood",
      "Mount lebanon",
      "West view",
      "Shadyside",
      "Kilbuck",
      "Castle shannon",
      "Penn hills",
      "Caste village",
      "Mc knight",
      "Blawnox",
      "Plum",
      "Upper saint clai",
      "Cedarhurst",
      "Zcta 152hh",
      "Washington",
      "Aleppo",
      "Amity",
      "Avella",
      "Beallsville",
      "Bentleyville",
      "Bobtown",
      "Brave",
      "Mc murray",
      "Carmichaels",
      "Cecil",
      "Clarksville",
      "Claysville",
      "Cokeburg",
      "Crucible",
      "Dilliner",
      "Prosperity",
      "Eighty four",
      "Ellsworth",
      "Finleyville",
      "Fredericktown",
      "Garards fort",
      "Graysville",
      "Greensboro",
      "Hickory",
      "Holbrook",
      "Houston",
      "Jefferson",
      "Marianna",
      "Mather",
      "Meadow lands",
      "Millsboro",
      "Davistown",
      "Muse",
      "Nemacolin",
      "New freeport",
      "Nineveh",
      "Rices landing",
      "Richeyville",
      "Rogersville",
      "Scenery hill",
      "Southview",
      "Spraggs",
      "Strabane",
      "Sycamore",
      "Venetia",
      "Vestaburg",
      "Waynesburg",
      "West alexander",
      "West finley",
      "Westland",
      "West middletown",
      "Wind ridge",
      "Zcta 153hh",
      "Uniontown",
      "Adah",
      "Addison",
      "Allenport",
      "Allison",
      "West brownsville",
      "California",
      "Cardale",
      "Chestnut ridge",
      "Coal center",
      "Listonburg",
      "South connellsvi",
      "Daisytown",
      "Dawson",
      "Denbo",
      "Dickerson run",
      "Dunbar",
      "Dunlevy",
      "East millsboro",
      "Elco",
      "Fairchance",
      "Farmington",
      "Fayette city",
      "Gibbon glade",
      "Grindstone",
      "Hibbs",
      "Hiller",
      "Hopwood",
      "Indian head",
      "Isabella",
      "Jacobs creek",
      "La belle",
      "Lake lynn",
      "Leckrone",
      "Leisenring",
      "Lemont furnace",
      "Lamberton",
      "Markleysburg",
      "Martin",
      "Grays landing",
      "Melcroft",
      "Mill run",
      "Newell",
      "New salem",
      "Normalville",
      "Ohiopyle",
      "Oliver",
      "Layton",
      "Point marion",
      "Republic",
      "Ronco",
      "Roscoe",
      "Smithfield",
      "Van meter",
      "Smock",
      "Star junction",
      "Stockdale",
      "Uledi",
      "Ursina",
      "Vanderbilt",
      "West leisenring",
      "White",
      "Wickhaven",
      "Zcta 154hh",
      "Somerset",
      "Acosta",
      "Alum bank",
      "Bedford",
      "Berlin",
      "Boswell",
      "Boynton",
      "Breezewood",
      "Buffalo mills",
      "Clearville",
      "Crystal spring",
      "Everett",
      "Glencoe",
      "Fishertown",
      "Fort hill",
      "Friedens",
      "Garrett",
      "Gray",
      "Hyndman",
      "Jenners",
      "Jennerstown",
      "Manns choice",
      "Markleton",
      "Meyersdale",
      "New baltimore",
      "New paris",
      "Rockwood",
      "Salisbury",
      "Schellsburg",
      "Shanksville",
      "Springs",
      "Stoystown",
      "Zcta 155hh",
      "Greensburg",
      "Acme",
      "Adamsburg",
      "Alverton",
      "Apollo",
      "Ardara",
      "Arona",
      "Avonmore",
      "Bradenville",
      "Champion",
      "Claridge",
      "Crabtree",
      "Darragh",
      "Delmont",
      "Derry",
      "Donegal",
      "East vandergrift",
      "Everson",
      "Export",
      "Forbes road",
      "Grapeville",
      "Hannastown",
      "Harrison city",
      "Herminie",
      "Hunker",
      "Hutchinson",
      "Hyde park",
      "North huntingdon",
      "Jeannette",
      "Jones mills",
      "Larimer",
      "Latrobe",
      "Laughlintown",
      "Leechburg",
      "Wilpen",
      "Lowber",
      "Loyalhanna",
      "Luxor",
      "Madison",
      "Manor",
      "Mount pleasant",
      "Murrysville",
      "New alexandria",
      "New derry",
      "New stanton",
      "North apollo",
      "Norvelt",
      "Penn",
      "Pleasant unity",
      "Rector",
      "Rillton",
      "Ruffs dale",
      "Salina",
      "Saltsburg",
      "Scottdale",
      "Slickville",
      "Southwest",
      "Spring church",
      "Stahlstown",
      "Tarrs",
      "United",
      "Park",
      "Westmoreland cit",
      "Whitney",
      "Wyano",
      "Youngstown",
      "Youngwood",
      "Yukon",
      "Zcta 156hh",
      "Indiana",
      "Alverda",
      "Anita",
      "Arcadia",
      "Aultman",
      "Barnesboro",
      "Big run",
      "Black lick",
      "Blairsville",
      "Burnside",
      "Carrolltown",
      "Chambersville",
      "Cherry tree",
      "Clarksburg",
      "Clymer",
      "Commodore",
      "Coolspring",
      "Coral",
      "Creekside",
      "De lancey",
      "Dixonville",
      "Elderton",
      "Ernest",
      "Gipsy",
      "Glen campbell",
      "Hamilton",
      "Heilwood",
      "Home",
      "Graceton",
      "Kent",
      "La jose",
      "Lucernemines",
      "Mc intyre",
      "Mc gees mills",
      "Marion center",
      "Marsteller",
      "Mentcle",
      "Nicktown",
      "Northpoint",
      "Oliveburg",
      "Penn run",
      "Punxsutawney",
      "Ringgold",
      "Rochester mills",
      "Rossiter",
      "Saint benedict",
      "Shelocta",
      "Spangler",
      "Sprankle mills",
      "Starford",
      "Timblin",
      "Torrance",
      "Valier",
      "Walston",
      "West lebanon",
      "Worthville",
      "Zcta 157hh",
      "Du bois",
      "Benezett",
      "Brockport",
      "Brockway",
      "Hazen",
      "Byrnedale",
      "Clarington",
      "Corsica",
      "Driftwood",
      "Emporium",
      "Falls creek",
      "Force",
      "Johnsonburg",
      "Kersey",
      "Knox dale",
      "Luthersburg",
      "Penfield",
      "Reynoldsville",
      "Portland mills",
      "Rockton",
      "Saint marys",
      "Sigel",
      "Sinnamahoning",
      "Stump creek",
      "Summerville",
      "Sykesville",
      "Troutville",
      "Weedville",
      "Wilcox",
      "Zcta 158hh",
      "Zcta 158xx",
      "Johnstown",
      "Armagh",
      "Bolivar",
      "Cairnbrook",
      "Cassandra",
      "Central city",
      "Colver",
      "Davidsville",
      "Dilltown",
      "Dunlo",
      "Ebensburg",
      "Elton",
      "Hollsopple",
      "Hooversville",
      "Jerome",
      "Lilly",
      "Loretto",
      "Mineral point",
      "Nanty glo",
      "New florence",
      "Puritan",
      "Revloc",
      "Robinson",
      "Salix",
      "Seward",
      "Sidman",
      "South fork",
      "Strongstown",
      "Summerhill",
      "Vintondale",
      "Windber",
      "Zcta 159hh",
      "Bon aire",
      "Zcta 16002",
      "Boyers",
      "Bruin",
      "Marwood",
      "Callery",
      "Chicora",
      "Connoquenessing",
      "East brady",
      "East butler",
      "Eau claire",
      "Evans city",
      "Fenelton",
      "Forestville",
      "Foxburg",
      "Harmony",
      "Harrisville",
      "Hilliards",
      "Karns city",
      "Lyndora",
      "Mars",
      "Parker",
      "Petrolia",
      "Portersville",
      "Prospect",
      "Renfrew",
      "Saint petersburg",
      "Sarver",
      "Saxonburg",
      "Slippery rock",
      "Valencia",
      "West sunbury",
      "Zelienople",
      "Zcta 160hh",
      "New castle",
      "Neshannock",
      "Adamsville",
      "Atlantic",
      "Bessemer",
      "Clark",
      "Clarks mills",
      "Darlington",
      "Edinburg",
      "Ellport",
      "Enon valley",
      "Farrell",
      "Fombell",
      "Fredonia",
      "Shenango",
      "Grove city",
      "Hadley",
      "Hartstown",
      "Hillsville",
      "Jackson center",
      "Westford",
      "Koppel",
      "Mercer",
      "New bedford",
      "New galilee",
      "New wilmington",
      "Pulaski",
      "Sandy lake",
      "Sharon",
      "Hermitage",
      "Sharpsville",
      "Sheakleyville",
      "Stoneboro",
      "Transfer",
      "Villa maria",
      "Volant",
      "Wampum",
      "West middlesex",
      "West pittsburg",
      "Wheatland",
      "Zcta 161hh",
      "Kittanning",
      "Adrian",
      "Cadogan",
      "Callensburg",
      "Clarion",
      "Cooksburg",
      "Cowansville",
      "Dayton",
      "Distant",
      "Fairmount city",
      "Ford city",
      "Ford cliff",
      "Freeport",
      "Hawthorn",
      "Knox",
      "Leeper",
      "Limestone",
      "Lucinda",
      "Mc grann",
      "Manorville",
      "Marienville",
      "Mayport",
      "New bethlehem",
      "Nu mine",
      "Oak ridge",
      "Plumville",
      "Huey",
      "Rural valley",
      "Sagamore",
      "Seminole",
      "Shippenville",
      "Sligo",
      "Smicksburg",
      "Strattanville",
      "Templeton",
      "Vowinckel",
      "Craigsville",
      "Yatesboro",
      "Zcta 162hh",
      "Zcta 162xx",
      "Oil city",
      "Carlton",
      "Clarendon",
      "Cochranton",
      "Conneaut lake",
      "Cooperstown",
      "Cranberry",
      "East hickory",
      "Endeavor",
      "Franklin",
      "Fryburg",
      "Guys mills",
      "Irvine",
      "Kossuth",
      "Lickingville",
      "Ludlow",
      "Marble",
      "Meadville",
      "Pittsfield",
      "Pleasantville",
      "Polk",
      "Reno",
      "Rouseville",
      "Russell",
      "Seneca",
      "Sheffield",
      "Sugar grove",
      "Tidioute",
      "Tiona",
      "Tionesta",
      "Titusville",
      "Townville",
      "Tylersburg",
      "Utica",
      "Venus",
      "North warren",
      "West hickory",
      "Youngsville",
      "Clintonville",
      "Emlenton",
      "Kennerdell",
      "Zcta 163hh",
      "Zcta 163xx",
      "Lundys lane",
      "Bear lake",
      "Cambridge spring",
      "Centerville",
      "Columbus",
      "Conneautville",
      "Corry",
      "Cranesville",
      "East springfield",
      "Edinboro",
      "Fairview",
      "Garland",
      "Girard",
      "Grand valley",
      "Harborcreek",
      "Harmonsburg",
      "Lake city",
      "Espyville",
      "Mc kean",
      "Mill village",
      "North east",
      "Saegertown",
      "Spartansburg",
      "Springboro",
      "Spring creek",
      "Union city",
      "Venango",
      "Waterford",
      "Wattsburg",
      "West springfield",
      "Zcta 164hh",
      "Erie",
      "Presque isle",
      "Wesleyville",
      "Zcta 165hh",
      "Altoona",
      "Barree",
      "Ashville",
      "Beccaria",
      "Bellwood",
      "Blandburg",
      "Brisbin",
      "Broad top",
      "Calvin",
      "Cassville",
      "Chest springs",
      "Claysburg",
      "Coalport",
      "Cresson",
      "Curryville",
      "Defiance",
      "Dudley",
      "Duncansville",
      "Dysart",
      "East freedom",
      "Entriken",
      "Fallentimber",
      "Flinton",
      "Gallitzin",
      "Glen hope",
      "Hastings",
      "Hesston",
      "Hollidaysburg",
      "Hopewell",
      "Houtzdale",
      "Huntingdon",
      "Imler",
      "Irvona",
      "James creek",
      "Loysburg",
      "Madera",
      "Martinsburg",
      "Morann",
      "New enterprise",
      "Newry",
      "Osceola mills",
      "St clairsville",
      "Patton",
      "Petersburg",
      "Queen",
      "Ramey",
      "Riddlesburg",
      "Roaring spring",
      "Robertsdale",
      "Sandy ridge",
      "Saxton",
      "Six mile run",
      "Smithmill",
      "Smokerun",
      "Sproul",
      "Spruce creek",
      "Todd",
      "Tyrone",
      "Waterfall",
      "Wells tannery",
      "Westover",
      "Ganister",
      "Wood",
      "Woodbury",
      "Zcta 166hh",
      "Zcta 166xx",
      "Bradford",
      "Austin",
      "Crosby",
      "Custer city",
      "Ormsby",
      "Derrick city",
      "Duke center",
      "East smethport",
      "Eldred",
      "Gifford",
      "Hazel hurst",
      "James city",
      "Kane",
      "Lewis run",
      "Mount jewett",
      "Port allegany",
      "Rew",
      "Rixford",
      "Roulette",
      "Shinglehouse",
      "Smethport",
      "Turtlepoint",
      "Westline",
      "Zcta 167xx",
      "State college",
      "University park",
      "Aaronsburg",
      "Beech creek",
      "Pleasant gap",
      "Bigler",
      "Blanchard",
      "Boalsburg",
      "Centre hall",
      "Clarence",
      "Clearfield",
      "Coburn",
      "Curwensville",
      "Drifting",
      "Frenchville",
      "Glen richey",
      "Grampian",
      "Grassflat",
      "Hawk run",
      "Howard",
      "Hyde",
      "Julian",
      "Karthaus",
      "Kylertown",
      "Lamar",
      "Lanse",
      "Lemont",
      "Madisonburg",
      "Milesburg",
      "Millheim",
      "Mineral springs",
      "Mingoville",
      "Morrisdale",
      "Moshannon",
      "Munson",
      "New millport",
      "Olanta",
      "Orviston",
      "Pennsylvania fur",
      "Philipsburg",
      "Port matilda",
      "Pottersdale",
      "Rebersburg",
      "Snow shoe",
      "Spring mills",
      "Wallaceton",
      "Warriors mark",
      "West decatur",
      "Winburne",
      "Woodland",
      "Woodward",
      "Zcta 168hh",
      "Zcta 168xx",
      "Wellsboro",
      "Arnot",
      "Blossburg",
      "Columbia cross r",
      "Oswayo",
      "Covington",
      "Cowanesque",
      "Elkland",
      "Gaines",
      "Galeton",
      "North bingham",
      "Gillett",
      "Granville summit",
      "Harrison valley",
      "Knoxville",
      "Lawrenceville",
      "Liberty",
      "Mainesburg",
      "Mansfield",
      "Middlebury cente",
      "Millerton",
      "Mills",
      "Morris",
      "Morris run",
      "Nelson",
      "Genesee",
      "Osceola",
      "Sabinsville",
      "Tioga",
      "Troy",
      "Ulysses",
      "Little marsh",
      "Zcta 169hh",
      "Zcta 169xx",
      "Allensville",
      "Annville",
      "Belleville",
      "Berrysburg",
      "Blain",
      "Boiling springs",
      "Burnham",
      "Campbelltown",
      "Shiremanstown",
      "Carlisle barrack",
      "Cornwall",
      "Dalmatia",
      "Dauphin",
      "Dillsburg",
      "Duncannon",
      "East waterford",
      "Elizabethtown",
      "Elizabethville",
      "Elliottsburg",
      "Enola",
      "Fredericksburg",
      "Messiah college",
      "Grantville",
      "Granville",
      "Halifax",
      "Hershey",
      "Highspire",
      "Honey grove",
      "Hummelstown",
      "Ickesburg",
      "Jonestown",
      "Landisburg",
      "Lawn",
      "Cleona",
      "Wormleysburg",
      "Lewistown",
      "Liverpool",
      "Lebanon",
      "Loysville",
      "Lykens",
      "Mc alisterville",
      "Mc veytown",
      "Mapleton depot",
      "Marysville",
      "Hampden",
      "Middletown",
      "Mifflin",
      "Mifflintown",
      "Mill creek",
      "Millersburg",
      "Millerstown",
      "Milroy",
      "Mount gretna",
      "Mount holly spri",
      "Mount union",
      "Myerstown",
      "New bloomfield",
      "New buffalo",
      "New cumberland",
      "New kingstown",
      "Newmanstown",
      "Newport",
      "Newton hamilton",
      "Oakland mills",
      "Ono",
      "Palmyra",
      "Pillow",
      "Plainfield",
      "Port royal",
      "Quentin",
      "Reedsville",
      "Richfield",
      "Richland",
      "Schaefferstown",
      "Shermans dale",
      "Summerdale",
      "Thompsontown",
      "Wiconisco",
      "Williamstown",
      "Yeagertown",
      "Zcta 170hh",
      "Zcta 170xx",
      "Harrisburg",
      "Penbrook",
      "Colonial park",
      "Swatara",
      "Steelton",
      "Zcta 171hh",
      "Chambersburg",
      "Artemas",
      "Big cove tannery",
      "Blairs mills",
      "Blue ridge summi",
      "Burnt cabins",
      "Doylesburg",
      "Dry run",
      "Fannettsburg",
      "Fayetteville",
      "Fort littleton",
      "Fort loudon",
      "Greencastle",
      "Harrisonville",
      "Hustontown",
      "Mc connellsburg",
      "Marion",
      "Mercersburg",
      "Mont alto",
      "Needmore",
      "Neelyton",
      "Newburg",
      "Newville",
      "Orbisonia",
      "Orrstown",
      "Rockhill furnace",
      "Saint thomas",
      "Saltillo",
      "Scotland",
      "Shade gap",
      "Shippensburg",
      "Shirleysburg",
      "Spring run",
      "Three springs",
      "Upperstrasburg",
      "Walnut bottom",
      "Warfordsburg",
      "Waynesboro",
      "Williamson",
      "Willow hill",
      "Abbottstown",
      "Airville",
      "Aspers",
      "Bendersville",
      "Biglerville",
      "Brogue",
      "Codorus",
      "Yoe",
      "Delta",
      "Dover",
      "East berlin",
      "East prospect",
      "Emigsville",
      "Etters",
      "Greenstone",
      "Fawn grove",
      "Felton",
      "Gardners",
      "Gettysburg",
      "Glen rock",
      "Brodbecks",
      "Hanover",
      "Lewisberry",
      "Littlestown",
      "Mc knightstown",
      "Mc sherrystown",
      "Manchester",
      "Mount wolf",
      "New freedom",
      "New oxford",
      "New park",
      "Orrtanna",
      "Railroad",
      "Red lion",
      "Seven valleys",
      "Shrewsbury",
      "Spring grove",
      "Stewartstown",
      "Thomasville",
      "Wellsville",
      "Windsor",
      "Wrightsville",
      "York haven",
      "York springs",
      "Zcta 173hh",
      "York",
      "East york",
      "West york",
      "Hellam",
      "Jacobus",
      "Zcta 174hh",
      "Akron",
      "Bainbridge",
      "Bird in hand",
      "Brownstown",
      "Ninepoints",
      "Columbia",
      "Conestoga",
      "Denver",
      "Drumore",
      "East earl",
      "East petersburg",
      "Ephrata",
      "Gap",
      "Gordonville",
      "Holtwood",
      "Kinzers",
      "Kirkwood",
      "Salunga",
      "Leola",
      "Brunnerville",
      "Manheim",
      "Marietta",
      "Millersville",
      "Florin",
      "Mountville",
      "Narvon",
      "New holland",
      "New providence",
      "Paradise",
      "Peach bottom",
      "Pequea",
      "Quarryville",
      "Reinholds",
      "Rheems",
      "Ronks",
      "Smoketown",
      "Stevens",
      "Strasburg",
      "Terre hill",
      "Washington boro",
      "Willow street",
      "Zcta 175hh",
      "Neffsville",
      "Lancaster",
      "Rohrerstown",
      "Zcta 176hh",
      "South williamspo",
      "Zcta 17702",
      "Avis",
      "Canton",
      "Cogan station",
      "Cross fork",
      "Dewart",
      "Eagles mere",
      "Hughesville",
      "Hyner",
      "Jersey mills",
      "Salladasburg",
      "Lairdsville",
      "Linden",
      "Lock haven",
      "Loganton",
      "Mc elhattan",
      "Mc ewensville",
      "Mackeyville",
      "Mill hall",
      "Montgomery",
      "Montoursville",
      "Muncy",
      "Muncy valley",
      "North bend",
      "Picture rocks",
      "Ralston",
      "Renovo",
      "Roaring branch",
      "Salona",
      "Shunk",
      "Trout run",
      "Turbotville",
      "Unityville",
      "Waterville",
      "Watsontown",
      "Westport",
      "Zcta 177hh",
      "Zcta 177xx",
      "Sunbury",
      "Allenwood",
      "Beaver springs",
      "Beavertown",
      "Benton",
      "Bloomsburg",
      "Catawissa",
      "Danville",
      "Dornsife",
      "Elysburg",
      "Excelsior",
      "Freeburg",
      "Hartleton",
      "Herndon",
      "Marion heights",
      "Kreamer",
      "Kulpmont",
      "Laurelton",
      "Leck kill",
      "Lewisburg",
      "Locust gap",
      "Mc clure",
      "Middleburg",
      "Mifflinburg",
      "Millmont",
      "Millville",
      "Milton",
      "Montandon",
      "Mount carmel",
      "Mount pleasant m",
      "New berlin",
      "New columbia",
      "Northumberland",
      "Orangeville",
      "Paxinos",
      "Paxtonville",
      "Penns creek",
      "Port trevorton",
      "Potts grove",
      "Ranshaw",
      "Rebuck",
      "Riverside",
      "Selinsgrove",
      "Stillwater",
      "Trevorton",
      "Troxelville",
      "Washingtonville",
      "Weikert",
      "West milton",
      "White deer",
      "Wilburton",
      "Winfield",
      "Zcta 178hh",
      "Zcta 178xx",
      "Pottsville",
      "Aristes",
      "Ashland",
      "Auburn",
      "Branchdale",
      "Brockton",
      "Centralia",
      "Cressona",
      "Cumbola",
      "Frackville",
      "Friedensburg",
      "Gilberton",
      "Girardville",
      "Gordon",
      "Hegins",
      "Klingerstown",
      "Lavelle",
      "Llewellyn",
      "Locustdale",
      "Lost creek",
      "Mahanoy city",
      "Mahanoy plane",
      "Mar lin",
      "Mary d",
      "Middleport",
      "Minersville",
      "Muir",
      "Kaska",
      "New ringgold",
      "Orwigsburg",
      "Pine grove",
      "Pitman",
      "Port carbon",
      "Ringtown",
      "Sacramento",
      "Saint clair",
      "Schuylkill haven",
      "Seltzer",
      "Shenandoah",
      "Spring glen",
      "Summit station",
      "Tower city",
      "Donaldson",
      "Tuscarora",
      "Valley view",
      "Zion grove",
      "Zcta 179hh",
      "Alburtis",
      "Aquashicola",
      "Roseto",
      "Bath",
      "Bethlehem",
      "Butztown",
      "Zcta 18020",
      "Bowmanstown",
      "Breinigsville",
      "Catasauqua",
      "Center valley",
      "Cherryville",
      "Coopersburg",
      "Coplay",
      "Danielsville",
      "Zcta 18040",
      "East greenville",
      "Forks township",
      "Zcta 18045",
      "Emmaus",
      "Fogelsville",
      "Hokendauqua",
      "Germansville",
      "Green lane",
      "Hellertown",
      "Hereford",
      "Kunkletown",
      "Laurys station",
      "Macungie",
      "Martins creek",
      "Nazareth",
      "New tripoli",
      "Northampton",
      "Orefield",
      "Palm",
      "Palmerton",
      "Pen argyl",
      "Pennsburg",
      "Perkiomenville",
      "Red hill",
      "Riegelsville",
      "Schnecksville",
      "Slatedale",
      "Emerald",
      "Springtown",
      "Stockertown",
      "Tatamy",
      "Treichlers",
      "Trexlertown",
      "Walnutport",
      "Wind gap",
      "Zionsville",
      "Zcta 180hh",
      "Allentown",
      "Wescosville",
      "Zcta 181hh",
      "West hazleton",
      "Albrightsville",
      "Andreas",
      "Barnesville",
      "Beaver meadows",
      "Coaldale",
      "Conyngham",
      "Delano",
      "Drifton",
      "Drums",
      "Freeland",
      "Harleigh",
      "Jim thorpe",
      "Junedale",
      "Kelayres",
      "Lansford",
      "Lattimer mines",
      "Weissport",
      "Mcadoo",
      "Milnesville",
      "Nesquehoning",
      "Fern glen",
      "Oneida",
      "Parryville",
      "Quakake",
      "Rock glen",
      "Sheppton",
      "Sugarloaf",
      "Summit hill",
      "Sybertsville",
      "Tamaqua",
      "Tresckow",
      "Weatherly",
      "Weston",
      "Zcta 182hh",
      "East stroudsburg",
      "Bartonsville",
      "Brodheadsville",
      "Buck hill falls",
      "Bushkill",
      "Canadensis",
      "Cresco",
      "Delaware water g",
      "Dingmans ferry",
      "Effort",
      "Gilbert",
      "Henryville",
      "Kresgeville",
      "Long pond",
      "Matamoras",
      "Milford",
      "Millrift",
      "Minisink hills",
      "Mount bethel",
      "Mount pocono",
      "Pocono summit",
      "Pocono lake",
      "Pocono manor",
      "Pocono pines",
      "Portland",
      "Saylorsburg",
      "Sciota",
      "Scotrun",
      "Shawnee on delaw",
      "Skytop",
      "Stroudsburg",
      "Swiftwater",
      "Tannersville",
      "Zcta 183hh",
      "Eynon",
      "Beach lake",
      "Simpson",
      "Clarks summit",
      "Clifford",
      "Dalton",
      "Damascus",
      "Equinunk",
      "Factoryville",
      "Fleetville",
      "Browndale",
      "Gouldsboro",
      "Greeley",
      "Greentown",
      "Hawley",
      "Herrick center",
      "Honesdale",
      "Mayfield",
      "Jessup",
      "Lackawaxen",
      "Lake ariel",
      "Lake como",
      "Lakeville",
      "Lakewood",
      "Lenoxville",
      "Milanville",
      "Moscow",
      "Newfoundland",
      "Nicholson",
      "Olyphant",
      "Paupack",
      "Peckville",
      "Pleasant mount",
      "Preston park",
      "Prompton",
      "Rowland",
      "Shohola",
      "South canaan",
      "South sterling",
      "Starlight",
      "Starrucca",
      "Tafton",
      "Thompson",
      "Tobyhanna",
      "Tyler hill",
      "Union dale",
      "Waverly",
      "Waymart",
      "White mills",
      "Zcta 184hh",
      "Zcta 184xx",
      "Scranton",
      "Moosic",
      "Dunmore",
      "Taylor",
      "Old forge",
      "Dickson city",
      "Beach haven",
      "Bear creek",
      "Berwick",
      "Blakeslee",
      "Cambra",
      "College miserico",
      "Dushore",
      "Falls",
      "Forksville",
      "Glen lyon",
      "Harveys lake",
      "Hillsgrove",
      "Hunlock creek",
      "Huntington mills",
      "Laceyville",
      "Lake harmony",
      "Lake winola",
      "Laporte",
      "Lopez",
      "Mehoopany",
      "Meshoppen",
      "Mifflinville",
      "Mildred",
      "Nanticoke",
      "Nescopeck",
      "Noxen",
      "Pittston",
      "Avoca",
      "Duryea",
      "West pittston",
      "Wyoming",
      "Plymouth",
      "Ransom",
      "Mocanaqua",
      "Sweet valley",
      "Center moreland",
      "Wapwallopen",
      "White haven",
      "Zcta 186hh",
      "Zcta 186xx",
      "Wilkes barre",
      "Hanover township",
      "Kingston",
      "Ashley",
      "Mountain top",
      "Shavertown",
      "Luzerne",
      "Montrose",
      "Athens",
      "Brackney",
      "Brooklyn",
      "Burlington",
      "Dimock",
      "East smithfield",
      "Friendsville",
      "Gibson",
      "Great bend",
      "Hallstead",
      "Harford",
      "Hop bottom",
      "Jackson",
      "Kingsley",
      "Lanesboro",
      "Lawton",
      "Le raysville",
      "Little meadows",
      "Milan",
      "Monroeton",
      "New albany",
      "New milford",
      "Rome",
      "Rushville",
      "Sayre",
      "South gibson",
      "Springville",
      "Stevensville",
      "Sugar run",
      "Susquehanna",
      "Towanda",
      "Ulster",
      "Warren center",
      "Wyalusing",
      "Wysox",
      "Zcta 188hh",
      "New britain",
      "Chalfont",
      "Colmar",
      "Dublin",
      "Erwinna",
      "Fountainville",
      "Furlong",
      "Hilltown",
      "Jamison",
      "Kintnersville",
      "Line lexington",
      "Montgomeryville",
      "New hope",
      "George school",
      "Ottsville",
      "Perkasie",
      "Pipersville",
      "Quakertown",
      "Richboro",
      "Richlandtown",
      "Sellersville",
      "Silverdale",
      "Bethton",
      "Holland",
      "Telford",
      "Trumbauersville",
      "Upper black eddy",
      "Warminster",
      "Warrington",
      "Washington cross",
      "Wycombe",
      "Zcta 189hh",
      "Ogontz campus",
      "Maple glen",
      "Ardmore",
      "Bala cynwyd",
      "Huntingdon valle",
      "Tullytown",
      "Broomall",
      "Bryn athyn",
      "Bryn mawr",
      "Cheltenham",
      "Chester",
      "Aston",
      "Brookhaven",
      "Primos secane",
      "Bensalem",
      "Croydon",
      "Crum lynne",
      "Collingdale",
      "Dresher",
      "Pilgrim gardens",
      "Zcta 19027",
      "Lester",
      "Fairless hills",
      "Flourtown",
      "Folcroft",
      "Folsom",
      "Fort washington",
      "Gladwyne",
      "Glenolden",
      "Glenside",
      "Hatboro",
      "Haverford",
      "Holmes",
      "Horsham",
      "Meadowbrook",
      "Penndel",
      "Yeadon",
      "Feasterville tre",
      "Levittown",
      "Boothwyn",
      "Glen riddle lima",
      "Springfield",
      "Merion station",
      "Yardley",
      "Morton",
      "Narberth",
      "Newtown square",
      "Norwood",
      "Oreland",
      "Prospect park",
      "Ridley park",
      "Sharon hill",
      "Swarthmore",
      "Upper darby",
      "Havertown",
      "Villanova",
      "Wallingford",
      "Radnor",
      "Willow grove nas",
      "Woodlyn",
      "Wyncote",
      "Wynnewood",
      "Zcta 190hh",
      "Philadelphia",
      "Zcta 191hh",
      "Paoli",
      "Atglen",
      "Avondale",
      "Berwyn",
      "Brandamore",
      "Chadds ford",
      "Cheyney",
      "Coatesville",
      "Cochranville",
      "Devon",
      "Downingtown",
      "Exton",
      "Glen mills",
      "Glenmoore",
      "Honey brook",
      "Immaculata",
      "Kennett square",
      "Landenberg",
      "Lincoln universi",
      "Frazer",
      "Modena",
      "Nottingham",
      "Oxford",
      "Parkesburg",
      "Pomeroy",
      "Sadsburyville",
      "Thorndale",
      "Thornton",
      "Toughkenamon",
      "West chester",
      "West grove",
      "Zcta 193hh",
      "Norristown",
      "Eagleville",
      "Bridgeport",
      "King of prussia",
      "Birchrunville",
      "Penllyn",
      "Chester springs",
      "Collegeville",
      "West conshohocke",
      "Frederick",
      "Gwynedd",
      "Gwynedd valley",
      "Harleysville",
      "Hatfield",
      "Lafayette hill",
      "Lansdale",
      "Mont clare",
      "North wales",
      "Parker ford",
      "Phoenixville",
      "Plymouth meeting",
      "Sanatoga",
      "Pottstown",
      "Limerick",
      "Sassamansville",
      "Schwenksville",
      "Skippack",
      "Spring city",
      "Spring mount",
      "Zieglersville",
      "Zcta 194hh",
      "Adamstown",
      "Bally",
      "Barto",
      "Bechtelsville",
      "Bernville",
      "Bethel",
      "Birdsboro",
      "Blandon",
      "Bowers",
      "Boyertown",
      "Centerport",
      "Douglassville",
      "Earlville",
      "Elverson",
      "Evansville",
      "Geigertown",
      "Gilbertsville",
      "Hamburg",
      "Kempton",
      "Kutztown",
      "Leesport",
      "Lenhartsville",
      "Lyon station",
      "Mertztown",
      "Mohnton",
      "Mohrsville",
      "Morgantown",
      "Mount aetna",
      "New berlinville",
      "Oley",
      "Port clinton",
      "Rehrersburg",
      "Robesonia",
      "Shartlesville",
      "Shoemakersville",
      "Strausstown",
      "Temple",
      "Topton",
      "Wernersville",
      "Womelsdorf",
      "Zcta 195hh",
      "Reading",
      "Mount penn",
      "Shillington",
      "Sinking spring",
      "West lawn",
      "Wyomissing",
      "Zcta 196hh",
    ],
    ZipCodes: {
      Macarthur: ["15001"],
      Fairoaks: ["15003"],
      Atlasburg: ["15004"],
      Baden: ["15005"],
      Bairdford: ["15006"],
      Bakerstown: ["15007"],
      Beaver: ["15009"],
      Racine: ["15010"],
      Rostraver: ["15012"],
      Brackenridge: ["15014"],
      Bradfordwoods: ["15015"],
      Bridgeville: ["15017"],
      "Buena vista": ["15018"],
      Bulger: ["15019"],
      Bunola: ["15020"],
      Paris: ["15021"],
      Charleroi: ["15022"],
      Cheswick: ["15024"],
      Large: ["15025"],
      Clinton: ["15026"],
      Conway: ["15027"],
      Coulters: ["15028"],
      Creighton: ["15030"],
      Cuddy: ["15031"],
      Donora: ["15033"],
      Dravosburg: ["15034"],
      "East mc keesport": ["15035"],
      Elizabeth: ["15037"],
      Elrama: ["15038"],
      Freedom: ["15042"],
      Georgetown: ["15043"],
      Gibsonia: ["15044"],
      Glassport: ["15045"],
      Glenwillard: ["15046"],
      Harwick: ["15049"],
      Hookstown: ["15050"],
      Indianola: ["15051"],
      Industry: ["15052"],
      Joffre: ["15053"],
      Langeloth: ["15054"],
      Lawrence: ["15055"],
      Leetsdale: ["15056"],
      "Mc donald": ["15057"],
      Midland: ["15059"],
      Midway: ["15060"],
      Monaca: ["15061"],
      Monessen: ["15062"],
      Monongahela: ["15063"],
      Morgan: ["15064"],
      Natrona: ["15065"],
      "New brighton": ["15066"],
      "New eagle": ["15067"],
      Arnold: ["15068"],
      Noblestown: ["15071"],
      Pricedale: ["15072"],
      Rochester: ["15074"],
      "Rural ridge": ["15075"],
      Russellton: ["15076"],
      Slovan: ["15078"],
      "South heights": ["15081"],
      Sturgeon: ["15082"],
      Sutersville: ["15083"],
      Tarentum: ["15084"],
      "Level green": ["15085"],
      Warrendale: ["15086"],
      Webster: ["15087"],
      "West elizabeth": ["15088"],
      "West newton": ["15089"],
      Wexford: ["15090"],
      "Zcta 150hh": ["150HH"],
      "Allison park": ["15101"],
      "Bethel park": ["15102"],
      Rankin: ["15104"],
      Carnegie: ["15106"],
      "Moon twp": ["15108"],
      Duquesne: ["15110"],
      "East pittsburgh": ["15112"],
      Glenshaw: ["15116"],
      Munhall: ["15120"],
      "W mifflin fin": ["15122"],
      Imperial: ["15126"],
      Library: ["15129"],
      "White oak": ["15131"],
      "Mc keesport": ["15132", "15133"],
      Boston: ["15135"],
      "Mc kees rocks": ["15136"],
      "North versailles": ["15137"],
      Oakmont: ["15139"],
      Pitcairn: ["15140"],
      Presto: ["15142"],
      Sewickley: ["15143"],
      Springdale: ["15144"],
      "Turtle creek": ["15145"],
      Monroeville: ["15146"],
      Verona: ["15147"],
      Wall: ["15148"],
      "Zcta 151hh": ["151HH"],
      Arsenal: ["15201"],
      Bellevue: ["15202"],
      Carson: ["15203"],
      Corliss: ["15204"],
      Crafton: ["15205"],
      "East liberty": ["15206"],
      Hazelwood: ["15207"],
      Homewood: ["15208"],
      Millvale: ["15209"],
      "Mount oliver": ["15210"],
      "Mount washington": ["15211"],
      Allegheny: ["15212"],
      Oakland: ["15213"],
      Observatory: ["15214"],
      Aspinwall: ["15215"],
      "South hills": ["15216"],
      "Squirrel hill": ["15217"],
      Swissvale: ["15218"],
      Uptown: ["15219"],
      "Parkway center": ["15220"],
      Wilkinsburg: ["15221"],
      Downtown: ["15222"],
      Etna: ["15223"],
      Bloomfield: ["15224"],
      "Neville island": ["15225"],
      Brookline: ["15226"],
      Brentwood: ["15227"],
      "Mount lebanon": ["15228"],
      "West view": ["15229"],
      Shadyside: ["15232"],
      Kilbuck: ["15233"],
      "Castle shannon": ["15234"],
      "Penn hills": ["15235"],
      "Caste village": ["15236"],
      "Mc knight": ["15237"],
      Blawnox: ["15238"],
      Plum: ["15239"],
      "Upper saint clai": ["15241"],
      Cedarhurst: ["15243"],
      "Zcta 152hh": ["152HH"],
      Washington: ["15301"],
      Aleppo: ["15310"],
      Amity: ["15311"],
      Avella: ["15312"],
      Beallsville: ["15313"],
      Bentleyville: ["15314"],
      Bobtown: ["15315"],
      Brave: ["15316"],
      "Mc murray": ["15317"],
      Carmichaels: ["15320"],
      Cecil: ["15321"],
      Clarksville: ["15322"],
      Claysville: ["15323"],
      Cokeburg: ["15324"],
      Crucible: ["15325"],
      Dilliner: ["15327"],
      Prosperity: ["15329"],
      "Eighty four": ["15330"],
      Ellsworth: ["15331"],
      Finleyville: ["15332"],
      Fredericktown: ["15333"],
      "Garards fort": ["15334"],
      Graysville: ["15337"],
      Greensboro: ["15338"],
      Hickory: ["15340"],
      Holbrook: ["15341"],
      Houston: ["15342"],
      Jefferson: ["15344"],
      Marianna: ["15345"],
      Mather: ["15346"],
      "Meadow lands": ["15347"],
      Millsboro: ["15348"],
      Davistown: ["15349"],
      Muse: ["15350"],
      Nemacolin: ["15351"],
      "New freeport": ["15352"],
      Nineveh: ["15353"],
      "Rices landing": ["15357"],
      Richeyville: ["15358"],
      Rogersville: ["15359"],
      "Scenery hill": ["15360"],
      Southview: ["15361"],
      Spraggs: ["15362"],
      Strabane: ["15363"],
      Sycamore: ["15364"],
      Venetia: ["15367"],
      Vestaburg: ["15368"],
      Waynesburg: ["15370"],
      "West alexander": ["15376"],
      "West finley": ["15377"],
      Westland: ["15378"],
      "West middletown": ["15379"],
      "Wind ridge": ["15380"],
      "Zcta 153hh": ["153HH"],
      Uniontown: ["15401"],
      Adah: ["15410"],
      Addison: ["15411"],
      Allenport: ["15412"],
      Allison: ["15413"],
      "West brownsville": ["15417"],
      California: ["15419"],
      Cardale: ["15420"],
      "Chestnut ridge": ["15422"],
      "Coal center": ["15423"],
      Listonburg: ["15424"],
      "South connellsvi": ["15425"],
      Daisytown: ["15427"],
      Dawson: ["15428"],
      Denbo: ["15429"],
      "Dickerson run": ["15430"],
      Dunbar: ["15431"],
      Dunlevy: ["15432"],
      "East millsboro": ["15433"],
      Elco: ["15434"],
      Fairchance: ["15436"],
      Farmington: ["15437"],
      "Fayette city": ["15438"],
      "Gibbon glade": ["15440"],
      Grindstone: ["15442"],
      Hibbs: ["15443"],
      Hiller: ["15444"],
      Hopwood: ["15445"],
      "Indian head": ["15446"],
      Isabella: ["15447"],
      "Jacobs creek": ["15448"],
      "La belle": ["15450"],
      "Lake lynn": ["15451"],
      Leckrone: ["15454"],
      Leisenring: ["15455"],
      "Lemont furnace": ["15456"],
      Lamberton: ["15458"],
      Markleysburg: ["15459"],
      Martin: ["15460"],
      "Grays landing": ["15461"],
      Melcroft: ["15462"],
      "Mill run": ["15464"],
      Newell: ["15466"],
      "New salem": ["15468"],
      Normalville: ["15469"],
      Ohiopyle: ["15470"],
      Oliver: ["15472"],
      Layton: ["15473"],
      "Point marion": ["15474"],
      Republic: ["15475"],
      Ronco: ["15476"],
      Roscoe: ["15477"],
      Smithfield: ["15478"],
      "Van meter": ["15479"],
      Smock: ["15480"],
      "Star junction": ["15482"],
      Stockdale: ["15483"],
      Uledi: ["15484"],
      Ursina: ["15485"],
      Vanderbilt: ["15486"],
      "West leisenring": ["15489"],
      White: ["15490"],
      Wickhaven: ["15492"],
      "Zcta 154hh": ["154HH"],
      Somerset: ["15501"],
      Acosta: ["15520"],
      "Alum bank": ["15521"],
      Bedford: ["15522"],
      Berlin: ["15530"],
      Boswell: ["15531"],
      Boynton: ["15532"],
      Breezewood: ["15533"],
      "Buffalo mills": ["15534"],
      Clearville: ["15535"],
      "Crystal spring": ["15536"],
      Everett: ["15537"],
      Glencoe: ["15538"],
      Fishertown: ["15539"],
      "Fort hill": ["15540"],
      Friedens: ["15541"],
      Garrett: ["15542"],
      Gray: ["15544"],
      Hyndman: ["15545"],
      Jenners: ["15546"],
      Jennerstown: ["15547"],
      "Manns choice": ["15550"],
      Markleton: ["15551"],
      Meyersdale: ["15552"],
      "New baltimore": ["15553"],
      "New paris": ["15554"],
      Rockwood: ["15557"],
      Salisbury: ["15558"],
      Schellsburg: ["15559"],
      Shanksville: ["15560"],
      Springs: ["15562"],
      Stoystown: ["15563"],
      "Zcta 155hh": ["155HH"],
      Greensburg: ["15601"],
      Acme: ["15610"],
      Adamsburg: ["15611"],
      Alverton: ["15612"],
      Apollo: ["15613"],
      Ardara: ["15615"],
      Arona: ["15617"],
      Avonmore: ["15618"],
      Bradenville: ["15620"],
      Champion: ["15622"],
      Claridge: ["15623"],
      Crabtree: ["15624"],
      Darragh: ["15625"],
      Delmont: ["15626"],
      Derry: ["15627"],
      Donegal: ["15628"],
      "East vandergrift": ["15629"],
      Everson: ["15631"],
      Export: ["15632"],
      "Forbes road": ["15633"],
      Grapeville: ["15634"],
      Hannastown: ["15635"],
      "Harrison city": ["15636"],
      Herminie: ["15637"],
      Hunker: ["15639"],
      Hutchinson: ["15640"],
      "Hyde park": ["15641"],
      "North huntingdon": ["15642"],
      Jeannette: ["15644"],
      "Jones mills": ["15646"],
      Larimer: ["15647"],
      Latrobe: ["15650"],
      Laughlintown: ["15655"],
      Leechburg: ["15656"],
      Wilpen: ["15658"],
      Lowber: ["15660"],
      Loyalhanna: ["15661"],
      Luxor: ["15662"],
      Madison: ["15663"],
      Manor: ["15665"],
      "Mount pleasant": ["15666"],
      Murrysville: ["15668"],
      "New alexandria": ["15670"],
      "New derry": ["15671"],
      "New stanton": ["15672"],
      "North apollo": ["15673"],
      Norvelt: ["15674"],
      Penn: ["15675"],
      "Pleasant unity": ["15676"],
      Rector: ["15677"],
      Rillton: ["15678"],
      "Ruffs dale": ["15679"],
      Salina: ["15680"],
      Saltsburg: ["15681"],
      Scottdale: ["15683"],
      Slickville: ["15684"],
      Southwest: ["15685"],
      "Spring church": ["15686"],
      Stahlstown: ["15687"],
      Tarrs: ["15688"],
      United: ["15689"],
      Park: ["15690"],
      "Westmoreland cit": ["15692"],
      Whitney: ["15693"],
      Wyano: ["15695"],
      Youngstown: ["15696"],
      Youngwood: ["15697"],
      Yukon: ["15698"],
      "Zcta 156hh": ["156HH"],
      Indiana: ["15701"],
      Alverda: ["15710"],
      Anita: ["15711"],
      Arcadia: ["15712"],
      Aultman: ["15713"],
      Barnesboro: ["15714"],
      "Big run": ["15715"],
      "Black lick": ["15716"],
      Blairsville: ["15717"],
      Burnside: ["15721"],
      Carrolltown: ["15722"],
      Chambersville: ["15723"],
      "Cherry tree": ["15724"],
      Clarksburg: ["15725"],
      Clymer: ["15728"],
      Commodore: ["15729"],
      Coolspring: ["15730"],
      Coral: ["15731"],
      Creekside: ["15732"],
      "De lancey": ["15733"],
      Dixonville: ["15734"],
      Elderton: ["15736"],
      Ernest: ["15739"],
      Gipsy: ["15741"],
      "Glen campbell": ["15742"],
      Hamilton: ["15744"],
      Heilwood: ["15745"],
      Home: ["15747"],
      Graceton: ["15748"],
      Kent: ["15752"],
      "La jose": ["15753"],
      Lucernemines: ["15754"],
      "Mc intyre": ["15756"],
      "Mc gees mills": ["15757"],
      "Marion center": ["15759"],
      Marsteller: ["15760"],
      Mentcle: ["15761"],
      Nicktown: ["15762"],
      Northpoint: ["15763"],
      Oliveburg: ["15764"],
      "Penn run": ["15765"],
      Punxsutawney: ["15767"],
      Ringgold: ["15770"],
      "Rochester mills": ["15771"],
      Rossiter: ["15772"],
      "Saint benedict": ["15773"],
      Shelocta: ["15774"],
      Spangler: ["15775"],
      "Sprankle mills": ["15776"],
      Starford: ["15777"],
      Timblin: ["15778"],
      Torrance: ["15779"],
      Valier: ["15780"],
      Walston: ["15781"],
      "West lebanon": ["15783"],
      Worthville: ["15784"],
      "Zcta 157hh": ["157HH"],
      "Du bois": ["15801"],
      Benezett: ["15821"],
      Brockport: ["15823"],
      Brockway: ["15824"],
      Hazen: ["15825"],
      Byrnedale: ["15827"],
      Clarington: ["15828"],
      Corsica: ["15829"],
      Driftwood: ["15832"],
      Emporium: ["15834"],
      "Falls creek": ["15840"],
      Force: ["15841"],
      Johnsonburg: ["15845"],
      Kersey: ["15846"],
      "Knox dale": ["15847"],
      Luthersburg: ["15848"],
      Penfield: ["15849"],
      Reynoldsville: ["15851"],
      "Portland mills": ["15853"],
      Rockton: ["15856"],
      "Saint marys": ["15857"],
      Sigel: ["15860"],
      Sinnamahoning: ["15861"],
      "Stump creek": ["15863"],
      Summerville: ["15864"],
      Sykesville: ["15865"],
      Troutville: ["15866"],
      Weedville: ["15868"],
      Wilcox: ["15870"],
      "Zcta 158hh": ["158HH"],
      "Zcta 158xx": ["158XX"],
      Johnstown: ["15901", "15902", "15904", "15905", "15906", "15909"],
      Armagh: ["15920"],
      Bolivar: ["15923"],
      Cairnbrook: ["15924"],
      Cassandra: ["15925"],
      "Central city": ["15926"],
      Colver: ["15927"],
      Davidsville: ["15928"],
      Dilltown: ["15929"],
      Dunlo: ["15930"],
      Ebensburg: ["15931"],
      Elton: ["15934"],
      Hollsopple: ["15935"],
      Hooversville: ["15936"],
      Jerome: ["15937"],
      Lilly: ["15938"],
      Loretto: ["15940"],
      "Mineral point": ["15942"],
      "Nanty glo": ["15943"],
      "New florence": ["15944"],
      Puritan: ["15946"],
      Revloc: ["15948"],
      Robinson: ["15949"],
      Salix: ["15952"],
      Seward: ["15954"],
      Sidman: ["15955"],
      "South fork": ["15956"],
      Strongstown: ["15957"],
      Summerhill: ["15958"],
      Vintondale: ["15961"],
      Windber: ["15963"],
      "Zcta 159hh": ["159HH"],
      "Bon aire": ["16001"],
      "Zcta 16002": ["16002"],
      Boyers: ["16020"],
      Bruin: ["16022"],
      Marwood: ["16023"],
      Callery: ["16024"],
      Chicora: ["16025"],
      Connoquenessing: ["16027"],
      "East brady": ["16028"],
      "East butler": ["16029"],
      "Eau claire": ["16030"],
      "Evans city": ["16033"],
      Fenelton: ["16034"],
      Forestville: ["16035"],
      Foxburg: ["16036"],
      Harmony: ["16037"],
      Harrisville: ["16038"],
      Hilliards: ["16040"],
      "Karns city": ["16041"],
      Lyndora: ["16045"],
      Mars: ["16046", "16066"],
      Parker: ["16049"],
      Petrolia: ["16050"],
      Portersville: ["16051"],
      Prospect: ["16052"],
      Renfrew: ["16053"],
      "Saint petersburg": ["16054"],
      Sarver: ["16055"],
      Saxonburg: ["16056"],
      "Slippery rock": ["16057"],
      Valencia: ["16059"],
      "West sunbury": ["16061"],
      Zelienople: ["16063"],
      "Zcta 160hh": ["160HH"],
      "New castle": ["16101", "16102"],
      Neshannock: ["16105"],
      Adamsville: ["16110"],
      Atlantic: ["16111"],
      Bessemer: ["16112"],
      Clark: ["16113"],
      "Clarks mills": ["16114"],
      Darlington: ["16115"],
      Edinburg: ["16116"],
      Ellport: ["16117"],
      "Enon valley": ["16120"],
      Farrell: ["16121"],
      Fombell: ["16123"],
      Fredonia: ["16124"],
      Shenango: ["16125"],
      "Grove city": ["16127"],
      Hadley: ["16130"],
      Hartstown: ["16131"],
      Hillsville: ["16132"],
      "Jackson center": ["16133"],
      Westford: ["16134"],
      Koppel: ["16136"],
      Mercer: ["16137"],
      "New bedford": ["16140"],
      "New galilee": ["16141"],
      "New wilmington": ["16142"],
      Pulaski: ["16143"],
      "Sandy lake": ["16145"],
      Sharon: ["16146"],
      Hermitage: ["16148"],
      Sharpsville: ["16150"],
      Sheakleyville: ["16151"],
      Stoneboro: ["16153"],
      Transfer: ["16154"],
      "Villa maria": ["16155"],
      Volant: ["16156"],
      Wampum: ["16157"],
      "West middlesex": ["16159"],
      "West pittsburg": ["16160"],
      Wheatland: ["16161"],
      "Zcta 161hh": ["161HH"],
      Kittanning: ["16201"],
      Adrian: ["16210"],
      Cadogan: ["16212"],
      Callensburg: ["16213"],
      Clarion: ["16214"],
      Cooksburg: ["16217"],
      Cowansville: ["16218"],
      Dayton: ["16222"],
      Distant: ["16223"],
      "Fairmount city": ["16224"],
      "Ford city": ["16226"],
      "Ford cliff": ["16228"],
      Freeport: ["16229"],
      Hawthorn: ["16230"],
      Knox: ["16232"],
      Leeper: ["16233"],
      Limestone: ["16234"],
      Lucinda: ["16235"],
      "Mc grann": ["16236"],
      Manorville: ["16238"],
      Marienville: ["16239"],
      Mayport: ["16240"],
      "New bethlehem": ["16242"],
      "Nu mine": ["16244"],
      "Oak ridge": ["16245"],
      Plumville: ["16246"],
      Huey: ["16248"],
      "Rural valley": ["16249"],
      Sagamore: ["16250"],
      Seminole: ["16253"],
      Shippenville: ["16254"],
      Sligo: ["16255"],
      Smicksburg: ["16256"],
      Strattanville: ["16258"],
      Templeton: ["16259"],
      Vowinckel: ["16260"],
      Craigsville: ["16262"],
      Yatesboro: ["16263"],
      "Zcta 162hh": ["162HH"],
      "Zcta 162xx": ["162XX"],
      "Oil city": ["16301"],
      Carlton: ["16311"],
      Clarendon: ["16313"],
      Cochranton: ["16314"],
      "Conneaut lake": ["16316"],
      Cooperstown: ["16317"],
      Cranberry: ["16319"],
      "East hickory": ["16321"],
      Endeavor: ["16322"],
      Franklin: ["16323"],
      Fryburg: ["16326"],
      "Guys mills": ["16327"],
      Irvine: ["16329"],
      Kossuth: ["16331"],
      Lickingville: ["16332"],
      Ludlow: ["16333"],
      Marble: ["16334"],
      Meadville: ["16335"],
      Pittsfield: ["16340"],
      Pleasantville: ["16341"],
      Polk: ["16342"],
      Reno: ["16343"],
      Rouseville: ["16344"],
      Russell: ["16345"],
      Seneca: ["16346"],
      Sheffield: ["16347"],
      "Sugar grove": ["16350"],
      Tidioute: ["16351"],
      Tiona: ["16352"],
      Tionesta: ["16353"],
      Titusville: ["16354"],
      Townville: ["16360"],
      Tylersburg: ["16361"],
      Utica: ["16362"],
      Venus: ["16364"],
      "North warren": ["16365"],
      "West hickory": ["16370"],
      Youngsville: ["16371"],
      Clintonville: ["16372"],
      Emlenton: ["16373"],
      Kennerdell: ["16374"],
      "Zcta 163hh": ["163HH"],
      "Zcta 163xx": ["163XX"],
      "Lundys lane": ["16401"],
      "Bear lake": ["16402"],
      "Cambridge spring": ["16403"],
      Centerville: ["16404"],
      Columbus: ["16405"],
      Conneautville: ["16406"],
      Corry: ["16407"],
      Cranesville: ["16410"],
      "East springfield": ["16411"],
      Edinboro: ["16412"],
      Fairview: ["16415"],
      Garland: ["16416"],
      Girard: ["16417"],
      "Grand valley": ["16420"],
      Harborcreek: ["16421"],
      Harmonsburg: ["16422"],
      "Lake city": ["16423"],
      Espyville: ["16424"],
      "Mc kean": ["16426"],
      "Mill village": ["16427"],
      "North east": ["16428"],
      Saegertown: ["16433"],
      Spartansburg: ["16434"],
      Springboro: ["16435"],
      "Spring creek": ["16436"],
      "Union city": ["16438"],
      Venango: ["16440"],
      Waterford: ["16441"],
      Wattsburg: ["16442"],
      "West springfield": ["16443"],
      "Zcta 164hh": ["164HH"],
      Erie: [
        "16501",
        "16502",
        "16503",
        "16504",
        "16506",
        "16507",
        "16508",
        "16509",
        "16511",
      ],
      "Presque isle": ["16505"],
      Wesleyville: ["16510"],
      "Zcta 165hh": ["165HH"],
      Altoona: ["16601", "16602"],
      Barree: ["16611"],
      Ashville: ["16613"],
      Beccaria: ["16616"],
      Bellwood: ["16617"],
      Blandburg: ["16619"],
      Brisbin: ["16620"],
      "Broad top": ["16621"],
      Calvin: ["16622"],
      Cassville: ["16623"],
      "Chest springs": ["16624"],
      Claysburg: ["16625"],
      Coalport: ["16627"],
      Cresson: ["16630"],
      Curryville: ["16631"],
      Defiance: ["16633"],
      Dudley: ["16634"],
      Duncansville: ["16635"],
      Dysart: ["16636"],
      "East freedom": ["16637"],
      Entriken: ["16638"],
      Fallentimber: ["16639"],
      Flinton: ["16640"],
      Gallitzin: ["16641"],
      "Glen hope": ["16645"],
      Hastings: ["16646"],
      Hesston: ["16647"],
      Hollidaysburg: ["16648"],
      Hopewell: ["16650"],
      Houtzdale: ["16651"],
      Huntingdon: ["16652"],
      Imler: ["16655"],
      Irvona: ["16656"],
      "James creek": ["16657"],
      Loysburg: ["16659"],
      Madera: ["16661"],
      Martinsburg: ["16662"],
      Morann: ["16663"],
      "New enterprise": ["16664"],
      Newry: ["16665"],
      "Osceola mills": ["16666"],
      "St clairsville": ["16667"],
      Patton: ["16668"],
      Petersburg: ["16669"],
      Queen: ["16670"],
      Ramey: ["16671"],
      Riddlesburg: ["16672"],
      "Roaring spring": ["16673"],
      Robertsdale: ["16674"],
      "Sandy ridge": ["16677"],
      Saxton: ["16678"],
      "Six mile run": ["16679"],
      Smithmill: ["16680"],
      Smokerun: ["16681"],
      Sproul: ["16682"],
      "Spruce creek": ["16683"],
      Todd: ["16685"],
      Tyrone: ["16686"],
      Waterfall: ["16689"],
      "Wells tannery": ["16691"],
      Westover: ["16692"],
      Ganister: ["16693"],
      Wood: ["16694"],
      Woodbury: ["16695"],
      "Zcta 166hh": ["166HH"],
      "Zcta 166xx": ["166XX"],
      Bradford: ["16701"],
      Austin: ["16720"],
      Crosby: ["16724"],
      "Custer city": ["16725"],
      Ormsby: ["16726"],
      "Derrick city": ["16727"],
      "Duke center": ["16729"],
      "East smethport": ["16730"],
      Eldred: ["16731"],
      Gifford: ["16732"],
      "Hazel hurst": ["16733"],
      "James city": ["16734"],
      Kane: ["16735"],
      "Lewis run": ["16738"],
      "Mount jewett": ["16740"],
      "Port allegany": ["16743"],
      Rew: ["16744"],
      Rixford: ["16745"],
      Roulette: ["16746"],
      Shinglehouse: ["16748"],
      Smethport: ["16749"],
      Turtlepoint: ["16750"],
      Westline: ["16751"],
      "Zcta 167xx": ["167XX"],
      "State college": ["16801", "16803"],
      "University park": ["16802"],
      Aaronsburg: ["16820"],
      "Beech creek": ["16822"],
      "Pleasant gap": ["16823"],
      Bigler: ["16825"],
      Blanchard: ["16826"],
      Boalsburg: ["16827"],
      "Centre hall": ["16828"],
      Clarence: ["16829"],
      Clearfield: ["16830"],
      Coburn: ["16832"],
      Curwensville: ["16833"],
      Drifting: ["16834"],
      Frenchville: ["16836"],
      "Glen richey": ["16837"],
      Grampian: ["16838"],
      Grassflat: ["16839"],
      "Hawk run": ["16840"],
      Howard: ["16841"],
      Hyde: ["16843"],
      Julian: ["16844"],
      Karthaus: ["16845"],
      Kylertown: ["16847"],
      Lamar: ["16848"],
      Lanse: ["16849"],
      Lemont: ["16851"],
      Madisonburg: ["16852"],
      Milesburg: ["16853"],
      Millheim: ["16854"],
      "Mineral springs": ["16855"],
      Mingoville: ["16856"],
      Morrisdale: ["16858"],
      Moshannon: ["16859"],
      Munson: ["16860"],
      "New millport": ["16861"],
      Olanta: ["16863"],
      Orviston: ["16864"],
      "Pennsylvania fur": ["16865"],
      Philipsburg: ["16866"],
      "Port matilda": ["16870"],
      Pottersdale: ["16871"],
      Rebersburg: ["16872"],
      "Snow shoe": ["16874"],
      "Spring mills": ["16875"],
      Wallaceton: ["16876"],
      "Warriors mark": ["16877"],
      "West decatur": ["16878"],
      Winburne: ["16879"],
      Woodland: ["16881"],
      Woodward: ["16882"],
      "Zcta 168hh": ["168HH"],
      "Zcta 168xx": ["168XX"],
      Wellsboro: ["16901"],
      Arnot: ["16911"],
      Blossburg: ["16912"],
      "Columbia cross r": ["16914"],
      Oswayo: ["16915"],
      Covington: ["16917"],
      Cowanesque: ["16918"],
      Elkland: ["16920"],
      Gaines: ["16921"],
      Galeton: ["16922"],
      "North bingham": ["16923"],
      Gillett: ["16925"],
      "Granville summit": ["16926"],
      "Harrison valley": ["16927"],
      Knoxville: ["16928"],
      Lawrenceville: ["16929"],
      Liberty: ["16930"],
      Mainesburg: ["16932"],
      Mansfield: ["16933"],
      "Middlebury cente": ["16935"],
      Millerton: ["16936"],
      Mills: ["16937"],
      Morris: ["16938"],
      "Morris run": ["16939"],
      Nelson: ["16940"],
      Genesee: ["16941"],
      Osceola: ["16942"],
      Sabinsville: ["16943"],
      Tioga: ["16946"],
      Troy: ["16947"],
      Ulysses: ["16948"],
      "Little marsh": ["16950"],
      "Zcta 169hh": ["169HH"],
      "Zcta 169xx": ["169XX"],
      Allensville: ["17002"],
      Annville: ["17003"],
      Belleville: ["17004"],
      Berrysburg: ["17005"],
      Blain: ["17006"],
      "Boiling springs": ["17007"],
      Burnham: ["17009"],
      Campbelltown: ["17010"],
      Shiremanstown: ["17011"],
      "Carlisle barrack": ["17013"],
      Cornwall: ["17016"],
      Dalmatia: ["17017"],
      Dauphin: ["17018"],
      Dillsburg: ["17019"],
      Duncannon: ["17020"],
      "East waterford": ["17021"],
      Elizabethtown: ["17022"],
      Elizabethville: ["17023"],
      Elliottsburg: ["17024"],
      Enola: ["17025"],
      Fredericksburg: ["17026"],
      "Messiah college": ["17027"],
      Grantville: ["17028"],
      Granville: ["17029"],
      Halifax: ["17032"],
      Hershey: ["17033"],
      Highspire: ["17034"],
      "Honey grove": ["17035"],
      Hummelstown: ["17036"],
      Ickesburg: ["17037"],
      Jonestown: ["17038"],
      Landisburg: ["17040"],
      Lawn: ["17041"],
      Cleona: ["17042"],
      Wormleysburg: ["17043"],
      Lewistown: ["17044"],
      Liverpool: ["17045"],
      Lebanon: ["17046"],
      Loysville: ["17047"],
      Lykens: ["17048"],
      "Mc alisterville": ["17049"],
      "Mc veytown": ["17051"],
      "Mapleton depot": ["17052"],
      Marysville: ["17053"],
      Hampden: ["17055"],
      Middletown: ["17057"],
      Mifflin: ["17058"],
      Mifflintown: ["17059"],
      "Mill creek": ["17060"],
      Millersburg: ["17061"],
      Millerstown: ["17062"],
      Milroy: ["17063"],
      "Mount gretna": ["17064"],
      "Mount holly spri": ["17065"],
      "Mount union": ["17066"],
      Myerstown: ["17067"],
      "New bloomfield": ["17068"],
      "New buffalo": ["17069"],
      "New cumberland": ["17070"],
      "New kingstown": ["17072"],
      Newmanstown: ["17073"],
      Newport: ["17074"],
      "Newton hamilton": ["17075"],
      "Oakland mills": ["17076"],
      Ono: ["17077"],
      Palmyra: ["17078"],
      Pillow: ["17080"],
      Plainfield: ["17081"],
      "Port royal": ["17082"],
      Quentin: ["17083"],
      Reedsville: ["17084"],
      Richfield: ["17086"],
      Richland: ["17087"],
      Schaefferstown: ["17088"],
      "Shermans dale": ["17090"],
      Summerdale: ["17093"],
      Thompsontown: ["17094"],
      Wiconisco: ["17097"],
      Williamstown: ["17098"],
      Yeagertown: ["17099"],
      "Zcta 170hh": ["170HH"],
      "Zcta 170xx": ["170XX"],
      Harrisburg: ["17101", "17102", "17104", "17110", "17112"],
      Penbrook: ["17103"],
      "Colonial park": ["17109"],
      Swatara: ["17111"],
      Steelton: ["17113"],
      "Zcta 171hh": ["171HH"],
      Chambersburg: ["17201"],
      Artemas: ["17211"],
      "Big cove tannery": ["17212"],
      "Blairs mills": ["17213"],
      "Blue ridge summi": ["17214"],
      "Burnt cabins": ["17215"],
      Doylesburg: ["17219"],
      "Dry run": ["17220"],
      Fannettsburg: ["17221"],
      Fayetteville: ["17222"],
      "Fort littleton": ["17223"],
      "Fort loudon": ["17224"],
      Greencastle: ["17225"],
      Harrisonville: ["17228"],
      Hustontown: ["17229"],
      "Mc connellsburg": ["17233"],
      Marion: ["17235"],
      Mercersburg: ["17236"],
      "Mont alto": ["17237"],
      Needmore: ["17238"],
      Neelyton: ["17239"],
      Newburg: ["17240"],
      Newville: ["17241"],
      Orbisonia: ["17243"],
      Orrstown: ["17244"],
      "Rockhill furnace": ["17249"],
      "Saint thomas": ["17252"],
      Saltillo: ["17253"],
      Scotland: ["17254"],
      "Shade gap": ["17255"],
      Shippensburg: ["17257"],
      Shirleysburg: ["17260"],
      "Spring run": ["17262"],
      "Three springs": ["17264"],
      Upperstrasburg: ["17265"],
      "Walnut bottom": ["17266"],
      Warfordsburg: ["17267"],
      Waynesboro: ["17268"],
      Williamson: ["17270"],
      "Willow hill": ["17271"],
      Abbottstown: ["17301"],
      Airville: ["17302"],
      Aspers: ["17304"],
      Bendersville: ["17306"],
      Biglerville: ["17307"],
      Brogue: ["17309"],
      Codorus: ["17311"],
      Yoe: ["17313"],
      Delta: ["17314"],
      Dover: ["17315"],
      "East berlin": ["17316"],
      "East prospect": ["17317"],
      Emigsville: ["17318"],
      Etters: ["17319"],
      Greenstone: ["17320"],
      "Fawn grove": ["17321"],
      Felton: ["17322"],
      Gardners: ["17324"],
      Gettysburg: ["17325"],
      "Glen rock": ["17327"],
      Brodbecks: ["17329"],
      Hanover: ["17331"],
      Lewisberry: ["17339"],
      Littlestown: ["17340"],
      "Mc knightstown": ["17343"],
      "Mc sherrystown": ["17344"],
      Manchester: ["17345"],
      "Mount wolf": ["17347"],
      "New freedom": ["17349"],
      "New oxford": ["17350"],
      "New park": ["17352"],
      Orrtanna: ["17353"],
      Railroad: ["17355"],
      "Red lion": ["17356"],
      "Seven valleys": ["17360"],
      Shrewsbury: ["17361"],
      "Spring grove": ["17362"],
      Stewartstown: ["17363"],
      Thomasville: ["17364"],
      Wellsville: ["17365"],
      Windsor: ["17366"],
      Wrightsville: ["17368"],
      "York haven": ["17370"],
      "York springs": ["17372"],
      "Zcta 173hh": ["173HH"],
      York: ["17401", "17403"],
      "East york": ["17402"],
      "West york": ["17404"],
      Hellam: ["17406"],
      Jacobus: ["17407"],
      "Zcta 174hh": ["174HH"],
      Akron: ["17501"],
      Bainbridge: ["17502"],
      "Bird in hand": ["17505"],
      Brownstown: ["17508"],
      Ninepoints: ["17509"],
      Columbia: ["17512"],
      Conestoga: ["17516"],
      Denver: ["17517"],
      Drumore: ["17518"],
      "East earl": ["17519"],
      "East petersburg": ["17520"],
      Ephrata: ["17522"],
      Gap: ["17527"],
      Gordonville: ["17529"],
      Holtwood: ["17532"],
      Kinzers: ["17535"],
      Kirkwood: ["17536"],
      Salunga: ["17538"],
      Leola: ["17540"],
      Brunnerville: ["17543"],
      Manheim: ["17545"],
      Marietta: ["17547"],
      Millersville: ["17551"],
      Florin: ["17552"],
      Mountville: ["17554"],
      Narvon: ["17555"],
      "New holland": ["17557"],
      "New providence": ["17560"],
      Paradise: ["17562"],
      "Peach bottom": ["17563"],
      Pequea: ["17565"],
      Quarryville: ["17566"],
      Reinholds: ["17569"],
      Rheems: ["17570"],
      Ronks: ["17572"],
      Smoketown: ["17576"],
      Stevens: ["17578"],
      Strasburg: ["17579"],
      "Terre hill": ["17581"],
      "Washington boro": ["17582"],
      "Willow street": ["17584"],
      "Zcta 175hh": ["175HH"],
      Neffsville: ["17601"],
      Lancaster: ["17602"],
      Rohrerstown: ["17603"],
      "Zcta 176hh": ["176HH"],
      "South williamspo": ["17701"],
      "Zcta 17702": ["17702"],
      Avis: ["17721"],
      Canton: ["17724"],
      "Cogan station": ["17728"],
      "Cross fork": ["17729"],
      Dewart: ["17730"],
      "Eagles mere": ["17731"],
      Hughesville: ["17737"],
      Hyner: ["17738"],
      "Jersey mills": ["17739"],
      Salladasburg: ["17740"],
      Lairdsville: ["17742"],
      Linden: ["17744"],
      "Lock haven": ["17745"],
      Loganton: ["17747"],
      "Mc elhattan": ["17748"],
      "Mc ewensville": ["17749"],
      Mackeyville: ["17750"],
      "Mill hall": ["17751"],
      Montgomery: ["17752"],
      Montoursville: ["17754"],
      Muncy: ["17756"],
      "Muncy valley": ["17758"],
      "North bend": ["17760"],
      "Picture rocks": ["17762"],
      Ralston: ["17763"],
      Renovo: ["17764"],
      "Roaring branch": ["17765"],
      Salona: ["17767"],
      Shunk: ["17768"],
      "Trout run": ["17771"],
      Turbotville: ["17772"],
      Unityville: ["17774"],
      Waterville: ["17776"],
      Watsontown: ["17777"],
      Westport: ["17778"],
      "Zcta 177hh": ["177HH"],
      "Zcta 177xx": ["177XX"],
      Sunbury: ["17801"],
      Allenwood: ["17810"],
      "Beaver springs": ["17812"],
      Beavertown: ["17813"],
      Benton: ["17814"],
      Bloomsburg: ["17815"],
      Catawissa: ["17820"],
      Danville: ["17821"],
      Dornsife: ["17823"],
      Elysburg: ["17824"],
      Excelsior: ["17825", "17872"],
      Freeburg: ["17827"],
      Hartleton: ["17829"],
      Herndon: ["17830"],
      "Marion heights": ["17832"],
      Kreamer: ["17833"],
      Kulpmont: ["17834"],
      Laurelton: ["17835"],
      "Leck kill": ["17836"],
      Lewisburg: ["17837"],
      "Locust gap": ["17840"],
      "Mc clure": ["17841"],
      Middleburg: ["17842"],
      Mifflinburg: ["17844"],
      Millmont: ["17845"],
      Millville: ["17846"],
      Milton: ["17847"],
      Montandon: ["17850"],
      "Mount carmel": ["17851"],
      "Mount pleasant m": ["17853"],
      "New berlin": ["17855"],
      "New columbia": ["17856"],
      Northumberland: ["17857"],
      Orangeville: ["17859"],
      Paxinos: ["17860"],
      Paxtonville: ["17861"],
      "Penns creek": ["17862"],
      "Port trevorton": ["17864"],
      "Potts grove": ["17865"],
      Ranshaw: ["17866"],
      Rebuck: ["17867"],
      Riverside: ["17868"],
      Selinsgrove: ["17870"],
      Stillwater: ["17878"],
      Trevorton: ["17881"],
      Troxelville: ["17882"],
      Washingtonville: ["17884"],
      Weikert: ["17885"],
      "West milton": ["17886"],
      "White deer": ["17887"],
      Wilburton: ["17888"],
      Winfield: ["17889"],
      "Zcta 178hh": ["178HH"],
      "Zcta 178xx": ["178XX"],
      Pottsville: ["17901"],
      Aristes: ["17920"],
      Ashland: ["17921"],
      Auburn: ["17922"],
      Branchdale: ["17923"],
      Brockton: ["17925"],
      Centralia: ["17927"],
      Cressona: ["17929"],
      Cumbola: ["17930"],
      Frackville: ["17931"],
      Friedensburg: ["17933"],
      Gilberton: ["17934"],
      Girardville: ["17935"],
      Gordon: ["17936"],
      Hegins: ["17938"],
      Klingerstown: ["17941"],
      Lavelle: ["17943"],
      Llewellyn: ["17944"],
      Locustdale: ["17945"],
      "Lost creek": ["17946"],
      "Mahanoy city": ["17948"],
      "Mahanoy plane": ["17949"],
      "Mar lin": ["17951"],
      "Mary d": ["17952"],
      Middleport: ["17953"],
      Minersville: ["17954"],
      Muir: ["17957"],
      Kaska: ["17959"],
      "New ringgold": ["17960"],
      Orwigsburg: ["17961"],
      "Pine grove": ["17963"],
      Pitman: ["17964"],
      "Port carbon": ["17965"],
      Ringtown: ["17967"],
      Sacramento: ["17968"],
      "Saint clair": ["17970"],
      "Schuylkill haven": ["17972"],
      Seltzer: ["17974"],
      Shenandoah: ["17976"],
      "Spring glen": ["17978"],
      "Summit station": ["17979"],
      "Tower city": ["17980"],
      Donaldson: ["17981"],
      Tuscarora: ["17982"],
      "Valley view": ["17983"],
      "Zion grove": ["17985"],
      "Zcta 179hh": ["179HH"],
      Alburtis: ["18011"],
      Aquashicola: ["18012"],
      Roseto: ["18013"],
      Bath: ["18014"],
      Bethlehem: ["18015", "18018"],
      Butztown: ["18017"],
      "Zcta 18020": ["18020"],
      Bowmanstown: ["18030"],
      Breinigsville: ["18031"],
      Catasauqua: ["18032"],
      "Center valley": ["18034"],
      Cherryville: ["18035"],
      Coopersburg: ["18036"],
      Coplay: ["18037"],
      Danielsville: ["18038"],
      "Zcta 18040": ["18040"],
      "East greenville": ["18041"],
      "Forks township": ["18042"],
      "Zcta 18045": ["18045"],
      Emmaus: ["18049"],
      Fogelsville: ["18051"],
      Hokendauqua: ["18052"],
      Germansville: ["18053"],
      "Green lane": ["18054"],
      Hellertown: ["18055"],
      Hereford: ["18056"],
      Kunkletown: ["18058"],
      "Laurys station": ["18059"],
      Macungie: ["18062"],
      "Martins creek": ["18063"],
      Nazareth: ["18064"],
      "New tripoli": ["18066"],
      Northampton: ["18067"],
      Orefield: ["18069"],
      Palm: ["18070"],
      Palmerton: ["18071"],
      "Pen argyl": ["18072"],
      Pennsburg: ["18073"],
      Perkiomenville: ["18074"],
      "Red hill": ["18076"],
      Riegelsville: ["18077"],
      Schnecksville: ["18078"],
      Slatedale: ["18079"],
      Emerald: ["18080"],
      Springtown: ["18081"],
      Stockertown: ["18083"],
      Tatamy: ["18085"],
      Treichlers: ["18086"],
      Trexlertown: ["18087"],
      Walnutport: ["18088"],
      "Wind gap": ["18091"],
      Zionsville: ["18092"],
      "Zcta 180hh": ["180HH"],
      Allentown: ["18101", "18102", "18103", "18104"],
      Wescosville: ["18106"],
      "Zcta 181hh": ["181HH"],
      "West hazleton": ["18201"],
      Albrightsville: ["18210"],
      Andreas: ["18211"],
      Barnesville: ["18214"],
      "Beaver meadows": ["18216"],
      Coaldale: ["18218"],
      Conyngham: ["18219"],
      Delano: ["18220"],
      Drifton: ["18221"],
      Drums: ["18222"],
      Freeland: ["18224"],
      Harleigh: ["18225"],
      "Jim thorpe": ["18229"],
      Junedale: ["18230"],
      Kelayres: ["18231"],
      Lansford: ["18232"],
      "Lattimer mines": ["18234"],
      Weissport: ["18235"],
      Mcadoo: ["18237"],
      Milnesville: ["18239"],
      Nesquehoning: ["18240"],
      "Fern glen": ["18241"],
      Oneida: ["18242"],
      Parryville: ["18244"],
      Quakake: ["18245"],
      "Rock glen": ["18246"],
      Sheppton: ["18248"],
      Sugarloaf: ["18249"],
      "Summit hill": ["18250"],
      Sybertsville: ["18251"],
      Tamaqua: ["18252"],
      Tresckow: ["18254"],
      Weatherly: ["18255"],
      Weston: ["18256"],
      "Zcta 182hh": ["182HH"],
      "East stroudsburg": ["18301"],
      Bartonsville: ["18321"],
      Brodheadsville: ["18322"],
      "Buck hill falls": ["18323"],
      Bushkill: ["18324"],
      Canadensis: ["18325"],
      Cresco: ["18326"],
      "Delaware water g": ["18327"],
      "Dingmans ferry": ["18328"],
      Effort: ["18330"],
      Gilbert: ["18331"],
      Henryville: ["18332"],
      Kresgeville: ["18333"],
      "Long pond": ["18334"],
      Matamoras: ["18336"],
      Milford: ["18337"],
      Millrift: ["18340"],
      "Minisink hills": ["18341"],
      "Mount bethel": ["18343"],
      "Mount pocono": ["18344"],
      "Pocono summit": ["18346"],
      "Pocono lake": ["18347"],
      "Pocono manor": ["18349"],
      "Pocono pines": ["18350"],
      Portland: ["18351"],
      Saylorsburg: ["18353"],
      Sciota: ["18354"],
      Scotrun: ["18355"],
      "Shawnee on delaw": ["18356"],
      Skytop: ["18357"],
      Stroudsburg: ["18360"],
      Swiftwater: ["18370"],
      Tannersville: ["18372"],
      "Zcta 183hh": ["183HH"],
      Eynon: ["18403"],
      "Beach lake": ["18405"],
      Simpson: ["18407"],
      "Clarks summit": ["18411"],
      Clifford: ["18413"],
      Dalton: ["18414"],
      Damascus: ["18415"],
      Equinunk: ["18417"],
      Factoryville: ["18419"],
      Fleetville: ["18420"],
      Browndale: ["18421"],
      Gouldsboro: ["18424"],
      Greeley: ["18425"],
      Greentown: ["18426"],
      Hawley: ["18428"],
      "Herrick center": ["18430"],
      Honesdale: ["18431"],
      Mayfield: ["18433"],
      Jessup: ["18434"],
      Lackawaxen: ["18435"],
      "Lake ariel": ["18436"],
      "Lake como": ["18437"],
      Lakeville: ["18438"],
      Lakewood: ["18439"],
      Lenoxville: ["18441"],
      Milanville: ["18443"],
      Moscow: ["18444"],
      Newfoundland: ["18445"],
      Nicholson: ["18446"],
      Olyphant: ["18447"],
      Paupack: ["18451"],
      Peckville: ["18452"],
      "Pleasant mount": ["18453"],
      "Preston park": ["18455"],
      Prompton: ["18456"],
      Rowland: ["18457"],
      Shohola: ["18458"],
      "South canaan": ["18459"],
      "South sterling": ["18460"],
      Starlight: ["18461"],
      Starrucca: ["18462"],
      Tafton: ["18464"],
      Thompson: ["18465"],
      Tobyhanna: ["18466"],
      "Tyler hill": ["18469"],
      "Union dale": ["18470"],
      Waverly: ["18471"],
      Waymart: ["18472"],
      "White mills": ["18473"],
      "Zcta 184hh": ["184HH"],
      "Zcta 184xx": ["184XX"],
      Scranton: ["18503", "18504", "18505", "18508", "18509", "18510"],
      Moosic: ["18507"],
      Dunmore: ["18512"],
      Taylor: ["18517"],
      "Old forge": ["18518"],
      "Dickson city": ["18519"],
      "Beach haven": ["18601"],
      "Bear creek": ["18602"],
      Berwick: ["18603"],
      Blakeslee: ["18610"],
      Cambra: ["18611"],
      "College miserico": ["18612"],
      Dushore: ["18614"],
      Falls: ["18615"],
      Forksville: ["18616"],
      "Glen lyon": ["18617"],
      "Harveys lake": ["18618"],
      Hillsgrove: ["18619"],
      "Hunlock creek": ["18621"],
      "Huntington mills": ["18622"],
      Laceyville: ["18623"],
      "Lake harmony": ["18624"],
      "Lake winola": ["18625"],
      Laporte: ["18626"],
      Lopez: ["18628"],
      Mehoopany: ["18629"],
      Meshoppen: ["18630"],
      Mifflinville: ["18631"],
      Mildred: ["18632"],
      Nanticoke: ["18634"],
      Nescopeck: ["18635"],
      Noxen: ["18636"],
      Pittston: ["18640"],
      Avoca: ["18641"],
      Duryea: ["18642"],
      "West pittston": ["18643"],
      Wyoming: ["18644"],
      Plymouth: ["18651"],
      Ransom: ["18653"],
      Mocanaqua: ["18655"],
      "Sweet valley": ["18656"],
      "Center moreland": ["18657"],
      Wapwallopen: ["18660"],
      "White haven": ["18661"],
      "Zcta 186hh": ["186HH"],
      "Zcta 186xx": ["186XX"],
      "Wilkes barre": ["18701", "18705"],
      "Hanover township": ["18702"],
      Kingston: ["18704"],
      Ashley: ["18706"],
      "Mountain top": ["18707"],
      Shavertown: ["18708"],
      Luzerne: ["18709"],
      Montrose: ["18801"],
      Athens: ["18810"],
      Brackney: ["18812"],
      Brooklyn: ["18813"],
      Burlington: ["18814"],
      Dimock: ["18816"],
      "East smithfield": ["18817"],
      Friendsville: ["18818"],
      Gibson: ["18820"],
      "Great bend": ["18821"],
      Hallstead: ["18822"],
      Harford: ["18823"],
      "Hop bottom": ["18824"],
      Jackson: ["18825"],
      Kingsley: ["18826"],
      Lanesboro: ["18827"],
      Lawton: ["18828"],
      "Le raysville": ["18829"],
      "Little meadows": ["18830"],
      Milan: ["18831"],
      Monroeton: ["18832"],
      "New albany": ["18833"],
      "New milford": ["18834"],
      Rome: ["18837"],
      Rushville: ["18839"],
      Sayre: ["18840"],
      "South gibson": ["18842"],
      Springville: ["18844"],
      Stevensville: ["18845"],
      "Sugar run": ["18846"],
      Susquehanna: ["18847"],
      Towanda: ["18848"],
      Ulster: ["18850"],
      "Warren center": ["18851"],
      Wyalusing: ["18853"],
      Wysox: ["18854"],
      "Zcta 188hh": ["188HH"],
      "New britain": ["18901"],
      Chalfont: ["18914"],
      Colmar: ["18915"],
      Dublin: ["18917"],
      Erwinna: ["18920"],
      Fountainville: ["18923"],
      Furlong: ["18925"],
      Hilltown: ["18927"],
      Jamison: ["18929"],
      Kintnersville: ["18930"],
      "Line lexington": ["18932"],
      Montgomeryville: ["18936"],
      "New hope": ["18938"],
      "George school": ["18940"],
      Ottsville: ["18942"],
      Perkasie: ["18944"],
      Pipersville: ["18947"],
      Quakertown: ["18951"],
      Richboro: ["18954"],
      Richlandtown: ["18955"],
      Sellersville: ["18960"],
      Silverdale: ["18962"],
      Bethton: ["18964"],
      Holland: ["18966"],
      Telford: ["18969"],
      Trumbauersville: ["18970"],
      "Upper black eddy": ["18972"],
      Warminster: ["18974"],
      Warrington: ["18976"],
      "Washington cross": ["18977"],
      Wycombe: ["18980"],
      "Zcta 189hh": ["189HH"],
      "Ogontz campus": ["19001"],
      "Maple glen": ["19002"],
      Ardmore: ["19003"],
      "Bala cynwyd": ["19004"],
      "Huntingdon valle": ["19006"],
      Tullytown: ["19007"],
      Broomall: ["19008"],
      "Bryn athyn": ["19009"],
      "Bryn mawr": ["19010"],
      Cheltenham: ["19012"],
      Chester: ["19013"],
      Aston: ["19014"],
      Brookhaven: ["19015"],
      "Primos secane": ["19018"],
      Bensalem: ["19020"],
      Croydon: ["19021"],
      "Crum lynne": ["19022"],
      Collingdale: ["19023"],
      Dresher: ["19025"],
      "Pilgrim gardens": ["19026"],
      "Zcta 19027": ["19027"],
      Lester: ["19029"],
      "Fairless hills": ["19030"],
      Flourtown: ["19031"],
      Folcroft: ["19032"],
      Folsom: ["19033"],
      "Fort washington": ["19034"],
      Gladwyne: ["19035"],
      Glenolden: ["19036"],
      Glenside: ["19038"],
      Hatboro: ["19040"],
      Haverford: ["19041"],
      Holmes: ["19043"],
      Horsham: ["19044"],
      Meadowbrook: ["19046"],
      Penndel: ["19047"],
      Yeadon: ["19050"],
      "Feasterville tre": ["19053"],
      Levittown: ["19054", "19055", "19056", "19057"],
      Boothwyn: ["19061"],
      "Glen riddle lima": ["19063"],
      Springfield: ["19064"],
      "Merion station": ["19066"],
      Yardley: ["19067"],
      Morton: ["19070"],
      Narberth: ["19072"],
      "Newtown square": ["19073"],
      Norwood: ["19074"],
      Oreland: ["19075"],
      "Prospect park": ["19076"],
      "Ridley park": ["19078"],
      "Sharon hill": ["19079"],
      Swarthmore: ["19081"],
      "Upper darby": ["19082"],
      Havertown: ["19083"],
      Villanova: ["19085"],
      Wallingford: ["19086"],
      Radnor: ["19087"],
      "Willow grove nas": ["19090"],
      Woodlyn: ["19094"],
      Wyncote: ["19095"],
      Wynnewood: ["19096"],
      "Zcta 190hh": ["190HH"],
      Philadelphia: [
        "19102",
        "19103",
        "19104",
        "19106",
        "19107",
        "19108",
        "19111",
        "19112",
        "19113",
        "19114",
        "19115",
        "19116",
        "19118",
        "19119",
        "19120",
        "19121",
        "19122",
        "19123",
        "19124",
        "19125",
        "19126",
        "19127",
        "19128",
        "19129",
        "19130",
        "19131",
        "19132",
        "19133",
        "19134",
        "19135",
        "19136",
        "19137",
        "19138",
        "19139",
        "19140",
        "19141",
        "19142",
        "19143",
        "19144",
        "19145",
        "19146",
        "19147",
        "19148",
        "19149",
        "19150",
        "19151",
        "19152",
        "19153",
        "19154",
      ],
      "Zcta 191hh": ["191HH"],
      Paoli: ["19301"],
      Atglen: ["19310"],
      Avondale: ["19311"],
      Berwyn: ["19312"],
      Brandamore: ["19316"],
      "Chadds ford": ["19317"],
      Cheyney: ["19319"],
      Coatesville: ["19320"],
      Cochranville: ["19330"],
      Devon: ["19333"],
      Downingtown: ["19335"],
      Exton: ["19341"],
      "Glen mills": ["19342"],
      Glenmoore: ["19343"],
      "Honey brook": ["19344"],
      Immaculata: ["19345"],
      "Kennett square": ["19348"],
      Landenberg: ["19350"],
      "Lincoln universi": ["19352"],
      Frazer: ["19355"],
      Modena: ["19358"],
      Nottingham: ["19362"],
      Oxford: ["19363"],
      Parkesburg: ["19365"],
      Pomeroy: ["19367"],
      Sadsburyville: ["19369"],
      Thorndale: ["19372"],
      Thornton: ["19373"],
      Toughkenamon: ["19374"],
      "West chester": ["19380", "19382"],
      "West grove": ["19390"],
      "Zcta 193hh": ["193HH"],
      Norristown: ["19401"],
      Eagleville: ["19403"],
      Bridgeport: ["19405"],
      "King of prussia": ["19406"],
      Birchrunville: ["19421"],
      Penllyn: ["19422"],
      "Chester springs": ["19425"],
      Collegeville: ["19426"],
      "West conshohocke": ["19428"],
      Frederick: ["19435"],
      Gwynedd: ["19436"],
      "Gwynedd valley": ["19437"],
      Harleysville: ["19438"],
      Hatfield: ["19440"],
      "Lafayette hill": ["19444"],
      Lansdale: ["19446"],
      "Mont clare": ["19453"],
      "North wales": ["19454"],
      "Parker ford": ["19457"],
      Phoenixville: ["19460"],
      "Plymouth meeting": ["19462"],
      Sanatoga: ["19464"],
      Pottstown: ["19465"],
      Limerick: ["19468"],
      Sassamansville: ["19472"],
      Schwenksville: ["19473"],
      Skippack: ["19474"],
      "Spring city": ["19475"],
      "Spring mount": ["19478"],
      Zieglersville: ["19492"],
      "Zcta 194hh": ["194HH"],
      Adamstown: ["19501"],
      Bally: ["19503"],
      Barto: ["19504"],
      Bechtelsville: ["19505"],
      Bernville: ["19506"],
      Bethel: ["19507"],
      Birdsboro: ["19508"],
      Blandon: ["19510"],
      Bowers: ["19511"],
      Boyertown: ["19512"],
      Centerport: ["19516"],
      Douglassville: ["19518"],
      Earlville: ["19519"],
      Elverson: ["19520"],
      Evansville: ["19522"],
      Geigertown: ["19523"],
      Gilbertsville: ["19525"],
      Hamburg: ["19526"],
      Kempton: ["19529"],
      Kutztown: ["19530"],
      Leesport: ["19533"],
      Lenhartsville: ["19534"],
      "Lyon station": ["19536"],
      Mertztown: ["19539"],
      Mohnton: ["19540"],
      Mohrsville: ["19541"],
      Morgantown: ["19543"],
      "Mount aetna": ["19544"],
      "New berlinville": ["19545"],
      Oley: ["19547"],
      "Port clinton": ["19549"],
      Rehrersburg: ["19550"],
      Robesonia: ["19551"],
      Shartlesville: ["19554"],
      Shoemakersville: ["19555"],
      Strausstown: ["19559"],
      Temple: ["19560"],
      Topton: ["19562"],
      Wernersville: ["19565"],
      Womelsdorf: ["19567"],
      "Zcta 195hh": ["195HH"],
      Reading: ["19601", "19602", "19604", "19605", "19611"],
      "Mount penn": ["19606"],
      Shillington: ["19607"],
      "Sinking spring": ["19608"],
      "West lawn": ["19609"],
      Wyomissing: ["19610"],
      "Zcta 196hh": ["196HH"],
    },
  },
  {
    abbrevation: "RI",
    name: "Rhode island",
    Cities: [
      "Ashaway",
      "Ashaway",
      "Barrington",
      "Block island",
      "Bradford",
      "Bristol",
      "Richmond",
      "Charlestown",
      "Chepachet",
      "Clayville",
      "Coventry",
      "West greenwich",
      "East greenwich",
      "Exeter",
      "Foster",
      "Greene",
      "Greenville",
      "Harrisville",
      "Hope",
      "Hopkinton",
      "Jamestown",
      "Little compton",
      "Manville",
      "Mapleville",
      "Middletown",
      "North kingstown",
      "North scituate",
      "Oakland",
      "Pascoag",
      "Pawtucket",
      "Central falls",
      "Cumberland",
      "Lincoln",
      "Portsmouth",
      "Saunderstown",
      "Tiverton",
      "Narragansett",
      "Kingston",
      "Warren",
      "Warwick",
      "Westerly",
      "West warwick",
      "Wood river junct",
      "North smithfield",
      "Zcta 028hh",
      "Providence",
      "Centredale",
      "Cranston",
      "East providence",
      "Riverside",
      "Rumford",
      "Smithfield",
      "Zcta 029hh",
    ],
    ZipCodes: {
      Ashaway: ["02804"],
      Barrington: ["02806"],
      "Block island": ["02807"],
      Bradford: ["02808"],
      Bristol: ["02809"],
      Richmond: ["02812", "02832", "02836", "02875", "02892", "02898"],
      Charlestown: ["02813"],
      Chepachet: ["02814"],
      Clayville: ["02815"],
      Coventry: ["02816"],
      "West greenwich": ["02817"],
      "East greenwich": ["02818"],
      Exeter: ["02822"],
      Foster: ["02825"],
      Greene: ["02827"],
      Greenville: ["02828"],
      Harrisville: ["02830"],
      Hope: ["02831"],
      Hopkinton: ["02833"],
      Jamestown: ["02835"],
      "Little compton": ["02837"],
      Manville: ["02838"],
      Mapleville: ["02839"],
      Middletown: ["02840", "02842"],
      "North kingstown": ["02852"],
      "North scituate": ["02857"],
      Oakland: ["02858"],
      Pascoag: ["02859"],
      Pawtucket: ["02860", "02861"],
      "Central falls": ["02863"],
      Cumberland: ["02864"],
      Lincoln: ["02865"],
      Portsmouth: ["02871"],
      Saunderstown: ["02874"],
      Tiverton: ["02878"],
      Narragansett: ["02879", "02882"],
      Kingston: ["02881"],
      Warren: ["02885"],
      Warwick: ["02886", "02888", "02889"],
      Westerly: ["02891"],
      "West warwick": ["02893"],
      "Wood river junct": ["02894"],
      "North smithfield": ["02895", "02896"],
      "Zcta 028hh": ["028HH"],
      Providence: ["02903", "02906", "02908"],
      Centredale: ["02904", "02911"],
      Cranston: ["02905", "02907", "02909", "02910", "02919", "02920", "02921"],
      "East providence": ["02914"],
      Riverside: ["02915"],
      Rumford: ["02916"],
      Smithfield: ["02917"],
      "Zcta 029hh": ["029HH"],
    },
  },
  {
    abbrevation: "SC",
    name: "South carolina",
    Cities: [
      "Alcolu",
      "Alcolu",
      "Bamberg",
      "Batesburg",
      "Bethune",
      "Bishopville",
      "Blackstock",
      "Blair",
      "Blythewood",
      "Bowman",
      "Camden",
      "Cameron",
      "Carlisle",
      "Cassatt",
      "Cayce",
      "Chapin",
      "Chappells",
      "Cope",
      "Cordova",
      "Dalzell",
      "Denmark",
      "Eastover",
      "Elgin",
      "Elliott",
      "Elloree",
      "Eutawville",
      "Gable",
      "Gadsden",
      "Gaston",
      "Gilbert",
      "Great falls",
      "Greeleyville",
      "Heath springs",
      "Holly hill",
      "Hopkins",
      "Horatio",
      "Irmo",
      "Jenkinsville",
      "Kershaw",
      "Lamar",
      "Leesville",
      "Lexington",
      "Liberty hill",
      "Little mountain",
      "Lugoff",
      "Lydia",
      "Lynchburg",
      "Ehrhardt",
      "Lodge",
      "Zcta 290hh",
      "Zcta 290xx",
      "Mc bee",
      "Paxville",
      "Saint charles",
      "Monetta",
      "Neeses",
      "Newberry",
      "New zion",
      "North",
      "Norway",
      "Olanta",
      "Orangeburg",
      "Zcta 29118",
      "Peak",
      "Pelion",
      "Perry",
      "Pinewood",
      "Pomaria",
      "Prosperity",
      "Rembert",
      "Ridge spring",
      "Ridgeway",
      "Rion",
      "Rowesville",
      "Fort motte",
      "Salley",
      "Saluda",
      "Santee",
      "Silverstreet",
      "Springfield",
      "Summerton",
      "Oswego",
      "Shaw a f b",
      "Sumter",
      "Swansea",
      "Timmonsville",
      "Turbeville",
      "Vance",
      "Wagener",
      "Ward",
      "Wedgefield",
      "West columbia",
      "Westville",
      "White rock",
      "Whitmire",
      "Winnsboro",
      "Zcta 291hh",
      "Zcta 291xx",
      "Columbia",
      "Zcta 292hh",
      "Zcta 292xx",
      "Spartanburg",
      "Valley falls",
      "Arcadia",
      "Buffalo",
      "Campobello",
      "Chesnee",
      "Clinton",
      "Cowpens",
      "Cross hill",
      "Duncan",
      "Enoree",
      "Fingerville",
      "Gaffney",
      "Inman",
      "Joanna",
      "Kelton",
      "Kinards",
      "Landrum",
      "Ora",
      "Lockhart",
      "Lyman",
      "Moore",
      "Mountville",
      "Pacolet",
      "Pacolet mills",
      "Glenn springs",
      "Reidville",
      "Roebuck",
      "Startex",
      "Union",
      "Waterloo",
      "Wellford",
      "Woodruff",
      "Zcta 293hh",
      "Charleston",
      "North charleston",
      "Jericho",
      "Awendaw",
      "Bethera",
      "Bonneau",
      "Branchville",
      "Cordesville",
      "Cottageville",
      "Cross",
      "Dorchester",
      "Edisto island",
      "Folly beach",
      "Georgetown",
      "Mount holly",
      "Green pond",
      "Harleyville",
      "Meggett",
      "Huger",
      "Isle of palms",
      "Jacksonboro",
      "Shulerville",
      "Johns island",
      "Ladson",
      "Mc clellanville",
      "Oakley",
      "Mount pleasant",
      "Zcta 29466",
      "Pineville",
      "Pinopolis",
      "Ravenel",
      "Reevesville",
      "Ridgeville",
      "Round o",
      "Ruffin",
      "Saint george",
      "Alvin",
      "Smoaks",
      "Sullivans island",
      "Summerville",
      "Wadmalaw island",
      "Ritter",
      "Wando",
      "Williams",
      "Zcta 294hh",
      "Zcta 294xx",
      "Florence",
      "Quinby",
      "Andrews",
      "Aynor",
      "Bennettsville",
      "Blenheim",
      "Cades",
      "Centenary",
      "Cheraw",
      "Clio",
      "Conway",
      "Bucksport",
      "Coward",
      "Darlington",
      "Dillon",
      "Effingham",
      "Fork",
      "Galivants ferry",
      "Green sea",
      "Gresham",
      "South of the bor",
      "Hartsville",
      "Hemingway",
      "Johnsonville",
      "Kingstree",
      "Lake city",
      "Lake view",
      "Lane",
      "Latta",
      "Little river",
      "Little rock",
      "Longs",
      "Loris",
      "Mc coll",
      "Marion",
      "Myrtle beach",
      "Mullins",
      "Surfside beach",
      "Murrells inlet",
      "Myrtle beach afb",
      "Nesmith",
      "Nichols",
      "Cherry grove bea",
      "Pamplico",
      "Patrick",
      "Pawleys island",
      "Rains",
      "Salters",
      "Scranton",
      "Sellers",
      "Society hill",
      "Tatum",
      "Wallace",
      "Zcta 295hh",
      "Zcta 295xx",
      "Greenville",
      "Zcta 29617",
      "Abbeville",
      "Anderson",
      "Zcta 29626",
      "Belton",
      "Calhoun falls",
      "Central",
      "Clemson",
      "Cleveland",
      "Shoals junction",
      "Due west",
      "Easley",
      "Fair play",
      "Fountain inn",
      "Greenwood",
      "Greer",
      "Hodges",
      "Honea path",
      "Iva",
      "La france",
      "Liberty",
      "Long creek",
      "Lowndesville",
      "Marietta",
      "Mauldin",
      "Mountain rest",
      "Newry",
      "Ninety six",
      "Cateechee",
      "Pelzer",
      "Pendleton",
      "Pickens",
      "Zcta 29672",
      "Piedmont",
      "Salem",
      "Sandy springs",
      "Seneca",
      "Zcta 29680",
      "Simpsonville",
      "Six mile",
      "Slater",
      "Starr",
      "Sunset",
      "Tamassee",
      "Taylors",
      "Tigerville",
      "Townville",
      "Travelers rest",
      "Walhalla",
      "Ware shoals",
      "Madison",
      "West union",
      "Williamston",
      "Zcta 296hh",
      "Cherokee falls",
      "Catawba",
      "Chester",
      "Zcta 29708",
      "Chesterfield",
      "Lake wylie",
      "Edgemoor",
      "Fort lawn",
      "Tega cay",
      "Hickory grove",
      "Jefferson",
      "Lancaster",
      "Lando",
      "Mc connells",
      "Mount croghan",
      "Pageland",
      "Richburg",
      "Rock hill",
      "Ruby",
      "Sharon",
      "Smyrna",
      "York",
      "Zcta 297hh",
      "Aiken",
      "Zcta 29805",
      "New ellenton",
      "Allendale",
      "Barnwell",
      "Bath",
      "Blackville",
      "Bradley",
      "Clarks hill",
      "Clearwater",
      "Edgefield",
      "Elko",
      "Fairfax",
      "Gloverville",
      "Graniteville",
      "Jackson",
      "Johnston",
      "Langley",
      "Mc cormick",
      "Martin",
      "Modoc",
      "Mount carmel",
      "Beech island",
      "Olar",
      "Parksville",
      "Plum branch",
      "Sycamore",
      "Trenton",
      "Troy",
      "Ulmer",
      "Vaucluse",
      "Warrenville",
      "Williston",
      "Windsor",
      "Zcta 29860",
      "Zcta 298hh",
      "Zcta 298xx",
      "Burton",
      "Zcta 29906",
      "Bluffton",
      "Brunson",
      "Early branch",
      "Estill",
      "St helena island",
      "Furman",
      "Garnett",
      "Gifford",
      "Hampton",
      "Hilton head isla",
      "Hardeeville",
      "Islandton",
      "Luray",
      "Pineland",
      "Port royal",
      "Coosawatchie",
      "Scotia",
      "Seabrook",
      "Sheldon",
      "Tillman",
      "Varnville",
      "Yemassee",
      "Zcta 299hh",
      "Zcta 299xx",
    ],
    ZipCodes: {
      Alcolu: ["29001"],
      Bamberg: ["29003"],
      Batesburg: ["29006"],
      Bethune: ["29009"],
      Bishopville: ["29010"],
      Blackstock: ["29014"],
      Blair: ["29015"],
      Blythewood: ["29016"],
      Bowman: ["29018"],
      Camden: ["29020"],
      Cameron: ["29030"],
      Carlisle: ["29031"],
      Cassatt: ["29032"],
      Cayce: ["29033"],
      Chapin: ["29036"],
      Chappells: ["29037"],
      Cope: ["29038"],
      Cordova: ["29039"],
      Dalzell: ["29040"],
      Denmark: ["29042"],
      Eastover: ["29044"],
      Elgin: ["29045"],
      Elliott: ["29046"],
      Elloree: ["29047"],
      Eutawville: ["29048"],
      Gable: ["29051"],
      Gadsden: ["29052"],
      Gaston: ["29053"],
      Gilbert: ["29054"],
      "Great falls": ["29055"],
      Greeleyville: ["29056"],
      "Heath springs": ["29058"],
      "Holly hill": ["29059"],
      Hopkins: ["29061"],
      Horatio: ["29062"],
      Irmo: ["29063"],
      Jenkinsville: ["29065"],
      Kershaw: ["29067"],
      Lamar: ["29069"],
      Leesville: ["29070"],
      Lexington: ["29072", "29073"],
      "Liberty hill": ["29074"],
      "Little mountain": ["29075"],
      Lugoff: ["29078"],
      Lydia: ["29079"],
      Lynchburg: ["29080"],
      Ehrhardt: ["29081"],
      Lodge: ["29082"],
      "Zcta 290hh": ["290HH"],
      "Zcta 290xx": ["290XX"],
      "Mc bee": ["29101"],
      Paxville: ["29102"],
      "Saint charles": ["29104"],
      Monetta: ["29105"],
      Neeses: ["29107"],
      Newberry: ["29108"],
      "New zion": ["29111"],
      North: ["29112"],
      Norway: ["29113"],
      Olanta: ["29114"],
      Orangeburg: ["29115"],
      "Zcta 29118": ["29118"],
      Peak: ["29122"],
      Pelion: ["29123"],
      Perry: ["29124"],
      Pinewood: ["29125"],
      Pomaria: ["29126"],
      Prosperity: ["29127"],
      Rembert: ["29128"],
      "Ridge spring": ["29129"],
      Ridgeway: ["29130"],
      Rion: ["29132"],
      Rowesville: ["29133"],
      "Fort motte": ["29135"],
      Salley: ["29137"],
      Saluda: ["29138"],
      Santee: ["29142"],
      Silverstreet: ["29145"],
      Springfield: ["29146"],
      Summerton: ["29148"],
      Oswego: ["29150"],
      "Shaw a f b": ["29152"],
      Sumter: ["29153", "29154"],
      Swansea: ["29160"],
      Timmonsville: ["29161"],
      Turbeville: ["29162"],
      Vance: ["29163"],
      Wagener: ["29164"],
      Ward: ["29166"],
      Wedgefield: ["29168"],
      "West columbia": ["29169", "29170", "29172"],
      Westville: ["29175"],
      "White rock": ["29177"],
      Whitmire: ["29178"],
      Winnsboro: ["29180"],
      "Zcta 291hh": ["291HH"],
      "Zcta 291xx": ["291XX"],
      Columbia: [
        "29201",
        "29202",
        "29203",
        "29204",
        "29205",
        "29206",
        "29207",
        "29209",
        "29210",
        "29212",
        "29223",
        "29229",
      ],
      "Zcta 292hh": ["292HH"],
      "Zcta 292xx": ["292XX"],
      Spartanburg: ["29301", "29302", "29306", "29307", "29316"],
      "Valley falls": ["29303"],
      Arcadia: ["29320"],
      Buffalo: ["29321"],
      Campobello: ["29322"],
      Chesnee: ["29323"],
      Clinton: ["29325"],
      Cowpens: ["29330"],
      "Cross hill": ["29332"],
      Duncan: ["29334"],
      Enoree: ["29335"],
      Fingerville: ["29338"],
      Gaffney: ["29340", "29341"],
      Inman: ["29349"],
      Joanna: ["29351"],
      Kelton: ["29353"],
      Kinards: ["29355"],
      Landrum: ["29356"],
      Ora: ["29360", "29645"],
      Lockhart: ["29364"],
      Lyman: ["29365"],
      Moore: ["29369"],
      Mountville: ["29370"],
      Pacolet: ["29372"],
      "Pacolet mills": ["29373"],
      "Glenn springs": ["29374"],
      Reidville: ["29375"],
      Roebuck: ["29376"],
      Startex: ["29377"],
      Union: ["29379"],
      Waterloo: ["29384"],
      Wellford: ["29385"],
      Woodruff: ["29388"],
      "Zcta 293hh": ["293HH"],
      Charleston: [
        "29401",
        "29403",
        "29404",
        "29405",
        "29407",
        "29412",
        "29414",
        "29418",
        "29420",
        "29423",
      ],
      "North charleston": ["29406"],
      Jericho: ["29426"],
      Awendaw: ["29429"],
      Bethera: ["29430"],
      Bonneau: ["29431"],
      Branchville: ["29432"],
      Cordesville: ["29434"],
      Cottageville: ["29435"],
      Cross: ["29436"],
      Dorchester: ["29437"],
      "Edisto island": ["29438"],
      "Folly beach": ["29439"],
      Georgetown: ["29440"],
      "Mount holly": ["29445"],
      "Green pond": ["29446"],
      Harleyville: ["29448"],
      Meggett: ["29449"],
      Huger: ["29450"],
      "Isle of palms": ["29451"],
      Jacksonboro: ["29452"],
      Shulerville: ["29453"],
      "Johns island": ["29455"],
      Ladson: ["29456"],
      "Mc clellanville": ["29458"],
      Oakley: ["29461"],
      "Mount pleasant": ["29464"],
      "Zcta 29466": ["29466"],
      Pineville: ["29468"],
      Pinopolis: ["29469"],
      Ravenel: ["29470"],
      Reevesville: ["29471"],
      Ridgeville: ["29472"],
      "Round o": ["29474"],
      Ruffin: ["29475"],
      "Saint george": ["29477"],
      Alvin: ["29479"],
      Smoaks: ["29481"],
      "Sullivans island": ["29482"],
      Summerville: ["29483", "29485"],
      "Wadmalaw island": ["29487"],
      Ritter: ["29488"],
      Wando: ["29492"],
      Williams: ["29493"],
      "Zcta 294hh": ["294HH"],
      "Zcta 294xx": ["294XX"],
      Florence: ["29501", "29505"],
      Quinby: ["29506"],
      Andrews: ["29510"],
      Aynor: ["29511"],
      Bennettsville: ["29512"],
      Blenheim: ["29516"],
      Cades: ["29518"],
      Centenary: ["29519"],
      Cheraw: ["29520"],
      Clio: ["29525"],
      Conway: ["29526"],
      Bucksport: ["29527"],
      Coward: ["29530"],
      Darlington: ["29532", "29540"],
      Dillon: ["29536"],
      Effingham: ["29541"],
      Fork: ["29543"],
      "Galivants ferry": ["29544"],
      "Green sea": ["29545"],
      Gresham: ["29546"],
      "South of the bor": ["29547"],
      Hartsville: ["29550"],
      Hemingway: ["29554"],
      Johnsonville: ["29555"],
      Kingstree: ["29556"],
      "Lake city": ["29560"],
      "Lake view": ["29563"],
      Lane: ["29564"],
      Latta: ["29565"],
      "Little river": ["29566"],
      "Little rock": ["29567"],
      Longs: ["29568"],
      Loris: ["29569"],
      "Mc coll": ["29570"],
      Marion: ["29571"],
      "Myrtle beach": ["29572", "29577"],
      Mullins: ["29574"],
      "Surfside beach": ["29575"],
      "Murrells inlet": ["29576"],
      "Myrtle beach afb": ["29579"],
      Nesmith: ["29580"],
      Nichols: ["29581"],
      "Cherry grove bea": ["29582"],
      Pamplico: ["29583"],
      Patrick: ["29584"],
      "Pawleys island": ["29585"],
      Rains: ["29589"],
      Salters: ["29590"],
      Scranton: ["29591"],
      Sellers: ["29592"],
      "Society hill": ["29593"],
      Tatum: ["29594"],
      Wallace: ["29596"],
      "Zcta 295hh": ["295HH"],
      "Zcta 295xx": ["295XX"],
      Greenville: ["29601", "29605", "29607", "29609", "29611", "29615"],
      "Zcta 29617": ["29617"],
      Abbeville: ["29620"],
      Anderson: ["29621", "29624", "29625"],
      "Zcta 29626": ["29626"],
      Belton: ["29627"],
      "Calhoun falls": ["29628"],
      Central: ["29630"],
      Clemson: ["29631"],
      Cleveland: ["29635"],
      "Shoals junction": ["29638"],
      "Due west": ["29639"],
      Easley: ["29640", "29642"],
      "Fair play": ["29643"],
      "Fountain inn": ["29644"],
      Greenwood: ["29646", "29649"],
      Greer: ["29650", "29651"],
      Hodges: ["29653"],
      "Honea path": ["29654"],
      Iva: ["29655"],
      "La france": ["29656"],
      Liberty: ["29657"],
      "Long creek": ["29658"],
      Lowndesville: ["29659"],
      Marietta: ["29661"],
      Mauldin: ["29662"],
      "Mountain rest": ["29664"],
      Newry: ["29665"],
      "Ninety six": ["29666"],
      Cateechee: ["29667"],
      Pelzer: ["29669"],
      Pendleton: ["29670"],
      Pickens: ["29671"],
      "Zcta 29672": ["29672"],
      Piedmont: ["29673"],
      Salem: ["29676"],
      "Sandy springs": ["29677"],
      Seneca: ["29678"],
      "Zcta 29680": ["29680"],
      Simpsonville: ["29681"],
      "Six mile": ["29682"],
      Slater: ["29683"],
      Starr: ["29684"],
      Sunset: ["29685"],
      Tamassee: ["29686"],
      Taylors: ["29687"],
      Tigerville: ["29688"],
      Townville: ["29689"],
      "Travelers rest": ["29690"],
      Walhalla: ["29691"],
      "Ware shoals": ["29692"],
      Madison: ["29693"],
      "West union": ["29696"],
      Williamston: ["29697"],
      "Zcta 296hh": ["296HH"],
      "Cherokee falls": ["29702"],
      Catawba: ["29704"],
      Chester: ["29706"],
      "Zcta 29708": ["29708"],
      Chesterfield: ["29709"],
      "Lake wylie": ["29710"],
      Edgemoor: ["29712"],
      "Fort lawn": ["29714"],
      "Tega cay": ["29715"],
      "Hickory grove": ["29717"],
      Jefferson: ["29718"],
      Lancaster: ["29720"],
      Lando: ["29724"],
      "Mc connells": ["29726"],
      "Mount croghan": ["29727"],
      Pageland: ["29728"],
      Richburg: ["29729"],
      "Rock hill": ["29730", "29732"],
      Ruby: ["29741"],
      Sharon: ["29742"],
      Smyrna: ["29743"],
      York: ["29745"],
      "Zcta 297hh": ["297HH"],
      Aiken: ["29801", "29803"],
      "Zcta 29805": ["29805"],
      "New ellenton": ["29809"],
      Allendale: ["29810"],
      Barnwell: ["29812"],
      Bath: ["29816"],
      Blackville: ["29817"],
      Bradley: ["29819"],
      "Clarks hill": ["29821"],
      Clearwater: ["29822"],
      Edgefield: ["29824"],
      Elko: ["29826"],
      Fairfax: ["29827"],
      Gloverville: ["29828"],
      Graniteville: ["29829"],
      Jackson: ["29831"],
      Johnston: ["29832"],
      Langley: ["29834"],
      "Mc cormick": ["29835"],
      Martin: ["29836"],
      Modoc: ["29838"],
      "Mount carmel": ["29840"],
      "Beech island": ["29841", "29842"],
      Olar: ["29843"],
      Parksville: ["29844"],
      "Plum branch": ["29845"],
      Sycamore: ["29846"],
      Trenton: ["29847"],
      Troy: ["29848"],
      Ulmer: ["29849"],
      Vaucluse: ["29850"],
      Warrenville: ["29851"],
      Williston: ["29853"],
      Windsor: ["29856"],
      "Zcta 29860": ["29860"],
      "Zcta 298hh": ["298HH"],
      "Zcta 298xx": ["298XX"],
      Burton: ["29902"],
      "Zcta 29906": ["29906"],
      Bluffton: ["29910"],
      Brunson: ["29911"],
      "Early branch": ["29916"],
      Estill: ["29918"],
      "St helena island": ["29920"],
      Furman: ["29921"],
      Garnett: ["29922"],
      Gifford: ["29923"],
      Hampton: ["29924"],
      "Hilton head isla": ["29926", "29928"],
      Hardeeville: ["29927"],
      Islandton: ["29929"],
      Luray: ["29932"],
      Pineland: ["29934"],
      "Port royal": ["29935"],
      Coosawatchie: ["29936"],
      Scotia: ["29939"],
      Seabrook: ["29940"],
      Sheldon: ["29941"],
      Tillman: ["29943"],
      Varnville: ["29944"],
      Yemassee: ["29945"],
      "Zcta 299hh": ["299HH"],
      "Zcta 299xx": ["299XX"],
    },
  },
  {
    abbrevation: "SD",
    name: "South dakota",
    Cities: [
      "",
      "",
      "Alcester",
      "Aurora",
      "Baltic",
      "Beresford",
      "Corson",
      "Brookings",
      "Burbank",
      "Canistota",
      "Canton",
      "Centerville",
      "Chancellor",
      "Chester",
      "Colman",
      "Colton",
      "Crooks",
      "Davis",
      "Dell rapids",
      "Egan",
      "Elk point",
      "Elkton",
      "Fairview",
      "Flandreau",
      "Freeman",
      "Garretson",
      "Gayville",
      "Harrisburg",
      "Hartford",
      "Hudson",
      "Humboldt",
      "Hurley",
      "Irene",
      "Jefferson",
      "Lennox",
      "Lesterville",
      "Lyons",
      "Madison",
      "Marion",
      "Meckling",
      "Menno",
      "Mission hill",
      "Monroe",
      "Montrose",
      "Dakota dunes",
      "Nunda",
      "Oldham",
      "Olivet",
      "Parker",
      "Ramona",
      "Renner",
      "Rutland",
      "Salem",
      "Scotland",
      "Sinai",
      "Springfield",
      "Tabor",
      "Tea",
      "Trent",
      "Tyndall",
      "Utica",
      "Valley springs",
      "Vermillion",
      "Viborg",
      "Volga",
      "Volin",
      "Wakonda",
      "Ward",
      "Wentworth",
      "Winfred",
      "Worthing",
      "Yankton",
      "Zcta 570hh",
      "Sioux falls",
      "Zcta 57108",
      "Zcta 57110",
      "Watertown",
      "Arlington",
      "Astoria",
      "Badger",
      "Big stone city",
      "Bradley",
      "Brandt",
      "Butler",
      "Bruce",
      "Bryant",
      "Castlewood",
      "Claire city",
      "Clark",
      "Altamont",
      "Corona",
      "De smet",
      "Eden",
      "Erwin",
      "Dempster",
      "Florence",
      "Garden city",
      "Gary",
      "Bemis",
      "Grenville",
      "Hayti",
      "Hazel",
      "Henry",
      "Hetland",
      "Kranzburg",
      "Labolt",
      "Lake city",
      "Lake norden",
      "Lake preston",
      "Marvin",
      "Milbank",
      "New effington",
      "Ortley",
      "Peever",
      "Raymond",
      "Albee",
      "Rosholt",
      "Roslyn",
      "Agency village",
      "South shore",
      "Stockholm",
      "Strandburg",
      "Summit",
      "Toronto",
      "Twin brooks",
      "Veblen",
      "Vienna",
      "Wallace",
      "Waubay",
      "Lily",
      "White",
      "Willow lake",
      "Wilmot",
      "Zcta 572hh",
      "Loomis",
      "Farmer",
      "Alpena",
      "Armour",
      "Forestburg",
      "Avon",
      "Bonesteel",
      "Dolton",
      "Canova",
      "Carpenter",
      "Carthage",
      "Cavour",
      "Chamberlain",
      "Corsica",
      "Dante",
      "Delmont",
      "Dimock",
      "Emery",
      "Ethan",
      "Fairfax",
      "Fedora",
      "Fort thompson",
      "Fulton",
      "Gann valley",
      "Geddes",
      "Harrison",
      "Highmore",
      "Stephan",
      "Hitchcock",
      "Roswell",
      "Huron",
      "Iroquois",
      "Kimball",
      "Lake andes",
      "Lane",
      "Letcher",
      "Marty",
      "Miller",
      "Mount vernon",
      "New holland",
      "Oacoma",
      "Parkston",
      "Pickstown",
      "Plankinton",
      "Academy",
      "Pukwana",
      "Ree heights",
      "Saint lawrence",
      "Spencer",
      "Stickney",
      "Tripp",
      "Virgil",
      "Wagner",
      "Wessington",
      "Wessington sprin",
      "White lake",
      "Wolsey",
      "Woonsocket",
      "Yale",
      "Zcta 573hh",
      "Aberdeen",
      "Akaska",
      "Amherst",
      "Andover",
      "Athol",
      "Barnard",
      "Bath",
      "Bowdle",
      "Britton",
      "Claremont",
      "Columbia",
      "Verdon",
      "Cresbard",
      "Doland",
      "Artas",
      "Miranda",
      "Frankfort",
      "Frederick",
      "Gettysburg",
      "Groton",
      "Hecla",
      "Hosmer",
      "Houghton",
      "Hoven",
      "Ipswich",
      "Java",
      "Langford",
      "Lebanon",
      "Leola",
      "Longlake",
      "Mansfield",
      "Mellette",
      "Mina",
      "Northville",
      "Onaka",
      "Orient",
      "Pierpont",
      "Redfield",
      "Rockham",
      "Roscoe",
      "Selby",
      "Seneca",
      "Stratford",
      "Tolstoy",
      "Tulare",
      "Turton",
      "Warner",
      "Wetonka",
      "Zcta 574hh",
      "Pierre",
      "Agar",
      "Belvidere",
      "Blunt",
      "Lucas",
      "Carter",
      "Colome",
      "Dallas",
      "Draper",
      "Fort pierre",
      "Dixon",
      "Hamill",
      "Harrold",
      "Hayes",
      "Herrick",
      "Holabird",
      "Ideal",
      "Iona",
      "Kadoka",
      "Kennebec",
      "Long valley",
      "Lower brule",
      "Vetal",
      "Ottumwa",
      "Milesville",
      "Mission",
      "Murdo",
      "Norris",
      "Okaton",
      "Okreek",
      "Onida",
      "Parmelee",
      "Philip",
      "Presho",
      "Reliance",
      "Rosebud",
      "Saint charles",
      "Saint francis",
      "Tuthill",
      "Vivian",
      "Wanblee",
      "Wewela",
      "White river",
      "Clearfield",
      "Witten",
      "Wood",
      "Zcta 575hh",
      "Zcta 575xx",
      "Mobridge",
      "Bison",
      "Walker",
      "Cherry creek",
      "Dupree",
      "Eagle butte",
      "Faith",
      "Firesteel",
      "Glenham",
      "Herreid",
      "Isabel",
      "Keldron",
      "Lantry",
      "Lemmon",
      "Lodgepole",
      "Mc intosh",
      "Mc laughlin",
      "Meadow",
      "Morristown",
      "Mound city",
      "Pollock",
      "Prairie city",
      "Ralph",
      "Reva",
      "La plant",
      "Shadehill",
      "Timber lake",
      "Trail city",
      "Wakpala",
      "Watauga",
      "Whitehorse",
      "Zcta 576hh",
      "Rockerville",
      "Silver city",
      "Zcta 57703",
      "Ellsworth afb",
      "Allen",
      "Denby",
      "Belle fourche",
      "Black hawk",
      "Box elder",
      "Buffalo",
      "Buffalo gap",
      "Sky ranch",
      "Caputa",
      "Creighton",
      "Crazy horse",
      "Deadwood",
      "Edgemont",
      "Elm springs",
      "Enning",
      "Fairburn",
      "Fruitdale",
      "Hermosa",
      "Hill city",
      "Hot springs",
      "Plainview",
      "Interior",
      "Keystone",
      "Kyle",
      "Spearfish canyon",
      "Ludlow",
      "Manderson",
      "Mud butte",
      "Nemo",
      "Newell",
      "New underwood",
      "Nisland",
      "Oelrichs",
      "Oglala",
      "Opal",
      "Oral",
      "Owanka",
      "Piedmont",
      "Pine ridge",
      "Porcupine",
      "Pringle",
      "Provo",
      "Cottonwood",
      "Redowl",
      "Rochford",
      "Saint onge",
      "Scenic",
      "Spearfish",
      "Hereford",
      "Stoneville",
      "Vale",
      "Wall",
      "Wasta",
      "White owl",
      "Whitewood",
      "Wounded knee",
      "Black hills stat",
      "Zcta 577hh",
    ],
    ZipCodes: {
      "": ["56219", "562HH", "69337"],
      Alcester: ["57001"],
      Aurora: ["57002"],
      Baltic: ["57003"],
      Beresford: ["57004"],
      Corson: ["57005"],
      Brookings: ["57006"],
      Burbank: ["57010"],
      Canistota: ["57012"],
      Canton: ["57013"],
      Centerville: ["57014"],
      Chancellor: ["57015"],
      Chester: ["57016"],
      Colman: ["57017"],
      Colton: ["57018"],
      Crooks: ["57020"],
      Davis: ["57021"],
      "Dell rapids": ["57022"],
      Egan: ["57024"],
      "Elk point": ["57025"],
      Elkton: ["57026"],
      Fairview: ["57027"],
      Flandreau: ["57028"],
      Freeman: ["57029"],
      Garretson: ["57030"],
      Gayville: ["57031"],
      Harrisburg: ["57032"],
      Hartford: ["57033"],
      Hudson: ["57034"],
      Humboldt: ["57035"],
      Hurley: ["57036"],
      Irene: ["57037"],
      Jefferson: ["57038"],
      Lennox: ["57039"],
      Lesterville: ["57040"],
      Lyons: ["57041"],
      Madison: ["57042"],
      Marion: ["57043"],
      Meckling: ["57044"],
      Menno: ["57045"],
      "Mission hill": ["57046"],
      Monroe: ["57047"],
      Montrose: ["57048"],
      "Dakota dunes": ["57049"],
      Nunda: ["57050"],
      Oldham: ["57051"],
      Olivet: ["57052"],
      Parker: ["57053"],
      Ramona: ["57054"],
      Renner: ["57055"],
      Rutland: ["57057"],
      Salem: ["57058"],
      Scotland: ["57059"],
      Sinai: ["57061"],
      Springfield: ["57062"],
      Tabor: ["57063"],
      Tea: ["57064"],
      Trent: ["57065"],
      Tyndall: ["57066"],
      Utica: ["57067"],
      "Valley springs": ["57068"],
      Vermillion: ["57069"],
      Viborg: ["57070"],
      Volga: ["57071"],
      Volin: ["57072"],
      Wakonda: ["57073"],
      Ward: ["57074"],
      Wentworth: ["57075"],
      Winfred: ["57076"],
      Worthing: ["57077"],
      Yankton: ["57078"],
      "Zcta 570hh": ["570HH"],
      "Sioux falls": ["57103", "57104", "57105", "57106", "57107"],
      "Zcta 57108": ["57108"],
      "Zcta 57110": ["57110"],
      Watertown: ["57201"],
      Arlington: ["57212"],
      Astoria: ["57213"],
      Badger: ["57214"],
      "Big stone city": ["57216"],
      Bradley: ["57217"],
      Brandt: ["57218"],
      Butler: ["57219"],
      Bruce: ["57220"],
      Bryant: ["57221"],
      Castlewood: ["57223"],
      "Claire city": ["57224"],
      Clark: ["57225"],
      Altamont: ["57226"],
      Corona: ["57227"],
      "De smet": ["57231"],
      Eden: ["57232"],
      Erwin: ["57233"],
      Dempster: ["57234"],
      Florence: ["57235"],
      "Garden city": ["57236"],
      Gary: ["57237"],
      Bemis: ["57238"],
      Grenville: ["57239"],
      Hayti: ["57241"],
      Hazel: ["57242"],
      Henry: ["57243"],
      Hetland: ["57244"],
      Kranzburg: ["57245"],
      Labolt: ["57246"],
      "Lake city": ["57247"],
      "Lake norden": ["57248"],
      "Lake preston": ["57249"],
      Marvin: ["57251"],
      Milbank: ["57252"],
      "New effington": ["57255"],
      Ortley: ["57256"],
      Peever: ["57257"],
      Raymond: ["57258"],
      Albee: ["57259"],
      Rosholt: ["57260"],
      Roslyn: ["57261"],
      "Agency village": ["57262"],
      "South shore": ["57263"],
      Stockholm: ["57264"],
      Strandburg: ["57265"],
      Summit: ["57266"],
      Toronto: ["57268"],
      "Twin brooks": ["57269"],
      Veblen: ["57270"],
      Vienna: ["57271"],
      Wallace: ["57272"],
      Waubay: ["57273"],
      Lily: ["57274"],
      White: ["57276"],
      "Willow lake": ["57278"],
      Wilmot: ["57279"],
      "Zcta 572hh": ["572HH"],
      Loomis: ["57301"],
      Farmer: ["57311"],
      Alpena: ["57312"],
      Armour: ["57313"],
      Forestburg: ["57314"],
      Avon: ["57315"],
      Bonesteel: ["57317"],
      Dolton: ["57319"],
      Canova: ["57321"],
      Carpenter: ["57322"],
      Carthage: ["57323"],
      Cavour: ["57324"],
      Chamberlain: ["57325"],
      Corsica: ["57328"],
      Dante: ["57329"],
      Delmont: ["57330"],
      Dimock: ["57331"],
      Emery: ["57332"],
      Ethan: ["57334"],
      Fairfax: ["57335"],
      Fedora: ["57337"],
      "Fort thompson": ["57339"],
      Fulton: ["57340"],
      "Gann valley": ["57341"],
      Geddes: ["57342"],
      Harrison: ["57344"],
      Highmore: ["57345"],
      Stephan: ["57346"],
      Hitchcock: ["57348"],
      Roswell: ["57349"],
      Huron: ["57350"],
      Iroquois: ["57353"],
      Kimball: ["57355"],
      "Lake andes": ["57356"],
      Lane: ["57358"],
      Letcher: ["57359"],
      Marty: ["57361"],
      Miller: ["57362"],
      "Mount vernon": ["57363"],
      "New holland": ["57364"],
      Oacoma: ["57365"],
      Parkston: ["57366"],
      Pickstown: ["57367"],
      Plankinton: ["57368"],
      Academy: ["57369"],
      Pukwana: ["57370"],
      "Ree heights": ["57371"],
      "Saint lawrence": ["57373"],
      Spencer: ["57374"],
      Stickney: ["57375"],
      Tripp: ["57376"],
      Virgil: ["57379"],
      Wagner: ["57380"],
      Wessington: ["57381"],
      "Wessington sprin": ["57382"],
      "White lake": ["57383"],
      Wolsey: ["57384"],
      Woonsocket: ["57385"],
      Yale: ["57386"],
      "Zcta 573hh": ["573HH"],
      Aberdeen: ["57401"],
      Akaska: ["57420"],
      Amherst: ["57421"],
      Andover: ["57422"],
      Athol: ["57424"],
      Barnard: ["57426"],
      Bath: ["57427"],
      Bowdle: ["57428"],
      Britton: ["57430"],
      Claremont: ["57432"],
      Columbia: ["57433"],
      Verdon: ["57434"],
      Cresbard: ["57435"],
      Doland: ["57436"],
      Artas: ["57437"],
      Miranda: ["57438"],
      Frankfort: ["57440"],
      Frederick: ["57441"],
      Gettysburg: ["57442"],
      Groton: ["57445"],
      Hecla: ["57446"],
      Hosmer: ["57448"],
      Houghton: ["57449"],
      Hoven: ["57450"],
      Ipswich: ["57451"],
      Java: ["57452"],
      Langford: ["57454"],
      Lebanon: ["57455"],
      Leola: ["57456"],
      Longlake: ["57457"],
      Mansfield: ["57460"],
      Mellette: ["57461"],
      Mina: ["57462"],
      Northville: ["57465"],
      Onaka: ["57466"],
      Orient: ["57467"],
      Pierpont: ["57468"],
      Redfield: ["57469"],
      Rockham: ["57470"],
      Roscoe: ["57471"],
      Selby: ["57472"],
      Seneca: ["57473"],
      Stratford: ["57474"],
      Tolstoy: ["57475"],
      Tulare: ["57476"],
      Turton: ["57477"],
      Warner: ["57479"],
      Wetonka: ["57481"],
      "Zcta 574hh": ["574HH"],
      Pierre: ["57501"],
      Agar: ["57520"],
      Belvidere: ["57521"],
      Blunt: ["57522"],
      Lucas: ["57523"],
      Carter: ["57526"],
      Colome: ["57528"],
      Dallas: ["57529"],
      Draper: ["57531"],
      "Fort pierre": ["57532"],
      Dixon: ["57533"],
      Hamill: ["57534"],
      Harrold: ["57536"],
      Hayes: ["57537"],
      Herrick: ["57538"],
      Holabird: ["57540"],
      Ideal: ["57541"],
      Iona: ["57542"],
      Kadoka: ["57543"],
      Kennebec: ["57544"],
      "Long valley": ["57547"],
      "Lower brule": ["57548"],
      Vetal: ["57551"],
      Ottumwa: ["57552"],
      Milesville: ["57553"],
      Mission: ["57555"],
      Murdo: ["57559"],
      Norris: ["57560"],
      Okaton: ["57562"],
      Okreek: ["57563"],
      Onida: ["57564"],
      Parmelee: ["57566"],
      Philip: ["57567"],
      Presho: ["57568"],
      Reliance: ["57569"],
      Rosebud: ["57570"],
      "Saint charles": ["57571"],
      "Saint francis": ["57572"],
      Tuthill: ["57574"],
      Vivian: ["57576"],
      Wanblee: ["57577"],
      Wewela: ["57578"],
      "White river": ["57579"],
      Clearfield: ["57580"],
      Witten: ["57584"],
      Wood: ["57585"],
      "Zcta 575hh": ["575HH"],
      "Zcta 575xx": ["575XX"],
      Mobridge: ["57601"],
      Bison: ["57620"],
      Walker: ["57621"],
      "Cherry creek": ["57622"],
      Dupree: ["57623"],
      "Eagle butte": ["57625"],
      Faith: ["57626"],
      Firesteel: ["57628"],
      Glenham: ["57631"],
      Herreid: ["57632"],
      Isabel: ["57633"],
      Keldron: ["57634"],
      Lantry: ["57636"],
      Lemmon: ["57638"],
      Lodgepole: ["57640"],
      "Mc intosh": ["57641"],
      "Mc laughlin": ["57642"],
      Meadow: ["57644"],
      Morristown: ["57645"],
      "Mound city": ["57646"],
      Pollock: ["57648"],
      "Prairie city": ["57649"],
      Ralph: ["57650"],
      Reva: ["57651"],
      "La plant": ["57652"],
      Shadehill: ["57653"],
      "Timber lake": ["57656"],
      "Trail city": ["57657"],
      Wakpala: ["57658"],
      Watauga: ["57660"],
      Whitehorse: ["57661"],
      "Zcta 576hh": ["576HH"],
      Rockerville: ["57701"],
      "Silver city": ["57702"],
      "Zcta 57703": ["57703"],
      "Ellsworth afb": ["57706"],
      Allen: ["57714"],
      Denby: ["57716"],
      "Belle fourche": ["57717"],
      "Black hawk": ["57718"],
      "Box elder": ["57719"],
      Buffalo: ["57720"],
      "Buffalo gap": ["57722"],
      "Sky ranch": ["57724"],
      Caputa: ["57725"],
      Creighton: ["57729"],
      "Crazy horse": ["57730"],
      Deadwood: ["57732"],
      Edgemont: ["57735"],
      "Elm springs": ["57736"],
      Enning: ["57737"],
      Fairburn: ["57738"],
      Fruitdale: ["57742"],
      Hermosa: ["57744"],
      "Hill city": ["57745"],
      "Hot springs": ["57747"],
      Plainview: ["57748"],
      Interior: ["57750"],
      Keystone: ["57751"],
      Kyle: ["57752"],
      "Spearfish canyon": ["57754"],
      Ludlow: ["57755"],
      Manderson: ["57756"],
      "Mud butte": ["57758"],
      Nemo: ["57759"],
      Newell: ["57760"],
      "New underwood": ["57761"],
      Nisland: ["57762"],
      Oelrichs: ["57763"],
      Oglala: ["57764"],
      Opal: ["57765"],
      Oral: ["57766"],
      Owanka: ["57767"],
      Piedmont: ["57769"],
      "Pine ridge": ["57770"],
      Porcupine: ["57772"],
      Pringle: ["57773"],
      Provo: ["57774"],
      Cottonwood: ["57775"],
      Redowl: ["57777"],
      Rochford: ["57778"],
      "Saint onge": ["57779"],
      Scenic: ["57780"],
      Spearfish: ["57783"],
      Hereford: ["57785"],
      Stoneville: ["57787"],
      Vale: ["57788"],
      Wall: ["57790"],
      Wasta: ["57791"],
      "White owl": ["57792"],
      Whitewood: ["57793"],
      "Wounded knee": ["57794"],
      "Black hills stat": ["57799"],
      "Zcta 577hh": ["577HH"],
    },
  },
  {
    abbrevation: "TN",
    name: "Tennessee",
    Cities: [
      "Adams",
      "Adams",
      "Alexandria",
      "Antioch",
      "Arrington",
      "Ashland city",
      "Auburntown",
      "Beechgrove",
      "Belfast",
      "Bell buckle",
      "Bethpage",
      "Big rock",
      "Bon aqua",
      "Bradyville",
      "Brentwood",
      "Bumpus mills",
      "Burns",
      "Defeated",
      "Castalian spring",
      "Cedar hill",
      "Centerville",
      "Chapel hill",
      "Chapmansboro",
      "Charlotte",
      "Christiana",
      "Clarksville",
      "College grove",
      "Cornersville",
      "Cottontown",
      "Cross plains",
      "Cumberland city",
      "Cumberland furna",
      "Cunningham",
      "Dickson",
      "Dixon springs",
      "Dover",
      "Dowelltown",
      "Eagleville",
      "Erin",
      "Fairview",
      "Franklin",
      "Gallatin",
      "Zcta 37067",
      "Zcta 37069",
      "Goodlettsville",
      "Greenbrier",
      "Hartsville",
      "Hendersonville",
      "Hermitage",
      "Hurricane mills",
      "Indian mound",
      "Joelton",
      "Kingston springs",
      "Lafayette",
      "Lascassas",
      "La vergne",
      "Lebanon",
      "Zcta 37090",
      "Lewisburg",
      "Gassaway",
      "Flatwoods",
      "Lobelville",
      "Wrigley",
      "Zcta 370hh",
      "Zcta 370xx",
      "Mc ewen",
      "Plaza",
      "Madison",
      "Milton",
      "Mount juliet",
      "Zcta 37127",
      "Zcta 37128",
      "Murfreesboro",
      "New johnsonville",
      "Nolensville",
      "Nunnelly",
      "Old hickory",
      "Only",
      "Orlinda",
      "Palmyra",
      "Pegram",
      "Petersburg",
      "Pleasant shade",
      "Pleasant view",
      "Pleasantville",
      "Portland",
      "Readyville",
      "Red boiling spri",
      "Riddleton",
      "Ridgetop",
      "Rockvale",
      "Royal",
      "Smithville",
      "Smyrna",
      "Southside",
      "Springfield",
      "Spring hill",
      "Stewart",
      "Tennessee ridge",
      "Thompsons statio",
      "Unionville",
      "Vanleer",
      "Wartrace",
      "Watertown",
      "Waverly",
      "Westmoreland",
      "White bluff",
      "White house",
      "Whites creek",
      "Woodbury",
      "Woodlawn",
      "Zcta 371hh",
      "Zcta 371xx",
      "Nashville",
      "Melrose",
      "Bellevue",
      "Zcta 372hh",
      "Altamont",
      "Apison",
      "Athens",
      "Beersheba spring",
      "Belvidere",
      "Benton",
      "Birchwood",
      "Calhoun",
      "Charleston",
      "Cleveland",
      "Coalmont",
      "Conasauga",
      "Postelle",
      "Cowan",
      "Dayton",
      "Decatur",
      "Decherd",
      "Delano",
      "Ducktown",
      "Dunlap",
      "Elora",
      "Englewood",
      "Estill springs",
      "Etowah",
      "Evensville",
      "Farner",
      "Fayetteville",
      "Flintville",
      "Georgetown",
      "Grandview",
      "Graysville",
      "Gruetli laager",
      "Guild",
      "Harrison",
      "Hillsboro",
      "Hixson",
      "Huntland",
      "Kimball",
      "Kelso",
      "Lookout mountain",
      "Lupton city",
      "Lynchburg",
      "Mc donald",
      "Hiwassee college",
      "Manchester",
      "Monteagle",
      "Morrison",
      "Mulberry",
      "Normandy",
      "Ocoee",
      "Oldfort",
      "Ooltewah",
      "Palmer",
      "Pelham",
      "Pikeville",
      "Reliance",
      "Riceville",
      "Sale creek",
      "Sequatchie",
      "Sewanee",
      "Sherwood",
      "Signal mountain",
      "Soddy daisy",
      "South pittsburg",
      "Spring city",
      "Tellico plains",
      "Tracy city",
      "Dickel",
      "Turtletown",
      "Viola",
      "Whiteside",
      "Whitwell",
      "Winchester",
      "Zcta 373hh",
      "Zcta 373xx",
      "Chattanooga",
      "East ridge",
      "Red bank",
      "Zcta 374hh",
      "Johnson city",
      "Gray",
      "Afton",
      "Blountville",
      "Bluff city",
      "Bristol",
      "Butler",
      "Chuckey",
      "Church hill",
      "Elizabethton",
      "Mount carmel",
      "Erwin",
      "Fall branch",
      "Flag pond",
      "Hampton",
      "Jonesborough",
      "Bloomingdale",
      "Colonial heights",
      "Kingsport",
      "Lynn garden",
      "Laurel bloomery",
      "Washington colle",
      "Mountain city",
      "Mtn home",
      "Piney flats",
      "Roan mountain",
      "Shady valley",
      "Telford",
      "Trade",
      "Unicoi",
      "Watauga",
      "Zcta 376hh",
      "Alcoa",
      "Andersonville",
      "Bean station",
      "Blaine",
      "Devonia",
      "Bulls gap",
      "Bybee",
      "Caryville",
      "Clairfield",
      "Clinton",
      "Coalfield",
      "Corryton",
      "Cosby",
      "Crab orchard",
      "Cumberland gap",
      "Dandridge",
      "Deer lodge",
      "Del rio",
      "Duff",
      "Eagan",
      "Eidson",
      "Elgin",
      "Friendsville",
      "Gatlinburg",
      "Greenback",
      "Baileyton",
      "Greeneville",
      "Zcta 37745",
      "Harriman",
      "Harrogate",
      "Hartford",
      "Heiskell",
      "Helenwood",
      "Huntsville",
      "Jacksboro",
      "Jefferson city",
      "Jellico",
      "Kingston",
      "Kodak",
      "Kyles ford",
      "Morley",
      "Lake city",
      "Lancing",
      "Lenoir city",
      "Zcta 37772",
      "Loudon",
      "Louisville",
      "Luttrell",
      "Zcta 377hh",
      "Zcta 377xx",
      "Maryville",
      "Mascot",
      "Maynardville",
      "Midway",
      "Mohawk",
      "Mooresburg",
      "Morristown",
      "Mosheim",
      "Newcomb",
      "New market",
      "Newport",
      "New tazewell",
      "Niota",
      "Norris",
      "Oakdale",
      "Oak ridge",
      "Oliver springs",
      "Oneida",
      "Parrottsville",
      "Petros",
      "Philadelphia",
      "Pioneer",
      "Powder springs",
      "Powell",
      "Robbins",
      "Rockford",
      "Rockwood",
      "Rogersville",
      "Russellville",
      "Rutledge",
      "Sevierville",
      "Pigeon forge",
      "Seymour",
      "Sharps chapel",
      "Sneedville",
      "Speedwell",
      "Strawberry plain",
      "Sunbright",
      "Surgoinsville",
      "Sweetwater",
      "Zcta 37876",
      "Talbott",
      "Tallassee",
      "Tazewell",
      "Ten mile",
      "Thorn hill",
      "Townsend",
      "Vonore",
      "Walland",
      "Wartburg",
      "Washburn",
      "Baneberry",
      "Whitesburg",
      "Winfield",
      "Zcta 378hh",
      "Zcta 378xx",
      "Knoxville",
      "Kimberlin height",
      "Karns",
      "Concord",
      "Concord farragut",
      "Zcta 379hh",
      "Alamo",
      "Arlington",
      "Atoka",
      "Bells",
      "Bolivar",
      "Brighton",
      "Brownsville",
      "Burlison",
      "Collierville",
      "Cordova",
      "Covington",
      "Crockett mills",
      "Drummonds",
      "Dyersburg",
      "Eads",
      "Finley",
      "Friendship",
      "Gallaway",
      "Gates",
      "Grand junction",
      "Halls",
      "Fort pillow",
      "Hickory valley",
      "Hornsby",
      "La grange",
      "Lenox",
      "Mason",
      "Maury city",
      "Middleton",
      "Millington",
      "Moscow",
      "Munford",
      "Newbern",
      "Oakland",
      "Pocahontas",
      "Ripley",
      "Rossville",
      "Saulsbury",
      "Somerville",
      "Stanton",
      "Whiteville",
      "Williston",
      "Tiptonville",
      "Ridgely",
      "Zcta 380hh",
      "Memphis",
      "Hickory hill",
      "Bartlett",
      "Germantown",
      "Zcta 381hh",
      "Mc kenzie",
      "Atwood",
      "Big sandy",
      "Buchanan",
      "Cottage grove",
      "Dresden",
      "Dukedom",
      "Gleason",
      "Greenfield",
      "Henry",
      "Hornbeak",
      "Kenton",
      "Mc lemoresville",
      "Mansfield",
      "Martin",
      "Obion",
      "Palmersville",
      "Paris",
      "Puryear",
      "Rives",
      "Sharon",
      "Springville",
      "South fulton",
      "Trezevant",
      "Trimble",
      "Troy",
      "Union city",
      "Zcta 382hh",
      "Jackson",
      "Adamsville",
      "Bath springs",
      "Beech bluff",
      "Bethel springs",
      "Bradford",
      "Bruceton",
      "Buena vista",
      "Camden",
      "Cedar grove",
      "Counce",
      "Crump",
      "Darden",
      "Decaturville",
      "Dyer",
      "Enville",
      "Eva",
      "Finger",
      "Gadsden",
      "Guys",
      "Henderson",
      "Holladay",
      "Hollow rock",
      "Humboldt",
      "Huntingdon",
      "Huron",
      "Jacks creek",
      "Lavinia",
      "Lexington",
      "Luray",
      "Medina",
      "Medon",
      "Michie",
      "Milan",
      "Milledgeville",
      "Morris chapel",
      "Oakfield",
      "Parsons",
      "Pinson",
      "Ramer",
      "Reagan",
      "Rutherford",
      "Saltillo",
      "Sardis",
      "Savannah",
      "Scotts hill",
      "Selmer",
      "Shiloh",
      "Spring creek",
      "Stantonville",
      "Sugar tree",
      "Toone",
      "Trenton",
      "Westport",
      "Wildersville",
      "Yuma",
      "Denmark",
      "Mercer",
      "Zcta 383hh",
      "Columbia",
      "Clifton",
      "Ardmore",
      "Collinwood",
      "Culleoka",
      "Cypress inn",
      "Duck river",
      "Ethridge",
      "Five points",
      "Frankewing",
      "Goodspring",
      "Hampshire",
      "Kimmins",
      "Iron city",
      "Lawrenceburg",
      "Leoma",
      "Loretto",
      "Lutts",
      "Lynnville",
      "Minor hill",
      "Mount pleasant",
      "Olivehill",
      "Primm springs",
      "Prospect",
      "Pulaski",
      "Saint joseph",
      "Santa fe",
      "Summertown",
      "Waynesboro",
      "Westpoint",
      "Williamsport",
      "Taft",
      "Zcta 384hh",
      "Algood",
      "Allardt",
      "Zcta 38506",
      "Allons",
      "Allred",
      "Alpine",
      "Baxter",
      "Bloomington spri",
      "Brush creek",
      "Buffalo valley",
      "Byrdstown",
      "Celina",
      "Chestnut mound",
      "Clarkrange",
      "Crawford",
      "Fairfield glade",
      "Jamestown",
      "Zcta 38558",
      "Doyle",
      "Elmwood",
      "Gainesboro",
      "Gordonsville",
      "Granville",
      "Grimsley",
      "Hickman",
      "Hilham",
      "Lancaster",
      "Livingston",
      "Monroe",
      "Monterey",
      "Moss",
      "Pall mall",
      "Quebeck",
      "Rickman",
      "Bone cave",
      "Silver point",
      "Ravenscroft",
      "Spencer",
      "Walling",
      "Whitleyville",
      "Wilder",
      "Zcta 385hh",
      "Zcta 385xx",
      "",
    ],
    ZipCodes: {
      Adams: ["37010"],
      Alexandria: ["37012"],
      Antioch: ["37013"],
      Arrington: ["37014"],
      "Ashland city": ["37015"],
      Auburntown: ["37016"],
      Beechgrove: ["37018"],
      Belfast: ["37019"],
      "Bell buckle": ["37020"],
      Bethpage: ["37022"],
      "Big rock": ["37023"],
      "Bon aqua": ["37025"],
      Bradyville: ["37026"],
      Brentwood: ["37027"],
      "Bumpus mills": ["37028"],
      Burns: ["37029"],
      Defeated: ["37030"],
      "Castalian spring": ["37031"],
      "Cedar hill": ["37032"],
      Centerville: ["37033"],
      "Chapel hill": ["37034"],
      Chapmansboro: ["37035"],
      Charlotte: ["37036"],
      Christiana: ["37037"],
      Clarksville: ["37040", "37042", "37043"],
      "College grove": ["37046"],
      Cornersville: ["37047"],
      Cottontown: ["37048"],
      "Cross plains": ["37049"],
      "Cumberland city": ["37050"],
      "Cumberland furna": ["37051"],
      Cunningham: ["37052"],
      Dickson: ["37055"],
      "Dixon springs": ["37057"],
      Dover: ["37058"],
      Dowelltown: ["37059"],
      Eagleville: ["37060"],
      Erin: ["37061"],
      Fairview: ["37062"],
      Franklin: ["37064"],
      Gallatin: ["37066"],
      "Zcta 37067": ["37067"],
      "Zcta 37069": ["37069"],
      Goodlettsville: ["37072"],
      Greenbrier: ["37073"],
      Hartsville: ["37074"],
      Hendersonville: ["37075"],
      Hermitage: ["37076"],
      "Hurricane mills": ["37078"],
      "Indian mound": ["37079"],
      Joelton: ["37080"],
      "Kingston springs": ["37082"],
      Lafayette: ["37083"],
      Lascassas: ["37085"],
      "La vergne": ["37086"],
      Lebanon: ["37087"],
      "Zcta 37090": ["37090"],
      Lewisburg: ["37091"],
      Gassaway: ["37095"],
      Flatwoods: ["37096"],
      Lobelville: ["37097"],
      Wrigley: ["37098"],
      "Zcta 370hh": ["370HH"],
      "Zcta 370xx": ["370XX"],
      "Mc ewen": ["37101"],
      Plaza: ["37110"],
      Madison: ["37115"],
      Milton: ["37118"],
      "Mount juliet": ["37122"],
      "Zcta 37127": ["37127"],
      "Zcta 37128": ["37128"],
      Murfreesboro: ["37129", "37130"],
      "New johnsonville": ["37134"],
      Nolensville: ["37135"],
      Nunnelly: ["37137"],
      "Old hickory": ["37138"],
      Only: ["37140"],
      Orlinda: ["37141"],
      Palmyra: ["37142"],
      Pegram: ["37143"],
      Petersburg: ["37144"],
      "Pleasant shade": ["37145"],
      "Pleasant view": ["37146"],
      Pleasantville: ["37147"],
      Portland: ["37148"],
      Readyville: ["37149"],
      "Red boiling spri": ["37150"],
      Riddleton: ["37151"],
      Ridgetop: ["37152"],
      Rockvale: ["37153"],
      Royal: ["37160"],
      Smithville: ["37166"],
      Smyrna: ["37167"],
      Southside: ["37171"],
      Springfield: ["37172"],
      "Spring hill": ["37174"],
      Stewart: ["37175"],
      "Tennessee ridge": ["37178"],
      "Thompsons statio": ["37179"],
      Unionville: ["37180"],
      Vanleer: ["37181"],
      Wartrace: ["37183"],
      Watertown: ["37184"],
      Waverly: ["37185"],
      Westmoreland: ["37186"],
      "White bluff": ["37187"],
      "White house": ["37188"],
      "Whites creek": ["37189"],
      Woodbury: ["37190"],
      Woodlawn: ["37191"],
      "Zcta 371hh": ["371HH"],
      "Zcta 371xx": ["371XX"],
      Nashville: [
        "37201",
        "37203",
        "37205",
        "37206",
        "37207",
        "37208",
        "37209",
        "37210",
        "37211",
        "37212",
        "37213",
        "37214",
        "37215",
        "37216",
        "37217",
        "37218",
        "37219",
        "37220",
        "37228",
      ],
      Melrose: ["37204"],
      Bellevue: ["37221"],
      "Zcta 372hh": ["372HH"],
      Altamont: ["37301"],
      Apison: ["37302"],
      Athens: ["37303"],
      "Beersheba spring": ["37305"],
      Belvidere: ["37306"],
      Benton: ["37307"],
      Birchwood: ["37308"],
      Calhoun: ["37309"],
      Charleston: ["37310"],
      Cleveland: ["37311", "37312", "37323"],
      Coalmont: ["37313"],
      Conasauga: ["37316"],
      Postelle: ["37317"],
      Cowan: ["37318"],
      Dayton: ["37321"],
      Decatur: ["37322"],
      Decherd: ["37324"],
      Delano: ["37325"],
      Ducktown: ["37326"],
      Dunlap: ["37327"],
      Elora: ["37328"],
      Englewood: ["37329"],
      "Estill springs": ["37330"],
      Etowah: ["37331"],
      Evensville: ["37332"],
      Farner: ["37333"],
      Fayetteville: ["37334"],
      Flintville: ["37335"],
      Georgetown: ["37336"],
      Grandview: ["37337"],
      Graysville: ["37338"],
      "Gruetli laager": ["37339"],
      Guild: ["37340"],
      Harrison: ["37341"],
      Hillsboro: ["37342"],
      Hixson: ["37343"],
      Huntland: ["37345"],
      Kimball: ["37347"],
      Kelso: ["37348"],
      "Lookout mountain": ["37350"],
      "Lupton city": ["37351"],
      Lynchburg: ["37352"],
      "Mc donald": ["37353"],
      "Hiwassee college": ["37354"],
      Manchester: ["37355"],
      Monteagle: ["37356"],
      Morrison: ["37357"],
      Mulberry: ["37359"],
      Normandy: ["37360"],
      Ocoee: ["37361"],
      Oldfort: ["37362"],
      Ooltewah: ["37363"],
      Palmer: ["37365"],
      Pelham: ["37366"],
      Pikeville: ["37367"],
      Reliance: ["37369"],
      Riceville: ["37370"],
      "Sale creek": ["37373"],
      Sequatchie: ["37374"],
      Sewanee: ["37375"],
      Sherwood: ["37376"],
      "Signal mountain": ["37377"],
      "Soddy daisy": ["37379"],
      "South pittsburg": ["37380"],
      "Spring city": ["37381"],
      "Tellico plains": ["37385"],
      "Tracy city": ["37387"],
      Dickel: ["37388"],
      Turtletown: ["37391"],
      Viola: ["37394"],
      Whiteside: ["37396"],
      Whitwell: ["37397"],
      Winchester: ["37398"],
      "Zcta 373hh": ["373HH"],
      "Zcta 373xx": ["373XX"],
      Chattanooga: [
        "37402",
        "37403",
        "37404",
        "37405",
        "37406",
        "37407",
        "37408",
        "37409",
        "37410",
        "37411",
        "37416",
        "37419",
        "37421",
      ],
      "East ridge": ["37412"],
      "Red bank": ["37415"],
      "Zcta 374hh": ["374HH"],
      "Johnson city": ["37601", "37604", "37614"],
      Gray: ["37615"],
      Afton: ["37616"],
      Blountville: ["37617"],
      "Bluff city": ["37618"],
      Bristol: ["37620"],
      Butler: ["37640"],
      Chuckey: ["37641"],
      "Church hill": ["37642"],
      Elizabethton: ["37643"],
      "Mount carmel": ["37645"],
      Erwin: ["37650"],
      "Fall branch": ["37656"],
      "Flag pond": ["37657"],
      Hampton: ["37658"],
      Jonesborough: ["37659"],
      Bloomingdale: ["37660"],
      "Colonial heights": ["37663"],
      Kingsport: ["37664"],
      "Lynn garden": ["37665"],
      "Laurel bloomery": ["37680"],
      "Washington colle": ["37681"],
      "Mountain city": ["37683"],
      "Mtn home": ["37684"],
      "Piney flats": ["37686"],
      "Roan mountain": ["37687"],
      "Shady valley": ["37688"],
      Telford: ["37690"],
      Trade: ["37691"],
      Unicoi: ["37692"],
      Watauga: ["37694"],
      "Zcta 376hh": ["376HH"],
      Alcoa: ["37701"],
      Andersonville: ["37705"],
      "Bean station": ["37708"],
      Blaine: ["37709"],
      Devonia: ["37710"],
      "Bulls gap": ["37711"],
      Bybee: ["37713"],
      Caryville: ["37714"],
      Clairfield: ["37715"],
      Clinton: ["37716"],
      Coalfield: ["37719"],
      Corryton: ["37721"],
      Cosby: ["37722"],
      "Crab orchard": ["37723"],
      "Cumberland gap": ["37724"],
      Dandridge: ["37725"],
      "Deer lodge": ["37726"],
      "Del rio": ["37727"],
      Duff: ["37729"],
      Eagan: ["37730"],
      Eidson: ["37731"],
      Elgin: ["37732"],
      Friendsville: ["37737"],
      Gatlinburg: ["37738"],
      Greenback: ["37742"],
      Baileyton: ["37743"],
      Greeneville: ["37744"],
      "Zcta 37745": ["37745"],
      Harriman: ["37748"],
      Harrogate: ["37752"],
      Hartford: ["37753"],
      Heiskell: ["37754"],
      Helenwood: ["37755"],
      Huntsville: ["37756"],
      Jacksboro: ["37757"],
      "Jefferson city": ["37760"],
      Jellico: ["37762"],
      Kingston: ["37763"],
      Kodak: ["37764"],
      "Kyles ford": ["37765"],
      Morley: ["37766"],
      "Lake city": ["37769"],
      Lancing: ["37770"],
      "Lenoir city": ["37771"],
      "Zcta 37772": ["37772"],
      Loudon: ["37774"],
      Louisville: ["37777"],
      Luttrell: ["37779"],
      "Zcta 377hh": ["377HH"],
      "Zcta 377xx": ["377XX"],
      Maryville: ["37801", "37803", "37804"],
      Mascot: ["37806"],
      Maynardville: ["37807"],
      Midway: ["37809"],
      Mohawk: ["37810"],
      Mooresburg: ["37811"],
      Morristown: ["37813", "37814"],
      Mosheim: ["37818"],
      Newcomb: ["37819"],
      "New market": ["37820"],
      Newport: ["37821"],
      "New tazewell": ["37825"],
      Niota: ["37826"],
      Norris: ["37828"],
      Oakdale: ["37829"],
      "Oak ridge": ["37830"],
      "Oliver springs": ["37840"],
      Oneida: ["37841"],
      Parrottsville: ["37843"],
      Petros: ["37845"],
      Philadelphia: ["37846"],
      Pioneer: ["37847"],
      "Powder springs": ["37848"],
      Powell: ["37849"],
      Robbins: ["37852"],
      Rockford: ["37853"],
      Rockwood: ["37854"],
      Rogersville: ["37857"],
      Russellville: ["37860"],
      Rutledge: ["37861"],
      Sevierville: ["37862"],
      "Pigeon forge": ["37863"],
      Seymour: ["37865"],
      "Sharps chapel": ["37866"],
      Sneedville: ["37869"],
      Speedwell: ["37870"],
      "Strawberry plain": ["37871"],
      Sunbright: ["37872"],
      Surgoinsville: ["37873"],
      Sweetwater: ["37874"],
      "Zcta 37876": ["37876"],
      Talbott: ["37877"],
      Tallassee: ["37878"],
      Tazewell: ["37879"],
      "Ten mile": ["37880"],
      "Thorn hill": ["37881"],
      Townsend: ["37882"],
      Vonore: ["37885"],
      Walland: ["37886"],
      Wartburg: ["37887"],
      Washburn: ["37888"],
      Baneberry: ["37890"],
      Whitesburg: ["37891"],
      Winfield: ["37892"],
      "Zcta 378hh": ["378HH"],
      "Zcta 378xx": ["378XX"],
      Knoxville: [
        "37902",
        "37909",
        "37912",
        "37914",
        "37915",
        "37916",
        "37917",
        "37918",
        "37919",
        "37923",
        "37924",
        "37931",
        "37938",
      ],
      "Kimberlin height": ["37920"],
      Karns: ["37921"],
      Concord: ["37922"],
      "Concord farragut": ["37932"],
      "Zcta 379hh": ["379HH"],
      Alamo: ["38001"],
      Arlington: ["38002"],
      Atoka: ["38004"],
      Bells: ["38006"],
      Bolivar: ["38008"],
      Brighton: ["38011"],
      Brownsville: ["38012"],
      Burlison: ["38015"],
      Collierville: ["38017"],
      Cordova: ["38018"],
      Covington: ["38019"],
      "Crockett mills": ["38021"],
      Drummonds: ["38023"],
      Dyersburg: ["38024"],
      Eads: ["38028"],
      Finley: ["38030"],
      Friendship: ["38034"],
      Gallaway: ["38036"],
      Gates: ["38037"],
      "Grand junction": ["38039"],
      Halls: ["38040"],
      "Fort pillow": ["38041"],
      "Hickory valley": ["38042"],
      Hornsby: ["38044"],
      "La grange": ["38046"],
      Lenox: ["38047"],
      Mason: ["38049"],
      "Maury city": ["38050"],
      Middleton: ["38052"],
      Millington: ["38053"],
      Moscow: ["38057"],
      Munford: ["38058"],
      Newbern: ["38059"],
      Oakland: ["38060"],
      Pocahontas: ["38061"],
      Ripley: ["38063"],
      Rossville: ["38066"],
      Saulsbury: ["38067"],
      Somerville: ["38068"],
      Stanton: ["38069"],
      Whiteville: ["38075"],
      Williston: ["38076"],
      Tiptonville: ["38079"],
      Ridgely: ["38080"],
      "Zcta 380hh": ["380HH"],
      Memphis: [
        "38103",
        "38104",
        "38105",
        "38106",
        "38107",
        "38108",
        "38109",
        "38111",
        "38112",
        "38114",
        "38116",
        "38117",
        "38118",
        "38119",
        "38120",
        "38122",
        "38125",
        "38126",
        "38127",
        "38128",
        "38131",
        "38132",
        "38133",
        "38135",
        "38141",
        "38157",
      ],
      "Hickory hill": ["38115"],
      Bartlett: ["38134"],
      Germantown: ["38138", "38139"],
      "Zcta 381hh": ["381HH"],
      "Mc kenzie": ["38201"],
      Atwood: ["38220"],
      "Big sandy": ["38221"],
      Buchanan: ["38222"],
      "Cottage grove": ["38224"],
      Dresden: ["38225"],
      Dukedom: ["38226"],
      Gleason: ["38229"],
      Greenfield: ["38230"],
      Henry: ["38231"],
      Hornbeak: ["38232"],
      Kenton: ["38233"],
      "Mc lemoresville": ["38235"],
      Mansfield: ["38236"],
      Martin: ["38237"],
      Obion: ["38240"],
      Palmersville: ["38241"],
      Paris: ["38242"],
      Puryear: ["38251"],
      Rives: ["38253"],
      Sharon: ["38255"],
      Springville: ["38256"],
      "South fulton": ["38257"],
      Trezevant: ["38258"],
      Trimble: ["38259"],
      Troy: ["38260"],
      "Union city": ["38261"],
      "Zcta 382hh": ["382HH"],
      Jackson: ["38301", "38305"],
      Adamsville: ["38310"],
      "Bath springs": ["38311"],
      "Beech bluff": ["38313"],
      "Bethel springs": ["38315"],
      Bradford: ["38316"],
      Bruceton: ["38317"],
      "Buena vista": ["38318"],
      Camden: ["38320"],
      "Cedar grove": ["38321"],
      Counce: ["38326"],
      Crump: ["38327"],
      Darden: ["38328"],
      Decaturville: ["38329"],
      Dyer: ["38330"],
      Enville: ["38332"],
      Eva: ["38333"],
      Finger: ["38334"],
      Gadsden: ["38337"],
      Guys: ["38339"],
      Henderson: ["38340"],
      Holladay: ["38341"],
      "Hollow rock": ["38342"],
      Humboldt: ["38343"],
      Huntingdon: ["38344"],
      Huron: ["38345"],
      "Jacks creek": ["38347"],
      Lavinia: ["38348"],
      Lexington: ["38351"],
      Luray: ["38352"],
      Medina: ["38355"],
      Medon: ["38356"],
      Michie: ["38357"],
      Milan: ["38358"],
      Milledgeville: ["38359"],
      "Morris chapel": ["38361"],
      Oakfield: ["38362"],
      Parsons: ["38363"],
      Pinson: ["38366"],
      Ramer: ["38367"],
      Reagan: ["38368"],
      Rutherford: ["38369"],
      Saltillo: ["38370"],
      Sardis: ["38371"],
      Savannah: ["38372"],
      "Scotts hill": ["38374"],
      Selmer: ["38375"],
      Shiloh: ["38376"],
      "Spring creek": ["38378"],
      Stantonville: ["38379"],
      "Sugar tree": ["38380"],
      Toone: ["38381"],
      Trenton: ["38382"],
      Westport: ["38387"],
      Wildersville: ["38388"],
      Yuma: ["38390"],
      Denmark: ["38391"],
      Mercer: ["38392"],
      "Zcta 383hh": ["383HH"],
      Columbia: ["38401"],
      Clifton: ["38425"],
      Ardmore: ["38449", "38453"],
      Collinwood: ["38450"],
      Culleoka: ["38451"],
      "Cypress inn": ["38452"],
      "Duck river": ["38454"],
      Ethridge: ["38456"],
      "Five points": ["38457"],
      Frankewing: ["38459"],
      Goodspring: ["38460"],
      Hampshire: ["38461"],
      Kimmins: ["38462"],
      "Iron city": ["38463"],
      Lawrenceburg: ["38464"],
      Leoma: ["38468"],
      Loretto: ["38469"],
      Lutts: ["38471"],
      Lynnville: ["38472"],
      "Minor hill": ["38473"],
      "Mount pleasant": ["38474"],
      Olivehill: ["38475"],
      "Primm springs": ["38476"],
      Prospect: ["38477"],
      Pulaski: ["38478"],
      "Saint joseph": ["38481"],
      "Santa fe": ["38482"],
      Summertown: ["38483"],
      Waynesboro: ["38485"],
      Westpoint: ["38486"],
      Williamsport: ["38487"],
      Taft: ["38488"],
      "Zcta 384hh": ["384HH"],
      Algood: ["38501"],
      Allardt: ["38504"],
      "Zcta 38506": ["38506"],
      Allons: ["38541"],
      Allred: ["38542"],
      Alpine: ["38543"],
      Baxter: ["38544"],
      "Bloomington spri": ["38545"],
      "Brush creek": ["38547"],
      "Buffalo valley": ["38548"],
      Byrdstown: ["38549"],
      Celina: ["38551"],
      "Chestnut mound": ["38552"],
      Clarkrange: ["38553"],
      Crawford: ["38554"],
      "Fairfield glade": ["38555"],
      Jamestown: ["38556"],
      "Zcta 38558": ["38558"],
      Doyle: ["38559"],
      Elmwood: ["38560"],
      Gainesboro: ["38562"],
      Gordonsville: ["38563"],
      Granville: ["38564"],
      Grimsley: ["38565"],
      Hickman: ["38567"],
      Hilham: ["38568"],
      Lancaster: ["38569"],
      Livingston: ["38570"],
      Monroe: ["38573"],
      Monterey: ["38574"],
      Moss: ["38575"],
      "Pall mall": ["38577"],
      Quebeck: ["38579"],
      Rickman: ["38580"],
      "Bone cave": ["38581"],
      "Silver point": ["38582"],
      Ravenscroft: ["38583"],
      Spencer: ["38585"],
      Walling: ["38587"],
      Whitleyville: ["38588"],
      Wilder: ["38589"],
      "Zcta 385hh": ["385HH"],
      "Zcta 385xx": ["385XX"],
      "": ["42223", "422XX"],
    },
  },
  {
    abbrevation: "TX",
    name: "Texas",
    Cities: [
      "",
      "",
      "Addison",
      "Allen",
      "Carrollton",
      "Celina",
      "Zcta 75013",
      "Coppell",
      "Denison",
      "Zcta 75022",
      "Plano",
      "Flower mound",
      "Zcta 75032",
      "Frisco",
      "Zcta 75035",
      "Irving",
      "Garland",
      "Sachse",
      "Grand prairie",
      "The colony",
      "Lewisville",
      "Gunter",
      "Lake dallas",
      "Highland village",
      "Lakewood village",
      "Mc kinney",
      "Pottsboro",
      "Zcta 75077",
      "Prosper",
      "Richardson",
      "Heath",
      "Rowlett",
      "Zcta 75089",
      "Sherman",
      "Murphy",
      "Wylie",
      "Zcta 750hh",
      "Bardwell",
      "Barry",
      "Canton",
      "Cedar hill",
      "Chatfield",
      "Corsicana",
      "Crandall",
      "De soto",
      "Duncanville",
      "Edgewood",
      "Ennis",
      "Copeville",
      "Eustace",
      "Ferris",
      "Forney",
      "Fruitvale",
      "Fate",
      "Lancaster",
      "Caddo mills",
      "Grand saline",
      "Hutchins",
      "Kaufman",
      "Seven points",
      "Kerens",
      "Gun barrel city",
      "Malakoff",
      "Mesquite",
      "Palmer",
      "Powell",
      "Ovilla",
      "Rice",
      "Scurry",
      "Seagoville",
      "Terrell",
      "Zcta 75161",
      "Trinidad",
      "Josephine",
      "Waxahachie",
      "Lavon",
      "Zcta 75167",
      "Wills point",
      "Wilmer",
      "Nevada",
      "Balch springs",
      "Royse city",
      "Zcta 751hh",
      "Dallas",
      "Village",
      "Cockrell hill",
      "Farmers branch",
      "Zcta 752hh",
      "Greenville",
      "Princeton",
      "Anna",
      "Alba",
      "Arthur city",
      "Bagwell",
      "Bailey",
      "Bells",
      "Ben franklin",
      "Blossom",
      "Bogata",
      "Bonham",
      "Brashear",
      "Brookston",
      "Campbell",
      "Celeste",
      "Blue ridge",
      "Clarksville",
      "Commerce",
      "Como",
      "Cooper",
      "Cumby",
      "Cunningham",
      "Deport",
      "Detroit",
      "Dike",
      "Dodd city",
      "Ector",
      "Emory",
      "Enloe",
      "Farmersville",
      "Golden",
      "Honey grove",
      "Ivanhoe",
      "Klondike",
      "Ladonia",
      "Lake creek",
      "Leesburg",
      "Leonard",
      "Lone oak",
      "Melissa",
      "Mount pleasant",
      "Mount vernon",
      "Howe",
      "Paris",
      "Reno",
      "Pattonville",
      "Pecan gap",
      "Petty",
      "Pickton",
      "Point",
      "Powderly",
      "Quinlan",
      "Randolph",
      "Ravenna",
      "Roxton",
      "Saltillo",
      "Savoy",
      "Scroggins",
      "Sulphur bluff",
      "Sulphur springs",
      "Westminster",
      "Sumner",
      "Talco",
      "Telephone",
      "Tom bean",
      "Trenton",
      "Whitewright",
      "Windom",
      "Winfield",
      "Winnsboro",
      "Van alstyne",
      "Wolfe city",
      "Yantis",
      "Zcta 754hh",
      "Wake village",
      "Texarkana",
      "Annona",
      "Atlanta",
      "Avery",
      "Bivins",
      "Bloomburg",
      "Cookville",
      "De kalb",
      "Douglassville",
      "Leary",
      "Kildare",
      "Linden",
      "Lodi",
      "Mc leod",
      "Marietta",
      "Maud",
      "Naples",
      "Nash",
      "Boston",
      "Omaha",
      "Queen city",
      "Redwater",
      "Simms",
      "Zcta 755hh",
      "Zcta 755xx",
      "Longview",
      "Avinger",
      "Beckville",
      "Carthage",
      "Cason",
      "Daingerfield",
      "De berry",
      "New diana",
      "Easton",
      "Gary",
      "Gilmer",
      "Gladewater",
      "Hallsville",
      "Harleton",
      "Henderson",
      "Zcta 75654",
      "Hughes springs",
      "Smithland",
      "Karnack",
      "Kilgore",
      "Laneville",
      "Lone star",
      "Long branch",
      "Marshall",
      "Zcta 75672",
      "Minden",
      "Mount enterprise",
      "Ore city",
      "Overton",
      "Pittsburg",
      "Tatum",
      "Waskom",
      "Clarksville city",
      "Zcta 756hh",
      "Tyler",
      "East texas cente",
      "Arp",
      "Athens",
      "Ben wheeler",
      "Big sandy",
      "Edom",
      "Mount selman",
      "Chandler",
      "Cuney",
      "Cushing",
      "Flint",
      "Frankston",
      "Gallatin",
      "Hawkins",
      "Jacksonville",
      "Larue",
      "Mt sylvan",
      "Mineola",
      "Murchison",
      "Poynor",
      "Quitman",
      "Reklaw",
      "Dialville",
      "Sacul",
      "Troup",
      "Van",
      "Whitehouse",
      "Winona",
      "Zcta 757hh",
      "Palestine",
      "Freestone",
      "Centerville",
      "Austonio",
      "Donie",
      "Slocum",
      "Fairfield",
      "Grapeland",
      "Groveton",
      "Jewett",
      "Kennard",
      "Kirvin",
      "Latexo",
      "Leona",
      "Lovelady",
      "Midway",
      "Montalba",
      "Oakwood",
      "Pennington",
      "Ratcliff",
      "Streetman",
      "Teague",
      "Tennessee colony",
      "Trinity",
      "Zcta 758hh",
      "Keltys",
      "Lufkin",
      "Forest",
      "Apple springs",
      "Bon wier",
      "Broaddus",
      "Bronson",
      "Brookeland",
      "Burkeville",
      "Call",
      "Camden",
      "Center",
      "Chester",
      "Chireno",
      "Rockland",
      "Barnum",
      "Diboll",
      "Doucette",
      "Douglass",
      "Etoile",
      "Garrison",
      "Hemphill",
      "Huntington",
      "Sam rayburn",
      "Joaquin",
      "Bon ami",
      "Milam",
      "Moscow",
      "Appleby",
      "Nacogdoches",
      "Newton",
      "Pineland",
      "Pollok",
      "San augustine",
      "Shelbyville",
      "Tenaha",
      "Timpson",
      "Wells",
      "Wiergate",
      "Woden",
      "Dogwood",
      "Zavalla",
      "Zcta 759hh",
      "Zcta 759xx",
      "Zcta 76001",
      "Zcta 76002",
      "Arlington",
      "Aledo",
      "Alvarado",
      "Azle",
      "Bedford",
      "Boyd",
      "Burleson",
      "Cleburne",
      "Colleyville",
      "Cresson",
      "Crowley",
      "Euless",
      "Forreston",
      "Glen rose",
      "Godley",
      "Granbury",
      "Grandview",
      "Grapevine",
      "Haslet",
      "Hurst",
      "Itasca",
      "Joshua",
      "Keene",
      "Kennedale",
      "Lillian",
      "Mansfield",
      "Maypearl",
      "Midlothian",
      "Millsap",
      "Mineral wells",
      "Nemo",
      "Newark",
      "Paradise",
      "Rainbow",
      "Rhome",
      "Springtown",
      "Venus",
      "Weatherford",
      "Zcta 76088",
      "Rio vista",
      "Zcta 760hh",
      "Fort worth",
      "White settlement",
      "River oaks",
      "Haltom city",
      "North richland h",
      "Benbrook",
      "Carswell afb",
      "Everman",
      "Watauga",
      "Saginaw",
      "Zcta 761hh",
      "Denton",
      "Alvord",
      "Argyle",
      "Aubrey",
      "Bellevue",
      "Bowie",
      "Collinsville",
      "Decatur",
      "Era",
      "Forestburg",
      "Lake kiowa",
      "Gordonville",
      "Greenwood",
      "Justin",
      "Keller",
      "Krum",
      "Lindsay",
      "Montague",
      "Muenster",
      "Myra",
      "Nocona",
      "Pilot point",
      "Ponder",
      "Ringgold",
      "Trophy club",
      "Sadler",
      "Saint jo",
      "Sanger",
      "Southmayd",
      "Sunset",
      "Tioga",
      "Valley view",
      "Whitesboro",
      "Zcta 762hh",
      "Wichita falls",
      "Sheppard afb",
      "Archer city",
      "Burkburnett",
      "Byers",
      "Electra",
      "Goree",
      "Harrold",
      "Henrietta",
      "Holliday",
      "Iowa park",
      "Megargel",
      "Munday",
      "Newcastle",
      "Oklaunion",
      "Olney",
      "Petrolia",
      "Scotland",
      "Seymour",
      "Vernon",
      "Weinert",
      "Windthorst",
      "Zcta 763hh",
      "Zcta 763xx",
      "Stephenville",
      "Breckenridge",
      "Bridgeport",
      "Bryson",
      "Caddo",
      "Albany",
      "Chico",
      "Blanket",
      "Bluff dale",
      "Carbon",
      "Carlton",
      "Cisco",
      "Comanche",
      "Cross plains",
      "De leon",
      "Desdemona",
      "Dublin",
      "Eastland",
      "Graford",
      "Graham",
      "Energy",
      "Gordon",
      "Gorman",
      "Gustine",
      "Hico",
      "Jacksboro",
      "Jermyn",
      "Loving",
      "Lipan",
      "Mingus",
      "Moran",
      "Olden",
      "Proctor",
      "Putnam",
      "Ranger",
      "Rising star",
      "Santo",
      "Sidney",
      "Strawn",
      "Tolar",
      "South bend",
      "Throckmorton",
      "Palo pinto",
      "Perrin",
      "Poolville",
      "Whitt",
      "Woodson",
      "Zcta 764hh",
      "Zcta 764xx",
      "Temple",
      "Bartlett",
      "Belton",
      "Buckholts",
      "Burlington",
      "Cameron",
      "Izoro",
      "Davilla",
      "Eddy",
      "Bee house",
      "Florence",
      "Turnersville",
      "Granger",
      "Hamilton",
      "Holland",
      "Jarrell",
      "Jonesboro",
      "Kempner",
      "Killeen",
      "Harker heights",
      "Fort hood",
      "Zcta 76548",
      "Zcta 76549",
      "Lampasas",
      "Little river",
      "Milano",
      "Moody",
      "Nolanville",
      "Oglesby",
      "Pottsville",
      "Purmela",
      "Rockdale",
      "Rogers",
      "Rosebud",
      "Salado",
      "Taylor",
      "Thorndale",
      "Thrall",
      "Troy",
      "Zcta 765hh",
      "Zcta 765xx",
      "Abbott",
      "Aquilla",
      "Axtell",
      "Blooming grove",
      "Blum",
      "Brandon",
      "Bremond",
      "Bruceville",
      "Bynum",
      "Chilton",
      "China spring",
      "Laguna park",
      "Coolidge",
      "Covington",
      "Cranfills gap",
      "Crawford",
      "Dawson",
      "Elm mott",
      "Frost",
      "Groesbeck",
      "Hewitt",
      "Hillsboro",
      "Hubbard",
      "Iredell",
      "Italy",
      "Kopperl",
      "Kosse",
      "Lorena",
      "Lott",
      "Mc gregor",
      "Malone",
      "Marlin",
      "Mart",
      "Meridian",
      "Mertens",
      "Mexia",
      "Milford",
      "Morgan",
      "Mount calm",
      "Otto",
      "Penelope",
      "Prairie hill",
      "Purdon",
      "Reagan",
      "Richland",
      "Riesel",
      "Satin",
      "Tehuacana",
      "Thornton",
      "Valley mills",
      "Walnut springs",
      "West",
      "Bonanza",
      "Wortham",
      "Zcta 766hh",
      "Waco",
      "Bellmead",
      "Beverly hills",
      "Woodway",
      "Zcta 767hh",
      "Early",
      "Zcta 76802",
      "Ballinger",
      "Bangs",
      "Bend",
      "Fife",
      "Brookesmith",
      "Burkett",
      "Castell",
      "Cherokee",
      "Coleman",
      "Eden",
      "Fort mc kavett",
      "Fredonia",
      "Goldthwaite",
      "Gouldbusk",
      "Hext",
      "Junction",
      "Lohn",
      "Lometa",
      "London",
      "Mason",
      "May",
      "Melvin",
      "Menard",
      "Miles",
      "Millersview",
      "Mullin",
      "Norton",
      "Paint rock",
      "Pontotoc",
      "Priddy",
      "Richland springs",
      "Rochelle",
      "Rockwood",
      "Roosevelt",
      "Rowena",
      "San saba",
      "Santa anna",
      "Talpa",
      "Valera",
      "Valley spring",
      "Voca",
      "Leaday",
      "Zephyr",
      "Zcta 768hh",
      "Zcta 768xx",
      "San angelo",
      "Barnhart",
      "Best",
      "Bronte",
      "Carlsbad",
      "Christoval",
      "Eldorado",
      "Eola",
      "Mereta",
      "Mertzon",
      "Ozona",
      "Robert lee",
      "Silver",
      "Sonora",
      "Sterling city",
      "Tennyson",
      "Vancourt",
      "Water valley",
      "Zcta 769hh",
      "Zcta 769xx",
      "Houston",
      "Jacinto city",
      "V a hospital",
      "Jersey village",
      "Conroe",
      "Grangerland",
      "Cut and shoot",
      "Panorama village",
      "Zcta 77306",
      "Zcta 77316",
      "Zcta 77318",
      "Zcta 77320",
      "Ace",
      "Cleveland",
      "Coldspring",
      "Dobbin",
      "Dodge",
      "Goodrich",
      "Huffman",
      "Humble",
      "Huntsville",
      "Segno",
      "Zcta 77354",
      "Magnolia",
      "Montgomery",
      "New caney",
      "New waverly",
      "Oakhurst",
      "Onalaska",
      "Pinehurst",
      "Plantersville",
      "Pointblank",
      "Porter",
      "Riverside",
      "Romayor",
      "Rye",
      "Shepherd",
      "Splendora",
      "Spring",
      "Thicket",
      "Tomball",
      "Votaw",
      "Willis",
      "Klein",
      "The woodlands",
      "Zcta 773hh",
      "Zcta 773xx",
      "Bellaire",
      "Altair",
      "Sargent",
      "Cedar lane",
      "Beasley",
      "Bellville",
      "Blessing",
      "Boling",
      "Brazoria",
      "Brookshire",
      "Chappell hill",
      "Collegeport",
      "Cypress",
      "Damon",
      "Danevang",
      "Eagle lake",
      "East bernard",
      "El campo",
      "Elmaton",
      "Fulshear",
      "Garwood",
      "Glen flora",
      "Guy",
      "Hempstead",
      "Prairie view",
      "Hockley",
      "Hungerford",
      "Park row",
      "Kendleton",
      "Lissie",
      "Louise",
      "Markham",
      "Matagorda",
      "Midfield",
      "Missouri city",
      "Needville",
      "Old ocean",
      "Orchard",
      "Palacios",
      "Pattison",
      "Pierce",
      "Pledger",
      "Clodine",
      "Rock island",
      "Rosenberg",
      "San felipe",
      "Sealy",
      "Sheridan",
      "Simonton",
      "Stafford",
      "Sugar land",
      "Sweeny",
      "Thompsons",
      "Van vleck",
      "Wadsworth",
      "Waller",
      "Wallis",
      "West columbia",
      "Wharton",
      "Zcta 774hh",
      "Zcta 774xx",
      "Pasadena",
      "Alta loma",
      "Alvin",
      "Monroe city",
      "Angleton",
      "Arcadia",
      "Bacliff",
      "Batson",
      "Baytown",
      "Channelview",
      "Clute",
      "Barrett",
      "Daisetta",
      "Danbury",
      "Dayton",
      "Deer park",
      "Devers",
      "San leon",
      "Quintana",
      "Fresno",
      "Friendswood",
      "Galena park",
      "Galveston",
      "Hankamer",
      "Hardin",
      "Highlands",
      "Hitchcock",
      "Hull",
      "Clear lake shore",
      "Lake jackson",
      "La marque",
      "Shoreacres",
      "League city",
      "Ames",
      "Liverpool",
      "Manvel",
      "Pearland",
      "Raywood",
      "Rosharon",
      "Saratoga",
      "El lago",
      "South houston",
      "Texas city",
      "Wallisville",
      "Webster",
      "Zcta 775hh",
      "Zcta 775xx",
      "Bridge city",
      "Buna",
      "China",
      "Deweyville",
      "Evadale",
      "Fred",
      "Gilchrist",
      "Groves",
      "Hamshire",
      "High island",
      "Hillister",
      "Kountze",
      "Nederland",
      "Nome",
      "West orange",
      "Orange",
      "Port acres",
      "Port arthur",
      "Crystal beach",
      "Port neches",
      "Sabine pass",
      "Silsbee",
      "Zcta 77657",
      "Sour lake",
      "Spurger",
      "Vidor",
      "Village mills",
      "Warren",
      "Winnie",
      "Zcta 776hh",
      "Zcta 776xx",
      "Beaumont",
      "Zcta 777hh",
      "Zcta 777xx",
      "Bryan",
      "Anderson",
      "Singleton",
      "Brenham",
      "Burton",
      "Caldwell",
      "Calvert",
      "College station",
      "Dime box",
      "Flynn",
      "Franklin",
      "Gause",
      "Hearne",
      "Iola",
      "Madisonville",
      "Marquez",
      "Millican",
      "Mumford",
      "Navasota",
      "New baden",
      "Hilltop lakes",
      "North zulch",
      "Richards",
      "Shiro",
      "Snook",
      "Somerville",
      "Washington",
      "Wheelock",
      "Zcta 778hh",
      "Victoria",
      "Austwell",
      "Bloomington",
      "Cuero",
      "Edna",
      "Fannin",
      "Francitas",
      "Ganado",
      "Goliad",
      "Hallettsville",
      "Inez",
      "La salle",
      "La ward",
      "Lolita",
      "Mcfaddin",
      "Meyersville",
      "Moulton",
      "Placedo",
      "Point comfort",
      "Port lavaca",
      "Port o connor",
      "Seadrift",
      "Shiner",
      "Telferner",
      "Tivoli",
      "Vanderbilt",
      "Westhoff",
      "Yoakum",
      "Zcta 779hh",
      "Zcta 779xx",
      "Artesia wells",
      "Atascosa",
      "Bandera",
      "Bergheim",
      "Bigfoot",
      "Sisterdale",
      "Calliham",
      "Campbellton",
      "Castroville",
      "Camp verde",
      "Charlotte",
      "Christine",
      "Comfort",
      "Cotulla",
      "Zcta 78015",
      "Devine",
      "Dilley",
      "Encinal",
      "Fowlerton",
      "George west",
      "Grey forest",
      "Hunt",
      "Ingram",
      "Jourdanton",
      "Kendalia",
      "Kerrville",
      "La coste",
      "Laredo",
      "Rio bravo",
      "Zcta 78045",
      "Zcta 78046",
      "Leming",
      "Lytle",
      "Mc coy",
      "Medina",
      "Mico",
      "Moore",
      "Mountain home",
      "Natalia",
      "Pearsall",
      "Lakehills",
      "Pleasanton",
      "Poteet",
      "Riomedina",
      "San ygnacio",
      "Somerset",
      "Spring branch",
      "Three rivers",
      "Tilden",
      "Von ormy",
      "Waring",
      "Whitsett",
      "Zapata",
      "Zcta 780hh",
      "Zcta 780xx",
      "Adkins",
      "Beeville",
      "Berclair",
      "Cibolo",
      "Converse",
      "Elmendorf",
      "Falls city",
      "Floresville",
      "Gillett",
      "Hobson",
      "Karnes city",
      "Kenedy",
      "La vernia",
      "Leesville",
      "Mc queeney",
      "Marion",
      "Mineral",
      "Canyon lake",
      "Nixon",
      "Nordheim",
      "Normanna",
      "Pandora",
      "Panna maria",
      "Pawnee",
      "Pettus",
      "Poth",
      "Randolph a f b",
      "Runge",
      "Saint hedwig",
      "Selma",
      "Seguin",
      "Smiley",
      "Stockdale",
      "Sutherland sprin",
      "Wetmore",
      "Yorktown",
      "Zcta 781hh",
      "Zcta 781xx",
      "Balcones heights",
      "San antonio",
      "Alamo heights",
      "Olmos park",
      "Castle hills",
      "Kirby",
      "Shavano park",
      "Hollywood park",
      "Live oak",
      "Fort sam houston",
      "Brooks a f b",
      "Wilford hall u s",
      "Leon valley",
      "Windcrest",
      "Garden ridge",
      "Zcta 782hh",
      "Zcta 782xx",
      "Agua dulce",
      "Alice",
      "Aransas pass",
      "Armstrong",
      "Banquete",
      "Bayside",
      "Bishop",
      "Bruni",
      "Chapman ranch",
      "Concepcion",
      "Driscoll",
      "Edroy",
      "Encino",
      "Falfurrias",
      "Freer",
      "Fulton",
      "Gregory",
      "Hebbronville",
      "Ingleside",
      "Kingsville naval",
      "Kingsville",
      "Mathis",
      "Mirando city",
      "Odem",
      "Oilton",
      "Orange grove",
      "Port aransas",
      "Portland",
      "Premont",
      "Realitos",
      "Refugio",
      "Riviera",
      "Robstown",
      "Rockport",
      "Sandia",
      "San diego",
      "Sarita",
      "Sinton",
      "Skidmore",
      "Taft",
      "Tynan",
      "Woodsboro",
      "Zcta 783hh",
      "Zcta 783xx",
      "Corpus christi",
      "Zcta 784hh",
      "Mcallen",
      "Alamo",
      "Brownsville",
      "Donna",
      "Monte alto",
      "Edinburg",
      "Elsa",
      "Falcon heights",
      "Garciasville",
      "Grulla",
      "Hargill",
      "Harlingen",
      "Hidalgo",
      "La blanca",
      "La feria",
      "La joya",
      "La villa",
      "Linn",
      "Los ebanos",
      "Bayview",
      "Lyford",
      "Mercedes",
      "Alton",
      "Olmito",
      "Penitas",
      "Pharr",
      "Port isabel",
      "Progreso",
      "Raymondville",
      "Rio grande city",
      "Rio hondo",
      "Roma",
      "San benito",
      "San isidro",
      "San juan",
      "Santa maria",
      "Santa rosa",
      "Sebastian",
      "Sullivan city",
      "Weslaco",
      "South padre isla",
      "Port mansfield",
      "Zcta 785hh",
      "Zcta 785xx",
      "Bastrop",
      "Bertram",
      "Blanco",
      "Bluffton",
      "Briggs",
      "Buchanan dam",
      "Buda",
      "Burnet",
      "Cedar creek",
      "Cedar park",
      "Cost",
      "Coupland",
      "Dale",
      "Del valle",
      "Doss",
      "Driftwood",
      "Dripping springs",
      "Elgin",
      "Fentress",
      "Fischer",
      "Fredericksburg",
      "Georgetown",
      "Andice",
      "Gonzales",
      "Harper",
      "Harwood",
      "Hutto",
      "Hye",
      "Johnson city",
      "Kingsbury",
      "Kingsland",
      "Uhland",
      "Leander",
      "Liberty hill",
      "Sunrise beach",
      "Lockhart",
      "Jonestown",
      "Luling",
      "Mc dade",
      "Manchaca",
      "Manor",
      "Cypress mill",
      "Martindale",
      "Maxwell",
      "Zcta 78657",
      "Ottine",
      "Paige",
      "Pflugerville",
      "Prairie lea",
      "Red rock",
      "Round mountain",
      "Round rock",
      "San marcos",
      "Spicewood",
      "Staples",
      "Albert",
      "Tow",
      "Willow city",
      "Wimberley",
      "Zcta 786hh",
      "Austin",
      "Lakeway",
      "West lake hills",
      "Creedmoor",
      "Zcta 787hh",
      "Uvalde",
      "Asherton",
      "Barksdale",
      "Batesville",
      "Big wells",
      "Brackettville",
      "Camp wood",
      "Carrizo springs",
      "Catarina",
      "Comstock",
      "Concan",
      "Crystal city",
      "Laughlin a f b",
      "D hanis",
      "Dryden",
      "Eagle pass",
      "El indio",
      "Dunlay",
      "Knippa",
      "La pryor",
      "Leakey",
      "Spofford",
      "Rio frio",
      "Rocksprings",
      "Sabinal",
      "Tarpley",
      "Utopia",
      "Vanderpool",
      "Yancey",
      "Zcta 788hh",
      "Zcta 788xx",
      "Carmine",
      "Cat spring",
      "Columbus",
      "Alleyton",
      "Ellinger",
      "Fayetteville",
      "Flatonia",
      "Giddings",
      "Glidden",
      "Industry",
      "La grange",
      "Ledbetter",
      "Lexington",
      "Lincoln",
      "Muldoon",
      "New ulm",
      "Rosanky",
      "Round top",
      "Schulenburg",
      "Smithville",
      "Waelder",
      "Warda",
      "Weimar",
      "West point",
      "Zcta 789hh",
      "Adrian",
      "Alanreed",
      "Allison",
      "Booker",
      "Phillips",
      "Bovina",
      "Boys ranch",
      "Briscoe",
      "Bushland",
      "Cactus",
      "Glazier",
      "Canyon",
      "Channing",
      "Claude",
      "Cotton center",
      "Dalhart",
      "Darrouzett",
      "Dawn",
      "Dimmitt",
      "Dumas",
      "Earth",
      "Edmonson",
      "Farnsworth",
      "Follett",
      "Black",
      "Fritch",
      "Groom",
      "Gruver",
      "Hale center",
      "Happy",
      "Hart",
      "Hartley",
      "Hereford",
      "Higgins",
      "Kress",
      "Lazbuddie",
      "Lefors",
      "Lipscomb",
      "Kellerville",
      "Miami",
      "Mobeetie",
      "Morse",
      "Nazareth",
      "Olton",
      "Pampa",
      "Panhandle",
      "Perryton",
      "Plainview",
      "Sanford",
      "Twitty",
      "Skellytown",
      "Spearman",
      "Springlake",
      "Stinnett",
      "Stratford",
      "Sunray",
      "Texline",
      "Vigo park",
      "Umbarger",
      "Vega",
      "Waka",
      "Wellington",
      "Wheeler",
      "White deer",
      "Wildorado",
      "Zcta 790hh",
      "Zcta 790xx",
      "Amarillo",
      "Zcta 791hh",
      "Zcta 791xx",
      "Kirkland",
      "Afton",
      "Cee vee",
      "Chillicothe",
      "Clarendon",
      "Crowell",
      "Dickens",
      "Dodson",
      "Dougherty",
      "Dumont",
      "Estelline",
      "Flomot",
      "Floydada",
      "Guthrie",
      "Hedley",
      "Lakeview",
      "Lelia lake",
      "Lockney",
      "Mcadoo",
      "Matador",
      "Memphis",
      "Odell",
      "Chalk",
      "Petersburg",
      "Quail",
      "Quanah",
      "Quitaque",
      "Roaring springs",
      "Silverton",
      "Tell",
      "Turkey",
      "Zcta 792hh",
      "Zcta 792xx",
      "Abernathy",
      "Amherst",
      "Anton",
      "Bledsoe",
      "Brownfield",
      "Bula",
      "Crosbyton",
      "Denver city",
      "Enochs",
      "Farwell",
      "Fieldton",
      "Idalou",
      "Justiceburg",
      "Lamesa",
      "Levelland",
      "Littlefield",
      "Loop",
      "Lorenzo",
      "Maple",
      "Meadow",
      "Morton",
      "Muleshoe",
      "New deal",
      "Odonnell",
      "Pep",
      "Plains",
      "Post",
      "Cone",
      "Ropesville",
      "Seagraves",
      "Seminole",
      "Shallowater",
      "Ransom canyon",
      "Smyer",
      "Spade",
      "Spur",
      "Sudan",
      "Sundown",
      "Tahoka",
      "Tokio",
      "Welch",
      "Wellman",
      "Whiteface",
      "Whitharral",
      "Wilson",
      "Wolfforth",
      "New home",
      "Zcta 793hh",
      "Zcta 793xx",
      "Lubbock",
      "Anson",
      "Aspermont",
      "Avoca",
      "Baird",
      "Benjamin",
      "Blackwell",
      "Buffalo gap",
      "Clyde",
      "Coahoma",
      "Colorado city",
      "Fluvanna",
      "Girard",
      "Goldsboro",
      "Hamlin",
      "Haskell",
      "Hawley",
      "Hermleigh",
      "Ira",
      "Jayton",
      "Knox city",
      "Lawn",
      "Loraine",
      "Lueders",
      "Mc caulley",
      "Maryneal",
      "Merkel",
      "Nolan",
      "Novice",
      "O brien",
      "Old glory",
      "Ovalo",
      "Roby",
      "Rochester",
      "Roscoe",
      "Rotan",
      "Rule",
      "Sagerton",
      "Dermott",
      "Stamford",
      "Sweetwater",
      "Sylvester",
      "Trent",
      "Tuscola",
      "Tye",
      "Westbrook",
      "Wingate",
      "Winters",
      "Zcta 795hh",
      "Zcta 795xx",
      "Abilene",
      "Dyess afb",
      "Zcta 796xx",
      "Midland",
      "Ackerly",
      "Andrews",
      "Balmorhea",
      "Barstow",
      "Vealmoor",
      "Coyanosa",
      "Crane",
      "Forsan",
      "Fort davis",
      "Fort stockton",
      "Gail",
      "Garden city",
      "Girvin",
      "Goldsmith",
      "Grandfalls",
      "Imperial",
      "Iraan",
      "Kermit",
      "Knott",
      "Lenorah",
      "Mc camey",
      "Mentone",
      "Midkiff",
      "Monahans",
      "Gardendale",
      "Notrees",
      "Odessa",
      "Verhalen",
      "Penwell",
      "Pyote",
      "Rankin",
      "Saragosa",
      "Sheffield",
      "Stanton",
      "Tarzan",
      "Toyah",
      "Wickett",
      "Wink",
      "Zcta 797hh",
      "Zcta 797xx",
      "Anthony",
      "Alpine",
      "Big bend nationa",
      "Canutillo",
      "Clint",
      "Dell city",
      "Fabens",
      "Fort hancock",
      "Marathon",
      "Marfa",
      "Presidio",
      "Redford",
      "Salt flat",
      "Sanderson",
      "San elizario",
      "Sierra blanca",
      "Terlingua",
      "Tornillo",
      "Valentine",
      "Kent",
      "Zcta 798xx",
      "El paso",
      "Fort bliss",
      "Horizon city",
      "Zcta 799xx",
    ],
    ZipCodes: {
      "": ["73949", "884XX"],
      Addison: ["75001"],
      Allen: ["75002"],
      Carrollton: ["75006", "75007", "75010"],
      Celina: ["75009"],
      "Zcta 75013": ["75013"],
      Coppell: ["75019"],
      Denison: ["75020", "75021"],
      "Zcta 75022": ["75022"],
      Plano: ["75023", "75024", "75025", "75074", "75075", "75093"],
      "Flower mound": ["75028"],
      "Zcta 75032": ["75032"],
      Frisco: ["75034"],
      "Zcta 75035": ["75035"],
      Irving: ["75038", "75039", "75060", "75061", "75062", "75063"],
      Garland: ["75040", "75041", "75042", "75043", "75044"],
      Sachse: ["75048"],
      "Grand prairie": ["75050", "75051", "75052"],
      "The colony": ["75056"],
      Lewisville: ["75057"],
      Gunter: ["75058"],
      "Lake dallas": ["75065"],
      "Highland village": ["75067"],
      "Lakewood village": ["75068"],
      "Mc kinney": ["75069", "75070"],
      Pottsboro: ["75076"],
      "Zcta 75077": ["75077"],
      Prosper: ["75078"],
      Richardson: ["75080", "75081", "75082"],
      Heath: ["75087"],
      Rowlett: ["75088"],
      "Zcta 75089": ["75089"],
      Sherman: ["75090", "75092"],
      Murphy: ["75094"],
      Wylie: ["75098"],
      "Zcta 750hh": ["750HH"],
      Bardwell: ["75101"],
      Barry: ["75102"],
      Canton: ["75103"],
      "Cedar hill": ["75104"],
      Chatfield: ["75105"],
      Corsicana: ["75110"],
      Crandall: ["75114"],
      "De soto": ["75115"],
      Duncanville: ["75116", "75137"],
      Edgewood: ["75117"],
      Ennis: ["75119"],
      Copeville: ["75121"],
      Eustace: ["75124"],
      Ferris: ["75125"],
      Forney: ["75126"],
      Fruitvale: ["75127"],
      Fate: ["75132"],
      Lancaster: ["75134", "75146"],
      "Caddo mills": ["75135"],
      "Grand saline": ["75140"],
      Hutchins: ["75141"],
      Kaufman: ["75142"],
      "Seven points": ["75143"],
      Kerens: ["75144"],
      "Gun barrel city": ["75147"],
      Malakoff: ["75148"],
      Mesquite: ["75149", "75150", "75181", "75182"],
      Palmer: ["75152"],
      Powell: ["75153"],
      Ovilla: ["75154"],
      Rice: ["75155"],
      Scurry: ["75158"],
      Seagoville: ["75159"],
      Terrell: ["75160"],
      "Zcta 75161": ["75161"],
      Trinidad: ["75163"],
      Josephine: ["75164"],
      Waxahachie: ["75165"],
      Lavon: ["75166"],
      "Zcta 75167": ["75167"],
      "Wills point": ["75169"],
      Wilmer: ["75172"],
      Nevada: ["75173"],
      "Balch springs": ["75180"],
      "Royse city": ["75189"],
      "Zcta 751hh": ["751HH"],
      Dallas: [
        "75201",
        "75202",
        "75203",
        "75204",
        "75206",
        "75207",
        "75208",
        "75209",
        "75210",
        "75212",
        "75214",
        "75215",
        "75216",
        "75217",
        "75218",
        "75219",
        "75220",
        "75223",
        "75224",
        "75225",
        "75226",
        "75227",
        "75228",
        "75229",
        "75230",
        "75231",
        "75232",
        "75233",
        "75235",
        "75236",
        "75237",
        "75238",
        "75240",
        "75241",
        "75243",
        "75246",
        "75247",
        "75248",
        "75249",
        "75251",
        "75252",
        "75253",
        "75270",
        "75287",
      ],
      Village: ["75205"],
      "Cockrell hill": ["75211"],
      "Farmers branch": ["75234", "75244"],
      "Zcta 752hh": ["752HH"],
      Greenville: ["75401", "75402"],
      Princeton: ["75407"],
      Anna: ["75409"],
      Alba: ["75410"],
      "Arthur city": ["75411"],
      Bagwell: ["75412"],
      Bailey: ["75413"],
      Bells: ["75414"],
      "Ben franklin": ["75415"],
      Blossom: ["75416"],
      Bogata: ["75417"],
      Bonham: ["75418"],
      Brashear: ["75420"],
      Brookston: ["75421"],
      Campbell: ["75422"],
      Celeste: ["75423"],
      "Blue ridge": ["75424"],
      Clarksville: ["75426"],
      Commerce: ["75428"],
      Como: ["75431"],
      Cooper: ["75432"],
      Cumby: ["75433"],
      Cunningham: ["75434"],
      Deport: ["75435"],
      Detroit: ["75436"],
      Dike: ["75437"],
      "Dodd city": ["75438"],
      Ector: ["75439"],
      Emory: ["75440"],
      Enloe: ["75441"],
      Farmersville: ["75442"],
      Golden: ["75444"],
      "Honey grove": ["75446"],
      Ivanhoe: ["75447"],
      Klondike: ["75448"],
      Ladonia: ["75449"],
      "Lake creek": ["75450"],
      Leesburg: ["75451"],
      Leonard: ["75452"],
      "Lone oak": ["75453"],
      Melissa: ["75454"],
      "Mount pleasant": ["75455"],
      "Mount vernon": ["75457"],
      Howe: ["75459"],
      Paris: ["75460"],
      Reno: ["75462"],
      Pattonville: ["75468"],
      "Pecan gap": ["75469"],
      Petty: ["75470"],
      Pickton: ["75471"],
      Point: ["75472"],
      Powderly: ["75473"],
      Quinlan: ["75474"],
      Randolph: ["75475"],
      Ravenna: ["75476"],
      Roxton: ["75477"],
      Saltillo: ["75478"],
      Savoy: ["75479"],
      Scroggins: ["75480"],
      "Sulphur bluff": ["75481"],
      "Sulphur springs": ["75482"],
      Westminster: ["75485"],
      Sumner: ["75486"],
      Talco: ["75487"],
      Telephone: ["75488"],
      "Tom bean": ["75489"],
      Trenton: ["75490"],
      Whitewright: ["75491"],
      Windom: ["75492"],
      Winfield: ["75493"],
      Winnsboro: ["75494"],
      "Van alstyne": ["75495"],
      "Wolfe city": ["75496"],
      Yantis: ["75497"],
      "Zcta 754hh": ["754HH"],
      "Wake village": ["75501"],
      Texarkana: ["75503"],
      Annona: ["75550"],
      Atlanta: ["75551"],
      Avery: ["75554"],
      Bivins: ["75555"],
      Bloomburg: ["75556"],
      Cookville: ["75558"],
      "De kalb": ["75559"],
      Douglassville: ["75560"],
      Leary: ["75561"],
      Kildare: ["75562"],
      Linden: ["75563"],
      Lodi: ["75564"],
      "Mc leod": ["75565"],
      Marietta: ["75566"],
      Maud: ["75567"],
      Naples: ["75568"],
      Nash: ["75569"],
      Boston: ["75570"],
      Omaha: ["75571"],
      "Queen city": ["75572"],
      Redwater: ["75573"],
      Simms: ["75574"],
      "Zcta 755hh": ["755HH"],
      "Zcta 755xx": ["755XX"],
      Longview: ["75601", "75602", "75603", "75604", "75605"],
      Avinger: ["75630"],
      Beckville: ["75631"],
      Carthage: ["75633"],
      Cason: ["75636"],
      Daingerfield: ["75638"],
      "De berry": ["75639"],
      "New diana": ["75640"],
      Easton: ["75641"],
      Gary: ["75643"],
      Gilmer: ["75644"],
      Gladewater: ["75647"],
      Hallsville: ["75650"],
      Harleton: ["75651"],
      Henderson: ["75652"],
      "Zcta 75654": ["75654"],
      "Hughes springs": ["75656"],
      Smithland: ["75657"],
      Karnack: ["75661"],
      Kilgore: ["75662"],
      Laneville: ["75667"],
      "Lone star": ["75668"],
      "Long branch": ["75669"],
      Marshall: ["75670"],
      "Zcta 75672": ["75672"],
      Minden: ["75680"],
      "Mount enterprise": ["75681"],
      "Ore city": ["75683"],
      Overton: ["75684"],
      Pittsburg: ["75686"],
      Tatum: ["75691"],
      Waskom: ["75692"],
      "Clarksville city": ["75693"],
      "Zcta 756hh": ["756HH"],
      Tyler: [
        "75701",
        "75702",
        "75703",
        "75704",
        "75705",
        "75706",
        "75707",
        "75709",
      ],
      "East texas cente": ["75708"],
      Arp: ["75750"],
      Athens: ["75751"],
      "Ben wheeler": ["75754"],
      "Big sandy": ["75755"],
      Edom: ["75756"],
      "Mount selman": ["75757"],
      Chandler: ["75758"],
      Cuney: ["75759"],
      Cushing: ["75760"],
      Flint: ["75762"],
      Frankston: ["75763"],
      Gallatin: ["75764"],
      Hawkins: ["75765"],
      Jacksonville: ["75766"],
      Larue: ["75770"],
      "Mt sylvan": ["75771"],
      Mineola: ["75773"],
      Murchison: ["75778"],
      Poynor: ["75782"],
      Quitman: ["75783"],
      Reklaw: ["75784"],
      Dialville: ["75785"],
      Sacul: ["75788"],
      Troup: ["75789"],
      Van: ["75790"],
      Whitehouse: ["75791"],
      Winona: ["75792"],
      "Zcta 757hh": ["757HH"],
      Palestine: ["75801"],
      Freestone: ["75831"],
      Centerville: ["75833"],
      Austonio: ["75835"],
      Donie: ["75838"],
      Slocum: ["75839"],
      Fairfield: ["75840"],
      Grapeland: ["75844"],
      Groveton: ["75845"],
      Jewett: ["75846"],
      Kennard: ["75847"],
      Kirvin: ["75848"],
      Latexo: ["75849"],
      Leona: ["75850"],
      Lovelady: ["75851"],
      Midway: ["75852"],
      Montalba: ["75853"],
      Oakwood: ["75855"],
      Pennington: ["75856"],
      Ratcliff: ["75858"],
      Streetman: ["75859"],
      Teague: ["75860"],
      "Tennessee colony": ["75861"],
      Trinity: ["75862"],
      "Zcta 758hh": ["758HH"],
      Keltys: ["75901"],
      Lufkin: ["75904"],
      Forest: ["75925"],
      "Apple springs": ["75926"],
      "Bon wier": ["75928"],
      Broaddus: ["75929"],
      Bronson: ["75930"],
      Brookeland: ["75931"],
      Burkeville: ["75932"],
      Call: ["75933"],
      Camden: ["75934"],
      Center: ["75935"],
      Chester: ["75936"],
      Chireno: ["75937"],
      Rockland: ["75938"],
      Barnum: ["75939"],
      Diboll: ["75941"],
      Doucette: ["75942"],
      Douglass: ["75943"],
      Etoile: ["75944"],
      Garrison: ["75946"],
      Hemphill: ["75948"],
      Huntington: ["75949"],
      "Sam rayburn": ["75951"],
      Joaquin: ["75954"],
      "Bon ami": ["75956"],
      Milam: ["75959"],
      Moscow: ["75960"],
      Appleby: ["75961"],
      Nacogdoches: ["75964"],
      Newton: ["75966"],
      Pineland: ["75968"],
      Pollok: ["75969"],
      "San augustine": ["75972"],
      Shelbyville: ["75973"],
      Tenaha: ["75974"],
      Timpson: ["75975"],
      Wells: ["75976"],
      Wiergate: ["75977"],
      Woden: ["75978"],
      Dogwood: ["75979"],
      Zavalla: ["75980"],
      "Zcta 759hh": ["759HH"],
      "Zcta 759xx": ["759XX"],
      "Zcta 76001": ["76001"],
      "Zcta 76002": ["76002"],
      Arlington: [
        "76006",
        "76010",
        "76011",
        "76012",
        "76013",
        "76014",
        "76015",
        "76016",
        "76017",
        "76018",
      ],
      Aledo: ["76008"],
      Alvarado: ["76009"],
      Azle: ["76020"],
      Bedford: ["76021", "76022"],
      Boyd: ["76023"],
      Burleson: ["76028"],
      Cleburne: ["76031"],
      Colleyville: ["76034"],
      Cresson: ["76035"],
      Crowley: ["76036"],
      Euless: ["76039", "76040"],
      Forreston: ["76041"],
      "Glen rose": ["76043"],
      Godley: ["76044"],
      Granbury: ["76048", "76049"],
      Grandview: ["76050"],
      Grapevine: ["76051", "76092"],
      Haslet: ["76052"],
      Hurst: ["76053", "76054"],
      Itasca: ["76055"],
      Joshua: ["76058"],
      Keene: ["76059"],
      Kennedale: ["76060"],
      Lillian: ["76061"],
      Mansfield: ["76063"],
      Maypearl: ["76064"],
      Midlothian: ["76065"],
      Millsap: ["76066"],
      "Mineral wells": ["76067"],
      Nemo: ["76070"],
      Newark: ["76071"],
      Paradise: ["76073"],
      Rainbow: ["76077"],
      Rhome: ["76078"],
      Springtown: ["76082"],
      Venus: ["76084"],
      Weatherford: ["76086", "76087"],
      "Zcta 76088": ["76088"],
      "Rio vista": ["76093"],
      "Zcta 760hh": ["760HH"],
      "Fort worth": [
        "76102",
        "76103",
        "76104",
        "76105",
        "76106",
        "76107",
        "76109",
        "76110",
        "76111",
        "76112",
        "76115",
        "76116",
        "76119",
        "76120",
        "76123",
        "76131",
        "76132",
        "76133",
        "76134",
        "76135",
        "76137",
        "76155",
        "76177",
      ],
      "White settlement": ["76108"],
      "River oaks": ["76114"],
      "Haltom city": ["76117"],
      "North richland h": ["76118", "76180"],
      Benbrook: ["76126"],
      "Carswell afb": ["76127"],
      Everman: ["76140"],
      Watauga: ["76148"],
      Saginaw: ["76179"],
      "Zcta 761hh": ["761HH"],
      Denton: ["76201", "76205", "76207", "76208"],
      Alvord: ["76225"],
      Argyle: ["76226"],
      Aubrey: ["76227"],
      Bellevue: ["76228"],
      Bowie: ["76230"],
      Collinsville: ["76233"],
      Decatur: ["76234"],
      Era: ["76238"],
      Forestburg: ["76239"],
      "Lake kiowa": ["76240"],
      Gordonville: ["76245"],
      Greenwood: ["76246"],
      Justin: ["76247"],
      Keller: ["76248"],
      Krum: ["76249"],
      Lindsay: ["76250"],
      Montague: ["76251"],
      Muenster: ["76252"],
      Myra: ["76253"],
      Nocona: ["76255"],
      "Pilot point": ["76258"],
      Ponder: ["76259"],
      Ringgold: ["76261"],
      "Trophy club": ["76262"],
      Sadler: ["76264"],
      "Saint jo": ["76265"],
      Sanger: ["76266"],
      Southmayd: ["76268"],
      Sunset: ["76270"],
      Tioga: ["76271"],
      "Valley view": ["76272"],
      Whitesboro: ["76273"],
      "Zcta 762hh": ["762HH"],
      "Wichita falls": [
        "76301",
        "76302",
        "76305",
        "76306",
        "76308",
        "76309",
        "76310",
      ],
      "Sheppard afb": ["76311"],
      "Archer city": ["76351"],
      Burkburnett: ["76354"],
      Byers: ["76357"],
      Electra: ["76360"],
      Goree: ["76363"],
      Harrold: ["76364"],
      Henrietta: ["76365"],
      Holliday: ["76366"],
      "Iowa park": ["76367"],
      Megargel: ["76370"],
      Munday: ["76371"],
      Newcastle: ["76372"],
      Oklaunion: ["76373"],
      Olney: ["76374"],
      Petrolia: ["76377"],
      Scotland: ["76379"],
      Seymour: ["76380"],
      Vernon: ["76384"],
      Weinert: ["76388"],
      Windthorst: ["76389"],
      "Zcta 763hh": ["763HH"],
      "Zcta 763xx": ["763XX"],
      Stephenville: ["76401"],
      Breckenridge: ["76424"],
      Bridgeport: ["76426"],
      Bryson: ["76427"],
      Caddo: ["76429"],
      Albany: ["76430"],
      Chico: ["76431"],
      Blanket: ["76432"],
      "Bluff dale": ["76433"],
      Carbon: ["76435"],
      Carlton: ["76436"],
      Cisco: ["76437"],
      Comanche: ["76442"],
      "Cross plains": ["76443"],
      "De leon": ["76444"],
      Desdemona: ["76445"],
      Dublin: ["76446"],
      Eastland: ["76448"],
      Graford: ["76449"],
      Graham: ["76450"],
      Energy: ["76452"],
      Gordon: ["76453"],
      Gorman: ["76454"],
      Gustine: ["76455"],
      Hico: ["76457"],
      Jacksboro: ["76458"],
      Jermyn: ["76459"],
      Loving: ["76460"],
      Lipan: ["76462"],
      Mingus: ["76463"],
      Moran: ["76464"],
      Olden: ["76466"],
      Proctor: ["76468"],
      Putnam: ["76469"],
      Ranger: ["76470"],
      "Rising star": ["76471"],
      Santo: ["76472"],
      Sidney: ["76474"],
      Strawn: ["76475"],
      Tolar: ["76476"],
      "South bend": ["76481"],
      Throckmorton: ["76483"],
      "Palo pinto": ["76484"],
      Perrin: ["76486"],
      Poolville: ["76487"],
      Whitt: ["76490"],
      Woodson: ["76491"],
      "Zcta 764hh": ["764HH"],
      "Zcta 764xx": ["764XX"],
      Temple: ["76501", "76502", "76504"],
      Bartlett: ["76511"],
      Belton: ["76513"],
      Buckholts: ["76518"],
      Burlington: ["76519"],
      Cameron: ["76520"],
      Izoro: ["76522"],
      Davilla: ["76523"],
      Eddy: ["76524"],
      "Bee house": ["76525"],
      Florence: ["76527"],
      Turnersville: ["76528"],
      Granger: ["76530"],
      Hamilton: ["76531"],
      Holland: ["76534"],
      Jarrell: ["76537"],
      Jonesboro: ["76538"],
      Kempner: ["76539"],
      Killeen: ["76541"],
      "Harker heights": ["76542", "76543"],
      "Fort hood": ["76544"],
      "Zcta 76548": ["76548"],
      "Zcta 76549": ["76549"],
      Lampasas: ["76550"],
      "Little river": ["76554"],
      Milano: ["76556"],
      Moody: ["76557"],
      Nolanville: ["76559"],
      Oglesby: ["76561"],
      Pottsville: ["76565"],
      Purmela: ["76566"],
      Rockdale: ["76567"],
      Rogers: ["76569"],
      Rosebud: ["76570"],
      Salado: ["76571"],
      Taylor: ["76574"],
      Thorndale: ["76577"],
      Thrall: ["76578"],
      Troy: ["76579"],
      "Zcta 765hh": ["765HH"],
      "Zcta 765xx": ["765XX"],
      Abbott: ["76621"],
      Aquilla: ["76622"],
      Axtell: ["76624"],
      "Blooming grove": ["76626"],
      Blum: ["76627"],
      Brandon: ["76628"],
      Bremond: ["76629"],
      Bruceville: ["76630"],
      Bynum: ["76631"],
      Chilton: ["76632"],
      "China spring": ["76633"],
      "Laguna park": ["76634"],
      Coolidge: ["76635"],
      Covington: ["76636"],
      "Cranfills gap": ["76637"],
      Crawford: ["76638"],
      Dawson: ["76639"],
      "Elm mott": ["76640"],
      Frost: ["76641"],
      Groesbeck: ["76642"],
      Hewitt: ["76643"],
      Hillsboro: ["76645"],
      Hubbard: ["76648"],
      Iredell: ["76649"],
      Italy: ["76651"],
      Kopperl: ["76652"],
      Kosse: ["76653"],
      Lorena: ["76655"],
      Lott: ["76656"],
      "Mc gregor": ["76657"],
      Malone: ["76660"],
      Marlin: ["76661"],
      Mart: ["76664"],
      Meridian: ["76665"],
      Mertens: ["76666"],
      Mexia: ["76667"],
      Milford: ["76670"],
      Morgan: ["76671"],
      "Mount calm": ["76673"],
      Otto: ["76675"],
      Penelope: ["76676"],
      "Prairie hill": ["76678"],
      Purdon: ["76679"],
      Reagan: ["76680"],
      Richland: ["76681"],
      Riesel: ["76682"],
      Satin: ["76685"],
      Tehuacana: ["76686"],
      Thornton: ["76687"],
      "Valley mills": ["76689"],
      "Walnut springs": ["76690"],
      West: ["76691"],
      Bonanza: ["76692"],
      Wortham: ["76693"],
      "Zcta 766hh": ["766HH"],
      Waco: ["76701", "76706", "76707", "76708", "76710", "76798"],
      Bellmead: ["76704", "76705"],
      "Beverly hills": ["76711"],
      Woodway: ["76712"],
      "Zcta 767hh": ["767HH"],
      Early: ["76801"],
      "Zcta 76802": ["76802"],
      Ballinger: ["76821"],
      Bangs: ["76823"],
      Bend: ["76824"],
      Fife: ["76825"],
      Brookesmith: ["76827"],
      Burkett: ["76828"],
      Castell: ["76831"],
      Cherokee: ["76832"],
      Coleman: ["76834"],
      Eden: ["76837"],
      "Fort mc kavett": ["76841"],
      Fredonia: ["76842"],
      Goldthwaite: ["76844"],
      Gouldbusk: ["76845"],
      Hext: ["76848"],
      Junction: ["76849"],
      Lohn: ["76852"],
      Lometa: ["76853"],
      London: ["76854"],
      Mason: ["76856"],
      May: ["76857"],
      Melvin: ["76858"],
      Menard: ["76859"],
      Miles: ["76861"],
      Millersview: ["76862"],
      Mullin: ["76864"],
      Norton: ["76865"],
      "Paint rock": ["76866"],
      Pontotoc: ["76869"],
      Priddy: ["76870"],
      "Richland springs": ["76871"],
      Rochelle: ["76872"],
      Rockwood: ["76873"],
      Roosevelt: ["76874"],
      Rowena: ["76875"],
      "San saba": ["76877"],
      "Santa anna": ["76878"],
      Talpa: ["76882"],
      Valera: ["76884"],
      "Valley spring": ["76885"],
      Voca: ["76887"],
      Leaday: ["76888"],
      Zephyr: ["76890"],
      "Zcta 768hh": ["768HH"],
      "Zcta 768xx": ["768XX"],
      "San angelo": ["76901", "76903", "76904", "76905"],
      Barnhart: ["76930"],
      Best: ["76932"],
      Bronte: ["76933"],
      Carlsbad: ["76934"],
      Christoval: ["76935"],
      Eldorado: ["76936"],
      Eola: ["76937"],
      Mereta: ["76940"],
      Mertzon: ["76941"],
      Ozona: ["76943"],
      "Robert lee": ["76945"],
      Silver: ["76949"],
      Sonora: ["76950"],
      "Sterling city": ["76951"],
      Tennyson: ["76953"],
      Vancourt: ["76955"],
      "Water valley": ["76958"],
      "Zcta 769hh": ["769HH"],
      "Zcta 769xx": ["769XX"],
      Houston: [
        "77002",
        "77003",
        "77004",
        "77005",
        "77006",
        "77007",
        "77008",
        "77009",
        "77010",
        "77011",
        "77012",
        "77013",
        "77014",
        "77015",
        "77016",
        "77017",
        "77018",
        "77019",
        "77020",
        "77021",
        "77022",
        "77023",
        "77024",
        "77025",
        "77026",
        "77027",
        "77028",
        "77031",
        "77032",
        "77033",
        "77034",
        "77035",
        "77036",
        "77037",
        "77038",
        "77039",
        "77041",
        "77042",
        "77043",
        "77044",
        "77045",
        "77046",
        "77047",
        "77048",
        "77049",
        "77050",
        "77051",
        "77053",
        "77054",
        "77055",
        "77056",
        "77057",
        "77058",
        "77059",
        "77060",
        "77061",
        "77062",
        "77063",
        "77064",
        "77065",
        "77066",
        "77067",
        "77068",
        "77069",
        "77070",
        "77071",
        "77072",
        "77073",
        "77074",
        "77075",
        "77076",
        "77077",
        "77078",
        "77079",
        "77080",
        "77081",
        "77082",
        "77083",
        "77084",
        "77085",
        "77086",
        "77087",
        "77088",
        "77089",
        "77090",
        "77091",
        "77092",
        "77093",
        "77094",
        "77095",
        "77096",
        "77098",
        "77099",
        "770HH",
      ],
      "Jacinto city": ["77029"],
      "V a hospital": ["77030"],
      "Jersey village": ["77040"],
      Conroe: ["77301", "77384", "77385"],
      Grangerland: ["77302"],
      "Cut and shoot": ["77303"],
      "Panorama village": ["77304"],
      "Zcta 77306": ["77306"],
      "Zcta 77316": ["77316"],
      "Zcta 77318": ["77318"],
      "Zcta 77320": ["77320"],
      Ace: ["77326"],
      Cleveland: ["77327"],
      Coldspring: ["77331"],
      Dobbin: ["77333"],
      Dodge: ["77334"],
      Goodrich: ["77335"],
      Huffman: ["77336"],
      Humble: ["77338", "77339", "77345", "77346", "77396"],
      Huntsville: ["77340", "77341"],
      Segno: ["77351"],
      "Zcta 77354": ["77354"],
      Magnolia: ["77355"],
      Montgomery: ["77356"],
      "New caney": ["77357"],
      "New waverly": ["77358"],
      Oakhurst: ["77359"],
      Onalaska: ["77360"],
      Pinehurst: ["77362"],
      Plantersville: ["77363"],
      Pointblank: ["77364"],
      Porter: ["77365"],
      Riverside: ["77367"],
      Romayor: ["77368"],
      Rye: ["77369"],
      Shepherd: ["77371"],
      Splendora: ["77372"],
      Spring: ["77373", "77382", "77386", "77388", "77389"],
      Thicket: ["77374"],
      Tomball: ["77375"],
      Votaw: ["77376"],
      Willis: ["77378"],
      Klein: ["77379"],
      "The woodlands": ["77380", "77381"],
      "Zcta 773hh": ["773HH"],
      "Zcta 773xx": ["773XX"],
      Bellaire: ["77401"],
      Altair: ["77412"],
      Sargent: ["77414"],
      "Cedar lane": ["77415"],
      Beasley: ["77417"],
      Bellville: ["77418"],
      Blessing: ["77419"],
      Boling: ["77420"],
      Brazoria: ["77422"],
      Brookshire: ["77423"],
      "Chappell hill": ["77426"],
      Collegeport: ["77428"],
      Cypress: ["77429", "77433"],
      Damon: ["77430"],
      Danevang: ["77432"],
      "Eagle lake": ["77434"],
      "East bernard": ["77435"],
      "El campo": ["77437"],
      Elmaton: ["77440"],
      Fulshear: ["77441"],
      Garwood: ["77442"],
      "Glen flora": ["77443"],
      Guy: ["77444"],
      Hempstead: ["77445"],
      "Prairie view": ["77446"],
      Hockley: ["77447"],
      Hungerford: ["77448"],
      "Park row": ["77449", "77450", "77493", "77494"],
      Kendleton: ["77451"],
      Lissie: ["77454"],
      Louise: ["77455"],
      Markham: ["77456"],
      Matagorda: ["77457"],
      Midfield: ["77458"],
      "Missouri city": ["77459", "77489"],
      Needville: ["77461"],
      "Old ocean": ["77463"],
      Orchard: ["77464"],
      Palacios: ["77465"],
      Pattison: ["77466"],
      Pierce: ["77467"],
      Pledger: ["77468"],
      Clodine: ["77469"],
      "Rock island": ["77470"],
      Rosenberg: ["77471"],
      "San felipe": ["77473"],
      Sealy: ["77474"],
      Sheridan: ["77475"],
      Simonton: ["77476"],
      Stafford: ["77477"],
      "Sugar land": ["77478", "77479"],
      Sweeny: ["77480"],
      Thompsons: ["77481"],
      "Van vleck": ["77482"],
      Wadsworth: ["77483"],
      Waller: ["77484"],
      Wallis: ["77485"],
      "West columbia": ["77486"],
      Wharton: ["77488"],
      "Zcta 774hh": ["774HH"],
      "Zcta 774xx": ["774XX"],
      Pasadena: ["77502", "77503", "77504", "77505", "77506", "77507"],
      "Alta loma": ["77510"],
      Alvin: ["77511"],
      "Monroe city": ["77514"],
      Angleton: ["77515"],
      Arcadia: ["77517"],
      Bacliff: ["77518"],
      Batson: ["77519"],
      Baytown: ["77520", "77521"],
      Channelview: ["77530"],
      Clute: ["77531"],
      Barrett: ["77532"],
      Daisetta: ["77533"],
      Danbury: ["77534"],
      Dayton: ["77535"],
      "Deer park": ["77536"],
      Devers: ["77538"],
      "San leon": ["77539"],
      Quintana: ["77541"],
      Fresno: ["77545"],
      Friendswood: ["77546"],
      "Galena park": ["77547"],
      Galveston: ["77550", "77551", "77553", "77554"],
      Hankamer: ["77560"],
      Hardin: ["77561"],
      Highlands: ["77562"],
      Hitchcock: ["77563"],
      Hull: ["77564"],
      "Clear lake shore": ["77565"],
      "Lake jackson": ["77566"],
      "La marque": ["77568"],
      Shoreacres: ["77571"],
      "League city": ["77573"],
      Ames: ["77575"],
      Liverpool: ["77577"],
      Manvel: ["77578"],
      Pearland: ["77581", "77584"],
      Raywood: ["77582"],
      Rosharon: ["77583"],
      Saratoga: ["77585"],
      "El lago": ["77586"],
      "South houston": ["77587"],
      "Texas city": ["77590", "77591"],
      Wallisville: ["77597"],
      Webster: ["77598"],
      "Zcta 775hh": ["775HH"],
      "Zcta 775xx": ["775XX"],
      "Bridge city": ["77611"],
      Buna: ["77612"],
      China: ["77613"],
      Deweyville: ["77614"],
      Evadale: ["77615"],
      Fred: ["77616"],
      Gilchrist: ["77617"],
      Groves: ["77619"],
      Hamshire: ["77622"],
      "High island": ["77623"],
      Hillister: ["77624"],
      Kountze: ["77625"],
      Nederland: ["77627"],
      Nome: ["77629"],
      "West orange": ["77630"],
      Orange: ["77632"],
      "Port acres": ["77640"],
      "Port arthur": ["77642"],
      "Crystal beach": ["77650"],
      "Port neches": ["77651"],
      "Sabine pass": ["77655"],
      Silsbee: ["77656"],
      "Zcta 77657": ["77657"],
      "Sour lake": ["77659"],
      Spurger: ["77660"],
      Vidor: ["77662"],
      "Village mills": ["77663"],
      Warren: ["77664"],
      Winnie: ["77665"],
      "Zcta 776hh": ["776HH"],
      "Zcta 776xx": ["776XX"],
      Beaumont: [
        "77701",
        "77702",
        "77703",
        "77705",
        "77706",
        "77707",
        "77708",
        "77713",
      ],
      "Zcta 777hh": ["777HH"],
      "Zcta 777xx": ["777XX"],
      Bryan: ["77801", "77802", "77803", "77807", "77808"],
      Anderson: ["77830"],
      Singleton: ["77831"],
      Brenham: ["77833"],
      Burton: ["77835"],
      Caldwell: ["77836"],
      Calvert: ["77837"],
      "College station": ["77840", "77845"],
      "Dime box": ["77853"],
      Flynn: ["77855"],
      Franklin: ["77856"],
      Gause: ["77857"],
      Hearne: ["77859"],
      Iola: ["77861"],
      Madisonville: ["77864"],
      Marquez: ["77865"],
      Millican: ["77866"],
      Mumford: ["77867"],
      Navasota: ["77868"],
      "New baden": ["77870"],
      "Hilltop lakes": ["77871"],
      "North zulch": ["77872"],
      Richards: ["77873"],
      Shiro: ["77876"],
      Snook: ["77878"],
      Somerville: ["77879"],
      Washington: ["77880"],
      Wheelock: ["77882"],
      "Zcta 778hh": ["778HH"],
      Victoria: ["77901", "77904", "77905"],
      Austwell: ["77950"],
      Bloomington: ["77951"],
      Cuero: ["77954"],
      Edna: ["77957"],
      Fannin: ["77960"],
      Francitas: ["77961"],
      Ganado: ["77962"],
      Goliad: ["77963"],
      Hallettsville: ["77964"],
      Inez: ["77968"],
      "La salle": ["77969"],
      "La ward": ["77970"],
      Lolita: ["77971"],
      Mcfaddin: ["77973"],
      Meyersville: ["77974"],
      Moulton: ["77975"],
      Placedo: ["77977"],
      "Point comfort": ["77978"],
      "Port lavaca": ["77979"],
      "Port o connor": ["77982"],
      Seadrift: ["77983"],
      Shiner: ["77984"],
      Telferner: ["77988"],
      Tivoli: ["77990"],
      Vanderbilt: ["77991"],
      Westhoff: ["77994"],
      Yoakum: ["77995"],
      "Zcta 779hh": ["779HH"],
      "Zcta 779xx": ["779XX"],
      "Artesia wells": ["78001"],
      Atascosa: ["78002"],
      Bandera: ["78003"],
      Bergheim: ["78004"],
      Bigfoot: ["78005"],
      Sisterdale: ["78006"],
      Calliham: ["78007"],
      Campbellton: ["78008"],
      Castroville: ["78009"],
      "Camp verde": ["78010"],
      Charlotte: ["78011"],
      Christine: ["78012"],
      Comfort: ["78013"],
      Cotulla: ["78014"],
      "Zcta 78015": ["78015"],
      Devine: ["78016"],
      Dilley: ["78017"],
      Encinal: ["78019"],
      Fowlerton: ["78021"],
      "George west": ["78022"],
      "Grey forest": ["78023"],
      Hunt: ["78024"],
      Ingram: ["78025"],
      Jourdanton: ["78026"],
      Kendalia: ["78027"],
      Kerrville: ["78028"],
      "La coste": ["78039"],
      Laredo: ["78040", "78041"],
      "Rio bravo": ["78043"],
      "Zcta 78045": ["78045"],
      "Zcta 78046": ["78046"],
      Leming: ["78050"],
      Lytle: ["78052"],
      "Mc coy": ["78053"],
      Medina: ["78055"],
      Mico: ["78056"],
      Moore: ["78057"],
      "Mountain home": ["78058"],
      Natalia: ["78059"],
      Pearsall: ["78061"],
      Lakehills: ["78063"],
      Pleasanton: ["78064"],
      Poteet: ["78065"],
      Riomedina: ["78066"],
      "San ygnacio": ["78067"],
      Somerset: ["78069"],
      "Spring branch": ["78070"],
      "Three rivers": ["78071"],
      Tilden: ["78072"],
      "Von ormy": ["78073"],
      Waring: ["78074"],
      Whitsett: ["78075"],
      Zapata: ["78076"],
      "Zcta 780hh": ["780HH"],
      "Zcta 780xx": ["780XX"],
      Adkins: ["78101"],
      Beeville: ["78102"],
      Berclair: ["78107"],
      Cibolo: ["78108"],
      Converse: ["78109"],
      Elmendorf: ["78112"],
      "Falls city": ["78113"],
      Floresville: ["78114"],
      Gillett: ["78116"],
      Hobson: ["78117"],
      "Karnes city": ["78118"],
      Kenedy: ["78119"],
      "La vernia": ["78121"],
      Leesville: ["78122"],
      "Mc queeney": ["78123"],
      Marion: ["78124"],
      Mineral: ["78125"],
      "Canyon lake": ["78130", "78132", "78133"],
      Nixon: ["78140"],
      Nordheim: ["78141"],
      Normanna: ["78142"],
      Pandora: ["78143"],
      "Panna maria": ["78144"],
      Pawnee: ["78145"],
      Pettus: ["78146"],
      Poth: ["78147"],
      "Randolph a f b": ["78148"],
      Runge: ["78151"],
      "Saint hedwig": ["78152"],
      Selma: ["78154"],
      Seguin: ["78155"],
      Smiley: ["78159"],
      Stockdale: ["78160"],
      "Sutherland sprin": ["78161"],
      Wetmore: ["78163", "78247"],
      Yorktown: ["78164"],
      "Zcta 781hh": ["781HH"],
      "Zcta 781xx": ["781XX"],
      "Balcones heights": ["78201"],
      "San antonio": [
        "78202",
        "78203",
        "78204",
        "78205",
        "78207",
        "78208",
        "78210",
        "78211",
        "78214",
        "78215",
        "78216",
        "78217",
        "78218",
        "78220",
        "78221",
        "78222",
        "78223",
        "78224",
        "78225",
        "78226",
        "78227",
        "78228",
        "78229",
        "78230",
        "78237",
        "78240",
        "78242",
        "78244",
        "78245",
        "78248",
        "78249",
        "78250",
        "78251",
        "78252",
        "78253",
        "78254",
        "78255",
        "78256",
        "78257",
        "78258",
        "78259",
        "78260",
        "78261",
        "78263",
        "78264",
      ],
      "Alamo heights": ["78209"],
      "Olmos park": ["78212"],
      "Castle hills": ["78213"],
      Kirby: ["78219"],
      "Shavano park": ["78231"],
      "Hollywood park": ["78232"],
      "Live oak": ["78233"],
      "Fort sam houston": ["78234"],
      "Brooks a f b": ["78235"],
      "Wilford hall u s": ["78236"],
      "Leon valley": ["78238"],
      Windcrest: ["78239"],
      "Garden ridge": ["78266"],
      "Zcta 782hh": ["782HH"],
      "Zcta 782xx": ["782XX"],
      "Agua dulce": ["78330"],
      Alice: ["78332"],
      "Aransas pass": ["78336"],
      Armstrong: ["78338"],
      Banquete: ["78339"],
      Bayside: ["78340"],
      Bishop: ["78343"],
      Bruni: ["78344"],
      "Chapman ranch": ["78347"],
      Concepcion: ["78349"],
      Driscoll: ["78351"],
      Edroy: ["78352"],
      Encino: ["78353"],
      Falfurrias: ["78355"],
      Freer: ["78357"],
      Fulton: ["78358"],
      Gregory: ["78359"],
      Hebbronville: ["78361"],
      Ingleside: ["78362"],
      "Kingsville naval": ["78363"],
      Kingsville: ["78364"],
      Mathis: ["78368"],
      "Mirando city": ["78369"],
      Odem: ["78370"],
      Oilton: ["78371"],
      "Orange grove": ["78372"],
      "Port aransas": ["78373"],
      Portland: ["78374"],
      Premont: ["78375"],
      Realitos: ["78376"],
      Refugio: ["78377"],
      Riviera: ["78379"],
      Robstown: ["78380"],
      Rockport: ["78382"],
      Sandia: ["78383"],
      "San diego": ["78384"],
      Sarita: ["78385"],
      Sinton: ["78387"],
      Skidmore: ["78389"],
      Taft: ["78390"],
      Tynan: ["78391"],
      Woodsboro: ["78393"],
      "Zcta 783hh": ["783HH"],
      "Zcta 783xx": ["783XX"],
      "Corpus christi": [
        "78401",
        "78402",
        "78404",
        "78405",
        "78406",
        "78407",
        "78408",
        "78409",
        "78410",
        "78411",
        "78412",
        "78413",
        "78414",
        "78415",
        "78416",
        "78417",
        "78418",
      ],
      "Zcta 784hh": ["784HH"],
      Mcallen: ["78501", "78503", "78504"],
      Alamo: ["78516"],
      Brownsville: ["78520", "78521", "78526"],
      Donna: ["78537"],
      "Monte alto": ["78538"],
      Edinburg: ["78539"],
      Elsa: ["78543"],
      "Falcon heights": ["78545"],
      Garciasville: ["78547"],
      Grulla: ["78548"],
      Hargill: ["78549"],
      Harlingen: ["78550", "78552"],
      Hidalgo: ["78557"],
      "La blanca": ["78558"],
      "La feria": ["78559"],
      "La joya": ["78560"],
      "La villa": ["78562"],
      Linn: ["78563"],
      "Los ebanos": ["78565"],
      Bayview: ["78566"],
      Lyford: ["78569"],
      Mercedes: ["78570"],
      Alton: ["78572"],
      Olmito: ["78575"],
      Penitas: ["78576"],
      Pharr: ["78577"],
      "Port isabel": ["78578"],
      Progreso: ["78579"],
      Raymondville: ["78580"],
      "Rio grande city": ["78582"],
      "Rio hondo": ["78583"],
      Roma: ["78584"],
      "San benito": ["78586"],
      "San isidro": ["78588"],
      "San juan": ["78589"],
      "Santa maria": ["78592"],
      "Santa rosa": ["78593"],
      Sebastian: ["78594"],
      "Sullivan city": ["78595"],
      Weslaco: ["78596"],
      "South padre isla": ["78597"],
      "Port mansfield": ["78598"],
      "Zcta 785hh": ["785HH"],
      "Zcta 785xx": ["785XX"],
      Bastrop: ["78602"],
      Bertram: ["78605"],
      Blanco: ["78606"],
      Bluffton: ["78607"],
      Briggs: ["78608"],
      "Buchanan dam": ["78609"],
      Buda: ["78610"],
      Burnet: ["78611"],
      "Cedar creek": ["78612"],
      "Cedar park": ["78613"],
      Cost: ["78614"],
      Coupland: ["78615"],
      Dale: ["78616"],
      "Del valle": ["78617"],
      Doss: ["78618"],
      Driftwood: ["78619"],
      "Dripping springs": ["78620"],
      Elgin: ["78621"],
      Fentress: ["78622"],
      Fischer: ["78623"],
      Fredericksburg: ["78624"],
      Georgetown: ["78626"],
      Andice: ["78628"],
      Gonzales: ["78629"],
      Harper: ["78631"],
      Harwood: ["78632"],
      Hutto: ["78634"],
      Hye: ["78635"],
      "Johnson city": ["78636"],
      Kingsbury: ["78638"],
      Kingsland: ["78639"],
      Uhland: ["78640"],
      Leander: ["78641"],
      "Liberty hill": ["78642"],
      "Sunrise beach": ["78643"],
      Lockhart: ["78644"],
      Jonestown: ["78645"],
      Luling: ["78648"],
      "Mc dade": ["78650"],
      Manchaca: ["78652"],
      Manor: ["78653"],
      "Cypress mill": ["78654"],
      Martindale: ["78655"],
      Maxwell: ["78656"],
      "Zcta 78657": ["78657"],
      Ottine: ["78658"],
      Paige: ["78659"],
      Pflugerville: ["78660"],
      "Prairie lea": ["78661"],
      "Red rock": ["78662"],
      "Round mountain": ["78663"],
      "Round rock": ["78664", "78681"],
      "San marcos": ["78666"],
      Spicewood: ["78669"],
      Staples: ["78670"],
      Albert: ["78671"],
      Tow: ["78672"],
      "Willow city": ["78675"],
      Wimberley: ["78676"],
      "Zcta 786hh": ["786HH"],
      Austin: [
        "78701",
        "78702",
        "78703",
        "78704",
        "78705",
        "78717",
        "78719",
        "78721",
        "78722",
        "78723",
        "78724",
        "78725",
        "78726",
        "78727",
        "78728",
        "78729",
        "78730",
        "78731",
        "78732",
        "78733",
        "78735",
        "78736",
        "78737",
        "78738",
        "78739",
        "78741",
        "78742",
        "78744",
        "78745",
        "78748",
        "78749",
        "78750",
        "78751",
        "78752",
        "78753",
        "78754",
        "78756",
        "78757",
        "78758",
        "78759",
      ],
      Lakeway: ["78734"],
      "West lake hills": ["78746"],
      Creedmoor: ["78747"],
      "Zcta 787hh": ["787HH"],
      Uvalde: ["78801"],
      Asherton: ["78827"],
      Barksdale: ["78828"],
      Batesville: ["78829"],
      "Big wells": ["78830"],
      Brackettville: ["78832"],
      "Camp wood": ["78833"],
      "Carrizo springs": ["78834"],
      Catarina: ["78836"],
      Comstock: ["78837"],
      Concan: ["78838"],
      "Crystal city": ["78839"],
      "Laughlin a f b": ["78840"],
      "D hanis": ["78850"],
      Dryden: ["78851"],
      "Eagle pass": ["78852"],
      "El indio": ["78860"],
      Dunlay: ["78861"],
      Knippa: ["78870"],
      "La pryor": ["78872"],
      Leakey: ["78873"],
      Spofford: ["78877"],
      "Rio frio": ["78879"],
      Rocksprings: ["78880"],
      Sabinal: ["78881"],
      Tarpley: ["78883"],
      Utopia: ["78884"],
      Vanderpool: ["78885"],
      Yancey: ["78886"],
      "Zcta 788hh": ["788HH"],
      "Zcta 788xx": ["788XX"],
      Carmine: ["78932"],
      "Cat spring": ["78933"],
      Columbus: ["78934"],
      Alleyton: ["78935"],
      Ellinger: ["78938"],
      Fayetteville: ["78940"],
      Flatonia: ["78941"],
      Giddings: ["78942"],
      Glidden: ["78943"],
      Industry: ["78944"],
      "La grange": ["78945"],
      Ledbetter: ["78946"],
      Lexington: ["78947"],
      Lincoln: ["78948"],
      Muldoon: ["78949"],
      "New ulm": ["78950"],
      Rosanky: ["78953"],
      "Round top": ["78954"],
      Schulenburg: ["78956"],
      Smithville: ["78957"],
      Waelder: ["78959"],
      Warda: ["78960"],
      Weimar: ["78962"],
      "West point": ["78963"],
      "Zcta 789hh": ["789HH"],
      Adrian: ["79001"],
      Alanreed: ["79002"],
      Allison: ["79003"],
      Booker: ["79005"],
      Phillips: ["79007"],
      Bovina: ["79009"],
      "Boys ranch": ["79010"],
      Briscoe: ["79011"],
      Bushland: ["79012"],
      Cactus: ["79013"],
      Glazier: ["79014"],
      Canyon: ["79015"],
      Channing: ["79018"],
      Claude: ["79019"],
      "Cotton center": ["79021"],
      Dalhart: ["79022"],
      Darrouzett: ["79024"],
      Dawn: ["79025"],
      Dimmitt: ["79027"],
      Dumas: ["79029"],
      Earth: ["79031"],
      Edmonson: ["79032"],
      Farnsworth: ["79033"],
      Follett: ["79034"],
      Black: ["79035"],
      Fritch: ["79036"],
      Groom: ["79039"],
      Gruver: ["79040"],
      "Hale center": ["79041"],
      Happy: ["79042"],
      Hart: ["79043"],
      Hartley: ["79044"],
      Hereford: ["79045"],
      Higgins: ["79046"],
      Kress: ["79052"],
      Lazbuddie: ["79053"],
      Lefors: ["79054"],
      Lipscomb: ["79056"],
      Kellerville: ["79057"],
      Miami: ["79059"],
      Mobeetie: ["79061"],
      Morse: ["79062"],
      Nazareth: ["79063"],
      Olton: ["79064"],
      Pampa: ["79065"],
      Panhandle: ["79068"],
      Perryton: ["79070"],
      Plainview: ["79072"],
      Sanford: ["79078"],
      Twitty: ["79079"],
      Skellytown: ["79080"],
      Spearman: ["79081"],
      Springlake: ["79082"],
      Stinnett: ["79083"],
      Stratford: ["79084"],
      Sunray: ["79086"],
      Texline: ["79087"],
      "Vigo park": ["79088"],
      Umbarger: ["79091"],
      Vega: ["79092"],
      Waka: ["79093"],
      Wellington: ["79095"],
      Wheeler: ["79096"],
      "White deer": ["79097"],
      Wildorado: ["79098"],
      "Zcta 790hh": ["790HH"],
      "Zcta 790xx": ["790XX"],
      Amarillo: [
        "79101",
        "79102",
        "79103",
        "79104",
        "79106",
        "79107",
        "79108",
        "79109",
        "79110",
        "79111",
        "79118",
        "79119",
        "79121",
        "79124",
      ],
      "Zcta 791hh": ["791HH"],
      "Zcta 791xx": ["791XX"],
      Kirkland: ["79201"],
      Afton: ["79220"],
      "Cee vee": ["79223"],
      Chillicothe: ["79225"],
      Clarendon: ["79226"],
      Crowell: ["79227"],
      Dickens: ["79229"],
      Dodson: ["79230"],
      Dougherty: ["79231"],
      Dumont: ["79232"],
      Estelline: ["79233"],
      Flomot: ["79234"],
      Floydada: ["79235"],
      Guthrie: ["79236"],
      Hedley: ["79237"],
      Lakeview: ["79239"],
      "Lelia lake": ["79240"],
      Lockney: ["79241"],
      Mcadoo: ["79243"],
      Matador: ["79244"],
      Memphis: ["79245"],
      Odell: ["79247"],
      Chalk: ["79248"],
      Petersburg: ["79250"],
      Quail: ["79251"],
      Quanah: ["79252"],
      Quitaque: ["79255"],
      "Roaring springs": ["79256"],
      Silverton: ["79257"],
      Tell: ["79259"],
      Turkey: ["79261"],
      "Zcta 792hh": ["792HH"],
      "Zcta 792xx": ["792XX"],
      Abernathy: ["79311"],
      Amherst: ["79312"],
      Anton: ["79313"],
      Bledsoe: ["79314"],
      Brownfield: ["79316"],
      Bula: ["79320"],
      Crosbyton: ["79322"],
      "Denver city": ["79323"],
      Enochs: ["79324"],
      Farwell: ["79325"],
      Fieldton: ["79326"],
      Idalou: ["79329"],
      Justiceburg: ["79330"],
      Lamesa: ["79331"],
      Levelland: ["79336"],
      Littlefield: ["79339"],
      Loop: ["79342"],
      Lorenzo: ["79343"],
      Maple: ["79344"],
      Meadow: ["79345"],
      Morton: ["79346"],
      Muleshoe: ["79347"],
      "New deal": ["79350"],
      Odonnell: ["79351"],
      Pep: ["79353"],
      Plains: ["79355"],
      Post: ["79356"],
      Cone: ["79357"],
      Ropesville: ["79358"],
      Seagraves: ["79359"],
      Seminole: ["79360"],
      Shallowater: ["79363"],
      "Ransom canyon": ["79364", "79366"],
      Smyer: ["79367"],
      Spade: ["79369"],
      Spur: ["79370"],
      Sudan: ["79371"],
      Sundown: ["79372"],
      Tahoka: ["79373"],
      Tokio: ["79376"],
      Welch: ["79377"],
      Wellman: ["79378"],
      Whiteface: ["79379"],
      Whitharral: ["79380"],
      Wilson: ["79381"],
      Wolfforth: ["79382"],
      "New home": ["79383"],
      "Zcta 793hh": ["793HH"],
      "Zcta 793xx": ["793XX"],
      Lubbock: [
        "79401",
        "79403",
        "79404",
        "79405",
        "79406",
        "79407",
        "79410",
        "79411",
        "79412",
        "79413",
        "79414",
        "79415",
        "79416",
        "79423",
        "79424",
      ],
      Anson: ["79501"],
      Aspermont: ["79502"],
      Avoca: ["79503"],
      Baird: ["79504"],
      Benjamin: ["79505"],
      Blackwell: ["79506"],
      "Buffalo gap": ["79508"],
      Clyde: ["79510"],
      Coahoma: ["79511"],
      "Colorado city": ["79512"],
      Fluvanna: ["79517"],
      Girard: ["79518"],
      Goldsboro: ["79519"],
      Hamlin: ["79520"],
      Haskell: ["79521"],
      Hawley: ["79525"],
      Hermleigh: ["79526"],
      Ira: ["79527"],
      Jayton: ["79528"],
      "Knox city": ["79529"],
      Lawn: ["79530"],
      Loraine: ["79532"],
      Lueders: ["79533"],
      "Mc caulley": ["79534"],
      Maryneal: ["79535"],
      Merkel: ["79536"],
      Nolan: ["79537"],
      Novice: ["79538"],
      "O brien": ["79539"],
      "Old glory": ["79540"],
      Ovalo: ["79541"],
      Roby: ["79543"],
      Rochester: ["79544"],
      Roscoe: ["79545"],
      Rotan: ["79546"],
      Rule: ["79547"],
      Sagerton: ["79548"],
      Dermott: ["79549"],
      Stamford: ["79553"],
      Sweetwater: ["79556"],
      Sylvester: ["79560"],
      Trent: ["79561"],
      Tuscola: ["79562"],
      Tye: ["79563"],
      Westbrook: ["79565"],
      Wingate: ["79566"],
      Winters: ["79567"],
      "Zcta 795hh": ["795HH"],
      "Zcta 795xx": ["795XX"],
      Abilene: ["79601", "79602", "79603", "79605", "79606"],
      "Dyess afb": ["79607"],
      "Zcta 796xx": ["796XX"],
      Midland: ["79701", "79703", "79705", "79706", "79707"],
      Ackerly: ["79713"],
      Andrews: ["79714"],
      Balmorhea: ["79718"],
      Barstow: ["79719"],
      Vealmoor: ["79720"],
      Coyanosa: ["79730"],
      Crane: ["79731"],
      Forsan: ["79733"],
      "Fort davis": ["79734"],
      "Fort stockton": ["79735"],
      Gail: ["79738"],
      "Garden city": ["79739"],
      Girvin: ["79740"],
      Goldsmith: ["79741"],
      Grandfalls: ["79742"],
      Imperial: ["79743"],
      Iraan: ["79744"],
      Kermit: ["79745"],
      Knott: ["79748"],
      Lenorah: ["79749"],
      "Mc camey": ["79752"],
      Mentone: ["79754"],
      Midkiff: ["79755"],
      Monahans: ["79756"],
      Gardendale: ["79758"],
      Notrees: ["79759"],
      Odessa: ["79761", "79762", "79763", "79764", "79765", "79766"],
      Verhalen: ["79772"],
      Penwell: ["79776"],
      Pyote: ["79777"],
      Rankin: ["79778"],
      Saragosa: ["79780"],
      Sheffield: ["79781"],
      Stanton: ["79782"],
      Tarzan: ["79783"],
      Toyah: ["79785"],
      Wickett: ["79788"],
      Wink: ["79789"],
      "Zcta 797hh": ["797HH"],
      "Zcta 797xx": ["797XX"],
      Anthony: ["79821"],
      Alpine: ["79830", "79831"],
      "Big bend nationa": ["79834"],
      Canutillo: ["79835"],
      Clint: ["79836"],
      "Dell city": ["79837"],
      Fabens: ["79838"],
      "Fort hancock": ["79839"],
      Marathon: ["79842"],
      Marfa: ["79843"],
      Presidio: ["79845"],
      Redford: ["79846"],
      "Salt flat": ["79847"],
      Sanderson: ["79848"],
      "San elizario": ["79849"],
      "Sierra blanca": ["79851"],
      Terlingua: ["79852"],
      Tornillo: ["79853"],
      Valentine: ["79854"],
      Kent: ["79855"],
      "Zcta 798xx": ["798XX"],
      "El paso": [
        "79901",
        "79902",
        "79903",
        "79904",
        "79905",
        "79907",
        "79912",
        "79915",
        "79922",
        "79924",
        "79925",
        "79930",
        "79932",
        "79934",
        "79935",
        "79936",
        "79938",
      ],
      "Fort bliss": ["79906", "79908"],
      "Horizon city": ["79927"],
      "Zcta 799xx": ["799XX"],
    },
  },
  {
    abbrevation: "UT",
    name: "Utah",
    Cities: [
      "Altamont",
      "Altamont",
      "Altonah",
      "American fork",
      "Alpine",
      "Bingham canyon",
      "Bluebell",
      "Bountiful",
      "Cedar valley",
      "Centerville",
      "Clearfield",
      "Coalville",
      "Croydon",
      "Draper",
      "Duchesne",
      "Dugway",
      "Dutch john",
      "Echo",
      "Farmington",
      "Fort duchesne",
      "Fruitland",
      "Garden city",
      "Grantsville",
      "Hanna",
      "Heber city",
      "Henefer",
      "Ibapah",
      "Jensen",
      "Kamas",
      "Kaysville",
      "Laketown",
      "Lapoint",
      "Layton",
      "Lindon",
      "Lehi",
      "Magna",
      "Manila",
      "Midvale",
      "Midway",
      "Morgan",
      "Mountain home",
      "Myton",
      "Neola",
      "North salt lake",
      "Oakley",
      "Hill air force b",
      "Orem",
      "Vineyard",
      "Park city",
      "Peoa",
      "Pleasant grove",
      "Randlett",
      "Randolph",
      "Lark",
      "Roosevelt",
      "Roy",
      "Rush valley",
      "Sandy",
      "Stockton",
      "Tabiona",
      "Talmage",
      "Tooele",
      "Syracuse",
      "Tridell",
      "Vernal",
      "Vernon",
      "Wallsburg",
      "Trout creek",
      "West jordan",
      "Whiterocks",
      "Woodruff",
      "Woods cross",
      "Alta",
      "South jordan",
      "Zcta 84097",
      "Zcta 84098",
      "Zcta 840hh",
      "Zcta 840xx",
      "Salt lake city",
      "Murray",
      "South salt lake",
      "Holladay",
      "Kearns",
      "West valley city",
      "Cottonwood",
      "Zcta 841hh",
      "Zcta 841xx",
      "Bear river city",
      "Brigham city",
      "Cache junction",
      "Clarkston",
      "Collinston",
      "Corinne",
      "Deweyville",
      "Eden",
      "Fielding",
      "Garland",
      "Grouse creek",
      "Honeyville",
      "Hooper",
      "Howell",
      "Huntsville",
      "Hyde park",
      "Hyrum",
      "Lewiston",
      "Logan",
      "Mantua",
      "Mendon",
      "Millville",
      "Newton",
      "Paradise",
      "Park valley",
      "Plymouth",
      "Portage",
      "Providence",
      "Richmond",
      "Riverside",
      "Smithfield",
      "Snowville",
      "Tremonton",
      "Trenton",
      "Wellsville",
      "Willard",
      "Zcta 84341",
      "Zcta 843hh",
      "Zcta 843xx",
      "Ogden",
      "Zcta 844hh",
      "Price",
      "Aneth",
      "Blanding",
      "Bluff",
      "Castle dale",
      "Clawson",
      "Cleveland",
      "East carbon",
      "Elmo",
      "Emery",
      "Ferron",
      "Green river",
      "Helper",
      "Huntington",
      "Kenilworth",
      "La sal",
      "Mexican hat",
      "Moab",
      "Bullfrog",
      "Montezuma creek",
      "Monticello",
      "Monument valley",
      "Orangeville",
      "Sunnyside",
      "Thompson",
      "Wellington",
      "Zcta 845hh",
      "Provo",
      "Aurora",
      "Axtell",
      "Centerfield",
      "Chester",
      "Delta",
      "Elberta",
      "Ephraim",
      "Eureka",
      "Fairview",
      "Fayette",
      "Fillmore",
      "Fountain green",
      "Goshen",
      "Gunnison",
      "Hinckley",
      "Holden",
      "Kanosh",
      "Leamington",
      "Levan",
      "Lynndyl",
      "Manti",
      "Mayfield",
      "Mona",
      "Moroni",
      "Mount pleasant",
      "Nephi",
      "Oak city",
      "Oasis",
      "Payson",
      "Redmond",
      "Woodland hills",
      "Salina",
      "Genola",
      "Scipio",
      "Sigurd",
      "Spanish fork",
      "Spring city",
      "Springville",
      "Mapleton",
      "Wales",
      "Zcta 846hh",
      "Zcta 846xx",
      "Venice",
      "Alton",
      "Annabella",
      "Antimony",
      "Beaver",
      "Beryl",
      "Bicknell",
      "Boulder",
      "Bryce canyon",
      "Cannonville",
      "Brian head",
      "Pintura",
      "Central",
      "Circleville",
      "Elsinore",
      "Enterprise",
      "Escalante",
      "Garrison",
      "Glendale",
      "Glenwood",
      "Greenville",
      "Greenwich",
      "Gunlock",
      "Hanksville",
      "Hatch",
      "Henrieville",
      "Hurricane",
      "Ivins",
      "Joseph",
      "Junction",
      "Big water",
      "Kingston",
      "Koosharem",
      "La verkin",
      "Fremont",
      "Lyman",
      "Marysvale",
      "Milford",
      "Minersville",
      "Modena",
      "Austin",
      "Mount carmel",
      "New harmony",
      "Orderville",
      "Panguitch",
      "Paragonah",
      "Parowan",
      "Duck creek villa",
      "Bryce",
      "Santa clara",
      "Sevier",
      "St george",
      "Summit",
      "Teasdale",
      "Toquerville",
      "Torrey",
      "Tropic",
      "Virgin",
      "Washington",
      "Pine valley",
      "Veyo",
      "Dammeron valley",
      "Zcta 84790",
      "Zcta 847hh",
      "Zcta 847xx",
      "",
    ],
    ZipCodes: {
      Altamont: ["84001"],
      Altonah: ["84002"],
      "American fork": ["84003"],
      Alpine: ["84004"],
      "Bingham canyon": ["84006"],
      Bluebell: ["84007"],
      Bountiful: ["84010"],
      "Cedar valley": ["84013"],
      Centerville: ["84014"],
      Clearfield: ["84015"],
      Coalville: ["84017"],
      Croydon: ["84018"],
      Draper: ["84020"],
      Duchesne: ["84021"],
      Dugway: ["84022"],
      "Dutch john": ["84023"],
      Echo: ["84024"],
      Farmington: ["84025"],
      "Fort duchesne": ["84026"],
      Fruitland: ["84027"],
      "Garden city": ["84028"],
      Grantsville: ["84029"],
      Hanna: ["84031"],
      "Heber city": ["84032"],
      Henefer: ["84033"],
      Ibapah: ["84034"],
      Jensen: ["84035"],
      Kamas: ["84036"],
      Kaysville: ["84037"],
      Laketown: ["84038"],
      Lapoint: ["84039"],
      Layton: ["84040", "84041"],
      Lindon: ["84042"],
      Lehi: ["84043"],
      Magna: ["84044"],
      Manila: ["84046"],
      Midvale: ["84047"],
      Midway: ["84049"],
      Morgan: ["84050"],
      "Mountain home": ["84051"],
      Myton: ["84052"],
      Neola: ["84053"],
      "North salt lake": ["84054"],
      Oakley: ["84055"],
      "Hill air force b": ["84056"],
      Orem: ["84057"],
      Vineyard: ["84058"],
      "Park city": ["84060"],
      Peoa: ["84061"],
      "Pleasant grove": ["84062"],
      Randlett: ["84063"],
      Randolph: ["84064"],
      Lark: ["84065"],
      Roosevelt: ["84066"],
      Roy: ["84067"],
      "Rush valley": ["84069"],
      Sandy: ["84070", "84093", "84094"],
      Stockton: ["84071"],
      Tabiona: ["84072"],
      Talmage: ["84073"],
      Tooele: ["84074"],
      Syracuse: ["84075"],
      Tridell: ["84076"],
      Vernal: ["84078"],
      Vernon: ["84080"],
      Wallsburg: ["84082"],
      "Trout creek": ["84083"],
      "West jordan": ["84084", "84088"],
      Whiterocks: ["84085"],
      Woodruff: ["84086"],
      "Woods cross": ["84087"],
      Alta: ["84092"],
      "South jordan": ["84095"],
      "Zcta 84097": ["84097"],
      "Zcta 84098": ["84098"],
      "Zcta 840hh": ["840HH"],
      "Zcta 840xx": ["840XX"],
      "Salt lake city": [
        "84101",
        "84102",
        "84103",
        "84104",
        "84105",
        "84106",
        "84108",
        "84109",
        "84111",
        "84112",
        "84113",
        "84116",
        "84128",
        "84180",
      ],
      Murray: ["84107", "84123"],
      "South salt lake": ["84115"],
      Holladay: ["84117", "84124"],
      Kearns: ["84118"],
      "West valley city": ["84119", "84120"],
      Cottonwood: ["84121"],
      "Zcta 841hh": ["841HH"],
      "Zcta 841xx": ["841XX"],
      "Bear river city": ["84301"],
      "Brigham city": ["84302"],
      "Cache junction": ["84304"],
      Clarkston: ["84305"],
      Collinston: ["84306"],
      Corinne: ["84307"],
      Deweyville: ["84309"],
      Eden: ["84310"],
      Fielding: ["84311"],
      Garland: ["84312"],
      "Grouse creek": ["84313"],
      Honeyville: ["84314"],
      Hooper: ["84315"],
      Howell: ["84316"],
      Huntsville: ["84317"],
      "Hyde park": ["84318"],
      Hyrum: ["84319"],
      Lewiston: ["84320"],
      Logan: ["84321"],
      Mantua: ["84324"],
      Mendon: ["84325"],
      Millville: ["84326"],
      Newton: ["84327"],
      Paradise: ["84328"],
      "Park valley": ["84329"],
      Plymouth: ["84330"],
      Portage: ["84331"],
      Providence: ["84332"],
      Richmond: ["84333"],
      Riverside: ["84334"],
      Smithfield: ["84335"],
      Snowville: ["84336"],
      Tremonton: ["84337"],
      Trenton: ["84338"],
      Wellsville: ["84339"],
      Willard: ["84340"],
      "Zcta 84341": ["84341"],
      "Zcta 843hh": ["843HH"],
      "Zcta 843xx": ["843XX"],
      Ogden: ["84401", "84403", "84404", "84405", "84414"],
      "Zcta 844hh": ["844HH"],
      Price: ["84501"],
      Aneth: ["84510"],
      Blanding: ["84511"],
      Bluff: ["84512"],
      "Castle dale": ["84513"],
      Clawson: ["84516"],
      Cleveland: ["84518"],
      "East carbon": ["84520"],
      Elmo: ["84521"],
      Emery: ["84522"],
      Ferron: ["84523"],
      "Green river": ["84525"],
      Helper: ["84526"],
      Huntington: ["84528"],
      Kenilworth: ["84529"],
      "La sal": ["84530"],
      "Mexican hat": ["84531"],
      Moab: ["84532"],
      Bullfrog: ["84533"],
      "Montezuma creek": ["84534"],
      Monticello: ["84535"],
      "Monument valley": ["84536"],
      Orangeville: ["84537"],
      Sunnyside: ["84539"],
      Thompson: ["84540"],
      Wellington: ["84542"],
      "Zcta 845hh": ["845HH"],
      Provo: ["84601", "84604", "84606"],
      Aurora: ["84620"],
      Axtell: ["84621"],
      Centerfield: ["84622"],
      Chester: ["84623"],
      Delta: ["84624"],
      Elberta: ["84626"],
      Ephraim: ["84627"],
      Eureka: ["84628"],
      Fairview: ["84629"],
      Fayette: ["84630"],
      Fillmore: ["84631"],
      "Fountain green": ["84632"],
      Goshen: ["84633"],
      Gunnison: ["84634"],
      Hinckley: ["84635"],
      Holden: ["84636"],
      Kanosh: ["84637"],
      Leamington: ["84638"],
      Levan: ["84639"],
      Lynndyl: ["84640"],
      Manti: ["84642"],
      Mayfield: ["84643"],
      Mona: ["84645"],
      Moroni: ["84646"],
      "Mount pleasant": ["84647"],
      Nephi: ["84648"],
      "Oak city": ["84649"],
      Oasis: ["84650"],
      Payson: ["84651"],
      Redmond: ["84652"],
      "Woodland hills": ["84653"],
      Salina: ["84654"],
      Genola: ["84655"],
      Scipio: ["84656"],
      Sigurd: ["84657"],
      "Spanish fork": ["84660"],
      "Spring city": ["84662"],
      Springville: ["84663"],
      Mapleton: ["84664"],
      Wales: ["84667"],
      "Zcta 846hh": ["846HH"],
      "Zcta 846xx": ["846XX"],
      Venice: ["84701"],
      Alton: ["84710"],
      Annabella: ["84711"],
      Antimony: ["84712"],
      Beaver: ["84713"],
      Beryl: ["84714"],
      Bicknell: ["84715"],
      Boulder: ["84716"],
      "Bryce canyon": ["84717"],
      Cannonville: ["84718"],
      "Brian head": ["84719"],
      Pintura: ["84720"],
      Central: ["84722"],
      Circleville: ["84723"],
      Elsinore: ["84724"],
      Enterprise: ["84725"],
      Escalante: ["84726"],
      Garrison: ["84728"],
      Glendale: ["84729"],
      Glenwood: ["84730"],
      Greenville: ["84731"],
      Greenwich: ["84732"],
      Gunlock: ["84733"],
      Hanksville: ["84734"],
      Hatch: ["84735"],
      Henrieville: ["84736"],
      Hurricane: ["84737"],
      Ivins: ["84738"],
      Joseph: ["84739"],
      Junction: ["84740"],
      "Big water": ["84741"],
      Kingston: ["84743"],
      Koosharem: ["84744"],
      "La verkin": ["84745"],
      Fremont: ["84747"],
      Lyman: ["84749"],
      Marysvale: ["84750"],
      Milford: ["84751"],
      Minersville: ["84752"],
      Modena: ["84753"],
      Austin: ["84754"],
      "Mount carmel": ["84755"],
      "New harmony": ["84757"],
      Orderville: ["84758"],
      Panguitch: ["84759"],
      Paragonah: ["84760"],
      Parowan: ["84761"],
      "Duck creek villa": ["84762"],
      Bryce: ["84764"],
      "Santa clara": ["84765"],
      Sevier: ["84766"],
      "St george": ["84770"],
      Summit: ["84772"],
      Teasdale: ["84773"],
      Toquerville: ["84774"],
      Torrey: ["84775"],
      Tropic: ["84776"],
      Virgin: ["84779"],
      Washington: ["84780"],
      "Pine valley": ["84781"],
      Veyo: ["84782"],
      "Dammeron valley": ["84783"],
      "Zcta 84790": ["84790"],
      "Zcta 847hh": ["847HH"],
      "Zcta 847xx": ["847XX"],
      "": ["86044", "860HH", "86514"],
    },
  },
  {
    abbrevation: "VT",
    name: "Vermont",
    Cities: [
      "White river junc",
      "White river junc",
      "Barnard",
      "Bethel",
      "Bradford",
      "Bridgewater",
      "Bridgewater corn",
      "Brookfield",
      "Brownsville",
      "Chelsea",
      "Corinth",
      "East corinth",
      "East randolph",
      "Ryegate",
      "East thetford",
      "Fairlee",
      "Groton",
      "Hartland",
      "Newbury",
      "North hartland",
      "North pomfret",
      "North thetford",
      "Norwich",
      "Plymouth",
      "Post mills",
      "Quechee",
      "Randolph",
      "Randolph center",
      "Reading",
      "Sharon",
      "South pomfret",
      "South royalton",
      "South ryegate",
      "South strafford",
      "South woodstock",
      "Strafford",
      "Taftsville",
      "Thetford",
      "Thetford center",
      "Tunbridge",
      "Vershire",
      "Wells river",
      "West fairlee",
      "West hartford",
      "West newbury",
      "West topsham",
      "Wilder",
      "Windsor",
      "Woodstock",
      "Zcta 050hh",
      "Bellows falls",
      "Cavendish",
      "Chester",
      "Grafton",
      "Bromley mtn",
      "Ludlow",
      "North springfiel",
      "Perkinsville",
      "Peru",
      "Proctorsville",
      "Saxtons river",
      "South londonderr",
      "Springfield",
      "Westminster",
      "Weston",
      "Bennington",
      "Arlington",
      "Dorset",
      "East arlington",
      "East dorset",
      "Manchester",
      "Manchester cente",
      "North bennington",
      "North pownal",
      "Pownal",
      "Shaftsbury",
      "Brattleboro",
      "Bondville",
      "East dover",
      "Jacksonville",
      "Jamaica",
      "Marlboro",
      "Newfane",
      "Putney",
      "Readsboro",
      "South newfane",
      "Stamford",
      "Townshend",
      "Vernon",
      "Wardsboro",
      "Mount snow",
      "West halifax",
      "West townshend",
      "West wardsboro",
      "Whitingham",
      "Williamsville",
      "Wilmington",
      "Zcta 053hh",
      "Zcta 053xx",
      "Burlington",
      "South burlington",
      "Winooski",
      "Alburg",
      "Bakersfield",
      "Belvidere center",
      "Bristol",
      "Cambridge",
      "Charlotte",
      "Colchester",
      "East fairfield",
      "Enosburg falls",
      "Essex junction",
      "Fairfax",
      "Fairfield",
      "Ferrisburg",
      "Franklin",
      "Grand isle",
      "Highgate center",
      "Hinesburg",
      "Huntington",
      "Isle la motte",
      "Smugglers notch",
      "Jericho center",
      "Milton",
      "Montgomery cente",
      "New haven",
      "North ferrisburg",
      "North hero",
      "Richford",
      "Bolton valley",
      "Saint albans",
      "Shelburne",
      "Sheldon",
      "South hero",
      "Starksboro",
      "Swanton",
      "Underhill",
      "Vergennes",
      "Waterville",
      "Westford",
      "Williston",
      "Zcta 054hh",
      "Montpelier",
      "Adamant",
      "Barre",
      "Cabot",
      "Calais",
      "East barre",
      "East calais",
      "East montpelier",
      "Eden",
      "Eden mills",
      "Graniteville",
      "Hyde park",
      "Johnson",
      "Lake elmore",
      "Marshfield",
      "Moretown",
      "Morrisville",
      "Riverton",
      "Northfield falls",
      "North montpelier",
      "Plainfield",
      "Roxbury",
      "Stowe",
      "Waitsfield",
      "Sugarbush valley",
      "Washgtin",
      "Waterbury",
      "Waterbury center",
      "Websterville",
      "Williamstown",
      "Wolcott",
      "Woodbury",
      "Worcester",
      "Zcta 056hh",
      "Rutland",
      "Belmont",
      "Hubbardton",
      "Brandon",
      "Bridport",
      "Castleton",
      "Center rutland",
      "Chittenden",
      "Cuttingsville",
      "Danby",
      "East wallingford",
      "Fair haven",
      "Florence",
      "Gaysville",
      "Granville",
      "Hancock",
      "Hydeville",
      "Killington",
      "Bread loaf",
      "Middletown sprin",
      "Mount holly",
      "North clarendon",
      "Orwell",
      "Pawlet",
      "Pittsfield",
      "Pittsford",
      "Poultney",
      "Proctor",
      "Ripton",
      "Rochester",
      "Salisbury",
      "Shoreham",
      "Stockbridge",
      "Wallingford",
      "Wells",
      "West pawlet",
      "West rupert",
      "West rutland",
      "Leicester juncti",
      "Zcta 057hh",
      "Saint johnsbury",
      "Albany",
      "Barnet",
      "Barton",
      "Concord",
      "Coventry",
      "Craftsbury",
      "Craftsbury commo",
      "Danville",
      "Derby",
      "Derby line",
      "East burke",
      "East charleston",
      "East hardwick",
      "East haven",
      "Glover",
      "Granby",
      "Greensboro",
      "Greensboro bend",
      "Hardwick",
      "Irasburg",
      "Island pond",
      "Lowell",
      "Lyndonville",
      "Morgan ctr",
      "Newport",
      "Newport center",
      "North concord",
      "Jay peak",
      "Orleans",
      "Sheffield",
      "Sutton",
      "Troy",
      "West burke",
      "West charleston",
      "West danville",
      "Westfield",
      "West glover",
      "Zcta 058hh",
      "Beecher falls",
      "Canaan",
      "Gilman",
      "Guildhall",
      "Lunenburg",
      "Norton",
    ],
    ZipCodes: {
      "White river junc": ["05001"],
      Barnard: ["05031"],
      Bethel: ["05032"],
      Bradford: ["05033"],
      Bridgewater: ["05034"],
      "Bridgewater corn": ["05035"],
      Brookfield: ["05036"],
      Brownsville: ["05037"],
      Chelsea: ["05038"],
      Corinth: ["05039"],
      "East corinth": ["05040", "05076"],
      "East randolph": ["05041"],
      Ryegate: ["05042"],
      "East thetford": ["05043"],
      Fairlee: ["05045"],
      Groton: ["05046"],
      Hartland: ["05048"],
      Newbury: ["05051"],
      "North hartland": ["05052"],
      "North pomfret": ["05053"],
      "North thetford": ["05054"],
      Norwich: ["05055"],
      Plymouth: ["05056"],
      "Post mills": ["05058"],
      Quechee: ["05059"],
      Randolph: ["05060"],
      "Randolph center": ["05061"],
      Reading: ["05062"],
      Sharon: ["05065"],
      "South pomfret": ["05067"],
      "South royalton": ["05068"],
      "South ryegate": ["05069"],
      "South strafford": ["05070"],
      "South woodstock": ["05071"],
      Strafford: ["05072"],
      Taftsville: ["05073"],
      Thetford: ["05074"],
      "Thetford center": ["05075"],
      Tunbridge: ["05077"],
      Vershire: ["05079"],
      "Wells river": ["05081"],
      "West fairlee": ["05083"],
      "West hartford": ["05084"],
      "West newbury": ["05085"],
      "West topsham": ["05086"],
      Wilder: ["05088"],
      Windsor: ["05089"],
      Woodstock: ["05091"],
      "Zcta 050hh": ["050HH"],
      "Bellows falls": ["05101"],
      Cavendish: ["05142"],
      Chester: ["05143"],
      Grafton: ["05146"],
      "Bromley mtn": ["05148"],
      Ludlow: ["05149"],
      "North springfiel": ["05150"],
      Perkinsville: ["05151"],
      Peru: ["05152"],
      Proctorsville: ["05153"],
      "Saxtons river": ["05154"],
      "South londonderr": ["05155"],
      Springfield: ["05156"],
      Westminster: ["05158"],
      Weston: ["05161"],
      Bennington: ["05201"],
      Arlington: ["05250"],
      Dorset: ["05251"],
      "East arlington": ["05252"],
      "East dorset": ["05253"],
      Manchester: ["05254"],
      "Manchester cente": ["05255"],
      "North bennington": ["05257"],
      "North pownal": ["05260"],
      Pownal: ["05261"],
      Shaftsbury: ["05262"],
      Brattleboro: ["05301"],
      Bondville: ["05340"],
      "East dover": ["05341"],
      Jacksonville: ["05342"],
      Jamaica: ["05343"],
      Marlboro: ["05344"],
      Newfane: ["05345"],
      Putney: ["05346"],
      Readsboro: ["05350"],
      "South newfane": ["05351"],
      Stamford: ["05352"],
      Townshend: ["05353"],
      Vernon: ["05354"],
      Wardsboro: ["05355"],
      "Mount snow": ["05356"],
      "West halifax": ["05358"],
      "West townshend": ["05359"],
      "West wardsboro": ["05360"],
      Whitingham: ["05361"],
      Williamsville: ["05362"],
      Wilmington: ["05363"],
      "Zcta 053hh": ["053HH"],
      "Zcta 053xx": ["053XX"],
      Burlington: ["05401"],
      "South burlington": ["05403"],
      Winooski: ["05404"],
      Alburg: ["05440"],
      Bakersfield: ["05441"],
      "Belvidere center": ["05442"],
      Bristol: ["05443"],
      Cambridge: ["05444"],
      Charlotte: ["05445"],
      Colchester: ["05446"],
      "East fairfield": ["05448"],
      "Enosburg falls": ["05450"],
      "Essex junction": ["05452"],
      Fairfax: ["05454"],
      Fairfield: ["05455"],
      Ferrisburg: ["05456"],
      Franklin: ["05457"],
      "Grand isle": ["05458"],
      "Highgate center": ["05459"],
      Hinesburg: ["05461"],
      Huntington: ["05462"],
      "Isle la motte": ["05463"],
      "Smugglers notch": ["05464"],
      "Jericho center": ["05465"],
      Milton: ["05468"],
      "Montgomery cente": ["05471"],
      "New haven": ["05472"],
      "North ferrisburg": ["05473"],
      "North hero": ["05474"],
      Richford: ["05476"],
      "Bolton valley": ["05477"],
      "Saint albans": ["05478"],
      Shelburne: ["05482"],
      Sheldon: ["05483"],
      "South hero": ["05486"],
      Starksboro: ["05487"],
      Swanton: ["05488"],
      Underhill: ["05489"],
      Vergennes: ["05491"],
      Waterville: ["05492"],
      Westford: ["05494"],
      Williston: ["05495"],
      "Zcta 054hh": ["054HH"],
      Montpelier: ["05602"],
      Adamant: ["05640"],
      Barre: ["05641"],
      Cabot: ["05647"],
      Calais: ["05648"],
      "East barre": ["05649"],
      "East calais": ["05650"],
      "East montpelier": ["05651"],
      Eden: ["05652"],
      "Eden mills": ["05653"],
      Graniteville: ["05654"],
      "Hyde park": ["05655"],
      Johnson: ["05656"],
      "Lake elmore": ["05657"],
      Marshfield: ["05658"],
      Moretown: ["05660"],
      Morrisville: ["05661"],
      Riverton: ["05663"],
      "Northfield falls": ["05664"],
      "North montpelier": ["05666"],
      Plainfield: ["05667"],
      Roxbury: ["05669"],
      Stowe: ["05672"],
      Waitsfield: ["05673"],
      "Sugarbush valley": ["05674"],
      Washgtin: ["05675"],
      Waterbury: ["05676"],
      "Waterbury center": ["05677"],
      Websterville: ["05678"],
      Williamstown: ["05679"],
      Wolcott: ["05680"],
      Woodbury: ["05681"],
      Worcester: ["05682"],
      "Zcta 056hh": ["056HH"],
      Rutland: ["05701"],
      Belmont: ["05730"],
      Hubbardton: ["05732"],
      Brandon: ["05733"],
      Bridport: ["05734"],
      Castleton: ["05735"],
      "Center rutland": ["05736"],
      Chittenden: ["05737"],
      Cuttingsville: ["05738"],
      Danby: ["05739"],
      "East wallingford": ["05742"],
      "Fair haven": ["05743"],
      Florence: ["05744"],
      Gaysville: ["05746"],
      Granville: ["05747"],
      Hancock: ["05748"],
      Hydeville: ["05750"],
      Killington: ["05751"],
      "Bread loaf": ["05753"],
      "Middletown sprin": ["05757"],
      "Mount holly": ["05758"],
      "North clarendon": ["05759"],
      Orwell: ["05760"],
      Pawlet: ["05761"],
      Pittsfield: ["05762"],
      Pittsford: ["05763"],
      Poultney: ["05764"],
      Proctor: ["05765"],
      Ripton: ["05766"],
      Rochester: ["05767"],
      Salisbury: ["05769"],
      Shoreham: ["05770"],
      Stockbridge: ["05772"],
      Wallingford: ["05773"],
      Wells: ["05774"],
      "West pawlet": ["05775"],
      "West rupert": ["05776"],
      "West rutland": ["05777"],
      "Leicester juncti": ["05778"],
      "Zcta 057hh": ["057HH"],
      "Saint johnsbury": ["05819"],
      Albany: ["05820"],
      Barnet: ["05821"],
      Barton: ["05822"],
      Concord: ["05824"],
      Coventry: ["05825"],
      Craftsbury: ["05826"],
      "Craftsbury commo": ["05827"],
      Danville: ["05828"],
      Derby: ["05829"],
      "Derby line": ["05830"],
      "East burke": ["05832"],
      "East charleston": ["05833"],
      "East hardwick": ["05836"],
      "East haven": ["05837"],
      Glover: ["05839"],
      Granby: ["05840"],
      Greensboro: ["05841"],
      "Greensboro bend": ["05842"],
      Hardwick: ["05843"],
      Irasburg: ["05845"],
      "Island pond": ["05846"],
      Lowell: ["05847"],
      Lyndonville: ["05851"],
      "Morgan ctr": ["05853"],
      Newport: ["05855"],
      "Newport center": ["05857"],
      "North concord": ["05858"],
      "Jay peak": ["05859"],
      Orleans: ["05860"],
      Sheffield: ["05866"],
      Sutton: ["05867"],
      Troy: ["05868"],
      "West burke": ["05871"],
      "West charleston": ["05872"],
      "West danville": ["05873"],
      Westfield: ["05874"],
      "West glover": ["05875"],
      "Zcta 058hh": ["058HH"],
      "Beecher falls": ["05902"],
      Canaan: ["05903"],
      Gilman: ["05904"],
      Guildhall: ["05905"],
      Lunenburg: ["05906"],
      Norton: ["05907"],
    },
  },
  {
    abbrevation: "VA",
    name: "Virginia",
    Cities: [
      "Zcta 20105",
      "Zcta 20105",
      "Zcta 20106",
      "Zcta 20109",
      "Zcta 20110",
      "Zcta 20111",
      "Zcta 20112",
      "Zcta 20115",
      "Zcta 20117",
      "Zcta 20118",
      "Zcta 20119",
      "Zcta 20120",
      "Zcta 20121",
      "Zcta 20124",
      "Zcta 20129",
      "Zcta 20130",
      "Zcta 20132",
      "Zcta 20135",
      "Zcta 20136",
      "Zcta 20137",
      "Zcta 20138",
      "Zcta 20139",
      "Zcta 20140",
      "Zcta 20141",
      "Zcta 20143",
      "Zcta 20144",
      "Zcta 20147",
      "Zcta 20148",
      "Zcta 20151",
      "Zcta 20152",
      "Zcta 20155",
      "Zcta 20158",
      "Sterling",
      "Dulles",
      "Zcta 20169",
      "Zcta 20170",
      "Zcta 20171",
      "Zcta 20175",
      "Zcta 20176",
      "Zcta 20180",
      "Zcta 20181",
      "Zcta 20184",
      "Zcta 20186",
      "Zcta 20187",
      "Zcta 20190",
      "Zcta 20191",
      "Zcta 20194",
      "Zcta 20197",
      "Zcta 20198",
      "Zcta 201hh",
      "Annandale",
      "Burke",
      "Dumfries",
      "Dunn loring",
      "Fairfax",
      "Fairfax station",
      "Baileys crossroa",
      "Mosby",
      "Pimmit",
      "Seven corners",
      "Falls church",
      "Fort belvoir",
      "Great falls",
      "Mason neck",
      "Zcta 220hh",
      "Mc lean",
      "West mclean",
      "Oakton",
      "Occoquan",
      "Quantico",
      "Springfield",
      "North springfiel",
      "West springfield",
      "Triangle",
      "Vienna",
      "Woodbridge",
      "Lakeridge",
      "Dale city",
      "Zcta 221hh",
      "Arlington",
      "Alexandria",
      "Jefferson manor",
      "Community",
      "Belle view",
      "Wellington",
      "Engleside",
      "Franconia",
      "Zcta 223hh",
      "Fredericksburg",
      "Falmouth",
      "Bowling green",
      "Burgess",
      "Burr hill",
      "Callao",
      "Caret",
      "Center cross",
      "Champlain",
      "Oak grove",
      "Dahlgren",
      "Howertons",
      "Farnham",
      "Hague",
      "Heathsville",
      "Hustle",
      "Irvington",
      "Kilmarnock",
      "King george",
      "Kinsale",
      "Zcta 224hh",
      "Zcta 224xx",
      "Lancaster",
      "Laneview",
      "Lively",
      "Locust grove",
      "Loretto",
      "Lottsburg",
      "Milford",
      "Montross",
      "Oldhams",
      "Partlow",
      "Port royal",
      "Rappahannock aca",
      "Reedville",
      "Rhoadesville",
      "Ruther glen",
      "Sharps",
      "Snell",
      "Stafford",
      "Tappahannock",
      "Unionville",
      "Nomini grove",
      "Weems",
      "Windmill point",
      "Wicomico church",
      "Woodford",
      "Zcta 225hh",
      "Zcta 225xx",
      "Winchester",
      "Browntown",
      "Berryville",
      "Boyce",
      "Clear brook",
      "Whitacre",
      "Fishers hill",
      "Flint hill",
      "Front royal",
      "Gore",
      "Hume",
      "Huntly",
      "Lebanon church",
      "Linden",
      "Markham",
      "Maurertown",
      "Middletown",
      "Millwood",
      "Rileyville",
      "Saint davids chu",
      "Star tannery",
      "Stephens city",
      "Stephenson",
      "Strasburg",
      "Toms brook",
      "White post",
      "Woodstock",
      "Zcta 226hh",
      "Raccoon ford",
      "Aroda",
      "Banco",
      "Morrisville",
      "Boston",
      "Brandy station",
      "Brightwood",
      "Castleton",
      "Elkwood",
      "Etlan",
      "Goldvein",
      "Haywood",
      "Hood",
      "Jeffersonton",
      "Lignum",
      "Aylor",
      "Midland",
      "Mitchells",
      "Oakpark",
      "Pratts",
      "Radiant",
      "Rapidan",
      "Remington",
      "Reva",
      "Richardsville",
      "Rixeyville",
      "Uno",
      "Sperryville",
      "Stevensburg",
      "Sumerduck",
      "Syria",
      "Viewtown",
      "Washington",
      "Woodville",
      "Zcta 227xx",
      "Harrisonburg",
      "Zcta 22802",
      "Basye",
      "Bergton",
      "Bridgewater",
      "Broadway",
      "Criders",
      "Montezuma",
      "Edinburg",
      "Elkton",
      "Fulks run",
      "Hinton",
      "Keezletown",
      "Linville",
      "Luray",
      "Mc gaheysville",
      "Mount crawford",
      "Conicville",
      "Mount solon",
      "New market",
      "Orkney springs",
      "Montevideo",
      "Shenandoah caver",
      "Shenandoah",
      "Singers glen",
      "Stanley",
      "Timberville",
      "Zcta 228hh",
      "Zcta 228xx",
      "Charlottesville",
      "Monticello",
      "University",
      "Zcta 22911",
      "Afton",
      "Tye river",
      "Burnleys",
      "Batesville",
      "Covesville",
      "Yancey mills",
      "Boonesville",
      "Earlysville",
      "Esmont",
      "Faber",
      "Woodrow wilson",
      "Mission home",
      "Cashs corner",
      "Greenwood",
      "Keene",
      "Boyd tavern",
      "Locust dale",
      "Lovingston",
      "Sherando",
      "Massies mill",
      "Montpelier stati",
      "Wintergreen",
      "Alberene",
      "Montford",
      "Bybee",
      "Piney river",
      "Roseland",
      "Advance mills",
      "Schuyler",
      "Rockfish",
      "Somerset",
      "Geer",
      "Troy",
      "Tyro",
      "Waynesboro",
      "Woodberry forest",
      "Zcta 229hh",
      "Zcta 229xx",
      "Achilles",
      "Amelia court hou",
      "Arvonia",
      "Ashland",
      "Aylett",
      "Barhamsville",
      "Beaverdam",
      "Bohannon",
      "Bremo bluff",
      "Bruington",
      "Bumpass",
      "Miles",
      "Tamworth",
      "Charles city",
      "Church view",
      "Blakes",
      "Columbia",
      "Crozier",
      "Cumberland",
      "Deltaville",
      "Diggs",
      "Doswell",
      "Dutton",
      "Fork union",
      "Foster",
      "Glen allen",
      "Pinero",
      "Gloucester point",
      "Goochland",
      "Grimstead",
      "Gum spring",
      "Gwynn",
      "Hallieford",
      "Hanover",
      "Hardyville",
      "Hartfield",
      "Hayes",
      "Highland springs",
      "Redart",
      "Jamaica",
      "Jetersville",
      "Kents store",
      "King and queen c",
      "King william",
      "Lanexa",
      "Little plymouth",
      "Locust hill",
      "Louisa",
      "Zcta 230hh",
      "Dabneys",
      "Manakin sabot",
      "Manquin",
      "Mascot",
      "Mathews",
      "Shanghai",
      "Mechanicsville",
      "Midlothian",
      "Millers tavern",
      "Zcta 23116",
      "Mineral",
      "Moon",
      "Moseley",
      "New canton",
      "New kent",
      "New point",
      "Newtown",
      "North",
      "Oilville",
      "Onemo",
      "Bavon",
      "Powhatan",
      "Providence forge",
      "Quinton",
      "Rockville",
      "Indian neck",
      "Saluda",
      "Sandston",
      "Sandy hook",
      "Plain view",
      "Stevensville",
      "Shadow",
      "Toano",
      "Syringa",
      "Remlik",
      "Wake",
      "Walkerton",
      "Ware neck",
      "Water view",
      "West point",
      "Wicomico",
      "Merrimac",
      "College of willi",
      "Williamsburg",
      "Montpelier",
      "Zcta 231hh",
      "Richmond",
      "Bellevue",
      "Lakeside",
      "Regency",
      "West end",
      "Ridge",
      "Ampthill",
      "Bon air",
      "Zcta 232hh",
      "Accomac",
      "Assawoman",
      "Atlantic",
      "Battery park",
      "Belle haven",
      "Birdsnest",
      "Bloxom",
      "Cape charles",
      "Carrollton",
      "Carrsville",
      "Cheriton",
      "Chesapeake",
      "Bowers hill",
      "Fentress",
      "Chincoteague",
      "Wallops island",
      "Eastville",
      "Exmore",
      "Franktown",
      "Greenbackville",
      "Greenbush",
      "Hacksneck",
      "Hallwood",
      "Harborton",
      "Horntown",
      "Zcta 233hh",
      "Keller",
      "Locustville",
      "Machipongo",
      "Mappsville",
      "Mears",
      "Melfa",
      "Nassawadox",
      "New church",
      "Oak hall",
      "Onancock",
      "Onley",
      "Painter",
      "Parksley",
      "Quinby",
      "Sanford",
      "Saxis",
      "Smithfield",
      "Suffolk",
      "Tangier",
      "Tasley",
      "Temperanceville",
      "Virginia beach",
      "Blackwater bridg",
      "Wachapreague",
      "Windsor",
      "Withams",
      "Zcta 234hh",
      "Norfolk",
      "Naval amphibious",
      "Zcta 235hh",
      "Newport news",
      "Hampton",
      "Poquoson",
      "Yorktown",
      "Naval weapons st",
      "Grafton",
      "Tabb",
      "Seaford",
      "Zcta 236hh",
      "Portsmouth",
      "Zcta 237hh",
      "Fort lee",
      "Ettrick",
      "Petersburg",
      "Alberta",
      "Blackstone",
      "Boykins",
      "Branchville",
      "Capron",
      "Carson",
      "Chester",
      "Chesterfield",
      "Church road",
      "Colonial heights",
      "Zcta 23836",
      "Courtland",
      "Dendron",
      "Dewitt",
      "Dinwiddie",
      "Disputanta",
      "Dolphin",
      "Drewryville",
      "Ebony",
      "Elberon",
      "Emporia",
      "Ammon",
      "Franklin",
      "Freeman",
      "Gasburg",
      "Hopewell",
      "Ivor",
      "Jarratt",
      "Triplet",
      "Mc kenney",
      "Newsoms",
      "Prince george",
      "Rawlings",
      "Sedley",
      "Skippers",
      "Spring grove",
      "Stony creek",
      "Surry",
      "Sutherland",
      "Valentines",
      "Wakefield",
      "Warfield",
      "Waverly",
      "White plains",
      "Wilsons",
      "Yale",
      "Zuni",
      "Claremont",
      "Zcta 238hh",
      "Zcta 238xx",
      "Farmville",
      "Baskerville",
      "Boydton",
      "Bracey",
      "Brodnax",
      "Buckingham",
      "Burkeville",
      "Charlotte court",
      "Chase city",
      "Clarksville",
      "Crewe",
      "Cullen",
      "Sprouses corner",
      "Drakes branch",
      "Dundas",
      "Evergreen",
      "Green bay",
      "Hampden sydney",
      "Kenbridge",
      "Keysville",
      "Blackridge",
      "Lunenburg",
      "Meherrin",
      "Pamplin",
      "Phenix",
      "Prospect",
      "Randolph",
      "Red house",
      "Red oak",
      "Rice",
      "Saxe",
      "Skipwith",
      "South hill",
      "Victoria",
      "Wylliesburg",
      "Zcta 239hh",
      "Roanoke",
      "Cave spring",
      "Hollins",
      "Hollins college",
      "Ararat",
      "Axton",
      "Bassett",
      "Belspring",
      "Bent mountain",
      "Whitethorne",
      "Blue ridge",
      "Boones mill",
      "Lithia",
      "Callaway",
      "Cascade",
      "Catawba",
      "Simpsons",
      "Christiansburg",
      "Claudville",
      "Cloverdale",
      "Collinsville",
      "Copper hill",
      "Critz",
      "Daleville",
      "Dublin",
      "Eagle rock",
      "Eggleston",
      "Ironto",
      "Ferrum",
      "Fieldale",
      "Fincastle",
      "Alum ridge",
      "Gladehill",
      "Glen lyn",
      "Goldbond",
      "Goodview",
      "Zcta 240hh",
      "Hardy",
      "Henry",
      "Huddleston",
      "Indian valley",
      "Martinsville",
      "Meadows of dan",
      "Moneta",
      "Montvale",
      "Narrows",
      "New castle",
      "Newport",
      "Paint bank",
      "Parrott",
      "Patrick springs",
      "Pearisburg",
      "Mountain lake",
      "Penhook",
      "Pilot",
      "Pittsville",
      "Fairlawn",
      "Radford",
      "Rich creek",
      "Ridgeway",
      "Riner",
      "Ripplemead",
      "Rocky mount",
      "Salem",
      "Sandy level",
      "Shawsville",
      "Spencer",
      "Staffordsville",
      "Stanleytown",
      "Stuart",
      "Thaxton",
      "Troutville",
      "Union hall",
      "Stewartsville",
      "Wirtz",
      "Woolwine",
      "Zcta 241hh",
      "Bristol",
      "Abingdon",
      "Zcta 24211",
      "Andover",
      "Exeter",
      "Bee",
      "Ben hur",
      "Big stone gap",
      "Birchleaf",
      "Blackwater",
      "Castlewood",
      "Cleveland",
      "Clinchco",
      "Clintwood",
      "Coeburn",
      "Damascus",
      "Dante",
      "Davenport",
      "Dryden",
      "Clinchport",
      "Dungannon",
      "Ewing",
      "Fort blackmore",
      "Gate city",
      "Haysi",
      "Hiltons",
      "Council",
      "Jonesville",
      "Keokee",
      "Lebanon",
      "Mc clure",
      "Mendota",
      "Nickelsville",
      "Nora",
      "Norton",
      "Pennington gap",
      "Pound",
      "Rosedale",
      "Rose hill",
      "Saint charles",
      "Saint paul",
      "Stonega",
      "Weber city",
      "Whitetop",
      "Wise",
      "Zcta 242hh",
      "Pulaski",
      "Atkins",
      "Austinville",
      "Barren springs",
      "Bastian",
      "Bland",
      "Broadford",
      "Cana",
      "Ceres",
      "Chilhowie",
      "Cripple creek",
      "Crockett",
      "Draper",
      "Dugspur",
      "Elk creek",
      "Emory",
      "Fancy gap",
      "Fries",
      "Galax",
      "Glade spring",
      "Hillsville",
      "Allisonia",
      "Independence",
      "Ivanhoe",
      "Lambsburg",
      "Laurel fork",
      "Marion",
      "Foster falls",
      "Meadowview",
      "Mouth of wilson",
      "Rocky gap",
      "Rural retreat",
      "Saltville",
      "Speedwell",
      "Sugar grove",
      "Tannersville",
      "Trout dale",
      "Willis",
      "Woodlawn",
      "Wytheville",
      "Zcta 243hh",
      "Woodrum",
      "Bacova",
      "Blue grass",
      "Brownsburg",
      "Buena vista",
      "Churchville",
      "Clifton forge",
      "Alleghany",
      "Craigsville",
      "Crimora",
      "Deerfield",
      "Doe hill",
      "Fairfield",
      "Fort defiance",
      "Goshen",
      "Greenville",
      "Grottoes",
      "Head waters",
      "Hot springs",
      "Iron gate",
      "Lexington",
      "Low moor",
      "Mc dowell",
      "Middlebrook",
      "Millboro spring",
      "Mint spring",
      "Montebello",
      "Monterey",
      "Mount sidney",
      "Port republic",
      "Raphine",
      "Rockbridge baths",
      "Selma",
      "Spottswood",
      "Stuarts draft",
      "Swoope",
      "Verona",
      "Vesuvius",
      "Bolar",
      "West augusta",
      "Weyers cave",
      "Burnsville",
      "Zcta 244hh",
      "Zcta 244xx",
      "Lynchburg",
      "Timberlake",
      "Altavista",
      "Alton",
      "Amherst",
      "Appomattox",
      "Bedford",
      "Big island",
      "Blairs",
      "Brookneal",
      "Buffalo junction",
      "Callands",
      "Chatham",
      "Clifford",
      "Clover",
      "Coleman falls",
      "Concord",
      "Crystal hill",
      "Danville",
      "Dry fork",
      "Evington",
      "Forest",
      "Gladstone",
      "Gladys",
      "Glasgow",
      "Goode",
      "Gretna",
      "Halifax",
      "Howardsville",
      "Hurt",
      "Java",
      "Keeling",
      "Long island",
      "Lynch station",
      "Madison heights",
      "Monroe",
      "Lennig",
      "Natural bridge",
      "Natural bridge s",
      "Nelson",
      "Norwood",
      "Ringgold",
      "Rustburg",
      "Scottsburg",
      "Scottsville",
      "South boston",
      "Spout spring",
      "Sutherlin",
      "Sweet briar",
      "Ingram",
      "Virgilina",
      "Wingina",
      "Zcta 245hh",
      "Amonate",
      "Bandy",
      "Conaway",
      "Bishop",
      "Bluefield",
      "Boissevain",
      "Breaks",
      "Cedar bluff",
      "Doran",
      "Falls mills",
      "Grundy",
      "Hurley",
      "Jewell valley",
      "Keen mountain",
      "Maxie",
      "Tiptop",
      "Patterson",
      "Pilgrims knob",
      "Pocahontas",
      "Pounding mill",
      "Raven",
      "Richlands",
      "Rowe",
      "Swords creek",
      "Tazewell",
      "Vansant",
      "Whitewood",
      "",
    ],
    ZipCodes: {
      "Zcta 20105": ["20105"],
      "Zcta 20106": ["20106"],
      "Zcta 20109": ["20109"],
      "Zcta 20110": ["20110"],
      "Zcta 20111": ["20111"],
      "Zcta 20112": ["20112"],
      "Zcta 20115": ["20115"],
      "Zcta 20117": ["20117"],
      "Zcta 20118": ["20118"],
      "Zcta 20119": ["20119"],
      "Zcta 20120": ["20120"],
      "Zcta 20121": ["20121"],
      "Zcta 20124": ["20124"],
      "Zcta 20129": ["20129"],
      "Zcta 20130": ["20130"],
      "Zcta 20132": ["20132"],
      "Zcta 20135": ["20135"],
      "Zcta 20136": ["20136"],
      "Zcta 20137": ["20137"],
      "Zcta 20138": ["20138"],
      "Zcta 20139": ["20139"],
      "Zcta 20140": ["20140"],
      "Zcta 20141": ["20141"],
      "Zcta 20143": ["20143"],
      "Zcta 20144": ["20144"],
      "Zcta 20147": ["20147"],
      "Zcta 20148": ["20148"],
      "Zcta 20151": ["20151"],
      "Zcta 20152": ["20152"],
      "Zcta 20155": ["20155"],
      "Zcta 20158": ["20158"],
      Sterling: ["20164", "20165"],
      Dulles: ["20166"],
      "Zcta 20169": ["20169"],
      "Zcta 20170": ["20170"],
      "Zcta 20171": ["20171"],
      "Zcta 20175": ["20175"],
      "Zcta 20176": ["20176"],
      "Zcta 20180": ["20180"],
      "Zcta 20181": ["20181"],
      "Zcta 20184": ["20184"],
      "Zcta 20186": ["20186"],
      "Zcta 20187": ["20187"],
      "Zcta 20190": ["20190"],
      "Zcta 20191": ["20191"],
      "Zcta 20194": ["20194"],
      "Zcta 20197": ["20197"],
      "Zcta 20198": ["20198"],
      "Zcta 201hh": ["201HH"],
      Annandale: ["22003"],
      Burke: ["22015"],
      Dumfries: ["22026"],
      "Dunn loring": ["22027"],
      Fairfax: ["22030", "22031", "22032", "22033"],
      "Fairfax station": ["22039"],
      "Baileys crossroa": ["22041"],
      Mosby: ["22042"],
      Pimmit: ["22043"],
      "Seven corners": ["22044"],
      "Falls church": ["22046"],
      "Fort belvoir": ["22060"],
      "Great falls": ["22066"],
      "Mason neck": ["22079"],
      "Zcta 220hh": ["220HH"],
      "Mc lean": ["22101"],
      "West mclean": ["22102"],
      Oakton: ["22124"],
      Occoquan: ["22125"],
      Quantico: ["22134"],
      Springfield: ["22150", "22153"],
      "North springfiel": ["22151"],
      "West springfield": ["22152"],
      Triangle: ["22172"],
      Vienna: ["22180", "22181", "22182"],
      Woodbridge: ["22191"],
      Lakeridge: ["22192"],
      "Dale city": ["22193"],
      "Zcta 221hh": ["221HH"],
      Arlington: [
        "22201",
        "22202",
        "22203",
        "22204",
        "22205",
        "22206",
        "22207",
        "22209",
        "22211",
        "22213",
      ],
      Alexandria: [
        "22301",
        "22302",
        "22304",
        "22305",
        "22311",
        "22312",
        "22314",
        "22315",
      ],
      "Jefferson manor": ["22303"],
      Community: ["22306"],
      "Belle view": ["22307"],
      Wellington: ["22308"],
      Engleside: ["22309"],
      Franconia: ["22310"],
      "Zcta 223hh": ["223HH"],
      Fredericksburg: ["22401", "22406", "22407", "22408"],
      Falmouth: ["22405"],
      "Bowling green": ["22427"],
      Burgess: ["22432"],
      "Burr hill": ["22433"],
      Callao: ["22435"],
      Caret: ["22436"],
      "Center cross": ["22437"],
      Champlain: ["22438"],
      "Oak grove": ["22443"],
      Dahlgren: ["22448"],
      Howertons: ["22454"],
      Farnham: ["22460"],
      Hague: ["22469"],
      Heathsville: ["22473"],
      Hustle: ["22476"],
      Irvington: ["22480"],
      Kilmarnock: ["22482"],
      "King george": ["22485"],
      Kinsale: ["22488"],
      "Zcta 224hh": ["224HH"],
      "Zcta 224xx": ["224XX"],
      Lancaster: ["22503"],
      Laneview: ["22504"],
      Lively: ["22507"],
      "Locust grove": ["22508"],
      Loretto: ["22509"],
      Lottsburg: ["22511"],
      Milford: ["22514"],
      Montross: ["22520"],
      Oldhams: ["22529"],
      Partlow: ["22534"],
      "Port royal": ["22535"],
      "Rappahannock aca": ["22538"],
      Reedville: ["22539"],
      Rhoadesville: ["22542"],
      "Ruther glen": ["22546"],
      Sharps: ["22548"],
      Snell: ["22553"],
      Stafford: ["22554"],
      Tappahannock: ["22560"],
      Unionville: ["22567"],
      "Nomini grove": ["22572"],
      Weems: ["22576"],
      "Windmill point": ["22578"],
      "Wicomico church": ["22579"],
      Woodford: ["22580"],
      "Zcta 225hh": ["225HH"],
      "Zcta 225xx": ["225XX"],
      Winchester: ["22601", "22602", "22603"],
      Browntown: ["22610"],
      Berryville: ["22611"],
      Boyce: ["22620"],
      "Clear brook": ["22624"],
      Whitacre: ["22625"],
      "Fishers hill": ["22626"],
      "Flint hill": ["22627"],
      "Front royal": ["22630"],
      Gore: ["22637"],
      Hume: ["22639"],
      Huntly: ["22640"],
      "Lebanon church": ["22641"],
      Linden: ["22642"],
      Markham: ["22643"],
      Maurertown: ["22644"],
      Middletown: ["22645"],
      Millwood: ["22646"],
      Rileyville: ["22650"],
      "Saint davids chu": ["22652"],
      "Star tannery": ["22654"],
      "Stephens city": ["22655"],
      Stephenson: ["22656"],
      Strasburg: ["22657"],
      "Toms brook": ["22660"],
      "White post": ["22663"],
      Woodstock: ["22664"],
      "Zcta 226hh": ["226HH"],
      "Raccoon ford": ["22701"],
      Aroda: ["22709"],
      Banco: ["22711"],
      Morrisville: ["22712"],
      Boston: ["22713"],
      "Brandy station": ["22714"],
      Brightwood: ["22715"],
      Castleton: ["22716"],
      Elkwood: ["22718"],
      Etlan: ["22719"],
      Goldvein: ["22720"],
      Haywood: ["22722"],
      Hood: ["22723"],
      Jeffersonton: ["22724"],
      Lignum: ["22726"],
      Aylor: ["22727"],
      Midland: ["22728"],
      Mitchells: ["22729"],
      Oakpark: ["22730"],
      Pratts: ["22731"],
      Radiant: ["22732"],
      Rapidan: ["22733"],
      Remington: ["22734"],
      Reva: ["22735"],
      Richardsville: ["22736"],
      Rixeyville: ["22737"],
      Uno: ["22738"],
      Sperryville: ["22740"],
      Stevensburg: ["22741"],
      Sumerduck: ["22742"],
      Syria: ["22743"],
      Viewtown: ["22746"],
      Washington: ["22747"],
      Woodville: ["22749"],
      "Zcta 227xx": ["227XX"],
      Harrisonburg: ["22801"],
      "Zcta 22802": ["22802"],
      Basye: ["22810"],
      Bergton: ["22811"],
      Bridgewater: ["22812"],
      Broadway: ["22815"],
      Criders: ["22820"],
      Montezuma: ["22821"],
      Edinburg: ["22824"],
      Elkton: ["22827"],
      "Fulks run": ["22830"],
      Hinton: ["22831"],
      Keezletown: ["22832"],
      Linville: ["22834"],
      Luray: ["22835"],
      "Mc gaheysville": ["22840"],
      "Mount crawford": ["22841"],
      Conicville: ["22842"],
      "Mount solon": ["22843"],
      "New market": ["22844"],
      "Orkney springs": ["22845"],
      Montevideo: ["22846"],
      "Shenandoah caver": ["22847"],
      Shenandoah: ["22849"],
      "Singers glen": ["22850"],
      Stanley: ["22851"],
      Timberville: ["22853"],
      "Zcta 228hh": ["228HH"],
      "Zcta 228xx": ["228XX"],
      Charlottesville: ["22901"],
      Monticello: ["22902"],
      University: ["22903"],
      "Zcta 22911": ["22911"],
      Afton: ["22920"],
      "Tye river": ["22922"],
      Burnleys: ["22923"],
      Batesville: ["22924"],
      Covesville: ["22931"],
      "Yancey mills": ["22932"],
      Boonesville: ["22935"],
      Earlysville: ["22936"],
      Esmont: ["22937"],
      Faber: ["22938"],
      "Woodrow wilson": ["22939"],
      "Mission home": ["22940"],
      "Cashs corner": ["22942"],
      Greenwood: ["22943"],
      Keene: ["22946"],
      "Boyd tavern": ["22947"],
      "Locust dale": ["22948"],
      Lovingston: ["22949"],
      Sherando: ["22952"],
      "Massies mill": ["22954"],
      "Montpelier stati": ["22957"],
      Wintergreen: ["22958"],
      Alberene: ["22959"],
      Montford: ["22960"],
      Bybee: ["22963"],
      "Piney river": ["22964"],
      Roseland: ["22967"],
      "Advance mills": ["22968"],
      Schuyler: ["22969"],
      Rockfish: ["22971"],
      Somerset: ["22972"],
      Geer: ["22973"],
      Troy: ["22974"],
      Tyro: ["22976"],
      Waynesboro: ["22980"],
      "Woodberry forest": ["22989"],
      "Zcta 229hh": ["229HH"],
      "Zcta 229xx": ["229XX"],
      Achilles: ["23001"],
      "Amelia court hou": ["23002"],
      Arvonia: ["23004"],
      Ashland: ["23005"],
      Aylett: ["23009"],
      Barhamsville: ["23011"],
      Beaverdam: ["23015"],
      Bohannon: ["23021"],
      "Bremo bluff": ["23022"],
      Bruington: ["23023"],
      Bumpass: ["23024"],
      Miles: ["23025"],
      Tamworth: ["23027"],
      "Charles city": ["23030"],
      "Church view": ["23032"],
      Blakes: ["23035"],
      Columbia: ["23038"],
      Crozier: ["23039"],
      Cumberland: ["23040"],
      Deltaville: ["23043"],
      Diggs: ["23045"],
      Doswell: ["23047"],
      Dutton: ["23050"],
      "Fork union": ["23055"],
      Foster: ["23056"],
      "Glen allen": ["23059", "23060"],
      Pinero: ["23061"],
      "Gloucester point": ["23062"],
      Goochland: ["23063"],
      Grimstead: ["23064"],
      "Gum spring": ["23065"],
      Gwynn: ["23066"],
      Hallieford: ["23068"],
      Hanover: ["23069"],
      Hardyville: ["23070"],
      Hartfield: ["23071"],
      Hayes: ["23072"],
      "Highland springs": ["23075"],
      Redart: ["23076"],
      Jamaica: ["23079"],
      Jetersville: ["23083"],
      "Kents store": ["23084"],
      "King and queen c": ["23085"],
      "King william": ["23086"],
      Lanexa: ["23089"],
      "Little plymouth": ["23091"],
      "Locust hill": ["23092"],
      Louisa: ["23093"],
      "Zcta 230hh": ["230HH"],
      Dabneys: ["23102"],
      "Manakin sabot": ["23103"],
      Manquin: ["23106"],
      Mascot: ["23108"],
      Mathews: ["23109"],
      Shanghai: ["23110"],
      Mechanicsville: ["23111"],
      Midlothian: ["23112", "23113"],
      "Millers tavern": ["23115"],
      "Zcta 23116": ["23116"],
      Mineral: ["23117"],
      Moon: ["23119"],
      Moseley: ["23120"],
      "New canton": ["23123"],
      "New kent": ["23124"],
      "New point": ["23125"],
      Newtown: ["23126"],
      North: ["23128"],
      Oilville: ["23129"],
      Onemo: ["23130"],
      Bavon: ["23138"],
      Powhatan: ["23139"],
      "Providence forge": ["23140"],
      Quinton: ["23141"],
      Rockville: ["23146"],
      "Indian neck": ["23148"],
      Saluda: ["23149"],
      Sandston: ["23150"],
      "Sandy hook": ["23153"],
      "Plain view": ["23156"],
      Stevensville: ["23161"],
      Shadow: ["23163"],
      Toano: ["23168"],
      Syringa: ["23169"],
      Remlik: ["23175"],
      Wake: ["23176"],
      Walkerton: ["23177"],
      "Ware neck": ["23178"],
      "Water view": ["23180"],
      "West point": ["23181"],
      Wicomico: ["23184"],
      Merrimac: ["23185"],
      "College of willi": ["23186"],
      Williamsburg: ["23188"],
      Montpelier: ["23192"],
      "Zcta 231hh": ["231HH"],
      Richmond: [
        "23219",
        "23220",
        "23221",
        "23222",
        "23223",
        "23224",
        "23225",
        "23226",
        "23231",
        "23236",
        "23237",
        "23294",
        "23298",
      ],
      Bellevue: ["23227"],
      Lakeside: ["23228"],
      Regency: ["23229"],
      "West end": ["23230"],
      Ridge: ["23233"],
      Ampthill: ["23234"],
      "Bon air": ["23235"],
      "Zcta 232hh": ["232HH"],
      Accomac: ["23301"],
      Assawoman: ["23302"],
      Atlantic: ["23303"],
      "Battery park": ["23304"],
      "Belle haven": ["23306"],
      Birdsnest: ["23307"],
      Bloxom: ["23308"],
      "Cape charles": ["23310"],
      Carrollton: ["23314"],
      Carrsville: ["23315"],
      Cheriton: ["23316"],
      Chesapeake: ["23320", "23323", "23324", "23325"],
      "Bowers hill": ["23321"],
      Fentress: ["23322"],
      Chincoteague: ["23336"],
      "Wallops island": ["23337"],
      Eastville: ["23347"],
      Exmore: ["23350"],
      Franktown: ["23354"],
      Greenbackville: ["23356"],
      Greenbush: ["23357"],
      Hacksneck: ["23358"],
      Hallwood: ["23359"],
      Harborton: ["23389"],
      Horntown: ["23395"],
      "Zcta 233hh": ["233HH"],
      Keller: ["23401"],
      Locustville: ["23404"],
      Machipongo: ["23405"],
      Mappsville: ["23407"],
      Mears: ["23409"],
      Melfa: ["23410"],
      Nassawadox: ["23413"],
      "New church": ["23415"],
      "Oak hall": ["23416"],
      Onancock: ["23417"],
      Onley: ["23418"],
      Painter: ["23420"],
      Parksley: ["23421"],
      Quinby: ["23423"],
      Sanford: ["23426"],
      Saxis: ["23427"],
      Smithfield: ["23430"],
      Suffolk: ["23432", "23433", "23434", "23435", "23436", "23437", "23438"],
      Tangier: ["23440"],
      Tasley: ["23441"],
      Temperanceville: ["23442"],
      "Virginia beach": [
        "23451",
        "23452",
        "23454",
        "23455",
        "23456",
        "23459",
        "23461",
        "23462",
        "23464",
      ],
      "Blackwater bridg": ["23457"],
      Wachapreague: ["23480"],
      Windsor: ["23487"],
      Withams: ["23488"],
      "Zcta 234hh": ["234HH"],
      Norfolk: [
        "23502",
        "23503",
        "23504",
        "23505",
        "23507",
        "23508",
        "23509",
        "23510",
        "23513",
        "23517",
        "23518",
        "23523",
      ],
      "Naval amphibious": ["23521"],
      "Zcta 235hh": ["235HH"],
      "Newport news": [
        "23601",
        "23602",
        "23603",
        "23604",
        "23605",
        "23606",
        "23607",
        "23608",
      ],
      Hampton: ["23651", "23661", "23663", "23664", "23665", "23666", "23669"],
      Poquoson: ["23662"],
      Yorktown: ["23690"],
      "Naval weapons st": ["23691"],
      Grafton: ["23692"],
      Tabb: ["23693"],
      Seaford: ["23696"],
      "Zcta 236hh": ["236HH"],
      Portsmouth: ["23701", "23702", "23703", "23704", "23707", "23708"],
      "Zcta 237hh": ["237HH"],
      "Fort lee": ["23801"],
      Ettrick: ["23803"],
      Petersburg: ["23805"],
      Alberta: ["23821"],
      Blackstone: ["23824"],
      Boykins: ["23827"],
      Branchville: ["23828"],
      Capron: ["23829"],
      Carson: ["23830"],
      Chester: ["23831"],
      Chesterfield: ["23832", "23838"],
      "Church road": ["23833"],
      "Colonial heights": ["23834"],
      "Zcta 23836": ["23836"],
      Courtland: ["23837"],
      Dendron: ["23839"],
      Dewitt: ["23840"],
      Dinwiddie: ["23841"],
      Disputanta: ["23842"],
      Dolphin: ["23843"],
      Drewryville: ["23844"],
      Ebony: ["23845"],
      Elberon: ["23846"],
      Emporia: ["23847"],
      Ammon: ["23850"],
      Franklin: ["23851"],
      Freeman: ["23856"],
      Gasburg: ["23857"],
      Hopewell: ["23860"],
      Ivor: ["23866"],
      Jarratt: ["23867"],
      Triplet: ["23868"],
      "Mc kenney": ["23872"],
      Newsoms: ["23874"],
      "Prince george": ["23875"],
      Rawlings: ["23876"],
      Sedley: ["23878"],
      Skippers: ["23879"],
      "Spring grove": ["23881"],
      "Stony creek": ["23882"],
      Surry: ["23883"],
      Sutherland: ["23885"],
      Valentines: ["23887"],
      Wakefield: ["23888"],
      Warfield: ["23889"],
      Waverly: ["23890"],
      "White plains": ["23893"],
      Wilsons: ["23894"],
      Yale: ["23897"],
      Zuni: ["23898"],
      Claremont: ["23899"],
      "Zcta 238hh": ["238HH"],
      "Zcta 238xx": ["238XX"],
      Farmville: ["23901"],
      Baskerville: ["23915"],
      Boydton: ["23917"],
      Bracey: ["23919"],
      Brodnax: ["23920"],
      Buckingham: ["23921"],
      Burkeville: ["23922"],
      "Charlotte court": ["23923"],
      "Chase city": ["23924"],
      Clarksville: ["23927"],
      Crewe: ["23930"],
      Cullen: ["23934"],
      "Sprouses corner": ["23936"],
      "Drakes branch": ["23937"],
      Dundas: ["23938"],
      Evergreen: ["23939"],
      "Green bay": ["23942"],
      "Hampden sydney": ["23943"],
      Kenbridge: ["23944"],
      Keysville: ["23947"],
      Blackridge: ["23950"],
      Lunenburg: ["23952"],
      Meherrin: ["23954"],
      Pamplin: ["23958"],
      Phenix: ["23959"],
      Prospect: ["23960"],
      Randolph: ["23962"],
      "Red house": ["23963"],
      "Red oak": ["23964"],
      Rice: ["23966"],
      Saxe: ["23967"],
      Skipwith: ["23968"],
      "South hill": ["23970"],
      Victoria: ["23974"],
      Wylliesburg: ["23976"],
      "Zcta 239hh": ["239HH"],
      Roanoke: ["24011", "24012", "24013", "24014", "24015", "24016", "24017"],
      "Cave spring": ["24018"],
      Hollins: ["24019"],
      "Hollins college": ["24020"],
      Ararat: ["24053"],
      Axton: ["24054"],
      Bassett: ["24055"],
      Belspring: ["24058"],
      "Bent mountain": ["24059"],
      Whitethorne: ["24060"],
      "Blue ridge": ["24064"],
      "Boones mill": ["24065"],
      Lithia: ["24066"],
      Callaway: ["24067"],
      Cascade: ["24069"],
      Catawba: ["24070"],
      Simpsons: ["24072"],
      Christiansburg: ["24073"],
      Claudville: ["24076"],
      Cloverdale: ["24077"],
      Collinsville: ["24078"],
      "Copper hill": ["24079"],
      Critz: ["24082"],
      Daleville: ["24083"],
      Dublin: ["24084"],
      "Eagle rock": ["24085"],
      Eggleston: ["24086"],
      Ironto: ["24087"],
      Ferrum: ["24088"],
      Fieldale: ["24089"],
      Fincastle: ["24090"],
      "Alum ridge": ["24091"],
      Gladehill: ["24092"],
      "Glen lyn": ["24093"],
      Goldbond: ["24094"],
      Goodview: ["24095"],
      "Zcta 240hh": ["240HH"],
      Hardy: ["24101"],
      Henry: ["24102"],
      Huddleston: ["24104"],
      "Indian valley": ["24105"],
      Martinsville: ["24112"],
      "Meadows of dan": ["24120"],
      Moneta: ["24121"],
      Montvale: ["24122"],
      Narrows: ["24124"],
      "New castle": ["24127"],
      Newport: ["24128"],
      "Paint bank": ["24131"],
      Parrott: ["24132"],
      "Patrick springs": ["24133"],
      Pearisburg: ["24134"],
      "Mountain lake": ["24136"],
      Penhook: ["24137"],
      Pilot: ["24138"],
      Pittsville: ["24139"],
      Fairlawn: ["24141"],
      Radford: ["24142"],
      "Rich creek": ["24147"],
      Ridgeway: ["24148"],
      Riner: ["24149"],
      Ripplemead: ["24150"],
      "Rocky mount": ["24151"],
      Salem: ["24153"],
      "Sandy level": ["24161"],
      Shawsville: ["24162"],
      Spencer: ["24165"],
      Staffordsville: ["24167"],
      Stanleytown: ["24168"],
      Stuart: ["24171"],
      Thaxton: ["24174"],
      Troutville: ["24175"],
      "Union hall": ["24176"],
      Stewartsville: ["24179"],
      Wirtz: ["24184"],
      Woolwine: ["24185"],
      "Zcta 241hh": ["241HH"],
      Bristol: ["24201", "24202"],
      Abingdon: ["24210"],
      "Zcta 24211": ["24211"],
      Andover: ["24215"],
      Exeter: ["24216"],
      Bee: ["24217"],
      "Ben hur": ["24218"],
      "Big stone gap": ["24219"],
      Birchleaf: ["24220"],
      Blackwater: ["24221"],
      Castlewood: ["24224"],
      Cleveland: ["24225"],
      Clinchco: ["24226"],
      Clintwood: ["24228"],
      Coeburn: ["24230"],
      Damascus: ["24236"],
      Dante: ["24237"],
      Davenport: ["24239"],
      Dryden: ["24243"],
      Clinchport: ["24244"],
      Dungannon: ["24245"],
      Ewing: ["24248"],
      "Fort blackmore": ["24250"],
      "Gate city": ["24251"],
      Haysi: ["24256"],
      Hiltons: ["24258"],
      Council: ["24260"],
      Jonesville: ["24263"],
      Keokee: ["24265"],
      Lebanon: ["24266"],
      "Mc clure": ["24269"],
      Mendota: ["24270"],
      Nickelsville: ["24271"],
      Nora: ["24272"],
      Norton: ["24273"],
      "Pennington gap": ["24277"],
      Pound: ["24279"],
      Rosedale: ["24280"],
      "Rose hill": ["24281"],
      "Saint charles": ["24282"],
      "Saint paul": ["24283"],
      Stonega: ["24285"],
      "Weber city": ["24290"],
      Whitetop: ["24292"],
      Wise: ["24293"],
      "Zcta 242hh": ["242HH"],
      Pulaski: ["24301"],
      Atkins: ["24311"],
      Austinville: ["24312"],
      "Barren springs": ["24313"],
      Bastian: ["24314"],
      Bland: ["24315"],
      Broadford: ["24316"],
      Cana: ["24317"],
      Ceres: ["24318"],
      Chilhowie: ["24319"],
      "Cripple creek": ["24322"],
      Crockett: ["24323"],
      Draper: ["24324"],
      Dugspur: ["24325"],
      "Elk creek": ["24326"],
      Emory: ["24327"],
      "Fancy gap": ["24328"],
      Fries: ["24330"],
      Galax: ["24333"],
      "Glade spring": ["24340"],
      Hillsville: ["24343"],
      Allisonia: ["24347"],
      Independence: ["24348"],
      Ivanhoe: ["24350"],
      Lambsburg: ["24351"],
      "Laurel fork": ["24352"],
      Marion: ["24354"],
      "Foster falls": ["24360"],
      Meadowview: ["24361"],
      "Mouth of wilson": ["24363"],
      "Rocky gap": ["24366"],
      "Rural retreat": ["24368"],
      Saltville: ["24370"],
      Speedwell: ["24374"],
      "Sugar grove": ["24375"],
      Tannersville: ["24377"],
      "Trout dale": ["24378"],
      Willis: ["24380"],
      Woodlawn: ["24381"],
      Wytheville: ["24382"],
      "Zcta 243hh": ["243HH"],
      Woodrum: ["24401"],
      Bacova: ["24412"],
      "Blue grass": ["24413"],
      Brownsburg: ["24415"],
      "Buena vista": ["24416"],
      Churchville: ["24421"],
      "Clifton forge": ["24422"],
      Alleghany: ["24426"],
      Craigsville: ["24430"],
      Crimora: ["24431"],
      Deerfield: ["24432"],
      "Doe hill": ["24433"],
      Fairfield: ["24435"],
      "Fort defiance": ["24437"],
      Goshen: ["24439"],
      Greenville: ["24440"],
      Grottoes: ["24441"],
      "Head waters": ["24442"],
      "Hot springs": ["24445"],
      "Iron gate": ["24448"],
      Lexington: ["24450"],
      "Low moor": ["24457"],
      "Mc dowell": ["24458"],
      Middlebrook: ["24459"],
      "Millboro spring": ["24460"],
      "Mint spring": ["24463"],
      Montebello: ["24464"],
      Monterey: ["24465"],
      "Mount sidney": ["24467"],
      "Port republic": ["24471"],
      Raphine: ["24472"],
      "Rockbridge baths": ["24473"],
      Selma: ["24474"],
      Spottswood: ["24475"],
      "Stuarts draft": ["24477"],
      Swoope: ["24479"],
      Verona: ["24482"],
      Vesuvius: ["24483"],
      Bolar: ["24484"],
      "West augusta": ["24485"],
      "Weyers cave": ["24486"],
      Burnsville: ["24487"],
      "Zcta 244hh": ["244HH"],
      "Zcta 244xx": ["244XX"],
      Lynchburg: ["24501", "24503", "24504"],
      Timberlake: ["24502"],
      Altavista: ["24517"],
      Alton: ["24520"],
      Amherst: ["24521"],
      Appomattox: ["24522"],
      Bedford: ["24523"],
      "Big island": ["24526"],
      Blairs: ["24527"],
      Brookneal: ["24528"],
      "Buffalo junction": ["24529"],
      Callands: ["24530"],
      Chatham: ["24531"],
      Clifford: ["24533"],
      Clover: ["24534"],
      "Coleman falls": ["24536"],
      Concord: ["24538"],
      "Crystal hill": ["24539"],
      Danville: ["24540", "24541"],
      "Dry fork": ["24549"],
      Evington: ["24550"],
      Forest: ["24551"],
      Gladstone: ["24553"],
      Gladys: ["24554"],
      Glasgow: ["24555"],
      Goode: ["24556"],
      Gretna: ["24557"],
      Halifax: ["24558"],
      Howardsville: ["24562"],
      Hurt: ["24563"],
      Java: ["24565"],
      Keeling: ["24566"],
      "Long island": ["24569"],
      "Lynch station": ["24571"],
      "Madison heights": ["24572"],
      Monroe: ["24574"],
      Lennig: ["24577"],
      "Natural bridge": ["24578"],
      "Natural bridge s": ["24579"],
      Nelson: ["24580"],
      Norwood: ["24581"],
      Ringgold: ["24586"],
      Rustburg: ["24588"],
      Scottsburg: ["24589"],
      Scottsville: ["24590"],
      "South boston": ["24592"],
      "Spout spring": ["24593"],
      Sutherlin: ["24594"],
      "Sweet briar": ["24595"],
      Ingram: ["24597"],
      Virgilina: ["24598"],
      Wingina: ["24599"],
      "Zcta 245hh": ["245HH"],
      Amonate: ["24601"],
      Bandy: ["24602"],
      Conaway: ["24603"],
      Bishop: ["24604"],
      Bluefield: ["24605"],
      Boissevain: ["24606"],
      Breaks: ["24607"],
      "Cedar bluff": ["24609"],
      Doran: ["24612"],
      "Falls mills": ["24613"],
      Grundy: ["24614"],
      Hurley: ["24620"],
      "Jewell valley": ["24622"],
      "Keen mountain": ["24624"],
      Maxie: ["24628"],
      Tiptop: ["24630"],
      Patterson: ["24631"],
      "Pilgrims knob": ["24634"],
      Pocahontas: ["24635"],
      "Pounding mill": ["24637"],
      Raven: ["24639"],
      Richlands: ["24641"],
      Rowe: ["24646"],
      "Swords creek": ["24649"],
      Tazewell: ["24651"],
      Vansant: ["24656"],
      Whitewood: ["24657"],
      "": ["37642"],
    },
  },
  {
    abbrevation: "WA",
    name: "Washington",
    Cities: [
      "Algona",
      "Algona",
      "Auburn",
      "Federal way",
      "Beaux arts",
      "Bellevue",
      "Black diamond",
      "Bothell",
      "Mill creek",
      "Carnation",
      "Duvall",
      "Woodway",
      "Enumclaw",
      "Fall city",
      "Hobart",
      "Edmonds",
      "Issaquah",
      "Kenmore",
      "Zcta 98029",
      "Kent",
      "Kirkland",
      "Brier",
      "Lynnwood",
      "Maple valley",
      "Medina",
      "Mercer island",
      "Mountlake terrac",
      "North bend",
      "Pacific",
      "Ravensdale",
      "Redmond",
      "Renton",
      "Snoqualmie",
      "Snoqualmie pass",
      "Vashon",
      "Woodinville",
      "Zcta 98092",
      "Zcta 980hh",
      "Zcta 980xx",
      "Seattle",
      "Tukwila",
      "Bainbridge islan",
      "Burien",
      "Normandy park",
      "Lk forest park",
      "Des moines",
      "Zcta 981hh",
      "Everett",
      "Acme",
      "Anacortes",
      "Blakely island",
      "Arlington",
      "Baring",
      "Bellingham",
      "Blaine",
      "Bow",
      "Burlington",
      "Clearlake",
      "Clinton",
      "Concrete",
      "Conway",
      "Coupeville",
      "Custer",
      "Darrington",
      "Deer harbor",
      "Glacier",
      "Eastsound",
      "Everson",
      "Ferndale",
      "Freeland",
      "Friday harbor",
      "Gold bar",
      "Granite falls",
      "Greenbank",
      "Index",
      "La conner",
      "Lake stevens",
      "Langley",
      "Lopez",
      "Lummi island",
      "Lynden",
      "Marblemount",
      "Marysville",
      "Monroe",
      "Mount vernon",
      "Zcta 98274",
      "Mukilteo",
      "Oak harbor",
      "Olga",
      "Orcas",
      "Point roberts",
      "Rockport",
      "Sedro woolley",
      "Shaw island",
      "Skykomish",
      "Snohomish",
      "Stanwood",
      "Sultan",
      "Sumas",
      "Zcta 98296",
      "Waldron",
      "Zcta 982hh",
      "Zcta 982xx",
      "Anderson island",
      "Ashford",
      "Beaver",
      "Bremerton",
      "Silverdale",
      "Brinnon",
      "Buckley",
      "Carbonado",
      "Chimacum",
      "Clallam bay",
      "Du pont",
      "Eatonville",
      "Gig harbor",
      "Elbe",
      "Forks",
      "Fox island",
      "Glenoma",
      "Gorst",
      "Graham",
      "Port hadlock",
      "Hansville",
      "Indianola",
      "Keyport",
      "Kingston",
      "Home",
      "La push",
      "Longbranch",
      "Manchester",
      "Milton",
      "Mineral",
      "Morton",
      "Neah bay",
      "Nordland",
      "Olalla",
      "Orting",
      "Packwood",
      "Port angeles",
      "Zcta 98363",
      "Port gamble",
      "Port ludlow",
      "South park villa",
      "Zcta 98367",
      "Port townsend",
      "Poulsbo",
      "Puyallup",
      "Zcta 98375",
      "Quilcene",
      "Randle",
      "Seabeck",
      "Sekiu",
      "Sequim",
      "South prairie",
      "Spanaway",
      "Steilacoom",
      "Bonney lake",
      "Suquamish",
      "Vaughn",
      "Wilkeson",
      "Zcta 983hh",
      "Zcta 983xx",
      "Tacoma",
      "Fife",
      "Fort lewis",
      "Lakewood center",
      "Parkland",
      "Fircrest",
      "Zcta 984hh",
      "Olympia",
      "Lacey",
      "Aberdeen",
      "Allyn",
      "Amanda park",
      "Bay center",
      "Bear creek",
      "Bucoda",
      "Centralia",
      "Chehalis",
      "Cinebar",
      "Copalis beach",
      "Copalis crossing",
      "Cosmopolis",
      "Curtis",
      "Doty",
      "Elma",
      "Ethel",
      "Grapeview",
      "Grayland",
      "Hoodsport",
      "Hoquiam",
      "Humptulips",
      "Lilliwaup",
      "Mc cleary",
      "Malone",
      "Matlock",
      "Moclips",
      "Montesano",
      "Mossyrock",
      "Napavine",
      "Oakville",
      "Ocean city",
      "Onalaska",
      "Pacific beach",
      "Pe ell",
      "Quinault",
      "Rainier",
      "Raymond",
      "Rochester",
      "Roy",
      "Ryderwood",
      "Salkum",
      "Shelton",
      "Silver creek",
      "South bend",
      "Taholah",
      "Tahuya",
      "Tenino",
      "Tokeland",
      "Toledo",
      "Union",
      "Vader",
      "Westport",
      "Winlock",
      "Yelm",
      "Zcta 985hh",
      "Zcta 985xx",
      "Amboy",
      "Appleton",
      "Ariel",
      "Battle ground",
      "Cook",
      "Brush prairie",
      "Camas",
      "Carson",
      "Castle rock",
      "Cathlamet",
      "Centerville",
      "Chinook",
      "Cougar",
      "Dallesport",
      "Glenwood",
      "Goldendale",
      "Grays river",
      "Ilwaco",
      "Kalama",
      "Kelso",
      "Klickitat",
      "La center",
      "Long beach",
      "Longview",
      "Lyle",
      "Naselle",
      "North bonneville",
      "Ocean park",
      "Oysterville",
      "Ridgefield",
      "Rosburg",
      "Seaview",
      "Silverlake",
      "Skamokawa",
      "Stevenson",
      "Toutle",
      "Trout lake",
      "Underwood",
      "Vancouver",
      "Orchards",
      "Hazel dell",
      "Wahkiacus",
      "Washougal",
      "White salmon",
      "Wishram",
      "Woodland",
      "Yacolt",
      "Zcta 98683",
      "Cascade park",
      "Felida",
      "Zcta 986hh",
      "Zcta 986xx",
      "Wenatchee",
      "East wenatchee",
      "Ardenvoir",
      "Brewster",
      "Bridgeport",
      "Carlton",
      "Cashmere",
      "Chelan",
      "Chelan falls",
      "Conconully",
      "Dryden",
      "Entiat",
      "Ephrata",
      "Leavenworth",
      "Loomis",
      "Malaga",
      "Malott",
      "Mansfield",
      "Manson",
      "Marlin",
      "Mazama",
      "Methow",
      "Monitor",
      "Moses lake",
      "Okanogan",
      "Omak",
      "Orondo",
      "Oroville",
      "Palisades",
      "Pateros",
      "Peshastin",
      "Quincy",
      "Riverside",
      "Rock island",
      "Soap lake",
      "Stehekin",
      "Stratford",
      "Tonasket",
      "Twisp",
      "Warden",
      "Waterville",
      "Wauconda",
      "Wilson creek",
      "Winthrop",
      "Zcta 988hh",
      "Zcta 988xx",
      "Terrace heights",
      "Yakima",
      "Union gap",
      "Wide hollow",
      "Cle elum",
      "Cowiche",
      "Easton",
      "Ellensburg",
      "Grandview",
      "Granger",
      "Harrah",
      "Kittitas",
      "Mabton",
      "Moxee",
      "White pass",
      "Outlook",
      "Ronald",
      "Roslyn",
      "Selah",
      "South cle elum",
      "Sunnyside",
      "Thorp",
      "Tieton",
      "Toppenish",
      "Vantage",
      "Wapato",
      "White swan",
      "Zillah",
      "Zcta 989hh",
      "Zcta 989xx",
      "Airway heights",
      "Chattaroy",
      "Cheney",
      "Colbert",
      "Deer park",
      "Edwall",
      "Elk",
      "Fairchild air fo",
      "Fairfield",
      "Ford",
      "Greenacres",
      "Lamont",
      "Latah",
      "Liberty lake",
      "Marshall",
      "Mead",
      "Espanola",
      "Mica",
      "Newman lake",
      "Nine mile falls",
      "Otis orchards",
      "Reardan",
      "Rockford",
      "Spangle",
      "Sprague",
      "Tekoa",
      "Tumtum",
      "Valleyford",
      "Veradale",
      "Wellpinit",
      "Zcta 990hh",
      "Zcta 990xx",
      "Addy",
      "Albion",
      "Almira",
      "Benge",
      "Chewelah",
      "Clayton",
      "Colfax",
      "Colton",
      "Colville",
      "Coulee city",
      "Coulee dam",
      "Creston",
      "Curlew",
      "Cusick",
      "Danville",
      "Davenport",
      "Electric city",
      "Elmer city",
      "Endicott",
      "Evans",
      "Farmington",
      "Fruitland",
      "Garfield",
      "Gifford",
      "Grand coulee",
      "Harrington",
      "Hartline",
      "Hay",
      "Hunters",
      "Inchelium",
      "Ione",
      "Keller",
      "Kettle falls",
      "Lacrosse",
      "Laurier",
      "Loon lake",
      "Malden",
      "Malo",
      "Marcus",
      "Metaline",
      "Metaline falls",
      "Mohler",
      "Nespelem",
      "Newport",
      "Northport",
      "Oakesdale",
      "Odessa",
      "Orient",
      "Palouse",
      "Pullman",
      "Republic",
      "Rice",
      "Ritzville",
      "Rosalia",
      "Saint john",
      "Springdale",
      "Thornton",
      "Uniontown",
      "Usk",
      "Valley",
      "Wilbur",
      "Zcta 991hh",
      "Zcta 991xx",
      "Spokane",
      "Zcta 992hh",
      "Pasco",
      "Benton city",
      "Beverly",
      "Bickleton",
      "Burbank",
      "College place",
      "Connell",
      "Dayton",
      "Dixie",
      "Eltopia",
      "Hatton",
      "Kahlotus",
      "Kennewick",
      "Zcta 99338",
      "Lind",
      "Mesa",
      "Mattawa",
      "Paterson",
      "Plymouth",
      "Pomeroy",
      "Prescott",
      "Zcta 99349",
      "Prosser",
      "Richland",
      "Zcta 99353",
      "Roosevelt",
      "Royal city",
      "Starbuck",
      "Lowden",
      "Waitsburg",
      "Walla walla",
      "Wallula",
      "Washtucna",
      "Zcta 993hh",
      "Zcta 993xx",
      "Anatone",
      "Asotin",
      "Clarkston",
      "Zcta 994hh",
    ],
    ZipCodes: {
      Algona: ["98001"],
      Auburn: ["98002"],
      "Federal way": ["98003", "98023"],
      "Beaux arts": ["98004"],
      Bellevue: ["98005", "98006", "98007", "98008"],
      "Black diamond": ["98010"],
      Bothell: ["98011", "98021"],
      "Mill creek": ["98012"],
      Carnation: ["98014"],
      Duvall: ["98019"],
      Woodway: ["98020"],
      Enumclaw: ["98022"],
      "Fall city": ["98024"],
      Hobart: ["98025"],
      Edmonds: ["98026"],
      Issaquah: ["98027"],
      Kenmore: ["98028"],
      "Zcta 98029": ["98029"],
      Kent: ["98031", "98032", "98042"],
      Kirkland: ["98033", "98034"],
      Brier: ["98036"],
      Lynnwood: ["98037"],
      "Maple valley": ["98038"],
      Medina: ["98039"],
      "Mercer island": ["98040"],
      "Mountlake terrac": ["98043"],
      "North bend": ["98045"],
      Pacific: ["98047"],
      Ravensdale: ["98051"],
      Redmond: ["98052", "98053"],
      Renton: ["98055", "98056", "98058", "98059"],
      Snoqualmie: ["98065"],
      "Snoqualmie pass": ["98068"],
      Vashon: ["98070"],
      Woodinville: ["98072"],
      "Zcta 98092": ["98092"],
      "Zcta 980hh": ["980HH"],
      "Zcta 980xx": ["980XX"],
      Seattle: [
        "98101",
        "98102",
        "98103",
        "98104",
        "98105",
        "98106",
        "98107",
        "98109",
        "98112",
        "98115",
        "98116",
        "98117",
        "98118",
        "98119",
        "98121",
        "98122",
        "98125",
        "98126",
        "98133",
        "98134",
        "98136",
        "98144",
        "98154",
        "98164",
        "98174",
        "98177",
        "98199",
      ],
      Tukwila: ["98108", "98168", "98178", "98188"],
      "Bainbridge islan": ["98110"],
      Burien: ["98146"],
      "Normandy park": ["98148", "98166"],
      "Lk forest park": ["98155"],
      "Des moines": ["98198"],
      "Zcta 981hh": ["981HH"],
      Everett: ["98201", "98203", "98204", "98205", "98208"],
      Acme: ["98220"],
      Anacortes: ["98221"],
      "Blakely island": ["98222"],
      Arlington: ["98223"],
      Baring: ["98224"],
      Bellingham: ["98225", "98226"],
      Blaine: ["98230"],
      Bow: ["98232"],
      Burlington: ["98233"],
      Clearlake: ["98235"],
      Clinton: ["98236"],
      Concrete: ["98237"],
      Conway: ["98238"],
      Coupeville: ["98239"],
      Custer: ["98240"],
      Darrington: ["98241"],
      "Deer harbor": ["98243"],
      Glacier: ["98244"],
      Eastsound: ["98245"],
      Everson: ["98247"],
      Ferndale: ["98248"],
      Freeland: ["98249"],
      "Friday harbor": ["98250"],
      "Gold bar": ["98251"],
      "Granite falls": ["98252"],
      Greenbank: ["98253"],
      Index: ["98256"],
      "La conner": ["98257"],
      "Lake stevens": ["98258"],
      Langley: ["98260"],
      Lopez: ["98261"],
      "Lummi island": ["98262"],
      Lynden: ["98264"],
      Marblemount: ["98267"],
      Marysville: ["98270", "98271"],
      Monroe: ["98272"],
      "Mount vernon": ["98273"],
      "Zcta 98274": ["98274"],
      Mukilteo: ["98275"],
      "Oak harbor": ["98277"],
      Olga: ["98279"],
      Orcas: ["98280"],
      "Point roberts": ["98281"],
      Rockport: ["98283"],
      "Sedro woolley": ["98284"],
      "Shaw island": ["98286"],
      Skykomish: ["98288"],
      Snohomish: ["98290"],
      Stanwood: ["98292"],
      Sultan: ["98294"],
      Sumas: ["98295"],
      "Zcta 98296": ["98296"],
      Waldron: ["98297"],
      "Zcta 982hh": ["982HH"],
      "Zcta 982xx": ["982XX"],
      "Anderson island": ["98303"],
      Ashford: ["98304"],
      Beaver: ["98305"],
      Bremerton: ["98310", "98311", "98312"],
      Silverdale: ["98315", "98383"],
      Brinnon: ["98320"],
      Buckley: ["98321"],
      Carbonado: ["98323"],
      Chimacum: ["98325"],
      "Clallam bay": ["98326"],
      "Du pont": ["98327"],
      Eatonville: ["98328"],
      "Gig harbor": ["98329", "98332", "98335"],
      Elbe: ["98330"],
      Forks: ["98331"],
      "Fox island": ["98333"],
      Glenoma: ["98336"],
      Gorst: ["98337"],
      Graham: ["98338"],
      "Port hadlock": ["98339"],
      Hansville: ["98340"],
      Indianola: ["98342"],
      Keyport: ["98345"],
      Kingston: ["98346"],
      Home: ["98349"],
      "La push": ["98350"],
      Longbranch: ["98351"],
      Manchester: ["98353"],
      Milton: ["98354"],
      Mineral: ["98355"],
      Morton: ["98356"],
      "Neah bay": ["98357"],
      Nordland: ["98358"],
      Olalla: ["98359"],
      Orting: ["98360"],
      Packwood: ["98361"],
      "Port angeles": ["98362"],
      "Zcta 98363": ["98363"],
      "Port gamble": ["98364"],
      "Port ludlow": ["98365"],
      "South park villa": ["98366"],
      "Zcta 98367": ["98367"],
      "Port townsend": ["98368"],
      Poulsbo: ["98370"],
      Puyallup: ["98371", "98372", "98373", "98374"],
      "Zcta 98375": ["98375"],
      Quilcene: ["98376"],
      Randle: ["98377"],
      Seabeck: ["98380"],
      Sekiu: ["98381"],
      Sequim: ["98382"],
      "South prairie": ["98385"],
      Spanaway: ["98387"],
      Steilacoom: ["98388"],
      "Bonney lake": ["98390"],
      Suquamish: ["98392"],
      Vaughn: ["98394"],
      Wilkeson: ["98396"],
      "Zcta 983hh": ["983HH"],
      "Zcta 983xx": ["983XX"],
      Tacoma: [
        "98402",
        "98403",
        "98404",
        "98405",
        "98406",
        "98407",
        "98408",
        "98409",
        "98421",
        "98422",
        "98443",
        "98465",
        "98467",
      ],
      Fife: ["98424"],
      "Fort lewis": ["98433"],
      "Lakewood center": ["98439", "98498", "98499"],
      Parkland: ["98444", "98445", "98446"],
      Fircrest: ["98466"],
      "Zcta 984hh": ["984HH"],
      Olympia: ["98501", "98502", "98512"],
      Lacey: ["98503", "98506", "98513", "98516"],
      Aberdeen: ["98520"],
      Allyn: ["98524"],
      "Amanda park": ["98526"],
      "Bay center": ["98527"],
      "Bear creek": ["98528"],
      Bucoda: ["98530"],
      Centralia: ["98531"],
      Chehalis: ["98532"],
      Cinebar: ["98533"],
      "Copalis beach": ["98535"],
      "Copalis crossing": ["98536"],
      Cosmopolis: ["98537"],
      Curtis: ["98538"],
      Doty: ["98539"],
      Elma: ["98541"],
      Ethel: ["98542"],
      Grapeview: ["98546"],
      Grayland: ["98547"],
      Hoodsport: ["98548"],
      Hoquiam: ["98550"],
      Humptulips: ["98552"],
      Lilliwaup: ["98555"],
      "Mc cleary": ["98557"],
      Malone: ["98559"],
      Matlock: ["98560"],
      Moclips: ["98562"],
      Montesano: ["98563"],
      Mossyrock: ["98564"],
      Napavine: ["98565"],
      Oakville: ["98568"],
      "Ocean city": ["98569"],
      Onalaska: ["98570"],
      "Pacific beach": ["98571"],
      "Pe ell": ["98572"],
      Quinault: ["98575"],
      Rainier: ["98576"],
      Raymond: ["98577"],
      Rochester: ["98579"],
      Roy: ["98580"],
      Ryderwood: ["98581"],
      Salkum: ["98582"],
      Shelton: ["98584"],
      "Silver creek": ["98585"],
      "South bend": ["98586"],
      Taholah: ["98587"],
      Tahuya: ["98588"],
      Tenino: ["98589"],
      Tokeland: ["98590"],
      Toledo: ["98591"],
      Union: ["98592"],
      Vader: ["98593"],
      Westport: ["98595"],
      Winlock: ["98596"],
      Yelm: ["98597"],
      "Zcta 985hh": ["985HH"],
      "Zcta 985xx": ["985XX"],
      Amboy: ["98601"],
      Appleton: ["98602"],
      Ariel: ["98603"],
      "Battle ground": ["98604"],
      Cook: ["98605"],
      "Brush prairie": ["98606"],
      Camas: ["98607"],
      Carson: ["98610"],
      "Castle rock": ["98611"],
      Cathlamet: ["98612"],
      Centerville: ["98613"],
      Chinook: ["98614"],
      Cougar: ["98616"],
      Dallesport: ["98617"],
      Glenwood: ["98619"],
      Goldendale: ["98620"],
      "Grays river": ["98621"],
      Ilwaco: ["98624"],
      Kalama: ["98625"],
      Kelso: ["98626"],
      Klickitat: ["98628"],
      "La center": ["98629"],
      "Long beach": ["98631"],
      Longview: ["98632"],
      Lyle: ["98635"],
      Naselle: ["98638"],
      "North bonneville": ["98639"],
      "Ocean park": ["98640"],
      Oysterville: ["98641"],
      Ridgefield: ["98642"],
      Rosburg: ["98643"],
      Seaview: ["98644"],
      Silverlake: ["98645"],
      Skamokawa: ["98647"],
      Stevenson: ["98648"],
      Toutle: ["98649"],
      "Trout lake": ["98650"],
      Underwood: ["98651"],
      Vancouver: ["98660", "98661", "98663", "98664", "98682", "98686"],
      Orchards: ["98662"],
      "Hazel dell": ["98665"],
      Wahkiacus: ["98670"],
      Washougal: ["98671"],
      "White salmon": ["98672"],
      Wishram: ["98673"],
      Woodland: ["98674"],
      Yacolt: ["98675"],
      "Zcta 98683": ["98683"],
      "Cascade park": ["98684"],
      Felida: ["98685"],
      "Zcta 986hh": ["986HH"],
      "Zcta 986xx": ["986XX"],
      Wenatchee: ["98801"],
      "East wenatchee": ["98802"],
      Ardenvoir: ["98811"],
      Brewster: ["98812"],
      Bridgeport: ["98813"],
      Carlton: ["98814"],
      Cashmere: ["98815"],
      Chelan: ["98816"],
      "Chelan falls": ["98817"],
      Conconully: ["98819"],
      Dryden: ["98821"],
      Entiat: ["98822"],
      Ephrata: ["98823"],
      Leavenworth: ["98826"],
      Loomis: ["98827"],
      Malaga: ["98828"],
      Malott: ["98829"],
      Mansfield: ["98830"],
      Manson: ["98831"],
      Marlin: ["98832"],
      Mazama: ["98833"],
      Methow: ["98834"],
      Monitor: ["98836"],
      "Moses lake": ["98837"],
      Okanogan: ["98840"],
      Omak: ["98841"],
      Orondo: ["98843"],
      Oroville: ["98844"],
      Palisades: ["98845"],
      Pateros: ["98846"],
      Peshastin: ["98847"],
      Quincy: ["98848"],
      Riverside: ["98849"],
      "Rock island": ["98850"],
      "Soap lake": ["98851"],
      Stehekin: ["98852"],
      Stratford: ["98853"],
      Tonasket: ["98855"],
      Twisp: ["98856"],
      Warden: ["98857"],
      Waterville: ["98858"],
      Wauconda: ["98859"],
      "Wilson creek": ["98860"],
      Winthrop: ["98862"],
      "Zcta 988hh": ["988HH"],
      "Zcta 988xx": ["988XX"],
      "Terrace heights": ["98901"],
      Yakima: ["98902"],
      "Union gap": ["98903"],
      "Wide hollow": ["98908"],
      "Cle elum": ["98922"],
      Cowiche: ["98923"],
      Easton: ["98925"],
      Ellensburg: ["98926"],
      Grandview: ["98930"],
      Granger: ["98932"],
      Harrah: ["98933"],
      Kittitas: ["98934"],
      Mabton: ["98935"],
      Moxee: ["98936"],
      "White pass": ["98937"],
      Outlook: ["98938"],
      Ronald: ["98940"],
      Roslyn: ["98941"],
      Selah: ["98942"],
      "South cle elum": ["98943"],
      Sunnyside: ["98944"],
      Thorp: ["98946"],
      Tieton: ["98947"],
      Toppenish: ["98948"],
      Vantage: ["98950"],
      Wapato: ["98951"],
      "White swan": ["98952"],
      Zillah: ["98953"],
      "Zcta 989hh": ["989HH"],
      "Zcta 989xx": ["989XX"],
      "Airway heights": ["99001"],
      Chattaroy: ["99003"],
      Cheney: ["99004"],
      Colbert: ["99005"],
      "Deer park": ["99006"],
      Edwall: ["99008"],
      Elk: ["99009"],
      "Fairchild air fo": ["99011"],
      Fairfield: ["99012"],
      Ford: ["99013"],
      Greenacres: ["99016"],
      Lamont: ["99017"],
      Latah: ["99018"],
      "Liberty lake": ["99019"],
      Marshall: ["99020"],
      Mead: ["99021"],
      Espanola: ["99022"],
      Mica: ["99023"],
      "Newman lake": ["99025"],
      "Nine mile falls": ["99026"],
      "Otis orchards": ["99027"],
      Reardan: ["99029"],
      Rockford: ["99030"],
      Spangle: ["99031"],
      Sprague: ["99032"],
      Tekoa: ["99033"],
      Tumtum: ["99034"],
      Valleyford: ["99036"],
      Veradale: ["99037"],
      Wellpinit: ["99040"],
      "Zcta 990hh": ["990HH"],
      "Zcta 990xx": ["990XX"],
      Addy: ["99101"],
      Albion: ["99102"],
      Almira: ["99103"],
      Benge: ["99105"],
      Chewelah: ["99109"],
      Clayton: ["99110"],
      Colfax: ["99111"],
      Colton: ["99113"],
      Colville: ["99114"],
      "Coulee city": ["99115"],
      "Coulee dam": ["99116"],
      Creston: ["99117"],
      Curlew: ["99118"],
      Cusick: ["99119"],
      Danville: ["99121"],
      Davenport: ["99122"],
      "Electric city": ["99123"],
      "Elmer city": ["99124"],
      Endicott: ["99125"],
      Evans: ["99126"],
      Farmington: ["99128"],
      Fruitland: ["99129"],
      Garfield: ["99130"],
      Gifford: ["99131"],
      "Grand coulee": ["99133"],
      Harrington: ["99134"],
      Hartline: ["99135"],
      Hay: ["99136"],
      Hunters: ["99137"],
      Inchelium: ["99138"],
      Ione: ["99139"],
      Keller: ["99140"],
      "Kettle falls": ["99141"],
      Lacrosse: ["99143"],
      Laurier: ["99146"],
      "Loon lake": ["99148"],
      Malden: ["99149"],
      Malo: ["99150"],
      Marcus: ["99151"],
      Metaline: ["99152"],
      "Metaline falls": ["99153"],
      Mohler: ["99154"],
      Nespelem: ["99155"],
      Newport: ["99156"],
      Northport: ["99157"],
      Oakesdale: ["99158"],
      Odessa: ["99159"],
      Orient: ["99160"],
      Palouse: ["99161"],
      Pullman: ["99163"],
      Republic: ["99166"],
      Rice: ["99167"],
      Ritzville: ["99169"],
      Rosalia: ["99170"],
      "Saint john": ["99171"],
      Springdale: ["99173"],
      Thornton: ["99176"],
      Uniontown: ["99179"],
      Usk: ["99180"],
      Valley: ["99181"],
      Wilbur: ["99185"],
      "Zcta 991hh": ["991HH"],
      "Zcta 991xx": ["991XX"],
      Spokane: [
        "99201",
        "99202",
        "99203",
        "99204",
        "99205",
        "99206",
        "99207",
        "99208",
        "99212",
        "99216",
        "99217",
        "99218",
        "99223",
        "99224",
      ],
      "Zcta 992hh": ["992HH"],
      Pasco: ["99301"],
      "Benton city": ["99320"],
      Beverly: ["99321"],
      Bickleton: ["99322"],
      Burbank: ["99323"],
      "College place": ["99324"],
      Connell: ["99326"],
      Dayton: ["99328"],
      Dixie: ["99329"],
      Eltopia: ["99330"],
      Hatton: ["99332"],
      Kahlotus: ["99335"],
      Kennewick: ["99336", "99337"],
      "Zcta 99338": ["99338"],
      Lind: ["99341"],
      Mesa: ["99343"],
      Mattawa: ["99344"],
      Paterson: ["99345"],
      Plymouth: ["99346"],
      Pomeroy: ["99347"],
      Prescott: ["99348"],
      "Zcta 99349": ["99349"],
      Prosser: ["99350"],
      Richland: ["99352"],
      "Zcta 99353": ["99353"],
      Roosevelt: ["99356"],
      "Royal city": ["99357"],
      Starbuck: ["99359"],
      Lowden: ["99360"],
      Waitsburg: ["99361"],
      "Walla walla": ["99362"],
      Wallula: ["99363"],
      Washtucna: ["99371"],
      "Zcta 993hh": ["993HH"],
      "Zcta 993xx": ["993XX"],
      Anatone: ["99401"],
      Asotin: ["99402"],
      Clarkston: ["99403"],
      "Zcta 994hh": ["994HH"],
    },
  },
  {
    abbrevation: "WV",
    name: "West virginia",
    Cities: [
      "Bluewell",
      "Bluewell",
      "Athens",
      "Beeson",
      "Bramwell",
      "Bud",
      "Covel",
      "Coaldale",
      "Herndon",
      "Kegley",
      "Lashmeet",
      "Dott",
      "Montcalm",
      "Oakvale",
      "Elgood",
      "Duhring",
      "Welch",
      "Anawalt",
      "Avondale",
      "Bartley",
      "Berwind",
      "Big sandy",
      "Bradshaw",
      "Brenton",
      "Caretta",
      "Clear fork",
      "Coal mountain",
      "Coalwood",
      "Crumpler",
      "Cyclone",
      "Asco",
      "Eckman",
      "Filbert",
      "Elkhorn",
      "Gary",
      "Hanover",
      "Hemphill",
      "Hensley",
      "Iaeger",
      "Ikes fork",
      "Isaban",
      "Jenkinjones",
      "Jesse",
      "Jolo",
      "Justice",
      "Keystone",
      "Kimball",
      "Kopperston",
      "Kyle",
      "Leckie",
      "Lynco",
      "Matheny",
      "Maybeury",
      "Mohawk",
      "Newhall",
      "New richmond",
      "Algoma",
      "North spring",
      "Oceana",
      "Pageton",
      "Panther",
      "Paynesville",
      "Pineville",
      "Raysal",
      "Rock view",
      "Roderfield",
      "Simon",
      "Squire",
      "Switchback",
      "Thorpe",
      "War",
      "Warriormine",
      "Wilcoe",
      "Gilliam",
      "Wyoming",
      "Zcta 248hh",
      "Lewisburg",
      "Dawson",
      "Arbovale",
      "Asbury",
      "Ballard",
      "Ballengee",
      "Bartow",
      "Buckeye",
      "Caldwell",
      "Stony bottom",
      "Crawley",
      "Dunmore",
      "Indian mills",
      "Fort spring",
      "Anthony",
      "Gap mills",
      "Grassy meadows",
      "Green bank",
      "Greenville",
      "Droop",
      "Kieffer",
      "Lindside",
      "Minnehaha spring",
      "Maxwelton",
      "Pence springs",
      "Peterstown",
      "Renick",
      "Ronceverte",
      "Secondcreek",
      "Pickaway",
      "Smoot",
      "Talcott",
      "Union",
      "Waiteville",
      "Wayside",
      "Neola",
      "Trout",
      "Zcta 249hh",
      "Zcta 249xx",
      "Alloy",
      "Alum creek",
      "Amma",
      "Arnett",
      "Artie",
      "Ashford",
      "Bald knob",
      "Bancroft",
      "Diamond",
      "Fola",
      "Bim",
      "Blair",
      "Bloomingrose",
      "Blount",
      "Bob white",
      "Bomont",
      "Boomer",
      "Buffalo",
      "Cabin creek",
      "Cannelton",
      "Cedar grove",
      "Charlton heights",
      "Clay",
      "Clear creek",
      "Quick",
      "Clothier",
      "Colcord",
      "Comfort",
      "Costa",
      "Danville",
      "Dawes",
      "Deep water",
      "Dixie",
      "Dorothy",
      "Duck",
      "Dunbar",
      "East bank",
      "Eleanor",
      "Frame",
      "Eskdale",
      "Ethel",
      "Foster",
      "Fraziers bottom",
      "Whittaker",
      "Gauley bridge",
      "Glasgow",
      "Glen",
      "Glen ferris",
      "Zcta 250hh",
      "Zcta 250xx",
      "Handley",
      "Hansford",
      "Henderson",
      "Hernshaw",
      "Hewett",
      "Hometown",
      "Hugheston",
      "Indore",
      "Big otter",
      "Ramage",
      "Kanawha falls",
      "Kimberly",
      "Kincaid",
      "Lake",
      "Arbuckle",
      "Liberty",
      "Lizemores",
      "London",
      "Madison",
      "Mammoth",
      "Maysel",
      "Miami",
      "Montgomery",
      "Mount carbon",
      "Naoma",
      "Nebo",
      "Nitro",
      "Orgas",
      "Ottawa",
      "Ovapa",
      "Page",
      "Peytona",
      "Lanham",
      "Pond gap",
      "Powellton",
      "Pratt",
      "Pigeon",
      "Racine",
      "Red house",
      "Ridgeview",
      "Robson",
      "Rock creek",
      "Saint albans",
      "Seth",
      "Sharples",
      "Longacre",
      "Southside",
      "Sylvester",
      "Zcta 251hh",
      "Tornado",
      "Bandytown",
      "Uneeda",
      "Van",
      "Wharton",
      "Garrison",
      "Widen",
      "Winfield",
      "Winifrede",
      "Advent",
      "Arnoldsburg",
      "Floe",
      "Cottageville",
      "Evans",
      "Gandeeville",
      "Gay",
      "Given",
      "Hartford",
      "Romance",
      "Left hand",
      "Duncan",
      "Letart",
      "Looneyville",
      "Mason",
      "Millstone",
      "Millwood",
      "Mount alto",
      "New haven",
      "Uler",
      "Normantown",
      "Minnora",
      "Reedy",
      "Ripley",
      "Sandyville",
      "Spencer",
      "Statts mills",
      "Tariff",
      "Valley fork",
      "Wallback",
      "Walton",
      "West columbia",
      "Zcta 252hh",
      "Charleston",
      "Big chimney",
      "South charleston",
      "Malden",
      "Cross lanes",
      "Marmet",
      "Sissonville",
      "Zcta 253hh",
      "Martinsburg",
      "Hancock",
      "Bunker hill",
      "Charles town",
      "Falling waters",
      "Gerrardstown",
      "Glengary",
      "Great cacapon",
      "Harpers ferry",
      "Cherry run",
      "Inwood",
      "Kearneysville",
      "Levels",
      "Paw paw",
      "Points",
      "Ranson",
      "Rippon",
      "Shenandoah junct",
      "Shepherdstown",
      "Slanesville",
      "Summit point",
      "Zcta 254hh",
      "Alkol",
      "Apple grove",
      "Ashton",
      "Barboursville",
      "Big creek",
      "Branchland",
      "Ceredo",
      "Chapmanville",
      "Culloden",
      "Dunlow",
      "East lynn",
      "Fort gay",
      "Gallipolis ferry",
      "Radnor",
      "Glenwood",
      "Griffithsville",
      "Hamlin",
      "Ferrellsburg",
      "Hurricane",
      "Julian",
      "Kenova",
      "Cove gap",
      "Lavalette",
      "Lesage",
      "Midkiff",
      "Milton",
      "Myra",
      "Ona",
      "Pecks mill",
      "Point pleasant",
      "Prichard",
      "Ranger",
      "Salt rock",
      "Scott depot",
      "Sod",
      "Morrisvale",
      "Sumerco",
      "Wayne",
      "West hamlin",
      "Yawkey",
      "Zcta 255hh",
      "Zcta 255xx",
      "West logan",
      "Accoville",
      "Robinette",
      "Baisden",
      "Bruno",
      "Chauncey",
      "Davin",
      "Gilbert",
      "Henlawson",
      "Holden",
      "Kistler",
      "Lorado",
      "Earling",
      "Mallory",
      "Hunt",
      "Monaville",
      "Mount gay",
      "Barnabus",
      "Peach creek",
      "Sarah ann",
      "Mc connell",
      "Switzer",
      "Verdunville",
      "Verner",
      "Wharncliffe",
      "Whitman",
      "Wilkinson",
      "Dehue",
      "Williamson",
      "Borderland",
      "Breeden",
      "Chattaroy",
      "Crum",
      "Myrtle",
      "Dingess",
      "Edgarton",
      "Kermit",
      "Lenore",
      "Lobata",
      "Meador",
      "Naugatuck",
      "North matewan",
      "Rawl",
      "Red jacket",
      "Varney",
      "Wilsondale",
      "Zcta 256hh",
      "Huntington",
      "Zcta 257hh",
      "Beckley",
      "Ansted",
      "Beaver",
      "Bolt",
      "Bradley",
      "Camp creek",
      "Whitby",
      "Cool ridge",
      "Corinne",
      "Crab orchard",
      "Clifftop",
      "Daniels",
      "Eccles",
      "Edmond",
      "Fairdale",
      "Cunard",
      "Flat top",
      "Ghent",
      "Glen daniel",
      "Glen fork",
      "Glen jean",
      "Sullivan",
      "Glen rogers",
      "Glen white",
      "Helen",
      "Hico",
      "Hilltop",
      "Jonben",
      "Josephine",
      "Lansing",
      "Lawton",
      "Lester",
      "Lochgelly",
      "Lookout",
      "Maben",
      "Mabscott",
      "Mc graws",
      "Midway",
      "Minden",
      "Mount hope",
      "Mullens",
      "Zcta 258hh",
      "Harvey",
      "Odd",
      "Pax",
      "Prince",
      "Winding gulf",
      "Prosperity",
      "Ravencliff",
      "East gulf",
      "Sabine",
      "Scarbro",
      "Abraham",
      "Slab fork",
      "Mcalpin",
      "Spanishburg",
      "Stephenson",
      "Surveyor",
      "Thurmond",
      "Victor",
      "Winona",
      "Hinton",
      "Charmco",
      "Rainelle",
      "Green sulphur sp",
      "Streeter",
      "Lerona",
      "Leslie",
      "Meadow bridge",
      "Meadow creek",
      "Nimitz",
      "Pipestem",
      "Marfrance",
      "Kessler",
      "Sandstone",
      "Spring dale",
      "White oak",
      "Zcta 259hh",
      "Zcta 259xx",
      "Elm grove",
      "Beech bottom",
      "Benwood",
      "Bethany",
      "Cameron",
      "Chester",
      "Colliers",
      "Dallas",
      "Follansbee",
      "Glen dale",
      "Glen easton",
      "Mc mechen",
      "Moundsville",
      "New cumberland",
      "Newell",
      "Proctor",
      "New manchester",
      "Triadelphia",
      "Valley grove",
      "Weirton",
      "Wellsburg",
      "West liberty",
      "Windsor heights",
      "Zcta 260hh",
      "Parkersburg",
      "North parkersbur",
      "Vienna",
      "Belleville",
      "Willow island",
      "Big bend",
      "Nobe",
      "Brohard",
      "Creston",
      "Davisville",
      "Elizabeth",
      "Friendly",
      "Grantsville",
      "Macfarlan",
      "Middlebourne",
      "Mineralwells",
      "Mount zion",
      "Munday",
      "New martinsville",
      "Paden city",
      "Palestine",
      "Petroleum",
      "Porters falls",
      "Ravenswood",
      "Reader",
      "Rockport",
      "Saint marys",
      "Sistersville",
      "Smithville",
      "Walker",
      "New england",
      "Waverly",
      "Wileyville",
      "Williamstown",
      "Zcta 261hh",
      "Tennerton",
      "Fenwick",
      "Erbacon",
      "Craigsville",
      "Cowen",
      "Gauley mills",
      "Snowshoe",
      "Adrian",
      "Cleveland",
      "Diana",
      "Alexander",
      "Replete",
      "Helvetia",
      "Kanawha head",
      "Pickens",
      "Rock cave",
      "Selbyville",
      "Tallmansville",
      "Volga",
      "Elkins",
      "Belington",
      "Beverly",
      "Wymer",
      "Coalton",
      "Dailey",
      "Davis",
      "Richwood",
      "Dryfork",
      "Durbin",
      "Upperglade",
      "Ellamore",
      "Glady",
      "Hambleton",
      "Harman",
      "Hendricks",
      "Huttonsville",
      "Junior",
      "Kerens",
      "Mabie",
      "Mill creek",
      "Monterville",
      "Montrose",
      "Norton",
      "Parsons",
      "Bolair",
      "Red creek",
      "Slatyfork",
      "Thomas",
      "Valley bend",
      "Mingo",
      "Job",
      "Bergoo",
      "Zcta 262hh",
      "Zcta 262xx",
      "Nutter fort ston",
      "Clarksburg",
      "Wilbur",
      "Alum bridge",
      "Anmoore",
      "Auburn",
      "Berea",
      "Bridgeport",
      "Bristol",
      "Brownton",
      "Gem",
      "Cairo",
      "Camden",
      "Center point",
      "Coxs mills",
      "Crawford",
      "Highland",
      "Wendel",
      "Folsom",
      "Galloway",
      "Baldwin",
      "Grafton",
      "Gypsy",
      "Mahone",
      "Haywood",
      "Hepzibah",
      "Horner",
      "Independence",
      "Wildcat",
      "Jacksonburg",
      "Jane lew",
      "Linn",
      "Lost creek",
      "Lumberport",
      "Zcta 263hh",
      "Meadowbrook",
      "Kasson",
      "Mount clare",
      "Newburg",
      "New milton",
      "Orlando",
      "Toll gate",
      "Broaddus",
      "Hastings",
      "Pullman",
      "Reynoldsville",
      "Rosemont",
      "Manheim",
      "Salem",
      "Sand fork",
      "Shinnston",
      "Simpson",
      "Smithburg",
      "Smithfield",
      "Spelter",
      "Thornton",
      "Troy",
      "Tunnelton",
      "Walkersville",
      "Wallace",
      "West milford",
      "Weston",
      "West union",
      "Wyatt",
      "Zcta 264hh",
      "Zcta 26501",
      "Star city",
      "Zcta 26508",
      "Albright",
      "Arthurdale",
      "Blacksville",
      "Bruceton mills",
      "Core",
      "Dellslow",
      "Granville",
      "Kingwood",
      "Maidsville",
      "Cascade",
      "Osage",
      "Pentress",
      "Reedsville",
      "Monongah",
      "Barrackville",
      "Coburn",
      "Carolina",
      "Enterprise",
      "Fairview",
      "Farmington",
      "Four states",
      "Grant town",
      "Hundred",
      "Idamay",
      "Littleton",
      "Mannington",
      "Metz",
      "Montana mines",
      "Rachel",
      "Rivesville",
      "Wadestown",
      "Wana",
      "Worthington",
      "Zcta 265hh",
      "Herold",
      "Birch river",
      "Flower",
      "Copen",
      "Dille",
      "Riffle",
      "Corley",
      "Clem",
      "Gassaway",
      "Heaters",
      "Tesla",
      "Napier",
      "Perkins",
      "Rosedale",
      "Shock",
      "Strange creek",
      "Wilsie",
      "Summersville",
      "Belva",
      "Calvin",
      "Canvas",
      "Drennen",
      "Gilboa",
      "Jodie",
      "Leivasy",
      "Mount lookout",
      "Runa",
      "Russelville",
      "Nettie",
      "Pool",
      "Swiss",
      "Tioga",
      "Zcta 266hh",
      "Zcta 266xx",
      "Augusta",
      "Amboy",
      "Wilson",
      "Burlington",
      "Capon bridge",
      "Delray",
      "Eglon",
      "Elk garden",
      "Fort ashby",
      "Gormania",
      "Green spring",
      "Scherr",
      "Lahmansville",
      "Mount storm",
      "New creek",
      "Piedmont",
      "Patterson creek",
      "Rio",
      "Romney",
      "Shanks",
      "Springfield",
      "Hopemont",
      "Wiley ford",
      "Zcta 267hh",
      "Zcta 267xx",
      "Baker",
      "Brandywine",
      "Circleville",
      "Franklin",
      "High view",
      "Lost city",
      "Mathias",
      "Riverton",
      "Sugar grove",
      "Bloomery",
      "Fisher",
      "Capon springs",
      "Maysville",
      "Rig",
      "Milam",
      "Old fields",
      "Dorcas",
      "Wardensville",
      "Purgitsville",
      "Cabins",
      "Lehew",
      "Upper tract",
      "Seneca rocks",
      "Onego",
      "Zcta 268hh",
      "Zcta 268xx",
    ],
    ZipCodes: {
      Bluewell: ["24701"],
      Athens: ["24712"],
      Beeson: ["24714"],
      Bramwell: ["24715"],
      Bud: ["24716"],
      Covel: ["24719"],
      Coaldale: ["24724"],
      Herndon: ["24726"],
      Kegley: ["24731"],
      Lashmeet: ["24733"],
      Dott: ["24736"],
      Montcalm: ["24737"],
      Oakvale: ["24739"],
      Elgood: ["24740"],
      Duhring: ["24747"],
      Welch: ["24801"],
      Anawalt: ["24808"],
      Avondale: ["24811"],
      Bartley: ["24813"],
      Berwind: ["24815"],
      "Big sandy": ["24816"],
      Bradshaw: ["24817"],
      Brenton: ["24818"],
      Caretta: ["24821"],
      "Clear fork": ["24822"],
      "Coal mountain": ["24823"],
      Coalwood: ["24824"],
      Crumpler: ["24825"],
      Cyclone: ["24827"],
      Asco: ["24828"],
      Eckman: ["24829"],
      Filbert: ["24830"],
      Elkhorn: ["24831"],
      Gary: ["24836"],
      Hanover: ["24839"],
      Hemphill: ["24842"],
      Hensley: ["24843"],
      Iaeger: ["24844"],
      "Ikes fork": ["24845"],
      Isaban: ["24846"],
      Jenkinjones: ["24848"],
      Jesse: ["24849"],
      Jolo: ["24850"],
      Justice: ["24851"],
      Keystone: ["24852"],
      Kimball: ["24853"],
      Kopperston: ["24854"],
      Kyle: ["24855"],
      Leckie: ["24856"],
      Lynco: ["24857"],
      Matheny: ["24860"],
      Maybeury: ["24861"],
      Mohawk: ["24862"],
      Newhall: ["24866"],
      "New richmond": ["24867"],
      Algoma: ["24868"],
      "North spring": ["24869"],
      Oceana: ["24870"],
      Pageton: ["24871"],
      Panther: ["24872"],
      Paynesville: ["24873"],
      Pineville: ["24874"],
      Raysal: ["24879"],
      "Rock view": ["24880"],
      Roderfield: ["24881"],
      Simon: ["24882"],
      Squire: ["24884"],
      Switchback: ["24887"],
      Thorpe: ["24888"],
      War: ["24892"],
      Warriormine: ["24894"],
      Wilcoe: ["24895"],
      Gilliam: ["24897"],
      Wyoming: ["24898"],
      "Zcta 248hh": ["248HH"],
      Lewisburg: ["24901"],
      Dawson: ["24910"],
      Arbovale: ["24915"],
      Asbury: ["24916"],
      Ballard: ["24918"],
      Ballengee: ["24919"],
      Bartow: ["24920"],
      Buckeye: ["24924"],
      Caldwell: ["24925"],
      "Stony bottom": ["24927"],
      Crawley: ["24931"],
      Dunmore: ["24934"],
      "Indian mills": ["24935"],
      "Fort spring": ["24936"],
      Anthony: ["24938"],
      "Gap mills": ["24941"],
      "Grassy meadows": ["24943"],
      "Green bank": ["24944"],
      Greenville: ["24945"],
      Droop: ["24946"],
      Kieffer: ["24950"],
      Lindside: ["24951"],
      "Minnehaha spring": ["24954"],
      Maxwelton: ["24957"],
      "Pence springs": ["24962"],
      Peterstown: ["24963"],
      Renick: ["24966"],
      Ronceverte: ["24970"],
      Secondcreek: ["24974"],
      Pickaway: ["24976"],
      Smoot: ["24977"],
      Talcott: ["24981"],
      Union: ["24983"],
      Waiteville: ["24984"],
      Wayside: ["24985"],
      Neola: ["24986"],
      Trout: ["24991"],
      "Zcta 249hh": ["249HH"],
      "Zcta 249xx": ["249XX"],
      Alloy: ["25002"],
      "Alum creek": ["25003"],
      Amma: ["25005"],
      Arnett: ["25007"],
      Artie: ["25008"],
      Ashford: ["25009"],
      "Bald knob": ["25010"],
      Bancroft: ["25011"],
      Diamond: ["25015"],
      Fola: ["25019"],
      Bim: ["25021"],
      Blair: ["25022"],
      Bloomingrose: ["25024"],
      Blount: ["25025"],
      "Bob white": ["25028"],
      Bomont: ["25030"],
      Boomer: ["25031"],
      Buffalo: ["25033"],
      "Cabin creek": ["25035"],
      Cannelton: ["25036"],
      "Cedar grove": ["25039"],
      "Charlton heights": ["25040"],
      Clay: ["25043"],
      "Clear creek": ["25044"],
      Quick: ["25045"],
      Clothier: ["25047"],
      Colcord: ["25048"],
      Comfort: ["25049"],
      Costa: ["25051"],
      Danville: ["25053"],
      Dawes: ["25054"],
      "Deep water": ["25057"],
      Dixie: ["25059"],
      Dorothy: ["25060"],
      Duck: ["25063"],
      Dunbar: ["25064"],
      "East bank": ["25067"],
      Eleanor: ["25070"],
      Frame: ["25071"],
      Eskdale: ["25075"],
      Ethel: ["25076"],
      Foster: ["25081"],
      "Fraziers bottom": ["25082"],
      Whittaker: ["25083"],
      "Gauley bridge": ["25085"],
      Glasgow: ["25086"],
      Glen: ["25088"],
      "Glen ferris": ["25090"],
      "Zcta 250hh": ["250HH"],
      "Zcta 250xx": ["250XX"],
      Handley: ["25102"],
      Hansford: ["25103"],
      Henderson: ["25106"],
      Hernshaw: ["25107"],
      Hewett: ["25108"],
      Hometown: ["25109"],
      Hugheston: ["25110"],
      Indore: ["25111"],
      "Big otter": ["25113"],
      Ramage: ["25114"],
      "Kanawha falls": ["25115"],
      Kimberly: ["25118"],
      Kincaid: ["25119"],
      Lake: ["25121"],
      Arbuckle: ["25123"],
      Liberty: ["25124"],
      Lizemores: ["25125"],
      London: ["25126"],
      Madison: ["25130"],
      Mammoth: ["25132"],
      Maysel: ["25133"],
      Miami: ["25134"],
      Montgomery: ["25136"],
      "Mount carbon": ["25139"],
      Naoma: ["25140"],
      Nebo: ["25141"],
      Nitro: ["25143"],
      Orgas: ["25148"],
      Ottawa: ["25149"],
      Ovapa: ["25150"],
      Page: ["25152"],
      Peytona: ["25154"],
      Lanham: ["25159"],
      "Pond gap": ["25160"],
      Powellton: ["25161"],
      Pratt: ["25162"],
      Pigeon: ["25164"],
      Racine: ["25165"],
      "Red house": ["25168"],
      Ridgeview: ["25169"],
      Robson: ["25173"],
      "Rock creek": ["25174"],
      "Saint albans": ["25177"],
      Seth: ["25181"],
      Sharples: ["25183"],
      Longacre: ["25186"],
      Southside: ["25187"],
      Sylvester: ["25193"],
      "Zcta 251hh": ["251HH"],
      Tornado: ["25202"],
      Bandytown: ["25204"],
      Uneeda: ["25205"],
      Van: ["25206"],
      Wharton: ["25208"],
      Garrison: ["25209"],
      Widen: ["25211"],
      Winfield: ["25213"],
      Winifrede: ["25214"],
      Advent: ["25231"],
      Arnoldsburg: ["25234"],
      Floe: ["25235"],
      Cottageville: ["25239"],
      Evans: ["25241"],
      Gandeeville: ["25243"],
      Gay: ["25244"],
      Given: ["25245"],
      Hartford: ["25247"],
      Romance: ["25248"],
      "Left hand": ["25251"],
      Duncan: ["25252"],
      Letart: ["25253"],
      Looneyville: ["25259"],
      Mason: ["25260"],
      Millstone: ["25261"],
      Millwood: ["25262"],
      "Mount alto": ["25264"],
      "New haven": ["25265"],
      Uler: ["25266"],
      Normantown: ["25267"],
      Minnora: ["25268"],
      Reedy: ["25270"],
      Ripley: ["25271"],
      Sandyville: ["25275"],
      Spencer: ["25276"],
      "Statts mills": ["25279"],
      Tariff: ["25281"],
      "Valley fork": ["25283"],
      Wallback: ["25285"],
      Walton: ["25286"],
      "West columbia": ["25287"],
      "Zcta 252hh": ["252HH"],
      Charleston: ["25301", "25304", "25311", "25312", "25314"],
      "Big chimney": ["25302"],
      "South charleston": ["25303", "25309"],
      Malden: ["25306"],
      "Cross lanes": ["25313"],
      Marmet: ["25315"],
      Sissonville: ["25320"],
      "Zcta 253hh": ["253HH"],
      Martinsburg: ["25401"],
      Hancock: ["25411"],
      "Bunker hill": ["25413"],
      "Charles town": ["25414"],
      "Falling waters": ["25419"],
      Gerrardstown: ["25420"],
      Glengary: ["25421"],
      "Great cacapon": ["25422"],
      "Harpers ferry": ["25425"],
      "Cherry run": ["25427"],
      Inwood: ["25428"],
      Kearneysville: ["25430"],
      Levels: ["25431"],
      "Paw paw": ["25434"],
      Points: ["25437"],
      Ranson: ["25438"],
      Rippon: ["25441"],
      "Shenandoah junct": ["25442"],
      Shepherdstown: ["25443"],
      Slanesville: ["25444"],
      "Summit point": ["25446"],
      "Zcta 254hh": ["254HH"],
      Alkol: ["25501"],
      "Apple grove": ["25502"],
      Ashton: ["25503"],
      Barboursville: ["25504"],
      "Big creek": ["25505"],
      Branchland: ["25506"],
      Ceredo: ["25507"],
      Chapmanville: ["25508"],
      Culloden: ["25510"],
      Dunlow: ["25511"],
      "East lynn": ["25512"],
      "Fort gay": ["25514"],
      "Gallipolis ferry": ["25515"],
      Radnor: ["25517"],
      Glenwood: ["25520"],
      Griffithsville: ["25521"],
      Hamlin: ["25523"],
      Ferrellsburg: ["25524"],
      Hurricane: ["25526"],
      Julian: ["25529"],
      Kenova: ["25530"],
      "Cove gap": ["25534"],
      Lavalette: ["25535"],
      Lesage: ["25537"],
      Midkiff: ["25540"],
      Milton: ["25541"],
      Myra: ["25544"],
      Ona: ["25545"],
      "Pecks mill": ["25547"],
      "Point pleasant": ["25550"],
      Prichard: ["25555"],
      Ranger: ["25557"],
      "Salt rock": ["25559"],
      "Scott depot": ["25560"],
      Sod: ["25564"],
      Morrisvale: ["25565"],
      Sumerco: ["25567"],
      Wayne: ["25570"],
      "West hamlin": ["25571"],
      Yawkey: ["25573"],
      "Zcta 255hh": ["255HH"],
      "Zcta 255xx": ["255XX"],
      "West logan": ["25601"],
      Accoville: ["25606"],
      Robinette: ["25607"],
      Baisden: ["25608"],
      Bruno: ["25611"],
      Chauncey: ["25612"],
      Davin: ["25617"],
      Gilbert: ["25621"],
      Henlawson: ["25624"],
      Holden: ["25625"],
      Kistler: ["25628"],
      Lorado: ["25630"],
      Earling: ["25632"],
      Mallory: ["25634"],
      Hunt: ["25635"],
      Monaville: ["25636"],
      "Mount gay": ["25637"],
      Barnabus: ["25638"],
      "Peach creek": ["25639"],
      "Sarah ann": ["25644"],
      "Mc connell": ["25646"],
      Switzer: ["25647"],
      Verdunville: ["25649"],
      Verner: ["25650"],
      Wharncliffe: ["25651"],
      Whitman: ["25652"],
      Wilkinson: ["25653"],
      Dehue: ["25654"],
      Williamson: ["25661"],
      Borderland: ["25665"],
      Breeden: ["25666"],
      Chattaroy: ["25667"],
      Crum: ["25669"],
      Myrtle: ["25670"],
      Dingess: ["25671"],
      Edgarton: ["25672"],
      Kermit: ["25674"],
      Lenore: ["25676"],
      Lobata: ["25678"],
      Meador: ["25682"],
      Naugatuck: ["25685"],
      "North matewan": ["25688"],
      Rawl: ["25691"],
      "Red jacket": ["25692"],
      Varney: ["25696"],
      Wilsondale: ["25699"],
      "Zcta 256hh": ["256HH"],
      Huntington: ["25701", "25702", "25703", "25704", "25705"],
      "Zcta 257hh": ["257HH"],
      Beckley: ["25801"],
      Ansted: ["25812"],
      Beaver: ["25813"],
      Bolt: ["25817"],
      Bradley: ["25818"],
      "Camp creek": ["25820"],
      Whitby: ["25823"],
      "Cool ridge": ["25825"],
      Corinne: ["25826"],
      "Crab orchard": ["25827"],
      Clifftop: ["25831"],
      Daniels: ["25832"],
      Eccles: ["25836"],
      Edmond: ["25837"],
      Fairdale: ["25839"],
      Cunard: ["25840"],
      "Flat top": ["25841"],
      Ghent: ["25843"],
      "Glen daniel": ["25844"],
      "Glen fork": ["25845"],
      "Glen jean": ["25846"],
      Sullivan: ["25847"],
      "Glen rogers": ["25848"],
      "Glen white": ["25849"],
      Helen: ["25853"],
      Hico: ["25854"],
      Hilltop: ["25855"],
      Jonben: ["25856"],
      Josephine: ["25857"],
      Lansing: ["25862"],
      Lawton: ["25864"],
      Lester: ["25865"],
      Lochgelly: ["25866"],
      Lookout: ["25868"],
      Maben: ["25870"],
      Mabscott: ["25871"],
      "Mc graws": ["25875"],
      Midway: ["25878"],
      Minden: ["25879"],
      "Mount hope": ["25880"],
      Mullens: ["25882"],
      "Zcta 258hh": ["258HH"],
      Harvey: ["25901"],
      Odd: ["25902"],
      Pax: ["25904"],
      Prince: ["25907"],
      "Winding gulf": ["25908"],
      Prosperity: ["25909"],
      Ravencliff: ["25913"],
      "East gulf": ["25915"],
      Sabine: ["25916"],
      Scarbro: ["25917"],
      Abraham: ["25918"],
      "Slab fork": ["25920"],
      Mcalpin: ["25921"],
      Spanishburg: ["25922"],
      Stephenson: ["25928"],
      Surveyor: ["25932"],
      Thurmond: ["25936"],
      Victor: ["25938"],
      Winona: ["25942"],
      Hinton: ["25951"],
      Charmco: ["25958"],
      Rainelle: ["25962"],
      "Green sulphur sp": ["25966"],
      Streeter: ["25969"],
      Lerona: ["25971"],
      Leslie: ["25972"],
      "Meadow bridge": ["25976"],
      "Meadow creek": ["25977"],
      Nimitz: ["25978"],
      Pipestem: ["25979"],
      Marfrance: ["25981"],
      Kessler: ["25984"],
      Sandstone: ["25985"],
      "Spring dale": ["25986"],
      "White oak": ["25989"],
      "Zcta 259hh": ["259HH"],
      "Zcta 259xx": ["259XX"],
      "Elm grove": ["26003"],
      "Beech bottom": ["26030"],
      Benwood: ["26031"],
      Bethany: ["26032"],
      Cameron: ["26033"],
      Chester: ["26034"],
      Colliers: ["26035"],
      Dallas: ["26036"],
      Follansbee: ["26037"],
      "Glen dale": ["26038"],
      "Glen easton": ["26039"],
      "Mc mechen": ["26040"],
      Moundsville: ["26041"],
      "New cumberland": ["26047"],
      Newell: ["26050"],
      Proctor: ["26055"],
      "New manchester": ["26056"],
      Triadelphia: ["26059"],
      "Valley grove": ["26060"],
      Weirton: ["26062"],
      Wellsburg: ["26070"],
      "West liberty": ["26074"],
      "Windsor heights": ["26075"],
      "Zcta 260hh": ["260HH"],
      Parkersburg: ["26101"],
      "North parkersbur": ["26104"],
      Vienna: ["26105"],
      Belleville: ["26133"],
      "Willow island": ["26134"],
      "Big bend": ["26136"],
      Nobe: ["26137"],
      Brohard: ["26138"],
      Creston: ["26141"],
      Davisville: ["26142"],
      Elizabeth: ["26143"],
      Friendly: ["26146"],
      Grantsville: ["26147"],
      Macfarlan: ["26148"],
      Middlebourne: ["26149"],
      Mineralwells: ["26150"],
      "Mount zion": ["26151"],
      Munday: ["26152"],
      "New martinsville": ["26155"],
      "Paden city": ["26159"],
      Palestine: ["26160"],
      Petroleum: ["26161"],
      "Porters falls": ["26162"],
      Ravenswood: ["26164"],
      Reader: ["26167"],
      Rockport: ["26169"],
      "Saint marys": ["26170"],
      Sistersville: ["26175"],
      Smithville: ["26178"],
      Walker: ["26180"],
      "New england": ["26181"],
      Waverly: ["26184"],
      Wileyville: ["26186"],
      Williamstown: ["26187"],
      "Zcta 261hh": ["261HH"],
      Tennerton: ["26201"],
      Fenwick: ["26202"],
      Erbacon: ["26203"],
      Craigsville: ["26205"],
      Cowen: ["26206"],
      "Gauley mills": ["26208"],
      Snowshoe: ["26209"],
      Adrian: ["26210"],
      Cleveland: ["26215"],
      Diana: ["26217"],
      Alexander: ["26218"],
      Replete: ["26222"],
      Helvetia: ["26224"],
      "Kanawha head": ["26228"],
      Pickens: ["26230"],
      "Rock cave": ["26234"],
      Selbyville: ["26236"],
      Tallmansville: ["26237"],
      Volga: ["26238"],
      Elkins: ["26241"],
      Belington: ["26250"],
      Beverly: ["26253"],
      Wymer: ["26254"],
      Coalton: ["26257"],
      Dailey: ["26259"],
      Davis: ["26260"],
      Richwood: ["26261"],
      Dryfork: ["26263"],
      Durbin: ["26264"],
      Upperglade: ["26266"],
      Ellamore: ["26267"],
      Glady: ["26268"],
      Hambleton: ["26269"],
      Harman: ["26270"],
      Hendricks: ["26271"],
      Huttonsville: ["26273"],
      Junior: ["26275"],
      Kerens: ["26276"],
      Mabie: ["26278"],
      "Mill creek": ["26280"],
      Monterville: ["26282"],
      Montrose: ["26283"],
      Norton: ["26285"],
      Parsons: ["26287"],
      Bolair: ["26288"],
      "Red creek": ["26289"],
      Slatyfork: ["26291"],
      Thomas: ["26292"],
      "Valley bend": ["26293"],
      Mingo: ["26294"],
      Job: ["26296"],
      Bergoo: ["26298"],
      "Zcta 262hh": ["262HH"],
      "Zcta 262xx": ["262XX"],
      "Nutter fort ston": ["26301"],
      Clarksburg: ["26302"],
      Wilbur: ["26320"],
      "Alum bridge": ["26321"],
      Anmoore: ["26323"],
      Auburn: ["26325"],
      Berea: ["26327"],
      Bridgeport: ["26330"],
      Bristol: ["26332"],
      Brownton: ["26334"],
      Gem: ["26335"],
      Cairo: ["26337"],
      Camden: ["26338"],
      "Center point": ["26339"],
      "Coxs mills": ["26342"],
      Crawford: ["26343"],
      Highland: ["26346"],
      Wendel: ["26347"],
      Folsom: ["26348"],
      Galloway: ["26349"],
      Baldwin: ["26351"],
      Grafton: ["26354"],
      Gypsy: ["26361"],
      Mahone: ["26362"],
      Haywood: ["26366"],
      Hepzibah: ["26369"],
      Horner: ["26372"],
      Independence: ["26374"],
      Wildcat: ["26376"],
      Jacksonburg: ["26377"],
      "Jane lew": ["26378"],
      Linn: ["26384"],
      "Lost creek": ["26385"],
      Lumberport: ["26386"],
      "Zcta 263hh": ["263HH"],
      Meadowbrook: ["26404"],
      Kasson: ["26405"],
      "Mount clare": ["26408"],
      Newburg: ["26410"],
      "New milton": ["26411"],
      Orlando: ["26412"],
      "Toll gate": ["26415"],
      Broaddus: ["26416"],
      Hastings: ["26419"],
      Pullman: ["26421"],
      Reynoldsville: ["26422"],
      Rosemont: ["26424"],
      Manheim: ["26425"],
      Salem: ["26426"],
      "Sand fork": ["26430"],
      Shinnston: ["26431"],
      Simpson: ["26435"],
      Smithburg: ["26436"],
      Smithfield: ["26437"],
      Spelter: ["26438"],
      Thornton: ["26440"],
      Troy: ["26443"],
      Tunnelton: ["26444"],
      Walkersville: ["26447"],
      Wallace: ["26448"],
      "West milford": ["26451"],
      Weston: ["26452"],
      "West union": ["26456"],
      Wyatt: ["26463"],
      "Zcta 264hh": ["264HH"],
      "Zcta 26501": ["26501"],
      "Star city": ["26505"],
      "Zcta 26508": ["26508"],
      Albright: ["26519"],
      Arthurdale: ["26520"],
      Blacksville: ["26521"],
      "Bruceton mills": ["26525"],
      Core: ["26529"],
      Dellslow: ["26531"],
      Granville: ["26534"],
      Kingwood: ["26537"],
      Maidsville: ["26541"],
      Cascade: ["26542"],
      Osage: ["26543"],
      Pentress: ["26544"],
      Reedsville: ["26547"],
      Monongah: ["26554"],
      Barrackville: ["26559"],
      Coburn: ["26562"],
      Carolina: ["26563"],
      Enterprise: ["26568"],
      Fairview: ["26570"],
      Farmington: ["26571"],
      "Four states": ["26572"],
      "Grant town": ["26574"],
      Hundred: ["26575"],
      Idamay: ["26576"],
      Littleton: ["26581"],
      Mannington: ["26582"],
      Metz: ["26585"],
      "Montana mines": ["26586"],
      Rachel: ["26587"],
      Rivesville: ["26588"],
      Wadestown: ["26589"],
      Wana: ["26590"],
      Worthington: ["26591"],
      "Zcta 265hh": ["265HH"],
      Herold: ["26601"],
      "Birch river": ["26610"],
      Flower: ["26611"],
      Copen: ["26615"],
      Dille: ["26617"],
      Riffle: ["26619"],
      Corley: ["26621"],
      Clem: ["26623"],
      Gassaway: ["26624"],
      Heaters: ["26627"],
      Tesla: ["26629"],
      Napier: ["26631"],
      Perkins: ["26634"],
      Rosedale: ["26636"],
      Shock: ["26638"],
      "Strange creek": ["26639"],
      Wilsie: ["26641"],
      Summersville: ["26651"],
      Belva: ["26656"],
      Calvin: ["26660"],
      Canvas: ["26662"],
      Drennen: ["26667"],
      Gilboa: ["26671"],
      Jodie: ["26674"],
      Leivasy: ["26676"],
      "Mount lookout": ["26678"],
      Runa: ["26679"],
      Russelville: ["26680"],
      Nettie: ["26681"],
      Pool: ["26684"],
      Swiss: ["26690"],
      Tioga: ["26691"],
      "Zcta 266hh": ["266HH"],
      "Zcta 266xx": ["266XX"],
      Augusta: ["26704"],
      Amboy: ["26705"],
      Wilson: ["26707"],
      Burlington: ["26710"],
      "Capon bridge": ["26711"],
      Delray: ["26714"],
      Eglon: ["26716"],
      "Elk garden": ["26717"],
      "Fort ashby": ["26719"],
      Gormania: ["26720"],
      "Green spring": ["26722"],
      Scherr: ["26726"],
      Lahmansville: ["26731"],
      "Mount storm": ["26739"],
      "New creek": ["26743"],
      Piedmont: ["26750"],
      "Patterson creek": ["26753"],
      Rio: ["26755"],
      Romney: ["26757"],
      Shanks: ["26761"],
      Springfield: ["26763"],
      Hopemont: ["26764"],
      "Wiley ford": ["26767"],
      "Zcta 267hh": ["267HH"],
      "Zcta 267xx": ["267XX"],
      Baker: ["26801"],
      Brandywine: ["26802"],
      Circleville: ["26804"],
      Franklin: ["26807"],
      "High view": ["26808"],
      "Lost city": ["26810"],
      Mathias: ["26812"],
      Riverton: ["26814"],
      "Sugar grove": ["26815"],
      Bloomery: ["26817"],
      Fisher: ["26818"],
      "Capon springs": ["26823"],
      Maysville: ["26833"],
      Rig: ["26836"],
      Milam: ["26838"],
      "Old fields": ["26845"],
      Dorcas: ["26847"],
      Wardensville: ["26851"],
      Purgitsville: ["26852"],
      Cabins: ["26855"],
      Lehew: ["26865"],
      "Upper tract": ["26866"],
      "Seneca rocks": ["26884"],
      Onego: ["26886"],
      "Zcta 268hh": ["268HH"],
      "Zcta 268xx": ["268XX"],
    },
  },
  {
    abbrevation: "WI",
    name: "Wisconsin",
    Cities: [
      "Adell",
      "Adell",
      "Allenton",
      "Ashippun",
      "Belgium",
      "Brookfield",
      "South byron",
      "Butler",
      "Campbellsport",
      "Cascade",
      "Cedarburg",
      "Cedar grove",
      "Chilton",
      "Cleveland",
      "Clyman",
      "Colgate",
      "Delafield",
      "Eden",
      "Elkhart lake",
      "Waubeka",
      "Germantown",
      "Glenbeulah",
      "Grafton",
      "Hartford",
      "Hartland",
      "Horicon",
      "Hubertus",
      "Hustisford",
      "Iron ridge",
      "Ixonia",
      "Jackson",
      "Johnson creek",
      "Juneau",
      "Kewaskum",
      "Kiel",
      "Kohler",
      "Lannon",
      "Lebanon",
      "Knowles",
      "Malone",
      "Mayville",
      "Menomonee falls",
      "Mount calvary",
      "Nashotah",
      "Neosho",
      "New holstein",
      "Newton",
      "Oakfield",
      "Oconomowoc",
      "Okauchee",
      "Oostburg",
      "Pewaukee",
      "Plymouth",
      "Port washington",
      "Random lake",
      "Richfield",
      "Rubicon",
      "Saint cloud",
      "Saukville",
      "Sheboygan",
      "Howards grove",
      "Sheboygan falls",
      "Slinger",
      "Stockbridge",
      "Sussex",
      "Zcta 53090",
      "Theresa",
      "Mequon",
      "Waldo",
      "Watertown",
      "West bend",
      "Zcta 530hh",
      "Big bend",
      "Bristol",
      "Burlington",
      "Caledonia",
      "Cudahy",
      "Darien",
      "Delavan",
      "Dousman",
      "Eagle",
      "East troy",
      "Elkhorn",
      "Elm grove",
      "Fontana",
      "Franksville",
      "Genoa city",
      "Greendale",
      "Hales corners",
      "Franklin",
      "Helenville",
      "Kansasville",
      "Kenosha",
      "New berlin",
      "Lake geneva",
      "Lyons",
      "Mukwonago",
      "Muskego",
      "North prairie",
      "Oak creek",
      "Palmyra",
      "Pleasant prairie",
      "Rochester",
      "Salem",
      "Silver lake",
      "South milwaukee",
      "Springfield",
      "Sturtevant",
      "Sullivan",
      "Trevor",
      "Twin lakes",
      "Union grove",
      "Wales",
      "Walworth",
      "Wind lake",
      "Waukesha",
      "Zcta 53189",
      "Whitewater",
      "Williams bay",
      "Wilmot",
      "Zenda",
      "Zcta 531hh",
      "Milwaukee",
      "Bay view",
      "Shorewood",
      "Wauwatosa",
      "West allis",
      "West milwaukee",
      "Greenfield",
      "Saint francis",
      "Zcta 532hh",
      "Racine",
      "Zcta 534hh",
      "Albany",
      "Arena",
      "Argyle",
      "Avalon",
      "Avoca",
      "Barneveld",
      "Belleville",
      "Belmont",
      "Shopiere",
      "Black earth",
      "Blanchardville",
      "Blue mounds",
      "Blue river",
      "Brodhead",
      "Brooklyn",
      "Browntown",
      "Cambridge",
      "Clinton",
      "Cobb",
      "Cottage grove",
      "Cross plains",
      "Dane",
      "Darlington",
      "Deerfield",
      "De forest",
      "Dodgeville",
      "Edgerton",
      "Evansville",
      "Footville",
      "Fort atkinson",
      "Gotham",
      "Gratiot",
      "Highland",
      "Hollandale",
      "Janesville",
      "Jefferson",
      "Juda",
      "Lake mills",
      "Linden",
      "Livingston",
      "Lodi",
      "Lone rock",
      "Lowell",
      "Mc farland",
      "Marshall",
      "Mazomanie",
      "Merrimac",
      "Middleton",
      "Milton",
      "Mineral point",
      "Monroe",
      "Montfort",
      "Monticello",
      "Morrisonville",
      "Mount horeb",
      "Muscoda",
      "New glarus",
      "Oregon",
      "Orfordville",
      "Plain",
      "Prairie du sac",
      "Reeseville",
      "Rewey",
      "Gillingham",
      "Ridgeway",
      "Sauk city",
      "Sharon",
      "Shullsburg",
      "South wayne",
      "Spring green",
      "Stoughton",
      "Sun prairie",
      "Verona",
      "Waterloo",
      "Waunakee",
      "Windsor",
      "Zcta 535hh",
      "Madison",
      "Fitchburg",
      "Monona",
      "Zcta 537hh",
      "Bagley",
      "Benton",
      "Bloomington",
      "Boscobel",
      "Cassville",
      "Cuba city",
      "Dickeyville",
      "Fennimore",
      "Glen haven",
      "Hazel green",
      "Lancaster",
      "Mount hope",
      "Patch grove",
      "Platteville",
      "Potosi",
      "Prairie du chien",
      "Stitzer",
      "Wauzeka",
      "Woodman",
      "Zcta 538hh",
      "Portage",
      "Adams",
      "Arlington",
      "Baraboo",
      "Beaver dam",
      "Brandon",
      "Briggsville",
      "Burnett",
      "Cambria",
      "Cazenovia",
      "Columbus",
      "Dalton",
      "Doylestown",
      "Elroy",
      "Endeavor",
      "Fair water",
      "Fall river",
      "Fox lake",
      "Friendship",
      "Friesland",
      "Grand marsh",
      "Hillpoint",
      "Kingston",
      "Lake delton",
      "La valle",
      "Loganville",
      "Lyndon station",
      "Markesan",
      "Marquette",
      "Mauston",
      "Montello",
      "New lisbon",
      "North freedom",
      "Oxford",
      "Packwaukee",
      "Pardeeville",
      "Poynette",
      "Randolph",
      "Reedsburg",
      "Rio",
      "Rock springs",
      "Union center",
      "Waupun",
      "Westfield",
      "Wisconsin dells",
      "Wonewoc",
      "Wyocena",
      "Zcta 539hh",
      "Deronda",
      "Baldwin",
      "Beldenville",
      "Clayton",
      "Clear lake",
      "Cushing",
      "Deer park",
      "Dresser",
      "Ellsworth",
      "Emerald",
      "Glenwood city",
      "Hager city",
      "Hammond",
      "Hudson",
      "New richmond",
      "Osceola",
      "Prescott",
      "River falls",
      "Roberts",
      "Saint croix fall",
      "Somerset",
      "Star prairie",
      "Wilson",
      "Woodville",
      "Saint joseph",
      "Zcta 540hh",
      "Abrams",
      "Amberg",
      "Armstrong creek",
      "Athelstane",
      "Center valley",
      "Navarino",
      "Brillion",
      "Cecil",
      "Coleman",
      "Combined locks",
      "Beaver",
      "De pere",
      "Dunbar",
      "Fence",
      "Florence",
      "Forest junction",
      "Gillett",
      "Goodman",
      "Greenleaf",
      "Green valley",
      "Gresham",
      "Hilbert",
      "Kaukauna",
      "Keshena",
      "Kimberly",
      "Krakow",
      "Lakewood",
      "Stiles",
      "Little chute",
      "Little suamico",
      "Marinette",
      "Mountain",
      "Neopit",
      "Niagara",
      "Nichols",
      "Oconto",
      "Oconto falls",
      "Oneida",
      "Pembine",
      "Peshtigo",
      "Porterfield",
      "Potter",
      "Pound",
      "Pulaski",
      "Seymour",
      "Shawano",
      "Sherwood",
      "Shiocton",
      "Sobieski",
      "Suamico",
      "Suring",
      "Townsend",
      "Wausaukee",
      "Wrightstown",
      "Zcta 541hh",
      "Zcta 541xx",
      "Algoma",
      "Baileys harbor",
      "Brussels",
      "Casco",
      "Collins",
      "Denmark",
      "Egg harbor",
      "Ellison bay",
      "Ephraim",
      "Fish creek",
      "Forestville",
      "Francis creek",
      "Kellnersville",
      "Kewaunee",
      "Luxemburg",
      "Manitowoc",
      "Maribel",
      "Mishicot",
      "New franken",
      "Reedsville",
      "Saint nazianz",
      "Sister bay",
      "Sturgeon bay",
      "Tisch mills",
      "Two rivers",
      "Valders",
      "Washington islan",
      "Whitelaw",
      "Zcta 542hh",
      "Allouez",
      "Green bay",
      "Howard",
      "Ashwaubenon",
      "Zcta 543hh",
      "Wausau",
      "Abbotsford",
      "Amherst",
      "Amherst junction",
      "Aniwa",
      "Antigo",
      "Arpin",
      "Hamburg",
      "Auburndale",
      "Babcock",
      "Birnamwood",
      "Bowler",
      "Brokaw",
      "Bryant",
      "Chili",
      "Colby",
      "Curtiss",
      "Custer",
      "Deerbrook",
      "Dorchester",
      "Fenwood",
      "Eland",
      "Elcho",
      "Elton",
      "Gilman",
      "Gleason",
      "Granton",
      "Greenwood",
      "Hatley",
      "Hewitt",
      "Irma",
      "Junction city",
      "Loyal",
      "Lublin",
      "Marathon",
      "Marshfield",
      "Medford",
      "Merrill",
      "Milladore",
      "Mosinee",
      "Neillsville",
      "Nekoosa",
      "Ogema",
      "Owen",
      "Pearson",
      "Pelican lake",
      "Pickerel",
      "Pittsville",
      "Plover",
      "Port edwards",
      "Rib lake",
      "Ringle",
      "Rosholt",
      "Rothschild",
      "Rudolph",
      "Schofield",
      "Spencer",
      "Stetsonville",
      "Stevens point",
      "Stratford",
      "Summit lake",
      "Tigerton",
      "Tomahawk",
      "Unity",
      "Vesper",
      "Westboro",
      "White lake",
      "Willard",
      "Wisconsin rapids",
      "Withee",
      "Wittenberg",
      "Zcta 544hh",
      "Zcta 544xx",
      "Monico",
      "Cavour",
      "Boulder junction",
      "Brantwood",
      "Butternut",
      "Catawba",
      "Clam lake",
      "Conover",
      "Crandon",
      "Eagle river",
      "Fifield",
      "Gile",
      "Ingram",
      "Glidden",
      "Harshaw",
      "Hawkins",
      "Hazelhurst",
      "Hurley",
      "Iron belt",
      "Kennan",
      "Lac du flambeau",
      "Lake tomahawk",
      "Land o lakes",
      "Laona",
      "Alvin",
      "Mc naughton",
      "Manitowish water",
      "Mellen",
      "Mercer",
      "Minocqua",
      "Pence",
      "Park falls",
      "Phelps",
      "Phillips",
      "Prentice",
      "Winchester",
      "Saint germain",
      "Saxon",
      "Sayner",
      "Starlake",
      "Three lakes",
      "Tony",
      "Tripoli",
      "Upson",
      "Wabeno",
      "Woodruff",
      "Zcta 545hh",
      "Zcta 545xx",
      "La crosse",
      "Alma",
      "Alma center",
      "Arcadia",
      "Arkdale",
      "Bangor",
      "Black river fall",
      "Blair",
      "Cutler",
      "Cashton",
      "Chaseburg",
      "Waumandee",
      "Coon valley",
      "Victory",
      "Dodge",
      "Eastman",
      "Ettrick",
      "Ferryville",
      "Fountain city",
      "Galesville",
      "Gays mills",
      "Genoa",
      "Yuba",
      "Northfield",
      "Holmen",
      "Hustler",
      "Kendall",
      "West lima",
      "Lynxville",
      "Melrose",
      "Mindoro",
      "Mount sterling",
      "Necedah",
      "Norwalk",
      "Onalaska",
      "Ontario",
      "Readstown",
      "Rockland",
      "Seneca",
      "Soldiers grove",
      "Sparta",
      "Steuben",
      "Stoddard",
      "Taylor",
      "Wyeville",
      "Trempealeau",
      "Viola",
      "Viroqua",
      "Warrens",
      "Westby",
      "West salem",
      "Wilton",
      "Zcta 546hh",
      "Zcta 546xx",
      "Eau claire",
      "Altoona",
      "Arkansaw",
      "Augusta",
      "Bay city",
      "Bloomer",
      "Boyceville",
      "Boyd",
      "Cadott",
      "Chetek",
      "Chippewa falls",
      "Colfax",
      "Conrath",
      "Cornell",
      "Dallas",
      "Downing",
      "Durand",
      "Eau galle",
      "Eleva",
      "Elk mound",
      "Elmwood",
      "Fairchild",
      "Fall creek",
      "Holcombe",
      "Humbird",
      "Independence",
      "Jim falls",
      "Knapp",
      "Maiden rock",
      "Menomonie",
      "Merrillan",
      "Modena",
      "Nelson",
      "New auburn",
      "Osseo",
      "Pepin",
      "Pigeon falls",
      "Plum city",
      "Prairie farm",
      "Ridgeland",
      "Sheldon",
      "Spring valley",
      "Stanley",
      "Stockholm",
      "Strum",
      "Thorp",
      "Wheeler",
      "Whitehall",
      "Zcta 547hh",
      "Zcta 547xx",
      "Spooner",
      "Almena",
      "Moquah",
      "Balsam lake",
      "Barron",
      "Barronett",
      "Bayfield",
      "Birchwood",
      "Bruce",
      "Brule",
      "Cable",
      "Cameron",
      "Centuria",
      "Comstock",
      "Cornucopia",
      "New post",
      "Cumberland",
      "Dairyland",
      "Drummond",
      "Edgewater",
      "Exeland",
      "Foxboro",
      "Clam falls",
      "Gordon",
      "Grand view",
      "Evergreen",
      "Haugen",
      "Hawthorne",
      "North woods beac",
      "Herbster",
      "Hertel",
      "High bridge",
      "Iron river",
      "Ladysmith",
      "Lake nebagamon",
      "La pointe",
      "Luck",
      "Maple",
      "Marengo",
      "Delta",
      "Mikana",
      "Milltown",
      "Minong",
      "Odanah",
      "Ojibwa",
      "Poplar",
      "Port wing",
      "Radisson",
      "Canton",
      "Sarona",
      "Shell lake",
      "Siren",
      "Barnes",
      "Wentworth",
      "Earl",
      "Stone lake",
      "Superior",
      "Trego",
      "Turtle lake",
      "Washburn",
      "Webster",
      "Weyerhaeuser",
      "Loretta",
      "Zcta 548hh",
      "Zcta 548xx",
      "Oshkosh",
      "Almond",
      "Appleton",
      "Bancroft",
      "Bear creek",
      "Berlin",
      "Butte des morts",
      "Caroline",
      "Clintonville",
      "Coloma",
      "Eldorado",
      "Embarrass",
      "Eureka",
      "Taycheedah",
      "North fond du la",
      "Fremont",
      "Green lake",
      "Greenville",
      "Hancock",
      "Hortonville",
      "Iola",
      "Larsen",
      "Leopolis",
      "Manawa",
      "Marion",
      "Menasha",
      "Neenah",
      "Neshkoro",
      "New london",
      "Ogdensburg",
      "Omro",
      "Pickett",
      "Pine river",
      "Plainfield",
      "Poy sippi",
      "Princeton",
      "Redgranite",
      "Ripon",
      "Rosendale",
      "Saxeville",
      "Scandinavia",
      "Tilleda",
      "Van dyne",
      "Waukau",
      "Waupaca",
      "Wautoma",
      "Weyauwega",
      "Wild rose",
      "Winnebago",
      "Winneconne",
      "Zcta 549hh",
    ],
    ZipCodes: {
      Adell: ["53001"],
      Allenton: ["53002"],
      Ashippun: ["53003"],
      Belgium: ["53004"],
      Brookfield: ["53005", "53045"],
      "South byron": ["53006"],
      Butler: ["53007"],
      Campbellsport: ["53010"],
      Cascade: ["53011"],
      Cedarburg: ["53012"],
      "Cedar grove": ["53013"],
      Chilton: ["53014"],
      Cleveland: ["53015"],
      Clyman: ["53016"],
      Colgate: ["53017"],
      Delafield: ["53018"],
      Eden: ["53019"],
      "Elkhart lake": ["53020"],
      Waubeka: ["53021"],
      Germantown: ["53022"],
      Glenbeulah: ["53023"],
      Grafton: ["53024"],
      Hartford: ["53027"],
      Hartland: ["53029"],
      Horicon: ["53032"],
      Hubertus: ["53033"],
      Hustisford: ["53034"],
      "Iron ridge": ["53035"],
      Ixonia: ["53036"],
      Jackson: ["53037"],
      "Johnson creek": ["53038"],
      Juneau: ["53039"],
      Kewaskum: ["53040"],
      Kiel: ["53042"],
      Kohler: ["53044"],
      Lannon: ["53046"],
      Lebanon: ["53047"],
      Knowles: ["53048"],
      Malone: ["53049"],
      Mayville: ["53050"],
      "Menomonee falls": ["53051"],
      "Mount calvary": ["53057"],
      Nashotah: ["53058"],
      Neosho: ["53059"],
      "New holstein": ["53061"],
      Newton: ["53063"],
      Oakfield: ["53065"],
      Oconomowoc: ["53066"],
      Okauchee: ["53069"],
      Oostburg: ["53070"],
      Pewaukee: ["53072"],
      Plymouth: ["53073"],
      "Port washington": ["53074"],
      "Random lake": ["53075"],
      Richfield: ["53076"],
      Rubicon: ["53078"],
      "Saint cloud": ["53079"],
      Saukville: ["53080"],
      Sheboygan: ["53081"],
      "Howards grove": ["53083"],
      "Sheboygan falls": ["53085"],
      Slinger: ["53086"],
      Stockbridge: ["53088"],
      Sussex: ["53089"],
      "Zcta 53090": ["53090"],
      Theresa: ["53091"],
      Mequon: ["53092", "53097"],
      Waldo: ["53093"],
      Watertown: ["53094", "53098"],
      "West bend": ["53095"],
      "Zcta 530hh": ["530HH"],
      "Big bend": ["53103"],
      Bristol: ["53104"],
      Burlington: ["53105"],
      Caledonia: ["53108"],
      Cudahy: ["53110"],
      Darien: ["53114"],
      Delavan: ["53115"],
      Dousman: ["53118"],
      Eagle: ["53119"],
      "East troy": ["53120"],
      Elkhorn: ["53121"],
      "Elm grove": ["53122"],
      Fontana: ["53125"],
      Franksville: ["53126"],
      "Genoa city": ["53128"],
      Greendale: ["53129"],
      "Hales corners": ["53130"],
      Franklin: ["53132"],
      Helenville: ["53137"],
      Kansasville: ["53139"],
      Kenosha: ["53140", "53142", "53143", "53144"],
      "New berlin": ["53146", "53151"],
      "Lake geneva": ["53147"],
      Lyons: ["53148"],
      Mukwonago: ["53149"],
      Muskego: ["53150"],
      "North prairie": ["53153"],
      "Oak creek": ["53154"],
      Palmyra: ["53156"],
      "Pleasant prairie": ["53158"],
      Rochester: ["53167"],
      Salem: ["53168"],
      "Silver lake": ["53170"],
      "South milwaukee": ["53172"],
      Springfield: ["53176"],
      Sturtevant: ["53177"],
      Sullivan: ["53178"],
      Trevor: ["53179"],
      "Twin lakes": ["53181"],
      "Union grove": ["53182"],
      Wales: ["53183"],
      Walworth: ["53184"],
      "Wind lake": ["53185"],
      Waukesha: ["53186", "53188"],
      "Zcta 53189": ["53189"],
      Whitewater: ["53190"],
      "Williams bay": ["53191"],
      Wilmot: ["53192"],
      Zenda: ["53195"],
      "Zcta 531hh": ["531HH"],
      Milwaukee: [
        "53202",
        "53203",
        "53204",
        "53205",
        "53206",
        "53208",
        "53209",
        "53210",
        "53212",
        "53216",
        "53217",
        "53218",
        "53219",
        "53221",
        "53222",
        "53223",
        "53224",
        "53225",
        "53227",
        "53233",
      ],
      "Bay view": ["53207"],
      Shorewood: ["53211"],
      Wauwatosa: ["53213", "53226"],
      "West allis": ["53214"],
      "West milwaukee": ["53215"],
      Greenfield: ["53220", "53228"],
      "Saint francis": ["53235"],
      "Zcta 532hh": ["532HH"],
      Racine: ["53402", "53403", "53404", "53405", "53406"],
      "Zcta 534hh": ["534HH"],
      Albany: ["53502"],
      Arena: ["53503"],
      Argyle: ["53504"],
      Avalon: ["53505"],
      Avoca: ["53506"],
      Barneveld: ["53507"],
      Belleville: ["53508"],
      Belmont: ["53510"],
      Shopiere: ["53511"],
      "Black earth": ["53515"],
      Blanchardville: ["53516"],
      "Blue mounds": ["53517"],
      "Blue river": ["53518"],
      Brodhead: ["53520"],
      Brooklyn: ["53521"],
      Browntown: ["53522"],
      Cambridge: ["53523"],
      Clinton: ["53525"],
      Cobb: ["53526"],
      "Cottage grove": ["53527"],
      "Cross plains": ["53528"],
      Dane: ["53529"],
      Darlington: ["53530"],
      Deerfield: ["53531"],
      "De forest": ["53532"],
      Dodgeville: ["53533"],
      Edgerton: ["53534"],
      Evansville: ["53536"],
      Footville: ["53537"],
      "Fort atkinson": ["53538"],
      Gotham: ["53540"],
      Gratiot: ["53541"],
      Highland: ["53543"],
      Hollandale: ["53544"],
      Janesville: ["53545", "53546"],
      Jefferson: ["53549"],
      Juda: ["53550"],
      "Lake mills": ["53551"],
      Linden: ["53553"],
      Livingston: ["53554"],
      Lodi: ["53555"],
      "Lone rock": ["53556"],
      Lowell: ["53557"],
      "Mc farland": ["53558"],
      Marshall: ["53559"],
      Mazomanie: ["53560"],
      Merrimac: ["53561"],
      Middleton: ["53562"],
      Milton: ["53563"],
      "Mineral point": ["53565"],
      Monroe: ["53566"],
      Montfort: ["53569"],
      Monticello: ["53570"],
      Morrisonville: ["53571"],
      "Mount horeb": ["53572"],
      Muscoda: ["53573"],
      "New glarus": ["53574"],
      Oregon: ["53575"],
      Orfordville: ["53576"],
      Plain: ["53577"],
      "Prairie du sac": ["53578"],
      Reeseville: ["53579"],
      Rewey: ["53580"],
      Gillingham: ["53581"],
      Ridgeway: ["53582"],
      "Sauk city": ["53583"],
      Sharon: ["53585"],
      Shullsburg: ["53586"],
      "South wayne": ["53587"],
      "Spring green": ["53588"],
      Stoughton: ["53589"],
      "Sun prairie": ["53590"],
      Verona: ["53593"],
      Waterloo: ["53594"],
      Waunakee: ["53597"],
      Windsor: ["53598"],
      "Zcta 535hh": ["535HH"],
      Madison: [
        "53703",
        "53704",
        "53705",
        "53706",
        "53711",
        "53714",
        "53715",
        "53717",
        "53718",
        "53719",
      ],
      Fitchburg: ["53713"],
      Monona: ["53716"],
      "Zcta 537hh": ["537HH"],
      Bagley: ["53801"],
      Benton: ["53803"],
      Bloomington: ["53804"],
      Boscobel: ["53805"],
      Cassville: ["53806"],
      "Cuba city": ["53807"],
      Dickeyville: ["53808"],
      Fennimore: ["53809"],
      "Glen haven": ["53810"],
      "Hazel green": ["53811"],
      Lancaster: ["53813"],
      "Mount hope": ["53816"],
      "Patch grove": ["53817"],
      Platteville: ["53818"],
      Potosi: ["53820"],
      "Prairie du chien": ["53821"],
      Stitzer: ["53825"],
      Wauzeka: ["53826"],
      Woodman: ["53827"],
      "Zcta 538hh": ["538HH"],
      Portage: ["53901"],
      Adams: ["53910"],
      Arlington: ["53911"],
      Baraboo: ["53913"],
      "Beaver dam": ["53916"],
      Brandon: ["53919"],
      Briggsville: ["53920"],
      Burnett: ["53922"],
      Cambria: ["53923"],
      Cazenovia: ["53924"],
      Columbus: ["53925"],
      Dalton: ["53926"],
      Doylestown: ["53928"],
      Elroy: ["53929"],
      Endeavor: ["53930"],
      "Fair water": ["53931"],
      "Fall river": ["53932"],
      "Fox lake": ["53933"],
      Friendship: ["53934"],
      Friesland: ["53935"],
      "Grand marsh": ["53936"],
      Hillpoint: ["53937"],
      Kingston: ["53939"],
      "Lake delton": ["53940"],
      "La valle": ["53941"],
      Loganville: ["53943"],
      "Lyndon station": ["53944"],
      Markesan: ["53946"],
      Marquette: ["53947"],
      Mauston: ["53948"],
      Montello: ["53949"],
      "New lisbon": ["53950"],
      "North freedom": ["53951"],
      Oxford: ["53952"],
      Packwaukee: ["53953"],
      Pardeeville: ["53954"],
      Poynette: ["53955"],
      Randolph: ["53956"],
      Reedsburg: ["53959"],
      Rio: ["53960"],
      "Rock springs": ["53961"],
      "Union center": ["53962"],
      Waupun: ["53963"],
      Westfield: ["53964"],
      "Wisconsin dells": ["53965"],
      Wonewoc: ["53968"],
      Wyocena: ["53969"],
      "Zcta 539hh": ["539HH"],
      Deronda: ["54001"],
      Baldwin: ["54002"],
      Beldenville: ["54003"],
      Clayton: ["54004"],
      "Clear lake": ["54005"],
      Cushing: ["54006"],
      "Deer park": ["54007"],
      Dresser: ["54009"],
      Ellsworth: ["54011"],
      Emerald: ["54012"],
      "Glenwood city": ["54013"],
      "Hager city": ["54014"],
      Hammond: ["54015"],
      Hudson: ["54016"],
      "New richmond": ["54017"],
      Osceola: ["54020"],
      Prescott: ["54021"],
      "River falls": ["54022"],
      Roberts: ["54023"],
      "Saint croix fall": ["54024"],
      Somerset: ["54025"],
      "Star prairie": ["54026"],
      Wilson: ["54027"],
      Woodville: ["54028"],
      "Saint joseph": ["54082"],
      "Zcta 540hh": ["540HH"],
      Abrams: ["54101"],
      Amberg: ["54102"],
      "Armstrong creek": ["54103"],
      Athelstane: ["54104"],
      "Center valley": ["54106"],
      Navarino: ["54107"],
      Brillion: ["54110"],
      Cecil: ["54111"],
      Coleman: ["54112"],
      "Combined locks": ["54113"],
      Beaver: ["54114"],
      "De pere": ["54115"],
      Dunbar: ["54119"],
      Fence: ["54120"],
      Florence: ["54121"],
      "Forest junction": ["54123"],
      Gillett: ["54124"],
      Goodman: ["54125"],
      Greenleaf: ["54126"],
      "Green valley": ["54127"],
      Gresham: ["54128"],
      Hilbert: ["54129"],
      Kaukauna: ["54130"],
      Keshena: ["54135"],
      Kimberly: ["54136"],
      Krakow: ["54137"],
      Lakewood: ["54138"],
      Stiles: ["54139"],
      "Little chute": ["54140"],
      "Little suamico": ["54141"],
      Marinette: ["54143"],
      Mountain: ["54149"],
      Neopit: ["54150"],
      Niagara: ["54151"],
      Nichols: ["54152"],
      Oconto: ["54153"],
      "Oconto falls": ["54154"],
      Oneida: ["54155"],
      Pembine: ["54156"],
      Peshtigo: ["54157"],
      Porterfield: ["54159"],
      Potter: ["54160"],
      Pound: ["54161"],
      Pulaski: ["54162"],
      Seymour: ["54165"],
      Shawano: ["54166"],
      Sherwood: ["54169"],
      Shiocton: ["54170"],
      Sobieski: ["54171"],
      Suamico: ["54173"],
      Suring: ["54174"],
      Townsend: ["54175"],
      Wausaukee: ["54177"],
      Wrightstown: ["54180"],
      "Zcta 541hh": ["541HH"],
      "Zcta 541xx": ["541XX"],
      Algoma: ["54201"],
      "Baileys harbor": ["54202"],
      Brussels: ["54204"],
      Casco: ["54205"],
      Collins: ["54207"],
      Denmark: ["54208"],
      "Egg harbor": ["54209"],
      "Ellison bay": ["54210"],
      Ephraim: ["54211"],
      "Fish creek": ["54212"],
      Forestville: ["54213"],
      "Francis creek": ["54214"],
      Kellnersville: ["54215"],
      Kewaunee: ["54216"],
      Luxemburg: ["54217"],
      Manitowoc: ["54220"],
      Maribel: ["54227"],
      Mishicot: ["54228"],
      "New franken": ["54229"],
      Reedsville: ["54230"],
      "Saint nazianz": ["54232"],
      "Sister bay": ["54234"],
      "Sturgeon bay": ["54235"],
      "Tisch mills": ["54240"],
      "Two rivers": ["54241"],
      Valders: ["54245"],
      "Washington islan": ["54246"],
      Whitelaw: ["54247"],
      "Zcta 542hh": ["542HH"],
      Allouez: ["54301"],
      "Green bay": ["54302", "54311", "54313"],
      Howard: ["54303"],
      Ashwaubenon: ["54304"],
      "Zcta 543hh": ["543HH"],
      Wausau: ["54401", "54403"],
      Abbotsford: ["54405"],
      Amherst: ["54406"],
      "Amherst junction": ["54407"],
      Aniwa: ["54408"],
      Antigo: ["54409"],
      Arpin: ["54410"],
      Hamburg: ["54411"],
      Auburndale: ["54412"],
      Babcock: ["54413"],
      Birnamwood: ["54414"],
      Bowler: ["54416"],
      Brokaw: ["54417"],
      Bryant: ["54418"],
      Chili: ["54420"],
      Colby: ["54421"],
      Curtiss: ["54422"],
      Custer: ["54423"],
      Deerbrook: ["54424"],
      Dorchester: ["54425"],
      Fenwood: ["54426"],
      Eland: ["54427"],
      Elcho: ["54428"],
      Elton: ["54430"],
      Gilman: ["54433"],
      Gleason: ["54435"],
      Granton: ["54436"],
      Greenwood: ["54437"],
      Hatley: ["54440"],
      Hewitt: ["54441"],
      Irma: ["54442"],
      "Junction city": ["54443"],
      Loyal: ["54446"],
      Lublin: ["54447"],
      Marathon: ["54448"],
      Marshfield: ["54449"],
      Medford: ["54451"],
      Merrill: ["54452"],
      Milladore: ["54454"],
      Mosinee: ["54455"],
      Neillsville: ["54456"],
      Nekoosa: ["54457"],
      Ogema: ["54459"],
      Owen: ["54460"],
      Pearson: ["54462"],
      "Pelican lake": ["54463"],
      Pickerel: ["54465"],
      Pittsville: ["54466"],
      Plover: ["54467"],
      "Port edwards": ["54469"],
      "Rib lake": ["54470"],
      Ringle: ["54471"],
      Rosholt: ["54473"],
      Rothschild: ["54474"],
      Rudolph: ["54475"],
      Schofield: ["54476"],
      Spencer: ["54479"],
      Stetsonville: ["54480"],
      "Stevens point": ["54481"],
      Stratford: ["54484"],
      "Summit lake": ["54485"],
      Tigerton: ["54486"],
      Tomahawk: ["54487"],
      Unity: ["54488"],
      Vesper: ["54489"],
      Westboro: ["54490"],
      "White lake": ["54491"],
      Willard: ["54493"],
      "Wisconsin rapids": ["54494", "54495"],
      Withee: ["54498"],
      Wittenberg: ["54499"],
      "Zcta 544hh": ["544HH"],
      "Zcta 544xx": ["544XX"],
      Monico: ["54501"],
      Cavour: ["54511"],
      "Boulder junction": ["54512"],
      Brantwood: ["54513"],
      Butternut: ["54514"],
      Catawba: ["54515"],
      "Clam lake": ["54517"],
      Conover: ["54519"],
      Crandon: ["54520"],
      "Eagle river": ["54521"],
      Fifield: ["54524"],
      Gile: ["54525"],
      Ingram: ["54526"],
      Glidden: ["54527"],
      Harshaw: ["54529"],
      Hawkins: ["54530"],
      Hazelhurst: ["54531"],
      Hurley: ["54534"],
      "Iron belt": ["54536"],
      Kennan: ["54537"],
      "Lac du flambeau": ["54538"],
      "Lake tomahawk": ["54539"],
      "Land o lakes": ["54540"],
      Laona: ["54541"],
      Alvin: ["54542"],
      "Mc naughton": ["54543"],
      "Manitowish water": ["54545"],
      Mellen: ["54546"],
      Mercer: ["54547"],
      Minocqua: ["54548"],
      Pence: ["54550"],
      "Park falls": ["54552"],
      Phelps: ["54554"],
      Phillips: ["54555"],
      Prentice: ["54556"],
      Winchester: ["54557"],
      "Saint germain": ["54558"],
      Saxon: ["54559"],
      Sayner: ["54560"],
      Starlake: ["54561"],
      "Three lakes": ["54562"],
      Tony: ["54563"],
      Tripoli: ["54564"],
      Upson: ["54565"],
      Wabeno: ["54566"],
      Woodruff: ["54568"],
      "Zcta 545hh": ["545HH"],
      "Zcta 545xx": ["545XX"],
      "La crosse": ["54601", "54603"],
      Alma: ["54610"],
      "Alma center": ["54611"],
      Arcadia: ["54612"],
      Arkdale: ["54613"],
      Bangor: ["54614"],
      "Black river fall": ["54615"],
      Blair: ["54616"],
      Cutler: ["54618"],
      Cashton: ["54619"],
      Chaseburg: ["54621"],
      Waumandee: ["54622"],
      "Coon valley": ["54623"],
      Victory: ["54624"],
      Dodge: ["54625"],
      Eastman: ["54626"],
      Ettrick: ["54627"],
      Ferryville: ["54628"],
      "Fountain city": ["54629"],
      Galesville: ["54630"],
      "Gays mills": ["54631"],
      Genoa: ["54632"],
      Yuba: ["54634"],
      Northfield: ["54635"],
      Holmen: ["54636"],
      Hustler: ["54637"],
      Kendall: ["54638"],
      "West lima": ["54639"],
      Lynxville: ["54640"],
      Melrose: ["54642"],
      Mindoro: ["54644"],
      "Mount sterling": ["54645"],
      Necedah: ["54646"],
      Norwalk: ["54648"],
      Onalaska: ["54650"],
      Ontario: ["54651"],
      Readstown: ["54652"],
      Rockland: ["54653"],
      Seneca: ["54654"],
      "Soldiers grove": ["54655"],
      Sparta: ["54656"],
      Steuben: ["54657"],
      Stoddard: ["54658"],
      Taylor: ["54659"],
      Wyeville: ["54660"],
      Trempealeau: ["54661"],
      Viola: ["54664"],
      Viroqua: ["54665"],
      Warrens: ["54666"],
      Westby: ["54667"],
      "West salem": ["54669"],
      Wilton: ["54670"],
      "Zcta 546hh": ["546HH"],
      "Zcta 546xx": ["546XX"],
      "Eau claire": ["54701", "54703"],
      Altoona: ["54720"],
      Arkansaw: ["54721"],
      Augusta: ["54722"],
      "Bay city": ["54723"],
      Bloomer: ["54724"],
      Boyceville: ["54725"],
      Boyd: ["54726"],
      Cadott: ["54727"],
      Chetek: ["54728"],
      "Chippewa falls": ["54729"],
      Colfax: ["54730"],
      Conrath: ["54731"],
      Cornell: ["54732"],
      Dallas: ["54733"],
      Downing: ["54734"],
      Durand: ["54736"],
      "Eau galle": ["54737"],
      Eleva: ["54738"],
      "Elk mound": ["54739"],
      Elmwood: ["54740"],
      Fairchild: ["54741"],
      "Fall creek": ["54742"],
      Holcombe: ["54745"],
      Humbird: ["54746"],
      Independence: ["54747"],
      "Jim falls": ["54748"],
      Knapp: ["54749"],
      "Maiden rock": ["54750"],
      Menomonie: ["54751"],
      Merrillan: ["54754"],
      Modena: ["54755"],
      Nelson: ["54756"],
      "New auburn": ["54757"],
      Osseo: ["54758"],
      Pepin: ["54759"],
      "Pigeon falls": ["54760"],
      "Plum city": ["54761"],
      "Prairie farm": ["54762"],
      Ridgeland: ["54763"],
      Sheldon: ["54766"],
      "Spring valley": ["54767"],
      Stanley: ["54768"],
      Stockholm: ["54769"],
      Strum: ["54770"],
      Thorp: ["54771"],
      Wheeler: ["54772"],
      Whitehall: ["54773"],
      "Zcta 547hh": ["547HH"],
      "Zcta 547xx": ["547XX"],
      Spooner: ["54801"],
      Almena: ["54805"],
      Moquah: ["54806"],
      "Balsam lake": ["54810"],
      Barron: ["54812"],
      Barronett: ["54813"],
      Bayfield: ["54814"],
      Birchwood: ["54817"],
      Bruce: ["54819"],
      Brule: ["54820"],
      Cable: ["54821"],
      Cameron: ["54822"],
      Centuria: ["54824"],
      Comstock: ["54826"],
      Cornucopia: ["54827"],
      "New post": ["54828"],
      Cumberland: ["54829"],
      Dairyland: ["54830"],
      Drummond: ["54832"],
      Edgewater: ["54834"],
      Exeland: ["54835"],
      Foxboro: ["54836"],
      "Clam falls": ["54837"],
      Gordon: ["54838"],
      "Grand view": ["54839"],
      Evergreen: ["54840"],
      Haugen: ["54841"],
      Hawthorne: ["54842"],
      "North woods beac": ["54843"],
      Herbster: ["54844"],
      Hertel: ["54845"],
      "High bridge": ["54846"],
      "Iron river": ["54847"],
      Ladysmith: ["54848"],
      "Lake nebagamon": ["54849"],
      "La pointe": ["54850"],
      Luck: ["54853"],
      Maple: ["54854"],
      Marengo: ["54855"],
      Delta: ["54856"],
      Mikana: ["54857"],
      Milltown: ["54858"],
      Minong: ["54859"],
      Odanah: ["54861"],
      Ojibwa: ["54862"],
      Poplar: ["54864"],
      "Port wing": ["54865"],
      Radisson: ["54867"],
      Canton: ["54868"],
      Sarona: ["54870"],
      "Shell lake": ["54871"],
      Siren: ["54872"],
      Barnes: ["54873"],
      Wentworth: ["54874"],
      Earl: ["54875"],
      "Stone lake": ["54876"],
      Superior: ["54880"],
      Trego: ["54888"],
      "Turtle lake": ["54889"],
      Washburn: ["54891"],
      Webster: ["54893"],
      Weyerhaeuser: ["54895"],
      Loretta: ["54896"],
      "Zcta 548hh": ["548HH"],
      "Zcta 548xx": ["548XX"],
      Oshkosh: ["54901", "54902", "54904"],
      Almond: ["54909"],
      Appleton: ["54911", "54913", "54914", "54915"],
      Bancroft: ["54921"],
      "Bear creek": ["54922"],
      Berlin: ["54923"],
      "Butte des morts": ["54927"],
      Caroline: ["54928"],
      Clintonville: ["54929"],
      Coloma: ["54930"],
      Eldorado: ["54932"],
      Embarrass: ["54933"],
      Eureka: ["54934"],
      Taycheedah: ["54935"],
      "North fond du la": ["54937"],
      Fremont: ["54940"],
      "Green lake": ["54941"],
      Greenville: ["54942"],
      Hancock: ["54943"],
      Hortonville: ["54944"],
      Iola: ["54945"],
      Larsen: ["54947"],
      Leopolis: ["54948"],
      Manawa: ["54949"],
      Marion: ["54950"],
      Menasha: ["54952"],
      Neenah: ["54956"],
      Neshkoro: ["54960"],
      "New london": ["54961"],
      Ogdensburg: ["54962"],
      Omro: ["54963"],
      Pickett: ["54964"],
      "Pine river": ["54965"],
      Plainfield: ["54966"],
      "Poy sippi": ["54967"],
      Princeton: ["54968"],
      Redgranite: ["54970"],
      Ripon: ["54971"],
      Rosendale: ["54974"],
      Saxeville: ["54976"],
      Scandinavia: ["54977"],
      Tilleda: ["54978"],
      "Van dyne": ["54979"],
      Waukau: ["54980"],
      Waupaca: ["54981"],
      Wautoma: ["54982"],
      Weyauwega: ["54983"],
      "Wild rose": ["54984"],
      Winnebago: ["54985"],
      Winneconne: ["54986"],
      "Zcta 549hh": ["549HH"],
    },
  },
  {
    abbrevation: "WY",
    name: "Wyoming",
    Cities: [
      "Cheyenne",
      "Cheyenne",
      "Albin",
      "Laramie",
      "Buford",
      "Burns",
      "Carpenter",
      "Centennial",
      "Garrett",
      "Granite canon",
      "Hillsdale",
      "Horse creek",
      "Jelm",
      "Zcta 82072",
      "Meriden",
      "Pine bluffs",
      "Rock river",
      "Tie siding",
      "Fishing bridge",
      "Wheatland",
      "Chugwater",
      "Fort laramie",
      "Glendo",
      "Guernsey",
      "Hartville",
      "Hawk springs",
      "Huntley",
      "Jay em",
      "Lagrange",
      "Lance creek",
      "Lingle",
      "Lost springs",
      "Lusk",
      "Manville",
      "Torrington",
      "Van tassell",
      "Veteran",
      "Yoder",
      "Zcta 822hh",
      "Rawlins",
      "Jeffrey city",
      "Baggs",
      "Bairoil",
      "Dixon",
      "Elk mountain",
      "Encampment",
      "Hanna",
      "Medicine bow",
      "Ryan park",
      "Savery",
      "Walcott",
      "Wamsutter",
      "Zcta 823hh",
      "Worland",
      "Basin",
      "Burlington",
      "Byron",
      "Cody",
      "Cowley",
      "Deaver",
      "Emblem",
      "Frannie",
      "Greybull",
      "Hyattville",
      "Kirby",
      "Lovell",
      "Manderson",
      "Meeteetse",
      "Otto",
      "Powell",
      "Ralston",
      "Shell",
      "Ten sleep",
      "Grass creek",
      "Zcta 824hh",
      "Zcta 824xx",
      "Gas hills",
      "Arapahoe",
      "Crowheart",
      "Dubois",
      "Fort washakie",
      "Hudson",
      "Kinnear",
      "Ethete",
      "Pavillion",
      "Zcta 825hh",
      "Casper",
      "Alcova",
      "Douglas",
      "Edgerton",
      "Evansville",
      "Glenrock",
      "Kaycee",
      "Linch",
      "Lysite",
      "Midwest",
      "Mills",
      "Shoshoni",
      "Zcta 826hh",
      "Newcastle",
      "Aladdin",
      "Alva",
      "Beulah",
      "Devils tower",
      "Gillette",
      "Hulett",
      "Pine haven",
      "Osage",
      "Recluse",
      "Rozet",
      "Sundance",
      "Upton",
      "Wright",
      "Sheridan",
      "Arvada",
      "Banner",
      "Big horn",
      "Buffalo",
      "Clearmont",
      "Dayton",
      "Leiter",
      "Parkman",
      "Acme",
      "Story",
      "Ranchester",
      "Wyarno",
      "Zcta 828hh",
      "Rock springs",
      "Bondurant",
      "Boulder",
      "Cora",
      "Little america",
      "Evanston",
      "Farson",
      "Fort bridger",
      "Granger",
      "Green river",
      "Lonetree",
      "Lyman",
      "Mc kinnon",
      "Mountain view",
      "Pinedale",
      "Point of rocks",
      "Reliance",
      "Robertson",
      "Superior",
      "Zcta 829hh",
      "Zcta 829xx",
      "Colter bay",
      "Kelly",
      "Moose",
      "Moran",
      "Wilson",
      "Zcta 830hh",
      "Zcta 830xx",
      "Kemmerer",
      "Afton",
      "Auburn",
      "Bedford",
      "Marbleton",
      "Cokeville",
      "Daniel",
      "Diamondville",
      "Etna",
      "Fairview",
      "Freedom",
      "Frontier",
      "Grover",
      "La barge",
      "Opal",
      "Smoot",
      "Thayne",
      "Alpine",
      "Zcta 831hh",
      "Zcta 831xx",
    ],
    ZipCodes: {
      Cheyenne: ["82001", "82007", "82009"],
      Albin: ["82050"],
      Laramie: ["82051", "82070"],
      Buford: ["82052"],
      Burns: ["82053"],
      Carpenter: ["82054"],
      Centennial: ["82055"],
      Garrett: ["82058"],
      "Granite canon": ["82059"],
      Hillsdale: ["82060"],
      "Horse creek": ["82061"],
      Jelm: ["82063"],
      "Zcta 82072": ["82072"],
      Meriden: ["82081"],
      "Pine bluffs": ["82082"],
      "Rock river": ["82083"],
      "Tie siding": ["82084"],
      "Fishing bridge": ["82190"],
      Wheatland: ["82201"],
      Chugwater: ["82210"],
      "Fort laramie": ["82212"],
      Glendo: ["82213"],
      Guernsey: ["82214"],
      Hartville: ["82215"],
      "Hawk springs": ["82217"],
      Huntley: ["82218"],
      "Jay em": ["82219"],
      Lagrange: ["82221"],
      "Lance creek": ["82222"],
      Lingle: ["82223"],
      "Lost springs": ["82224"],
      Lusk: ["82225"],
      Manville: ["82227"],
      Torrington: ["82240"],
      "Van tassell": ["82242"],
      Veteran: ["82243"],
      Yoder: ["82244"],
      "Zcta 822hh": ["822HH"],
      Rawlins: ["82301"],
      "Jeffrey city": ["82310"],
      Baggs: ["82321"],
      Bairoil: ["82322"],
      Dixon: ["82323"],
      "Elk mountain": ["82324"],
      Encampment: ["82325"],
      Hanna: ["82327"],
      "Medicine bow": ["82329"],
      "Ryan park": ["82331"],
      Savery: ["82332"],
      Walcott: ["82335"],
      Wamsutter: ["82336"],
      "Zcta 823hh": ["823HH"],
      Worland: ["82401"],
      Basin: ["82410"],
      Burlington: ["82411"],
      Byron: ["82412"],
      Cody: ["82414"],
      Cowley: ["82420"],
      Deaver: ["82421"],
      Emblem: ["82422"],
      Frannie: ["82423"],
      Greybull: ["82426"],
      Hyattville: ["82428"],
      Kirby: ["82430"],
      Lovell: ["82431"],
      Manderson: ["82432"],
      Meeteetse: ["82433"],
      Otto: ["82434"],
      Powell: ["82435"],
      Ralston: ["82440"],
      Shell: ["82441"],
      "Ten sleep": ["82442"],
      "Grass creek": ["82443"],
      "Zcta 824hh": ["824HH"],
      "Zcta 824xx": ["824XX"],
      "Gas hills": ["82501"],
      Arapahoe: ["82510"],
      Crowheart: ["82512"],
      Dubois: ["82513"],
      "Fort washakie": ["82514"],
      Hudson: ["82515"],
      Kinnear: ["82516"],
      Ethete: ["82520"],
      Pavillion: ["82523"],
      "Zcta 825hh": ["825HH"],
      Casper: ["82601", "82604", "82609"],
      Alcova: ["82620"],
      Douglas: ["82633"],
      Edgerton: ["82635"],
      Evansville: ["82636"],
      Glenrock: ["82637"],
      Kaycee: ["82639"],
      Linch: ["82640"],
      Lysite: ["82642"],
      Midwest: ["82643"],
      Mills: ["82644"],
      Shoshoni: ["82649"],
      "Zcta 826hh": ["826HH"],
      Newcastle: ["82701"],
      Aladdin: ["82710"],
      Alva: ["82711"],
      Beulah: ["82712"],
      "Devils tower": ["82714"],
      Gillette: ["82716", "82717", "82718", "82731"],
      Hulett: ["82720"],
      "Pine haven": ["82721"],
      Osage: ["82723"],
      Recluse: ["82725"],
      Rozet: ["82727"],
      Sundance: ["82729"],
      Upton: ["82730"],
      Wright: ["82732"],
      Sheridan: ["82801"],
      Arvada: ["82831"],
      Banner: ["82832"],
      "Big horn": ["82833"],
      Buffalo: ["82834"],
      Clearmont: ["82835"],
      Dayton: ["82836"],
      Leiter: ["82837"],
      Parkman: ["82838"],
      Acme: ["82839"],
      Story: ["82842"],
      Ranchester: ["82844"],
      Wyarno: ["82845"],
      "Zcta 828hh": ["828HH"],
      "Rock springs": ["82901"],
      Bondurant: ["82922"],
      Boulder: ["82923"],
      Cora: ["82925"],
      "Little america": ["82929"],
      Evanston: ["82930"],
      Farson: ["82932"],
      "Fort bridger": ["82933"],
      Granger: ["82934"],
      "Green river": ["82935"],
      Lonetree: ["82936"],
      Lyman: ["82937"],
      "Mc kinnon": ["82938"],
      "Mountain view": ["82939"],
      Pinedale: ["82941"],
      "Point of rocks": ["82942"],
      Reliance: ["82943"],
      Robertson: ["82944"],
      Superior: ["82945"],
      "Zcta 829hh": ["829HH"],
      "Zcta 829xx": ["829XX"],
      "Colter bay": ["83001"],
      Kelly: ["83011"],
      Moose: ["83012"],
      Moran: ["83013"],
      Wilson: ["83014"],
      "Zcta 830hh": ["830HH"],
      "Zcta 830xx": ["830XX"],
      Kemmerer: ["83101"],
      Afton: ["83110"],
      Auburn: ["83111"],
      Bedford: ["83112"],
      Marbleton: ["83113"],
      Cokeville: ["83114"],
      Daniel: ["83115"],
      Diamondville: ["83116"],
      Etna: ["83118"],
      Fairview: ["83119"],
      Freedom: ["83120"],
      Frontier: ["83121"],
      Grover: ["83122"],
      "La barge": ["83123"],
      Opal: ["83124"],
      Smoot: ["83126"],
      Thayne: ["83127"],
      Alpine: ["83128"],
      "Zcta 831hh": ["831HH"],
      "Zcta 831xx": ["831XX"],
    },
  },
];

export const addZipCodeKeysToCities = (statesData) => {
  statesData.forEach((state) => {
    const zipCodeKeys = Object.keys(state.ZipCodes);
    state.Cities = state.Cities.concat(zipCodeKeys);
  });
  return statesData;
};

// const modifiedData = addZipCodeKeysToCities(statesData);
// console.log(modifiedData, 99);
