import axios from "axios";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import "./AffterPaymnet.scss";
import { STRIPE_AFTER_PAYMNET } from "../../../API/ApiConstants";
import { Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AuthenticationAction } from "../../../Redux/Actions/Action";
function AfterPaymnet() {
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {}, [30000]);
    const callAPi = async () => {
      try {
        let res = await axios.post(`${STRIPE_AFTER_PAYMNET}${id}`);
        if (res.status === 200) {
          let snackData = {
            severity: "success",
            message:
              "Your account has been successfully credited with the specified amount.",
            open: true,
          };

          dispatch(AuthenticationAction.setSnackBarValues(snackData));

          navigate("/dashboard");
        } else {
          // Handle non-200 status codes if needed

          let errorSnackData = {
            severity: "error",
            message: "Error crediting your account. Please try again.",
            open: true,
          };
          navigate("/pricing");
          dispatch(AuthenticationAction.setSnackBarValues(errorSnackData));
        }
      } catch (error) {
        let errorSnackData = {
          severity: "error",
          message:
            "Your session id has expired, or an unexpected error occurred.",
          open: true,
        };

        dispatch(AuthenticationAction.setSnackBarValues(errorSnackData));
        navigate("/pricing");
      }
    };
    callAPi();
  }, [id]);
  return (
    <div className='after_paymnet'>
      <Paper className='after_paymnet_paper' elevation={3}></Paper>
    </div>
  );
}

export default AfterPaymnet;
