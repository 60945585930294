import React, { useEffect, useState } from 'react';
import './InfoDialog.scss';

import Button from '@mui/material/Button';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import SocialAccounts from '../SocialAccouts/SocialAccounts';
import { useNavigate } from 'react-router';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_API } from '../../../API/ApiConstants';
import { useAuthUser } from 'react-auth-kit';
import { useIsAuthenticated } from 'react-auth-kit';
import { StripeIcon } from '../../../Asset/Icons/Icons';
import { TextField } from '@mui/material';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function InfoDialog({
  Butttontitle,
  plan,
  planDetails,
  desc0,
  totalCost,

  price,
}) {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [payAsGo, setPayAsGo] = useState(false);
  const [error, setErrors] = useState('');
  const [stripeCost, setStripeCost] = useState(null);
  const isAuthenticated = useIsAuthenticated();
  const [open, setOpen] = useState(false);
  const auth = useAuthUser();
  const handleClickOpen = () => {
    if (isAuthenticated()) {
      setOpen(true);
    } else {
      navigate('/login');
    }
  };

  const handleClose = () => {
    setOpen(false);
    setErrors('');
  };

  const handleStripePayment = async () => {
    if (payAsGo) {
      if (stripeCost < 25 || stripeCost > 3999) {
        setErrors('Please ensure that the amount falls within the range of 25 to 3999.');
        return;
      }
    }

    const data = { plan: plan, amount: stripeCost, userId: auth().userId };
    const stripe = await loadStripe(`${process.env.REACT_APP_Publish_Stripe_KEY}`);

    const headers = {
      'Content-Type': 'application/json',
    };

    const fetchData = async () => {
      setLoader(true);

      try {
        const response = await axios.post(STRIPE_API, data, {
          headers: headers,
        });

        const session = response.data;

        const result = await stripe.redirectToCheckout({
          sessionId: session,
        });

        if (result.error) {
          console.log('Error during Checkout redirect:', result.error);
        } else if (result.complete) {
          console.log('Payment was successful!');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoader(false);
    };

    fetchData();
  };

  useEffect(() => {
    if (price === '0.02') {
      setStripeCost(9999);
    } else if (price === '0.03') {
      setStripeCost(7500);
    } else if (price === '0.04') {
      setStripeCost(4000);
    } else if (price === '0.05') {
      setStripeCost(100);
      setPayAsGo(true);
    } else {
      setStripeCost(100);
    }
    return () => {
      setPayAsGo(false);
      setStripeCost(null);
      setErrors('');
    };
  }, [price]);

  return (
    <div className="info_dialong_container">
      <Button className="btn btn--white" variant="outlined" onClick={() => handleClickOpen()}>
        {Butttontitle}
      </Button>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {plan}
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>{desc0}</Typography>
          {planDetails.length > 0 ? <h4>Plan Details :</h4> : <></>}
          <Typography gutterBottom>
            {planDetails.map((data, index) => {
              return <p key={index}>{data}</p>;
            })}
            {totalCost.length > 0 ? <p>{totalCost}</p> : <></>}
          </Typography>
          {payAsGo ? (
            <TextField
              error={error.length > 0}
              defaultValue={100}
              id="outlined-basic"
              label="Add a amount $"
              variant="outlined"
              type="number"
              helperText={error}
              required={true}
              onChange={(e) => setStripeCost(e.target.value)}
            />
          ) : (
            <></>
          )}
          <Typography gutterBottom className="icons_container">
            <h4>
              For any other queries or to contact us, please reach out through the following
              platforms.
            </h4>
            <SocialAccounts />
          </Typography>
        </DialogContent>
        <DialogActions>
          <div className="payment_button">
            <Button
              startIcon={StripeIcon}
              disabled={loader}
              className="highlighted"
              variant="contained"
              color="success"
              // onClick={() => handleFastSpringPayment()}
            >
              {loader ? <CircularProgress color="warning" size="2rem" /> : 'Make a payment'}
            </Button>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
