// src/components/HomePage.js
import React from 'react';
import './HomePage.scss';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from 'react-auth-kit';
import HomeCard from '../../Atom/Card/HomeCard';
import servicePic from '../../../Asset/img/service.jpg';
import {
  Fingerprint,
  Star,
  Sync,
  Presentation,
  Rocket,
  Peoples,
  RightCircle,
  ArrowRight,
} from '../../../Asset/Icons/Icons';
import IconCircle from '../../Atom/IconCircle/IconCircle';
import { Paper } from '@mui/material';

import Builder from '../../Atom/Builder/Builder';
import ContactCard from '../../Molecule/ContactCard/ContactCard';
import CategoriesSection from '../../Molecule/CategoriesSection/CategoriesSection';
const why_to_data = [
  'Quick signup and premium support',
  'Self-Service processing',
  'No contracts',
  'Competitive volume-based pricing',
  'Quick & Flexible Delivery',
  'Your Customer data is never shared or sold',
];
const precision_data = [
  { percentage: '100%', title: 'Property Records' },
  { percentage: '97%', title: 'Deeds/Sales' },
  { percentage: '98%', title: 'Mortgages' },
  { percentage: '69%', title: 'Pre-Foreclosures' },
];
const cardContent = [
  {
    color: 'blue',
    icon: Star,
    title: 'Accuracy',
    description:
      "We're committed to delivering 100% accurate, up-to-date information Our commitment is your assurance - trust us for the precision, integrity, and confidence you need to succeed in your data endeavors.",
  },
  {
    color: 'red',
    icon: Sync,
    title: 'Reliability',
    description:
      "Our API manages your data history, delivering fresh and unique records every time while maintaining your privacy. We don't store your history; instead, we create a secure link for your exclusive access, anytime, anywhere.",
  },
  {
    color: 'green',
    icon: Fingerprint,
    title: 'Ethic',
    description:
      'Providing You with Accurate Data at Your Fingertips, Sourced Responsibly and Transparently. Our Commitment to Ethical Data Practices Ensures Trust and Integrity in Every Interaction.',
  },
];
const builder_data = [
  {
    icon: Star,
    title: 'Nationwide Report Coverage',
    description:
      'Enhance your market research with pinpoint accuracy using our geo-filter functionality. Customize your data analysis to specific regions, guaranteeing you receive the most pertinent and focused insights tailored to your business requirements.',
  },
  {
    icon: Presentation,
    title: 'Multi Sourced Reports',
    description:
      'Harness comprehensive insights from multiple data sources. Our approach integrates a network of over 5000 publishers, ensuring robust and up-to-date public record title data. Augmented with AI precision, we maintain superior currency and accuracy in every dataset.',
  },
  {
    icon: 'Rocket',
    title: 'Flexible Reports Delivery',
    description:
      'Experience unmatched flexibility in report delivery options. Whether you need API integration, bulk downloads, or customized lists, we ensure seamless access to comprehensive market research reports. Get the insights you need, delivered in the format that best suits your business needs.',
  },
];
const linkDataCategory = [
  'datacategories/9C4D5E3C-43F4-46AB-B39D-8BC75B8A6784',
  'datacategories/1F8EE987-2F54-4E6D-9F0C-2B0E6DCEE1E3',
  'datacategories/D890BF7A-6A85-4BBD-A9F4-0A913E67E989',
  'datacategories/6D7A5E38-3D7F-4A8D-8462-9FB4852C2EBC',
  'datacategories/FDB7260E-DCAB-41D9-ACBE-525A7216DD2F',
  'datacategories/YUA7260E-D2AB-41C9-AEBE-525A7216CC67',
];
const HomePage = () => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const userAuth = isAuthenticated();

  const handleNavigation = () => {
    navigate('/latest_Reports');
    // if (userAuth) {
    //   navigate('/viewer');
    // } else {
    //   navigate('/sign up');
    // }
  };
  // useEffect(() => {
  //   navigate("/pricing");
  // }, []);
  return (
    <div className="homepage">
      <section className="hero">
        <div className="hero-content">
          <h1>Strong Matches The Strategic Gap With Revenue Impacting Solutions</h1>
          <h4>INTERCONNECTED SEGMENTS ADJACENCIES IN MARKET ECOSYSTEMS</h4>

          <p>
            An independent global market research company providing business prospects & strategy
            research services for the consumer markets. An independent global market research
            company providing business prospects & strategy research services for the consumer
            markets. An independent global market research company providing business prospects &
            strategy research services for the consumer markets.
          </p>
          <p>
            Welcome to the dynamic world of market research! In an era where data drives decisions,
            understanding your market is essential. Market research serves as the compass guiding
            businesses through the complexities of consumer preferences, industry trends, and
            competitive landscapes. At its core, it empowers organizations to uncover actionable
            insights, optimize strategies, and ultimately, achieve sustainable growth. Explore the
            transformative power of market research with us as we navigate the currents of consumer
            behavior and market dynamics together.
          </p>

          <Paper onClick={handleNavigation} elevation={3} className="free_trial_paper">
            <p>Latest Reports</p>
            {ArrowRight}
          </Paper>
        </div>
        <div className="empty_div"></div>
      </section>
      <srction className="categories_section_home">
        <h1>Industries</h1>
        <h3>Navigating Success with Data Your Industry Pacesetter</h3>
        <CategoriesSection linksData={linkDataCategory} />
      </srction>
      <section className="card_section">
        {cardContent.map((data, index) => {
          return (
            <HomeCard
              key={index}
              icon={data.icon}
              description={data.description}
              title={data.title}
              color={data.color}
            />
          );
        })}
      </section>
      <section className="featured">
        <div className="content">
          <IconCircle icon={Peoples} />
          <h2>Working with us is your success</h2>

          <p>
            We are highly impressed with the outstanding service provided by TechSolutions Ltd. and
            wholeheartedly recommend them to industry peers. Engaging TechSolutions for a thorough
            IT infrastructure audit was crucial for optimizing our systems and ensuring future
            scalability. Their team's expertise and meticulous approach exceeded our expectations,
            offering tailored solutions that precisely addressed our business challenges.
            TechSolutions' proactive stance and attention to detail throughout the process showcased
            their commitment to delivering exceptional results. Thanks to their insights, we're now
            implementing strategic improvements that promise significant operational efficiencies
            and enhanced client satisfaction. TechSolutions has proven to be a trusted partner, and
            we eagerly anticipate continuing our productive collaboration with them.
          </p>
        </div>

        <div className="image_container">
          <Paper elevation={5} className="paper">
            <img src={servicePic} alt="team" />
            <h3>Top Notch Services</h3>
            <p>
              Dara box market research attempts to ensure the accuracy of information on the service
              guarantee that search results or site content are entirely correct, current, or
              complete.
            </p>
          </Paper>
        </div>
      </section>
      {/* <section className='pricing_container'>
        <Paper className='paper' elevation={3}>
          <h1 onClick={handleNavigation}>Get Started</h1>
          <p>
            Join for free,Get some free searches,then pay as you go.No minimum ,
            no subscription{" "}
          </p>
        </Paper>
      </section> */}

      <section className="precision_section_main">
        <h1>Data, Upgraded Amplify Your Reach with Targeted Precision</h1>
        <div className="precision_section">
          {precision_data.map((data, index) => {
            return (
              <div key={index} className="content_precision">
                <h2>{data.percentage}</h2>
                <h3>{data.title}</h3>
              </div>
            );
          })}
        </div>
      </section>

      <section className="main_why_to_use">
        <h1>WHY CHOOSE US ?</h1>{' '}
        <section className="why_to_container">
          {why_to_data.map((data, index) => {
            return (
              <Paper key={index} className="paper_card" elevation={2}>
                {RightCircle}
                <h2>{data}</h2>
              </Paper>
            );
          })}
        </section>
      </section>

      <section className="builder_main_container">
        <div className="upper_container">
          <h1>Build something</h1>
          <p>
            Join us on a journey of transforming information into opportunity, and explore the
            limitless potential that data-driven strategies can bring to your organization .
          </p>
        </div>
        <div className="lower_container">
          {builder_data.map((data, index) => {
            return (
              <Builder
                key={index}
                title={data.title}
                description={data.description}
                icon={data.icon}
              />
            );
          })}
        </div>
        <ContactCard />
      </section>
    </div>
  );
};

export default HomePage;
