import {
  Signal,
  Dollar,
  Document,
  BiDollarIcon,
  ReasearchIcon,
  DataTransferIcon,
  DataPoint,
  DataLakeIcon,
  DataComplianceIcon,
  SignIn,
  LogIn,
  DashBoardIcon,
  HomeIcon,
  ToolIcon,
  BuildingIcon,
  CertificateIcon,
  WorkerIcon,
  GraphIcon,
  VentilatorHeart,
  DesktopIcon,
  ForkIcon,
  ChemicalIcon,
  SemiConductor,
  TruckLogo,
  BoxIcon,
  ShoppingBasket,
  WheatIcon,
  RobotIcon,
  IndustryLogo,
} from '../Asset/Icons/Icons';
export const pricing_data = [
  {
    title: 'Pay As You Go',
    subtitles: [
      '3 day tours',
      'Up to 30 people',
      '2 tour guides',
      'Sleep in cozy hotels',
      'Difficulty: easy',
    ],
    price: '$0.10',
    buttonTitle: 'Add Funds',
  },
  {
    title: 'Standard',
    subtitles: [
      '3 day tours',
      'Up to 30 people',
      '2 tour guides',
      'Sleep in cozy hotels',
      'Difficulty: easy',
    ],
    price: '$0.8',
    buttonTitle: 'Upgrade',
  },
  {
    title: 'Professional',
    subtitles: [
      '3 day tours',
      'Up to 30 people',
      '2 tour guides',
      'Sleep in cozy hotels',
      'Difficulty: easy',
    ],
    price: '$0.6',
    buttonTitle: 'Upgrade',
  },
  {
    title: 'Enterprices',
    subtitles: [
      '3 day tours',
      'Up to 30 people',
      '2 tour guides',
      'Sleep in cozy hotels',
      'Difficulty: easy',
    ],
    price: '$0.4',
    buttonTitle: 'Upgrade',
  },
];

export const data_points = [
  { column: 'First Name', type: 'string' },
  { column: 'Last Name', type: 'string' },

  { column: 'Full Name', type: 'string' },
  { column: 'Age', type: 'number' },
  { column: 'Phone Number', type: 'string' },
  { column: 'Phone Line Type', type: 'string' },
  { column: 'Street', type: 'string' },
  { column: 'City', type: 'string' },
  { column: 'State', type: 'string' },
  { column: 'PostalCode', type: 'string' },
  { column: 'Email Addresses', type: 'string' },
  { column: 'Bathrooms', type: 'number' },
  { column: 'Year Built', type: 'number' },
  { column: 'Bedrooms', type: 'number' },
  { column: 'Square Feet', type: 'string' },
  { column: 'Estimated Value', type: 'string' },
  { column: 'Estimated Equity', type: 'string' },
  { column: 'Last Sale Amount', type: 'string' },
  { column: 'Last Sale Date', type: 'string' },
  { column: 'Occupancy Type', type: 'string' },
  { column: 'Ownership Type', type: 'string' },
  { column: 'Land Use', type: 'string' },
  { column: 'Property Class', type: 'string' },
  { column: 'Subdivision', type: 'string' },
  { column: 'Lot Square Feet', type: 'string' },
  { column: 'APN', type: 'string' },
];
export const why_data = [
  {
    title: 'Accuracy: ',
    description:
      'Our system ensures that you always have the most accurate and up-to-date information at your fingertips.',
  },
  {
    title: 'Efficiency: ',
    description:
      'You get the data you need without sifting through duplicates, saving you time and resources.',
  },
  {
    title: 'Confidence: ',
    description:
      'With our data management system, you can trust the reliability of the information you receive.',
  },
];
export const works_data = [
  {
    title: 'User-Specific Index: ',
    description:
      'When you make your first purchase, we assign you a unique identifier. This identifier is your passport to accessing data seamlessly in the future.',
  },
  {
    title: 'Storing Data with Unique IDs: ',
    description:
      'Every data record we receive from our sources is associated with a unique identifier. This identifier combines your user-specific index with a record-specific ID or timestamp.',
  },
  {
    title: 'Handling Duplicate Data: ',
    description:
      'When you return to buy more data, simply provide your user-specific index in the API request. We check our database to determine the last unique identifier or timestamp associated with your account.',
  },
  {
    title: 'No Duplicate Data: ',
    description:
      'With this information, we ensure that you receive only the new data records, those with identifiers or timestamps greater than the last one you received. This means you never have to worry about getting duplicate data.',
  },
];

export const drop_down_data_login = [
  { title: 'Dashboard', link: '/dashboard', icon: DashBoardIcon },
  { title: 'API', link: '/research_methodology', icon: Signal },
  { title: 'Billing', link: '/pricing', icon: BiDollarIcon },
  { title: 'Data Viewer', link: '/viewer', icon: Document },
];
export const drop_down_data = [
  { title: 'Dashboard', link: '/', icon: DashBoardIcon },
  { title: 'Pricing', link: '/pricing', icon: BiDollarIcon },
  { title: 'Research Methodology', link: '/research_methodology', icon: ReasearchIcon },
  {
    title: 'Latest Reports',
    link: '/latest_Reports',
    icon: Document,
  },
];
export const app_bar_menu = [
  // { title: "Data Transparency", link: "/datatransparency", icon: Dollar },
  { title: 'Pricing', link: '/pricing', icon: BiDollarIcon },
  { title: 'Research Methodology', link: '/research_methodology', icon: ReasearchIcon },
  {
    title: 'Latest Reports',
    link: '/latest_Reports',
    icon: Document,
  },
];

export const app_bar_menu_login = [
  // { title: "Data Transparency", link: "/datatransparency", icon: Dollar },
  { title: 'Research Methodology', link: '/research_methodology', icon: Signal },
  { title: 'Billing', link: '/pricing', icon: BiDollarIcon },
  { title: 'Data Viewer', link: '/viewer', icon: Document },
];

export const Terms_Page_Data = [
  {
    title: 'Definitions:',
    description: [
      'DATA API CONNECT refers to Data API Connect, Inc., a New York corporation that operates the website and API known as DataApiConnect.com. It may also be referred to in these Terms as the Site or Service or DataApiConnect.com.',
      'User refers to any person or organization using the website DataApiConnect.com, or its API, regardless of if during a free trial or paid usage, regardless of mode of data delivery including but not limited to API delivery, Site data viewer, or third-party data distribution platform.',
    ],
  },
  {
    title: 'Business API Users Only:',
    description: [
      'DataApiConnect.com is a service for business API users only and is not intended for use by the general public or any non-business entity. DATA API CONNECT reserves the right to terminate any User account if it is discovered the User is not a business API user.',
    ],
  },
  {
    title: 'No Warranties Exist:',
    description: [
      "USE OF THIS SITE IS AT USER'S SOLE RISK. ALL CONTENT AND SERVICES ARE PROVIDED AS IS, WITH NO WARRANTIES OR GUARANTEES WHATSOEVER. DATA API CONNECT EXPRESSLY DISCLAIMS TO THE FULLEST EXTENT PERMITTED BY LAW ALL EXPRESS, IMPLIED, STATUTORY, AND OTHER WARRANTIES, GUARANTEES, OR REPRESENTATIONS, INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY AND INTELLECTUAL PROPERTY RIGHTS.",
      "WITHOUT LIMITATION, DATA API CONNECT MAKES NO WARRANTY OR GUARANTEE THAT THIS SITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE. USER UNDERSTANDS AND AGREES THAT ACCESSING THE SERVICE IS AT THE USER’S OWN RISK AND DISCRETION AND THAT THE USER IS SOLELY RESPONSIBLE FOR ANY DAMAGES THAT MAY RESULT, INCLUDING CLAIMS BY THIRD PARTIES ARISING OUT OF USER'S USE OF THE SERVICE.",
    ],
  },
  {
    title: 'Liability:',
    description: [
      'IN NO EVENT WILL DATA API CONNECT OR ITS DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PERSON FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING FOR ANY LOST PROFITS OR LOST DATA ARISING FROM YOUR USE OF THE SITE. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, THE SITE’S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO THE SITE FOR THE SERVICE, BUT IN NO CASE WILL THE SITE’S LIABILITY TO YOU EXCEED $100',
      'IN NO EVENT WILL DATA API CONNECT OR ITS DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PERSON FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING FOR ANY LOST PROFITS OR LOST DATA ARISING FROM YOUR USE OF THE SITE. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, THE SITE’S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO THE SITE FOR THE SERVICE, BUT IN NO CASE WILL THE SITE’S LIABILITY TO YOU EXCEED $100',
    ],
  },
  {
    title: 'Indemnity:',
    description: [
      "USER FURTHER AGREES TO HOLD HARMLESS, DEFEND AND INDEMNIFY DATA API CONNECT, AND ITS EMPLOYEES, SUBSIDIARIES, AGENTS, AND REPRESENTATIVES, FROM AND AGAINST ANY LIABILITY ARISING FROM OR IN ANY WAY RELATED TO YOUR USE OF THE SITE OR PROVISION OF SERVICES, INCLUDING ANY LIABILITY OR EXPENSE ARISING FROM ALL CLAIMS, LOSSES, DAMAGES (ACTUAL AND CONSEQUENTIAL), SUITS, JUDGMENTS, LITIGATION COSTS, AND ATTORNEYS' FEES, OF EVERY KIND AND NATURE, INCLUDING THIRD PARTY CLAIMS, KNOWN AND UNKNOWN, FORESEEABLE AND UNFORESEEABLE, DISCLOSED AND UNDISCLOSED.",
    ],
  },
  {
    title: 'Fulfillment Policy:',
    description: [
      'Refund policy: A Subscriber may request a refund at any time of its unused funds by requesting to do so using the site contact form.',
      'Delivery policy: All data is delivered via our secure API. We do not ship physical products.',
      'Return policy: We do not ship physical products. All of our products are data that is delivered by our API. Sorry, we have a non-return policy on our data products once delivered.',
      'Cancellation policy: A Subscriber may cancel service at any time without penalty by logging in to his account, navigating to My Account, then scrolling down to Cancel Account.',
    ],
  },
  {
    title: 'Legal Advice:',
    description: [
      'No information appearing on DataApiConnect.com shall be construed as legal advice being given by DATA API CONNECT.',
    ],
  },
  {
    title: "User's Security Obligations:",
    description: [
      'User agrees to treat its login credentials as sensitive information and will not permit any unauthorized third party from accessing the site via the User’s account.',
      'User shall regularly monitor its account, and all communications occurring thereunder, for any unauthorized use. User must notify DATA API CONNECT immediately if they become aware of any actual or potential compromise of its account at the Service.',
    ],
  },
  {
    title: 'Responsible Use of Public Data:',
    description: [
      'DATA API CONNECT supports the responsible use of public data. User shall not employ the Service for any purpose that is unlawful, fraudulent, or designed to harass others, including for doxing and spamming.',
      'User agrees to cooperate with any Service investigation into any actual or possible fraudulent, unauthorized, or unlawful activity.',
    ],
  },
  {
    title: 'Errors in Public Record Data:',
    description: [
      "DataApiConnect.com, including its API service, re-publishes public record information. Errors may and do exist in public record data. Correction of the public record data can only be made with the originating organization and is not the responsibility of the Service. If any person or organization desires to remove an incorrect record, it may do so by using the site's Remove My Info and Do No Sell My Info (California Residents Only) pages.",
    ],
  },
  {
    title: 'Third Party Content and Services:',
    description: [
      'The website Content available includes third party public record content that originates or is otherwise authored or published by the third parties, completely independent of any review by the Website.',
      'Inclusion of third party resources on the Website does not imply endorsement or approval of any resource. The Service does not investigate, review, monitor or otherwise regulate third party Content that may appear on the Site, and the Service does not warrant the accuracy, completeness or appropriateness of third party resources appearing on the Site.',
    ],
  },
  {
    title: 'Fair Credit Reporting Act:',
    description: [
      "User acknowledges and agrees that the Site is not a “consumer reporting agency” and does not constitute a “consumer report,” as defined by The Fair Credit Reporting Act (FCRA) and shall not be used for any FCRA regulated purpose by User. The Service may not be used in whole or in part as a factor in determining any consumer's eligibility for credit, insurance, housing, or employment or for any other purpose regulated by the FCRA.",
    ],
  },
  {
    title: 'Telephone Consumer Protection Act:',
    description: [
      'DATA API CONNECT assumes no legal liability for any phone records mistakenly identified as the wrong linetype including under the Telephone Consumer Protection Act and similar Federal and State consumer protection laws. DATA API CONNECT cannot guarantee 100% accuracy for its telephone linetype identification.',
    ],
  },
  {
    title: 'Purchasers of Personal Information of California Consumers:',
    description: [
      'All personal information is sold or disclosed by DATA API CONNECT only for limited and specified purposes to wit, data lookups and public record research and verification; USER agrees to provide the same level of privacy protection as is required by the CCPA and CPRA. DATA API CONNECT is granted the right to take reasonable and appropriate steps to help ensure that the Assignee uses the personal information transferred in a manner consistent with obligations under the CCPA and CPRA.',
      'USER is required to notify DATA API CONNECT if it makes a determination that it can no longer meet its obligations under this title. USER grants DATA API CONNECT the right, upon notice, to take reasonable and appropriate steps to stop and remediate unauthorized use of personal information.',
    ],
  },
  {
    title: 'Right of the Service to Suspend/Cancel User:',
    description: [
      'DATA API CONNECT reserves the right to stop, suspend or cancel service to any User whatsoever with or without due cause and with or without advance warning.',
    ],
  },
  {
    title: 'Removal of Consumer Information:',
    description: [
      "Any consumer may remove his or her information from appearing on the Website at any time by using the site's Remove My Info and Do No Sell My Info (California Residents Only) pages.",
    ],
  },
  {
    title: 'Site Usage Intended for U.S.Lookups Only:',
    description: [
      'The Service is intended to be used for lookups of data for U.S. based consumers and businesses only. It is not intended to be used in, or applicable to, persons or businesses outside of the United States.',
    ],
  },
  {
    title: 'Commercial Solicitation Restriction:',
    description: [
      "Certain public data obtained and re-published by the Service is restricted for use for commercial solicitation by the source government agency data provider. The Service includes an option under the site's My Account section to suppress records coming from government agencies who have a known commercial solicitation restriction.",
      "DATA API CONNECT IS UNABLE TO GUARANTEE THAT THIS LIST OF KNOWN COMMERCIAL SOLICITATION DATA SOURCES IS COMPLETE. If the User foresees using the Service for commercial solicitation, it is the User's responsibility to enable the My Account option to suppress these records using the on/off switch labeled Commercial Solicitation Restrictions.",
      "Further, if User foresees using any data from Service for commercial solicitation, it is User's sole duty to comply with all such restrictions on use of data.",
    ],
  },
  {
    title: 'Compliance with All State and Federal Data Privacy Laws:',
    description: [
      "User agrees to abide by the terms of the Service's Privacy Policy which can be found here: Privacy Policy. User agrees that it will comply with all federal and State data privacy laws and regulations with data it obtains from the Service.",
    ],
  },
  {
    title: 'Trademark:',
    description: [
      'DataApiConnect.com and Data API Connect are trademarks of the Website owners and may not be used without our expressed written permission.',
    ],
  },
  {
    title: 'Governing Law:',
    description: [
      'Both parties agree that by visiting or using DataApiConnect.com, the laws of the State of New York, without regard to principles of conflict of laws, will govern these Terms. Any legal disputes shall be heard in the state or federal courts located in New York County, New York.',
    ],
  },
  {
    title: 'Severability:',
    description: [
      'If any provision or portion of these Terms is deemed to be unenforceable, that provision of these Terms will be enforced to the maximum extent permissible so as to affect the intent of the parties, and the remainder of these Terms will continue in full force and effect.',
    ],
  },
  {
    title: 'Headings:',
    description: [
      'Headings used in these Terms are provided for convenience only and shall not be used to construe meaning or intent.',
    ],
  },
  {
    title: 'Waiver:',
    description: [
      "The Service's failure to act with respect to a breach of these terms by a User does not waive the Service’s right to act with respect to subsequent or similar breaches.",
    ],
  },
  {
    title: 'Entirety:',
    description: [
      'These Terms are the entire and sole agreement between DATA API CONNECT and User. DATA API CONNECT reserves the right to update or modify these Terms from time to time and sole notification and publication of the changes will be on this page.',
    ],
  },
];

export const Use_Cases_Data = [
  'Property Valuation: Users can access accurate property data to determine property values, aiding in pricing, appraisal, and investment decisions.',
  'Market Analysis: Access market trends and historical data for informed real estate investment choices.',
  'Lead Generation: Generate leads for potential buyers, sellers, or tenants using contact information obtained from property data.',
  'Marketing Campaigns: Target audiences with precision using demographic and property data, enhancing marketing and advertising efforts.',
  'Property Management: Streamline property management with data on ownership, occupancy, features, and property history.',
  'Investment Decisions: Evaluate potential investment properties based on property type, value, and other relevant data.',
  'Legal Documentation: Use property data for legal documentation and property transactions.',
  'Ownership Verification: Confirm property ownership and title information for various purposes.',
  'Zoning and Land Use Analysis: Analyze land use data for zoning and urban development plans.',
  'Customer Resources: Offer guides, articles, and tools to assist clients in real estate endeavors.',
];

export const Pricing_Card_Data = [
  {
    ButtonTitle: 'START NOW',
    price: '0.05',
    planTitle: 'Pay As You Go',
    title01: 'No subscriptoin,',
    title02: 'No commitment.',
    bestSeller: false,
    info: [
      'Free Trial',
      'No set up,No Hidden fees.',
      'Instant API Aceess',
      'No Usage Limit',
      'Geographic Coverage',
      'Premium Support ',
      'Ping Post Service',
    ],
    planDetails: [],
    totalCost: '',
    desc0:
      "Thank you for choosing our 'Pay as you go' plan. We understand the value of flexibility and have designed this plan with 'No subscription, No commitment' in mind. Please note that at this time, we are in the process of implementing card payments for this plan, but they are not yet available. However, we offer convenient payment options through wire transfer and ACH.",
    desc1: '',
    paymentInfo: [
      'Account Holder : Data API Connect LLC',
      'ACH and Wire routing number: 026073150',
      'Account Number: 8313797815',
      'Account Type: Checking',
      'Email : info@databoxmarketresearch.com',
      'Phone: +1 (484) 978-9456',
      'Address: 30 W. 26th Street, Sixth Floor, New York, NY 10010, United States.',
    ],
    desc2:
      "Once you've made your payment using one of these methods, please share the receipt with us through WhatsApp, Skype, or Telegram. Our contact details for these platforms are as follows:",
    desc3: [
      'After receiving your payment confirmation, we will ensure your account is credited with the funds, and you can start using our services right away.',
    ],
  },
  {
    ButtonTitle: 'UPGRADE',
    price: '0.04',
    planTitle: 'Standard',
    title01: 'Minimum 100k',
    title02: 'Data Records.',
    bestSeller: true,
    info: [
      'Free Trial',
      'No set up,No Hidden fees.',
      'Instant API Aceess',
      'Tired data savings',
      'Geographic Coverage',
      'Premium Support ',
      'Ping Post Service',
    ],
    planDetails: ['Pricing: $0.04 per Data Record', 'Minimum: 100,000 Data Records'],
    totalCost: 'Total Cost for Standard Plan: $4,000',
    desc0:
      "Welcome to our 'Standard Plan,' a robust choice for your data needs. With this plan, we offer you competitive pricing at $0.04 per data record. Here's the deal - no subscription, no commitment, just a straightforward pricing structure.",
    desc1:
      "We understand that transparency is important to you. With our 'Standard Plan,' you know exactly what you're getting and what you're paying. Whether you need data for a specific project or an ongoing venture, this plan provides the flexibility you require.",

    paymentInfo: [
      'Account Holder : Data API Connect LLC',
      'ACH and Wire routing number: 026073150',
      'Account Number: 8313797815',
      'Account Type: Checking',
      'Email : info@databoxmarketresearch.com',
      'Phone: +1 (484) 978-9456',
      'Address: 30 W. 26th Street, Sixth Floor, New York, NY 10010, United States.',
    ],
    desc2:
      'Once your payment of $4,000 is processed, please share the receipt with us through WhatsApp, Skype, or Telegram. Our contact details for these platforms are as follows:',
    desc3: [
      "Upon receiving your payment confirmation, we will ensure your account is credited with the funds, allowing you to access the 'Standard Plan' immediately.",
      'Our team believes in providing a clear and competitive pricing structure without the need for subscriptions or long-term commitments. Our working hours are in Eastern Standard Time (EST), from 10 am to 10 pm.',
    ],
  },
  {
    ButtonTitle: 'UPGRADE',
    price: '0.03',
    planTitle: 'Professional',
    title01: 'Minimum 500k',
    title02: 'Data Records.',
    bestSeller: false,
    info: [
      'Free Trial',
      'No set up,No Hidden fees.',
      'Instant API Aceess',
      'Tired data savings',
      'Geographic Coverage',
      'Premium Support ',
      'Ping Post Service',
    ],
    planDetails: ['Pricing: $0.03 per Data Record', 'Minimum: 500,000 Data Records'],
    totalCost: 'Total Cost for Professional Plan: $15,000',
    desc0:
      "Welcome to our 'Professional Plan,' designed to meet your data needs with a focus on professionalism and affordability. With this plan, we offer a competitive price of $0.06 per data record. No commitments, just quality service.",
    desc1:
      "We understand that clarity and affordability are essential to you. The 'Professional Plan' offers a straightforward pricing structure, ensuring you know exactly what you're getting and what you're paying for.",
    paymentInfo: [
      'Account Holder : Data API Connect LLC',
      'ACH and Wire routing number: 026073150',
      'Account Number: 8313797815',
      'Account Type: Checking',
      'Email : info@databoxmarketresearch.com',
      'Phone: +1 (484) 978-9456',
      'Address: 30 W. 26th Street, Sixth Floor, New York, NY 10010, United States.',
    ],
    desc2:
      'After processing your payment of $15,000, please share the receipt with us through WhatsApp, Skype, or Telegram. You can reach us on these platforms using the following contact details:',
    desc3: [
      "Upon receiving your payment confirmation, we will ensure your account is credited with the funds, allowing you to access the 'Standard Plan' immediately.",
      'Our team believes in providing a clear and competitive pricing structure without the need for subscriptions or long-term commitments. Our working hours are in Eastern Standard Time (EST), from 10 am to 10 pm.',
      'Thank you for choosing Data API Connect LLC, and we look forward to serving your enterprise-level data requirements.',
    ],
  },
  {
    ButtonTitle: 'UPGRADE',
    price: '0.02',
    planTitle: 'Enterprise',
    title01: 'Minimum 1 Million',
    title02: 'Data Records.',
    bestSeller: false,
    info: [
      'Free Trial',
      'No set up,No Hidden fees.',
      'Instant API Aceess',
      'Tired data savings',
      'Geographic Coverage',
      'Premium Support ',
      'Ping Post Service',
    ],
    planDetails: ['Pricing: $0.02 per Data Record', 'Minimum: 1 Million Data Records'],
    totalCost: 'Total Cost for Enterprise Plan: $20,000',
    desc0:
      "Welcome to our 'Enterprise Plan,' a top-tier solution designed to meet the data requirements of your business at a cost-effective rate. With this plan, we offer a competitive price of $0.04 per data record, providing you with the flexibility and resources needed for large-scale data projects.",
    desc1:
      "We understand that transparency and affordability are paramount to your business. The 'Enterprise Plan' provides a straightforward pricing structure, so you know exactly what you're getting and what you're paying for.",
    paymentInfo: [
      'Account Holder : Data API Connect LLC',
      'ACH and Wire routing number: 026073150',
      'Account Number: 8313797815',
      'Account Type: Checking',
      'Email : info@databoxmarketresearch.com',
      'Phone: +1 (484) 978-9456',
      'Address: 30 W. 26th Street, Sixth Floor, New York, NY 10010, United States.',
    ],
    desc2:
      'Upon processing your payment of $20,000, please share the receipt with us through WhatsApp, Skype, or Telegram. You can reach us on these platforms using the following contact details:',
    desc3: [
      "Upon receipt of your payment confirmation, we will promptly credit your account with the funds, providing you with access to the 'Enterprise Plan' immediately.",
      "Our team is dedicated to delivering clear and competitive pricing structures without the need for subscriptions or long-term commitments. We're here to support your enterprise data needs. Our working hours are in Eastern Standard Time (EST), from 10 am to 10 pm.",
      'Thank you for choosing Data API Connect LLC, and we look forward to serving your enterprise-level data requirements.',
    ],
  },
];

export const Pricing_Card_Basic_Plans = [
  {
    ButtonTitle: 'Contact Us',
    price: '0.05',
    planTitle: 'Single User',
    title01: 'Single User',
    title02: '',
    cost: 3000,
    bestSeller: false,
    info: [
      '15% Free Customization',
      'Excel sheet can be purchased for USD 500',
      '2hr analyst support on post purchase queries',
      'No Set up , Hidden fees',
      'No subscription',
    ],

    planDetails: ['Pricing: $0.0079 per Data Record', 'Minimum: 100000 Data Records'],
    totalCost: 'Total Cost : $3000',
    desc0:
      "Thank you for choosing our 'Basic Starting' plan. We understand the value of flexibility and have designed this plan with 'No subscription, No commitment' in mind. Please note that at this time, we are in the process of implementing card payments for this plan, but they are not yet available. However, we offer convenient payment options through wire transfer and ACH.",
    desc1: '',
    paymentInfo: [
      'Account Holder : Data API Connect LLC',
      'ACH and Wire routing number: 026073150',
      'Account Number: 8313797815',
      'Account Type: Checking',
      'Email : info@databoxmarketresearch.com',
      'Phone: +1 (484) 978-9456',
      'Address: 30 W. 26th Street, Sixth Floor, New York, NY 10010, United States.',
    ],
    desc2:
      "Once you've made your payment using one of these methods, please share the receipt with us through WhatsApp, Skype, or Telegram. Our contact details for these platforms are as follows:",
    desc3: [
      'After receiving your payment confirmation, we will ensure your account is credited with the funds, and you can start using our services right away.',
    ],
  },

  {
    ButtonTitle: 'Contact Us',
    price: '0.05',
    planTitle: 'Multi User',
    title01: 'Multi User',
    title02: '',
    cost: 4500,
    bestSeller: false,
    info: [
      '20% Free Customization',
      '5hr analyst support on post purchase queries',
      'Printable Version',
      'Excel sheet to be provided free of cost',
      'No Set up , Hidden fees',
    ],

    planDetails: ['Pricing: $0.00596 per Data Record', 'Minimum:25,000 Data Records'],
    totalCost: 'Total Cost : $4500',
    desc0:
      "Thank you for choosing our 'Basic Standard' plan. We understand the value of flexibility and have designed this plan with 'No subscription, No commitment' in mind. Please note that at this time, we are in the process of implementing card payments for this plan, but they are not yet available. However, we offer convenient payment options through wire transfer and ACH.",
    desc1: '',
    paymentInfo: [
      'Account Holder : Data API Connect LLC',
      'ACH and Wire routing number: 026073150',
      'Account Number: 8313797815',
      'Account Type: Checking',
      'Email : info@databoxmarketresearch.com',
      'Phone: +1 (484) 978-9456',
      'Address: 30 W. 26th Street, Sixth Floor, New York, NY 10010, United States.',
    ],
    desc2:
      "Once you've made your payment using one of these methods, please share the receipt with us through WhatsApp, Skype, or Telegram. Our contact details for these platforms are as follows:",
    desc3: [
      'After receiving your payment confirmation, we will ensure your account is credited with the funds, and you can start using our services right away.',
    ],
  },

  {
    ButtonTitle: 'Contact Us',
    price: '0.05',
    planTitle: 'Enterprise User',
    title01: 'Enterprise User',
    title02: '',
    cost: 6500,
    bestSeller: false,
    info: [
      '25% Free Customization',
      '10hr analyst support on post purchase queries',
      'Printable Version',
      'Excel sheet to be provided free of cost',
      '6 month validity to customize the report within the scope with the lead analyst support',
      '1 Free update of report after year',
    ],
    imp_info: [
      'Free trial 50 Credits included Phone & Emails',
      'BONUS! 1000 Free phones / credits',
      'BONUS! 1000 Free phones / credits',
    ],
    planDetails: ['Pricing: $0.00498 per Data Record', 'Minimum: 50,000 Data Records'],
    totalCost: 'Total Cost : $6500',
    desc0:
      "Thank you for choosing our 'Basic Business' plan. We understand the value of flexibility and have designed this plan with 'No subscription, No commitment' in mind. Please note that at this time, we are in the process of implementing card payments for this plan, but they are not yet available. However, we offer convenient payment options through wire transfer and ACH.",
    desc1: '',
    paymentInfo: [
      'Account Holder : Data API Connect LLC',
      'ACH and Wire routing number: 026073150',
      'Account Number: 8313797815',
      'Account Type: Checking',
      'Email : info@databoxmarketresearch.com',
      'Phone: +1 (484) 978-9456',
      'Address: 30 W. 26th Street, Sixth Floor, New York, NY 10010, United States.',
    ],
    desc2:
      "Once you've made your payment using one of these methods, please share the receipt with us through WhatsApp, Skype, or Telegram. Our contact details for these platforms are as follows:",
    desc3: [
      'After receiving your payment confirmation, we will ensure your account is credited with the funds, and you can start using our services right away.',
    ],
  },
];

export const Pricing_Card_Entrepreneur_Plans = [
  {
    ButtonTitle: 'Make A Payment',
    price: '0.05',
    planTitle: 'Basic',
    title01: 'Solo Includes',
    title02: 'Nationwide property data',
    cost: 449,
    bestSeller: false,
    info: [
      '100,000 Credits For $449',
      'Instant Api Access',
      'Zapier Integration',

      'No Set up , Hidden fees',

      'No subscription, Per Credit Per Record',
    ],
    imp_info: [
      'Free trial 50 Credits included Phone & Emails',
      'BONUS! 2500 Free phones / credits',
      'BONUS! 2500 Free phones / credits',
    ],
    planDetails: ['Pricing: $0.00449 per Data Record', 'Minimum: 100,000 Data Records'],
    totalCost: 'Total Cost : $449',
    desc0:
      "Thank you for choosing our 'Entrepreneur Basic' plan. We understand the value of flexibility and have designed this plan with 'No subscription, No commitment' in mind. Please note that at this time, we are in the process of implementing card payments for this plan, but they are not yet available. However, we offer convenient payment options through wire transfer and ACH.",
    desc1: '',
    paymentInfo: [
      'Account Holder : Data API Connect LLC',
      'ACH and Wire routing number: 026073150',
      'Account Number: 8313797815',
      'Account Type: Checking',
      'Email : info@databoxmarketresearch.com',
      'Phone: +1 (484) 978-9456',
      'Address: 30 W. 26th Street, Sixth Floor, New York, NY 10010, United States.',
    ],
    desc2:
      "Once you've made your payment using one of these methods, please share the receipt with us through WhatsApp, Skype, or Telegram. Our contact details for these platforms are as follows:",
    desc3: [
      'After receiving your payment confirmation, we will ensure your account is credited with the funds, and you can start using our services right away.',
    ],
  },

  {
    ButtonTitle: 'Make A Payment',
    price: '0.05',
    planTitle: 'Standard',
    title01: 'Standard Includes',
    title02: 'Nationwide property data',
    cost: 849,
    bestSeller: false,
    info: [
      '250,000 Credits For $849',
      'Instant Api Access',
      'Zapier Integration',

      'No Set up , Hidden fees',

      'No subscription, Per Credit Per Record',
    ],
    imp_info: [
      'Free trial 50 Credits included Phone & Emails',
      'BONUS! 5000 Free phones / credits',
      'BONUS! 5000 Free phones / credits',
    ],
    planDetails: ['Pricing: $0.003396 per Data Record', 'Minimum: 250,000 Data Records'],
    totalCost: 'Total Cost : $849',
    desc0:
      "Thank you for choosing our 'Entrepreneur Standard' plan. We understand the value of flexibility and have designed this plan with 'No subscription, No commitment' in mind. Please note that at this time, we are in the process of implementing card payments for this plan, but they are not yet available. However, we offer convenient payment options through wire transfer and ACH.",
    desc1: '',
    paymentInfo: [
      'Account Holder : Data API Connect LLC',
      'ACH and Wire routing number: 026073150',
      'Account Number: 8313797815',
      'Account Type: Checking',
      'Email : info@databoxmarketresearch.com',
      'Phone: +1 (484) 978-9456',
      'Address: 30 W. 26th Street, Sixth Floor, New York, NY 10010, United States.',
    ],
    desc2:
      "Once you've made your payment using one of these methods, please share the receipt with us through WhatsApp, Skype, or Telegram. Our contact details for these platforms are as follows:",
    desc3: [
      'After receiving your payment confirmation, we will ensure your account is credited with the funds, and you can start using our services right away.',
    ],
  },
  {
    ButtonTitle: 'Make A Payment',
    price: '0.05',
    planTitle: 'Business',
    title01: 'Business Includes',
    title02: 'Nationwide property data',
    cost: 1549,
    bestSeller: false,
    info: [
      '500,000 Credits For $1549',
      'Instant Api Access',
      'Zapier Integration',
      'No Set up , Hidden fees',
      'No subscription, Per Credit Per Record',
    ],
    imp_info: [
      'Free trial 50 Credits included Phone & Emails',
      'BONUS! 10000 Free phones / credits',
      'BONUS! 10000 Free phones / credits',
    ],
    planDetails: ['Pricing: $0.003098 per Data Record', 'Minimum: 500,000 Data Records'],
    totalCost: 'Tootal Cost : $1549',
    desc0:
      "Thank you for choosing our 'Entrepreneur Business' plan. We understand the value of flexibility and have designed this plan with 'No subscription, No commitment' in mind. Please note that at this time, we are in the process of implementing card payments for this plan, but they are not yet available. However, we offer convenient payment options through wire transfer and ACH.",
    desc1: '',
    paymentInfo: [
      'Account Holder : Data API Connect LLC',
      'ACH and Wire routing number: 026073150',
      'Account Number: 8313797815',
      'Account Type: Checking',
      'Email : info@databoxmarketresearch.com',
      'Phone: +1 (484) 978-9456',
      'Address: 30 W. 26th Street, Sixth Floor, New York, NY 10010, United States.',
    ],
    desc2:
      "Once you've made your payment using one of these methods, please share the receipt with us through WhatsApp, Skype, or Telegram. Our contact details for these platforms are as follows:",
    desc3: [
      'After receiving your payment confirmation, we will ensure your account is credited with the funds, and you can start using our services right away.',
    ],
  },
];

export const DropDownCategories = [
  {
    title: 'Health Care Sector',
    description:
      'The health care sector encompasses organizations providing medical care, pharmaceuticals, and healthcare services to maintain and improve human health.',
    link: '/report/22b97628-e485-4c8b-8e44-3d78bb4d7a81',
    icon: VentilatorHeart,
  },

  {
    title: 'Information Technology',
    description:
      'Information Technology (IT) manages information through computers, software, and networks, playing a pivotal role in software development, cybersecurity, and data management.',
    link: '/report/97f8a529-6d3b-4b48-9437-af30f21cc267',
    icon: DesktopIcon,
  },
  {
    title: 'Food & Beverages',
    description:
      'This industry involves the production, processing, and distribution of food and drink products, contributing to the sustenance of consumers.',
    link: '/report/8e9d4de5-2899-4b57-a36d-9f3f6d1e0a3b',
    icon: ForkIcon,
  },
  {
    title: 'Chemical & Materials',
    description:
      'Focused on manufacturing chemicals and materials, this industry supports sectors like construction, automotive, and electronics in creating diverse products.',
    link: '/report/1dc938c6-6e29-4e4b-82b2-92d7a417754a',
    icon: ChemicalIcon,
  },
  {
    title: 'Semiconductorn & Electronics',
    description:
      'Responsible for producing semiconductor devices and electronic components, this sector drives technological advancements and innovations in electronics.',
    link: '/report/d8651b5e-3d15-48cd-84a8-9b01fd784e38',
    icon: SemiConductor,
  },
  {
    title: 'Automotive & Transport',
    description:
      'Encompassing vehicle design, manufacturing, and maintenance, this industry shapes global transportation systems for people and goods.',
    link: '/report/dbe1bda9-593b-4e6c-9f39-5c7a2b24691b',
    icon: TruckLogo,
  },
  {
    title: 'Materials & Packing',
    description:
      'Key to efficient storage and transportation, this industry produces materials and packaging solutions for various products.',
    link: '/report/a2fbc2b6-6e4b-4e21-91ef-2c6b819b1f59',
    icon: BoxIcon,
  },
  {
    title: 'FMCG',
    description:
      'Involving frequently purchased consumer goods, FMCG companies operate with high-volume, low-margin business models.',
    link: '/report/8f8a7fb7-9f98-4e03-8c1b-9b1c2a24fbf1',
    icon: ShoppingBasket,
  },
  {
    title: 'Agreen & animal feed',
    description:
      'This industry focuses on crop cultivation and the production of animal feed, supporting global food supply chains.',
    link: '/report/4c21d4bd-82d5-4f71-88f3-d8a5dd6b5d43',
    icon: WheatIcon,
  },
  {
    title: 'Industrial Automation',
    description:
      'Utilizing control systems and technology, industrial automation automates manufacturing processes, enhancing efficiency and safety.',
    link: '/report/6cf8e8e5-3b97-4827-bdfc-1a5a7e7cb654',
    icon: RobotIcon,
  },

  {
    title: 'Oil and Gas Energy',
    description:
      'This industry involves the exploration, extraction, refining, and distribution of oil and gas resources, serving as a cornerstone for global energy supply.',
    link: '/report/17a6740a-dc91-47a4-9b19-3c072c25c16e',
    icon: IndustryLogo,
  },
];
export const actionButtonData = DropDownCategories.map((item) => {
  return { title: item.title, link: item.link, icon: item.icon };
});
export const catergories_card_data = [
  {
    id: '9C4D5E3C-43F4-46AB-B39D-8BC75B8A6784',
    title: 'Health Care Sector',
    icon: VentilatorHeart,
    bgColor: 1,
    description:
      'The health care sector encompasses organizations providing medical care, pharmaceuticals, and healthcare services to maintain and improve human health.',
    link: '/report/22b97628-e485-4c8b-8e44-3d78bb4d7a81',
  },
  // {
  //   id: '9C4D5E3C-43F4-46AB-B39D-8BC75B8A6784',
  //   title: 'Mortgage professionals',
  //   icon: HomeIcon,
  //   link: '',
  //   bgColor: 1,
  //   description:
  //     'Our cutting-edge data solution enables mortgage professionals to discover and connect easily with borrowers who precisely match your offerings. Say goodbye to unnecessary layers and welcome a streamlined approach to generating new clients. Maximize your efficiency, reduce complexity, and transform your client acquisition strategy',
  // },
  {
    id: '1F8EE987-2F54-4E6D-9F0C-2B0E6DCEE1E3',
    title: 'Information Technology',
    icon: DesktopIcon,
    bgColor: 2,
    description:
      'Information Technology (IT) manages information through computers, software, and networks, playing a pivotal role in software development, cybersecurity, and data management.',
    link: '/report/97f8a529-6d3b-4b48-9437-af30f21cc267',
  },
  {
    id: '3A2B76D5-8E2D-4D1C-A7D3-FB9E688D2F97',
    bgColor: 3,
    title: 'Food & Beverages',
    description:
      'This industry involves the production, processing, and distribution of food and drink products, contributing to the sustenance of consumers.',
    link: '/report/8e9d4de5-2899-4b57-a36d-9f3f6d1e0a3b',
    icon: ForkIcon,
  },
  {
    id: '9C18E2B0-ABED-4E43-8043-8F35681D309D',
    bgColor: 4,
    title: 'Chemical & Materials',
    description:
      'Focused on manufacturing chemicals and materials, this industry supports sectors like construction, automotive, and electronics in creating diverse products.',
    link: '/report/1dc938c6-6e29-4e4b-82b2-92d7a417754a',
    icon: ChemicalIcon,
  },
  {
    id: '7F4C819A-D09F-4C4B-BE57-9F7C5E680F76',
    bgColor: 5,
    title: 'Semiconductorn & Electronics',
    description:
      'Responsible for producing semiconductor devices and electronic components, this sector drives technological advancements and innovations in electronics.',
    link: '/report/d8651b5e-3d15-48cd-84a8-9b01fd784e38',
    icon: SemiConductor,
  },
  {
    id: 'B0A6F3E9-DBD9-4A69-8618-F892BFB7C850',
    bgColor: 6,
    title: 'Automotive & Transport',
    description:
      'Encompassing vehicle design, manufacturing, and maintenance, this industry shapes global transportation systems for people and goods.',
    link: '/report/dbe1bda9-593b-4e6c-9f39-5c7a2b24691b',
    icon: TruckLogo,
  },
  {
    id: '6D7CEA4F-88D9-4345-B251-2F2C072A893B',
    bgColor: 1,
    title: 'Materials & Packing',
    description:
      'Key to efficient storage and transportation, this industry produces materials and packaging solutions for various products.',
    link: '/report/a2fbc2b6-6e4b-4e21-91ef-2c6b819b1f59',
    icon: BoxIcon,
  },
  {
    id: 'ECA2A128-2C52-4A2E-90FA-00F563C3D28F',
    bgColor: 2,
    title: 'FMCG',
    description:
      'Involving frequently purchased consumer goods, FMCG companies operate with high-volume, low-margin business models.',
    link: '/report/8f8a7fb7-9f98-4e03-8c1b-9b1c2a24fbf1',
    icon: ShoppingBasket,
  },
  {
    id: '2E97373A-F274-4C82-BFC7-9203FEF179B9',
    bgColor: 3,
    title: 'Agreen & animal feed',
    description:
      'This industry focuses on crop cultivation and the production of animal feed, supporting global food supply chains.',
    link: '/report/4c21d4bd-82d5-4f71-88f3-d8a5dd6b5d43',
    icon: WheatIcon,
  },
  {
    id: 'C80F2C36-5C8E-453B-9E29-FC1C0B8A8CE4',
    bgColor: 4,
    title: 'Industrial Automation',
    description:
      'Utilizing control systems and technology, industrial automation automates manufacturing processes, enhancing efficiency and safety.',
    link: '/report/6cf8e8e5-3b97-4827-bdfc-1a5a7e7cb654',
    icon: RobotIcon,
  },
  {
    id: '58F724EB-D12B-4D7C-A43B-FFB9C92936CF',
    bgColor: 5,
    title: 'Oil and Gas Energy',
    description:
      'This industry involves the exploration, extraction, refining, and distribution of oil and gas resources, serving as a cornerstone for global energy supply.',
    link: '/report/17a6740a-dc91-47a4-9b19-3c072c25c16e',
    icon: IndustryLogo,
  },

  // {
  //   id: '1F8EE987-2F54-4E6D-9F0C-2B0E6DCEE1E3',
  //   title: 'Residential home services',
  //   icon: ToolIcon,
  //   link: '',
  //   bgColor: 2,
  //   description:
  //     'Drive demand for your offerings by directly connecting with potential customers in home improvement, repair, roofing, renovation, security services, and more. Leveraging public records, our solution ensures you discover and connect with every potential customer in your market. Streamline your outreach, enhance your visibility, and revolutionize the way you grow your business.',
  // },
  // {
  //   id: 'D890BF7A-6A85-4BBD-A9F4-0A913E67E989',
  //   title: 'Real estate investors',
  //   icon: GraphIcon,
  //   link: '',
  //   bgColor: 3,
  //   description:
  //     'Experience the power of investing at scale, backed by enhanced public records data that allows in-depth research on both residential and commercial properties. Our solution provides unparalleled insights, empowering you to make strategic decisions and seize opportunities in the dynamic real estate market.',
  // },
  // {
  //   id: '6D7A5E38-3D7F-4A8D-8462-9FB4852C2EBC',
  //   title: 'Residential Realtors',
  //   icon: BuildingIcon,
  //   link: '',
  //   bgColor: 4,
  //   description:
  //     "Take control of your real estate business and differentiate yourself from the crowd. Our platform is designed for Residential Realtors who aspire to grow their business independently . By choosing our solution, you are choosing to prioritize your success over Zillow's. Elevate your visibility, streamline your client acquisition, and revolutionize the way you operate in the real estate market.",
  // },
  // {
  //   id: 'FDB7260E-DCAB-41D9-ACBE-525A7216DD2F',
  //   title: 'Title Companies',
  //   icon: CertificateIcon,
  //   link: '',
  //   bgColor: 5,
  //   description:
  //     'Grow your business independently without relying on third-party platforms. Our platform empowers Title Companies to cut out intermediaries and drive new clients and listings directly. Say goodbye to being overshadowed and take the reins of your business growth. Opt for a solution that prioritizes your success over third-party platforms.',
  // },
  // {
  //   id: 'YUA7260E-D2AB-41C9-AEBE-525A7216CC67',
  //   title: 'Technology Services',
  //   icon: WorkerIcon,
  //   link: '',
  //   bgColor: 6,
  //   description:
  //     'Empower your applications and fuel the success of your SaaS ventures with our cutting-edge Property Data API. Unlock unparalleled innovation as you seamlessly integrate comprehensive property data and analytics. Elevate your offerings, enhance user experiences, and stay ahead in the dynamic app and SaaS economy. Transform possibilities into realities with our data-driven solutions.',
  // },
];
export const latest_reports_items = [
  {
    bgColor: 1,
    typeId: 'dbe1bda9-593b-4e6c-9f39-5c7a2b24691b',
    reportId: 'BX9b1e3c6f-9a8d-491c-a776-6ef5bf81ec4d',
    title:
      'The global electric vehicles market was estimated to be worth USD 254.41 billion in 2022 and is projected to grow at a CAGR of 19.83% from 2023 to 2031.',
    img: 'healthImage',
  },
  {
    bgColor: 2,
    typeId: 'a2fbc2b6-6e4b-4e21-91ef-2c6b819b1f59',
    reportId: 'HGY8d2f3b6e-ef04-465b-8f89-6f3y0a16e2c3',
    title:
      'The global luggage and leather goods market was estimated to be worth USD 491.09 billion in 2022 and is projected to grow at a CAGR of 5.27% from 2023 to 2031',
    img: 'healthImage',
  },

  {
    bgColor: 2,
    typeId: 'a2fbc2b6-6e4b-4e21-91ef-2c6b819b1f59',
    reportId: 'MAP9e8b4a2d-1d85-4d3a-94a2-37bc5e25f28a',
    title: 'Africa Baby Feeding Bottle Market – Industry Trends and Forecast to 2030',
    img: 'bottleFedding',
  },
  {
    bgColor: 3,
    typeId: '97f8a529-6d3b-4b48-9437-af30f21cc267',
    reportId: 'ICT43b4f10d-5a2e-234-9a9c-063c1e3c7d5b',
    title: 'Global Managed File Transfer (MFT) Software Services Market',
    img: 'fileTransfer',
  },
  {
    bgColor: 1,
    typeId: '22b97628-e485-4c8b-8e44-3d78bb4d7a81',
    reportId: 'HCSf72eb30b-8cf1-4a2b-bc74-5c1e232f3478',
    title: 'Global Digital Health Market – Industry Trends and Forecast to 2030',
    img: 'healthImage',
  },
  {
    bgColor: 2,
    typeId: 'a2fbc2b6-6e4b-4e21-91ef-2c6b819b1f59',
    reportId: 'MAP9e8b4a2d-1d85-4d3a-94a2-37bc5e25f28a',
    title: 'Africa Baby Feeding Bottle Market – Industry Trends and Forecast to 2030',
    img: 'bottleFedding',
  },
  {
    bgColor: 3,
    typeId: '97f8a529-6d3b-4b48-9437-af30f21cc267',
    reportId: 'ICT43b4f10d-5a2e-234-9a9c-063c1e3c7d5b',
    title: 'Global Managed File Transfer (MFT) Software Services Market',
    img: 'fileTransfer',
  },
];
export const reportsData = [
  {
    id: '22b97628-e485-4c8b-8e44-3d78bb4d7a81',
    title: 'Health Care Sector',
    industry: [
      {
        id: 'HCSf72eb30b-8cf1-4a2b-bc74-5c1e232f3478',
        report_title: 'Global Digital Health Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Digital Health Market, By Technology (Digital Health Systems, Tele-healthcare, mHealth, Healthcare Analytics), Application (Cardiology, Diabetes, Neurology, Sleep Apnea, Oncology, Others), Delivery Mode (On-Premise, Cloud-Based), Components (Software, Services, Hardware), End-User (Healthcare Providers, Healthcare Payers, Pharmaceutical Companies, Others) - Industry Trends and Forecast to 2030.',
          'Global Digital Health Market, By Technology (Digital Health Systems, Tele-healthcare, mHealth, Healthcare Analytics), Application (Cardiology, Diabetes, Neurology, Sleep Apnea, Oncology, Others), Delivery Mode (On-Premise, Cloud-Based), Components (Software, Services, Hardware), End-User (Healthcare Providers, Healthcare Payers, Pharmaceutical Companies, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-13T00:00:00',
        imageURl: '2020/255b6629-0506-406a-a9d6-9f25a8751883.png',
        reportUrl: 'global-digital-health-market',
        tableOfContent: [],
      },
      {
        id: 'HCS6db4ec9d-1a11-4c3e-b82b-965cc0a5bd2a',
        report_title:
          'Global Agmented Reality (AR) and Virtual Reality (VR) In Healthcare Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global augmented reality (AR) and virtual reality (VR) in healthcare market, By Component (Hardware, Software, Services), Technology (Augmented Reality, Virtual Reality), End-User (Hospitals, Clinics, and Surgical Centers, Research Organizations and Pharma Companies, Research and Diagnostics Laboratories, Government and Defense Institutions, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-11T00:00:00',
        imageURl: '2020/255b6629-0506-406a-a9d6-9f25a8751883.png',
        reportUrl: 'global-ar-and-vr-in-healthcare-market',
      },
      {
        id: 'HCS4f1c056f-c8e4-492c-b736-50e14696b7b5',
        report_title:
          'Global Healthcare Supply Chain Management Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Healthcare Supply Chain Management Market, Product Type (Healthcare supply chain management Analysers, POC Test Kits, Healthcare supply chain management Analyser Kits and Reagents), Application (Basic Metabolite Panel, Liver Function Test, Electrolyte Panel, Kidney Function Test, Lipid Profile, Specialty Chemical Test, Thyroid Profile, Other Biochemical Tests), End-User (Hospitals, Pathology Laboratories, Maternity Centres, Outpatient Centres, Clinics) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-06T00:00:00',
        imageURl: '2020/255b6629-0506-406a-a9d6-9f25a8751883.png',
        reportUrl: 'global-healthcare-supply-chain-management-market',
      },
      {
        id: 'HCSb7b0f6a1-43d2-4a65-8dd2-42c70f1bb773',
        report_title: 'Europe Health Insurance Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Europe Health Insurance Market, By Offering (Services and Solution), Provider (Public and Private), Level of Coverage (Bronze, Silver, Gold and Platinum), Network (Point Of Service (POS), Exclusive Provider Organization (EPOS), Indemnity Health Insurance, Health Savings Account (HSA), Qualified Small Employer Health Reimbursement Arrangements (QSEHRAS), Preferred Provider Organization (PPO), Health Maintenance Organization (HMO) and Others), Demographics (Adults, Minors, and Seniors), Coverage Type (Lifetime and Term), End User (Corporate and Individual), Distribution Channel (Direct Sales, Financial Institutions, E-Commerce, Hospitals, Clinics and Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-04T00:00:00',
        imageURl: '2020/255b6629-0506-406a-a9d6-9f25a8751883.png',
        reportUrl: 'europe-health-insurance-market',

        tableOfContent: [
          '1 INTRODUCTION',
          '1.1 OBJECTIVES OF THE STUDY',
          '1.2 MARKET DEFINITION',
          '1.3 OVERVIEW OF EUROPE HEALTH INSURANCE MARKET',
          '1.4 LIMITATIONS',
          '1.5 MARKETS COVERED',
          '2 MARKET SEGMENTATION',
          '2.1 MARKETS COVERED',
          '2.2 GEOGRAPHICAL SCOPE',
          '2.3 YEARS CONSIDERED FOR THE STUDY',
          '2.4 CURRENCY AND PRICING',
          '2.5 DBMR TRIPOD DATA VALIDATION MODEL',
          '2.6 MULTIVARIATE MODELING',
          '2.7 PRIMARY INTERVIEWS WITH KEY OPINION LEADERS',
          '2.8 DBMR MARKET POSITION GRID',
          '2.9 MARKET END-USER COVERAGE GRID',
          '2.1 SECONDARY SOURCES',
          '2.11 ASSUMPTIONS',
          '3 EXECUTIVE SUMMARY',
          '4 PREMIUM INSIGHTS',
          '4.1 PREMIUM INSIGHT- GLOBAL OVERVIEW',
          '4.2 MIGRATION TRENDS IN EUROPE',
          '5 MARKET OVERVIEW',
          '5.1 DRIVERS',
          '5.1.1 INCREASING COST OF MEDICAL SERVICES',
          '5.1.2 GROWING NUMBER OF DAYCARE PROCEDURES',
          '5.1.3 MANDATORY PROVISION OF HEALTHCARE INSURANCE IN PUBLIC AND PRIVATE SECTORS',
          '5.2 RESTRAINTS',
          '5.2.1 HIGH COST OF INSURANCE PREMIUMS',
          '5.2.2 STRICT DOCUMENTATION PROCESS FOR REIMBURSEMENT CLAIM',
          '5.3 OPPORTUNITIES',
          '5.3.1 ADVANTAGES OF HEALTH INSURANCE POLICIES',
          '5.3.2 INCREASING HEALTHCARE EXPENDITURE',
          '5.4 CHALLENGE',
          '5.4.1 LACK OF AWARENESS REGARDING THE BENEFITS OF HEALTH INSURANCE',
          '6 EUROPE HEALTH INSURANCE MARKET, BY OFFERING',
          '6.1 OVERVIEW',
          '6.2 SERVICES',
          '6.2.1 INPATIENT TREATMENT',
          '6.2.2 OUTPATIENT TREATMENT',
          '6.2.3 MEDICAL ASSURANCE',
          '6.2.4 OTHERS',
          '6.3 SOLUTION',
          '6.3.1 MEDICAL ASSURANCE',
          '6.3.2 DISEASES INSURANCE',
          '6.3.3 INCOME PROTECTION INSURANCE',
          '7 EUROPE HEALTH INSURANCE MARKET, BY PROVIDER',
          '7.1 OVERVIEW',
          '7.2 PUBLIC',
          '7.3 PRIVATE',
          '8 EUROPE HEALTH INSURANCE MARKET, BY COVERAGE TYPE',
          '8.1 OVERVIEW',
          '8.2 LIFETIME',
          '8.3 TERM',
          '9 EUROPE HEALTH INSURANCE MARKET, BY END USE',
          '9.1 OVERVIEW',
          '9.2 CORPORATE',
          '9.2.1 SERVICES',
          '9.2.1 SOLUTION',
          '9.3 INDIVIDUAL',
          '9.3.1 SERVICES',
          '9.3.2 SOLUTION',
          '10 EUROPE HEALTH INSURANCE MARKET, BY DISTRIBUTION CHANNEL',
          '10.1 OVERVIEW',
          '10.2 DIRECT SALES',
          '10.3 FINANCIAL INSTITUTIONS',
          '10.4 E-COMMERCE',
          '10.5 HOSPITALS',
          '10.6 CLINICS',
          '10.7 OTHERS',
          '11 EUROPE HEALTH INSURANCE MARKET, BY LEVEL OF COVERAGE',
          '11.1 OVERVIEW',
          '11.2 BRONZE',
          '11.3 SILVER',
          '11.4 GOLD',
          '11.5 PLATINUM',
          '12 EUROPE HEALTH INSURANCE MARKET, BY NETWORK',
          '12.1 OVERVIEW',
          '12.2 POINT OF SERVICE (POS)',
          '12.3 EXCLUSIVE PROVIDER ORGANIZATION (EPOS)',
          '12.4 PREFERRED PROVIDER ORGANIZATION (PPO)',
          '12.5 INDEMNITY HEALTH INSURANCE',
          '12.6 HEALTH MAINTENANCE ORGANIZATION (HMO)',
          '12.7 HEALTH SAVINGS ACCOUNT (HSA)',
          '12.8 QUALIFIED SMALL EMPLOYER HEALTH REIMBURSEMENT ARRANGEMENTS (QSEHRAS)',
          '12.9 OTHERS',
          '13 EUROPE HEALTH INSURANCE MARKET, BY DEMOGRAPHICS',
          '13.1 OVERVIEW',
          '13.2 ADULTS',
          '13.3 MINORS',
          '13.4 SENIORS',
          '14 EUROPE',
          '14.1 U.K.',
          '14.2 GERMANY',
          '14.3 FRANCE',
          '14.4 RUSSIA',
          '14.5 ITALY',
          '14.6 SPAIN',
          '14.7 NETHERLANDS',
          '14.8 POLAND',
          '14.9 SWITZERLAND',
          '14.1 BELGIUM',
          '14.11 SWEDEN',
          '14.12 TURKEY',
          '14.13 DENMARK',
          '14.14 NORWAY',
          '14.15 FINLAND',
          '14.16 REST OF EUROPE',
          '15 EUROPE HEALTH INSURANCE MARKET: COMPANY LANDSCAPE',
          '15.1 COMPANY SHARE ANALYSIS: EUROPE',
          '16 SWOT ANALYSIS',
          '17 COMPANY PROFILES',
          '17.1 AETNA INC. (A SUBSIDIARY OF CVS HEALTH)',
          '17.1.1 COMPANY SNAPSHOT',
          '17.1.2 REVENUE ANALYSIS',
          '17.1.3 PRODUCT PORTFOLIO',
          '17.1.4 RECENT DEVELOPMENTS',
          '17.2 UNITEDHEALTH GROUP',
          '17.2.1 COMPANY SNAPSHOT',
          '17.2.2 REVENUE ANALYSIS',
          '17.2.3 PRODUCT PORTFOLIO',
          '17.2.4 RECENT DEVELOPMENTS',
          '17.3 AXA',
          '17.3.1 COMPANY SNAPSHOT',
          '17.3.2 REVENUE ANALYSIS',
          '17.3.3 PRODUCT PORTFOLIO',
          '17.3.4 RECENT DEVELOPMENTS',
          '17.4 BUPA',
          '17.4.1 COMPANY SNAPSHOT',
          '17.4.2 REVENUE ANALYSIS',
          '17.4.3 PRODUCT PORTFOLIO',
          '17.4.4 RECENT DEVELOPMENTS',
          '17.5 ASSICURANZIONI GENERALI S.P.A.',
          '17.5.1 COMPANY SNAPSHOT',
          '17.5.2 REVENUE ANALYSIS',
          '17.5.3 PRODUCT PORTFOLIO',
          '17.5.4 RECENT DEVELOPMENTS',
          '17.6 ALLIANZ CARE (A SUBSIDIARY OF ALLIANZ)',
          '17.6.1 COMPANY SNAPSHOT',
          '17.6.2 REVENUE ANALYSIS',
          '17.6.3 PRODUCT PORTFOLIO',
          '17.6.4 RECENT DEVELOPMENTS',
          '17.7 AVIVA',
          '17.7.1 COMPANY SNAPSHOT',
          '17.7.2 REVENUE ANALYSIS',
          '17.7.3 PRODUCT PORTFOLIO',
          '17.7.4 RECENT DEVELOPMENTS',
          '17.8 BROADSTONE CORPORATE BENEFITS LIMITED',
          '17.8.1 COMPANY SNAPSHOT',
          '17.8.2 PRODUCT PORTFOLIO',
          '17.8.3 RECENT DEVELOPMENTS',
          '17.9 CIGNA',
          '17.9.1 COMPANY SNAPSHOT',
          '17.9.2 REVENUE ANALYSIS',
          '17.9.3 PRODUCT PORTFOLIO',
          '17.9.4 RECENT DEVELOPMENTS',
          '17.1 FREEDOM HEALTH INSURANCE',
          '17.10.1 COMPANY SNAPSHOT',
          '17.10.2 PRODUCT PORTFOLIO',
          '17.10.3 RECENT DEVELOPMENTS',
          '17.11 GENERAL AND MEDICAL FINANCE LTD',
          '17.11.1 COMPANY SNAPSHOT',
          '17.11.2 PRODUCT PORTFOLIO',
          '17.11.3 RECENT DEVELOPMENTS',
          '17.12 HEALTHCARE INTERNATIONAL GLOBAL NETWORK LTD.',
          '17.12.1 COMPANY SNAPSHOT',
          '17.12.2 PRODUCT PORTFOLIO',
          '17.12.3 RECENT DEVELOPMENT',
          '17.13 INTERNATIONAL MEDICAL GROUP, INC.',
          '17.13.1 COMPANY SNAPSHOT',
          '17.13.2 PRODUCT PORTFOLIO',
          '17.13.3 RECENT DEVELOPMENTS',
          '17.14 MAPFRE',
          '17.14.1 COMPANY SNAPSHOT',
          '17.14.2 REVENUE ANALYSIS',
          '17.14.3 PRODUCT PORTFOLIO',
          '17.14.4 RECENT DEVELOPMENTS',
          '17.15 NOW HEALTH INTERNATIONAL',
          '17.15.1 COMPANY SNAPSHOT',
          '17.15.2 PRODUCT PORTFOLIO',
          '17.15.3 RECENT DEEVLOPMENTS',
          '17.16 ORACLE',
          '17.16.1 COMPANY SNAPSHOT',
          '17.16.2 REVENUE ANALYSIS',
          '17.16.3 PRODUCT PORTFOLIO',
          '17.16.4 RECENT DEVELOPMENTS',
          '17.17 SAGA',
          '17.17.1 COMPANY SNAPSHOT',
          '17.17.2 REVENUE ANALYSIS',
          '17.17.3 PRODUCT PORTFOLIO',
          '17.17.4 RECENT DEVELOPMENTS',
          '17.18 VHI GROUP',
          '17.18.1 COMPANY SNAPSHOT',
          '17.18.2 PRODUCT PORTFOLIO',
          '17.18.3 RECENT DEVELOPMENT',
          '17.19 VITALITY (A SUBSIDIARY OF DISCOVERY LTD)',
          '17.19.1 COMPANY SNAPSHOT',
          '17.19.2 PRODUCT PORTFOLIO',
          '17.19.3 RECENT DEVELOPMENTS',
          '18 QUESTIONNAIRE',
          '19 RELATED REPORTS',
        ],
      },
      {
        id: 'HCSb7b0f6a1-43d2-4a65-8dd2-42c70f1bb773',
        report_title:
          'Global Medical Device Technologies Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Medical Device Technologies Market, By Device Area (In Vitro Diagnostics (IVD)</a>, Cardiology Devices, Orthopedic Devices, Diagnostic Imaging Devices, Endoscopy Devices, Ophthalmology Devices, Drug Delivery Devices, Wound Management Devices, Other Device Areas), Type (Molecular Diagnostics, Diagnostic Imaging, Non-Invasive Monitoring, Drug Delivery, Mobility Aid Technologies, Micro-Fluids and Mems, Bio-Implants, Biomaterials, Minimal/Non-Invasive Surgery, Telemedicine), End User (Hospitals and Clinics Medical Device Industries, Pharmaceutical and Research Organizations, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-03T00:00:00',
        imageURl: '2020/255b6629-0506-406a-a9d6-9f25a8751883.png',
        reportUrl: 'global-medical-device-technologies-market',
      },
      {
        id: 'HCSc8768a5e-04d4-4cc7-835d-3d9e1835ea98',
        report_title: 'Global Life Science Software Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Life Science Software Market, By Type (Predictive, Descriptive, Prescriptive), Application (Marketing, Compliance, R&amp;D, Pharmacovigilance, SCM), Component (Software, Service), Solution (Cloud-based and On-Premises) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-03T00:00:00',
        imageURl: '2020/255b6629-0506-406a-a9d6-9f25a8751883.png',
        reportUrl: 'global-life-science-software-market',
      },
      {
        id: 'HCS7e87e06e-bf7d-471e-8b59-82a9eb2fe735',
        report_title: 'Global Physiological Monitors Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Physiological Monitors Market By Product (Hemodynamic Monitoring Devices, Neuromonitoring Devices, Cardiac Monitoring Devices, Fetal and Neonatal Monitoring Devices, Respiratory Monitoring Devices, Multiparameter Monitoring Devices, Remote Patient Monitoring Devices, Weight Monitoring Devices, Temperature Monitoring Devices, Urine Output Monitoring Devices, Others),&nbsp; Application (Cardiology, Neurology, Respiratory, Fetal and Neonatal, Weight Management and Fitness Monitoring, Other), End User (Hospitals and Clinics, Home Settings, Ambulatory Surgical Centers</a> (ASC), Others) &ndash; Industry Trends and Forecast to 2030.",\r\n',
        ],
        publishDate: '2023-09-28T00:00:00',
        imageURl: '2020/255b6629-0506-406a-a9d6-9f25a8751883.png',
        reportUrl: 'global-physiological-monitors-market',
      },
      {
        id: 'HCSdcdfe270-683d-432e-aa7a-f2c0f46092fc',
        report_title:
          'Singapore Private Health Insurance Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Singapore Private Health Insurance Market, By Type (Critical Illness Insurance, Individual Health Insurance, Family Health Insurance, Disease-Specific Insurance, and Others), Health Plan Category/Metal Levels (Bronze, Silver, Gold Platinum, and Others), Provider Type (Health Maintenance Organizations (HMOS), Preferred Provider Organizations (PPOS), Exclusive Provider Organizations (EPOS), Point-Of-Service (POS) Plans, High-Deductible Health Plans (HDHPS) ,and Others), Age Group (Young Adulthood (19-44 Years), Middle Adulthood (45-64 Years), and Older Adulthood (65 Years And Above)), Distribution Channel (Direct Insurance Companies, Insurance Aggregators, and Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-06-13T00:00:00',
        imageURl: '2020/255b6629-0506-406a-a9d6-9f25a8751883.png',
        reportUrl: 'singapore-private-health-insurance-market',
      },
      {
        id: 'HCS23ecb1bf-5a44-4a5f-86da-ff3f6b96e06f',
        report_title:
          'Middle East and Africa Health Tourism Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Middle East and Africa Health Tourism Market, By Service (Transport, Accommodation, Hospitality, Others), Type of Treatment (Cosmetic Treatment, Dental Treatment, Cardiovascular Treatment, Orthopaedic Treatment, Bariatric Treatment, Fertility Treatment, Cancer Treatment, Ophthalmic Treatment, Neurological Treatment, Others), Age Group (Senior Citizens, Minors, Adults), By Service Provider (Private, Public) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-06-08T00:00:00',
        imageURl: '2020/255b6629-0506-406a-a9d6-9f25a8751883.png',
        reportUrl: 'middle-east-and-africa-health-tourism-market',
      },
      {
        id: 'HCS68ebc900-18b7-4bf7-9f05-7dd306c01c19',
        report_title:
          'North America AI in Bioinformatics Market – Industry Trends and Forecast to 2029',
        short_discription: [
          'North America AI Bioinformatics Market, By Offering (Services, Software, and Others), Type (Machine Learning, Deep Learning, and Others), Product and Services (Bioinformatics Services, Bioinformatics Platforms, and Knowledge Management Tools), Application (Genomics, Chemoinformatics &amp; Drug Design, Proteomics, DNA Sequencing, System Biology, Transcriptomics, Text Mining, Microarrays, Metabolomics, and Others), Sector (Medical Biotechnology, Animal Biotechnology, Plant Biotechnology, Environmental Biotechnology, Forensic Biotechnology, and Others) - Industry Trends and Forecast to 2029.",\r\n',
        ],
        publishDate: '2023-06-07T00:00:00',
        imageURl: '2020/255b6629-0506-406a-a9d6-9f25a8751883.png',
        reportUrl: 'north-america-ai-in-bioinformatics-market',

        tableOfContent: [
          '1 INTRODUCTION',
          '1.1 OBJECTIVES OF THE STUDY',
          '1.2 MARKET DEFINITION',
          '1.3 OVERVIEW OF NORTH AMERICA AI IN BIOINFORMATICS MARKET',
          '1.4 LIMITATIONS',
          '1.5 MARKETS COVERED',
          '2 MARKET SEGMENTATION',
          '2.1 MARKETS COVERED',
          '2.2 GEOGRAPHICAL SCOPE',
          '2.3 YEARS CONSIDERED FOR THE STUDY',
          '2.4 CURRENCY AND PRICING',
          '2.5 DBMR TRIPOD DATA VALIDATION MODEL',
          '2.6 MULTIVARIATE MODELLING',
          '2.7 OFFERING LIFELINE CURVE',
          '2.8 PRIMARY INTERVIEWS WITH KEY OPINION LEADERS',
          '2.9 DBMR MARKET POSITION GRID',
          '2.1 MARKET SECTOR COVERAGE GRID',
          '2.11 VENDOR SHARE ANALYSIS',
          '2.12 SECONDARY SOURCES',
          '2.13 ASSUMPTIONS',
          '3 EXECUTIVE SUMMARY',
          '4 PREMIUM INSIGHTS',
          '4.1 PESTEL ANALYSIS',
          "4.2 PORTER'S FIVE FORCES",
          '5 NORTH AMERICA AI IN BIOINFORMATICS MARKET :MARKET OVERVIEW',
          '5.1 DRIVERS',
          '5.1.1 REDUCTION IN THE GENETIC SEQUENCING COST',
          '5.1.2 GROWING DEMAND FOR BIOINFORMATICS',
          '5.1.3 INCREASING PUBLIC-PRIVATE SECTOR FUNDING FOR BIOINFORMATICS',
          '5.2 RESTRAINTS',
          '5.2.1 HIGH COST OF INSTRUMENTATION',
          '5.2.2 CYBERSECURITY CONCERNS IN BIOINFORMATICS',
          '&emsp;',
          '5.3 OPPORTUNITIES',
          '5.3.1 RISE IN HEALTHCARE EXPENDITURE',
          '5.3.2 STRATEGIC INITIATIVES BY KEY PLAYERS',
          '5.3.3 ADVANCEMENT IN BIOINFORMATICS TECHNOLOGY',
          '5.4 CHALLENGES',
          '5.4.1 LACK OF SKILLED PROFESSIONALS TO PERFORM AI-BASED BIOINFORMATICS TECHNOLOGY',
          '5.4.2 CHALLENGES OF IMPLEMENTING BIOINFORMATIC TECHNOLOGY IN THE CLINICAL LAB',
          '&emsp;',
          '6 NORTH AMERICA AI IN BIOINFORMATICS MARKET, BY OFFERING',
          '6.1 OVERVIEW',
          '6.2 SERVICES',
          '6.3 SOFTWARE',
          '6.4 OTHERS',
          '&emsp;',
          '7 NORTH AMERICA AI IN BIOINFORMATICS MARKET, BY TYPE',
          '7.1 OVERVIEW',
          '7.2 MACHINE LEARNING',
          '7.3 DEEP LEARNING',
          '7.4 OTHERS',
          '&emsp;',
          '8 NORTH AMERICA AI IN BIOINFORMATICS MARKET, BY PRODUCTS AND SERVICES',
          '8.1 OVERVIEW',
          '8.2 BIOINFORMATICS SERVICES',
          '8.2.1 DATA ANALYSIS',
          '8.2.2 SEQUENCING SERVICES',
          '8.2.3 DATABASE MANAGEMENT',
          '8.2.4 OTHERS',
          '8.3 BIOINFORMATICS PLATFORMS',
          '8.3.1 SEQUENCE ANALYSIS PLATFORMS',
          '8.3.2 SEQUENCE ALIGNMENT PLATFORMS',
          '8.3.3 STRUCTURAL ANALYSIS PLATFORMS',
          '8.3.4 SEQUENCE MANIPULATION PLATFORMS',
          '8.3.5 OTHERS',
          '8.4 KNOWLEDGE MANAGEMENT TOOLS',
          '&emsp;',
          '8.4.1 GENERALIZED KNOWLEDGE MANAGEMENT TOOLS',
          '8.4.2 SPECIALIZED KNOWLEDGE MANAGEMENT TOOLS',
          '&emsp;',
          '9 NORTH AMERICA AI IN BIOINFORMATICS MARKET, BY APPLICATION',
          '9.1 OVERVIEW',
          '9.2 GENOMICS',
          '9.3 CHEMOINFORMATICS &amp; DRUG DESIGN',
          '9.4 PROTEOMICS',
          '9.5 DNA SEQUENCING',
          '9.6 SYSTEM BIOLOGY',
          '9.7 TRANSCRIPTOMICS',
          '9.8 TEXT MINING',
          '9.9 MICROARRAYS',
          '9.1 METABOLOMICS',
          '9.11 OTHERS',
          '&emsp;',
          '10 NORTH AMERICA AI IN BIOINFORMATICS MARKET, BY SECTOR',
          '10.1 OVERVIEW',
          '10.2 MEDICAL BIOTECHNOLOGY',
          '10.3 ANIMAL BIOTECHNOLOGY',
          '10.4 PLANT BIOTECHNOLOGY',
          '10.5 ENVIRONMENTAL BIOTECHNOLOGY',
          '10.6 FORENSIC BIOTECHNOLOGY',
          '10.7 OTHERS',
          '&emsp;',
          '11 NORTH AMERICA AI IN BIOINFORMATICS MARKET, BY COUNTRY',
          '11.1 U.S.',
          '11.2 CANADA',
          '11.3 MEXICO',
          '&emsp;',
          '12 NORTH AMERICA AI IN BIOINFORMATICS MARKET: COMPANY LANDSCAPE',
          '&emsp;',
          '12.1 COMPANY SHARE ANALYSIS: NORTH AMERICA',
          '13 SWOT ANALYSIS',
          '14 COMPANY PROFILE',
          '14.1 ILLUMINA, INC.',
          '14.1.1 COMPANY SNAPSHOT',
          '14.1.2 REVENUE ANALYSIS',
          '14.1.3 PRODUCT PORTFOLIO',
          '14.1.4 RECENT DEVELOPMENTS',
          '&emsp;',
          '14.2 DASSAULT SYSTEMES',
          '14.2.1 COMPANY SNAPSHOT',
          '14.2.2 REVENUE ANALYSIS',
          '14.2.3 PRODUCT PORTFOLIO',
          '14.2.4 RECENT DEVELOPMENT',
          '14.3 EUROFINS SCIENTIFIC',
          '14.3.1 COMPANY SNAPSHOT',
          '14.3.2 REVENUE ANALYSIS',
          '14.3.3 PRODUCT PORTFOLIO',
          '14.3.4 RECENT DEVELOPMENT',
          '14.4 THERMO FISHER SCIENTIFIC INC.',
          '14.4.1 COMPANY SNAPSHOT',
          '14.4.2 REVENUE ANALYSIS',
          '14.4.3 PRODUCT PORTFOLIO',
          '14.4.4 RECENT DEVELOPMENT',
          '14.5 QIAGEN',
          '14.5.1 COMPANY SNAPSHOT',
          '14.5.2 PRODUCT PORTFOLIO',
          '14.5.3 RECENT DEVELOPMENT',
          '14.6 ARDIGEN',
          '14.6.1 COMPANY SNAPSHOT',
          '14.6.2 PRODUCT PORTFOLIO',
          '14.6.3 RECENT DEVELOPMENT',
          '14.7 BIOMAX INFORMATICS AG',
          '14.7.1 COMPANY SNAPSHOT',
          '14.7.2 PRODUCT PORTFOLIO',
          '14.7.3 RECENT DEVELOPMENT',
          '14.8 CELBRIDGESCIENCE.',
          '14.8.1 COMPANY SNAPSHOT',
          '14.8.2 PRODUCT PORTFOLIO',
          '14.8.3 RECENT DEVELOPMENTS',
          '14.9 DNASTAR',
          '14.9.1 COMPANY SNAPSHOT',
          '14.9.2 PRODUCT PORTFOLIO',
          '14.9.3 RECENT DEVELOPMENT',
          '14.1 ELUCIDATA',
          '14.10.1 COMPANY SNAPSHOT',
          '14.10.2 PRODUCT PORTFOLIO',
          '14.10.3 RECENT DEVELOPMENT',
          '&emsp;',
          '14.11 INDX. AI.',
          '14.11.1 COMPANY SNAPSHOT',
          '14.11.2 PRODUCT PORTFOLIO',
          '14.11.3 RECENT DEVELOPMENTS',
          '14.12 INSILICO MEDICINE',
          '14.12.1 COMPANY SNAPSHOT',
          '14.12.2 PRODUCT PORTFOLIO',
          '14.12.3 RECENT DEVELOPMENTS',
          '14.13 JADBIO &amp;GNOSIS DATA ANALYSIS',
          '14.13.1 COMPANY SNAPSHOT',
          '14.13.2 PRODUCT PORTFOLIO',
          '14.13.3 RECENT DEVELOPMENTS',
          '14.14 NEOGENOMICS LABORATORIES',
          '14.14.1 COMPANY SNAPSHOT',
          '14.14.2 REVENUE ANALYSIS',
          '14.14.3 PRODUCT PORTFOLIO',
          '14.14.4 RECENT DEVELOPMENT',
          '14.15 PAIGE AI, INC.',
          '14.15.1 COMPANY SNAPSHOT',
          '14.15.2 PRODUCT PORTFOLIO',
          '14.15.3 RECENT DEVELOPMENT',
          '14.16 SOMALOGIC OPERATING CO., INC.',
          '14.16.1 COMPANY SNAPSHOT',
          '14.16.2 PRODUCT PORTFOLIO',
          '14.16.3 RECENT DEVELOPMENT',
          '14.17 SOPHIA GENETICS',
          '14.17.1 COMPANY SNAPSHOT',
          '14.17.2 PRODUCT PORTFOLIO',
          '14.17.3 RECENT DEVELOPMENT',
          '14.18 SOURCE BIOSCIENCE.',
          '14.18.1 COMPANY SNAPSHOT',
          '14.18.2 PRODUCT PORTFOLIO',
          '14.18.3 RECENT DEVELOPMENT',
          '15 QUESTIONNAIRE',
          '16 RELATED REPORTS',
        ],
      },
      {
        id: 'HCS5f7c081b-8b50-4a10-9e87-88c972914453',
        report_title: 'Global Health Insurance Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Health Insurance Market, By Type (Product, Solutions), Services (Inpatient Treatment, Outpatient Treatment, Medical Assistance, Others), Level of Coverage (Bronze, Silver, Gold, Platinum), Service Providers (Public Health Insurance Providers, Private Health Insurance Providers), Health Insurance Plans (Point of Service (POS), Exclusive Provider Organization (EPOS), Indemnity Health Insurance, Health Savings Account (HSA), Qualified Small Employer Health Reimbursement Arrangements (QSEHRAS), Preferred Provider Organization (PPO), Health Maintenance Organization (HMO), Others), Demographics (Adults, Minors, Senior Citizens), Coverage Type (Lifetime Coverage, Term Coverage), End User (Corporates, Individuals, Others) Distribution Channel (Direct Sales, Financial Institutions, E-commerce, Hospitals, Clinics, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-05-29T00:00:00',
        imageURl: '2020/255b6629-0506-406a-a9d6-9f25a8751883.png',
        reportUrl: 'global-health-insurance-market',
      },
      {
        id: 'HCSd9db4cb0-5bd3-4a7e-a0dd-7c153f36f61c',
        report_title:
          'Global Dental Practice Management Software Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Dental Practice Management Software Market, By Delivery Mode (Cloud-Based Delivery Mode, Hybrid Delivery Mode, and On Premise Delivery Mode), Component (Patient Management, Records Managements, Finance &amp; Accounting, Analytical And Dashboards and Others), Deployment (Windows, MacOS, iOS, Android and Others), Interface Type (Biometric Device Interface, QR Code Scanner Interface, Barcode Scanner Interface and RFID Interface), End User (Hospitals, Dental Clinics, and Others), Distribution Channel (Direct Tender and Third Party Distributors) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-05-11T00:00:00',
        imageURl: '2020/255b6629-0506-406a-a9d6-9f25a8751883.png',
        reportUrl: 'global-dental-practice-management-software-market',
      },
      {
        id: 'HCSac18a0cf-960a-41a7-b1b3-0c5f0c8f4c37',
        report_title:
          'Europe Dental Practice Management Software Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Europe Dental Practice Management Software Market, By Delivery Mode (Cloud-Based Delivery Mode, Hybrid Delivery Mode, and On Premise Delivery Mode), Component (Patient Management, Records Managements, Finance &amp; Accounting, Analytical And Dashboards and Others), Deployment (Windows, MacOS, iOS, Android and Others), Interface Type (Biometric Device Interface, QR Code Scanner Interface, Barcode Scanner Interface and RFID Interface), End User (Hospitals, Dental Clinics, and Others), Distribution Channel (Direct Tender and Third Party Distributors) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-05-11T00:00:00',
        imageURl: '2020/255b6629-0506-406a-a9d6-9f25a8751883.png',
        reportUrl: 'europe-dental-practice-management-software-market',
      },
      {
        id: 'HCSebf491a9-79f5-40d2-865b-93d1066e3be3',
        report_title:
          'Asia-Pacific Dental Practice Management Software Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Asia-Pacific Dental Practice Management Software Market, By Delivery Mode (Cloud-Based Delivery Mode, Hybrid Delivery Mode, and On Premise Delivery Mode), Component (Patient Management, Records Managements, Finance &amp; Accounting, Analytical And Dashboards and Others), Deployment (Windows, MacOS, iOS, Android and Others), Interface Type (Biometric Device Interface, QR Code Scanner Interface, Barcode Scanner Interface and RFID Interface), End User (Hospitals, Dental Clinics, and Others), Distribution Channel (Direct Tender and Third Party Distributors) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-05-11T00:00:00',
        imageURl: '2020/255b6629-0506-406a-a9d6-9f25a8751883.png',
        reportUrl: 'asia-pacific-dental-practice-management-software-market',
      },
      {
        id: 'HCSfb63e529-94e1-4b97-9a19-1c75d03a9403',
        report_title:
          'Middle East and Africa Dental Practice Management Software Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Middle East and Africa Dental Practice Management Software Market, By Delivery Mode (Cloud-Based Delivery Mode, Hybrid Delivery Mode, and On Premise Delivery Mode), Component (Patient Management, Records Managements, Finance &amp; Accounting, Analytical And Dashboards and Others), Deployment (Windows, MacOS, iOS, Android and Others), Interface Type (Biometric Device Interface, QR Code Scanner Interface, Barcode Scanner Interface and RFID Interface), End User (Hospitals, Dental Clinics, and Others), Distribution Channel (Direct Tender and Third Party Distributors) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-05-11T00:00:00',
        imageURl: '2020/255b6629-0506-406a-a9d6-9f25a8751883.png',
        reportUrl: 'middle-east-and-africa-dental-practice-management-software-market',
      },
      {
        id: 'HCS6c06d288-3c77-4b4c-9c0e-6a0ad0519baf',
        report_title:
          'North America Dental Practice Management Software Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'North America Dental Practice Management Software Market, By Delivery Mode (Cloud-Based Delivery Mode, Hybrid Delivery Mode, and On Premise Delivery Mode), Component (Patient Management, Records Managements, Finance &amp; Accounting, Analytical And Dashboards and Others), Deployment (Windows, MacOS, iOS, Android and Others), Interface Type (Biometric Device Interface, QR Code Scanner Interface, Barcode Scanner Interface and RFID Interface), End User (Hospitals, Dental Clinics, and Others), Distribution Channel (Direct Tender and Third Party Distributors) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-05-11T00:00:00',
        imageURl: '2020/255b6629-0506-406a-a9d6-9f25a8751883.png',
        reportUrl: 'north-america-dental-practice-management-software-market',
      },
      {
        id: 'HCS02f1e63b-bf95-4678-a88a-39b0d7ab1b0c',
        report_title: 'Global Medical Coding Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Medical Coding Market, By Classification System (International Classification of Diseases (ICD) and Healthcare Common Procedure Code System (HCPCS), Current Procedural Terminology), Component (In-house, Outsourced), End-User (Hospitals, Diagnostic Centers, Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-05-08T00:00:00',
        imageURl: '2020/255b6629-0506-406a-a9d6-9f25a8751883.png',
        reportUrl: 'global-medical-coding-market',
      },
      {
        id: 'HCS69e21ba1-bc62-46bf-a5f4-bb76d2cf620e',
        report_title:
          'Australia and New Zealand Healthcare Logistics Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Australia and New Zealand Healthcare Logistics Market, By Services (Transportation, Packaging, Storage and Warehouse, Monitoring and Others), Temperature (Ambient, Chilled/Refrigerated, Frozen And Cryogenic), Logistics Type (Sea Freight Logistics, Air Freight Logistics, Overland Logistics), Application (Medicine, Bulk Drug Handlers,&nbsp; Patient Logistics, Vaccine,&nbsp; Biological Material And Organs, Hazardous Cargo and Others), End User (Biopharmaceutical Companies, Medical Devices Companies, Reference and Diagnostic Laboratories, Hospitals &amp; Clinics, Academic and Research Institutes Emergency Medical Services Companies and Others), Distribution Channel (Third Party and Conventional Logistics) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-03-23T00:00:00',
        imageURl: '2020/255b6629-0506-406a-a9d6-9f25a8751883.png',
        reportUrl: 'australia-and-new-zealand-healthcare-logistics-market',
      },
      {
        id: 'HCSa7b5ab5a-df8f-4f69-9c35-1b0fe44c0f48',
        report_title:
          'Mexico Private Health Insurance Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Mexico Private Health Insurance Market, By Product Type (Into Mediclaim Insurance, Hospitalization Coverage Insurance, Critical Illness Insurance, Individual Coverage Insurance, Family Floater Coverage Insurance, Senior Citizen Coverage Insurance, Unit Linked Health Plans, Permanent Health Insurance, and Others), Business Solution (Lead Generation Solutions, Revenue Management and Billing Solutions, Robotic Process Automation, Insurance Cloud Solutions, Claims Administration Cloud Solutions, Value-Based Payments Solutions, Artificial Intelligence</a> &amp; Blockchain Solutions, Intelligent Case Management Solutions, and Others), Assistance/Service Type (Inpatient/Daycare, Hospital Accommodation, Medical Evacuation, Oncology, Rehabilitation Treatment, Palliative Care, Organ Transplant, Psychiatry and Psychotherapy, Laser Eye Treatment, Accidental Death Benefit, Maternity, Dental, Out-Patient, Repatriation Plan, and Others), Level Of Coverage (Bronze, Silver, Gold, and Platinum), Type Of Insurance Plans (Point Of Service (POS), Exclusive Provider Organization (EPOS), Indemnity Health Insurance, Health Savings Account (HSA), Qualified Small Employer Health Reimbursement Arrangements (QSEHRAS), Preferred Provider Organization (PPO), Health Maintenance Organization (HMO), and Others), Demographics (Adults, Minors, and Senior), Coverage Type (Lifetime Coverage and Term Coverage), End User (Family, Corporate, Individual, and Others), Distribution Channel (Direct Sales, Financial Institutions, E-Commerce, Hospitals, Clinics, and Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-03-21T00:00:00',
        imageURl: '2020/255b6629-0506-406a-a9d6-9f25a8751883.png',
        reportUrl: 'mexico-private-health-insurance-market',
      },
      {
        id: 'HCSe0b39659-5f7d-4a8c-9b74-8a3ce529e0a5',
        report_title:
          'North America Electronic Medical Records (EMR) Market – Industry Trends and Forecast to 2029',
        short_discription: [
          'North America Electronic Medical Records (EMR) Market, By Component (Software and Services), Type (Traditional EMR, Speech Enabled EMR, Interoperable EMR, and Others), Application (Specialty Application and General Application), End User (Hospitals, General Physician Clinics, Specialized Clinics, Ambulatory Surgical Centres</a>, and Others), Delivery Mode (Client Based Setups, Cloud Based Setups, and Hybrid Setups), Industry Trends and Forecast to 2029.',
        ],
        publishDate: '2023-03-09T00:00:00',
        imageURl: '2020/255b6629-0506-406a-a9d6-9f25a8751883.png',
        reportUrl: 'north-america-electronic-medical-records-emr-market',
      },
    ],
  },
  {
    id: '97f8a529-6d3b-4b48-9437-af30f21cc267',
    title: 'ICT',
    industry: [
      {
        id: 'ICT43b4f10d-5a2e-234-9a9c-063c1e3c7d5b',
        report_title: 'Global Managed File Transfer (MFT) Software Services Market',
        short_discription: [
          "Managed file transfer software and services market is anticipated to be worth around US$ 1,654.3 Million in 2022, with the market reaching over US$ 5,257.3 Million in 2033. It is also projected to be worth US$ 1,813.1 Million in 2023 and is estimated to grow at a CAGR of 11.2% from 2023 ot 2033.Managed File Transfer (MFT) is a type of software that is designed to securely exchange files between organizations or individuals. It is a technology that provides a centralized platform for managing and automating the movement of data between disparate systems, both within and outside an organization's network Managed file transfer market accounts for the 25% of the file transfer market.MFT solutions typically include features such as encryption, authentication, access control, and audit trails to ensure the confidentiality, integrity, and traceability of file transfers. MFT solutions can also support complex workflows, automate file processing tasks, and provide visibility and monitoring capabilities to track the status of file transfers.Increasing demand for secured file transferring systems along with various regulatory are the major factors influencing the growth of the managed file transfer market. Many industries such as finance, healthcare, and government are subject to regulatory requirements that mandate the secure transfer of sensitive data. MFT solutions can help organizations comply with these regulations and avoid potential penalties or fines",
        ],
        publishDate: '2023-10-30T00:00:00',
        imageURl: '2020/8124fa52-19cb-440e-a6c1-d2bada7b6581.png',
        reportUrl: 'europe-business-process-as-a-service-bpaas-market',
      },
      {
        id: 'ICT43b4f10d-5a2e-4a8e-9a9c-063c1e3c7d5b',
        report_title:
          'Europe Business Process as a Service (BPaaS) Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Europe Business Process as a Service (BPaaS) Market, By Solutions (Platform, Services), Deployment Model (Public Cloud, Private Cloud and Hybrid Cloud), Business Processes (Human Resources (HR &amp; Payroll), Finance &amp; Accounting, Sales &amp; Marketing, Procurement &amp; Supply Chain Management, Customer Services &amp; Support (Customer Experience), Operations, Analytics, and Others), Organization Size (Large Organization and Small and Medium-Sized Enterprises (SMES)), Application (Managerial, Operational, and Supportive and Connected Market Area), Cloud Delivery Model (SAAS Based Process Services (SAASBP Services), PAAS Based Business Process Services (PAAS BP Services) and IAAS Business Process Services (IAAS BP Services)), End User (Banking, Financial Services and Insurance (BFSI), IT &amp; Telecommunication, Retail &amp; E-Commerce, Healthcare, Government, Manufacturing, Media &amp; Entertainment, and Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-30T00:00:00',
        imageURl: '2020/8124fa52-19cb-440e-a6c1-d2bada7b6581.png',
        reportUrl: 'europe-business-process-as-a-service-bpaas-market',
      },
      {
        id: 'ICT6f8a8269-0c85-46db-9f2a-9ac45ab3e546',
        report_title:
          'North America Modular Data Center Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'North America Modular Data Center Market, By Component (Solution and Services), Type (Full Functional, Partial Fabricated, and Micro Data Centers), Application (Smarter Data Centers, Capacity Expansion, High Performance/ Edge Computing</a>, Disaster Recovery, Emergency and Temporary Deployment, and Data Center Expansion), Deployment Size (Large Size Data, Medium Size Data Center, and Small Size Data Center), Tier Type (Tier 4, Tier 3, Tier 2, and Tier 1), Location Type (Outdoor and Indoor), Vertical (BFSI, IT &amp; Telecom, Energy &amp; Utilities, Industrial, Government &amp; Defense, Manufacturing, Healthcare, Transport &amp; Logistics, Media &amp; Entertainment, Retail, Education, and Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-30T00:00:00',
        imageURl: '2020/8124fa52-19cb-440e-a6c1-d2bada7b6581.png',
        reportUrl: 'north-america-modular-data-center-market',
      },
      {
        id: 'ICTd3728246-80d1-4d80-bb65-0e70e13a5e3e',
        report_title:
          'Asia-Pacific Virtual Reality (VR) Health Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Asia-Pacific Virtual Reality (VR) Health Market, By Product (Hardware, Software And Services), Technology (Head-Mounted Technology, Gesture-Tracking Technology, Projector &amp; Display Walls Technology, Mobile Device-Based And Others), Application (Medical Training And Education, Virtual Reality Exposure Therapy, Surgery, Patient Care Management, Rehabilitation, Pain Management And Others), Therapeutic Area (Craniofacial, Cardiovascular, Ophthalmology, Pulmonary, Neurological, Sleep Disorders, Phobias, Addictions, Posttraumatic Stress Disorder (PTSD) And Others), End User (Hospitals, Clinics, Surgical Centres, Ambulatory Surgical Centers</a>, Diagnostic Centers, Rehabilitation Centers, Long-Term Care Facilities, Mental Health Institutions And Others), Distribution Channel (Direct Tenders, Retail Sales And Third Party Distributor) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-27T00:00:00',
        imageURl: '2020/8124fa52-19cb-440e-a6c1-d2bada7b6581.png',
        reportUrl: 'asia-pacific-virtual-reality-vr-health-market',
      },
      {
        id: 'ICT1c7f2b5a-6c32-48e2-877e-536983682ca9',
        report_title: 'Global Application Security Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Application Security Market, By Solution (Web apps, Mobile apps), Service (Professional, Managed), Testing (Static Application Security Testing, Dynamic Application Security Testing, Interactive Application Security Testing), Deployment (On-cloud, On-Premise), Organization (Large Enterprises, Small and Medium Enterprises), End-user (Government and Defense, Banking, Financial Services and Insurance, IT and Telecom, Healthcare, Retail, Education, Other Verticals) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-27T00:00:00',
        imageURl: '2020/8124fa52-19cb-440e-a6c1-d2bada7b6581.png',
        reportUrl: 'global-application-security-market',
      },
      {
        id: 'ICTe9154483-fbe0-4c0e-9019-2c925d8df746',
        report_title: 'Global Insight Engines Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Insight Engines Market, By Insight Type (Prescriptive Insights, Predictive Insights, Descriptive Insights), Deployment Type (Cloud, On-Premises), Component (Tools, Services), Application (Workforce Management,</a> Customer Experience Management, Operations Management, Risk and Compliance Management, Sales and Marketing Management, Others), Organization Size (Large Enterprises, Small and Medium-Sized Enterprises), Industry Vertical (Telecom and IT, Banking, Financial Services, Insurance, Retail and E-commerce, Healthcare, Manufacturing, Government, Media and Entertainment, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-26T00:00:00',
        imageURl: '2020/8124fa52-19cb-440e-a6c1-d2bada7b6581.png',
        reportUrl: 'global-insight-engines-market',
      },
      {
        id: 'ICT02d34720-6a65-4ad1-8c1b-924f85815c1f',
        report_title:
          'Global Digital Out of Home (OOH) Advertising Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Digital Out of Home (OOH) Advertising Market, By Location (Indoor and Outdoor), Product (Digital Billboard, Digital Signage, Digital Screens and Others), Application (Street Furniture, Public Location Based, Transit and Others), End-User (Retail, Automotive, Banking and Financial Services, Insurance (BFSI), Food and Beverages, Healthcare, Education, Government, Public Sector and Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-26T00:00:00',
        imageURl: '2020/8124fa52-19cb-440e-a6c1-d2bada7b6581.png',
        reportUrl: 'global-digital-out-of-home-ooh-advertising-market',
      },
      {
        id: 'ICTc2c8991a-581f-4a3f-b0d5-6a2f6bc1d9d1',
        report_title:
          'Global Digital Ray Substation Automation Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global &nbsp;digital ray substation automation market, By Component (Substation Automation System, Communication Network, Electrical System, Recloser Controller, Programmable Logical Controller, Capacitor Bank Controller, Digital Transducer/Smart Meter, Load Tap Controller, Communication Channel, Digital Relays, Protection Devices and Monitoring &amp; Control System), Module (IEDS, Communication Network and  System), Communication Channel (Ethernet, Power Line Communication, Copper Wire Communication and Optical Fiber Communication), Application (Utility, Steel, Mining, Oil and Gas and Transportation) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-25T00:00:00',
        imageURl: '2020/8124fa52-19cb-440e-a6c1-d2bada7b6581.png',
        reportUrl: 'global-digital-ray-substation-automation-market',
      },
      {
        id: 'ICTa10c39e5-270a-4d68-a66a-317f6a7e2a2d',
        report_title:
          'Global Enterprise Content Management Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Enterprise Content Management Market, By Solutions (Content Management, Case Management, Workflow Management, Record Management, Digital Asset Management, Other Types of Management, e-Discovery, Imaging and Capturing, Others), Deployment (On- Premise, Cloud Based), Organization Size (Large and Small and Medium-Sized Enterprises), Service (Professional Services, Managed Services), End User Industry (Telecom and IT, Banking Financial Services and Insurance (BFSI), Retail, Education, Manufacturing, Media and Entertainment, Government, Healthcare, Other End-user Industries) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-25T00:00:00',
        imageURl: '2020/8124fa52-19cb-440e-a6c1-d2bada7b6581.png',
        reportUrl: 'global-enterprise-content-management-market',
      },
      {
        id: 'ICTca04a688-2a5d-4e4c-b309-62b6e65cf13c',
        report_title:
          'Europe Education Personal Computer (PC) Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Europe Education Personal Computer (PC) Market, By Product Type (Desktop, Laptop, Tablet, and Chromebook), Operating System (Window, Mac OS, and Others), Storage (512 GB to 1 TB, 256 GB to 512 GB, Above 1 TB, and Less than 256 GB), Memory (8 GB, 16 GB, 4 GB, and 32 GB), Screen Size (14 Inches &amp; Above, 12 To 14 Inches, Under 12 Inches), Resolution (Full HD, HD, and Others), Age Group (Above 18, 15-18, 11 to 14, 5 to 10), Application (Digital Content Access, Video Conferencing/Remote Learning Access, Modeling and Simulation, Digital Content Creation, Programming &amp; Coding, and Others), Price Range (Mid-Range High Range, and Low Range), Distribution Channel (Direct Sales and Resellers), End User (Colleges/Universities, Schools and Others) - Industry Trends and Forecast to 2030',
        ],
        publishDate: '2023-10-23T00:00:00',
        imageURl: '2020/8124fa52-19cb-440e-a6c1-d2bada7b6581.png',
        reportUrl: 'europe-education-personal-computer-pc-market',
      },
      {
        id: 'ICTf16db4b3-44d3-40a7-b471-e0be2b1b0ba4',
        report_title:
          'Global Mobile Apps and Web Analytics Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Mobile Apps and Web Analytics Market, By Component (Solution and Service), Deployment (On-Premises and Cloud-Based), Vertical (Banking Financial Services and Insurance, Healthcare, Transportation and Logistics, Media and Entertainment, IT and Telecom and Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-20T00:00:00',
        imageURl: '2020/8124fa52-19cb-440e-a6c1-d2bada7b6581.png',
        reportUrl: 'global-mobile-apps-and-web-analytics-market',
      },
      {
        id: 'ICTf670c1ea-39bb-475c-8e3a-23e93bc67dd9',
        report_title: 'Global Edge Analytics Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Edge Analytics Market, By Component (Solutions and Services), Type (Descriptive Analytics, Predictive Analytics</a>, Prescriptive Analytics and Diagnostic Analytics), Business Application (Marketing, Sales, Operations, Finance and Human Resources), Deployment Model (On-Premises and On-Cloud), Vertical (Healthcare and Life Sciences, Banking, Financial Services, and Insurance, Manufacturing, Retail and Consumer Goods, IT and Telecommunication, Transportation and Logistics, Media and Entertainment, Energy and Utility, Government and Defense, Travel and Hospitality and Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-20T00:00:00',
        imageURl: '2020/8124fa52-19cb-440e-a6c1-d2bada7b6581.png',
        reportUrl: 'global-edge-analytics-market',
      },
      {
        id: 'ICT2cf97d9b-6b2c-4167-b6d1-f39cf2db3278',
        report_title:
          'Global Cloud Services Brokerage Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Cloud Services Brokerage Market, By Service Type (Operations Management, Catalog Management, Workload Management, Integration, Reporting and Analytics, Security and &nbsp;Compliance, Training and Consulting, Support and Maintenance), Platform (Internal Brokerage Enablement, External Brokerage Enablement), Organization Size (SMEs, Large Enterprises), Deployment Model (Public Cloud, Private Cloud, Hybrid Cloud), Industrial Vertical (BFSI, Healthcare and &nbsp;Life Sciences, IT and Telecommunications, Retail and Consumer Goods, Manufacturing, Government and Public Sector, Media and Entertainment, Energy and Utilities, Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-20T00:00:00',
        imageURl: '2020/8124fa52-19cb-440e-a6c1-d2bada7b6581.png',
        reportUrl: 'global-cloud-services-brokerage-market',
      },
      {
        ICT: 'ICTc787cfd2-7fe3-4c67-aac6-5a7bcf4ea98d',
        report_title: 'Global Geospatial Analytics Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Geospatial Analytics Market, By Component (Software and Solutions and Services), Type (Surface and Field Analytics, Network and Location Analytics</a>, Geo-Visualization and Others), Application (Surveying, Medicine and Public Safety, Disaster Risk Reduction and Management, Climate Change Adaptation and Others), Vertical (Business, Automotive, Utility and Communication, Government, Defense and Intelligence, Natural Resources and Others), Technology (Remote Sensing, Geographical Positioning System (GPS), Geographic Information System (GIS) and Other Geospatial Analytics Technologies) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-19T00:00:00',
        imageURl: '2020/8124fa52-19cb-440e-a6c1-d2bada7b6581.png',
        reportUrl: 'global-geospatial-analytics-market',
      },
      {
        id: 'ICTbd19b2d1-e6da-44a3-8f7c-6a4659e5d3f0',
        report_title: 'Global Video Analytics System Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Video Analytics System Market, By Type (Software, Services), Deployment (On-Premises, Cloud), Application (Incident Detection, Crowd Management, Intrusion Management, Traffic Monitoring, Automatic Number Plate Recognition, Facial Recognition, Others), Vertical (BFSI, City Surveillance, Critical Infrastructure, Education, Hospitality and Entertainment, Manufacturing, Defense and Border Security, Retail and Consumer Goods, Traffic Management, Transportation, Others), Organization Size (Small and Medium Enterprise, Large Enterprise) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-19T00:00:00',
        imageURl: '2020/8124fa52-19cb-440e-a6c1-d2bada7b6581.png',
        reportUrl: 'global-video-analytics-system-market',
      },
      {
        id: 'ICT5675c746-eeb1-4a85-a7cc-f67d7f5fbfbd',
        report_title:
          'Global Application Performance Monitoring Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Application Performance Monitoring Market, By Deployment Type (On- Premises, Cloud, Hybrid), Access Type (Web, Mobile), End-User (BFSI, E-Commerce, Manufacturing, Healthcare, Retail, IT and Telecommunications, Media and Entertainment, Academics, Government, Others), Solution Type (Software, Service) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-19T00:00:00',
        imageURl: '2020/8124fa52-19cb-440e-a6c1-d2bada7b6581.png',
        reportUrl: 'global-application-performance-monitoring-market',
      },
      {
        id: 'ICT7a82ed5e-b42a-4463-bcd1-4e4d56f2553a',
        report_title:
          'Global Cloud Infrastructure Services Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Cloud Infrastructure Services Market, By Service (Storage as a Service, Compute as a Service, Backup as a Service, Desktop as a Service, Networking as a Service and Managed Hosting), Deployment (Public Cloud, Private Cloud and Hybrid Cloud), Organization Size (SMEs and Large Enterprises), Vertical (IT and Telecommunications, BFSI, Retail, Energy, Manufacturing, Healthcare, Media and Entertainment and Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-19T00:00:00',
        imageURl: '2020/8124fa52-19cb-440e-a6c1-d2bada7b6581.png',
        reportUrl: 'global-cloud-infrastructure-services-market',
      },
      {
        id: 'ICT4c662f7f-d849-4fbd-bc4d-86a76238be77',
        report_title:
          'Global Poker Lottery Online Gambling Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'By Device Type (Desktop, Mobile), End User (Gambling Enthusiasts, Social Exuberant, Dabblers) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-18T00:00:00',
        imageURl: '2020/8124fa52-19cb-440e-a6c1-d2bada7b6581.png',
        reportUrl: 'global-poker-lottery-online-gambling-market',
      },
      {
        id: 'ICT2bcdfb09-e579-475a-8c84-7a45d25ad1ee',
        report_title:
          'Middle East and Africa IoT (Internet of Things) for Public Safety Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Middle East and Africa IoT (Internet of Things) for Public Safety Market, By Component (Solutions, Platform, Service), Application (Surveillance and Security, Disaster Management, Critical Infrastructure Security), End User (Industrial IoT, Connected Healthcare, Smart Transportation, Smart Utilities, Smart Building and Home Automation, Homeland Security, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-18T00:00:00',
        imageURl: '2020/8124fa52-19cb-440e-a6c1-d2bada7b6581.png',
        reportUrl: 'middle-east-and-africa-iot-internet-of-things-for-public-safety-market',
      },
      {
        id: 'ICTea59e3c8-4d7c-4da1-8e5a-71c1b7b356b4',
        report_title:
          'Global Workflow Management System Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Workflow Management System Market, By Component (Software, Services), Deployment Type (Cloud, On-Premises) Industry Vertical (Banking Financial Services and Insurance, Public Sector, Healthcare, Energy and Utilities, Retail, IT and Telecom, Travel and Hospitality, Transportation and Logistics, Education, Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-18T00:00:00',
        imageURl: '2020/8124fa52-19cb-440e-a6c1-d2bada7b6581.png',
        reportUrl: 'global-workflow-management-system-market',
      },
      {
        id: 'ICT4f3a85e8-5db2-4f3e-8f82-63e3ef7abfed',
        report_title:
          'Global Artificial Intelligence (AI) Insurtech Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Artificial Intelligence (AI) Insurtech Market, By Component (Hardware, Software, Services), Technology (Machine Learning and Deep Learning, Natural Language Processing (NLP), Machine Vision, Robotic Automation), Deployment Model (On-Premises, Cloud), Enterprises Size (Large Enterprises, SMEs Enterprises), Application (Claims Management, Risk Management and Compliance, Chatbots, Others), Sector (Life Insurance, Health Insurance, Title Insurance, Auto Insurance, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-17T00:00:00',
        imageURl: '2020/8124fa52-19cb-440e-a6c1-d2bada7b6581.png',
        reportUrl: 'global-artificial-intelligence-ai-insurtech-market',
      },
    ],
  },
  {
    id: '8e9d4de5-2899-4b57-a36d-9f3f6d1e0a3b',
    title: 'Food and Beverages',
    industry: [
      {
        id: 'FABd5a5a92b-3f12-4b6c-8e37-8bc4ef9e2aef',
        report_title:
          'Global Food Grade Paraffin Wax Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Food Grade Paraffin Wax Market, By Application (Candles, Packaging, Cosmetics, Hotmelts, Board Sizing, Rubber, and Others), Product Type (Fully-Refined Food grade paraffin wax, Semi Refined Food Grade Paraffin Wax, and Others), Form (Liquid Food Grade Paraffin Wax, Solid Food grade Paraffin Wax, and Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-30T00:00:00',
        imageURl: '2020/47d8dc6c-1df5-4893-892b-0518c0f5235f.png',
        reportUrl: 'global-food-grade-paraffin-wax-market',
      },
      {
        id: 'FAB8f9c1cde-7dc1-4a20-924e-67fde62b56a1',
        report_title: 'Global Craft Beer Market - Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Craft Beer Market, By Product Type (Ale, Lagers, Specialty Beers, and Others), Distribution Channel (On- Trade, and Off- Trade), Age Group (21&ndash;35 Years Old, 40&ndash;54 Years Old, and 55 Years and Above) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-30T00:00:00',
        imageURl: '2020/47d8dc6c-1df5-4893-892b-0518c0f5235f.png',
        reportUrl: 'global-craft-beer-market',
      },
      {
        id: 'FAB2e6f8b47-9a73-4348-aa27-15c7ae1a4812',
        report_title: 'Global Vitamin K Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Vitamin K Market, By Type (Vitamin K1, Vitamin K2), Application (Osteoporosis, Vitamin-K Dependent Clotting Factor Deficiency [VKCFD], Dermal Applications, Prothrombin Deficiency, Vitamin K Deficiency Bleeding [VKDB]), Route of Administration (Oral, Topical, Parenteral), Mode of Consumption (Pills, Powders, Creams, Liquids) - Industry Trends and Forecast to 2023 to 2030.',
        ],
        publishDate: '2023-10-27T00:00:00',
        imageURl: '2020/47d8dc6c-1df5-4893-892b-0518c0f5235f.png',
        reportUrl: 'global-vitamin-k-market',
      },
      {
        id: 'FABb5b6b950-61e8-43d2-b4cd-3fae0e7c3a7d',
        report_title: 'Global Packaged Fruit Juice Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Packaged Fruit Juice Market, By Type (100% Fruit Juice, Nectars, Juice Drinks, Concentrates, Powdered Juice, Others), Flavours (Orange, Apple, Mango, Mixed Fruit, Others), Distribution Channel (Supermarkets, Hypermarkets) - Industry Trends and Forecast to 2023 to 2030.',
        ],
        publishDate: '2023-10-27T00:00:00',
        imageURl: '2020/47d8dc6c-1df5-4893-892b-0518c0f5235f.png',
        reportUrl: 'global-packaged-fruit-juice-market',
      },
      {
        id: 'FABc3e4d79a-86d7-4b12-ae9e-2f076546bb63',
        report_title:
          'Global Gluten-Free Bakery Products Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Gluten-Free Bakery Products Market, By Ingredients (Main Ingredients, Other Ingredients), Product Type (Bread, Rolls and Buns, Cakes and Cheese Cakes, Muffins and Cup Cakes, Cookies and Biscuits, Doughnuts, Sandwiches and Wraps, Dough and Ready Mixes, Others), Distribution Channel (Store-Based, Non-Store Based) - Industry Trends and Forecast to 2023 to 2030.',
        ],
        publishDate: '2023-10-27T00:00:00',
        imageURl: '2020/47d8dc6c-1df5-4893-892b-0518c0f5235f.png',
        reportUrl: 'global-gluten-free-bakery-products-market',
      },
      {
        id: 'FAB9e17b268-5fe5-4fe1-8629-d2ef2cc7cf9a',
        report_title: 'Global Rice Protein Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Rice Protein Market, By Product (Rice Protein Isolates, Rice Protein Concentrate, Others), Form (Organic and Inorganic), Application (Food and Beverage,Animal Feed, Pharmaceuticals, Cosmetics) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-26T00:00:00',
        imageURl: '2020/47d8dc6c-1df5-4893-892b-0518c0f5235f.png',
        reportUrl: 'global-rice-protein-market',
      },
      {
        id: 'FABf10e9b14-c3f6-4e61-8f86-df8c10822880',
        report_title: 'Global Rice Starch Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Rice Starch Market, By Product (Food Grade, Cosmetic Grade, and Pharmaceutical Grade), Application (Bakery Goods &amp; Bakery Fillings, Confectionary Coatings &amp; Liquorice, Dairy Dessert &amp; Yogurt, Dairy Fruit Preparations, Body Powder, Dry Shampoo, and Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-26T00:00:00',
        imageURl: '2020/47d8dc6c-1df5-4893-892b-0518c0f5235f.png',
        reportUrl: 'global-rice-starch-market',
      },
      {
        id: 'FAB4b9a5e97-0ec5-46d4-bd69-45a356f5b3f8',
        report_title: 'Global Orange Juices Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Orange Juices Market, By Storage Requirement (Normal Temperature and Chilled), Products (Juice, Nectar, and Still Drinks), Distribution Channel (Supermarkets and Hypermarkets, Convenience Stores, Specialty Food Stores, and Online Retail) - Industry Trends and Forecast to 2023 to 2030.',
        ],
        publishDate: '2023-10-26T00:00:00',
        imageURl: '2020/47d8dc6c-1df5-4893-892b-0518c0f5235f.png',
        reportUrl: 'global-orange-juices-market',
      },
      {
        id: 'FAB5d33e9bc-cc66-4a79-99cf-1eaac854c648',
        report_title: 'Global Egg Yolk Replacer Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Egg Yolk Replacer Market, By Form (Dry, Liquid), Source (Plant, Animal), Ingredient (Dairy Proteins, Starch, Algal Flour, Soy-based Products), Application (Bakery &amp; Confectionery, Savouries, Sauces, Dressings &amp; Spreads) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-26T00:00:00',
        imageURl: '2020/47d8dc6c-1df5-4893-892b-0518c0f5235f.png',
        reportUrl: 'global-egg-yolk-replacer-market',
      },
      {
        id: 'FAB7a4d31e3-8f76-4573-ae97-f2d3d7001aef',
        report_title: 'Global Frozen Bakery Product Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Frozen Bakery Product Market, By Product Type (Breads &amp; Rolls, Pizza &amp; Pizza Crust, Cakes &amp; Pastries,Cookies &amp; Biscuits, and others), Category (Conventional and Gluten-Free), Distribution Channel (Supermarket/Hypermarket, Convenience Stores, Cafes &amp; Bakery Shops, Online, and Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-26T00:00:00',
        imageURl: '2020/47d8dc6c-1df5-4893-892b-0518c0f5235f.png',
        reportUrl: 'global-frozen-bakery-product-market',
      },
      {
        id: 'FABe8f67821-c9f9-45fb-9077-3157b06ec485',
        report_title:
          'North America Poultry Probiotic Ingredients Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'North America Poultry Probiotic Ingredients Market, By Product (Bacteria Based and Fungi and Yeast Based), Type (Single Strain and Multiple Strain), Form (Dry and Liquid), Function (Feed Intake and Efficiency, Yield, Gut Health, Nutrition, Egg Production and Quality Immunity and Others), Application (Broilers, Layers, Breeders, Chicks and Poults, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-26T00:00:00',
        imageURl: '2020/47d8dc6c-1df5-4893-892b-0518c0f5235f.png',
        reportUrl: 'north-america-poultry-probiotic-ingredients-market',
      },
      {
        id: 'FABbb0c58b7-50ec-4a11-9c05-623d23bb1034',
        report_title: 'Global Robusta Coffee Beans Market - Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Robusta Coffee Beans Market, By End User (Food and Beverage, Pharmaceuticals, Personal Care), Distribution Channel (Online, Offline) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-25T00:00:00',
        imageURl: '2020/47d8dc6c-1df5-4893-892b-0518c0f5235f.png',
        reportUrl: 'global-robusta-coffee-beans-market',
      },
      {
        id: 'FAB6a18359c-335e-4c96-a0d4-95a4c66f7a3f',
        report_title:
          'Global Frozen Fruits and Vegetables Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Frozen Fruits and Vegetables Market, By Type (Fruits and Vegetable), Category (Organic and Conventional), Form (Whole, Sliced and Cubed and Others), Distribution Channel (Store-Based, Non-Store-Based) - Industry Trends and Forecast to 2023 to 2030.',
        ],
        publishDate: '2023-10-25T00:00:00',
        imageURl: '2020/47d8dc6c-1df5-4893-892b-0518c0f5235f.png',
        reportUrl: 'global-frozen-fruits-and-vegetables-market',
      },
      {
        id: 'FAB3f9ed7cf-3ec3-45a0-a2f4-3b4d3657c3a6',
        report_title: 'Global Gluten-Free Products Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Gluten-Free Products Market, By Source (Animal Source, Plant Source), Type (Bakery Products, Snacks &amp; RTE Products, Pizzas &amp; Pastas, Condiments &amp; Dressings, Seasonings &amp; Spreads, Desserts &amp; Ice Creams, Rice, Others), Distribution Channel (Convenience Stores, Specialty Stores, Drugstores &amp; Pharmacies) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-25T00:00:00',
        imageURl: '2020/47d8dc6c-1df5-4893-892b-0518c0f5235f.png',
        reportUrl: 'global-gluten-free-products-market',
      },
      {
        id: 'FAB22c399d4-8f9d-4eaa-8a05-8373d4857b1a',
        report_title: 'Global Milk Powder Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Milk Powder Market, By Type (Whole Milk Powder, Skimmed Milk Powder, Dairy Whitener, Buttermilk Powder, Fat-Filled Milk Powder, and Other Milk Powder), End Use (Nutritional Food, Infant Formulas, Confectionaries, Baked Sweets, Savories, Household, Foodservice Industry and Others), Distribution Channel (Business to Business, Business to Consumer) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-25T00:00:00',
        imageURl: '2020/47d8dc6c-1df5-4893-892b-0518c0f5235f.png',
        reportUrl: 'global-milk-powder-market',
      },
      {
        id: 'FABc91dd798-20c7-4d7b-9cb8-1246a2bfa4a2',
        report_title:
          'Global Freeze Dried Fruits and Vegetables Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Freeze Dried Fruits and Vegetables Market, By Type (Fruits and Vegetable), Form (Powders and Granules, Chunks and Pieces and Flakes), Distribution Channel (Supermarkets and Hypermarkets, Convenience Stores, Online Retail and Other Distribution Channel), Application (Breakfast Cereal​, Soups and Snacks​, Ice Cream and Desserts​, Bakery and Confectionery​, Dips and Dressings​ and Others) - Industry Trends and Forecast to 2023 to 2030.',
        ],
        publishDate: '2023-10-25T00:00:00',
        imageURl: '2020/47d8dc6c-1df5-4893-892b-0518c0f5235f.png',
        reportUrl: 'global-freeze-dried-fruits-and-vegetables-market',
      },
      {
        id: 'FABd6dcb40a-4f98-4d0e-b56b-65a3c0dbd4d2',
        report_title:
          'Global Prenatal Vitamin Supplement Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Prenatal Vitamin Supplement Market, By Dosage (Powder, Capsule, Gummy), Distribution Channel (Hospital Pharmacies, Online Pharmacies, Supermarkets, Drug Stores), Product Type (Folic Acid, Iron Supplements, Calcium Supplements, Essential Fatty Acid, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-20T00:00:00',
        imageURl: '2020/47d8dc6c-1df5-4893-892b-0518c0f5235f.png',
        reportUrl: 'global-prenatal-vitamin-supplement-market',
      },
      {
        id: 'FABa651292c-8a23-41ef-95b2-8659ea17fcdd',
        report_title:
          'Global Nitrogen, Phosphorus and Potassium (NPK) Fertilizers Market - Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Nitrogen, Phosphorus And Potassium (NPK) Fertilizers Market, By Fertilizers (Feed-grade, Food-grade), Application (Bakery Products, Dairy Products, Meat and Meat Products, Beverages), Form (Powder, Liquid, Others), Type (Nitrogen, Phosphorus, Potassium, Others), Crop Type (Cereals and Grains, Oilseeds and Pulses, Fruits and Vegetables and Others), Mode of Application (Fertigation, Foliar, Others), Livestock (Ruminants, Poultry, Swine, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-20T00:00:00',
        imageURl: '2020/47d8dc6c-1df5-4893-892b-0518c0f5235f.png',
        reportUrl: 'global-npk-fertilizers-market',
      },
      {
        id: 'FABf48ed67d-647f-4f12-89af-964c74a6e2a1',
        report_title: 'Global Low-Fat Cheese Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Low-Fat Cheese Market, By Product (Feta, Ricotta, Mozzarella Sticks, Hard Cheese), Distribution Channel (Store-Based, Online), Application (Biscuits, Snacks, Soups, Sauces, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-20T00:00:00',
        imageURl: '2020/47d8dc6c-1df5-4893-892b-0518c0f5235f.png',
        reportUrl: 'global-low-fat-cheese-market',
      },
      {
        id: 'FAB0c0c0a7d-5a68-4ee5-b5da-9f3844bfe5b3',
        report_title: 'Global Wagyu Beef Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Wagyu Beef Market, By Product Type (Japanese Breed, Australian Breed, Cross Breed and Others), Applications (Direct To Human Use, Industrial Use), Nature (Organic, Conventional), Distribution Channels (Online, Retail) - Industry Trends and Forecast to 2023 to 2030.',
        ],
        publishDate: '2023-10-20T00:00:00',
        imageURl: '2020/47d8dc6c-1df5-4893-892b-0518c0f5235f.png',
        reportUrl: 'global-wagyu-beef-market',
      },
    ],
  },
  {
    id: '1dc938c6-6e29-4e4b-82b2-92d7a417754a',
    title: 'Chemical Materials',
    industry: [
      {
        id: 'CM4b8f1c2-9d32-4a6e-8f20-9b5e2a846d72',
        report_title:
          'The global plastic injection molding machine market was estimated to be worth USD 9.79 billion in 2022 and is projected to grow at a CAGR of 4.54% from 2023 to 2031',
        short_discription: [
          'A plastic injection molding machine is a device designed to manufacture plastic parts by injecting molten plastic material into a mold. It works by melting plastic granules and then forcefully injecting the molten plastic into a mold cavity, where it cools and solidifies into the final part. These machines are widely used in various industries for producing a vast range of plastic products, from simple household items to complex automotive components. Their efficiency, ability to produce large volumes, and capability to manufacture intricate designs make them essential in the plastic manufacturing sector.',
          'The major segments covered in the plastic injection molding machine market are:',
          'By Machine Type: Hydraulic, All-Electric, Hybrid',
          'By End-Use Industry: Automotive, Consumer Goods, Packaging, Healthcare, Electricals & Electronics, Others',
          'By Region: APAC, North America, Europe, Middle East & Africa, South America',
          'Rapid industrialization is driving the growth of the plastic injection molding machine market. Industrialization is the process of transforming economies from primarily agricultural to one based on the manufacturing of goods and services. Plastic injection molding machines are integral to industrialization by enabling mass production of plastic products, fostering manufacturing efficiency and innovation. In December 2023, the 29th International Yearbook of Industrial Statistics by UNIDO shows a global industrial sector growth of 2.3%, indicating a post-pandemic recovery in areas including manufacturing, mining, and utilities.',
          'In February 2023, Sumitomo Heavy Industries Ltd., a Japan-based machinery manufacturing company launched the 20-ton iM18E compact hybrid injection molding machine, using approximately 21% less space than similar models. It combines a hydraulic clamp with an electric plasticizing unit for reduced power consumption and improved precision, especially suitable for connectors and electronic parts, achieving a high injection speed of 600 mm/s. Additionally, its hybrid drive system and energy-saving technologies significantly lower energy usage, supporting sustainability efforts, while its design focuses on reducing operational costs and boosting productivity, including features for material cost savings and optional efficiency enhancements.',
          "In December 2022, Duy Tan Plastic Company has enhanced its manufacturing capabilities by acquiring 12 JSW machines from Japan Steel Works, Ltd. This strategic investment in cutting-edge Japanese technology positions the company to deliver superior quality products to its clientele. The new equipment underscores Duy Tan's commitment to innovation and excellence in production. As a result, customers will benefit from the advanced quality and reliability of the company's offerings.",
        ],

        publishDate: '2023-10-31T00:00:00',
        imageURl: '2020/146fd0fa-b837-4dd7-87a0-4789727ac7c1.png',
        reportUrl: 'global-tackifier-market',
      },
      {
        id: 'CMaf8c3b79-2c19-4f69-8b1e-9d3f1f156b39',
        report_title: 'Global Tackifier Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Tackifier Market, By Product (Hydrocarbon Resins, Rosin Resins, and Terpene Resins), Feedstock (Synthetic Tackifiers, Natural/Biobased Tackifiers), Form (Solid, Liquid, and Resin Dispersion), Application (Pressure Sensitive Adhesives (PSA), Hot Melt Adhesives (HMA), and Others), End-User (Packaging, Construction/Assembly, Nonwoven, Bookbinding, and Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-31T00:00:00',
        imageURl: '2020/146fd0fa-b837-4dd7-87a0-4789727ac7c1.png',
        reportUrl: 'global-tackifier-market',
      },
      {
        id: 'CM5d6cfa56-bfe1-4d6d-8a21-3e925e7b10ef',
        report_title:
          'Global Methylene Diphenyl Diisocyanate (MDI), Toluene Diisocyanate (TDI) and Polyurethane Market - Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Methylene Diphenyl Diisocyanate (MDI), Toluene Diisocyanate (TDI) and Polyurethane Market,&nbsp;By Type (Toluene Diisocyanate (TDI), Methylene Diphenyl Diisocyanate (MDI)), Raw Material (Crude Oil, Natural Gas, Toluene, Benzene,&nbsp;Nitric Acid, Methanol, Chlorine, Others), Application (Polyurethane Foam, Polyurethane Elastomers, Polyurethane Adhesives and Sealants, Polyurethane Coatings, Others), End Use (Footwear, Furniture, Automotive, Building And Construction, Home Appliances, Textile, Healthcare, Electrical and Electronics, Packaging, Industrial Machinery, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-27T00:00:00',
        imageURl: '2020/146fd0fa-b837-4dd7-87a0-4789727ac7c1.png',
        reportUrl: 'global-mdi-tdi-polyurethane-market',
      },
      {
        id: 'CM1a3c3c88-45d6-4f5a-8e18-cb61e1e7af6e',
        report_title: 'North America Specialty Gas Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'North America Specialty Gas Market, By Type (High Purity Gases, Noble Gases, Carbon Gases, Halogen Gases, and Others), Ingredients (Argon, Bromine, Nitrogen, Helium, Carbon Monoxide, Xenon, Methane, Krypton, Oxygen, Neon, Hydrogen, and Others), Application (Manufacturing, Electronics, Healthcare, Academics, Analytical &amp; Calibration, Refrigeration, and Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-26T00:00:00',
        imageURl: '2020/146fd0fa-b837-4dd7-87a0-4789727ac7c1.png',
        reportUrl: 'north-america-specialty-gas-market',
      },
      {
        id: 'CM9b7db89f-3b1f-4599-83cf-ef4a7747c2f7',
        report_title: 'Global Acrylic Monomers Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Acrylic Monomers Market, By Product (Acrylate,Acrylic Acids and Salts, Bisphenol Acrylics, Polyfunctional Acrylics, Fluorinated Acrylics, Acrylonitrile, Acrylamide and Methacrylamide, Malemide, Carbohydrate Monomers, and Others), Application (Plastic, Adhesives and Sealants, Synthetic Resins, Acrylic Fibers, Building Materials, Fabrics, Acrylic Rubber, and Others), End Use (Paints and Coatings, Building and Construction, Automotive, Consumer Goods, Packaging, Water Treatment, Marine, Aerospace, and Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-23T00:00:00',
        imageURl: '2020/146fd0fa-b837-4dd7-87a0-4789727ac7c1.png',
        reportUrl: 'global-acrylic-monomers-market',
      },
      {
        id: 'CMf0fe6f71-c1b5-45a2-964f-9d24e2c4b3d9',
        report_title: 'Global Rust Remover Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Rust Remover Market, By Product Type (Acid Rust Removers, Neutral Rust Removers, Alkaline Rust Removers), Application (Automotive, Aviation and Aerospace, Oil and Gas/Petrochemical, Marine, Construction and Infrastructure, Metal Machining, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-20T00:00:00',
        imageURl: '2020/146fd0fa-b837-4dd7-87a0-4789727ac7c1.png',
        reportUrl: 'global-rust-remover-market',
      },
      {
        id: 'CM7e043f8a-9420-4cf9-bf53-6e7816e58c86',
        report_title:
          'Global Ultra-High-Purity Anhydrous Hydrogen Chloride (HCl) Gas Market - Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Ultra-High-Purity Anhydrous Hydrogen Chloride (HCl) Gas Market, By Product Type (Pharmaceutical Grade, Electronic Grade), Application (Chemical, Electronics, Steel and Metals, Pharmaceutical and Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-20T00:00:00',
        imageURl: '2020/146fd0fa-b837-4dd7-87a0-4789727ac7c1.png',
        reportUrl: 'global-ultra-high-purity-anhydrous-hydrogen-chloride-hcl-gas-market',
      },
      {
        id: 'CMcb8589d2-d10d-44cc-8db9-1760a1fc843a',
        report_title: 'Global Silicon Metal Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Silicon Metal Market Global Silicon Metal, By Product Type (Metallurgy Grade, Chemical Grade), Application (Aluminum Alloys, Semiconductors, Solar Panels, Stainless Steel, Other Applications) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-19T00:00:00',
        imageURl: '2020/146fd0fa-b837-4dd7-87a0-4789727ac7c1.png',
        reportUrl: 'global-silicon-metal-market',
      },
      {
        id: 'CM26fe523f-04c2-44d8-bbf2-9a6e49c003e8',
        report_title: 'Global Industrial Enzymes Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Industrial Enzymes Market, By Type (Carbohydrases, Proteases, Phytases, Polymerases and Nucleases, Lipases, Others), Form (Liquid, Dry), Source (Plant, Animal, Micro-organism), Application (Food Processing, Animal Feed, Healthcare, Textiles, Leather Processing, Detergents and Cleaners, Bio-Fuel, Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-19T00:00:00',
        imageURl: '2020/146fd0fa-b837-4dd7-87a0-4789727ac7c1.png',
        reportUrl: 'global-industrial-enzymes-market',
      },
      {
        id: 'CM9128bf2a-8e5e-4707-9a6a-408745f2f5b9',
        report_title:
          'Global Thermoplastic Polyurethane Market - Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Thermoplastic Polyurethane Market, By Raw Material (Diisocyanate, Polyols, Diols, Others), Type (Polyester, Polyether, Polycaprolactone), End-User (Footwear, Industrial Machinery, Automotive, Electronics, Medical, Consumer Goods, Sports and Leisure, Building and Construction, Textile, Heavy Engineering, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-19T00:00:00',
        imageURl: '2020/146fd0fa-b837-4dd7-87a0-4789727ac7c1.png',
        reportUrl: 'global-thermoplastic-polyurethane-market',
      },
      {
        id: 'CMa5fcf43a-3c8d-4d02-ae3e-1c647fd9f2ea',
        report_title: 'Global Iron Ore Mining Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Iron Ore Mining Market Global Iron Ore Mining Market, By Type (Iron Ore Mining Fines, Iron Ore Mining Pellets, Iron Ore Pellet Feed, Others), End Users (Construction, Transportation, Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-19T00:00:00',
        imageURl: '2020/146fd0fa-b837-4dd7-87a0-4789727ac7c1.png',
        reportUrl: 'global-iron-ore-mining-market',
      },
      {
        id: 'CMd22e3a3d-0bbf-4ef4-b073-6d74d1188374',
        report_title: 'Global Sulfate of Potash Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Sulfate of Potash Market, By Product (Standard SOP, Granular SOP, Soluble SOP), Crop Type (Fruits and Vegetables, Cereals and Grains,Oilseeds and Pulses, Others), Application (Agricultural, Industrial, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-18T00:00:00',
        imageURl: '2020/146fd0fa-b837-4dd7-87a0-4789727ac7c1.png',
        reportUrl: 'global-sulfate-potash-market',
      },
      {
        id: 'CMbc5b8c5f-1c07-4990-92a7-4b796256c8c2',
        report_title:
          'Global Carbon Fiber Reinforced Thermoplastic Composites (CFRTP) Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Carbon Fiber Reinforced Thermoplastic Composites (CFRTP) Market, By Resin Type (Polyamide (PA), Polycarbonate (PC), Polyphenylene Sulfide (PPS)), Product Type (Carbon Fiber, Long Carbon Fiber and Short Carbon Fiber), Applications (Aerospace, Automotive and Consumer Durables) - Industry Trends and Forecast to 2023 to 2030.',
        ],
        publishDate: '2023-10-17T00:00:00',
        imageURl: '2020/146fd0fa-b837-4dd7-87a0-4789727ac7c1.png',
        reportUrl: 'global-cfrtp-market',
      },
      {
        id: 'CMd96a1b8d-7c3c-4b6b-95ab-ec3a5ecf6f32',
        report_title: 'Global Decorative Coatings Market - Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Decorative Coatings Market, By Product (Primer, Enamel, Emulsions, and Others), Resin Type (Acrylic, Alkyd, Polyurethane, Epoxy, Polyester, and Others), Formulation (Water-Based, and Solvent-Based), Substrate (Metal, Plastic, Glass, and Others), Coat Type (Top Coat, Base Coat, and Others), Application (Architectural, Automotive, Domestic Appliances, Medical, Sporting Goods, Jewellery, Cosmetics, and Aerospace) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-17T00:00:00',
        imageURl: '2020/146fd0fa-b837-4dd7-87a0-4789727ac7c1.png',
        reportUrl: 'global-decorative-coatings-market',
      },
      {
        id: 'CMf22d8ee9-7b92-4c95-bb22-7c0d55f5c52b',
        report_title: 'Global Flexitank Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Flexitank Market, By Product&nbsp; (Monolayer, Bi-layer, Multilayer), Type (Single Use, Reusable), Loading Type (Top Loading, Bottom Loading), Material (Polyethylene, Polypropylene, Others), Application (Food Grade Liquids, Non-Hazardous Chemicals, Industrial Liquids, Agricultural Liquids, Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-17T00:00:00',
        imageURl: '2020/146fd0fa-b837-4dd7-87a0-4789727ac7c1.png',
        reportUrl: 'global-flexitank-market',
      },
      {
        id: 'CM1bd47f78-e52b-4675-a053-8d650e84ff25',
        report_title: 'Global Sodium Formate Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Sodium Formate Market, By Product Type (Sodium Organic Salt, Ammonium Organic Salt, Zinc Organic Salt, Magnesium Organic Salt), Application (Aviation, Food and Beverage, Textile, Printing, Oil and Gas, Chemical, Electron, Agriculture, Industrial, Other Applications), End-Use (Catalyst, Reducing Agent, Manufacture of Sodium Hydrosulfite, Intermediate for the Manufacture of Formic Acid and Oxalic Acid, Manufacture of N,N-Dimethylformamide, De-icing Agent, Oilfield Services, Work-Over Fluids, Other End Uses) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-16T00:00:00',
        imageURl: '2020/146fd0fa-b837-4dd7-87a0-4789727ac7c1.png',
        reportUrl: 'global-sodium-formate-market',
      },
      {
        id: 'CM52e77779-3039-41d1-8eab-3de3193b4227',
        report_title:
          'Global Film Thickness Measurement Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Film Thickness Measurement Market, By Technology (Eddy Current, Magnetic Induction, Optical and Ultrasonic), Film Type (Dry Film and Wet Film), Instrument Type (Oxygen Permeability Tester, Gas Permeability Tester, Water Vapor Permeability Tester, Air Permeability Tester, Tensile Testing Machine, Heat Seal Tester, Coefficient of Friction Tester, Peel Tester, Box Compression Tester, Torque Tester, Hot Tack Tester, Thickness Measurement, Tearing Tester, Impact Tester, Heat Shrinkage Tester, Headspace Gas Analysing, Leak Tester, Flex Durability Tester, Adhesive Tester, Ink Rub Tester, Fogging Tester, Evaporation Residual Tester, Universal Testing Machine and Tensile Strength Tester), End-User (Displays and Monitor Developers, Polymer Coating Market, Encapsulation, Optics and Contact Lances, LED Designers, Cells and Batteries Manufacturer, Semiconductor Industry, Nano Materials, Glass Producers, Biometric Sensors Industry, Ophthalmic Industry) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-16T00:00:00',
        imageURl: '2020/146fd0fa-b837-4dd7-87a0-4789727ac7c1.png',
        reportUrl: 'global-film-thickness-measurement-market',
      },
      {
        id: 'CM30f3157d-2ea8-46e5-89ab-187f96b18ef5',
        report_title:
          'Europe and U.S. Cleaner and Degreaser Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Europe and U.S. Cleaner and Degreaser Market, By Product Type (Solvent Based and Water Based), Type (Heavy Duty Degreasers/Cleaners, Butyl Degreasers/Cleaners, Non-Butyl Degreasers/Cleaners, Natural Degreasers/Cleaners, and Others), Raw Material (Petroleum-Based Degreasers/Cleaners, Ammonia-Based Degreasers/Cleaners, Environmentally Compatible Degreasers/Cleaners, and Others), Form (Liquid, Solid, and Foam), Degreasing or Cleaning Methods (Manual and Automated), Packaging (Drum, Pail, Spray Bottle, >Aerosol Cans, and Others), End Use (General Manufacturing, Automotive, Food &amp; Beverages, Electrical/Utility, Building &amp; Construction, Aviation/Aerospace, Marine, Mining, Agriculture, and Others), Sales Channel (B2B and B2C) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-16T00:00:00',
        imageURl: '2020/146fd0fa-b837-4dd7-87a0-4789727ac7c1.png',
        reportUrl: 'europe-and-us-cleaner-and-degreaser-market',
      },
      {
        id: 'CMc0b1c36d-1c63-4aa5-a5ae-eebdf6e49a4d',
        report_title:
          'Europe Advanced Recycling Technologies Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Europe Advanced Recycling Technologies Market, By Technology (Pyrolysis/Cracking, Gasification, Depolymerization, and Microwave), Process Output (Naphtha, Heavy Gas Oil, and Wax Residue), End Use (Packaging, Infrastructure &amp; Construction, onsumer , and Automotive) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-16T00:00:00',
        imageURl: '2020/146fd0fa-b837-4dd7-87a0-4789727ac7c1.png',
        reportUrl: 'europe-advanced-recycling-technologies-market',
      },
      {
        id: 'CM3d2e29e9-6c5b-4e6a-a85a-c1ff13b8f149',
        report_title:
          'Global Personal Care Contract Manufacturing Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Personal Care Contract Manufacturing Market, By Formulation (Liquids, Creams, Lotions, Oils, Gels, Others), Service Type (R&amp;D and Formulation, Manufacturing, Packaging &amp; Allied Services), Product Type (Skincare, Haircare, and Make-up and cosmetics) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-15T00:00:00',
        imageURl: '2020/146fd0fa-b837-4dd7-87a0-4789727ac7c1.png',
        reportUrl: 'global-personal-care-contract-manufacturing-market',
      },
      {
        id: 'CMf0eb1e3b-d6c1-4348-823e-9e3c7915e9f8',
        report_title: 'Global Colour Cosmetics Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Colour Cosmetics Market, By Product Type (Chemical, Natural and Organic) Application (Nail Products, Lip Products, Eye Products, Facial Products, Hair Products and Others), Distribution Channel (Offline and Online), Target Market (Prestige Products Market and Mass Products Market) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-14T00:00:00',
        imageURl: '2020/146fd0fa-b837-4dd7-87a0-4789727ac7c1.png',
        reportUrl: 'global-colour-cosmetics-market',
      },
    ],
  },
  {
    id: 'd8651b5e-3d15-48cd-84a8-9b01fd784e38',
    title: 'Semiconductorn and Electronics',
    industry: [
      {
        id: 'SAEd9a8c7c1-6b8a-4f6d-803e-fcfaeb29be58',
        report_title: 'Global Radar Sensor Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Radar Sensor Market, By Type (Imaging Radar and Non-Imaging Radar), Band (HF, VHF, and HUF Bands; L, S, C, and X Bands; Ku, K, Ka, and V and W Bands), Range (Short-Range Radar Sensor, Medium-Range Radar Sensor, and Long-Range Radar Sensor), Component (Transmitter, Receiver, Processor, Antenna, and Video Amplifier), Technology (Time Domain Reflectometry, Ultra-Wideband, RF MEMS-based Radar Sensors, and Millimeter Wave), Application (Automotive, Aerospace and Defense, Industrial, Security and Surveillance, Traffic Monitoring and Management, Environmental and Weather Monitoring, and Other Applications), Vertical (Commercial, Government and Industrial) -Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-31T00:00:00',
        imageURl: '2020/b1150f35-dad5-43c6-8431-f2484eff5181.png',
        reportUrl: 'global-radar-sensor-market',
      },
      {
        id: 'SAE4e5f81cf-720d-43fd-b944-5b08d568fe87',
        report_title:
          'Global Image Based Barcode Reader Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Image Based Barcode Reader Market, By Product Type (Portable/Handheld Scanners, Rugged Scanners, PDA Scanners, Smart Phones, Automatic Readers, Cordless Scanners, Fixed-position Scanners, Stationary Scanners, and Presentation Scanners), Imaging Type (1D Image/ Code Scanning and 2D Image/ Code Scanning), Technology (S Mounted Lens, C Mounted Lens, and Autofocus / Liquid Based Lens), Connectivity (USB, Bluetooth, Wi-Fi, and PoE (Power over Ethernet)), End Use (Retail and Commercial, Manufacturing, Automotive, F&amp;B, Semiconductors, General Manufacturing, Health Care, Logistics &amp; Warehouse, Commercial Aviation, Defense, and Others), Sales Channel (Online and Offline) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-31T00:00:00',
        imageURl: '2020/b1150f35-dad5-43c6-8431-f2484eff5181.png',
        reportUrl: 'global-image-based-barcode-reader-market',
      },
      {
        id: 'SAEa1d9b3ca-8212-4bd8-af6a-9eebc32894f3',
        report_title:
          'North America Cargo Inspection Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'North America Cargo Inspection Market, By Industry Type (Mining, Metals, Agriculture, Oil, Gas,Petrochemicals, Chemicals, Machine Manufacturing, Automotive, Medical Devices Others), Type (LNG, LPG, Coal, Iron Ore, Grains, Fertilizers, Ro-Ro, Cruise Others), Offering (Product, Services), Inspection Type (Bunker Quantity Surveys, Hold &amp; Hatch Inspection Hold Survey, Draft Survey, Cargo Damage Survey, Cargo Lashing/ Handling/Logistics, Cargo Tally Verification Draft Survey, Cargo Survey, Pre-Shipment Inspection, Cargo Sampling (Oil), Cargo Measurement (Oil), Proof and Inspection of Asset, Pre-Vetting Inspection, Pre-Purchase Vessel Inspection, Vessel On/Off Hire Survey, Vessel Condition and Damage Survey, Cleanliness ISM Preparation Audit, Marine Warranty Survey Navigation Audit Others), Technology (Non-Intrusive Inspection Technology, Non-Destructive Evaluation (NDE) Technology), Port Types (Sea Ports, Inland Ports, Dry Ports, Warm Water Ports) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-27T00:00:00',
        imageURl: '2020/b1150f35-dad5-43c6-8431-f2484eff5181.png',
        reportUrl: 'north-america-cargo-inspection-market',
      },
      {
        SAE: 'SAEc4f7f8f3-8b90-47c3-8a8b-3bf7bde8dbf5',
        report_title: 'Global Smart Sensors Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Smart Sensors Market, By Sensor Type (Temperature and Humidity Sensors, Flow Sensors, Touch Sensors, Image Sensors, Motion and Occupancy Sensors, Water Sensors, Light Sensors, Position Sensors, Ultrasonic Sensors), Technology (MEMS, CMOS, SiP, SoC, Other Technology), Component (Analogue To Digital Converters (ADCs), Digital To Analogue Converters (DACs), Transceivers, Amplifiers, Microcontrollers, Others), Network Connectivity (Wired and Wireless), End Users (Industrial Automation, Biomedical and Healthcare, Consumer Electronics, Building Automation, Automotive, Aerospace and Defence, Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-27T00:00:00',
        imageURl: '2020/b1150f35-dad5-43c6-8431-f2484eff5181.png',
        reportUrl: 'global-smart-sensors-market',
      },
      {
        id: 'SAEc7e6a9db-cfa0-4f1f-9ebf-126e0cace1c1',
        report_title: 'Global Focused Ion Beam (FIB) Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Focused Ion Beam (FIB) Market, By Offering (Hardware, Software, Services, Research and Consulting Services), Tracking Type (Remote Tracking and Mobile Tracking), Application (Assistive Communication, and Human Behavior and Market Research, Others), Vertical (Retail and Advertisement, Consumer Electronics, Healthcare and Research Labs, Government, Defense, and Aerospace, Automotive and Transportation, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-26T00:00:00',
        imageURl: '2020/b1150f35-dad5-43c6-8431-f2484eff5181.png',
        reportUrl: 'global-focused-ion-beam-fib-market',
      },
      {
        id: 'SAEbc6e05d7-9f7e-40da-a1e4-d4f8e66dd769',
        report_title:
          'Middle East and Arica Proximity Sensor Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Middle East and Arica Proximity Sensor Market, By Technology (Inductive, Capacitive, Photoelectric, Magnetic, Ultrasonic and Others), Product (Fixed Distance and Adjustable Distance), Sensing Range (0mm-10mm, 10mm-40mm, 40mm-60mm, 60mm-80mm and Greater than 80mm), Channel Type (Single Channel and Multi-Channel), Application (Ground Proximity Warning System, Parking Sensor Systems, Assembly Line Automation, Anti-Aircraft Warfare, Roller Coasters, Vibration Monitoring System, Mobile Devices, Conveyor Systems, Detection of Object, Position, Inspection and Counting and Others), Type (Rectangular Inductive Sensor, Cylindrical Inductive Sensor, Ring Inductive Sensor and Slot Inductive Sensor), End-User (Aerospace and Defense, Automotive, Building Automation, Industrial, Consumer Electronics (Smartphones and Tablets), Food and Beverage, Pharmaceutical, Construction, Energy and Other) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-25T00:00:00',
        imageURl: '2020/b1150f35-dad5-43c6-8431-f2484eff5181.png',
        reportUrl: 'middle-east-and-arica-proximity-sensor-market',
      },
      {
        id: 'SAEb2e0c7d1-8d89-4cb2-80f5-bfe1967066461',
        report_title:
          'Global Rugged Industrial Display Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Rugged Industrial Display Market, By Product Type (Smartphone and Handheld Computer, Tablet PC, Laptop and Notebook, Avionics Display, Vehicle-mounted Computer, and Panel PC and Mission-critical Display), Type (Dot matrix, LED, LCD), Operating System (IOS, Windows, Android), Rugged(Semi Rugged, Fully Rugged, Ultra Rugged), End User (Government, Defence, Aerospace, Hospitals, Industrial, Commercial), Country (U.S., Canada, Mexico, Brazil, Argentina, Rest of South America, Germany, Italy, U.K., France, Spain, Netherlands, Belgium, Switzerland, Turkey, Russia, Rest of Europe, Japan, China, India, South Korea, Australia, Singapore, Malaysia, Thailand, Indonesia, Philippines, Rest of Asia-Pacific, Saudi Arabia, U.A.E, South Africa, Egypt, Israel, Rest of Middle East and Africa) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-25T00:00:00',
        imageURl: '2020/b1150f35-dad5-43c6-8431-f2484eff5181.png',
        reportUrl: 'global-rugged-industrial-display-market',
      },
      {
        id: 'SAE21d623f4-5c4b-4db5-9df2-e43773b899fd',
        report_title: 'Europe Proximity Sensor Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Europe Proximity Sensor Market, By Technology (Inductive, Capacitive, Photoelectric, Magnetic, Ultrasonic, Others), Product (Fixed Distance, Adjustable Distance), Sensing Range (0mm-10mm, 10mm-40mm, 40mm-60mm, 60mm-80mm, Greater than 80mm), Channel Type (Single Channel, Multi-Channel), Application (Ground Proximity Warning System, Parking Sensor Systems, Assembly Line Automation, Anti-Aircraft Warfare, Roller Coasters, Vibration Monitoring System, Mobile Devices, Conveyor Systems, Detection of Object, Position, Inspection and Counting, Others), Type (Rectangular Inductive Sensor, Cylindrical Inductive Sensor, Ring Inductive Sensor, Slot Inductive Sensor), End-User (Aerospace and Defense, Automotive, Building Automation, Industrial, Consumer Electronics, Food and Beverage, Pharmaceutical, Construction, Energy, Other) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-20T00:00:00',
        imageURl: '2020/b1150f35-dad5-43c6-8431-f2484eff5181.png',
        reportUrl: 'europe-proximity-sensor-market',
      },
      {
        id: 'SAE758c670b-98a2-4f82-bc93-8d212c01f1a5',
        report_title:
          'Global Hardware Security Modules Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Hardware Security Modules Market, By Type (LAN Based, PCIE Based, USB Based), Deployment Type (On-Premise, Cloud), Application (Payment Processing, Code and Document Signing, Secure Sockets Layer (SSL), Transport Layer Security (TLS), Authentication, Database Encryption, Credential Management, Application-Level Encryption), End-Users (BFSI, Government, Technology and Communication, Industrial and Manufacturing, Energy and Utility, Retail and Consumer Products, Healthcare and Life Sciences, Education, Entertainment, and Media, Automotive, Transportation, and Hospitality) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-20T00:00:00',
        imageURl: '2020/b1150f35-dad5-43c6-8431-f2484eff5181.png',
        reportUrl: 'global-hardware-security-modules-market',
      },
      {
        id: 'SAE5e6a5f9a-0d0a-44b3-9c89-2d84b2d5846e',
        report_title: 'Global MEMS Oscillator Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global MEMS Oscillator Market, By Band (MHz Band, kHz Band), Packaging Type (Surface-Mount Device Package, Chip-Scale Package), General Circuitry (Simple Packaged MEMS Oscillator (SPMO), Temperature-Compensated MEMS Oscillator (TCMO), Voltage-Controlled MEMS Oscillator (VCMO), Frequency Select MEMS Oscillator (FSMO), Digital-Controlled MEMS Oscillator (DCMO), Spread-Spectrum MEMS Oscillator (SSMO)), Application (Automotive, <a href="https://www.databridgemarketresearch.com/reports/global-consumer-electronics-market">Consumer Electronics</a>, Industrial, Mobile Devices, Military and Aerospace, Networking, Server, Storage, and Telecommunications, Wearables and Internet of Things, Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-20T00:00:00',
        imageURl: '2020/b1150f35-dad5-43c6-8431-f2484eff5181.png',
        reportUrl: 'global-mems-oscillator-market',
      },
      {
        id: 'SAEb0bb1a44-fc3d-41e0-b3b7-6f7d4cc89184',
        report_title:
          'Global Industrial Cooking Fire Protection Systems for Food Manufacturing Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Industrial Cooking Fire Protection Systems for Food Manufacturing Market, By Product (Fire Detection Systems and Fire Management Systems), Services (Engineering Services, Installation and Design Services, Maintenance Services, Managed Services and Other Services) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-19T00:00:00',
        imageURl: '2020/b1150f35-dad5-43c6-8431-f2484eff5181.png',
        reportUrl:
          'global-industrial-cooking-fire-protection-systems-market-food-manufacturing-market',
      },
      {
        id: 'SAEd29bfb0a-0d7b-4d88-b5bb-3e64c3982b69',
        report_title:
          'Global Automotive Testing, Inspection and Certification (TIC) Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Automotive Testing, Inspection and Certification (TIC) Market, By Service Type (Testing Services, Inspection Services, Certification Services, Other), Sourcing Type (In-House, Outsourced), Application (Electrical Systems and Components, Telematics, Vehicle Inspection Services, Homologation Testing, Interior and Exterior Materials, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-19T00:00:00',
        imageURl: '2020/b1150f35-dad5-43c6-8431-f2484eff5181.png',
        reportUrl: 'global-automotive-tic-market',
      },
      {
        id: 'SAE2e9f344f-4a69-46d4-80aa-4b1aef5b23f0',
        report_title:
          'Global End User Experience Monitoring (EUEM) Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global End User Experience Monitoring (EUEM) Market, By Component (Products and Services), Access Type (Web and Mobile), Vertical (BFSI, IT and Telecommunications, and Government and Public Sector) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-19T00:00:00',
        imageURl: '2020/b1150f35-dad5-43c6-8431-f2484eff5181.png',
        reportUrl: 'global-end-user-experience-monitoring-euem-market',
      },
      {
        id: 'SAEe08d2f11-5911-4714-b8f5-4f617ebd986f',
        report_title:
          'Europe Color Management and RIP (Raster Image Processors) Software for Digital Textile Printing Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Europe Color Management and RIP (Raster Image Processors) Software for Digital Textile Printing &nbsp;Market, By Offering (Software, Services), Application (Cost Controlling, Image Editing, Print Profiling or Layout Management, Color Management, Quality Control, Others), Printing Type (Screen Printing, Direct Printing, Dye Sublimation), Deployment Model (On-Premises, Cloud), Organization Size (Small and Medium Enterprises, Large Enterprises), End Product (Home D&eacute;cor, Fashion, Sportswear and Beachwear, Soft Signage, Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-19T00:00:00',
        imageURl: '2020/b1150f35-dad5-43c6-8431-f2484eff5181.png',
        reportUrl:
          'europe-color-management-and-rip-color-management-and-rip-software-for-digital-textile-printing-market',
      },
      {
        id: 'SAEc19cd461-dc1f-4e4f-9441-8f05a63769b5',
        report_title:
          'Middle East and Africa Digital Forensics Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Middle East and Africa Digital Forensics Market, Component (Hardware, Software and Tools, Services), Type (Computer Forensics, Mobile Device Forensics, Network Forensics, Database Forensics, Malware Forensics, Email Forensics, Automotive Forensics, Drone Forensics, Others), Application (Fraud Investigations, Intellectual Property Theft, Industrial Espionage, Forgery-Related Matters, Bankruptcy Investigations, Issues Concern with the Regulatory Compliance, Employment Disputes, Inappropriate use of the Internet and Email in the Workplace), Industry (Government and Defense, Banking, Financial Services, and Insurance, Telecom and IT, Retail, Transportation and Logistics, Law Firms, Healthcare, Media and Entertainment, Others), Mode of Purchase (Group Purchase, Individual), Distribution Channel (Third Party Distributor, Retail Sales, Online Sales, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-19T00:00:00',
        imageURl: '2020/b1150f35-dad5-43c6-8431-f2484eff5181.png',
        reportUrl: 'middle-east-and-africa-digital-forensics-market',
      },
      {
        id: 'SAEa99d8820-65cb-4787-92c3-140fde1fb267',
        report_title: 'Europe Digital Forensics Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Europe digital forensics market, By Component (Hardware, Software and Tools, Services), Type (Computer Forensics, Mobile Device Forensics, Network Forensics, Database Forensics, Malware Forensics, Email Forensics, Automotive Forensics, Drone Forensics, Others), Application (Fraud Investigations, Intellectual Property Theft, Industrial Espionage, Forgery-Related Matters, Bankruptcy Investigations, Issues Concern with the Regulatory Compliance, Employment Disputes, Inappropriate use of the Internet and Email in the Workplace), Industry (Government and Defense, Banking, Financial Services, and Insurance, Telecom and IT, Retail, Transportation and Logistics, Law Firms, Healthcare, Media and Entertainment, Others), Mode of Purchase (Group Purchase, Individual), Distribution Channel (Third Party Distributor, Retail Sales, Online Sales, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-19T00:00:00',
        imageURl: '2020/b1150f35-dad5-43c6-8431-f2484eff5181.png',
        reportUrl: 'europe-digital-forensics-market',
      },
      {
        id: 'SAE7e906ef7-2548-41a1-bf3e-3e23e2a5dfc2',
        report_title: 'Europe Nano Milling Equipment Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Europe Nano Milling Equipment Market, By Type (Ball Mills, Bead Mills, Jet Mills and Others), Milling Machines (Less than 50 nm and More than 50 nm), Motor Output Power (More than 150 Watt and Less than 150 Watt), Feed Dimension (More than 6mm and Less than 6mm), Weight (More than 100 Kg and Less than 100 Kg), End User (Pharmaceuticals, Cosmetics, Chemicals, Industrial, and Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-19T00:00:00',
        imageURl: '2020/b1150f35-dad5-43c6-8431-f2484eff5181.png',
        reportUrl: 'europe-nano-milling-equipment-market',
      },
      {
        id: 'SAEfa9c9a5e-c93a-482b-9c9f-6e39375c019d',
        report_title: 'Global Soft Robotics Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Soft Robotics Market, By Type (Soft Grippers, Co-Robots, Inflated Robots, Wearable Robots, Edible Robots and others), Components (Hardware, Software), Application (Medical and Healthcare, Food and Beverages, Logistics, Entertainment and Gaming, Automotive, Survey and Exploration and others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-18T00:00:00',
        imageURl: '2020/b1150f35-dad5-43c6-8431-f2484eff5181.png',
        reportUrl: 'global-soft-robotics-market',
      },
      {
        id: 'SAE4f7ea2f1-165f-41f2-947a-b5a131e08455',
        report_title:
          'Middle East and Africa Color Management and RIP (Raster Image Processors) Software for Digital Textile Printing Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Middle East and Africa Color Management and RIP (Raster Image Processors) Software for Digital Textile Printing Market, By Offering (Software and Services), Application (Cost Controlling, Image Editing, Print Profiling or Layout Management, Color Management, Quality Control, and Others), Printing Type (Screen Printing, Direct Printing, and Dye Sublimation), Deployment Model (On-Premises and Cloud), Organization Size (Small and Medium Enterprises and Large Enterprises), End Product (Home D&eacute;cor, Fashion, Sportswear and Beachwear, Soft Signage, and Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-18T00:00:00',
        imageURl: '2020/b1150f35-dad5-43c6-8431-f2484eff5181.png',
        reportUrl:
          'middle-east-and-africa-color-management-and-rip-color-management-and-rip-software-for-digital-textile-printing-market',
      },
      {
        id: 'SAEb2e0c7d1-8d89-4cb2-80f5-bfe19e066461',
        report_title:
          'Global Telecom Electronic Manufacturing Services Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Telecom Electronic Manufacturing Services Market, By Service (Electronic Design and Engineering, Electronics Assembly, Electric Manufacturing, Supply Chain Management, Others), Application (Large Enterprise, Small and Medium Enterprise) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-18T00:00:00',
        imageURl: '2020/b1150f35-dad5-43c6-8431-f2484eff5181.png',
        reportUrl: 'global-telecom-electronic-manufacturing-services-market',
      },
    ],
  },
  {
    id: 'dbe1bda9-593b-4e6c-9f39-5c7a2b24691b',
    title: 'Automotive',
    industry: [
      {
        id: 'AM9b1e3c6f-4a8d-491c-a877-6ef5bf81ec4d',
        report_title: 'Global Space Tourism Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Space Tourism Market, By Type (Sub-Orbital Space Tourism, Orbital Space Tourism, Lunar Tourism, Inter-Planetary Tourism, and Space Hotel Tourism), End User (Government, Commercial, and Others), HNW Communities (20M to 40M, 40M to 60M, and More than Above Ranges), and Sales Channel (Launch Provider, Third Party Partnership, and OTAs) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-31T00:00:00',
        imageURl: '2020/22e19f2f-fe4b-48bb-aa9a-e36acba1e240.png',
        reportUrl: 'global-space-tourism-market',
      },
      {
        id: 'BX9b1e3c6f-9a8d-491c-a776-6ef5bf81ec4d',

        report_title:
          'The global electric vehicles market was estimated to be worth USD254.41 billion in 2022 and is projected to grow at a CAGR of 19.83% from 2023 to 2031.',
        short_discription: [
          'Electric vehicles (EVs) are automobiles powered by electric motors, using energy typically stored in rechargeable batteries. They are designed to offer a cleaner alternative to gasoline-powered vehicles by reducing emissions related to air pollution and carbon footprint. EVs are increasingly used worldwide in personal transportation, public transport systems, and specialized applications like delivery and utility services.',
          'The major segments covered in the industrial automation market are:',
          'Electric vehicles (EVs) are automobiles powered by electric motors, using energy typically stored in rechargeable batteries. They are designed to offer a cleaner alternative to gasoline-powered vehicles by reducing emissions related to air pollution and carbon footprint. EVs are increasingly used worldwide in personal transportation, public transport systems, and specialized applications like delivery and utility services.',
          'The major segments covered in the industrial automation market are:',
          'By Components: Battery Cells & Packs, On-Board Charger, Motor, Reducer, Fuel Stack, Power Control Unit, Battery Management System, Fuel Processor, Power Conditioner, Air Compressor, Humidifier.',
          'By Vehicle Type: Passenger Cars, Commercial Vehicles, Other Vehicle Types.',
          'By Propulsion Type: Battery Electric Vehicles (BEVs), Plug-in Hybrid Electric Vehicles (PHEVs), Hybrid Electric Vehicles (HEVs), Fuel Cell Electric Vehicles (FCEVs).',
          'By Vehicle Class: Low-priced, Mid-priced, Luxury.',
          'By Top Speed: <125 MPH, >125 MPH.',
          'By Vehicle Drive Type: Front Wheel Drive, Rear Wheel Drive, All Wheel Drive.',
          'By EV Charging Point Type: Normal Charging, Super Charging.',
          'By Vehicle Connectivity: V2B or V2H, V2G, V2V, V2X.',
          'By End Use: Private, Commercial Fleets.',
          'By Region: North America, Europe, Asia Pacific, Latin America, Middle East and Africa.',
          'The growing adoption of electric vehicles is driving the growth of the industrial automation market. The adoption of electric vehicles is increasing primarily due to growing environmental concerns, advancements in technology, and supportive government policies. For instance, according to the International Energy Agency, a France-based Intergovernmental organization, in 2022, electric car sales exceeded 10 million, representing 14% of all new car sales, a significant increase from approximately 9% in 2021.',
          'In May 2023, Honda Motor Co. Ltd., a Japan-based automobile company launched the e: Ny1, a new electric SUV in Europe built on the e: N Architecture F platform, designed for the B-segment market with a focus on aerodynamics and a low center of gravity. It features a 201 hp motor and a 68.8 kWh battery that offers a 256-mile range and supports fast charging from 10% to 80% in 45 minutes. The interior is highlighted by a modern 15.1-inch touchscreen and a redesigned center console.',
        ],
        publishDate: '2023-10-31T00:00:00',
        imageURl: '2020/22e19f2f-fe4b-48bb-aa9a-e36acba1e240.png',
        reportUrl: 'global-space-tourism-market',
      },
      {
        id: 'AM5d7f6a4b-23ce-4e23-b45c-ae3c77e6792d',
        report_title:
          'Global Automotive Differential Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Automotive Differential Market, By Differential Type (Open, Locking, Limited-Slip Differential (LSD), Electronic Limited-Slip Differential (ELSD), and Torque Vectoring), Drive Type (Front Wheel Drive (FWD), Rear Wheel Drive (RWD), and All-Wheel Drive/ Four Wheel Drive (AWD/4WD)), Vehicle Type (Passenger Car (PC), Light Commercial Vehicle (LCV), Truck and Bus), Hybrid Vehicles (Hybrid Electric Vehicles (HEVS) and Plug in Hybrid Electric Vehicles (PHEVS)), Off-Highway Vehicle Type (Agriculture Tractors, Construction &amp; Mining Equipment, and Forklift), Component (Differential Bearing, Differential Gear, Differential Case, and Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-25T00:00:00',
        imageURl: '2020/22e19f2f-fe4b-48bb-aa9a-e36acba1e240.png',
        reportUrl: 'global-automotive-differential-market',
      },
      {
        id: 'AM2f98a1d6-cf1b-42bc-a4e1-87590eb2d44d',
        report_title: 'Global Locomotive Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Locomotive Market, By Operational Engine (Station Pilot, Banking Engine, Pilot Engine, Others), Technology (IGBT Power Module, GTO Thyristor Module, SiC Power Module), Locomotive Technology (Turbocharged, Maglevs, Conventional Locomotive), Motive Power (Gas Turbine Electric, Steam Diesel Hybrid, Diesel, Atomic Electric, Steam, Fuel Cell Electric, Electric, Gasoline, Hybrid), Components (Traction, Inverter, Alternator, Rectifier, Auxiliary Power Conversion Unit), Rolling Stock Type (DMU, EMU, Diesel and Electric Locomotive), Application (Passenger, Freight, Switcher Locomotives) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-25T00:00:00',
        imageURl: '2020/22e19f2f-fe4b-48bb-aa9a-e36acba1e240.png',
        reportUrl: 'global-locomotive-market',
      },
      {
        id: 'AMe1a7d8f9-6920-4f2a-8d35-0d9ebd8e9717',
        report_title: 'Global Thermal Imaging Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Thermal Imaging Market, By Sensor Type (Handheld or Portable Camera, Fixed and Mounted Core, Scopes and Vision Goggles, Others), Application (Security and Surveillance, Monitoring and Inspection, Detection and Measurement, Personal Vision System, Search and Rescue, Others), Product (Thermal Cameras, Thermal Scopes, Thermal Modules), Technology (Cooled, Uncooled), Wavelength (Long-Wave Infrared (LWIR), Mid-Wave Infrared (MWIR), Short-Wave Infrared (SWIR)), End-Use (Industrial, Commercial, Residential, Aerospace and Defense, Automotive, Healthcare, Life Sciences, Oil and Gas, Food and Beverages) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-20T00:00:00',
        imageURl: '2020/22e19f2f-fe4b-48bb-aa9a-e36acba1e240.png',
        reportUrl: 'global-thermal-imaging-market',
      },
      {
        id: 'AMbf43e0b6-7bc3-4a3a-8c34-112b8f3aefc4',
        report_title:
          'Global Automotive Variable Valve Timing (VVT) and Start-Stop System Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Automotive Variable Valve Timing (VVT) and Start-Stop System Market, By Technology (Cam-Phasing, Cam-Phasing Plus Changing), Valvetrain (SOHC, DOHC), Fuel Type (Diesel VVT System, Gasoline VVT System), Vehicle Type (Passenger Car, Light Commercial Vehicle, Heavy Commercial Vehicle), Phaser Type (Hydraulic Cam Phaser, Electronic Cam Phaser), Starter Technology (Belt-Driven Alternator Starter, Enhanced Starter, Direct Starter, Integrated Starter Generator), Vehicle Type (Passenger Car, Light Commercial Vehicle, Heavy Commercial Vehicle) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-19T00:00:00',
        imageURl: '2020/22e19f2f-fe4b-48bb-aa9a-e36acba1e240.png',
        reportUrl: 'global-automotive-vvt-and-start-stop-system-market',
      },
      {
        id: 'AMa6c5b4e1-0a5b-4962-b102-1ebc82313d52',
        report_title:
          'Global Baggage Handling System Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Baggage Handling System Market, By Mode of Transport (Airport, Marine and Rail), Solution (Check-In, Screening and Loading, Conveying and Sorting and Unloading and Reclaim), Check-In Services (Assisted Service and Self Service), Conveying (Conveyor and Destination Coded Vehicle), Tracking (Barcode and  Radio Frequency Identification (RFID)) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-18T00:00:00',
        imageURl: '2020/22e19f2f-fe4b-48bb-aa9a-e36acba1e240.png',
        reportUrl: 'global-baggage-handling-system-market',
      },
      {
        id: 'AM878d1c6b-0c8b-4260-9443-cc62b0dbfcf8',
        report_title:
          'Global Automotive Micro Electro Mechanical Systems (MEMS) Sensor Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Automotive Micro Electro Mechanical Systems (MEMS) Sensor Market, By Type (Tire Pressure Sensors, Engine Oil Sensors, Combustion Sensors, Fuel Injection and Fuel Pump Sensors, Air Bag Deployment Sensors, Gyroscopes, Fuel Rail Pressure Sensors), Application (Advanced Driver Assistance System, Electronic Control Unit, Electronic Stability Control, Heating Ventilation and Air Conditioning System, Safety and Security, In-Car Navigation, OIS Cameras, Microphone In Cabin,Tire Pressure Monitoring System, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-18T00:00:00',
        imageURl: '2020/22e19f2f-fe4b-48bb-aa9a-e36acba1e240.png',
        reportUrl: 'global-automotive-micro-electro-mechanical-systems-mems-sensor-market',
      },
      {
        id: 'AM3a8b3ef4-47cb-4145-9a12-5a04e81a0312',
        report_title: 'North America Used Car Market - Industry Trends and Forecast to 2030',
        short_discription: [
          'North America Used Car Market, By Vendor Type (Organized, Unorganized), Propulsion (Petrol, Diesel,CNG, LPG, Electric and Others),&nbsp; Engine Capacity (Full Size (Above 2500 CC), Mid-size (Between 1500-2499 CC), Small (Below 1499 CC)), Dealership (Franchised, Independent), Sales Channel (Online, Offline), Vehicle Type (Passenger Car, LCV, HCV and Electric Vehicle) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-18T00:00:00',
        imageURl: '2020/22e19f2f-fe4b-48bb-aa9a-e36acba1e240.png',
        reportUrl: 'north-america-used-car-market',
      },
      {
        id: 'AMc40a02b5-83c2-4a11-8e27-704141ac7d6a',
        report_title: 'Global Airport Sleeping Pods Market – Industry Trends and Forecast to 2030 ',
        short_discription: [
          'Global Airport Sleeping Pods Market, By Product (Single Occupancy Sleeping Pods, Shared Occupancy Sleeping Pods), End-User (Children, Adult), Module (Compact Nap Pod, Single Nap Pod, Double Nap Pod, Multiple Nap Pod) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-17T00:00:00',
        imageURl: '2020/22e19f2f-fe4b-48bb-aa9a-e36acba1e240.png',
        reportUrl: 'global-airport-sleeping-pods-market',
      },
      {
        id: 'AM7c8d6c45-2a2b-4e4d-94a3-80e5052da2a7',
        report_title: 'Global Military Drones Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Military Drones Market, By Platform (Strategic, Tactical, Small), Speed (Subsonic, Supersonic), Service Type (Fixed Wing, Rotary Wing, Hybrid/Transitional), Propulsion Type (Hybrid Fuel Cell, Battery Powered,&nbsp; Others), Launching Mode (Catapult Launcher, Automatic Take-Off and Landing, Hand Launched, Vertical Take-Off), Range (Beyond Line Of Sight (BLOS), Extended Visual Line Of Sight (EVLOS), Visual Line Of Sight (VLOS)), Application (Reconnaissance, Surveillance &amp; Target Acquisition (ISRT), Intelligence, Combat Operations, Delivery and Transportation, and Battle Damage Management), Endurance (&gt;6 Hours, 2&ndash;6 Hours, and &lt;1&ndash;2 Hours), MTOW (&gt;150 Kilograms, 25&ndash;150 Kilograms, and &lt;25 Kilograms) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-16T00:00:00',
        imageURl: '2020/22e19f2f-fe4b-48bb-aa9a-e36acba1e240.png',
        reportUrl: 'global-military-drones-market',
      },
      {
        id: 'AM96e77545-438c-4a4b-9328-8a41d861ecad',
        report_title:
          'Middle East and Africa Luxury Car Leasing Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Middle East and Africa Luxury Car Leasing Market, By Type (Compact Luxury Cars, Mid-Size Luxury Cars, Full-Size Luxury Cars, Luxury Crossovers and Minivans and Luxury SUVs), Rental Type (Business, Leisure), Term (Short-term rental, Long-term rental, Finance leasing), Category (Self-Driven, Chauffeur-Driven), Applications (Airport, Off-airport), End User (Local Usage, Airport Transport, Outstation, Others), Booking Mode (Online, Offline) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-16T00:00:00',
        imageURl: '2020/22e19f2f-fe4b-48bb-aa9a-e36acba1e240.png',
        reportUrl: 'middle-east-and-africa-luxury-car-leasing-market',
      },
      {
        id: 'AMb7f5a1ed-749d-4f98-9507-68f19f5eb103',
        report_title: 'Global Hydraulic Excavator Market - Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Hydraulic Excavator Market, By Type (Crawler, Mini, Wheeled), Power Rating (0-300 HP, 301-500 HP, Above 501 HP), End-User (Contractors, Rental providers, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-14T00:00:00',
        imageURl: '2020/22e19f2f-fe4b-48bb-aa9a-e36acba1e240.png',
        reportUrl: 'global-hydraulic-excavator-market',
      },
      {
        id: 'AMf2300c3a-95e0-4ba3-876d-2b30a5a5a853',
        report_title: 'Global CNG and LPG Vehicles Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global CNG and LPG Vehicles Market, By Vehicle Type (Passenger Cars, Light Commercial Vehicles, Industrial Truck), Fuel Type (CNG and LPG) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-13T00:00:00',
        imageURl: '2020/22e19f2f-fe4b-48bb-aa9a-e36acba1e240.png',
        reportUrl: 'global-cng-and-lpg-vehicles-market',
      },
      {
        id: 'AMe6a5f88d-4c3d-4d1c-938f-781241bf399f',
        report_title:
          'Global Electro Pneumatic Train Brakes Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Electro Pneumatic Train Brakes Market, By Brake Type (Self-Lapping Brakes, Retardation Controllers, Variable Load Control, P-Wire Control), Application&nbsp; (Passenger Trains, Freight Trains, Metro Trains, High Speed Trains, and Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-13T00:00:00',
        imageURl: '2020/22e19f2f-fe4b-48bb-aa9a-e36acba1e240.png',
        reportUrl: 'global-electro-pneumatic-train-brakes-market',
      },
      {
        id: 'AMf1c74d61-6d21-4575-94d3-70f3b2f68f01',
        report_title: 'Global Military Vetronics Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Military Vetronics Market, By System (Command, Control and Communications (C3) System, Navigation System, Weapon Control System, Power System, Vehicle Protection System), Platform (Light Armored Vehicle, Main Battle Tank, Mine-Resistant Ambush Protection (MRAP) Vehicle, Infantry Fighting Vehicle (IFV), Unmanned Armored Ground Vehicle, Amphibious Vehicle), Fit (Line Fit, Retro Fit), Application (Defense, Homeland Security), End User (OEM, Aftermarket) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-13T00:00:00',
        imageURl: '2020/22e19f2f-fe4b-48bb-aa9a-e36acba1e240.png',
        reportUrl: 'global-military-vetronics-market',
      },
      {
        id: 'AMd8b4c53d-4a9c-4389-b568-6d97db9b16b9',
        report_title:
          'Middle East and Africa Electric Bike (E-Bike) Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Middle East and Africa Electric Bike (E-Bike) Market By Battery Type (Lithium-Ion, Lithium-Ion Polymer, Nickel Metal Hydride, Lead Acid  Sealed Lead Acid and Others), Hub Motor Location (Mid Drive Hub Motor, Rear Hub Motor and Front Hub Motor), Mode (Pedal Assist and Throttle), Battery Power (Under 750 W and Over 750 W), Class (Class I (Pedal Assist/Pedelec), Class II (Throttle) and Class III (Speed Pedelec)), Usage (City/Urban, Cruise, Mountain/Trekking Bikes, Racing, Cargo and Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-12T00:00:00',
        imageURl: '2020/22e19f2f-fe4b-48bb-aa9a-e36acba1e240.png',
        reportUrl: 'middle-east-and-africa-e-bike-market',
      },
      {
        id: 'AMe6f2e877-9d85-4a1c-a207-30dd1e2cc156',
        report_title: 'Global Micro Motor Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Micro Motor Market, By Type (AC, DC), Technology (Brushed, Brushless), Application (Automotive, Aerospace, Agriculture Equipment System, 3D Printing, Construction and Mining Equipment System, Medical Equipment), Power Consumption (Less than 11 Volts, 12-24 Volts, 25-48 Volts, More than 48 Volts) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-12T00:00:00',
        imageURl: '2020/22e19f2f-fe4b-48bb-aa9a-e36acba1e240.png',
        reportUrl: 'global-micro-motor-market',
      },
      {
        id: 'AMa6c6b5bf-57f5-4b5b-b495-1ef9847fde37',
        report_title:
          'Global Head Up Display (HUD) Vehicle Infotainment Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Head Up Display (HUD) Vehicle Infotainment Market, By Form (Embedded, Tethered, Integrated), Location (Front RoW, Rear RoW), Connectivity (3G, 4G, 5G, Bluetooth, Wi-Fi), Operating System (Linux, Qnx, Microsoft, Of-Others), Service (Entertainment Services, Navigation Services, E-Call, Vehicle Diagnostics, Others), Vehicle Type (Passenger Car, Light Commercial Vehicle, Heavy Commercial Vehicle), Alternate Fuel Vehicle (Battery Electric Vehicles (BEV), Plug-in Hybrid Electric Vehicles (PHEV), Hybrid Electric Vehicles (HEV)) Sales Channel (Original Equipment Manufacturer (OEM), Aftermarket) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-11T00:00:00',
        imageURl: '2020/22e19f2f-fe4b-48bb-aa9a-e36acba1e240.png',
        reportUrl: 'global-hud-head-up-display-vehicle-infotainment-market',
      },
      {
        id: 'AMc7e1a80d-8df9-4e01-b2e9-7a6e51b982c8',
        report_title:
          'Global Industrial Floor Scrubbers Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Industrial Floor Scrubbers Market, By Product Type (Walk-behind Scrubbers, Ride-on Scrubbers and Robotic Scrubbers), End-Users (Transportation, Healthcare and Pharmaceuticals, Government, Education, Hospitality, Manufacturing and Warehousing, Retail and Food) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-11T00:00:00',
        imageURl: '2020/22e19f2f-fe4b-48bb-aa9a-e36acba1e240.png',
        reportUrl: 'global-industrial-floor-scrubbers-market',
      },
      {
        id: 'AMb1e6a3cf-c2c3-428c-b1cf-cd159c1fb6b7',
        report_title:
          'Global Automotive Advanced Driver Assistance Systems (ADAS) and Park Assist Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Automotive Advanced Driver Assistance Systems (ADAS) and Park Assist Market, &nbsp;By System (Adaptive Cruise Control, Adaptive Front Light, Automatic Emergency Braking, Blind Spot Detection, Cross Traffic Alert, Driver Monitoring System, Forward Collision Warning, Intelligent Park Assist, Lane Departure Warning, Night Vision System, Pedestrian Detection System, Road Sign Recognition, Tire Pressure Monitoring System, Traffic Jam Assist), Component (Camera Unit, Lidar Sensor, Radar Sensor, Ultrasonic Sensor), Offering (Hardware, Software), Vehicle Type (Buses, Light Commercial Vehicles, Passenger Cars, Trucks), Electric Vehicle Type (Battery Electric Vehicle, Fuel Cell Electric Vehicle, Hybrid Electric Vehicle, Plug-In Hybrid Electric Vehicle), Propulsion (Electric, Petrol, Diesel) &nbsp;&ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-10T00:00:00',
        imageURl: '2020/22e19f2f-fe4b-48bb-aa9a-e36acba1e240.png',
        reportUrl: 'global-automotive-adas-and-park-assist-market',
      },
    ],
  },

  {
    id: 'a2fbc2b6-6e4b-4e21-91ef-2c6b819b1f59',
    title: 'Materials and Packing',
    industry: [
      {
        id: 'HGY8d2f3b6e-ef04-465b-8f89-6f3y0a16e2c3',
        report_title:
          'The global luggage and leather goods market was estimated to be worth USD 491.09 billion in 2022 and is projected to grow at a CAGR of 5.27% from 2023 to 2031.',
        short_discription: [
          'Luggage and leather goods encompass a wide range of items designed for carrying personal belongings while traveling or for personal use. Luggage refers specifically to suitcases, bags, and containers that hold a travelers articles during transit. Leather goods, on the other hand, are products crafted from leather and include items such as wallets, belts, handbags, and briefcases. These items are valued for their durability, flexibility, and aesthetic appeal, serving both functional and fashion purposes. Together, luggage and leather goods play essential roles in organizing and securing items for ease of transport and access, catering to the needs of daily life and travel.',
          'The major segments covered in the luggage and leather goods market are:',
          'By Product Type: Luggage, Leather Goods',
          'By Material: Genuine Leather, Synthetic/Artificial Leather, Other Materials',
          'By End User: Men, Women, Children',
          'By Price Range: Luxury, Mid-Range, Budget',
          'By Distribution Channel: Online Retail, Offline Retail',
          'By Purpose/Usage: Travel, Everyday Use, Professional Use, Fashion and Luxury',
          'By Region: North America, Europe, Asia Pacific, Latin America, Middle East and Africa',
          'Increasing tourism and travel trends are driving the growth of the luggage and leather goods market. Tourism and travel refer to the act of people moving outside their usual environment for leisure, business, or other purposes for a temporary stay. Tourism and travel drive demand for luggage and leather goods as travelers often seek durable, functional, and stylish items for their journeys. According to the United Nations World Tourism Organization in 2022, the United States welcomed 50.9 million international arrivals, up from 22.3 million in 2021.',
          "In March 2022, Carl Friedrik, a Scandinavian luxury leather goods brand known for its sleek, intelligent design and durable quality, has expanded into the US and launched its collection at Case London's Piccadilly flagship, Harrods, and Heathrow Terminal 2 Airside store. The brand offers products ranging from The Palissy briefcase and suitcases to travel accessories with a focus on sustainable materials and transparency. Carl Friedrik promises a 100-day trial and lifetime guarantee, emphasizing confidence in their minimalist, high-quality goods designed for modern professionals.",
        ],
        publishDate: '2023-10-20T00:00:00',
        imageURl: '2020/589a8823-e596-49ef-9457-6e55b9ebd438.png',
        reportUrl: 'global-paper-cone-market',
      },
      {
        id: 'MAP5d2f3b6e-ef04-465b-8f21-6f5d0a16e2c3',
        report_title: 'Global Paper Cone Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Paper Cone Market, By Type (Tight Bong Paper Cone, Jumbo Paper Cone, Yarn Paper Cone, Printed Paper Cone, Bobbins, Textile Paper Cone, Wax Paper Cone, Conical Paper Cone, Construction Cone, Plain Paper Cone, Others), Number of Piles (Single Ply, Multiple Ply), Taper of The Cone (Upto 30 Taper, 30 To 50 Taper, Above 50 Taper), Size (1-Degree 51-Minutes Cone, 3-Degree 51-Minutes Cone, 5-Degree 57-Minutes Cone, 3-Degree 30-Minutes Cone, 6-Degree 20-Minutes Cone, 9-Degree 51-Minutes Cone, 9-Degree 36-Minutes Cone, 7-Degree 22-Minutes Cone, Others), Distribution Channel (Direct Sales/B2B, Specialist Retailers, Convenience Stores, Supermarkets/Hypermarkets, E-Commerce, Others), End-User (Textile, Construction, Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-20T00:00:00',
        imageURl: '2020/589a8823-e596-49ef-9457-6e55b9ebd438.png',
        reportUrl: 'global-paper-cone-market',
      },
      {
        id: 'MAP9e8b4a2d-1d85-4d3a-94a2-37bc5e25f28a',
        report_title: 'Africa Baby Feeding Bottle Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Africa Baby Feeding Bottle Market, By Product (Standard Bottle, Anti-Colic Bottle, Self-Sterilizing Bottles, Disposable Bottles, Bottles With Disposable Liners, Angle Neck Bottles, Wide Neck Bottles, Vented Bottles and Others), Raw Material (Plastic, Stainless Steel>, Glass, Silicone and Others), Capacity (Up To 4 Oz, 4.1 TO 6 Oz, 6.1 TO 9 Oz, and &gt; 9 Oz), Nipple Type (Traditional, Naturally Shaped, Vented, Orthodontic, Multi-Flow and Others), Age Group (0-6 Months, 6-18 Months and Above 18 Months), Distribution Channel (Supermarkets/Hypermarkets, Convenience Stores, Pharmacy and Drug Stores, Specialty Stores, E-Commerce and Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-20T00:00:00',
        imageURl: '2020/589a8823-e596-49ef-9457-6e55b9ebd438.png',
        reportUrl: 'africa-baby-feeding-bottle-market',
      },
      {
        id: 'MAPf0a7c9b1-6e71-4b8e-8a4d-71e39f3c0b0d',
        report_title: 'Global Molded Fiber Packaging Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Molded Fiber Packaging Market, By Product (Trays, Clamshell Containers, Boxes, End Cap, Others), Type (Thick-Wall, Transfer Molded, Thermoformed Fiber, Processed Pulp), Source (Wood Pulp, Non-Wood Pulp), End-User (Food and Beverage, Electronics, Personal Care, Healthcare, Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-19T00:00:00',
        imageURl: '2020/589a8823-e596-49ef-9457-6e55b9ebd438.png',
        reportUrl: 'global-molded-fiber-packaging-market',
      },
      {
        id: 'MAPd3c1e28a-4d7f-4a16-9520-7e7d6c2c785e',
        report_title:
          'Middle East and Africa Pharmaceutical Glass Packaging Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Middle East and Africa Pharmaceutical Glass Packaging Market, By Material (Type I, Type II, and Type III), Product (Bottles, Ampoules, Cartridges and Syringes, Vials, and Others), Drug Type (Generic, Branded, and Biologic) Application (Oral, Injectable, Nasal, and Others), End-Use (Pharmaceutical Companies, Biopharmaceutical Companies, Contract Development, and Manufacturing Companies, Compound Pharmacy, and Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-19T00:00:00',
        imageURl: '2020/589a8823-e596-49ef-9457-6e55b9ebd438.png',
        reportUrl: 'middle-east-and-africa-pharmaceutical-glass-packaging-market',
      },
      {
        id: 'MAP8a4b7f9e-2ea6-4d11-8f8d-492bf61abf9d',
        report_title: 'Global Propylene Glycol Market - Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Propylene Glycol Market, By Source (Petroleum-Based Propylene Glycol, Bio-Based Propylene Glycol), Grade (Antifreeze and Functional Fluids, Unsaturated Polyester Resin, Food, Pharmaceuticals and Cosmetics, Plasticizers, Liquid Detergents) End User (Building and Construction, Pharmaceuticals and Cosmetics, Transportation, Food and Beverages) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-18T00:00:00',
        imageURl: '2020/589a8823-e596-49ef-9457-6e55b9ebd438.png',
        reportUrl: 'global-propylene-glycol-market',
      },
      {
        id: 'MAP2b5e1c4d-0d47-4a8d-bb7d-f69a228c5d09',
        report_title:
          'Global High Density Polyethylene (HDPE) Bottles Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global High Density Polyethylene (HDPE) Bottles Market, By Cap Type (Screw Closures, Snap Closures, Push-Pull Closures, Disc Top closures, Spray Closures, Dispensers, Dropper), Bottle Capacity (Less than 30 ml, 31 ml &ndash; 100 ml, 101 ml &ndash; 500 ml, 501 ml &ndash; 1Lt, Above 1Lt), Visibility (Translucent, Opaque), Neck Type (Narrow Mouth Bottles, Wide Mouth Bottles), Barrier Type (Low-barrier Bottles, High-barrier Bottles), Feedstock (Naphtha, Natural Gas, Others), Form (Blow Molded, Pipe and Extruded, Film and Sheet, Injection Molded), Manufacturing Process (Gas Phase Process, Slurry Process, Solution Process), End User (Domestic Use, Chemical Industries, Cosmetic Industries, Pharmaceutical Industries) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-18T00:00:00',
        imageURl: '2020/589a8823-e596-49ef-9457-6e55b9ebd438.png',
        reportUrl: 'global-high-density-polyethylene-hdpe-bottles-market',
      },
      {
        id: 'MAPbcfd9a6d-7a3d-4a3b-aed3-4e34bf1c7869',
        report_title:
          'Global Low Density Polyethylene (LDPE) and Linear Low-Density Polyethylene (LLDPE) Sealant Web Film Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Low Density Polyethylene (LDPE) and Linear Low-Density Polyethylene (LLDPE) Sealant Web Film Market, By Material Type (LDPE, LLDPE), Thickness (Up to 45 Micron, 45 to 60 Micron, Above 60 Micron), Product Type (<a href="https://www.databridgemarketresearch.com/reports/global-flat-pouches-market">Flat Pouches</a>, Standup Pouches, Bags and Others), End-Use Industry (Food, Beverage, Personal Care and Cosmetics, Pharmaceuticals, Home Care Products, Electrical and Electronics, <a href="https://www.databridgemarketresearch.com/reports/global-textile-market">Textile</a>, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-17T00:00:00',
        imageURl: '2020/589a8823-e596-49ef-9457-6e55b9ebd438.png',
        reportUrl: 'global-ldpe-and-lldpe-sealant-web-film-market',
      },
      {
        id: 'MAP8c4e5f6d-0d83-478b-bf63-44e0ea3b1b97',
        report_title:
          'Global Shrink Sleeve - Stretch Sleeve Labels Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Shrink Sleeve - Stretch Sleeve Labels Market, By Type (Stretch, Shrink), Polymer Film (PVC (Polyvinyl Chloride), PET-G (Polyethylene Terephthalate Glycol), OPS (Oriented Polystyrene), PLA (<a href="https://www.databridgemarketresearch.com/reports/global-polylactic-acid-pla-market">Polylactic Acid</a>), PE (Polyethylene)), Printing Technology (Gravure, Flexography, Digital), Ink (Water Based, Solvent Based, UV), Application (Beverages, Food, Personal care, Health care, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-17T00:00:00',
        imageURl: '2020/589a8823-e596-49ef-9457-6e55b9ebd438.png',
        reportUrl: 'global-shrink-sleeve-stretch-sleeve-labels-market',
      },
      {
        id: 'MAPa3e9c0d5-32d2-4f4b-ae09-16eab3a4a3a9',
        report_title:
          'Global Biodegradable Paper Packaging Materials Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Biodegradable Paper Packaging Materials Market, By Materials Type (Corrugated Papers, Specialty Papers, Boxboard or Carton board,&nbsp; Kraft Papers and Molded Pulp), Packaging Type (Corrugated Boxes, Wrapping paper,&nbsp; Inserts and Dividers, Bottles and Cup Carriers, Trays, Plates, Clamshells, Display Packaging, Bags and Sacks, Tapes and Labels), Packaging Level (Primary Packaging, Secondary Packaging, Tertiary Packaging), End-Use (Food Packaging, Beverages Packaging, Tobacco Packaging, Healthcare Packaging, Personal Care and <a href="https://www.databridgemarketresearch.com/reports/global-cosmetics-market">Cosmetics</a>,&nbsp; Homecare and Toiletries, Electrical and Electronics, Industrial Packaging, E-Commerce Packaging, Chemical and Fertilizers) - Industry Trends and Forecast to 2023 to 2030.',
        ],
        publishDate: '2023-10-17T00:00:00',
        imageURl: '2020/589a8823-e596-49ef-9457-6e55b9ebd438.png',
        reportUrl: 'global-biodegradable-paper-packaging-materials-market',
      },
      {
        id: 'MAPf2a9d2e4-8dfe-442d-831e-bb890e9fc85a',
        report_title:
          'Middle East and Africa Active, Smart and Intelligent Packaging Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Middle East and Africa Active, Smart and Intelligent Packaging Market, By Type (Active Packaging, Smart Packaging, and Intelligent Packaging), Function (Moisture Control, Temperature Indicators, Shelf Life Sensing, Product Tracking, and Others), Technology (RFID Tags, QR Codes, NFC Tags, Coding and Markings, <a href="https://www.databridgemarketresearch.com/reports/global-sensors-market">Sensors</a> and Output Devices, and Others), Material (Plastic, Paper, Glass, Corrugated Board, Metal, And Others), Level (Primary, Secondary, and Tertiary), and Application (Food, Beverages, Personal Care, Healthcare, Automotive, Consumer Electronics, and Others) - Industry Trends and Forecast to 2023 to 2030.',
        ],
        publishDate: '2023-10-16T00:00:00',
        imageURl: '2020/589a8823-e596-49ef-9457-6e55b9ebd438.png',
        reportUrl: 'middle-east-and-africa-active-smart-and-intelligent-packaging-market',
      },
      {
        id: 'MAP4f7d5e5b-6f0b-4c23-b3b9-eab80b172f0a',
        report_title:
          'Global Temperature Controlled Packaging Solutions Market - Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Temperature Controlled Packaging Solutions Market, By Product (Insulated Shippers, Insulated Containers,<a href="https://www.databridgemarketresearch.com/reports/global-refrigerant-market"> Refrigerants</a>, Others), Type (Active Systems, Passive Systems), Usability (Single, Reuse), Revenue Type (Products, Services), End User (Food and Beverages, Healthcare, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-16T00:00:00',
        imageURl: '2020/589a8823-e596-49ef-9457-6e55b9ebd438.png',
        reportUrl: 'global-temperature-controlled-packaging-solutions-market',
      },
      {
        id: 'MAPd8b2c1d3-75fb-43d2-9e3d-8b3c2f95a10b',
        report_title:
          'Global Intermediate Bulk Containers (IBC) Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Intermediate Bulk Containers (IBC) Market, By Product (RIBCs (Rigid Intermediate Bulk Containers), FIBCs (Flexible Intermediate Bulk Containers), Grade (Type A, Type B, Type C, Type D, Food Grade), Material (Plastic, Metal), End-User (Food and Beverage, Agriculture, Chemicals, Oil and Gas, Paints, Inks and Dyes, Pharmaceuticals, Textile, Building and Construction, Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-13T00:00:00',
        imageURl: '2020/589a8823-e596-49ef-9457-6e55b9ebd438.png',
        reportUrl: 'global-intermediate-bulk-containers-ibc-market',
      },
      {
        id: 'MAPef8b1e8d-7fc3-496e-8e0f-bb1e1f56d8bf',
        report_title:
          'Global Primary Pharmaceutical Packaging Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Primary Pharmaceutical Packaging Market, By Type (Plastic Bottles, Blister Packs, Labels and Accessories, Caps and Closures, Medical Specialty Bags, Pre-Filled Syringes, Temperature-Controlled Packaging, Pouches and Strip Packs, Ampoules, Vials, Pre-Filled Inhalers, Medication Tubes, Jars and Canisters, Cartridges, Others), Material (Plastics and Polymers, Paper &amp; Paperboards, Glass, Metals, Others), Drug Delivery (Oral Drug Delivery Packaging, Pulmonary Drug Delivery Packaging, Transdermal Drug Delivery Packaging, Injectable Packaging, Topical Drug Delivery Packaging, Nasal Drug Delivery Packaging, Ocular Drug Delivery Packaging, Iv Drugs Delivery Packaging, Other Drugs Delivery Packaging) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-13T00:00:00',
        imageURl: '2020/589a8823-e596-49ef-9457-6e55b9ebd438.png',
        reportUrl: 'global-primary-pharmaceutical-packaging-market',
      },
      {
        id: 'MAP7f7d4e6d-af1a-48a0-93e2-f0b6a0cc673d',
        report_title: 'Europe Spouted Pouches Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Europe Spouted Pouches Market, By Product (Beverages, Syrups, Cleaning Solution, Oils and Others), Color (Green, Red, Black, Blue, Silver and Gold), Component (Cap, Straw and Film), Layer (Four, Three and Two),&nbsp; Pouch Size (Less Than 200 Ml, 200 Ml To 500 Ml, 500 Ml To 1000 Ml and More Than 1000 Ml), Filling Process (Aseptic, Retort, Standard and Hot-Filled), Material (Plastic, Aluminum and Paper), Application (Food, Beverages, Home and Personal Care, Automotive, Pharmaceutical and Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-12T00:00:00',
        imageURl: '2020/589a8823-e596-49ef-9457-6e55b9ebd438.png',
        reportUrl: 'europe-spouted-pouches-market',
      },
      {
        id: 'MAPcf8c1e7b-04c2-4fb7-bf21-6e57cd1a3d6d',
        report_title:
          'Asia-Pacific Consumer Electronics Packaging Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Asia-Pacific Consumer Electronics Packaging Market, By Type (Corrugated Boxes, Paperboard Boxes, Thermoformed Trays, Blister Packs and Clamshell, Protective Packaging, Bags, Sacks, Pouches, Films, Foam Packaging, Air Bubble Pouches and Others), Packaging Material (Plastic, Paper, Aluminum Foil, Cellulose and Others), Layer (Primary Packaging, Secondary Packaging and Tertiary Packaging), Technology (Active Packaging, Intelligent Packaging, Modified Atmospheric Packaging, Anti-Microbial Packaging, Aseptic Packaging and Others), Printing Technology (Flexographic, Gravure and Others), Distribution Channel (<a href="https://www.databridgemarketresearch.com/reports/asia-pacific-e-commerce-market">E-Commerce</a>, Supermarkets/Hypermarkets, Specialty Stores and Others), Application (Mobile Phones, Computers, TVs, DTH and Set-Top Boxes, Music System, Printer, Scanner and Photocopy Machines, Game Console and Toys, Camcorders and Camera, Electronic Wearable, Digital Media Adapters and Others) - Industry Trends and Forecast to 2023 to 2030.',
        ],
        publishDate: '2023-10-12T00:00:00',
        imageURl: '2020/589a8823-e596-49ef-9457-6e55b9ebd438.png',
        reportUrl: 'asia-pacific-consumer-electronics-packaging-market',
      },
      {
        id: 'MAP4e3b1d8a-5b3a-480c-a8e7-3c4e1d2f9eab',
        report_title: 'Global Rigid Packaging Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Rigid Packaging Market, By Material (Plastic, Metal, Wood, Glass and Paper &amp; Paperboard), Application Type (Food &amp; Beverages, Chemical Industry, Consumer Goods, Healthcare &amp; Pharmaceutical, Other), Product Type (Boxes, Trays, Containers &amp; Cans, Bottles &amp; Jars, Others Frozen) - Industry Trends and Forecast to 2023 to 2030.',
        ],
        publishDate: '2023-10-12T00:00:00',
        imageURl: '2020/589a8823-e596-49ef-9457-6e55b9ebd438.png',
        reportUrl: 'global-rigid-packaging-market',
      },
      {
        id: 'MAPb7e9d8f3-c17a-415b-b0c9-dba5a4fe3c27',
        report_title:
          'Global Intermediate Bulk Containers (IBC) Rental Business Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Intermediate Bulk Containers (IBC) Rental Business Market, By Material Type (Carbon Steel, Stainless Steel, Plastic), Product Type (Flexitanks, Stainless Steel, Insolvency and Bankruptcy Code, Carbon Steel Insolvency and Bankruptcy Code, Plastic Insolvency and Bankruptcy Code, Composite Insolvency and Bankruptcy Code), Content (Liquid, Solids, Semi-Solids), Capacity (Up To 1,000 Liters, 1,001-1,500 Liters, 1,501-2,000 Liters, Above 2,000 Liters), End User (Industrial Chemicals, Petroleum and Lubricants, Paints, Inks and Dyes, Food and Beverages, Personal Care and Cosmetics, Synthetic and Natural Rubber, Tires and Auto Components, Pharmaceuticals, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-12T00:00:00',
        imageURl: '2020/589a8823-e596-49ef-9457-6e55b9ebd438.png',
        reportUrl: 'global-intermediate-bulk-containers-ibc-rental-business-market',
      },
      {
        id: 'MAP2b3d4a1e-28c1-4b9a-ae55-cd2d6fc4db17',
        report_title:
          'Global Medical Device Packaging Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Medical Device Packaging Market, By Products (<a href="https://www.databridgemarketresearch.com/reports/global-pouches-market">Pouches</a>, Trays, Clamshell Packs, Wrap Films, Bags, Boxes, Cans, Others), Accessories (Labels, Lidding, Others), Material (Glass, Aluminium, Paper and Paperboard, Plastics, Teak, Others), Application (Sterile Packaging, Non-Sterile Packaging), Distribution Channel (Direct, Retail), End-User (Medical Manufacturing, Contract Packaging, Retail Packaging, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-11T00:00:00',
        imageURl: '2020/589a8823-e596-49ef-9457-6e55b9ebd438.png',
        reportUrl: 'global-medical-device-packaging-market',
      },
      {
        id: 'MAP1f3d7e9c-e068-45e3-9c87-ef4b68f176b4',
        report_title:
          'Global Digital Textile Printing Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Digital Textile Printing Market, By Process Type (Direct-to-Garment, Direct-to-Fabric), Printing Process (Roll to Roll Process, DTG Process), Ink Type (Sublimation, Pigment, Reactive, Acid and Others), Application (Industrial, Textile and Decor, Advertisement, Clothing and Apparel, Soft Signage) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-11T00:00:00',
        imageURl: '2020/589a8823-e596-49ef-9457-6e55b9ebd438.png',
        reportUrl: 'global-digital-textile-printing-market',
      },
      {
        id: 'MAPb4f8d1a9-8de3-42ab-9b1e-3a68a1f65e38',
        report_title:
          'Global Vial Sealing Cap Machines Market - Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Vial Sealing Cap Machines Market, By Technology (Automatic Vial Cap Sealing Machine, Semi- Automatic Vial Cap Sealing Machine, and Manual Vial Cap Sealing Machine), Number of Heads at a Time (Single Head Vial Cap Sealing Machine, and Multi-Head Vial Cap Sealing Machine), End-User Industry (Pharmaceutical, Beverage, and Personal and Care) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-11T00:00:00',
        imageURl: '2020/589a8823-e596-49ef-9457-6e55b9ebd438.png',
        reportUrl: 'global-vial-sealing-cap-machines-market',
      },
    ],
  },

  {
    id: '8f8a7fb7-9f98-4e03-8c1b-9b1c2a24fbf1',
    title: 'FMCG',

    industry: [
      {
        id: 'FMCGa1b2c3d4-e5f6-g7h8-i9j0-k1l2m3n4o5p',
        report_title: 'Asia-Pacific Kids E-Scooter Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Asia-Pacific Kids E-Scooter Market, By Type (2 Wheel and 3 Wheel), Design (Foldable and Unfoldable), Foot Platform (Less Than 3.5 Feet, 3.5 Feet, 5 Feet, and Others), Material (Industrial-Grade Aluminum Alloy, <a href="https://www.databridgemarketresearch.com/reports/global-carbon-fiber-market">Carbon Fiber</a>, Plastic, and Composite), Age (5 - 8 Years, 8 - 12 Years, and Less Than 5 Years) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-30T00:00:00',
        imageURl: '2020/86e33331-c65d-450e-b04b-a03e854ff131.png',
        reportUrl: 'asia-pacific-kids-e-scooter-market',
      },
      {
        id: 'FMCGq6r7s8t9-u0v1w2x3y4z5a6b7c8d9e0f',
        report_title:
          'Global Dry-Cleaning and Laundry Services Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Dry-Cleaning and Laundry Services Market, By Type (Laundry, Dry Cleaning and Duvet Clean), End Users (Commercial, Residential, and Coin-Operated), Distribution Channel (Online and Offline) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-26T00:00:00',
        imageURl: '2020/86e33331-c65d-450e-b04b-a03e854ff131.png',
        reportUrl: 'global-dry-cleaning-and-laundry-services-market',
      },
      {
        id: 'FMCGg1h2i3j4-k5l6m7n8o9p0q1r2s3t4u5v6w7',
        report_title: 'Global Digital Camera Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Digital Camera Market, By Product Type (Digital Single-Lens Reflex (DSLR) Cameras, Compact Digital Cameras, Bridge Compact Digital Cameras, Mirrorless Interchangeable Lens Cameras), Distribution Channel (Online, Offline), Digital Sensor Type (CCD Sensor, CMOS Sensor, FOVEON X3 Sensor, Live MOS Sensor), Component (Lenses, <a href="https://www.databridgemarketresearch.com/reports/global-sensors-market">Sensors</a>, LCD Screen, Memory Card, Others), End User (Personal, Professional) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-25T00:00:00',
        imageURl: '2020/86e33331-c65d-450e-b04b-a03e854ff131.png',
        reportUrl: 'global-digital-camera-market',
      },
      {
        id: 'FMCGx8y9z0a-b1c2d3e4f5g6h7i8j9k0l1m2n3',
        report_title: 'Global Camping Chair Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Camping Chair Market, By Design (Classic, Two-Legged, Three-Legged, Rocker, Folding, Glider, Others), Size (Large Camping Chair, Kid-sized Camping Chair), Application (Backyard Camping, RV Camping, Backpacking), Distribution Channel (Offline, Online) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-19T00:00:00',
        imageURl: '2020/86e33331-c65d-450e-b04b-a03e854ff131.png',
        reportUrl: 'global-camping-chair-market',
      },
      {
        id: 'FMCGo4p5q6r7-s8t9u0v1w2x3y4z5a6b7c8d9',
        report_title: 'Global Indoor Plants Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Indoor Plants Market, By Type (Shade-loving plants, Low light plants and High light plants), Application (Absorb Harmful Gases and Home Decoration), Product (Succulent Plants, Berbaceous Plants, Woody Plants and Hydroponic Plants) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-18T00:00:00',
        imageURl: '2020/86e33331-c65d-450e-b04b-a03e854ff131.png',
        reportUrl: 'global-indoor-plants-market',
      },
      {
        id: 'FMCGe0f1g2h3-i4j5k6l7m8n9o0p1q2r3s4t5',
        report_title: 'Global Camping Tent Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Camping Tent Market, By Tent Type (Tunnel Tent, Dome Tent, Geodesic Tent, and Others), Tent Capacity (One Person, Two Persons, and Three or More Persons), Distribution Channel (Specialty Sporting Goods Stores, Supermarkets and Hypermarkets, Online Stores, and Others), End Use (Recreational Activities, Military and Civil, and Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-17T00:00:00',
        imageURl: '2020/86e33331-c65d-450e-b04b-a03e854ff131.png',
        reportUrl: 'global-camping-tent-market',
      },
      {
        id: 'FMCGu6v7w8x9-y0z1a2b3c4d5e6f7g8h9i0j1',
        report_title: 'Global Pet Care E-commerce Market - Industry Trends and Forecast to 2030',
        short_discription: [
          'Global pet care e-commerce market, By Animal Type (Canine, Feline, Crawler, and Others), Type (Pet Care Products, Pet Food, Services, and Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-17T00:00:00',
        imageURl: '2020/86e33331-c65d-450e-b04b-a03e854ff131.png',
        reportUrl: 'global-pet-care-e-commerce-market',
      },
      {
        id: 'FMCGk2l3m4n5-o6p7q8r9s0t1u2v3w4x5y6z7',
        report_title: 'Global Wallpaper Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Wallpaper Market, By Product (Nonwoven, Paper, <a href="https://www.databridgemarketresearch.com/reports/global-vinyls-market">Vinyl</a>, and Fabric), Distribution Channel (Online and Offline), End-Use (Residential and Commercial) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-17T00:00:00',
        imageURl: '2020/86e33331-c65d-450e-b04b-a03e854ff131.png',
        reportUrl: 'global-wallpaper-market',
      },
      {
        id: 'FMCGp8q9r0s1-t2u3v4w5x6y7z8a9b0c1d2e3',
        report_title: 'Global Home Entertainment Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Home Entertainment Market, By Product Type (Audio Equipment, Video Equipment, <a href="https://www.databridgemarketresearch.com/reports/global-gaming-consoles-market">Gaming Consoles</a>), Connectivity (Wired, Wireless), Distribution Channel (Offline, Online) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-17T00:00:00',
        imageURl: '2020/86e33331-c65d-450e-b04b-a03e854ff131.png',
        reportUrl: 'global-home-entertainment-market',
      },
      {
        id: 'FMCGf4g5h6i7-j8k9l0m1n2o3p4q5r6s7t8',
        report_title: 'Global Ornamental Fish Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Ornamental Fish Market, By Product Type (Tropical Freshwater, Temperate, and Marine), Application (Residential and Commercial), Point Of Sale (Dedicated Stores, Multi-Specialty Stores, Online, and Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-17T00:00:00',
        imageURl: '2020/86e33331-c65d-450e-b04b-a03e854ff131.png',
        reportUrl: 'global-ornamental-fish-market',
      },
      {
        id: 'FMCGu9v0w1x2-y3z4a5b6c7d8e9f0g1h2i3j4',
        report_title: 'Global Wash Basins Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Wash Basins Market Global Wash Basins Market, By Product (Wash Basins with Integrated Half Pedestal, Counter Wash Basins With or Without Utility Counter, Tabletop Wash Basins, Tabletop Wall Hung Wash Basins, Wall Hung Wash Basins), Type (Ceramic Type, Composite Type, Others), Application (Household, Commercial, Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-16T00:00:00',
        imageURl: '2020/86e33331-c65d-450e-b04b-a03e854ff131.png',
        reportUrl: 'global-wash-basins-market',
      },
      {
        id: 'FMCGo5p6q7r8-s9t0u1v2w3x4y5z6a7b8c9d0',
        report_title: 'Global Organic Hair Care Market - Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Organic Hair Care Market, By Type, (Shampoo, Conditioner. Hair Loss Treatment Products, Hair Colorants, Hair Styling Products, Perms and Relaxants, Others), Distribution Channel (Hypermarket/Supermarket, Specialty Stores, Online Stores, Pharmacies/Drug Stores, Convenience Stores, Other Distribution Channels), Hair Type, (Normal, Dry, Oily) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-16T00:00:00',
        imageURl: '2020/86e33331-c65d-450e-b04b-a03e854ff131.png',
        reportUrl: 'global-organic-hair-care-market',
      },
      {
        id: 'FMCGg1h2i3j4-k5l6m7n8o9p0q1r2s3t4u5',
        report_title: 'Global Golf Bags Market - Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Golf Bags market, By Product Type (Cart Bags, Stand Bags, Staff Bags, Carry Bags, Others), Price Category (High/Premium, Medium, Low), Distribution Channel (Offline, Online) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-16T00:00:00',
        imageURl: '2020/86e33331-c65d-450e-b04b-a03e854ff131.png',
        reportUrl: 'global-golf-bags-market',
      },
      {
        id: 'FMCGv6w7x8y9-z0a1b2c3d4e5f6g7h8i9j0k1',
        report_title: 'Global Colored Contact Lenses Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Colored Contact Lenses Market, By Type (Visibility Tint, Enhancement Tint, Opaque Tint), Distribution Channel (Optical Stores, Retail Pharmacies, Drugstores,<a href="https://www.databridgemarketresearch.com/reports/asia-pacific-e-commerce-market"> E-Commerce</a>), Application (With Vision Correction, Without Vision Correction) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-16T00:00:00',
        imageURl: '2020/86e33331-c65d-450e-b04b-a03e854ff131.png',
        reportUrl: 'global-colored-contact-lenses-market',
      },
      {
        id: 'FMCGl2m3n4o5-p6q7r8s9t0u1v2w3x4y5z6a7',
        report_title: 'Global Disinfectant Wipes Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Disinfectant Wipes Market, By Product (Chlorine Compounds, Quaternary Ammonium, Oxidizing Agents, Phenol, Alcohol, Iodine Compounds, Chlorhexidine Gluconate, Aldehydes, Others), Usability (Disposable and Non-Disposable), Packaging (Flatpack, Canister, Others), Material (Textile Fibre Wipes, Virgin Fiber Wipes, Advanced Fiber Wipes, Others), Levels of Disinfection (High, Intermediate, Low), Flavour (Lavender and Jasmine, Citrus, Lemon, Coconut, Others), Type (Sporicidal, Bactericidal, Tuberculocidal, Virucidal, Fungicidal, Germicidal), Distribution Channel (Direct Tenders, Retail Sales), End-User (Healthcare, Commercial, Industrial Kitchen, Transportation Industry, Optical Industry, Electronic and Computer Industry, Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-16T00:00:00',
        imageURl: '2020/86e33331-c65d-450e-b04b-a03e854ff131.png',
        reportUrl: 'global-disinfectant-wipes-market',
      },
      {
        id: 'FMCGb8c9d0e1-f2g3h4i5j6k7l8m9n0o1p2q3',
        report_title: 'Global Protective Footwear Market - Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Protective Footwear Market, By Material (Waterproof, Rubber, Leather, Plastic, and Polyurethane), Type (Shoes, Boots), Applications (Manufacturing, Construction, Mining, Oil and Gas, Chemicals, Food, Pharmaceutical, and Transportation) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-15T00:00:00',
        imageURl: '2020/86e33331-c65d-450e-b04b-a03e854ff131.png',
        reportUrl: 'global-protective-footwear-market',
      },
      {
        id: 'FMCGr4s5t6u7-v8w9x0y1z2a3b4c5d6e7f8g9',
        report_title: 'Global Consumer Electronics Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Consumer Electronics Market, By Product Type (Consumer Electronic Devices, Wearable Devices, Smart Home Devices), Application (Personal, Professional), Distribution Channel (Hypermarkets/Supermarkets, Specialty Stores, Multi-brand Stores, Online Retailers, Wholesalers and Distributors, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-14T00:00:00',
        imageURl: '2020/86e33331-c65d-450e-b04b-a03e854ff131.png',
        reportUrl: 'global-consumer-electronics-market',
      },
      {
        id: 'FMCGh0i1j2k3l4-m5n6o7p8q9r0s1t2u3v4w5',
        report_title: 'Global Kiteboarding Equipment Market - Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Kiteboarding Equipment Market, By Type (Kiteboards, Accessories, Protective Gear and Others), Distribution Channel (Online and Offline) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-13T00:00:00',
        imageURl: '2020/86e33331-c65d-450e-b04b-a03e854ff131.png',
        reportUrl: 'global-kiteboarding-equipment-market',
      },
      {
        id: 'FMCGx6y7z8a9-b0c1d2e3f4g5h6i7j8k9l0m1',
        report_title: 'Global Pool Tables Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Pool Tables Market, By Type (American Pool Tables, English Pool Tables, Others), Size (7 ft., 8 ft., 9 ft., others), End Use (Household, Commercial, Professional) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-12T00:00:00',
        imageURl: '2020/86e33331-c65d-450e-b04b-a03e854ff131.png',
        reportUrl: 'global-pool-tables-market',
      },
      {
        id: 'FMCGn2o3p4q5-r6s7t8u9v0w1x2y3z4a5b6c7',
        report_title: 'Global Sneakers Market - Industry Trends and Forecast to 2030',
        short_discription: [
          'Global sneakers market, By Product Type (Low-Top Sneakers, Mid-Top Sneakers, High-Top Sneakers), End-User (Men, Women, Kids), Price Point (Luxury, Economic), Category (Private Label, Branded), Distribution Channel (Supermarket/Hypermarket, Specialty Stores, Brand Outlets, <a href="https://www.databridgemarketresearch.com/reports/asia-pacific-e-commerce-market">E-commerce</a>, Others)&nbsp;- Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-12T00:00:00',
        imageURl: '2020/86e33331-c65d-450e-b04b-a03e854ff131.png',
        reportUrl: 'global-sneakers-market',
      },
    ],
  },
  {
    id: '4c21d4bd-82d5-4f71-88f3-d8a5dd6b5d43',
    title: 'Agreen and animal feed',
    industry: [
      {
        id: 'AFa2b3c4d5-e6f7g8h9i0j1k2l3m4n5o6',
        report_title:
          'Global Microbial Based Biological Seed Treatment Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Microbial Based Biological Seed Treatment Market, By Product (Biofertilizers, Biostimulants, Biopesticides, Plant Growth Regulators (PGRs), and Others), Form (Dry and Liquid), Method (Seed Coating, Seed Pelleted, Suspensions, Seed Dressing, and Others), Treatment Machine (Coating Machine, Dressing Machine, and Pelleting Machine), Function (Seed Protection, and Seed Enchantments), Distribution Channel (Direct Tenders, and Retailing), Crop Type ( Cereals &amp; Grains, Oilseed &amp; Pulses, Fruits, Vegetables, Herbs, Turf &amp; Ornamental, Floral, and Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-27T00:00:00',
        imageURl: '2020/0912a281-aa67-45eb-b45d-8ffbb5fa188b.jpg',
        reportUrl: 'global-microbials-based-biological-seed-treatment-market',
      },
      {
        id: 'AFp7q8r9s0-t1u2v3w4x5y6z7a8b9c0d1e2',
        report_title:
          'Middle East and Africa Vegetables and Fruits Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Middle East and Africa Vegetables and Fruits Market, By Type (Vegetable Type and Fruit Type), Product Type (Fresh, Canned, Frozen, Dried and Dehydrated, Purees and Paste, Concentrates, and Others), Category (Vegetables and Fruits), Nature (Vegetables and Fruits), Flavour (Vegetables and Fruits), Form, (Vegetables and Fruits), Packaging (<a href="https://www.databridgemarketresearch.com/reports/global-pouches-market">Pouches</a>, Tray, Bags, Cans, Jar, Boxes, and Others), End-User (Retail/Household and Food Service), Distribution Channel (Store Based Retailers and Non-Store Based Retailers) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-18T00:00:00',
        imageURl: '2020/0912a281-aa67-45eb-b45d-8ffbb5fa188b.jpg',
        reportUrl: 'middle-east-and-africa-vegetables-and-fruits-market',
      },
      {
        id: 'AFf5g6h7i8-j9k0l1m2n3o4p5q6r7s8t9',
        report_title:
          'Global Genetic Engineering Plant Genomics Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Genetic Engineering Plant Genomics Market, By Type (Molecular Engineering, Genetic Engineering and Genome Editing and Others), Trait (Yield Improvement, Disease Resistance, Herbicide Tolerance and Insect Resistance and Abiotic Stress Tolerance), Objectives (DNA Extraction and Purification, DNA/RNA Sequencing, Genotyping, Gene Expression Profiling, Marker-Assisted Selection, GMO-Trait Purity Testing, Hybrid Purity Testing, Targeted Resequencing, DNA Parentage Testing, QTL Mapping and Genetic Disease), Application (Cereals and Grains, <a href="https://www.databridgemarketresearch.com/reports/global-oilseeds-market">Oilseeds</a> and Pulses, Fruits and Vegetables, Sugar Crops, Ornamentals and Alfalfa - (Industry Trends and Forecast to 2023 to 2030.',
        ],
        publishDate: '2023-10-16T00:00:00',
        imageURl: '2020/0912a281-aa67-45eb-b45d-8ffbb5fa188b.jpg',
        reportUrl: 'global-genetic-engineering-plant-genomics-market',
      },
      {
        id: 'AFv0w1x2y3-z4a5b6c7d8e9f0g1h2i3j4',
        report_title:
          'Global Remote Sensing Technology for Agriculture Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Remote Sensing Technology for Agriculture Market, By Component (Hardware, Software, and Service), Application (Yield Monitoring, Field Mapping, Crop Scouting, Weather Tracking &amp; Forecasting, Irrigation Management, Inventory Management, Farm Labour Management, and Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-15T00:00:00',
        imageURl: '2020/0912a281-aa67-45eb-b45d-8ffbb5fa188b.jpg',
        reportUrl: 'global-remote-sensing-technology-for-agriculture-market',
      },
      {
        id: 'AFo0p1q2r3-s4t5u6v7w8x9y0z1a2b3c4d5',
        report_title:
          'Global Smart Harvest for Indoor Farming Market - Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Smart Harvest for Indoor Farming Market, By Component (Harvesting robots, GPS, Sensors, RFID, Others), Technology (Hardware, Software), Crop type (Vegetables, Fruits) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-12T00:00:00',
        imageURl: '2020/0912a281-aa67-45eb-b45d-8ffbb5fa188b.jpg',
        reportUrl: 'global-smart-harvest-for-indoor-farming-market',
      },
      {
        id: 'AFi6j7k8l9-m0n1o2p3q4r5s6t7u8v9w0x1y2',
        report_title:
          'Global Pest Control for Livestock Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Pest Control for Livestock Market, By Control Method (Chemical, Biological, Mechanical, Software &amp; Services, and Others), Mode of Application (Sprays, Powder, Pellets, Traps, Baits, and Others), Pest Type (Insects, Rodents, Birds, Wildlife, Reptiles, Arachnida, Termite, Molluscs, and Others), End Use (Cattle Farms, Poultry Farms, Pig Farms, Sheep Farms, Horse Farms, Goat Farms, Residential / Household, and Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-12T00:00:00',
        imageURl: '2020/0912a281-aa67-45eb-b45d-8ffbb5fa188b.jpg',
        reportUrl: 'global-pest-control-for-livestock-market',
      },
      {
        id: 'AFz3a4b5c6d7-e8f9g0h1i2j3k4l5m6n7o8',
        report_title:
          'Global Agricultural Fertigation and Chemigation Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Agricultural Fertigation and Chemigation Market, By Agricultural Input (Fertilizer, Insecticide, Fungicide, Herbicide, Others), Crop Type (Field Crops, Plantation Crops, Orchard Crops, <a href="https://www.databridgemarketresearch.com/reports/global-forages-market">Forage</a> and Turf Grasses), Application (Agriculture Irrigation, Landscape Irrigation, Greenhouse Irrigation, Others), Irrigation System (Sprinkler Irrigation, Drip Irrigation, Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-12T00:00:00',
        imageURl: '2020/0912a281-aa67-45eb-b45d-8ffbb5fa188b.jpg',
        reportUrl: 'global-agricultural-fertigation-and-chemigation-market',
      },
      {
        id: 'AFg6h7i8j9-k0l1m2n3o4p5q6r7s8t9u0v1w2',
        report_title: 'Global Pet Food Ingredients Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Pet Food Ingredients Market, By Source (Animal Derivatives, Plant Derivatives, Synthetic Derivatives), Ingredient (Meat and Meat Products, Cereals, Vegetables, Fats, Additives), Animal (Dog, Cat, Bird, Fish, Others), Form (Dry Pet Food, Wet Pet Food, Mixture) - Industry Trends and Forecast to 2023 to 2030.',
        ],
        publishDate: '2023-10-11T00:00:00',
        imageURl: '2020/0912a281-aa67-45eb-b45d-8ffbb5fa188b.jpg',
        reportUrl: 'global-pet-food-ingredients-market',
      },
      {
        id: 'AFx3y4z5a6-b7c8d9e0f1g2h3i4j5k6l7m8n9',
        report_title: 'Global Smart Agriculture Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Smart Agriculture Market, By Agriculture Type (Precision Farming, Precision Forestry, Livestock Monitoring, Smart Greenhouse, Precision Aquaculture, Others), Solution (Network Management, Agriculture Asset Management, Supervisory Control and Data Acquisition, Logistics and Supply Chain Management, Smart Water Management, Others), Offering (Hardware, Software, Services), Application (Yield Monitoring, Field Mapping, Crop Scouting, Weather Tracking and Forecasting, Irrigation Management, Farm Labor Management, Financial Management, Feeding Management, Milk Harvesting, Breeding Management, Fish Tracking and Fleet Navigation, Water Quality Management, HVAC Management, Others) - Industry Trends and Forecast to 2023 to 2030.',
        ],
        publishDate: '2023-10-11T00:00:00',
        imageURl: '2020/0912a281-aa67-45eb-b45d-8ffbb5fa188b.jpg',
        reportUrl: 'global-smart-agriculture-market',
      },
      {
        id: 'AFu0v1w2x3-y4z5a6b7c8d9e0f1g2h3i4j5k6',
        report_title: 'Global Silage Inoculants Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Silage Inoculants Market, By Type (Homo-Fermentative, Hetero-Fermentative and Homo and Hetero-Fermentative), Form (Dry and Liquid), Application (Cereals and Grains, Legumes and Pulses and others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-09T00:00:00',
        imageURl: '2020/0912a281-aa67-45eb-b45d-8ffbb5fa188b.jpg',
        reportUrl: 'global-silage-inoculants-market',
      },
      {
        id: 'AFk7l8m9n0-o1p2q3r4s5t6u7v8w9x0y1z2a3b4',
        report_title:
          'Global Agriculture Tractor Tires Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Agriculture Tractor Tires Market, By Product (Radial and Bias), Distribution Channel (Aftermarket and OEM) - Industry Trends and Forecast to 2023 to 2030.',
        ],
        publishDate: '2023-10-06T00:00:00',
        imageURl: '2020/0912a281-aa67-45eb-b45d-8ffbb5fa188b.jpg',
        reportUrl: 'global-agriculture-tractor-tires-market',
      },
      {
        id: 'AFc5d6e7f8-g9h0i1j2k3l4m5n6o7p8q9r0s1',
        report_title: 'Global Hemp Fiber Market - Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Hemp Fiber Market, By Source (Organic, and Conventional), Application (Food, Beverages, Personal Care Products, <a href="https://www.databridgemarketresearch.com/reports/global-textile-market">Textiles</a>, Pharmaceuticals, and Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-05T00:00:00',
        imageURl: '2020/0912a281-aa67-45eb-b45d-8ffbb5fa188b.jpg',
        reportUrl: 'global-hemp-fiber-market',
      },
      {
        id: 'AFt2u3v4w5-y6z7a8b9c0d1e2f3g4h5i6j7',
        report_title:
          'Global Farm Management Software and Services Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Farm Management Software and Services Market, By Deployment Type (On-Premises, Cloud Based), Agriculture Type (Precision Farming, <a href="https://www.databridgemarketresearch.com/reports/global-livestock-monitoring-market">Livestock Monitoring</a>, Fish Farming, Smart Greenhouse Farming), Service Type (Professional Services, Managed Services, Maintenance and Support Services, Others), Application (Record Keeping, Farm Mapping, Monitoring and Forecasting, Farm Economics) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-04T00:00:00',
        imageURl: '2020/0912a281-aa67-45eb-b45d-8ffbb5fa188b.jpg',
        reportUrl: 'global-farm-management-software-and-services-market',
      },
      {
        id: 'AFm8n9o0p1-n2o3p4q5r6s7t8u9v0w1x2y3z4a5b6',
        report_title:
          'Global Hybrid Fruits and Vegetable Seeds Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Hybrid Fruits and Vegetable Seeds Market, By Crop Type (Cereals and Grains, Fruits and Vegetables and Oilseeds), Seed Treatment (Treated and Untreated), Duration (Short-term Hybrid Fruits and Vegetable Seeds, Medium-term Hybrid Fruits and Vegetable Seeds and Long-term Hybrid Fruits and Vegetable Seeds), Farm Type (Indoor and Outdoor) - Industry Trends and Forecast to 2023 to 2030.',
        ],
        publishDate: '2023-10-03T00:00:00',
        imageURl: '2020/0912a281-aa67-45eb-b45d-8ffbb5fa188b.jpg',
        reportUrl: 'global-hybrid-fruits-and-vegetable-seeds-market',
      },
      {
        id: 'AFo7p8q9r0-s1t2u3v4w5x6y7z8a9b0c1d2e3f4',
        report_title: 'Global Plant Antifreeze Market - Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Plant Antifreeze Market, By Product Type (Glycerine, Propylene Glycol, Ethylene Glycol), Application (Crops, Fruit Plants, Flowering Plants and Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-09-30T00:00:00',
        imageURl: '2020/0912a281-aa67-45eb-b45d-8ffbb5fa188b.jpg',
        reportUrl: 'global-plant-antifreeze-market',
      },
      {
        id: 'AFu5v6w7x8-y9z0a1b2c3d4e5f6g7h8i9j0k1l2m3',
        report_title: 'Global Vegetable Snacks Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Vegetable Snacks Market, By Type (Chips, Nuts and Seeds, Biscuits and Cookies, Popcorns, and Others), Distribution Channel (Store-Based, Supermarket and Hypermarket, Convenience Store and Non-Store-Based), Packaging Type (Pouches, Cans, Jars, and Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-09-30T00:00:00',
        imageURl: '2020/0912a281-aa67-45eb-b45d-8ffbb5fa188b.jpg',
        reportUrl: 'global-vegetable-snacks-market',
      },
      {
        id: 'AFh4i5j6k7-l8m9n0o1p2q3r4s5t6u7v8w9x0y1z2',
        report_title:
          'Global Liquid Potassium Fertilizers Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Liquid Potassium Fertilizers Market, By Category (Organic, Synthetic), Crop Type (Cereals and Grains, Fruits and Vegetables, <a href="https://www.databridgemarketresearch.com/reports/global-oilseeds-market">Oilseeds</a> and Pulses, Others), Mode of Application (Soil, Foliar, Fertigation, Others) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-09-28T00:00:00',
        imageURl: '2020/0912a281-aa67-45eb-b45d-8ffbb5fa188b.jpg',
        reportUrl: 'global-liquid-potassium-fertilizers-market',
      },
      {
        id: 'AFq3r4s5t6-u7v8w9x0y1z2a3b4c5d6e7f8g9h0i1',
        report_title:
          'Global Big Data Analytics in Agriculture Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Big Data Analytics in Agriculture Market, By Type (Capturing Data, Storing Data, Sharing Data, Analyzing Data, and Others), Application (Crop Production, Farm Equipment, Weather, and Chemicals), End users (Farmers, Agriculture Regulatory Bodies, Weather Forecast, <a href="https://www.databridgemarketresearch.com/reports/global-agrochemicals-market">Agrochemical</a> and Farm Equipment Industries) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-09-26T00:00:00',
        imageURl: '2020/0912a281-aa67-45eb-b45d-8ffbb5fa188b.jpg',
        reportUrl: 'global-big-data-analytics-in-agriculture-market',
      },
      {
        id: 'AFq3r4s5t6-u7v8w9x8951z2a3b4c5d6e7f8g9h0i1',
        report_title: 'Global Fertilizer Spreader Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Fertilizer Spreader Market, By Product Type (Dry Spreaders, Drop Spreaders, Rotary Spreaders, Liquid Spreaders and Pendulum Spreaders), Components (Hopper or Storage, Drop Tube and Fertilizer Distributor), Application (Farm, Garden Landscape and Others), Distribution Channel (Original Equipment Manufacturer (OEM) and Aftermarket) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-09-26T00:00:00',
        imageURl: '2020/0912a281-aa67-45eb-b45d-8ffbb5fa188b.jpg',
        reportUrl: 'global-fertilizer-spreader-market',
      },
      {
        id: 'AFq3r490t6-u7v8w9x0y1z2a3b4c5d6e7f8g9h0i1',
        report_title: 'Global Rumen Bypass Fat Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Rumen Bypass Fat Market, By Type (Calcium Soap, Triglycerides, Hydrogenated Fats, and Special Blends), Oil (<a href="https://www.databridgemarketresearch.com/reports/global-palm-oil-market\'">Palm Oil</a>, Rapeseed Oil, <a href="https://www.databridgemarketresearch.com/reports/global-olive-oil-market">Olive Oil</a>, and Others), Acid (Palmitic Acid, Stearic Acid, Linoleic Acid, and Myristic Acid), Application (Dairy Cow Nutrition, Beef Cattle Nutrition, Sheep and Goat Nutrition, and Other Ruminants) - Industry Trends and&nbsp; Forecast to 2030.',
        ],
        publishDate: '2023-09-07T00:00:00',
        imageURl: '2020/0912a281-aa67-45eb-b45d-8ffbb5fa188b.jpg',
        reportUrl: 'global-rumen-bypass-fat-market',
      },
    ],
  },
  {
    id: '6cf8e8e5-3b97-4827-bdfc-1a5a7e7cb654',
    title: 'Industrial Automation',
    industry: [
      {
        id: 'IAa1b2c3d4-e5f6g7h8i9j0k1l2m3n4o5',
        report_title:
          'North America Decanter Centrifuge Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'North America Decanter Centrifuge Market, Offering (Product and Services), Design (Horizontal and Vertical), Enterprise Size (Large Enterprise and Small and Medium Enterprise), End-User (Waste Water Treatment, Oil/Gas Exploration and Petrochemical Industry, Food and Beverages, Mining and Metal Processing, Chemical, Pharmaceutical and Biotechnology, Pulp and Paper, Polymer Industry, Water Purification and Others), Distribution Channel (Direct and Indirect) - Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-02T00:00:00',
        imageURl: '2023/1ca1dec4-cbea-4bc5-8686-87b34c49a6dc.png',
        reportUrl: 'north-america-decanter-centrifuge-market',
      },
      {
        id: 'IAb1c2d3e4-f5g6h7i8j9k0l1m2n3o4',
        report_title:
          'Global Scroll and Absorption Chillers Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Scroll and Absorption Chillers Market, By Product (Scroll Chiller, Absorption Chiller), Capacity (Greater than 100 KW, Ranges between 101 KW to 300 KW and 301 KW to 700 KW, Less than 701 KW), Vertical (Commercial, Industrial, Others) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-09-27T00:00:00',
        imageURl: '2023/1ca1dec4-cbea-4bc5-8686-87b34c49a6dc.png',
        reportUrl: 'global-scroll-and-absorption-chillers-market',
      },
      {
        id: 'IAc1d2e3f4-g5h6i7j8k9l0m1n2o3',
        report_title:
          'Global Packaged Water Treatment Market – Industry Trends and Forecast to 2029',
        short_discription: [
          'Global Packaged Water Treatment Market,&nbsp;By Technology Type (Extended Aeration, Moving Bed Biofilm Reactor, Membrane Bioreactor, Sequential Batch Reactor, Reverse Osmosis, Other Technologies), Application (Municipal Waste water, Industrial Waste water, Drinking Water) &ndash; Industry Trends and Forecast to 2029',
        ],
        publishDate: '2022-09-19T12:54:04.7697363',
        imageURl: '2023/1ca1dec4-cbea-4bc5-8686-87b34c49a6dc.png',
        reportUrl: 'global-packaged-water-treatment-market',
      },
      {
        id: 'IAd1e2f3g4-h5i6j7k8l9m0n1o2',
        report_title: 'Global Grinding Machinery Market – Industry Trends and Forecast to 2029',
        short_discription: [
          'Global Grinding Machinery Market,&nbsp;By Type (Cylindrical, Surface, Gear, Tool &amp; Cutter, and Other Grinding Machinery), Application (Aerospace &amp; Defense, Automotive, Electrical &amp; Electronics, Shipbuilding, and Others), Distribution Channel (Online and Offline) &ndash; Industry Trends and Forecast to 2029',
        ],
        publishDate: '2022-09-13T22:43:28.0404401',
        imageURl: '2023/1ca1dec4-cbea-4bc5-8686-87b34c49a6dc.png',
        reportUrl: 'global-grinding-machinery-market',
      },
      {
        id: 'IAe1f2g3h4-i5j6k7l8m9n0o1',
        report_title: 'Global Laser Welding Machine Market – Industry Trends and Forecast to 2029',
        short_discription: [
          'Global Laser Welding Machine Market,&nbsp;By Technology (Fiber Laser Welding Machine, Co₂ Laser Welding Machine and Solid-State Laser Welding Machine), Application (Medical, Electronics, Jewelry industry, Automotive, Tool and Mold-making and Others) &ndash; Industry Trends and Forecast to 2029.',
        ],
        publishDate: '2022-09-08T00:00:00',
        imageURl: '2023/1ca1dec4-cbea-4bc5-8686-87b34c49a6dc.png',
        reportUrl: 'global-laser-welding-machine-market',
      },
      {
        id: 'IAf1g2h3i4-j5k6l7m8n9o0',
        report_title: 'Global Spinning Machinery Market – Industry Trends and Forecast to 2029',
        short_discription: [
          'Global Spinning Machinery Market,&nbsp;By Type (Ring and Rotor Spinning), Material (Natural, Synthetic and others), End-Use Industry (Clothing, Textile and other Industry) &ndash; Industry Trends and Forecast to 2029.',
        ],
        publishDate: '2022-08-14T21:17:31.9354683',
        imageURl: '2023/1ca1dec4-cbea-4bc5-8686-87b34c49a6dc.png',
        reportUrl: 'global-spinning-machinery-market',
      },
      {
        id: 'IAg1h2i3j4-k5l6m7n8o9',
        report_title:
          'Global Environmental Test Chambers Market – Industry Trends and Forecast to 2029',
        short_discription: [
          'Global Environmental Test Chambers Market,&nbsp;By Type (Temperature and Humidity Chamber, Thermal Shock, Corrosion Test Chamber, Xenon Test Chamber and Other), Application (Automotive, Aerospace, Electronics, Biological, Pharmaceutical) &ndash; Industry Trends and Forecast to 2029',
        ],
        publishDate: '2022-08-09T17:05:20.350331',
        imageURl: '2023/1ca1dec4-cbea-4bc5-8686-87b34c49a6dc.png',
        reportUrl: 'global-environmental-test-chambers-market',
      },
      {
        id: 'IAh1i2j3k4-l5m6n7o8',
        report_title: 'Global Nanorobotics Market – Industry Trends and Forecast to 2029',
        short_discription: [
          'Global Nanorobotics Market,&nbsp;By Product Type (Nanomanipulators, Bio-nanorobotics, Magnetically Guided Nanobots/Nanorobots, Others), Application (Biomedical, Nanomedicine, Others), End User (Hospitals, Diagnostic Centers, Research Laboratories, Others) &ndash; Industry Trends and Forecast to 2029',
        ],
        publishDate: '2022-08-09T16:30:03.4029135',
        imageURl: '2023/1ca1dec4-cbea-4bc5-8686-87b34c49a6dc.png',
        reportUrl: 'global-nanorobotics-market',
      },
      {
        id: 'IAi1j2k3l4-m5n6o7p8',
        report_title:
          'Global Field Device Management Market – Industry Trends and Forecast to 2029',
        short_discription: [
          'Global Field Device Management Market,&nbsp;By&nbsp;Offering (Hardware, Software), Communication Protocol (Foundation Fieldbus and Hart, Profibus, Profinet, Ethernet/I.P., Modbus, Others), Deployment Type (On-Premises, Cloud), Industry (Discrete Industries, Process Industries) &ndash; Industry Trends and Forecast to 2029',
        ],
        publishDate: '2022-07-28T00:00:00',
        imageURl: '2023/1ca1dec4-cbea-4bc5-8686-87b34c49a6dc.png',
        reportUrl: 'global-field-device-management-market',
      },
      {
        id: 'IAj1k2l3m4-n5o6p7q8',
        report_title: 'Global Cleanroom Lighting Market – Industry Trends and Forecast to 2029',
        short_discription: [
          'Global Cleanroom Lighting Market,&nbsp;By Light Source (LED, Fluorescent), Mounting Type (Recessed Mounted, Surface Mounted), Component (Hardware, Software, Services), End User (Healthcare and Life Sciences, Manufacturing, Food and Beverages, Others) &ndash; Industry Trends and Forecast to 2029',
        ],
        publishDate: '2022-07-22T00:00:00',
        imageURl: '2023/1ca1dec4-cbea-4bc5-8686-87b34c49a6dc.png',
        reportUrl: 'global-cleanroom-lighting-market',
      },
    ],
  },
  {
    id: '17a6740a-dc91-47a4-9b19-3c072c25c16e',
    title: 'Oil and Gas Energy',
    industry: [
      {
        id: 'OGEa1b2c3d4-e5f6g7h8i9j0k1l2m3n4o5',
        report_title: 'Global Solar Farms Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global Solar Farms Market, By Type (Utility-scale, Distributed Generation, Microgrids, Others) and End User Industry (Residential, Commercial, Industrial) &ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-17T00:00:00',
        imageURl: '2023/28f5614b-f621-4dbd-b0e2-0e20ee5d2bd3.png',
        reportUrl: 'global-solar-farm-market',
      },
      {
        id: 'OGEb1c2d3e4-f5g6h7i8j9k0l1m2n3o4',
        report_title: 'Global Gas Hydrates Market – Industry Trends and Forecast to 2030',
        short_discription: [
          'Global&nbsp;Gas&nbsp;Hydrates Market, By Type (Onshore Gas Hydrates, Offshore/ Marine Gas Hydrates), Origin (Seabed, Permafrost), Application (Transportation Fuel, Commercial, Industrial)&nbsp;&ndash; Industry Trends and Forecast to 2030.',
        ],
        publishDate: '2023-10-03T00:00:00',
        imageURl: '2023/28f5614b-f621-4dbd-b0e2-0e20ee5d2bd3.png',
        reportUrl: 'global-gas-hydrates-market',
      },
      {
        id: 'OGEc1d2e3f4-g5h6i7j8k9l0m1n2o3',
        report_title:
          'Global Next Generation Biofuels Market – Industry Trends and Forecast to 2029',
        short_discription: [
          'Global Next Generation Biofuels Market,&nbsp;By Biofuel Type (<a href="https://www.databridgemarketresearch.com/reports/global-biodiesel-market">Biodiesels</a>, Biogas, Bio butanol, and Others), Process (Biochemical Process and Thermochemical Process), Raw Material (Lignocellulose, Jatropha, Camelina, Algae, and Others), and Application (Transportation, Power Generation and Others) &ndash; Industry Trends and Forecast to 2029',
        ],
        publishDate: '2022-09-22T00:00:00',
        imageURl: '2023/28f5614b-f621-4dbd-b0e2-0e20ee5d2bd3.png',
        reportUrl: 'global-next-generation-biofuels-market',
      },
      {
        id: 'OGEd1e2f3g4-h5i6j7k8l9m0n1o2',
        report_title: 'Global Ozone Generator Market – Industry Trends and Forecast to 2029',
        short_discription: [
          'Global Ozone Generator Market,&nbsp;By Capacity (50mg/hr-5gm/hr, 5gm/hr-100gm/hr, 100gm/hr-1kg/hr, 1kg/hr-5kg/hr, &gt;5kg/hr), Application (Wastewater Treatment, <a href="https://www.databridgemarketresearch.com/reports/global-industrial-wastewater-treatment-market">Industrial Wastewater Treatment</a>, Municipal Wastewater Treatment, Aquaculture, Swimming Pool, Laboratory and Medical and Potable Water Treatment), Type (Ultraviolet Generators, Cold Plasma Generators, Corona Discharge Generators, Electrolytic Generators and Others) &ndash; Industry Trends and Forecast to 2029',
        ],
        publishDate: '2022-09-19T00:00:00',
        imageURl: '2023/28f5614b-f621-4dbd-b0e2-0e20ee5d2bd3.png',
        reportUrl: 'global-ozone-generator-market',
      },
      {
        id: 'OGEe1f2g3h4-i5j6k7l8m9n0o1',
        report_title: 'Global Run of River Power Market – Industry Trends and Forecast to 2029',
        short_discription: [
          'Global Run of River Power Market,&nbsp;By Type (Pondage, Without Pondage) &ndash; Industry Trends and Forecast to 2029',
        ],
        publishDate: '2022-09-13T22:45:51.71539',
        imageURl: '2023/28f5614b-f621-4dbd-b0e2-0e20ee5d2bd3.png',
        reportUrl: 'global-run-of-river-power-market',
      },
      {
        id: 'OGEf1g2h3i4-j5k6l7m8n9o0',
        report_title:
          'Global Bilayer Membrane Heterojunction Organic Solar Cell (OPV) Market – Industry Trends and Forecast to 2029',
        short_discription: [
          'Global Bilayer Membrane Heterojunction Organic Solar Cell (OPV) Market, By&nbsp;Material (Polymers, Small Molecules), Application (BIPV &amp; Architecture, Consumer Electronics, Wearable Devices, Automotive, Military &amp; Device, Others), Physical Size (More than 140*100 mm square, less than 140*100 mm square), End-User (Commercial, Industrial, Residential, Others) &ndash; Industry Trends and Forecast to 2029',
        ],
        publishDate: '2022-09-13T22:39:11.5057242',
        imageURl: '2023/28f5614b-f621-4dbd-b0e2-0e20ee5d2bd3.png',
        reportUrl: 'global-bilayer-membrane-heterojunction-organic-solar-cell-opv-market',
      },
      {
        id: 'OGEg1h2i3j4-k5l6m7n8o9',
        report_title:
          'Global Solar Photovoltaic (PV) Backsheet Market – Industry Trends and Forecast to 2029',
        short_discription: [
          'Global Solar Photovoltaic (PV) Backsheet Market,&nbsp;By Product (TPT-Primed, TPE, PET, PVDF, PEN), Thickness (&lt;100 Micrometer, 100 To 500 Micrometer, &gt; 500 Micrometer), Material (Fluoride, Non Fluoride), Technology (Crystalline, Thin Film, Ultra-Thin Film), Installation (Roof Mounted, Ground, and Floating), End User (Residential, Industrial, Utility, and Others) &ndash; Industry Trends and Forecast to 2029',
        ],
        publishDate: '2022-09-09T23:59:41.8137325',
        imageURl: '2023/28f5614b-f621-4dbd-b0e2-0e20ee5d2bd3.png',
        reportUrl: 'global-solar-photovoltaic-pv-backsheet-market',
      },
      {
        id: 'OGEh1i2j3k4-l5m6n7o8',
        report_title:
          'Global Circulating Fluidized Bed Boiler Market – Industry Trends and Forecast to 2029',
        short_discription: [
          'Global Circulating Fluidized Bed Boiler Market,&nbsp;By Product (Subcritical, Supercritical, Ultra-Supercritical), Capacity (Less than 100 MW, 100&ndash;200 MW, 200&ndash;300 MW, 300 MW, Above), Fuel Type (Coal, Biomass, Others), Application (Energy and Power, Industrial, Others) &ndash; Industry Trends and Forecast to 2029.',
        ],
        publishDate: '2022-09-08T00:00:00',
        imageURl: '2023/28f5614b-f621-4dbd-b0e2-0e20ee5d2bd3.png',
        reportUrl: 'global-circulating-fluidized-bed-boiler-market',
      },
      {
        id: 'OGEi1j2k3l4-m5n6o7p8',
        report_title:
          'Global Space-based Solar Power Market – Industry Trends and Forecast to 2029',
        short_discription: [
          'Global Space-based Solar Power Market,&nbsp;By Solar Satellite Type (Microwave Transmitting Solar Satellite, Laser Transmitting Solar Satellite), Application (Electricity Generation, Space Applications) &ndash; Industry Trends and Forecast to 2029',
        ],
        publishDate: '2022-09-02T00:00:00',
        imageURl: '2023/28f5614b-f621-4dbd-b0e2-0e20ee5d2bd3.png',
        reportUrl: 'global-space-based-solar-power-market',
      },
      {
        id: 'OGEj1k2l3m4-n5o6p7q8',
        report_title: 'Global Bioenergy Market – Industry Trends and Forecast to 2029',
        short_discription: [
          'Global Bioenergy Market,&nbsp;By Product Type (Solid Biomass, Liquid Biofuel, Biogas, Others), Feedstock (Agricultural Waste, Wood Waste, Solid Waste, and Others), Technology (Gasification, Fast Pyrolysis, Fermentation, Other Technologies), Application (Power Generation, Heat Generation, Transportation, Others) &ndash; Industry Trends and Forecast to 2029',
        ],
        publishDate: '2022-09-02T00:00:00',
        imageURl: '2023/28f5614b-f621-4dbd-b0e2-0e20ee5d2bd3.png',
        reportUrl: 'global-bioenergy-market',
      },
    ],
  },
];
export const currencies = [
  { label: 'Afghanistan', value: 'AF' },
  { label: 'land Islands', value: 'AX' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'AndorrA', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguilla', value: 'AI' },
  { label: 'Antarctica', value: 'AQ' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bermuda', value: 'BM' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Bouvet Island', value: 'BV' },
  { label: 'Brazil', value: 'BR' },

  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Cape Verde', value: 'CV' },
  { label: 'Cayman Islands', value: 'KY' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Christmas Island', value: 'CX' },
  { label: 'Cocos (Keeling) Islands', value: 'CC' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Congo', value: 'CG' },
  { label: 'Congo, The Democratic Republic of the', value: 'CD' },
  { label: 'Cook Islands', value: 'CK' },
  { label: 'Costa Rica', value: 'CR' },
  { label: "Cote D'Ivoire", value: 'CI' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Falkland Islands (Malvinas)', value: 'FK' },
  { label: 'Faroe Islands', value: 'FO' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'French Guiana', value: 'GF' },
  { label: 'French Polynesia', value: 'PF' },
  { label: 'French Southern Territories', value: 'TF' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Heard Island and Mcdonald Islands', value: 'HM' },
  { label: 'Holy See (Vatican City State)', value: 'VA' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Iran, Islamic Republic Of', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: "Korea, Democratic People'S Republic of", value: 'KP' },
  { label: 'Korea, Republic of', value: 'KR' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: "Lao People'S Democratic Republic", value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libyan Arab Jamahiriya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Macao', value: 'MO' },
  { label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Micronesia, Federated States of', value: 'FM' },
  { label: 'Moldova, Republic of', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montenegro', value: 'ME' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Netherlands Antilles', value: 'AN' },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Niue', value: 'NU' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palau', value: 'PW' },
  { label: 'Palestinian Territory, Occupied', value: 'PS' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Pitcairn', value: 'PN' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Reunion', value: 'RE' },
  { label: 'Romania', value: 'RO' },
  { label: 'Russian Federation', value: 'RU' },
  { label: 'RWANDA', value: 'RW' },
  { label: 'Saint Helena', value: 'SH' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Saint Pierre and Miquelon', value: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'ST' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbia', value: 'RS' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { label: 'Spain', value: 'ES' },
  { label: 'Srilanka', value: 'LK' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Suriname', value: 'SR' },
  { label: 'Svalbard and Jan Mayen', value: 'SJ' },
  { label: 'Swaziland', value: 'SZ' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syrian Arab Republic', value: 'SY' },
  { label: 'Taiwan, Province of China', value: 'TW' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania, United Republic of', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Turks and Caicos Islands', value: 'TC' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'United States', value: 'US' },
  { label: 'United States Minor Outlying Islands', value: 'UM' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Vietnam', value: 'VN' },
  { label: 'Virgin Islands, British', value: 'VG' },
  { label: 'Virgin Islands, U.S.', value: 'VI' },
  { label: 'Wallis and Futuna', value: 'WF' },
  { label: 'Western Sahara', value: 'EH' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' },
];
