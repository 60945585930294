import React from 'react';
import { useParams } from 'react-router-dom';
import PrimaryReport from '../../Molecule/PrimaryReport/PrimaryReport';

function SearchReport() {
  const { search } = useParams();

  return (
    <div>
      <PrimaryReport id="" search={search} />
    </div>
  );
}

export default SearchReport;
