import React from "react";
import "./PricingCard.scss"; // Import your CSS file here
import InfoDialog from "../InfoDialog/InfoDialog";

import { StarIcon } from "../../../Asset/Icons/Icons";

const PricingCard = ({ pricing_data }) => {
  return (
    <>
      {pricing_data.map((cardData, index) => {
        return (
          <div key={index} className='price_card'>
            <div className='card'>
              <div className='card__side card__side--front'>
                <div className='card__picture card__picture--2'>&nbsp;</div>
                <h4 className='card__heading'>
                  <span className='card__heading-span card__heading-span--2'>
                    {cardData.planTitle}{" "}
                    {cardData.bestSeller ? StarIcon : <></>}
                  </span>
                </h4>
                <div className='card__details'>
                  <ul>
                    {cardData?.info?.map((data, index) => {
                      return <li key={index}>{data}</li>;
                    })}
                  </ul>
                </div>
              </div>
              <div className='card__side card__side--back card__side--back-2'>
                <div className='card__cta'>
                  <div className='card__price-box'>
                    {cardData.bestSeller ? (
                      <h1>Best Seller {StarIcon}</h1>
                    ) : (
                      <></>
                    )}
                    <h2>{cardData.title01}</h2>
                    <h2>{cardData.title02}</h2>
                    <p className='card__price-only'>per Record</p>
                    <p className='card__price-value'>${cardData.price}</p>
                  </div>
                  <InfoDialog
                    desc0={cardData.desc0}
                    desc1={cardData.desc1}
                    planDetails={cardData.planDetails}
                    plan={cardData.planTitle}
                    Butttontitle={cardData.ButtonTitle}
                    totalCost={cardData.totalCost}
                    paymentDetails={cardData.paymentInfo}
                    desc2={cardData.desc2}
                    desc3={cardData.desc3}
                    price={cardData.price}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default PricingCard;
