import React from 'react';
import './ContactCard.scss';
import Card from '@mui/material/Card';
import { EmailAddressIcon, LocationIcon, PhoneIcon } from '../../../Asset/Icons/Icons';

function ContactCard() {
  return (
    <div className="contact_card_container">
      <Card className="card_container">
        <div className="all_data">
          <div className="icon_div">{PhoneIcon}</div>
          <div className="details_container">
            <h4>Phone Number</h4>
            <p>+91 77095 75164</p>
          </div>
        </div>
        <div className="all_data">
          <div className="icon_div">{EmailAddressIcon}</div>
          <div className="details_container">
            <h4>Email Address</h4>
            <p>info@databoxmarketresearch .com</p>
          </div>
        </div>
        <div className="all_data">
          <div className="icon_div">{LocationIcon}</div>
          <div className="details_container">
            <h4>Address</h4>
            <p>
              Databox Market Research New Kalyani Nagar, Wadgaon Sheri,F residence,Karan Ceto
              107,Pune Maharashtra 411014,IN
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default ContactCard;
