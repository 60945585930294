import React, { useState } from 'react';
import PricingCard from '../../Molecule/PricingCard/PricingCard';
import { Paper, Button } from '@mui/material';
import './Pricing.scss';
import {
  Pricing_Card_Data,
  Pricing_Card_Basic_Plans,
  Pricing_Card_Entrepreneur_Plans,
} from '../../../Data/Data';
import PricingCardBulk from '../../Molecule/PricingBulk/PricingBulk';

function Pricing() {
  const [active, setActive] = useState('basic');
  const handleTab = (val) => {
    setActive(val);
  };
  return (
    <div className="pricing_main_container">
      <div className="pricing_buttons">
        <h3 className="heading">Your Business, Your Budget Simple Pricing, Powerful Results</h3>
        <div className="content_div">
          Get a customized delivery with free bonus phone numbers! simply email us, sit back, relax,
          and receive your file within hours directly in your inbox. Connect effortlessly with
          high-quality phone and email data tailored just for you.
        </div>
        <h1>Plans & Pricing </h1>
        {/* <div className='button_div'>
          <Button
            variant='contained'
            onClick={() => handleTab("basic")}
            className={
              active === "basic" ? "active buttton_class" : "buttton_class"
            }
          >
            Basic
          </Button>
          <Button
            variant='contained'
            onClick={() => handleTab("entrepreneur")}
            className={
              active === "entrepreneur"
                ? "active buttton_class"
                : "buttton_class"
            }
          >
            Entrepreneur
          </Button>
          <Button
            variant='contained'
            onClick={() => handleTab("premium")}
            className={
              active === "premium" ? "active buttton_class" : "buttton_class"
            }
          >
            Premium
          </Button>
        </div> */}
      </div>

      {active === 'basic' ? (
        <>
          <h1>Basic Plans </h1>
          <div className="pricing_page_container">
            <PricingCardBulk pricing_data={Pricing_Card_Basic_Plans} />
          </div>
        </>
      ) : (
        <></>
      )}
      {/* 
      {active === 'entrepreneur' ? (
        <>
          <h1>Entrepreneur Plans </h1>
          <div className="pricing_page_container">
            <PricingCardBulk pricing_data={Pricing_Card_Entrepreneur_Plans} />
          </div>
        </>
      ) : (
        <></>
      )}
      {active === 'premium' ? (
        <>
          <h1>Premium Pricing</h1>
          <div className="pricing_page_container">
            <PricingCard pricing_data={Pricing_Card_Data} />
          </div>
        </>
      ) : (
        <></>
      )} */}
    </div>
  );
}

export default Pricing;
