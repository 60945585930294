import React, { useEffect } from 'react';
import './Terms.scss';
import FaqComp from '../../Molecule/FaqComp/FaqComp';
import SearchComponent from '../../Molecule/SearchComponent/SearchComponent';
const TermsData = [
  'Online Requests: Upon successful payment processing, the requested market research report will be delivered to the provided email address within 48-72 hours.',
  'Physical Requests: For hard copy deliveries, the report will be sent to the corresponding address obtained through email within three to five business working days post the payment receiving date. Extra charges may apply based on geographic locations.',
  'Refund and Cancellation: Prior to placing an order, please carefully review all terms and conditions. Once payment is processed, orders cannot be canceled, and refunds are not applicable.',
  'Customization Requests: We offer tailored market research reports to meet client requirements. The additional time needed for customization will be determined by our expert team of business development and dedicated research analysts, considering various perspectives.',
];
const desclaimerData = [
  'Our market research reports compile informative data through secondary research, utilizing sources like annual reports, press releases, websites, white papers, certified publications, and investor presentations from global industry leaders. While global-level market insights are verified through primary research, discrepancies may occur',
  'Databox Market Research disclaims responsibility for any inappropriate data provided by global manufacturers or individual users. Our global database of market reports, created through dedicated ',
  'and expert research services, is strictly confidential and cannot be disclosed to third parties or general publications, aligning with our commitment to client reliability and integrity.',
  'Insights, views, and opinions articulated by our research analysts are extensive; however, we do not guarantee the absolute accuracy of the data. We prioritize the confidentiality of sensitive global market data, ensuring our information services are not circulated, lent, or disclosed to third parties without prior permission from Databox Market Research.',
];
function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="terms_condition_container">
      <SearchComponent />
      <div className="content_div">
        <h1>Terms and conditions</h1>
        <div className="policies_container">
          <ul>
            {TermsData.map((data, index) => {
              return <li key={index}>{data}</li>;
            })}
          </ul>
        </div>
      </div>
      <div className="content_div">
        <h1>Disclaimer</h1>
        <div className="policies_container">
          <ul>
            {desclaimerData.map((data, index) => {
              return <li key={index}>{data}</li>;
            })}
          </ul>
        </div>
      </div>
      <div className="content_div_01">
        <FaqComp />
      </div>
    </div>
  );
}

export default Terms;
